import { useMemo } from 'react';
import { cx } from '@emotion/css';
import {
  FormState,
  LabelCustomEnum,
  LabelQrCodeOptionEnum,
  LabelTypeEnum,
} from 'app/modules/labels/views/Labels/components/Forms/types';
import Box from 'app/ui-components';

import getCodesList from '../../../utils/getCodesList';
import getDetails from '../../../utils/getDetails';
import getFieldList from '../../../utils/getFieldList';
import getSubTitle from '../../../utils/getSubTitle';
import CodeContainer from '../../CodeContainer';
import PdfViewer from '../../PdfViewer';
import { CodeSchema, LabelStockTitleEnum } from '../../types';

interface Props {
  codes: FormState & { data: Array<CodeSchema> };
  type?: LabelTypeEnum;
}

const Avery6570 = (props: Props) => {
  const { codes, type } = props;
  const _codes = useMemo(() => getCodesList(codes, 32), [codes]);
  const field = useMemo(() => getFieldList(codes, LabelQrCodeOptionEnum.Single), [codes]);
  return (
    <>
      <PdfViewer
        margin={{ top: 36, left: 33, bottom: 35, right: 33 }}
        print={LabelStockTitleEnum.Avery6570}
        type={codes.type}>
        {_codes.length > 0 &&
          _codes.map((chunk) => {
            return (
              <Box className={cx(`flex flex-wrap justify-between`)}>
                {chunk?.length > 0 &&
                  chunk?.map((code: any, index: number) => {
                    const detail = getDetails(code, type, codes);
                    const subTitle = getSubTitle(code, type);
                    return (
                      <>
                        {code.isBlank ? (
                          <Box key={index} className={cx(`h-[90px] w-[125px]`)}></Box>
                        ) : (
                          <Box
                            key={index}
                            className={cx(
                              `h-[90px] w-[125px] items-center justify-center px-2 pt-[5px]`,
                            )}>
                            <CodeContainer
                              code={code}
                              displayImage={codes.arrowDisplay}
                              height={'h-[3.4em]'}
                              size={56}
                              type={type}
                              width={'w-[2.5em]'}
                            />

                            <Box className={cx('max-w-[125px] break-words pt-[2px] text-center')}>
                              <span className={'block font-[Inter] text-[10px] leading-[1.2]'}>
                                {detail[0]}
                              </span>
                              <span className={'block font-[Inter] text-[10px] leading-[1.2]'}>
                                {detail[2]}
                              </span>
                              {/* <span className={'block font-[Inter] text-[10px] leading-[1.2]'}>{detail[2]}</span> */}
                              <span className={'block font-[Inter] text-[10px] leading-[1.2]'}>
                                {codes.custom === LabelCustomEnum.Custom ? '' : subTitle}
                              </span>
                            </Box>
                          </Box>
                        )}
                        {index !== 0 && (index + 1) % 32 === 0 && (
                          <Box className={cx('page-break hidden')}></Box>
                        )}
                      </>
                    );
                  })}
              </Box>
            );
          })}
      </PdfViewer>
    </>
  );
};

export default Avery6570;
