import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useUndeleteLayout from 'app/modules/undelete/views/undelete/hook/useUndeleteLayout';

function UndeletePage(props: RouteComponentProps) {
  const { history } = props;
  const { tabs, redirectTo } = useUndeleteLayout(history);

  return (
    <AdminLayout customTabs={tabs} defaultTab={redirectTo}>
      <></>
    </AdminLayout>
  );
}

export default withRouter(UndeletePage);
