import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import DefaultUserPhoto from 'app/assets/default_user.jpg';
import { Menu, MenuItem } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import HeaderNavLink from '../HeaderNavLink';
import routes from '../../../../../consts/routes';
import { logout } from '../../../../auth/views/Login/utils/authTokens';
import useOutSideClick from '../../../../../hooks/useOutSideClick/useOutSideClick';
import { UnifiedHeaderContext } from '../../context';

const ProfileMenu = () => {
  const { onLinkButtonClick } = useContext(UnifiedHeaderContext);
  const { profilePicture, firstName, name } = useCurrentUser();

  const ref = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const onLogoutClick = useCallback(() => {
    onLinkButtonClick(logout);
  }, [onLinkButtonClick]);

  const onOutSideClick = useCallback(() => {
    if (open) {
      toggleMenu();
    }
  }, [open, toggleMenu]);

  useOutSideClick([ref], onOutSideClick);

  return (
    <div className="relative flex items-center" ref={ref}>
      <div className="relative flex h-[100%] cursor-pointer items-center">
        <img
          className="inline-block h-[32px] w-[32px] min-w-[32px] max-w-[32px] items-center rounded-full bg-white object-cover ring-2 ring-white"
          src={profilePicture || DefaultUserPhoto}
          alt={firstName}
          onClick={(event) => {
            event.preventDefault();
            toggleMenu();
          }}
        />
      </div>
      <div
        className={`absolute top-[100%] right-0 z-[1] w-[180px] overflow-hidden rounded-[5px] border-[1px] border-solid border-[rgba(0,0,0,0.2)] bg-white text-grey-900 ease-in ${
          !open ? 'hidden' : ''
        }`}
        style={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <div className="border-bottom-solid bg-grey-100 py-[13px] px-[15px]">
          <div className="mb-[2px] break-words text-[14px]">Signed in as</div>
          <div className="mt-[5px] break-words text-[16px] font-semibold text-grey-900">{name}</div>
        </div>

        <HeaderNavLink
          className="block w-[100%] border-t-[1px] border-t-[rgba(0,0,0,0.15)] bg-white px-[17px] py-[15px] text-[16px] font-normal hover:text-blue-900"
          to={routes.Profile()}
          onClick={toggleMenu}>
          <span>Profile</span>
        </HeaderNavLink>
        <button
          className="block w-[100%] border-t-[1px] border-t-[rgba(0,0,0,0.15)] bg-white px-[17px] py-[15px] text-left text-[16px] font-normal hover:text-blue-900"
          onClick={onLogoutClick}>
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  );
};

export default ProfileMenu;
