import { Controller } from 'react-hook-form';
import QRCode from 'qrcode.react';
import FormSelect from 'app/components/Form/FormSelect';
import Label from 'app/i18n/Label';
import Box from 'app/ui-components/Box';

import useLabelsFrom from '../../../context/useLabelsFrom';
import { LabelCustomEnum } from '../types';
import { getCustomFieldOptions } from '../utils';

interface Props {
  type?: string;
}
const TopQrCodeLabels = (props: Props) => {
  const { type } = props
  const { control, isCustomFieldValid } = useLabelsFrom();

  return (
    <form>
      <Box className="grid max-w-[414px] grid-cols-1 gap-4 rounded-md border border-solid border-[#c4c4c4]">
        <>
          <Box className="m-auto max-w-[135px]">
            <QRCode fgColor="#000" renderAs={'svg'} size={150} value="Procure" />
          </Box>
          <Box className="m-10 mt-0">
            <Controller
              control={control}
              name="firstField"
              render={({ field, fieldState }) => (
                <FormSelect
                  {...field}
                  error={fieldState.error}
                  options={getCustomFieldOptions(type, LabelCustomEnum.Custom)}
                  placeholder="Select Field"
                />
              )}
            />
          </Box>
        </>
      </Box>
      {isCustomFieldValid && (
        <span className="text-[12px] text-[#ec0a0b]">
          {Label.FormValidationMessages.CustomLabelRequired}
        </span>
      )}
    </form>
  );
};

export default TopQrCodeLabels;
