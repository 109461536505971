import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import User from 'app/i18n/User';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import currentUser from 'app/modules/auth/utils/currentUser';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';

import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteUsersMutation } from './graphql/mutations/generated/deleteUsers';
import { useUsersTableQuery } from './graphql/queries/generated/usersTable';
import { getUserTableColumns } from './utils/columns';
import analytics from 'app/analytics';

const columns = getUserTableColumns();

function Users(props: RouteComponentProps) {
  const { history } = props;
  const [tenantId, setTenantId] = useState<string | undefined>(currentUser.tenantId());
  const [{ fetching: disabled }, deleteUsers] = useDeleteUsersMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditUser(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      deleteUsers({ input: { userIds: ids } }).then((response) => {
        analytics?.track('Deleted', { name: 'User' });
        if (response.data?.deleteUsers?.success) {
          SnackbarService.show({ message: 'This user has been successfully deleted.' });
        }
      });
    },
    [deleteUsers],
  );

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useUsersTableQuery,
    {
      filters: { search: search.debouncedSearchText, tenantIds: tenantId ? [tenantId] : undefined },
      sorts: table.state.sorts,
    },
    { edgeKey: 'users', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.users.edges?.map((edge) => edge.node) || [];
  }, [data]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager className="!px-[24px] !pt-0" subject={AllowedPermissionsSubjectEnum.User}>
      <EntityManager.Title documentTitle={User.UserAccounts} title={User.Users} />
      <EntityManager.Add label={User.NewUser} to={routes.AddUser()} />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          loading={fetching || disabled}
          pagination={true}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          persistKey="users_table"
          setState={table.setState}
          state={table.state}
          total={data?.users.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Users);
