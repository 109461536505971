const Project = {
  Projects: 'Projects',
  NewProject: 'New Project',
  EditProject: 'Edit Project',
  FormLabels: {
    Name: 'Name',
    Description: 'Description',
    Code: 'Project Code',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Project Name up to 30 characters.',
    DescriptionMaxLength: 'Description must be at most 500 characters',
  },
  FormPlaceholders: {
    Name: 'Enter project name',
    Description: 'Enter project description',
    Code: 'Enter project code',
    Search: 'Search for project',
  },
};

export default Project;
