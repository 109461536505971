import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';

import { isExternalUserHavePartnerTenants } from '../../utils/externalTenant';
import { UseNormalOrderRequestReturnType } from '../OrderItemRequest/hook/useNormalOrderRequest';
import OrderCostPerUnitField from './components/OrderCostPerUnitField';
import OrderItemInStockField from './components/OrderItemInStockField';
import OrderNonStockedFormFields from './components/OrderNonStockedFormFields';
import OrderPartnerTenantField from './components/OrderPartnerTenantField';
import OrderProjectField from './components/OrderProjectField';
import OrderQuantityField from './components/OrderQuantityField';
import { ORDER_REQUEST_FORM_RULES } from './utils/rules';
import { ORDER_INSERT_TYPE, OrderRequestInput } from './type';
import ScrollToError from 'app/utils/ScrollToError';

export interface OrderRequestFormProps {
  onAddItem: (orderItem: OrderRequestInput) => void;
  formState: UseNormalOrderRequestReturnType['normalOrderState']['formState'];
  actions: UseNormalOrderRequestReturnType['normalOrderActions'];
  onBulkOrderRequest: () => void;
  disabled?: boolean;
}

const OrderRequestForm = (props: OrderRequestFormProps) => {
  const { onAddItem, onBulkOrderRequest, disabled, formState, actions } = props;
  const { handleSubmit, onResetForm } = actions;

  const user = useCurrentUser();
  const { isExternalTenant, partnerTenantId, control } = formState;
  const formRef = useRef<HTMLDivElement>(null);

  const onSubmit = useCallback(
    (values: any) => {
      onAddItem(values);
      onResetForm();
    },
    [onAddItem, onResetForm],
  );

  const disabledFormField = useMemo(() => {
    return !!(isExternalUserHavePartnerTenants(user) && !partnerTenantId) || !!disabled;
  }, [partnerTenantId, disabled, user]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, (errors) => ScrollToError(errors, formRef)), [onSubmit, handleSubmit]);

  return (
    <div ref={formRef} className="w-[300px] pt-6 md:min-w-[416px] md:max-w-[416px]  lg:min-w-[416px] lg:min-w-[416px] xl:max-w-[416px] xl:max-w-[416px]">
      <OrderPartnerTenantField
        paddedView
        control={control}
        name="partnerTenantId"
        onPartnerTenantChange={actions.onPartnerTenantChange}
      />

      <EntityManager.Title
        documentTitle={Orders.OrderRequestDocumentTitle}
        title={Orders.RequestItem}
      />

      <form className="order-request-form mt-20">
        <Controller
          control={control}
          name="itemType"
          render={({ field }) => <input type="hidden" {...field} />}
          rules={ORDER_REQUEST_FORM_RULES.itemType}
        />

        <Box className="mb-[20px]">
          <OrderItemInStockField
            name='itemId'
            disabled={disabledFormField}
            formLabel="Item In Stock"
            formState={formState}
            isCollapsed={formState.itemType !== ORDER_INSERT_TYPE.ITEM_IN_STOCK}
            onBulkOrderRequest={onBulkOrderRequest}
          />

          <OrderNonStockedFormFields
            disabled={disabledFormField}
            formLabel="Non-Stocked Items"
            formState={formState}
            isCollapsed={
              !formState.itemType || formState.itemType !== ORDER_INSERT_TYPE.NON_STOCKED_ITEMS
            }
          />
          {formState?.errors?.itemType && (
            <span className="ml-[40px] text-[14px] text-red-800">
              {formState.errors.itemType.message as ReactNode}
            </span>
          )}
        </Box>

        <OrderProjectField
          disabled={disabledFormField}
          formLabel="Project"
          formState={formState}
          isExternalTenant={isExternalTenant}
        />
        <OrderQuantityField
          isRequired
          disabled={disabledFormField}
          formLabel="Quantity"
          formState={formState}
        />
        <OrderCostPerUnitField
          disabled={disabledFormField}
          formLabel="Cost per Unit"
          formState={formState}
        />

        <div className="flex justify-end">
          <Button theme="success" onClick={onFormSubmit} classes="min-w-[120px] h-[44px]">
            {Orders.Actions.AddItem}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OrderRequestForm;
