import { DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getSoftwareDetailsColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'ID',
    value: 'id',
    width: 150,
  },
  {
    id: 'software',
    label: 'Software',
    value: 'software',
  },
];
