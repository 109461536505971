import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type ProjectsTableRowFragment = { __typename?: 'ProjectSchema', id: string, name: string, description?: string | null, projectCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } };

export const ProjectsTableRowFragmentDoc = gql`
    fragment ProjectsTableRow on ProjectSchema {
  id
  name
  description
  projectCode
  updatedBy {
    id
    name
  }
  updatedAt
}
    `;