import { useMemo } from 'react';
import { cx } from '@emotion/css';
import {
  FormState,
  LabelQrCodeOptionEnum,
  LabelTypeEnum,
} from 'app/modules/labels/views/Labels/components/Forms/types';
import Box from 'app/ui-components';

import getCodesList from '../../../utils/getCodesList';
import getFieldList from '../../../utils/getFieldList';
import getTitle from '../../../utils/getTitle';
import CodeContainer from '../../CodeContainer';
import PdfViewer from '../../PdfViewer';
import { CodeSchema, LabelStockTitleEnum } from '../../types';

interface Props {
  codes: FormState & { data: Array<CodeSchema> };
  type?: LabelTypeEnum;
}

const ThreeInchRound = (props: Props) => {
  const { codes, type } = props;
  const _codes = useMemo(() => getCodesList(codes, 80), [codes]);
  const field = useMemo(() => getFieldList(codes, LabelQrCodeOptionEnum.Single), [codes]);
  return (
    <>
      <PdfViewer
        margin={{ top: 44, left: 26, bottom: 44, right: 26 }}
        print={LabelStockTitleEnum.ThreeRoundLabels}
        type={codes.type}>
        {_codes.length > 0 &&
          _codes.map((chunk) => {
            return (
              <Box className={cx('grid grid-cols-8 gap-x-[16px] gap-y-16')} id="divToPrint">
                {chunk?.length > 0 &&
                  chunk?.map((code: any, index: number) => {
                    const title = getTitle(code, type, field);
                    return (
                      <>
                        {code.isBlank ? (
                          <Box key={index} className={cx(`h-[56px] w-[56px]`)}></Box>
                        ) : (
                          <Box
                            key={index}
                            className={cx(
                              `flex h-[56px] w-[56px] flex-col items-center justify-center`,
                            )}>
                            <CodeContainer code={code} displayImage={'no'} size={22} />
                            <span
                              className={cx(
                                'max-w-[43px] overflow-hidden whitespace-nowrap font-[Inter] text-[7.5px]',
                              )}>
                              {title}
                            </span>
                          </Box>
                        )}
                        {index !== 0 && (index + 1) % 80 === 0 && (
                          <Box className={cx('page-break hidden')}></Box>
                        )}
                      </>
                    );
                  })}
              </Box>
            );
          })}
      </PdfViewer>
    </>
  );
};

export default ThreeInchRound;
