import { formatPhoneNumber } from './utils';

interface Props {
  phoneNumber?: string;
}

const PhoneNumberValueNode = (props: Props) => {
  const { phoneNumber } = props;

  if (!phoneNumber) {
    return null;
  }

  return (
    <span className="truncate" title={formatPhoneNumber(phoneNumber)}>
      {formatPhoneNumber(phoneNumber)}
    </span>
  );
};
export default PhoneNumberValueNode;
