import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddBinsInWarehouseMutationVariables = Types.Exact<{
  input: Types.AddBinsInWarehouseInput;
  query: Types.GetLastLocationNamesOfChildInput;
}>;


export type AddBinsInWarehouseMutation = { __typename?: 'Mutation', addBinsInWarehouse: { __typename?: 'WarehouseMutationPayload', success: boolean, reason?: string | null, locations?: Array<{ __typename?: 'LocationSchema', id: string, name: string, site: { __typename?: 'LocationSchema', id: string, name: string } }> | null, warehouse?: { __typename?: 'WarehouseSchema', id: string, lastLocationNamesOfChild: Array<string> } | null } };


export const AddBinsInWarehouseDocument = gql`
    mutation addBinsInWarehouse($input: AddBinsInWarehouseInput!, $query: GetLastLocationNamesOfChildInput!) {
  addBinsInWarehouse(input: $input) {
    success
    reason
    locations {
      id
      name
      site {
        id
        name
      }
    }
    warehouse {
      id
      lastLocationNamesOfChild(input: $query)
    }
  }
}
    `;

export function useAddBinsInWarehouseMutation() {
  return Urql.useMutation<AddBinsInWarehouseMutation, AddBinsInWarehouseMutationVariables>(AddBinsInWarehouseDocument);
};