import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Button } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Contact from 'app/i18n/Contact';
import SearchHelper from 'app/modules/components/SearchHelper';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';
import useDebounce from 'app/hooks/useDebounce';
import { useZipCodeLookupQuery } from 'app/modules/auth/views/SignUp/graphql/queries/generated/geoCode';

import { SnackbarService } from '../../../../components/Snackbar';
import { autoCompleteAddress } from '../../utils/autoCompleteAddress';
import { INVENTORY_FORM_RULES } from '../../utils/validation';
import { useCreateAddressMutation } from './graphql/mutations/generated/createAddress';
import { useUpdateAddressMutation } from './graphql/mutations/generated/updateAddress';
import { useAddressDetailsQuery } from './graphql/queries/generated/address';
import analytics from 'app/analytics';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  companyId: string;
  addressId: string;
  onSavedAddress: (id?: string) => void;
};

type addresses = {
  properties: {
    address_line1: string;
    address_line2: string;
    city: string;
    state: string;
    postcode: string;
  };
};

const AddressFormModal = (props: Props) => {
  const { isOpen, onCancel, companyId, onSavedAddress, addressId } = props;
  const NewAddress = {
    addressName: '',
    addressLineOne: '',
    addressLineTwo: '',
    zipCode: '',
    city: '',
    state: '',
  };
  const [{ fetching }, onCreateAddress] = useCreateAddressMutation();
  const [{ fetching: onUpdateFetching }, onUpdateAddress] = useUpdateAddressMutation();
  const [searchAddress, setSearchAddress] = useState('');
  const [searchAddressData, setSearchAddressData] = useState<addresses[]>([]);
  const [addressSuggestion, setAddressSuggestion] = useState<string[]>([]);
  const [selectedData, setSelectedData] = useState<addresses>();

  const [{ data }] = useAddressDetailsQuery({
    variables: { filters: { addressIds: [String(addressId)] } },
    pause: !Boolean(addressId),
  });

  const addressData = data?.addresses?.edges[0]?.node;
  const addressFormDefaultValues = useMemo(() => {
    return (
      addressData || {
        addressLineOne: '',
        addressLineTwo: '',
        addressName: '',
        city: '',
        companyId: '',
        country: '',
        state: '',
        zipCode: '',
      }
    );
  }, [addressData]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch
  } = useForm({
    mode: 'all',
    defaultValues: addressFormDefaultValues,
  });

  const zipcode = watch('zipCode');
  const validZipCode = useMemo(() => zipcode.trim().length >=5 ? zipcode : '', [zipcode])
  const zipCodeDebouncedValue = useDebounce(validZipCode.trim());

  const [{ data: zipCodeData, error }] = useZipCodeLookupQuery({
    variables: { filters: { zipcode: zipCodeDebouncedValue } },
    pause: !zipCodeDebouncedValue,
  });

  useEffect(() => {
    reset(addressFormDefaultValues);
  }, [addressFormDefaultValues]);

  useEffect(() => {
    if (error) {
      setValue('city', '');
      setValue('state', '');
    }
  }, [error, setValue]);

  useEffect(() => {
    setValue('city', zipCodeData?.geoCode.city || getValues('city'));
    setValue('state', zipCodeData?.geoCode.state || getValues('state'));
  }, [zipCodeData, getValues, setValue]);

  const onSubmit = useCallback(
    (input: any) => {
      if (addressId) {
        onUpdateAddress({ input: { ...input, companyId, addressId } }).then((response) => {
          if (response.data?.updateAddress.address?.id) {
            analytics?.track('Edited', { name: 'Address' });
            SnackbarService.show({
              message: Contact.SuccessMessages.AddressUpdated,
            });
            onSavedAddress();
          } else {
            console.error('[Update Category] Failed', response);
          }
        });
      } else {
        onCreateAddress({ input: { ...input, companyId } }).then((response) => {
          analytics?.track('Created', { name: 'Address' });
          const id = response.data?.createAddress.address?.id;
          if (id) {
            SnackbarService.show({
              message: Contact.SuccessMessages.AddressCreated,
            });
            onSavedAddress(id);
          } else {
            console.error('[Create Compnay] Failed', response);
          }
        });
      }
    },
    [onCreateAddress],
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onChangeAddress(searchAddress);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchAddress]);

  const onChangeAddress = async (value: string) => {
    if (value.length >= 3) {
      setAddressSuggestion([]);
      const data = await autoCompleteAddress(searchAddress);
      setSearchAddressData(data.features);
      data.features.slice(0, 5).map((address: addresses) => {
        setAddressSuggestion((value) => [
          ...value,
          address.properties.address_line1 + ', ' + address.properties.address_line2,
        ]);
      });
    }
  };

  useEffect(() => {
    reset({
      addressName: getValues('addressName') || '',
      addressLineOne: selectedData
        ? selectedData.properties.address_line1 + ', ' + selectedData.properties.address_line2
        : '',
      addressLineTwo: getValues('addressLineTwo') || '',
      zipCode: selectedData?.properties.postcode || '',
      city: selectedData?.properties.city,
      state: selectedData?.properties.state,
    });
  }, [selectedData]);

  const _isDisabled = useMemo(() => fetching || onUpdateFetching, [fetching, onUpdateFetching]);

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        if(fetching || onUpdateFetching) return;

        const partnerValues = {
          ...values,
          addressName: removeMultipleSpaces(values.addressName),
          addressLineOne: removeMultipleSpaces(values.addressLineOne),
          addressLineTwo: removeMultipleSpaces(values?.addressLineTwo || ''),
          city: removeMultipleSpaces(values.city),
          state: removeMultipleSpaces(values.state),
        };

        onSubmit(partnerValues);
      }),
    [onSubmit],
  );
  return (
    <Modal
      actions={
        <div>
          <Button loading={_isDisabled} disabled={_isDisabled} theme="success" onClick={onFormSubmit}>
            {addressId ? Common.Actions.Save : Common.Actions.Add}
          </Button>
          <Button classes="ml-[4px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="hoover-product-detail-modal"
      open={isOpen}
      title={addressId ? Contact.UpdateAddress : Contact.AddNewAddress}
      onClose={onCancel}>
      <div className="w-[100%] overflow-x-hidden md:w-[560px]">
        <form onFocus={() => setAddressSuggestion([])}>
          <Grid container paddingTop={1} spacing={1}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="addressName"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    autoFocus
                    isRequired
                    {...field}
                    error={fieldState.error}
                    label={Contact.FormLabels.AddressName}
                    placeholder={Contact.FormLabels.AddressName}
                    formLabel={Contact.FormLabels.AddressName}
                  />
                )}
                rules={INVENTORY_FORM_RULES.addressName}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="addressLineOne"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    error={fieldState.error}
                    label={Contact.FormLabels.AddressLine1}
                    placeholder={Contact.FormLabels.AddressLine1}
                    onChange={(value) => {
                      field.onChange(value);
                      !value && setAddressSuggestion([]);
                      setSearchAddress(value);
                    }}
                    formLabel={Contact.FormLabels.AddressLine1}
                  />
                )}
                rules={INVENTORY_FORM_RULES.addressLine1}
              />
            </Grid>
            <Grid item xs={12}>
              <SearchHelper
                data={addressSuggestion}
                searchValue={searchAddress}
                type="Address"
                onSearchData={(value: string, index: number) => {
                  setSelectedData(searchAddressData[index]);
                  setAddressSuggestion([]);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="addressLineTwo"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    error={fieldState.error}
                    label={Contact.FormLabels.AddressLine2}
                    placeholder={Contact.FormLabels.AddressLine2}
                    formLabel={Contact.FormLabels.AddressLine2}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="zipCode"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    error={fieldState.error}
                    label={Contact.FormLabels.Zipcode}
                    placeholder={Contact.FormLabels.Zipcode}
                    formLabel={Contact.FormLabels.Zipcode}
                  />
                )}
                rules={INVENTORY_FORM_RULES.zipCode}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    isRequired
                    {...field}
                    error={fieldState.error}
                    label={Contact.FormLabels.City}
                    placeholder={Contact.FormLabels.City}
                    formLabel={Contact.FormLabels.City}
                  />
                )}
                rules={INVENTORY_FORM_RULES.city}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    isRequired
                    {...field}
                    error={fieldState.error}
                    label={Contact.FormLabels.State}
                    placeholder={Contact.FormLabels.State}
                    formLabel={Contact.FormLabels.State}
                  />
                )}
                rules={INVENTORY_FORM_RULES.state}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default AddressFormModal;
