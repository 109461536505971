import LocationSelector from 'app/modules/locations/components/LocationSelector';

const ReceiveToLocation = (props: any) => {
  const { row, state } = props;
  const { entities, setEntities } = state.extraProps || {};

  const quantityValue = entities[row.id]?.quantityReceived;
  const siteIdValue = entities[row.id]?.siteId;
  const notes = entities[row.id]?.notes;

  const onHandleChange = (value: any) => {
    setEntities({
      ...entities,
      [row.id]: {
        receivableId: row.id,
        itemId: row.itemId || row.id,
        siteId: siteIdValue || '',
        quantityReceived: quantityValue || '',
        locationId: value,
        notes: notes || '',
      },
    });
  };

  return (
    <>
      <LocationSelector
        className="flex !h-full w-full min-w-[150px] max-w-[180px] items-center justify-center"
        controllerHeight="36px"
        disabled={!siteIdValue}
        inputControlPadding="0px 6px"
        popperProps={{
          style: {
            maxWidth: '250px',
          },
          placement: 'bottom-end',
        }}
        size="small"
        types={[]}
        value={siteIdValue}
        onChange={(value) => onHandleChange(value)}
      />
    </>
  );
};

export default ReceiveToLocation;
