import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';

import { SetPasswordInput } from '../types';

const useSetPassword = () => {
  const [status, error, data, onExecute] = useRest(`${API_URL}/auth/password/set`);

  const execute = useCallback(
    async (values: SetPasswordInput) => onExecute({ body: JSON.stringify(values) }),
    [onExecute],
  );
  return { fetching: status === FetchingStatus.Fetching, error, data, execute };
};

export default useSetPassword;
