import KitSummaryProvider from 'app/modules/components/KitSummary/context/KitSummaryProvider';
import { ItemTypeEnum } from 'app/types/schema';

import InventoryForm from '../InventoryForm';
import { UseInventoryFormReturnType } from '../InventoryForm/hook/useInventoryForm';
type Props = {
  inventoryId?: string;
  disabled: boolean;
  isEditMode?: boolean;
  state: UseInventoryFormReturnType['state'];
  loading?: boolean;
  onSubmit: (values: any, kitItems: any) => void;
  onCancel?: () => void;
  onDelete?: () => void;
};
const InventoryFormView = (props: Props) => {
  const { disabled, isEditMode, loading, state, onDelete, onCancel, onSubmit } = props;

  return (
    <KitSummaryProvider state={state} types={[ItemTypeEnum.Inventory]}>
      <InventoryForm
        disabled={disabled}
        isEditMode={isEditMode}
        loading={loading}
        state={state}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </KitSummaryProvider>
  );
};

export default InventoryFormView;
