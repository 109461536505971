import 'app/modules/orders/views/OrderRequest/components/OrderRequestForm/order-request.css';
import { useCallback, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout } from '@procurenetworks/procure-component-library';
import { FeatureFlagProvider } from 'app/libs/featureFlag';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import useCurrentUser from '../../auth/hooks/useCurrentUser';
import { getOrderLayoutTabs } from './utils';

type Props = React.PropsWithChildren<RouteComponentProps> & { disabled?: boolean };

function OrdersLayout(props: Props) {
  const { children, history, disabled } = props;
  const { workspacePermissions } = useCurrentUser();

  const onTabChange = useCallback(
    (tab: string) => {
      history.push(`/orders/${tab}`);
    },
    [history],
  );

  const tabs = useMemo(() => getOrderLayoutTabs(workspacePermissions), [workspacePermissions]);

  const isTabActive = useCallback(
    (tab: string) => {
      return history.location.pathname.includes(`/${tab}`);
    },
    [history.location.pathname],
  );

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}>
      <Nav
        tabs
        className="flex gap-[8px] rounded-tl-[12px] rounded-tr-[12px] bg-white p-[16px] md:p-[24px] xl:p-[24px]">
        {tabs.map(({ label, value }) => (
          <NavItem key={value}>
            <NavLink
              className={getTabNavLinkClassName(isTabActive(value), disabled)}
              disabled={disabled}
              onClick={() => onTabChange(value)}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <FeatureFlagProvider>{children}</FeatureFlagProvider>
    </Layout>
  );
}

export default withRouter(OrdersLayout);
