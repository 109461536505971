import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormItemLocationOption } from 'app/modules/locations/components/FormItemLocationSelect/types';
import { AssetItemSchema, InventoryItemSchema, ItemTypeEnum } from 'app/types/schema';
import useItemInStockData from '../useItemInStockData';

const DEFAULT_VALUES = {
  itemInStockId: '',
  sourceLocationId: '',
  quantity: '0',
};

interface Props {
  types: ItemTypeEnum[];
}

export type Option =
  | Pick<InventoryItemSchema, 'id' | 'sku' | 'title' | 'cost'>
  | Pick<AssetItemSchema, 'id' | 'sku' | 'title'| 'cost'>;

interface State {
  item?: Option | null;
  sourceLocation?: FormItemLocationOption | null;
}

const initialState = {
  item: null,
  sourceLocation: null,
};

const useKitSummaryForm = (props: Props) => {
  const [state, _setState] = useState<State>({
    ...initialState,
  });
  const { types } = props;

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const { control, watch, setValue, handleSubmit, reset, register, trigger } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
    },
  });

  const itemInStockId = watch('itemInStockId');
  const sourceLocationId = watch('sourceLocationId');
  const quantity = watch('quantity');

  const { itemInStock } = useItemInStockData(itemInStockId || '', types);

  useEffect(() => {
    setValue('sourceLocationId', '');
  }, [itemInStockId]);

  const availableQuantity = useMemo(() => {
    return state?.sourceLocation?.availableQuantity;
  }, [state?.sourceLocation]);

  const totalQuantity = useMemo(() => {
    return state?.sourceLocation?.totalQuantity;
  }, [state?.sourceLocation]);

  useEffect(() => {
    if (availableQuantity !== undefined) {
      register('quantity');
    }
  }, [availableQuantity]);

  const onResetForm = useCallback(() => {
    reset({
      ...DEFAULT_VALUES,
    });
    setState({
      ...initialState,
    });
  }, [reset, setState]);

  return {
    formState: {
      control,
      itemInStockId,
      sourceLocationId,
      availableQuantity,
      totalQuantity,
      itemId: itemInStockId,
      quantity,
      setState,
    },
    itemInStock,
    state,
    handleSubmit,
    onResetForm,
  };
};

export type UseKitSummaryFormReturnType = ReturnType<typeof useKitSummaryForm>;
export default useKitSummaryForm;
