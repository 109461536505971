import { FieldError } from 'react-hook-form';

type Props = { error?: Partial<FieldError> };

function FormError(props: Props) {
  const { error, ...rest } = props;

  if (!error) {
    return null;
  }

  return (
    <p className="mt-4 text-[14px] text-red-800" {...rest}>
      {error.message || 'Required'}
    </p>
  );
}

export default FormError;
