import { ORDER_ITEM_STATUS } from '../OrderHistory/utils/types';
import { OrderRequestEdge, OrderRequestItemSchema } from '../../../../types/schema';

export enum ORDER_VIEW {
  DEFAULT = 'DEFAULT',
  EDIT_ORDER_VIEW = 'EDIT_ORDER_VIEW',
  RETURN_ORDER_VIEW = 'RETURN_ORDER_VIEW',
}

export const ITEM_TYPE = {
  ASSETS: 'asset',
  INVENTORY: 'inventory',
  SPECIAL_ORDERS: 'no-sku',
};

export const ORDER_ITEM_VISIBLE_STATUS = {
  [ORDER_ITEM_STATUS.OPEN]: 'open',
  [ORDER_ITEM_STATUS.BACK_ORDERED]: 'back-ordered',
  [ORDER_ITEM_STATUS.PACKED]: 'packed',
  [ORDER_ITEM_STATUS.OUT_FOR_DELIVERY]: 'out-for-delivery',
  [ORDER_ITEM_STATUS.DELIVERED]: 'delivered',
  [ORDER_ITEM_STATUS.RETURNED]: 'returned',
  [ORDER_ITEM_STATUS.CANCELLED]: 'cancelled',
  [ORDER_ITEM_STATUS.CLOSED]: 'closed',
};

export const VALID_ITEM_STATUS_FOR_CURRENT_STATUS = {
  [ORDER_ITEM_STATUS.OPEN]: [
    ORDER_ITEM_STATUS.OPEN,
    ORDER_ITEM_STATUS.BACK_ORDERED,
    ORDER_ITEM_STATUS.CANCELLED,
    ORDER_ITEM_STATUS.CLOSED,
  ],
  [ORDER_ITEM_STATUS.BACK_ORDERED]: [
    ORDER_ITEM_STATUS.BACK_ORDERED,
    ORDER_ITEM_STATUS.CANCELLED,
    ORDER_ITEM_STATUS.CLOSED,
  ],
  [ORDER_ITEM_STATUS.CANCELLED]: [ORDER_ITEM_STATUS.CANCELLED, ORDER_ITEM_STATUS.CLOSED],
  [ORDER_ITEM_STATUS.PACKED]: [
    ORDER_ITEM_STATUS.PACKED,
    ORDER_ITEM_STATUS.DELIVERED,
    ORDER_ITEM_STATUS.RETURNED,
  ],
  [ORDER_ITEM_STATUS.OUT_FOR_DELIVERY]: [
    ORDER_ITEM_STATUS.OUT_FOR_DELIVERY,
    ORDER_ITEM_STATUS.DELIVERED,
    ORDER_ITEM_STATUS.RETURNED,
  ],
  [ORDER_ITEM_STATUS.DELIVERED]: [ORDER_ITEM_STATUS.DELIVERED, ORDER_ITEM_STATUS.CLOSED],
  [ORDER_ITEM_STATUS.CLOSED]: [ORDER_ITEM_STATUS.CLOSED],
  [ORDER_ITEM_STATUS.RETURNED]: [],
};

export type OrderDetailsData = OrderRequestEdge['node'];
