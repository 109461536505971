import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { AssetModelItemSchema } from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

import { useAssetModelTableQuery } from '../../views/AssetModels/graphql/query/generated/assetModelTable';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useAssetModelTableQuery({
    variables: {
      filters: {
        search: searchedValue,
      },
    },
  });

  useEffect(() => {
    const result = data?.assetModelItems?.edges?.map((a) => {
      const item = a.node as AssetModelItemSchema

      const {result, searchBy} = processSearch(["title","sku","description", "mName", "modelNumber"], searchedValue, item)

      return {
        label: searchBy,
        id: item.id,
        value: result
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue.trim());
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
