import React, { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InputAdornment, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import moment from 'moment';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import Inventory from 'app/i18n/Inventory';
import { ASSET_FORM_RULES } from 'app/modules/assets/components/AssetForm/utils';
import { UseSearchAssetsStateReturnType } from 'app/modules/assets/views/searchAssets/hook/useSearchAssetsState';
import AIFormCategorySelect from 'app/modules/assetsInventory/components/FormCategorySelect';
import FormManufactureSelect from 'app/modules/assetsInventory/components/FormManufacturerSelect';
import FormVendorSelect from 'app/modules/assetsInventory/components/FormVendorSelect';
import ItemLink from 'app/modules/assetsInventory/components/ItemLink';
import FormCategorySelect from 'app/modules/categories/components/FormCategorySelect';
import { isCategoryActive } from 'app/modules/categories/utils/utils';
import QuantityInput from 'app/modules/components/QuantityInput';
import FormCompanySelect from 'app/modules/contacts/components/FormCompanySelect';
import { VendorLink } from 'app/modules/contacts/components/VendorLink';
import { CategoryFilterLabelNodeProps } from 'app/modules/inventory/components/CategoryFilterLabelNode/types';
import {
  getMaximumQuantityFromStockInformationBySiteId,
  getMinimumQuantityFromStockInformationBySiteId,
} from 'app/modules/inventory/components/QuantityBySite/utils/stockInormation';
import { VendorFilterLabelNodeProps } from 'app/modules/inventory/components/VendorFilterLabelNode';
import ManufactureTableNode from 'app/modules/manufacturers/components/ManufactureTableNode';
import { ItemTypeEnum } from 'app/types/schema';
import { getCostWithCurrency } from 'app/utils/cost';
import SyncStateWithUrlParam from 'app/utils/queryParams';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInputBase-root': {
      fontWeight: '600 !important',
    },
    '& input::placeholder': {
      fontWeight: '600',
      opacity: 1,
    },
  },
}));

export const EditInputNode = memo(
  (props: any): any => {
    const { row, state, headCell } = props;
    const { extraProps } = state;
    const { isEditable } = extraProps;

    const isEditableNode = useMemo(() => {
      let editable = row.editable && isEditable;

      if (
        ['mName', 'modelNumber', 'model', 'brand'].includes(headCell.value) &&
        (row.type === ItemTypeEnum.AssetKit || row.type === ItemTypeEnum.InventoryKit)
      )
        editable = false;

      return editable;
    }, [row.editable, row.type, isEditable, headCell.value]);

    if (isEditableNode) {
      return (
        <FormTextFieldValueNode
          defaultValue={row?.editableFields[headCell.value]}
          extraProps={extraProps}
          keyName={headCell.value}
          row={row}
        />
      );
    }
    return <span>{row?.[headCell.value]}</span>;
  },
  (pp, np) => {
    const {
      row,
      state: { extraProps },
      headCell,
    } = pp;
    const {
      row: nRow,
      state: { extraProps: nExtraProps },
      headCell: nHeadCell,
    } = np;

    return isEqual(
      {
        editable: row.editable,
        type: row.type,
        value: headCell.value,
        defaultValue: row.editableFields?.[nHeadCell.type],
        isEditable: extraProps.isEditable,
        control: extraProps.control,
      },
      {
        editable: nRow.editable,
        type: nRow.type,
        value: nHeadCell.type,
        defaultValue: nRow.editableFields?.[nHeadCell.type],
        isEditable: nExtraProps.isEditable,
        control: nExtraProps.control,
      },
    );
  },
);
export interface FormTextFieldValueNodeProps {
  row: any;
  focusId?: string;
  focusIndex?: number;
  setFocusId?: React.Dispatch<React.SetStateAction<string>>;
  setFocusIndex?: React.Dispatch<React.SetStateAction<number>>;
  extraProps: any;
  keyName: string;
  defaultValue: string;
}

export const FormTextFieldValueNode: React.FC<FormTextFieldValueNodeProps> = memo(
  (props: FormTextFieldValueNodeProps) => {
    const { row, extraProps, keyName, defaultValue } = props;
    const value = useWatch({ control: extraProps.control, name: `${row.id}_${keyName}` });
    const isTitle = keyName === 'title';

    useEffect(() => {
      extraProps.control.register(`${row.id}_${keyName}`, { defaultValue: defaultValue });

      return () => {
        extraProps.control.unregister(`${row.id}_${keyName}`);
      };
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = e.target.value;

      if (extraProps?.setEditableSiteState) {
        extraProps.setEditableSiteState(row.id, { [keyName]: newValue });
      }
    };

    const fieldState = extraProps.getFieldState(`${row.id}_${keyName}`);
    let rules = {};
    if (isTitle) rules = ASSET_FORM_RULES.title;
    else if (keyName == 'mName') rules = ASSET_FORM_RULES.mName;

    return (
      <div className="text-field-container">
        <Controller
          control={extraProps.control}
          name={`${row.id}_${keyName}`}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                InputProps={{
                  endAdornment:
                    isTitle || fieldState?.error?.message ? (
                      <InputAdornment position="end">
                        {isTitle && !fieldState?.error?.message && (
                          <span id={'length-check-' + row.id} style={{ fontSize: '11px' }}>
                            {value?.length || defaultValue?.length}/{32}
                          </span>
                        )}
                        {fieldState?.error?.message && (
                          <Tooltip title={fieldState?.error?.message}>
                            <ErrorOutlineIcon color="error" fontSize="small" />
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ) : null,
                }}
                className="mt-0 w-full"
                color={fieldState.invalid[`${row.id}_${keyName}`] ? 'error' : 'info'}
                defaultValue={defaultValue}
                error={fieldState.invalid}
                inputProps={isTitle ? { maxLength: 32 } : {}}
                size="small"
                onBlur={(e) => {
                  handleChange(e);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
              />
            );
          }}
          rules={rules}
        />
      </div>
    );
  },
);

export const EditCategoryValueNode = memo(
  (props: any) => {
    const { row, state } = props;
    const { extraProps } = state;
    const fieldState = extraProps.getFieldState(`${row.id}_category`);

    useEffect(() => {
      extraProps.control.register(`${row.id}_category`, {
        defaultValue: row?.editableFields?.categoryId,
      });

      return () => {
        extraProps.control.unregister(`${row.id}_category`);
      };
    }, []);

    if (!(row.editable && extraProps.isEditable)) {
      return (
        <div>
          {isCategoryActive(row?.category?.status) && row.category?.name ? row.category?.name : ''}
        </div>
      );
    }
    return (
      <div className="w-full">
        <Controller
          control={extraProps.control}
          name={`${row.id}_category`}
          render={({ field }) => {
            return (
              <AIFormCategorySelect
                {...field}
                error={fieldState.error}
                placeholder={Inventory.FormPlaceholders.Category}
                selectInputClass="flex"
                value={{ id: row?.editableFields?.categoryId, name: row?.category?.name }}
                onChange={(value) => {
                  if (extraProps?.setEditableSiteState) {
                    extraProps.setEditableSiteState(row.id, {
                      categoryId: value,
                    });
                    field.onChange({ target: { name: `${row.id}_category`, value: value } });
                  }
                }}
              />
            );
          }}
          rules={ASSET_FORM_RULES.categoryId}
        />
      </div>
    );
  },
  (pp, np) => {
    //remove category destructuring
    const {
      row: { category, editable, editableFields: categoryId },
      state: { extraProps },
    } = pp;
    const {
      row: { category: nCategory, editable: nEditable, editableFields: nCategoryId },
      state: { extraProps: nExtraProps },
    } = np;

    return isEqual(
      {
        categoryId,
        editable,
        isEditable: extraProps.isEditable,
        control: extraProps.control,
        name: category?.name,
      },
      {
        nCategoryId,
        nEditable,
        isEditable: nExtraProps.isEditable,
        control: nExtraProps.control,
        nName: nCategory?.nName,
      },
    );
  },
);

export const CategoryFilterLabelNode = (props: CategoryFilterLabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filters } = extraProps as {
    filters: UseSearchAssetsStateReturnType['filters'];
  };

  SyncStateWithUrlParam('categoryId', filters.state, filters.setState)

  const classes = useStyle();
  return (
    <div className="table-filter">
      <FormCategorySelect
        className={clsx(classes.root)}
        placeholder={Inventory.FormPlaceholders.Category}
        selectInputClass="flex"
        size="small"
        value={filters.state.categoryId}
        onChange={(value) => {
          filters.setState({
            categoryId: value,
          });
        }}
      />
    </div>
  );
};

export const VendorValueNode = memo(
  (props: any) => {
    const { row, state } = props;
    const { extraProps } = state;

    const isTypeKit = row.type === ItemTypeEnum.AssetKit || row.type === ItemTypeEnum.InventoryKit;
    if (!(row.editable && extraProps.isEditable && !isTypeKit)) {
      return <VendorLink vendor={row.vendor} />;
    }
    return (
      <div className="w-full">
        <Controller
          control={extraProps.control}
          name={`${row.id}_vendor`}
          render={({ field }) => (
            <FormVendorSelect
              {...field}
              menuPortalTarget={document.body}
              name="vendor-select"
              placeholder={Inventory.FormPlaceholders.Company}
              selectInputClass="flex"
              value={{ id: row?.editableFields?.vendorId, companyName: row?.vendor?.companyName }}
              onChange={(value: any) => {
                if (extraProps?.setEditableSiteState) {
                  extraProps.setEditableSiteState(row.id, {
                    vendorId: value,
                  });
                  field.onChange({ target: { name: 'vendorId', value: 'value' } });
                }
              }}
            />
          )}
          rules={{ required: true }}
        />
      </div>
    );
  },
  (pp, np) => {
    const {
      row: { type, vendor, editable, editableFields: vendorId },
      state: { extraProps },
    } = pp;
    const {
      row: { type: nType, vendor: nVendor, editable: nEditable, editableFields: nVendorId },
      state: { extraProps: nExtraProps },
    } = np;

    return isEqual(
      [vendorId, editable, extraProps.isEditable, extraProps.control, type, vendor],
      [nVendorId, nEditable, nExtraProps.isEditable, nExtraProps.control, nType, nVendor],
    );
  },
);

export const VendorFilterLabelNode = memo((props: VendorFilterLabelNodeProps) => {
  const { state } = props;
  const classes = useStyle();
  const { extraProps } = state;
  const { filters } = extraProps as {
    filters: UseSearchAssetsStateReturnType['filters'];
  };

  SyncStateWithUrlParam('companyId', filters.state, filters.setState)

  return (
    <div className="table-filter">
      <FormCompanySelect
        className={clsx(classes.root)}
        menuPortalTarget={document.body}
        name="vendor-select"
        placeholder={Inventory.FormPlaceholders.Company}
        selectInputClass=""
        value={filters.state.companyId}
        onChange={(value) => {
          filters.setState({
            companyId: value,
          });
        }}
        size="small"
      />
    </div>
  );
});

export const ManufacturerValueNode = memo(
  (props: any) => {
    const { row, state } = props;
    const { extraProps } = state;

    return (
      <div className="w-full">
        <FormManufactureSelect
          className="mt-0 flex"
          placeholder={Inventory.FormPlaceholders.ManufacturerSelect}
          value={{ id: row?.editableFields?.manufacturerId, name: row.manufacturer?.name }}
          onChange={(value) => {
            if (extraProps?.setEditableSiteState) {
              extraProps.setEditableSiteState(row.id, {
                manufacturerId: value,
              });
            }
          }}
        />
      </div>
    );
  },
  (pp, np) => {
    const {
      row: { editableFields: manufacturerId },
    } = pp;
    const {
      row: { editableFields: nManufacturerId },
    } = np;

    return isEqual(manufacturerId, nManufacturerId);
  },
);

export const FormCheckBoxInputNode = memo(
  ({ row, state }: any) => {
    const { extraProps } = state;

    return (
      <FormCheckBoxInput
        medium
        classNames={`${row.editable ? 'cursor-pointer' : 'cursor-default'}`}
        disabled={!row.editable || !extraProps.isEditable}
        value={
          row.editable && extraProps.isEditable
            ? row.editableFields?.pickableThroughOrderRequest
            : row.pickableThroughOrderRequest
        }
        onChange={(value) => {
          if (extraProps?.setEditableSiteState) {
            extraProps.setEditableSiteState(row.id, {
              pickableThroughOrderRequest: value,
            });
          }
        }}
      />
    );
  },
  (pp, np) => {
    const {
      row,
      state: { extraProps },
    } = pp;
    const {
      row: nRow,
      state: { extraProps: nExtraProps },
    } = np;

    return isEqual(
      {
        editable: row.editable,
        pickableThroughOrderRequest: row.pickableThroughOrderRequest,
        ePickableThroughOrderRequest: row.editableFields?.pickableThroughOrderRequest,
        isEditable: extraProps.isEditable,
      },
      {
        editable: nRow.editable,
        pickableThroughOrderRequest: nRow.pickableThroughOrderRequest,
        ePickableThroughOrderRequest: nRow.editableFields?.pickableThroughOrderRequest,
        isEditable: nExtraProps.isEditable,
      },
    );
  },
);

export const QuantityInputNode = memo(
  ({ row, state, headCell }: any) => {
    const { extraProps } = state;
    const { value } = headCell;
    const [localValue, setLocalValue] = useState(row?.editableFields?.[value]);

    if (extraProps.siteId) {
      const boundaryValue =
        value === 'minimumQuantity'
          ? getMinimumQuantityFromStockInformationBySiteId(
            row.stockInformation || [],
            extraProps.siteId,
          )
          : getMaximumQuantityFromStockInformationBySiteId(
            row.stockInformation || [],
            extraProps.siteId,
          );

      if (row.editable && extraProps.isEditable) {
        return (
          <QuantityInput
            key={`maximum-quantity-input${row.id}`}
            className="mt-0 flex"
            maximumLength={9}
            size="small"
            value={localValue || boundaryValue}
            onBlur={() => {
              if (extraProps?.setEditableSiteState) {
                extraProps.setEditableSiteState(row.id, {
                  [value]: localValue || boundaryValue,
                });
              }
            }}
            onChange={(qValue) => setLocalValue(qValue)}
          />
        );
      }
      return <span>{boundaryValue}</span>;
    }
    return <span>{row[value]}</span>;
  },
  (pp, np) => {
    const {
      row,
      state: { extraProps },
      headCell,
    } = pp;
    const {
      row: nRow,
      state: { extraProps: nExtraProps },
      headCell: nHeadCell,
    } = np;

    return isEqual(
      {
        editable: row.editable,
        // value: row.editableFields?.[headCell.value],
        isEditable: extraProps.isEditable,
        setEditableSiteState: extraProps.setEditableSiteState,
        siteId: extraProps.siteId,
      },
      {
        editable: nRow.editable,
        // value: nRow.editableFields?.[nHeadCell.value],
        isEditable: nExtraProps.isEditable,
        setEditableSiteState: nExtraProps.setEditableSiteState,
        siteId: nExtraProps.siteId,
      },
    );
  },
);

export const ManufactureInputNode = memo(
  ({ row, state }: any) => {
    const isTypeKit = row.type === ItemTypeEnum.AssetKit || row.type === ItemTypeEnum.InventoryKit;
    if (row.editable && state.extraProps.isEditable && !isTypeKit) {
      return <ManufacturerValueNode row={row} state={state} />;
    }
    return <ManufactureTableNode manufacturer={row.manufacturer} />;
  },
  (pp, np) => {
    const {
      row,
      state: { extraProps },
    } = pp;
    const {
      row: nRow,
      state: { extraProps: nExtraProps },
    } = np;

    return isEqual(
      {
        editable: row.editable,
        type: row.type,
        pickableThroughOrderRequest: row.manufacturer,
        isEditable: extraProps.isEditable,
      },
      {
        editable: nRow.editable,
        editabletype: nRow.type,
        pickableThroughOrderRequest: nRow.manufacturer,
        isEditable: nExtraProps.isEditable,
      },
    );
  },
);

export const SKUItem = memo(
  ({ row }: any) => {
    return (
      <ItemLink id={row.id} type={row.type} target={row?.target}>
        {row?.sku}
      </ItemLink>
    );
  },
  (pp: any, np: any) => {
    const {
      row: { id, type, sku },
    } = pp;
    const {
      row: { id: nId, type: nType, sku: nSKU },
    } = np;

    return isEqual({ id, type, sku }, { id: nId, type, nType, sku: nSKU });
  },
);

export const Cost = memo(
  ({ row }: any) => {
    return (
      <span>{row?.cost !== undefined ? `${getCostWithCurrency(Number(row?.cost))}` : ''}</span>
    );
  },
  (pp: any, np: any) => {
    const { row } = pp;
    const { row: nRow } = np;

    return isEqual(row.cost, nRow.cost);
  },
);

export const WarrentyExpiryDate = memo(
  ({ row }: any) => {
    if (row.warrantyExpiryDate) {
      let background = '';
      const today = moment();
      const warrantyDate = moment(row.warrantyExpiryDate);
      const days = warrantyDate.diff(today, 'days') + 1;
      if (warrantyDate.isAfter(today)) {
        if (days < 60) {
          background = 'yellow';
        } else {
          background = 'green';
        }
      } else {
        background = 'red';
      }
      return (
        <div
          className={`h-[30px] w-[30px] rounded-full`}
          style={{
            background: background,
          }}></div>
      );
    } else {
      return <span></span>;
    }
  },
  (pp: any, np: any) => {
    const { row } = pp;
    const { row: nRow } = np;

    return isEqual(row.warrantyExpiryDate, nRow.warrantyExpiryDate);
  },
);
