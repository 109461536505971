import { AllowedEntityPermissions, EntityPermissionsMap } from 'app/types/schema';

const getGroupSelectedPermissions = (
  permissionGroup: EntityPermissionsMap,
  selectedPermissions?: string[] | null,
) => {
  if (!selectedPermissions) {
    return [];
  }

  return permissionGroup?.entityPermissions.reduce(
    (result: AllowedEntityPermissions[], permission: AllowedEntityPermissions) => {
      if (selectedPermissions.includes(permission.key)) {
        result.push(permission);
      }
      return result;
    },
    [] as AllowedEntityPermissions[],
  );
};

export default getGroupSelectedPermissions;
