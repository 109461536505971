import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';

const AssetModelBrandField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="brand"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            error={fieldState.error}
            isDisabled={disabled}
            label={AssetModel.FormLabels.Brand}
          />
        )}
      />
    </div>
  );
};

export default AssetModelBrandField;
