import { useEffect, useState } from 'react';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { RoleSchema } from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

import { getAllowedRoleTypes } from '../../utils';
import { useRolesTableQuery } from '../../views/Roles/graphql/queries/generated/rolesTable';

const useSearchSuggestion = () => {
  const currentUser = useCurrentUser();
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useRolesTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        types: getAllowedRoleTypes(currentUser),
      },
    },
  });

  useEffect(() => {
    const result = data?.roles?.edges?.map((a) => {
      const item = a.node as RoleSchema;

      const { result, searchBy } = processSearch(['name', 'description'], searchedValue, item);

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue.trim());
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
