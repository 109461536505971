import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import AssetAuditReportDetails from 'app/modules/reports/views/AuditReport/views/AssetAuditReportDetails';

const AssetAuditReportDetailsPage = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;

  return <AssetAuditReportDetails id={match.params.id} />;
};

export default withRouter(AssetAuditReportDetailsPage);
