import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useUndeleteLayout from 'app/modules/undelete/views/undelete/hook/useUndeleteLayout';
import UndeleteInventory from 'app/modules/undelete/views/undeleteInventory';

function UndeleteInventoryPage(props: RouteComponentProps) {
  const { history } = props;
  const { tabs } = useUndeleteLayout(history);

  return (
    <AdminLayout customTabs={tabs}>
      <UndeleteInventory />
    </AdminLayout>
  );
}

export default withRouter(UndeleteInventoryPage);
