import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ContactDetailsFragmentDoc } from '../../fragments/generated/contact';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactDetailsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ContactFilters>;
}>;


export type ContactDetailsQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactConnection', totalCount: number, edges: Array<{ __typename?: 'ContactEdge', node: { __typename?: 'ContactSchema', id: string, companyId: string, firstName: string, lastName: string, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null, address: { __typename?: 'AddressSchema', id: string } } }> } };


export const ContactDetailsDocument = gql`
    query contactDetails($filters: ContactFilters) {
  contacts(filters: $filters) {
    totalCount
    edges {
      node {
        ...ContactDetails
        id
      }
    }
  }
}
    ${ContactDetailsFragmentDoc}`;

export function useContactDetailsQuery(options?: Omit<Urql.UseQueryArgs<ContactDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactDetailsQuery>({ query: ContactDetailsDocument, ...options });
};