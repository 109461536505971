import { Button } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import { useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import routes from 'app/consts/routes';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { isExternalUserHavePartnerTenants } from '../../views/OrderRequest/utils/externalTenant';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestTypeEnum,
} from '../../../../types/schema';
import { useAccessControl } from 'app/components/AccessControl';

interface Props {
  classes?: string;
  orderDetail: OrderDetailsData;
}
const RequestAgainButton = (prop: Props & RouteComponentProps) => {
  const { classes, orderDetail, history } = prop;

  const { isParentTenantOrderView } = useContext(OrderDetailContext);
  const user = useCurrentUser();
  const isExternalUser = useMemo(() => isExternalUserHavePartnerTenants(user), [user]);

  const canAccessOrderRequest = useAccessControl(
    user.workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.OrderRequest,
  );

  const isExternalOrder = useMemo(
    () => orderDetail.type === OrderRequestTypeEnum.External,
    [orderDetail.type],
  );

  const onRequestAgain = useCallback(() => {
    if (orderDetail.id) {
      history.push(routes.OrderRequestAgain(orderDetail.id));
    }
  }, [orderDetail.id, history]);

  if (isParentTenantOrderView || (isExternalOrder && !isExternalUser) || !canAccessOrderRequest) {
    return null;
  }

  return (
    <div className={classes} onClick={onRequestAgain}>
      <Button theme="success" classes="min-w-[140px] h-[44px]">
        {Orders.Actions.RequestAgain}
      </Button>
    </div>
  );
};

export default withRouter(RequestAgainButton);
