import { useMemo } from 'react';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { Maybe } from 'graphql/jsutils/Maybe';
import FormSelect from 'app/components/Form/FormSelect';
import Label from 'app/i18n/Label';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import Box from 'app/ui-components/Box';

import { LocationTypeEnum } from '../../../../../../../types/schema';
import useLabelsFrom from '../../../context/useLabelsFrom';
import { LabelCustomEnum, LabelsFormProps, LabelStockEnum, LabelTypeEnum } from '../types';
import {
  getArrowDisplayOptions,
  getInventoryLabelFormatOptions,
  getLabelCustomOptions,
  getLabelOffsetOptions,
  getLabelStockOptions,
  getLabelTypeOptions,
} from '../utils';
import { getValidationRules } from '../utils/validation';

function isLabelTypeIsAssetInventoryType(type?: string) {
  if (!type) {
    return false;
  }

  return [
    LabelTypeEnum.Inventory,
    LabelTypeEnum.InventoryKit,
    LabelTypeEnum.Asset,
    LabelTypeEnum.AssetKit,
  ].includes(type as LabelTypeEnum);
}

const LabelsForm = (props: LabelsFormProps) => {
  const { control, values, clearErrors, setValue, watch, getValues, setIsCustomFieldValid } =
    useLabelsFrom();

  const isValid = useMemo(() => getValidationRules(values?.type), [values?.type]);

  const isAssetInventoryType = useMemo(
    () => isLabelTypeIsAssetInventoryType(values?.type),
    [values?.type],
  );

  const onTypeChange = (
    field: ControllerRenderProps<FieldValues, 'type'>,
    value: Maybe<string> | undefined,
  ) => {
    field.onChange(value);
    if (clearErrors) clearErrors();
    if (setValue) {
      if (
        [LabelTypeEnum.ShippingSetUp, LabelTypeEnum.WarehouseSetup].includes(value as LabelTypeEnum)
      ) {
        const option = getLabelStockOptions(value, values?.labelFormat);
        setValue('labelStock', option[0] || option);
      } else {
        setValue('labelStock', '');
      }
      if (value === LabelTypeEnum.WarehouseSetup) {
        setValue('arrowDisplay', 'up');
      } else {
        setValue('arrowDisplay', 'no');
      }
      setValue('firstField', '');
      setValue('secondField', '');
      setValue('thirdField', '');
      setValue('siteId', '');
      setValue('labelFormat', '');
      setValue('offset', '');
      setValue('location', '');
      setValue('destinationSite', '');
      setValue('containerType', '');
      setValue('labelsCount', '');
      setValue('custom', '');
      setIsCustomFieldValid && setIsCustomFieldValid(false);
    }
  };
  const onLabelFormatChange = (
    field: ControllerRenderProps<FieldValues, 'labelFormat'>,
    value: Maybe<string> | undefined,
  ) => {
    field.onChange(value);
    if (clearErrors) clearErrors();
    if (setValue) {
      if (isLabelTypeIsAssetInventoryType(value as LabelTypeEnum)) {
        const option = getLabelStockOptions(value, values?.labelFormat);
        setValue('labelStock', option);
      } else {
        setValue('labelStock', '');
      }
      setValue('offset', '');
      setIsCustomFieldValid && setIsCustomFieldValid(false);
    }
  };

  const onLabelCustomChange = (
    field: ControllerRenderProps<FieldValues, 'custom'>,
    type: Maybe<string> | undefined,
    custom: Maybe<string> | undefined,
  ) => {
    field.onChange(custom);
    if (clearErrors) clearErrors();
    if (setValue) {
      if ([LabelCustomEnum.Default].includes(custom as LabelCustomEnum)) {
        setValue('firstField', '');
        setValue('secondField', '');
        setValue('thirdField', '');
        setIsCustomFieldValid && setIsCustomFieldValid(false);
      }
    }
  };
  const onLabelStockChange = (
    field: ControllerRenderProps<FieldValues, 'labelStock'>,
    value: Maybe<string> | undefined,
  ) => {
    field.onChange(value);
    if (clearErrors) clearErrors();
    if (setValue) {
      setValue('firstField', '');
      setValue('secondField', '');
      setValue('thirdField', '');
      setValue('custom', '');
      setValue('offset', '');
      setIsCustomFieldValid && setIsCustomFieldValid(false);
    }
  };

  const siteIdTypesFilters = useMemo(() => {
    return props?.formType === LabelTypeEnum.Shipping
      ? [LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]
      : undefined;
  }, [props?.formType]);

  return (
    <form>
      <Box className="!mt-[24px] w-full space-y-24 py-16 sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
        <>
          <Controller
            control={control}
            name="type"
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                autoFocus
                isRequired
                error={fieldState.error}
                label={Label.Form.LabelType}
                options={getLabelTypeOptions()}
                onChange={(value) => onTypeChange(field, value)}
              />
            )}
            rules={isValid?.LabelType}
          />
          {values && isAssetInventoryType ? (
            <Controller
              control={control}
              name="labelFormat"
              render={({ field, fieldState }) => {
                return (
                  <FormSelect
                    {...field}
                    error={fieldState.error}
                    isRequired={
                      ![
                        LabelTypeEnum.Location,
                        LabelTypeEnum.ShippingSetUp,
                        LabelTypeEnum.WarehouseSetup,
                      ].includes(values.type as LabelTypeEnum)
                    }
                    label={Label.Form.LabelFormat}
                    options={getInventoryLabelFormatOptions(values.type)}
                    onChange={(value) => onLabelFormatChange(field, value)}
                  />
                );
              }}
              rules={isValid?.LabelFormat}
            />
          ) : null}

          {values &&
            !['', LabelTypeEnum.ShippingSetUp, LabelTypeEnum.WarehouseSetup].includes(
              values.type,
            ) && (
              <Controller
                control={control}
                name="siteId"
                render={({ field, fieldState }) => (
                  <FormSiteSelect
                    {...field}
                    className="flex-1"
                    error={fieldState.error}
                    isRequired={values.type === LabelTypeEnum.Location && true}
                    label={Label.Form.Site}
                    types={siteIdTypesFilters}
                    value={getValues && getValues('siteId')}
                    selectClassName="mt-[6px]"
                  />
                )}
                rules={isValid?.Site}
              />
            )}
          {isAssetInventoryType ? (
            <Controller
              control={control}
              name="location"
              render={({ field, fieldState }) => (
                <FormSiteSelect
                  {...field}
                  disabled={getValues && !getValues('siteId')}
                  error={fieldState.error}
                  label="Location"
                  type={LabelTypeEnum.Location}
                  value={getValues && getValues('siteId')}
                />
              )}
            />
          ) : null}
          <Controller
            control={control}
            name="labelStock"
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                disableClearable={true}
                error={fieldState.error}
                isRequired={values && ![LabelTypeEnum.ShippingSetUp, ''].includes(values.type)}
                label={Label.Form.LabelStock}
                options={getLabelStockOptions(values?.type as string, values?.labelFormat || '')}
                onChange={(value) => onLabelStockChange(field, value)}
              />
            )}
            rules={isValid?.LabelStock}
          />
          {values &&
            values.type !== LabelTypeEnum.Shipping &&
            values.type !== LabelTypeEnum.ShippingSetUp && (
              <Controller
                control={control}
                name="arrowDisplay"
                render={({ field, fieldState }) => (
                  <FormSelect
                    {...field}
                    error={fieldState.error}
                    label={Label.Form.ArrowDisplay}
                    options={getArrowDisplayOptions()}
                  />
                )}
              />
            )}
          {values &&
            values.type !== LabelTypeEnum.ShippingSetUp &&
            values.type !== '' &&
            values.labelStock !== '' &&
            props.isCustomLabelFieldEnable && (
              <Controller
                control={control}
                name="custom"
                render={({ field, fieldState }) => (
                  <FormSelect
                    isRequired={[LabelCustomEnum.Custom].includes(values.custom as LabelCustomEnum)}
                    {...field}
                    error={fieldState.error}
                    label={Label.Form.LabelCustom}
                    options={getLabelCustomOptions()}
                    onChange={(custom) => onLabelCustomChange(field, values?.type, custom)}
                  />
                )}
              />
            )}
        </>
      </Box>
    </form>
  );
};

export default LabelsForm;
