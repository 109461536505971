import { useContext } from 'react';

import { KitSummaryContext, KitSummaryContextType } from './KitSummaryProvider';

const useKitSummary = (): KitSummaryContextType => {
  const context = useContext(KitSummaryContext) as unknown as KitSummaryContextType;

  return {
    ...context,
  };
};

export default useKitSummary;
