import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteOrderRequestMutationVariables = Types.Exact<{
  input: Types.DeleteOrderRequestInput;
}>;


export type DeleteOrderRequestMutation = { __typename?: 'Mutation', deleteOrderRequest: { __typename?: 'MutationResponse', success: boolean } };


export const DeleteOrderRequestDocument = gql`
    mutation deleteOrderRequest($input: DeleteOrderRequestInput!) {
  deleteOrderRequest(input: $input) {
    success
  }
}
    `;

export function useDeleteOrderRequestMutation() {
  return Urql.useMutation<DeleteOrderRequestMutation, DeleteOrderRequestMutationVariables>(DeleteOrderRequestDocument);
};