import { useCallback, useMemo, useRef, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, Button, Divider, Stack, Switch } from '@mui/material';
import moment from 'moment';
import Loader from 'app/components/Loader';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { useUpdateAllBellNotificationsStatusOfUserMutation } from 'app/modules/bellNotifications/graphql/queries/generated/updateAllBellNotificationsStatusOfUser';
import { useUpdateBellNotificationsStatusMutation } from 'app/modules/bellNotifications/graphql/queries/generated/updateBellNotificationsStatus';
import { BellNotificationSchema, BellNotificationStatusEnum } from 'app/types/schema';
import ClearIcon from '@mui/icons-material/Clear';
import useOutSideClick from '../../../../../hooks/useOutSideClick/useOutSideClick';
import { useNotifications } from '../../hook/useNotifications';
import NotificationItem from './bellNotificationItem';
import styled from '@emotion/styled';
import { CustomIcons } from '@procurenetworks/procure-component-library';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  display: 'flex',
  borderRadius: '20px',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#6C3',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 20,
    height: 20,
    borderRadius: 100,
    // transition: theme.transitions.create(['width'], {
    //   duration: 200,
    // }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#EAECF0',
    boxSizing: 'border-box',
  },
}));

const NotificationsMenu = () => {
  const user = useCurrentUser();

  const {
    fetching,
    notificationList,
    unreadNotificationCount,
    onlyUnreadNotifications,
    resetSettings,
    onToggleOnlyUnreadNotifications,
    hasNextPage,
    goToNextPage,
  } = useNotifications({ userId: user.id });

  // Toggle Menu On Bel Notification Click.
  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const toggleMenu = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      event?.preventDefault();
      setOpen(!open);
    },
    [setOpen, open],
  );

  const onOutSideClick = useCallback(() => {
    if (open) {
      toggleMenu();
    }
  }, [open, toggleMenu]);
  useOutSideClick([ref], onOutSideClick);

  // Daywise categorization of notifications.
  const daywiseNotifications = useMemo(() => {
    let daywiseNotifications: any = {};
    const currentTime = moment();
    for (let i = 0; i < notificationList.length; i++) {
      let duration = moment.duration(currentTime.diff(moment(notificationList[i].node.createdAt)));
      if (duration.asHours() < 22) {
        if (!daywiseNotifications['today']) daywiseNotifications['today'] = [];
        daywiseNotifications['today'].push(notificationList[i].node);
      } else if (duration.asHours() < 36) {
        if (!daywiseNotifications['yesterday']) daywiseNotifications['yesterday'] = [];
        daywiseNotifications['yesterday'].push(notificationList[i].node);
      } else {
        if (!daywiseNotifications['older']) daywiseNotifications['older'] = [];
        daywiseNotifications['older'].push(notificationList[i].node);
      }
    }

    return daywiseNotifications;
  }, [notificationList]);

  const showReadAllNotification = useMemo(() => {
    if (unreadNotificationCount === 0) {
      return false;
    }
    return Object.keys(daywiseNotifications).some((day: string) => {
      return daywiseNotifications[day].some((checkStatus: any) => checkStatus?.status === BellNotificationStatusEnum.Unread);
    });
  }, [daywiseNotifications, unreadNotificationCount]);

  const [{ }, onUpdateAllBellNotificationsStatusOfUserMutation] =
    useUpdateAllBellNotificationsStatusOfUserMutation();
  const onMarkAllNotificationsAsRead = async (event?: React.MouseEvent<HTMLElement>) => {
    try {
      onUpdateAllBellNotificationsStatusOfUserMutation({
        input: {
          newStatus: BellNotificationStatusEnum.Read,
          currentStatus: BellNotificationStatusEnum.Unread,
        },
      }).then((response) => {
        console.log('Successfully marked all notificaitons as read.', response);
        resetSettings();
      });
    } catch (err) {
      console.error('Failed to mark all notificaitons as read.', err);
    }
  };

  const [{ }, onUpdateBellNotificationsStatusMutation] = useUpdateBellNotificationsStatusMutation();
  const onMarkNotificationRead = async (notification: BellNotificationSchema) => {
    onUpdateBellNotificationsStatusMutation({
      input: { bellNotificationIds: [notification.id], status: BellNotificationStatusEnum.Read },
    }).catch((err) => {
      console.error('Failed to mark all notificaitons as read.', err);
    });
  };
  return (
    <div ref={ref} className="relative flex items-center">
      <div>
        <Box className="mr-[8px] flex h-[40px] w-[40px] items-center justify-center">
          <span className="text-gray-500" role="img" onClick={toggleMenu}>
            {unreadNotificationCount !== 0 && (
              <Badge
                className="cursor-pointer"
                badgeContent={unreadNotificationCount > 99 ? `99+` : unreadNotificationCount}
                color="error">
                <CustomIcons.BellIcon />
              </Badge>
            )}
            {unreadNotificationCount === 0 && (
              <Box className="cursor-pointer">
                <CustomIcons.BellIcon />
              </Box>
            )}
          </span>
        </Box>
      </div>
      <div
        className={`
            absolute 
            top-[100%] 
            right-0 
            z-[1] 
            w-[450px]
            rounded-[12px]
            border-[1px]
            border-solid 
            border-grey-300 
            bg-white 
            p-[24px]
            text-grey-900 
            ease-in 
            lg:w-[575px] ${!open ? 'hidden' : ''}
            `}
        style={{
          boxShadow:
            '0px 8px 10px 0px rgba(16, 24, 40, 0.08), 0px 2px 8px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <div className="justify-betweemn flex items-center">
          <div className="flex-1 text-[24px] font-semibold text-gray-900">Notifications</div>
          <div className="flex items-center gap-[16px]">
            <div className="w-max">
              <Stack direction="row" spacing={1} alignItems="center" gap="16px">
                <span className="text-[14px] font-medium text-grey-800">Only show unread</span>
                <AntSwitch
                  checked={onlyUnreadNotifications}
                  inputProps={{ 'aria-label': 'ant design' }}
                  onChange={onToggleOnlyUnreadNotifications}
                />
              </Stack>
            </div>
            <div onClick={() => setOpen(false)}>
              <ClearIcon className="cursor-pointer text-[32px] text-red-700" />
            </div>
          </div>
        </div>

        <Divider className="mt-[16px] bg-grey-300" />
        <Stack className="no-scrollbar relative mt-[24px] h-[250px] overflow-y-auto lg:h-[320px] xl:h-[320px]">
          <div className="flex w-full flex-col items-end gap-[16px] absolute">
            {showReadAllNotification && (
              <span
                className="w-max cursor-pointer  text-[14px] text-blue-900 underline underline-offset-4"
                onClick={onMarkAllNotificationsAsRead}>
                Mark all as read
              </span>
            )}
          </div>
          {notificationList.length > 0 && (
            <>
              {['today', 'yesterday', 'older'].map(function (day, i) {
                return (
                  daywiseNotifications[day] && (
                    <div key={day}>
                      <div className="flex items-center justify-between">
                        <div>
                          <span className="mb-[16px] flex-1 text-[14px] font-semibold text-grey-500">
                            {day.toUpperCase()}
                          </span>
                        </div>
                      </div>
                      {daywiseNotifications[day].map((notification: any) => {
                        return (
                          <NotificationItem
                            key={notification.id}
                            notification={notification}
                            onMarkNotificationRead={onMarkNotificationRead}
                          />
                        );
                      })}
                    </div>
                  )
                );
              })}
              <div className="flex w-full justify-center py-[10px]">
                {fetching && <Loader />}
                {!fetching && hasNextPage && (
                  <Button
                    size="small"
                    variant="outlined"
                    className="h-[44px] !px-[12px] !py-[10px] text-[14px] font-medium text-grey-900"
                    onClick={goToNextPage}>
                    Load More
                  </Button>
                )}
              </div>
            </>
          )}
          {!fetching && notificationList.length === 0 && (
            <div className="flex px-[12px] py-[20vh] ">
              <span className="flex-1 text-center text-[16px] font-medium text-grey-800">
                No Notifications found.
              </span>
            </div>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default NotificationsMenu;
