import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import InventoryAssetSelectorFormField from 'app/modules/reports/components/InventoryAssetSelectorFormField';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportSKUSelect from 'app/modules/reports/components/ReportSKUSelect';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import {
  getAssetTransactionEntityTypes,
  getItemLocationItemTypesFromTransactionType,
} from '../../../../utils/itemtypes';
import AgingReportDateFilter, { getAgingReportDateFilterOptions } from '../AgingReportDateFilter';
import { AgingReportFormProps, AgingReportFormValues } from './types';

const defaultValues: AgingReportFormValues = {
  type: TransactionEntityTypeEnum.Asset,
  skuIds: [],
  categoryIds: [],
  siteIds: [],
  format: 'preview',
  updatedAt: getAgingReportDateFilterOptions()[0].updatedAt,
  itemTypes: getAssetTransactionEntityTypes(),
};

const AgingReportForm = (props: AgingReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const type = watch('type');
  const itemTypes = watch('itemTypes');

  useEffect(() => {
    setValue('itemTypes', getItemLocationItemTypesFromTransactionType(type));
  }, [type]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit, itemTypes]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (type: TransactionEntityTypeEnum) => {
      reset({ ...defaultValues, type: type });
    },
    [reset],
  );

  return (
    <form
      className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
      onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <InventoryAssetSelectorFormField control={control} onChange={onTypeChange} />
        <ReportSKUSelect control={control} types={itemTypes} />
        <ReportCategoriesSelect control={control} />
        <ReportSitesSelect control={control} />
        <AgingReportDateFilter control={control} />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default AgingReportForm;
