function isProductCodeValid(arr: any[], type: string = 'upc') {
  let odd = 0;
  let even = 0;
  let lastElement = arr[arr.length - 1];

  arr.forEach((itemString, index) => {
    const item = parseInt(itemString)
    const pointer = index + 1;
    if ((pointer < arr.length)) {
      if (pointer % 2 !== 0) {
        odd += item;
      } else {
        even += item;
      }
    }
  });

  if (type === "upc") {
    odd = odd * 3;
  } else {
    even = even * 3;
  }
  let total = odd + even;
  total = total % 10;
  total = total === 0 ? 0 : 10 - total;
  return total === parseInt(lastElement);
}

export function isValidProductCode(code: string) {
  const productCode = code.replaceAll(' ', '');
  const productCodeLength = productCode.length;

  if ([10, 12, 13, 14].includes(productCodeLength) && !/^\s*$/.test(productCode)) {
    if (productCodeLength === 14) {
      return true;
    }

    const productCodeArray = productCode.split('');

    if (productCodeLength >= 10 && productCodeLength < 13) {
      return isProductCodeValid(productCodeArray);
    } else if (productCodeLength === 13) {
      return isProductCodeValid(productCodeArray, 'ean');
    }
  }
  return false;
}
