import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import FormDatePickerInput from '../../../../../../components/ProcureForm/FormDatePickerInput';
import Assets from '../../../../../../i18n/Assets';
import { DateFormatEnum, formatDate } from '../../../../../../utils/date';
import { AssetFieldProps } from '../../types';

const AssetWarrantyExpiryDateField = (props: AssetFieldProps) => {
  const { disabled, formState,size="medium" } = props;
  const { control, purchaseDate, isItemTypeAsset } = formState;

  const minWarrantyDate = useMemo(() => {
    return (purchaseDate && formatDate(purchaseDate.toString(), DateFormatEnum.YYYY_MM_DD)) || null;
  }, [purchaseDate]);

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="warrantyExpiryDate"
        render={({ field, fieldState }) => (
          <FormDatePickerInput
            {...field}
            key="warrantyExpiryDate"
            clearable
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.WarrantyExpiryDate}
            minDate={minWarrantyDate}
            size={size}
            classes='mt-6'
          />
        )}
      />
    </div>
  );
};

export default AssetWarrantyExpiryDateField;
