import {
  getNextValidStatus,
  LeastItemStatues,
} from '../../views/OrderHistory/components/OrderStatusTableFilter/utils';
import {
  OrderRequestItemSchema,
  OrderRequestItemStatusEnum,
  UpdateOrderRequestItemStatusByOrderRequestIdInput,
} from '../../../../types/schema';
import { Option } from './type';
import { OrderedNotePopoverDefaultValue } from '../FormOrderDetailStatus/hook/useOrderedNotePopoverState';
import { getCurrentUTCTime } from '../../../assets/utils/dto';
import { DateFormatEnum, formatDate } from '../../../../utils/date';

export function getCurrentOption(orderRequestItem: OrderRequestItemSchema) {
  return {
    ...(LeastItemStatues.find((status) => status.value === orderRequestItem.status) || {
      label: '',
      value: orderRequestItem.status,
    }),
    disabled: false,
  } as Option;
}

const AllowedStatusChangeForCreatedByUser = [
  OrderRequestItemStatusEnum.Closed,
  OrderRequestItemStatusEnum.Cancelled,
];

export function getOptions(
  orderRequestItem: OrderRequestItemSchema,
  options: {
    canCreateShipment?: boolean;
    isOrderCreatedByCurrentUser: boolean;
  },
) {
  const { canCreateShipment, isOrderCreatedByCurrentUser } = options;
  const validStatuesForCurrentStatus = getNextValidStatus(orderRequestItem);

  return LeastItemStatues.map((leastItemStatus) => {
    let disabled = !validStatuesForCurrentStatus.find(
      (validStatus) => leastItemStatus.value === validStatus.value,
    );

    if (
      !disabled &&
      !canCreateShipment &&
      isOrderCreatedByCurrentUser &&
      !AllowedStatusChangeForCreatedByUser.includes(leastItemStatus.value)
    ) {
      disabled = true;
    }

    return {
      disabled,
      label: leastItemStatus.label,
      value: leastItemStatus.value,
    } as Option;
  });
}

export function createUpdateOrderRequestItemsStatusInput(data: {
  orderRequestId: string;
  orderRequestItemId: string;
  nonRemovableNote?: OrderedNotePopoverDefaultValue;
  status: OrderRequestItemStatusEnum;
}): UpdateOrderRequestItemStatusByOrderRequestIdInput {
  const { nonRemovableNote, orderRequestId, orderRequestItemId, status } = data;
  const { orderId, estimatedDeliveryDate, vendor } = nonRemovableNote || {};
  return {
    status,
    orderRequestItemId,
    orderRequestId,
    nonRemovableNote: nonRemovableNote
      ? {
          orderId,
          vendor,
          estimatedDeliveryDate: estimatedDeliveryDate
            ? getCurrentUTCTime(formatDate(estimatedDeliveryDate.toISOString(), DateFormatEnum.UTC))
            : undefined,
        }
      : undefined,
  };
}
