import AdminLayout from 'app/components/AdminLayout';
import AddRole from 'app/modules/roles/views/AddRole';

function AddRolePage() {
  return (
    <AdminLayout>
      <AddRole />
    </AdminLayout>
  );
}

export default AddRolePage;
