import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateExternalOrderRequestFromChildTenantMutationVariables = Types.Exact<{
  input: Types.UpdateExternalOrderRequestFromChildTenantInput;
}>;


export type UpdateExternalOrderRequestFromChildTenantMutation = { __typename?: 'Mutation', updateExternalOrderRequestFromChildTenant: { __typename?: 'UpdateOrderRequestPayload', success: boolean } };


export const UpdateExternalOrderRequestFromChildTenantDocument = gql`
    mutation updateExternalOrderRequestFromChildTenant($input: UpdateExternalOrderRequestFromChildTenantInput!) {
  updateExternalOrderRequestFromChildTenant(input: $input) {
    success
  }
}
    `;

export function useUpdateExternalOrderRequestFromChildTenantMutation() {
  return Urql.useMutation<UpdateExternalOrderRequestFromChildTenantMutation, UpdateExternalOrderRequestFromChildTenantMutationVariables>(UpdateExternalOrderRequestFromChildTenantDocument);
};