import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UndeleteUsersMutationVariables = Types.Exact<{
  input: Types.UndeleteUsersInput;
}>;


export type UndeleteUsersMutation = { __typename?: 'Mutation', undeleteUsers: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const UndeleteUsersDocument = gql`
    mutation undeleteUsers($input: UndeleteUsersInput!) {
  undeleteUsers(input: $input) {
    success
    reason
  }
}
    `;

export function useUndeleteUsersMutation() {
  return Urql.useMutation<UndeleteUsersMutation, UndeleteUsersMutationVariables>(UndeleteUsersDocument);
};