import { UpdatesConfig } from '@urql/exchange-graphcache';

import { invalidateQuery } from './utils';

export const cacheMutations: UpdatesConfig['Mutation'] = {
  createAddress: invalidateQuery('addresses'),
  updateAddress: invalidateQuery('addresses'),
  deleteAddresses: invalidateQuery(['contacts', 'addresses']),

  createContact: invalidateQuery(['contacts', 'addresses']),
  updateContact: invalidateQuery(['contacts', 'addresses']),
  deleteContacts: invalidateQuery(['contacts', 'addresses']),

  createShippingContainer: invalidateQuery(['itemlocations', 'locations', 'shippingContainers']),

  createCompany: invalidateQuery(['companies', 'contacts', 'addresses']),
  updateCompany: invalidateQuery(['companies', 'contacts', 'addresses']),
  deleteCompanies: invalidateQuery(['companies', 'contacts', 'addresses']),

  createLocation: invalidateQuery(['locations', 'warehouses']),
  deleteLocations: invalidateQuery(['locations', 'warehouses']),
  createCategory: invalidateQuery('categories'),
  deleteCategories: invalidateQuery('categories'),
  createDepartment: invalidateQuery('departments'),
  deleteDepartments: invalidateQuery('departments'),
  createManufacturer: invalidateQuery('manufacturers'),
  updateManufacturer: invalidateQuery('manufacturers'),
  deleteManufacturers: invalidateQuery('manufacturers'),
  createProject: invalidateQuery('projects'),
  deleteProjects: invalidateQuery('projects'),
  createTenant: invalidateQuery('tenants'),
  deleteTenant: invalidateQuery('tenants'),
  createPartnerTenant: invalidateQuery('partnerTenants'),
  updatePartnerTenants: invalidateQuery('partnerTenants'),
  deletePartnerTenant: invalidateQuery('partnerTenants'),
  createUser: invalidateQuery('users'),
  deleteUsers: invalidateQuery(['users', 'categories', 'locations']),
  undeleteUsers: invalidateQuery(['users', 'categories', 'locations']),

  createRole: invalidateQuery('roles'),
  deleteRoles: invalidateQuery('roles'),

  addZonesInWarehouse: invalidateQuery(['warehouses', 'locations']),
  createWarehouse: invalidateQuery('warehouses'),

  createAssetItem: invalidateQuery(['assetItems', 'itemlocations', 'items']),
  updateAssetItem: invalidateQuery(['assetItems', 'itemlocations', 'items']),
  updateAssetItems: invalidateQuery(['assetItems', 'itemlocations', 'items']),
  deleteAssetItems: invalidateQuery(['assetItems', 'itemlocations', 'items']),
  undeleteAssetItems: invalidateQuery(['items', 'assetItems', 'itemlocations']),
  createAssetKitItem: invalidateQuery(['items', 'itemlocations']),
  updateAssetKitItems: invalidateQuery(['assetItems', 'itemlocations', 'items']),
  updateAssetKitItem: invalidateQuery(['items', 'itemlocations']),
  deleteAssetKitItem: invalidateQuery(['items', 'itemlocations']),

  createAssetItemReminder: invalidateQuery(['items']),
  updateAssetItemReminder: invalidateQuery(['items']),
  deleteAssetItemReminder: invalidateQuery(['items']),

  createInventoryItem: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  updateInventoryItem: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  updateInventoryItems: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  deleteInventoryItems: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  undeleteInventoryItems: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  createInventoryKitItem: invalidateQuery(['items', 'itemlocations']),
  updateInventoryKitItems: invalidateQuery(['items', 'inventoryItems', 'itemlocations']),
  updateInventoryKitItem: invalidateQuery(['items', 'itemlocations']),
  deleteInventoryKitItem: invalidateQuery(['items', 'itemlocations']),

  createMoveTransactions: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'transactions',
    'locations',
    'itemlocations',
    'items',
  ]),
  createRemoveTransactions: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'transactions',
    'locations',
    'itemlocations',
    'items',
  ]),
  createRestockTransactions: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'transactions',
    'locations',
    'itemlocations',
    'items',
  ]),
  updateQuantityConfigurationsAtItemLocations: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'locations',
    'itemlocations',
    'items',
  ]),
  createCheckInTransaction: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'transactions',
    'locations',
    'itemlocations',
    'items',
    'valuesInEntities',
  ]),
  createCheckOutTransactions: invalidateQuery([
    'assetItems',
    'inventoryItems',
    'transactions',
    'locations',
    'itemlocations',
    'items',
  ]),
  createAuditTransactions: invalidateQuery('auditTransactionReports'),
  createReserveTransactions: invalidateQuery('transactions'),
  updateReserveTransactions: invalidateQuery('transactions'),
  unreserveTransactions: invalidateQuery('transactions'),

  createAffiliateConfiguration: invalidateQuery(['affiliateConfigurations']),
  deleteAffiliateConfigurations: invalidateQuery(['affiliateConfigurations']),
  updateAffiliateConfiguration: invalidateQuery(['affiliateConfigurations']),

  createNetworkAsset: invalidateQuery([
    'networkAssets',
    'distinctNetworkAssets',
    'getAllNetworkAssetWarranty',
  ]),
  bulkImportNetworkAssets: invalidateQuery(['assetItems', 'items']),
  deleteNetworkAssets: invalidateQuery([
    'networkAssets',
    'distinctNetworkAssets',
    'getAllNetworkAssetWarranty',
  ]),
  createAssetModelItem: invalidateQuery('assetModelItems'),
  updateAssetModelItem: invalidateQuery('assetModelItems'),
  deleteAssetModelItems: invalidateQuery('assetModelItems'),
  createWishlist: invalidateQuery('wishlists'),
  updateWishlist: invalidateQuery('wishlists'),
  deleteWishlists: invalidateQuery('wishlists'),
  verifyWishlistCollaborateInvitation: invalidateQuery('wishlists'),

  deleteOrderRequest: invalidateQuery('orderRequests'),
  deleteOrderRequests: invalidateQuery('orderRequests'),
  createOrderRequest: invalidateQuery('orderRequests'),
  createExternalOrderRequest: invalidateQuery('orderRequests'),
  updateOrderRequestItemStatusByOrderRequestId: invalidateQuery('orderRequests'),
  updateOrderRequest: invalidateQuery('orderRequests'),
  updateExternalOrderRequestFromParentTenant: invalidateQuery('orderRequests'),
  updateExternalOrderRequestFromChildTenant: invalidateQuery('orderRequests'),
  returnOrderRequestItems: invalidateQuery('orderRequests'),
  resendReturnedOrderRequestItemEmail: invalidateQuery('orderRequests'),

  markShippingTransactionsOfContainerIdsAsDelivered: invalidateQuery('shippingTransactions'),

  unpackShippingTransactionsOfContainerId: invalidateQuery([
    'packedShippingContainers',
    'orderRequests',
    'shippingTransactions',
  ]),
  unpackShippingTransactionsOfTrackingIds: invalidateQuery([
    'packedShippingContainers',
    'orderRequests',
    'shippingTransactions',
  ]),

  updateAllBellNotificationsStatusOfUser: invalidateQuery('bellNotifications'),
  updateUser: invalidateQuery('users'),

  createReceivables: invalidateQuery(['receivables']),
  receiveItems: invalidateQuery(['receivables']),
  updateReceivable: invalidateQuery(['receivables']),
  deleteReceivables: invalidateQuery(['receivables']),
};
