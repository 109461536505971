import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InventoryCostReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type InventoryCostReportQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', totalCount: number, edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema' } | { __typename?: 'AssetKitItemSchema' } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, model?: string | null, totalQuantity?: number | null, cost?: number | null, unitCost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, totalQuantity?: number | null, cost?: number | null, unitCost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const InventoryCostReportDocument = gql`
    query inventoryCostReport($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  items(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ... on InventoryItemSchema {
          id
          sku
          status
          type
          title
          category {
            id
            name
            categoryCode
          }
          model
          totalQuantity
          cost
          unitCost
        }
        ... on InventoryKitItemSchema {
          id
          sku
          status
          type
          title
          category {
            id
            name
            categoryCode
          }
          totalQuantity
          cost
          unitCost
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    `;

export function useInventoryCostReportQuery(options?: Omit<Urql.UseQueryArgs<InventoryCostReportQueryVariables>, 'query'>) {
  return Urql.useQuery<InventoryCostReportQuery>({ query: InventoryCostReportDocument, ...options });
};