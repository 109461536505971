import { forwardRef, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { DatePicker } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';
import { Size } from 'app/types/button';

import FormLabel from '../../Form/FormLabel';
import { FormDatePickerInputStyles } from './styles';

interface Props {
  id?: string;
  label?: string;
  isRequired?: boolean;
  value?: Date | string | null;
  onChange?: (value: Date | null) => void;
  error?: FieldError | undefined;
  disabled?: boolean;
  clearable?: boolean;
  minDate?: string;
  size?: string;
  maxDate?: string;
  classes?: string;
  placeholder?: string;
  name?: string;
}
const FormDatePickerInput = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    value,
    label,
    onChange,
    error,
    isRequired,
    disabled,
    minDate,
    maxDate,
    size,
    clearable,
    classes,
    placeholder,
    id = '',
    name
  } = props;

  const onInputChange = useCallback(
    (date: Date | null) => {
      onChange?.(date);
    },
    [onChange],
  );

  return (
    <div ref={ref}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <div className={clsx(FormDatePickerInputStyles, classes)}>
        <DatePicker
          name={name}
          clearable={clearable}
          disabled={!!disabled}
          error={!!error}
          errorMessage={error ? (error.message as string) : ''}
          inputFormat="MM/dd/yyyy"
          maxDate={maxDate || ''}
          minDate={minDate || ''}
          placeholder={placeholder}
          size={(size || 'medium') as Size}
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
});

export default FormDatePickerInput;
