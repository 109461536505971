import { Button } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import useOrderReturnPermissionCheck from '../../hook/useOrderReturnPermissionCheck';

interface Props {
  classes?: string;
  onClick: () => void;
  orderDetail: any;
}
const ReturnItemsButton = (prop: Props) => {
  const { classes, onClick, orderDetail } = prop;
  const { isOrderReturnable, isReturnButtonDisabled } = useOrderReturnPermissionCheck({
    orderDetail: orderDetail,
  });

  if (!isOrderReturnable) {
    return null;
  }

  return (
    <div className={classes}>
      <Button
        disabled={isReturnButtonDisabled}
        theme="danger"
        onClick={onClick}
        classes="min-w-[140px] h-[44px]">
        {Orders.Actions.ReturnItems}
      </Button>
    </div>
  );
};

export default ReturnItemsButton;
