import {
  DataGridComponentState,
  DataGridHeadCell,
} from '@procurenetworks/procure-component-library';
import FormValuesInItemsTableFilter from '../../../../../assetsInventory/components/FormValuesInItemsTableFilter';
import { useCallback } from 'react';
import {
  ItemStatusEnum,
  ItemStringBasedColumnFilterFieldEnum,
  ItemTypeEnum,
} from '../../../../../../types/schema';
import { UseSearchAssetsStateReturnType } from '../../hook/useSearchAssetsState';
import SyncStateWithUrlParam from 'app/utils/queryParams';

export interface LabelNodeProps {
  headCell: DataGridHeadCell;
  state: DataGridComponentState;
}

const ModelNumberLabelNode = (props: LabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filters } = extraProps as {
    filters: UseSearchAssetsStateReturnType['filters'];
  };
  const { modelNumbers } = filters.state;

  SyncStateWithUrlParam('modelNumbers', filters.state, filters.setState)

  const onChange = useCallback(
    (nextValue: string[]) => {
      filters.setState({
        modelNumbers: nextValue,
      });
    },
    [filters.setState],
  );

  return (
    <div className="table-filter">
      <FormValuesInItemsTableFilter
        value={modelNumbers}
        onChange={onChange}
        className=" "
        key="model-number-label-node"
        name="model-number-label-node"
        placeholder="Model Number"
        queryVariables={{
          field: ItemStringBasedColumnFilterFieldEnum.ModelNumbers,
          filters: {
            types: [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit],
            statuses: [ItemStatusEnum.Active],
          },
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </div>
  );
};

export default ModelNumberLabelNode;
