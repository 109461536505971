const Manufacturer = {
  Manufacturers: 'Manufacturers',
  NewManufacturer: 'New Manufacturer',
  EditManufacturer: 'Edit Manufacturer',
  FormLabels: {
    ManufacturerName: 'Manufacturer',
    Website: 'Website',
    Email: 'Email',
    ContactNumber: 'Contact Number',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Manufacturer Name between 3 and 30 characters.',
    EmailInvalid: 'Please enter a valid Email address.',
  },
  FormPlaceholders: {
    Name: 'Enter Manufacturers Name',
  },
};

export default Manufacturer;
