import { ItemLocationSchema, LocationTypeEnum } from '../../../../../types/schema';
import { adaptLocation } from '../../../../locations/utils/location';

export const isOutOfStockAtDateExpired = (outOfStockAt?: null | string) => {
  const date = new Date();
  date.setDate(date.getDate() - 120);

  return outOfStockAt !== null && new Date(outOfStockAt as string) > date;
};

export const groupStockInformationBySite = (stockInformation: ItemLocationSchema[]) => {
  return stockInformation
    .filter((item) => item.location.type === LocationTypeEnum.Site)
    .map((stockInformationItem) => {
      const totalQuantityFromLocations = stockInformationItem.totalQuantityFromLocations || 0;
      const totalQuantity = stockInformationItem.totalQuantity || 0;
      return {
        ...stockInformationItem,
        totalQuantityFromLocations: totalQuantityFromLocations + totalQuantity,
        locations: [] as any,
        editable: false,
        editableFields: null,
      };
    });
};

export const checkTotalQuantityAndOutOfStockAtDate = (
  totalQuantity?: number | null,
  outOfStockAt?: null | string,
) => {
  return (
    totalQuantity !== null &&
    (totalQuantity !== 0 || (totalQuantity === 0 && isOutOfStockAtDateExpired(outOfStockAt)))
  );
};

export const parseStockInformationToRows = (stockInformation: ItemLocationSchema[]) => {
  const groupedStockInformationBySite = groupStockInformationBySite(stockInformation);

  stockInformation.forEach((item: ItemLocationSchema) => {
    const totalQuantity = item.totalQuantity;
    const outOfStockAt = item.outOfStockAt;
    if (checkTotalQuantityAndOutOfStockAtDate(totalQuantity, outOfStockAt)) {
      const stockInformationSiteItem = groupedStockInformationBySite.find(
        (data) => data.site.id === item.site.id,
      );
      if (stockInformationSiteItem) {
        stockInformationSiteItem.locations.push({
          ...adaptLocation(stockInformationSiteItem?.site?.id || '', item.location),
          totalQuantity: item.totalQuantity || 0,
        });
      }
    }
  });

  return groupedStockInformationBySite.filter((item) => item.locations.length > 0);
};

export const parseSearchAssetInventoryStockInformationToRows = (
  stockInformation: ItemLocationSchema[],
) => {
  const rows: any[] = [];

  stockInformation.forEach((item: ItemLocationSchema) => {
    const totalQuantity = item.totalQuantity;
    const outOfStockAt = item.outOfStockAt;
    const siteId = item.siteId;
    const location = item.location;
    if (checkTotalQuantityAndOutOfStockAtDate(totalQuantity, outOfStockAt)) {
      rows.push({
        ...adaptLocation(siteId, location),
        totalQuantity: totalQuantity || 0,
      });
    }
  });

  return rows;
};

export const isAllStockInformationHaveZeroQuantity = (
  stockInformation: ItemLocationSchema[],
): boolean => {
  const results = stockInformation.find(
    (item) => item.totalQuantity !== 0 && item.totalQuantity !== null,
  );

  return !results;
};

export const findStockInformationBySiteId = (
  stockInformation: ItemLocationSchema[],
  siteId: string,
) => {
  return stockInformation.find((item) => item.location.id === siteId);
};

export const getMinimumQuantityFromStockInformationBySiteId = (
  stockInformation: ItemLocationSchema[],
  siteId: string,
) => {
  return findStockInformationBySiteId(stockInformation || [], siteId)?.minimumQuantity || null;
};

export const getMaximumQuantityFromStockInformationBySiteId = (
  stockInformation: ItemLocationSchema[],
  siteId: string,
) => {
  return findStockInformationBySiteId(stockInformation || [], siteId)?.maximumQuantity || null;
};
