import { useCallback, useState } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import Stack from 'app/ui-components/Stack';

import { useAccessControl } from '../../../../../../components/AccessControl';
import Common from '../../../../../../i18n/Common';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../../../types/schema';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import DeleteConfirmationModal from '../../../../../components/EntityManager/DeleteConfirmationModal';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];

  onDelete: (ids: string[]) => void;
  onEdit: (id: string) => void;
}

const RoleActions = (props: Props) => {
  const { table, onEdit, onDelete } = props;
  const { state } = table;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const { workspacePermissions } = useCurrentUser();

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Role,
  );

  const canRead = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Read,
    AllowedPermissionsSubjectEnum.Role,
  );

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Role,
  );

  const canReadEditDelete = canEdit || canRead || canDelete;

  const onShowDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);

  const onCancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, [setShowDeleteConfirmation]);

  const onConfirmDelete = useCallback(() => {
    if (table.state.selectedRowIds) {
      onDelete(table.state.selectedRowIds);
    }
    setShowDeleteConfirmation(false);
  }, [onDelete, table.state.selectedRowIds]);

  const handleOnEdit = useCallback(() => {
    if (table.state.selectedRowIds) {
      onEdit(table.state.selectedRowIds[0]);
    }
  }, [onEdit, table.state.selectedRowIds]);

  if (!canReadEditDelete) {
    return null;
  }

  return (
    <>
      {canDelete ? (
        <DeleteConfirmationModal
          open={showDeleteConfirmation}
          onCancel={onCancelDelete}
          onConfirm={onConfirmDelete}
        />
      ) : undefined}

      <Stack className="mt-8 gap-[16px] px-16" justifyContent="end">
        {canEdit || canRead ? (
          <Button
            disabled={!(state.selectedRowIds?.length === 1)}
            theme="info"
            onClick={handleOnEdit}
            classes="min-w-[94px] h-[44px]">
            {canEdit ? Common.Actions.Edit : Common.Actions.View}
          </Button>
        ) : null}
        {canDelete ? (
          <Button
            disabled={!state.selectedRowIds.length}
            theme="danger"
            onClick={onShowDeleteConfirmation}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Delete}
          </Button>
        ) : null}
      </Stack>
    </>
  );
};

export default RoleActions;
