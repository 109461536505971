import moment from 'moment';
import { SelectOption } from 'app/components/Select/types';
import { DateFilter } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

export const getAuditDateFilterOptions = (): (SelectOption & { createdAt: DateFilter })[] => {
  const getStartTime = (years: number) => {
    return moment().subtract(years, 'year').startOf('day').toISOString();
  };

  const getEndTime = () => {
    return moment().endOf('day').toISOString();
  };

  return [
    // {
    //   value: 'last-year',
    //   label: 'Last year',
    //   createdAt: {
    //     timezone: getCurrentTimeZoneValue(),
    //     absolute: { startTime: getStartTime(3), endTime: getEndTime() },
    //   },
    // },
    {
      value: 'custom',
      label: 'Select Date Range',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: null, endTime: null },
      },
    },
  ];
};
