import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Button } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Network from 'app/i18n/Network';
import { Note } from 'app/types/schema';

import { NOTES_FORM_RULES } from '../utils/validation';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  note?: Note | null | undefined;
  onSaveNote: any;
};

const NotesModal = (props: Props) => {
  const { note, isOpen, onSaveNote, onCancel } = props;

  const noteDetailDefaultValues = useMemo(() => {
    return (
      note || {
        heading: '',
        note: '',
      }
    );
  }, [note]);

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: noteDetailDefaultValues,
  });

  const onSubmit = useCallback(
    (input: any) => {
      onSaveNote(input);
    },
    [onSaveNote],
  );

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  return (
    <Modal
      actions={
        <div>
          <Button classes="w-[90px]" theme="success" onClick={onFormSubmit}>
            {note?.heading && note?.note ? Common.Actions.Save : Common.Actions.Add}
          </Button>
          <Button classes="ml-[16px] w-[90px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="notes-modal"
      open={isOpen}
      title={note?.heading && note?.note ? Network.Action.UpdateNote : Network.Action.AddNote}
      onClose={onCancel}>
      <div className="w-[100%] overflow-x-hidden md:w-[560px]">
        <form>
          <Grid container paddingTop={1} spacing={1}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="heading"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    autoFocus
                    isRequired
                    {...field}
                    error={fieldState.error}
                    label={Network.FormLabels.Title}
                    placeholder={Network.FormLabels.Title}
                    formLabel={Network.FormLabels.Title}
                  />
                )}
                rules={NOTES_FORM_RULES.title}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="note"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    error={fieldState.error}
                    label={Network.FormLabels.Note}
                    placeholder={Network.FormLabels.Note}
                    formLabel={Network.FormLabels.Note}
                  />
                )}
                rules={NOTES_FORM_RULES.note}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default NotesModal;
