import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getCheckOutTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
  },
  {
    id: 'sourceSiteName',
    label: 'From Site',
    value: 'sourceSiteName',
  },
  {
    id: 'sourceLocationName',
    label: 'From Location',
    value: 'sourceLocationName',
  },

  {
    id: 'destinationName',
    label: 'To Site',
    value: 'destinationName',
  },
  {
    id: 'destinationLocationName',
    label: 'To Location',
    value: 'destinationLocationName',
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    value: 'dueDate',
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'id',
    label: 'Action',
    value: 'id',
    valueNode: ({ row, state }) => {
      return (
        <Button
          theme="danger"
          onClick={() => {
            state?.extraProps?.onRemoveRow(row.id);
          }}>
          Remove
        </Button>
      );
    },
  },
];
