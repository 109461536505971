import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Network from 'app/i18n/Network';

import useChart from '../view/dashboard/components/chart/useChart';

const CHART_HEIGHT = 275;
const LEGEND_HEIGHT = 60;

const StyledChartWrapper = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),

  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

type NetworkAssetsWarrantyTypes = {
  title: string;
  subheader: string;
  chartColors: string[];
  chartData: Array<any>;
};

export const NetworkAssetsWarranty = ({
  title,
  subheader,
  chartColors,
  chartData,
  ...other
}: NetworkAssetsWarrantyTypes) => {
  const theme = useTheme();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const nonZeroElement = chartSeries.find((elem) => elem !== 0);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: any) => seriesName,
        title: {
          formatter: (seriesName: any) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card
      {...other}
      className="border-grey300 rounded-[12px] border "
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
      }}>
      {!nonZeroElement ? (
        <Typography
          style={{
            color: 'gray',
            fontSize: '14px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '25px',
          }}>
          {Network.NothingToShow}
        </Typography>
      ) : (
        <>
          <CardHeader subheader={subheader} title={title} />
          <StyledChartWrapper dir="ltr">
            <ReactApexChart height={200} options={chartOptions} series={chartSeries} type="pie" />
          </StyledChartWrapper>
        </>
      )}
    </Card>
  );
};
