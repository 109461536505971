import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateLocationMutationVariables = Types.Exact<{
  input: Types.CreateLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'CreateLocationPayload', success: boolean, reason?: string | null, location?: { __typename?: 'LocationSchema', id: string } | null } };


export const CreateLocationDocument = gql`
    mutation createLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    success
    reason
    location {
      id
    }
  }
}
    `;

export function useCreateLocationMutation() {
  return Urql.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument);
};