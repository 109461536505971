import {
  composeValidators,
  duplicateValidator,
  textMaxLengthValidator,
  textValidator,
  urlValidator} from 'app/components/Form/utils/validators';
import Wishlist from 'app/i18n/Wishlist';

export const CREATELIST_FORM_RULES = {
  firstName: {
    validate: composeValidators(
      textValidator(Wishlist.FormValidations.FirstName),
      textMaxLengthValidator(Wishlist.FormValidations.FirstName, 30),
    ),
  },
  lastName: {
    validate: composeValidators(
      textValidator(Wishlist.FormValidations.LastName),
      textMaxLengthValidator(Wishlist.FormValidations.LastName, 30),
    ),
  },
  name: {
    validate: textValidator(Wishlist.FormValidations.WishlistRequired),  
  },
  wishlistName: {
    validate: textValidator(Wishlist.FormValidations.WishlistRequired),  
  },
  shippingAddress: {
    validate: textValidator(Wishlist.FormValidations.ShippingRequired),  
  },
  city: {
    validate: textValidator(Wishlist.FormValidations.CityRequired),  
  },
  state: {
    validate: textValidator(Wishlist.FormValidations.StateRequired),  
  },
  zipCode: {
    validate: textValidator(Wishlist.FormValidations.ZipCodeRequired),  
  },
  customUrl: (isDuplicate: boolean) =>{
    return {
      validate: composeValidators(
        textValidator(Wishlist.FormValidations.CustomUrlRequired),  
        urlValidator(Wishlist.FormValidations.CustomUrlInvalid),
        duplicateValidator(Wishlist.FormValidations.CustomUrlDuplicate, isDuplicate)
      ),
    }
  },
  eventDate: {
    validate: textValidator(Wishlist.FormValidations.EventDateRequired),  
  },
};
