import { SnackbarService } from "app/components/Snackbar";
import Network from "app/i18n/Network";
import { useBulkImportNetworkAssetsMutation } from "app/modules/network/graphql/mutations/generated/bulkImportNetworkAssets";
import {NetworkAssetSchema } from "app/types/schema";

type Props = {
  networkAssetDetail: NetworkAssetSchema | undefined;
}

const useImportAssetData = (props: Props) => {
  const { networkAssetDetail} = props;
  const [{fetching: importingAssets}, bulkImportNetworkAssets] = useBulkImportNetworkAssetsMutation()


  const handleImportClick = () =>{
    bulkImportNetworkAssets({input: {networkAssetIds: [networkAssetDetail?.id || '']}}).then((response) => {
      if (response.data?.bulkImportNetworkAssets.success) {
        SnackbarService.show({ message: Network.SuccessMessages.ITAssetImported });
      }
    })
  }

  return {
    handleImportClick,
    importingAssets
  };
};
export type UseOperatingSystemDataReturnType = ReturnType<typeof useImportAssetData>;
export default useImportAssetData;
