import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import FormError from 'app/components/Form/FormError';
import FormLabel from 'app/components/Form/FormLabel';
import { LabelTypeEnum } from 'app/modules/labels/views/Labels/components/Forms/types';
import Box from 'app/ui-components/Box';

import { LocationTypeEnum } from '../../../../types/schema';
import LocationSelector from '../LocationSelector';
import SiteSelector from '../SiteSelector';

interface Props {
  className?: string;
  selectClassName?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  name?: string;
  type?: string;
  value?: string | null;
  menuPosition?: 'absolute' | 'fixed';
  onBlur?: () => void;
  onChange?: (value: string | undefined, option?: any) => void;
  error?: FieldError;
  types?: LocationTypeEnum[];
  placeholder?: string;
  enabledAllOption?: boolean;
  autoFocus?: boolean;
  clearable?: boolean;
  selectedLocationValue?: string;
  locationIds?: string[];
  pause?: boolean;
  currentSelectedOption?: any;
}

const FormSiteSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, selectClassName = 'mt-0', error, label, isRequired, type, ...rest } = props;

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      {type === LabelTypeEnum.Location ? (
        <LocationSelector {...rest} />
      ) : (
        <SiteSelector {...rest} className={selectClassName} />
      )}
      <FormError error={error} />
    </Box>
  );
});

export default FormSiteSelect;
