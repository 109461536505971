import { Controller, Validate } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import AssetModel from 'app/i18n/AssetModel';
import FormCostInput from 'app/modules/components/FormCostInput';

import { AssetModelFieldProps } from '../../type';
import { ASSET_MODEL_FORM_RULES } from '../../utils/utils';

const AssetModelUnitCostOverrideField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div>
      <div className="mb-[24px]">
        <Controller
          control={control}
          name="unitCost"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={disabled}
              label={AssetModel.FormLabels.UnitCost}
            />
          )}
        />
      </div>

      <div className="mb-[24px]">
        <Controller
          control={control}
          name="costOverride"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={disabled}
              label={AssetModel.FormLabels.CostOverride}
              maxValue={999.0}
            />
          )}
          // @ts-ignore
          rules={ASSET_MODEL_FORM_RULES.costOverride as { validate: Validate<string | number> }}
        />
      </div>
    </div>
  );
};

export default AssetModelUnitCostOverrideField;
