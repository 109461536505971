import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import Inventory from 'app/i18n/Inventory';
import { UseInventoryFormReturnType } from 'app/modules/inventory/components/InventoryForm/hook/useInventoryForm';

interface Props {
  formState: UseInventoryFormReturnType['state'];
}

const InventorySkuField = (props: Props) => {
  const { formState } = props;
  const { itemId, control } = formState;

  if (!itemId) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="sku"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isDisabled
            isRequired
            error={fieldState.error}
            label={Inventory.FormLabels.Sku}
            placeholder={Inventory.FormPlaceholders.Sku}
          />
        )}
      />
    </div>
  );
};

export default InventorySkuField;
