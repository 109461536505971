import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ItemsReceivableInOrderQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderRequestFilters>;
}>;


export type ItemsReceivableInOrderQuery = { __typename?: 'Query', orderRequests: { __typename?: 'OrderRequestConnection', edges?: Array<{ __typename?: 'OrderRequestEdge', node: { __typename?: 'OrderRequestSchema', id: string, receivingOrderRequestItems: { __typename?: 'ReceivableOrderRequestItemsPayload', errorCode?: Types.ReceivableOrderRequestItemErrorCodeEnum | null, orderRequestItems: Array<{ __typename?: 'OrderRequestItemSchema', id: string, upcCode?: string | null, website?: string | null, description?: string | null, itemId?: string | null, quantity: number, type: Types.OrderRequestItemTypeEnum, item?: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, title: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, sku: string, title: string } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string, title: string } | null, orderRequest?: { __typename?: 'OrderRequestSchema', orderRequestCode: string, id: string, title?: string | null } | null }> } } }> | null } };


export const ItemsReceivableInOrderDocument = gql`
    query itemsReceivableInOrder($filter: OrderRequestFilters) {
  orderRequests(filters: $filter) {
    edges {
      node {
        id
        receivingOrderRequestItems(filters: {statuses: [ORDERED]}) {
          orderRequestItems {
            id
            item {
              ... on AssetItemSchema {
                id
                sku
                title
              }
              ... on AssetKitItemSchema {
                id
                sku
                title
              }
              ... on InventoryItemSchema {
                id
                sku
                title
              }
              ... on InventoryKitItemSchema {
                id
                sku
                title
              }
            }
            upcCode
            website
            description
            itemId
            quantity
            type
            orderRequest {
              orderRequestCode
              id
              title
            }
          }
          errorCode
        }
      }
    }
  }
}
    `;

export function useItemsReceivableInOrderQuery(options?: Omit<Urql.UseQueryArgs<ItemsReceivableInOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<ItemsReceivableInOrderQuery>({ query: ItemsReceivableInOrderDocument, ...options });
};