import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { UserTableRowFragmentDoc } from '../../../../Users/graphql/fragments/generated/UserTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateUserMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UpdateUserPayload', success: boolean, reason?: string | null, user?: { __typename?: 'UserSchema', id: string, profilePicture?: string | null, firstName: string, lastName?: string | null, emailId: string, status: Types.UserStatusEnum, lastLogin?: string | null, roles: Array<{ __typename?: 'RoleSchema', id: string, name: string }> } | null } };


export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    success
    reason
    user {
      ...UserTableRow
    }
  }
}
    ${UserTableRowFragmentDoc}`;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};