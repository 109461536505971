import { Content } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import Inventory from 'app/i18n/Inventory';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';
import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';

import Loader from '../../../../components/Loader';
import EntityManager from '../../../components/EntityManager';
import EditInventoryView from '../../components/EditInventoryView';
import useEditInventoryState from './hook/useEditInventoryState';

type Props = RouteComponentProps & { inventoryId: string };

const EditInventory = (props: Props) => {
  const { inventoryId } = props;

  const { fetching, inventoryData, inventoryFormData, stockInformation } =
    useEditInventoryState(inventoryId);

  if (fetching) {
    return <Loader />;
  }

  if (!inventoryData) {
    return <Redirect to={routes.InventorySearch()} />;
  }

  return (
    <EntityManager className="mt-[0px]">
      <EntityManager.Title
        documentTitle={Inventory.EditInventory}
        title={Inventory.EditInventory}
        hasTopDivider
      />
      <Content>
        <FindUPCProvider>
          <EditInventoryView
            withQuickLinks
            inventoryData={inventoryData}
            inventoryFormData={inventoryFormData}
            inventoryId={inventoryId}
            stockInformation={stockInformation}
          />
        </FindUPCProvider>
      </Content>
    </EntityManager>
  );
};

export default withRouter(EditInventory);
