import { useMemo } from 'react';
import { EntityPermissionsMap } from 'app/types/schema';

import { useWorkspaceAllowedEntityPermissionsQuery } from '../graphql/queries/generated/workspaceAllowedEntityPermissions';

function useEntityPermissions() {
  const [{ data, fetching }] = useWorkspaceAllowedEntityPermissionsQuery();

  const entityPermissions = useMemo(() => {
    const allowedEntityPermissionsMap = data?.workspace.allowedEntityPermissionsMap;
    return (allowedEntityPermissionsMap || []) as EntityPermissionsMap[];
  }, [data?.workspace.allowedEntityPermissionsMap]);

  return { fetching, entityPermissions };
}

export default useEntityPermissions;
