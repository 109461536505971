import { css } from '@emotion/css';

export const Cards = css`
  @media (max-width: 1024px) {
    flex-grow: 1;
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (max-width: 600px) {
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
`;

export const MerchantLogo = css`
  @media (max-width: 376px) {
    height: 100px;
  }
  @media (min-width: 425px) {
    height: 125px;
  }
  @media (min-width: 767px) {
    height: 124px;
  }
  @media (min-width: 991px) {
    height: 155px;
  }
  @media (min-width: 1024px) {
    height: 93px;
  }
  @media (min-width: 1440px) {
    height: 162px;
  }
`;
