import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useUndeleteLayout from 'app/modules/undelete/views/undelete/hook/useUndeleteLayout';
import UndeleteAsset from 'app/modules/undelete/views/undeleteAsset';

function UndeleteAssetPage(props: RouteComponentProps) {
  const { history } = props;
  const { tabs } = useUndeleteLayout(history);

  return (
    <AdminLayout customTabs={tabs}>
      <UndeleteAsset />
    </AdminLayout>
  );
}

export default withRouter(UndeleteAssetPage);
