import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import { getTransactionFilterValues } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';

import { useCustomReportQuery } from '../../graphql/generated/customReport';
import { CustomReportFormValues } from '../CustomReportForm/types';
import { getCustomReportTableColumns } from './columns';
import { Box } from '@mui/material';

interface Props {
  values: CustomReportFormValues;
}

const CustomReportTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useCustomReportQuery,
    {
      filters: {
        categoryIds: values.categoryIds,
        projectIds: values.projectIds,
        entityIds: values.skuIds,
        _or: [
          { billToSiteIds: values.siteIds },
          { sourceSiteIds: values.siteIds },
          { destinationSiteIds: values.siteIds },
        ],
        _and: [{ _or: getTransactionFilterValues(values.transactionTypes) }],
        entityTypes: values.itemTypes,
        createdAt: values.createdAt,
      },
      sorts: [{ sortField: 'createdAt', sortOrder: values.sortOrder }],
    },
    { edgeKey: 'transactions', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.transactions.edges?.map(({ node }) => node) || [];
  }, [data?.transactions.edges]);

  return (
    <Box className='mt-[24px] mx-[24px] border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
    <ReportPreviewTable
      columns={getCustomReportTableColumns(values.columns)}
      data={rows}
      initialLoading={initialFetching}
      loading={fetching}
      pagination={true}
      persistKey="custom_report_table"
      setState={table.setState}
      state={table.state}
      total={data?.transactions.totalCount}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationClass="p-[24px]"
      className="px-[0px]"
    />
    </Box>
  );
};

export default CustomReportTable;
