import Wishlist from 'app/i18n/Wishlist';
import EntityManager from 'app/modules/components/EntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import CreateList from './components/CreateList';
import { WishlistCreateListProps } from './types';

const WishlistCreateList = (props: WishlistCreateListProps) => {
  const { wishlistId, isCreatingProduct } = props;
  const title = wishlistId
    ? Wishlist.ListView.WishlistSearch
    : Wishlist.CreateList.CraeteList;

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Wishlist} className='rounded-bl-[12px] rounded-br-[12px] !pt-[0px]'>
      <EntityManager.Title documentTitle={title} title={title} titleWrapperClass='border-t-[1px] border-grey-300'/>
      <Box data-testid="create-list-page">
        <CreateList data-testid="wishlist" isCreatingProduct={isCreatingProduct} wishlistId={wishlistId} />
      </Box>
    </EntityManager>
  );
};

export default WishlistCreateList;
