import { Box } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import Wishlist from 'app/i18n/Wishlist';
import { Button } from '@procurenetworks/procure-component-library';

type CopyToClipboardType = {
  text?: string;
  label?: string;
  isButton?: boolean;
};

const CopyToClipboard = (props: CopyToClipboardType) => {
  const { text, label, isButton } = props;

  const handleCopyClick = () => {
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          SnackbarService.show({
            message: `${label} ${Wishlist.CopyMessage}`,
          });
        })
        .catch((error) => {
          console.error('Failed to copy link:', error);
        });
    }
  };

  return (
    <>
      {isButton ? (
        <Box>
          <Button theme="info" onClick={handleCopyClick} classes="h-[44px] w-[133px] ml-[10px]">
            Share Wishlist
          </Button>
        </Box>
      ) : (
        <Box onClick={handleCopyClick} className="cursor-pointer">
          <CustomIcons.CopyIcon />
        </Box>
      )}
    </>
  );
};

export default CopyToClipboard;
