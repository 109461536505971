import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { getItemLocationItemsTypesFromTransactionTypes } from 'app/modules/reports/utils/itemtypes';
import { SortOrderEnum } from 'app/types/schema';

import { useAssetInventorySiteReportQuery } from '../../graphql/generated/assetInventorySiteReport';
import { SiteReportFormValues, SiteReportOption } from '../../types';
import { getSortsBySiteOption } from '../../utils/filter';
import { getSiteReportTableColumns } from './columns';
import { Box } from '@mui/material';
import { LocationTypeEnum } from 'app/types/schema';

interface Props {
  values: SiteReportFormValues;
}

const SiteReportTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useAssetInventorySiteReportQuery,
    {
      filters: {
        itemIds: values.skuIds,
        siteIds: values.siteIds,
        categoryIds: values.categoryIds,
        itemTypes: getItemLocationItemsTypesFromTransactionTypes(values.itemTypes),
        _or: [
          { nonZeroTotalQuantity: true },
          { recentOnly: true },
          {
            locationTypes: [LocationTypeEnum.Site],
            _or: [
              { nonZeroMinimumQuantity: true },
              { nonZeroTotalQuantityFromLocations: true }
            ]
          }
        ]
      },
      sorts: getSortsBySiteOption(values.siteReportOption),
    },
    { edgeKey: 'itemlocations', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    if (values.siteReportOption === SiteReportOption.onlyMinimumQuantityThresholdBreached) {
      return data?.itemlocations.edges?.map(({ node }) => node) || [];
    }
    return data?.itemlocations.edges?.map(({ node }) => node) || [];
  }, [data?.itemlocations.edges, values.siteReportOption]);

  const columns = useMemo(() => getSiteReportTableColumns(values.types), [values.types]);

  return (
    <Box className='mx-[24px] mt-[24px] border border-grey-300 rounded-[12px]' sx={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)' }}>
      <ReportPreviewTable
        columns={columns}
        data={rows}
        initialLoading={initialFetching}
        loading={fetching}
        pagination={true}
        persistKey="asset_inventory_report_table"
        setState={table.setState}
        state={table.state}
        total={data?.itemlocations.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        paginationClass='p-[24px]'
        className='px-[0px]'
      />
    </Box>
  );
};

export default SiteReportTable;
