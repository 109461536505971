import { RouteComponentProps } from 'app/libs/navigation';
import { withRouter } from 'app/libs/navigation';
import EditTransactions from 'app/modules/assetsInventory/Views/Confirmation/EditTransactions';

import AssetsLayout from '../../../../modules/assets/layout';

const AssetEditTransactionsPage = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;
  return (
    <AssetsLayout>
      <EditTransactions parentTransactionId={match.params.id} />
    </AssetsLayout>
  );
};

export default withRouter(AssetEditTransactionsPage);
