import { Controller, useForm } from 'react-hook-form';
import { Popover } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormDatePickerField from 'app/components/ProcureForm/FormDatePickerField';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Orders from 'app/i18n/Orders';
import EntityManager from 'app/modules/components/EntityManager';

import { UseOrderedNotePopoverStateReturnType } from '../FormOrderDetailStatus/hook/useOrderedNotePopoverState';
import { useMemo } from 'react';

interface Props {
  state: UseOrderedNotePopoverStateReturnType['state'];
  action: UseOrderedNotePopoverStateReturnType['action'];
  onSubmit: (values: any) => void;
  anchorEl: any;
  disabled?: boolean;
}
const OrderedNotePopover = (props: Props) => {
  const { state, action, onSubmit, anchorEl, disabled } = props;

  const { control, open } = state;
  const { onClose, handleSubmit } = action;
  const handleOnSubmit = useMemo(() => handleSubmit(onSubmit), [onSubmit, handleSubmit]);

  const getMinDueDate = () => {
    const today = new Date();
    return today;
  };
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id="ordered-note-popover"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '12px !important',
        },
      }}>
      <EntityManager>
        <EntityManager.Title title={Orders.OrderNotes} />
        <div className="px-[15px]">
          <div className="mt-[15px]">
            <Controller
              control={control}
              name="vendor"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  label={Orders.FormLabels.Vendor}
                  disabled={disabled}
                  formLabel={Orders.FormLabels.Vendor}
                />
              )}
            />
          </div>
          <div className="mt-[15px]">
            <Controller
              control={control}
              name="orderId"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  label={Orders.FormLabels.OrderNumber}
                  disabled={disabled}
                  formLabel={Orders.FormLabels.OrderNumber}
                />
              )}
            />
          </div>
          <div className="mt-[15px]">
            <Controller
              control={control}
              name="estimatedDeliveryDate"
              render={({ field, fieldState }) => (
                <FormDatePickerField
                  {...field}
                  label={Orders.FormLabels.EstimatedDelivery}
                  minDate={getMinDueDate().toISOString()}
                  clearable
                  disabled={disabled}
                  formLabel={Orders.FormLabels.EstimatedDelivery}
                />
              )}
            />
          </div>
          <div className="mt-[24px] flex justify-end gap-[16px]">
            <Button
              theme="success"
              onClick={handleOnSubmit}
              loading={disabled}
              classes="min-w-[94px] h-[44px]">
              {Common.Actions.Add}
            </Button>
            <div>
              <Button onClick={onClose} disabled={disabled} classes="min-w-[94px] h-[44px]">
                {Common.Actions.Cancel}
              </Button>
            </div>
          </div>
        </div>
      </EntityManager>
    </Popover>
  );
};

export default OrderedNotePopover;
