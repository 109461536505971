import { ReactNode, useCallback, useMemo } from 'react';
import { isAccessible } from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ShippingContainerSchema,
} from 'app/types/schema';

import EntityManager from '../../../../../components/EntityManager';
import { UsePackedShippingContainersTableReturnType } from '../../hook/usePackedShippingContainersTable';
import { getShipmentTableColumns } from '../../utils/columns';
import TransactionsTable from './components/TransactionsTable';
import { EntityType } from './components/TransactionsTable/utils';
import UnpackConfirmationDialog from './components/UnpackConfirmationDialog';
import useUnpackContainer from './hooks/useUnpackContainer';

interface ITableProps {
  filterNodes?: ReactNode;
  paginationWrapperClass?: string;
}
interface Props extends Partial<UsePackedShippingContainersTableReturnType>, Partial<ITableProps> {}

const PackedShipmentItemTable = (props: Props) => {
  const {
    table,
    rows,
    fetching,
    totalCount,
    filtersState,
    onNextPage,
    onPrevPage,
    onReset,
    setFilterState,
    paginationWrapperClass,
    filterNodes,
  } = props;
  const currentUser = useCurrentUser();
  const isUnPackPermission = isAccessible(
    (currentUser && currentUser.workspacePermissions) || [],
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Shipment,
  );
  const { unpackDialogState, unpacking, unpackContainerId, actions } = useUnpackContainer();

  const onConfirmUnpack = useCallback(() => {
    actions?.onConfirmUnpack(EntityType.CONTAINER);
  }, [actions]);

  const renderDetailPanel = (data: { row: ShippingContainerSchema }) => (
    <TransactionsTable row={data.row} />
  );

  const columns = useMemo(
    () => getShipmentTableColumns({ isUnPackPermission }),
    [isUnPackPermission],
  );

  return (
    <div>
      <EntityManager.Table
        ignoreRelayPagination
        pagination
        actions={table?.actions}
        columns={columns}
        data={rows}
        extraProps={{
          onUnPack: (row: any) => actions?.onUnpack(row?.containerId),
          unpackContainerId,
          unPackContainerLoading: unpacking,
          setFilterState,
          filtersState,
        }}
        getDetailPanelContent={renderDetailPanel}
        getDetailPanelHeight={() => 'auto'}
        loading={fetching}
        persistKey="transaction_history_table"
        setState={table?.setState as UseEntityManagerReturnType['table']['setState']}
        state={table?.state as UseEntityManagerReturnType['table']['state']}
        total={totalCount || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
        paginationWrapperClass={paginationWrapperClass}
        filterNodes={filterNodes}
      />
      <UnpackConfirmationDialog
        id="unpack-order-dialog"
        open={unpackDialogState.open}
        onCancelUnpack={actions?.onCancelUnpack}
        onConfirmUnpack={onConfirmUnpack}
      />
    </div>
  );
};

export default PackedShipmentItemTable;
