import { forwardRef, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { DatePicker } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';
import { Size } from 'app/types/button';
import { Maybe } from 'app/types/schema';

import FormLabel from '../FormLabel';
import { FormDatePickerFieldStyles } from './styles';

interface Props {
  label?: string;
  name?: string;
  isRequired?: boolean;
  value?: Date | null | Maybe<string> | Maybe<number>;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: Date | null) => void;
  error?: FieldError | undefined;
  disabled?: boolean;
  clearable?: boolean;
  minDate?: string;
  size?: string;
  maxDate?: string;
  formLabel?: string;
}

const FormDatePickerField = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const {
    value,
    label,
    onChange,
    error,
    isRequired,
    disabled,
    minDate,
    maxDate,
    size,
    clearable,
    formLabel,
  } = props;

  const onInputChange = useCallback(
    (date: Date | null) => {
      onChange?.(date);
    },
    [onChange],
  );

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;

  return (
    <div ref={ref}>
      {formLabel ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
      <div className={clsx(FormDatePickerFieldStyles, 'mt-[6px]')}>
        <DatePicker
          clearable={clearable}
          disabled={!!disabled}
          error={!!error}
          errorMessage={error ? (error.message as string) : ''}
          inputFormat="MM/dd/yyyy"
          labelNode={LabelNode}
          maxDate={maxDate || ''}
          minDate={minDate || ''}
          size={(size || 'medium') as Size}
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
});

export default FormDatePickerField;
