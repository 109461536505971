import useCurrentUser from '../../../modules/auth/hooks/useCurrentUser';
import { useFeatureFlag as useConfigCatFeatureFlag } from '../configCat';
import { FeatureFlagEnum } from '../type';

export const useFeatureFlag = (featureFlagKey: FeatureFlagEnum, defaultValue?: any) => {
  const { tenant } = useCurrentUser();
  const { id: tenantId, tenantCode } = tenant || {};

  const { value, loading } = useConfigCatFeatureFlag(featureFlagKey, defaultValue || false, {
    identifier: tenantId || '',
    custom: {
      tenantCode: tenantCode || '',
    },
  });

  return {
    loading,
    value,
  };
};
