import Network from 'app/i18n/Network';

import {
  composeValidators,
  dateValidator,
  emailValidator,
  numberValidator,
  textMaxLengthValidator,
  textValidator,
} from '../../../components/Form/utils/validators';
export const NETWORK_ASSET_FORM_RULES = {
  userName: {
    validate: composeValidators(
      textValidator(Network.FormValidationMessages.UserNameRequired),
      textMaxLengthValidator(Network.FormValidationMessages.UserNameMaxAllowed, 30),
    ),
  },
  email: {
    validate: composeValidators(
      textValidator(Network.FormValidationMessages.EmailRequired),
      emailValidator(Network.FormValidationMessages.EmailInvalid),
    ),
  },
  location: {
    validate: textValidator(Network.FormValidationMessages.UserNameRequired),
  },
  department: {
    validate: textValidator(Network.FormValidationMessages.EmailRequired),
  },
  startDate: {
    validate: dateValidator(Network.FormValidationMessages.startDateRequired),
  },
  endDate: {
    validate: dateValidator(Network.FormValidationMessages.endDateRequired),
  },
  numberValidation: {
    validate: numberValidator(Network.FormValidationMessages.NumberRequired)
  } 
};
