import { Button } from '@procurenetworks/procure-component-library';

import Common from '../../../../../i18n/Common';
import EntityManager from '../../../../components/EntityManager';
import { UseRestockItemStateReturnType } from '../../hook/useRestockItemState';
import { getRestockTransactionTableColumns } from './utils/columns';
import Assets from '../../../../../i18n/Assets';

interface Props {
  tableState: UseRestockItemStateReturnType['tableState'];
  onSaveTransactions: () => void;
  fetching: boolean;
}

const RestockTable = (props: Props) => {
  const { tableState, onSaveTransactions, fetching } = props;
  const { table, rows, onResetFormAndTable, totalRows, onRemoveTransaction } = tableState;
  return (
    <>
      <EntityManager.Table
        pagination
        actions={table.actions}
        columns={getRestockTransactionTableColumns()}
        data={rows}
        extraProps={{
          onRemoveTransaction,
        }}
        persistKey="restock_table"
        setState={table.setState}
        state={table.state}
        total={rows.length}
        paginationWrapperClass="p-[24px]"
      />
      <div className="flex">
        <div className="flex flex-auto justify-end gap-[16px] p-[24px]">
          <Button
            disabled={totalRows === 0}
            loading={fetching}
            theme="success"
            onClick={onSaveTransactions}
            classes="min-w-[94px] h-[44px]">
            {Assets.Actions.Restock}
          </Button>
          <Button classes="min-w-[94px] h-[44px]" disabled={fetching} onClick={onResetFormAndTable}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RestockTable;
