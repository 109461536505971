import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditPartnerTenantsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.PartnerTenantFilters>;
}>;


export type EditPartnerTenantsQuery = { __typename?: 'Query', partnerTenants: { __typename?: 'PartnerTenantConnection', totalCount: number, edges: Array<{ __typename?: 'PartnerTenantEdge', node: { __typename?: 'PartnerTenantSchema', id: string, partnerType: Types.PartnerTenantTypeEnum, status: Types.PartnerTenantStatusEnum, childTenantId: string, parentTenantId: string, allowedUserIds: Array<string>, accessibleCategoryIds: Array<string>, tenant: { __typename?: 'TenantSchema', id: string, name: string, logo?: string | null } } }> } };


export const EditPartnerTenantsDocument = gql`
    query editPartnerTenants($filters: PartnerTenantFilters) {
  partnerTenants(filters: $filters) {
    totalCount
    edges {
      node {
        id
        tenant {
          id
          name
          logo
        }
        partnerType
        status
        childTenantId
        parentTenantId
        allowedUserIds
        accessibleCategoryIds
      }
    }
  }
}
    `;

export function useEditPartnerTenantsQuery(options?: Omit<Urql.UseQueryArgs<EditPartnerTenantsQueryVariables>, 'query'>) {
  return Urql.useQuery<EditPartnerTenantsQuery>({ query: EditPartnerTenantsDocument, ...options });
};