import React from 'react';

import { getRedirectToPath } from '../../modules/errorPages/utils/redirectTo';
import Error5XX from '../../modules/errorPages/views/Error5xx';

export const Error5xxPage = () => {
  const redirectToPath = getRedirectToPath();
  return <Error5XX redirectToPath={redirectToPath || null} />;
};

export default Error5xxPage;
