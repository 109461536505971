import WishlistCreateList from 'app/modules/wishlist/views/createList';
import WishlistLayoutAuth from 'app/modules/wishlist/WishlistLayout';

function WishlistCreateListPage() {
  return (
    <WishlistLayoutAuth>
      <WishlistCreateList />
    </WishlistLayoutAuth>
  );
}

export default WishlistCreateListPage;
