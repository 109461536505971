import { Button, Dialog } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Receivables from 'app/i18n/Receivables';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

interface Props {
  count: number;
  onConfirm: () => void;
  onClose: () => void;
}

const ReceiveItemsConfirmation = (props: Props) => {
  const { count, onConfirm, onClose } = props;

  return (
    <Dialog
      open
      actions={
        <Stack justifyContent="end" spacing="space-x-16">
          <Button theme="success" onClick={onConfirm} classes="w-[94px] h-[44px]">
            {Common.Actions.Yes}
          </Button>
          <Button theme="danger" onClick={onClose} classes="w-[94px] h-[44px]">
            {Common.Actions.No}
          </Button>
        </Stack>
      }
      id="audit-create-confirmation"
      title="Receive Items"
      onClose={onClose}>
      <Stack alignItems="start" className="mt-[16px] w-[600px]" direction="col">
        <p className="text-16 -ml-[4px] font-medium text-grey-800">
          {Receivables.ConfirmationMessage.MarkItemsReceived(count)}
        </p>
        <Box className="-mx-24 mt-24 -mb-16 h-1 self-stretch bg-[#e5e5e5]" />
      </Stack>
    </Dialog>
  );
};

export default ReceiveItemsConfirmation;
