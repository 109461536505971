import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateReserveTransactionsMutationVariables = Types.Exact<{
  input: Types.UpdateReserveTransactionsInput;
}>;


export type UpdateReserveTransactionsMutation = { __typename?: 'Mutation', updateReserveTransactions: { __typename?: 'UpdateTransactionsPayload', success: boolean, reasons?: Array<string> | null, transactions?: Array<{ __typename?: 'TransactionSchema', createdAt: string, updatedAt: string, id: string, entityType: Types.TransactionEntityTypeEnum, entityId: string, type: Types.TransactionTypeEnum, subType: Types.TransactionSubTypeEnum, endDate?: string | null, startDate?: string | null, relatedTransactionId?: string | null, status: Types.TransactionStatusEnum, previousQuantity?: number | null, departmentId?: string | null, projectId?: string | null, parentTransactionId: string, meta?: { __typename?: 'TransactionMetaSchema', note?: string | null } | null }> | null } };


export const UpdateReserveTransactionsDocument = gql`
    mutation updateReserveTransactions($input: UpdateReserveTransactionsInput!) {
  updateReserveTransactions(input: $input) {
    success
    reasons
    transactions {
      createdAt
      updatedAt
      id
      entityType
      entityId
      type
      subType
      endDate
      startDate
      relatedTransactionId
      status
      previousQuantity
      departmentId
      projectId
      parentTransactionId
      meta {
        note
      }
    }
  }
}
    `;

export function useUpdateReserveTransactionsMutation() {
  return Urql.useMutation<UpdateReserveTransactionsMutation, UpdateReserveTransactionsMutationVariables>(UpdateReserveTransactionsDocument);
};