import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { processSearch } from 'app/utils/processSearch';

const useSearchSuggestion = (options?: { searchOptions: any[] }) => {
  const { searchOptions } = options || {};

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>([]);
  const [filterOptionData, setFilterOptionData] = useState<suggestionData[] | any>([]);
  useEffect(() => {
    const filteredArray = Array.isArray(searchOptions)
      ? searchOptions
          .flatMap((obj) => ({
            id: obj.carrier || '',
            carrier: obj.carrier || '',
            title: obj.orderRequest?.title || '',
            vendor: obj.vendor.companyName || '',
            trackingNumber: obj.trackingNumber || '',
            orderRequestCode: obj.orderRequest.orderRequestCode || '',
            deliverTo: obj.orderRequest.deliverTo.name || '',
            itemId: obj.id.toString(),
          }))
          .filter((obj) => {
            const objectValues = Object.values(obj);
            return (
              Object.values(obj)
                .filter((value, index) => Object.keys(obj)[index] !== 'id')
                .some((value) => value !== '') &&
              objectValues.some((value) =>
                value?.toLowerCase().includes(searchValue?.toLowerCase()),
              )
            );
          })
      : [];
    if (filteredArray.length) setFilterOptionData(filteredArray);
    const result = filteredArray.map((item) => {
      const { result, searchBy } = processSearch(
        ['carrier', 'title', 'vendor', 'trackingNumber', 'orderRequestCode', 'deliverTo'],
        searchValue,
        item,
      );

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });
    setSearchSuggestions(result || []);
  }, [searchOptions, searchValue]);
  return {
    searchSuggestions,
    setSearchValue,
    filterOptionData,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
