import { useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { stringifyVariables } from '@urql/core';
import get from 'lodash/get';
import useEvent from 'app/hooks/useEvent';
import * as Urql from 'app/libs/urql';
import { PageInfo } from 'app/types/schema';

interface Params {
  edgeKey?: string;
  pageSize?: number;
  requestPolicy?: Urql.UseQueryArgs['requestPolicy'];
  pause?: Urql.UseQueryArgs['pause'];
}

enum NetworkStatus {
  InitialFetching = 'INITIAL_FETCHING',
  Ready = 'READY',
  FetchMore = 'FETCH_MORE',
  Error = 'ERROR',
}

function usePagination<Query extends object, Variables>(
  useQuery: (
    options: Omit<Urql.UseQueryArgs<Variables>, 'query'>,
  ) => Urql.UseQueryResponse<Query, object>,
  variables: Variables,
  { edgeKey = 'items', pageSize = 25, ...params }: Params = {},
) {
  const [after, setAfter] = useState<string>();
  const [before, setBefore] = useState<string>();

  const [result, refetch] = useQuery({
    variables: { ...variables, limit: pageSize, after, before },
    ...params,
  });
  const { fetching, data, error } = result;

  const [networkStatus, setNetworkStatus] = useState(
    fetching ? NetworkStatus.InitialFetching : NetworkStatus.Ready,
  );

  const inputVariables = useMemo(
    () => stringifyVariables({ ...variables, limit: pageSize }),
    [pageSize, variables],
  );
  // @ts-ignore
  const pageInfo = get(data, edgeKey)?.pageInfo as PageInfo | undefined;

  // will reset | restore the pagination cursor if mutations variables are changed
  // useUpdateEffect is used as mutations variables changed should not run on mount.
  useUpdateEffect(() => {
    if (!fetching && pageInfo) {
      setNetworkStatus(NetworkStatus.Ready);
    } else {
      setAfter(undefined);
      setBefore(undefined);
      setNetworkStatus(NetworkStatus.InitialFetching);
    }
  }, [inputVariables, params.pause, pageSize]);

  const onNextPage = useEvent(() => {
    if (pageInfo?.nextCursor) {
      setBefore(undefined);
      setAfter(pageInfo.nextCursor);
    }
  });

  const onPrevPage = useEvent(() => {
    if (pageInfo?.prevCursor) {
      setAfter(undefined);
      setBefore(pageInfo.prevCursor);
    }
  });

  const onReset = useEvent(() => {
    setAfter(undefined);
    setBefore(undefined);
  });

  useEffect(() => {
    if (!fetching && !error) {
      setNetworkStatus(NetworkStatus.Ready);
    } else if (error) {
      setNetworkStatus(NetworkStatus.Error);
    }
  }, [fetching, data, error, pageInfo]);

  return {
    ...result,
    onReset,
    refetch,
    onPrevPage: pageInfo?.hasPrevPage ? onPrevPage : undefined,
    onNextPage: pageInfo?.hasNextPage ? onNextPage : undefined,
    /** @deprecated use onNextPage/onPrevPage instead **/
    fetchMore: pageInfo?.hasNextPage ? onNextPage : undefined,
    initialFetching: networkStatus === NetworkStatus.InitialFetching,
    fetchingMore: networkStatus === NetworkStatus.FetchMore,
  };
}

export type UsePaginationReturnType = ReturnType<typeof usePagination>;

export default usePagination;
