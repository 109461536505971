import { Controller } from 'react-hook-form';
import AssetModel from 'app/i18n/AssetModel';
import FormCompanySelect from 'app/modules/contacts/components/FormCompanySelect';

import { AssetModelFieldProps } from '../../type';

const AssetModelVendorField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="vendorId"
        render={({ field, fieldState }) => (
          <FormCompanySelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={AssetModel.FormLabels.Vendor}
          />
        )}
      />
    </div>
  );
};
export default AssetModelVendorField;
