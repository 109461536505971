import { useMemo } from 'react';
import Loader from 'app/components/Loader';
import usePagination from 'app/hooks/usePagination';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { SortOrderEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';
import { formatDate } from 'app/utils/date';

import { useAuditReportDetailsQuery } from '../../graphql/queries/generated/auditReportDetails';
import { useAuditReportTransactionsQuery } from '../../graphql/queries/generated/auditReportTransactions';
import { getAuditReportDetailsColumns } from './columns';
import { AuditReportDetailsProps } from './types';

const AuditReportDetails = (props: AuditReportDetailsProps) => {
  const { id, title } = props;
  const { table } = useReportPreviewTable();

  const [{ fetching: detailsFetching, data: detailsData }] = useAuditReportDetailsQuery({
    variables: { filters: { parentTransactionIds: [id] }, limit: 1 },
  });

  const { fetching, data, fetchMore } = usePagination(
    useAuditReportTransactionsQuery,
    {
      filters: { parentTransactionIds: [id] },
      sorts: [{ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc }],
    },
    { edgeKey: 'transactions', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.transactions.edges?.map(({ node }) => node) || [];
  }, [data?.transactions.edges]);

  const auditDetailsData = useMemo(() => {
    return detailsData?.transactions.edges?.[0]?.node;
  }, [detailsData]);

  if (detailsFetching) {
    return <Loader />;
  }

  if (!auditDetailsData) {
    return null;
  }

  return (
    <ReportContainer className="p-16" title={title} stackWrapperClass='rounded-tr-[12px] rounded-br-[12px]'>
      <Stack className="mt-16">
        <p className="w-[130px] text-13 text-grey-800">Created By:</p>
        <p className="text-13 font-semibold text-grey-800">{auditDetailsData.createdBy?.name}</p>
      </Stack>
      <Stack className="mt-16">
        <p className="w-[130px] text-13 text-grey-800">Date Created:</p>
        <p className="text-13 font-semibold text-grey-800">
          {formatDate(auditDetailsData.createdAt)}
        </p>
      </Stack>
      <ReportPreviewTable
        className="mt-48"
        columns={getAuditReportDetailsColumns()}
        data={rows}
        fetchMore={fetchMore}
        loading={fetching}
        pagination={true}
        persistKey="audit_report_details"
        setState={table.setState}
        state={table.state}
        total={data?.transactions.totalCount}
      />
    </ReportContainer>
  );
};

export default AuditReportDetails;
