import { css } from '@emotion/css';

export const tableLoadingStyles = css`
  .MuiButtonBase-root {
    pointer-events: none;
  }
`;
export const tableDropDownStyle = css`
  .MuiDataGrid-menuIcon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 11px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: var(--grey100);
  }
`;

export const dropdownHeaderStyle = css`
  .MuiDataGrid-columnHeaderTitleContainerContent {
    .table-filter {
      width: 100%;
    }
  }
  .MuiOutlinedInput-input {
    font-weight: 600;
  }
`;
export const customDropdownHeaderStyle = css`
  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 80% !important;
    height: 44px !important;
    .table-filter {
      width: 158px;
      height: 34px !important;

      .MuiAutocomplete-root {
        margin-top: 0px !important;
      }
    }
  }
  .MuiOutlinedInput-input {
    font-weight: 600;
  }
`;
