import { Controller } from 'react-hook-form';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import Inventory from '../../../../../../i18n/Inventory';
import { InventoryFormProps } from '../../types';
import { INVENTORY_FORM_RULES } from '../../utils/utils';

const InventoryTitleField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isRequired
            characterLimit={32}
            error={fieldState.error}
            isDisabled={disabled}
            label={Inventory.FormLabels.Title}
          />
        )}
        rules={INVENTORY_FORM_RULES.title}
      />
    </div>
  );
};

export default InventoryTitleField;
