
import { isAccessible } from 'app/components/AccessControl';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

export const getWishlistlayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) => {
  return [
    {
      label: 'Search',
      value: 'list-view',
      subject: AllowedPermissionsSubjectEnum.Wishlist,
      action: AllowedPermissionActionsEnum.Create,
    },
    {
      label: 'Create Wishlist',
      value: 'create-list',
      subject: AllowedPermissionsSubjectEnum.Wishlist,
      action: AllowedPermissionActionsEnum.Create,
    },
  ].filter((item) => isAccessible(permissions, item.action, item.subject));
};
