import { useCallback, useContext } from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'app/libs/navigation';
import { UnifiedHeaderContext } from '../../context';

type Props = {
  children: any;
  to: string;
  activeClassName?: string;
  className?: string;
  onClick?: () => void;
} & RouteComponentProps;

const HeaderNavLink = (props: Props) => {
  const { to, children, activeClassName, className, history, onClick } = props;
  const { onLinkButtonClick } = useContext(UnifiedHeaderContext);

  const redirect = useCallback(() => {
    history.push(to);
  }, [history, to]);

  const handleRedirection = useCallback(
    (event: any) => {
      event.preventDefault();
      onLinkButtonClick(redirect);
      onClick?.();
    },
    [history, onLinkButtonClick, to, redirect, onClick],
  );

  return (
    <NavLink
      to={to}
      className={className}
      activeClassName={activeClassName}
      onClick={handleRedirection}>
      {children}
    </NavLink>
  );
};

export default withRouter(HeaderNavLink);
