import WarningProvider from 'app/components/WarningAlert';

import InventoriesLayout from '../../modules/inventory/layout';
import RestockInventory from '../../modules/inventory/views/RestockInventory';

function RestockInventoryPage() {
  return (
    <InventoriesLayout>
      <WarningProvider>
        <RestockInventory />
      </WarningProvider>
    </InventoriesLayout>
  );
}

export default RestockInventoryPage;
