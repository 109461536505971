import {
  composeValidators,
  numberMinValidator,
  numberValidator,
  textValidator,
  websiteValidator,
} from 'app/components/Form/utils/validators';
import Orders from 'app/i18n/Orders';
import { upcCodeOrAsinCodeValidator } from 'app/modules/assetsInventory/utils/rules';
import { CreateMediaInput, Maybe } from 'app/types/schema';

export const ORDER_REQUEST_FORM_RULES = {
  skuValidator: {
    validate: textValidator(Orders.FormValidationMessages.ItemInStockRequired),
  },

  nonStockedField(validateAllEmpty?: () => string | boolean) {
    return {
      validate: validateAllEmpty,
    };
  },
  upcCode: {
    validate: upcCodeOrAsinCodeValidator,
  },
  website(validateAllEmpty?: () => string | boolean) {
    return {
      validate: validateAllEmpty,
    };
  },
  description(validateAllEmpty?: () => string | boolean) {
    return {
      validate: validateAllEmpty,
    };
  },
  organization: {
    validate: textValidator(Orders.FormValidationMessages.OrganizationRequired),
  },
  image(validateAllEmpty?: () => string | boolean) {
    return {
      validate: validateAllEmpty,
    };
  },
  quantity: {
    validate: composeValidators(
      textValidator(Orders.FormValidationMessages.QuantityInvalid),
      numberValidator(Orders.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Orders.FormValidationMessages.QuantityInvalid, 1),
    ),
  },
  cost: {
    validate: composeValidators(
      numberValidator(Orders.FormValidationMessages.CostInvalid),
      numberMinValidator(Orders.FormValidationMessages.QuantityInvalid, 0),
    ),
  },

  itemType: {
    validate: textValidator(Orders.FormValidationMessages.SelectAnItem),
  },
};
