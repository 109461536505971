import { Controller } from 'react-hook-form';
import FormTextArea from 'app/components/Form/FormTextArea';

import AssetModel from '../../../../../../i18n/AssetModel';
import { AssetModelFieldProps } from '../../type';

const AssetModelDescriptionField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <FormTextArea
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={AssetModel.FormLabels.Description}
          />
        )}
      />
    </div>
  );
};

export default AssetModelDescriptionField;
