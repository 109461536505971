import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditLocationDataFragmentDoc } from '../../fragments/generated/editLocationData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditLocationDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
}>;


export type EditLocationDataQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, siteId: string, locationCode?: string | null, formattedDescription?: string | null } }> } };


export const EditLocationDataDocument = gql`
    query editLocationData($filters: LocationFilters) {
  locations(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditLocationData
      }
    }
  }
}
    ${EditLocationDataFragmentDoc}`;

export function useEditLocationDataQuery(options?: Omit<Urql.UseQueryArgs<EditLocationDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditLocationDataQuery>({ query: EditLocationDataDocument, ...options });
};