import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { LocationTypeEnum, SortOrderEnum } from 'app/types/schema';

import { useFormSiteSelectQuery } from '../FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { LocationSelectorOption, LocationSelectorProps } from './types';
import { adaptLocationToOption } from './utils';

const getOptionLabel = (option: any) => option.name;
const getOptionValue = (option: LocationSelectorOption) => option.id;

type Props = LocationSelectorProps & { error?: Partial<FieldError> };
const LocationSelector = (props: Props) => {
  const {
    className = 'mt-8',
    disabled,
    placeholder,
    value,
    onBlur,
    types,
    onChange,
    selectedLocationValue,
    locationIds,
    size = 'medium',
    controllerHeight = '48px',
    inputControlPadding = '2.5px 4px 2.5px 3px',
    popperProps,
    error,
    name
  } = props;

  const [selectedValue, setSelectedValue] = useState<LocationSelectorOption | null>(null);

  useEffect(() => {
    setSelectedValue(null);
    onChange?.('', null);
  }, [value]);
  const [{ fetching: isLoading, data: optionsData }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        siteId: value,
        types: types || [LocationTypeEnum.Location],
        locationIds: locationIds,
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
    pause: !Boolean(value),
  });

  const options = useMemo<any>(
    () => adaptLocationToOption(optionsData?.locations.edges || [], value),
    [optionsData?.locations.edges],
  );

  useEffect(() => {
    const matchedOption = options.find(
      (option: LocationSelectorOption) => option.id === selectedLocationValue,
    );
    if (matchedOption) {
      setSelectedValue(matchedOption);
    } else {
      setSelectedValue(null);
    }
  }, [options, selectedLocationValue]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: any) => {
      if (option) {
        setSelectedValue(option);
        onChange?.(option.id, option);
      } else {
        setSelectedValue(null);
        onChange?.('', null);
      }
    },
    [onChange],
  );

  return (
    <Autocomplete
      className={cx('!h-[48px] rounded !rounded-[8px] disabled:bg-grey-500', className)}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      label={''}
      loading={isLoading}
      options={options}
      popperProps={popperProps}
      renderInput={(params: any) => (
        <TextField
          name={name}
          {...params}
          classes="disabled:!rounded-[8px]"
          error={!!error}
          errorMessage={error?.message}
          placeholder={placeholder}
          onKeyDown={(e: any) => {
            e.stopPropagation();
          }}
        />
      )}
      size={size}
      sx={{
        '& .MuiFormControl-root': {
          '& .MuiOutlinedInput-root': {
            height: `${controllerHeight} !important`,
            '& .MuiAutocomplete-input': {
              padding: `${inputControlPadding && inputControlPadding} !important`,
            },
          },
        },
      }}
      value={selectedValue as any}
      onBlur={onBlur}
      onChange={onValueChange}
    />
  );
};

export default LocationSelector;
