import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type UserTableRowFragment = { __typename?: 'UserSchema', id: string, profilePicture?: string | null, firstName: string, lastName?: string | null, emailId: string, status: Types.UserStatusEnum, lastLogin?: string | null, roles: Array<{ __typename?: 'RoleSchema', id: string, name: string }> };

export const UserTableRowFragmentDoc = gql`
    fragment UserTableRow on UserSchema {
  id
  profilePicture
  firstName
  lastName
  emailId
  status
  lastLogin
  roles {
    id
    name
  }
}
    `;