import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompaniesFilterQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CompanyFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CompaniesFilterQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node: { __typename?: 'CompanySchema', id: string, companyName: string } }> } };


export const CompaniesFilterDocument = gql`
    query companiesFilter($filters: CompanyFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  companies(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        companyName
      }
    }
  }
}
    `;

export function useCompaniesFilterQuery(options?: Omit<Urql.UseQueryArgs<CompaniesFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<CompaniesFilterQuery>({ query: CompaniesFilterDocument, ...options });
};