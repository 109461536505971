import { createContext, memo, ReactNode } from 'react';
import {
  BellNotificationByUserSubscription,
  useBellNotificationByUserSubscription,
} from 'app/modules/bellNotifications/graphql/queries/generated/notificationPubsubSubscription';
import { BellNotificationSubscriptionOperationEnum } from 'app/types/schema';

export const NotificationContext = createContext<{
  bellNotificationUpdatedSubscription: BellNotificationByUserSubscription | undefined;
  bellNotificationCreatedSubscription: BellNotificationByUserSubscription | undefined;
}>({
  bellNotificationUpdatedSubscription: undefined,
  bellNotificationCreatedSubscription: undefined,
});

type Props = {
  children: ReactNode;
  isLoggedIn: boolean;
  userId: string;
};

const NotificationContextProvider = ({ isLoggedIn, userId, children }: Props) => {
  const [{ data: bellNotificationUpdatedSubscription }] = useBellNotificationByUserSubscription({
    pause: !isLoggedIn,
    variables: {
      data: {
        userId: userId ?? '',
        operations: [BellNotificationSubscriptionOperationEnum.BellNotificationUpdated],
      },
    },
  });

  const [{ data: bellNotificationCreatedSubscription }] = useBellNotificationByUserSubscription({
    pause: !isLoggedIn,
    variables: {
      data: {
        userId: userId ?? '',
        operations: [BellNotificationSubscriptionOperationEnum.BellNotificationCreated],
      },
    },
  });

  return (
    <NotificationContext.Provider
      value={{ bellNotificationUpdatedSubscription, bellNotificationCreatedSubscription }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default memo(NotificationContextProvider);
