import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import FormLabel from '../../../../components/Form/FormLabel';
import {
  ItemStatusEnum,
  LocationStatusEnum,
  LocationTypeEnum,
  SortOrderEnum,
} from '../../../../types/schema';
import { useFormSiteSelectQuery } from '../FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { LocationSelectorOption } from '../LocationSelector/types';
import { useMostRecentLocationsQuery } from './graphql/generated/mostRecentLocations';
import { adaptItemLocationAndLocationToOptionGroup } from './utils';

interface Props {
  className?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  siteId?: string | null;
  itemInStockId?: string | null;
  onBlur?: () => void;
  onChange?: (value: string | undefined, option?: any) => void;
  error?: FieldError;
  placeholder?: string;
  types?: LocationTypeEnum[];
  locationIds?: string[];
  disabledMostRecent?: boolean;
  name?: string;
}

const getOptionLabel = (option: LocationSelectorOption) => option.name;
const getOptionValue = (option: LocationSelectorOption) => option.id;

const formatGroupLabel = (data: any) => (
  <div className="text-[12px] font-semibold capitalize text-[#595959]">{data.label}</div>
);

const formatOptionLabel = (data: any) => <div className="ml-3">{data.name}</div>;

const FormToLocationSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    error,
    label,
    isRequired,
    onChange,
    siteId,
    disabled,
    placeholder,
    itemInStockId,
    onBlur,
    locationIds,
    disabledMostRecent,
    name
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<LocationSelectorOption | null>(null);

  useEffect(() => {
    setSelectedValue(null);
    onChange?.('', null);
  }, [siteId]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: LocationSelectorOption) => {
      if (option) {
        setSelectedValue(option);
        onChange?.(option.id, option);
      } else {
        setSelectedValue(null);
        onChange?.('', null);
      }
    },
    [onChange],
  );

  const [{ fetching, data: locationData }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        siteId,
        locationIds,
        types: [],
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
    pause: !Boolean(siteId),
  });

  const [{ fetching: mostRecentLocationFetching, data: mostRecentLocations }] =
    useMostRecentLocationsQuery({
      variables: {
        filters: {
          _or: [{ recentOnly: true }, { nonZeroTotalQuantity: true }],
          siteIds: siteId ? [siteId] : [],
          itemIds: itemInStockId ? [itemInStockId] : [],
          locationIds,
          itemStatuses: [ItemStatusEnum.Active],
          locationStatuses: [LocationStatusEnum.Active],
        },
        sorts: [
          {
            sortField: 'outOfStockAt',
            sortOrder: SortOrderEnum.Desc,
          },
        ],
      },
      pause: !Boolean(siteId) || !Boolean(itemInStockId) || Boolean(disabledMostRecent),
    });

  let { options, isGroupOptions } = useMemo(() => {
    const mostRecentItemLocationsEdges = !disabledMostRecent
      ? mostRecentLocations?.itemlocations?.edges || []
      : [];

    return adaptItemLocationAndLocationToOptionGroup(
      locationData?.locations?.edges || [],
      mostRecentItemLocationsEdges,
      siteId,
    );
  }, [
    locationData?.locations?.edges,
    mostRecentLocations?.itemlocations?.edges,
    disabledMostRecent,
  ]);

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        className={cx(
          'mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none',
          className,
        )}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        groupBy={(isGroupOptions && ((option: any) => option?.group)) || undefined}
        label={''}
        loading={fetching || mostRecentLocationFetching}
        options={options}
        renderInput={(params: any) => (
          <TextField
            name={name}
            {...params}
            value={selectedValue?.name}
          />
        )}
        value={selectedValue}
        onBlur={onBlur}
        onChange={onValueChange}
        onInputChange={onInputValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormToLocationSelect;
