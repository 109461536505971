import React, { useEffect } from 'react';
import { Button, Content, CustomIcons } from '@procurenetworks/procure-component-library';
import { useAccessControl } from 'app/components/AccessControl';
import Network from 'app/i18n/Network';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import DeleteConfirmationModal from 'app/modules/components/EntityManager/DeleteConfirmationModal';
import Stack from 'app/ui-components/Stack';

import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  NetworkAssetStatusEnum,
} from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import NetworkAssetTable from './components/NetworkAssetTable';
import OperatingSystems from './components/OperatingSystems';
import SelectDisplay from './components/SelectDisplay';
import useNetworkAssetsState from './hook/useNetworkAssetsState';
import useOperatingSystemData from './hook/useOperatingSystemData';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { Box, InputAdornment } from '@mui/material';

const NetworkAssets = (props: RouteComponentProps) => {
  const { history } = props;
  const {
    filters,
    table,
    networkAssetTableState,
    search,
    display,
    memoizedSetDisplay,
    showDeleteConfirmation,
    onCancelDelete,
    onDelete,
    onConfirmDelete,
  } = useNetworkAssetsState();

  const { fetchingOperatingSystems, operatingSystems } = useOperatingSystemData();
  const { workspacePermissions } = useCurrentUser();

  useEffect(() => {
    filters.setState({
      ...filters.state,
      statuses: [NetworkAssetStatusEnum.Active],
    });
  }, []);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion(
    [NetworkAssetStatusEnum.Active],
    false,
  );

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Asset} className="pt-0">
      <EntityManager.Title
        documentTitle={Network.NetworkAssets}
        title={Network.NetworkAssets}
        hasTopDivider
      />
      <Content>
        {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT !== 'production' && canDelete ? (
          <Stack className="mt-2" justifyContent="end">
            <Button classes="!ml-[4px]" theme="danger" onClick={onDelete}>
              {Network.Action.DeleteAll}
            </Button>
          </Stack>
        ) : null}
        <OperatingSystems
          fetching={fetchingOperatingSystems}
          history={history}
          operatingSystems={operatingSystems}
        />
        <Box
          className="mt-[32px] rounded-[12px] border border-grey-300"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <NetworkAssetTable
            persistKey={'network_asset_table'}
            clickable
            display={display}
            filters={filters}
            history={history}
            table={table}
            tableState={networkAssetTableState}
            filterNodes={
              <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
                <EntityManager.Search
                  {...search}
                  isDropdown
                  suggestionData={searchSuggestions || []}
                  onChangeSearch={setSearchValue}
                  placeholder="Search"
                  startAdornment={
                    <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                      <CustomIcons.SearchIcon />
                    </InputAdornment>
                  }
                />
                <SelectDisplay display={display} setDisplay={memoizedSetDisplay} />
              </Box>
            }
          />
        </Box>
      </Content>
      <DeleteConfirmationModal
        open={showDeleteConfirmation}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
      />
    </EntityManager>
  );
};

export default withRouter(NetworkAssets);
