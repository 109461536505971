import { omit } from 'lodash';

import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  regexValidator,
  textMaxLengthValidator,
  textValidator,
} from '../../../../components/Form/utils/validators';
import Assets from '../../../../i18n/Assets';
import { ItemTypeEnum } from '../../../../types/schema';
import { upcCodeOrAsinCodeValidator } from '../../../assetsInventory/utils/rules';

export const ASSET_ITEM_TYPES = [
  {
    label: 'New Asset',
    value: ItemTypeEnum.Asset,
  },
  {
    label: 'New Kit',
    value: ItemTypeEnum.AssetKit,
  },
];

export const ASSET_FORM_RULES = {
  type: {
    validate: textValidator(Assets.FormValidationMessages.TypeRequired),
  },
  upc: {
    validate: upcCodeOrAsinCodeValidator,
  },
  categoryId: {
    validate: textValidator(Assets.FormValidationMessages.CategoryRequired),
  },
  mName: {
    validate: textValidator(Assets.FormValidationMessages.ModelRequired),
  },
  title: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.TitleRequired),
      textMaxLengthValidator(Assets.FormValidationMessages.TitleRequired, 32),
    ),
  },
  destinationSiteId: {
    validate: textValidator(Assets.FormValidationMessages.SiteRequired),
  },
  destinationLocationId: {
    validate: textValidator(Assets.FormValidationMessages.SiteLocationRequired),
  },
  unitCost: {
    validate: composeValidators(
      numberValidator(Assets.FormValidationMessages.CostInvalid),
      numberMinValidator(Assets.FormValidationMessages.CostInvalid, 0),
    ),
  },
  costOverride: {
    validate: composeValidators(
      numberValidator(Assets.FormValidationMessages.CostOverrideInvalid),
      regexValidator(Assets.FormValidationMessages.CostOverrideInvalid, /^\d{1,3}(\.\d{1,2})?$/),
      numberMinValidator(Assets.FormValidationMessages.CostOverrideInvalid, 0),
      numberMaxValidator(Assets.FormValidationMessages.CostOverrideInvalid, 999.99),
    ),
  },
  quantity: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberValidator(Assets.FormValidationMessages.QuantityInvalid),
    ),
  },
  quantityForSerialNumber: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberValidator(Assets.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Assets.FormValidationMessages.QuantityInvalid, 1),
      numberMaxValidator(Assets.FormValidationMessages.SerialNumberQuantityInvalid, 1),
    ),
  },
};
