import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import noop from 'lodash/noop';

import { SnackbarService } from '../Snackbar';
import { WarningContentProps, WarningContextType, WarningProviderProps } from './types';
import WarningAlert from './WarningAlert';

const defaultValue: WarningContextType = {
  showWarning: noop,
};
export let WarningService = defaultValue;
const WarningContext = createContext<WarningContextType>(defaultValue);

const WarningProvider = ({ children }: WarningProviderProps) => {
  const [warning, setWarning] = useState<WarningContentProps | null>(null);

  const onClose = useCallback(() => {
    warning?.onCancel?.();
    setWarning(null);
  }, [warning, setWarning]);

  const onConfirm = useCallback(() => {
    warning?.onConfirm?.();
    setWarning(null);
  }, [warning, setWarning]);

  const value = useMemo<WarningContextType>(
    () => ({
      showWarning: (params) => {
        SnackbarService.clear();
        setWarning(params);
      },
    }),
    [setWarning],
  );

  useEffect(() => {
    WarningService = value;

    return () => {
      WarningService = defaultValue;
    };
  }, [value]);

  return (
    <WarningContext.Provider value={value}>
      {children}

      <WarningAlert
        message={warning ? warning.message : ''}
        className={warning?.className}
        open={Boolean(warning)}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </WarningContext.Provider>
  );
};

export default WarningProvider;
