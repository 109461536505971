const Orders = {
  RequestItem: 'Request Item',
  Items: 'Items',
  ItemsInStock: 'Items In Stock',
  NonStockedItems: 'Non-Stocked Items',
  SelectItemsToReturn: 'Select Items To Return',
  BulkOrderRequest: 'Bulk Order Request',
  ItemRequested: 'Items Requested',
  DeliveryDetails: 'Delivery Details',
  OrderRequestDocumentTitle: 'Order Request',
  OrderHistoryDocumentTitle: 'Order History',
  OrderDetailDocumentTitle: 'Order Details',
  UpdateItem: 'Update Item',
  OrderDetails: 'Order Details',
  OrderHistory: 'Order History',
  OrderNotes: 'Order Notes',
  FormLabels: {
    ItemRequestStep: 'Order Request',
    ItemDeliveryStep: 'Delivery Details',
    ItemsInStock: 'Items In stock',
    UpcCode: 'UPC or ASIN Code',
    Website: 'Website',
    Description: 'Description',
    AddImage: 'Add Image',
    Quantity: 'Quantity',
    CostPerUnit: 'Cost Per Unit',
    CostEach: 'Cost Each',
    Project: 'Project',
    OrderDate: 'Order Date',
    OrderTitle: 'Order Title',
    EnterOrderTitle: 'Enter Order Title',
    Name: 'Name',
    Email: 'Email',
    DueDate: 'Due Date',
    ShipTo: 'Ship To',
    Department: 'Department',
    OrderId: 'Order ID',
    DeliverTo: 'Deliver To',
    BillTo: 'Bill To',
    SelectOrganization: 'Select Organization',
    Organization: 'Organization',
    Status: 'Status',
    Vendor: 'Vendor',
    OrderNumber: 'Order Number',
    EstimatedDelivery: 'Estimated Delivery',
    Assets: 'Assets',
    Inventory: 'Inventory',
    NonStockItems: 'Non-Stocked Items',
    Image: 'Image',
    ItemDescription: 'Item Description',
    Title: 'Title',
    Requester: 'Requester',
  },
  FormPlaceholders: {
    ItemsInStock: 'Search Item',
  },
  FormValidationMessages: {
    MissingNonStockedFieldValues:
      'Please enter data into at least one field to request a non-stocked item.',
    ItemInStockRequired: 'Please enter a valid SKU.',
    ItemInStockInvalid: 'Yikes! SKU not found. Please enter a valid SKU.',
    UpcCodeInvalid: 'Please enter a valid UPC or ASIN number between 10 and 14 digits.',
    WebsiteInvalid: 'Please enter a valid Website.',
    DescriptionInvalid: 'Please enter a valid Description.',
    QuantityInvalid: 'Please enter a valid Quantity.',
    CostInvalid: 'Please enter a valid Unit Cost.',
    ImageInvalid: 'Please enter a valid Image.',
    DueDateRequired: 'Please enter a Due Date.',
    ShipToRequired: 'Please select a Ship To site.',
    BillToRequired: 'Please select a Bill To site.',
    BulkOrderItemRequired: 'Please enter data into required fields or remove any empty rows.',
    OrganizationRequired: 'Please select an Organization.',
    switchOrganizationWarning:
      'If you switch the organization, items in your cart will be cleared. Do you want to switch organizations and clear your cart?',
    ImageSizeInvalid: (size: number) => `File size must be less than ${size}MB.`,
    UnSavedChangesMessage: 'Unsaved changes will be lost. Would you like to proceed?',
    confirmDelete: `Are you sure you want to delete this order request?`,
    confirmBulkDelete: `Are you sure you want to delete the selected order request(s)?`,
    DeliverToIdRequired: 'Please select a Deliver To user.',
    QuantityMissing: 'Please enter a quantity.',
    DuplicateOrderItemInBulkOrderRequest:
      'This item is a duplicate. Would you like to add this quantity to your previous entry?',
    DuplicateOrderItemInNormalOrderRequest: (quantity: number) =>
      `This item exists in your cart. The quantity will be updated to ${quantity}.`,
    DuplicateOrderItem: (sku: string, quantity: number) =>
      `This item ${sku} currently exists in your order. The quantity will be updated to ${quantity}.`,
    NoReturnItemSelected: 'Please select items to return',
    InvalidQuantityInReturnOrder:
      'Please review the quantity. The return quantity must be equal to or less than the quantity you received.',
    ExternalOrderReOrderFailed:
      'This re-order cannot be completed as your organization is no longer a partner with the selected organization.',
    QuantityRequiredAndShouldBeNonZero:
      'Quantity is a required field and must be greater than zero.',
    NoRecordFound: 'No Record Found',
    SelectAnItem: 'Please select an item.',
  },
  SuccessMessages: {
    ItemAdded: 'Item has been added successfully.',
    OrderCreated: 'Your order request has been successfully created.',
    OrderCanceled: 'Order successfully canceled.',
    OrderDeleted: 'Order successfully deleted.',
    BulkOrderDeleted: 'Order request(s) successfully deleted.',
    CartCleared: 'All items have been removed from your cart.',
    OrderStatusUpdated: 'Order Request Item status updated successfully.',
    OrderUpdated: 'Order Request updated successfully.',
    OrderReturned: 'Order successfully returned!',
    ResendReturnEmailSent: 'Email successfully sent!',
    OrderReturnCancelled: 'This order return has been cancelled at your request.',
  },
  ErrorMessages: {
    SKUNotRequestable: (items: string[]) =>
      `${items.length > 1 ? 'These items' : 'This item'} ${items.join(', ')} ${
        items.length > 1 ? 'are' : 'is'
      } no longer available to be requested.`,
    OrderNewItemEmpty: 'Please manually enter a SKU or UPC code or select an item from the drop down.'
  },
  Actions: {
    AddItem: 'Add Item',
    AddItemRow: 'Add',
    SubmitOrder: 'Submit Order',
    Previous: 'Previous',
    CreateBulkOrder: 'Create Bulk Order',
    Print: 'Print',
    UpdateOrder: 'Update Order',
    CompleteReturn: 'Complete Return',
    ReturnItems: 'Return Items',
    EditOrder: 'Edit Order',
    RequestAgain: 'Request Again',
    ResendReturnEmail: 'Resend Return Email',
    PrintReturnLabel: 'Print Return Label',
    CancelReturn: 'Cancel Return',
    Remove: 'Remove'
  },
};

export default Orders;
