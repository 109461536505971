import {
  composeValidators,
  textMaxLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';

import Inventory from '../../../../../i18n/Inventory';
import {
  availableQuantityAndTotalQuantityValidator,
  itemInStockValidator,
} from '../../../utils/rules';

export const ConsumedReason = 'Consumed';
export const DamagedReason = 'Damaged';
export const DonatedReason = 'Donated';
export const SoldReason = 'Sold';
export const OtherReason = 'Other';

export const REASON_ITEMS = [
  {
    label: ConsumedReason,
    value: ConsumedReason,
  },
  {
    label: DamagedReason,
    value: DamagedReason,
  },
  {
    label: DonatedReason,
    value: DonatedReason,
  },
  {
    label: SoldReason,
    value: SoldReason,
  },
  {
    label: OtherReason,
    value: OtherReason,
  },
];

export const REMOVE_FORM_RULES = {
  itemInStockSearch: {
    validate: textValidator(Inventory.FormValidationMessages.ItemInStockSearchRequired),
  },
  itemInStock: itemInStockValidator,
  sourceId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteRequired),
  },
  sourceLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteLocationRequired),
  },
  reason: {
    validate: textValidator(Inventory.FormValidationMessages.ReasonRequired),
  },
  reasonMessage: {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.ReasonMessageRequired),
      textMaxLengthValidator(Inventory.FormValidationMessages.ReasonMaxLength, 32),
    ),
  },
  quantity: availableQuantityAndTotalQuantityValidator,
};
