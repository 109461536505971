import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { debounce, get, uniqBy } from 'lodash';

import { AsyncMultiSelectProps, AsyncSelectOption } from './types';
import { Chip } from '@mui/material';

const getOptionLabel = (option: AsyncSelectOption) => option.label;

const EMPTY_ARRAY: never[] = [];

const AsyncMultiSelect = (props: AsyncMultiSelectProps) => {
  const {
    queryVariables,
    useSelectedValue,
    useSelectQuery,
    placeholder = '',
    value: valueProp,
    onBlur,
    onChange,
    disabled,
    autoFocus,
    paginated,
    enabledAllOption,
    helperText,
    disableOptionCondition = () => false,
    name,
    onClick
  } = props;

  const value = valueProp || EMPTY_ARRAY;
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<AsyncSelectOption[]>([]);
  const [activePage, setActivePage] = useState(0);
  const isQueryVariablesChange = JSON.stringify(queryVariables);
  useEffect(() => {
    setActivePage(0);
  }, [isQueryVariablesChange]);

  const { isLoading, options, onNextPage } = useSelectQuery({
    inputValue: searchValue,
    activePage,
    ...queryVariables,
    value,
  });

  const { isDisabled, selectedValues } = useSelectedValue({
    value,
    pause: !value.length || Boolean(value.length && selectedValue.length),
    tenantIds: get(queryVariables, 'tenantIds', []),
    options: options,
  });

  useEffect(() => {
    if (value.length === 0) {
      setSelectedValue([]);
    }
  }, [value]);

  useEffect(() => {
    if (selectedValues) setSelectedValue(selectedValues);
  }, [isDisabled]);

  useEffect(() => {
    if (
      !selectedValue.every(({ value: selectedOption }) => value.includes(selectedOption)) &&
      value.length &&
      selectedValue.length
    ) {
      setSelectedValue(
        selectedValue.filter(({ value: selectedOption }) => value.includes(selectedOption)),
      );
    }
  }, [value, selectedValue]);

  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;

      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !isLoading) {
        onNextPage();
        setActivePage(activePage + 1);
      }
    },
    [onNextPage, isLoading, setActivePage, activePage],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, selectedOptions: AsyncSelectOption[]) => {
      let _options: AsyncSelectOption[];
      if (enabledAllOption && Boolean(selectedOptions.find((option) => option.value === 'all'))) {
        _options = uniqBy(
          options.filter((option) => option.value !== 'all'),
          (role) => role.value,
        ) as AsyncSelectOption[];
      } else {
        _options = uniqBy(selectedOptions, (role) => role.value) as AsyncSelectOption[];
      }

      setSelectedValue(_options as AsyncSelectOption[]);
      onChange?.(
        _options.map(({ value }) => value),
        _options,
        selectedValues
      );
    },
    [onChange, enabledAllOption, options],
  );

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    [],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    onSearch(value);
  };

  return (
    <Autocomplete
      multiple
      ListboxProps={
        paginated ? {
          onScroll: onScroll,
          role: 'list-box',
        } : {}
      }
      className="mt-8 rounded"
      disabled={isDisabled || disabled}
      getOptionLabel={getOptionLabel}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option?.value === value?.value;
      }}
      label={''}
      loading={isLoading}
      options={options}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          autoFocus={autoFocus}
          placeholder={!selectedValue?.length ? placeholder : ''}
          helperText={helperText}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            disabled={disableOptionCondition(option)}
            onDelete={!disabled ? getTagProps({ index }).onDelete : undefined}
          />
        ))
      }
      renderOption={(props: any, option) => {
        const showLoadingOption =
          paginated && !!(options.indexOf(option) === options.length - 1 && onNextPage);
        if (disableOptionCondition(option)) return null;
        return (
          <div {...props} key={option.value}>
            <div>
              <div>{option.label}</div>
              {showLoadingOption ? (
                <div className="d-block mt-[10px] w-[100%]">Loading....</div>
              ) : null}
            </div>
          </div>
        );
      }}
      value={selectedValue}
      onBlur={onBlur}
      onChange={onValueChange}
      onInputChange={onInputValueChange}
    />
  );
};

export default AsyncMultiSelect;
