import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateRemoveTransactionsMutationVariables = Types.Exact<{
  input: Types.CreateRemoveTransactionsInput;
}>;


export type CreateRemoveTransactionsMutation = { __typename?: 'Mutation', createRemoveTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', id: string, parentTransactionId: string }> | null } };


export const CreateRemoveTransactionsDocument = gql`
    mutation createRemoveTransactions($input: CreateRemoveTransactionsInput!) {
  createRemoveTransactions(input: $input) {
    success
    transactions {
      id
      parentTransactionId
    }
  }
}
    `;

export function useCreateRemoveTransactionsMutation() {
  return Urql.useMutation<CreateRemoveTransactionsMutation, CreateRemoveTransactionsMutationVariables>(CreateRemoveTransactionsDocument);
};