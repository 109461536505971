import AffiliateMerchants from 'app/modules/shop/view/admin/AffiliateMerchants';

import ShopLayout from '../../../modules/shop/layout';

function AffiliateConfigurationPage() {
  return (
    <ShopLayout>
      <AffiliateMerchants />
    </ShopLayout>
  );
}

export default AffiliateConfigurationPage;
