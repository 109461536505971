import {
  arrayValidator,
  composeValidators,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Category from 'app/i18n/Category';

export const CATEGORY_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Category.FormValidationMessages.NameRequired),
      textMinLengthValidator(Category.FormValidationMessages.NameRequired, 3),
      textMaxLengthValidator(Category.FormValidationMessages.NameRequired, 32),
    ),
  },
  description: {
    validate: textMaxLengthValidator(Category.FormValidationMessages.DescriptionMaxLength, 500),
  },
  categoryManagerIds: {
    validate: arrayValidator(Category.FormValidationMessages.CategoryManagerLength),
  },
};
