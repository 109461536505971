import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import Category from 'app/i18n/Category';
import Common from 'app/i18n/Common';
import { RoleTypeFilterEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { CategoryFormProps } from './types';
import { CATEGORY_FORM_RULES as rules } from './utils';

function AddCategoryForm(props: CategoryFormProps) {
  const { defaultValues, disabled, onCancel, onSubmit } = props;

  const { control, handleSubmit } = useForm({ defaultValues });
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const categoryValues = {
          ...values,
          name: removeMultipleSpaces(values.name),
          description: removeExtraSpacesAndNewlines(values?.description || ''),
          formattedDescription: values?.description || '',
          categoryCode: removeMultipleSpaces(values?.categoryCode || ''),
        };
        onSubmit(categoryValues);
      }),
    [handleSubmit, onSubmit],
  );

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              characterLimit={32}
              error={fieldState.error}
              label={Category.FormLabels.CategoryName}
            />
          )}
          rules={rules.name}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FormTextArea
              {...field}
              characterLimit={500}
              error={fieldState.error}
              label={Category.FormLabels.Description}
            />
          )}
          rules={rules.description}
        />
        <Controller
          control={control}
          name="categoryManagerIds"
          render={({ field, fieldState }) => (
            <FormUsersSelect
              {...field}
              isRequired
              error={fieldState.error}
              label={Category.FormLabels.CategoryManager}
              queryVariables={{ roleTypes: [RoleTypeFilterEnum.CategoryManager] }}
            />
          )}
          rules={rules.categoryManagerIds}
        />
        <Controller
          control={control}
          name="categoryCode"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              characterLimit={30}
              error={fieldState.error}
              label={Category.FormLabels.CategoryCode}
            />
          )}
        />
        <Box className="flex justify-end space-x-[16px]">
          <Button
            disabled={disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default AddCategoryForm;
