import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';

import FormCheckBoxInput from '../../../../../../components/ProcureForm/FormCheckBoxInput';
import { AssetFieldProps } from '../../types';

const AssetDisplayInOrderField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;

  const { control, isItemTypeAsset } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="pickableThroughOrderRequest"
        render={({ field, fieldState }) => (
          <FormCheckBoxInput
            {...field}
            disabled={disabled}
            label={Assets.FormLabels.DisplayInOrder}
            classNames='!p-0 mr-[8px]' 
          />
        )}
      />
    </div>
  );
};

export default AssetDisplayInOrderField;
