import { forwardRef, HTMLInputTypeAttribute, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import { Input } from '@mui/material';
import Common from 'app/i18n/Common';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  label?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string | number>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError;
  serverError?: boolean;
  autoFocus?: boolean;
  response?: string;
}

const FormInputSignUp = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    error,
    name,
    value,
    onBlur,
    onChange,
    type = 'text',
    label,
    serverError,
    autoFocus,
    response,
    isRequired,
  } = props;

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      onChange?.(text);
    },
    [onChange],
  );

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {label}
        </FormLabel>
      ) : null}
      <Input
        autoFocus={autoFocus || false}
        className="text-32 mt-6 h-[50px] w-[100%] border border-grey-500 text-center placeholder:text-grey-600 focus:border-blue-900 focus:outline-none"  
        name={name}
        placeholder={Common.Actions.Enter}
        type={type}
        value={value || ''}
        onBlur={onBlur}
        onChange={onChangeText}
        size='small'
      />
      <FormError error={error} />
      {serverError && <p className="mt-2 text-[12px] text-[red]">{response}</p>}
    </Box>
  );
});

export default FormInputSignUp;
