import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type UserProfileFragment = { __typename?: 'UserSchema', id: string, firstName: string, lastName?: string | null, emailId: string, name: string, tenantId: string, isSuperAdmin?: boolean | null, profilePicture?: string | null, isExternalUser?: boolean | null, workspacePermissions: Array<{ __typename?: 'UserWorkspacePermission', action: Types.AllowedPermissionActionsEnum, subject: Types.AllowedPermissionsSubjectEnum }>, roles: Array<{ __typename?: 'RoleSchema', id: string, name: string }>, defaultSite?: { __typename?: 'LocationSchema', id: string, name: string, status: Types.LocationStatusEnum } | null, tenant?: { __typename?: 'TenantSchema', id: string, name: string, tenantCode: string, moduleVisibilityConfig?: { __typename?: 'ModuleVisibilityConfigType', assets: Types.TenantFeatureTypeEnum, itAsset: Types.TenantFeatureTypeEnum, inventory: Types.TenantFeatureTypeEnum, contacts: Types.TenantFeatureTypeEnum, orderRequest: Types.TenantFeatureTypeEnum, shipping: Types.TenantFeatureTypeEnum, receiving: Types.TenantFeatureTypeEnum, report: Types.TenantFeatureTypeEnum, admin: Types.TenantFeatureTypeEnum, shop: Types.TenantFeatureTypeEnum, wishlist: Types.TenantFeatureTypeEnum } | null } | null, parentPartnerTenants?: Array<{ __typename?: 'TenantSchema', id: string, name: string, accessibleCategories?: Array<{ __typename?: 'CategorySchema', id: string, name: string }> | null }> | null };

export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserSchema {
  id
  firstName
  lastName
  emailId
  name
  tenantId
  isSuperAdmin
  profilePicture
  isExternalUser
  workspacePermissions {
    action
    subject
  }
  roles {
    id
    name
  }
  defaultSite {
    id
    name
    status
  }
  tenant {
    id
    name
    tenantCode
    moduleVisibilityConfig {
      assets
      itAsset
      inventory
      contacts
      orderRequest
      shipping
      receiving
      report
      admin
      shop
      wishlist
    }
  }
  parentPartnerTenants {
    id
    name
    accessibleCategories {
      id
      name
    }
  }
}
    `;