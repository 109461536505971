import { ShippingContainerSchema } from "app/types/schema"

import { LabelTypeEnum } from "../../Labels/components/Forms/types"
import { CodeSchema } from "../components/types"

const getSubTitle = (item: CodeSchema, type: LabelTypeEnum | undefined) => {
    switch (type) {
        case LabelTypeEnum.Shipping:
        case LabelTypeEnum.ShippingSetUp:
            return (item as ShippingContainerSchema)?.containerId
    
        default:
            return
    }
}

export default getSubTitle