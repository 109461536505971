import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type LocationTableRowFragment = { __typename?: 'LocationSchema', id: string, name: string, updatedAt: string, description?: string | null, locationCode?: string | null, site: { __typename?: 'LocationSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } };

export const LocationTableRowFragmentDoc = gql`
    fragment LocationTableRow on LocationSchema {
  id
  name
  updatedAt
  description
  locationCode
  site {
    id
    name
  }
  updatedBy {
    id
    name
  }
}
    `;