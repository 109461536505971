import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Radio, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import RadioControl from 'app/components/Radio';
import Orders from 'app/i18n/Orders';
import useItemInStockWithSearchDialogState from 'app/modules/assetsInventory/components/FormItemInStockWithSearchDialog/hook/useItemInStockWithSearchDialogState';
import ItemsInStockSearchDialog from 'app/modules/assetsInventory/components/ItemInStockSearchDialog';

import Common from '../../../../../../../../i18n/Common';
import { format } from '../../../../../../../../utils/cost';
import OrderFormItemInStock from '../../../../../../../assetsInventory/components/FormItemInStock/OrderFormItemInStock';
import { ItemInStock } from '../../../../type';
import { SupportedOrderItemTypes } from '../../../OrderItemRequest/type';
import useInsertItemChange from '../../hook/useInsertItemChange';
import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';
import OrderItemInStockInput from './OrderItemInStockInput';
import FormLabel from 'app/components/ProcureForm/FormLabel';

interface Props {
  onBulkOrderRequest: () => void;
  isCollapsed: boolean;
  formLabel?: string;
  name?: string;
}

const OrderItemInStockField = (props: OrderFieldProps & Props) => {
  const { formState, onBulkOrderRequest, disabled, isCollapsed, formLabel, name } = props;
  const { control, setValue, itemInStock } = formState;

  const { onInsertItemChange, setInputRef, isInsertItemTypeSame } = useInsertItemChange({
    insertItemType: ORDER_INSERT_TYPE.ITEM_IN_STOCK,
    formState,
  });

  const { actions: itemInStockDialogActions, state: itemInStockDialogState } =
    useItemInStockWithSearchDialogState();

  const onSearchIconClick = useCallback(() => {
    itemInStockDialogActions.onShowItemInStockSearchDialog(itemInStock);
  }, [itemInStockDialogActions, itemInStock]);

  const onItemInStockValueChange = useCallback(
    (itemInStock?: ItemInStock) => {
      setValue('itemInStock', itemInStock || null);
      setValue('cost', format(itemInStock?.cost || '0.00'));
    },
    [setValue],
  );

  const onAddItemInStock = useCallback(
    (itemInStock: ItemInStock) => {
      setValue('itemId', itemInStock?.id || '', {
        shouldValidate: true,
      });
      onItemInStockValueChange(itemInStock);
      itemInStockDialogActions.onHideItemInStockSearchDialog();
    },
    [onItemInStockValueChange, itemInStockDialogActions, setValue],
  );

  const partnerTenantId = useMemo(
    () => (formState.isExternalTenant ? formState.partnerTenantId : undefined),
    [formState.isExternalTenant, formState.partnerTenantId],
  );

  const showItemInStockFields = useCallback(
    () => formState.actions.onInsertItemTypeChange(ORDER_INSERT_TYPE.ITEM_IN_STOCK),
    [formState.actions],
  );

  const handleClick = useCallback(() => {
    showItemInStockFields();
  }, [showItemInStockFields]);

  const hasError: boolean = useMemo(() => {
    return !isEmpty(formState.errors?.itemId);
  }, [formState]);

  return (
    <>
      {formLabel ? (
        <FormLabel className="ml-[40px] text-[14px] font-medium text-grey-800">
          {formLabel}
        </FormLabel>
      ) : null}
      <Accordion
        className="mb-[20px] mt-6 border-0 shadow-none before:content-none"
        expanded={!isCollapsed || hasError}
        onClick={handleClick}>
        <div className="flex items-center">
          <div className="flex w-[40px]">
            <RadioControl
              checked={formState.itemType === ORDER_INSERT_TYPE.ITEM_IN_STOCK}
              onChange={handleClick}
            />
          </div>
          <AccordionSummary
            className="flex !min-h-[50px] flex-auto items-center rounded-[8px]"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              border: '1.5px solid lightgrey',
              '& .Mui-expanded': {
                margin: '0px !important',
              },
              paddingLeft: '12px !important',
            }}
            onKeyDown={handleClick}>
            <Typography>{Orders.ItemsInStock}</Typography>
          </AccordionSummary>
        </div>
        <AccordionDetails className="p-0 pl-[40px]">
          <div>
            <div className="flex">
              <div className="flex-1 flex-auto">
                <Controller
                  control={control}
                  name="itemId"
                  render={({ field, fieldState }) => (
                    <>
                      <OrderFormItemInStock
                        disabled={disabled}
                        {...field}
                        pickableThroughOrderRequest
                        error={fieldState.error}
                        partnerTenantId={partnerTenantId}
                        placeholder="Search Item"
                        renderInput={(params) => (
                          <OrderItemInStockInput
                            name={name}
                            autoFocus
                            getInputReference={setInputRef}
                            inputParams={params}
                            label={Orders.FormLabels.ItemsInStock}
                            placeholder={Orders.FormPlaceholders.ItemsInStock}
                            onSearchIconClick={onSearchIconClick}
                          />
                        )}
                        onChange={(value, option) => {
                          field?.onChange(value);
                          onItemInStockValueChange(option);
                        }}
                        onFocus={onInsertItemChange}
                      />
                    </>
                  )}
                  rules={isInsertItemTypeSame ? ORDER_REQUEST_FORM_RULES.skuValidator : undefined}
                />
              </div>
            </div>
            <div className="mt-[13px] flex justify-end">
              <div className="bulk-order-link text-blue-900 underline" onClick={onBulkOrderRequest}>
                {Orders.Actions.CreateBulkOrder}
              </div>
            </div>
            <ItemsInStockSearchDialog
              actions={itemInStockDialogActions}
              state={itemInStockDialogState}
              types={SupportedOrderItemTypes}
              partnerTenantId={partnerTenantId}
              confirmButtonText={Common.Actions.Add}
              onConfirm={onAddItemInStock}
              onClose={itemInStockDialogActions.onHideItemInStockSearchDialog}
              pickableThroughOrderRequest
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OrderItemInStockField;
