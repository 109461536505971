import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditRoleDataFragment = { __typename?: 'RoleSchema', name: string, id: string, tenantId?: string | null, type: Types.RoleTypeEnum, description?: string | null, formattedDescription?: string | null, metadata?: { __typename?: 'RoleMetadataSchema', configuredPermissions?: Array<string> | null } | null };

export const EditRoleDataFragmentDoc = gql`
    fragment EditRoleData on RoleSchema {
  name
  id
  tenantId
  type
  description
  formattedDescription
  metadata {
    configuredPermissions
  }
}
    `;