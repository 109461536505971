import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditRoleDataFragmentDoc } from '../../fragments/generated/editRoleData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditRoleDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.RoleFilters>;
}>;


export type EditRoleDataQuery = { __typename?: 'Query', roles: { __typename?: 'RoleConnection', totalCount: number, edges?: Array<{ __typename?: 'RoleEdge', node: { __typename?: 'RoleSchema', name: string, id: string, tenantId?: string | null, type: Types.RoleTypeEnum, description?: string | null, formattedDescription?: string | null, metadata?: { __typename?: 'RoleMetadataSchema', configuredPermissions?: Array<string> | null } | null } }> | null } };


export const EditRoleDataDocument = gql`
    query editRoleData($filters: RoleFilters) {
  roles(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditRoleData
      }
    }
  }
}
    ${EditRoleDataFragmentDoc}`;

export function useEditRoleDataQuery(options?: Omit<Urql.UseQueryArgs<EditRoleDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditRoleDataQuery>({ query: EditRoleDataDocument, ...options });
};