import React, { ReactElement, ReactNode } from 'react';
import { useAccessControl } from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { DeviceRowType } from 'app/modules/network/types';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import EntityManager from '../../../../../components/EntityManager';
import { UseNetworkAssetsStateReturnType } from '../../hook/useNetworkAssetsState';
import { getNetworkAssetsTableColumns } from '../../utils/columns';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  filters: UseNetworkAssetsStateReturnType['filters'];
  tableState: UseNetworkAssetsStateReturnType['networkAssetTableState'];
  history?: any;
  clickable: boolean;
  discovered?: boolean;
  devicesRow: DeviceRowType[];
  disableSelection?: boolean;
  persistKey: string;
  filterNodes?: ReactNode | ReactElement
}

const NetworkAssetListView = (props: Props) => {
  const { table, filters, tableState, clickable, discovered, devicesRow, disableSelection, persistKey, filterNodes } =
    props;
  const { workspacePermissions } = useCurrentUser();
  const editPermission = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  const canRedirect = editPermission && clickable;

  return (
    <EntityManager.MultiSelectTable
      ignoreRelayPagination
      isItAsset
      pagination
      persistSelectionData
      actions={table.actions}
      columns={getNetworkAssetsTableColumns(canRedirect, !!discovered)}
      data={devicesRow}
      defaultSortState={{
        id: undefined,
      }}
      disableSelection={disableSelection}
      extraProps={{
        filters,
      }}
      getRowId={(row: any) => row.id}
      loading={!devicesRow || tableState.fetching}
      persistKey={persistKey}
      setState={table.setState}
      state={table.state}
      total={tableState.totalCount}
      onNextPage={tableState.onNextPage}
      onPrevPage={tableState.onPrevPage}
      onReset={tableState.onReset}
      filterNodes={filterNodes}
      paginationWrapperClass='p-[24px] flex-wrap md:gap-[24px]'
    />
  );
};

export default NetworkAssetListView;
