import { Controller } from 'react-hook-form';
import Inventory from 'app/i18n/Inventory';

import FormCheckBoxInput from '../../../../../../components/ProcureForm/FormCheckBoxInput';
import { InventoryFormProps } from '../../types';

const InventoryDisplayInOrderField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeProduct } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="pickableThroughOrderRequest"
        render={({ field, fieldState }) => (
          <FormCheckBoxInput
            {...field}
            disabled={disabled}
            label={Inventory.FormLabels.DisplayInOrder}
            classNames='!p-0 mr-[8px]'
          />
        )}
      />
    </div>
  );
};

export default InventoryDisplayInOrderField;
