import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAssetItemsMutationVariables = Types.Exact<{
  input: Types.DeleteItemsInput;
}>;


export type DeleteAssetItemsMutation = { __typename?: 'Mutation', deleteAssetItems: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteAssetItemsDocument = gql`
    mutation deleteAssetItems($input: DeleteItemsInput!) {
  deleteAssetItems(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteAssetItemsMutation() {
  return Urql.useMutation<DeleteAssetItemsMutation, DeleteAssetItemsMutationVariables>(DeleteAssetItemsDocument);
};