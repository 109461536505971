import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { AddressesTableRowFragmentDoc } from '../../fragments/generated/addressesTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddressesTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AddressFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddressesTableQuery = { __typename?: 'Query', addresses: { __typename?: 'AddressConnection', totalCount: number, edges: Array<{ __typename?: 'AddressEdge', node: { __typename?: 'AddressSchema', id: string, addressLineOne: string, addressLineTwo?: string | null, addressName: string, city: string, companyId: string, state: string, country?: string | null, zipCode: string } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const AddressesTableDocument = gql`
    query addressesTable($filters: AddressFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  addresses(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...AddressesTableRow
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    ${AddressesTableRowFragmentDoc}`;

export function useAddressesTableQuery(options?: Omit<Urql.UseQueryArgs<AddressesTableQueryVariables>, 'query'>) {
  return Urql.useQuery<AddressesTableQuery>({ query: AddressesTableDocument, ...options });
};