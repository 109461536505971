import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import { RoleTypeEnum } from 'app/types/schema';

export const getAllowedRoleTypes = (user: UserProfileFragment) => {
  const isSuperAdmin = canSwitchTenants(user.workspacePermissions);

  return isSuperAdmin
    ? [RoleTypeEnum.Custom, RoleTypeEnum.System, RoleTypeEnum.Hidden]
    : [RoleTypeEnum.Custom, RoleTypeEnum.System];
};
