import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import useFormFileUploader from '../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import { CreateMediaInput } from '../../../../../../types/schema';
import { parseProduceDataToAssetFormValues } from '../../../../../assets/hook/useAssetForm/utils';
import usePersistAttachmentsHelper from '../../../../../components/FindUPC/components/ProductDetailFormModal/hook/usePersistUpcProductData';
import { FindUpcContextType } from '../../../../../components/FindUPC/context/FindUPCProvider';
import useFindUPC from '../../../../../components/FindUPC/context/useFindUPC';
import { AssetModelItemFormInput } from '../../type';

interface Props {
  defaultValues: AssetModelItemFormInput;
}

const useAssetModelForm = (props: Props) => {
  const { defaultValues } = props;
  const { control, handleSubmit, watch, setValue, resetField, reset, getValues } = useForm({
    defaultValues: {
      ...defaultValues,
      description: defaultValues.formattedDescription || defaultValues.description,
      findUpc: false,
    },
  });

  const id = watch('id');
  const upcCode = watch('upcCode');
  const noUpcCode = watch('noUpcCode');
  const showFindUpcSection = watch('findUpc');
  const attachments = watch('attachments');

  const defaultAttachments = useMemo(() => {
    return (
      defaultValues?.attachments?.map((attachment: CreateMediaInput, index: number) => {
        return {
          id: `attachment-${index}`,
          ...attachment,
          resolutions: attachment?.resolutions || [],
        };
      }) || []
    );
  }, [defaultValues?.attachments]);

  const {
    inputProps: attachmentInputProps,
    setAttachments: setAssetAttachment,
    addFileFromUrl: addAssetAttachmentFromUrl,
  } = useFormFileUploader('attachments', defaultAttachments);

  const { parseNoUpcAssetAttachments, parseProduceDataImagesToAssetAttachments } =
    usePersistAttachmentsHelper(attachmentInputProps);

  const { search: findUpcCodeSearch } = useFindUPC() as FindUpcContextType;

  const onFindUpcClick = useCallback(() => {
    setValue('findUpc', true);
    findUpcCodeSearch?.onChange(upcCode);
  }, [setValue, upcCode, findUpcCodeSearch?.onChange]);

  const onNoUpcCodeChange = useCallback(
    (noUpcCodeValue: boolean) => {
      if (noUpcCodeValue) {
        setValue('findUpc', false);
        resetField('upcCode', { defaultValue: '' });
        parseNoUpcAssetAttachments();
      } else {
        setValue('upcCode', '');
      }
    },
    [parseNoUpcAssetAttachments],
  );

  const onSaveProductData = useCallback(
    (values: any) => {
      const productImages = values?.images || [];
      parseProduceDataImagesToAssetAttachments(productImages);
      // Upload Product image Url to S3
      productImages.forEach((image: string) => {
        addAssetAttachmentFromUrl(image);
      });

      reset({
        ...getValues(),
        ...parseProduceDataToAssetFormValues(values),
      });
    },
    [
      reset,
      getValues,
      attachments,
      addAssetAttachmentFromUrl,
      parseProduceDataImagesToAssetAttachments,
      setAssetAttachment,
    ],
  );

  return {
    formState: {
      control,
      attachmentInputProps,
      noUpcCode,
      onNoUpcCodeChange,
      onFindUpcClick,
      handleSubmit,
      id,
    },
    showFindUpcSection,
    onSaveProductData,
  };
};

export type UseAssetModelFormReturnType = ReturnType<typeof useAssetModelForm>;

export default useAssetModelForm;
