import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type CompanyDetailsFragment = { __typename?: 'CompanySchema', companyEmail?: string | null, companyFax?: string | null, companyName: string, companyWebsite?: string | null, isVendor?: boolean | null, companyContactNumber?: string | null, id: string };

export const CompanyDetailsFragmentDoc = gql`
    fragment CompanyDetails on CompanySchema {
  companyEmail
  companyFax
  companyName
  companyWebsite
  isVendor
  companyContactNumber
  id
}
    `;