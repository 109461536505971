import { Controller, useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import Box from 'app/ui-components';

interface Props {
  title: string;
  onChange: (value: string) => void;
}

const TableHeader = (props: Props) => {
  const { control } = useForm();

  return (
    <>
      {/* <TitleBar title={props.title} /> */}
      <Box className="w-[400px] space-y-24 py-16">
        <Controller
          control={control}
          name="search"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              error={fieldState.error}
              // label={Label.Form.Search}
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              onChange={(value: string) => {
                field.onChange(value);
                props.onChange(value);
              }}
            />
          )}
        />
      </Box>
    </>
  );
};

export default TableHeader;
