import { Controller } from 'react-hook-form';
import { RadioGroup } from '@procurenetworks/procure-component-library';
import Stack from 'app/ui-components/Stack';

import { SiteReportOption } from 'app/modules/reports/views/SiteReport/types';
import { siteReportRadioGroupStyleOverrides } from './styles';

interface Props {
  control: any;
}

const RADIO_ITEMS = [
  {
    id: SiteReportOption.nonZeroTotalQuantity,
    value: SiteReportOption.nonZeroTotalQuantity,
    label: 'Quantity is greater than zero',
  },
  {
    id: SiteReportOption.includeItemsWithZeroQuantity,
    value: SiteReportOption.includeItemsWithZeroQuantity,
    label: 'Quantity is greater than zero and/or equal to zero',
  },
  {
    id: SiteReportOption.onlyMinimumQuantityThresholdBreached,
    value: SiteReportOption.onlyMinimumQuantityThresholdBreached,
    label: 'Quantity is equal to or less than Minimum Quantity only',
  },
];

const ReorderReportOptions = (props: Props) => {
  const { control } = props;

  return (
    <Stack className={siteReportRadioGroupStyleOverrides} direction="col">
      <Controller
        control={control}
        name="siteReportOption"
        render={({ field }) => (
          <RadioGroup radioItems={RADIO_ITEMS} value={field.value} onChange={field.onChange} />
        )}
      />
    </Stack>
  );
};

export default ReorderReportOptions;