import { find } from 'lodash';
import { ShippingTransactionStatusEnum } from 'app/types/schema';

export const ShipTransactionStatues = [
  {
    label: 'Packed',
    value: ShippingTransactionStatusEnum.Packed,
  },
  {
    label: 'Unpacked',
    value: ShippingTransactionStatusEnum.Unpacked,
  },
  {
    label: 'Out For Delivery',
    value: ShippingTransactionStatusEnum.OutForDelivery,
  },
  {
    label: 'Delivered',
    value: ShippingTransactionStatusEnum.Delivered,
  },
];

export function getLeastItemStatusLabel(status: ShippingTransactionStatusEnum) {
  return find(ShipTransactionStatues, (leastItemStatus) => leastItemStatus.value === status)?.label;
}
