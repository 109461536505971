import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import { AsyncMultiTableFilterProps } from 'app/components/AsyncMultiTableFilter/types';
import {
  AsyncSingleSelectProps,
  UseSelectedValueReturn,
} from 'app/components/AsyncSingleSelect/types';
import AsyncSingleTableFilter from 'app/components/AsyncSingleTableFilter';

import {
  UseSelectedValueProps,
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from '../../../../components/AsyncMultiSelect/utils';
import AsyncMultiTableFilter from '../../../../components/AsyncMultiTableFilter';
import {
  Maybe,
  RoleTypeFilterEnum,
  SortOrderEnum,
  UserStatusEnum,
  ValueInEntitiesFiltersConfig,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from '../../../../types/schema';
import { useValuesInEntitiesSelectQuery } from '../../graphql/queries/generated/distinctUserSelect';
import { useUsersSelectQuery } from '../../graphql/queries/generated/usersSelect';
import { UsersSelectProps } from '../UsersSelect';

interface UsersQueryVariables {
  roleTypes?: RoleTypeFilterEnum[];
  statuses?: UserStatusEnum[];
  tenantIds?: string[];
  includingPartnerTenantExternalUsers?: boolean;
  distinctTableName?: ValuesInEntitiesTableNameEnum;
  distinctKeys?: ValuesInEntitiesDistinctByKeysEnum[];
  filters?: keyof ValueInEntitiesFiltersConfig;
  filterKey?: keyof ValueInEntitiesFiltersConfig;
}

export interface UserSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: UsersQueryVariables;
}

const useSelectedValues = (
  props: UseSelectedValueProps & UsersQueryVariables & { isMultiple?: boolean },
): UseSelectedValuesReturn | UseSelectedValueReturn => {
  const {
    value,
    pause,
    tenantIds,
    roleTypes,
    includingPartnerTenantExternalUsers,
    statuses,
    isMultiple = true,
  } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    pause,
    variables: {
      filters: {
        userIds: value,
        tenantIds,
        roleTypes,
        includingPartnerTenantExternalUsers,
        statuses: statuses,
      },
    },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () =>
      data?.users.edges
        ?.map(adaptNodeEdgeToOption)
        ?.filter((option) => value.includes(option.value)) || [],
    [data?.users.edges],
  );

  if (isMultiple) return { isDisabled: fetching, selectedValues: values };
  else return { isDisabled: fetching, selectedValue: values[0] };
};

const useSelectQuery = (props: UseSelectQueryProps & UsersQueryVariables): UseSelectQueryReturn => {
  const { inputValue, roleTypes, tenantIds, includingPartnerTenantExternalUsers, statuses } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    variables: {
      filters: {
        search: inputValue,
        roleTypes,
        tenantIds,
        includingPartnerTenantExternalUsers,
        statuses: statuses ?? [UserStatusEnum.Active],
      },
      limit: 10000,
      sorts: [
        { sortField: 'firstName', sortOrder: SortOrderEnum.Asc },
        { sortField: 'lastName', sortOrder: SortOrderEnum.Asc },
      ],
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.users.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.users.edges],
  );

  return { isLoading: fetching, options };
};

const useDistinctValueSelectQuery = (
  props: UseSelectQueryProps & UsersQueryVariables,
): UseSelectQueryReturn => {
  const { distinctTableName, distinctKeys, filters, filterKey = 'transactionFilters' } = props;
  const [{ fetching, data }] = useValuesInEntitiesSelectQuery({
    variables: {
      inputs: {
        tableName: distinctTableName || ValuesInEntitiesTableNameEnum.Transactions,
        distinctByKeys: distinctKeys || [],
        filters: {
          [filterKey]: filters,
        },
      },
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(() => {
    const options =
      data?.valuesInEntities.payload[0].entities.map((entity) => {
        return {
          entity,
          value: entity.id,
          label: entity.name,
        };
      }) || [];

    return options;
  }, [data?.valuesInEntities.payload]);
  return { isLoading: fetching, options };
};

const UsersTableFilter = (props: UsersSelectProps) => {
  const { isMultiple = true, value, onChange, isDistinct, ...otherProps } = props;

  if (isMultiple) {
    return (
      <AsyncMultiTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValuesReturn}
        value={value as Maybe<string[]>}
        onChange={onChange as AsyncMultiTableFilterProps['onChange']}
      />
    );
  } else {
    return (
      <AsyncSingleTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValueReturn}
        value={value as Maybe<string>}
        onChange={onChange as AsyncSingleSelectProps['onChange']}
      />
    );
  }
};

export default UsersTableFilter;
