import Link from 'app/components/Link';
import routes from 'app/consts/routes';
import { ItemTypeEnum } from 'app/types/schema';

const InvalidQuantity = ({ item, kitQuantity }: { item: any; kitQuantity?: number }) => {
  const isItemTypeInventory = item?.type === ItemTypeEnum.Inventory;
  const message =
    'Insufficient items exist to build this quantity of kits. Please reduce the quantity of kits';
  const hasSerialNumber = item?.serialNumber;

  return (
    <div className="text-[16px] font-semibold">
      {hasSerialNumber ? (
        <p>{message}</p>
      ) : (
        <>
          {message} or restock{' '}
          {kitQuantity ? kitQuantity * item?.quantity - item?.availableQuantity : item?.quantity} of
          <a
            className={'mx-[5px] cursor-pointer text-[#3366CC] no-underline'}
            target="_blank"
            href={
              isItemTypeInventory ? routes.EditInventory(item?.id) : routes.EditAssets(item?.id)
            }>
            {item?.sku}
          </a>
          to {isItemTypeInventory ? 'inventory' : 'asset'}.
        </>
      )}
    </div>
  );
};

export default InvalidQuantity;
