import Reports from 'app/i18n/Reports';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import { DateFormatEnum, formatDate } from 'app/utils/date';
import { sortBy } from 'app/utils/sort';

export const ASSET_SPECIFIC_COLUMNS = [
  'purchaseDate',
  'replacementDate',
  'warrantyExpiryDate',
  'modelNumber',
  'serialNumber',
];

export const CUSTOM_REPORT_COLUMNS = [
  {
    key: 'sku',
    label: 'SKU',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'container',
    label: 'Container',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  // TODO: [manufacturer] verify and move.
  {
    key: 'manufacturer',
    label: 'Manufacturer',
  },
  {
    key: 'serialNumber',
    label: 'Serial Number',
  },
  {
    key: 'vendor',
    label: 'Vendor',
  },
  {
    key: 'projectCode',
    label: 'Project',
  },
  {
    key: 'fromSite',
    label: 'From Site',
  },
  {
    key: 'fromLocation',
    label: 'From Location',
  },
  {
    key: 'toLocation',
    label: 'To Location',
  },
  {
    key: 'toSite',
    label: 'To Site',
  },
  {
    key: 'departmentCode',
    label: 'Department Code',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'modelNumber',
    label: 'Model Number',
  },
  {
    key: 'transactionType',
    label: 'Transaction Type',
  },
  {
    key: 'createdAt',
    label: 'Transaction Date',
  },
  {
    key: 'category',
    label: 'Category',
  },
  {
    key: 'categoryCode',
    label: 'Category Code',
  },
  {
    key: 'costOverride',
    label: 'Cost Override %',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'replacementDate',
    label: 'Replacement Date',
  },
  {
    key: 'unitCostWithoutOverride',
    label: Reports.UnitCost,
  },
  {
    key: 'unitCostWithOverride',
    label: Reports.UnitCostWithOverride,
  },
  {
    key: 'totalCostWithoutOverride',
    label: Reports.TotalCostWithoutOverride,
  },
  {
    key: 'totalCostWithOverride',
    label: Reports.TotalCostWithOverride,
  },
].sort(sortBy('label', true));

export function getReportColumns(itemType: TransactionEntityTypeEnum, transactionTypes: string[]) {
  const columns: { key: string, label: string }[] = [];

  if (itemType === TransactionEntityTypeEnum.Inventory) {
    columns.push(...CUSTOM_REPORT_COLUMNS.filter((item) => !ASSET_SPECIFIC_COLUMNS.includes(item.key)));
  } else {
    columns.push(...CUSTOM_REPORT_COLUMNS);
  }

  if(transactionTypes.includes('remove'))
    columns.push({ key: 'reason', label: Reports.Reason})

  return columns
}

export function isColumnsContainsAssetColumns(columns: string[]) {
  const assetColumns = columns.filter((column) => ASSET_SPECIFIC_COLUMNS.includes(column));
  return assetColumns.length > 0;
}

export function removeAssetColumns(columns: string[]) {
  return columns.filter((column) => !ASSET_SPECIFIC_COLUMNS.includes(column));
}

const getTransactionDate = () => {
  const date = new Date(new Date().setDate(new Date().getDate() - 1));
  const formattedDate = formatDate(String(date));
  return formattedDate;
};

export const CUSTOM_REPORT_ASSET_ROWS = [
  {
    id: '62f7ce35e35f99b103808ecb',
    sku: 'AST1264194',
    title: 'Han Solo Foam Blaster',
    model: 'Black',
    modelNumber: 'FG112-1SW',
    category: 'Security',
    categoryCode: '012-34-5678',
    container: 'PKG-010102',
    departmentCode: '987-65-4321',
    projectCode: '12-AB-345-DEF',
    description:
      'Solo Foam Blaster. It is made with high density foam. It is perfect for cosplay and halloween party.\n9 3/4" Overall\nHigh Density foam\nRemoveable scoop very detailed, fits any hand perfectly.\nFor Cosplay, Gifts, Collectible, Halloween Costume Only',
    brand: 'Otaku Gear',
    manufacturer: 'Pacific Solution',
    quantity: '49',
    reason: "Consumed",
    unitCostWithoutOverride: '$16.99',
    totalCostWithoutOverride: '$832.51',
    unitCostWithOverride: '$16.99',
    totalCostWithOverride: '$832.51',
    transactionType: 'New',
    createdAt: getTransactionDate(),
    fromSite: '0987',
    toSite: '7890',
    fromLocation: 'Location A100',
    toLocation: 'Location A103',
    replacementDate: formatDate(new Date().toString(), DateFormatEnum.Date),
    costOverride: '0%',
    serialNumber: null,
    vendor: 'Amazon',
  },
  {
    id: '6345d2f87410505c87b1acf2',
    sku: 'AST2210110',
    title: 'Mandalorian Mask',
    model: 'Grey, Large',
    modelNumber: '75008',
    category: 'Apparel',
    container: 'BIN-021101',
    categoryCode: '123456789',
    departmentCode: '98-7654-32-10',
    projectCode: '1 A 23 BC',
    description:
      'THE MANDALORIAN MASK: Inspired by the Star Wars entertainment, this iconic The Mandalorian mask makes a wonderful gift for Star Wars fans',
    brand: 'Hasbro',
    manufacturer: 'Pacific Solution',
    quantity: '10',
    reason: "Donated",
    unitCostWithoutOverride: '$12.99',
    totalCostWithoutOverride: '$129.90',
    unitCostWithOverride: '$13.12',
    totalCostWithOverride: '$131.20',
    transactionType: 'New',
    createdAt: getTransactionDate(),
    fromSite: '7890',
    toSite: '0987',
    fromLocation: 'LP Location B100',
    toLocation: 'LP Location B102',
    replacementDate: formatDate(new Date().toString(), DateFormatEnum.Date),
    costOverride: '1%',
    serialNumber: null,
    vendor: 'Amazon',
  },
];
export const CUSTOM_REPORT_INVENTORY_ROWS = [
  {
    id: '62f7ce39e35f99b10380aa22',
    sku: 'INV1227890',
    title: 'R2-D2 Attire',
    model: 'R2-D2 - Small',
    modelNumber: null,
    category: 'Apparel',
    categoryCode: '123456789',
    container: 'PKG-010102',
    departmentCode: '987-65-4321',
    projectCode: '12-AB-345-DEF',
    reason: "Consumed",
    description:
      "This paw-some Halloween costume is an instant classic! Take your pup on an intergalactic trick-or-treat adventure when you suit them up in this adorable costume. If you're looking for more fun Star Wars(TM) costumes for your dog, just search our site to discover more fun options! Polyester. Hood, Shirt w/ attached paw covers",
    brand: 'Rubies',
    manufacturer: 'Pacific Solution',
    quantity: '1',
    unitCostWithoutOverride: '$27.04',
    totalCostWithoutOverride: '$27.04',
    unitCostWithOverride: '$29.26',
    totalCostWithOverride: '$29.26',
    transactionType: 'New',
    createdAt: getTransactionDate(),
    fromSite: '0987',
    toSite: '7890',
    fromLocation: 'LP Location I104',
    toLocation: 'LP Location I107',
    costOverride: '8.2%',
    serialNumber: null,
    vendor: 'Amazon',
  },
  {
    id: '62f7ce39e35f99b10380aa26',
    sku: 'INV8932058',
    title: 'Star Wars Jedi Training Club T',
    model: 'Medium, Heather Gray',
    modelNumber: null,
    category: 'Apparel',
    categoryCode: '123456789',
    container: 'BIN-021101',
    departmentCode: '98-7654-32-10',
    projectCode: '1 A 23 BC',
    description:
      'Solid colors: 100% Cotton; Heather Grey: 90% Cotton, 10% Polyester; All Other Heathers: 50% Cotton, 50% Polyester\nImported\nMachine Wash\nStar Wars Officially Licensed Apparel\n17STRW01104A-001\nLightweight, Classic fit, Double-needle sleeve and bottom hem\n\nStop looking for fashion help in Alderaan places! Add a little out-of-this-world style to your wardrobe with some truly epic Star Wars shirts! Transport yourself to a galaxy far, far away with a cool new Star Wars shirt featuring Darth Vader, Luke Skywalker, Yoda, Chewbacca, Princess Leia, Boba Fett, and all your favorite Star Wars characters!',
    brand: 'Hasbro',
    manufacturer: 'Pacific Solution',
    quantity: '100',
    reason: "Donated",
    unitCostWithoutOverride: '$22.99',
    totalCostWithoutOverride: '$2299.00',
    unitCostWithOverride: '$22.99',
    totalCostWithOverride: '$2299.00',
    transactionType: 'New',
    createdAt: getTransactionDate(),
    fromSite: '7890',
    toSite: '0987',
    fromLocation: 'LP Location I108',
    toLocation: 'LP Location I121',
    costOverride: '0%',
    serialNumber: null,
    vendor: 'Amazon',
  },
];
