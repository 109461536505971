import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Button } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import Modal from 'app/components/Modal';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import { SnackbarService } from 'app/components/Snackbar';
import Common from 'app/i18n/Common';
import Network from 'app/i18n/Network';
import { useUpdateNetworkAssetMutation } from 'app/modules/network/graphql/mutations/generated/updateNetworkAsset';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';
import analytics from 'app/analytics';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  onSavedUser: () => void;
  warrantyData: WarrantyDataType;
  mac: string;
};

type WarrantyDataType = {
  startDate?: string;
  endDate?: string;
};

const WarrantyFormModal = (props: Props) => {
  const { isOpen, onCancel, onSavedUser, warrantyData, mac } = props;
  const [, onUpdateNetworkAsset] = useUpdateNetworkAssetMutation();

  const networkAssetFormDefaultValues = useMemo(() => {
    return {
      startDate: warrantyData.startDate ? new Date(warrantyData.startDate) : null,
      endDate: warrantyData.endDate ? new Date(warrantyData?.endDate) : null,
    };
  }, [warrantyData]);

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: networkAssetFormDefaultValues,
  });

  useEffect(() => {
    reset(networkAssetFormDefaultValues);
  }, [networkAssetFormDefaultValues, reset]);

  const onSubmit = useCallback(
    (input: any) => {
      onUpdateNetworkAsset({ input: { warranty: { ...input }, mac: mac } }).then((response) => {
        if (response.data?.createNetworkAsset.success) {
          analytics?.track('Edited', { name: 'Network Asset' });
          SnackbarService.show({
            message: Network.SuccessMessages.NetworkAssetUpdated,
          });
          onSavedUser();
        } else {
          console.error('[Update Category] Failed', response);
        }
      });
    },
    [mac, onSavedUser, onUpdateNetworkAsset],
  );

  const maxPurchaseDate = useMemo(() => {
    const date = new Date();
    return moment(date).format('YYYY-MM-DD');
  }, []);

  const minWarrantyDate = useMemo(() => {
    return maxPurchaseDate;
  }, [maxPurchaseDate]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  return (
    <Modal
      actions={
        <div>
          <Button classes="w-[90px]" theme="success" onClick={onFormSubmit}>
            {Common.Actions.Save}
          </Button>
          <Button classes="ml-[16px] w-[90px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="location-user-modal"
      open={isOpen}
      title={Network.UpdateWarranty}
      onClose={onCancel}>
      <div className="w-[100%] overflow-x-hidden md:w-[560px]">
        <form>
          <Grid container paddingTop={1} rowGap={'24px'}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="startDate"
                render={({ field, fieldState }) => (
                  <FormDatePickerInput
                    isRequired
                    {...field}
                    key="startDate"
                    error={fieldState.error}
                    label={Network.FormLabels.PurchaseDate}
                    maxDate={maxPurchaseDate}
                  />
                )}
                rules={NETWORK_ASSET_FORM_RULES.startDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="endDate"
                render={({ field, fieldState }) => (
                  <FormDatePickerInput
                    isRequired
                    {...field}
                    key="endDate"
                    error={fieldState.error}
                    label={Network.FormLabels.WarrantyExpiryDate}
                    minDate={minWarrantyDate}
                  />
                )}
                rules={NETWORK_ASSET_FORM_RULES.endDate}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default WarrantyFormModal;
