import { sortBy } from '../../../../utils/sort';
import { getLocationName, isUnassignedLocation } from '../../utils/location';
import { FormItemLocationOption } from './types';

interface AdoptLocationParams {
  itemLocationEdges: any[];
  isFromLocationInput: boolean;
  siteId?: string;
}

const adaptItemLocationEdgeToOption = (
  { node }: any,
  isFromLocationInput: boolean,
  siteId?: string,
): FormItemLocationOption => {
  const { site, location, availableQuantity, totalQuantity } = node;
  const { id, name: siteName } = site;
  return {
    id: isFromLocationInput ? location?.id : id,
    name: isFromLocationInput ? getLocationName(siteId, location) : siteName,
    availableQuantity: availableQuantity,
    totalQuantity: totalQuantity,
    location: location,
    site: site,
  };
};

export const adaptItemLocationToOption = (
  params: AdoptLocationParams,
): FormItemLocationOption[] => {
  const { itemLocationEdges, isFromLocationInput, siteId } = params;

  const options: FormItemLocationOption[] = [];
  const unAssignedOptions: FormItemLocationOption[] = [];

  itemLocationEdges.forEach((item) => {
    const option = adaptItemLocationEdgeToOption(item, isFromLocationInput, siteId);
    if (isFromLocationInput && isUnassignedLocation(option.id, siteId || '')) {
      unAssignedOptions.push(option);
    } else {
      options.push(option);
    }
  });

  return [...unAssignedOptions, ...options.sort(sortBy('name'))];
};
