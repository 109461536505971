import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DepartmentsSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.DepartmentFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DepartmentsSelectQuery = { __typename?: 'Query', departments: { __typename?: 'DepartmentConnection', edges?: Array<{ __typename?: 'DepartmentEdge', node: { __typename?: 'DepartmentSchema', id: string, name: string } }> | null } };


export const DepartmentsSelectDocument = gql`
    query departmentsSelect($filters: DepartmentFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  departments(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useDepartmentsSelectQuery(options?: Omit<Urql.UseQueryArgs<DepartmentsSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<DepartmentsSelectQuery>({ query: DepartmentsSelectDocument, ...options });
};