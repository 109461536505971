import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WishlistShareIdQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WishlistValidateFilters>;
}>;


export type WishlistShareIdQuery = { __typename?: 'Query', wishlistValidateShareId: { __typename?: 'WishlistValidateShareIdPayload', exists?: boolean | null } };


export const WishlistShareIdDocument = gql`
    query wishlistShareId($filters: WishlistValidateFilters) {
  wishlistValidateShareId(filters: $filters) {
    exists
  }
}
    `;

export function useWishlistShareIdQuery(options?: Omit<Urql.UseQueryArgs<WishlistShareIdQueryVariables>, 'query'>) {
  return Urql.useQuery<WishlistShareIdQuery>({ query: WishlistShareIdDocument, ...options });
};