import routes from 'app/consts/routes';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';
const action = AllowedPermissionActionsEnum.Access;

const reportRouteHierarchy = [
  {
    route: routes.AgingReport(),
    subject: AllowedPermissionsSubjectEnum.AgeingReport,
    permission: action,
  },
  {
    route: routes.AuditReport(),
    subject: AllowedPermissionsSubjectEnum.AssetAuditReport,
    permission: action,
  },
  {
    route: routes.CostReport(),
    subject: AllowedPermissionsSubjectEnum.CostReport,
    permission: action,
  },
  {
    route: routes.LowStockReport(),
    subject: AllowedPermissionsSubjectEnum.LowStockReport,
    permission: action,
  },
  {
    route: routes.SiteReport(),
    subject: AllowedPermissionsSubjectEnum.SiteReport,
    permission: action,
  },
  {
    route: routes.TransactionReport(),
    subject: AllowedPermissionsSubjectEnum.TransactionReport,
    permission: action,
  },
  {
    route: routes.OrderRequestReport(),
    subject: AllowedPermissionsSubjectEnum.OrderRequestReport,
    permission: action,
  },
  {
    route: routes.ItAssetReport(),
    subject: AllowedPermissionsSubjectEnum.ItAsset,
    permission: action,
  },
  {
    route: routes.AccountingReport(),
    subject: AllowedPermissionsSubjectEnum.AccountingReport,
    permission: action,
  },
  {
    route: routes.CustomReport(),
    subject: AllowedPermissionsSubjectEnum.CustomReport,
    permission: action,
  },
];

export default reportRouteHierarchy;
