import jwtDecode from 'jwt-decode';

import { TokenData } from '../type';

export const getTokenData = (): TokenData | null => {
  try {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      const decodedToken = jwtDecode(token) as TokenData;
      if ((decodedToken.exp * 1000) > new Date().getTime()) {
        return decodedToken;
      }
      throw new Error('InvitationTokenExpired');
    }
    return null;
  } catch (error) {
    throw error;
  }
};
