import React, { useMemo } from 'react';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import Stack from 'app/ui-components/Stack';

import Modal from '../../../../components/Modal';
import Assets from '../../../../i18n/Assets';
import Common from '../../../../i18n/Common';
import EntityManager from '../../../components/EntityManager';
import useCheckInAssetsState from './hook/useCheckInAssetsState';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { checkInAssetsTableColumns } from './utils/columns';
import { Box, InputAdornment } from '@mui/material';

const CheckIn = () => {
  const {
    table,
    rows,
    rowCount,
    search,
    onPrevPage,
    onNextPage,
    onReset,
    fetching,
    setEditableFields,
    handleCreateCheckInTransaction,
    creatingCheckInTransaction,
    onCancelTransaction,
    onConfirmCheckInTransaction,
    transactionData,
    filterState,
    setFilterState,
  } = useCheckInAssetsState();

  const columns = useMemo(() => checkInAssetsTableColumns(), []);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] !p-0">
      <Box className="px-[16px] md:px-[24px] lg:px-[24px]">
        <EntityManager.Title
          documentTitle={Assets.CheckInDocument}
          title={Assets.CheckinAssets}
          hasTopDivider
        />
      </Box>
      <EntityManager.Table
        ignoreRelayPagination
        pagination
        actions={table.actions}
        columns={columns}
        data={rows}
        extraProps={{
          setEditableFields,
          onConfirmCheckInTransaction,
          filterState,
          setFilterState,
        }}
        loading={fetching}
        minWidth={0}
        persistKey="checkIn_table"
        rowHeight={85}
        setState={table.setState}
        state={table.state}
        testId="check_in_table"
        total={rowCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
        filterNodes={
          <Stack justifyContent="between" className="w-full md:w-fit lg:w-fit">
            <EntityManager.Search
              {...search}
              isDropdown
              suggestionData={searchSuggestions}
              onChangeSearch={setSearchValue}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
            />
          </Stack>
        }
        paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
      />

      <Modal
        disableBackdropClick
        actions={
          <div className="flex gap-[16px]">
            <Button
              loading={creatingCheckInTransaction}
              theme="success"
              onClick={() => {
                handleCreateCheckInTransaction();
              }}
              classes="min-w-[94px] h-[44px]">
              {transactionData?.transaction ? Common.Actions.Yes : Common.Actions.Ok}
            </Button>
            <Button
              disabled={creatingCheckInTransaction}
              onClick={onCancelTransaction}
              classes="min-w-[94px] h-[44px]">
              {Common.Actions.Cancel}
            </Button>
          </div>
        }
        disable={creatingCheckInTransaction}
        id="check-in-transaction"
        open={transactionData.open}
        title={Assets.FormLabels.CheckInDialog}
        onClose={onCancelTransaction}>
        <div className="lg:max-w-[459px]lg:max-w-[459px] h-[73px] md:max-w-[459px]">
          <div className="mt-[16px]">
            <span className="text-[16px] font-medium leading-[24px] text-grey-800">
              {' '}
              {transactionData.message}
            </span>
          </div>
        </div>
      </Modal>
    </EntityManager>
  );
};

export default CheckIn;
