const Location = {
  Locations: 'Locations',
  NewLocation: 'New Location',
  EditLocation: 'Edit Location',
  FormLabels: {
    Name: 'Location',
    Description: 'Description',
    Site: 'Site',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Location Name between 3 and 30 characters.',
    DescriptionMaxLength: 'Please enter a Description up to 500 characters.',
    CodeMaxLength: 'Please enter a Location Code up to 30 characters.',
    SiteRequired: 'Please select a Site.',
  },
  FormPlaceholders: {
    Name: 'Enter location name',
    Description: 'Enter location description',
    Code: 'Enter location code',
    Search: 'searchAssets for location',
    SelectSite: 'Site',
  },
};

export default Location;
