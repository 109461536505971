import Wishlist from 'app/i18n/Wishlist';
import EntityManager from 'app/modules/components/EntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';

import ReferOther from './components';

const WishlistReferOther = () => {
  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Wishlist}>
      <EntityManager.Title
        documentTitle={Wishlist.ReferOther.InviteOthers}
        title={Wishlist.ReferOther.SupportCharity}
      />
      <ReferOther />
    </EntityManager>
  );
};

export default WishlistReferOther;
