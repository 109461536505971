import { isUnassignedLocation } from '../../../../../locations/utils/location';

interface Props {
  row: any;
  valuePath: 'maximumQuantity' | 'minimumQuantity';
}

const ReorderReportMinMaxQuantity = (props: Props) => {
  const { row, valuePath } = props;
  if (
    row?.location?.id &&
    row?.site?.id &&
    isUnassignedLocation(row?.location?.id, row?.site?.id)
  ) {
    return (
      <span className="truncate" title={row[valuePath]}>
        {row[valuePath]}
      </span>
    );
  }

  return <span>-</span>;
};

export default ReorderReportMinMaxQuantity;