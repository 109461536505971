import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteDepartmentsMutationVariables = Types.Exact<{
  input: Types.DeleteDepartmentsInput;
}>;


export type DeleteDepartmentsMutation = { __typename?: 'Mutation', deleteDepartments: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteDepartmentsDocument = gql`
    mutation deleteDepartments($input: DeleteDepartmentsInput!) {
  deleteDepartments(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteDepartmentsMutation() {
  return Urql.useMutation<DeleteDepartmentsMutation, DeleteDepartmentsMutationVariables>(DeleteDepartmentsDocument);
};