import { relayPagination } from '@urql/exchange-graphcache/extras';
import { createClient as createWSClient } from 'graphql-ws';
import { CombinedError, errorExchange, subscriptionExchange } from 'urql';
import { SnackbarService } from 'app/components/Snackbar';
import { API_URL, APP_CONFIG } from 'app/consts/config';
import currentUser from 'app/modules/auth/utils/currentUser';
import { logout } from 'app/modules/auth/views/Login/utils/authTokens';
import { httpError5XXCodes, redirectToError5xxPage } from 'app/utils/httpCode';

import BugReportingService from '../sentry';
import authExchange from './authExchange';
import { cacheMutations } from './mutations';
import { cacheExchange, createClient, dedupExchange, fetchExchange } from './';

export const getURQLClientUrl = () => {
  return `${API_URL}/api/graphql`;
};

export const getUrqlClient = () => {
  const subscriptionClient = createWSClient({
    url: `${APP_CONFIG.apiUrls.wssAPI}/api/graphql?accessToken=${currentUser.at()}`,
    connectionParams: () => ({ headers: { Authorization: `Bearer ${currentUser.at()}` } }),
  });

  return createClient({
    url: getURQLClientUrl(),
    maskTypename: true,
    exchanges: [
      dedupExchange,
      // @ts-ignore
      cacheExchange({
        updates: { Mutation: cacheMutations },
      }),
      errorExchange({
        onError: (error: CombinedError) => {
          const status = error?.response?.status;

          if (status === 403) {
            logout();
            return;
          }

          BugReportingService.reportGraphqlError(error);
          if (status && httpError5XXCodes.has(status) && !error?.message) {
            redirectToError5xxPage();
          } else if (error?.message) {
            SnackbarService.showError({ message: error.message.replace('[GraphQL] ', ''), fullError : error });
          }
        },
      }),
      authExchange(),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) => ({
          subscribe: (sink) => {
            const dispose = subscriptionClient.subscribe(operation, sink);
            return { unsubscribe: dispose };
          },
        }),
      }),
    ],
  });
};

export const getHooverClient = () => {
  return createClient({
    url: APP_CONFIG.apiUrls.hover,
    maskTypename: true,
    exchanges: [
      dedupExchange,
      fetchExchange,
      // @ts-ignore
      cacheExchange({
        resolvers: {
          Query: {
            products: relayPagination(),
          },
        },
      }),
    ],
  });
};

export const getWishlistClient = () => {
  return createClient({
    url: `${API_URL}/public/api/graphql`,
    maskTypename: true,
    exchanges: [
      dedupExchange,
      fetchExchange,
      // @ts-ignore
      cacheExchange({
        resolvers: {
          Query: {
            wishlistByShareId: relayPagination(),
          },
        },
      }),
    ],
  });
};
