import { CheckEditDuplicateEnum } from 'app/types/contact';
import {
  CompanySchema,
  ContactSchema,
  CreateCompanyInput,
  CreateContactInput,
} from 'app/types/schema';

const shallowEqual = (object1: any, object2: any, comparisonKeys: Array<string>, checkType?: string,) => {
  const keys = Object.keys(object1).filter((i) => comparisonKeys.includes(i));
  for (let key of keys) {
  
    if(checkType === CheckEditDuplicateEnum.EDIT && (object1.id === object2.id)){
        continue;
    }else{
      if (
        object1[key] &&
        object1[key]?.toString()?.toLowerCase() === object2[key]?.toString()?.toLowerCase()
      ) {
        return true;
      }
    }
  }
  return false;
};

const handleCheckDuplicate = (
  list: Array<Partial<CompanySchema | ContactSchema>>,
  newData: Partial<CreateCompanyInput | CreateContactInput>,
  comparisonKeys: Array<string>,
  checkType?: string,
) => {
  if (list?.length > 0) {
    const isDuplicate = list.find((item: any) => {
      return shallowEqual(newData, item, comparisonKeys, checkType);
    });
    return isDuplicate;
  }
  return false;
};

export default handleCheckDuplicate;
