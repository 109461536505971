import React, { useMemo } from 'react';

export type PermissionType<T, Q> = { action: T; subject: Q };
export type Permissions<T, Q> = PermissionType<T, Q>[] | undefined | null;

export interface AccessControlProps<T, Q> {
  action: T;
  childActions?: T[];
  subject?: Q;
  childSubjects?: Q[];
  permissions?: Permissions<T, Q>;
}

/**
 *
 * @param {array} permissions list of users permissions (mandatory)
 * @param {string} action action user can perform (mandatory)
 * @param {string} subject subject on which user can perform action (mandatory)
 * @param {array<string>} childActions list of actions which are user can perform
 * @param {array<string>} childSubjects list of subjects on which user can perform actions
 * @returns {boolean}
 * for check the user has permission to perform any actions in different modules or not
 * permissions array is mandatory for checking user permissions based on action and subject
 * child actions stand for, checking other actions with the main action that the user can perform based on the subject
 * child subjects stands for, check if the user has permission to perform actions on the child module with the main module (subject)
 */
export const isAccessible = <T, Q>(
  permissions: Permissions<T, Q>,
  action: T,
  subject: Q,
  childActions?: T[],
  childSubjects?: Q[],
) => {
  let hasPermission = permissions?.some((p) => p.action === action && p.subject === subject);
  const hasChildActions = childActions && childActions?.length > 0;
  const hasChildSubjects = childSubjects && childSubjects?.length > 0;
  if (hasChildActions && hasChildSubjects) {
    if (hasPermission) {
      hasPermission = permissions?.some(
        (p) =>
          childActions?.some((ca) => ca === p.action) &&
          childSubjects.some((cs) => cs === p.subject),
      );
    }
  } else if (!hasChildActions && hasChildSubjects) {
    if (hasPermission) {
      hasPermission = permissions?.some(
        (p) => p.action === action && childSubjects.some((cs) => cs === p.subject),
      );
    }
  } else if (hasChildActions && !hasChildSubjects) {
    if (hasPermission) {
      hasPermission = permissions?.some(
        (p) => childActions?.some((ca) => ca === p.action) && p.subject === subject,
      );
    }
  }
  return hasPermission ?? false;
};

export const useAccessControl = <T, Q>(permissions: Permissions<T, Q>, action: T, subject: Q) => {
  return useMemo(() => isAccessible(permissions, action, subject), [action, permissions, subject]);
};

const AccessControl = <T, Q>(props: AccessControlProps<T, Q> & { children: React.ReactNode }) => {
  const { action, subject, permissions, children } = props;
  const canAccess = useAccessControl(permissions, action, subject);

  return canAccess ? <>{children}</> : null;
};
export default AccessControl;
