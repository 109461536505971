import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type AddressesDropDownFragment = { __typename?: 'AddressSchema', addressName: string, id: string };

export const AddressesDropDownFragmentDoc = gql`
    fragment AddressesDropDown on AddressSchema {
  addressName
  id
}
    `;