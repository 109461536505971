import memoize from 'lodash/memoize';
import { Client } from 'urql';
import { APP_CONFIG } from 'app/consts/config';
import authModel from 'app/modules/auth/model';
import { LoginTokens } from 'app/modules/auth/types';
import Cookie from 'app/utils/cookie';
import parseJwt from 'app/utils/jwt';
import BugReportingService from '../../../../../libs/sentry';
import { resetPaginationSettings } from 'app/utils/paginationSettingsUtil';

const { cookieKeys } = APP_CONFIG;

export const logout = async () => {
  BugReportingService.setUser(null);
  Cookie.remove(cookieKeys.id, { domain: cookieKeys.domain });
  Cookie.remove(cookieKeys.tenant, { domain: cookieKeys.domain });
  Cookie.remove(cookieKeys.accessToken, { domain: cookieKeys.domain });
  Cookie.remove(cookieKeys.refreshToken, { domain: cookieKeys.domain });

  // remove page settings from local storage.
  resetPaginationSettings();

  window.location.href = '/login';
};

export const setLoginCookies = (loginToken: LoginTokens, rememberMe?: boolean): Promise<void> => {
  const { userId, tenantId } = getUserVariables(loginToken.accessToken);
  Cookie.set(cookieKeys.id, userId, {
    expires: 30,
    domain: cookieKeys.domain,
  });

  Cookie.set(cookieKeys.tenant, tenantId, {
    expires: 30,
    domain: cookieKeys.domain,
  });

  Cookie.set(cookieKeys.accessToken, loginToken?.accessToken, {
    expires: 1 / 24,
    domain: cookieKeys.domain,
  });

  Cookie.set(cookieKeys.refreshToken, loginToken?.refreshToken, {
    expires: 30,
    domain: cookieKeys.domain,
  });

  return Promise.resolve();
};

export const setLoginCookieAndGetUser = async (
  client: Client,
  loginToken: LoginTokens,
  rememberMe?: boolean,
) => {
  await setLoginCookies(loginToken, rememberMe);
  await authModel.fetchUser(client);
};

const getUserVariables = memoize((accessToken: string) => {
  let userData = {
    tenantId: '',
    userId: '',
  };

  try {
    if (accessToken) {
      userData = parseJwt(accessToken);
    }
  } catch (e) {
    console.error('Error parsing userId from token', e);
  }

  return {
    tenantId: userData.tenantId || '',
    userId: userData.userId || '',
  };
});

export default getUserVariables;
