import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateOrderRequestMutationVariables = Types.Exact<{
  input: Types.UpdateOrderRequestInput;
}>;


export type UpdateOrderRequestMutation = { __typename?: 'Mutation', updateOrderRequest: { __typename?: 'UpdateOrderRequestPayload', success: boolean } };


export const UpdateOrderRequestDocument = gql`
    mutation updateOrderRequest($input: UpdateOrderRequestInput!) {
  updateOrderRequest(input: $input) {
    success
  }
}
    `;

export function useUpdateOrderRequestMutation() {
  return Urql.useMutation<UpdateOrderRequestMutation, UpdateOrderRequestMutationVariables>(UpdateOrderRequestDocument);
};