import { forwardRef, useCallback, useMemo, useState, useEffect } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import { useDistinctNetworkAssetsQuery } from '../../graphql/queries/generated/networkAssetDistinctValues';

interface Props {
  className?: string;
  selectInputClass?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string) => void;
  placeholder?: string;
  error?: FieldError | undefined;
}

type Option = {
  name: string;
  id: string;
};

const getOptionLabel = (option: any) => option.name;

const FormOperatingSystemSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    label,
    isRequired,
    disabled,
    onBlur,
    onChange,
    error,
    placeholder = '',
    selectInputClass = 'mt-6',
    value
  } = props;

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [{ fetching: isLoading, data: optionsData }] = useDistinctNetworkAssetsQuery({
    variables: {
      filters: {
        values: ['operatingSystemPlatform'],
      },
    },
  });

  const options = useMemo<any>(
    () =>
      optionsData?.distinctNetworkAssets?.distinctValues?.operatingSystemPlatform
        ?.map(({ value }, index) => {
          return {
            name: value,
            id: index,
          };
        })
        .filter((value) => value.name !== null) || [],
    [optionsData?.distinctNetworkAssets?.distinctValues?.operatingSystemPlatform],
  );

  console.log(options);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, options: any) => {
      setSelectedValue(options as Option);
      onChange?.(options?.name || '');
    },
    [onChange],
  );

  useEffect(() => {
    if (value) {
      const selected = options.find((option: Option) => option.name === value);
      setSelectedValue(selected || null);
    } else {
      setSelectedValue(null);
    }
  }, [value, options]);

  return (
    <Box ref={ref} className={cx('flex w-full flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}

      <Autocomplete
        className={`w-full text-[14px] ${selectInputClass}`}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        label={''}
        loading={isLoading}
        options={options}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
        size="small"
        sx={{
          '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment' : {
            right: '0px !important',
          },
          '& > div': {
            display: 'flex',
          },
        }}
        value={selectedValue}
        onBlur={onBlur}
        onChange={onValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormOperatingSystemSelect;
