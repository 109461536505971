import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormRestockLocationSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type FormRestockLocationSelectQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', name: string, id: string } }> } };


export const FormRestockLocationSelectDocument = gql`
    query formRestockLocationSelect($filters: LocationFilters, $sorts: [SortProps!], $limit: Float) {
  locations(filters: $filters, limit: $limit, sorts: $sorts) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

export function useFormRestockLocationSelectQuery(options?: Omit<Urql.UseQueryArgs<FormRestockLocationSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormRestockLocationSelectQuery>({ query: FormRestockLocationSelectDocument, ...options });
};