import Contacts from 'app/modules/contacts/layout/index';
import Company from 'app/modules/contacts/views/Company';

const CompnayPage = () => {
  return (
    <Contacts currentPage="Company">
      <Company />
    </Contacts>
  );
};
export default CompnayPage;
