import { OrderRequestItemSchema, ReceivableSchema } from 'app/types/schema';

export function getReceivingSKUItemData(data: ReceivableSchema): OrderRequestItemSchema {
  const itemId = data.itemId;
  const orderRequestItems = data.orderRequest?.orderRequestItems;

  if (!itemId) {
    // Non SKU Items will not have itemId
    return data.orderRequestItem as OrderRequestItemSchema;
  }
  const findOrderRequestItem = orderRequestItems?.find((item) => item.itemId === itemId);

  return findOrderRequestItem as OrderRequestItemSchema;
}
