import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateEmailIdMutationVariables = Types.Exact<{
  input: Types.UpdateEmailIdInput;
}>;


export type UpdateEmailIdMutation = { __typename?: 'Mutation', updateEmailId: { __typename?: 'UpdateUserPayload', success: boolean, reason?: string | null, user?: { __typename?: 'UserSchema', id: string } | null } };


export const UpdateEmailIdDocument = gql`
    mutation updateEmailId($input: UpdateEmailIdInput!) {
  updateEmailId(input: $input) {
    success
    reason
    user {
      id
    }
  }
}
    `;

export function useUpdateEmailIdMutation() {
  return Urql.useMutation<UpdateEmailIdMutation, UpdateEmailIdMutationVariables>(UpdateEmailIdDocument);
};