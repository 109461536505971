import { ToolTip } from 'app/libs/ToolTip';

import { OrderRequestSchema } from '../../../../../../types/schema';
import OrderLink from '../../../../components/OrderLink';
import OrderIdToolTip from '../OrderIdToolTip';

interface ITableOrderIdView {
  row: OrderRequestSchema;
  target?: string;
}

const TableOrderIdView = (props: ITableOrderIdView) => {
  const { row, target = '_self' } = props;
  const { type, orderRequestCode, id, orderRequestItems } = row;

  return (
    <ToolTip
      key={id + '-order-history-hover-tooltip'}
      overlay={() => <OrderIdToolTip row={row} />}
      overlayClassName="order-history-tooptip"
      placement="bottom"
      trigger={['hover']}>
      <div className="truncate">
        <OrderLink shouldTruncate id={id} target={target} type={type}>
          {orderRequestCode}
        </OrderLink>
      </div>
    </ToolTip>
  );
};

export default TableOrderIdView;
