import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditSiteDataFragmentDoc } from '../../fragments/generated/editSiteData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditSiteDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
}>;


export type EditSiteDataQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, locationCode?: string | null, type: Types.LocationTypeEnum, siteStaffIds?: Array<string> | null, siteManagerIds?: Array<string> | null, formattedDescription?: string | null } }> } };


export const EditSiteDataDocument = gql`
    query editSiteData($filters: LocationFilters) {
  locations(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditSiteData
      }
    }
  }
}
    ${EditSiteDataFragmentDoc}`;

export function useEditSiteDataQuery(options?: Omit<Urql.UseQueryArgs<EditSiteDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditSiteDataQuery>({ query: EditSiteDataDocument, ...options });
};