import Loader from 'app/components/Loader';

import {
  getWarehouseLocations,
  parseWarehouseLocationName,
  WAREHOUSE_LABEL_CONFIG,
} from '../../../WarehouseDetails/utils';
import { useWarehouseLocationsQuery } from '../../graphql/queries/generated/warehouseLocations';
import { WarehouseLocationsProps } from '../../types';
import WarehouseItems from '../WarehouseItems';
import { useEffect } from 'react';

const WarehouseAisles = (props: WarehouseLocationsProps) => {
  const { warehouseId, selectedItems, handleLocations } = props;

  const item = selectedItems[0];
  const { zone, aisle } = parseWarehouseLocationName(item);

  const [{ fetching, data }] = useWarehouseLocationsQuery({
    variables: {
      filters: { warehouseIds: [warehouseId] },
      input: { zoneName: zone, maxChildIdentifier: aisle },
    },
    requestPolicy: 'cache-and-network',
  });

  const aisles = getWarehouseLocations(data);

  useEffect(() => {
    handleLocations(aisles);
   },[fetching]); 

  if (fetching) {
    return <Loader />;
  }

  return (
    <WarehouseItems
      itemKey="aisles"
      items={aisles}
      rackIncrementor={data?.warehouses.edges[0]?.node?.rackIncrementor || undefined}
      label={WAREHOUSE_LABEL_CONFIG.zones.getLabelFromLocation(item)}
      subItemKey="racks"
      {...props}
    />
  );
};

export default WarehouseAisles;
