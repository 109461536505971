import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';

const useApiEmailAvailabilityCheck = () => {
  const [fetchStatus, error, data, execute] = useRest(`${API_URL}/tenant/exists`);

  const isFetching = fetchStatus === FetchingStatus.Fetching;
  const executeAPI = useCallback(
    async (values: { email: string }) =>
      execute({
        body: JSON.stringify({
          adminEmailId: values.email
        }),
      }),
    [execute],
  );
  return { fetchingApi: isFetching, error, data, executeEmailAvailabilityCheck: executeAPI };
};

export default useApiEmailAvailabilityCheck;
