import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import Reports from 'app/i18n/Reports';
import {
  TransactionEntityTypeEnum,
  TransactionFilters,
  TransactionSubTypeEnum,
  TransactionTypeEnum,
} from 'app/types/schema';

export const TRANSACTION_TYPE_ITEMS = [
  { id: 'new', value: 'new', label: Reports.TransactionTypes.New },
  { id: 'move', value: 'move', label: Reports.TransactionTypes.Move },
  { id: 'checkin', value: 'checkin', label: Reports.TransactionTypes.CheckIn },
  { id: 'checkout', value: 'checkout', label: Reports.TransactionTypes.CheckOut },
  { id: 'remove', value: 'remove', label: Reports.TransactionTypes.Remove },
  { id: 'removeKit', value: 'removeKit', label: Reports.TransactionTypes.RemoveKit },
  { id: 'reserve', value: 'reserve', label: Reports.TransactionTypes.Reserve },
  { id: 'restock', value: 'restock', label: Reports.TransactionTypes.Restock },
  { id: 'restockKit', value: 'restockKit', label: Reports.TransactionTypes.RestockKit },
  { id: 'audit', value: 'audit', label: Reports.TransactionTypes.Audit },
  { id: 'delete', value: 'delete', label: Reports.TransactionTypes.Delete },
  // { id: 'moveShip', value: 'moveShip', label: Reports.TransactionTypes.Move },
  // { id: 'moveOrder', value: 'moveOrder', label: Reports.TransactionTypes.Move },
  // { id: 'kit', value: 'kit', label: Reports.TransactionTypes.Kit },
];

const ASSET_TRANSACTION_TYPES = ['checkin', 'checkout', 'reserve'];
export enum REPORT_TYPES {
  ACCOUNT = 'ACCOUNT',
  TRANSACTION = 'TRANSACTION',
  CUSTOM = 'CUSTOM',
}
export const getTransactionTypeItems = (
  reportType: REPORT_TYPES,
  types?: TransactionEntityTypeEnum[],
) => {
  let transactionItemTypes = [];
  transactionItemTypes = TRANSACTION_TYPE_ITEMS;
  if (
    types?.includes(TransactionEntityTypeEnum.Inventory) &&
    !types?.includes(TransactionEntityTypeEnum.Asset)
  ) {
    transactionItemTypes = transactionItemTypes.filter((transactionTypeItem) => {
      return !ASSET_TRANSACTION_TYPES.includes(transactionTypeItem.id);
    });
  }

  if (reportType === REPORT_TYPES.ACCOUNT) {
    transactionItemTypes = transactionItemTypes.filter((item) => item.value !== 'reserve');
  }

  return transactionItemTypes;
};

export const TRANSACTION_TYPE_CONFIG: Record<string, TransactionFilters> = {
  new: {
    types: [TransactionTypeEnum.Restock],
    subTypes: [TransactionSubTypeEnum.New],
  },
  move: {
    types: [TransactionTypeEnum.Move],
    subTypes: [
      TransactionSubTypeEnum.Move,
      TransactionSubTypeEnum.OrderShip,
      TransactionSubTypeEnum.Ship,
      TransactionSubTypeEnum.ExternalOrderIn,
    ],
  },
  checkin: {
    types: [TransactionTypeEnum.Move],
    subTypes: [TransactionSubTypeEnum.Checkin],
  },
  checkout: {
    types: [TransactionTypeEnum.Move],
    subTypes: [TransactionSubTypeEnum.Checkout],
  },
  remove: {
    types: [TransactionTypeEnum.Remove],
    subTypes: [TransactionSubTypeEnum.Remove],
  },
  restock: {
    types: [TransactionTypeEnum.Restock],
    subTypes: [TransactionSubTypeEnum.Restock, TransactionSubTypeEnum.Receive],
  },
  audit: {
    types: [TransactionTypeEnum.Audit],
    subTypes: [TransactionSubTypeEnum.Audit],
  },
  delete: {
    types: [TransactionTypeEnum.Remove],
    subTypes: [TransactionSubTypeEnum.Delete],
  },
  undelete: {
    types: [TransactionTypeEnum.Restock],
    subTypes: [TransactionSubTypeEnum.Undelete],
  },
  restockKit: {
    types: [TransactionTypeEnum.Restock],
    subTypes: [TransactionSubTypeEnum.Kit],
  },
  removeKit: {
    types: [TransactionTypeEnum.Remove],
    subTypes: [TransactionSubTypeEnum.Kit],
  },
  reserve: {
    types: [TransactionTypeEnum.Reserve],
    subTypes: [TransactionSubTypeEnum.Reserve],
  },
  /**  */
  // moveShip: {
  //   types: [TransactionTypeEnum.Move],
  //   subTypes: [TransactionSubTypeEnum.Ship],
  // },
  // moveOrder: {
  //   types: [TransactionTypeEnum.Move],
  //   subTypes: [TransactionSubTypeEnum.OrderShip],
  // },
  // kit: {
  //   _or: [
  //     {
  //       types: [TransactionTypeEnum.Restock],
  //       subTypes: [TransactionSubTypeEnum.Kit],
  //     },
  //     {
  //       types: [TransactionTypeEnum.Remove],
  //       subTypes: [TransactionSubTypeEnum.Kit],
  //     },
  //   ],
  // },
};

export const getTransactionFilterValues = (values: string[]): TransactionFilters[] => {
  return values.map((value) => TRANSACTION_TYPE_CONFIG[value]);
};

export const getExportTransactionFilterValues = (values: string[]) => {
  return getTransactionFilterValues(values).map((filters) => ({
    ...filters,
    types: filters.types?.map(camelCase),
    subTypes: filters.subTypes?.map(camelCase),
  }));
};

export const getTransactionTypeLabel = (
  type: TransactionTypeEnum,
  subType: TransactionSubTypeEnum,
): string => {
  let label = '';

  Object.entries(TRANSACTION_TYPE_CONFIG).forEach(([key, value]) => {
    if (value.types?.includes(type) && value.subTypes?.includes(subType)) {
      const item = TRANSACTION_TYPE_ITEMS.find((item) => item.value === key);
      label = item?.label ?? '';
    }
  });

  if (!label) {
    label = `${capitalize(type)} ${capitalize(subType)}`;
  }

  return label;
};
