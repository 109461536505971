import { ReactElement, ReactNode, useMemo } from 'react';

import { useAccessControl } from '../../../../../../components/AccessControl';
import { UsePaginationReturnType } from '../../../../../../hooks/usePagination';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestSchema,
} from '../../../../../../types/schema';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import EntityManager from '../../../../../components/EntityManager';
import { UseEntityManagerReturnType } from '../../../../../components/EntityManager/useEntityManager';
import {
  getOrderHistoryTableColumnsWithDeletePermission,
  getOrderHistoryTableColumnsWithoutDeletePermission,
} from '../../utils/columns';
import OrderItemsTable from '../OrderItemsTable';
import { UseOrderHistoryFiltersReturnType } from '../../hook/useOrderHistoryFilters';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  rows: OrderRequestSchema[];
  count: number;
  onNextPage: UsePaginationReturnType['onNextPage'];
  onPrevPage: UsePaginationReturnType['onPrevPage'];
  onReset: UsePaginationReturnType['onReset'];
  fetching?: boolean;
  onDeleteOrder?: (orderId: string) => void;
  deleteOrderState: {
    deletingOrder?: boolean;
    deletingOrderId: string | null;
    deletingBulkOrder?: boolean;
  };
  filtersState: UseOrderHistoryFiltersReturnType['filtersState'];
  setFilterState: UseOrderHistoryFiltersReturnType['setFilterState'];
  filterNodes?: ReactNode | ReactElement;
  paginationWrapper?: string;
  isRowSelectable?: (row: any) => boolean;
}

const OrderHistoryTable = (props: Props) => {
  const {
    table,
    rows,
    onReset,
    onPrevPage,
    onNextPage,
    count,
    fetching,
    onDeleteOrder,
    deleteOrderState,
    setFilterState,
    filtersState,
    filterNodes,
    paginationWrapper,
    isRowSelectable,
  } = props;

  const renderDetailPanel = (data: { row: OrderRequestSchema }) => (
    <OrderItemsTable row={data.row} />
  );

  const { workspacePermissions } = useCurrentUser();

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.OrderRequest,
  );

  const columns = useMemo(() => {
    return canDelete
      ? getOrderHistoryTableColumnsWithDeletePermission()
      : getOrderHistoryTableColumnsWithoutDeletePermission();
  }, [canDelete]);

  return (
    <EntityManager.MultiSelectTable
      ignoreRelayPagination
      pagination
      actions={table.actions}
      columns={columns}
      data={rows}
      extraProps={{
        onDeleteOrder,
        deleteOrderState,
        setFilterState,
        filtersState,
      }}
      isRowSelectable={isRowSelectable}
      getDetailPanelContent={renderDetailPanel}
      getDetailPanelHeight={() => 'auto'}
      loading={fetching}
      persistKey="order-history-table"
      setState={table.setState}
      state={table.state}
      total={count}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      onReset={onReset}
      filterNodes={filterNodes}
      paginationWrapperClass={paginationWrapper}
    />
  );
};

export default OrderHistoryTable;
