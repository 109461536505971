import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { sortBy } from '../../../../utils/sort';
import { adaptLocation, getLocationName, isUnassignedLocation } from '../../utils/location';
import { LocationSelectorOption } from './types';

export const adaptLocationToOption = (
  edges: any[],
  siteId?: string | null | undefined,
): LocationSelectorOption[] => {
  const options: LocationSelectorOption[] = [];
  const unAssignedOptions: LocationSelectorOption[] = [];

  edges.forEach((item) => {
    const option = adaptNodeEdgeToNode(item);

    if (isUnassignedLocation(option.id, siteId || '')) {
      unAssignedOptions.push(adaptLocation(siteId || '', option));
    } else {
      options.push(option);
    }
  });

  return [...unAssignedOptions, ...options.sort(sortBy('name'))];
};
