import { CreateMediaInput, EntitySourceEnum } from 'app/types/schema';

import { ItemInStock } from '../../type';
import { UseOrderRequestFormReturnType } from './hook/useOrderRequestForm';

export enum ORDER_INSERT_TYPE {
  ITEM_IN_STOCK = 'ITEM_IN_STOCK',
  UPC_OR_ASIN_CODE = 'UPC_OR_ASIN_CODE',
  WEBSITE = 'WEBSITE',
  IMAGE = 'IMAGE',
  DESCRIPTION = 'DESCRIPTION',
  NON_STOCKED_ITEMS = 'NON_STOCKED_ITEMS',
}

export enum FormFieldType {
  ITEM_IN_STOCK = 'ITEM_IN_STOCK',
  NON_STOCKED_ITEMS = 'NON_STOCKED_ITEMS',
}

export interface OrderRequestInput {
  id?: string;
  itemId: string;
  itemInStock?: ItemInStock;
  upcCode?: string;
  website?: string;
  imageUrl?: CreateMediaInput[];
  description?: string;
  projectId: string;
  projectName: string;
  quantity: string;
  cost: string;
  itemType?: ORDER_INSERT_TYPE;
  lastUpdatedProject?: boolean;
  partnerTenantId?: string;
}

export interface OrderFieldProps {
  formState: UseOrderRequestFormReturnType['formState'];
  disabled?: boolean;
  isExternalTenant?: boolean;
  validateAllEmpty?: () => string | boolean;
  formLabel?:string
  isRequired?:boolean
}
