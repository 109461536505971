import React, { useCallback, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import useShop from 'app/modules/shop/context/useShop';
import Box from 'app/ui-components';

import { isObjectEmpty } from '../../utils';

export type ChipData = {
  key: number;
  label: string;
  val: string;
};

interface Props {
  value: string;
  chipData: ChipData[];
  setValue: (value: React.SetStateAction<string>) => void;
  setChipData: (value: React.SetStateAction<ChipData[]>) => void;
  setError: (value: React.SetStateAction<boolean>) => void;
  onSearchModal?: (data: any) => void;
}

const ChipList: React.FC<Props> = ({
  value,
  chipData,
  setChipData,
  setValue,
  onSearchModal,
  setError,
}) => {
  const { searchQuery, setState } = useShop();

  useEffect(() => {
    if (isEmpty(searchQuery) || isObjectEmpty(searchQuery)) {
      setChipData([
        { key: 0, label: 'Code', val: '' },
        { key: 1, label: 'Name', val: '' },
        { key: 2, label: 'Model', val: '' },
        { key: 3, label: 'Brand', val: '' },
        { key: 4, label: 'ISBN', val: '' },
      ]);
    }
  }, [searchQuery]);

  const handleDelete = (chipToDelete: any) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete?.key));
    const deleteData: any = { ...searchQuery };
    const deleteLabel = chipToDelete.label.toLowerCase();
    deleteData[deleteLabel] = '';

    if (chipToDelete.val === value) {
      setValue('');
    }

    setState && setState({ searchQuery: deleteData });

    onSearchModal && onSearchModal(deleteData);

    setError(false);
  };

  const chipLabel = useCallback((label: string) => {
    if (label.toLowerCase() === 'isbn') {
      return label.toUpperCase();
    } else {
      return startCase(camelCase(label));
    }
  }, []);

  return (
    <>
      <Box className={'mt-[16px] mb-[24px] flex flex-row flex-wrap'}>
        {chipData
          ?.filter((i) => i.val)
          ?.map((data) => {
            const label = chipLabel(data.label);
            return (
              <Box key={data.key} className={'w-fit'}>
                <Box
                  className={
                    ' flex max-w-[250px] items-center justify-between rounded-[16px] bg-grey-300 py-[6px] px-[16px] md:max-w-[400px] lg:max-w-[600px] xl:max-w-[600px]'
                  }>
                  <span
                    className={
                      ' overflow-hidden text-ellipsis whitespace-nowrap text-[12px] font-medium text-grey-900'
                    }>{`${label}: ${data.val}`}</span>
                  <Box
                    className={
                      ' ml-[10px] cursor-pointer border-none bg-none text-[#ddd] outline-none'
                    }
                    onClick={data.label === 'React' ? undefined : handleDelete(data)}>
                    <CloseIcon fontSize="small" className="text-grey-900" />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default ChipList;
