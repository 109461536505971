import { Controller } from 'react-hook-form';
import Shop from 'app/i18n/Shop';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';
import { AFFILIATE_MERCHANT_FORM_RULES } from '../utils/utils';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
}

const AffiliateMerchantNameField = (props: Props) => {
  const { formState, disabled } = props;
  const { control, setValue } = formState;

  const onChange = (text: string) => {
    const _text = text.replace(/\s{2,}|\./g, ' ')
    setValue('name', _text);
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isRequired
            characterLimit={32}
            error={fieldState.error}
            isDisabled={disabled}
            label={Shop.Admin.FormLabel.Name}
            onChange={onChange}
          />
        )}
        rules={AFFILIATE_MERCHANT_FORM_RULES.name}
      />
    </div>
  );
};

export default AffiliateMerchantNameField;
