import { useCallback, useMemo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import EntityManager from 'app/modules/components/EntityManager';

import { getReceiveTableColumns, getShipmentReceiveTableColumns } from '../../../utils/columns';
import useScheduledLists from '../../hook/useScheduledList';

function ScheduledReceiving(props: any) {
  const { onNext, previewRows, title, onCancel, filterNodes, onRemoveRow } = props;
  const { table, rows, rowCount, onPrevPage, onNextPage, onReset, fetching } = useScheduledLists();

  const tableColumns = useMemo(() => getReceiveTableColumns(), []);
  const shipmentReceiveTableColumn = useMemo(() => getShipmentReceiveTableColumns(), []);

  const handleOnNext = useCallback(() => {
    onNext(previewRows);
  }, [previewRows]);

  return (
    <Box className="flex-1">
      <EntityManager.Title
        title={(previewRows?.length > 0 && title) || 'Scheduled Shipments'}
        titleWrapperClass="!pt-[0px] !mt-[6px]"
      />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          pagination
          actions={table.actions}
          columns={shipmentReceiveTableColumn || []}
          data={previewRows}
          minWidth={0}
          extraProps={{
            onRemoveRow,
          }}
          paginationWrapperClass="p-[24px]"
          persistKey="schedule_receive_table"
          setState={table.setState}
          state={table.state}
          testId="schedule_receive_table"
          total={previewRows.length || 0}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={filterNodes}
        />
        {previewRows?.length > 0 && (
          <div className="flex justify-end gap-[16px] py-[16px] px-[24px]">
            <Button classes="min-w-[94px] h-[44px]" theme="info" onClick={handleOnNext}>
              {Common.Actions.Next}
            </Button>

            <Button classes="min-w-[94px] h-[44px]" onClick={onCancel}>
              {Common.Actions.Cancel}
            </Button>
          </div>
        )}{' '}
      </Box>
    </Box>
  );
}

export default ScheduledReceiving;
