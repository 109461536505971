import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import routes from 'app/consts/routes';
import Login from 'app/i18n/Login';
import { Link } from 'app/libs/navigation';
import { LoginFormProps } from 'app/modules/auth/types';
import { LOGIN_FORM_RULES } from 'app/modules/auth/views/Login/components/LoginForm/rules';
import { ButtonHeight } from 'app/types/button';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import FormCheckBoxInput from '../../../../../../components/ProcureForm/FormCheckBoxInput';
import FormPasswordInput from '../../../../../../components/ProcureForm/FormPasswordInput';

const LOGO = `${process.env.PUBLIC_URL}/images/procure-square-logo-96px.png`;
const DEFAULT_VALUES = { email: '', password: '', rememberMe: true };

function LoginForm(props: LoginFormProps) {
  const { disabled, onSubmit } = props;

  const { control, setValue, handleSubmit } = useForm({ defaultValues: DEFAULT_VALUES });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  const onEmailChange = (field: any) => {
    setValue('email', field.replace(/\s+/g, ''));
  };

  const onPasswordChange = (field: any) => {
    setValue('password', field.replace(/\s+/g, ''));
  };

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <img alt="Logo" className="mx-auto" src={LOGO} />
      <h2 className="mt-12 text-center text-[30px] font-semibold text-grey-900">Welcome!</h2>
      <Box className="mt-[16px] space-y-24">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              error={fieldState.error}
              formLabel={Login.FormLabels.Email}
              placeholder={Login.FormLabels.Email}
              onChange={onEmailChange}
            />
          )}
          rules={LOGIN_FORM_RULES.email}
        />
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <Box className="min-w-full">
              <FormPasswordInput
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                formLabel={Login.FormLabels.Password}
                placeholder={Login.FormLabels.Password}
                onChange={onPasswordChange}
              />
            </Box>
          )}
          rules={LOGIN_FORM_RULES.password}
        />
        <Stack alignItems="center" justifyContent="between">
          <Controller
            control={control}
            name="rememberMe"
            render={({ field }) => (
              <FormCheckBoxInput
                label={Login.FormLabels.RememberMe}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Box className="forgot-password-link font-medium">
            <Link to={routes.ForgotPassword()}>Forgot Password?</Link>
          </Box>
        </Stack>
        <Box className="flex justify-center">
          <Button
            classes="!min-w-[100%]"
            disabled={disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}>
            {Login.FormActions.LOGIN}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default LoginForm;
