import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';

import LocationUserDetail from './components/LocationUserDetail';
import LocationUserFormModal from './components/LocationUserFormModal';
import WarrantyAcquistionDetail from './components/WarrantyAcquistionDetail';
import WarrantyFormModal from './components/WarrantyFormModal';
import useLocationUserDataForm from '../../hook/useNetworkAssetData';

const OverviewDetail = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;
  const mac = networkAssetDetail?.mac || '';
  const [userModal, setUserModal] = useState(false);
  const [warrantyModal, setWarrantyModal] = useState(false);

  const locationUserData = {
    userName: networkAssetDetail?.userName || '',
    email: networkAssetDetail?.email || '',
    destinationLocationName: networkAssetDetail?.location?.name || '',
    destinationSiteName: networkAssetDetail?.site?.name || '',
    destinationLocationId: networkAssetDetail?.location?.id || '',
    destinationSiteId: networkAssetDetail?.site?.id || '',
  };

  const WarrantyData = {
    startDate: networkAssetDetail?.warranty?.startDate || '',
    endDate: networkAssetDetail?.warranty?.endDate || '',
  };

  const locationUserFormDefaultValues = useMemo(() => {
    return ({
      userName: networkAssetDetail?.userName,
      email: networkAssetDetail?.email,
      destinationLocationId: networkAssetDetail?.location?.id,
      destinationSiteId: networkAssetDetail?.site?.id,
    });
  }, [networkAssetDetail]);

  const {
    formState
  } = useLocationUserDataForm({
    defaultValues: locationUserFormDefaultValues,
  });

  return (
    <>
      <Box sx={{ flexGrow: 1}}>
        <LocationUserDetail locationUserDetail={locationUserData} setUserModal={setUserModal} />
        <WarrantyAcquistionDetail
          setWarrantyModal={setWarrantyModal}
          warrantyEndDate={networkAssetDetail?.warranty?.endDate || ''}
          warrantyStartDate={networkAssetDetail?.warranty?.startDate || ''}
        />
      </Box>

      <LocationUserFormModal
        isOpen={userModal}
        mac={mac}
        onCancel={() => {
          setUserModal(false)
          formState.reset(locationUserFormDefaultValues);
        }}
        onSavedUser={() => setUserModal(false)}
        formState={formState}
      />

      <WarrantyFormModal
        isOpen={warrantyModal}
        mac={mac}
        warrantyData={WarrantyData}
        onCancel={() => setWarrantyModal(false)}
        onSavedUser={() => setWarrantyModal(false)}
      />
    </>
  );
};

export default OverviewDetail;
