import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BellNotificationByUserSubscriptionVariables = Types.Exact<{
  data: Types.BellNotificationByUserSubscriptionInput;
}>;


export type BellNotificationByUserSubscription = { __typename?: 'Subscription', bellNotificationByUser: { __typename?: 'BellNotificationSchema', id: string, tenantId: string, userId: string, title: string, description?: string | null, webClickUrl?: string | null, mobileClickUrl?: string | null, showButton?: boolean | null, buttonLabel?: string | null, buttonWebUrl?: string | null, buttonMobileUrl?: string | null, status: Types.BellNotificationStatusEnum } };


export const BellNotificationByUserDocument = gql`
    subscription bellNotificationByUser($data: BellNotificationByUserSubscriptionInput!) {
  bellNotificationByUser(data: $data) {
    id
    tenantId
    userId
    title
    description
    webClickUrl
    mobileClickUrl
    showButton
    buttonLabel
    buttonWebUrl
    buttonMobileUrl
    status
  }
}
    `;

export function useBellNotificationByUserSubscription<TData = BellNotificationByUserSubscription>(options: Omit<Urql.UseSubscriptionArgs<BellNotificationByUserSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<BellNotificationByUserSubscription, TData>) {
  return Urql.useSubscription<BellNotificationByUserSubscription, TData, BellNotificationByUserSubscriptionVariables>({ query: BellNotificationByUserDocument, ...options }, handler);
};