import { useCallback, useMemo } from 'react';
import { Layout, SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { FeatureFlagProvider } from 'app/libs/featureFlag';
import { RoutePropsWithChildren, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';

import TabLayout from '../TabLayout';
import { TabOption } from '../TabLayout/types';
import { getAdminLayoutRoutesConfig, getAdminLayoutSidebarItems } from './utils';

function AdminLayout(
  props: RoutePropsWithChildren & { customTabs?: Array<TabOption>; defaultTab?: string },
) {
  const { children, history, match, customTabs, defaultTab } = props;
  const currentUser = useCurrentUser();

  const routesConfig = useMemo(() => getAdminLayoutRoutesConfig(currentUser), [currentUser]);

  const onSidebarItemClick = useCallback(
    (item: any) => {
      const config = routesConfig.find((config) => config.id === item.id);

      if (config) {
        history.push(config.route);
      }
    },
    [history, routesConfig],
  );

  const sideBarItemGroup = useMemo(
    () => getAdminLayoutSidebarItems(currentUser.workspacePermissions),
    [currentUser.workspacePermissions],
  );

  const selectedItemId = useMemo(() => {
    const selectedItem = routesConfig.find(({ routes }) => routes.includes(match.path));
    return selectedItem ? selectedItem.id : '';
  }, [match.path, routesConfig]);

  const filterMenuItems: SideBarItemGroup[] = useMemo(() => {
    return sideBarItemGroup.filter((item) => item.sideBarItems.length > 0) || [];
  }, [sideBarItemGroup]);

  return (
    <Layout
      withAppBar
      withDrawer={!!filterMenuItems.length}
      components={{ AppBar: <UnifiedHeader /> }}
      sideBarProps={{
        sideBarItemGroup: filterMenuItems,
        selectedItemId,
        onClick: onSidebarItemClick,
      }}>
      {customTabs ? (
        <TabLayout
          className="rounded-tr-[8px] border-grey-900"
          defaultTab={defaultTab}
          routes={routesConfig}
          tabs={customTabs}
        />
      ) : null}
      <FeatureFlagProvider>{children}</FeatureFlagProvider>
    </Layout>
  );
}

export default withRouter(AdminLayout);
