import { getCostWithCurrency } from '../../../utils/cost';
import { SelectOption } from '../views/ITAssetReport/components/MultiSelect/types';

export const getTotalCost = (cost?: number, totalQuantity?: number) => {
  return getCostWithCurrency((cost || 0) * (totalQuantity || 0));
};

export const getUnitCost = (cost?: number) => {
  return getCostWithCurrency(cost || 0);
};

export const toTitleCase = (input: string) => {
  const result = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  
  return result
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const labelSort = (data: SelectOption[]) => {
  data.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
        return -1;
    }
    if (labelA > labelB) {
        return 1;
    }
    return 0;
  });
  
  return data;
}
