import { Controller } from 'react-hook-form';
import Orders from 'app/i18n/Orders';
import FormNumberInput from 'app/modules/components/Procure/FormNumberInput';

import {
  FIELD_ARRAY_KEY,
  UseBulkOrderRequestReturnType,
} from '../../../OrderItemRequest/hook/useBulkOrderRequest';
import { OrderRequestInput } from '../../../OrderRequestForm/type';
import { ORDER_REQUEST_FORM_RULES } from '../../../OrderRequestForm/utils/rules';
import { useCallback } from 'react';

interface Props {
  state: UseBulkOrderRequestReturnType['bulkOrderState'];
  index: number;
  disabledValidation: boolean;
  disabled?: boolean;
  onUpdateOrderItem: (index: number, nextState: Partial<OrderRequestInput>) => void;
}

const BulkOrderQuantityField = (props: Props) => {
  const { disabledValidation, index, state, onUpdateOrderItem, disabled } = props;
  const { control, trigger } = state;

  const onQtyChange = useCallback(
    (quantity: string) => {
      onUpdateOrderItem(index, {
        quantity: quantity,
      });
    },
    [onUpdateOrderItem, index],
  );

  return (
    <div className="bulk-order-item-quantity-input">
      <Controller
        control={control}
        name={`${FIELD_ARRAY_KEY}.${index}.quantity`}
        render={({ field, fieldState }) => {
          return (
            <FormNumberInput
              {...field}
              isRequired
              disabled={disabled}
              error={fieldState.error}
              label={Orders.FormLabels.Quantity}
              minimumValue={0}
              maximumLength={7}
              placeholder={Orders.FormLabels.Quantity}
              onChange={(value) => {
                field.onChange(value);
                onQtyChange(value);
              }}
              formLabel='Quantity'
            />
          );
        }}
        rules={disabledValidation ? undefined : ORDER_REQUEST_FORM_RULES.quantity}
      />
    </div>
  );
};
export default BulkOrderQuantityField;
