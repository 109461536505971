import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReportJobSubscriptionVariables = Types.Exact<{
  data: Types.JobByCreatorSubscriptionInput;
}>;


export type ReportJobSubscription = { __typename?: 'Subscription', jobByCreator: { __typename?: 'JobByCreatorSubscriptionPayload', operation: Types.JobSubscriptionOperationEnum, job: { __typename?: 'JobSchema', id: string, name: string, createdAt: string, status: Types.JobStatusEnum, error?: string | null, exportJobMetadata?: { __typename?: 'ExportJobMetadataType', exportReportUrl: string } | null } } };


export const ReportJobDocument = gql`
    subscription reportJob($data: JobByCreatorSubscriptionInput!) {
  jobByCreator(data: $data) {
    operation
    job {
      id
      name
      createdAt
      status
      error
      exportJobMetadata {
        exportReportUrl
      }
    }
  }
}
    `;

export function useReportJobSubscription<TData = ReportJobSubscription>(options: Omit<Urql.UseSubscriptionArgs<ReportJobSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<ReportJobSubscription, TData>) {
  return Urql.useSubscription<ReportJobSubscription, TData, ReportJobSubscriptionVariables>({ query: ReportJobDocument, ...options }, handler);
};