import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import Undelete from 'app/i18n/Undelete';
import EntityManager from 'app/modules/components/EntityManager';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';

import Stack from '../../../../ui-components/Stack';
import { UseDeletedStateReturnType } from '../../views/undelete/hook/useDeletedItemsState';
import { UseSearchSuggestionReturnType } from '../../views/undeleteInventory/hook/useSearchSuggestion';

interface Props {
  search: UseMultiSelectEntityManagerReturnType['search'];
  filters: UseDeletedStateReturnType['filters'];
  searchSuggestions: UseSearchSuggestionReturnType['searchSuggestions'];
  setSearchValue: UseSearchSuggestionReturnType['setSearchValue'];
}

function SearchBox(props: Props) {
  const { search, filters, setSearchValue, searchSuggestions } = props;

  return (
    <>
      <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
        <Box className="w-[400px]">
          <EntityManager.Search
            isDropdown
            removedSearchQoutedValue
            suggestionData={searchSuggestions || []}
            onChangeSearch={setSearchValue}
            {...search}
            autoFocus
            placeholder="Search"
            startAdornment={
              <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                <CustomIcons.SearchIcon />
              </InputAdornment>
            }
          />
        </Box>

        <FormSiteSelect
          enabledAllOption
          className="w-full md:w-[374px] lg:w-[180px]"
          // label={Undelete.FormLabels.Site}
          value={filters.site.value}
          onChange={filters.site.onChange}
        />
      </Box>
    </>
  );
}

export default SearchBox;
