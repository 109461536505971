import { Button } from '@procurenetworks/procure-component-library';
import Reports from 'app/i18n/Reports';
import Stack from 'app/ui-components/Stack';

interface Props {
  disabled?: boolean;
  onSubmit?: () => void;
  onClear?: () => void;
}

const ReportFormActions = (props: Props) => {
  const { disabled, onSubmit, onClear } = props;

  return (
    <Stack justifyContent="end" spacing="space-x-[16px]">
      <Button
        disabled={disabled}
        loading={disabled}
        theme="success"
        onClick={onSubmit}
        classes="max-w-[95px] h-[44px]">
        {Reports.Actions.Generate}
      </Button>
      <Button onClick={onClear} classes="min-w-[86px] h-[44px]">
        {Reports.Actions.Clear}
      </Button>
    </Stack>
  );
};

export default ReportFormActions;
