import { withRouter } from 'app/libs/navigation';
import { RouteComponentProps } from 'app/libs/navigation';
import EditAffiliateMerchant from 'app/modules/shop/view/admin/EditAffiliateMerchant';

import ShopLayout from '../../../modules/shop/layout';

function EditAffiliateConfigurationPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <ShopLayout>
      <EditAffiliateMerchant affiliateConfigurationId={match.params.id} />
    </ShopLayout>
  );
}

export default withRouter(EditAffiliateConfigurationPage);
