import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string
};

const GraphicsForm = (props: Props) => {
  const { control ,className="mt-[0px]"} = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Graphics.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="graphicsVendor"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Graphics.Vendor}
                placeholder={Network.FormLabels.Graphics.Vendor}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="graphicsModel"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
              containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Graphics.Model}
                placeholder={Network.FormLabels.Graphics.Model}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="graphicsBus"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
              containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Graphics.Bus}
                placeholder={Network.FormLabels.Graphics.Bus}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="graphicsVram"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
              containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Graphics.VRAM}
                placeholder={Network.FormLabels.Graphics.VRAM}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GraphicsForm;
