import { Controller } from 'react-hook-form';
import Shop from 'app/i18n/Shop';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';
import { AFFILIATE_MERCHANT_FORM_RULES } from '../utils/utils';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
}

const AffiliateMerchantDomainField = (props: Props) => {
  const { formState, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="domain"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isRequired
            error={fieldState.error}
            isDisabled={disabled}
            label={Shop.Admin.FormLabel.Domain}
          />
        )}
        rules={AFFILIATE_MERCHANT_FORM_RULES.domain}
      />
    </div>
  );
};

export default AffiliateMerchantDomainField;
