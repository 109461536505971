import React, { forwardRef, useCallback } from 'react';
import { cx } from '@emotion/css';
import { RadioGroup, RadioItemProps } from '@procurenetworks/procure-component-library';

import { Maybe } from '../../../types/schema';
import FormLabel from '../../Form/FormLabel';
import { RadioOptionLabelStyles } from './style';

interface Props {
  label?: string;
  classNames?: string;
  value?: Maybe<any>;
  onChange?: (value: any) => void;
  items: RadioItemProps[];
  disabled?: boolean;
}

const FormRadioGroupInput = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { items, value, label, onChange, disabled, classNames } = props;

  const onInputChange = useCallback(
    (value: any, _event: any) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <div ref={ref}>
      <FormLabel>{label || ''}</FormLabel>
      <div className={`mt-6 ${classNames}`}>
        <RadioGroup
          disabled={!!disabled}
          radioItems={items}
          radioLabelClasses={cx(RadioOptionLabelStyles)}
          value={value}
          onChange={onInputChange}
          row
        />
      </div>
    </div>
  );
});

export default FormRadioGroupInput;
