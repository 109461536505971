const Label = {
  Labels: 'Labels',
  PrintLabels: 'Print Labels',
  LabelsAdmin: 'Labels - Admin',
  Form: {
    LabelType: 'Label Type',
    LabelStock: 'Label Stock',
    LabelFormat: 'Label Format',
    ArrowDisplay: 'Arrow Display',
    LabelCustom: 'Label Custom',
    Site: 'Site',
    Search: 'Search',
    Location: 'Location',
    SelectSite: 'Select Site',
    SelectLocation: 'Select Location',
    SelectStock: 'Select Stock',
    SelectOffset: 'Select Offset',
    SelectArrow: 'Select Arrow',
    SelectLabelType: 'Select Label Type',
    SelectLabelFormat: 'Select Label Format',
    SelectOne: '-- Select One --',
    LabelCount: 'Select Number',
    EnterNumber: 'Enter Number',
    ArrowDisplayOptions: {
      NoArrow: 'No Arrow',
      UpArrow: 'Up Arrow',
      DownArrow: 'Down Arrow',
    },
    LabelTypeOptions: {
      Selectone: '-- Select One --',
      Inventory: 'Inventory',
      InventoryKit: 'Inventory Kit',
      Asset: 'Asset',
      AssetKit: 'Asset Kit',
      Shipping: 'Shipping',
      Location: 'Location',
      ShippingLabelSetup: 'Shipping Label Setup',
      WarehouseSetup: 'Warehouse Setup',
    },
    LabelCustomOptions: {
      None: 'None',
      Default: 'Default',
      Custom: 'Custom',
      SelectSuk: 'Select SUK',
    },
    ContainerTypeOptions: {
      SelectOne: '-- Select One --',
      Bin: 'Bin',
      Package: 'Package',
      Pallet: 'Pallet',
    },
    InventoryLabelFormatOptions: {
      SKUSiteLocation: 'SKU/Site/Location',
      TitleLocation: 'Title/Location',
      TextOnly: 'Title/SKU (text only, no QR code)',
      SkuOnly: 'SKU Only',
      Location: 'none',
    },
    ShippingSetup: {
      DestinationSite: 'Please select the destination Site for these labels.',
      ContainerType: 'Please select the container type.',
      LabelsCount: 'How many labels would you like to print?',
    },
    WarehouseSetup: {
      LabelSite: 'What Site will use these labels?',
      Zones: 'How many Zones will you have?',
      Aisles: 'How many Zones will you have?',
      Racks: 'How many Racks will you have in each row?',
      Shelves: 'How many Shelves will you have in each rack?',
      Bins: 'How many Bins will you have on each shelf?',
    },
  },
  FormValidationMessages: {
    LabelTypeRequired: 'Please select a Label Type.',
    LabelFormatRequired: 'Please select a Label Format.',
    LabelStockRequired: 'Please select a Label Stock.',
    SiteRequired: 'Please select a Site.',
    DestinationSiteRequired: 'Please select a Destination Site.',
    ContainerTypeRequired: 'Please select a Container Type.',
    LabelCountRequired: 'Please select a number of labels to be printed',
    LabelSiteRequired: 'Please select a Site.',
    LabelRacksRequired: 'Please select a Label Type.',
    LabelShelvesRequired: 'Please select a Label Type.',
    MultipleCustomLabelRequired: 'Please select at least one field.',
    CustomLabelRequired: 'Custom label is required.',
  },
  Location: {
    LocationSearch: 'Location Search',
  },
  Shipping: {
    SiteSearch: 'Site Search',
    ShippingSetup: 'Shipping Setup',
  },
  Warehouse: {
    WarehouseSearch: 'Warehouse Search',
    WarehouseSetup: 'Warehouse Setup',
  },
  Asset: {
    AssetSearch: 'Asset Search',
  },
  Inventory: {
    InventorySearch: 'Inventory Search',
  },
  Selectone: {
    SelectSearch: 'None',
  },
  None: 'None',
};

export default Label;
