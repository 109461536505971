import AdminLayout from 'app/components/AdminLayout';
import Categories from 'app/modules/categories/views/Categories';

function CategoriesPage() {
  return (
    <AdminLayout>
      <Categories />
    </AdminLayout>
  );
}

export default CategoriesPage;
