import AdminLayout from 'app/components/AdminLayout';
import AddAssetModel from 'app/modules/assetModels/views/AddAssetModel';

function AddAssetModelsPage() {
  return (
    <AdminLayout>
      <AddAssetModel />
    </AdminLayout>
  );
}

export default AddAssetModelsPage;
