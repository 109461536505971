import _ from 'lodash';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  UserWorkspacePermission} from 'app/types/schema';

import routes from '../../../consts/routes';
import { HeaderItem } from './types';

export const headerItems: HeaderItem[] = [
  {
    title: 'Assets',
    id: "assets",
    app: 'core',
    path: routes.Assets(),
    workspacePermission: AllowedPermissionsSubjectEnum.Asset,
  },
  {
    title: 'Inventory',
    id: "inventory",
    app: 'core',
    path: routes.Inventory(),
    workspacePermission: AllowedPermissionsSubjectEnum.Inventory,
  },
  {
    title: 'Contacts',
    id: "contacts",
    app: 'core',
    path: '/companies',
    workspacePermission: AllowedPermissionsSubjectEnum.Contact,
  },
  {
    title: 'Orders',
    id: "orderRequest",
    app: 'or',
    path: routes.Orders(),
    workspacePermission: AllowedPermissionsSubjectEnum.OrderRequest,
  },
  {
    title: 'Shipping',
    id: "shipping",
    app: 'ship',
    path: routes.Shipping(),
    workspacePermission: AllowedPermissionsSubjectEnum.Shipment,
  },
  {
    title: 'Receiving',
    id: "receiving",
    app: 'core',
    path: routes.Receiving(),
    workspacePermission: AllowedPermissionsSubjectEnum.Receivable,
  },
  {
    title: 'Reports',
    id: "report",
    app: 'core',
    path: routes.Reports(),
    workspacePermission: AllowedPermissionsSubjectEnum.Report,
  },
  {
    title: 'Admin',
    id: "admin",
    app: 'core',
    path: routes.Admin(),
    workspacePermission: AllowedPermissionsSubjectEnum.Admin,
  },
  {
    title: 'Shop',
    id: "shop",
    app: 'shop',
    path: '/shops',
    workspacePermission: AllowedPermissionsSubjectEnum.Shop,
  },
  {
    title: 'IT Assets',
    id: "itAsset",
    app: 'core',
    path: '/it',
    workspacePermission: AllowedPermissionsSubjectEnum.ItAsset,
  },
  {
    title: 'Wishlist',
    id: "wishlist",
    app: 'core',
    path: '/wishlist',
    workspacePermission: AllowedPermissionsSubjectEnum.Shop,
  },
];

export function getHeaderItemsAsPerPermissions(workspacePermissions: UserWorkspacePermission[]) {
  return _.filter(headerItems, (headerItem) => {
    if (!headerItem.workspacePermission) {
      return true;
    }
    return !!workspacePermissions?.find(
      (workspacePermission) =>
        workspacePermission.subject === headerItem.workspacePermission &&
        workspacePermission.action === AllowedPermissionActionsEnum.Access,
    );
  });
}
