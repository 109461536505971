import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { DepartmentTableRowFragmentDoc } from '../../../../Departments/graphql/fragments/generated/DepartmentTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateDepartmentMutationVariables = Types.Exact<{
  input: Types.UpdateDepartmentInput;
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'UpdateDepartmentPayload', success: boolean, reason?: string | null, department?: { __typename?: 'DepartmentSchema', id: string, name: string, description?: string | null, departmentCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } | null } };


export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($input: UpdateDepartmentInput!) {
  updateDepartment(input: $input) {
    success
    reason
    department {
      ...DepartmentTableRow
    }
  }
}
    ${DepartmentTableRowFragmentDoc}`;

export function useUpdateDepartmentMutation() {
  return Urql.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument);
};