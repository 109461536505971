import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string
};

const MemoryForm = (props: Props) => {
  const { control ,className} = props;

  return (
    <>
      <Typography
        className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Memory.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memoryTotal"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.Total}
                placeholder={Network.FormLabels.Memory.Total}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memorySlab"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.Slab}
                placeholder={Network.FormLabels.Memory.Slab}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memoryBuffer"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.Buffers}
                placeholder={Network.FormLabels.Memory.Buffers}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memorySwapTotal"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.SwapTotal}
                placeholder={Network.FormLabels.Memory.SwapTotal}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memorySwapFree"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.SwapFree}
                placeholder={Network.FormLabels.Memory.SwapFree}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="memorySwapUsed"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Memory.SwapUsed}
                placeholder={Network.FormLabels.Memory.SwapUsed}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MemoryForm;
