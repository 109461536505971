import '../style.css';
import Wishlist from 'app/i18n/Wishlist';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Box from 'app/ui-components/Box';

import CopyBox from './copybox';

const ReferOther = (props: RouteComponentProps) => {
  const IMAGE_URL = `${process.env.PUBLIC_URL}/images/logo.png`;
  return (
    <>
      <Box className="space-y-30 mt-[8.1vw] mb-24 min-w-[150px] max-w-[900px] p-20 sm:mt-[8.1vh-sm] lg:mt-[8.1vh-lg]" data-testid="refer-other-page">
        <Box>
          <h1 className="heading text-[gray]">{Wishlist.ReferOther.InviteOthers}</h1>
          <h1 className="heading text-[#66CCFF]">{Wishlist.ReferOther.SupportCharity}</h1>
        </Box>
        <p className="content">
          {Wishlist.ReferOther.SupportParagraphs.EncourageSupport}
          <b> Procure</b>
        </p>
        <p className="content">{Wishlist.ReferOther.SupportParagraphs.ProcureDonates}</p>

        <p className="content">
          <i>{Wishlist.LandingPage.SupportParagraphs.NoExtraCost}</i>
          {Wishlist.LandingPage.SupportParagraphs.MerchantProvidesDonation}
        </p>

        <p className="content">{Wishlist.ReferOther.SupportParagraphs.CopyLink}</p>

        <Box className="mt-[24px] space-y-24">
          <h1 className="heading text-[gray]">{Wishlist.ReferOther.Share}</h1>
          <CopyBox />
        </Box>
        <img alt="procure-logo" className="mt-[40px] h-[35px] w-[141px]" src={IMAGE_URL} />
      </Box>
    </>
  );
}

export default withRouter(ReferOther);
