import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';

type Props = {
  control: any;
  className?:string
};

const OSForm = (props: Props) => {
  const { control,className } = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Os.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osPlatform"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Platform}
                placeholder={Network.FormLabels.Os.Platform}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osDistro"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Distro}
                placeholder={Network.FormLabels.Os.Distro}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osCodename"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Codename}
                placeholder={Network.FormLabels.Os.Codename}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osRelease"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Release}
                placeholder={Network.FormLabels.Os.Release}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osHostname"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Hostname}
                placeholder={Network.FormLabels.Os.Hostname}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osSerial"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Serial}
                placeholder={Network.FormLabels.Os.Serial}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osArchitecture"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Architecture}
                placeholder={Network.FormLabels.Os.Architecture}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="osKernal"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Os.Kernal}
                placeholder={Network.FormLabels.Os.Kernal}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OSForm;
