import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Button, CheckBox } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import Network from 'app/i18n/Network';
import { defaultGridColumn } from 'app/modules/network/utils';

import { detailModelType } from '../../types';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  onSaveNote?: any;
  dataComboDetail: detailModelType[];
  setDataComboDetail: any;
};

const ManageInsightsModal = (props: Props) => {
  const { isOpen, dataComboDetail, setDataComboDetail, onCancel } = props;

  const handleCheck = (index: number, value: boolean) => {
    let updatedArray = dataComboDetail;
    updatedArray[index].visible = value;
    setDataComboDetail([...updatedArray]);
  };

  return (
    <Modal
      fullWidth
      id="details-modal"
      open={isOpen}
      title={Network.Labels.Manage}
      onClose={onCancel}>
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
          <Grid container columns={defaultGridColumn}>
            {dataComboDetail.map((data, index) => {
              return (
                <Grid item lg={3} md={3} sm={4} xs={6}>
                  <CheckBox
                    label={data.title}
                    value={data.visible || false}
                    onChange={(value) => {
                      handleCheck(index, value);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </div>
    </Modal>
  );
};

export default ManageInsightsModal;
