import AccessControl from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import AddAffiliateMerchant from 'app/modules/shop/view/admin/AddAffiliateMerchant';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import ShopLayout from '../../../modules/shop/layout';

function AddAffiliateConfigurationPage() {
  const { workspacePermissions } = useCurrentUser();
  return (
    <ShopLayout>
      <AccessControl
        action={AllowedPermissionActionsEnum.Create}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.AffiliateConfiguration}>
        <AddAffiliateMerchant />
      </AccessControl>
    </ShopLayout>
  );
}

export default AddAffiliateConfigurationPage;
