import { useMemo } from 'react';
import routes from 'app/consts/routes';
import { AccessControlLink } from 'app/libs/navigation';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ItemTypeEnum,
} from 'app/types/schema';

interface Props {
  children: React.ReactElement;
  type: ItemTypeEnum;
  id: string;
  target?: string;
}

const ItemLink = (props: Props) => {
  const { children, type, id, target } = props;

  const { route, subject } = useMemo(() => {
    switch (type) {
      case ItemTypeEnum.Asset:
      case ItemTypeEnum.AssetKit:
        return {
          route: routes.EditAssets(id),
          subject: AllowedPermissionsSubjectEnum.Asset,
        };

      case ItemTypeEnum.Inventory:
      case ItemTypeEnum.InventoryKit:
        return {
          route: routes.EditInventory(id),
          subject: AllowedPermissionsSubjectEnum.Inventory,
        };

      case ItemTypeEnum.AssetModel:
        return {
          route: routes.AssetsSearch(
            `?${new URLSearchParams({
              parentId: id,
            }).toString()}`,
          ),
          subject: AllowedPermissionsSubjectEnum.Asset,
        };

      default:
        return {
          route: null,
          subject: null,
        };
    }
  }, [type]);

  if (!subject || !route) {
    return null;
  }

  return (
    <AccessControlLink
      action={AllowedPermissionActionsEnum.Access}
      className="sku-link truncate"
      subject={subject}
      target={target}
      to={route}>
      {children}
    </AccessControlLink>
  );
};

export default ItemLink;
