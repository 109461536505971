const SignUp = {
  FormActions: {
    signup: 'SignUp',
    letsdoThis: `Let's Do This!`,
    yes: 'Yes',
    no: 'No',
    next: 'Next',
    previous: 'Previous',
    donorSignUp: 'Sign me up to help!',
    cancel: 'Cancel',
    completeSignup: 'Complete Sign Up',
    useDifferentEmail: 'Use a Different Email'
  },
  FormLabels: {
    FirstName: 'First Name',
    LastName: 'Last Name',
    Email: 'Work Email',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    Organization: 'Organization',
    Address: 'Address',
    Zip: 'Zip',
    City: 'City',
    State: 'State',
    Website: 'Website',
    PrimaryPhoneNumber: 'Primary Phone Number',
    description: {
      first: `Our mission is to help you save money so you can serve more people.`,
      second: `Procure simplifies asset tracking and inventory management and keeps all your stuff in one place. 
      Procure will help you save money so you can serve more people!`,
      third: `Let’s set up your account on Procure.`
    },
    stepTwoTitle: 'Thanks for signing up!',
    stepTwoCorrectEmail: 'Is this the correct email address for you?',
    stepTwoDesc: `If it's not the right email address, click 'No' and enter a different email address. 
        We need to send an account activation email to you as you complete this sign up process.`,
    stepThreeDesc: `What's the name and address of your organization?`,
    stepFourTitle: 'Almost Done.',
    stepFiveTitle: 'Almost Done. Choose your password.',
    termsAndPolicy: 'By proceeding to create your account and use Procure, you are agreeing to our ',
    termLabel: 'Terms of Service ',
    policyLabel: ' Privacy Policy. ',
    donor: {
      Email: 'Email',
      confirmEmail: 'Confirm Email',
      Password: 'Password',
      ConfirmPassword: 'Confirm Password',
      SelectOrganization: "Select an Organization to Support",
      TermAndPolicyText: 'By creating an account you agree to our Terms of Service and Privacy Policy',
      description: {
        first: `We help the organizations you love save money to serve more people.`,
        second: `To get started, select an organization from the list that you would like to support through your online purchases.`,
        third: `To create an account, enter your name, email and password. With your account, 
            you may create lists to support the non-profit of your choice!`,
        forth: `Every time you make a purchase online, a percentage of the total will be donated to the organization you select below.`,
      },
    },
    unAvailableMsg: `Another user with this email address already exists. Please enter a different email address.`
  },

  FormValidations: {
    FirstName: 'Please enter a First Name up to 30 characters.',
    LastName: 'Please enter a Last Name up to 30 characters.',
    EmailRequired: 'Please enter an Email Address.',
    EmailInvalid: 'Please enter a valid Email Address.',
    EmailExist: 'Email already exist',
    ConfirmEmailNotMatched: 'Please ensure the confirm email field matches your entered email.',
    WebsiteInvalid: 'Please enter a valid Web Address.',
    WebsiteExist: 'Website already exist',
    Password: 'Please enter a Password.',
    ConfirmPassword: 'Please enter a Confirm Password.',
    ConfirmNewPasswordNotMatched: 'Please re-enter the password, as this password must match.',
    Organization: 'Please enter an Organization Name up to 30 characters.',
    OrganizationExist: 'Organization already exist',
    Address: 'Please enter an Address.',
    Zip: 'Please enter a Zip Code.',
    ZipInvalid: 'Please enter a valid Zip Code.',
    City: 'Please enter a City.',
    State: 'Please enter a State.',
    PrimaryPhoneNumberRequired: 'Please enter a Phone Number.',
    PrimaryPhoneNumberInvalid: 'Please enter a Phone Number.',
  },
}
export default SignUp;
