import { ReceivableHistorySchema } from 'app/types/schema';

type ReceivingHistoryNotesNodeProps = {
  receivingHistory: ReceivableHistorySchema[];
};

function ReceivingHistoryNotesNode(props: ReceivingHistoryNotesNodeProps) {
  const receivingHistory = props.receivingHistory;

  return (
    <div className="h-full w-full">
      <div className="flex h-full flex-col justify-center">
        <div className="scrollbar-x-hide flex-1 divide-y-2 divide-dashed overflow-y-auto">
          {receivingHistory.map((history, index) => {
            return (
              <p key={'notes_history_' + history._id} className="text-sm text-wrap py-10">
                {history.notes}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ReceivingHistoryNotesNode;
