import { useCallback, useMemo } from 'react';
import { difference, filter, reduce, union } from 'lodash';
import { AllowedEntityPermissions, EntityPermissionsMap } from 'app/types/schema';

import { PermissionGroupType, PermissionItemType } from '../types';
import getGroupSelectedPermissions from '../utils/getGroupSelectedPermissions';
import getPermissionDependencies from '../utils/getPermissionDependencies';
import getPermissionDescription from '../utils/getPermissionDescription';

interface GroupPermissionItem {
  id: string;
  label: string;
  description?: string | null;
}

interface Props {
  selectedEntityPermissionKeys: string[];
  entityPermissions: EntityPermissionsMap[];
  onChange: (entityPermissionKeys: string[]) => void;
  hideNotSelectedItems?: boolean;
}

function getPermissionLabel(
  selectedCount: number,
  totalEntityPermissions: number,
  hideNotSelectedItems?: boolean,
) {
  if (hideNotSelectedItems) {
    return `Permissions`;
  }
  return `Permissions (${selectedCount}/${totalEntityPermissions})`;
}

function usePermissionGroups({
  entityPermissions,
  selectedEntityPermissionKeys,
  onChange,
  hideNotSelectedItems,
}: Props) {
  const permissionDependencyMap = useMemo(
    () => getPermissionDependencies(entityPermissions),
    [entityPermissions],
  );

  const getGroupItem = useCallback(
    (permissionGroup: EntityPermissionsMap, selectedPermissions: string[]) => ({
      id: permissionGroup.key,
      label: permissionGroup.label,
      description: getPermissionDescription(selectedPermissions),
    }),
    [],
  );

  const getFilterGroup = useCallback(
    (permissionGroup: EntityPermissionsMap, selectedCount: number) =>
      ({
        id: permissionGroup.key,
        label: getPermissionLabel(
          selectedCount,
          permissionGroup.entityPermissions.length,
          hideNotSelectedItems,
        ),
        options: permissionGroup.entityPermissions.map((permission: AllowedEntityPermissions) => {
          return { value: permission.key, label: permission.label, disabled: permission.disabled };
        }),
      } as PermissionGroupType),
    [],
  );

  const groupPermissions = useMemo(
    () =>
      reduce(
        entityPermissions,
        (groupMap, permissionGroup) => {
          const selectedGroupPermissions = getGroupSelectedPermissions(
            permissionGroup,
            selectedEntityPermissionKeys,
          );
          const selectedCount = selectedGroupPermissions.length;
          const selectedGroupPermissionKeys = selectedGroupPermissions.map(
            (permission) => permission.key,
          );

          groupMap[permissionGroup.key] = {
            item: getGroupItem(permissionGroup, selectedGroupPermissionKeys),
            filterGroup: getFilterGroup(permissionGroup, selectedCount),
          };

          return groupMap;
        },
        {} as Record<string, { item: GroupPermissionItem; filterGroup: PermissionGroupType }>,
      ),
    [entityPermissions, getFilterGroup, getGroupItem, selectedEntityPermissionKeys],
  );

  const selectedItemsByGroup = useMemo(
    () =>
      reduce(
        entityPermissions,
        (groupMap, permissionGroup) => {
          groupMap[permissionGroup.key] = groupPermissions[
            permissionGroup.key
          ]?.filterGroup?.options.filter((option: PermissionItemType) =>
            selectedEntityPermissionKeys.includes(option.value),
          );
          return groupMap;
        },
        {} as Record<string, PermissionItemType[]>,
      ),
    [entityPermissions, groupPermissions, selectedEntityPermissionKeys],
  );

  const onOptionClick = useCallback(
    (item: PermissionItemType) => {
      console.log('permissionDependencyMap[item]', permissionDependencyMap);
      if (selectedEntityPermissionKeys.includes(item.value)) {
        const keysToRemove = [item.value, ...permissionDependencyMap[item.value]?.dependencyOf];
        onChange(difference(selectedEntityPermissionKeys, keysToRemove));
      } else {
        const keysToAdd = [item.value, ...permissionDependencyMap[item.value]?.dependentOn];
        onChange(union(selectedEntityPermissionKeys, keysToAdd));
      }
    },
    [onChange, permissionDependencyMap, selectedEntityPermissionKeys],
  );

  const onAddMultiple = useCallback(
    (items: PermissionItemType[]) => {
      const itemKeys = items.filter((item) => !item.disabled).map((item) => item.value);
      const dependentKeys: string[] = [];
      itemKeys.forEach((item) => {
        dependentKeys.push(...(permissionDependencyMap[item]?.dependentOn || []));
      });
      onChange(
        Array.from(new Set([...selectedEntityPermissionKeys, ...itemKeys, ...dependentKeys])),
      );
    },
    [onChange, permissionDependencyMap, selectedEntityPermissionKeys],
  );

  const onRemoveMultiple = useCallback(
    (items: PermissionItemType[]) => {
      const itemKeys = items.filter((item) => !item.disabled).map((item) => item.value);
      const dependencyOfKeys: string[] = [];
      itemKeys.forEach((item) =>
        dependencyOfKeys.push(...(permissionDependencyMap[item]?.dependencyOf || [])),
      );
      onChange(
        filter(
          selectedEntityPermissionKeys,
          (key) => !itemKeys.includes(key) || !dependencyOfKeys.includes(key),
        ),
      );
    },
    [onChange, permissionDependencyMap, selectedEntityPermissionKeys],
  );

  return {
    groupPermissions,
    selectedItemsByGroup,
    onOptionClick,
    onAddMultiple,
    onRemoveMultiple,
  };
}

export default usePermissionGroups;
