import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssetTransactionReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TransactionFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AssetTransactionReportQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', totalCount: number, edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, quantity: number, entityType: Types.TransactionEntityTypeEnum, type: Types.TransactionTypeEnum, subType: Types.TransactionSubTypeEnum, createdAt: string, meta?: { __typename?: 'TransactionMetaSchema', reason?: string | null } | null, entity?: { __typename?: 'AssetItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, mName: string, unitCost?: number | null, cost?: number | null, replacementDate?: string | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, unitCost?: number | null, cost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'InventoryItemSchema' } | { __typename?: 'InventoryKitItemSchema' } | null, project?: { __typename?: 'ProjectSchema', id: string, name: string, projectCode?: string | null } | null, department?: { __typename?: 'DepartmentSchema', id: string, name: string, departmentCode?: string | null } | null, destinationSite?: { __typename?: 'LocationSchema', id: string, name: string, locationCode?: string | null } | null, sourceSite?: { __typename?: 'LocationSchema', id: string, name: string, locationCode?: string | null } | null, billToSite?: { __typename?: 'LocationSchema', id: string, name: string, locationCode?: string | null } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string } } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const AssetTransactionReportDocument = gql`
    query assetTransactionReport($filters: TransactionFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  transactions(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        quantity
        entityType
        type
        subType
        createdAt
        meta {
          reason
        }
        entity {
          ... on AssetItemSchema {
            id
            sku
            status
            type
            title
            mName
            unitCost
            cost
            category {
              id
              name
              categoryCode
            }
            replacementDate
          }
          ... on AssetKitItemSchema {
            id
            sku
            status
            type
            title
            unitCost
            cost
            category {
              id
              name
              categoryCode
            }
          }
        }
        project {
          id
          name
          projectCode
        }
        department {
          id
          name
          departmentCode
        }
        destinationSite {
          id
          name
          locationCode
        }
        sourceSite {
          id
          name
          locationCode
        }
        billToSite {
          id
          name
          locationCode
        }
        createdBy {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    `;

export function useAssetTransactionReportQuery(options?: Omit<Urql.UseQueryArgs<AssetTransactionReportQueryVariables>, 'query'>) {
  return Urql.useQuery<AssetTransactionReportQuery>({ query: AssetTransactionReportDocument, ...options });
};