import { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import routes from '../../../../consts/routes';
import Assets from '../../../../i18n/Assets';
import {
  ItemTypeEnum,
  TransactionEntityTypeEnum,
  TransactionStatusEnum,
} from '../../../../types/schema';
import MoveManager from '../../../assetsInventory/move';
import { useCreateMoveTransactionsMutation } from '../../../assetsInventory/move/graphql/mutations/generated/createMoveTransactions';
import useMoveItemState from '../../../assetsInventory/move/hook/useMoveState';
import { getParentTransactionIdFormTransactions } from '../../../assetsInventory/utils/utils';
import EntityManager from '../../../components/EntityManager';
import analytics from 'app/analytics';

function MoveAssets(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateMoveTransactions] = useCreateMoveTransactionsMutation();

  const {
    formState,
    transactions,
    onFormSubmit,
    onResetFormAndTable,
    tableState,
    availableQuantity,
  } = useMoveItemState({
    transactionType: TransactionEntityTypeEnum.Asset,
  });

  const saveMoveTransactions = useCallback(() => {
    onCreateMoveTransactions({
      input: {
        status: TransactionStatusEnum.Completed,
        entities: transactions,
      },
    }).then((response) => {
      analytics?.track('Created', { name: 'Asset Move' });
      const createMoveTransactionsResponse = response?.data?.createMoveTransactions || undefined;
      const { transactions: createdTransactions, success } = createMoveTransactionsResponse || {};
      if (success) {
        SnackbarService.show({
          message: Assets.SuccessMessages.AssetMoved,
        });
        const parentTransactionId = getParentTransactionIdFormTransactions(createdTransactions);
        if (parentTransactionId) {
          history.push(routes.ConfirmationAssetEditTransactions(parentTransactionId));
        }
        onResetFormAndTable();
      } else {
        console.error('[Create move] Failed', response);
      }
    });
  }, [transactions, onCreateMoveTransactions, onResetFormAndTable, history]);

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-0">
      <EntityManager.Title documentTitle={Assets.MoveTitle} title={Assets.Move} hasTopDivider />
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full 
            pt-6
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px] 
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
            xl:max-w-[416px]
        ">
          <MoveManager.Form
            availableQuantity={availableQuantity}
            formState={formState}
            types={[ItemTypeEnum.Asset, ItemTypeEnum.AssetKit]}
            onFormSubmit={onFormSubmit}
          />
        </Box>

        <Box className="mt-[24px] flex-1">
          <Box
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}
            className="rounded-[12px] border border-grey-300">
            <MoveManager.Table
              fetching={fetching}
              pagination={true}
              tableState={tableState}
              onSaveTransactions={saveMoveTransactions}
              paginationWrapper="p-[24px]"
              foooterWrapperClass="p-[24px]"
            />
          </Box>
        </Box>
      </Box>
    </EntityManager>
  );
}

export default withRouter(MoveAssets);
