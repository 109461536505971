import { ORDER_VIEW } from '../../views/OrderDetail/type';
import DefaultOrderDetailActions from '../DefaultOrderDetailActions';
import EditOrderDetailsActions from '../EditOrderDetailActions';
import { useCallback, useMemo } from 'react';
import routes from '../../../../consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { UseOrderDetailsReturnType } from '../../views/OrderDetail/hook/useOrderDetails';
import ReturnOrderDetailActions from '../ReturnOrderDetailActions';
import { UseMultiSelectEntityManagerReturnType } from '../../../components/EntityManager/useMultiSelectEntityManager';

interface Props {
  onUpdateOrder: () => void;
  onReturnOrder: () => void;
  state: UseOrderDetailsReturnType['state'];
  actions: UseOrderDetailsReturnType['actions'];
  disabled?: boolean;
  tableState: {
    selectedRowIds: string[];
  };
}

const OrderDetailActions = (props: Props & RouteComponentProps) => {
  const { onUpdateOrder, disabled, history, state, actions, onReturnOrder, tableState } = props;
  const { orderView, orderDetail } = state;
  const { onCancel } = actions;

  const onBackClick = useCallback(() => {
    history.push(routes.OrderHistory());
  }, [history]);

  switch (orderView) {
    case ORDER_VIEW.EDIT_ORDER_VIEW:
      return (
        <EditOrderDetailsActions
          onCancel={onCancel}
          onUpdateOrder={onUpdateOrder}
          disabled={disabled}
        />
      );

    case ORDER_VIEW.RETURN_ORDER_VIEW:
      return (
        <ReturnOrderDetailActions
          tableState={tableState}
          onCancel={onCancel}
          disabled={disabled}
          onReturnOrder={onReturnOrder}
        />
      );

    default:
      return <DefaultOrderDetailActions orderDetail={orderDetail} onBackClick={onBackClick} />;
  }
};

export default withRouter(OrderDetailActions);
