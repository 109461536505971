import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BellNotificationsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.BellNotificationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type BellNotificationsQuery = { __typename?: 'Query', bellNotifications: { __typename?: 'BellNotificationConnection', totalCount: number, edges?: Array<{ __typename?: 'BellNotificationEdge', node: { __typename?: 'BellNotificationSchema', id: string, title: string, description?: string | null, webClickUrl?: string | null, mobileClickUrl?: string | null, showButton?: boolean | null, buttonLabel?: string | null, buttonWebUrl?: string | null, buttonMobileUrl?: string | null, status: Types.BellNotificationStatusEnum, createdAt: string } }> | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, nextCursor?: string | null, hasPrevPage: boolean, prevCursor?: string | null } | null } };


export const BellNotificationsDocument = gql`
    query BellNotifications($filters: BellNotificationFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  bellNotifications(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
  ) {
    edges {
      node {
        id
        title
        description
        webClickUrl
        mobileClickUrl
        showButton
        buttonLabel
        buttonWebUrl
        buttonMobileUrl
        status
        createdAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      nextCursor
      hasPrevPage
      prevCursor
    }
  }
}
    `;

export function useBellNotificationsQuery(options?: Omit<Urql.UseQueryArgs<BellNotificationsQueryVariables>, 'query'>) {
  return Urql.useQuery<BellNotificationsQuery>({ query: BellNotificationsDocument, ...options });
};