import AssetsLayout from '../../modules/assets/layout';
import CheckIn from '../../modules/assets/views/CheckIn';

function CheckInAssetsPage() {
  return (
    <AssetsLayout>
      <CheckIn />
    </AssetsLayout>
  );
}

export default CheckInAssetsPage;
