import React from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import Network from 'app/i18n/Network';

import EntityManager from '../../../components/EntityManager';
import NetworkAssetDetailContent from './components/NetworkAssetDetailContent';
import { Box } from '@mui/material';

interface Props {
  networkAssetId: string;
}

const NetworkAssetDetail = (props: Props) => {
  const { networkAssetId } = props;

  return (
    <EntityManager className="!px-[0px] !pb-0">
      <Box className="px-[24px]">
        <EntityManager.Title
          documentTitle={Network.NetworkAssetDetail}
          title={Network.NetworkAssetDetail}
          titleWrapperClass="border-t-[1px] border-grey-300"
        />
      </Box>

      <NetworkAssetDetailContent NetworkAssetId={networkAssetId} />
    </EntityManager>
  );
};

export default NetworkAssetDetail;
