const Reports = {
  Asset: 'Asset',
  Assets: 'Assets',
  Inventory: 'Inventory',
  ItAsset: "IT Asset",
  AssetsandInventory: 'Assets and Inventory',
  Manufacturer: 'Manufacturer',
  Location: 'Location',
  Container: 'Container',
  FromSite: 'From Site',
  ToSite: 'To Site',
  Site: 'Site',
  Users: 'Users',
  Project: 'Project',
  Department: 'Department',
  SiteCode: 'Site Code',
  Category: 'Category',
  SKU: 'SKU',
  All: 'All',
  SelectSite: 'Select Site',
  Title: 'Title',
  fromLocation: 'From Location',
  toLocation: 'To Location',
  Model: 'Model',
  Brand: 'Brand',
  Date: 'Date',
  ModelNumber: 'Model Number',
  CostReport: 'Cost Report',
  LowStockReport: 'Low Stock Report',
  SiteReport: 'Site Report',
  TransactionReport: 'Transaction Report',
  AgingReport: 'Aging Report',
  AccountingReport: 'Accounting Report',
  CustomReport: 'Custom Report',
  AuditReport: 'Audit Report',
  AssetAuditReport: 'Asset Audit Report',
  InventoryAuditReport: 'Inventory Audit Report',
  AuditReportSuccess: 'This Audit Report has been successfully created.',
  OrderRequestReport: 'Order Request Report',
  NewAudit: 'New Audit',
  NewAssetAudit: 'New Asset Audit',
  NewInventoryAudit: 'New Inventory Audit',
  ItAssetReport: 'IT Asset Report',
  SelectReportColumns: 'Select Report Columns',
  Quantity: 'Quantity',
  Reason: 'Reason',
  MinimumQuantity: 'MIN Quantity',
  MaximumQuantity: 'MAX Quantity',
  Ascending: 'Ascending',
  Descending: 'Descending',
  Qty: 'Quantity',
  Cost: 'Cost',
  UnitCost: 'Unit Cost',
  UnitCostWithOverride: 'Unit Cost (With Cost Override)',
  TotalCostWithoutOverride: 'Total Cost (Without Cost Override)',
  TotalCostWithOverride: 'Total Cost (With Cost Override)',
  ReplacementDate: 'Replacement Date',
  ReportFormat: {
    ReportFormat: 'Report Format',
    PreviewReport: 'Preview Report',
    CSVExport: 'CSV Export',
    PDFExport: 'PDF Export',
  },
  TransactionType: 'Transaction Type',
  TransactionTypes: {
    New: 'New',
    Move: 'Move',
    CheckIn: 'Check In',
    CheckOut: 'Check Out',
    Remove: 'Remove',
    RemoveKit: 'Remove (Kit)',
    Reserve: 'Reserve',
    Restock: 'Restock',
    RestockKit: 'Restock (Kit)',
    Audit: 'Audit',
    Delete: 'Delete',
    moveShip: 'Move (Ship)',
    moveOrder: 'Move (Order)',
    Kit: 'Kit',
    ValidationMessage: 'Please select at least one transaction to generate a report',
  },
  Actions: {
    Generate: 'Generate',
    Clear: 'Clear',
  },
  FormValidationMessages: {
    SiteRequired: 'Please select a Site.',
  }
};

export default Reports;
