import { withRouter } from 'app/libs/navigation';

import EntityManager from '../../../components/EntityManager';
import ScheduleReceive from './components/ScheduleReceivingStep1';
import ScheduleSteps from './components/ScheduleStepper';
import { SCHEDULE_STEP } from './components/ScheduleStepper/utils';
import SelectItemsReceived from './components/SelectItemsReceived';
import useScheduleShipment from './hook/useScheduleShipment';

const Schedule = () => {
  const { state, _setState } = useScheduleShipment();

  const onNext = (values: any) => {
    _setState({ step1Data: values, activeStep: SCHEDULE_STEP.SELECT_ITEMS_RECEIVE });
  };

  const renderOrderStep = (state: any) => {
    switch (state.activeStep) {
      case SCHEDULE_STEP.SCHEDULE_RECEIVING:
        return <ScheduleReceive onNext={onNext} step1Data={state.step1Data} />;

      case SCHEDULE_STEP.SELECT_ITEMS_RECEIVE:
        return (
          <SelectItemsReceived
            step1Data={state.step1Data}
            _setState={_setState}
            onPrevious={() => _setState({ activeStep: SCHEDULE_STEP.SCHEDULE_RECEIVING })}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-0">
      <ScheduleSteps activeStep={state.activeStep} />
      {renderOrderStep(state)}
    </EntityManager>
  );
};

export default withRouter(Schedule);
