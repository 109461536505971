import { Button } from '@procurenetworks/procure-component-library';
import Common from '../../../../i18n/Common';
import ReactToPrint from 'react-to-print';
import Orders from '../../../../i18n/Orders';
import OrderDetailToPrint from '../OrderDetailToPrint';
import { useRef } from 'react';
import { OrderDetailsData } from '../../views/OrderDetail/type';

interface Props {
  onBackClick: () => void;
  orderDetail: OrderDetailsData;
}
const DefaultOrderDetailActions = (props: Props) => {
  const { onBackClick, orderDetail } = props;
  const componentRef = useRef(null);

  return (
    <div className="mt-[10px] flex justify-end gap-[16px] px-[24px] py-[16px]">
      <div>
        <Button onClick={onBackClick} classes="min-w-[94px] h-[44px]">
          {Common.Actions.Back}
        </Button>
      </div>
      <div className="ml-[4px]">
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button theme="info" classes="min-w-[94px] h-[44px]">
              {Orders.Actions.Print}
            </Button>
          )}
        />
        <div className="hidden">
          <OrderDetailToPrint ref={componentRef} orderDetail={orderDetail} />
        </div>
      </div>
    </div>
  );
};

export default DefaultOrderDetailActions;
