import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';
import { bytesToGB } from 'app/modules/network/utils';
import Stack from 'app/ui-components/Stack';

const SystemInsights = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;

  const disk = networkAssetDetail?.storage?.length && networkAssetDetail?.storage[0].size;
  const systemModel = networkAssetDetail?.system?.model || 'Not Available';
  const memory = networkAssetDetail?.memory?.total;
  const cpuBrand = networkAssetDetail?.cpu?.brand || 'Not Available';

  const systemInsightDetails = [
    { label: 'System Model', value: systemModel },
    { label: 'CPU Brand', value: cpuBrand },
    { label: 'Memory', value: bytesToGB(memory) },
    {
      label: 'Storage',
      value: bytesToGB(disk),
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Typography className='text-grey-900 font-semibold text-[18px]'>
          System Insights
        </Typography>
        <Divider className='my-[16px]'/>
        <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
          <CardContent>
            <Grid container columns={{ xs: 8, sm: 8, md: 8, lg: 12 }} spacing={2}>
              {systemInsightDetails.map((item, index) => {
                const isLastItem = index === systemInsightDetails.length - 1;
                return (
                  <Grid
                    key={index}
                    item
                    lg={3}
                    md={4}
                   
                    xs={6}>
                    <Stack
                      alignItems="center"
                      className={`mt-2 ${index !== 0 && 'border-l-[1px] border-grey-300'}`}
                      direction="col"
                      justifyContent="center"
                      >
                      <Typography className='text-[16px] text-grey-900 font-semibold'>{item.label}</Typography>
                      <Typography
                        className='text-[14px] text-grey-800'
                        
                        sx={{
                          fontSize: 14,
                          marginTop: '10px',
                          textAlign: 'center',
                          width: '100%',
                        }}
                        >
                        {item.value}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SystemInsights;
