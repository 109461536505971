import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { formatDate } from 'app/utils/date';

export const getSitesTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Site',
    value: 'name',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    sortable: false,
  },
  {
    id: 'locationCode',
    label: 'Site Code',
    value: 'locationCode',
    sortable: false,
  },
  {
    id: 'siteManager',
    label: 'Site Manager(s)',
    value: 'siteManager',
    valueNode: ({ row }) => {
      const managers = row.siteManagers;
      const managerNames = managers?.length
        ? managers.map((user: any) => user.name).join(', ')
        : '-';
      return (
        <span className="truncate" title={managerNames}>
          {managerNames}
        </span>
      );
    },
  },
  {
    id: 'siteStaff',
    label: 'Site Staff(s)',
    value: 'siteStaff',
    valueNode: ({ row }) => {
      const managers = row.siteStaffs;
      const managerNames = managers?.length
        ? managers.map((user: any) => user.name).join(', ')
        : '-';
      return (
        <span className="truncate" title={managerNames}>
          {managerNames}
        </span>
      );
    },
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
    value: 'updatedBy',
    sortable: false,
    valueNode: ({ row }) => (
      <span className="truncate" title={row.updatedBy?.name}>
        {row.updatedBy?.name}
      </span>
    ),
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    value: 'updatedAt',
    sortable: true,
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt)}>
        {formatDate(row.updatedAt)}
      </span>
    ),
  },
];
