import { cx } from '@emotion/css';
import Box from 'app/ui-components/Box';

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const WarehouseContainer = (props: Props) => {
  const { children, className, onClick } = props;

  return (
    <Box
      className={cx(
        'group border border-solid border-orange-900 p-16 hover:bg-[#ffa500]',
        className,
      )}
      onClick={onClick}>
      {children}
    </Box>
  );
};

export default WarehouseContainer;
