import { ORDER_INSERT_TYPE, OrderRequestInput } from '../type';

export function getOrderInsertRadioItems(insertOption: ORDER_INSERT_TYPE) {
  return [
    {
      id: insertOption,
      value: insertOption,
    },
  ];
}

export function getDefaultFormValues(data?: Partial<OrderRequestInput>): OrderRequestInput {
  return {
    itemId: '',
    upcCode: '',
    website: '',
    imageUrl: [],
    description: '',
    projectId: '',
    projectName: '',
    quantity: '',
    cost: '0.00',
    itemType: undefined,
    partnerTenantId: '',
    ...(data || {}),
  };
}

export function getUnregisterFormValues() {
  return [
    'itemId',
    'upcCode',
    'website',
    'imageUrl',
    'description',
    'projectId',
    'projectName',
    'quantity',
    'cost',
    'itemType',
  ];
}
