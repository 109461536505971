import { useCallback, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useAssetReminderState from 'app/modules/assets/components/AssetForm/components/AssetReminderField/hook/useAssetReminderState';
import QuantityBySite from 'app/modules/inventory/components/QuantityBySite';
import TransactionHistory from 'app/modules/inventory/components/TransactionHistory';

import AccessControl from '../../../../../../components/AccessControl';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  AssetItemEdge,
  AssetItemReminder,
  ItemLocationSchema,
  ItemTypeEnum,
} from '../../../../../../types/schema';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import AssetForm from '../../../../components/AssetForm';
import useAssetForm from '../../../../hook/useAssetForm';
import { prepareFormData, prepareRemindersFormData } from './utils';

type Props = {
  assetItem: AssetItemEdge['node'];
} & RouteComponentProps;

const DeletedAssetContent = (props: Props) => {
  const { assetItem } = props;

  const { workspacePermissions } = useCurrentUser();

  const formValues = useMemo(() => {
    return prepareFormData(assetItem);
  }, [assetItem]);
  const reminders = useMemo(() => {
    return prepareRemindersFormData(assetItem?.reminders as AssetItemReminder[], assetItem?.id);
  }, [assetItem?.id, assetItem?.reminders]);

  const { formState, onCancelEditable } = useAssetForm({
    defaultValues: formValues,
    defaultEditable: false,
    assetItem: assetItem,
  });
  const { state: assetReminderState, setState: setAssetReminderState } = useAssetReminderState({
    reminders,
    editable: formState?.editable
  });

  const stockInformation = useMemo(() => {
    return assetItem?.stockInformation || [];
  }, [assetItem]);

  const onCancel = useCallback(() => {
    onCancelEditable();
  }, [onCancelEditable]);

  return (
    <div>
      <AssetForm
        disabled={true}
        formState={{ ...formState, state: assetReminderState, setState: setAssetReminderState, isRemindersViewMode: true }}
        onCancel={onCancel}
        onDelete={() => { }}
        onSubmit={() => { }}
      />

      <AccessControl
        action={AllowedPermissionActionsEnum.Edit}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.Asset}>
        <QuantityBySite
          disabled={true}
          itemId={assetItem.id}
          stockInformation={stockInformation as ItemLocationSchema[]}
        />
      </AccessControl>

      <div className="mt-20">
        <TransactionHistory itemId={assetItem.id} itemType={ItemTypeEnum.Asset} />
      </div>
    </div>
  );
};

export default withRouter(DeletedAssetContent);
