import { useCallback, useEffect, useMemo } from 'react';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import Common from 'app/i18n/Common';
import EntityManager from 'app/modules/components/EntityManager';

import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import usePagination from '../../../../hooks/usePagination';
import { ItemTypeEnum, SortOrderEnum } from '../../../../types/schema';
import { ItemInStock } from '../../../orders/views/OrderRequest/type';
import { useItemInStockSearchTableQuery } from '../../graphql/query/generated/itemInStockSearchTable';
import { UseItemInStockWithSearchDialogStateReturnType } from '../FormItemInStockWithSearchDialog/hook/useItemInStockWithSearchDialogState';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { getItemInStockSearchTableColumns } from './columns';
import { Box, InputAdornment } from '@mui/material';

type ItemInStockType = ItemInStock;

interface Props {
  state: UseItemInStockWithSearchDialogStateReturnType['state'];
  actions: UseItemInStockWithSearchDialogStateReturnType['actions'];
  onClose: () => void;
  onConfirm: (item: ItemInStockType, meta?: any) => void;
  types?: ItemTypeEnum[];
  tenantIds?: string[];
  partnerTenantId?: string;
  confirmButtonText: string;
  pickableThroughOrderRequest?: boolean;
}

const ItemsInStockSearchDialog = (props: Props) => {
  const {
    onClose,
    onConfirm,
    state,
    actions,
    types,
    tenantIds,
    partnerTenantId,
    confirmButtonText,
    pickableThroughOrderRequest,
  } = props;
  const { search, table, open } = state;

  const { setSearchValue, searchSuggestions } = useSearchSuggestion(
    {
      types,
      categoryIds: state.categoryId ? [state.categoryId] : undefined,
      tenantIds: tenantIds || undefined,
      pickableThroughOrderRequest: pickableThroughOrderRequest,
      partnerTenantId,
    },
    search.value,
  );

  const onCloseHandler = () => {
    setSearchValue('');
    onClose();
  };

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useItemInStockSearchTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        pickableThroughOrderRequest: pickableThroughOrderRequest,
        categoryIds: state.categoryId ? [state.categoryId] : undefined,
        tenantIds: tenantIds || [],
        itemIds: state.selectedRowId ? [state.selectedRowId] : undefined,
        types: types,
        partnerTenantId,
      },
      sorts: table.state.sorts.length
        ? table.state.sorts
        : [
            {
              sortField: 'sku',
              sortOrder: SortOrderEnum.Asc,
            },
          ],
    },
    {
      edgeKey: 'items',
      pageSize: table.state.numberOfRowsPerPage,
      pause: !state.open,
    },
  );

  const rows = useMemo(() => {
    return data?.items.edges?.map(adaptNodeEdgeToNode) || [];
  }, [data?.items.edges]);

  const selectedItemInStock = useMemo(() => {
    if (!table.state.selectedRowId) {
      return null;
    }

    return rows.find((row) => row.id === table.state.selectedRowId);
  }, [table.state.selectedRowId, rows]);

  const onAddItemInStock = useCallback(() => {
    if (selectedItemInStock) {
      onConfirm(selectedItemInStock, state.meta);
      table.setState({
        selectedRowId: null,
      });
    }
  }, [onConfirm, selectedItemInStock, table, state.meta]);

  useEffect(() => {
    if (open && !state.selectedRowId) {
      table.setState({
        selectedRowId: null,
      });
    }
  }, [
    table.state.search,
    table.state.activePage,
    table.state.numberOfRowsPerPage,
    state.categoryId,
    open,
  ]);

  useEffect(() => {
    if (state.selectedRowId && open) {
      const selectedRow = rows.find((row) => row.id === state.selectedRowId);
      if (selectedRow) {
        search.onChange(selectedRow?.sku || '');
        table.setState({
          selectedRowId: selectedRow.id,
        });
      }
    } else {
      if (rows?.length === 1) {
        table.setState({
          selectedRowId: rows?.[0].id,
        });
      }
    }
  }, [rows]);

  useEffect(() => {
    if (state.selectedRowId) {
      actions.clearSelectedRowId();
    }
  }, [search.debouncedSearchText]);

  return (
    <Modal
      disableBackdropClick
      fullWidth
      actions={
        <div className="flex gap-[16px]">
          <div>
            <Button
              disabled={selectedItemInStock === null}
              loading={fetching}
              theme="success"
              onClick={onAddItemInStock}
              classes="min-w-[94px] h-[44px]">
              {confirmButtonText}
            </Button>
          </div>
          <div className="ml-1">
            <Button disabled={fetching} onClick={onCloseHandler} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Cancel}
            </Button>
          </div>
        </div>
      }
      id="item-in-stock-search-dialog"
      open={open}
      title="Item Search"
      onClose={onCloseHandler}>
      <Box
        className="rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          ignoreRelayPagination
          pagination
          persistSelectionData
          actions={table.actions}
          columns={getItemInStockSearchTableColumns()}
          data={rows}
          extraProps={{
            state,
            actions,
            partnerTenantId,
          }}
          loading={fetching}
          persistKey="item_in_stock_search_dialog_table"
          setState={table.setState}
          state={table.state}
          total={data?.items?.totalCount || 0}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={
            <EntityManager.Search
              {...search}
              isDropdown
              suggestionData={searchSuggestions}
              onChangeSearch={setSearchValue}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
            />
          }
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        />
      </Box>
    </Modal>
  );
};

export default ItemsInStockSearchDialog;
