import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';

type Props = {
  control: any;
  className?:string;
};

const MotherboardForm = (props: Props) => {
  const { control ,className="mt-0"} = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Motherboard.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="motherboardManufacturer"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Motherboard.Manufacturer}
                placeholder={Network.FormLabels.Motherboard.Manufacturer}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="motherboardVersion"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Motherboard.Version}
                placeholder={Network.FormLabels.Motherboard.Version}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="motherboardModel"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}

                error={fieldState.error}
                label={Network.FormLabels.Motherboard.Model}
                placeholder={Network.FormLabels.Motherboard.Model}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="motherboardSerial"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}

                error={fieldState.error}
                label={Network.FormLabels.Motherboard.Serial}
                placeholder={Network.FormLabels.Motherboard.Serial}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MotherboardForm;
