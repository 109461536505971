import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';

import AccessControl from '../../components/AccessControl';
import AssetsLayout from '../../modules/assets/layout';
import AddAsset from '../../modules/assets/views/AddAsset';
import useCurrentUser from '../../modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../types/schema';

function AddAssetPage() {
  const { workspacePermissions } = useCurrentUser();

  return (
    <AssetsLayout>
      <AccessControl
        action={AllowedPermissionActionsEnum.Create}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.Asset}>
        <FindUPCProvider>
          <AddAsset />
        </FindUPCProvider>
      </AccessControl>
    </AssetsLayout>
  );
}

export default AddAssetPage;
