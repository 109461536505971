import { useMemo } from 'react';
import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';

import { OrderRequestItemSchema, OrderRequestItemTypeEnum } from '../../../../types/schema';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  withLink?: boolean;
}

const OrderRequestItemSku = (props: Props) => {
  const { orderRequestItem, withLink } = props;

  const itemRouteLink = useMemo(() => {
    const itemId = orderRequestItem?.item?.id;
    if (!withLink || !itemId) {
      return null;
    }

    const itemType = orderRequestItem.type;

    switch (itemType) {
      case OrderRequestItemTypeEnum.Asset:
        return routes.EditAssets(itemId);

      case OrderRequestItemTypeEnum.AssetKit:
        return routes.EditAssets(itemId);

      case OrderRequestItemTypeEnum.Inventory:
        return routes.EditInventory(itemId);

      case OrderRequestItemTypeEnum.InventoryKit:
        return routes.EditInventory(itemId);

      default:
        return null;
    }
  }, [withLink, orderRequestItem]);

  const skuText = (
    <span className="truncate" title={orderRequestItem?.item?.sku || ''}>
      {orderRequestItem?.item?.sku || ''}
    </span>
  );

  if (itemRouteLink) {
    return (
      <Link className="sku-link truncate" to={itemRouteLink}>
        {skuText}
      </Link>
    );
  }

  return skuText;
};

export default OrderRequestItemSku;
