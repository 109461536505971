import { useCallback, useEffect, useState } from 'react';
import { LocationStatusEnum } from 'app/types/schema';

import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';

interface State {
  siteIds: string[];
  siteIdState: string;
}

const useDeletedItemsState = () => {
  const { defaultSite } = useCurrentUser();
  const defaultSiteId =
    defaultSite?.status === LocationStatusEnum.Deleted ? 'none' : defaultSite?.id;

  const [state, _setState] = useState<State>({
    siteIdState: defaultSiteId || 'none',
    siteIds: defaultSiteId && defaultSiteId !== 'none' ? [defaultSiteId] : [],
  });

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  useEffect(() => {
    setState({
      siteIds: state.siteIdState && state.siteIdState === 'none' ? [] : [state.siteIdState],
    });
  }, [state.siteIdState]);

  const onSiteFilterChange = (value: any) => {
    setState({
      siteIdState: value,
    });
  };
  return {
    state,
    filters: {
      site: {
        value: state.siteIdState,
        onChange: onSiteFilterChange,
      },
    },
    setState,
    siteId: state.siteIdState && state.siteIdState !== 'none' ? state.siteIdState : undefined,
  };
};

export type UseDeletedStateReturnType = ReturnType<typeof useDeletedItemsState>;
export default useDeletedItemsState;
