import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cx } from '@emotion/css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Chip, IconButton, TextField } from '@mui/material';
import { Button, Card } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import { DescriptionStyle } from 'app/modules/shop/utils/styles';
import { useUpdateWishlistMutation } from 'app/modules/wishlist/views/createList/graphql/mutations/generated/updateWishlist';
import { SelectedProducts, WishListDetailType } from 'app/modules/wishlist/views/createList/types';
import { WishlistVendorEnum } from 'app/types/schema';
import Box from 'app/ui-components';
import analytics from 'app/analytics';

interface Product {
  id: string;
  name: string;
  vendor: WishlistVendorEnum;
  price: string;
  quantity: number;
  imageUrl: string;
  purchasedQuantity?: number;
  hooverProductId: string;
  affiliateLink: string;
}

interface Props {
  item: Product;
  wishListName?: string;
  wishlistId?: string;
  selectedProducts?: SelectedProducts[];
  addProductHandler: (card: SelectedProducts) => void;
  wishlistDetails?: WishListDetailType;
}

const ProductCard: React.FC<Props> = (props: Props) => {
  const { item, addProductHandler, wishListName, wishlistId, selectedProducts, wishlistDetails } =
    props;

  const _handleRedirectShop = useCallback((url: string) => {
    const winObject = window?.open(url, '_blank');
    if (winObject) winObject.focus();
  }, []);

  const [productItem, setProductItem] = useState<Product>(item);
  const [showQuantity, showQuantityToggle] = useState(false);
  const [isProductSaving, setProductSaving] = useState(false);

  const [{ fetching: fetchingUpdateWishlist }, onUpdateWishlist] = useUpdateWishlistMutation();

  const increaseQuantity = () => {
    setProductItem({ ...productItem, quantity: productItem.quantity + 1 });
  };

  const decreaseQuantity = () => {
    setProductItem({ ...productItem, quantity: productItem.quantity - 1 });
  };

  const handleQuantityChange = (event: any) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setProductItem({ ...productItem, quantity: newQuantity });
    }
  };

  const selectedProduct = useMemo(() => {
    if (!Array.isArray(selectedProducts) || !selectedProducts?.length) {
      return null;
    }

    return selectedProducts.find((product) => product.hooverProductId === item.hooverProductId);
  }, [selectedProducts, item]);

  useEffect(() => {
    const selectedQuantity = selectedProduct?.quantity || 0;

    setProductItem({
      ...item,
      quantity: selectedQuantity > item.quantity ? selectedQuantity : item.quantity,
    });
  }, [selectedProduct, item, setProductItem]);

  const isFullyPurchased = useMemo(() => {
    if (!selectedProduct?.purchasedQuantity) {
      return false;
    }

    return Number(selectedProduct?.purchasedQuantity || 0) === Number(productItem.quantity);
  }, [selectedProduct, productItem]);

  const isProductInArray = (products: SelectedProducts[], product: SelectedProducts) => {
    return products.some((p) => p.id === product.id);
  };

  const updateProductQuantity = async () => {
    addProductHandler(productItem);
    setProductSaving(true);

    let selectedWishlistItems = [];

    if (!Array.isArray(selectedProducts) || !selectedProducts.length) {
      selectedWishlistItems.push({ ...productItem });
    } else if (isProductInArray(selectedProducts, productItem)) {
      const updatedProducts = selectedProducts.map((p) =>
        p.id === productItem.id ? { ...p, quantity: productItem.quantity } : p,
      );
      selectedWishlistItems = updatedProducts;
    } else {
      selectedWishlistItems = [...selectedProducts, productItem];
    }

    // setSelectedWishlistProducts([...selectedWishlistItems]);

    const wishlistProductInput = selectedWishlistItems.map((item) => ({
      hooverProductId: item.hooverProductId.toString(),
      quantity: item.quantity || 0,
      currentPurchasedQuantity: item.purchasedQuantity || 0,
      vendor: item.vendor?.toUpperCase() as WishlistVendorEnum,
    }));

    try {
      const response = await onUpdateWishlist({
        input: {
          name: wishListName || '',
          productQuantityConfig: wishlistProductInput,
          wishlistId: wishlistId || '',
          collaborators: wishlistDetails?.collaborators,
        },
      });
      analytics?.track('Edited', { name: 'WishList' });

      const { id: wishListId } = response.data?.updateWishlist?.wishlist || {};

      if (wishListId) {
        SnackbarService.show({ message: 'This Wishlist has been successfully updated.' });
      }
      showQuantityToggle(false);
    } catch (error) {
      console.log('Something went wrong while saving wishlist', error);
      SnackbarService.showError({ message: 'Something went wrong while saving wishlist' });
    } finally {
      setProductSaving(false);
    }
  };

  return (
    <>
      <Card
        withImage
        cardActions={
          <>
            <Box
              className={`mb-[6px] flex h-[35px] w-full ${
                !selectedProduct || showQuantity ? 'justify-center' : ''
              } items-center`}>
              {!showQuantity && selectedProduct && (
                <Chip
                  className="rounded-[8px] bg-blue-900 text-[16px] font-medium text-white"
                  label={`${selectedProduct.quantity}`}
                  onClick={() => showQuantityToggle(true)}
                />
              )}

              {showQuantity ? (
                <>
                  <IconButton
                    disabled={productItem.quantity === 1}
                    onClick={() => decreaseQuantity()}>
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    inputProps={{ min: 0 }}
                    sx={{
                      width: 'auto',
                      maxWidth: '60px',
                      '.MuiInputBase-root': {
                        height: '44px !important',
                      },
                      '.MuiInputBase-input': {
                        textAlign: "center"
                      }
                    }}
                    value={productItem.quantity}
                    variant="outlined"
                    onChange={handleQuantityChange}
                  />
                  <IconButton onClick={increaseQuantity}>
                    <AddIcon />
                  </IconButton>{' '}
                  <Button
                    classes="!ml-[4px]"
                    loading={isProductSaving || fetchingUpdateWishlist}
                    theme="success"
                    onClick={() => updateProductQuantity()}>
                    Add
                  </Button>
                </>
              ) : (
                <>
                  {!selectedProduct && (
                    <Button
                      classes="!ml-[4px] w-full"
                      theme="success"
                      onClick={() => showQuantityToggle(true)}>
                      Add to Wishlist
                    </Button>
                  )}
                </>
              )}
            </Box>
          </>
        }
        data={item}
        descriptionNode={
          <>
            <Box
              className={cx(
                DescriptionStyle,
                'cursor-pointer text-[14px] font-semibold leading-[20px] text-grey-900',
              )}
              onClick={() => _handleRedirectShop(item.affiliateLink)}>
              {item.name}
            </Box>
            <p className="m-0 mt-4 cursor-default text-[12px] font-bold text-grey-900">
              {item.price}
            </p>
            <p className="m-0 cursor-default truncate text-[12px] text-grey-800">{item.vendor}</p>
          </>
        }
        imagePath={item.imageUrl}
        maxTitleLine={1}
        target="_blank"
        title=""
        onImageClick={()=>{_handleRedirectShop(item.affiliateLink)}}
      />
    </>
  );
};

export default ProductCard;
