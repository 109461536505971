import { css } from '@emotion/css';

export const AddReminderDialog = css`
  .MuiDialog-paper {
    max-width: 530px;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    display: none !important;
  }
  .MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export const ViewReminderDialog = css`
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
  }
  .MuiDialog-paper {
    max-width: 530px;
  }
  .MuiDialogContent-root {
    padding: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    display: none !important;
  }
  .MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export const ViewReminderContent = css`
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;
