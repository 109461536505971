import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import useReportCSVExport from 'app/modules/reports/hooks/useReportCSVExport';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import AssetCostTable from './components/AssetCostTable';
import CostReportForm from './components/CostReportForm';
import { CostReportFormValues } from './components/CostReportForm/types';
import InventoryCostTable from './components/InventoryCostTable';
import { ExportReportNameEnum } from 'app/consts/enum';

const CostReport = () => {
  const [values, setValues] = useState<CostReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values?.type === TransactionEntityTypeEnum.Inventory) {
      return <InventoryCostTable values={values} />;
    }

    if (values?.type === TransactionEntityTypeEnum.Asset) {
      return <AssetCostTable values={values} />;
    }

    return null;
  };

  const onSubmit = useCallback(
    (nextValues: CostReportFormValues | null) => {
      if (nextValues?.format === 'csv') {
        const filters = {
          categoryIds: nextValues.categoryIds,
          itemIds: nextValues.skuIds,
          types: nextValues.itemTypes.map(camelCase),
        };
        const sorts = [{ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc }];

        if (nextValues.type === TransactionEntityTypeEnum.Inventory) {
          executeExport({
            entityType: 'inventoryCost',
            filters: { inventoryItemsFilters: filters },
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
            reportName: ExportReportNameEnum.CostReport
          });
        } else if (nextValues.type === TransactionEntityTypeEnum.Asset) {
          executeExport({
            entityType: 'assetCost',
            filters: { assetItemsFilters: filters },
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
            reportName: ExportReportNameEnum.CostReport
          });
        }
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer
      title={Reports.CostReport}
      stackWrapperClass="rounded-tr-[12px] rounded-br-[12px]">
      <CostReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null}
    </ReportContainer>
  );
};

export default CostReport;
