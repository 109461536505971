import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UsersSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.UserFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UsersSelectQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'UserSchema', id: string, name: string, emailId: string } }> } };


export const UsersSelectDocument = gql`
    query usersSelect($filters: UserFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  users(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        name
        emailId
      }
    }
  }
}
    `;

export function useUsersSelectQuery(options?: Omit<Urql.UseQueryArgs<UsersSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<UsersSelectQuery>({ query: UsersSelectDocument, ...options });
};