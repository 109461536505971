import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import Inventory from 'app/i18n/Inventory';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import Modal from '../../../../../../components/Modal';
import {
  ItemUnion,
  TransactionEntityTypeEnum,
  TransactionStatusEnum,
} from '../../../../../../types/schema';
import { useCreateMoveTransactionsMutation } from '../../../../../assetsInventory/move/graphql/mutations/generated/createMoveTransactions';
import { getParentTransactionIdFormTransactions } from '../../../../../assetsInventory/utils/utils';
import { LabelTypeEnum } from '../../../../../labels/views/Labels/components/Forms/types';
import FormSiteSelect from '../../../../../locations/components/FormSiteSelect';
import { BULK_MOVE_FORM_RULES } from './rules';
import { prepareCreateMoveTransactions } from './utils';

interface Props {
  type: TransactionEntityTypeEnum;
  onCancel: () => void;
  open: boolean;
  siteId: string | undefined;
  selectedItems: ItemUnion[];
}

const BulkMoveModal = (props: Props & RouteComponentProps) => {
  const { type, onCancel, open, selectedItems, siteId, history } = props;

  const { control, watch, handleSubmit, reset } = useForm({
    defaultValues: {
      destinationSiteId: '',
      destinationLocationId: '',
    },
  });

  const [{ fetching }, onCreateMoveTransactions] = useCreateMoveTransactionsMutation();

  const isAssetTransactionType = type === TransactionEntityTypeEnum.Asset;
  const onSubmitBulkOMove = useCallback(
    (values: { destinationSiteId: string; destinationLocationId: string }) => {
      if (!siteId) {
        return;
      }

      const createMoveTransactionEntities = prepareCreateMoveTransactions(selectedItems, {
        destinationSiteId: values.destinationSiteId,
        destinationLocationId: values.destinationLocationId,
        type,
      });

      onCreateMoveTransactions({
        input: {
          entities: createMoveTransactionEntities,
          status: TransactionStatusEnum.Completed,
        },
      }).then((response) => {
        const createMoveTransactionsResponse = response?.data?.createMoveTransactions || undefined;
        const { transactions: createdTransactions, success } = createMoveTransactionsResponse || {};
        if (success) {
          SnackbarService.show({
            message: isAssetTransactionType
              ? Assets.SuccessMessages.AssetMoved
              : Inventory.SuccessMessages.InventoryMoved,
          });
          const parentTransactionId = getParentTransactionIdFormTransactions(createdTransactions);
          if (parentTransactionId) {
            history.push(
              isAssetTransactionType
                ? routes.ConfirmationAssetEditTransactions(parentTransactionId)
                : routes.ConfirmationInventoryEditTransactions(parentTransactionId),
            );
          }
        } else {
          console.error('[Create move] Failed', response);
        }
      });
    },
    [onCreateMoveTransactions, selectedItems, siteId, type, isAssetTransactionType],
  );

  const onFormSubmit = useMemo(
    () => handleSubmit(onSubmitBulkOMove),
    [handleSubmit, onSubmitBulkOMove],
  );

  const bulkModalTitle = useMemo(() => {
    let title = '';
    if (type === TransactionEntityTypeEnum.Asset) {
      title = 'Asset';
    } else if (TransactionEntityTypeEnum.Inventory) {
      title = 'Inventory';
    }
    return `${title} Bulk Move: ${selectedItems.length} record(s)`;
  }, [selectedItems]);

  const destinationSiteId = watch('destinationSiteId');

  const handleCancel = useCallback(() => {
    reset();
    onCancel();
  }, [onCancel]);

  return (
    <Modal
      actions={
        <div className="z-0 flex gap-[16px]">
          <Button loading={fetching} theme="success" onClick={onFormSubmit}>
            {Assets.Actions.Move}
          </Button>
          <Button classes="ml-[4px]" disabled={fetching} onClick={handleCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      disable={fetching}
      id="bulk-move-dialog"
      open={open}
      title={bulkModalTitle}
      onClose={handleCancel}>
      <form className="mt-[24px] w-[480px]">
        <Controller
          control={control}
          name="destinationSiteId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              autoFocus
              isRequired
              className="z-50 flex-1"
              error={fieldState.error}
              label={Assets.FormLabels.ToSite}
              menuPosition="fixed"
              placeholder={Assets.FormPlaceholders.ToSite}
              selectClassName="mt-[6px]"
            />
          )}
          rules={BULK_MOVE_FORM_RULES.destinationSiteId}></Controller>
        <Controller
          control={control}
          name="destinationLocationId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              className="mt-20 flex-1"
              error={fieldState.error}
              label={Assets.FormLabels.ToLocation}
              menuPosition="fixed"
              placeholder={Assets.FormPlaceholders.ToLocation}
              type={LabelTypeEnum.Location}
              types={[]}
              value={destinationSiteId}
            />
          )}
          rules={BULK_MOVE_FORM_RULES.destinationLocationId}></Controller>
      </form>
    </Modal>
  );
};

export default withRouter(BulkMoveModal);
