import { ShippingContainerTypePrefixEnum } from 'app/types/schema';

export interface FormState {
  type: string;
  siteId: string;
  labelFormat: string;
  labelStock: string;
  arrowDisplay: string;
  offset: string;
  location: string;
  containerType: ShippingContainerTypePrefixEnum;
  destinationSite: string;
  labelsCount: number;
  custom: LabelCustomEnum;
  firstField: string;
  secondField: string;
  thirdField: string;
}

export type labelFormFieldConfig = {
  isCustomLabelFieldEnable: boolean;
};
export interface LabelsFormProps {
  formType?: string;

  isCustomLabelFieldEnable: boolean;
}

export interface ShippingSetupFormProps { }

export const enum LabelTypeEnum {
  Inventory = 'inventory',
  InventoryKit = 'inventory_kit',
  Asset = 'asset',
  AssetKit = 'asset_kit',
  Location = 'location',
  Shipping = 'shipping',
  ShippingSetUp = 'shippingSetup',
  WarehouseSetup = 'warehouseSetup',
}

export enum LabelFormat {
  None = '',
  SkuSiteLocation = '1',
  TitleLocation = '2',
  TextOnly = '3',
  SkuOnly = '4',
}

export const enum LabelStockEnum {
  Avery6792 = '1',
  Avery6570 = '2',
  Avery5160 = '3',
  Avery5163 = '4',
  OneRoundLabels = '5',
  ThreeRoundLabels = '6',
  OneSquareLabels = '7',
  ThreeSquareLabels = '8',
  ZebraPrinter = '9',
}

export const enum LabelCustomEnum {
  Custom = 'Custom',
  Default = 'Default',
}

export const enum LabelQrCodeOptionEnum {
  Single = 'Single',
  Multi = 'Multi',
}
