import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { LabelComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';
import moment from 'moment';
import Ship from 'app/i18n/Ship';
import DepartmentsTableFilter from 'app/modules/departments/components/DepartmentsTableFilter';
import ShipmentTableColumnFilter from '../components/ShipmentTableColumnFilter';
import {
  Maybe,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from 'app/types/schema';

import ShipOrderId from '../../../components/ShipOrderId';
import ShippingStatusValueNode from '../components/ShippingStatusValueNode';
import ShipTransactionStatueFilter from '../components/ShipTransactionStatueFilter';
import { ShipmentColumnFilterTypeEnum } from '../types';
import SyncStateWithUrlParam from 'app/utils/queryParams';

const getDepartmentsHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'departments',
  label: 'Department',
  value: 'departments',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam("departmentIds", filtersState?.departmentIds, setFilterState);

    return (
      <div>
        <DepartmentsTableFilter
          key="departments-table-filter"
          name="departments"
          placeholder={Ship.FormLabels.Department}
          value={filtersState?.departmentIds}
          onChange={(value: string[]) => {
            setFilterState?.({
              departmentIds: value,
            });
          }}
        />
      </div>
    );
  },
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.orderRequest?.department?.name}>
      {row?.orderRequest?.department?.name}
    </span>
  ),
  ...(config || {}),
});

const getCreatedByHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'createdBy',
  label: 'Name',
  value: 'createdBy',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam("requestorIds", filtersState?.requestorIds, setFilterState);

    return (
      <div>
        <ShipmentTableColumnFilter
          key="requestor-table-filter"
          isDistinct
          name="createdBy"
          placeholder={Ship.FormLabels.Requester}
          queryVariables={{
            filterKey: 'shippingTransactionFilters',
            distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.RequestorId, ValuesInEntitiesDistinctByKeysEnum.DeliverToId, ValuesInEntitiesDistinctByKeysEnum.DestinationSiteId],
            ...getDistinctQuerySiteFilter(filtersState),
            filterType: ShipmentColumnFilterTypeEnum.USER
          }}
          filterType={ShipmentColumnFilterTypeEnum.USER}
          value={filtersState?.requestorIds}
          onChange={(value: string[]) => {
            setFilterState?.({
              requestorIds: value
            });
          }}
        />
      </div>
    );
  },
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.orderRequest?.createdBy?.name}>
      {row?.orderRequest?.createdBy?.name}
    </span>
  ),
  ...(config || {}),
});

const getDistinctQuerySiteFilter = (filterState: any) => {
  const transactionFilters = {
    destinationSiteIds: [],
    requestorIds: [],
    deliverToIds: [],
    departmentIds: [],
    statuses: [],
    search: filterState?.search || '',
  };

  return {
    distinctTableName: ValuesInEntitiesTableNameEnum.ShippingTransactions,
    filters: transactionFilters,
  };
};

const getDeliverToHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'deliverTo',
  label: 'Deliver To',
  value: 'deliverTo',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam("deliverToIds", filtersState?.deliverToIds, setFilterState);

    return (
      <div>
        <ShipmentTableColumnFilter
          key="deliverTo-table-filter"
          isDistinct
          name="deliverTo"
          placeholder={Ship.FormLabels.DeliverTo}
          queryVariables={{
            filterKey: 'shippingTransactionFilters',
            ...getDistinctQuerySiteFilter(filtersState),
            filterType: ShipmentColumnFilterTypeEnum.DESTINATION
          }}
          filterType={ShipmentColumnFilterTypeEnum.DESTINATION}
          value={filtersState?.deliverToIds}
          onChange={(value: Maybe<string[]>) => {
            setFilterState?.({
              deliverToIds: value,
            });
          }}
        />
      </div>
    );
  },
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.orderRequest?.deliverTo?.name}>
      {row?.orderRequest?.deliverTo?.name}
    </span>
  ),
  ...(config || {}),
});

const getDestinationSiteHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'destinationSiteIds',
  label: 'Ship To',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam("destinationSiteIds", filtersState?.destinationSiteIds, setFilterState);

    return (
      <div>
        <ShipmentTableColumnFilter
          key="destination-site-table-filter"
          isDistinct
          name="toSite"
          placeholder={Ship.FormLabels.ShipTo}
          queryVariables={{
            filterKey: 'shippingTransactionFilters',
            ...getDistinctQuerySiteFilter(filtersState),
            filterType: ShipmentColumnFilterTypeEnum.SITE
          }}
          filterType={ShipmentColumnFilterTypeEnum.SITE}
          value={filtersState?.destinationSiteIds}
          onChange={(value: string[]) => {
            setFilterState?.({
              destinationSiteIds: value,
            });
          }}
        />
      </div>
    );
  },
  value: 'destinationSiteName',
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.destinationSite?.name}>
      {row?.destinationSite?.name}
    </span>
  ),
  ...(config || {}),
});

const getStatusHeadCell = (config?: Partial<DataGridHeadCell>) => ({
  id: 'status',
  label: 'Status',
  width: 230,
  labelNode: (props: LabelComponentProps) => {
    const { state } = props;
    const { extraProps } = state;
    const { filtersState, setFilterState } = extraProps || {};
    SyncStateWithUrlParam("statuses", filtersState?.statuses, setFilterState);

    return (
      <div>
        <ShipTransactionStatueFilter
          key="status-table-filter"
          name="status"
          placeholder={Ship.FormLabels.Status}
          value={filtersState?.statuses}
          onChange={(value: string[]) => {
            setFilterState?.({
              statuses: value,
            });
          }}
        />
      </div>
    );
  },
  value: 'status',
  valueNode: ShippingStatusValueNode,
  ...(config || {}),
});

export const getShipmentTableColumns = (): DataGridHeadCell[] => {
  return (
    [
      {
        id: 'orderId',
        label: 'Order ID',
        value: 'orderId',
        valueNode: ({ row }) => {
          return <ShipOrderId row={row} />;
        },
      },
      {
        id: 'containerId',
        label: 'Container ID',
        value: 'containerId',
        sortable: true,
      },
      getCreatedByHeadCell(),
      getDestinationSiteHeadCell(),
      getDeliverToHeadCell(),
      getDepartmentsHeadCell(),
      {
        id: 'shipDate',
        label: 'Ship Date',
        value: 'shipDate',
        valueNode: ({ row }) => (
          <div
            className="truncate"
            title={row.createdAt ? moment(row.createdAt).format('L LT') : '-'}>
            {row.createdAt ? moment(row.createdAt).format('L LT') : '-'}
          </div>
        ),
        sortField: 'createdAt',
        sortable: true,
      },
      getStatusHeadCell(),
      {
        id: 'deliveryDate',
        label: 'Delivery Date',
        value: 'deliveryDate',
        valueNode: ({ row }) => (
          <div
            className="truncate"
            title={row.deliveryDate ? moment(row.deliveryDate).format('L LT') : '-'}>
            {row.deliveryDate ? moment(row.deliveryDate).format('L LT') : '-'}
          </div>
        ),
        sortable: true,
      },
    ] as DataGridHeadCell[]
  ).map((item) => {
    return {
      ...item,
      sortable: item.sortable,
    } as DataGridHeadCell;
  });
};
