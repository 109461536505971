import React, { forwardRef, useCallback } from 'react';

import { Radio } from '@mui/material';
import { Maybe } from 'app/types/schema';

interface Props {
  label?: string;
  classNames?: string;
  value?: Maybe<any>;
  onChange?: (value: any) => void;
  disabled?: boolean;
  checked?: boolean;
}

const RadioControl = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { value, onChange, disabled, classNames, checked } = props;

  const onInputChange = useCallback(
    (value: any, _event: any) => {
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <div ref={ref}>
      <div className={`${classNames}`}>
        <Radio
          checked={checked}
          disabled={!!disabled}
          sx={{
            '&.Mui-checked': {
              color: 'var(--blue)',
            },
          }}
          value={value}
          onChange={onInputChange}
        />
      </div>
    </div>
  );
});

export default RadioControl;
