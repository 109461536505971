declare type Align = 'left' | 'right' | 'center';
export interface ValueComponentProps {
  headCell: DataGridHeadCell;
  row: any;
}

export interface LabelComponentProps {
  headCell: DataGridHeadCell;
}
export interface DataGridHeadCell {
  id: string | number;
  label?: string;
  labelNode?: React.FC<LabelComponentProps>;
  value: string;
  valueNode?: React.FC<ValueComponentProps>;
  sortable?: boolean;
  width?: number;
  flex?: number;
  rowAlign?: Align;
  labelAlign?: Align;
}

export type ContactType = {
  addressId: string;
  companyId: string;
  description: string;
  email: string;
  fax: string;
  firstName: string;
  homeContactNumber: string;
  lastName: string;
  mobileContactNumber: string;
  officeContactNumber: string;
  title: string;
  companyName: string;
};

export type ContactListItem = ContactType & {
  name: string;
};

export type ContactTransportType = {
  _id?: string;
  addressId: string;
  companyId: string;
  description: string;
  email: string;
  fax: string;
  firstName: string;
  homeContactNumber: string;
  lastName: string;
  mobileContactNumber: string;
  officeContactNumber: string;
  title: string;
  valueNode?: React.FC<ValueComponentProps>;
};

export const enum CheckEditDuplicateEnum {
  SUBMIT = 'SUBMIT',
  EDIT = 'EDIT'
};
