import React from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import ShopContent from 'app/modules/shop/view/shop';

import ShopLayout from '../../../modules/shop/layout';

const Suppliers: React.FC<RouteComponentProps<any, {}, unknown>> = ({ history }) => {
  return (
    <ShopLayout>
      <ShopContent />
    </ShopLayout>
  );
};

export default withRouter(Suppliers);
