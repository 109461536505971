import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditLocationDataFragment = { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, siteId: string, locationCode?: string | null, formattedDescription?: string | null };

export const EditLocationDataFragmentDoc = gql`
    fragment EditLocationData on LocationSchema {
  id
  name
  description
  siteId
  locationCode
  formattedDescription
}
    `;