import { LabelStockEnum } from '../../../Labels/components/Forms/types';
import Avery5160 from '../../components/Avery/Avery5160';
import Avery5163 from '../../components/Avery/Avery5163';
import Avery6570 from '../../components/Avery/Avery6570';
import Avery6792 from '../../components/Avery/Avery6792';
import PrintLabelContainer from '../../components/PrintLabelContainer';
import OneInchRound from '../../components/Round/OneInchRound';
import ThreeInchRound from '../../components/Round/ThreeInchRound';
import OneInchSquare from '../../components/Square/OneInchSquare';
import ThreeInchSquare from '../../components/Square/ThreeInchSquare';
import Zebra from '../../components/Zebra';

interface Props {
  values: any;
}

const PrintInventoryLabelStock = (props: Props) => {
  const { values } = props;
  console.log('PrintInventoryLabelStock', values);
  const labelStockData: any = localStorage.getItem('printLabels');
  const stockPrintDatas = JSON.parse(labelStockData);

  let stockType = stockPrintDatas?.labelStock || LabelStockEnum.Avery6792;

  const renderCode = () => {
    switch (stockType) {
      case LabelStockEnum.Avery6792:
        return <Avery6792 codes={values} />;
      case LabelStockEnum.Avery6570:
        return <Avery6570 codes={values} />;
      case LabelStockEnum.Avery5160:
        return <Avery5160 codes={values} />;
      case LabelStockEnum.Avery5163:
        return <Avery5163 codes={values} />;
      case LabelStockEnum.OneRoundLabels:
        return <OneInchRound codes={values} />;
      case LabelStockEnum.ThreeRoundLabels:
        return <ThreeInchRound codes={values} />;
      case LabelStockEnum.OneSquareLabels:
        return <OneInchSquare codes={values} />;
      case LabelStockEnum.ThreeSquareLabels:
        return <ThreeInchSquare codes={values} />;
      case LabelStockEnum.ZebraPrinter:
        return <Zebra codes={values} />;
      default:
        return <Avery6792 codes={values} />;
    }
  };

  return (
    <>
      <PrintLabelContainer>{renderCode()}</PrintLabelContainer>
    </>
  );
};

export default PrintInventoryLabelStock;
