import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import FormSelect from 'app/components/Form/FormSelect';
import { SelectOption } from 'app/components/Select/types';
import Reports from 'app/i18n/Reports';
import { DateFilter, Maybe } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

interface Props {
  control: any;
}

const DEFAULT_VALUE = '30-60';

export const getAgingReportDateFilterOptions = (): (SelectOption & {
  updatedAt: DateFilter | undefined;
})[] => {
  const getStartTime = (days: number) => {
    return moment().subtract(days, 'days').startOf('day').toISOString();
  };

  const getEndTime = (days: number) => {
    return moment().subtract(days, 'days').endOf('day').toISOString();
  };

  return [
    {
      value: '30-60',
      label: '30 to 60 days',
      updatedAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(60), endTime: getEndTime(30) },
      },
    },
    {
      value: '60-90',
      label: '60 to 90 days',
      updatedAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(90), endTime: getEndTime(60) },
      },
    },
    {
      value: '90-120',
      label: '90 to 120 days',
      updatedAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(120), endTime: getEndTime(90) },
      },
    },
    {
      value: '120+',
      label: '120 days +',
      updatedAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(10000), endTime: getEndTime(120) },
      },
    },
  ];
};

const AgingReportDateFilter = (props: Props) => {
  const { control } = props;
  const options = useMemo(() => getAgingReportDateFilterOptions(), []);

  return (
    <Controller
      control={control}
      name="updatedAt"
      render={({ field, fieldState }) => {
        const onChange = (value?: Maybe<string>) => {
          field.onChange(
            options.find((option) => option.value === value)?.updatedAt ||
              options?.[0]?.updatedAt ||
              null,
          );
        };
        const value = options.find((option) => isEqual(option.updatedAt, field.value))?.value;

        return (
          <FormSelect
            {...field}
            error={fieldState.error}
            label={Reports.Date}
            options={options}
            value={value || DEFAULT_VALUE}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

export default AgingReportDateFilter;
