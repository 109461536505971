import * as React from 'react'
import { Stack } from '@mui/material'
import { StepperProps as MuiStepperProps } from '@mui/material'
import { OrderStepper } from '@procurenetworks/procure-component-library';

export interface WishlistStepperProps extends MuiStepperProps {
  activeStep: number
  steps: string[]
}

const WishlistStepper: React.FC<WishlistStepperProps> = (props: WishlistStepperProps) => {

  const { steps, activeStep } = props;
  
  return (
    <Stack spacing={4}>
      <OrderStepper steps={steps} activeStep={activeStep} />
    </Stack>
  )
}

export default WishlistStepper;
