import WarningProvider from 'app/components/WarningAlert';

import AssetsLayout from '../../modules/assets/layout';
import MoveAssets from '../../modules/assets/views/MoveAssets';

function MoveAssetsPage() {
  return (
    <AssetsLayout>
      <WarningProvider>
        <MoveAssets />
      </WarningProvider>
    </AssetsLayout>
  );
}

export default MoveAssetsPage;
