import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditDepartmentDataFragmentDoc } from '../../fragments/generated/EditDepartment';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditDepartmentQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.DepartmentFilters>;
}>;


export type EditDepartmentQuery = { __typename?: 'Query', departments: { __typename?: 'DepartmentConnection', totalCount: number, edges?: Array<{ __typename?: 'DepartmentEdge', node: { __typename?: 'DepartmentSchema', id: string, name: string, description?: string | null, departmentCode?: string | null, formattedDescription?: string | null } }> | null } };


export const EditDepartmentDocument = gql`
    query editDepartment($filters: DepartmentFilters) {
  departments(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditDepartmentData
      }
    }
  }
}
    ${EditDepartmentDataFragmentDoc}`;

export function useEditDepartmentQuery(options?: Omit<Urql.UseQueryArgs<EditDepartmentQueryVariables>, 'query'>) {
  return Urql.useQuery<EditDepartmentQuery>({ query: EditDepartmentDocument, ...options });
};