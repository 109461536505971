import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';
import FormCompanySelect from 'app/modules/contacts/components/FormCompanySelect';

import { AssetFieldProps } from '../../types';

const AssetVendorField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeAsset } = formState;

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="vendorId"
        render={({ field, fieldState }) => (
          <FormCompanySelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.Vendor}
          />
        )}
      />
    </div>
  );
};

export default AssetVendorField;
