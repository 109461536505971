import { isAccessible } from 'app/components/AccessControl';
import Routes from 'app/consts/routes';
import Assets from 'app/i18n/Assets';
import Inventory from 'app/i18n/Inventory';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

const getQueryParams = (itemInStockId?: string) => {
  return itemInStockId
    ? `?${new URLSearchParams({
        itemInStockId,
      }).toString()}`
    : undefined;
};

export const getAssetQuickLinks = (
  permissions: UserProfileFragment['workspacePermissions'],
  itemInStockId?: string,
) => {
  const queryParams = getQueryParams(itemInStockId);

  return [
    {
      label: Assets.FormLabels.Move,
      url: Routes.MoveAssets(queryParams),
      subject: AllowedPermissionsSubjectEnum.AssetMove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Assets.FormLabels.Remove,
      url: Routes.RemoveAssets(queryParams),
      subject: AllowedPermissionsSubjectEnum.AssetRemove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Assets.FormLabels.Restock,
      url: Routes.RestockAssets(queryParams),
      subject: AllowedPermissionsSubjectEnum.AssetRestock,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Assets.FormLabels.Reserve,
      url: Routes.Reserve(queryParams),
      subject: AllowedPermissionsSubjectEnum.AssetReserve,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Assets.FormLabels.CheckOut,
      url: Routes.CheckOutAssets(queryParams),
      subject: AllowedPermissionsSubjectEnum.AssetCheckout,
      action: AllowedPermissionActionsEnum.Access,
    },
  ].filter((quickLink) => isAccessible(permissions, quickLink.action, quickLink.subject));
};

export const getInventoryQuickLinks = (
  permissions: UserProfileFragment['workspacePermissions'],
  itemInStockId?: string,
) => {
  const queryParams = getQueryParams(itemInStockId);
  return [
    {
      label: Inventory.FormLabels.Move,
      url: Routes.MoveInventory(queryParams),
      subject: AllowedPermissionsSubjectEnum.InventoryMove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Inventory.FormLabels.Remove,
      url: Routes.RemoveInventory(queryParams),
      subject: AllowedPermissionsSubjectEnum.InventoryRemove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: Inventory.FormLabels.Restock,
      url: Routes.RestockInventory(queryParams),
      subject: AllowedPermissionsSubjectEnum.InventoryRestock,
      action: AllowedPermissionActionsEnum.Access,
    },
  ].filter((quickLink) => isAccessible(permissions, quickLink.action, quickLink.subject));
};
