import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateShippingContainerMutationVariables = Types.Exact<{
  input: Types.CreateShippingContainersInput;
}>;


export type CreateShippingContainerMutation = { __typename?: 'Mutation', createShippingContainer: { __typename?: 'CreateShippingContainersPayload', shippingContainers?: Array<{ __typename?: 'ShippingContainerSchema', id: string, containerId: string, destinationSite: { __typename?: 'LocationSchema', id: string, name: string } }> | null } };


export const CreateShippingContainerDocument = gql`
    mutation createShippingContainer($input: CreateShippingContainersInput!) {
  createShippingContainer(input: $input) {
    shippingContainers {
      id
      containerId
      destinationSite {
        id
        name
      }
    }
  }
}
    `;

export function useCreateShippingContainerMutation() {
  return Urql.useMutation<CreateShippingContainerMutation, CreateShippingContainerMutationVariables>(CreateShippingContainerDocument);
};