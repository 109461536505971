import { forwardRef, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import PhoneInput from 'app/libs/PhoneInput';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  label: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  error?: FieldError;
  placeholder?:string
}

const inputStyle = { width: '100%' ,minHeight:'50px',borderRadius:'8px'};

const FormPhoneInput = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, error, label, name, isRequired, value, onBlur, onChange,placeholder } = props;

  const inputProps = useMemo(() => ({ name }), [name]);

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      <FormLabel isRequired={isRequired}>{label}</FormLabel>
      <PhoneInput
        containerClass="mt-8"
        inputProps={inputProps}
        inputStyle={inputStyle}
        value={value || ''}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormPhoneInput;
