import { useCallback, useMemo } from 'react';
import { ValueComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';
import FormProjectSelect from 'app/components/ProcureForm/FormProjectSelect';

const ProjectValueNode = (props: ValueComponentProps) => {
  const { state, row } = props;

  const isShowLastUpdatedProjects = useMemo(() => {
    return Boolean(state?.extraProps?.showLastUpdatedProjects);
  }, [state?.extraProps?.showLastUpdatedProjects]);

  const onChange = useCallback(
    (value: any, option: any) => {
      state?.extraProps?.onUpdateOrderItem(row.id, {
        projectId: value,
        projectName: option?.name || '',
        lastUpdatedProject: isShowLastUpdatedProjects,
      });
    },
    [state?.extraProps?.onUpdateOrderItem, isShowLastUpdatedProjects],
  );

  const showApplyToAllItems = useMemo(() => {
    if (isShowLastUpdatedProjects) {
      return row.lastUpdatedProject;
    }
  }, [isShowLastUpdatedProjects, row]);

  return (
    <div className="mt-1">
      <FormProjectSelect
        key={row.id}
        className="w-[160px]"
        placeholder="No Project"
        size="small"
        value={row.projectId}
        onChange={onChange}
      />
      {showApplyToAllItems ? (
        <span
          className="apply-to-all-link flex justify-end text-blue-900 underline"
          onClick={() => {
            state.extraProps?.onUpdateOrderItems?.({
              projectId: row.projectId || '',
              projectName: row.projectName || null,
            });
          }}>
          Apply to all items
        </span>
      ) : null}
    </div>
  );
};

export default ProjectValueNode;
