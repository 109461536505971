import { OrderRequestItemSchema, OrderRequestItemTypeEnum } from '../../../../types/schema';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import OrderRequestItemInStockEditView from '../OrderRequestItemInStockEditView';
import OrderRequestNoSKUEditView from '../OrderRequestNoSKUEditView';
import { isAssetInventoryTypeItem } from '../../views/OrderDetail/hook/useOrderRequestItems/utils';
import { memo } from 'react';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
  valueKey: string;
}

const OrderRequestItemDescriptionEditView = (props: Props) => {
  const { orderRequestItem, state, actions, valueKey } = props;
  const { orderDetail } = state;
  const { type } = orderRequestItem;

  const originalOrderRequestType = orderDetail.orderRequestItems.find(
    (orderItem) => orderItem.id === orderRequestItem.id,
  )?.type;

  if (
    (valueKey !== 'sku' &&
      !isAssetInventoryTypeItem(type) &&
      originalOrderRequestType === OrderRequestItemTypeEnum.NoSku) ||
    (type === OrderRequestItemTypeEnum.NoSku && valueKey !== 'sku')
  ) {
    return (
      <OrderRequestNoSKUEditView
        orderRequestItem={orderRequestItem}
        actions={actions}
        state={state}
        valueKey={valueKey}
      />
    );
  }

  if (isAssetInventoryTypeItem(type) && valueKey === 'sku') {
    return (
      <OrderRequestItemInStockEditView orderRequestItem={orderRequestItem} actions={actions} />
    );
  }

  return null;
};

export default memo(OrderRequestItemDescriptionEditView);
