
import { SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { isAccessible } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

const action = AllowedPermissionActionsEnum.Access;

export const getNetworkAssetLayoutRoutesConfig = () => {

  return [
    {
      id: 'networkAssets',
      route: routes.NetworkDashboard(),
      routes: [routes.NetworkDashboard(), routes.NetworkAssets(), routes.NetworkAssetEdit(':id'), routes.NetworkAssetDetail(':id'), routes.NetworkDiscoveryTool(), routes.NetworkInsights(), routes.NetworkDiscoveredAssets()],
    },
    {
      id: 'users',
      route: routes.ITAssetUsers(),
      routes: [routes.ITAssetUsers()],
    },
    {
      id: 'dashboard',
      route: routes.NetworkDashboard(),
      routes: [routes.NetworkDashboard()],
    },
    {
      id: 'discovered',
      route: routes.NetworkDiscoveredAssets(),
      routes: [routes.NetworkDiscoveredAssets()],
    },
    {
      id: 'assets',
      route: routes.NetworkAssets(),
      routes: [routes.NetworkAssets(), routes.NetworkAssetDetail(':id'), routes.NetworkAssetEdit(':id')],
    },
    {
      id: 'discovery-tool',
      route: routes.NetworkDiscoveryTool(),
      routes: [routes.NetworkDiscoveryTool()],
    },
    {
      id: 'asset-insights',
      route: routes.NetworkInsights(),
      routes: [routes.NetworkInsights()],
    },
  ];
};

export const getNetworkAssetLayoutSidebarItems = (
  permissions: UserProfileFragment['workspacePermissions'],
) => {
  return [
    {
      id: 'section-1',
      sideBarItems: [
        {
          id: 'networkAssets',
          label: "IT Assets",
          subject: AllowedPermissionsSubjectEnum.ItAsset,
        },
        {
          id: 'users',
          label: "Users",
          subject: AllowedPermissionsSubjectEnum.ItAsset,
        },
        // I will use these tabs in future
        // {
        //   id: 'contracts',
        //   label: "Contracts",
        //   subject: AllowedPermissionsSubjectEnum.Asset,
        // },
        // {
        //   id: 'helpdesk',
        //   label: "Help Desk Tickets",
        //   subject: AllowedPermissionsSubjectEnum.Asset,
        // }
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },

  ] as SideBarItemGroup[];
};

export const getNetworkAssetlayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) => {
  return [
    {
      label: 'Dashboard',
      value: 'dashboard',
      subject: AllowedPermissionsSubjectEnum.ItAssetDashboard,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'IT Assets',
      value: 'assets',
      subject: AllowedPermissionsSubjectEnum.ItAsset,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Discovered Assets',
      value: 'discovered',
      subject: AllowedPermissionsSubjectEnum.ItAssetDiscoveryAsset,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Discovery Tool',
      value: 'discovery-tool',
      subject: AllowedPermissionsSubjectEnum.ItAssetDiscoveryTool,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Insights',
      value: 'asset-insights',
      subject: AllowedPermissionsSubjectEnum.ItAssetInsight,
      action: AllowedPermissionActionsEnum.Access,
    }
  ].filter((item) => isAccessible(permissions, item.action, item.subject));
};