import { css } from '@emotion/css';

export const titleBarStyles = css`
  div[class^='makeStyles-title'] {
    padding-top: 0;
  }
`;

export const styles = {
  fileName: {
    overflowWrap: 'anywhere'
  }
}