import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Category from 'app/i18n/Category';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateCategoryInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import CategoryForm from '../../components/CategoryForm';
import { useCreateCategoryMutation } from './graphql/queries/generated/createCategory';
import analytics from 'app/analytics';

const DEFAULT_VALUES: CreateCategoryInput = {
  name: '',
  description: '',
  categoryCode: '',
};

function AddCategory(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateCategory] = useCreateCategoryMutation();

  const onSubmit = useCallback(
    (input: CreateCategoryInput) => {
      onCreateCategory({ input }).then((response) => {
        analytics?.track('Created', { name: 'Category' });
        if (response.data?.createCategory.category?.id) {
          SnackbarService.show({ message: 'This category has been successfully created.' });
          history.replace(routes.Categories());
        }
      });
    },
    [history, onCreateCategory],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Categories());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Category.NewCategory} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <CategoryForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddCategory);
