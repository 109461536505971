import { forwardRef, useCallback } from 'react';
import { cx } from '@emotion/css';
import Checkbox from '@mui/material/Checkbox';
import { Maybe } from '../../../types/schema';
import Stack from '../../../ui-components/Stack';

interface Props {
  label?: string | React.ReactNode;
  classNames?: string;
  value?: Maybe<boolean>;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  name?: string;
  medium?: boolean;
}

const FormCheckBoxInput = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { label, value, classNames, onChange, disabled, name, medium } = props;

  const onInputChange = useCallback(
    (_event: any, newValue: boolean) => {
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  return (
    <Stack
      ref={ref}
      alignItems="center"
      className="cursor-pointer"
      justifyContent="start"
      onClick={() => {
        if (!disabled) {
          onInputChange('', !value);
        }
      }}>
      <Checkbox
        checked={value || false}
        className={cx(value ? '!text-[#66cd33]' : '!text-grey-800', classNames)}
        disabled={disabled}
        name={name}
        size={medium ? 'medium' : 'small'}
        onChange={onInputChange}
      />
      <p className="text-13 font-semibold text-grey-800">{label}</p>
    </Stack>
  );
});

export default FormCheckBoxInput;
