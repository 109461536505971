import WishlistReferOther from 'app/modules/wishlist/views/referOther';

import WishlistLayout from '../../modules/wishlist/WishlistLayout';
function WishlistReferOtherPage() {
  return (
    <WishlistLayout>
      <WishlistReferOther />
    </WishlistLayout>
  );
}

export default WishlistReferOtherPage;
