export const UNASSIGNED_TEXT = 'Unassigned';

export const getLocationName = (
  siteId?: string,
  location?: {
    id?: string;
    name?: string;
  },
): string => {
  if (!!siteId && !!location && isUnassignedLocation(location?.id || '', siteId)) {
    return UNASSIGNED_TEXT;
  }
  return location?.name || '';
};

export const isUnassignedLocation = (locationId: string, siteId: string) => {
  return siteId === locationId;
};

export const adaptLocation = (siteId: string, location: any) => {
  if (siteId && isUnassignedLocation(location.id, siteId)) {
    return {
      ...location,
      name: getLocationName(siteId, location),
    };
  }
  return {
    ...location,
  };
};
