import Grid from '@mui/material/Grid';
import AccessControl from 'app/components/AccessControl';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import CompanyList from 'app/modules/contacts/components/companySearch';
import ContactList from 'app/modules/contacts/components/contactList';
import PageTitle from 'app/ui-components/Title';

import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import AddressList from '../../components/addressList';
import DuplicateFormWrapper from '../../components/duplicateFormWrapper';
import { Box, Divider } from '@mui/material';

const Company = (props: RouteComponentProps<{ id: string }>) => {
  const { workspacePermissions } = useCurrentUser();
  const { match } = props;
  const companyId = match.params.id || '';

  return (
    <>
      <PageTitle title={Contact.Company} />
      <Box className="w-full border-x-[1px] border-grey-300 bg-white">
        <Divider />
      </Box>
      <Box className="flex flex-wrap gap-[48px] rounded-bl-[12px] rounded-br-[12px] bg-transparent pb-[40px] ">
        <Box className="w-[320px] md:min-w-[380px] lg:max-w-[380px] xl:max-w-[380px]">
          <AccessControl
            action={AllowedPermissionActionsEnum.Read}
            permissions={workspacePermissions}
            subject={AllowedPermissionsSubjectEnum.Company}>
            <CompanyList />
          </AccessControl>
        </Box>
        <Box className="flex-1">
          <AccessControl
            action={AllowedPermissionActionsEnum.Read}
            permissions={workspacePermissions}
            subject={AllowedPermissionsSubjectEnum.Company}>
            <DuplicateFormWrapper form={'Company'} />
          </AccessControl>
          {companyId && (
            <>
              <AccessControl
                action={AllowedPermissionActionsEnum.Read}
                permissions={workspacePermissions}
                subject={AllowedPermissionsSubjectEnum.Contact}>
                <ContactList />
              </AccessControl>
              <AccessControl
                action={AllowedPermissionActionsEnum.Read}
                permissions={workspacePermissions}
                subject={AllowedPermissionsSubjectEnum.Address}>
                <AddressList />
              </AccessControl>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Company);
