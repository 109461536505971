import FormDatePickerInput from '../../../components/ProcureForm/FormDatePickerInput';
import React from 'react';

interface Props {
  value: Date | null;
  onChange: (value: Date | null) => void;
  disabled?: boolean;
  placeholder?: string;
}

const DateTableFilter = (props: Props) => {
  const { disabled, onChange, value, placeholder } = props;
  return (
    <FormDatePickerInput
      value={value}
      clearable
      disabled={disabled}
      onChange={onChange}
      size="small"
      placeholder={placeholder}
      classes="table-date-filter-input"
    />
  );
};

export default DateTableFilter;
