import { css } from '@emotion/css';

export const FormDatePickerInputStyles = css`
  .MuiOutlinedInput-input {
    font-size: 13px;
  }

  .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 8px;
  }
`;
