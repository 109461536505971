import {
  composeValidators,
  emailValidator,
  textMaxLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import SignUp from 'app/i18n/Signup';
import { Maybe } from 'app/types/schema';

const zipCodeValidator = (message: string) => (value?: Maybe<string>) =>
  (value?.length === 5 && /^[0-9]+$/.test(value)) || message || false;

export const SIGNUP_FORM_RULES = {
  firstName: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.FirstName),
      textMaxLengthValidator(SignUp.FormValidations.FirstName, 30),
    ),
  },
  lastName: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.LastName),
      textMaxLengthValidator(SignUp.FormValidations.LastName, 30),
    ),
  },
  password: {
    validate: textValidator(SignUp.FormValidations.Password),
  },
  email: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.EmailRequired),
      emailValidator(SignUp.FormValidations.EmailInvalid),
    ),
  },
  primaryPhoneNumber: {
    validate: textValidator(SignUp.FormValidations.PrimaryPhoneNumberRequired),
  },
  organization: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.Organization),
      textMaxLengthValidator(SignUp.FormValidations.Organization, 30),
    ),
  },
  address: {
    validate: textValidator(SignUp.FormValidations.Address),
  },
  city: {
    validate: textValidator(SignUp.FormValidations.City),
  },
  state: {
    validate: textValidator(SignUp.FormValidations.State),
  },
  website: {
    validate: textValidator(SignUp.FormValidations.WebsiteInvalid),
  },
  zipCode: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.Zip),
      zipCodeValidator(SignUp.FormValidations.ZipInvalid),
    ),
  },
};
