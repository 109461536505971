import { APP_CONFIG } from "app/consts/config";
import { request } from "app/utils/request";

export const requestAgentUrl = async (fileName: string) => {
	console.log(APP_CONFIG.downloadAgentSignUrl)
	const response = await request(
		APP_CONFIG.downloadAgentSignUrl,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				fileName: fileName
			}),
		},
		{
			auth: true,
		},
	);
	const result =  await response.json();

	if(result?.url){
		const  { url } = result;
		window.open(url, '_blank');
	}
};