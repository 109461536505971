import Undelete from 'app/i18n/Undelete';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import SearchBox from '../../components/SearchBox';
import UndeleteItemsTable from '../../components/UndeleteItemsTable';
import { useUndeleteAssetMutation } from './graphql/mutations/generated/undeleteAsset';
import useDeletedItemsTable from './hook/useDeletedAssetsTable';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { getUndeleteAssetTableColumns } from './utils/columns';
import { Box } from '@mui/material';

function UndeleteItems(props: RouteComponentProps) {
  const { search, filters, table, data, rows, fetching, onNextPage, onPrevPage, onReset } =
    useDeletedItemsTable();
  const [{ fetching: assetLoading }, onUndeleteAsset] = useUndeleteAssetMutation();
  const { searchSuggestions, setSearchValue } = useSearchSuggestion();
  return (
    <>
      <EntityManager
        subject={AllowedPermissionsSubjectEnum.Undelete}
        className="pt-0  md:px-24 lg:px-24"
        layoutWrapperClass>
        <EntityManager.Title
          documentTitle={Undelete.UndeleteAsset}
          title={Undelete.UndeleteAsset}
          hasTopDivider
        />
        <Box
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}
          className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
          <UndeleteItemsTable
            columns={getUndeleteAssetTableColumns}
            data={rows}
            fetching={fetching}
            persistKey="undelete_assets_table"
            siteId={filters.site.value}
            table={table}
            total={data?.assetItems.totalCount}
            undeleting={assetLoading}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
            onUndeleteItems={onUndeleteAsset}
            filterNodes={
              <SearchBox
                filters={filters}
                search={search}
                searchSuggestions={searchSuggestions}
                setSearchValue={setSearchValue}
              />
            }
            paginationWrapper="p-[24px] flex-wrap md:gap-[24px]"
          />
        </Box>
      </EntityManager>
    </>
  );
}

export default withRouter(UndeleteItems);
