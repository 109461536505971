import AdminLayout from 'app/components/AdminLayout';
import Locations from 'app/modules/locations/views/Locations';

function LocationsPage() {
  return (
    <AdminLayout>
      <Locations />
    </AdminLayout>
  );
}

export default LocationsPage;
