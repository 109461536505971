import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { UserProfileFragmentDoc } from '../../fragments/generated/UserProfile';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', currentUser: { __typename?: 'UserSchema', id: string, firstName: string, lastName?: string | null, emailId: string, name: string, tenantId: string, isSuperAdmin?: boolean | null, profilePicture?: string | null, isExternalUser?: boolean | null, workspacePermissions: Array<{ __typename?: 'UserWorkspacePermission', action: Types.AllowedPermissionActionsEnum, subject: Types.AllowedPermissionsSubjectEnum }>, roles: Array<{ __typename?: 'RoleSchema', id: string, name: string }>, defaultSite?: { __typename?: 'LocationSchema', id: string, name: string, status: Types.LocationStatusEnum } | null, tenant?: { __typename?: 'TenantSchema', id: string, name: string, tenantCode: string, moduleVisibilityConfig?: { __typename?: 'ModuleVisibilityConfigType', assets: Types.TenantFeatureTypeEnum, itAsset: Types.TenantFeatureTypeEnum, inventory: Types.TenantFeatureTypeEnum, contacts: Types.TenantFeatureTypeEnum, orderRequest: Types.TenantFeatureTypeEnum, shipping: Types.TenantFeatureTypeEnum, receiving: Types.TenantFeatureTypeEnum, report: Types.TenantFeatureTypeEnum, admin: Types.TenantFeatureTypeEnum, shop: Types.TenantFeatureTypeEnum, wishlist: Types.TenantFeatureTypeEnum } | null } | null, parentPartnerTenants?: Array<{ __typename?: 'TenantSchema', id: string, name: string, accessibleCategories?: Array<{ __typename?: 'CategorySchema', id: string, name: string }> | null }> | null } };


export const UserProfileDocument = gql`
    query userProfile {
  currentUser {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

export function useUserProfileQuery(options?: Omit<Urql.UseQueryArgs<UserProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<UserProfileQuery>({ query: UserProfileDocument, ...options });
};