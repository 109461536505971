import { isAccessible } from 'app/components/AccessControl';
import { EntityType, ItemType } from 'app/consts/enum';
import Common from 'app/i18n/Common';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

const action = AllowedPermissionActionsEnum.Undelete;

export const getUndeleteTabs = (permissions: UserProfileFragment['workspacePermissions']) => {
  return [
    {
      label: Common.Modules.Undelete.UndeleteAsset,
      value: ItemType.Asset,
      subject: AllowedPermissionsSubjectEnum.Asset,
    },
    {
      label: Common.Modules.Undelete.UndeleteInventory,
      value: ItemType.Inventory,
      subject: AllowedPermissionsSubjectEnum.Inventory,
    },
    {
      label: Common.Modules.Undelete.UndeleteUser,
      value: EntityType.User,
      subject: AllowedPermissionsSubjectEnum.User,
    }
  ].filter((item) => isAccessible(permissions, action, item.subject));
};
