export const getParentsUntil = (el: Element, selector: string): Element[] => {
  let parents: Element[] = [];
  let _el: Node | null = el.parentNode;

  while (_el instanceof Element) {
    // Ensures _el is an instance of Element, which includes HTMLElement
    parents.unshift(_el);
    if (_el.matches(selector)) return parents;
    _el = _el.parentNode;
  }

  return parents;
};
