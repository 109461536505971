import { useCallback, useEffect } from 'react';
import { Button, TitleBar } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Label from 'app/i18n/Label';
import EntityManager from 'app/modules/components/EntityManager';
import Stack from 'app/ui-components/Stack';

import { LabelsTableProps } from './types';
import { Box } from '@mui/material';

const LabelsTable = (props: LabelsTableProps) => {
  const {
    columns,
    data,
    state,
    actions,
    setState,
    total = 0,
    loading,
    onNextPage,
    onPrevPage,
    onReset,
    onPrintLabels,
    filterNode,
    tableType,
  } = props;

  const onCancel = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    const updatedSelectedRowsData = state.selectedRowData.filter((row) => {
      return state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = state.selectedRowIds.filter((id) => {
      return !state.selectedRowData.find((data) => data.id === id);
    });

    const newRows: any = data?.filter((row: any) => newSelectedIds.includes(row.id));
    setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [state.selectedRowIds, data, setState]);

  return (
    <>
      <Box className="mb-[24px]">
        <TitleBar title={`${tableType} Search` || ''} />
      </Box>
      <Box
        className="rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          pagination
          persistSelectionData
          actions={actions}
          columns={columns}
          data={data}
          loading={loading}
          persistKey="labels_table"
          setState={setState}
          state={state}
          total={total}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          paginationWrapperClass="!p-[24px] flex-wrap md:gap-[24px]"
          filterNodes={filterNode}
        />
        <Stack className="gap-[16px] py-[16px] px-[24px]" justifyContent="end">
          <Button
            theme="success"
            onClick={onPrintLabels}
            disabled={!state.selectedRowIds.length}
            classes="min-w-[120px] h-[44px]">
            {Label.PrintLabels}
          </Button>
          <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default LabelsTable;
