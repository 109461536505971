import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type ManufacturerTableRowFragment = { __typename?: 'ManufacturerSchema', id: string, name: string, emailId?: string | null, phoneNo?: string | null, url?: string | null, updatedAt: string };

export const ManufacturerTableRowFragmentDoc = gql`
    fragment ManufacturerTableRow on ManufacturerSchema {
  id
  name
  emailId
  phoneNo
  url
  updatedAt
}
    `;