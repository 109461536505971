import { Box } from "@mui/material";
import { Autocomplete, TextField } from "@procurenetworks/procure-component-library";
import FormLabel from "app/components/ProcureForm/FormLabel";
import Receivables from "app/i18n/Receivables";
import { forwardRef, useCallback, useEffect, useState } from "react";

export const FormVendorSelect = forwardRef<HTMLDivElement, any>((props, ref) => {
    const { value, onChange, onBlur, disabled,label, vendorOptions, error } = props;
    const [selectedValue, setSelectedValue] = useState(null);
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
      const selected = vendors && vendors.find((vendor:any) => vendor.value === value);
      if (selected) {
        setSelectedValue(selected);
      }
    }, [value, vendors]);

    const onValueChange = useCallback(
      (event: React.SyntheticEvent, option: any) => {
        setSelectedValue(option);
        if (option) {
          onChange(option.value);
        } else {
          onChange('');
        }
      },
      [onChange]
    );    

    useEffect(() => {
      if(vendorOptions){
        let options =
        vendorOptions?.valuesInEntities.payload[0].entities.map((entity:any) => {
          return {
            entity,
            value: entity.id,
            label: entity.name,
          };
        }) || [];

        setVendors(options)
      }
    },[vendorOptions])

    return (
     <Box ref={ref} className="flex flex-col">
        <FormLabel className="text-[14px] font-medium text-grey-800" >
          {label}
        </FormLabel>
        <Autocomplete
          label=""
          className="py-2 w-full rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none"
          disabled={disabled}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          options={vendors}
          renderInput={(params) => (
            <TextField
              {...params}
              label={Receivables.FormLabels.vendor}
              placeholder="Select vendor"
              error={!!error}
              helperText={error?.message}
            />
          )}
          size="medium"
          value={selectedValue}
          onChange={onValueChange}
          onBlur={onBlur}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        />
      </Box>
    );
  });