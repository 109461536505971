import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import Common from 'app/i18n/Common';
import { InvitePartnerTenantInput } from 'app/types/schema';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { SnackbarService } from '../../../../components/Snackbar';
import Partner from '../../../../i18n/Partner';
import { useInvitePartnerTenantMutation } from '../../graphql/mutations/generated/InvitePartnerTenant';
import InvitePartnerForm from '../InvitePartnerForm';
import { UseInvitePartnerDialogStateReturnType } from './hook/useInvitePartnerDialog';
import { createInvitePartnerTenantInput } from './utils';

const DEFAULT_VALUES: InvitePartnerTenantInput = {
  emailId: '',
  firstName: '',
  lastName: '',
};

interface Props {
  // onCancel: () => void;
  state: UseInvitePartnerDialogStateReturnType['state'];
  setState: UseInvitePartnerDialogStateReturnType['setState'];
}

const InvitePartnerDialog = (props: Props) => {
  const { state, setState } = props;

  const [{ fetching }, onInvitePartnerTenant] = useInvitePartnerTenantMutation();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const handleCancel = useCallback(() => {
    reset();
    setState({
      open: false,
    });
  }, [setState, reset]);

  const onSubmit = useCallback(
    async (values: InvitePartnerTenantInput) => {
      const response = await onInvitePartnerTenant({
        input: createInvitePartnerTenantInput(values),
      });

      if (response?.data?.invitePartnerTenant?.success) {
        handleCancel();

        SnackbarService.show({
          message: Partner.SuccessMessages.TenantInvited,
        });
      }
    },
    [onInvitePartnerTenant, setState, reset, handleCancel],
  );

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const partnerValues = {
          ...values,
          firstName: removeMultipleSpaces(values.firstName),
          lastName: removeMultipleSpaces(values?.lastName || ''),
          emailId: removeMultipleSpaces(values?.emailId || ''),
        };
        onSubmit(partnerValues);
      }),
    [onSubmit, handleSubmit],
  );

  return (
    <Modal
      actions={
        <div className="flex gap-[16px]">
          <Button
            loading={fetching}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Invite}
          </Button>
          <Button disabled={fetching} onClick={handleCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="invite-partner-dialog"
      open={state.open}
      title="Invite Partner"
      onClose={handleCancel}>
      <div className="min-w-[632px]">
        <InvitePartnerForm control={control} loading={fetching} />
      </div>
    </Modal>
  );
};

export default InvitePartnerDialog;
