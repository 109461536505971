import { useMemo } from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import { Redirect } from 'app/libs/navigation';

import Loader from '../../../../components/Loader';
import routes from '../../../../consts/routes';
import Assets from '../../../../i18n/Assets';
import { AssetItemEdge, ItemStatusEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import DeletedAssetContent from './components/DeletedAssetContent';
import { useDeletedAssetDataQuery } from './graphql/query/generated/deletedAssetData';

interface Props {
  assetId: string;
}

const DeletedAsset = (props: Props) => {
  const { assetId } = props;

  const [{ data, fetching }] = useDeletedAssetDataQuery({
    variables: {
      filters: {
        itemIds: [assetId],
        statuses: [ItemStatusEnum.Deleted]
      },
    },
  });

  const assetItem: AssetItemEdge['node'] | undefined = useMemo(() => {
    return (data?.assetItems?.edges?.[0]?.node as AssetItemEdge['node']) || undefined;
  }, [data?.assetItems?.edges]);

  if (fetching) {
    return <Loader />;
  }

  if (!assetItem) {
    return <Redirect to={routes.AssetsSearch()} />;
  }

  return (
    <EntityManager>
      <EntityManager.Title documentTitle={Assets.DeletedAssetTitle} title={Assets.DeletedAsset} />
      <Content>
        <DeletedAssetContent assetItem={assetItem} />
      </Content>
    </EntityManager>
  );
};

export default DeletedAsset;
