import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { SitesTableRowFragmentDoc } from '../../../../Sites/graphql/fragments/generated/SitesTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateSiteMutationVariables = Types.Exact<{
  input: Types.UpdateLocationInput;
}>;


export type UpdateSiteMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'UpdateLocationPayload', success: boolean, reason?: string | null, location?: { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, updatedAt: string, locationCode?: string | null, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, siteManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }>, siteStaffs: Array<{ __typename?: 'UserSchema', id: string, name: string }> } | null } };


export const UpdateSiteDocument = gql`
    mutation updateSite($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    success
    reason
    location {
      ...SitesTableRow
    }
  }
}
    ${SitesTableRowFragmentDoc}`;

export function useUpdateSiteMutation() {
  return Urql.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument);
};