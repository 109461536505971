import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormInputSignUp from 'app/components/Form/FormInputSignUp';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import { SIGNUP_FORM_RULES as rules } from '../rules';
import { SignUpStepFourProps } from '../types';

function SignUpStepFour(props: SignUpStepFourProps) {
  const { onConfirm, onClose, firstName, lastName, defaultValues, isLoading } = props;

  const { control, handleSubmit } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onConfirm), [handleSubmit, onConfirm]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <h1 className="mt-12 text-center text-[36px] font-semibold text-grey-900">
        {SignUp.FormLabels.stepFourTitle}
      </h1>
      <p className="mt-12 text-center text-grey-900">
        What's the web address for {firstName} {lastName}?
      </p>
      <Box className="mx-auto max-w-[550px] space-y-24 px-20 py-40">
        <Controller
          control={control}
          name="url"
          render={({ field, fieldState }) => (
            <FormInputSignUp
              {...field}
              autoFocus
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={SignUp.FormLabels.Website}
              response={SignUp.FormValidations.WebsiteExist}
            />
          )}
          rules={rules.website}
        />
        <Stack className="flex justify-between" spacing="space-x-32">
          <Button classes="" onClick={onClose}>
            {SignUp.FormActions.previous}
          </Button>
          <Button classes="" loading={isLoading} theme="success" onClick={onFormSubmit}>
            {SignUp.FormActions.completeSignup}
          </Button>
        </Stack>
      </Box>
      <Box className="mt-28 flex justify-center">
        <p className="max-w-[500px] text-center text-grey-900">
          {SignUp.FormLabels.termsAndPolicy}
          <a
            className="text-blue-900"
            href="https://www.procure.co/index.php/terms-of-service/"
            rel="noreferrer"
            target="_blank">
            {SignUp.FormLabels.termLabel}
          </a>
          and
          <a
            className="text-blue-900"
            href="https://www.procure.co/index.php/privacy-policy/"
            rel="noreferrer"
            target="_blank">
            {SignUp.FormLabels.policyLabel}
          </a>
        </p>
      </Box>
    </form>
  );
}

export default SignUpStepFour;
