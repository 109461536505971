import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Portal } from '@mui/material';
import noop from 'lodash/noop';

import Snackbar, { duration } from './Snackbar';
import { SnackbarContentProps, SnackbarContextType, SnackbarProviderProps } from './types';

const defaultValue: SnackbarContextType = { show: noop, showError: noop, showWarning: noop, showInfo: noop,  clear: noop };

export let SnackbarService = defaultValue;
const SnackbarContext = createContext<SnackbarContextType>(defaultValue);

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<SnackbarContentProps | null>(null);
  const [snackbarTimeOutId, setSnackbarTimeOutId] = useState<any>(null);
  const onClose = useCallback(() => setSnackbar(null), []);
  const container = useRef(null);

  const clearSnackbarTimeOut = useCallback(() => {
    if (snackbarTimeOutId) {
      clearTimeout(snackbarTimeOutId);
    }
  }, [snackbarTimeOutId]);

  const setSnackBarTimeOut = useCallback(() => {
    const timeOutId = setTimeout(() => {
      onClose();
    }, duration);

    setSnackbarTimeOutId(timeOutId);
  }, [setSnackbarTimeOutId]);

  const value = useMemo<SnackbarContextType>(
    () => ({
      show: (params) => {
        clearSnackbarTimeOut();
        setSnackbar({ type: 'success', ...params });
        setSnackBarTimeOut();
      },
      showError: (params) => {
        clearSnackbarTimeOut();
        setSnackbar({ type: 'error', ...params });
        setSnackBarTimeOut();
      },
      showWarning: (params) => {
        clearSnackbarTimeOut();
        setSnackbar({ type: 'warning', ...params });
        setSnackBarTimeOut();
      },
      showInfo: (params) => {
        clearSnackbarTimeOut();
        setSnackbar({ type: 'info', ...params });
        setSnackBarTimeOut();
      },
      clear: () => {
        clearSnackbarTimeOut();
        setSnackBarTimeOut();
        setSnackbar(null);
      },
    }),
    [
      setSnackbarTimeOutId,
      snackbarTimeOutId,
      setSnackBarTimeOut,
      setSnackBarTimeOut,
      clearSnackbarTimeOut,
    ],
  );

  useEffect(() => {
    SnackbarService = value;

    return () => {
      SnackbarService = defaultValue;
    };
  }, [value]);

  return (
    <Portal container={container.current}>
      <SnackbarContext.Provider value={value}>
        {children}
        <Snackbar message="" open={Boolean(snackbar)} onClose={onClose} {...snackbar} />
      </SnackbarContext.Provider>
    </Portal>
  );
};

const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
};

export { useSnackbar };
export default SnackbarProvider;
