import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { useInventorySearchSuggestionQuery } from 'app/modules/inventory/views/Inventory/graphql/queries/generated/inventorySearchSuggestion';
import { InventoryItemSchema, ItemTypeEnum } from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

const useSearchSuggestion = (options?: { siteId?: string }) => {
  const { siteId } = options || {};

  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useInventorySearchSuggestionQuery({
    variables: {
      filters: {
        search: searchedValue,
        siteIds: siteId ? [siteId] : [],
        types: [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit],
        includeLocationsInSearch: !!siteId,
      },
    },
  });

  useEffect(() => {
    const result = data?.items?.edges?.map((a) => {
      const item = a.node as InventoryItemSchema;
      const { result, searchBy } = processSearch(
        ['title', 'sku', 'description'],
        searchedValue,
        item,
      );

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });

    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
