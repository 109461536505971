import { forwardRef } from 'react';
import { DepartmentsSelectProps } from 'app/modules/departments/components/DepartmentSelect';

import DepartmentSelect from '../../../modules/departments/components/DepartmentSelect';
import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & DepartmentsSelectProps;

const FormDepartmentSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer
      ref={ref}
      className={className}
      error={error}
      isRequired={isRequired}
      label={label}>
      <DepartmentSelect {...selectProps} />
    </FormFieldContainer>
  );
});

export default FormDepartmentSelect;
