import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type SitesTableRowFragment = { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, updatedAt: string, locationCode?: string | null, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, siteManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }>, siteStaffs: Array<{ __typename?: 'UserSchema', id: string, name: string }> };

export const SitesTableRowFragmentDoc = gql`
    fragment SitesTableRow on LocationSchema {
  id
  name
  description
  updatedAt
  locationCode
  updatedBy {
    id
    name
  }
  siteManagers {
    id
    name
  }
  siteStaffs {
    id
    name
  }
}
    `;