import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { useCategoriesSelectQuery } from 'app/modules/categories/graphql/queries/generated/categoriesSelect';
import { CategorySchema, SortOrderEnum } from '../../../types/schema';
import { adaptNodeEdgeToNode } from '../../AsyncMultiSelect/utils';
import useCurrentUser from '../../../modules/auth/hooks/useCurrentUser';

export type Option = Pick<CategorySchema, 'id' | 'name'>;

const getOptionLabel = (option: any) => option.name;

type Props = {
  className?: string;
  label?: string;
  name: string;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string, option: Option | null) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  partnerTenantId?: string;
};

const FormCategorySelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { onChange, value, className, placeholder, partnerTenantId } = props;
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const { parentPartnerTenants } = useCurrentUser();

  const partnerTenantOptions = useMemo(() => {
    if (!partnerTenantId) {
      return [];
    }
    const partnerTenantData = parentPartnerTenants?.find(
      (parentPartnerTenant) => parentPartnerTenant.id === partnerTenantId,
    );
    return partnerTenantData?.accessibleCategories || [];
  }, [partnerTenantId, parentPartnerTenants]);

  const [{ fetching, data }] = useCategoriesSelectQuery({
    variables: {
      filters: { search: '' },
      limit: 1000,
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
    pause: !!partnerTenantId,
  });

  const options = useMemo(() => {
    if (partnerTenantId) {
      return partnerTenantOptions;
    }
    return data?.categories?.edges?.map(adaptNodeEdgeToNode) || [];
  }, [data?.categories?.edges, partnerTenantOptions, partnerTenantId]);

  const onValueChange = useCallback(
    (_: any, option: any) => {
      const updatedOption = option ? (option as Option) : null;
      const id = updatedOption?.id || '';
      setSelectedValue(updatedOption);
      onChange?.(id, updatedOption);
    },
    [onChange, setSelectedValue],
  );

  useEffect(() => {
    const selectedOption = options.find((option) => option.id === value);
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [options]);

  return (
    <div ref={ref} className="mt-10">
      <Autocomplete
        className={className}
        getOptionLabel={getOptionLabel}
        label={''}
        loading={fetching}
        options={options}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} size="small" />}
        value={selectedValue as any}
        onChange={onValueChange}
        size="small"
      />
    </div>
  );
});

export default FormCategorySelect;
