import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import FormFieldContainer from 'app/components/Form/FormFieldContainer';
import { SelectOption } from 'app/components/Select/types';
import { OrderRequestItemStatusEnum } from 'app/types/schema';

const getOptions = (): SelectOption[] => {
  return [
    { value: OrderRequestItemStatusEnum.BackOrdered, label: 'Back Ordered' },
    { value: OrderRequestItemStatusEnum.Cancelled, label: 'Cancelled' },
    { value: OrderRequestItemStatusEnum.Closed, label: 'Closed' },
    { value: OrderRequestItemStatusEnum.Delivered, label: 'Delivered' },
    { value: OrderRequestItemStatusEnum.Ordered, label: 'Ordered' },
    { value: OrderRequestItemStatusEnum.Open, label: 'Open' },
    { value: OrderRequestItemStatusEnum.OutForDelivery, label: 'Out-for-delivery' },
    { value: OrderRequestItemStatusEnum.Packed, label: 'Packed' },
    { value: OrderRequestItemStatusEnum.Returned, label: 'Returned' },
    { value: OrderRequestItemStatusEnum.ReturnPacked, label: 'Return packed' },
    { value: OrderRequestItemStatusEnum.ReturnOutForDelivery, label: 'Return out-for-delivery' },
  ];
};

interface SelectProps {
  value: string[];
  onChange: (values: string[]) => void;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, options } = props;
  const selectedValues = useMemo(() => {
    return value
      .map((status) => options?.find(({ value }) => value === status))
      .filter(Boolean) as SelectOption[];
  }, [value]);

  return { isDisabled: false, selectedValues };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;

  const options = useMemo(
    () =>
      getOptions().filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [inputValue],
  );

  return { isLoading: false, options };
};

const OrderRequestStatusSelect = (props: SelectProps) => {
  return (
    <AsyncMultiSelect
      {...props}
      placeholder="All"
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

interface FormFieldProps {
  control: any;
}

const OrderRequestStatusFormField = (props: FormFieldProps) => {
  const { control } = props;

  return (
    <Controller
      control={control}
      name="orderItemStatuses"
      render={({ field }) => (
        <FormFieldContainer label="Order Status">
          <OrderRequestStatusSelect {...field} />
        </FormFieldContainer>
      )}
    />
  );
};

export default OrderRequestStatusFormField;
