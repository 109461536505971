import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateRoleMutationVariables = Types.Exact<{
  input: Types.CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'CreateRolePayload', success: boolean, reason?: string | null, role?: { __typename?: 'RoleSchema', id: string } | null } };


export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    success
    reason
    role {
      id
    }
  }
}
    `;

export function useCreateRoleMutation() {
  return Urql.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument);
};