import { forwardRef } from 'react';
import DepartmentsSelect, {
  DepartmentsSelectProps,
} from 'app/modules/departments/components/DepartmentsSelect';

import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & DepartmentsSelectProps;

const FormDepartmentsSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer
      ref={ref}
      className={className}
      error={error}
      isRequired={isRequired}
      label={label}>
      <DepartmentsSelect {...selectProps} />
    </FormFieldContainer>
  );
});

export default FormDepartmentsSelect;
