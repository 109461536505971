import { OrderRequestItemSchema } from '../../../../types/schema';
import TableCostView from '../../../components/TableCostView';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
}

const OrderRequestItemCostView = (props: Props) => {
  const { orderRequestItem } = props;
  return <TableCostView cost={orderRequestItem?.cost as unknown as number} />;
};

export default OrderRequestItemCostView;
