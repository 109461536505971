import { forwardRef, HTMLInputTypeAttribute, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Button } from '@procurenetworks/procure-component-library';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';
import FormInputImageUploader from 'app/components/ProcureForm/FormInputImageUploader';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import { ButtonSize } from 'app/types/button';

interface Props {
  className?: string;
  label?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const FormInputWithButton = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    className,
    error,
    label,
    name,
    isRequired,
    value,
    onBlur,
    onChange,
    placeholder,
    disabled,
    readonly,
  } = props;

  useEffect(() => {
    !value && setIsVisible(false);
  }, [value]);

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      onChange?.(text);
    },
    [onChange],
  );

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label && <FormLabel isRequired={isRequired}> {label} </FormLabel>}
      <Box className="!mt-[6px] flex items-start gap-[16px]">
        <FormTextInput
          {...props}
          isRequired
          className="w-full md:!w-[300px] !max-w-[300px] password-input"
          error={error}
          type={isVisible ? 'text' : 'password'}
        />
        <Button
          classes="!min-w-[100px] h-[50px]"
          theme={isVisible ? 'danger' : 'success'}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          size={ButtonSize.medium}>
          {isVisible ? 'Hide' : 'Show'}
        </Button>
      </Box>
    </Box>
  );
});

export default FormInputWithButton;
