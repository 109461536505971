import 'react-phone-input-2/lib/style.css';
import PhoneInputComponent from 'react-phone-input-2';

type Props = React.ComponentProps<typeof PhoneInputComponent>;

function PhoneInput(props: Props) {
  return (
    <PhoneInputComponent
      disableCountryCode
      disableDropdown
      country="us"
      enableAreaCodes={true}
      inputStyle={{ fontFamily: "'Open Sans', sans-serif" }}
      onlyCountries={['us']}
      placeholder=""
      showDropdown={false}
      {...props}
    />
  );
}

export default PhoneInput;
