import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInventoryKitItemMutationVariables = Types.Exact<{
  input: Types.UpdateInventoryKitItemInput;
}>;


export type UpdateInventoryKitItemMutation = { __typename?: 'Mutation', updateInventoryKitItem: { __typename?: 'UpdateInventoryKitItemPayload', success: boolean, reason?: string | null, inventoryKitItem?: { __typename?: 'InventoryKitItemSchema', id: string, tenantId: string, title: string, description?: string | null } | null } };


export const UpdateInventoryKitItemDocument = gql`
    mutation updateInventoryKitItem($input: UpdateInventoryKitItemInput!) {
  updateInventoryKitItem(input: $input) {
    success
    reason
    inventoryKitItem {
      id
      tenantId
      title
      description
    }
  }
}
    `;

export function useUpdateInventoryKitItemMutation() {
  return Urql.useMutation<UpdateInventoryKitItemMutation, UpdateInventoryKitItemMutationVariables>(UpdateInventoryKitItemDocument);
};