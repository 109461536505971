import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormCategoriesSelect from 'app/components/Form/FormCategoriesSelect';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import Common from 'app/i18n/Common';
import Partner from 'app/i18n/Partner';
import FormOrganizationSelect from 'app/modules/organizations/views/AddOrganization/components/FormOrganizationSelect';
import { RoleTypeFilterEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { EditPartnerFormProps } from './types';
import { PARTNER_FORM_RULES as Rules } from './utils';

function EditPartnerForm(props: EditPartnerFormProps) {
  const { isSuperAdmin, defaultValues, loading, disabled, childTenantId, onCancel, onSubmit } =
    props;
  const { control, handleSubmit } = useForm({ defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="organization"
          render={({ field, fieldState }) => (
            <FormOrganizationSelect
              {...field}
              disabled
              error={fieldState.error}
              label={Partner.FormLabels.Organization}
            />
          )}
        />

        <Controller
          control={control}
          name="accessibleCategoryIds"
          render={({ field, fieldState }) => (
            <FormCategoriesSelect
              {...field}
              isRequired
              className="flex-1"
              disabled={disabled}
              error={fieldState.error}
              label={Partner.FormLabels.Categories}
              enabledAllOption
            />
          )}
          rules={Rules.categories}
        />
        <Controller
          control={control}
          name="allowedUserIds"
          render={({ field, fieldState }) => (
            <FormUsersSelect
              {...field}
              isRequired
              disabled={disabled}
              error={fieldState.error}
              label={Partner.FormLabels.Users}
              queryVariables={{
                roleTypes: [RoleTypeFilterEnum.ExternalUser],
                tenantIds: childTenantId ? [childTenantId] : [],
              }}
            />
          )}
          rules={Rules.users}
        />

        <Box className="flex justify-end space-x-16">
          <Button
            disabled={loading || disabled}
            loading={loading}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          {isSuperAdmin ? (
            <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Cancel}
            </Button>
          ) : null}
        </Box>
      </Box>
    </form>
  );
}

export default EditPartnerForm;
