import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { OrganizationTableRowFragmentDoc } from '../../../../Organizations/graphql/fragments/generated/OrganizationTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTenantMutationVariables = Types.Exact<{
  input: Types.UpdateTenantInput;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', updateTenant: { __typename?: 'UpdateTenantPayload', success: boolean, reason?: string | null, tenant?: { __typename?: 'TenantSchema', id: string, logo?: string | null, name: string, tenantCode: string, primaryPhoneNumber?: string | null, status: Types.TenantStatusEnum, locationDetails: { __typename?: 'TenantLocationDetailsType', address: string, city: string, state: string, zipCode: string }, admin?: { __typename?: 'UserSchema', id: string, name: string } | null } | null } };


export const UpdateTenantDocument = gql`
    mutation updateTenant($input: UpdateTenantInput!) {
  updateTenant(input: $input) {
    success
    reason
    tenant {
      ...OrganizationTableRow
    }
  }
}
    ${OrganizationTableRowFragmentDoc}`;

export function useUpdateTenantMutation() {
  return Urql.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument);
};