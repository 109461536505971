import AdminLayout from 'app/components/AdminLayout';
import Manufacturers from 'app/modules/manufacturers/views/Manufacturers';

function ManufacturerPage() {
  return (
    <AdminLayout>
      <Manufacturers />
    </AdminLayout>
  );
}

export default ManufacturerPage;
