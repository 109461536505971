import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const CardWrapper = styled.div`
  padding-left: 24px;
  padding-bottom: 24px;
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  @media (max-width: 1024px) {
    .shopCard {
      justify-content: center;
    }
  }
  @media (max-width: 1024px) {
    flex-grow: 1;
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (max-width: 600px) {
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
`;
export const Item = styled.div`
  width: 100%;
  display: inline-block;
  height: auto;
  box-sizing: border-box;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid var(--gray-300);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1) !important;
  img {
    height: 40px;
    padding: 4px 10px;
    max-width: 100%;
    margin: 0 auto;
    outline: none;
    display: block !important;
  }
  & .action-button {
    margin: 6px auto;
  }
  & .MuiCardMedia-root {
    background-size: contain !important;
    height: 158px !important;
    margin: 20px auto !important;
    border-bottom: none !important;
    max-width: 66% !important;
    max-height: 23% !important;
  }
  & .MuiPaper-elevation {
    border-radius: 16px !important;
  }
`;

export const DescriptionStyle = css`
  width: 100%;
  // line-height: 1.2em;
  height: 44px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
