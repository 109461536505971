import { useMemo } from 'react';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncSingleSelect/types';
import { Maybe, RoleTypeFilterEnum, SortOrderEnum, UserStatusEnum } from 'app/types/schema';

import AsyncSingleSelect from '../../../../components/AsyncSingleSelect';
import { useUsersSelectQuery } from '../../graphql/queries/generated/usersSelect';
import { FieldError } from 'react-hook-form';

interface UsersQueryVariables {
  roleTypes?: RoleTypeFilterEnum[];
}

export interface UsersSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: ((values: string) => void) | ((valueId: string, value: any) => void);
  placeholder?: string;
  disabled?: boolean;
  queryVariables?: UsersQueryVariables;
  label?: string;
  isRequired?: boolean;
  size?: any;
  error?: FieldError;
  formLabel?:string;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    pause,
    variables: { filters: { userIds: value ? [value] : [] } },
  });
  const selectedValue = useMemo(() => {
    const options =
      data?.users.edges?.map(adaptNodeEdgeToOption).filter((option) => option.value === value) ||
      [];
    return options?.[0];
  }, [data?.users.edges]);

  return { isDisabled: fetching, selectedValue };
};

const useSelectQuery = (props: UseSelectQueryProps & UsersQueryVariables): UseSelectQueryReturn => {
  const { inputValue, roleTypes } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    variables: {
      filters: { search: inputValue, roleTypes, statuses: [UserStatusEnum.Active] },
      limit: 10000,
      sorts: [
        {
          sortField: 'firstName',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'lastName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
  });
  const options = useMemo(
    () => data?.users.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.users.edges],
  );

  return { isLoading: fetching, options };
};

const UsersSelect = (props: UsersSelectProps) => {
  return (
    <AsyncSingleSelect
      {...props}
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

export default UsersSelect;
