import { useCallback, useEffect, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import ForgotPasswordToken from 'app/i18n/ForgotPassword';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Box from 'app/ui-components/Box';
import PageTitle from 'app/ui-components/Title';

import { ForgotPasswordTokenEnum } from '../../types';
import LoginHeader from '../Login/components/LoginHeader';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import useForgotPassword from './hooks/useForgotPassword';
import { ForgotPasswordInput, ForgotPasswordResponse } from './types';

function ForgotPassword(props: RouteComponentProps) {
  const { history } = props;
  const { fetching, execute } = useForgotPassword();

  const errorCode = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('errorCode') || '';
  }, []);

  useEffect(() => {
    if (errorCode && errorCode === ForgotPasswordTokenEnum.TOKEN_EXPIRED) {
      SnackbarService.showError({
        message: ForgotPasswordToken.ForgotPasswordTokenValidationMessages.ExpiredToken,
      });
    }
  }, [errorCode]);

  const onForgotPassword = useCallback(
    async (props: ForgotPasswordInput) => {
      props = {
        ...props,
        emailId: props.emailId.trim()
      }
      execute(props).then((result: ForgotPasswordResponse) => {
        if (result.success) {
          SnackbarService.show({
            message:
              'If this is a valid email, you will receive instructions in your inbox to reset your password.',
          });
          history.push('/login');
        } else {
          SnackbarService.showError({
            message: result.errorCode || result.message || 'Some error occurred. Please try again later.',
          });
        }
      });
    },
    [execute, history],
  );

  return (
    <>
      <LoginHeader />
      <PageTitle title="Forgot Password" />
      <Box className="w-100% h-full flex justify-center items-center">
      <Box className="w-full md:w-[360px] lg:w-[486px] bg-white p-[24px] rounded-[12px]">
          <ForgotPasswordForm disabled={fetching} onSubmit={onForgotPassword} />
        </Box>
      </Box>
    </>
  );
}

export default withRouter(ForgotPassword);
