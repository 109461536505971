import { useEffect, useState } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import Undelete from 'app/i18n/Undelete';
import ConfirmationModal from 'app/modules/components/ConfirmationModal';

import Common from '../../../../i18n/Common';
import Stack from '../../../../ui-components/Stack';
import EntityManager from '../../../components/EntityManager';
import { UndeleteUsersMutation } from '../../views/undeleteUser/graphql/mutations/generated/undeleteUsers';
import UnDeleteErrorDialog from '../UnDeleteErrorDialog';
import useUnDeleteErrorDialog from '../UnDeleteErrorDialog/hook/useUnDeleteErrorDialog';
import { UndeleteUserTableProps } from './types';
import {
  isUnDeleteHaveFailedRecords,
  isUnDeleteHaveSuccessRecords,
} from './utils';
import analytics from 'app/analytics';

const UndeleteUsersTable = (props: UndeleteUserTableProps) => {
  const [isOpenUndeleteConfirm, setIsOpenUndeleteConfirm] = useState<boolean>(false);
  const {
    table,
    fetching,
    undeleting,
    data,
    columns,
    total,
    persistKey,
    onUndeleteUsers,
    onNextPage,
    onPrevPage,
    onReset,
    filterNode,
    paginationWrapper
  } = props;

  const { state: unDeleteDialogState, setState: setUnDeleteDialogState } = useUnDeleteErrorDialog();


  const onCancelConfirm = () => {
    setIsOpenUndeleteConfirm(false);
  };

  const onUndelete = async () => {
    let userIds: Array<string> = [];
    userIds = table.state.selectedRowData.map((user) => {
      return user?.id;
    });

    onUndeleteUsers({
      input: { userIds },
    })
      .then((res) => {
        const undeleteUsersResponse = (res.data as UndeleteUsersMutation)?.undeleteUsers;
        analytics?.track('UnDelete', { name: 'Users' });
        if (undeleteUsersResponse.success ) {
          const message = userIds.length > 1 ? Undelete.SuccessMessages.UsersUndeleted : Undelete.SuccessMessages.UserUndeleted ;
          SnackbarService.show({
            message
          });
          if (userIds.length && onPrevPage) {
            onPrevPage();
          }
        }
        table.setState({ selectedRowIds: [] });
      })
      .catch((error) => {
        console.log(error);
      });
    setIsOpenUndeleteConfirm(false);
  };

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows: any = data?.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, data, table.setState]);

  return (
    <div>
      <EntityManager.MultiSelectTable
        ignoreRelayPagination
        pagination
        persistSelectionData
        actions={table.actions}
        columns={columns()}
        data={data}
        defaultSortState={{
          id: undefined,
        }}
        loading={fetching}
        persistKey={persistKey}
        setState={table.setState}
        state={table.state}
        total={total}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
        filterNodes={filterNode}
        paginationWrapperClass={paginationWrapper}
      />
      <Stack className="px-[24px] py-[16px] gap-[16px]" justifyContent="end">
        <Button
          classes="!ml-[4px]"
          disabled={!table.state.selectedRowIds.length || undeleting || fetching}
          loading={undeleting}
          theme="success"
          onClick={() => setIsOpenUndeleteConfirm(true)}>
          {Common.Actions.Undelete}
        </Button>
        <Button
          classes="!ml-[4px]"
          disabled={!table.state.selectedRowIds.length || undeleting}
          onClick={() => table.setState({ selectedRowIds: [] })}>
          {Common.Actions.Cancel}
        </Button>
      </Stack>
      <ConfirmationModal
        message={Undelete.MultipleItemUndeleteMessage(table.state.selectedRowIds.length)}
        open={isOpenUndeleteConfirm}
        type={Undelete.Undelete}
        onCancel={onCancelConfirm}
        onConfirm={onUndelete}
      />

      <UnDeleteErrorDialog setState={setUnDeleteDialogState} state={unDeleteDialogState} />
    </div>
  );
};

export default UndeleteUsersTable;
