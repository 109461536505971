type FormRefType<T> = React.RefObject<T>;

const ScrollToError = (formError: object, formRef: FormRefType<any>) => {
  if (formError) {
    const errorKeys = Object.keys(formError);
    if (formRef.current && errorKeys.length > 0) {
      const firstErrorKey = errorKeys[0];
      const errorElement = formRef.current.querySelector(`[name="${firstErrorKey}"]`);
      if (errorElement) {
        const topOffset = errorElement.getBoundingClientRect().top;
        const scrollPosition = window.scrollY + topOffset - 100;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }
    }
  }
};

export default ScrollToError;