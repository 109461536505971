import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';

import useInsertItemChange from '../../hook/useInsertItemChange';
import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderUpcAsinCodeField = (props: OrderFieldProps) => {
  const { formState, disabled, isExternalTenant } = props;
  const { control } = formState;

  const { value: isUpcCodeFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.UpcCodeFieldEnabledInOrderRequestForm,
  );

  const { onInsertItemChange, setInputRef, isInsertItemTypeSame } = useInsertItemChange({
    insertItemType: ORDER_INSERT_TYPE.NON_STOCKED_ITEMS,
    formState,
  });

  if (loading || !isUpcCodeFieldEnabled || isExternalTenant) {
    return null;
  }

  return (
    <div className="mb-10 flex">
      <div className="flex-1 flex-auto">
        <Controller
          control={control}
          name="upcCode"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              disabled={disabled}
              error={fieldState.error}
              getInputReference={setInputRef}
              label={Orders.FormLabels.UpcCode}
              formLabel={Orders.FormLabels.UpcCode}
            />
          )}
          rules={
            formState.getValues('upcCode')
              ? ORDER_REQUEST_FORM_RULES.upcCode
              : ORDER_REQUEST_FORM_RULES.nonStockedField(formState.actions.validateAllEmpty)
          }
        />
      </div>
    </div>
  );
};

export default OrderUpcAsinCodeField;
