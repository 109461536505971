import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import useFormFileUploader from '../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import {
  CreateJobPreprocessorEntityMap,
  ImportJobMetadataType,
} from '../../../../../../types/schema';
import { BulkImportStep } from '../../type';

interface ImportState {
  headers: string[];
  jobId?: string;
  entityMap: CreateJobPreprocessorEntityMap[];
  currentStep: BulkImportStep;
  loadingJob: boolean;
  importStatusDialog: {
    open: boolean;
    importJobMetadata?: ImportJobMetadataType | null;
  };
}

const useImportFormState = () => {
  const [state, _setState] = useState<ImportState>({
    headers: [],
    jobId: undefined,
    entityMap: [],
    currentStep: BulkImportStep.ImportForm,
    loadingJob: false,
    importStatusDialog: {
      open: false,
      importJobMetadata: undefined,
    },
  });

  const setState = useCallback(
    (nextState: Partial<ImportState>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      entityType: '',
      attachments: [],
    },
  });

  const getImportFormValues = useCallback(() => {
    return getValues();
  }, [getValues]);

  const { inputProps: attachmentInputProps, clearAttachments } = useFormFileUploader('attachments');

  const resetImportForm = useCallback(() => {
    setState({
      loadingJob: false,
      entityMap: [],
      headers: [],
      currentStep: BulkImportStep.ImportForm,
      importStatusDialog: {
        open: false,
        importJobMetadata: undefined,
      },
      jobId: undefined,
    });
    clearAttachments();
    reset();
  }, [setState, reset, clearAttachments]);

  const onCloseInvalidImportInputDialog = useCallback(() => {
    resetImportForm();
  }, [resetImportForm]);

  return {
    formState: {
      control,
      attachmentInputProps,
      handleSubmit,
      resetImportForm,
    },
    getImportFormValues,
    state,
    setState,
    onCloseInvalidImportInputDialog,
    resetImportForm,
  };
};

export type UseImportFormReturnType = ReturnType<typeof useImportFormState>;

export default useImportFormState;
