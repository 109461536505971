import { LabelFormat, LabelStockEnum, LabelTypeEnum } from '../../../components/Forms/types';
import {
  sortByDestinationNameContainerId,
  sortByItemTitleLocationName,
  sortByItemTitleSku,
  sortByLocationName,
  sortBySku,
  sortBySkuLocationName,
} from './sorthelper';

export function sortLabelData(params: {
  labelFormat?: LabelFormat;
  labelStock?: LabelStockEnum;
  type?: LabelTypeEnum;
  data: any[];
}) {
  const { data, labelFormat, labelStock, type } = params;

  if (!type) {
    return [];
  }

  switch (type) {
    case LabelTypeEnum.Asset:
      return sortAssetLabelData({
        data,
        labelStock,
        labelFormat,
      });
    case LabelTypeEnum.AssetKit:
      return sortAssetLabelData({
        data,
        labelStock,
        labelFormat,
      });

    case LabelTypeEnum.Inventory:
      return sortInventoryLabelData({
        data,
        labelFormat,
        labelStock,
      });

    case LabelTypeEnum.InventoryKit:
      return sortInventoryLabelData({
        data,
        labelFormat,
        labelStock,
      });
    case LabelTypeEnum.Location:
      return sortLocationLabelData({
        data,
        labelStock,
      });
    case LabelTypeEnum.Shipping:
      return sortShippingLabelData({
        data,
        labelStock,
      });
    case LabelTypeEnum.ShippingSetUp:
      return sortShippingSetupData({
        data,
        labelStock,
      });
  }

  return data;
}

function sortAssetLabelData(params: {
  data: any[];
  labelFormat?: LabelFormat;
  labelStock?: LabelStockEnum;
}) {
  const { data, labelFormat, labelStock } = params;
  if (!labelFormat || !labelStock) {
    return data;
  }

  switch (labelFormat) {
    case LabelFormat.SkuSiteLocation:
      return sortData(data, {
        labelStock,
        sortBySkuLocationName: [
          LabelStockEnum.Avery6792,
          LabelStockEnum.Avery6570,
          LabelStockEnum.Avery5160,
          LabelStockEnum.Avery5163,
          LabelStockEnum.ZebraPrinter,
        ],
        sortBySku: [
          LabelStockEnum.OneRoundLabels,
          LabelStockEnum.ThreeRoundLabels,
          LabelStockEnum.OneSquareLabels,
          LabelStockEnum.ThreeSquareLabels,
        ],
      });
    case LabelFormat.TitleLocation:
      return sortData(data, {
        labelStock,
        sortByItemTitleLocationName: [LabelStockEnum.Avery5163],
      });
    case LabelFormat.TextOnly:
      return sortData(data, {
        labelStock,
        sortByItemTitleSku: [
          LabelStockEnum.Avery6792,
          LabelStockEnum.Avery5163,
          LabelStockEnum.ZebraPrinter,
        ],
      });
    default:
      return data;
  }
}

function sortInventoryLabelData(params: {
  data: any[];
  labelFormat?: LabelFormat;
  labelStock?: LabelStockEnum;
}) {
  const { data, labelFormat, labelStock } = params;
  if (!labelFormat || !labelStock) {
    return data;
  }
  switch (labelFormat) {
    case LabelFormat.SkuSiteLocation:
      return sortData(data, {
        labelStock,
        sortBySkuLocationName: [
          LabelStockEnum.Avery6792,
          LabelStockEnum.Avery6570,
          LabelStockEnum.Avery5160,
          LabelStockEnum.Avery5163,
          LabelStockEnum.ZebraPrinter,
        ],
      });
    case LabelFormat.TitleLocation:
      return sortData(data, {
        labelStock,
        sortByItemTitleLocationName: [LabelStockEnum.Avery5163],
      });
    case LabelFormat.TextOnly:
      return sortData(data, {
        labelStock,
        sortByItemTitleSku: [
          LabelStockEnum.Avery6792,
          LabelStockEnum.Avery5163,
          LabelStockEnum.ZebraPrinter,
        ],
      });
    default:
      return data;
  }
}

function sortLocationLabelData(params: { data: any[]; labelStock?: LabelStockEnum }) {
  const { data, labelStock } = params;

  if (!labelStock) {
    return data;
  }

  return sortData(data, {
    labelStock,
    sortByLocationName: [
      LabelStockEnum.Avery6792,
      LabelStockEnum.Avery6570,
      LabelStockEnum.Avery5160,
      LabelStockEnum.Avery5163,
      LabelStockEnum.ZebraPrinter,
    ],
  });
}

function sortShippingLabelData(params: { data: any[]; labelStock?: LabelStockEnum }) {
  const { data, labelStock } = params;

  if (!labelStock) {
    return data;
  }

  return sortData(data, {
    labelStock,
    sortByDestinationNameContainerId: [LabelStockEnum.Avery5163],
  });
}

function sortShippingSetupData(params: { data: any[]; labelStock?: LabelStockEnum }) {
  const { data, labelStock } = params;
  if (!labelStock) {
    return data;
  }
  return sortData(data, {
    labelStock,
    sortByDestinationNameContainerId: [LabelStockEnum.Avery5163],
  });
}

function sortData(
  data: any[],
  config: {
    labelStock: LabelStockEnum;
    sortBySkuLocationName?: LabelStockEnum[];
    sortBySku?: LabelStockEnum[];
    sortByItemTitleLocationName?: LabelStockEnum[];
    sortByItemTitleSku?: LabelStockEnum[];
    sortByLocationName?: LabelStockEnum[];
    sortByDestinationNameContainerId?: LabelStockEnum[];
  },
) {
  const {
    labelStock,
    sortBySku: sortBySkuLabelStocks,
    sortBySkuLocationName: sortBySkuLocationNameLabelStocks,
    sortByItemTitleLocationName: sortByItemTitleLocationNameStocks,
    sortByItemTitleSku: sortByItemTitleSkuStocksStocks,
    sortByLocationName: sortByLocationNameStocksStocks,
    sortByDestinationNameContainerId: sortByDestinationNameContainerIdStocks,
  } = config;

  if (sortBySkuLocationNameLabelStocks && sortBySkuLocationNameLabelStocks.includes(labelStock)) {
    return sortBySkuLocationName(data);
  } else if (sortBySkuLabelStocks && sortBySkuLabelStocks.includes(labelStock)) {
    return sortBySku(data);
  } else if (
    sortByItemTitleLocationNameStocks &&
    sortByItemTitleLocationNameStocks.includes(labelStock)
  ) {
    return sortByItemTitleLocationName(data);
  } else if (
    sortByItemTitleSkuStocksStocks &&
    sortByItemTitleSkuStocksStocks.includes(labelStock)
  ) {
    return sortByItemTitleSku(data);
  } else if (
    sortByLocationNameStocksStocks &&
    sortByLocationNameStocksStocks.includes(labelStock)
  ) {
    return sortByLocationName(data);
  } else if (
    sortByDestinationNameContainerIdStocks &&
    sortByDestinationNameContainerIdStocks.includes(labelStock)
  ) {
    return sortByDestinationNameContainerId(data);
  } else {
    return data;
  }
}
