import AdminLayout from 'app/components/AdminLayout';
import Departments from 'app/modules/departments/views/Departments';

function DepartmentsPage() {
  return (
    <AdminLayout>
      <Departments />
    </AdminLayout>
  );
}

export default DepartmentsPage;
