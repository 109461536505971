import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccessControl } from 'app/components/AccessControl';
import usePagination from 'app/hooks/usePagination';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useReceivablesQuery } from 'app/modules/receiving/graphql/queries/generated/receivables';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  LocationStatusEnum,
  ReceivableStatusEnum,
  ReceivableTypeEnum,
} from 'app/types/schema';

import { SCHEDULE_STEP } from '../../components/ScheduleStepper/utils';

function useScheduledLists() {
  const [rows, setRows] = useState([]);
  const [state, _setState] = useState({
    activeOrderStep: SCHEDULE_STEP.SCHEDULE_RECEIVING,
  });
  const { table } = useEntityManager({
    selection: false,
    sortState: {
      id: 'createdAt',
      orderBy: 'desc',
    },
  });
  const { defaultSite, workspacePermissions } = useCurrentUser();
  const defaultSiteId =
    defaultSite?.status === LocationStatusEnum.Deleted ? 'none' : defaultSite?.id;
  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Asset,
  );
  const onDelete = useCallback((ids: string[], items?: any[]) => {}, []);
  const { search, table: multiEditTable } = useMultiSelectEntityManager({
    onDelete,
    selection: canDelete,
    hideActions: true,
  });

  const { onNextPage, onPrevPage, onReset, fetching, data } = usePagination(
    useReceivablesQuery,
    {
      filters: {
        types: [ReceivableTypeEnum.OrderRequest],
        statuses: [ReceivableStatusEnum.InProgress],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'receivables',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  useEffect(() => {
    const rows: any = data?.receivables?.edges?.map?.((edge) => edge.node) || [];
    setRows(rows);
  }, [data?.receivables?.edges]);

  return {
    table,
    multiEditTable,
    rows,
    rowCount: data?.receivables?.totalCount || 0,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    state,
    _setState,
  };
}

export default useScheduledLists;
