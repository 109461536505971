import { useCallback, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import routes from '../../../../consts/routes';
import Assets from '../../../../i18n/Assets';
import { ItemTypeEnum, RestockTransactionSubTypeEnum } from '../../../../types/schema';
import { useCreateRestockTransactionsMutation } from '../../../assetsInventory/restock/graphql/mutations/generated/createRestockTransactions';
import EntityManager from '../../../components/EntityManager';
import { AssetReminderInput } from '../../components/AssetForm/components/AssetReminderField/components/AssetReminderForm/types';
import useAssetReminderState from '../../components/AssetForm/components/AssetReminderField/hook/useAssetReminderState';
import { AssetItemFormInput } from '../../components/AssetForm/types';
import AssetFormView from '../../components/AssetFormView';
import useAssetForm from '../../hook/useAssetForm';
import { useCreateAssetItemReminderMutation } from './graphql/mutations/generated/addAssetReminder';
import { useCreateAssetItemMutation } from './graphql/mutations/generated/createAssetItem';
import { useCreateAssetKitItemMutation } from './graphql/mutations/generated/createAssetKitItem';
import analytics from 'app/analytics';

const DEFAULT_VALUES: AssetItemFormInput = {
  type: ItemTypeEnum.Asset,
  sku: 'AST******',
  upcCode: '',
  noUpcCode: false,
  mName: '',
  modelNumber: '',
  brand: '',
  categoryId: '',
  title: '',
  serialNumber: '',
  description: '',
  vendorId: '',
  destinationSiteId: '',
  destinationLocationId: '',
  quantity: '0',
  unitCost: '0.00',
  reminder: [],
  costOverride: '0.00',
  purchaseDate: null,
  replacementDate: null,
  warrantyExpiryDate: null,
  attachments: [],
  protectedAttachments: [],
  pickableThroughOrderRequest: true,
  parentId: '',
  manufacturerId: '',
};

const AddAsset = (props: RouteComponentProps) => {
  const { history } = props;

  const [{ fetching: creatingAsset }, onCreateAssetItem] = useCreateAssetItemMutation();
  const [{ fetching: creatingKit }, onCreateAssetKitItem] = useCreateAssetKitItemMutation();
  const [{ fetching: creatingAssetItemReminders }, onCreateAssetItemReminder] =
    useCreateAssetItemReminderMutation();

  const [{ fetching: creatingRestockTraction }, onCreateRestockTransactions] =
    useCreateRestockTransactionsMutation();

  const {
    formState,
    createAssetItemInput,
    createAssetKitItemInput,
    createRestockTransactionInput,
    createAddAssetItemReminderInput,
  } = useAssetForm({
    defaultValues: DEFAULT_VALUES,
  });

  const { state: assetReminderState, setState: setAssetReminderState } = useAssetReminderState();

  const saveReminders = useCallback(
    async (assetItemId: string) => {
      try {
        await Promise.all([
          ...assetReminderState?.reminders?.map((reminder: AssetReminderInput) => {
            return onCreateAssetItemReminder({
              input: createAddAssetItemReminderInput(reminder, assetItemId),
            });
          }),
        ]);
        analytics?.track('Created', { name: 'Asset Reminders' });
      } catch (error) {
        console.log(error);
      }
    },
    [assetReminderState?.reminders, createAddAssetItemReminderInput, onCreateAssetItemReminder],
  );

  const onCancel = useCallback(() => {
    history.push(routes.AssetsSearch());
  }, [history]);

  const onCreateProduct = useCallback(
    async (values: any) => {
      // Creating Asset Item
      const response = await onCreateAssetItem({
        input: createAssetItemInput(values),
      });
      const entityId = response?.data?.createAssetItem.assetItem?.id || undefined;
      if (response?.data?.createAssetItem?.success && entityId) {
        analytics?.track('Created', { name: 'Asset' });
        // Creating restock Transactions based on Selected Location and Site
        SnackbarService.show({
          message: Assets.SuccessMessages.AssetCreated,
        });
        saveReminders(entityId);
        const restockTransactionInput = createRestockTransactionInput(
          {
            ...values,
            entityId,
          },
          RestockTransactionSubTypeEnum.New,
        );

        const restockTransactions = await onCreateRestockTransactions({
          input: restockTransactionInput,
        });

        if (!restockTransactions.error) {
          history.replace(routes.AssetsSearch());
        } else {
          console.error('[Create Restock Transaction Failed in Asset]', response);
        }
        history.replace(routes.AssetsSearch());
      } else {
        console.error('[Create Asset] Failed', response);
      }
    },
    [
      onCreateAssetItem,
      createAssetItemInput,
      createRestockTransactionInput,
      onCreateRestockTransactions,
      saveReminders,
      assetReminderState?.reminders,
      history,
    ],
  );

  const onCreateKit = useCallback(async (input: any, kitItems: any[]) => {
    // Creating Asset Kit Item
    const response = await onCreateAssetKitItem({
      input: {
        ...createAssetKitItemInput(input, kitItems),
      },
    });
    const entityId = response?.data?.createAssetKitItem?.assetKitItem?.id || undefined;
    if (response?.data?.createAssetKitItem?.success && entityId) {
      analytics?.track('Created', { name: 'Asset Kit' });
      SnackbarService.show({
        message: Assets.SuccessMessages.AssetKitCreated,
      });

      history.replace(routes.Assets());
    } else {
      console.error('[Create Asset Kit] Failed', response);
    }
  }, []);

  const onSubmit = useCallback(
    async (input: any, kitItems?: any) => {
      const itemInput = {
        ...input,
        title: removeMultipleSpaces(input.title),
        description: removeExtraSpacesAndNewlines(input.description),
        formattedDescription: input.description,
      };

      // Creating Asset Item
      if (itemInput.type === ItemTypeEnum.Asset) {
        onCreateProduct(itemInput);
      } else {
        onCreateKit(itemInput, kitItems);
      }
    },
    [onCreateKit, assetReminderState?.reminders, onCreateProduct],
  );
  const _disabled = useMemo(
    () => creatingAsset || creatingKit || creatingRestockTraction,
    [creatingAsset, creatingKit, creatingRestockTraction],
  );

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-[0px]">
      <EntityManager.Title
        documentTitle={Assets.AddAssetTitle}
        title={Assets.AddAsset}
        hasTopDivider
      />

      <AssetFormView
        disabled={_disabled}
        loading={_disabled}
        state={{ ...formState, state: assetReminderState, setState: setAssetReminderState }}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </EntityManager>
  );
};

export default withRouter(AddAsset);
