import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormImageUpload from 'app/components/Form/FormImageUpload';
import FormLabel from 'app/components/Form/FormLabel';
import FormRolesSelect from 'app/components/Form/FormRolesSelect';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import User from 'app/i18n/User';
import ToggleSwitch from 'app/libs/ToggleSwitch';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import FormOrganizationSelect from 'app/modules/organizations/views/AddOrganization/components/FormOrganizationSelect';
import { useRolesSelectQuery } from 'app/modules/roles/graphql/queries/generated/rolesSelect';
import { AllowedScopeEntityEnum, UserStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { AddUserFormProps } from './types';
import { USER_FORM_RULES as Rules } from './utils';

const STAFF = 'Staff';

function AddUserForm(props: AddUserFormProps) {
  const {
    disabled,
    defaultValues,
    isEditMode,
    disableResetButton,
    onCancel,
    onSubmit,
    onResetPassword,
  } = props;
  const currentUser = useCurrentUser();
  const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);
  const [isUploading, setIsUploading] = useState(false);
  const { control, handleSubmit, setValue, getValues } = useForm({ defaultValues });

  const [{ data }] = useRolesSelectQuery({
    variables: { filters: { name: STAFF } },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (!getValues('scopedRoles').length) {
      const staffId = data?.roles?.edges?.find((value) => value.node.name === STAFF)?.node.id || '';
      if (staffId) {
        setValue('scopedRoles', [
          {
            roleId: staffId,
            scopeEntity: AllowedScopeEntityEnum.Scopeless,
            scopeGroupIds: [],
          },
        ]);
      }
    }
  }, [data]);

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const userInput = {
          ...values,
          firstName: removeMultipleSpaces(values.firstName),
          lastName: removeMultipleSpaces(values?.lastName || ''),
        };
        onSubmit(userInput);
      }),
    [handleSubmit, onSubmit],
  );

  const isUserActive = useMemo(() => {
    return [
      UserStatusEnum.Invited,
      UserStatusEnum.Active,
      UserStatusEnum.EmailNotVerified,
    ].includes(defaultValues.status);
  }, [defaultValues]);

  const onToggleStatus = useCallback(() => {
    const on = [
      UserStatusEnum.Invited,
      UserStatusEnum.Active,
      UserStatusEnum.EmailNotVerified,
    ].includes(getValues().status);
    setValue('status', on ? UserStatusEnum.Inactive : UserStatusEnum.Active);
  }, [getValues, setValue]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="profilePicture"
          render={({ field, fieldState }) => (
            <FormImageUpload
              {...field}
              error={fieldState.error}
              label="Profile Image"
              setIsUploading={setIsUploading}
            />
          )}
        />
        <Controller
          control={control}
          name="tenantId"
          render={({ field, fieldState }) => (
            <FormOrganizationSelect
              {...field}
              isRequired
              className="flex-1"
              disabled={isEditMode || !isSuperAdmin}
              error={fieldState.error}
              label={User.FormLabels.Organization}
            />
          )}
          rules={Rules.tenantId}
        />
        <Box className="flex space-x-32">
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                characterLimit={30}
                className="flex-1"
                error={fieldState.error}
                label={User.FormLabels.FirstName}
              />
            )}
            rules={Rules.firstName}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                characterLimit={30}
                className="flex-1"
                error={fieldState.error}
                label={User.FormLabels.LastName}
              />
            )}
            rules={Rules.lastName}
          />
        </Box>
        <Controller
          control={control}
          name="emailId"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={User.FormLabels.Email}
              type="email"
            />
          )}
          rules={Rules.emailId}
        />
        <Controller
          control={control}
          name="defaultSiteId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              enabledAllOption
              error={fieldState.error}
              label={User.FormLabels.DefaultSite}
              placeholder={User.FormPlaceholders.DefaultSite}
            />
          )}
        />
        <Controller
          control={control}
          name="scopedRoles"
          render={({ field, fieldState }) => (
            <FormRolesSelect
              {...field}
              isRequired
              error={fieldState.error}
              label={User.FormLabels.UserRole}
              helperText={User.FormPlaceholders.UserRoleHelperText}
            />
          )}
          rules={Rules.scopedRoles}
        />
        <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const on = [
              UserStatusEnum.Invited,
              UserStatusEnum.Active,
              UserStatusEnum.EmailNotVerified,
            ].includes(field.value);
            return (
              <Box ref={field.ref} className="flex flex-col">
                <FormLabel>Active</FormLabel>
                <ToggleSwitch className="mt-8" on={on} onClick={onToggleStatus} />
              </Box>
            );
          }}
        />
        <Box className="flex justify-end space-x-16">
          <Button
            disabled={isUploading || disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[132px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          {isEditMode && (
            <Button
              disabled={disableResetButton || !isUserActive}
              loading={disableResetButton}
              theme="info"
              onClick={() => {
                onResetPassword?.({ emailId: getValues().emailId });
              }}
              classes="min-w-[147px] h-[44px]">
              {Common.Actions.Reset}
            </Button>
          )}
          <Button onClick={onCancel} classes="min-w-[132px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default AddUserForm;
