import { useMemo } from 'react';
import { OrderStepper } from '@procurenetworks/procure-component-library';
import { OrderRequestItemStatusEnum } from '../../../../types/schema';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { UseOrderDetailsReturnType } from '../../views/OrderDetail/hook/useOrderDetails';

interface Props {
  orderDetail: OrderDetailsData;
}

const OrderDetailSteps = (props: Props) => {
  const { orderDetail } = props;
  const { status } = orderDetail;
  const steps = useMemo(() => ['Shipping soon', 'Packed', 'Out for delivery', 'Delivered'], []);
  const activeStep = useMemo(() => {
    switch (status) {
      case OrderRequestItemStatusEnum.Packed:
        return 1;
      case OrderRequestItemStatusEnum.OutForDelivery:
        return 2;
      case OrderRequestItemStatusEnum.Delivered:
      case OrderRequestItemStatusEnum.Closed:
        return 3;
      default:
        return 0;
    }
  }, [status]);

  return (
    <div className="order-stepper mt-[20px] mb-[33px]">
      <OrderStepper steps={steps} activeStep={activeStep} />
    </div>
  );
};

export default OrderDetailSteps;
