import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Card, Tab, Tabs } from '@mui/material';
import useChart from '../../dashboard/components/chart/useChart';
import { comboDataType } from '../types';
import { useStyles } from './useStyles';

export default function BarGraph({ title, subheader, comboData, ...other }: comboDataType) {
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState(0);

  const comboDataWithoutApps = comboData.filter((data) => data.title !== 'Applications');

  const chartLabels = comboDataWithoutApps[currentTab].details.map((i) => i.label);

  const chartSeries = comboDataWithoutApps[currentTab].details.map((i) => i.value);

  const chartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: any) => seriesName,
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: 20,
        borderRadius: 2,
        distributed: true,
      },
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  return (
    <Card
      {...other}
      className="border-grey300 mt-[16px] rounded-[12px] border md:mt-[16px] md:!ml-[0px] lg:mt-[0px] lg:!ml-[24px]"
      sx={{
        height: '100%',
        boxShadow:
          '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important',
      }}>
      <Box
        sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: '24px', paddingTop: '24px' }}>
        <Tabs
          allowScrollButtonsMobile
          scrollButtons
          aria-label="basic tabs example"
          value={currentTab}
          variant="scrollable"
          onChange={(e, value) => {
            setCurrentTab(value);
          }}
          className={classes.root}>
          {comboDataWithoutApps.map((data, index) => (
            <Tab label={data.title} key={index} />
          ))}
        </Tabs>
      </Box>

      <Box dir="ltr" sx={{ mx: 3 }}>
        <ReactApexChart
          height={325}
          options={chartOptions}
          series={[{ data: chartSeries as number[] }]}
          type="bar"
        />
      </Box>
    </Card>
  );
}
