import { UseImportFormReturnType } from './hook/useImportFormState';

export type ImportFormFieldProps = {
  formState: UseImportFormReturnType['formState'];
  disabled?: boolean;
};

export enum BulkImportStep {
  ImportForm = 'IMPORT_FORM',
  ImportMappingForm = 'IMPORT_MAPPING_FORM',
}
