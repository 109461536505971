import { textValidator } from 'app/components/Form/utils/validators';
import Network from 'app/i18n/Network';


export const NOTES_FORM_RULES = {
  title: {
    validate: textValidator(Network.FormValidationMessages.Title),
  },
  note: {
    validate: textValidator(Network.FormValidationMessages.Note),
  }
};
