import { useEffect } from 'react';
import Loader from 'app/components/Loader';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Partner from 'app/i18n/Partner';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import { TokenDataValue } from '../../type';
import { useVerifyWishlistCollaborateInvitationMutation } from './graphql/mutations/generated/verifyWishlistCollaborationInput';

type Props = {
  tokenDataValue?: TokenDataValue;
} & RouteComponentProps;

function InviteWishlist(props: Props) {
  const { tokenDataValue, history } = props;
  const [{ fetching }, onVerifyWishlistCollaborate] =
    useVerifyWishlistCollaborateInvitationMutation();

  useEffect(() => {
    if (fetching || !tokenDataValue) {
      return;
    }

    onVerifyWishlistCollaborate({
      input: {
        token: tokenDataValue?.token || '',
      },
    }).then((response) => {
      if (response?.data?.verifyWishlistCollaborateInvitation?.success) {
        SnackbarService.show({
          message: Partner.SuccessMessages.InvitationAccepted,
        });
      }
      history.push(routes.WishlistListView());
    });
  }, [tokenDataValue]);

  if (fetching) {
    return <Loader />;
  }

  return null;
}

export default withRouter(InviteWishlist);
