import { RouteComponentProps } from 'react-router';
import { WishlistShippingAddressSchemaInput } from 'app/types/schema';

export type SelectedProducts = {
  id?: string;
  name?: string;
  price?: string;
  vendor?: string;
  rating?: number;
  commentCount?: number;
  quantity: number;
  purchasedQuantity?: number;
  imageUrl?: string;
  hooverProductId: string;
  affiliateLink?: string;
  description?: string;
};

export type UpdateShippingAddressInputType = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export interface WishlistProductCardProps {
  selectedProducts: SelectedProducts[];
  addProductHandler: (card: SelectedProducts) => void;
  products: SelectedProducts[];
}

export interface ListViewProps {
  selectedProducts: SelectedProducts[];
  addViewHandler: (data?: WishListDetailType) => void;
  cancelEdit: () => void;
  saveHandler: () => void;
  removeProductHandler: (index: number) => void;
  wishlistDetails?: WishListDetailType;
  editListHandler?: (products: SelectedProducts[]) => void;
  itemQuantityUpdate: (id: string, quantity: number) => void;
  itemPurchasedQuantityUpdate: (id: string, quantity: number) => void;
  editViewHandler: () => void;
  setIsEditing: (value: boolean) => void;
  refetch: () => void;
  isEditing: boolean;
  fetchingCreateWishlist?: boolean;
  wishlistId: string;
  wishlistName?: string;
  loading: boolean;
  totalProducts: number;
  pageNumber: number;
  rowsPerPage: number;
  handlePageChange: (event: any, page: number) => void;
  handleRowsPerPageChange: (event: any) => void;
}

export interface WishlistActionsType {
  listView?: boolean;
  isEditinglist?: boolean;
  isCreatingProduct?: boolean;
  search: any;
  history: any;
  wishlistId?: string;
}

export interface WishlistEditType {
  history: any;
}

export interface EditListProps extends RouteComponentProps {
  listViewProps: ListViewProps;
}
export interface WishlistType {
  name: string;
  id: string;
  shareId: string;
  products: SelectedProducts[];
}

export interface WishlistCreateListProps {
  listView?: boolean;
  isCreatingProduct?: boolean;
  isEditinglist?: boolean;
  wishlistId?: string;
}

export interface CreateListProps extends RouteComponentProps {
  listView?: boolean;
  isCreatingProduct?: boolean;
  isEditinglist?: boolean;
  wishlistId?: string;
}

export interface QuantityErrorType {
  state?: boolean;
  index?: number;
}

export interface CreateListStepOneProps {
  onSubmit: (values: CreateListStepOneInput) => void;
  isLoading?: boolean;
  error?: boolean;
  defaultValues: CreateListStepOneInput;
}

export type CreateListStepOneInput = {
  firstName?: string;
  lastName?: string;
  wishlistName?: string;
  collaborator?: [];
  name?: string;
  shippingAddress?: WishlistShippingAddressSchemaInput;
  shareId?: string;
  description?: string;
  eventDate?: Date | null;
};

export interface EditWishlistDetailProps {
  watch: any;
  wishlistId: string;
  control: any;
  defaultValues: WishListDetailType;
}

export type WishListDetailType = {
  firstName?: string;
  lastName?: string;
  name?: string;
  shippingAddress?: UpdateShippingAddressInputType;
  shareId?: string;
  description?: string;
  collaborators?: string[];
  __typename?: string;
  eventDate?: string;
  createdById?: string;
  id?: string;
  showDescription?: string;
  formattedDescription?: string;
};

export type PaginationState = {
  page?: number;
  pageSize?: number;
};

export enum VendorTypeEnum {
  WALMART = 'Walmart',
  AMAZON = 'Amazon',
}
