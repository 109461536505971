import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import User from 'app/i18n/User';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import currentUser from 'app/modules/auth/utils/currentUser';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateUserInput, UserStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import AddUserForm from './components/AddUserForm';
import { useCreateUserMutation } from './graphql/mutations/generated/createUser';
import analytics from 'app/analytics';

function AddUser(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateUser] = useCreateUserMutation();

  const onSubmit = useCallback(
    (input: CreateUserInput) => {
      onCreateUser({ input }).then((response) => {
        analytics?.track('Created', { name: 'User' });
        const userId = response.data?.createUser.user?.id;
        if (userId) {
          SnackbarService.show({ message: 'This user has been successfully created.' });
          history.replace(routes.EditUser(userId));
        }
      });
    },
    [history, onCreateUser],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Users());
  }, [history]);

  const defaultValues = useMemo<CreateUserInput>(() => {
    return {
      emailId: '',
      lastName: '',
      tenantId: currentUser.tenantId() || '',
      firstName: '',
      profilePicture: '',
      status: UserStatusEnum.Invited,
      scopedRoles: [],
      defaultSiteId: null,
    };
  }, []);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={User.NewUser} hasTopDivider />
        <Box className="max-w-[532px]">
          <AddUserForm
            defaultValues={defaultValues}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddUser);
