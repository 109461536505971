import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteLocationsMutationVariables = Types.Exact<{
  input: Types.DeleteLocationsInput;
}>;


export type DeleteLocationsMutation = { __typename?: 'Mutation', deleteLocations: { __typename?: 'MutationResponse', success: boolean, reasons?: Array<string> | null } };


export const DeleteLocationsDocument = gql`
    mutation deleteLocations($input: DeleteLocationsInput!) {
  deleteLocations(input: $input) {
    success
    reasons
  }
}
    `;

export function useDeleteLocationsMutation() {
  return Urql.useMutation<DeleteLocationsMutation, DeleteLocationsMutationVariables>(DeleteLocationsDocument);
};