import { Button } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import Assets from 'app/i18n/Assets';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import AssetReminderDialog from './components/AssetReminderDialog';
import ViewAssetReminderDialog from './components/ViewAssetReminderDialog';
import { UseAssetReminderStateReturnType } from './hook/useAssetReminderState';

interface Props {
  formState: UseAssetFormReturnType['formState'] & UseAssetReminderStateReturnType;
  disabled: boolean;
}

const AssetReminderField = (props: Props) => {
  const { formState, disabled } = props;

  const { isItemTypeAsset, state, itemId, setState, isEditMode, isRemindersViewMode, editable } =
    formState;

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <div className="mb-8">
        <FormLabel className="d-block">{Assets.FormLabels.Reminders.Reminder}</FormLabel>
      </div>
      <Stack justifyContent="between">
        {((isEditMode && state?.reminderList?.length === 0) || !isEditMode) && (
          <Box testId="add-reminder">
            <Button
              disabled={disabled}
              theme="success"
              onClick={() => {
                setState({ openAddReminder: true });
              }}
              classes="min-w-[133px] !px-[10px] h-[44px]">
              {Assets.Actions.Reminder.AddReminder}
            </Button>
          </Box>
        )}
        {state?.reminderList?.length > 0 && (
          <Box testId="view-reminder">
            <Button theme="info" onClick={() => setState({ openViewReminder: true })}>
              {Assets.Actions.Reminder.ViewReminders}
            </Button>
          </Box>
        )}
      </Stack>

      <AssetReminderDialog setState={setState} state={state} assetItemId={itemId} />
      <ViewAssetReminderDialog
        assetItemId={itemId}
        isEditMode={isEditMode}
        isRemindersViewMode={isRemindersViewMode}
        setState={setState}
        state={state}
        editable={editable}
      />
    </div>
  );
};

export default AssetReminderField;
