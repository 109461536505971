import { Link } from 'app/libs/navigation';
import { OrderRequestTypeEnum } from '../../../../types/schema';
import { useMemo } from 'react';
import { getOrderDetailsLinkByType } from '../../utils/utils';

interface Props {
  id: string;
  type?: OrderRequestTypeEnum | null;
  children: React.ReactNode;
  target?: string;
  shouldTruncate?: boolean;
}

const OrderLink = (props: Props) => {
  const { id, children, type, target = '_self', shouldTruncate = false } = props;
  const orderDetailLink = useMemo(() => getOrderDetailsLinkByType(id, type), [type, id]);

  return (
    <Link
      className={`order-item-link ${shouldTruncate ? 'truncate' : ''}`}
      to={orderDetailLink}
      target={target}>
      {children}
    </Link>
  );
};

export default OrderLink;
