import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps } from 'app/libs/navigation';
import { MediaSchema, UpdateQuantityConfigurationsAtItemLocationInput } from 'app/types/schema';
import { omit } from 'lodash';

export const getParentTransactionIdFormTransactions = (transactions: any): string | undefined => {
  if (transactions && transactions?.length > 0) {
    return transactions[0].parentTransactionId || undefined;
  }

  return undefined;
};

export const onOperationComplete = ({
  response,
  operation,
  message,
  redirect,
  error,
  cb,
  history,
}: {
  response: any;
  operation: string;
  message: string;
  redirect?: string;
  error: string;
  cb?: () => void;
  history?: RouteComponentProps['history'];
}) => {
  if (response?.data?.[operation]?.success) {
    SnackbarService.show({
      message,
    });
    cb && cb();
    redirect && history?.push(redirect);
  } else {
    console.error(error, response);
  }
};

export function prepareBulkEditInputEntries(
  items: any[],
  siteId: string,
): UpdateQuantityConfigurationsAtItemLocationInput[] {
  return items.map((item) => {
    const minimumQuantity = item.values.minimumQuantity;
    const maximumQuantity = item.values.maximumQuantity;
    return {
      minimumQuantity:
        minimumQuantity !== '' && !isNaN(Number(minimumQuantity)) ? Number(minimumQuantity) : null,
      maximumQuantity:
        maximumQuantity !== '' && !isNaN(Number(maximumQuantity)) ? Number(maximumQuantity) : null,
      itemId: item.id,
      locationId: siteId,
    };
  });
}

export const cleanedAttachmentItems = (images: MediaSchema[]) => {
  return images?.map((image) => {
    const resolutions = image?.resolutions?.map((resolution) => omit(resolution, ['__typename']));
    const newImage = { ...image, resolutions };
    return omit(newImage, ['__typename']);
  });
};
