import React, { createContext, useState, useContext } from 'react';

interface DataContextType {
  setData: (filterType: string, data: any) => void;
  getData: (filterType: string) => any;
}

const defaultContextValue: DataContextType = {
  setData: () => { },
  getData: () => null
};

interface Props {
  children: React.ReactNode;
}

interface DataContextType {
  setData: (filterType: string, data: any) => void;
  getData: (filterType: string) => any;
}

export const ShipContext = createContext<DataContextType>(defaultContextValue);

const UserShippingSelectContextProvider = ({ children }: Props) => {
  const [dataCache, setDataCache] = useState<{ [key: string]: any }>({});
  const setData = (filterType: any, data: any) => {
    setDataCache((prev: any) => ({ ...prev, [filterType]: data }));
  };

  const getData = (filterType: any) => {
    return dataCache[filterType];
  };

  return (
    <ShipContext.Provider value={{ setData, getData }}>
      {children}
    </ShipContext.Provider>
  );
};

export const useData = () => useContext(ShipContext);

export default UserShippingSelectContextProvider;