import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { UserSchema } from 'app/types/schema';
import { formatDate } from 'app/utils/date';

export const getCategoriesTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Category',
    value: 'name',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    addReadMore: true,
  },
  {
    id: 'categoryCode',
    label: 'Category Code',
    value: 'categoryCode',
  },
  {
    id: 'categoryManager',
    label: 'Category Manager(s)',
    value: 'categoryManager',
    valueNode: ({ row }) => {
      const managers = row.categoryManagers;
      const managerNames = managers
        ?.map((manager: Pick<UserSchema, 'id' | 'name'>) => manager.name)
        .join(', ');
      return (
        <span className="truncate" title={managerNames}>
          {managerNames}
        </span>
      );
    },
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
    value: 'updatedBy',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.updatedBy?.name}>
        {row.updatedBy?.name}
      </span>
    ),
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    value: 'updatedAt',
    sortable: true,
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt)}>
        {formatDate(row.updatedAt)}
      </span>
    ),
  },
];
