import './style.css';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import useSignUpDonorApi from './hooks/useApiCreateJoin';
import SignUpDonorForm from './components';
import { SignUpDonorFormInput } from './types';
const defaultValues = {
  firstName: '',
  lastName: '',
  tenantId: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
};

function SignUpTypeDonor(props: RouteComponentProps) {
  const { history } = props;
  const {fetchingApi, executeApiCreateJoin } = useSignUpDonorApi();

  const onSubmit = async (props: SignUpDonorFormInput) => {
    const {firstName, lastName, email, password, tenantId} = props;
    const ApiPayloads:SignUpDonorFormInput = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      tenantId: "6280c26ddd77a643666d692b"
    }
    const response = await executeApiCreateJoin(ApiPayloads);
    if (response?.success) {
      SnackbarService.show({ message: 'User Created successfully' });
      history.push(routes.Login());
    }
    else{
      SnackbarService.showError({ message: response?.message || "SignUp Failed" });
    }
  };

  const onCancel = () => {};

  return (
    <div className="flex items-center justify-center">
      <SignUpDonorForm defaultValues={defaultValues} isLoading={fetchingApi} onCancel={onCancel} onSubmit={onSubmit} />
    </div>
  );
}

export default withRouter(SignUpTypeDonor);
