import AdminLayout from 'app/components/AdminLayout';
import routes from 'app/consts/routes';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import EditOrganization from 'app/modules/organizations/views/EditOrganization';

function EditOrganizationPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  const currentUser = useCurrentUser();
  const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);

  if (!isSuperAdmin && match.params.id !== currentUser.tenantId) {
    return <Redirect to={routes.EditOrganization(currentUser.tenantId)} />;
  }

  return (
    <AdminLayout>
      <EditOrganization tenantId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditOrganizationPage);
