
import { ItemTypeEnum } from 'app/types/schema';

import { useItemInStockQuery } from '../../graphql/query/generated/itemInStockSelect';

const useItemInStockData = (itemInStockId: string, types?: ItemTypeEnum[]) => {
  const [{ data: itemInStock, fetching }] = useItemInStockQuery({
    variables: {
      filters: {
        itemIds: itemInStockId ? [itemInStockId] : [],
        types: types || [],
      },
    },
  });

  return {
    fetching,
    itemInStock: itemInStock?.items?.edges?.[0]?.node || undefined,
  };
};

export default useItemInStockData;
