import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import Box from 'app/ui-components/Box';

export const getAuditReportDetailsColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row.entity} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
    valueNode: ({ row }) => <>{row.entity?.title}</>,
  },
  {
    id: 'category',
    label: Category.Category,
    value: 'category',
    valueNode: ({ row }) => <>{row.entity?.category?.name}</>,
  },
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => <>{row.destinationSite?.name}</>,
  },
  {
    id: 'location',
    label: 'Location',
    value: 'location',
    valueNode: ({ row }) => <>{row.destinationLocation?.name}</>,
  },
  {
    id: 'previousQuantity',
    label: Reports.Qty,
    value: 'previousQuantity',
    rowAlign: 'right',
  },
  {
    id: 'quantity',
    label: 'Actual',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'variance',
    label: 'Variance',
    value: 'variance',
    rowAlign: 'right',
    valueNode: ({ row }) => {
      const variance = row.quantity - row.previousQuantity;
      const isNegative = variance < 0;

      return <Box className={isNegative ? 'red' : 'green'}>{Math.abs(variance)}</Box>;
    },
  },
  {
    id: 'notes',
    label: 'Notes',
    value: 'notes',
    valueNode: ({ row }) => <>{row.meta?.note}</>,
  },
];
