import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';
import { ASSET_MODEL_FORM_RULES } from '../../utils/utils';
const AssetModelTitleField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isRequired
            characterLimit={32}
            error={fieldState.error}
            isDisabled={disabled}
            label={AssetModel.FormLabels.Title}
          />
        )}
        rules={ASSET_MODEL_FORM_RULES.title}
      />
    </div>
  );
};

export default AssetModelTitleField;
