import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Category from 'app/i18n/Category';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { useUsersSelectQuery } from 'app/modules/users/graphql/queries/generated/usersSelect';
import { CreateCategoryInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import CategoryForm from '../../components/CategoryForm';
import { useUpdateCategoryMutation } from './graphql/mutations/generated/updateCategory';
import { useEditCategoryDataQuery } from './graphql/queries/generated/editCategoryData';
import analytics from 'app/analytics';

type Props = RouteComponentProps & { categoryId: string };

function EditCategory(props: Props) {
  const { categoryId, history } = props;

  const [{ fetching: disabled }, onUpdateCategory] = useUpdateCategoryMutation();
  const [{ data, error, fetching }] = useEditCategoryDataQuery({
    variables: { filters: { categoryIds: [categoryId] } },
    requestPolicy: 'network-only',
  });

  const onCancel = useCallback(() => {
    history.replace(routes.Categories());
  }, [history]);

  const categoryNode = useMemo(() => data?.categories.edges?.[0]?.node, [data?.categories.edges]);
  const userCatManager = useMemo(() => categoryNode?.categoryManagerIds, [categoryNode]);

  const [{ data: managerData }] = useUsersSelectQuery({
    pause: !userCatManager,
    variables: {
      filters: { userIds: userCatManager },
    },
  });

  const category = useMemo<CreateCategoryInput | null>(() => {
    if (categoryNode) {
      return {
        name: categoryNode.name,
        categoryCode: categoryNode.categoryCode,
        description: categoryNode.formattedDescription ?? '',
        categoryManagerIds: managerData?.users?.edges?.length ? userCatManager : [],
      };
    }

    return null;
  }, [data?.categories.edges, managerData]);

  const onSubmit = useCallback(
    (input: CreateCategoryInput) => {
      onUpdateCategory({
        input: { ...input, categoryId, categoryManagerIds: input.categoryManagerIds || [] },
      }).then((response) => {
        analytics?.track('Edited', { name: 'Category' });
        if (response.data?.updateCategory.category?.id) {
          SnackbarService.show({ message: 'This category has been successfully updated.' });
          history.replace(routes.Categories());
        }
      });
    },
    [categoryId, history, onUpdateCategory],
  );

  if (!category || !userCatManager || !managerData) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[382px] space-y-40', titlebarStyles)}>
        <TitleBar title={Category.EditCategory} />
        <CategoryForm
          defaultValues={category}
          disabled={disabled}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}

export default withRouter(EditCategory);
