import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditProjectDataFragment = { __typename?: 'ProjectSchema', id: string, name: string, description?: string | null, projectCode?: string | null, formattedDescription?: string | null };

export const EditProjectDataFragmentDoc = gql`
    fragment EditProjectData on ProjectSchema {
  id
  name
  description
  projectCode
  formattedDescription
}
    `;