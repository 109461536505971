import React, { createContext } from 'react';

import useEntityManager, { UseEntityManagerReturnType } from '../../EntityManager/useEntityManager';

export type FindUpcContextType = {
  search: UseEntityManagerReturnType['search'];
  table: UseEntityManagerReturnType['table'];
};

export const FindUPCContext = createContext({});

interface Props {
  children: React.ReactNode;
}

const FindUPCProvider = ({ children }: Props) => {
  const { search, table } = useEntityManager({
    selection: false,
  });

  const context: FindUpcContextType = {
    search,
    table,
  };
  return <FindUPCContext.Provider value={context}>{children}</FindUPCContext.Provider>;
};

export default FindUPCProvider;
