import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AffiliateMerchantsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AffiliateConfigurationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AffiliateMerchantsTableQuery = { __typename?: 'Query', affiliateConfigurations: { __typename?: 'AffiliateConfigurationConnection', totalCount: number, edges: Array<{ __typename?: 'AffiliateConfigurationEdge', node: { __typename?: 'AffiliateConfigurationSchema', id: string, name: string, affiliateUrl: string, logoUrl: string, commission: number, updatedAt: string, status: Types.AffiliateConfigurationStatusEnum, createdBy: { __typename?: 'UserSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const AffiliateMerchantsTableDocument = gql`
    query affiliateMerchantsTable($filters: AffiliateConfigurationFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  affiliateConfigurations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        name
        affiliateUrl
        logoUrl
        commission
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        updatedAt
        status
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useAffiliateMerchantsTableQuery(options?: Omit<Urql.UseQueryArgs<AffiliateMerchantsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateMerchantsTableQuery>({ query: AffiliateMerchantsTableDocument, ...options });
};