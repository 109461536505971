import { useCallback, useMemo } from 'react';
import Loader from 'app/components/Loader';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { onOperationComplete } from 'app/modules/assetsInventory/utils/utils';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import AccessControl from '../../../../components/AccessControl';
import routes from '../../../../consts/routes';
import Inventory from '../../../../i18n/Inventory';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  InventoryKitItemSchema,
  ItemLocationSchema,
  ItemTypeEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { useUpdateInventoryItemMutation } from '../../views/EditInventory/graphql/mutations/generated/updateInventoryItem';
import { useUpdateInventoryKitItemMutation } from '../../views/EditInventory/graphql/mutations/generated/updateInventoryKitItem';
import { UseEditInventoryStateReturnType } from '../../views/EditInventory/hook/useEditInventoryState';
import { useDeleteInventoryItemsMutation } from '../../views/Inventory/graphql/mutations/generated/deleteInventoryItems';
import { useDeleteInventoryKitItemsMutation } from '../../views/Inventory/graphql/mutations/generated/deleteInventoryKitItems';
import useInventoryForm from '../InventoryForm/hook/useInventoryForm';
import InventoryFormView from '../InventoryFormView';
import QuantityBySite from '../QuantityBySite';
import QuickLinks from '../QuickLinks';
import TransactionHistory from '../TransactionHistory';
import analytics from 'app/analytics';

type Props = {
  inventoryId: string;
  disabled?: boolean;
  withQuickLinks?: boolean;
  inventoryFormData: UseEditInventoryStateReturnType['inventoryFormData'];
  inventoryData: UseEditInventoryStateReturnType['inventoryData'];
  stockInformation: UseEditInventoryStateReturnType['stockInformation'];
} & RouteComponentProps;

const EditInventoryView = (props: Props) => {
  const {
    inventoryFormData,
    inventoryData,
    stockInformation,
    inventoryId,
    disabled,
    withQuickLinks,
    history,
  } = props;

  const { workspacePermissions } = useCurrentUser();

  const [{ fetching: updatingInventory }, onUpdateInventory] = useUpdateInventoryItemMutation();
  const [{ fetching: updatingInventoryKit }, onUpdateInventoryKit] =
    useUpdateInventoryKitItemMutation();
  const [{ fetching: deletingInventoryItems }, executeDeleteInventoryItems] =
    useDeleteInventoryItemsMutation();
  const [{ fetching: deletingInventoryKitItems }, executeDeleteInventoryKitItems] =
    useDeleteInventoryKitItemsMutation();

  const { state, createUpdateInventoryItemInput, createUpdateInventoryKitItemInput } =
    useInventoryForm({
      defaultValues: inventoryFormData,
      itemData: inventoryData as InventoryKitItemSchema,
      inventoryId,
      defaultEditable: false,
      isEditMode: true,
    });

  const { cancelEditable } = state;

  const onSubmit = useCallback(
    (input: any) => {
      const itemInput = {
        ...input,
        title: removeMultipleSpaces(input.title),
        description: removeExtraSpacesAndNewlines(input.description),
        formattedDescription: input.description,
      };
      if (state.itemData && state.itemData.type === ItemTypeEnum.InventoryKit) {
        onUpdateInventoryKit({
          input: createUpdateInventoryKitItemInput(itemInput),
        }).then((response) => {
          analytics?.track('Edited', { name: 'Inventory Kit' });
          onOperationComplete({
            response,
            message: Inventory.SuccessMessages.InventoryKitUpdated,
            error: '[Update Inventory Kit] Failed',
            operation: 'updateInventoryKitItem',
            cb: cancelEditable,
          });
        });
      } else {
        onUpdateInventory({
          input: createUpdateInventoryItemInput(itemInput),
        }).then((response) => {
          analytics?.track('Edited', { name: 'Inventory' });
          onOperationComplete({
            response,
            message: Inventory.SuccessMessages.InventoryUpdated,
            error: '[Update Inventory] Failed',
            operation: 'updateInventoryItem',
            cb: cancelEditable,
          });
        });
      }
    },
    [history, onUpdateInventory, inventoryId],
  );

  const onDelete = useCallback(() => {
    if (state.itemData && state.itemData.type === ItemTypeEnum.InventoryKit) {
      const _inventoryData = inventoryData as InventoryKitItemSchema;
      executeDeleteInventoryKitItems({
        input: {
          inventoryKitItemId: _inventoryData?.id,
        },
      }).then((response) => {
        analytics?.track('Deleted', { name: 'Inventory Kit' });
        onOperationComplete({
          response,
          message: Inventory.SuccessMessages.InventoryKitDeleted,
          error: '[Delete Inventory Kit] Failed',
          operation: 'deleteInventoryKitItem',
          redirect: routes.InventorySearch(),
          history,
        });
      });
    } else {
      executeDeleteInventoryItems({
        input: {
          itemIds: [inventoryId],
        },
      }).then((response) => {
        analytics?.track('Deleted', { name: 'Inventory' });
        onOperationComplete({
          response,
          message: Inventory.SuccessMessages.InventoryDeleted,
          error: '[Delete Inventory] Failed',
          operation: 'deleteInventoryItems',
          redirect: routes.InventorySearch(),
          history,
        });
      });
    }
  }, [inventoryId, executeDeleteInventoryItems, history]);

  const _disabled = useMemo(
    () =>
      disabled ||
      updatingInventory ||
      deletingInventoryItems ||
      updatingInventoryKit ||
      deletingInventoryKitItems,
    [
      deletingInventoryItems,
      deletingInventoryKitItems,
      disabled,
      updatingInventory,
      updatingInventoryKit,
    ],
  );
  const loading = useMemo(
    () =>
      updatingInventory ||
      deletingInventoryItems ||
      updatingInventoryKit ||
      deletingInventoryKitItems,
    [deletingInventoryItems, deletingInventoryKitItems, updatingInventory, updatingInventoryKit],
  );

  if (updatingInventory) return <Loader />;
  return (
    <div>
      <InventoryFormView
        disabled={_disabled}
        isEditMode={true}
        loading={loading}
        state={state}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />

      {withQuickLinks && (
        <QuickLinks disabled={disabled} itemInStockId={inventoryId} type={ItemTypeEnum.Inventory} />
      )}

      <AccessControl
        action={AllowedPermissionActionsEnum.Edit}
        permissions={workspacePermissions}
        subject={AllowedPermissionsSubjectEnum.Inventory}>
        <QuantityBySite
          disabled={disabled}
          itemId={inventoryId}
          stockInformation={(stockInformation || []) as ItemLocationSchema[]}
        />
      </AccessControl>

      <div className="mt-20">
        <TransactionHistory itemId={inventoryId} itemType={ItemTypeEnum.Inventory} />
      </div>
    </div>
  );
};

export default withRouter(EditInventoryView);
