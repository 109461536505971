import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WorkspaceAllowedEntityPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WorkspaceAllowedEntityPermissionsQuery = { __typename?: 'Query', workspace: { __typename?: 'WorkspaceSchema', id: string, allowedEntityPermissionsMap: Array<{ __typename?: 'EntityPermissionsMap', key: string, label: string, icon?: string | null, entityPermissions: Array<{ __typename?: 'AllowedEntityPermissions', key: string, entity: Types.AllowedPermissionsEntityEnum, dependentOn: Array<string>, label: string, disabled?: boolean | null }> }> } };


export const WorkspaceAllowedEntityPermissionsDocument = gql`
    query workspaceAllowedEntityPermissions {
  workspace {
    id
    allowedEntityPermissionsMap {
      key
      label
      icon
      entityPermissions {
        key
        entity
        dependentOn
        label
        disabled
      }
    }
  }
}
    `;

export function useWorkspaceAllowedEntityPermissionsQuery(options?: Omit<Urql.UseQueryArgs<WorkspaceAllowedEntityPermissionsQueryVariables>, 'query'>) {
  return Urql.useQuery<WorkspaceAllowedEntityPermissionsQuery>({ query: WorkspaceAllowedEntityPermissionsDocument, ...options });
};