import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';
import { defaultGridColumn } from 'app/modules/network/utils';
import Stack from 'app/ui-components/Stack';

import useDetailInformation from '../../../Details/hooks/useDetailInformation';

const NetworkDetail = (props: NetworkAssetSchemaProps) => {
  const { networkData } = useDetailInformation(props);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Typography className='text-grey-900 font-semibold text-[18px]'>
          Network Detail
        </Typography>
        <Divider className='my-[16px]'/>
        <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
          <CardContent>
            <Grid container columns={defaultGridColumn} spacing={2}>
              {networkData.map((item, index) => {
                return (
                  <Grid key={index} item lg={6} md={6} sm={6} xs={12}>
                    <Stack className="mt-2" direction="col" justifyContent="center">
                      <Typography  className='text-[16px] text-grey-900 font-semibold'>{item.label}</Typography>
                      <Typography  className='text-[14px] text-grey-800 mt-[8px]'>
                        {item.value || 'Not Available'}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default NetworkDetail;
