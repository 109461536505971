export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown; }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AbsoluteDateFilter = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type AddAislesInWarehouseInput = {
  aisles: Scalars['Float'];
  bins: Scalars['Float'];
  racks: Scalars['Float'];
  shelves: Scalars['Float'];
  warehouseId: Scalars['String'];
  zoneName: Scalars['String'];
};

export type AddBinsInWarehouseInput = {
  aisleNumber: Scalars['Float'];
  bins: Scalars['Float'];
  rackNumber: Scalars['Float'];
  shelfName: Scalars['String'];
  warehouseId: Scalars['String'];
  zoneName: Scalars['String'];
};

export type AddRacksInWarehouseInput = {
  aisleNumber?: InputMaybe<Scalars['Float']>;
  bins?: InputMaybe<Scalars['Float']>;
  racks: Scalars['Float'];
  shelves: Scalars['Float'];
  warehouseId: Scalars['String'];
  zoneName?: InputMaybe<Scalars['String']>;
};

export type AddShelvesInWarehouseInput = {
  aisleNumber?: InputMaybe<Scalars['Float']>;
  bins?: InputMaybe<Scalars['Float']>;
  rackNumber: Scalars['Float'];
  shelves: Scalars['Float'];
  warehouseId: Scalars['String'];
  zoneName?: InputMaybe<Scalars['String']>;
};

export type AddZonesInWarehouseInput = {
  aisles: Scalars['Float'];
  bins: Scalars['Float'];
  racks: Scalars['Float'];
  shelves: Scalars['Float'];
  warehouseId: Scalars['String'];
  zones: Scalars['Float'];
};

/** This returns paginated addresses */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  edges: Array<AddressEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  node: AddressSchema;
};

/** Address filters */
export type AddressFilters = {
  _and?: InputMaybe<Array<AddressFilters>>;
  _or?: InputMaybe<Array<AddressFilters>>;
  addressIds?: InputMaybe<Array<Scalars['String']>>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<AddressStatusEnum>>;
};

/** Address schema */
export type AddressSchema = {
  __typename?: 'AddressSchema';
  addressLineOne: Scalars['String'];
  addressLineTwo?: Maybe<Scalars['String']>;
  addressName: Scalars['String'];
  city: Scalars['String'];
  companyId: Scalars['String'];
  companySqlId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of address */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  state: Scalars['String'];
  status: AddressStatusEnum;
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of address */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  zipCode: Scalars['String'];
};

export const enum AddressStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

/** This returns paginated affiliateConfigurations */
export type AffiliateConfigurationConnection = {
  __typename?: 'AffiliateConfigurationConnection';
  edges: Array<AffiliateConfigurationEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AffiliateConfigurationEdge = {
  __typename?: 'AffiliateConfigurationEdge';
  node: AffiliateConfigurationSchema;
};

/** AffiliateConfiguration filters */
export type AffiliateConfigurationFilters = {
  _and?: InputMaybe<Array<AffiliateConfigurationFilters>>;
  _or?: InputMaybe<Array<AffiliateConfigurationFilters>>;
  affiliateConfigurationIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<AffiliateConfigurationStatusEnum>>;
};

/** AffiliateConfiguration schema */
export type AffiliateConfigurationSchema = {
  __typename?: 'AffiliateConfigurationSchema';
  affiliateUrl: Scalars['String'];
  commission: Scalars['Float'];
  createdAt: Scalars['String'];
  /** Returns createdBy user of affiliateConfiguration */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  domain: Scalars['String'];
  id: Scalars['String'];
  isEnabledForChromeExtension?: Maybe<Scalars['Boolean']>;
  isEnabledForComparisonShopping?: Maybe<Scalars['Boolean']>;
  isScrapingAllowed?: Maybe<Scalars['Boolean']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  status: AffiliateConfigurationStatusEnum;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of affiliateConfiguration */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum AffiliateConfigurationStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  InActive = 'IN_ACTIVE'
};

export type AgedItemDetail = {
  __typename?: 'AgedItemDetail';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  itemId: Scalars['String'];
  itemSKU: Scalars['String'];
  itemTitle: Scalars['String'];
  itemType: Scalars['String'];
  quantity: Scalars['Float'];
  siteId: Scalars['String'];
  siteName: Scalars['String'];
};

export type AllowedEntityPermissions = {
  __typename?: 'AllowedEntityPermissions';
  actions: Array<AllowedPermissionActionsEnum>;
  defaultEnabled?: Maybe<Scalars['Boolean']>;
  dependentOn: Array<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  eitherOfDependentOn?: Maybe<Array<PermissionDependency>>;
  entity: AllowedPermissionsEntityEnum;
  hidden?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label: Scalars['String'];
  subject: AllowedPermissionsSubjectEnum;
};

export const enum AllowedPermissionActionsEnum {
  Access = 'ACCESS',
  Asset = 'ASSET',
  Create = 'CREATE',
  Delete = 'DELETE',
  Download = 'DOWNLOAD',
  Edit = 'EDIT',
  Import = 'IMPORT',
  Inventory = 'INVENTORY',
  List = 'LIST',
  Manage = 'MANAGE',
  Masquerade = 'MASQUERADE',
  NoSku = 'NO_SKU',
  Read = 'READ',
  Receive = 'RECEIVE',
  ResetPassword = 'RESET_PASSWORD',
  Return = 'RETURN',
  Scan = 'SCAN',
  Switch = 'SWITCH',
  Undelete = 'UNDELETE',
  Unpack = 'UNPACK',
  View = 'VIEW'
};

export const enum AllowedPermissionsEntityEnum {
  AffiliateConfiguration = 'AFFILIATE_CONFIGURATION',
  Archives = 'ARCHIVES',
  Asset = 'ASSET',
  AssetModel = 'ASSET_MODEL',
  BellNotification = 'BELL_NOTIFICATION',
  Category = 'CATEGORY',
  Contact = 'CONTACT',
  Department = 'DEPARTMENT',
  Group = 'GROUP',
  Inventory = 'INVENTORY',
  ItAsset = 'IT_ASSET',
  Label = 'LABEL',
  Location = 'LOCATION',
  Manufacturer = 'MANUFACTURER',
  Module = 'MODULE',
  Notification = 'NOTIFICATION',
  Order = 'ORDER',
  PartnerTenant = 'PARTNER_TENANT',
  Project = 'PROJECT',
  Receivable = 'RECEIVABLE',
  Report = 'REPORT',
  Role = 'ROLE',
  Ship = 'SHIP',
  Shop = 'SHOP',
  Site = 'SITE',
  Tenant = 'TENANT',
  User = 'USER',
  Warehouse = 'WAREHOUSE'
};

export const enum AllowedPermissionsSubjectEnum {
  AccountingReport = 'ACCOUNTING_REPORT',
  Address = 'ADDRESS',
  Admin = 'ADMIN',
  AffiliateConfiguration = 'AFFILIATE_CONFIGURATION',
  AgeingReport = 'AGEING_REPORT',
  AgingReportAsset = 'AGING_REPORT_ASSET',
  AgingReportInventory = 'AGING_REPORT_INVENTORY',
  Archives = 'ARCHIVES',
  Asset = 'ASSET',
  AssetAudit = 'ASSET_AUDIT',
  AssetAuditReport = 'ASSET_AUDIT_REPORT',
  AssetCheckout = 'ASSET_CHECKOUT',
  AssetCheckIn = 'ASSET_CHECK_IN',
  AssetCheckInReminder = 'ASSET_CHECK_IN_REMINDER',
  AssetCheckOut = 'ASSET_CHECK_OUT',
  AssetLabel = 'ASSET_LABEL',
  AssetMaintenanceReminder = 'ASSET_MAINTENANCE_REMINDER',
  AssetModel = 'ASSET_MODEL',
  AssetMove = 'ASSET_MOVE',
  AssetProtectedDocument = 'ASSET_PROTECTED_DOCUMENT',
  AssetReminder = 'ASSET_REMINDER',
  AssetRemove = 'ASSET_REMOVE',
  AssetReservationExpiration = 'ASSET_RESERVATION_EXPIRATION',
  AssetReservationReminder = 'ASSET_RESERVATION_REMINDER',
  AssetReservationRequest = 'ASSET_RESERVATION_REQUEST',
  AssetReserve = 'ASSET_RESERVE',
  AssetRestock = 'ASSET_RESTOCK',
  AssetShipment = 'ASSET_SHIPMENT',
  AssetWarrantyExpire = 'ASSET_WARRANTY_EXPIRE',
  AuditReport = 'AUDIT_REPORT',
  Category = 'CATEGORY',
  Company = 'COMPANY',
  Contact = 'CONTACT',
  Container = 'CONTAINER',
  ContainerLabel = 'CONTAINER_LABEL',
  ContainerSetupLabel = 'CONTAINER_SETUP_LABEL',
  CostReport = 'COST_REPORT',
  CustomReport = 'CUSTOM_REPORT',
  Department = 'DEPARTMENT',
  Group = 'GROUP',
  Import = 'IMPORT',
  InactiveShippingContainer = 'INACTIVE_SHIPPING_CONTAINER',
  Inventory = 'INVENTORY',
  InventoryAudit = 'INVENTORY_AUDIT',
  InventoryAuditReport = 'INVENTORY_AUDIT_REPORT',
  InventoryLabel = 'INVENTORY_LABEL',
  InventoryMove = 'INVENTORY_MOVE',
  InventoryRemove = 'INVENTORY_REMOVE',
  InventoryRestock = 'INVENTORY_RESTOCK',
  InventoryShipment = 'INVENTORY_SHIPMENT',
  InvitePartnerExistingTenant = 'INVITE_PARTNER_EXISTING_TENANT',
  ItAsset = 'IT_ASSET',
  ItAssetAgent = 'IT_ASSET_AGENT',
  ItAssetDashboard = 'IT_ASSET_DASHBOARD',
  ItAssetDiscoveryAsset = 'IT_ASSET_DISCOVERY_ASSET',
  ItAssetDiscoveryTool = 'IT_ASSET_DISCOVERY_TOOL',
  ItAssetInsight = 'IT_ASSET_INSIGHT',
  ItAssetNetwork = 'IT_ASSET_NETWORK',
  ItAssetSystem = 'IT_ASSET_SYSTEM',
  Label = 'LABEL',
  Location = 'LOCATION',
  LocationLabel = 'LOCATION_LABEL',
  LowStockReport = 'LOW_STOCK_REPORT',
  Manufacturer = 'MANUFACTURER',
  OrderAsset = 'ORDER_ASSET',
  OrderInventory = 'ORDER_INVENTORY',
  OrderNoSku = 'ORDER_NO_SKU',
  OrderReadyForPickUp = 'ORDER_READY_FOR_PICK_UP',
  OrderRequest = 'ORDER_REQUEST',
  OrderRequestCreated = 'ORDER_REQUEST_CREATED',
  OrderRequestDeleted = 'ORDER_REQUEST_DELETED',
  OrderRequestDelivered = 'ORDER_REQUEST_DELIVERED',
  OrderRequestItemsReturned = 'ORDER_REQUEST_ITEMS_RETURNED',
  OrderRequestItemsReturnCancelled = 'ORDER_REQUEST_ITEMS_RETURN_CANCELLED',
  OrderRequestReceived = 'ORDER_REQUEST_RECEIVED',
  OrderRequestReport = 'ORDER_REQUEST_REPORT',
  OrderRequestStatusUpdated = 'ORDER_REQUEST_STATUS_UPDATED',
  OrderRequestUpdated = 'ORDER_REQUEST_UPDATED',
  Pack = 'PACK',
  PartnerTenant = 'PARTNER_TENANT',
  PickListCreated = 'PICK_LIST_CREATED',
  Project = 'PROJECT',
  Receivable = 'RECEIVABLE',
  Receiving = 'RECEIVING',
  Report = 'REPORT',
  ReOrderReport = 'RE_ORDER_REPORT',
  Role = 'ROLE',
  ScanContainer = 'SCAN_CONTAINER',
  Shipment = 'SHIPMENT',
  ShipmentReceived = 'SHIPMENT_RECEIVED',
  Shop = 'SHOP',
  Site = 'SITE',
  SiteReport = 'SITE_REPORT',
  Tenant = 'TENANT',
  TransactionReport = 'TRANSACTION_REPORT',
  Undelete = 'UNDELETE',
  User = 'USER',
  Warehouse = 'WAREHOUSE',
  Wishlist = 'WISHLIST'
};

export type AllowedScope = {
  __typename?: 'AllowedScope';
  required: Scalars['Boolean'];
  scope: AllowedScopeEntityEnum;
};

export const enum AllowedScopeEntityEnum {
  Category = 'CATEGORY',
  Scopeless = 'SCOPELESS',
  Site = 'SITE'
};

export type AllowedScopeInput = {
  required: Scalars['Boolean'];
  scope: AllowedScopeEntityEnum;
  scopeApplicableOn: ScopeApplicableOnEnum;
};

export const enum ApplicableOnEnum {
  User = 'USER'
};

export type ArchiveShippingTransactionsInput = {
  transactionIds: Array<Scalars['String']>;
};

/** This returns paginated assetItems */
export type AssetItemConnection = {
  __typename?: 'AssetItemConnection';
  edges: Array<AssetItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AssetItemEdge = {
  __typename?: 'AssetItemEdge';
  node: AssetItemSchema;
};

/** Asset item filters */
export type AssetItemFilters = {
  _and?: InputMaybe<Array<AssetItemFilters>>;
  _or?: InputMaybe<Array<AssetItemFilters>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  macAddresses?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  reminderId?: InputMaybe<Scalars['String']>;
  reminderJobId?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetItemReminder = {
  __typename?: 'AssetItemReminder';
  _id: Scalars['String'];
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  cron?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  formattedNote?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  lastReminderAt?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  note: Scalars['String'];
  notifyUserIds: Array<Scalars['String']>;
  recurring?: Maybe<Scalars['Boolean']>;
  recurringInterval?: Maybe<Scalars['Float']>;
  startReminderAt: Scalars['String'];
  status: AssetItemReminderStatusEnum;
  type?: Maybe<AssetItemReminderTypeEnum>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedById: Scalars['String'];
};

export type AssetItemReminderInput = {
  cron?: InputMaybe<Scalars['String']>;
  formattedNote?: InputMaybe<Scalars['String']>;
  lastReminderAt?: InputMaybe<Scalars['String']>;
  note: Scalars['String'];
  notifyUserIds: Array<Scalars['String']>;
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringInterval?: InputMaybe<Scalars['Float']>;
  startReminderAt: Scalars['String'];
  type?: InputMaybe<AssetItemReminderTypeEnum>;
};

export const enum AssetItemReminderStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum AssetItemReminderTypeEnum {
  Maintenance = 'MAINTENANCE',
  Reminder = 'REMINDER'
};

export type AssetItemSchema = {
  __typename?: 'AssetItemSchema';
  /** Returns asset model of the asset item */
  assetModel?: Maybe<AssetModelItemSchema>;
  /** Returns all the assets of the assetModel */
  assets?: Maybe<Array<AssetItemSchema>>;
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of asset item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the asset item */
  cost?: Maybe<Scalars['Float']>;
  /** Cost override of the asset item */
  costOverride?: Maybe<Scalars['Float']>;
  /** IT Asset CPU Brand */
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of asset item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** IT Asset IP Address */
  ipAddress?: Maybe<Scalars['String']>;
  mName: Scalars['String'];
  /** IT Asset MAC Address */
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  /** Asset Model Id of Asset */
  parentId?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  /** Storing media of items */
  protectedAttachments?: Maybe<Array<MediaSchema>>;
  purchaseDate?: Maybe<Scalars['String']>;
  reminders?: Maybe<Array<AssetItemReminder>>;
  replacementDate?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of asset items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of asset item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  /** Unit cost of the asset item */
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of asset item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns actions allowed on subjects of asset items */
  userEntityPermissions?: Maybe<Array<UserEntityPermission>>;
  /** Returns vendor of asset item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
  warrantyExpiryDate?: Maybe<Scalars['String']>;
  warrantyExpiryScheduledJobId?: Maybe<Scalars['String']>;
};


export type AssetItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


export type AssetItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

export type AssetKitConfig = {
  __typename?: 'AssetKitConfig';
  /** Returns assetItem of kitConfig */
  assetItem: AssetItemSchema;
  count: Scalars['Float'];
  itemId: Scalars['String'];
  /** Returns location of kitConfig */
  location: LocationSchema;
  locationId: Scalars['String'];
};

export type AssetKitInputConfig = {
  count: Scalars['Float'];
  itemId: Scalars['String'];
  locationId: Scalars['String'];
};

/** This returns paginated assetItems */
export type AssetKitItemConnection = {
  __typename?: 'AssetKitItemConnection';
  edges: Array<AssetKitItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AssetKitItemEdge = {
  __typename?: 'AssetKitItemEdge';
  node: AssetKitItemSchema;
};

/** Asset item filters */
export type AssetKitItemFilters = {
  _and?: InputMaybe<Array<AssetKitItemFilters>>;
  _or?: InputMaybe<Array<AssetKitItemFilters>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  kitItemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetKitItemSchema = {
  __typename?: 'AssetKitItemSchema';
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of asset kit item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the asset kit item */
  cost?: Maybe<Scalars['Float']>;
  /** Cost override of the asset kit item */
  costOverride?: Maybe<Scalars['Float']>;
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of asset kit item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  kitConfigs: Array<AssetKitConfig>;
  locationId?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
  /** Returns reminders of asset item */
  reminders: Array<AssetItemReminder>;
  /** Returns site of asset kit item */
  site?: Maybe<LocationSchema>;
  siteId: Scalars['String'];
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of asset kit items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of asset kit item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  /** Unit cost of the asset kit item */
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of asset kit item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns vendor of item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};


export type AssetKitItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


export type AssetKitItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

/** This returns paginated assetModelItems */
export type AssetModelItemConnection = {
  __typename?: 'AssetModelItemConnection';
  edges: Array<AssetModelItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type AssetModelItemEdge = {
  __typename?: 'AssetModelItemEdge';
  node: AssetModelItemSchema;
};

/** Asset Model item filters */
export type AssetModelItemFilters = {
  _and?: InputMaybe<Array<AssetModelItemFilters>>;
  _or?: InputMaybe<Array<AssetModelItemFilters>>;
  assetModelIds?: InputMaybe<Array<Scalars['String']>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type AssetModelItemSchema = {
  __typename?: 'AssetModelItemSchema';
  /** Returns all the assets of the assetModel */
  assets?: Maybe<Array<AssetItemSchema>>;
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of asset item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the asset item */
  cost?: Maybe<Scalars['Float']>;
  /** Cost override of the asset item */
  costOverride?: Maybe<Scalars['Float']>;
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of asset item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  mName: Scalars['String'];
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  /** Returns reminders of asset item */
  reminders: Array<AssetItemReminder>;
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of asset items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  /** Unit cost of the asset item */
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of asset item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns actions allowed on subjects of asset items */
  userEntityPermissions?: Maybe<Array<UserEntityPermission>>;
  /** Returns vendor of item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};


export type AssetModelItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


export type AssetModelItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

export type AuditTransactionEntityInput = {
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  meta?: InputMaybe<AuditTransactionMetaInput>;
  quantity: Scalars['Int'];
};

export type AuditTransactionMetaInput = {
  formattedNote?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

/** Audit transaction report filters */
export type AuditTransactionReportFilter = {
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  entityType?: InputMaybe<TransactionEntityTypeEnum>;
  entityTypes: Array<TransactionEntityTypeEnum>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Audit transaction report schema */
export type AuditTransactionReportSchema = {
  __typename?: 'AuditTransactionReportSchema';
  createdAt: Scalars['String'];
  /** Returns createdBy user of auditTransactionReport */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  id: Scalars['String'];
  /** Returns site of auditTransactionReport */
  site?: Maybe<LocationSchema>;
  siteId: Scalars['String'];
  tenantId: Scalars['String'];
};

/** AuthToken filters */
export type AuthTokenFilters = {
  statuses?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export const enum AuthTokenStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

/** Auth token schema */
export type AuthTokenType = {
  __typename?: 'AuthTokenType';
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: AuthTokenStatusEnum;
  tenantId: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
};

export type Battery = {
  acConnected?: InputMaybe<Scalars['Boolean']>;
  capacityUnit?: InputMaybe<Scalars['String']>;
  currentCapacity?: InputMaybe<Scalars['Int']>;
  cycleCount?: InputMaybe<Scalars['Int']>;
  designedCapacity?: InputMaybe<Scalars['Int']>;
  hasBattery?: InputMaybe<Scalars['Boolean']>;
  isCharging?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  maxCapacity?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  percent?: InputMaybe<Scalars['Int']>;
  serial?: InputMaybe<Scalars['String']>;
  timeRemaining?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  voltage?: InputMaybe<Scalars['Float']>;
};

/** Battery Schema */
export type BatterySchema = {
  __typename?: 'BatterySchema';
  acConnected?: Maybe<Scalars['Boolean']>;
  capacityUnit?: Maybe<Scalars['String']>;
  currentCapacity?: Maybe<Scalars['Int']>;
  cycleCount?: Maybe<Scalars['Int']>;
  designedCapacity?: Maybe<Scalars['Int']>;
  hasBattery?: Maybe<Scalars['Boolean']>;
  isCharging?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  maxCapacity?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Int']>;
  serial?: Maybe<Scalars['String']>;
  timeRemaining?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['Float']>;
};

export type BellNotificationByUserSubscriptionInput = {
  /** Operations to subscribe on */
  operations?: InputMaybe<Array<BellNotificationSubscriptionOperationEnum>>;
  /** UserId of the bell notification */
  userId: Scalars['String'];
};

/** This returns paginated bell notifications */
export type BellNotificationConnection = {
  __typename?: 'BellNotificationConnection';
  edges?: Maybe<Array<BellNotificationEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type BellNotificationEdge = {
  __typename?: 'BellNotificationEdge';
  node: BellNotificationSchema;
};

/** Bell Notification filters */
export type BellNotificationFilters = {
  _and?: InputMaybe<Array<BellNotificationFilters>>;
  _or?: InputMaybe<Array<BellNotificationFilters>>;
  bellNotificationIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<BellNotificationStatusEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Bell Notifications */
export type BellNotificationSchema = {
  __typename?: 'BellNotificationSchema';
  buttonLabel?: Maybe<Scalars['String']>;
  buttonMobileUrl?: Maybe<Scalars['String']>;
  buttonWebUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mobileClickUrl?: Maybe<Scalars['String']>;
  showButton?: Maybe<Scalars['Boolean']>;
  status: BellNotificationStatusEnum;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['String'];
  webClickUrl?: Maybe<Scalars['String']>;
};

export const enum BellNotificationStatusEnum {
  Archived = 'ARCHIVED',
  Read = 'READ',
  Unread = 'UNREAD'
};

export const enum BellNotificationSubscriptionOperationEnum {
  BellNotificationCreated = 'BELL_NOTIFICATION_CREATED',
  BellNotificationUpdated = 'BELL_NOTIFICATION_UPDATED'
};

export type Bios = {
  releaseDate?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** BIOS Schema */
export type BiosSchema = {
  __typename?: 'BiosSchema';
  releaseDate?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type BlockOrderRequestInput = {
  orderRequestId: Scalars['String'];
};

export type BulkImportNetworkAssetInput = {
  networkAssetIds: Array<Scalars['String']>;
};

export type CancelOrderRequestInput = {
  orderRequestId: Scalars['String'];
};

export type CancelOrderRequestPayload = {
  __typename?: 'CancelOrderRequestPayload';
  orderRequest?: Maybe<OrderRequestSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export const enum CategoryApplicableOnEnum {
  Asset = 'ASSET',
  Inventory = 'INVENTORY'
};

/** This returns paginated Categories */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  edges: Array<CategoryEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  node: CategorySchema;
};

/** Category filters */
export type CategoryFilters = {
  _and?: InputMaybe<Array<CategoryFilters>>;
  _or?: InputMaybe<Array<CategoryFilters>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  categoryManagerIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<CategoryStatusEnum>>;
};

/** Category schema */
export type CategorySchema = {
  __typename?: 'CategorySchema';
  /** On which item the category is applicable */
  applicableOn: Array<CategoryApplicableOnEnum>;
  assetFlag?: Maybe<Scalars['Boolean']>;
  categoryCode?: Maybe<Scalars['String']>;
  categoryManagerIds: Array<Scalars['String']>;
  /** Returns categoryManagers of category */
  categoryManagers: Array<UserSchema>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of category */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  inventoryFlag?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  status: CategoryStatusEnum;
  /** Tenant Id of the category */
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of category */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum CategoryStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type CheckOutTransactionEntityInput = {
  actorId: Scalars['String'];
  departmentId?: InputMaybe<Scalars['String']>;
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  dueDate?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  entityType?: InputMaybe<TransactionEntityTypeEnum>;
  meta?: InputMaybe<CheckOutTransactionMetaInputInput>;
  quantity: Scalars['Int'];
  reservedTransactionId?: InputMaybe<Scalars['String']>;
  sourceLocationId: Scalars['String'];
  sourceSiteId: Scalars['String'];
};

export type CheckOutTransactionMetaInputInput = {
  formattedNote?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export type CloseOrderRequestInput = {
  orderRequestId: Scalars['String'];
};

export type CloseOrderRequestPayload = {
  __typename?: 'CloseOrderRequestPayload';
  orderRequest?: Maybe<OrderRequestSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type AddRowOrderDetailsSchema = {
  id: Scalars['ID'];
  imageUrl?: Scalars['String'] | null;
  itemId: Scalars['ID'];
  description?: Scalars['String'] | null;
  upcCode?: Scalars['String'] | null;
  website?: Scalars['String'] | null;
  status: OrderRequestItemStatusEnum;
  type: Scalars['String'];
  item: Object;
  quantity: Scalars['Int'];
  projectId: Scalars['ID'] | null;
  project: Scalars['String'] | null;
  cost: Scalars['String'];
  nonRemovableNotes?: any[];
  note: Scalars['String'] | null;
  parentOrderRequestItemId: Scalars['ID'] | null;
  itemIdInPartnerTenant: Scalars['ID'] | null;
  trackingDetails?: any[];
  isNewItem?: Scalars['Boolean'];
};

/** This returns paginated companies */
export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<CompanyEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  node: CompanySchema;
};

/** Company filters */
export type CompanyFilters = {
  _and?: InputMaybe<Array<CompanyFilters>>;
  _or?: InputMaybe<Array<CompanyFilters>>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  isVendor?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<CompanyStatusEnum>>;
};

/** Company schema */
export type CompanySchema = {
  __typename?: 'CompanySchema';
  companyContactNumber?: Maybe<Scalars['String']>;
  companyEmail?: Maybe<Scalars['String']>;
  companyFax?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyWebsite?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of company */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isVendor?: Maybe<Scalars['Boolean']>;
  sqlId?: Maybe<Scalars['Int']>;
  status: CompanyStatusEnum;
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of company */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum CompanyStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

/** This returns paginated contacts */
export type ContactConnection = {
  __typename?: 'ContactConnection';
  edges: Array<ContactEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  node: ContactSchema;
};

/** Contact filters */
export type ContactFilters = {
  _and?: InputMaybe<Array<ContactFilters>>;
  _or?: InputMaybe<Array<ContactFilters>>;
  addressIds?: InputMaybe<Array<Scalars['String']>>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  contactIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ContactStatusEnum>>;
};

/** Contact schema */
export type ContactSchema = {
  __typename?: 'ContactSchema';
  /** Returns address of contact */
  address: AddressSchema;
  addressId: Scalars['String'];
  /** Returns company of contact */
  company: CompanySchema;
  companyId: Scalars['String'];
  companySqlId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of contact */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  homeContactNumber?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName: Scalars['String'];
  mobileContactNumber?: Maybe<Scalars['String']>;
  officeContactNumber?: Maybe<Scalars['String']>;
  status: ContactStatusEnum;
  tenantId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of contact */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum ContactStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type Cpu = {
  brand?: InputMaybe<Scalars['String']>;
  cores?: InputMaybe<Scalars['Int']>;
  efficiencyCores?: InputMaybe<Scalars['Int']>;
  family?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Scalars['String']>;
  governor?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  performanceCores?: InputMaybe<Scalars['Int']>;
  physicalCores?: InputMaybe<Scalars['Int']>;
  processors?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['String']>;
  socket?: InputMaybe<Scalars['String']>;
  speed?: InputMaybe<Scalars['Float']>;
  speedMax?: InputMaybe<Scalars['Float']>;
  speedMin?: InputMaybe<Scalars['Float']>;
  stepping?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  virtualization?: InputMaybe<Scalars['Boolean']>;
  voltage?: InputMaybe<Scalars['String']>;
};

/** CPU Schema */
export type CpuSchema = {
  __typename?: 'CpuSchema';
  brand?: Maybe<Scalars['String']>;
  cores?: Maybe<Scalars['Int']>;
  efficiencyCores?: Maybe<Scalars['Int']>;
  family?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['String']>;
  governor?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  performanceCores?: Maybe<Scalars['Int']>;
  physicalCores?: Maybe<Scalars['Int']>;
  processors?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['String']>;
  socket?: Maybe<Scalars['String']>;
  speed?: Maybe<Scalars['Int']>;
  speedMax?: Maybe<Scalars['Int']>;
  speedMin?: Maybe<Scalars['Int']>;
  stepping?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  virtualization?: Maybe<Scalars['Boolean']>;
  voltage?: Maybe<Scalars['String']>;
};

export type CreateAddressInput = {
  addressLineOne: Scalars['String'];
  addressLineTwo?: InputMaybe<Scalars['String']>;
  addressName: Scalars['String'];
  city: Scalars['String'];
  companyId: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateAddressPayload = {
  __typename?: 'CreateAddressPayload';
  address?: Maybe<AddressSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAffiliateConfigurationInput = {
  affiliateUrl: Scalars['String'];
  commission: Scalars['Float'];
  domain: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<AffiliateConfigurationStatusEnum>;
};

export type CreateAffiliateConfigurationPayload = {
  __typename?: 'CreateAffiliateConfigurationPayload';
  affiliateConfiguration: AffiliateConfigurationSchema;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAssetInputs = {
  createAssetItemInputs: Array<CreateAssetItemInput>;
  jobId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  cpuBrand?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  mName: Scalars['String'];
  macAddress?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  /** Asset Model Id of Asset */
  parentId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  /** Storing media of items. */
  protectedAttachments?: InputMaybe<Array<CreateMediaInput>>;
  purchaseDate?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  reminders?: InputMaybe<Array<AssetItemReminderInput>>;
  replacementDate?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<ItemTypeEnum>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
  warrantyExpiryDate?: InputMaybe<Scalars['String']>;
};

export type CreateAssetItemPayload = {
  __typename?: 'CreateAssetItemPayload';
  assetItem?: Maybe<AssetItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAssetItemReminderInput = {
  assetItemId: Scalars['String'];
  cron?: InputMaybe<Scalars['String']>;
  formattedNote?: InputMaybe<Scalars['String']>;
  lastReminderAt?: InputMaybe<Scalars['String']>;
  note: Scalars['String'];
  notifyUserIds: Array<Scalars['String']>;
  recurring?: InputMaybe<Scalars['Boolean']>;
  recurringInterval?: InputMaybe<Scalars['Float']>;
  startReminderAt: Scalars['String'];
  type?: InputMaybe<AssetItemReminderTypeEnum>;
};

export type CreateAssetItemReminderPayload = {
  __typename?: 'CreateAssetItemReminderPayload';
  assetItemReminder?: Maybe<AssetItemReminder>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAssetKitItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  kitConfigs?: InputMaybe<Array<AssetKitInputConfig>>;
  locationId?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
  siteId: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<ItemTypeEnum>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetKitItemPayload = {
  __typename?: 'CreateAssetKitItemPayload';
  assetKitItem?: Maybe<AssetKitItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAssetModelItemInput = {
  /** AssetId from which the new assetModel is to be created */
  assetId?: InputMaybe<Scalars['String']>;
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  mName: Scalars['String'];
  manufacturerId?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  type?: InputMaybe<ItemTypeEnum>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateAssetModelItemPayload = {
  __typename?: 'CreateAssetModelItemPayload';
  assetModelItem?: Maybe<AssetModelItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateAuditTransactionsInput = {
  entities: Array<AuditTransactionEntityInput>;
};

export type CreateCategoryInput = {
  /** On which item the category is applicable */
  applicableOn?: InputMaybe<Array<CategoryApplicableOnEnum>>;
  assetFlag?: InputMaybe<Scalars['Boolean']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryManagerIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  inventoryFlag?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<CategorySchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateCheckInTransactionInput = {
  checkedOutTransactionId: Scalars['String'];
  meta?: InputMaybe<CheckOutTransactionMetaInputInput>;
  quantity: Scalars['Int'];
};

export type CreateCheckOutTransactionsInput = {
  entities: Array<CheckOutTransactionEntityInput>;
};

export type CreateCompaniesInput = {
  createCompanyInputs: Array<CreateCompanyInput>;
  jobId?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyInput = {
  companyContactNumber?: InputMaybe<Scalars['String']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyFax?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyWebsite?: InputMaybe<Scalars['String']>;
  isVendor?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload';
  company?: Maybe<CompanySchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateContactInput = {
  addressId: Scalars['String'];
  companyId: Scalars['String'];
  companySqlId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  homeContactNumber?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  mobileContactNumber?: InputMaybe<Scalars['String']>;
  officeContactNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  contact?: Maybe<ContactSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateDepartmentInput = {
  departmentCode: Scalars['String'];
  description: Scalars['String'];
  formattedDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateDepartmentPayload = {
  __typename?: 'CreateDepartmentPayload';
  department?: Maybe<DepartmentSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateDepartmentsPayload = {
  __typename?: 'CreateDepartmentsPayload';
  departments?: Maybe<Array<DepartmentSchema>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateExternalOrderRequestInput = {
  billToSiteId: Scalars['String'];
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId: Scalars['String'];
  dueDate: Scalars['String'];
  items: Array<CreateExternalOrderRequestItemInput>;
  parentTenantId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrderRequestTypeEnum>;
};

export type CreateExternalOrderRequestItemInput = {
  /** Should only be defined when the item is an asset or inventory item */
  categoryIdOfItemInParentTenant: Scalars['String'];
  cost: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  itemIdInParentTenant: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  type: OrderRequestItemTypeEnum;
};

export type CreateImportJobInput = {
  createJobHeaderMap: Array<CreateJobHeaderMap>;
  entityType: ImportEntityEnum;
  url: Scalars['String'];
};

export type CreateInventoryItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  mName?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Float']>;
  siteId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type?: InputMaybe<ItemTypeEnum>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateInventoryItemPayload = {
  __typename?: 'CreateInventoryItemPayload';
  inventoryItem?: Maybe<InventoryItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateInventoryItemsInput = {
  createInventoryItemInputs: Array<CreateInventoryItemInput>;
  jobId?: InputMaybe<Scalars['String']>;
};

export type CreateInventoryKitItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  kitConfigs?: InputMaybe<Array<InventoryKitInputConfig>>;
  locationId?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
  siteId: Scalars['String'];
  title: Scalars['String'];
  type?: InputMaybe<ItemTypeEnum>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateInventoryKitItemPayload = {
  __typename?: 'CreateInventoryKitItemPayload';
  inventoryKitItem?: Maybe<InventoryKitItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<Array<CreateMediaInput>>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes?: InputMaybe<Array<ExternalProductCodeInput>>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateJobHeaderMap = {
  columnHeader: Scalars['String'];
  entityField: Scalars['String'];
};

export type CreateJobInput = {
  name: Scalars['String'];
  /** Purpose of the job */
  purpose: JobPurposeEnum;
  /** Status of the job */
  status: JobStatusEnum;
};

export type CreateJobPayload = {
  __typename?: 'CreateJobPayload';
  /** job id of the job created */
  job?: Maybe<JobSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateJobPreprocessorEntityMap = {
  __typename?: 'CreateJobPreprocessorEntityMap';
  entityField: Scalars['String'];
  entityLabel: Scalars['String'];
  isRequired: Scalars['Boolean'];
};

export type CreateJobPreprocessorPayload = {
  __typename?: 'CreateJobPreprocessorPayload';
  columnHeaders?: Maybe<Array<Scalars['String']>>;
  entityMap?: Maybe<Array<CreateJobPreprocessorEntityMap>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateKitRemoveTransactionsInput = {
  entities: Array<RemoveTransactionEntityInput>;
  subType?: InputMaybe<RemoveTransactionSubTypeEnum>;
};

export type CreateLanNetworkAssetInputs = {
  createLanNetworkAsset: Array<CreateLanNetworkAssetItemInput>;
};

export type CreateLanNetworkAssetItemInput = {
  ipAddress?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type CreateLanNetworkAssetPayload = {
  __typename?: 'CreateLanNetworkAssetPayload';
  networkAssets?: Maybe<NetworkAssetSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateLocationInput = {
  /** On which item the location is applicable */
  applicableOn?: InputMaybe<Array<LocationApplicableOnEnum>>;
  canStoreAsset?: InputMaybe<Scalars['Boolean']>;
  canStoreInventory?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** Site Id of the Location */
  siteId?: InputMaybe<Scalars['String']>;
  siteManagerIds?: InputMaybe<Array<Scalars['String']>>;
  siteStaffIds?: InputMaybe<Array<Scalars['String']>>;
  type: LocationTypeEnum;
};

export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  location?: Maybe<LocationSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateLocationsPayload = {
  __typename?: 'CreateLocationsPayload';
  locations?: Maybe<Array<LocationSchema>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateManufacturerInput = {
  emailId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateManufacturerPayload = {
  __typename?: 'CreateManufacturerPayload';
  manufacturer?: Maybe<ManufacturerSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** General Media Object, can be used with any entity */
export type CreateMediaInput = {
  /** Information regarding Media category(Image, video, audio, document) */
  kind: MediaKindEnum;
  /** media type of uploaded entity */
  mimeType?: InputMaybe<Scalars['String']>;
  resolutions: Array<ResolutionInput>;
  /** Size of the attachment in kbs */
  size?: InputMaybe<Scalars['Int']>;
  /** url of the attachment stored on our end */
  url?: InputMaybe<Scalars['String']>;
};

export type CreateMoveTransactionsInput = {
  entities: Array<MoveTransactionEntityInput>;
  status?: InputMaybe<TransactionStatusEnum>;
  subType?: InputMaybe<MoveTransactionSubTypeEnum>;
};

export type CreateNetworkAssetInput = {
  battery?: InputMaybe<Battery>;
  bios?: InputMaybe<Bios>;
  cpu?: InputMaybe<Cpu>;
  deviceOwnerId?: InputMaybe<Scalars['String']>;
  disk?: InputMaybe<Array<Disk>>;
  display?: InputMaybe<Array<Display>>;
  email?: InputMaybe<Scalars['String']>;
  graphics?: InputMaybe<Array<Graphics>>;
  ipAddress?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  memory?: InputMaybe<Memory>;
  modelNumber?: InputMaybe<Scalars['String']>;
  motherboard?: InputMaybe<MotherBoard>;
  network?: InputMaybe<Array<Network>>;
  note?: InputMaybe<Note>;
  operatingSystem?: InputMaybe<OperatingSystem>;
  port?: InputMaybe<Array<Port>>;
  process?: InputMaybe<Process>;
  serialNumber?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  software?: InputMaybe<Array<Scalars['String']>>;
  storage?: InputMaybe<Array<Storage>>;
  system?: InputMaybe<System>;
  type?: InputMaybe<NetworkAssetTypeEnum>;
  userName?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Uuid>;
  warranty?: InputMaybe<Warranty>;
};

export type CreateNetworkAssetPayload = {
  __typename?: 'CreateNetworkAssetPayload';
  networkAssets?: Maybe<NetworkAssetSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateOrderRequestInput = {
  billToSiteId: Scalars['String'];
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId: Scalars['String'];
  dueDate: Scalars['String'];
  items?: InputMaybe<Array<CreateOrderRequestItemInput>>;
  title?: InputMaybe<Scalars['String']>;
  type: OrderRequestTypeEnum;
};

export type CreateOrderRequestItemInput = {
  categoryId?: InputMaybe<Scalars['String']>;
  cost: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  itemIdInPartnerTenant?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  type: OrderRequestItemTypeEnum;
  upcCode?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type CreateOrderRequestPayload = {
  __typename?: 'CreateOrderRequestPayload';
  orderRequest?: Maybe<OrderRequestSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreatePartnerTenantInput = {
  accessibleCategoryIds: Array<Scalars['String']>;
  allowedUserIds: Array<Scalars['String']>;
  childTenantId: Scalars['String'];
  parentTenantId: Scalars['String'];
};

export type CreatePartnerTenantPayload = {
  __typename?: 'CreatePartnerTenantPayload';
  partnerTenant?: Maybe<PartnerTenantSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreatePickListInput = {
  items: Array<CreatePickListItemInput>;
  orderRequestId: Scalars['String'];
  siteId: Scalars['String'];
};

export type CreatePickListItemInContainerInput = {
  containerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CreatePickListItemInput = {
  /** Packing segregation of item into one or more containers. */
  containers: Array<CreatePickListItemInContainerInput>;
  cost: Scalars['String'];
  /** itemId of the inventory/asset item that is being packed. */
  itemId: Scalars['String'];
  /** Source location from which the item is being picked and packed into container(s). */
  locationId: Scalars['String'];
  /** Id of the orderRequestItem that is being packed. */
  orderRequestItemId: Scalars['String'];
};

export type CreateProjectInput = {
  description: Scalars['String'];
  formattedDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectCode: Scalars['String'];
};

export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  project?: Maybe<ProjectSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateProjectsInput = {
  createProjectInputs: Array<CreateProjectInput>;
};

export type CreateProjectsPayload = {
  __typename?: 'CreateProjectsPayload';
  projects?: Maybe<Array<ProjectSchema>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type CreateReceivableItemConfig = {
  carrier?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  formattedNotes?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  orderRequestItemId?: InputMaybe<Scalars['String']>;
  quantityOrdered?: InputMaybe<Scalars['Float']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateReceivablesInput = {
  carrier?: InputMaybe<Scalars['String']>;
  deliverToLocationId?: InputMaybe<Scalars['String']>;
  deliverToSiteId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  formattedNotes?: InputMaybe<Scalars['String']>;
  itemConfigs?: InputMaybe<Array<CreateReceivableItemConfig>>;
  notes?: InputMaybe<Scalars['String']>;
  orderRequestId?: InputMaybe<Scalars['String']>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type CreateRemoveTransactionsInput = {
  entities: Array<RemoveTransactionEntityInput>;
  subType?: InputMaybe<RemoveTransactionSubTypeEnum>;
};

export type CreateReserveTransactionsInput = {
  entities: Array<ReserveTransactionEntityInput>;
};

export type CreateRestockTransactionsInput = {
  entities: Array<RestockTransactionEntityInput>;
  subType?: InputMaybe<RestockTransactionSubTypeEnum>;
};

export type CreateRoleInput = {
  allowedScopes?: InputMaybe<Array<AllowedScopeInput>>;
  /** Type of role */
  applicableOn?: InputMaybe<Array<ApplicableOnEnum>>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<RoleMetadataInput>;
  name: Scalars['String'];
  /** Type of role */
  type?: InputMaybe<RoleTypeEnum>;
};

export type CreateRolePayload = {
  __typename?: 'CreateRolePayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<RoleSchema>;
  success: Scalars['Boolean'];
};

/** Schedule Job Payload */
export type CreateScheduleJobPayload = {
  __typename?: 'CreateScheduleJobPayload';
  /** Uniq Identifier to reference scheduled entity */
  key?: Maybe<Scalars['String']>;
  /** Scheduled Job ID */
  scheduledJobId: Scalars['String'];
};

/** Schedule Job Payload */
export type CreateScheduleJobsPayload = {
  __typename?: 'CreateScheduleJobsPayload';
  /** Scheduled Jobs */
  scheduledJobs: Array<CreateScheduleJobPayload>;
};

/** Job to be scheduled */
export type CreateScheduledJobInput = {
  grpcMetadata?: InputMaybe<ScheduledJobGrpcMetadataInput>;
  /** Type of job http/grpc */
  invocationType: InvocationTypeEnum;
  key?: InputMaybe<Scalars['String']>;
  /** Job is repeating or not */
  recurring?: InputMaybe<Scalars['Boolean']>;
  /** Job should repeat for all tenants */
  recurringForAllTenants?: InputMaybe<Scalars['Boolean']>;
  recurringJobMetadata?: InputMaybe<RecurringScheduledJobMetadataInput>;
  /** DateTime when the job needs to be executed */
  scheduledAt: Scalars['String'];
  tenantId: Scalars['String'];
};

/** Jobs to be scheduled */
export type CreateScheduledJobsInput = {
  jobs: Array<CreateScheduledJobInput>;
};

export type CreateShippingContainersInput = {
  containerType: ShippingContainerTypePrefixEnum;
  count: Scalars['Int'];
  destinationSiteId: Scalars['String'];
};

export type CreateShippingContainersPayload = {
  __typename?: 'CreateShippingContainersPayload';
  shippingContainers?: Maybe<Array<ShippingContainerSchema>>;
};

export type CreateShippingTransactionsBasedOnContainerIntoContainerInput = {
  childContainerIds: Array<Scalars['String']>;
  parentContainerId: Scalars['String'];
};

export type CreateShippingTransactionsBasedOnContainerIntoContainerInputs = {
  inputs: Array<CreateShippingTransactionsBasedOnContainerIntoContainerInput>;
};

export type CreateShippingTransactionsBasedOnInventoryMoveTransactionInput = {
  packItemsInShippingContainerInputs: Array<PackItemsInShippingContainerInput>;
};

export type CreateShippingTransactionsPayload = {
  __typename?: 'CreateShippingTransactionsPayload';
  shippingTransactions: Array<ShippingTransactionSchema>;
};

export type CreateTenantInput = {
  adminEmailId: Scalars['String'];
  config: TenantConfigInputType;
  firstName: Scalars['String'];
  invitedByTenantId?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  locationDetails: TenantLocationDetailsInputType;
  logo?: InputMaybe<Scalars['String']>;
  moduleVisibilityConfig?: InputMaybe<ModuleVisibilityConfigInputType>;
  name: Scalars['String'];
  primaryPhoneNumber: Scalars['String'];
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  status: TenantStatusEnum;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateTenantPayload = {
  __typename?: 'CreateTenantPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  tenant?: Maybe<TenantSchema>;
};

export type CreateTransactionsPayload = {
  __typename?: 'CreateTransactionsPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  transactions?: Maybe<Array<TransactionSchema>>;
};

export type CreateUserInput = {
  defaultSiteId?: InputMaybe<Scalars['String']>;
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  scopedRoles: Array<UserScopedRoleInput>;
  status: UserStatusEnum;
  /** tenant id of the user */
  tenantId: Scalars['String'];
  userTimeZone?: InputMaybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<UserSchema>;
};

export type CreateWarehouseInput = {
  aisles?: InputMaybe<Scalars['Float']>;
  bins?: InputMaybe<Scalars['Float']>;
  racks: Scalars['Float'];
  shelves: Scalars['Float'];
  siteId: Scalars['String'];
  warehouseType?: InputMaybe<WarehouseTypeEnum>;
  zones?: InputMaybe<Scalars['Float']>;
};

export type CreateWishlistInput = {
  collaborators?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  eventDate: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products: Array<WishlistProductSchemaInput>;
  shareId?: InputMaybe<Scalars['String']>;
  shippingAddress: WishlistShippingAddressSchemaInput;
  tenantId?: InputMaybe<Scalars['String']>;
};

export type CreateWishlistPayload = {
  __typename?: 'CreateWishlistPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  wishlist?: Maybe<WishlistSchema>;
};

export type DateFilter = {
  absolute?: InputMaybe<AbsoluteDateFilter>;
  relative?: InputMaybe<RelativeDateFilter>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type DeleteAddressesInput = {
  addressIds: Array<Scalars['String']>;
};

export type DeleteAffiliateConfigurationsInput = {
  affiliateConfigurationIds: Array<Scalars['String']>;
};

export type DeleteAssetItemReminderInput = {
  reminderId: Scalars['String'];
};

export type DeleteAssetKitItemInput = {
  assetKitItemId?: InputMaybe<Scalars['String']>;
};

export type DeleteCategoriesInput = {
  categoryIds: Array<Scalars['String']>;
};

export type DeleteCompaniesInput = {
  companyIds: Array<Scalars['String']>;
};

export type DeleteContactsInput = {
  contactIds: Array<Scalars['String']>;
};

export type DeleteDepartmentsInput = {
  departmentIds: Array<Scalars['String']>;
};

export type DeleteInTransitTransactionsInput = {
  transactionIds: Array<Scalars['String']>;
};

export type DeleteInventoryKitItemInput = {
  inventoryKitItemId?: InputMaybe<Scalars['String']>;
};

export type DeleteItemsInput = {
  itemIds: Array<Scalars['String']>;
};

export type DeleteLocationsInput = {
  locationIds: Array<Scalars['String']>;
};

export type DeleteManufacturersInput = {
  manufacturerIds: Array<Scalars['String']>;
};

export type DeleteNetworkAssetInput = {
  deleteAllNetworkAssets: Scalars['Boolean'];
};

export type DeleteOrderRequestInput = {
  orderRequestId: Scalars['String'];
};

export type DeleteOrderRequestsInput = {
  orderRequestIds: Array<Scalars['String']>;
};

export type DeletePartnerTenantsInput = {
  partnerTenantIds: Array<Scalars['String']>;
};

export type DeleteProjectsInput = {
  projectIds: Array<Scalars['String']>;
};

export type DeleteReceivablesInput = {
  receivableIds: Array<Scalars['String']>;
};

export type DeleteRolesInput = {
  roleIds: Array<Scalars['String']>;
};

/** Job to be deleted */
export type DeleteScheduledJobInput = {
  /** ID of job to be deleted */
  scheduledJobId: Scalars['String'];
};

export type DeleteScheduledJobPayload = {
  __typename?: 'DeleteScheduledJobPayload';
  success: Scalars['Boolean'];
};

export type DeleteShippingContainersInput = {
  containerIds: Array<Scalars['String']>;
};

export type DeleteTenantInput = {
  tenantId: Scalars['String'];
};

export type DeleteUsersInput = {
  userIds: Array<Scalars['String']>;
};

export type DeleteWarehouseInput = {
  warehouseId: Scalars['String'];
};

export type DeleteWishlistsInput = {
  wishlistIds: Array<Scalars['String']>;
};

/** This returns paginated departments */
export type DepartmentConnection = {
  __typename?: 'DepartmentConnection';
  edges?: Maybe<Array<DepartmentEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  node: DepartmentSchema;
};

/** Department filters */
export type DepartmentFilters = {
  _and?: InputMaybe<Array<DepartmentFilters>>;
  _or?: InputMaybe<Array<DepartmentFilters>>;
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<DepartmentStatusEnum>>;
};

/** Department schema */
export type DepartmentSchema = {
  __typename?: 'DepartmentSchema';
  createdAt: Scalars['String'];
  /** Returns createdBy user of department */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  departmentCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of department */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum DepartmentStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type Disk = {
  available?: InputMaybe<Scalars['Float']>;
  fileSystem?: InputMaybe<Scalars['String']>;
  mount?: InputMaybe<Scalars['String']>;
  readWrite?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  use?: InputMaybe<Scalars['Float']>;
  used?: InputMaybe<Scalars['Float']>;
};

/** Disk Schema */
export type DiskSchema = {
  __typename?: 'DiskSchema';
  available?: Maybe<Scalars['Float']>;
  fileSystem?: Maybe<Scalars['String']>;
  mount?: Maybe<Scalars['String']>;
  readWrite?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  use?: Maybe<Scalars['Float']>;
  used?: Maybe<Scalars['Float']>;
};

export type Display = {
  builtin?: InputMaybe<Scalars['Boolean']>;
  connection?: InputMaybe<Scalars['String']>;
  currentRefreshRate?: InputMaybe<Scalars['Int']>;
  currentResX?: InputMaybe<Scalars['Int']>;
  currentResY?: InputMaybe<Scalars['Int']>;
  deviceName?: InputMaybe<Scalars['String']>;
  main?: InputMaybe<Scalars['Boolean']>;
  model?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

/** Display Schema */
export type DisplaySchema = {
  __typename?: 'DisplaySchema';
  builtin?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<Scalars['String']>;
  currentRefreshRate?: Maybe<Scalars['Int']>;
  currentResX?: Maybe<Scalars['Int']>;
  currentResY?: Maybe<Scalars['Int']>;
  deviceName?: Maybe<Scalars['String']>;
  main?: Maybe<Scalars['Boolean']>;
  model?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

/** DistictValueSchema */
export type DistictValueSchema = {
  __typename?: 'DistictValueSchema';
  count?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

/** DistinctCache filters */
export type DistinctCacheFilters = {
  _and?: InputMaybe<Array<DistinctCacheFilters>>;
  _or?: InputMaybe<Array<DistinctCacheFilters>>;
  tableNames?: InputMaybe<Array<DistinctCacheTableNameEnum>>;
};

/** DistinctCache */
export type DistinctCacheSchema = {
  __typename?: 'DistinctCacheSchema';
  createdAt: Scalars['String'];
  distinctValues: Array<DistinctValuesType>;
  id: Scalars['String'];
  tableName: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export const enum DistinctCacheTableNameEnum {
  ContainerSearch = 'CONTAINER_SEARCH',
  OrderRequestHistory = 'ORDER_REQUEST_HISTORY',
  ShipmentSearch = 'SHIPMENT_SEARCH'
};

/** DistinctValueNetworkAssetsPayload */
export type DistinctValueNetworkAssetsPayload = {
  __typename?: 'DistinctValueNetworkAssetsPayload';
  distinctValues: NetworkAssetsDistictValueSchema;
};

/** DistinctValue */
export type DistinctValuesType = {
  __typename?: 'DistinctValuesType';
  field: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type DoesTenantExistsForTheEmail = {
  adminEmailId: Scalars['String'];
};

export type EditWarehouseInput = {
  aisles?: InputMaybe<Scalars['Int']>;
  bins?: InputMaybe<Scalars['Int']>;
  locationPrefixes: Array<Scalars['String']>;
  racks?: InputMaybe<Scalars['Int']>;
  shelves?: InputMaybe<Scalars['Int']>;
  warehouseId: Scalars['String'];
  zones?: InputMaybe<Scalars['Int']>;
};

export type EntityPermissionsMap = {
  __typename?: 'EntityPermissionsMap';
  entityPermissions: Array<AllowedEntityPermissions>;
  hidden?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
};

export const enum EntitySourceEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
};

export type ExportJobMetadataInput = {
  exportReportUrl: Scalars['String'];
};

/** Job metadata */
export type ExportJobMetadataType = {
  __typename?: 'ExportJobMetadataType';
  exportReportUrl: Scalars['String'];
};

/** Item External Product Code Schema (UPC, ASIN etc.) */
export type ExternalProductCodeInput = {
  code: Scalars['String'];
  type: ItemExternalProductCodeTypeEnum;
};

/** Geo Code Acceptable City Name schema */
export type GeoCodeAcceptableCityNameSchema = {
  __typename?: 'GeoCodeAcceptableCityNameSchema';
  city: Scalars['String'];
  state: Scalars['String'];
};

/** Geo Code Coordinate schema */
export type GeoCodeCoordinatesSchema = {
  __typename?: 'GeoCodeCoordinatesSchema';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** GeoCode filters */
export type GeoCodeFilters = {
  _and?: InputMaybe<Array<GeoCodeFilters>>;
  _or?: InputMaybe<Array<GeoCodeFilters>>;
  zipcode: Scalars['String'];
};

/** GeoCode schema. */
export type GeoCodeSchema = {
  __typename?: 'GeoCodeSchema';
  acceptableCityNames?: Maybe<Array<GeoCodeAcceptableCityNameSchema>>;
  areaCodes?: Maybe<Array<Scalars['Float']>>;
  city: Scalars['String'];
  coordinates?: Maybe<GeoCodeCoordinatesSchema>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  state: Scalars['String'];
  timezone?: Maybe<GeoCodeTimezoneSchema>;
  updatedAt: Scalars['String'];
  zipcode: Scalars['String'];
};

/** Geo Code Timezone schema */
export type GeoCodeTimezoneSchema = {
  __typename?: 'GeoCodeTimezoneSchema';
  abbreviation: Scalars['String'];
  identifier: Scalars['String'];
  isDaylightSavingTime: Scalars['Boolean'];
  utcOffsetInSeconds: Scalars['Float'];
};

/** Get Item Age Notification Details Input */
export type GetAgingItemDetailsInput = {
  age: Scalars['Float'];
  itemTypes: Array<Scalars['String']>;
};

/** Network Assets filters for warranty */
export type GetAllNetworkAssetsWarrantyInput = {
  types?: InputMaybe<Array<NetworkAssetTypeEnum>>;
};

/** GetAllNetworkAssetsWarrantyPayload */
export type GetAllNetworkAssetsWarrantyPayload = {
  __typename?: 'GetAllNetworkAssetsWarrantyPayload';
  totalAssetsWarranty: Array<TotalNetworkAssetsWarranty>;
};

/** Payload for all products */
export type GetAllProductsPayload = {
  __typename?: 'GetAllProductsPayload';
  products: Array<ProductSchema>;
};

export type GetAllWarehouseLocationsConfigsInput = {
  aisles?: InputMaybe<Scalars['Int']>;
  bins?: InputMaybe<Scalars['Int']>;
  racks?: InputMaybe<Scalars['Int']>;
  shelves?: InputMaybe<Scalars['Int']>;
  zones?: InputMaybe<Scalars['Int']>;
};

export type GetAllWarehouseLocationsConfigsPayload = {
  __typename?: 'GetAllWarehouseLocationsConfigsPayload';
  configs: Array<WarehouseLocationConfig>;
};

export type GetByIdFilter = {
  id: Scalars['String'];
};

/** Get job input */
export type GetJobInput = {
  filter: JobFilter;
};

/** JobDocument */
export type GetJobPayload = {
  __typename?: 'GetJobPayload';
  job?: Maybe<JobSchema>;
};

export type GetLastLocationNamesOfChildInput = {
  aisleNumber?: InputMaybe<Scalars['Int']>;
  maxChildIdentifier?: InputMaybe<Scalars['String']>;
  rackNumber?: InputMaybe<Scalars['Int']>;
  shelfName?: InputMaybe<Scalars['String']>;
  warehouseId?: InputMaybe<Scalars['String']>;
  warehouseType?: InputMaybe<WarehouseTypeEnum>;
  zoneName?: InputMaybe<Scalars['String']>;
};

export type GetReturnedOrderRequestDetailForNotificationInput = {
  orderRequestId: Scalars['String'];
  orderRequestItemId: Scalars['String'];
};

/** Get Returned Order Request Detail For Notification Payload */
export type GetReturnedOrderRequestDetailForNotificationPayload = {
  __typename?: 'GetReturnedOrderRequestDetailForNotificationPayload';
  /** Assume a stringified JSON array */
  templateVariables: Scalars['String'];
};

export type GetTotalQuantityOfItemsInput = {
  siteId?: InputMaybe<Scalars['String']>;
};

export type GetValuesInEntitiesConfig = {
  __typename?: 'GetValuesInEntitiesConfig';
  entities: Array<ValuesInEntitiesSchema>;
  key: Scalars['String'];
};

export type GetValuesInEntitiesInput = {
  distinctByKeys: Array<ValuesInEntitiesDistinctByKeysEnum>;
  filters: ValueInEntitiesFiltersConfig;
  tableName: ValuesInEntitiesTableNameEnum;
};

export type GetValuesInEntitiesPayload = {
  __typename?: 'GetValuesInEntitiesPayload';
  payload: Array<GetValuesInEntitiesConfig>;
};

export type Graphics = {
  bus?: InputMaybe<Scalars['String']>;
  busAddress?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  pciID?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
  vram?: InputMaybe<Scalars['Int']>;
  vramDynamic?: InputMaybe<Scalars['Boolean']>;
};

/** Graphics Schema */
export type GraphicsSchema = {
  __typename?: 'GraphicsSchema';
  bus?: Maybe<Scalars['String']>;
  busAddress?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  pciID?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
  vram?: Maybe<Scalars['Int']>;
  vramDynamic?: Maybe<Scalars['Boolean']>;
};

export const enum ImportEntityEnum {
  Asset = 'ASSET',
  AssetModel = 'ASSET_MODEL',
  Category = 'CATEGORY',
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Inventory = 'INVENTORY',
  Location = 'LOCATION',
  Manufacturers = 'MANUFACTURERS',
  Project = 'PROJECT',
  Site = 'SITE',
  User = 'USER'
};

export type ImportJobMetadataInput = {
  importErrorMessageUrl?: InputMaybe<Scalars['String']>;
  inValidDataCount: Scalars['Float'];
  validDataCount: Scalars['Float'];
};

/** ImportJobMetadataType */
export type ImportJobMetadataType = {
  __typename?: 'ImportJobMetadataType';
  importErrorMessageUrl?: Maybe<Scalars['String']>;
  inValidDataCount?: Maybe<Scalars['Float']>;
  validDataCount?: Maybe<Scalars['Float']>;
};

/** This returns paginated inventoryItems */
export type InventoryItemConnection = {
  __typename?: 'InventoryItemConnection';
  edges: Array<InventoryItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type InventoryItemEdge = {
  __typename?: 'InventoryItemEdge';
  node: InventoryItemSchema;
};

/** InventoryItem filters */
export type InventoryItemFilters = {
  _and?: InputMaybe<Array<InventoryItemFilters>>;
  _or?: InputMaybe<Array<InventoryItemFilters>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type InventoryItemSchema = {
  __typename?: 'InventoryItemSchema';
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of inventory item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the inventory item */
  cost?: Maybe<Scalars['Float']>;
  /** Cost override of the inventory item */
  costOverride?: Maybe<Scalars['Float']>;
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of inventory item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  /** @deprecated Use model instead of mName. */
  mName?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  /** Returns model of inventory item */
  model?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  /** Returns reminders of asset item */
  reminders: Array<AssetItemReminder>;
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of inventory items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of inventory item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  /** Unit cost of the inventory item */
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of inventory item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns actions allowed on subjects of inventory items */
  userEntityPermissions?: Maybe<Array<UserEntityPermission>>;
  /** Returns vendor of inventory item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};


export type InventoryItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


export type InventoryItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

export type InventoryKitConfig = {
  __typename?: 'InventoryKitConfig';
  count: Scalars['Float'];
  /** Returns inventoryItem of kitConfig */
  inventoryItem: InventoryItemSchema;
  itemId: Scalars['String'];
  /** Returns location of kitConfig */
  location: LocationSchema;
  locationId: Scalars['String'];
};

export type InventoryKitInputConfig = {
  count: Scalars['Float'];
  itemId: Scalars['String'];
  locationId: Scalars['String'];
};

/** This returns paginated assetItems */
export type InventoryKitItemConnection = {
  __typename?: 'InventoryKitItemConnection';
  edges: Array<InventoryKitItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type InventoryKitItemEdge = {
  __typename?: 'InventoryKitItemEdge';
  node: InventoryKitItemSchema;
};

/** Inventory item filters */
export type InventoryKitItemFilters = {
  _and?: InputMaybe<Array<InventoryKitItemFilters>>;
  _or?: InputMaybe<Array<InventoryKitItemFilters>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  kitItemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type InventoryKitItemSchema = {
  __typename?: 'InventoryKitItemSchema';
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of inventory kit item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the inventory kit item */
  cost?: Maybe<Scalars['Float']>;
  /** Cost override of the inventory kit item */
  costOverride?: Maybe<Scalars['Float']>;
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of inventory kit item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  kitConfigs: Array<InventoryKitConfig>;
  locationId?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
  /** Returns reminders of asset item */
  reminders: Array<AssetItemReminder>;
  /** Returns site of inventory kit item */
  site?: Maybe<LocationSchema>;
  siteId: Scalars['String'];
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of inventory kit items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of inventory kit item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  /** Unit cost of the inventory kit item */
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of inventory kit item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns vendor of item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};


export type InventoryKitItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


export type InventoryKitItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

export type InvitePartnerTenantInput = {
  emailId: Scalars['String'];
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
};

export type InvitePartnerTenantPayload = {
  __typename?: 'InvitePartnerTenantPayload';
  inviteToken?: Maybe<Scalars['String']>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export const enum InvocationTypeEnum {
  Grpc = 'GRPC',
  Http = 'HTTP'
};

/** This returns paginated items */
export type ItemConnection = {
  __typename?: 'ItemConnection';
  edges: Array<ItemEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  node: ItemUnion;
};

/** Item External Product Code Schema (UPC, ASIN etc.) */
export type ItemExternalProductCodeSchema = {
  __typename?: 'ItemExternalProductCodeSchema';
  code: Scalars['String'];
  type: ItemExternalProductCodeTypeEnum;
};

export const enum ItemExternalProductCodeTypeEnum {
  Asin = 'ASIN',
  Ean = 'EAN',
  Gtin = 'GTIN',
  Isbn10 = 'ISBN10',
  Isbn13 = 'ISBN13',
  Upc = 'UPC'
};

/** Item filters */
export type ItemFilters = {
  _and?: InputMaybe<Array<ItemFilters>>;
  _or?: InputMaybe<Array<ItemFilters>>;
  brands?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  includeLocationsInSearch?: InputMaybe<Scalars['Boolean']>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  mNames?: InputMaybe<Array<Scalars['String']>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  modelNumbers?: InputMaybe<Array<Scalars['String']>>;
  nonChildItemsOnly?: InputMaybe<Scalars['Boolean']>;
  parentIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  productCodes?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ItemStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<ItemTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

/** This returns paginated item locations */
export type ItemLocationConfigConnection = {
  __typename?: 'ItemLocationConfigConnection';
  edges: Array<ItemLocationConfigEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ItemLocationConfigEdge = {
  __typename?: 'ItemLocationConfigEdge';
  node: ItemLocationConfigSchema;
};

/** ItemLocationConfig filters */
export type ItemLocationConfigFilters = {
  _and?: InputMaybe<Array<ItemLocationConfigFilters>>;
  _or?: InputMaybe<Array<ItemLocationConfigFilters>>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
};

/** ItemLocationConfigConfig Schema */
export type ItemLocationConfigSchema = {
  __typename?: 'ItemLocationConfigSchema';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  itemId: Scalars['String'];
  locationId: Scalars['String'];
  locationType: LocationTypeEnum;
  maximumQuantity?: Maybe<Scalars['Int']>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  siteId: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
};

/** This returns paginated item locations */
export type ItemLocationConnection = {
  __typename?: 'ItemLocationConnection';
  edges: Array<ItemLocationEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ItemLocationEdge = {
  __typename?: 'ItemLocationEdge';
  node: ItemLocationSchema;
};

/** ItemLocation's external fields that can  be used to sort output */
export type ItemLocationExpandedSortFieldsSchema = {
  __typename?: 'ItemLocationExpandedSortFieldsSchema';
  locationName?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
};

/** ItemLocation filters */
export type ItemLocationFilters = {
  _and?: InputMaybe<Array<ItemLocationFilters>>;
  _or?: InputMaybe<Array<ItemLocationFilters>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  itemLocationIds?: InputMaybe<Array<Scalars['String']>>;
  itemStatuses?: InputMaybe<Array<ItemStatusEnum>>;
  itemTypes?: InputMaybe<Array<ItemLocationItemTypeEnum>>;
  lastTransactionDate?: InputMaybe<DateFilter>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  locationStatuses?: InputMaybe<Array<LocationStatusEnum>>;
  locationTypes?: InputMaybe<Array<LocationTypeEnum>>;
  nonZeroAvailableQuantity?: InputMaybe<Scalars['Boolean']>;
  nonZeroAvailableQuantityFromLocations?: InputMaybe<Scalars['Boolean']>;
  nonZeroInTransitQuantity?: InputMaybe<Scalars['Boolean']>;
  nonZeroMinimumQuantity?: InputMaybe<Scalars['Boolean']>;
  nonZeroTotalQuantity?: InputMaybe<Scalars['Boolean']>;
  nonZeroTotalQuantityFromLocations?: InputMaybe<Scalars['Boolean']>;
  onlyLocationsOfMinimumQuantityThresholdBreachedSite?: InputMaybe<Scalars['Boolean']>;
  onlyMinimumQuantityThresholdBreached?: InputMaybe<Scalars['Boolean']>;
  onlyMinimumQuantityThresholdOfSiteBreached?: InputMaybe<Scalars['Boolean']>;
  outOfStockAt?: InputMaybe<DateFilter>;
  recentOnly?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<DateFilter>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
  zeroAvailableQuantity?: InputMaybe<Scalars['Boolean']>;
  zeroAvailableQuantityFromLocations?: InputMaybe<Scalars['Boolean']>;
  zeroTotalQuantity?: InputMaybe<Scalars['Boolean']>;
  zeroTotalQuantityFromLocations?: InputMaybe<Scalars['Boolean']>;
};

export const enum ItemLocationItemTypeEnum {
  Asset = 'ASSET',
  AssetKit = 'ASSET_KIT',
  AssetModel = 'ASSET_MODEL',
  Inventory = 'INVENTORY',
  InventoryKit = 'INVENTORY_KIT',
  ItAsset = "IT_ASSET"
};

/** ItemLocation Schema */
export type ItemLocationSchema = {
  __typename?: 'ItemLocationSchema';
  availableQuantity?: Maybe<Scalars['Int']>;
  availableQuantityFromLocations?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of itemlocation */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  expandedSortFields?: Maybe<ItemLocationExpandedSortFieldsSchema>;
  id: Scalars['String'];
  /** Returns item of itemLocation */
  item: ItemUnion;
  itemId: Scalars['String'];
  itemStatus: ItemStatusEnum;
  itemType: ItemLocationItemTypeEnum;
  lastTransactionDate?: Maybe<Scalars['String']>;
  /** Returns site of itemLocation */
  location: LocationSchema;
  locationId: Scalars['String'];
  locationStatus: LocationStatusEnum;
  locationType: LocationTypeEnum;
  lockedQuantities: LockedQuantitiesSchema;
  maximumQuantity?: Maybe<Scalars['Int']>;
  minimumQuantity?: Maybe<Scalars['Int']>;
  minimumQuantityThresholdBreached?: Maybe<Scalars['Boolean']>;
  minimumQuantityThresholdOfSiteBreached?: Maybe<Scalars['Boolean']>;
  outOfStockAt?: Maybe<Scalars['String']>;
  /** Returns site of itemLocation */
  site: LocationSchema;
  siteId: Scalars['String'];
  tenantId: Scalars['String'];
  totalQuantity?: Maybe<Scalars['Int']>;
  totalQuantityFromLocations?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of itemlocation */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export type ItemReceivableConfigInput = {
  formattedNotes?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  quantityReceived: Scalars['Float'];
  receivableId: Scalars['String'];
  siteId?: InputMaybe<Scalars['String']>;
};

/** Item schema */
export type ItemSchema = {
  __typename?: 'ItemSchema';
  /** Storing media of items */
  attachments?: Maybe<Array<MediaSchema>>;
  brand?: Maybe<Scalars['String']>;
  /** Returns category of item */
  category?: Maybe<CategorySchema>;
  categoryId: Scalars['String'];
  /** Returns total cost of the item */
  cost?: Maybe<Scalars['Float']>;
  costOverride?: Maybe<Scalars['Float']>;
  cpuBrand?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of item */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  externalProductCodes?: Maybe<Array<ItemExternalProductCodeSchema>>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
  /** Returns manufacturer of item */
  manufacturer?: Maybe<ManufacturerSchema>;
  manufacturerId?: Maybe<Scalars['String']>;
  pickableThroughOrderRequest?: Maybe<Scalars['Boolean']>;
  /** Returns reminders of asset item */
  reminders: Array<AssetItemReminder>;
  sku: Scalars['String'];
  status: ItemStatusEnum;
  /** Returns stock of asset items at different locations */
  stockInformation?: Maybe<Array<ItemLocationSchema>>;
  tenantId: Scalars['String'];
  title: Scalars['String'];
  /** Returns total quantity of item */
  totalQuantity?: Maybe<Scalars['Int']>;
  type: ItemTypeEnum;
  unitCost?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of item */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns vendor of item */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};


/** Item schema */
export type ItemSchemaStockInformationArgs = {
  locationId?: InputMaybe<Scalars['String']>;
  nonZeroAvailableQuantityOnly?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
};


/** Item schema */
export type ItemSchemaTotalQuantityArgs = {
  siteId?: InputMaybe<Scalars['String']>;
};

export const enum ItemStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum ItemStringBasedColumnFilterFieldEnum {
  Brands = 'BRANDS',
  Models = 'MODELS',
  ModelNumbers = 'MODEL_NUMBERS'
};

export const enum ItemTypeEnum {
  Asset = 'ASSET',
  AssetKit = 'ASSET_KIT',
  AssetModel = 'ASSET_MODEL',
  Inventory = 'INVENTORY',
  InventoryKit = 'INVENTORY_KIT'
};

/** It will resolve different Item types */
export type ItemUnion = AssetItemSchema | AssetKitItemSchema | AssetModelItemSchema | InventoryItemSchema | InventoryKitItemSchema;

export type JobByCreatorSubscriptionInput = {
  /** userId of the job creator */
  createdById: Scalars['String'];
  /** Operations to subscribe on */
  operations?: InputMaybe<Array<JobSubscriptionOperationEnum>>;
  /** Job purposes to subscribe on */
  purposes: Array<JobPurposeEnum>;
};

export type JobByCreatorSubscriptionPayload = {
  __typename?: 'JobByCreatorSubscriptionPayload';
  job: JobSchema;
  /** operation which happened on the job */
  operation: JobSubscriptionOperationEnum;
};

/** This returns paginated Jobs */
export type JobConnection = {
  __typename?: 'JobConnection';
  edges: Array<JobEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type JobEdge = {
  __typename?: 'JobEdge';
  node: JobSchema;
};

/** Job filters */
export type JobFilter = {
  jobId: Scalars['String'];
};

/** Job filters */
export type JobFilters = {
  _and?: InputMaybe<Array<JobFilters>>;
  _or?: InputMaybe<Array<JobFilters>>;
  jobIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<JobStatusEnum>>;
};

export const enum JobPurposeEnum {
  ReportExport = 'REPORT_EXPORT',
  UserImport = 'USER_IMPORT'
};

/** Job schema */
export type JobSchema = {
  __typename?: 'JobSchema';
  completedAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  /** Error of the job */
  error?: Maybe<Scalars['String']>;
  exportJobMetadata?: Maybe<ExportJobMetadataType>;
  id: Scalars['String'];
  importErrorMessageUrl?: Maybe<Scalars['String']>;
  importJobMetadata?: Maybe<ImportJobMetadataType>;
  /** Success Message of the Job */
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  purpose?: Maybe<JobPurposeEnum>;
  retryCount?: Maybe<Scalars['Float']>;
  status: JobStatusEnum;
  /** Tenant Id of the Location */
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedById: Scalars['String'];
};

export const enum JobStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InputValidationFailed = 'INPUT_VALIDATION_FAILED',
  InputValidationInProcess = 'INPUT_VALIDATION_IN_PROCESS',
  InProcess = 'IN_PROCESS',
  NotStarted = 'NOT_STARTED'
};

export type JobSubscriptionInput = {
  /** jobId to subscribe job on */
  jobId: Scalars['String'];
};

export const enum JobSubscriptionOperationEnum {
  JobCreated = 'JOB_CREATED',
  JobUpdated = 'JOB_UPDATED'
};

export const enum LocationApplicableOnEnum {
  Asset = 'ASSET',
  Inventory = 'INVENTORY'
};

/** This returns paginated locations */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges: Array<LocationEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  node: LocationSchema;
};

/** Location filters */
export type LocationFilters = {
  _and?: InputMaybe<Array<LocationFilters>>;
  _or?: InputMaybe<Array<LocationFilters>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  namePrefix?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  siteManagerIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<LocationStatusEnum>>;
  tenantIdsOfPartnerTenant?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<LocationTypeEnum>>;
  warehouseId?: InputMaybe<Scalars['String']>;
};

/** Location schema */
export type LocationSchema = {
  __typename?: 'LocationSchema';
  /** On which entity the role is applicable */
  applicableOn: Array<LocationApplicableOnEnum>;
  /** @deprecated Use applicableOn */
  canStoreAsset?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use applicableOn */
  canStoreInventory?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of location */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use case has been handled using location type instead. */
  entitySource?: Maybe<EntitySourceEnum>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locationCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Returns site of location */
  site: LocationSchema;
  /** Site Id of the location */
  siteId: Scalars['String'];
  siteManagerIds?: Maybe<Array<Scalars['String']>>;
  /** Returns siteManagers of site */
  siteManagers: Array<UserSchema>;
  siteStaffIds?: Maybe<Array<Scalars['String']>>;
  /** Returns siteStaffs of site */
  siteStaffs: Array<UserSchema>;
  status: LocationStatusEnum;
  /** Tenant Id of the Location */
  tenantId: Scalars['String'];
  /** Tenant Id of the partner tenant */
  tenantIdOfPartnerTenant?: Maybe<Scalars['String']>;
  type: LocationTypeEnum;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of location */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Warehouse Id of the Location */
  warehouseId?: Maybe<Scalars['String']>;
};

export const enum LocationStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum LocationTypeEnum {
  Location = 'LOCATION',
  PartnerTenant = 'PARTNER_TENANT',
  Site = 'SITE'
};

/** Item locked quantity data */
export type LockedQuantitiesSchema = {
  __typename?: 'LockedQuantitiesSchema';
  checkoutQuantity?: Maybe<Scalars['Int']>;
  inTransitQuantity?: Maybe<Scalars['Int']>;
  reservedQuantity?: Maybe<Scalars['Int']>;
};

/** This returns paginated manufacturers */
export type ManufacturerConnection = {
  __typename?: 'ManufacturerConnection';
  edges?: Maybe<Array<ManufacturerEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ManufacturerEdge = {
  __typename?: 'ManufacturerEdge';
  node: ManufacturerSchema;
};

/** Manufacturer filters */
export type ManufacturerFilters = {
  _and?: InputMaybe<Array<ManufacturerFilters>>;
  _or?: InputMaybe<Array<ManufacturerFilters>>;
  manufacturerIds?: InputMaybe<Array<Scalars['String']>>;
  names?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ManufacturerStatusEnum>>;
};

/** manufacturer entity. */
export type ManufacturerSchema = {
  __typename?: 'ManufacturerSchema';
  createdAt: Scalars['String'];
  /** Returns createdBy user of manufacturer */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of manufacturer */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export const enum ManufacturerStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export type MarkMoveTransactionsCompleteInput = {
  transactionIds: Array<Scalars['String']>;
};

export type MarkShippingTransactionsOfContainerIdsAsDeliveredInput = {
  containerIds: Array<Scalars['String']>;
  deliveryNote?: InputMaybe<Scalars['String']>;
  deliveryType?: InputMaybe<ShippingTransactionDeliveryTypeEnum>;
  deliveryVerificationRecord?: InputMaybe<ShippingTransactionVerificationInputType>;
};

export type MarkShippingTransactionsOfContainerIdsAsOutForDeliveryInput = {
  containerIds: Array<Scalars['String']>;
};

export type MarkShippingTransactionsOfTrackingIdsAsDeliveredInput = {
  trackingIds: Array<Scalars['String']>;
};

export const enum MediaKindEnum {
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
  File = 'FILE',
  Folder = 'FOLDER',
  Image = 'IMAGE',
  Video = 'VIDEO',
  WebLink = 'WEB_LINK',
  YoutubeLink = 'YOUTUBE_LINK'
};

/** General Media Object, can be used with any entity */
export type MediaSchema = {
  __typename?: 'MediaSchema';
  /** Information regarding Media category(Image, video, audio, document) */
  kind: MediaKindEnum;
  /** media type of uploaded entity */
  mimeType?: Maybe<Scalars['String']>;
  resolutions: Array<ResolutionType>;
  /** Size of the attachment in kbs */
  size?: Maybe<Scalars['Int']>;
  /** url of the attachment stored on our end */
  url?: Maybe<Scalars['String']>;
};

export type Memory = {
  active?: InputMaybe<Scalars['Float']>;
  available?: InputMaybe<Scalars['Float']>;
  buffcache?: InputMaybe<Scalars['Float']>;
  buffers?: InputMaybe<Scalars['Float']>;
  cached?: InputMaybe<Scalars['Float']>;
  free?: InputMaybe<Scalars['Float']>;
  slab?: InputMaybe<Scalars['Float']>;
  swapfree?: InputMaybe<Scalars['Float']>;
  swaptotal?: InputMaybe<Scalars['Float']>;
  swapused?: InputMaybe<Scalars['Float']>;
  total?: InputMaybe<Scalars['Float']>;
  used?: InputMaybe<Scalars['Float']>;
};

/** Memory Schema */
export type MemorySchema = {
  __typename?: 'MemorySchema';
  active?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Float']>;
  buffcache?: Maybe<Scalars['Float']>;
  buffers?: Maybe<Scalars['Float']>;
  cached?: Maybe<Scalars['Float']>;
  free?: Maybe<Scalars['Float']>;
  slab?: Maybe<Scalars['Float']>;
  swapfree?: Maybe<Scalars['Float']>;
  swaptotal?: Maybe<Scalars['Float']>;
  swapused?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  used?: Maybe<Scalars['Float']>;
};

export type ModuleVisibilityConfigInputType = {
  admin: TenantFeatureTypeEnum;
  assets: TenantFeatureTypeEnum;
  contacts: TenantFeatureTypeEnum;
  inventory: TenantFeatureTypeEnum;
  itAsset: TenantFeatureTypeEnum;
  orderRequest: TenantFeatureTypeEnum;
  receiving: TenantFeatureTypeEnum;
  report: TenantFeatureTypeEnum;
  shipping: TenantFeatureTypeEnum;
  shop: TenantFeatureTypeEnum;
  wishlist: TenantFeatureTypeEnum;
};

/** Tenant Feature Configs */
export type ModuleVisibilityConfigType = {
  __typename?: 'ModuleVisibilityConfigType';
  admin: TenantFeatureTypeEnum;
  assets: TenantFeatureTypeEnum;
  contacts: TenantFeatureTypeEnum;
  inventory: TenantFeatureTypeEnum;
  itAsset: TenantFeatureTypeEnum;
  orderRequest: TenantFeatureTypeEnum;
  receiving: TenantFeatureTypeEnum;
  report: TenantFeatureTypeEnum;
  shipping: TenantFeatureTypeEnum;
  shop: TenantFeatureTypeEnum;
  wishlist: TenantFeatureTypeEnum;
};

export type MotherBoard = {
  assetTag?: InputMaybe<Scalars['String']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** MotherBoard Schema */
export type MotherBoardSchema = {
  __typename?: 'MotherBoardSchema';
  assetTag?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type MoveTransactionEntityInput = {
  billToSiteId?: InputMaybe<Scalars['String']>;
  containerId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  meta?: InputMaybe<MoveTransactionMetaInput>;
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  sourceLocationId: Scalars['String'];
  sourceSiteId: Scalars['String'];
};

export type MoveTransactionMetaInput = {
  orderRequestId?: InputMaybe<Scalars['String']>;
  orderRequestItemId?: InputMaybe<Scalars['String']>;
  shippingTransactionId?: InputMaybe<Scalars['String']>;
  trackingIdInPartnerTenant?: InputMaybe<Scalars['String']>;
};

export const enum MoveTransactionSubTypeEnum {
  Checkin = 'CHECKIN',
  Checkout = 'CHECKOUT',
  ExternalOrderIn = 'EXTERNAL_ORDER_IN',
  Move = 'MOVE',
  OrderShip = 'ORDER_SHIP',
  Ship = 'SHIP'
};

export type Mutation = {
  __typename?: 'Mutation';
  addAislesInWarehouse: WarehouseMutationPayload;
  addBinsInWarehouse: WarehouseMutationPayload;
  addRacksInWarehouse: WarehouseMutationPayload;
  addShelvesInWarehouse: WarehouseMutationPayload;
  addZonesInWarehouse: WarehouseMutationPayload;
  blockOrderRequest: MutationResponse;
  bulkImportNetworkAssets: MutationResponse;
  cancelOrderRequest: CancelOrderRequestPayload;
  closeOrderRequest: CloseOrderRequestPayload;
  createAddress: CreateAddressPayload;
  createAffiliateConfiguration: CreateAffiliateConfigurationPayload;
  createAssetItem: CreateAssetItemPayload;
  createAssetItemReminder: CreateAssetItemReminderPayload;
  createAssetKitItem: CreateAssetKitItemPayload;
  createAssetModelItem: CreateAssetModelItemPayload;
  createAuditTransactions: CreateTransactionsPayload;
  createCategory: CreateCategoryPayload;
  createCheckInTransaction: CreateTransactionsPayload;
  createCheckOutTransactions: CreateTransactionsPayload;
  createCompany: CreateCompanyPayload;
  createContact: CreateContactPayload;
  createDepartment: CreateDepartmentPayload;
  createExternalOrderRequest: CreateOrderRequestPayload;
  createImportJob: CreateJobPayload;
  createInventoryItem: CreateInventoryItemPayload;
  createInventoryKitItem: CreateInventoryKitItemPayload;
  createJob: CreateJobPayload;
  createKitRemoveTransactions: CreateTransactionsPayload;
  createLanNetworkAsset: CreateNetworkAssetPayload;
  createLocation: CreateLocationPayload;
  createManufacturer: CreateManufacturerPayload;
  createMoveTransactions: CreateTransactionsPayload;
  createNetworkAsset: CreateNetworkAssetPayload;
  createOrderRequest: CreateOrderRequestPayload;
  createPartnerTenant: CreatePartnerTenantPayload;
  createPickList: MutationResponse;
  createProject: CreateProjectPayload;
  createReceivables: ReceivablesPayload;
  createRemoveTransactions: CreateTransactionsPayload;
  createReserveTransactions: CreateTransactionsPayload;
  createRestockTransactions: CreateTransactionsPayload;
  createRole: CreateRolePayload;
  createShippingContainer: CreateShippingContainersPayload;
  createShippingTransactionsBasedOnContainerIntoContainer: CreateShippingTransactionsPayload;
  createShippingTransactionsBasedOnInventoryMoveTransaction: CreateShippingTransactionsPayload;
  createTenant: CreateTenantPayload;
  createUser: CreateUserPayload;
  createWarehouse: WarehouseMutationPayload;
  createWishlist: CreateWishlistPayload;
  deleteAddresses: MutationResponse;
  deleteAffiliateConfigurations: MutationResponse;
  deleteAssetItemReminder: MutationResponse;
  deleteAssetItems: MutationResponse;
  deleteAssetKitItem: MutationResponse;
  deleteAssetModelItems: MutationResponse;
  deleteCategories: MutationResponse;
  deleteCompanies: MutationResponse;
  deleteContacts: MutationResponse;
  deleteDepartments: MutationResponse;
  deleteInTransitTransactions: MutationResponse;
  deleteInventoryItems: MutationResponse;
  deleteInventoryKitItem: MutationResponse;
  deleteLocations: MutationResponse;
  deleteManufacturers: MutationResponse;
  deleteNetworkAssets: MutationResponse;
  deleteOrderRequest: MutationResponse;
  deleteOrderRequests: MutationResponse;
  deletePartnerTenant: MutationResponse;
  deleteProjects: MutationResponse;
  deleteReceivables: MutationResponse;
  deleteRoles: MutationResponse;
  deleteShippingContainers: MutationResponse;
  deleteTenant: MutationResponse;
  deleteUsers: MutationResponse;
  deleteWarehouses: MutationResponse;
  deleteWishlists: MutationResponse;
  editWarehouse: WarehouseMutationPayload;
  getJob: GetJobPayload;
  invitePartnerTenant: InvitePartnerTenantPayload;
  markMoveTransactionsComplete: MutationResponse;
  markShippingTransactionsOfContainerIdsAsDelivered: MutationResponse;
  markShippingTransactionsOfContainerIdsAsOutForDelivery: MutationResponse;
  preprocessImportJob: CreateJobPreprocessorPayload;
  receiveItems: ReceivablesPayload;
  resendReturnedOrderRequestItemEmail: MutationResponse;
  returnOrderRequestItems: MutationResponse;
  rollupQuantitiesFromLocationsOfItemLocations: MutationResponse;
  unblockOrderRequest: MutationResponse;
  undeleteAssetItems: UndeleteItemsPayload;
  undeleteInventoryItems: UndeleteItemsPayload;
  undeleteUsers: MutationResponse;
  unpackShippingTransactionsOfContainerId: MutationResponse;
  unpackShippingTransactionsOfTrackingIds: MutationResponse;
  unreserveTransactions: MutationResponse;
  updateAddress: UpdateAddressPayload;
  updateAffiliateConfiguration: UpdateAffiliateConfigurationPayload;
  updateAllBellNotificationsStatusOfUser: MutationResponse;
  updateAssetItem: UpdateAssetItemPayload;
  updateAssetItemReminder: UpdateAssetItemReminderPayload;
  updateAssetItems: MutationResponse;
  updateAssetKitItem: UpdateAssetKitItemPayload;
  updateAssetKitItems: MutationResponse;
  updateAssetModelItem: UpdateAssetModelItemPayload;
  updateBellNotificationsStatus: UpdateBellNotificationsStatusPayload;
  updateCategory: UpdateCategoryPayload;
  updateCompany: UpdateCompanyPayload;
  updateContact: UpdateContactPayload;
  updateDepartment: UpdateDepartmentPayload;
  updateEmailId: UpdateUserPayload;
  updateExternalOrderRequestFromChildTenant: UpdateOrderRequestPayload;
  updateExternalOrderRequestFromParentTenant: UpdateOrderRequestPayload;
  updateInventoryItem: UpdateInventoryItemPayload;
  updateInventoryItems: MutationResponse;
  updateInventoryKitItem: UpdateInventoryKitItemPayload;
  updateInventoryKitItems: MutationResponse;
  updateJob: MutationResponse;
  updateLocation: UpdateLocationPayload;
  updateManufacturer: UpdateManufacturerPayload;
  updateOrderRequest: UpdateOrderRequestPayload;
  updateOrderRequestItemStatusByOrderRequestId: MutationResponse;
  updatePartnerTenants: MutationResponse;
  updatePassword: UpdateUserPayload;
  updateProductQuantityInWishlist: UpdateWishlistPayload;
  updateProject: UpdateProjectPayload;
  updateQuantityConfigurationsAtItemLocations: MutationResponse;
  updateReceivable: ReceivablesPayload;
  updateReserveTransactions: UpdateTransactionsPayload;
  updateRole: UpdateRolePayload;
  updateTenant: UpdateTenantPayload;
  updateUser: UpdateUserPayload;
  updateWarehouse: WarehouseMutationPayload;
  updateWishlist: UpdateWishlistPayload;
  verifyWishlistCollaborateInvitation: MutationResponse;
};


export type MutationAddAislesInWarehouseArgs = {
  input: AddAislesInWarehouseInput;
};


export type MutationAddBinsInWarehouseArgs = {
  input: AddBinsInWarehouseInput;
};


export type MutationAddRacksInWarehouseArgs = {
  input: AddRacksInWarehouseInput;
};


export type MutationAddShelvesInWarehouseArgs = {
  input: AddShelvesInWarehouseInput;
};


export type MutationAddZonesInWarehouseArgs = {
  input: AddZonesInWarehouseInput;
};


export type MutationBlockOrderRequestArgs = {
  input: BlockOrderRequestInput;
};


export type MutationBulkImportNetworkAssetsArgs = {
  input: BulkImportNetworkAssetInput;
};


export type MutationCancelOrderRequestArgs = {
  input: CancelOrderRequestInput;
};


export type MutationCloseOrderRequestArgs = {
  input: CloseOrderRequestInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateAffiliateConfigurationArgs = {
  input: CreateAffiliateConfigurationInput;
};


export type MutationCreateAssetItemArgs = {
  input: CreateAssetItemInput;
};


export type MutationCreateAssetItemReminderArgs = {
  input: CreateAssetItemReminderInput;
};


export type MutationCreateAssetKitItemArgs = {
  input: CreateAssetKitItemInput;
};


export type MutationCreateAssetModelItemArgs = {
  input: CreateAssetModelItemInput;
};


export type MutationCreateAuditTransactionsArgs = {
  input: CreateAuditTransactionsInput;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateCheckInTransactionArgs = {
  input: CreateCheckInTransactionInput;
};


export type MutationCreateCheckOutTransactionsArgs = {
  input: CreateCheckOutTransactionsInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateDepartmentArgs = {
  input: CreateDepartmentInput;
};


export type MutationCreateExternalOrderRequestArgs = {
  input: CreateExternalOrderRequestInput;
};


export type MutationCreateImportJobArgs = {
  input: CreateImportJobInput;
};


export type MutationCreateInventoryItemArgs = {
  input: CreateInventoryItemInput;
};


export type MutationCreateInventoryKitItemArgs = {
  input: CreateInventoryKitItemInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateKitRemoveTransactionsArgs = {
  input: CreateKitRemoveTransactionsInput;
};


export type MutationCreateLanNetworkAssetArgs = {
  input: CreateLanNetworkAssetInputs;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateManufacturerArgs = {
  input: CreateManufacturerInput;
};


export type MutationCreateMoveTransactionsArgs = {
  input: CreateMoveTransactionsInput;
};


export type MutationCreateNetworkAssetArgs = {
  input: CreateNetworkAssetInput;
};


export type MutationCreateOrderRequestArgs = {
  input: CreateOrderRequestInput;
};


export type MutationCreatePartnerTenantArgs = {
  input: CreatePartnerTenantInput;
};


export type MutationCreatePickListArgs = {
  input: CreatePickListInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateReceivablesArgs = {
  input: CreateReceivablesInput;
};


export type MutationCreateRemoveTransactionsArgs = {
  input: CreateRemoveTransactionsInput;
};


export type MutationCreateReserveTransactionsArgs = {
  input: CreateReserveTransactionsInput;
};


export type MutationCreateRestockTransactionsArgs = {
  input: CreateRestockTransactionsInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateShippingContainerArgs = {
  input: CreateShippingContainersInput;
};


export type MutationCreateShippingTransactionsBasedOnContainerIntoContainerArgs = {
  input: CreateShippingTransactionsBasedOnContainerIntoContainerInputs;
};


export type MutationCreateShippingTransactionsBasedOnInventoryMoveTransactionArgs = {
  input: CreateShippingTransactionsBasedOnInventoryMoveTransactionInput;
};


export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateWarehouseArgs = {
  input: CreateWarehouseInput;
};


export type MutationCreateWishlistArgs = {
  input: CreateWishlistInput;
};


export type MutationDeleteAddressesArgs = {
  input: DeleteAddressesInput;
};


export type MutationDeleteAffiliateConfigurationsArgs = {
  input: DeleteAffiliateConfigurationsInput;
};


export type MutationDeleteAssetItemReminderArgs = {
  input: DeleteAssetItemReminderInput;
};


export type MutationDeleteAssetItemsArgs = {
  input: DeleteItemsInput;
};


export type MutationDeleteAssetKitItemArgs = {
  input: DeleteAssetKitItemInput;
};


export type MutationDeleteAssetModelItemsArgs = {
  input: DeleteItemsInput;
};


export type MutationDeleteCategoriesArgs = {
  input: DeleteCategoriesInput;
};


export type MutationDeleteCompaniesArgs = {
  input: DeleteCompaniesInput;
};


export type MutationDeleteContactsArgs = {
  input: DeleteContactsInput;
};


export type MutationDeleteDepartmentsArgs = {
  input: DeleteDepartmentsInput;
};


export type MutationDeleteInTransitTransactionsArgs = {
  input: DeleteInTransitTransactionsInput;
};


export type MutationDeleteInventoryItemsArgs = {
  input: DeleteItemsInput;
};


export type MutationDeleteInventoryKitItemArgs = {
  input: DeleteInventoryKitItemInput;
};


export type MutationDeleteLocationsArgs = {
  input: DeleteLocationsInput;
};


export type MutationDeleteManufacturersArgs = {
  input: DeleteManufacturersInput;
};


export type MutationDeleteNetworkAssetsArgs = {
  input: DeleteNetworkAssetInput;
};


export type MutationDeleteOrderRequestArgs = {
  input: DeleteOrderRequestInput;
};


export type MutationDeleteOrderRequestsArgs = {
  input: DeleteOrderRequestsInput;
};


export type MutationDeletePartnerTenantArgs = {
  input: DeletePartnerTenantsInput;
};


export type MutationDeleteProjectsArgs = {
  input: DeleteProjectsInput;
};


export type MutationDeleteReceivablesArgs = {
  input: DeleteReceivablesInput;
};


export type MutationDeleteRolesArgs = {
  input: DeleteRolesInput;
};


export type MutationDeleteShippingContainersArgs = {
  input: UpdateStatusOfShippingContainersInput;
};


export type MutationDeleteTenantArgs = {
  input: DeleteTenantInput;
};


export type MutationDeleteUsersArgs = {
  input: DeleteUsersInput;
};


export type MutationDeleteWarehousesArgs = {
  input: DeleteWarehouseInput;
};


export type MutationDeleteWishlistsArgs = {
  input: DeleteWishlistsInput;
};


export type MutationEditWarehouseArgs = {
  input: EditWarehouseInput;
};


export type MutationGetJobArgs = {
  input: GetJobInput;
};


export type MutationInvitePartnerTenantArgs = {
  input: InvitePartnerTenantInput;
};


export type MutationMarkMoveTransactionsCompleteArgs = {
  input: MarkMoveTransactionsCompleteInput;
};


export type MutationMarkShippingTransactionsOfContainerIdsAsDeliveredArgs = {
  input: MarkShippingTransactionsOfContainerIdsAsDeliveredInput;
};


export type MutationMarkShippingTransactionsOfContainerIdsAsOutForDeliveryArgs = {
  input: MarkShippingTransactionsOfContainerIdsAsOutForDeliveryInput;
};


export type MutationPreprocessImportJobArgs = {
  input: PreprocessImportJobInput;
};


export type MutationReceiveItemsArgs = {
  input: ReceiveItemsInput;
};


export type MutationResendReturnedOrderRequestItemEmailArgs = {
  input: ResendReturnedOrderRequestItemEmailInput;
};


export type MutationReturnOrderRequestItemsArgs = {
  input: ReturnOrderRequestItemsInput;
};


export type MutationRollupQuantitiesFromLocationsOfItemLocationsArgs = {
  inputs: Array<RollupQuantitiesFromLocationsOfItemLocationInput>;
};


export type MutationUnblockOrderRequestArgs = {
  input: UnblockOrderRequestInput;
};


export type MutationUndeleteAssetItemsArgs = {
  input: UndeleteItemsInput;
};


export type MutationUndeleteInventoryItemsArgs = {
  input: UndeleteItemsInput;
};


export type MutationUndeleteUsersArgs = {
  input: UndeleteUsersInput;
};


export type MutationUnpackShippingTransactionsOfContainerIdArgs = {
  input: UnpackShippingTransactionsOfContainerIdInput;
};


export type MutationUnpackShippingTransactionsOfTrackingIdsArgs = {
  input: UnpackShippingTransactionsOfTrackingIdsInput;
};


export type MutationUnreserveTransactionsArgs = {
  input: UnreserveTransactionsInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAffiliateConfigurationArgs = {
  input: UpdateAffiliateConfigurationInput;
};


export type MutationUpdateAllBellNotificationsStatusOfUserArgs = {
  input: UpdateAllBellNotificationsStatusOfUserInput;
};


export type MutationUpdateAssetItemArgs = {
  input: UpdateAssetItemInput;
};


export type MutationUpdateAssetItemReminderArgs = {
  input: UpdateAssetItemReminderInput;
};


export type MutationUpdateAssetItemsArgs = {
  input: UpdateAssetItemsInput;
};


export type MutationUpdateAssetKitItemArgs = {
  input: UpdateAssetKitItemInput;
};


export type MutationUpdateAssetKitItemsArgs = {
  input: UpdateAssetKitItemsInput;
};


export type MutationUpdateAssetModelItemArgs = {
  input: UpdateAssetModelItemInput;
};


export type MutationUpdateBellNotificationsStatusArgs = {
  input: UpdateBellNotificationsStatusInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateDepartmentArgs = {
  input: UpdateDepartmentInput;
};


export type MutationUpdateEmailIdArgs = {
  input: UpdateEmailIdInput;
};


export type MutationUpdateExternalOrderRequestFromChildTenantArgs = {
  input: UpdateExternalOrderRequestFromChildTenantInput;
};


export type MutationUpdateExternalOrderRequestFromParentTenantArgs = {
  input: UpdateExternalOrderRequestFromParentTenantInput;
};


export type MutationUpdateInventoryItemArgs = {
  input: UpdateInventoryItemInput;
};


export type MutationUpdateInventoryItemsArgs = {
  input: UpdateInventoryItemsInput;
};


export type MutationUpdateInventoryKitItemArgs = {
  input: UpdateInventoryKitItemInput;
};


export type MutationUpdateInventoryKitItemsArgs = {
  input: UpdateInventoryKitItemsInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateManufacturerArgs = {
  input: UpdateManufacturerInput;
};


export type MutationUpdateOrderRequestArgs = {
  input: UpdateOrderRequestInput;
};


export type MutationUpdateOrderRequestItemStatusByOrderRequestIdArgs = {
  input: UpdateOrderRequestItemStatusByOrderRequestIdInput;
};


export type MutationUpdatePartnerTenantsArgs = {
  inputs: Array<UpdatePartnerTenantInput>;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateProductQuantityInWishlistArgs = {
  input: UpdateProductQuantityInWishlistInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationUpdateQuantityConfigurationsAtItemLocationsArgs = {
  input: Array<UpdateQuantityConfigurationsAtItemLocationInput>;
};


export type MutationUpdateReceivableArgs = {
  input: UpdateReceivableInput;
};


export type MutationUpdateReserveTransactionsArgs = {
  input: UpdateReserveTransactionsInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateWarehouseArgs = {
  input: UpdateWarehouseInput;
};


export type MutationUpdateWishlistArgs = {
  input: UpdateWishlistInput;
};


export type MutationVerifyWishlistCollaborateInvitationArgs = {
  input: VerifyWishlistCollaborationInput;
};

/** Mutation Response */
export type MutationResponse = {
  __typename?: 'MutationResponse';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Network = {
  default?: InputMaybe<Scalars['Boolean']>;
  dhcp?: InputMaybe<Scalars['Boolean']>;
  duplex?: InputMaybe<Scalars['String']>;
  iface?: InputMaybe<Scalars['String']>;
  ifaceName?: InputMaybe<Scalars['String']>;
  internal?: InputMaybe<Scalars['Boolean']>;
  ip4?: InputMaybe<Scalars['String']>;
  ip4subnet?: InputMaybe<Scalars['String']>;
  ip6?: InputMaybe<Scalars['String']>;
  ip6subnet?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  mtu?: InputMaybe<Scalars['Int']>;
  operstate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  virtual?: InputMaybe<Scalars['Boolean']>;
};

/** This returns paginated NetworkAssets */
export type NetworkAssetConnection = {
  __typename?: 'NetworkAssetConnection';
  edges: Array<NetworkAssetEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type NetworkAssetEdge = {
  __typename?: 'NetworkAssetEdge';
  node: NetworkAssetSchema;
};

/** Network assets schema */
export type NetworkAssetSchema = {
  __typename?: 'NetworkAssetSchema';
  battery?: Maybe<BatterySchema>;
  bios?: Maybe<BiosSchema>;
  cpu?: Maybe<CpuSchema>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of network Asset */
  createdBy: UserSchema;
  createdById?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  deviceOwnerId?: Maybe<Scalars['String']>;
  /** Disk schema */
  disk?: Maybe<Array<DiskSchema>>;
  display?: Maybe<Array<DisplaySchema>>;
  email?: Maybe<Scalars['String']>;
  graphics?: Maybe<Array<GraphicsSchema>>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  ipOctetNumeric?: Maybe<Scalars['Float']>;
  /** Returns location of network asset */
  location?: Maybe<LocationSchema>;
  locationId?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  memory?: Maybe<MemorySchema>;
  modelNumber?: Maybe<Scalars['String']>;
  motherboard?: Maybe<MotherBoardSchema>;
  /** Network schema */
  network?: Maybe<Array<NetworkSchema>>;
  note?: Maybe<NoteSchema>;
  operatingSystem?: Maybe<OsSchema>;
  /** Port schema */
  port?: Maybe<Array<PortSchema>>;
  process?: Maybe<ProcessSchema>;
  serialNumber?: Maybe<Scalars['String']>;
  /** Returns site of network asset */
  site?: Maybe<LocationSchema>;
  siteId?: Maybe<Scalars['String']>;
  /** Disk schema */
  software?: Maybe<Array<Scalars['String']>>;
  status: NetworkAssetStatusEnum;
  /** Port schema */
  storage?: Maybe<Array<StorageSchema>>;
  system?: Maybe<SystemSchema>;
  tenantId?: Maybe<Scalars['String']>;
  type?: Maybe<NetworkAssetTypeEnum>;
  updatedAt: Scalars['String'];
  updatedById?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  uuid?: Maybe<UuidSchema>;
  vendor?: Maybe<Scalars['String']>;
  warranty?: Maybe<WarrantySchema>;
  /** Returns warrantyStatus of networkAsset */
  warrantyStatus: Scalars['String'];
};

export const enum NetworkAssetStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Notinstalled = 'NOTINSTALLED'
};

export const enum NetworkAssetTypeEnum {
  Desktop = 'DESKTOP',
  Ipad = 'IPAD',
  Iphone = 'IPHONE',
  Laptop = 'LAPTOP',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Phone = 'PHONE',
  Tablet = 'TABLET'
};

export const enum NetworkAssetWarrantyTypeEnum {
  Expired = 'EXPIRED',
  ExpiringSoon = 'EXPIRING_SOON',
  InWarranty = 'IN_WARRANTY',
  NoWarranty = 'NO_WARRANTY'
};

/** NetworkAssetsDistictValueSchema */
export type NetworkAssetsDistictValueSchema = {
  __typename?: 'NetworkAssetsDistictValueSchema';
  batteryManufacturer?: Maybe<Array<DistictValueSchema>>;
  biosReleaseDate?: Maybe<Array<DistictValueSchema>>;
  biosVersion?: Maybe<Array<DistictValueSchema>>;
  cpuBrand?: Maybe<Array<DistictValueSchema>>;
  cpuManufacturer?: Maybe<Array<DistictValueSchema>>;
  graphicsModel?: Maybe<Array<DistictValueSchema>>;
  location?: Maybe<Array<DistictValueSchema>>;
  motherboardModel?: Maybe<Array<DistictValueSchema>>;
  operatingSystem?: Maybe<Array<DistictValueSchema>>;
  operatingSystemPlatform?: Maybe<Array<DistictValueSchema>>;
  software?: Maybe<Array<DistictValueSchema>>;
  storageName?: Maybe<Array<DistictValueSchema>>;
  systemManufacturer?: Maybe<Array<DistictValueSchema>>;
  systemModel?: Maybe<Array<DistictValueSchema>>;
  updatedById?: Maybe<Array<DistictValueSchema>>;
};

/** Network Assets filters */
export type NetworkAssetsDistinctValueFilters = {
  statuses?: InputMaybe<Array<NetworkAssetStatusEnum>>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** Network Assets filters */
export type NetworkAssetsFilters = {
  batteryManufacturer?: InputMaybe<Array<Scalars['String']>>;
  biosReleaseDate?: InputMaybe<Array<Scalars['String']>>;
  biosVersion?: InputMaybe<Array<Scalars['String']>>;
  cpuBrand?: InputMaybe<Array<Scalars['String']>>;
  cpuManufacturer?: InputMaybe<Array<Scalars['String']>>;
  emailIds?: InputMaybe<Array<Scalars['String']>>;
  graphicsModel?: InputMaybe<Array<Scalars['String']>>;
  mac?: InputMaybe<Array<Scalars['String']>>;
  motherboardModel?: InputMaybe<Array<Scalars['String']>>;
  networkAssetIds?: InputMaybe<Array<Scalars['String']>>;
  operatingSystem?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  siteIds?: InputMaybe<Array<Scalars['String']>>;
  software?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<NetworkAssetStatusEnum>>;
  storageName?: InputMaybe<Array<Scalars['String']>>;
  systemManufacturer?: InputMaybe<Array<Scalars['String']>>;
  systemModel?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<NetworkAssetTypeEnum>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
  warrantyTypes?: InputMaybe<Array<NetworkAssetWarrantyTypeEnum>>;
};

/** Network Assets filters */
export type NetworkAssetsWarrantyFilters = {
  type?: InputMaybe<NetworkAssetTypeEnum>;
};

/** Network Schema */
export type NetworkSchema = {
  __typename?: 'NetworkSchema';
  default?: Maybe<Scalars['Boolean']>;
  dhcp?: Maybe<Scalars['Boolean']>;
  duplex?: Maybe<Scalars['String']>;
  iface?: Maybe<Scalars['String']>;
  ifaceName?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['Boolean']>;
  ip4?: Maybe<Scalars['String']>;
  ip4subnet?: Maybe<Scalars['String']>;
  ip6?: Maybe<Scalars['String']>;
  ip6subnet?: Maybe<Scalars['String']>;
  mac?: Maybe<Scalars['String']>;
  mtu?: Maybe<Scalars['Int']>;
  operstate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};

export type Note = {
  heading?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

/** Note Schema */
export type NoteSchema = {
  __typename?: 'NoteSchema';
  heading?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

/** OS  */
export type OperatingSystem = {
  arch?: InputMaybe<Scalars['String']>;
  build?: InputMaybe<Scalars['String']>;
  codename?: InputMaybe<Scalars['String']>;
  codepage?: InputMaybe<Scalars['String']>;
  distro?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  hostname?: InputMaybe<Scalars['String']>;
  kernel?: InputMaybe<Scalars['String']>;
  logofile?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  release?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
  servicepack?: InputMaybe<Scalars['String']>;
  uefi?: InputMaybe<Scalars['Boolean']>;
};

/** This returns paginated orderRequests */
export type OrderRequestConnection = {
  __typename?: 'OrderRequestConnection';
  edges?: Maybe<Array<OrderRequestEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** OrderRequestEdge */
export type OrderRequestEdge = {
  __typename?: 'OrderRequestEdge';
  node: OrderRequestSchema;
};

/** Applicable filters in getting order requests. */
export type OrderRequestFilters = {
  _and?: InputMaybe<Array<OrderRequestFilters>>;
  _or?: InputMaybe<Array<OrderRequestFilters>>;
  availableAtSiteIds?: InputMaybe<Array<Scalars['String']>>;
  billToSiteIds?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  childTenantIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  deletedAt?: InputMaybe<DateFilter>;
  deliverToIds?: InputMaybe<Array<Scalars['String']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  destinationSiteIds?: InputMaybe<Array<Scalars['String']>>;
  dueDate?: InputMaybe<DateFilter>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  fulfillingSiteIds?: InputMaybe<Array<Scalars['String']>>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  leastItemStatuses?: InputMaybe<Array<OrderRequestItemStatusEnum>>;
  orderRequestIds?: InputMaybe<Array<Scalars['String']>>;
  orderRequestTypes?: InputMaybe<Array<OrderRequestTypeEnum>>;
  parentTenantIds?: InputMaybe<Array<Scalars['String']>>;
  pickableOrders?: InputMaybe<Scalars['Boolean']>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<OrderRequestItemStatusEnum>>;
};

/** This returns paginated orderRequests */
export type OrderRequestItemConnection = {
  __typename?: 'OrderRequestItemConnection';
  edges?: Maybe<Array<OrderRequestItemEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** OrderRequestItemEdge */
export type OrderRequestItemEdge = {
  __typename?: 'OrderRequestItemEdge';
  node: OrderRequestItemSchema;
};

/** Applicable filters in getting order request items. */
export type OrderRequestItemFilters = {
  _and?: InputMaybe<Array<OrderRequestItemFilters>>;
  _or?: InputMaybe<Array<OrderRequestItemFilters>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  entityIdsInSourceTenant?: InputMaybe<Array<Scalars['String']>>;
  entitySources?: InputMaybe<Array<EntitySourceEnum>>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  orderRequestIds?: InputMaybe<Array<Scalars['String']>>;
  orderRequestItemIds?: InputMaybe<Array<Scalars['String']>>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  skus?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<OrderRequestItemStatusEnum>>;
  trackingIds?: InputMaybe<Array<Scalars['String']>>;
  trackingIdsInParentTenant?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<OrderRequestItemTypeEnum>>;
};

export type OrderRequestItemNonRemovableNoteInput = {
  estimatedDeliveryDate?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type OrderRequestItemNonRemovableNoteSchema = {
  __typename?: 'OrderRequestItemNonRemovableNoteSchema';
  estimatedDeliveryDate?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type OrderRequestItemSchema = {
  __typename?: 'OrderRequestItemSchema';
  cost?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of orderRequestItem */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: Scalars['String'];
  /** Returns fromSite of orderRequestItem */
  fromSite?: Maybe<LocationSchema>;
  fromSiteId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  /** Returns item of orderRequest */
  item?: Maybe<ItemUnion>;
  itemId?: Maybe<Scalars['String']>;
  itemIdInPartnerTenant?: Maybe<Scalars['String']>;
  nonRemovableNotes?: Maybe<Array<OrderRequestItemNonRemovableNoteSchema>>;
  note?: Maybe<Scalars['String']>;
  /** OrderRequest of orderRequestItem */
  orderRequest?: Maybe<OrderRequestSchema>;
  orderRequestId: Scalars['String'];
  parentOrderRequestItemId?: Maybe<Scalars['String']>;
  /** Returns project of orderRequestItem */
  project?: Maybe<ProjectSchema>;
  projectId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  status: OrderRequestItemStatusEnum;
  statusHistory: Array<OrderRequestItemStatusHistorySchema>;
  tenantId: Scalars['String'];
  trackingDetails: Array<OrderRequestItemTrackingDetailsSchema>;
  trackingHistory: Array<OrderRequestItemTrackingDetailsSchema>;
  transactionDetails: Array<OrderRequestItemTransactionDetailsSchema>;
  transactionHistory: Array<OrderRequestItemTransactionDetailsSchema>;
  type: OrderRequestItemTypeEnum;
  upcCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of orderRequestItem */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export const enum OrderRequestItemStatusEnum {
  BackOrdered = 'BACK_ORDERED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Delivered = 'DELIVERED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Packed = 'PACKED',
  Returned = 'RETURNED',
  ReturnOutForDelivery = 'RETURN_OUT_FOR_DELIVERY',
  ReturnPacked = 'RETURN_PACKED'
};

export type OrderRequestItemStatusHistorySchema = {
  __typename?: 'OrderRequestItemStatusHistorySchema';
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OrderRequestItemTrackingDetailsSchema = {
  __typename?: 'OrderRequestItemTrackingDetailsSchema';
  locationId: Scalars['String'];
  quantity: Scalars['String'];
  status: Scalars['String'];
  trackingId: Scalars['String'];
};

export type OrderRequestItemTransactionDetailsSchema = {
  __typename?: 'OrderRequestItemTransactionDetailsSchema';
  quantity: Scalars['String'];
  status: Scalars['String'];
  trackingIdInPartnerTenant: Scalars['String'];
  transactionId: Scalars['String'];
};

export const enum OrderRequestItemTypeEnum {
  Asset = 'ASSET',
  AssetKit = 'ASSET_KIT',
  Inventory = 'INVENTORY',
  InventoryKit = 'INVENTORY_KIT',
  NoSku = 'NO_SKU'
};

/** Order request entity. */
export type OrderRequestSchema = {
  __typename?: 'OrderRequestSchema';
  availableAtSiteIds?: Maybe<Array<Scalars['String']>>;
  /** Returns billToSite of orderRequest */
  billToSite: LocationSchema;
  billToSiteId: Scalars['String'];
  /** Returns childTenant of orderRequest */
  childTenant?: Maybe<TenantSchema>;
  childTenantId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of orderRequest */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  /** Returns deliverTo user of orderRequest */
  deliverTo: UserSchema;
  deliverToId?: Maybe<Scalars['String']>;
  /** Returns department of item */
  department?: Maybe<DepartmentSchema>;
  departmentId?: Maybe<Scalars['String']>;
  /** Returns destinationSite of orderRequest */
  destinationSite: LocationSchema;
  destinationSiteId: Scalars['String'];
  dueDate: Scalars['String'];
  entityIdInSourceTenant?: Maybe<Scalars['String']>;
  entitySource: EntitySourceEnum;
  /** Returns fulfillingSites of orderRequest */
  fulfillingSites: Array<LocationSchema>;
  id: Scalars['String'];
  /** Returns lastStatusUpdatedAt of the items */
  lastStatusUpdatedAt: Scalars['String'];
  leastItemStatus: OrderRequestItemStatusEnum;
  orderRequestCode: Scalars['String'];
  /** Returns orderRequestItems of orderRequest */
  orderRequestItems: Array<OrderRequestItemSchema>;
  /** Returns parentTenant of orderRequest */
  parentTenant?: Maybe<TenantSchema>;
  parentTenantId?: Maybe<Scalars['String']>;
  /** Returns receiving orderRequestItems of orderRequest */
  receivingOrderRequestItems: ReceivableOrderRequestItemsPayload;
  status: OrderRequestItemStatusEnum;
  tenantId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  type?: Maybe<OrderRequestTypeEnum>;
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of orderRequest */
  updatedBy: UserSchema;
  updatedById?: Maybe<Scalars['String']>;
};


/** Order request entity. */
export type OrderRequestSchemaOrderRequestItemsArgs = {
  filters?: InputMaybe<OrderRequestItemFilters>;
};


/** Order request entity. */
export type OrderRequestSchemaReceivingOrderRequestItemsArgs = {
  filters?: InputMaybe<OrderRequestItemFilters>;
};

export const enum OrderRequestTypeEnum {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
};

/** OS Schema */
export type OsSchema = {
  __typename?: 'OsSchema';
  arch?: Maybe<Scalars['String']>;
  build?: Maybe<Scalars['String']>;
  codename?: Maybe<Scalars['String']>;
  codepage?: Maybe<Scalars['String']>;
  distro?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  kernel?: Maybe<Scalars['String']>;
  logofile?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  release?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  servicepack?: Maybe<Scalars['String']>;
  uefi?: Maybe<Scalars['Boolean']>;
};

export type PackItemsInShippingContainerInput = {
  containerId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  items: Array<ShippingTransactionInventoryItemInput>;
  sourceSiteId: Scalars['String'];
};

/** PagedInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  nextCursor?: Maybe<Scalars['String']>;
  prevCursor?: Maybe<Scalars['String']>;
};

export const enum PaginationErrorCodeEnum {
  InvalidCursor = 'INVALID_CURSOR'
};

export type PaginationProps = {
  /** This would be a cursor string to fetch next set of values. */
  after?: InputMaybe<Scalars['String']>;
  /** This would be a cursor string to fetch previous set of values. */
  before?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<SortProps>>;
};

/** This returns paginated partnerTenants */
export type PartnerTenantConnection = {
  __typename?: 'PartnerTenantConnection';
  edges: Array<PartnerTenantEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type PartnerTenantEdge = {
  __typename?: 'PartnerTenantEdge';
  node: PartnerTenantSchema;
};

/** PartnerTenant filters */
export type PartnerTenantFilters = {
  _and?: InputMaybe<Array<PartnerTenantFilters>>;
  _or?: InputMaybe<Array<PartnerTenantFilters>>;
  accessibleCategoryIds?: InputMaybe<Array<Scalars['String']>>;
  allowedUserIds?: InputMaybe<Array<Scalars['String']>>;
  childTenantIds?: InputMaybe<Array<Scalars['String']>>;
  parentTenantIds?: InputMaybe<Array<Scalars['String']>>;
  partnerTenantIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<PartnerTenantStatusEnum>>;
};

/** PartnerTenant schema */
export type PartnerTenantSchema = {
  __typename?: 'PartnerTenantSchema';
  /** Returns accessible categories to tenant. */
  accessibleCategories?: Maybe<Array<CategorySchema>>;
  accessibleCategoryIds: Array<Scalars['String']>;
  allowedUserIds: Array<Scalars['String']>;
  /** Returns allowed user of partnerTenant */
  allowedUsers?: Maybe<Array<UserSchema>>;
  /** Returns childTenant of partnerTenant */
  childTenant: TenantSchema;
  childTenantId: Scalars['String'];
  createdAt: Scalars['String'];
  /** Returns createdBy user of partnerTenant */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Returns parentTenant of partnerTenant */
  parentTenant: TenantSchema;
  parentTenantId: Scalars['String'];
  /** Returns type of partner tenant */
  partnerType: PartnerTenantTypeEnum;
  status: PartnerTenantStatusEnum;
  /** Returns tenant of partnerTenant based on current tenant for listing */
  tenant: TenantSchema;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of partnerTenant */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum PartnerTenantStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum PartnerTenantTypeEnum {
  Child = 'CHILD',
  Parent = 'PARENT'
};

export type PermissionDependency = {
  __typename?: 'PermissionDependency';
  dependentOn: Array<Scalars['String']>;
};

/** Permitted Actions and Subjects on an Entity */
export type PermittedOnSchema = {
  __typename?: 'PermittedOnSchema';
  entity: AllowedPermissionsEntityEnum;
  permissions: Array<Scalars['String']>;
};

export type PickListItemSchema = {
  __typename?: 'PickListItemSchema';
  cost: Scalars['Int'];
  locationId: Scalars['String'];
  orderRequestItemId: Scalars['String'];
  quantityPicked: Scalars['Int'];
};

export type PickListSchema = {
  __typename?: 'PickListSchema';
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  deletedAt: Scalars['String'];
  id: Scalars['String'];
  orderRequestId: Scalars['String'];
  pickListItems: Array<PickListItemSchema>;
  siteId: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Port = {
  localAddress?: InputMaybe<Scalars['String']>;
  localPort?: InputMaybe<Scalars['String']>;
  peerAddress?: InputMaybe<Scalars['String']>;
  peerPort?: InputMaybe<Scalars['String']>;
  pid?: InputMaybe<Scalars['Int']>;
  process?: InputMaybe<Scalars['String']>;
  protocol?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Port Schema */
export type PortSchema = {
  __typename?: 'PortSchema';
  localAddress?: Maybe<Scalars['String']>;
  localPort?: Maybe<Scalars['String']>;
  peerAddress?: Maybe<Scalars['String']>;
  peerPort?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['Int']>;
  process?: Maybe<Scalars['String']>;
  protocol?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PreprocessImportJobInput = {
  entityType: ImportEntityEnum;
  url: Scalars['String'];
};

export type Process = {
  all?: InputMaybe<Scalars['Int']>;
  blocked?: InputMaybe<Scalars['Int']>;
  running?: InputMaybe<Scalars['Int']>;
  sleeping?: InputMaybe<Scalars['Int']>;
  unknown?: InputMaybe<Scalars['Int']>;
};

/** Process Schema */
export type ProcessSchema = {
  __typename?: 'ProcessSchema';
  all?: Maybe<Scalars['Int']>;
  blocked?: Maybe<Scalars['Int']>;
  running?: Maybe<Scalars['Int']>;
  sleeping?: Maybe<Scalars['Int']>;
  unknown?: Maybe<Scalars['Int']>;
};

export type ProductDetails = {
  __typename?: 'ProductDetails';
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Product Filters */
export type ProductFilters = {
  globalTradeItemNumbers?: InputMaybe<Array<Scalars['String']>>;
  manufacturer?: InputMaybe<StringFilter>;
  merchantSKUs?: InputMaybe<Array<ProductMerchantSkuFilter>>;
  modelName?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productCode?: InputMaybe<Scalars['String']>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProductIds = {
  __typename?: 'ProductIds';
  asin?: Maybe<Scalars['String']>;
  gtin: Scalars['String'];
  isbn?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
};

/** Product Merchant SKU filter */
export type ProductMerchantSkuFilter = {
  merchant?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
};

export type ProductNetwork = {
  __typename?: 'ProductNetwork';
  click: Scalars['String'];
  lastModified: Scalars['String'];
  lastSyncedOn?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  merchantName: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
};

export type ProductOffer = {
  __typename?: 'ProductOffer';
  availability?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Scalars['String']>;
  network: ProductNetwork;
  price: ProductPrice;
  sku: Scalars['String'];
  urlId: Scalars['String'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  actual: Scalars['Float'];
  bundle?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  current: Scalars['Float'];
  min?: Maybe<Scalars['String']>;
  perUnitPrice?: Maybe<Scalars['Float']>;
  shipping?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
};

export type ProductSchema = {
  __typename?: 'ProductSchema';
  createdAt: Scalars['String'];
  details?: Maybe<ProductDetails>;
  id?: Maybe<Scalars['String']>;
  ids?: Maybe<ProductIds>;
  offerCount?: Maybe<Scalars['Float']>;
  offers?: Maybe<Array<ProductOffer>>;
  updatedAt: Scalars['String'];
};

/** This returns products */
export type ProductsPayload = {
  __typename?: 'ProductsPayload';
  nodes: Array<ProductSchema>;
  totalCount: Scalars['Float'];
};

/** This returns paginated projects */
export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges?: Maybe<Array<ProjectEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  node: ProjectSchema;
};

/** Project filters */
export type ProjectFilters = {
  _and?: InputMaybe<Array<ProjectFilters>>;
  _or?: InputMaybe<Array<ProjectFilters>>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ProjectStatusEnum>>;
};

/** Project schema */
export type ProjectSchema = {
  __typename?: 'ProjectSchema';
  createdAt: Scalars['String'];
  /** Returns createdBy user of project */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  projectCode?: Maybe<Scalars['String']>;
  sqlId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of project */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum ProjectStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

/** QRCode filters */
export type QrCodeFilters = {
  _and?: InputMaybe<Array<QrCodeFilters>>;
  _or?: InputMaybe<Array<QrCodeFilters>>;
  code: Scalars['String'];
};

/** QRCode schema */
export type QrCodeSchema = {
  __typename?: 'QRCodeSchema';
  code: Scalars['String'];
  createdAt: Scalars['String'];
  data: Scalars['String'];
  id: Scalars['String'];
  tenantId: Scalars['String'];
  type: QrCodeTypeEnum;
  updatedAt: Scalars['String'];
};

export const enum QrCodeTypeEnum {
  Container = 'CONTAINER',
  ItemLocation = 'ITEM_LOCATION',
  Location = 'LOCATION'
};

export type Query = {
  __typename?: 'Query';
  addresses: AddressConnection;
  affiliateConfigurations: AffiliateConfigurationConnection;
  allAddresses: Array<AddressSchema>;
  allCompanies: Array<CompanySchema>;
  allContacts: Array<ContactSchema>;
  assetItems: AssetItemConnection;
  assetModelItems: AssetModelItemConnection;
  auditTransactionReports: Array<AuditTransactionReportSchema>;
  bellNotifications: BellNotificationConnection;
  categories: CategoryConnection;
  companies: CompanyConnection;
  contacts: ContactConnection;
  currentUser: UserSchema;
  departments: DepartmentConnection;
  distinctCaches: Array<DistinctCacheSchema>;
  distinctNetworkAssets: DistinctValueNetworkAssetsPayload;
  geoCode: GeoCodeSchema;
  getAllNetworkAssetWarranty: GetAllNetworkAssetsWarrantyPayload;
  getReturnedOrderRequestDetailForNotification: GetReturnedOrderRequestDetailForNotificationPayload;
  inventoryItems: InventoryItemConnection;
  itemlocations: ItemLocationConnection;
  items: ItemConnection;
  job: GetJobPayload;
  jobs: JobConnection;
  locations: LocationConnection;
  manufacturers: ManufacturerConnection;
  networkAssets: NetworkAssetConnection;
  orderRequests: OrderRequestConnection;
  packedShippingContainers: ShippingContainerConnection;
  partnerTenants: PartnerTenantConnection;
  projects: ProjectConnection;
  qrCode: QrCodeSchema;
  receivables: ReceivableConnection;
  roles: RoleConnection;
  shippingContainers: ShippingContainerConnection;
  shippingTransactions: ShippingTransactionConnection;
  tenants: TenantConnection;
  transactions: TransactionConnection;
  user: UserSchema;
  users: UserConnection;
  valuesInEntities: GetValuesInEntitiesPayload;
  valuesInItems: Array<Scalars['String']>;
  warehouses: WarehouseConnection;
  wishlistByShareId: WishlistForPublicApiPayload;
  wishlistValidateShareId: WishlistValidateShareIdPayload;
  wishlists: WishlistConnection;
  workspace: WorkspaceSchema;
};


export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AddressFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryAffiliateConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AffiliateConfigurationFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryAllAddressesArgs = {
  filters?: InputMaybe<AddressFilters>;
};


export type QueryAllCompaniesArgs = {
  filters?: InputMaybe<CompanyFilters>;
};


export type QueryAllContactsArgs = {
  filters?: InputMaybe<ContactFilters>;
};


export type QueryAssetItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AssetItemFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryAssetModelItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AssetModelItemFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryAuditTransactionReportsArgs = {
  filters: AuditTransactionReportFilter;
};


export type QueryBellNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<BellNotificationFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CategoryFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<CompanyFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ContactFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DepartmentFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryDistinctCachesArgs = {
  filters?: InputMaybe<DistinctCacheFilters>;
};


export type QueryDistinctNetworkAssetsArgs = {
  filters?: InputMaybe<NetworkAssetsDistinctValueFilters>;
};


export type QueryGeoCodeArgs = {
  filters: GeoCodeFilters;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryGetAllNetworkAssetWarrantyArgs = {
  filters?: InputMaybe<GetAllNetworkAssetsWarrantyInput>;
};


export type QueryGetReturnedOrderRequestDetailForNotificationArgs = {
  input: GetReturnedOrderRequestDetailForNotificationInput;
};


export type QueryInventoryItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<InventoryItemFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryItemlocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ItemLocationFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ItemFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryJobArgs = {
  filter?: InputMaybe<JobFilter>;
};


export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<LocationFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryManufacturersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ManufacturerFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryNetworkAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<NetworkAssetsFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryOrderRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<OrderRequestFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryPackedShippingContainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ShippingContainerFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryPartnerTenantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<PartnerTenantFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ProjectFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryQrCodeArgs = {
  filters?: InputMaybe<QrCodeFilters>;
};


export type QueryReceivablesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ReceivableFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<RoleFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryShippingContainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ShippingContainerFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryShippingTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ShippingTransactionFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryTenantsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TenantFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TransactionFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<UserFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryValuesInEntitiesArgs = {
  input: GetValuesInEntitiesInput;
};


export type QueryValuesInItemsArgs = {
  field: ItemStringBasedColumnFilterFieldEnum;
  filters?: InputMaybe<ItemFilters>;
};


export type QueryWarehousesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<WarehouseFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};


export type QueryWishlistByShareIdArgs = {
  shareId?: InputMaybe<Scalars['String']>;
};


export type QueryWishlistValidateShareIdArgs = {
  filters?: InputMaybe<WishlistValidateFilters>;
};


export type QueryWishlistsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<WishlistFilters>;
  limit?: InputMaybe<Scalars['Float']>;
  sorts?: InputMaybe<Array<SortProps>>;
};

/** This returns paginated Receiving Transactions */
export type ReceivableConnection = {
  __typename?: 'ReceivableConnection';
  edges?: Maybe<Array<ReceivableEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ReceivableEdge = {
  __typename?: 'ReceivableEdge';
  node: ReceivableSchema;
};

export type ReceivableFilters = {
  deliverToIds?: InputMaybe<Array<Scalars['String']>>;
  dueDate?: InputMaybe<DateFilter>;
  itemIds?: InputMaybe<Array<Scalars['String']>>;
  orderRequestIds?: InputMaybe<Array<Scalars['String']>>;
  parentReceivableIds?: InputMaybe<Array<Scalars['String']>>;
  receivableIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<ReceivableStatusEnum>>;
  types?: InputMaybe<Array<ReceivableTypeEnum>>;
  vendorIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ReceivableHistoryInput = {
  formattedNotes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
};

export type ReceivableHistorySchema = {
  __typename?: 'ReceivableHistorySchema';
  _id: Scalars['String'];
  formattedNotes?: Maybe<Scalars['String']>;
  /** Returns location of Particular Receivable History */
  location?: Maybe<LocationSchema>;
  locationId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  quantityReceived: Scalars['Float'];
  receivedAt: Scalars['String'];
  restockTransactionId?: Maybe<Scalars['String']>;
  /** Returns deliverToSite of Receivable */
  site?: Maybe<LocationSchema>;
  siteId?: Maybe<Scalars['String']>;
};

export const enum ReceivableOrderRequestItemErrorCodeEnum {
  AllItemsReceivedRequest = 'ALL_ITEMS_RECEIVED_REQUEST',
  ZeroOrderedItem = 'ZERO_ORDERED_ITEM'
};

export type ReceivableOrderRequestItemsPayload = {
  __typename?: 'ReceivableOrderRequestItemsPayload';
  errorCode?: Maybe<ReceivableOrderRequestItemErrorCodeEnum>;
  orderRequestItems: Array<OrderRequestItemSchema>;
};

export type ReceivableSchema = {
  __typename?: 'ReceivableSchema';
  carrier?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of Receivable */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  /** Returns deliverToLocation of Receivable */
  deliverToLocation?: Maybe<LocationSchema>;
  deliverToLocationId?: Maybe<Scalars['String']>;
  /** Returns deliverToSite of Receivable */
  deliverToSite?: Maybe<LocationSchema>;
  deliverToSiteId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  formattedNotes?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Returns item of receivable */
  item?: Maybe<ItemUnion>;
  itemId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** OrderRequest of Receivable */
  orderRequest?: Maybe<OrderRequestSchema>;
  orderRequestId?: Maybe<Scalars['String']>;
  /** Returns orderRequestItem of recivables */
  orderRequestItem?: Maybe<OrderRequestItemSchema>;
  orderRequestItemId?: Maybe<Scalars['String']>;
  parentReceivableId?: Maybe<Scalars['String']>;
  quantityOrdered?: Maybe<Scalars['Float']>;
  quantityPending?: Maybe<Scalars['Float']>;
  receivingHistory: Array<ReceivableHistorySchema>;
  status: ReceivableStatusEnum;
  tenantId: Scalars['String'];
  trackingNumber?: Maybe<Scalars['String']>;
  type: ReceivableTypeEnum;
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of Receivable */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  /** Returns vendor of receivable */
  vendor?: Maybe<CompanySchema>;
  vendorId?: Maybe<Scalars['String']>;
};

export const enum ReceivableStatusEnum {
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
};

export const enum ReceivableTypeEnum {
  Item = 'ITEM',
  OrderRequest = 'ORDER_REQUEST'
};

/** Edge */
export type ReceivablesPayload = {
  __typename?: 'ReceivablesPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  receivables: Array<ReceivableSchema>;
  success: Scalars['Boolean'];
};

export type ReceiveItemsInput = {
  receiveItemsConfig: Array<ItemReceivableConfigInput>;
};

export const enum RecipientServiceEnum {
  Inventory = 'INVENTORY',
  OrderRequest = 'ORDER_REQUEST',
  Organization = 'ORGANIZATION',
  Ship = 'SHIP'
};

export type RecurringScheduledJobMetadata = {
  __typename?: 'RecurringScheduledJobMetadata';
  cron: Scalars['String'];
  firstExecutionAt: Scalars['String'];
  lastExecutionAt?: Maybe<Scalars['String']>;
  recurringCount?: Maybe<Scalars['Float']>;
  recurringInterval?: Maybe<Scalars['Float']>;
};

export type RecurringScheduledJobMetadataInput = {
  cron: Scalars['String'];
  firstExecutionAt: Scalars['String'];
  lastExecutionAt?: InputMaybe<Scalars['String']>;
  recurringCount?: InputMaybe<Scalars['Float']>;
  recurringInterval?: InputMaybe<Scalars['Float']>;
};

export type RelativeDateFilter = {
  operator: RelativeDateFilterOperatorEnum;
  /** Timezone is required if the filter is relative type */
  timezone: Scalars['String'];
  unit: RelativeDateFilterUnitEnum;
  value?: InputMaybe<Scalars['Float']>;
};

export const enum RelativeDateFilterOperatorEnum {
  Last = 'LAST',
  Next = 'NEXT',
  This = 'THIS'
};

export const enum RelativeDateFilterUnitEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
};

export type RemoveTransactionEntityInput = {
  entityId: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  meta: RemoveTransactionMetaInput;
  quantity: Scalars['Int'];
  sourceLocationId: Scalars['String'];
  sourceSiteId: Scalars['String'];
};

export type RemoveTransactionMetaInput = {
  formattedReason?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
};

export const enum RemoveTransactionSubTypeEnum {
  Checkin = 'CHECKIN',
  Delete = 'DELETE',
  Remove = 'REMOVE'
};

export type ResendReturnedOrderRequestItemEmailInput = {
  orderRequestId: Scalars['String'];
  orderRequestItemId: Scalars['String'];
};

export type ReserveTransactionEntityInput = {
  actorId: Scalars['String'];
  departmentId?: InputMaybe<Scalars['String']>;
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  meta?: InputMaybe<ReserveTransactionMetaInputInput>;
  quantity: Scalars['Int'];
  startDate: Scalars['String'];
};

export type ReserveTransactionMetaInputInput = {
  formattedNote?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export const enum ResolutionEnum {
  L = 'L',
  S = 'S',
  Thumb = 'THUMB',
  Unknown = 'UNKNOWN'
};

/** Multiple Resolution Type */
export type ResolutionInput = {
  type: ResolutionEnum;
  url: Scalars['String'];
};

/** Multiple Resolution Type */
export type ResolutionType = {
  __typename?: 'ResolutionType';
  type: ResolutionEnum;
  url: Scalars['String'];
};

export type RestockTransactionEntityInput = {
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  entityId: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  quantity: Scalars['Int'];
};

export const enum RestockTransactionSubTypeEnum {
  New = 'NEW',
  Restock = 'RESTOCK',
  Undelete = 'UNDELETE'
};

export type ReturnOrderRequestItemsInput = {
  orderRequestId: Scalars['String'];
  returnedOrderRequestItems: Array<ReturnedOrderRequestItemDetailsInput>;
};

export type ReturnedOrderRequestItemDetailsInput = {
  checked?: InputMaybe<Scalars['Boolean']>;
  orderRequestItemId: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
};

/** This returns paginated roles */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges?: Maybe<Array<RoleEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  node: RoleSchema;
};

export type RoleFilters = {
  _and?: InputMaybe<Array<RoleFilters>>;
  _or?: InputMaybe<Array<RoleFilters>>;
  applicableOns?: InputMaybe<Array<ApplicableOnEnum>>;
  name?: InputMaybe<Scalars['String']>;
  roleIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<RoleStatusEnum>>;
  types?: InputMaybe<Array<RoleTypeEnum>>;
};

export type RoleMetadataInput = {
  configuredPermissions: Array<Scalars['String']>;
};

export type RoleMetadataSchema = {
  __typename?: 'RoleMetadataSchema';
  configuredPermissions?: Maybe<Array<Scalars['String']>>;
};

/** The Role model */
export type RoleSchema = {
  __typename?: 'RoleSchema';
  /** Scope through which a role could be limited on. */
  allowedScopes: Array<AllowedScope>;
  /** On which entity the role is applicable */
  applicableOn: Array<ApplicableOnEnum>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of role */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata?: Maybe<RoleMetadataSchema>;
  name: Scalars['String'];
  permittedOn: Array<PermittedOnSchema>;
  status: Scalars['String'];
  /** Returns createdBy tenant of role */
  tenant?: Maybe<TenantSchema>;
  /** Tenant Id of the category */
  tenantId?: Maybe<Scalars['String']>;
  /** Type of role */
  type: RoleTypeEnum;
  updatedAt: Scalars['String'];
  /** Returns createdBy user of role */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

export const enum RoleStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum RoleTypeEnum {
  Custom = 'CUSTOM',
  Hidden = 'HIDDEN',
  InternAl = 'INTERNAl',
  System = 'SYSTEM'
};

export const enum RoleTypeFilterEnum {
  CategoryManager = 'CATEGORY_MANAGER',
  ExternalUser = 'EXTERNAL_USER',
  SiteManager = 'SITE_MANAGER',
  SiteStaff = 'SITE_STAFF'
};

export type RollupQuantitiesFromLocationsOfItemLocationInput = {
  itemId: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
  siteId: Scalars['String'];
};

/** GRPC Job Schema */
export type ScheduledGrpcJobType = {
  __typename?: 'ScheduledGrpcJobType';
  recipientService: RecipientServiceEnum;
  rpcInput: Scalars['String'];
  rpcMethod: Scalars['String'];
};

/** This returns paginated ScheduledJobs */
export type ScheduledJobConnection = {
  __typename?: 'ScheduledJobConnection';
  edges: Array<ScheduledJobEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ScheduledJobEdge = {
  __typename?: 'ScheduledJobEdge';
  node: ScheduledJobSchema;
};

/** GRPC Metadata of job */
export type ScheduledJobGrpcMetadataInput = {
  recipientService: RecipientServiceEnum;
  rpcInput: Scalars['String'];
  rpcMethod: Scalars['String'];
};

/** Scheduled Job Schema */
export type ScheduledJobSchema = {
  __typename?: 'ScheduledJobSchema';
  cancelledAt?: Maybe<Scalars['String']>;
  cancelledById?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  grpcMetadata?: Maybe<ScheduledGrpcJobType>;
  id: Scalars['String'];
  /** Type of job grpc/http */
  invocationType: InvocationTypeEnum;
  key?: Maybe<Scalars['String']>;
  /** Job is repeating or not */
  recurring: Scalars['Boolean'];
  recurringForAllTenants?: Maybe<Scalars['Boolean']>;
  recurringJobMetadata?: Maybe<RecurringScheduledJobMetadata>;
  /** DateTime of scheduled Job */
  scheduledAt: Scalars['String'];
  /** Execution Status Of Job */
  status: ScheduledJobStatusEnum;
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedById: Scalars['String'];
};

export const enum ScheduledJobStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS'
};

export type ScheduledJobsFilters = {
  _and?: InputMaybe<Array<ScheduledJobsFilters>>;
  _or?: InputMaybe<Array<ScheduledJobsFilters>>;
  /** Directly Parsed filter query for fetching scheduledJob */
  filterQuery?: InputMaybe<Scalars['String']>;
  scheduledJobIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ScheduledJobStatusEnum>>;
};

export const enum ScopeApplicableOnEnum {
  Entity = 'ENTITY',
  Notification = 'NOTIFICATION'
};

/** ScopedRoles of user */
export type ScopedRole = {
  __typename?: 'ScopedRole';
  roleId: Scalars['String'];
  scopeEntity: AllowedScopeEntityEnum;
  scopeGroupIds?: Maybe<Array<Scalars['String']>>;
};

/** This returns paginated shipping containers */
export type ShippingContainerConnection = {
  __typename?: 'ShippingContainerConnection';
  edges?: Maybe<Array<ShippingContainerEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type ShippingContainerEdge = {
  __typename?: 'ShippingContainerEdge';
  node: ShippingContainerSchema;
};

/** Expanded Sort fields for Shipping Container */
export type ShippingContainerExpandedSortFieldsSchema = {
  __typename?: 'ShippingContainerExpandedSortFieldsSchema';
  createdByName?: Maybe<Scalars['String']>;
  destinationSiteName?: Maybe<Scalars['String']>;
};

/** Applicable filters in getting shipping containers. */
export type ShippingContainerFilters = {
  _and?: InputMaybe<Array<ShippingContainerFilters>>;
  _or?: InputMaybe<Array<ShippingContainerFilters>>;
  containerIds?: InputMaybe<Array<Scalars['String']>>;
  containerTypePrefix?: InputMaybe<ShippingContainerTypePrefixEnum>;
  deliveryPersonIds?: InputMaybe<Array<Scalars['String']>>;
  destinationSiteIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  shippingContainerIds?: InputMaybe<Array<Scalars['String']>>;
  shippingTransactionStatuses?: InputMaybe<Array<ShippingTransactionStatusEnum>>;
  statuses?: InputMaybe<Array<ShippingContainerStatusEnum>>;
  trackingIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Shipping container to facilitate movement of items within the tenant. */
export type ShippingContainerSchema = {
  __typename?: 'ShippingContainerSchema';
  /** Human reader container id that will pe printed in the QR Code label */
  containerId: Scalars['String'];
  createdAt: Scalars['String'];
  /** Returns createdBy user of shippingContainer */
  createdBy: UserSchema;
  /** User id of the creator. */
  createdById: Scalars['String'];
  /** Timestamp at which the document was marked as deleted. */
  deletedAt: Scalars['String'];
  /** User id of the user who did delete operation for the container. */
  deletedById: Scalars['String'];
  /** Returns destinationSite of shippingContainer */
  destinationSite: LocationSchema;
  /** Site id to which the items packed in this container is delivered. */
  destinationSiteId: Scalars['String'];
  id: Scalars['String'];
  /** Lets you know whether the container can be reused for shipping transactions. */
  isReusable: Scalars['Boolean'];
  qrCodeImage?: Maybe<Scalars['String']>;
  /** Returns current shipping transactions of container */
  shippingTransactions?: Maybe<Array<ShippingTransactionSchema>>;
  status: Scalars['String'];
  /** Tenant id to which this container belongs to. */
  tenantId: Scalars['String'];
  /** Type of shipping container */
  type: ShippingContainerTypePrefixEnum;
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of shippingContainer */
  updatedBy: UserSchema;
  updatedById?: Maybe<Scalars['String']>;
};

export const enum ShippingContainerStatusEnum {
  Active = 'ACTIVE',
  Archive = 'ARCHIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Used = 'USED'
};

export const enum ShippingContainerTypePrefixEnum {
  Bin = 'BIN',
  Pkg = 'PKG',
  Plt = 'PLT'
};

export type ShippingTransactionChildEntitySchema = {
  __typename?: 'ShippingTransactionChildEntitySchema';
  entityId: Scalars['String'];
  /** Type of entity packed inside this container */
  entityType: Scalars['String'];
};

/** This returns paginated shipping Transactions */
export type ShippingTransactionConnection = {
  __typename?: 'ShippingTransactionConnection';
  edges?: Maybe<Array<ShippingTransactionEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

export const enum ShippingTransactionDeliveryTypeEnum {
  Delivered = 'DELIVERED',
  PickedUp = 'PICKED_UP'
};

export type ShippingTransactionDeliveryVerificationRecordSchema = {
  __typename?: 'ShippingTransactionDeliveryVerificationRecordSchema';
  /** Returns receiverUser in shippingTransaction delivery verification record. */
  receiverUser?: Maybe<UserSchema>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  verificationImageUrl?: Maybe<Scalars['String']>;
};

/** Edge */
export type ShippingTransactionEdge = {
  __typename?: 'ShippingTransactionEdge';
  node: ShippingTransactionSchema;
};

/** Shipping transaction filters */
export type ShippingTransactionFilters = {
  _and?: InputMaybe<Array<ShippingTransactionFilters>>;
  _or?: InputMaybe<Array<ShippingTransactionFilters>>;
  childEntityIds?: InputMaybe<Array<Scalars['String']>>;
  containerIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  currentSiteIds?: InputMaybe<Array<Scalars['String']>>;
  deletedAt?: InputMaybe<Scalars['String']>;
  deliverToIds?: InputMaybe<Array<Scalars['String']>>;
  deliveryDate?: InputMaybe<DateFilter>;
  deliveryPersonIds?: InputMaybe<Array<Scalars['String']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  destinationSiteIds?: InputMaybe<Array<Scalars['String']>>;
  latestsOfTheContainerOnly?: InputMaybe<Scalars['Boolean']>;
  orderRequestIds?: InputMaybe<Array<Scalars['String']>>;
  parentShippingTransactionIds?: InputMaybe<Array<Scalars['String']>>;
  requestorIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  shippingTransactionIds?: InputMaybe<Array<Scalars['String']>>;
  sourceSiteIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<ShippingTransactionStatusEnum>>;
  trackingIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<DateFilter>;
};

export type ShippingTransactionInventoryItemInput = {
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  parentTransactionId: Scalars['String'];
  quantity: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type ShippingTransactionInventoryItemSchema = {
  __typename?: 'ShippingTransactionInventoryItemSchema';
  /** Returns item in shippingTransactionItem */
  item: ItemUnion;
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  parentTransactionId: Scalars['String'];
  quantity: Scalars['Int'];
  transactionId: Scalars['String'];
};

export type ShippingTransactionItemInput = {
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  quantity: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
};

export type ShippingTransactionItemSchema = {
  __typename?: 'ShippingTransactionItemSchema';
  /** Returns item in shippingTransactionItem */
  item: ItemUnion;
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  quantity: Scalars['Int'];
};

export const enum ShippingTransactionItemSourceEnum {
  Inventory = 'INVENTORY',
  OrderRequest = 'ORDER_REQUEST'
};

/** It will resolve different shipping transaction item types */
export type ShippingTransactionItemUnion = ShippingTransactionInventoryItemSchema | ShippingTransactionOrderRequestItemSchema;

export type ShippingTransactionOrderRequestItemInput = {
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  orderRequestItemId: Scalars['String'];
  projectId: Scalars['String'];
  quantity: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
};

export type ShippingTransactionOrderRequestItemSchema = {
  __typename?: 'ShippingTransactionOrderRequestItemSchema';
  /** Returns item in shippingTransactionItem */
  item: ItemUnion;
  itemId: Scalars['String'];
  itemSource: ShippingTransactionItemSourceEnum;
  itemType: ItemTypeEnum;
  locationId: Scalars['String'];
  /** Returns orderRequestItem of shippingTransactionOrderRequestItem */
  orderRequestItem: OrderRequestItemSchema;
  orderRequestItemId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type ShippingTransactionSchema = {
  __typename?: 'ShippingTransactionSchema';
  childEntities?: Maybe<Array<ShippingTransactionChildEntitySchema>>;
  containerId: Scalars['String'];
  createdAt: Scalars['String'];
  /** Returns createdBy user of shippingTransaction */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  /** Returns currentSite of shippingTransaction */
  currentSite: LocationSchema;
  currentSiteId: Scalars['String'];
  currentSiteIdHistory: Array<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  /** DeliverTo user of shippingTransaction */
  deliverTo?: Maybe<UserSchema>;
  deliverToId?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryNote?: Maybe<Scalars['String']>;
  /** Delivery user of shippingTransaction */
  deliveryPerson?: Maybe<UserSchema>;
  deliveryPersonId?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<ShippingTransactionDeliveryTypeEnum>;
  deliveryVerificationRecord?: Maybe<ShippingTransactionDeliveryVerificationRecordSchema>;
  /** Returns destinationSite of shippingTransaction */
  destinationSite: LocationSchema;
  destinationSiteId: Scalars['String'];
  id: Scalars['String'];
  /** Tells whether the shippingTransaction is of a root container */
  isRootShipment?: Maybe<Scalars['Boolean']>;
  items: Array<ShippingTransactionItemUnion>;
  latestOfTheContainer?: Maybe<Scalars['Boolean']>;
  /** OrderRequest of shippingTransaction */
  orderRequest?: Maybe<OrderRequestSchema>;
  orderRequestId?: Maybe<Scalars['String']>;
  parentShippingTransactionId?: Maybe<Scalars['String']>;
  /** Returns destinationSite of shippingTransaction */
  sourceSite: LocationSchema;
  sourceSiteId: Scalars['String'];
  status: ShippingTransactionStatusEnum;
  statusHistory: Array<ShippingTransactionStatusHistorySchema>;
  tenantId: Scalars['String'];
  trackingId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of shippingTransaction */
  updatedBy: UserSchema;
  updatedById?: Maybe<Scalars['String']>;
};

export const enum ShippingTransactionStatusEnum {
  Delivered = 'DELIVERED',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Packed = 'PACKED',
  Unpacked = 'UNPACKED'
};

export type ShippingTransactionStatusHistorySchema = {
  __typename?: 'ShippingTransactionStatusHistorySchema';
  createdAt: Scalars['String'];
  createdById: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ShippingTransactionVerificationInputType = {
  userId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
  verificationImage?: InputMaybe<Scalars['String']>;
  verificationImageUrl?: InputMaybe<Scalars['String']>;
};

export const enum SortOrderEnum {
  Asc = 'ASC',
  Desc = 'DESC'
};

export type SortProps = {
  fieldPath?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<SortOrderEnum>;
};

export type Storage = {
  device?: InputMaybe<Scalars['String']>;
  firmwareRevision?: InputMaybe<Scalars['String']>;
  interfaceType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serialNum?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  smartStatus?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

/** Storage Schema */
export type StorageSchema = {
  __typename?: 'StorageSchema';
  device?: Maybe<Scalars['String']>;
  firmwareRevision?: Maybe<Scalars['String']>;
  interfaceType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serialNum?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  smartStatus?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

/** String Filters */
export type StringFilter = {
  equalTo?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  matches?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  bellNotificationByUser: BellNotificationSchema;
  job: JobSchema;
  jobByCreator: JobByCreatorSubscriptionPayload;
};


export type SubscriptionBellNotificationByUserArgs = {
  data: BellNotificationByUserSubscriptionInput;
};


export type SubscriptionJobArgs = {
  data: JobSubscriptionInput;
};


export type SubscriptionJobByCreatorArgs = {
  data: JobByCreatorSubscriptionInput;
};

export type System = {
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
  virtual?: InputMaybe<Scalars['Boolean']>;
};

/** OS Schema */
export type SystemSchema = {
  __typename?: 'SystemSchema';
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  virtual?: Maybe<Scalars['Boolean']>;
};

export type TenantConfigInputType = {
  allowSignUp?: InputMaybe<Scalars['Boolean']>;
  whileListedDomains?: InputMaybe<Array<Scalars['String']>>;
};

/** Tenant Configs */
export type TenantConfigType = {
  __typename?: 'TenantConfigType';
  allowSignUp: Scalars['Boolean'];
  whileListedDomains: Array<Scalars['String']>;
};

/** This returns paginated tenants */
export type TenantConnection = {
  __typename?: 'TenantConnection';
  edges: Array<TenantEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type TenantEdge = {
  __typename?: 'TenantEdge';
  node: TenantSchema;
};

export const enum TenantFeatureTypeEnum {
  Hide = 'HIDE',
  Screen = 'SCREEN',
  Show = 'SHOW'
};

/** Tenant filters */
export type TenantFilters = {
  _and?: InputMaybe<Array<TenantFilters>>;
  _or?: InputMaybe<Array<TenantFilters>>;
  names?: InputMaybe<Array<Scalars['String']>>;
  partnersOnly?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<TenantStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  whileListedDomains?: InputMaybe<Array<Scalars['String']>>;
  zipCodes?: InputMaybe<Array<Scalars['String']>>;
};

export type TenantLocationDetailsInputType = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Tenant Location Details */
export type TenantLocationDetailsType = {
  __typename?: 'TenantLocationDetailsType';
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Tenant schema */
export type TenantSchema = {
  __typename?: 'TenantSchema';
  /** Returns accessible categories for this tenant in currentUser's tenant */
  accessibleCategories?: Maybe<Array<CategorySchema>>;
  /** Returns admin user of tenant */
  admin?: Maybe<UserSchema>;
  adminEmailId: Scalars['String'];
  config?: Maybe<TenantConfigType>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of tenant */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deactivatedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invitedByTenantId?: Maybe<Scalars['String']>;
  locationDetails: TenantLocationDetailsType;
  logo?: Maybe<Scalars['String']>;
  moduleVisibilityConfig?: Maybe<ModuleVisibilityConfigType>;
  name: Scalars['String'];
  partnerTenantIds?: Maybe<Array<Scalars['String']>>;
  /** Returns partner tenants of tenant */
  partnerTenants?: Maybe<Array<TenantSchema>>;
  primaryPhoneNumber?: Maybe<Scalars['String']>;
  secondaryPhoneNumber?: Maybe<Scalars['String']>;
  status: TenantStatusEnum;
  /** Returns name to be displayed during super admin display */
  superAdminDisplayName: Scalars['String'];
  tenantCode: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of tenant */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export const enum TenantStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  InActive = 'IN_ACTIVE'
};

export type TimestampRangeInput = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

/** This returns TotalNetworkAssetsWarranty */
export type TotalNetworkAssetsWarranty = {
  __typename?: 'TotalNetworkAssetsWarranty';
  expired: Array<NetworkAssetSchema>;
  expiringSoon: Array<NetworkAssetSchema>;
  inWarranty: Array<NetworkAssetSchema>;
  noWarranty: Array<NetworkAssetSchema>;
  totalQuantity: Scalars['Float'];
  type: Scalars['String'];
};

/** This returns paginated transactions */
export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges: Array<TransactionEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  node: TransactionSchema;
};

export const enum TransactionEntityTypeEnum {
  Asset = 'ASSET',
  AssetKit = 'ASSET_KIT',
  AssetModel = 'ASSET_MODEL',
  Inventory = 'INVENTORY',
  InventoryKit = 'INVENTORY_KIT',
  ItAsset = "IT_ASSET"
};

/** It will resolve different TransactionEntity types */
export type TransactionEntityUnion = AssetItemSchema | AssetKitItemSchema | InventoryItemSchema | InventoryKitItemSchema;

/** Transaction filters */
export type TransactionFilters = {
  _and?: InputMaybe<Array<TransactionFilters>>;
  _or?: InputMaybe<Array<TransactionFilters>>;
  actorIds?: InputMaybe<Array<Scalars['String']>>;
  billToSiteIds?: InputMaybe<Array<Scalars['String']>>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<DateFilter>;
  createdByIds?: InputMaybe<Array<Scalars['String']>>;
  departmentIds?: InputMaybe<Array<Scalars['String']>>;
  destinationLocationIds?: InputMaybe<Array<Scalars['String']>>;
  destinationSiteIds?: InputMaybe<Array<Scalars['String']>>;
  dueDate?: InputMaybe<DateFilter>;
  endDate?: InputMaybe<DateFilter>;
  entityIds?: InputMaybe<Array<Scalars['String']>>;
  entityTypes?: InputMaybe<Array<TransactionEntityTypeEnum>>;
  parentTransactionIds?: InputMaybe<Array<Scalars['String']>>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  relatedTransactionIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  sourceLocationIds?: InputMaybe<Array<Scalars['String']>>;
  sourceSiteIds?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<DateFilter>;
  statuses?: InputMaybe<Array<TransactionStatusEnum>>;
  subTypes?: InputMaybe<Array<TransactionSubTypeEnum>>;
  transactionIds?: InputMaybe<Array<Scalars['String']>>;
  types?: InputMaybe<Array<TransactionTypeEnum>>;
};

/** Move transaction meta schema (transaction related information) */
export type TransactionMetaSchema = {
  __typename?: 'TransactionMetaSchema';
  formattedNote?: Maybe<Scalars['String']>;
  formattedReason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  orderRequestId?: Maybe<Scalars['String']>;
  orderRequestItemId?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  shippingTransactionId?: Maybe<Scalars['String']>;
  trackingIdInPartnerTenant?: Maybe<Scalars['String']>;
};

/** Transaction Base Schema */
export type TransactionSchema = {
  __typename?: 'TransactionSchema';
  /** Returns createdBy user of transaction */
  actor?: Maybe<UserSchema>;
  actorId?: Maybe<Scalars['String']>;
  /** Returns billToSite of transaction */
  billToSite?: Maybe<LocationSchema>;
  billToSiteId?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of transaction */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  /** Returns department of item */
  department?: Maybe<DepartmentSchema>;
  departmentId?: Maybe<Scalars['String']>;
  /** Returns destinationLocation of transaction */
  destinationLocation?: Maybe<LocationSchema>;
  destinationLocationId?: Maybe<Scalars['String']>;
  /** Returns destinationSite of transaction */
  destinationSite?: Maybe<LocationSchema>;
  destinationSiteId?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  /** Returns entity of transaction */
  entity?: Maybe<TransactionEntityUnion>;
  entityId: Scalars['String'];
  entityType: TransactionEntityTypeEnum;
  id: Scalars['String'];
  meta?: Maybe<TransactionMetaSchema>;
  /** Returns originalQuantity of transaction */
  originalQuantity: Scalars['Int'];
  parentTransactionId: Scalars['String'];
  previousQuantity?: Maybe<Scalars['Int']>;
  /** Returns project of transaction */
  project?: Maybe<ProjectSchema>;
  projectId?: Maybe<Scalars['String']>;
  /** Returns quantity of transaction */
  quantity: Scalars['Int'];
  relatedTransactionId?: Maybe<Scalars['String']>;
  /** Returns sourceLocation of transaction */
  sourceLocation?: Maybe<LocationSchema>;
  sourceLocationId?: Maybe<Scalars['String']>;
  /** Returns sourceSite of transaction */
  sourceSite?: Maybe<LocationSchema>;
  sourceSiteId?: Maybe<Scalars['String']>;
  sqlId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  status: TransactionStatusEnum;
  subType: TransactionSubTypeEnum;
  tenantId: Scalars['String'];
  type: TransactionTypeEnum;
  updatedAt: Scalars['String'];
  /** Returns updatedBy user of transaction */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};


/** Transaction Base Schema */
export type TransactionSchemaQuantityArgs = {
  pending?: InputMaybe<Scalars['Boolean']>;
};

export const enum TransactionStatusEnum {
  CheckedOut = 'CHECKED_OUT',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  InCustody = 'IN_CUSTODY',
  InTransit = 'IN_TRANSIT',
  Overdue = 'OVERDUE',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  Pending = 'PENDING',
  Reserved = 'RESERVED'
};

export const enum TransactionSubTypeEnum {
  Audit = 'AUDIT',
  Checkin = 'CHECKIN',
  Checkout = 'CHECKOUT',
  Delete = 'DELETE',
  ExternalOrderIn = 'EXTERNAL_ORDER_IN',
  Kit = 'KIT',
  Move = 'MOVE',
  New = 'NEW',
  OrderShip = 'ORDER_SHIP',
  Receive = 'RECEIVE',
  Remove = 'REMOVE',
  Reserve = 'RESERVE',
  Restock = 'RESTOCK',
  Ship = 'SHIP',
  Undelete = 'UNDELETE'
};

export const enum TransactionTypeEnum {
  Audit = 'AUDIT',
  Move = 'MOVE',
  Remove = 'REMOVE',
  Reserve = 'RESERVE',
  Restock = 'RESTOCK'
};

export type Uuid = {
  hardware?: InputMaybe<Scalars['String']>;
  macs?: InputMaybe<Array<Scalars['String']>>;
  osSerialNumber?: InputMaybe<Scalars['String']>;
};

/** UUID Schema */
export type UuidSchema = {
  __typename?: 'UUIDSchema';
  hardware?: Maybe<Scalars['String']>;
  macs?: Maybe<Array<Scalars['String']>>;
  osSerialNumber?: Maybe<Scalars['String']>;
};

export type UnblockOrderRequestInput = {
  orderRequestId: Scalars['String'];
};

export type UndeleteAssetItemReminderInput = {
  reminderId: Scalars['String'];
};

export type UndeleteItemsInput = {
  itemIds: Array<Scalars['String']>;
};

export type UndeleteItemsPayload = {
  __typename?: 'UndeleteItemsPayload';
  failedCount: Scalars['Float'];
  successCount: Scalars['Float'];
};

/** Job to be undeleted */
export type UndeleteScheduledJobsInput = {
  /** IDs of job to be undeleted */
  scheduledJobIds?: InputMaybe<Array<Scalars['String']>>;
};

export type UndeleteUsersInput = {
  userIds: Array<Scalars['String']>;
};

export type UnpackShippingTransactionsOfContainerIdInput = {
  containerId: Scalars['String'];
};

export type UnpackShippingTransactionsOfTrackingIdsInput = {
  trackingIds: Array<Scalars['String']>;
};

export type UnreserveTransactionsInput = {
  transactionIds: Array<Scalars['String']>;
};

export type UpdateAddressInput = {
  addressId: Scalars['String'];
  addressLineOne?: InputMaybe<Scalars['String']>;
  addressLineTwo?: InputMaybe<Scalars['String']>;
  addressName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateAddressPayload = {
  __typename?: 'UpdateAddressPayload';
  address?: Maybe<AddressSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAffiliateConfigurationInput = {
  affiliateConfigurationId: Scalars['String'];
  affiliateUrl: Scalars['String'];
  commission: Scalars['Float'];
  domain: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<AffiliateConfigurationStatusEnum>;
};

export type UpdateAffiliateConfigurationPayload = {
  __typename?: 'UpdateAffiliateConfigurationPayload';
  affiliateConfiguration?: Maybe<AffiliateConfigurationSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAllBellNotificationsStatusOfUserInput = {
  currentStatus: BellNotificationStatusEnum;
  newStatus: BellNotificationStatusEnum;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetItemInput = {
  /** Storing media of items. */
  attachments: Array<CreateMediaInput>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes: Array<ExternalProductCodeInput>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  mName?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  /** Storing media of items. */
  protectedAttachments: Array<CreateMediaInput>;
  purchaseDate?: InputMaybe<Scalars['String']>;
  replacementDate?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
  warrantyExpiryDate?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetItemPayload = {
  __typename?: 'UpdateAssetItemPayload';
  assetItem?: Maybe<AssetItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAssetItemReminderInput = {
  cron?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['String']>;
  deletedById?: InputMaybe<Scalars['String']>;
  formattedNote?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  notifyUserIds?: InputMaybe<Array<Scalars['String']>>;
  recurringInterval?: InputMaybe<Scalars['Float']>;
  reminderId: Scalars['String'];
  startReminderAt?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetItemReminderTypeEnum>;
};

export type UpdateAssetItemReminderPayload = {
  __typename?: 'UpdateAssetItemReminderPayload';
  assetItemReminder?: Maybe<AssetItemReminder>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAssetItemsInput = {
  updateAssets: Array<UpdateAssetItemInput>;
};

export type UpdateAssetKitItemInput = {
  assetKitItemId?: InputMaybe<Scalars['String']>;
  /** Storing media of items. */
  attachments?: InputMaybe<UpdateMediaInput>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetKitItemPayload = {
  __typename?: 'UpdateAssetKitItemPayload';
  assetKitItem?: Maybe<AssetKitItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAssetKitItemsInput = {
  updateAssetKits: Array<UpdateAssetKitItemInput>;
};

export type UpdateAssetModelItemInput = {
  /** Storing media of items. */
  attachments: Array<CreateMediaInput>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes: Array<ExternalProductCodeInput>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  mName?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  modelNumber?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type UpdateAssetModelItemPayload = {
  __typename?: 'UpdateAssetModelItemPayload';
  assetModelItem?: Maybe<AssetModelItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateAssetModelItemsInput = {
  updateAssetModels: Array<UpdateAssetModelItemInput>;
};

export type UpdateBellNotificationsStatusInput = {
  bellNotificationIds: Array<Scalars['String']>;
  status: BellNotificationStatusEnum;
};

export type UpdateBellNotificationsStatusPayload = {
  __typename?: 'UpdateBellNotificationsStatusPayload';
  bellNotificationIds?: Maybe<Array<Scalars['String']>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateCategoryInput = {
  /** On which item the category is applicable */
  applicableOn?: InputMaybe<Array<CategoryApplicableOnEnum>>;
  assetFlag?: InputMaybe<Scalars['Boolean']>;
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  categoryManagerIds: Array<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  inventoryFlag?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<CategorySchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateCompaniesInput = {
  updateCompanies: Array<UpdateCompanyInput>;
};

export type UpdateCompanyInput = {
  companyContactNumber?: InputMaybe<Scalars['String']>;
  companyEmail?: InputMaybe<Scalars['String']>;
  companyFax?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  companyWebsite?: InputMaybe<Scalars['String']>;
  isVendor?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateCompanyPayload = {
  __typename?: 'UpdateCompanyPayload';
  company?: Maybe<CompanySchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateContactInput = {
  addressId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySqlId?: InputMaybe<Scalars['Int']>;
  contactId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  homeContactNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobileContactNumber?: InputMaybe<Scalars['String']>;
  officeContactNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact?: Maybe<ContactSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateDepartmentInput = {
  departmentCode: Scalars['String'];
  departmentId: Scalars['String'];
  description: Scalars['String'];
  formattedDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateDepartmentPayload = {
  __typename?: 'UpdateDepartmentPayload';
  department?: Maybe<DepartmentSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateEmailIdInput = {
  emailId: Scalars['String'];
  userId: Scalars['String'];
};

export type UpdateExternalOrderRequestFromChildTenantInput = {
  billToSiteId?: InputMaybe<Scalars['String']>;
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  items: Array<UpdateExternalOrderRequestItemFromChildTenantInput>;
  itemsToAdd?: InputMaybe<Array<CreateExternalOrderRequestItemInput>>;
  orderRequestId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateExternalOrderRequestFromParentTenantInput = {
  billToSiteId?: InputMaybe<Scalars['String']>;
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  items: Array<UpdateExternalOrderRequestItemFromParentTenantInput>;
  orderRequestId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateExternalOrderRequestItemFromChildTenantInput = {
  /** Should only be defined when the item is an asset or inventory item */
  categoryIdOfItemInParentTenant: Scalars['String'];
  /** Should only be defined when the item is a special order item */
  cost: Scalars['Float'];
  isEdited?: InputMaybe<Scalars['Boolean']>;
  itemIdInPartnerTenant: Scalars['String'];
  nonRemovableNotes?: InputMaybe<Array<OrderRequestItemNonRemovableNoteInput>>;
  note?: InputMaybe<Scalars['String']>;
  orderRequestItemId: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  status: OrderRequestItemStatusEnum;
  type: OrderRequestItemTypeEnum;
};

export type UpdateExternalOrderRequestItemFromParentTenantInput = {
  /** Should only be defined when the item is an asset or inventory item */
  categoryIdOfItemInParentTenant: Scalars['String'];
  /** Should only be defined when the item is a special order item */
  cost: Scalars['Float'];
  isEdited?: InputMaybe<Scalars['Boolean']>;
  itemId: Scalars['String'];
  nonRemovableNotes?: InputMaybe<Array<OrderRequestItemNonRemovableNoteInput>>;
  note?: InputMaybe<Scalars['String']>;
  orderRequestItemId: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  status: OrderRequestItemStatusEnum;
  type: OrderRequestItemTypeEnum;
};

export type UpdateExternalOrderRequestItemInput = {
  /** Should only be defined when the item is an asset or inventory item */
  categoryIdOfItemInParentTenant: Scalars['String'];
  /** Should only be defined when the item is a special order item */
  cost: Scalars['Float'];
  isEdited?: InputMaybe<Scalars['Boolean']>;
  nonRemovableNotes?: InputMaybe<Array<OrderRequestItemNonRemovableNoteInput>>;
  note?: InputMaybe<Scalars['String']>;
  orderRequestItemId: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  status: OrderRequestItemStatusEnum;
  type: OrderRequestItemTypeEnum;
};

export type UpdateInventoryItemInput = {
  /** Storing media of items. */
  attachments: Array<CreateMediaInput>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes: Array<ExternalProductCodeInput>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  mName?: InputMaybe<Scalars['String']>;
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type UpdateInventoryItemPayload = {
  __typename?: 'UpdateInventoryItemPayload';
  inventoryItem?: Maybe<InventoryItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateInventoryItemsInput = {
  updateInventories: Array<UpdateInventoryItemInput>;
};

export type UpdateInventoryKitItemInput = {
  /** Storing media of items. */
  attachments?: InputMaybe<UpdateMediaInput>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  inventoryKitItemId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateInventoryKitItemPayload = {
  __typename?: 'UpdateInventoryKitItemPayload';
  inventoryKitItem?: Maybe<InventoryKitItemSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateInventoryKitItemsInput = {
  updateInventoryKits: Array<UpdateInventoryKitItemInput>;
};

export type UpdateItemInput = {
  /** Storing media of items. */
  attachments: Array<CreateMediaInput>;
  brand?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  costOverride?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  externalProductCodes: Array<ExternalProductCodeInput>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  manufacturerId?: InputMaybe<Scalars['String']>;
  pickableThroughOrderRequest?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  unitCost?: InputMaybe<Scalars['Float']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type UpdateJobInput = {
  completedAt?: InputMaybe<Scalars['String']>;
  /** Error message that occurred during the job */
  error?: InputMaybe<Scalars['String']>;
  exportJobMetadata?: InputMaybe<ExportJobMetadataInput>;
  importErrorMessageUrl?: InputMaybe<Scalars['String']>;
  importJobMetadata?: InputMaybe<ImportJobMetadataInput>;
  /** Id of the job */
  jobId: Scalars['String'];
  /** Success Message of the Job */
  message?: InputMaybe<Scalars['String']>;
  /** Status of the job */
  status?: InputMaybe<JobStatusEnum>;
};

export type UpdateLanNetworkAssetInputs = {
  updateLanNetworkAsset: Array<UpdateLanNetworkAssetItemInput>;
};

export type UpdateLanNetworkAssetItemInput = {
  _id: Scalars['String'];
  ipAddress?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  vendor?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationInput = {
  /** On which item the location is applicable */
  applicableOn?: InputMaybe<Array<LocationApplicableOnEnum>>;
  canStoreAsset?: InputMaybe<Scalars['Boolean']>;
  canStoreInventory?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  locationCode?: InputMaybe<Scalars['String']>;
  locationId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  siteManagerIds: Array<Scalars['String']>;
  siteStaffIds: Array<Scalars['String']>;
  type?: InputMaybe<LocationTypeEnum>;
};

export type UpdateLocationPayload = {
  __typename?: 'UpdateLocationPayload';
  location?: Maybe<LocationSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateManufacturerInput = {
  emailId?: InputMaybe<Scalars['String']>;
  manufacturerId: Scalars['String'];
  name: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateManufacturerPayload = {
  __typename?: 'UpdateManufacturerPayload';
  manufacturer?: Maybe<ManufacturerSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateMediaInput = {
  /** Storing media of items. */
  set: Array<CreateMediaInput>;
};

export type UpdateNetworkAssetInput = {
  battery?: InputMaybe<Scalars['String']>;
  bios?: InputMaybe<Scalars['String']>;
  cpu?: InputMaybe<Scalars['String']>;
  disk?: InputMaybe<Scalars['String']>;
  display?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  graphics?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  memory?: InputMaybe<Scalars['String']>;
  motherboard?: InputMaybe<Scalars['String']>;
  network?: InputMaybe<Scalars['String']>;
  operatingSystem?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['String']>;
  process?: InputMaybe<Scalars['String']>;
  storage?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
  wifi?: InputMaybe<Scalars['String']>;
};

export type UpdateNetworkAssetPayload = {
  __typename?: 'UpdateNetworkAssetPayload';
  networkAssets?: Maybe<NetworkAssetSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateOrderRequestCommonInput = {
  billToSiteId?: InputMaybe<Scalars['String']>;
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  orderRequestId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderRequestInput = {
  billToSiteId?: InputMaybe<Scalars['String']>;
  deliverToId?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['String']>;
  destinationSiteId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  items: Array<UpdateOrderRequestItemInput>;
  itemsToAdd?: InputMaybe<Array<CreateOrderRequestItemInput>>;
  orderRequestId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderRequestItemInput = {
  /** Should only be defined when the item is an asset or inventory order item */
  categoryId?: InputMaybe<Scalars['String']>;
  /** Should only be defined when the item is a special order item */
  cost?: InputMaybe<Scalars['Float']>;
  /** Should only be defined when the item is a special order item */
  description?: InputMaybe<Scalars['String']>;
  /** Should only be defined when the item is a special order item */
  imageUrl?: InputMaybe<Scalars['String']>;
  isEdited?: InputMaybe<Scalars['Boolean']>;
  /** Should only be defined when the item is an asset or inventory order item */
  itemId?: InputMaybe<Scalars['String']>;
  nonRemovableNotes?: InputMaybe<Array<OrderRequestItemNonRemovableNoteInput>>;
  note?: InputMaybe<Scalars['String']>;
  orderRequestItemId: Scalars['String'];
  projectId?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  status: OrderRequestItemStatusEnum;
  type: OrderRequestItemTypeEnum;
  upcCode?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderRequestItemStatusByOrderRequestIdInput = {
  nonRemovableNote?: InputMaybe<OrderRequestItemNonRemovableNoteInput>;
  orderRequestId: Scalars['String'];
  orderRequestItemId: Scalars['String'];
  status: OrderRequestItemStatusEnum;
};

export type UpdateOrderRequestPayload = {
  __typename?: 'UpdateOrderRequestPayload';
  orderRequest?: Maybe<OrderRequestSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdatePartnerTenantInput = {
  accessibleCategoryIds: Array<Scalars['String']>;
  allowedUserIds: Array<Scalars['String']>;
  partnerTenantId: Scalars['String'];
};

export type UpdatePartnerTenantPayload = {
  __typename?: 'UpdatePartnerTenantPayload';
  partnerTenant?: Maybe<PartnerTenantSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  userId: Scalars['String'];
};

export type UpdateProductQuantityInWishlistInput = {
  products: Array<WishlistProductSchemaInput>;
  wishlistId: Scalars['String'];
};

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  projectCode: Scalars['String'];
  projectId: Scalars['String'];
};

export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  project?: Maybe<ProjectSchema>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type UpdateQuantityConfigurationsAtItemLocationInput = {
  itemId: Scalars['String'];
  locationId: Scalars['String'];
  maximumQuantity?: InputMaybe<Scalars['Int']>;
  minimumQuantity?: InputMaybe<Scalars['Int']>;
};

export type UpdateReceivableHistoryInput = {
  addToSet?: InputMaybe<Array<ReceivableHistoryInput>>;
  set?: InputMaybe<Array<ReceivableHistoryInput>>;
  update?: InputMaybe<Array<ReceivableHistoryInput>>;
};

export type UpdateReceivableInput = {
  carrier?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  formattedNotes?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  quantityOrdered?: InputMaybe<Scalars['Float']>;
  receivableId: Scalars['String'];
  receivingHistory?: InputMaybe<UpdateReceivableHistoryInput>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type UpdateReserveTransactionEntityInput = {
  actorId: Scalars['String'];
  departmentId?: InputMaybe<Scalars['String']>;
  destinationLocationId: Scalars['String'];
  destinationSiteId: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  entityId: Scalars['String'];
  meta?: InputMaybe<ReserveTransactionMetaInputInput>;
  quantity: Scalars['Int'];
  startDate: Scalars['String'];
  /** Pass this field if you want to update reserved transaction */
  transactionId: Scalars['String'];
};

export type UpdateReserveTransactionsInput = {
  entities: Array<UpdateReserveTransactionEntityInput>;
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  metadata: RoleMetadataInput;
  name?: InputMaybe<Scalars['String']>;
  roleId: Scalars['String'];
};

export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  role?: Maybe<RoleSchema>;
  success: Scalars['Boolean'];
};

/** Wishlist Shipping Address schema */
export type UpdateShippingAddressInputType = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type UpdateStatusOfShippingContainersInput = {
  containerIds: Array<Scalars['String']>;
};

export type UpdateTenantInput = {
  config?: InputMaybe<TenantConfigInputType>;
  locationDetails?: InputMaybe<TenantLocationDetailsInputType>;
  logo?: InputMaybe<Scalars['String']>;
  moduleVisibilityConfig?: InputMaybe<ModuleVisibilityConfigInputType>;
  name?: InputMaybe<Scalars['String']>;
  primaryPhoneNumber?: InputMaybe<Scalars['String']>;
  secondaryPhoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TenantStatusEnum>;
  tenantId: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateTenantPayload = {
  __typename?: 'UpdateTenantPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  tenant?: Maybe<TenantSchema>;
};

export type UpdateTransactionsPayload = {
  __typename?: 'UpdateTransactionsPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  transactions?: Maybe<Array<TransactionSchema>>;
};

export type UpdateUserInput = {
  defaultSiteId?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  scopedRoles?: InputMaybe<Array<UserScopedRoleInput>>;
  status?: InputMaybe<UserStatusEnum>;
  userId: Scalars['String'];
  userTimeZone?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  user?: Maybe<UserSchema>;
};

export type UpdateWarehouseInput = {
  aisles?: InputMaybe<Scalars['Int']>;
  bins?: InputMaybe<Scalars['Int']>;
  racks?: InputMaybe<Scalars['Int']>;
  shelves?: InputMaybe<Scalars['Int']>;
  warehouseId: Scalars['String'];
  zones?: InputMaybe<Scalars['Int']>;
};

export type UpdateWishlistInput = {
  collaborators?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  formattedDescription?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productQuantityConfig: Array<UpdateWishlistInputType>;
  shareId?: InputMaybe<Scalars['String']>;
  shippingAddress?: InputMaybe<UpdateShippingAddressInputType>;
  tenantId?: InputMaybe<Scalars['String']>;
  wishlistId: Scalars['String'];
};

/** Wishlist Product schema */
export type UpdateWishlistInputType = {
  currentPurchasedQuantity?: InputMaybe<Scalars['Float']>;
  hooverProductId: Scalars['String'];
  quantity: Scalars['Float'];
  vendor: WishlistVendorEnum;
  wishlistProductId?: InputMaybe<Scalars['String']>;
};

export type UpdateWishlistPayload = {
  __typename?: 'UpdateWishlistPayload';
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  wishlist?: Maybe<WishlistSchema>;
};

/** This returns paginated users */
export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type UserEdge = {
  __typename?: 'UserEdge';
  node: UserSchema;
};

export type UserEntityPermission = {
  __typename?: 'UserEntityPermission';
  action: AllowedPermissionActionsEnum;
  subject: AllowedPermissionsSubjectEnum;
};

/** User filters */
export type UserFilters = {
  _and?: InputMaybe<Array<UserFilters>>;
  _or?: InputMaybe<Array<UserFilters>>;
  emailIds?: InputMaybe<Array<Scalars['String']>>;
  hasItAssets?: InputMaybe<Scalars['Boolean']>;
  includingPartnerTenantExternalUsers?: InputMaybe<Scalars['Boolean']>;
  roleTypes?: InputMaybe<Array<RoleTypeFilterEnum>>;
  search?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<UserStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['String']>>;
};

/** User schema */
export type UserSchema = {
  __typename?: 'UserSchema';
  accessibleTenantIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of user */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deactivatedAt?: Maybe<Scalars['String']>;
  /** Returns default site of user */
  defaultSite?: Maybe<LocationSchema>;
  defaultSiteId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  emailId: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isExternalUser?: Maybe<Scalars['Boolean']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  /** Returns it assets of user */
  itAssets?: Maybe<Array<NetworkAssetSchema>>;
  lastLogin?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Returns name of user */
  name: Scalars['String'];
  /** Returns partner tenants of user */
  parentPartnerTenants?: Maybe<Array<TenantSchema>>;
  password?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  /** Returns roles of user */
  roles: Array<RoleSchema>;
  scopedRoles: Array<ScopedRole>;
  status: UserStatusEnum;
  /** Returns tenant of user */
  tenant?: Maybe<TenantSchema>;
  /** Tenant Id of the user */
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of user */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  userTimeZone?: Maybe<Scalars['String']>;
  /** Returns createdBy user of user */
  workspacePermissions: Array<UserWorkspacePermission>;
};

export type UserScopedRoleInput = {
  roleId: Scalars['String'];
  scopeEntity?: InputMaybe<AllowedScopeEntityEnum>;
  scopeGroupIds?: InputMaybe<Array<Scalars['String']>>;
};

export const enum UserStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  Inactive = 'INACTIVE',
  Invited = 'INVITED',
  System = 'SYSTEM',
  TenantDeleted = 'TENANT_DELETED'
};

export type UserWorkspacePermission = {
  __typename?: 'UserWorkspacePermission';
  action: AllowedPermissionActionsEnum;
  subject: AllowedPermissionsSubjectEnum;
};

export type ValueInEntitiesFiltersConfig = {
  itemFilters?: InputMaybe<ItemFilters>;
  receivableFilters?: InputMaybe<ReceivableFilters>;
  shippingContainerFilters?: InputMaybe<ShippingContainerFilters>;
  shippingTransactionFilters?: InputMaybe<ShippingTransactionFilters>;
  transactionFilters?: InputMaybe<TransactionFilters>;
};

export const enum ValuesInEntitiesDistinctByKeysEnum {
  ActorId = 'ACTOR_ID',
  CreatedById = 'CREATED_BY_ID',
  DeliverToId = 'DELIVER_TO_ID',
  DepartmentId = 'DEPARTMENT_ID',
  DestinationLocationId = 'DESTINATION_LOCATION_ID',
  DestinationSiteId = 'DESTINATION_SITE_ID',
  OrderRequestId = 'ORDER_REQUEST_ID',
  RequestorId = 'REQUESTOR_ID',
  SourceLocationId = 'SOURCE_LOCATION_ID',
  SourceSiteId = 'SOURCE_SITE_ID',
  UpdatedById = 'UPDATED_BY_ID',
  VendorId = 'VENDOR_ID'
};

export type ValuesInEntitiesSchema = {
  __typename?: 'ValuesInEntitiesSchema';
  id: Scalars['String'];
  name: Scalars['String'];
  type: ValuesInEntitiesSchemaTypeEnum;
};

export const enum ValuesInEntitiesSchemaTypeEnum {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Location = 'LOCATION',
  OrderRequest = 'ORDER_REQUEST',
  Site = 'SITE',
  User = 'USER'
};

export const enum ValuesInEntitiesTableNameEnum {
  Receivable = 'RECEIVABLE',
  ShippingContainers = 'SHIPPING_CONTAINERS',
  ShippingTransactions = 'SHIPPING_TRANSACTIONS',
  Transactions = 'TRANSACTIONS'
};

export type VerifyWishlistCollaborationInput = {
  token: Scalars['String'];
};

/** This returns paginated Warehouses */
export type WarehouseConnection = {
  __typename?: 'WarehouseConnection';
  edges: Array<WarehouseEdge>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type WarehouseEdge = {
  __typename?: 'WarehouseEdge';
  node: WarehouseSchema;
};

/** Warehouse filters */
export type WarehouseFilters = {
  _and?: InputMaybe<Array<WarehouseFilters>>;
  _or?: InputMaybe<Array<WarehouseFilters>>;
  siteId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<WarehouseStatusEnum>>;
  warehouseIds?: InputMaybe<Array<Scalars['String']>>;
  warehouseTypes?: InputMaybe<Array<WarehouseTypeEnum>>;
};

export type WarehouseLocationConfig = {
  __typename?: 'WarehouseLocationConfig';
  name: Scalars['String'];
  status: LocationStatusEnum;
};

export type WarehouseMutationPayload = {
  __typename?: 'WarehouseMutationPayload';
  locations?: Maybe<Array<LocationSchema>>;
  /** @deprecated We have moved away to array of strings in reasons field */
  reason?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
  warehouse?: Maybe<WarehouseSchema>;
};

/** Warehouse schema */
export type WarehouseSchema = {
  __typename?: 'WarehouseSchema';
  aisles?: Maybe<Scalars['Float']>;
  bins?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of Warehouse */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastLocationNamesOfChild: Array<Scalars['String']>;
  locationsConfig: GetAllWarehouseLocationsConfigsPayload;
  /** @deprecated Should not be used anywhere */
  rackIncrementor?: Maybe<Scalars['Float']>;
  racks: Scalars['Float'];
  shelves: Scalars['Float'];
  /** Returns site of warehouse */
  site: LocationSchema;
  siteId: Scalars['String'];
  status: WarehouseStatusEnum;
  /** Tenant Id of the Warehouse */
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of Warehouse */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
  warehouseType: WarehouseTypeEnum;
  zones?: Maybe<Scalars['Float']>;
};


/** Warehouse schema */
export type WarehouseSchemaLastLocationNamesOfChildArgs = {
  input: GetLastLocationNamesOfChildInput;
};


/** Warehouse schema */
export type WarehouseSchemaLocationsConfigArgs = {
  warehouseLocationsConfigs?: InputMaybe<GetAllWarehouseLocationsConfigsInput>;
};

export const enum WarehouseStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

export const enum WarehouseTypeEnum {
  RackAndShelf = 'RACK_AND_SHELF',
  ZoneToBin = 'ZONE_TO_BIN'
};

export type Warranty = {
  endDate?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

/** Warranty Schema */
export type WarrantySchema = {
  __typename?: 'WarrantySchema';
  endDate?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

/** Wishlist Product schema */
export type WishlistCollaboratorSchema = {
  __typename?: 'WishlistCollaboratorSchema';
  status: WishlistCollaboratorStatusEnum;
  token?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export const enum WishlistCollaboratorStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
};

/** This returns paginated wishlists */
export type WishlistConnection = {
  __typename?: 'WishlistConnection';
  edges?: Maybe<Array<WishlistEdge>>;
  errorCode?: Maybe<PaginationErrorCodeEnum>;
  /** PageInfo */
  pageInfo?: Maybe<PageInfo>;
  success: Scalars['Boolean'];
  totalCount: Scalars['Float'];
};

/** Edge */
export type WishlistEdge = {
  __typename?: 'WishlistEdge';
  node: WishlistSchema;
};

/** Wishlist filters */
export type WishlistFilters = {
  _and?: InputMaybe<Array<WishlistFilters>>;
  _or?: InputMaybe<Array<WishlistFilters>>;
  collaboratorToken?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['String']>;
  hooverProductIds?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  shareId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<WishlistStatusEnum>>;
  tenantIds?: InputMaybe<Array<Scalars['String']>>;
  wishlistIds?: InputMaybe<Array<Scalars['String']>>;
};

/** WishlistForPublicApiPayload */
export type WishlistForPublicApiPayload = {
  __typename?: 'WishlistForPublicApiPayload';
  wishlist?: Maybe<Array<WishlistSchema>>;
};

/** Wishlist Product schema */
export type WishlistProductSchema = {
  __typename?: 'WishlistProductSchema';
  currentPurchasedQuantity: Scalars['Float'];
  hooverProductId: Scalars['String'];
  id: Scalars['String'];
  quantity: Scalars['Float'];
  vendor: WishlistVendorEnum;
};

/** Wishlist Product schema */
export type WishlistProductSchemaInput = {
  currentPurchasedQuantity?: InputMaybe<Scalars['Float']>;
  hooverProductId: Scalars['String'];
  quantity: Scalars['Float'];
  updatedPurchasedQuantity?: InputMaybe<Scalars['Float']>;
  vendor: WishlistVendorEnum;
  wishlistProductId?: InputMaybe<Scalars['String']>;
};

/** Wishlist schema */
export type WishlistSchema = {
  __typename?: 'WishlistSchema';
  collaborators: Array<WishlistCollaboratorSchema>;
  createdAt: Scalars['String'];
  /** Returns createdBy user of Wishlist */
  createdBy: UserSchema;
  createdById: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  deletedById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eventDate: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  formattedDescription?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  products: Array<WishlistProductSchema>;
  /** If wishlist, needs to be shared, this ID can be used */
  shareId: Scalars['String'];
  shippingAddress: WishlistShippingAddressSchema;
  sqlId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  tenantId: Scalars['String'];
  updatedAt: Scalars['String'];
  /** Returns createdBy user of Wishlist */
  updatedBy: UserSchema;
  updatedById: Scalars['String'];
};

/** Wishlist Product schema */
export type WishlistShippingAddressSchema = {
  __typename?: 'WishlistShippingAddressSchema';
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Wishlist Shipping Address schema */
export type WishlistShippingAddressSchemaInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export const enum WishlistStatusEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
};

/** Wishlist Validate filters */
export type WishlistValidateFilters = {
  shareId?: InputMaybe<Scalars['String']>;
  wishlistId?: InputMaybe<Scalars['String']>;
};

/** WishlistValidateShareIdPayload */
export type WishlistValidateShareIdPayload = {
  __typename?: 'WishlistValidateShareIdPayload';
  exists?: Maybe<Scalars['Boolean']>;
};

export const enum WishlistVendorEnum {
  Amazon = 'AMAZON',
  Walmart = 'WALMART'
};

/** Workspace model */
export type WorkspaceSchema = {
  __typename?: 'WorkspaceSchema';
  allowedEntityPermissionsMap: Array<EntityPermissionsMap>;
  createdAt: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['String'];
};
