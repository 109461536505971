import { forwardRef, useCallback, useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { toArray } from 'lodash';
import FileInput from 'app/components/FileInput';
import { SnackbarService } from 'app/components/Snackbar';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import useUppy from 'app/uppy/useUppy';

import FormError from '../FormError';
import FormLabel from '../FormLabel';
import { Avatar } from '@mui/material';
// import defaultAvatar from '/public/images/defaultAvatar.svg';

interface Props {
  className?: string;
  label: string;
  isRequired?: boolean;
  name?: string;
  error?: FieldError;
  value?: Maybe<string>;
  onChange?: (value: string) => void;
  setIsUploading?: (isUploading: boolean) => void;
}

const ALLOWED_EXTENSIONS = ['jpg', 'png'];

const FormImageUpload = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { files, uppy, isUploading } = useUppy({ purpose: 'Image Upload' });
  const inputRef = useRef<HTMLInputElement>(null);
  const { className, error, label, name, isRequired, value, onChange, setIsUploading } = props;

  const onClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  useEffect(() => {
    setIsUploading?.(isUploading);
  }, [isUploading, setIsUploading]);

  useEffect(() => {
    const filesList = toArray(files);
    const lastIndex = filesList.length - 1;

    const uploadedImageUrl = filesList[lastIndex]?.url;

    if (uploadedImageUrl && uploadedImageUrl !== value) {
      onChange?.(uploadedImageUrl);
    }
  }, [files, onChange, value]);

  const getAllowedFiles = useCallback((files: File[]) => {
    const allowedFiles = files.filter((file) => {
      const extension = file.name.split('.').pop() ?? '';
      return ALLOWED_EXTENSIONS.includes(extension);
    });

    if (allowedFiles.length !== files.length) {
      SnackbarService.showError({ message: 'Invalid File Uploaded. Only Image File allowed (jpg, png)' });
    }

    return allowedFiles;
  }, []);

  const onFileDrop = (acceptedFiles: File[]) => {
    const allowedFiles = getAllowedFiles(acceptedFiles)
    allowedFiles.forEach((file) => {
      uppy.addFile({
        data: file,
        name: file.name.replaceAll(/[&/\\#,\-+()$~%'":*?<>{}]/gi, '_'),
        type: file.type,
      });
    })
  }

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      <FormLabel isRequired={isRequired}>{label}</FormLabel>
      <Dropzone onDrop={onFileDrop}
        accept={{ "image/png": [".png"], "image/jpeg": [".jpg"] }} >
        {({ getRootProps, getInputProps, isDragReject }) => (
          <div className="h-[182px] w-[254px]" {...getRootProps()} >
            <Box
              className="relative mt-6 flex justify-center items-center flex-col h-[182px] w-[254px] cursor-pointer border border-grey-500 rounded-[8px] p-10 text-center">
              {/* <FileInput
                ref={inputRef}
                accept=".jpg, .png"
                getAllowedFiles={getAllowedFiles}
                name={name}
                uppy={uppy}
              /> */}

              <input {...getInputProps()} />
              <p className="mx-auto mb-[14px] text-14 font-semibold text-grey-500">
                {!isDragReject && 'Upload Image'}
                {isDragReject && 'Invalid file type!'}
              </p>

              <Box className='min-h-[84px] min-w-[84px] rounded-full flex justify-center items-center  bg-grey-300'>
                <Avatar sx={{ width: value ? 84 : 32, height: value ? 84 : 32, marginX: 'auto', bgcolor: '#EAECF0' }} src={value as string || '/images/defaultAvatar.svg'} />
              </Box>
            </Box>
          </div>
        )}
      </Dropzone>
      <FormError error={error} />
    </Box>
  );
});

export default FormImageUpload;
