import { OrderRequestItemSchema } from 'app/types/schema';
import FormOrderRequestItemStatus from '../FormOrderRequestItemStatus';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { useContext } from 'react';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  orderDetail: OrderDetailsData;
  disabled?: boolean;
}

const OrderRequestItemStatus = (props: Props) => {
  const { orderRequestItem, orderDetail, disabled } = props;
  const { isChildTenantOrderView } = useContext(OrderDetailContext);
  return (
    <FormOrderRequestItemStatus
      orderRequestItem={orderRequestItem}
      orderDetail={orderDetail}
      disabled={disabled || isChildTenantOrderView}
    />
  );
};

export default OrderRequestItemStatus;
