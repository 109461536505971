import { useMemo } from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import { Redirect } from 'app/libs/navigation';
import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';

import Loader from '../../../../components/Loader';
import routes from '../../../../consts/routes';
import Assets from '../../../../i18n/Assets';
import { AssetItemEdge } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import EditAssetContent from './components/EditAssetContent';
import { useEditAssetDataQuery } from './graphql/query/generated/editAssetData';

interface Props {
  assetId: string;
}

const EditAsset = (props: Props) => {
  const { assetId } = props;

  const [{ data, fetching }] = useEditAssetDataQuery({
    variables: {
      filters: {
        itemIds: [assetId],
      },
    },
  });

  const assetItem: AssetItemEdge['node'] | undefined = useMemo(() => {
    return (data?.items?.edges?.[0]?.node as AssetItemEdge['node']) || undefined;
  }, [data?.items?.edges]);

  if (fetching) {
    return <Loader />;
  }

  if (!assetItem) {
    return <Redirect to={routes.AssetsSearch()} />;
  }

  return (
    <EntityManager className="pt-[0px]">
      <EntityManager.Title
        documentTitle={Assets.EditAssetTitle}
        title={Assets.EditAsset}
        hasTopDivider
      />
      <FindUPCProvider>
        <EditAssetContent assetItem={assetItem} />
      </FindUPCProvider>
    </EntityManager>
  );
};

export default EditAsset;
