import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateManufacturerMutationVariables = Types.Exact<{
  input: Types.CreateManufacturerInput;
}>;


export type CreateManufacturerMutation = { __typename?: 'Mutation', createManufacturer: { __typename?: 'CreateManufacturerPayload', success: boolean, reason?: string | null, manufacturer?: { __typename?: 'ManufacturerSchema', id: string } | null } };


export const CreateManufacturerDocument = gql`
    mutation createManufacturer($input: CreateManufacturerInput!) {
  createManufacturer(input: $input) {
    success
    reason
    manufacturer {
      id
    }
  }
}
    `;

export function useCreateManufacturerMutation() {
  return Urql.useMutation<CreateManufacturerMutation, CreateManufacturerMutationVariables>(CreateManufacturerDocument);
};