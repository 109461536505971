import { Controller } from 'react-hook-form';
import FormDatePickerField from 'app/components/ProcureForm/FormDatePickerField';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';
import FormLabel from 'app/components/ProcureForm/FormLabel';

const OrderDueDateField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, disabled, isRequired } = props;
  const { control } = formState;

  const getMinDueDate = () => {
    const minDueDate = new Date();

    const todayNoonData = new Date();
    todayNoonData.setHours(12, 0, 0, 0);

    if (minDueDate < todayNoonData) {
      return minDueDate;
    }
    minDueDate.setDate(minDueDate.getDate() + 1);
    return minDueDate;
  };

  return (
    <div className="mb-[24px]">
     
      <Controller
        control={control}
        name="dueDate"
        render={({ field, fieldState }) => (
          <FormDatePickerField
            {...field}
            key="dueDate"
            clearable
            disabled={disabled}
            error={fieldState.error}
            isRequired={isRequired}
            label={Orders.FormLabels.DueDate}
            minDate={getMinDueDate().toISOString()}
            formLabel={Orders.FormLabels.DueDate}
          />
        )}
        rules={{
          validate: (value) => {
            if (!value) return Orders.FormValidationMessages.DueDateRequired;
            return true;
          },
        }}
      />
    </div>
  );
};

export default OrderDueDateField;
