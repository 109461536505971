import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type RolesTableRowFragment = { __typename?: 'RoleSchema', id: string, name: string, description?: string | null, updatedAt: string, tenant?: { __typename?: 'TenantSchema', id: string, name: string } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } };

export const RolesTableRowFragmentDoc = gql`
    fragment RolesTableRow on RoleSchema {
  id
  name
  description
  updatedAt
  tenant {
    id
    name
  }
  createdBy {
    id
    name
  }
  updatedBy {
    id
    name
  }
}
    `;