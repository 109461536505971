import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type ContactDetailsFragment = { __typename?: 'ContactSchema', companyId: string, firstName: string, lastName: string, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null, id: string, address: { __typename?: 'AddressSchema', id: string } };

export const ContactDetailsFragmentDoc = gql`
    fragment ContactDetails on ContactSchema {
  address {
    id
  }
  companyId
  firstName
  lastName
  email
  fax
  homeContactNumber
  mobileContactNumber
  officeContactNumber
  title
  id
}
    `;