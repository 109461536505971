import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogContent } from '@mui/material';
import { Button, Dialog, RadioGroup } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import Warehouse from 'app/i18n/Warehouse';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import Common from '../../../../../../i18n/Common';
import { WAREHOUSE_FORM_CONFIG } from '../../../WarehouseDetails/components/WarehouseSetupDialog/utils';
import { WarehouseValueKey } from '../../../WarehouseDetails/types';
import { ORDERED_WAREHOUSE_KEYS, RACKS_AND_SHELVES_KEYS } from '../../../WarehouseDetails/utils';
import { WarehouseTypeEnum } from 'app/types/schema';

const getNumberValue = (value: string | number) => Number(value) || 0;

interface Props {
  disabled?: boolean;
  loading?: boolean;
  keysToShow?: WarehouseValueKey[];
  onClose: () => void;
  onSubmit: (values: { [key in WarehouseValueKey]: number } & { warehouseType?: WarehouseTypeEnum }) => void;
  isCreateWarehouse?: boolean;
}

const WarehouseSetupDialog = (props: Props) => {
  const { disabled, loading, keysToShow = ORDERED_WAREHOUSE_KEYS, onClose, onSubmit, isCreateWarehouse } = props;
  const [warehouseType, setWarehouseType] = useState<WarehouseTypeEnum>(WarehouseTypeEnum.ZoneToBin);

  const { control, handleSubmit } = useForm<{ [key in WarehouseValueKey]: number }>({
    defaultValues: { zones: 1, aisles: 1, racks: 1, shelves: 1, bins: 1 },
  });

  const visibleKeys = useMemo(() => {
    if (!isCreateWarehouse) return keysToShow;
    return warehouseType === WarehouseTypeEnum.ZoneToBin
      ? ORDERED_WAREHOUSE_KEYS
      : RACKS_AND_SHELVES_KEYS;
  }, [isCreateWarehouse, warehouseType, keysToShow]);


  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const submittedValues = visibleKeys.reduce((acc, key) => {
          acc[key as WarehouseValueKey] = getNumberValue(values[key as WarehouseValueKey]);
          return acc;
        }, {} as { [key in WarehouseValueKey]: number });

        if (isCreateWarehouse) {
          onSubmit({ ...submittedValues, warehouseType });
        } else {
          onSubmit(submittedValues);
        }
      }),
    [handleSubmit, onSubmit, visibleKeys, isCreateWarehouse, warehouseType],
  );

  const RADIO_ITEMS = [
    { id: WarehouseTypeEnum.ZoneToBin, value: WarehouseTypeEnum.ZoneToBin, label: 'Zone to Bin' },
    { id: WarehouseTypeEnum.RackAndShelf, value: WarehouseTypeEnum.RackAndShelf, label: 'Rack & Shelves' },
  ];

  return (
    <Dialog
      open
      className="!p-0"
      id="warehouse-setup"
      title={Warehouse.WarehouseSetup}
      onClose={onClose}>
      <DialogContent className="min-w-[632px] !p-0" sx={{ padding: '0px' }}>
        <form onSubmit={onFormSubmit}>
          <Box className="mt-24 space-y-24">
            {isCreateWarehouse && (
              <div className='flex justify-center items-center warehouse-setup-radio-container'>
                <RadioGroup
                  radioItems={RADIO_ITEMS}
                  value={warehouseType}
                  onChange={(value) => setWarehouseType(value as WarehouseTypeEnum)}
                />
              </div>
            )}
            {visibleKeys.map((key) => {
              const config = WAREHOUSE_FORM_CONFIG[key as keyof typeof WAREHOUSE_FORM_CONFIG];
              return (
                <Controller
                  key={key}
                  control={control}
                  name={key as WarehouseValueKey}
                  render={({ field, fieldState }) => (
                    <FormTextInput
                      {...field}
                      error={fieldState.error}
                      label={config.label}
                      placeholder={config.placeholder}
                      type="number"
                    />
                  )}
                  rules={config.rules}
                />
              );
            })}
            <Stack justifyContent="end" className="gap-[20px]">
              <Button
                classes="min-w-[80px] h-[44px]"
                disabled={disabled}
                loading={loading}
                theme="success"
                onClick={onFormSubmit}>
                {Common.Actions.Save}
              </Button>
              <Button
                onClick={onClose}
                classes="min-w-[80px] h-[44px]">
                {Common.Actions.Cancel}
              </Button>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WarehouseSetupDialog;
