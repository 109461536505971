import gql from 'graphql-tag';
import { OperationResult } from 'urql';

import { getHooverClient } from '../../../../libs/urql/getClient';

export type Image = {
  original: string;
};

export type OfferImagesConnection = {
  nodes: Image[];
};

export type Offer = {
  description: string;
  images: OfferImagesConnection;
};

export type ProductOffersConnection = {
  nodes: Offer[];
  totalCount: number;
};

export type Manufacturer = {
  name?: string;
};

export type Product = {
  id: string;
  name: string;
  globalTradeItemNumber?: string;
  manufacturer?: Manufacturer;
  modelName: string;
  offers: ProductOffersConnection;
};

export type QueryProductsConnection = {
  nodes: Product[];
  totalCount: number;
};

export type FetchHooverProductResponse = {
  data: {
    products: QueryProductsConnection;
  };
};

export const Products = gql`
  query GetProducts(
    $name: String!
    $page: Int!
    $totalPage: Int!
    $model: String!
    $code: String!
    $brand: String!
  ) {
    products(
      filter: {
        name: { matches: $name }
        productCode: $code
        modelName: { equalTo: $model }
        manufacturer: { matches: $brand }
      }
      first: $totalPage
      skip: $page
    ) {
      totalCount
      nodes {
        id
        name
        manufacturer {
          name
        }
        modelName
        globalTradeItemNumber
        offers {
          nodes {
            description
            images {
              nodes {
                original
              }
            }
          }
        }
      }
    }
  }
`;

export function useHooverProductsQuery() {
  const hooverUrqlClient = getHooverClient();

  const fetchHooverProducts = (
    variables: any,
  ): Promise<OperationResult<FetchHooverProductResponse>> => {
    return hooverUrqlClient
      .query(Products, {
        ...variables,
      })
      .toPromise();
  };

  return {
    fetchHooverProducts,
  };
}

// export type UseHooverProductsReturnType = ReturnType<typeof useHooverProducts>
// export default useHooverProducts
