import { memo } from 'react';
import Stack from 'app/ui-components/Stack';

interface Props {
  description?: string | null;
  title: string;
}

function PermissionGroupHeader({ description, title }: Props) {
  return (
    <Stack direction="col" spacing="space-y-4">
      <p className="text-13 font-semibold text-grey-800">{title}</p>
      <p className="text-12 text-grey-800">{description}</p>
    </Stack>
  );
}

export default memo(PermissionGroupHeader);
