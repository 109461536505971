import { Link } from 'app/libs/navigation';

const IMAGE_URL = `${process.env.PUBLIC_URL}/images/logo.png`;

const LoginHeader = () => {
  return (
    <header className="header absolute top-0 left-0 flex min-h-[60px] w-[100vw] border-b-[1px] border-solid border-grey-300 bg-white px-[15px]">
      <div className="m-0 flex flex-auto flex-wrap items-center justify-center">
        <Link className="navbar-header text-[#2e2e2e]" to="/">
          <img alt="Logo" className="w-[124px]" src={IMAGE_URL} />
        </Link>
      </div>
    </header>
  );
};

export default LoginHeader;
