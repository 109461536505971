import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { processSearch } from 'app/utils/processSearch';

import { OrderRequestInput } from '../../../OrderRequestForm/type';

const useSearchSuggestion = (options?: { orderItems: OrderRequestInput[]}) => {

  const { orderItems } = options || {};

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>([]);

  useEffect(() => {
    const filteredArray = Array.isArray(orderItems)
      ? orderItems
          .flatMap(obj => ({
            id: obj.id || obj.itemInStock?.id,
            title: obj.itemInStock?.title || "",
            sku: obj.itemInStock?.sku || "",
            description: obj.description || "",
            upcCode: obj.upcCode || "",
            website: obj.website || ""
          }))
          .filter(obj => {
            const objectValues = Object.values(obj);
            return (
              Object.values(obj).filter((value, index) => Object.keys(obj)[index] !== "id").some(value => value !== "") &&
              objectValues.some(value =>
                value.toLowerCase().includes(searchValue.toLowerCase())
              )
            );
          })
      : [];

    const result = filteredArray.map((item) => {
      const {result, searchBy} = processSearch(["title","sku","upcCode","website","description"], searchValue, item)
      
      return {
        label: searchBy,
        id: item.id,
        value: result
      };
    });

    setSearchSuggestions(result || []);
  }, [orderItems, searchValue]);


  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
