import { useCallback, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Layout } from '@procurenetworks/procure-component-library';
import { matchPath } from 'app/libs/navigation';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { getTabNavLinkClassName } from 'app/utils/navLink';
import useCurrentUser from '../../auth/hooks/useCurrentUser';
import { getAssetLayoutTabs } from './utils';

type Props = React.PropsWithChildren<RouteComponentProps> & { disabled?: boolean };

function AssetsLayout(props: Props) {
  const { children, history, disabled } = props;
  const { workspacePermissions } = useCurrentUser();

  const onTabChange = useCallback(
    (tab: string) => {
      history.push(`/assets/${tab}`);
    },
    [history],
  );

  const tabs = useMemo(() => getAssetLayoutTabs(workspacePermissions), [workspacePermissions]);

  const isTabActive = useCallback(
    (tab: string) => {
      const result = !!matchPath(history.location.pathname, {
        path: `/assets/${tab}`,
        exact: false,
        strict: false,
      });

      return result;
    },
    [history.location.pathname],
  );

  const scrollView = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}
      mainClassName="!border-0">
      <div className="rounded-[12px]">
        <Nav
          tabs
          className="no-scrollbar flex gap-[8px] overflow-x-scroll rounded-tl-[12px] rounded-tr-[12px] bg-white p-[24px] p-[16px] md:p-[24px] xl:p-[24px]">
          {tabs.map(({ label, value }, index) => (
            <NavItem key={value} id={`${index}`}>
              <NavLink
                className={getTabNavLinkClassName(isTabActive(value), disabled)}
                disabled={disabled}
                onClick={() => {
                  onTabChange(value);
                  scrollView(`${index}`);
                }}>
                {label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {children}
      </div>
    </Layout>
  );
}

export default withRouter(AssetsLayout);
