import WishlistSettings from 'app/modules/wishlist/views/wishlistSettings';

import WishlistLayout from '../../modules/wishlist/WishlistLayout';
function WishlistSettingsPage() {
  return (
    <WishlistLayout>
      <WishlistSettings />
    </WishlistLayout>
  );
}

export default WishlistSettingsPage;
