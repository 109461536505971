import AdminLayout from 'app/components/AdminLayout';
import Users from 'app/modules/users/views/Users';

function UsersPage() {
  return (
    <AdminLayout>
      <Users />
    </AdminLayout>
  );
}

export default UsersPage;
