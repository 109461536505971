import { GridRowHeightParams } from '@mui/x-data-grid';
import { CreateMediaInput } from 'app/types/schema';

import { ORDER_INSERT_TYPE, OrderRequestInput } from '../../OrderRequestForm/type';
import _ from 'lodash';
import Orders from '../../../../../../../i18n/Orders';

export function getAttachment(row: OrderRequestInput): CreateMediaInput[] | undefined {
  let attachments: CreateMediaInput[] = [];

  switch (row.itemType) {
    case ORDER_INSERT_TYPE.NON_STOCKED_ITEMS:
      attachments.push(...(row?.imageUrl as CreateMediaInput[]));
      break;
    case ORDER_INSERT_TYPE.ITEM_IN_STOCK:
      const itemInStockAttachment = row?.itemInStock?.attachments;
      attachments.push(...(itemInStockAttachment || []));
  }
  return attachments;
}

export function composeFilter(searchQuery: string, keys: string[]) {
  const search = (searchQuery || '').toLowerCase();

  return (item: any) => {
    let results = false;

    if (searchQuery === '') {
      return true;
    }

    for (const element of keys) {
      const test = element.split('.');

      let resultData: any = null;
      test.forEach((itemKey: string) => {
        if (!resultData) {
          resultData = item[itemKey];
        } else {
          if (resultData) {
            resultData = resultData[itemKey];
          } else {
            resultData = null;
          }
        }
      });

      if (resultData && resultData?.toString()?.toLowerCase().includes(search)) {
        results = true;
        break;
      }
    }
    return results;
  };
}

export function getRowHeight(params: GridRowHeightParams) {
  if (params.model.lastUpdatedProject) {
    return 75;
  }
  return null;
}

export function getTotalOfItems(orderItems: OrderRequestInput[]) {
  let total = 0;
  _.forEach(orderItems, (row) => {
    total += Number.parseFloat(row.quantity || '1') * Number.parseFloat(row.cost);
  });

  return total;
}

export function validateOrderItems(orderItems: OrderRequestInput[]) {
  let errorMessage = null;

  for (let i = 0; i < orderItems.length; i++) {
    const orderItem = orderItems[i];
    if (!orderItem.quantity || orderItem.quantity === '') {
      errorMessage = Orders.FormValidationMessages.QuantityMissing;
      break;
    }
  }

  return errorMessage;
}
