import _ from 'lodash';

import { OrderRequestItemSchema, OrderRequestItemStatusEnum } from '../../../../../types/schema';
import moment from 'moment';

export function hasDeleteActionForOrderItems(orderItmes: OrderRequestItemSchema[], row: any) {
  return (
    _.every(orderItmes, (item) => item.status === OrderRequestItemStatusEnum.Open) ||
    isOrderRequestDeletable(row)
  );
}

const isDateOlderThan60Days = (dateString: any) => {
  const inputDate = moment(dateString);
  const currentDate = moment();
  const differenceInDays = currentDate.diff(inputDate, 'days');
  return differenceInDays > 60;
};
export const isOrderRequestDeletable = (rowItem: any) => {
  const orderItems = rowItem.orderRequestItems;
  return (
    _.every(orderItems, (item) => item.status === OrderRequestItemStatusEnum.Closed) &&
    isDateOlderThan60Days(rowItem.lastStatusUpdatedAt)
  );
};
