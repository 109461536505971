import AdminLayout from 'app/components/AdminLayout';
import AddCategory from 'app/modules/categories/views/AddCategory';

function AddCategoryPage() {
  return (
    <AdminLayout>
      <AddCategory />
    </AdminLayout>
  );
}

export default AddCategoryPage;
