import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAffiliateMerchantMutationVariables = Types.Exact<{
  input: Types.DeleteAffiliateConfigurationsInput;
}>;


export type DeleteAffiliateMerchantMutation = { __typename?: 'Mutation', deleteAffiliateConfigurations: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteAffiliateMerchantDocument = gql`
    mutation deleteAffiliateMerchant($input: DeleteAffiliateConfigurationsInput!) {
  deleteAffiliateConfigurations(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteAffiliateMerchantMutation() {
  return Urql.useMutation<DeleteAffiliateMerchantMutation, DeleteAffiliateMerchantMutationVariables>(DeleteAffiliateMerchantDocument);
};