import '../style.css';
import { Button } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import Wishlist from 'app/i18n/Wishlist';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Box from 'app/ui-components/Box';

const Settings = (props: RouteComponentProps) => {
  const { history } = props;
  const IMAGE_URL = `${process.env.PUBLIC_URL}/images/logo.png`;
  return (
    <>
      <Box className="space-y-30 mt-[2.1vw] mb-24 min-w-[150px] max-w-[900px] p-20 sm:mt-[2.1vh-sm] lg:mt-[2.1vh-lg]" data-testid="setting-page">
        <Box>
          <h1 className="heading text-[#666666]">{Wishlist.Settings.Review}</h1>
          <h1 className="heading text-[#66CCFF]">{Wishlist.Settings.Settings}</h1>
        </Box>

        <p className="heading2">{Wishlist.Settings.ChangeOrganization}</p>
        <Button
          classes="min-h-[51px] md:w-[214px] button"
          theme="success"
          onClick={() => {
            history.push(routes.WishlistCreateList());
          }}>
          {Wishlist.FormActions.SelectOrganization}
        </Button>

        <p className="heading2">{Wishlist.Settings.ViewList}</p>
        <Button
          classes="min-h-[51px] md:w-[214px] button"
          theme="info"
          onClick={() => {
            history.push(routes.WishlistListView());
          }}>
          {Wishlist.FormActions.ViewList}
        </Button>

        <p className="heading2">{Wishlist.Settings.DeleteAccount}</p>
        <Button
          classes="min-h-[51px] md:w-[214px] button"
          theme="danger"
          onClick={() => {
            history.push(routes.WishlistCreateList());
          }}>
          {Wishlist.FormActions.DeleteAccount}
        </Button>

        <Box className="max-w-[270px]">
          <p className="delete-description">
            {Wishlist.Settings.DeleteDescription}
            <p className="text-[#66CCFF]">{Wishlist.Settings.DeleteNote}</p>
          </p>
        </Box>

        <img alt="procure-logo" className="mt-[40px] h-[35px] w-[141px]" src={IMAGE_URL} />
      </Box>
    </>
  );
};

export default withRouter(Settings);
