import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { ContactSchema } from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

import { useContactListQuery } from '../../components/contactSearch/graphql/queries/generated/contatct';

const useContactSearchSuggestion = ( companyId?: string ) => {

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useContactListQuery({
    variables: {
      limit: 7,
      filters: {
        search: searchedValue,
        companyIds: companyId ? [companyId] : [] 
      },
    },
  });

  useEffect(() => {
    const result = data?.contacts?.edges?.map((a) => {
      const contact = a.node as ContactSchema
      const fullName = `${contact.firstName} ${contact.lastName}`;

      const updatedContact = {
        ...contact, 
        fullName: fullName
      }

      const {result, searchBy} = processSearch(["fullName","mobileContactNumber"], searchedValue, updatedContact)

      return {
        label: searchBy,
        id: contact.id,
        value: result
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseContactSearchSuggestionReturnType = ReturnType<typeof useContactSearchSuggestion>;

export default useContactSearchSuggestion;
