import AdminLayout from 'app/components/AdminLayout';
import AddSite from 'app/modules/sites/views/AddSite';

function AddSitePage() {
  return (
    <AdminLayout>
      <AddSite />
    </AdminLayout>
  );
}

export default AddSitePage;
