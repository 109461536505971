import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import ITAssetUsers from 'app/modules/network/view/ITAssetUsers';

function ITAssetUsersPage() {
  return (
    <NetworkAssetLayout>
      <ITAssetUsers />
    </NetworkAssetLayout>
  );
}

export default ITAssetUsersPage;
