import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Site from 'app/i18n/Site';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { useUsersSelectQuery } from 'app/modules/users/graphql/queries/generated/usersSelect';
import { CreateLocationInput, LocationTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import SiteForm from '../../components/SiteForm';
import { useUpdateSiteMutation } from './graphql/mutations/generated/editSite';
import { useEditSiteDataQuery } from './graphql/queries/generated/editSiteData';
import analytics from 'app/analytics';

type Props = RouteComponentProps & { siteId: string };

const EditSite = (props: Props) => {
  const { siteId, history } = props;

  const [{ fetching: disabled }, onUpdateSite] = useUpdateSiteMutation();
  const [{ data }] = useEditSiteDataQuery({
    variables: { filters: { locationIds: [siteId] } },
    requestPolicy: 'network-only',
  });

  const siteNode = useMemo(() => data?.locations.edges?.[0]?.node, [data?.locations.edges]);
  const userSiteManager = useMemo(() => siteNode?.siteManagerIds, [siteNode]);

  const [{ data: siteManagerData }] = useUsersSelectQuery({
    pause: !userSiteManager,
    variables: {
      filters: { userIds: userSiteManager },
    },
  });

  const site = useMemo<CreateLocationInput | null>(() => {
    if (siteNode) {
      return {
        name: siteNode.name,
        locationCode: siteNode.locationCode != null ? siteNode.locationCode : undefined,
        description: siteNode.formattedDescription ?? '',
        type: LocationTypeEnum.Site,
        siteStaffIds: siteNode.siteStaffIds,
        siteManagerIds: siteManagerData?.users?.edges?.length ? userSiteManager : [],
      };
    }

    return null;
  }, [data?.locations.edges, siteManagerData]);

  const onSubmit = useCallback(
    (input: CreateLocationInput) => {
      onUpdateSite({
        input: {
          ...input,
          locationId: siteId,
          type: LocationTypeEnum.Site,
          siteStaffIds: input.siteStaffIds || [],
          siteManagerIds: input.siteManagerIds || [],
        },
      }).then((response) => {
        analytics?.track('Edited', { name: 'Site' });
        if (response.data?.updateLocation.location?.id) {
          SnackbarService.show({ message: 'This site has been successfully updated.' });
          history.replace(routes.Sites());
        }
      });
    },
    [history, onUpdateSite, siteId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Sites());
  }, [history]);

  if (!site || !userSiteManager || !siteManagerData) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <TitleBar title={Site.EditSite} />
      <Box className={cx('mt-[24px] max-w-[382px]', titlebarStyles)}>
        <SiteForm
          defaultValues={site}
          disabled={disabled}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
};

export default withRouter(EditSite);
