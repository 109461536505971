import { useMemo } from 'react';
import { cx } from '@emotion/css';
import {
  FormState,
  LabelCustomEnum,
  LabelTypeEnum,
} from 'app/modules/labels/views/Labels/components/Forms/types';
import Box from 'app/ui-components';

import getCodesList from '../../../utils/getCodesList';
import getDetails from '../../../utils/getDetails';
import getSubTitle from '../../../utils/getSubTitle';
import CodeContainer from '../../CodeContainer';
import PdfViewer from '../../PdfViewer';
import { CodeSchema, LabelStockTitleEnum } from '../../types';

interface Props {
  codes: FormState & { data: Array<CodeSchema> };
  type?: LabelTypeEnum;
}

const Avery5163 = (props: Props) => {
  const { codes, type } = props;
  const _codes = useMemo(() => getCodesList(codes, 10), [codes]);
  const labelFormate = codes.labelFormat;

  return (
    <>
      <PdfViewer
        margin={{ top: 37, left: 10, bottom: 37, right: 0 }}
        print={LabelStockTitleEnum.Avery5163}
        type={codes.type}>
        {_codes.length > 0 &&
          _codes.map((chunk) => {
            return (
              <Box className={cx('grid grid-cols-2 gap-0')} id="divToPrint">
                {chunk?.length > 0 &&
                  chunk?.map((code: any, index: number) => {
                    const detail = getDetails(code, type, codes);
                    const subTitle = getSubTitle(code, type);
                    return (
                      <>
                        {code.isBlank ? (
                          <Box key={index} className={cx(`h-[144px] w-[287px]`)}></Box>
                        ) : (
                          <Box
                            key={index}
                            className={cx(`flex h-[144px] flex-1 items-center pl-[5px]`)}>
                            {labelFormate !== '3' && (
                              <div className={'w-[' + (['up', 'down'].indexOf(codes.arrowDisplay) >= 0 ? '105' : '70') + 'px]'}>
                                <CodeContainer
                                  code={code}
                                  displayImage={labelFormate !== '3' ? codes.arrowDisplay : 'no'}
                                  height={'h-[3.4em]'}
                                  size={54}
                                  type={type}
                                  width={'w-[3.4em]'}
                                />
                              </div>
                            )}
                            <div className={cx('overflow-hidden pl-10 flex-1')}>
                              <span className={'block font-[Inter] text-[13pt] leading-[1.3]'}>
                                {detail[0]}
                              </span>
                              <span className={'block font-[Inter] text-[12pt] leading-[1.3]'}>
                                {detail[1]}
                              </span>
                              <span className={'block font-[Inter] text-[12pt] leading-[1.3]'}>
                                {detail[2]}
                              </span>
                              <span className={'block font-[Inter] text-[11pt] leading-[1.3]'}>
                                {codes.custom === LabelCustomEnum.Custom ? '' : subTitle}
                              </span>
                            </div>
                          </Box>
                        )}
                        {index !== 0 && (index + 1) % 10 === 0 && (
                          <Box className={cx('page-break hidden')}></Box>
                        )}
                      </>
                    );
                  })}
              </Box>
            );
          })}
      </PdfViewer>
    </>
  );
};

export default Avery5163;
