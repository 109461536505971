import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import InventoryAssetSelectorFormField from 'app/modules/reports/components/InventoryAssetSelectorFormField';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportDepartmentsSelect from 'app/modules/reports/components/ReportDepartmentsSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportProjectsSelect from 'app/modules/reports/components/ReportProjectsSelect';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportSKUSelect from 'app/modules/reports/components/ReportSKUSelect';
import ReportUsersSelect from 'app/modules/reports/components/ReportUsersSelect';
import TransactionTypeFormField from 'app/modules/reports/components/TransactionTypeFormField';
import { REPORT_TYPES } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import { LocationTypeEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import {
  getAssetTransactionEntityTypes,
  getItemLocationItemTypesFromTransactionType,
} from '../../../../utils/itemtypes';
import TransactionReportDateFilter from '../TransactionReportDateFilter';
import { getTransactionDateFilterOptions } from '../TransactionReportDateFilter/utils';
import { TransactionReportFormProps, TransactionReportFormValues } from './types';

const defaultValues: TransactionReportFormValues = {
  type: TransactionEntityTypeEnum.Asset,
  skuIds: [],
  categoryIds: [],
  departmentIds: [],
  projectIds: [],
  siteIds: [],
  transactionTypes: [],
  userIds: [],
  format: 'preview',
  createdAt: getTransactionDateFilterOptions()[0].createdAt,
  itemTypes: getAssetTransactionEntityTypes(),
};

const TransactionReportForm = (props: TransactionReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const type: TransactionEntityTypeEnum = watch('type');
  const itemTypes = watch('itemTypes');

  useEffect(() => {
    setValue('itemTypes', getItemLocationItemTypesFromTransactionType(type));
  }, [type]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (type: TransactionEntityTypeEnum) => {
      reset({ ...defaultValues, type: type });
    },
    [reset],
  );

  return (
    <form
      className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
      onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <InventoryAssetSelectorFormField control={control} onChange={onTypeChange} />
        <ReportSKUSelect control={control} types={itemTypes} />
        <ReportCategoriesSelect control={control} />
        <ReportSitesSelect
          control={control}
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <ReportDepartmentsSelect control={control} />
        <ReportProjectsSelect control={control} />
        <ReportUsersSelect control={control} />
        <TransactionReportDateFilter control={control} />
        <TransactionTypeFormField
          validate
          control={control}
          reportType={REPORT_TYPES.TRANSACTION}
          types={[type]}
        />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default TransactionReportForm;
