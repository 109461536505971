import { ORDER_VIEW } from '../../views/OrderDetail/type';
import RequestAgainButton from '../RequestAgainButton';
import EditOrderButton from '../EditOrderButton';
import ReturnItemsButton from '../ReturnItemsButton';
import { UseOrderDetailsReturnType } from '../../views/OrderDetail/hook/useOrderDetails';

interface Props {
  actions: UseOrderDetailsReturnType['actions'];
  state: UseOrderDetailsReturnType['state'];
}
export const OrderActions = (props: Props) => {
  const { actions, state } = props;
  const { orderView, orderDetail } = state;

  if (orderView !== ORDER_VIEW.DEFAULT) {
    return null;
  }

  return (
    <div className="flex gap-[16px]">
      <ReturnItemsButton onClick={actions.onReturn} orderDetail={orderDetail} />
      <EditOrderButton onClick={actions.onEdit} orderDetail={orderDetail} />
      <RequestAgainButton orderDetail={orderDetail} />
    </div>
  );
};

export default OrderActions;
