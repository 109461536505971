import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAddressesMutationVariables = Types.Exact<{
  input: Types.DeleteAddressesInput;
}>;


export type DeleteAddressesMutation = { __typename?: 'Mutation', deleteAddresses: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteAddressesDocument = gql`
    mutation deleteAddresses($input: DeleteAddressesInput!) {
  deleteAddresses(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteAddressesMutation() {
  return Urql.useMutation<DeleteAddressesMutation, DeleteAddressesMutationVariables>(DeleteAddressesDocument);
};