import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import {
  ItemLocationEdge,
  ItemStatusEnum,
  ItemTypeEnum,
  LocationStatusEnum,
  LocationTypeEnum,
  Maybe,
  SortOrderEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import FormLabel from '../../../../components/Form/FormLabel';
import { getLocationName, isUnassignedLocation } from '../../utils/location';
import { useFormRestockLocationSelectQuery } from './graphql/queries/generated/formRestockLocationSelect';
import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { sortBy } from '../../../../utils/sort';
import { adaptItemLocationToOption } from '../FormItemLocationSelect/utils';
import { useFormRestockKitLocationSelectQuery } from './graphql/queries/generated/formRestockKitLocationSelect';
import { useItemLocationDataQuery } from '../../../inventory/graphql/queries/generated/itemLocationData';
import { adaptLocationToOption } from '../FormToLocationSelect/utils';

// interface FormItemLocationOption {
//   id: string;
//   name: string;
// }
type FormItemLocationOption = any;

interface FormRestockSiteSelectProps {
  className?: string;
  clearable?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  label?: string;
  error?: FieldError | undefined;
  itemId?: string;
  siteId?: string;
  itemType?: ItemTypeEnum;
  placeholder?: string;
  locationIds?: string[];
  value?: Maybe<string>;
  onChange?: (value: string | undefined, option?: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  types?: LocationTypeEnum[];
  isLocationInput?: boolean;
  name?: string;
}

const getOptionLabel = (option: FormItemLocationOption) => option.name;

function isKitItemType(itemType?: ItemTypeEnum) {
  return Boolean(itemType && [ItemTypeEnum.AssetKit, ItemTypeEnum.InventoryKit].includes(itemType));
}

const FormRestockLocationSelect = forwardRef<HTMLDivElement, FormRestockSiteSelectProps>(
  (props, ref) => {
    const {
      className = 'mt-8',
      disabled,
      placeholder,
      value,
      onBlur,
      onChange,
      types,
      siteId,
      itemId,
      label,
      isRequired,
      error,
      locationIds,
      itemType,
      isLocationInput,
      name
    } = props;

    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState<FormItemLocationOption | null>(null);

    const [{ fetching: isDisabled, data: selectedData }] = useItemLocationDataQuery({
      variables: {
        filters: {
          itemLocationIds: value ? [value] : [],
          itemStatuses: [ItemStatusEnum.Active],
          locationStatuses: [LocationStatusEnum.Active],
        },
      },
      pause: !value || !!selectedValue || (isLocationInput ? !siteId || !itemId : !itemId),
    });

    useEffect(() => {
      if (!value) {
        setSelectedValue(null);
      }
    }, [value]);

    useEffect(() => {
      const selectedOption = selectedData?.itemlocations.edges[0]?.node;

      if (selectedOption) {
        const { site, location } = selectedOption;
        const { id, name: siteName } = site;
        setSelectedValue({
          id: isLocationInput ? location?.id : id,
          name: isLocationInput ? getLocationName(siteId, location) : siteName,
          location,
          site,
        });
      }
    }, [selectedData?.itemlocations.edges]);

    const search = useMemo(() => {
      if (isLocationInput && siteId && isUnassignedLocation(selectedValue?.id || '', siteId)) {
        return selectedValue?.location?.name || inputValue;
      }

      return inputValue;
    }, [isLocationInput, siteId, selectedValue, inputValue]);

    const [{ fetching: isLoadingRestockKitLocations, data: restockItemLocationsData }] =
      useFormRestockKitLocationSelectQuery({
        variables: {
          filters: {
            itemIds: itemId ? [itemId] : [],
            siteIds: siteId ? [siteId] : [],
            search: search,
            locationTypes: isLocationInput ? [] : [LocationTypeEnum.Site],
            itemStatuses: [ItemStatusEnum.Active],
            locationStatuses: [LocationStatusEnum.Active],
          },
          limit: 10000,
        },
        pause: disabled || !itemId || !isKitItemType(itemType) || true, // TODO: We have pause a itemInStockquery, Once backend changes are done, we will enabled it.
      });

    const [{ fetching: isLoadingRestockLocations, data: restockLocationsData }] =
      useFormRestockLocationSelectQuery({
        variables: {
          filters: {
            types: isLocationInput ? [] : [LocationTypeEnum.Site],
            search: search,
            locationIds: locationIds,
            siteId: siteId,
            statuses: [LocationStatusEnum.Active],
          },
          sorts: [
            {
              sortField: 'name',
              sortOrder: SortOrderEnum.Asc,
            },
          ],
          limit: 10000,
        },
        pause: disabled || !itemId,
        // TODO: We have pause a itemInStockquery, Once backend changes are done, we will enabled it.
        // Boolean(
        //   isLoadingRestockKitLocations ||
        //     (restockItemLocationsData?.itemlocations?.edges &&
        //       restockItemLocationsData?.itemlocations?.edges?.length > 0),
        // ),
      });

    const options = useMemo<FormItemLocationOption[]>(() => {
      // TODO: We have pause a itemInStockquery, Once backend changes are done, we will enabled it.
      // if (
      //   itemType &&
      //   isKitItemType(itemType) &&
      //   restockItemLocationsData?.itemlocations?.edges?.length !== 0
      // ) {
      //   return adaptItemLocationToOption({
      //     isFromLocationInput: isLocationInput || false,
      //     itemLocationEdges:
      //       restockItemLocationsData?.itemlocations.edges || ([] as ItemLocationEdge[]),
      //     siteId,
      //   });
      // }
      return adaptLocationToOption(restockLocationsData?.locations?.edges || [], siteId);
    }, [
      restockLocationsData?.locations?.edges,
      restockItemLocationsData?.itemlocations?.edges,
      itemType,
      isLocationInput,
      siteId,
    ]);

    const onValueChange = useCallback(
      (event: React.SyntheticEvent, option: FormItemLocationOption | null) => {
        if (option) {
          setSelectedValue(option);
          onChange?.(option.id, option);
        } else {
          setSelectedValue(null);
          onChange?.(undefined);
        }
      },
      [onChange],
    );

    const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
      setInputValue(value);
    };

    return (
      <Box ref={ref} className={cx('flex flex-col', className)}>
        {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
        <Autocomplete
          className="mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none"
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          label={''}
          loading={isLoadingRestockKitLocations || isLoadingRestockLocations}
          options={options}
          renderInput={(params) => <TextField name={name} {...params} placeholder={placeholder} />}
          value={selectedValue}
          onBlur={onBlur}
          onChange={onValueChange}
          onInputChange={onInputValueChange}
        />
        <FormError error={error} />
      </Box>
    );
  },
);

export default FormRestockLocationSelect;
