import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAssetKitItemMutationVariables = Types.Exact<{
  input: Types.CreateAssetKitItemInput;
}>;


export type CreateAssetKitItemMutation = { __typename?: 'Mutation', createAssetKitItem: { __typename?: 'CreateAssetKitItemPayload', success: boolean, reason?: string | null, assetKitItem?: { __typename?: 'AssetKitItemSchema', id: string } | null } };


export const CreateAssetKitItemDocument = gql`
    mutation createAssetKitItem($input: CreateAssetKitItemInput!) {
  createAssetKitItem(input: $input) {
    success
    reason
    assetKitItem {
      id
    }
  }
}
    `;

export function useCreateAssetKitItemMutation() {
  return Urql.useMutation<CreateAssetKitItemMutation, CreateAssetKitItemMutationVariables>(CreateAssetKitItemDocument);
};