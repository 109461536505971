import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import OrderRequestContextProvider from '../../../modules/orders/provider/OrderRequestContextProvider';
import OrderRequestLayout from '../../../modules/orders/layout/OrderRequestLayout';
import WarningProvider from '../../../components/WarningAlert';
import OrderRequestAgain from '../../../modules/orders/views/OrderRequestAgain';

function OrderRequestAgainPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  const orderId = match.params.id;

  return (
    <WarningProvider>
      <OrderRequestContextProvider>
        <OrderRequestLayout>
          <OrderRequestAgain orderId={orderId} />
        </OrderRequestLayout>
      </OrderRequestContextProvider>
    </WarningProvider>
  );
}

export default withRouter(OrderRequestAgainPage);
