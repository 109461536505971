import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AuditReportTransactionsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TransactionFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AuditReportTransactionsQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', totalCount: number, edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, quantity: number, previousQuantity?: number | null, meta?: { __typename?: 'TransactionMetaSchema', note?: string | null } | null, entity?: { __typename?: 'AssetItemSchema', id: string, sku: string, type: Types.ItemTypeEnum, status: Types.ItemStatusEnum, title: string, category?: { __typename?: 'CategorySchema', id: string, name: string } | null } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, type: Types.ItemTypeEnum, status: Types.ItemStatusEnum, title: string, category?: { __typename?: 'CategorySchema', id: string, name: string } | null } | { __typename?: 'InventoryItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, category?: { __typename?: 'CategorySchema', id: string, name: string } | null } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, category?: { __typename?: 'CategorySchema', id: string, name: string } | null } | null, destinationSite?: { __typename?: 'LocationSchema', id: string, name: string } | null, destinationLocation?: { __typename?: 'LocationSchema', id: string, name: string } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, nextCursor?: string | null } | null } };


export const AuditReportTransactionsDocument = gql`
    query auditReportTransactions($filters: TransactionFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  transactions(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        quantity
        previousQuantity
        meta {
          note
        }
        entity {
          ... on AssetItemSchema {
            id
            sku
            type
            status
            title
            category {
              id
              name
            }
          }
          ... on AssetKitItemSchema {
            id
            sku
            type
            status
            title
            category {
              id
              name
            }
          }
          ... on InventoryItemSchema {
            id
            sku
            status
            type
            title
            category {
              id
              name
            }
          }
          ... on InventoryKitItemSchema {
            id
            sku
            status
            type
            title
            category {
              id
              name
            }
          }
        }
        destinationSite {
          id
          name
        }
        destinationLocation {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      nextCursor
    }
  }
}
    `;

export function useAuditReportTransactionsQuery(options?: Omit<Urql.UseQueryArgs<AuditReportTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<AuditReportTransactionsQuery>({ query: AuditReportTransactionsDocument, ...options });
};