import Undelete from 'app/i18n/Undelete';

import { UndeleteAssetMutation } from '../../views/undeleteAsset/graphql/mutations/generated/undeleteAsset';
import { UndeleteInventoryMutation } from '../../views/undeleteInventory/graphql/mutations/generated/undeleteInventory';

export function isUnDeleteHaveFailedRecords(
  response?:
    | UndeleteAssetMutation['undeleteAssetItems']
    | UndeleteInventoryMutation['undeleteInventoryItems'],
) {
  return Boolean(response && response?.failedCount > 0);
}
export function isUnDeleteHaveSuccessRecords(
  response?:
    | UndeleteAssetMutation['undeleteAssetItems']
    | UndeleteInventoryMutation['undeleteInventoryItems'],
) {
  return Boolean(response && response?.successCount > 0);
}

export function getUnDeleteDialogState(
  response:
    | UndeleteAssetMutation['undeleteAssetItems']
    | UndeleteInventoryMutation['undeleteInventoryItems'],
) {
  return {
    errorCount: response?.failedCount || 0,
    successCount: response?.successCount || 0,
  };
}

export function getUnDeleteSuccessMessage(
  response?:
    | UndeleteAssetMutation['undeleteAssetItems']
    | UndeleteInventoryMutation['undeleteInventoryItems'],
) {
  if (!response) return null;

  const successCount = response?.successCount || 0;
  return getUnDeleteSuccessMessageByCount(successCount);
}

export function getUnDeleteSuccessMessageByCount(count: number) {
  if (count === 1) {
    return `${count} ${Undelete.SuccessMessages.SuccessFullySingleRecordDeleted}`;
  }
  return `${count} ${Undelete.SuccessMessages.SuccessFullyDeleted}`;
}

export function getUnDeleteUnSuccessFullyDeletedMessageByCount(count: number) {
  if (count === 1) {
    return `${count} ${Undelete.SuccessMessages.NotSuccessFullySingleRecordDeleted}`;
  }
  return `${count} ${Undelete.SuccessMessages.NotSuccessFullyDeleted}`;
}
