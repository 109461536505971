import { Box } from '@mui/material';
import { DatePicker } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import { Size } from 'app/types/button';
import { DateFilter } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { reportDateRangeSelectorStyles } from './styles';

const inputFormat = 'MM/dd/yyyy' as const;

interface Props {
  value: DateFilter;
  onChange: (value: DateFilter) => void;
  size?: Size;
}

const ReportDateRangeSelector = (props: Props) => {
  const { value, onChange, size = 'medium' } = props;

  const onStartTimeChange = (startTime: Date | null) => {
    onChange({
      ...value,
      absolute: {
        ...value.absolute,
        startTime: startTime ? moment(startTime).startOf('day').toISOString() : null,
      },
    });
  };

  const onEndTimeChange = (endTime: Date | null) => {
    onChange({
      ...value,
      absolute: {
        ...value.absolute,
        endTime: endTime ? moment(endTime).endOf('day').toISOString() : null,
      },
    });
  };

  return (
    <Stack alignItems="center" className={reportDateRangeSelectorStyles} spacing="space-x-16">
      <Box className="w-3/6">
        <DatePicker
          inputFormat={inputFormat}
          size={size}
          value={value?.absolute?.startTime}
          onChange={onStartTimeChange}
        />
      </Box>
      <Box className="w-3/6">
        <DatePicker
          inputFormat={inputFormat}
          size={size}
          value={value?.absolute?.endTime}
          onChange={onEndTimeChange}
        />
      </Box>
    </Stack>
  );
};

export default ReportDateRangeSelector;
