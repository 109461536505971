import { useCallback, useEffect, useState } from 'react';

import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { LocationStatusEnum } from 'app/types/schema';

interface State {
  categoryIdState: string;
  brands: string[];
  mNames: string[];
  categoryIds: string[];
  siteIds: string[];
  siteIdState: string;
  companyIdsState: string[];
  companyIdState: string;
  editInventoryItemsModal: boolean;
  bulkMoveItemModal: boolean;
  bulkEditItemModal: boolean;
}

interface ImageState {
  imageUrl: string;
  open: boolean;
}

const useInventoryState = () => {
  const { defaultSite } = useCurrentUser();
  const defaultSiteId = defaultSite?.status === LocationStatusEnum.Deleted ? '' : defaultSite?.id;

  const [state, _setState] = useState<State>({
    categoryIdState: '',
    brands: [],
    mNames: [],
    categoryIds: [],
    siteIdState: defaultSiteId || '',
    siteIds: defaultSiteId ? [defaultSiteId] : [],
    companyIdState: '',
    companyIdsState: [],
    editInventoryItemsModal: false,
    bulkMoveItemModal: false,
    bulkEditItemModal: false,
  });

  const [imagePreviewState, _setImagePreviewState] = useState<ImageState>({
    open: false,
    imageUrl: '',
  });

  const setImagePreviewState = useCallback((nextState: Partial<ImageState>) => {
    _setImagePreviewState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  useEffect(() => {
    setState({
      siteIds: state.siteIdState ? [state.siteIdState] : [],
    });
  }, [state.siteIdState]);

  useEffect(() => {
    setState({
      companyIdsState: state.companyIdState === '' ? [] : [state.companyIdState],
    });
  }, [state.companyIdState]);

  useEffect(() => {
    setState({
      categoryIds: state.categoryIdState === '' ? [] : [state.categoryIdState],
    });
  }, [state.categoryIdState]);

  const onCategoryFilterChange = (value: any) => {
    setState({
      categoryIdState: value,
    });
  };

  const onSiteFilterChange = (value: any) => {
    setState({
      siteIdState: value,
    });
  };

  const onCloseImageViewDialog = useCallback(() => {
    setImagePreviewState({
      open: false,
    });
  }, [setImagePreviewState]);

  const showImageViewDialog = useCallback(
    (image: string) => {
      setImagePreviewState({
        imageUrl: image,
        open: true,
      });
    },
    [setImagePreviewState],
  );

  return {
    state,
    filters: {
      brands: state.brands,
      mNames: state.mNames,
      category: {
        value: state.categoryIdState,
        onChange: onCategoryFilterChange,
      },
      site: {
        value: state.siteIdState,
        onChange: onSiteFilterChange,
      },
      company: {
        value: state.companyIdState,
      },
    },
    editInventoryItemsModal: state.editInventoryItemsModal,
    setState,
    siteId: state.siteIdState ? state.siteIdState : undefined,
    imagePreviewState,
    onCloseImageViewDialog,
    showImageViewDialog,
  };
};

export type UseInventoryStateReturnType = ReturnType<typeof useInventoryState>;
export default useInventoryState;
