import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { getTransactionTypeLabel } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import { getTotalCost, getUnitCost } from 'app/modules/reports/utils';
import { formatDate } from 'app/utils/date';

import ReplacementDateTableNode from '../../../../../assets/components/ReplacementDateTableNode';
import ManufactureTableNode from '../../../../../manufacturers/components/ManufactureTableNode';

export const getCustomReportTableColumns = (selectedColumns: string[]) =>
  (
    [
      {
        id: 'sku',
        label: Reports.SKU,
        value: 'sku',
        valueNode: ({ row }) => <ReportSKULink item={row.entity} />,
      },
      {
        id: 'title',
        label: Reports.Title,
        value: 'title',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.title}>
            {row.entity?.title}
          </span>
        ),
      },
      {
        id: 'container',
        label: Reports.Container,
        value: 'container',
        valueNode: ({ row }) => (
          <span className="truncate" title={row?.containerId}>
            {row?.containerId}
          </span>
        ),
      },
      {
        id: 'description',
        label: 'Description',
        value: 'description',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.description}>
            {row.entity?.description}
          </span>
        ),
      },
      {
        id: 'brand',
        label: Reports.Brand,
        value: 'brand',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.brand}>
            {row.entity?.brand}
          </span>
        ),
      },
      {
        id: 'manufacturer',
        label: Reports.Manufacturer,
        value: 'brand',
        valueNode: ({ row }) => <ManufactureTableNode manufacturer={row.entity?.manufacturer} />,
      },
      {
        id: 'serialNumber',
        label: 'Serial Number',
        value: 'serialNumber',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.serialNumber}>
            {row.entity?.serialNumber}
          </span>
        ),
      },
      {
        id: 'vendor',
        label: 'Vendor',
        value: 'vendor',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.vendor?.companyName}>
            {row.entity?.vendor?.companyName}
          </span>
        ),
      },
      {
        id: 'projectCode',
        label: 'Project Code',
        value: 'projectCode',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.project?.projectCode}>
            {row.project?.projectCode}
          </span>
        ),
      },
      {
        id: 'fromSite',
        label: Reports.FromSite,
        value: 'fromSite',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.sourceSite?.name}>
            {row.sourceSite?.name}
          </span>
        ),
      },
      {
        id: 'fromLocation',
        label: Reports.fromLocation,
        value: 'fromLocation',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.sourceLocation?.name}>
            {row.sourceLocation?.name}
          </span>
        ),
      },
      {
        id: 'toLocation',
        label: Reports.toLocation,
        value: 'toLocation',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.destinationLocation?.name}>
            {row.destinationLocation?.name}
          </span>
        ),
      },
      {
        id: 'toSite',
        label: Reports.ToSite,
        value: 'toSite',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.destinationSite?.name}>
            {row.destinationSite?.name}
          </span>
        ),
      },
      {
        id: 'departmentCode',
        label: 'Department Code',
        value: 'departmentCode',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.department?.departmentCode}>
            {row.department?.departmentCode}
          </span>
        ),
      },
      {
        id: 'model',
        label: Reports.Model,
        value: 'model',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.mName || row.entity?.model}>
            {row.entity?.mName || row.entity?.model}
          </span>
        ),
      },
      {
        id: 'modelNumber',
        label: Reports.ModelNumber,
        value: 'modelNumber',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.modelNumber}>
            {row.entity?.modelNumber}
          </span>
        ),
      },
      {
        id: 'transactionType',
        label: 'Transaction Type',
        value: 'transactionType',
        valueNode: ({ row }) => (
          <span className="truncate" title={getTransactionTypeLabel(row.type, row.subType)}>
            {getTransactionTypeLabel(row.type, row.subType)}
          </span>
        ),
      },
      {
        id: 'reason',
        label: 'Reason',
        value: 'reason',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.meta?.reason}>
            {row.meta?.reason}
          </span>
        ),
      },
      {
        id: 'createdAt',
        label: 'Transaction Date',
        value: 'createdAt',
        valueNode: ({ row }) => (
          <span className="truncate" title={formatDate(row.createdAt)}>
            {formatDate(row.createdAt)}
          </span>
        ),
      },
      {
        id: 'category',
        label: Category.Category,
        value: 'category',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.category?.name}>
            {row.entity?.category?.name}
          </span>
        ),
      },
      {
        id: 'categoryCode',
        label: 'Category Code',
        value: 'categoryCode',
        valueNode: ({ row }) => (
          <span className="truncate" title={row.entity?.category?.categoryCode}>
            {row.entity?.category?.categoryCode}
          </span>
        ),
      },
      {
        id: 'costOverride',
        label: 'Cost Override %',
        value: 'costOverride',
        rowAlign: 'right',
        valueNode: ({ row }) => (
          <span
            className="truncate"
            title={row.entity?.costOverride ? `${row.entity?.costOverride}%` : ''}>
            {row.entity?.costOverride ? `${row.entity?.costOverride}%` : null}
          </span>
        ),
      },
      {
        id: 'quantity',
        label: Reports.Quantity,
        value: 'quantity',
        rowAlign: 'right',
      },
      {
        id: 'unitCostWithoutOverride',
        label: Reports.UnitCost,
        value: 'unitCostWithoutOverride',
        rowAlign: 'right',
        valueNode: ({ row }) => (
          <span className="truncate" title={getUnitCost(row.entity?.unitCost)}>
            {getUnitCost(row.entity?.unitCost)}
          </span>
        ),
        width: 250,
      },
      {
        id: 'unitCostWithOverride',
        label: Reports.UnitCostWithOverride,
        value: 'unitCostWithOverride',
        rowAlign: 'right',
        valueNode: ({ row }) => (
          <span className="truncate" title={getUnitCost(row.entity?.cost)}>
            {getUnitCost(row.entity?.cost)}
          </span>
        ),
        width: 250,
      },
      {
        id: 'totalCostWithoutOverride',
        label: Reports.TotalCostWithoutOverride,
        value: 'totalCostWithoutOverride',
        rowAlign: 'right',
        valueNode: ({ row }) => (
          <span className="truncate" title={getTotalCost(row.entity?.unitCost, row.quantity)}>
            {getTotalCost(row.entity?.unitCost, row.quantity)}
          </span>
        ),
        width: 280,
      },
      {
        id: 'totalCostWithOverride',
        label: Reports.TotalCostWithOverride,
        value: 'totalCostWithOverride',
        rowAlign: 'right',
        valueNode: ({ row }) => (
          <span className="truncate" title={getTotalCost(row.entity?.cost, row.quantity)}>
            {getTotalCost(row.entity?.cost, row.quantity)}
          </span>
        ),
        width: 280,
      },
      {
        id: 'replacementDate',
        label: 'Replacement Date',
        value: 'replacementDate',
        valueNode: ({ row }) => (
          <ReplacementDateTableNode replacementDate={row.entity?.replacementDate} />
        ),
      },
    ] as DataGridHeadCell[]
  )
    .filter((column) => selectedColumns.includes(column.id))
    .sort((a, b) => {
      const aIndex = selectedColumns.findIndex((col) => a.id === col);
      const bIndex = selectedColumns.findIndex((col) => b.id === col);

      return aIndex - bIndex;
    });
