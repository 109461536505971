import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';

import { useProjectsSelectQuery } from '../../../modules/projects/graphql/queries/generated/projectsSelect';
import { ProjectSchema, SortOrderEnum } from '../../../types/schema';
import { adaptNodeEdgeToNode } from '../../AsyncMultiSelect/utils';
import FormLabel from '../FormLabel';

export type Option = Pick<ProjectSchema, 'id' | 'name'>;

const getOptionLabel = (option: any) => {
  return option.name;
};

type Props = {
  className?: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string, option: Option | null) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  size?: any;
  id?: string;
  formLabel?:string;
};

const FormProjectSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    value,
    onBlur,
    label,
    onChange,
    error,
    className = 'mt-6',
    isRequired,
    disabled,
    placeholder,
    autoFocus,
    size,
    id,
    formLabel
  } = props;
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const [{ data: selectedData }] = useProjectsSelectQuery({
    variables: {
      filters: {
        projectIds: value ? [value] : [],
      },
    },
    pause: !value || Boolean(!value && !selectedValue) || value === selectedValue?.id,
  });

  const [{ fetching, data }] = useProjectsSelectQuery({
    variables: {
      filters: { search: '' },
      sorts: [
        {
          sortOrder: SortOrderEnum.Asc,
          sortField: 'name',
        },
      ],
      limit: 10000,
    },
  });

  const options = useMemo(() => {
    const options = [];
    options.push(...(data?.projects?.edges?.map(adaptNodeEdgeToNode) || []));
    return options;
  }, [data?.projects?.edges]);

  const onValueChange = useCallback(
    (_: any, option: any) => {
      const updatedOption = option ? (option as Option) : null;
      const id = updatedOption?.id || '';
      onChange?.(id, updatedOption);
      setSelectedValue(updatedOption);
    },
    [onChange, setSelectedValue],
  );

  useEffect(() => {
    const selectedOption = selectedData?.projects.edges?.[0]?.node;
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [selectedData?.projects.edges]);

  useEffect(() => {
    const selectedOption = options.find((option) => option.id === value);
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [options]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;
  return (<>
    {formLabel ? (
      <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
        {formLabel}
      </FormLabel>
    ) : null}
    <div ref={ref}>
      <Autocomplete
        className={className || ''}
        disabled={!!disabled}
        getOptionLabel={getOptionLabel}
        id={id}
        label=""
        loading={fetching}
        options={options as any}
        isOptionEqualToValue={(option, valueForOption) => {
          return option?.id === valueForOption?.id;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={!!autoFocus}
            error={!!error}
            errorMessage={error ? (error.message as string) : ''}
            label={label}
            labelNode={LabelNode}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        size={size}
        value={selectedValue as any}
        onBlur={onBlur}
        onChange={onValueChange}
        onKeyDown={(event: any) => {
          event.stopPropagation();
        }}
      />
    </div>
    </>);
});

export default FormProjectSelect;
