import { useCallback, useEffect, useMemo, useState } from 'react';
import omit from 'lodash/omit';
import { SnackbarService } from 'app/components/Snackbar';
import Assets from 'app/i18n/Assets';
import { prepareBulkEditInputEntries } from 'app/modules/assetsInventory/utils/utils';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useUpdateQuantityConfigurationsAtItemLocationsMutation } from 'app/modules/inventory/components/QuantityBySite/graphql/mutations/generated/updateQuantityConfigurationsAtItemLocations';
import { ItemTypeEnum } from 'app/types/schema';

import { cleanedAttachmentItems } from '../../../assetsInventory/utils/utils';
import { useUpdateAssetItemsMutation } from '../../views/EditAsset/graphql/mutations/generated/updateAssetItems';
import { useUpdateAssetKitItemsMutation } from '../../views/EditAsset/graphql/mutations/generated/updateAssetKitItems';
import { UseSearchAssetsStateReturnType } from '../../views/searchAssets/hook/useSearchAssetsState';
import analytics from 'app/analytics';

type dataMinMaxType = {
  minimumQuantity?: string;
  maximumQuantity?: string;
};

type QuantityBySiteProps = {
  table: UseMultiSelectEntityManagerReturnType['table'];
  tableState: UseSearchAssetsStateReturnType['tableState'];
  siteId?: string;
  bulkEditState: UseSearchAssetsStateReturnType['bulkEditState'];
  formHasErrors?: boolean;
};

const useQuantityBySiteState = (props: QuantityBySiteProps) => {
  const { table, tableState, siteId, bulkEditState, formHasErrors } = props;

  const [tableData, setTableData] =
    useState<UseSearchAssetsStateReturnType['tableState']>(tableState);

  const [{ fetching: updateItemsFetching }, updateAssetItems] = useUpdateAssetItemsMutation();
  const [{ fetching: updateKitItemsFetching }, updateAssetKitItems] =
    useUpdateAssetKitItemsMutation();

  const updateEditableValues = (
    tableState: UseSearchAssetsStateReturnType['tableState'],
    rowIds: string[],
  ) => {
    const updatedRows = tableState.rows.map((row: any) => {
      if (rowIds.includes(row.id)) {
        return {
          ...row,
          editable: true,
          editableFields: {
            minimumQuantity:
              row?.minimumQuantity?.toString() || row.stockInformation[0]?.minimumQuantity,
            maximumQuantity:
              row?.maximumQuantity?.toString() || row.stockInformation[0]?.maximumQuantity,
            pickableThroughOrderRequest: row.pickableThroughOrderRequest,
            title: row.title,
            mName: row.mName,
            modelNumber: row.modelNumber,
            brand: row.brand,
            description: row.description,
            type: row.type,
            categoryId: row.categoryId,
            vendorId: row.vendorId,
            manufacturerId: row.manufacturerId,
          },
        };
      }
      return row;
    });
    setTableData({ ...tableState, rows: updatedRows });
  };

  const onBulkEdit = useCallback(() => {
    // updateEditableValues(tableState, table.state.selectedRowIds)
    bulkEditState.setBulkEditState({
      open: true,
    });
  }, [bulkEditState]);

  const onCancelBulkEdit = useCallback(() => {
    updateEditableValues(tableState, table.state.selectedRowIds);
    bulkEditState.setBulkEditState({
      open: false,
    });
  }, [bulkEditState]);

  const [{ fetching: fetchingQuantity }, onUpdateQuantityAtItemLocations] =
    useUpdateQuantityConfigurationsAtItemLocationsMutation();

  useEffect(() => {
    const updatedRows = tableState.rows.map((tableRow: any) => {
      const existingRow = tableData.rows.find((row: any) => row.id === tableRow.id);

      let row = existingRow ? { ...existingRow, ...tableRow } : tableRow;

      if (!table.state.selectedRowIds.includes(row.id)) {
        row = omit(row, ['editing', 'editableFields']);
      }

      return row;
    });

    setTableData({
      ...tableState,
      rows: updatedRows,
    });
  }, [tableState]);

  const debouncedUpdateTableData = useCallback(
    (rowId: string, data: dataMinMaxType) => {
      let updatedRows = tableData.rows.map((row: any) => {
        if (row.id === rowId) {
          return {
            ...row,
            editableFields: {
              ...row.editableFields,
              ...data,
            },
          };
        }
        return row;
      });
      setTableData((prevTableData) => ({
        ...prevTableData,
        rows: updatedRows,
      }));
    },
    [tableData.rows, setTableData],
  );

  const setEditableSiteState = useCallback(
    (rowId: string, data: dataMinMaxType) => {
      debouncedUpdateTableData(rowId, data);
    },
    [debouncedUpdateTableData],
  );

  const updatedValues: any = useMemo(() => {
    const mapped = [];
    for(let row of tableData.rows){
      // @ts-ignore
      if(table.state.selectedRowIds.includes(row?.id)){
        mapped.push({
          // @ts-ignore
          id: row.id,
          // @ts-ignore
          values: { ...row, ...row.editableFields },
        })
      }
    }
    return mapped;
  }, [table.state.selectedRowIds, tableData]);

  const onSaveBulkEdit = useCallback(async () => {
    const assets: any[] = [];
    const assetKits: any[] = [];

    if (formHasErrors) return;

    updatedValues.forEach((item: any) => {
      const asset = {
        itemId: item.id,
        pickableThroughOrderRequest: item.values.pickableThroughOrderRequest,
        externalProductCodes: [],
        protectedAttachments: cleanedAttachmentItems(item.values?.protectedAttachments) || [],
        attachments: cleanedAttachmentItems(item.values?.attachments) || [],
        title: item.values.title,
        mName: item.values.mName,
        modelNumber: item.values.modelNumber,
        brand: item.values.brand,
        description: item.values.description,
        categoryId: item.values.categoryId,
        vendorId: item.values.vendorId,
        manufacturerId: item.values.manufacturerId,
      };

      if (item.values.type === ItemTypeEnum.AssetKit) {
        const assetKit = {
          assetKitItemId: asset.itemId,
          pickableThroughOrderRequest: asset.pickableThroughOrderRequest,
          title: asset.title,
          description: asset.description,
          categoryId: asset.categoryId,
        };
        assetKits.push(assetKit);
      } else {
        assets.push(asset);
      }
    });

    let promises: any[] = [];

    if (assets.length) {
      const updateAssetsPromise = updateAssetItems({ input: { updateAssets: assets } });
      promises.push(updateAssetsPromise);
    }

    if (assetKits.length) {
      const updateAssetKitsPromise = updateAssetKitItems({ input: { updateAssetKits: assetKits } });
      promises.push(updateAssetKitsPromise);
    }

    if (siteId) {
      const inputValues = prepareBulkEditInputEntries(updatedValues, siteId);
      if (inputValues.length) {
        const updateQuantityPromise = onUpdateQuantityAtItemLocations({
          input: inputValues,
        });
        promises.push(updateQuantityPromise);
      }
    }

    Promise.all([promises])
      .then(() => {
        analytics?.track('Edited', { name: 'Asset Bulk Edit' });
        SnackbarService.show({
          message: Assets.SuccessMessages.AssetItemsUpdated(updatedValues),
        });
        onCancelBulkEdit();
      })
      .catch((error) => {
        console.error('[onSaveBulkEdit] Failed!', error);
      });
  }, [updatedValues, formHasErrors]);

  useEffect(() => {
    updateEditableValues(tableState, table.state.selectedRowIds);
    if (!table.state.selectedRowIds.length) {
      onCancelBulkEdit();
    }
  }, [table.state.selectedRowIds, tableState.rows]);

  return {
    tableData,
    setTableData,
    setEditableSiteState,
    onSaveBulkEdit,
    onBulkEdit,
    onCancelBulkEdit,
    fetchingBulkEdit: fetchingQuantity || updateItemsFetching || updateKitItemsFetching,
  };
};

export type UseQuantityBySiteStateReturnType = ReturnType<typeof useQuantityBySiteState>;

export default useQuantityBySiteState;
