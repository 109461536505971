import { useMemo } from 'react';
import { ItemTypeEnum } from 'app/types/schema';

import { useItemInStockQuery } from '../../graphql/query/generated/itemInStockSelect';

const useKitItems = (kitItems: any[], types?: ItemTypeEnum[]) => {
  const itemIds = kitItems?.length > 0 ? kitItems.map((item) => item.itemId) : [];
  const [{ data, fetching }] = useItemInStockQuery({
    variables: {
      filters: {
        itemIds: itemIds || [],
        types: types || [],
      },
    },
  });

  const items = useMemo(() => {
    return data?.items?.edges?.map((item) => {
      const { node } = item as unknown as {
        node: {
          title: string;
          sku: string;
          id: string;
          cost: string;
          attachments: any[];
          type: ItemTypeEnum;
        };
      };
      const kitItemQuantity = kitItems?.find((kitItem) => kitItem?.itemId === node?.id) || {};
      const sourceLocation = kitItems?.find((item) => item.itemId === node.id);

      return {
        sku: node?.sku,
        cost: node?.cost,
        id: node?.id,
        title: node?.title,
        quantity: Number(kitItemQuantity?.count),
        attachments: node?.attachments,
        type: node?.type,
        target: '_blank',
        sourceLocation: sourceLocation?.location,
      };
    });
  }, [data?.items?.edges]);

  return {
    fetching,
    items: items || undefined,
  };
};

export default useKitItems;
