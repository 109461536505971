import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { DeliveryDetailInput } from '../../types';

interface Props {
  defaultValues: DeliveryDetailInput;
}

const useOrderDeliveryDetailForm = (props: Props) => {
  const { defaultValues } = props;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  const onResetForm = useCallback(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  return {
    formState: {
      control,
    },
    handleSubmit,
    onResetForm,
  };
};

export type UseOrderDeliveryDetailFormReturnType = ReturnType<typeof useOrderDeliveryDetailForm>;

export default useOrderDeliveryDetailForm;
