import { textValidator } from 'app/components/Form/utils/validators';
import Orders from 'app/i18n/Orders';

export const ORDER_DELIVERY_DETAIL_FORM_RULES = {
  shipTo: { validate: textValidator(Orders.FormValidationMessages.ShipToRequired) },
  billTo: {
    validate: textValidator(Orders.FormValidationMessages.BillToRequired),
  },
  deliverToId: {
    validate: textValidator(Orders.FormValidationMessages.DeliverToIdRequired),
  },
};
