import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditManufacturerDataFragment = { __typename?: 'ManufacturerSchema', id: string, name: string, emailId?: string | null, phoneNo?: string | null, url?: string | null };

export const EditManufacturerDataFragmentDoc = gql`
    fragment EditManufacturerData on ManufacturerSchema {
  id
  name
  emailId
  phoneNo
  url
}
    `;