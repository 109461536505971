import React, { Suspense, useMemo } from 'react';

import Loader from '../../components/Loader';
import { isSupportedCsvFormat } from '../../utils/attachments';
const DocFileViewer = React.lazy(() => import('./component/DocViewer'));
const ReactFilePreview = React.lazy(() => import('./component/ReactFilePreview'));

interface Props {
  filePath: string;
}

const FileViewer = (props: Props) => {
  const { filePath } = props;

  const isCsvTypeFile = useMemo(() => isSupportedCsvFormat(filePath), [filePath]);
  return (
    <Suspense fallback={<Loader />}>
      {isCsvTypeFile ? (
        <ReactFilePreview filePath={filePath} />
      ) : (
        <DocFileViewer filePath={filePath} />
      )}
    </Suspense>
  );
};

export default FileViewer;
