import { cx } from '@emotion/css';
import times from 'lodash/times';
import Loader from 'app/components/Loader';
import Warehouse from 'app/i18n/Warehouse';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import WarehouseItem from '../../../WarehouseDetails/components/WarehouseItem';
import WarehouseItemsContainer from '../../../WarehouseDetails/components/WarehouseItemsContainer';
import WarehouseLabel from '../../../WarehouseDetails/components/WarehouseLabel';
import {
  getWarehouseLocations,
  parseWarehouseLocationName,
  WAREHOUSE_LABEL_CONFIG,
} from '../../../WarehouseDetails/utils';
import { useWarehouseLocationsQuery } from '../../graphql/queries/generated/warehouseLocations';
import { WarehouseLocationsProps } from '../../types';

const WarehouseBins = (props: WarehouseLocationsProps) => {
  const { warehouseId, selectedItems } = props;

  const item = selectedItems[3];
  const { zone, aisle, rack, shelf, bin } = parseWarehouseLocationName(item);

  const [{ fetching, data }] = useWarehouseLocationsQuery({
    variables: {
      filters: { warehouseIds: [warehouseId] },
      input: {
        zoneName: zone,
        aisleNumber: Number(aisle),
        rackNumber: Number(rack),
        shelfName: shelf,
        maxChildIdentifier: bin,
      },
    },
    requestPolicy: 'cache-and-network',
  });

  if (fetching) {
    return <Loader />;
  }

  const bins = getWarehouseLocations(data);
  const label = WAREHOUSE_LABEL_CONFIG.shelves.getLabelFromLocation(item);

  return (
    <Stack className="mt-48" direction="col" spacing="space-y-10">
      <Stack alignItems="center" justifyContent="between">
        <WarehouseLabel>{label}</WarehouseLabel>
      </Stack>
      <Stack direction="col">
        {bins.length ? (
          <WarehouseItemsContainer>
            <Stack direction="col" spacing="space-y-10">
              <p className={cx('text-13 font-bold text-[#666666]')}>Bins</p>
              <Box className="grid grid-cols-4 gap-6 lg:grid-cols-12">
                {times(bins.length).map((__, itemIndex) => {
                  const config = WAREHOUSE_LABEL_CONFIG.bins;
                  return <WarehouseItem key={itemIndex} label={config.getLabel(itemIndex)} />;
                })}
              </Box>
            </Stack>
          </WarehouseItemsContainer>
        ) : (
          <Stack className="py-32" justifyContent="center">
            <WarehouseLabel>{Warehouse.NoZoneAvailable}</WarehouseLabel>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default WarehouseBins;
