import 'app/modules/orders/views/OrderRequest/components/OrderRequestForm/order-request.css';
import { useCallback, useEffect, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout } from '@procurenetworks/procure-component-library';
import { WarningService } from 'app/components/WarningAlert';
import Orders from 'app/i18n/Orders';
import { FeatureFlagProvider } from 'app/libs/featureFlag';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import { OrderRequestContextService } from '../../provider/OrderRequestContextProvider';
import { getOrderLayoutTabs } from '../utils';
import { redirectToError5xxPage } from '../../../../utils/httpCode';
import _ from 'lodash';

type Props = React.PropsWithChildren<RouteComponentProps> & { disabled?: boolean };

function OrderRequestLayout(props: Props) {
  const { children, history, disabled } = props;
  const { workspacePermissions } = useCurrentUser();

  const onShowUnSavedChangesMessage = useCallback((onConfirm: () => void) => {
    WarningService.showWarning({
      message: Orders.FormValidationMessages.UnSavedChangesMessage,
      onConfirm: () => {
        OrderRequestContextService.clearDataFromStorage();
        onConfirm();
      },
    });
  }, []);

  const onTabChange = useCallback(
    (tab: string) => {
      if (
        tab === 'history' &&
        OrderRequestContextService.orderItemLength &&
        OrderRequestContextService.orderItemLength > 0
      ) {
        onShowUnSavedChangesMessage(() => {
          history.push(`/orders/${tab}`);
        });
      } else {
        OrderRequestContextService.clearDataFromStorage();
        history.push(`/orders/${tab}`);
      }
    },
    [history, onShowUnSavedChangesMessage],
  );

  const tabs = useMemo(() => getOrderLayoutTabs(workspacePermissions), [workspacePermissions]);

  const isTabActive = useCallback(
    (tab: string) => {
      return history.location.pathname.includes(`/${tab}`);
    },
    [history.location.pathname],
  );

  const onLinkClick = useCallback(
    (next: () => void) => {
      if (
        OrderRequestContextService.orderItemLength &&
        OrderRequestContextService.orderItemLength > 0
      ) {
        onShowUnSavedChangesMessage(() => {
          OrderRequestContextService.clearDataFromStorage();
          next();
        });
      } else {
        OrderRequestContextService.clearDataFromStorage();
        next();
      }
    },
    [onShowUnSavedChangesMessage],
  );

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader onLinkClick={onLinkClick} />,
      }}
      mainClassName="!border-0">
      <Nav
        tabs
        className="flex gap-[8px] rounded-tl-[12px] rounded-tr-[12px] bg-white p-[16px] md:p-[24px] xl:p-[24px]">
        {tabs.map(({ label, value }) => (
          <NavItem key={value}>
            <NavLink
              className={getTabNavLinkClassName(isTabActive(value), disabled)}
              disabled={disabled}
              onClick={() => onTabChange(value)}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <FeatureFlagProvider>{children}</FeatureFlagProvider>
    </Layout>
  );
}

export default withRouter(OrderRequestLayout);
