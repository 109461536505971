import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletePartnerTenantMutationVariables = Types.Exact<{
  input: Types.DeletePartnerTenantsInput;
}>;


export type DeletePartnerTenantMutation = { __typename?: 'Mutation', deletePartnerTenant: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeletePartnerTenantDocument = gql`
    mutation deletePartnerTenant($input: DeletePartnerTenantsInput!) {
  deletePartnerTenant(input: $input) {
    success
    reason
  }
}
    `;

export function useDeletePartnerTenantMutation() {
  return Urql.useMutation<DeletePartnerTenantMutation, DeletePartnerTenantMutationVariables>(DeletePartnerTenantDocument);
};