import { APP_CONFIG } from 'app/consts/config';

const requestOptions = {
  method: 'GET'
};

export const autoCompleteAddress = async(str: string) => {
  const URL = `${APP_CONFIG.goopify}?text=${str}&apiKey=${APP_CONFIG.geopifyApiKey}`;
  try {
    const response = await fetch(URL, requestOptions)
    return response.json();
  } catch(error: any){
    console.error('[AutoCompleteAddress] Failed', error);
    return [];
  }
}
