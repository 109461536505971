import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProductQuantityInWishlistMutationVariables = Types.Exact<{
  input: Types.UpdateProductQuantityInWishlistInput;
}>;


export type UpdateProductQuantityInWishlistMutation = { __typename?: 'Mutation', updateProductQuantityInWishlist: { __typename?: 'UpdateWishlistPayload', success: boolean, reason?: string | null, wishlist?: { __typename?: 'WishlistSchema', id: string, name: string } | null } };


export const UpdateProductQuantityInWishlistDocument = gql`
    mutation updateProductQuantityInWishlist($input: UpdateProductQuantityInWishlistInput!) {
  updateProductQuantityInWishlist(input: $input) {
    success
    reason
    wishlist {
      id
      name
    }
  }
}
    `;

export function useUpdateProductQuantityInWishlistMutation() {
  return Urql.useMutation<UpdateProductQuantityInWishlistMutation, UpdateProductQuantityInWishlistMutationVariables>(UpdateProductQuantityInWishlistDocument);
};