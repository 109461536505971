import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { DetailsComponentProps } from 'app/modules/network/types';
import Stack from 'app/ui-components/Stack';

const DetailsComponent = (props: DetailsComponentProps) => {
  const { data, heading } = props;
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Typography className='text-grey-900 font-semibold text-[18px]'>
          {heading}
        </Typography>
        <Divider className='my-[16px]'/>
        <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
          <CardContent>
            <Grid container columns={{ xs: 8, sm: 8, md: 12, lg: 12 }} spacing={2}>
              {data?.length &&
                data.map((item, index) => {
                  return (
                    <Grid key={index} item lg={3} md={3} sm={6} xs={6}>
                      <Stack className="mt-2" direction="col">
                        <Typography className='text-[16px] text-grey-900 font-semibold'>{item.label}</Typography>
                        <Typography className='text-[14px] text-grey-800 font-semibold mt-[8px]'>
                          {item.value || 'Not Available'}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DetailsComponent;
