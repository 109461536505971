import _ from 'lodash';

import { FormState } from '../../Labels/components/Forms/types';
import { CodeSchema } from '../components/types';

const getCodesList = (
  codes: FormState & {
    data: Array<CodeSchema>;
  },
  chunkSize: number,
) => {
  const codeOffset = parseInt(codes?.offset || '0', 10);
  const codeList = [
    ...Array.from(Array(codeOffset)).map(() => ({ isBlank: true })),
    ...codes?.data,
  ];

  let codesChunk = _.chunk(codeList, chunkSize);
  const lastChunk = codesChunk[codesChunk.length - 1]
  codesChunk[codesChunk.length - 1] = [...lastChunk, ...Array.from(Array(chunkSize - lastChunk.length)).map(() => ({ isBlank: true }))]
  
  return codesChunk;
};

export default getCodesList;
