import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAssetKitItemMutationVariables = Types.Exact<{
  input: Types.UpdateAssetKitItemInput;
}>;


export type UpdateAssetKitItemMutation = { __typename?: 'Mutation', updateAssetKitItem: { __typename?: 'UpdateAssetKitItemPayload', success: boolean, reason?: string | null, assetKitItem?: { __typename?: 'AssetKitItemSchema', id: string } | null } };


export const UpdateAssetKitItemDocument = gql`
    mutation updateAssetKitItem($input: UpdateAssetKitItemInput!) {
  updateAssetKitItem(input: $input) {
    success
    reason
    assetKitItem {
      id
    }
  }
}
    `;

export function useUpdateAssetKitItemMutation() {
  return Urql.useMutation<UpdateAssetKitItemMutation, UpdateAssetKitItemMutationVariables>(UpdateAssetKitItemDocument);
};