import { composeValidators, confirmValidator, emailValidator, textValidator} from 'app/components/Form/utils/validators';
import SignUp from 'app/i18n/Signup';

export const SIGNUP_FORM_RULES = {
  firstName: {
    validate: textValidator(SignUp.FormValidations.FirstName),
  },
  lastName: {
    validate: textValidator(SignUp.FormValidations.LastName),
  },
  password: {
    validate: textValidator(SignUp.FormValidations.Password),
  },
  confirmPassword: (password: string, ConfirmPassword?: string) => {
    return {
      validate: composeValidators(
        textValidator(SignUp.FormValidations.Password),
        confirmValidator(SignUp.FormValidations.ConfirmNewPasswordNotMatched, {
          _value: password,
          confirmValue: ConfirmPassword,
        }),
      ),
    }
  },
  email: {
    validate: composeValidators(
      textValidator(SignUp.FormValidations.EmailRequired),
      emailValidator(SignUp.FormValidations.EmailInvalid),
    ),
  },
  confirmEmail: (email: string, confirmEmail?: string) => {
    return {
      validate: composeValidators(
        textValidator(SignUp.FormValidations.EmailRequired),
        emailValidator(SignUp.FormValidations.EmailInvalid),
        confirmValidator(SignUp.FormValidations.ConfirmEmailNotMatched, {
          _value: email,
          confirmValue: confirmEmail,
        }),
      ),
    }
  },
  organization: {
    validate: textValidator(SignUp.FormValidations.Organization),
  },
};
