import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UserFilterQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.UserFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UserFilterQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'UserSchema', id: string, firstName: string, lastName?: string | null, name: string } }> } };


export const UserFilterDocument = gql`
    query UserFilter($filters: UserFilters, $sorts: [SortProps!], $limit: Float) {
  users(filters: $filters, sorts: $sorts, limit: $limit) {
    edges {
      node {
        id
        firstName
        lastName
        name
      }
    }
  }
}
    `;

export function useUserFilterQuery(options?: Omit<Urql.UseQueryArgs<UserFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<UserFilterQuery>({ query: UserFilterDocument, ...options });
};