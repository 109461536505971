import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { useNetworkAssetsTableQuery } from 'app/modules/network/graphql/queries/generated/networkAssetTable';
import { processSearch } from 'app/utils/processSearch';

import { NetworkAssetSchema, NetworkAssetStatusEnum } from '../../../../../../types/schema';

const useSearchSuggestion = (statuses: NetworkAssetStatusEnum[], discovered: boolean) => {

  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();
  const [{ data }] = useNetworkAssetsTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        statuses: statuses
      },
      limit: 5
    },
  });

  useEffect(() => {
    const result = data?.networkAssets?.edges?.map((a) => {
      const item = a.node as NetworkAssetSchema;

      const { result, searchBy } = processSearch(
        discovered ? ['mac', 'userName', 'ipAddress'] : ['mac', 'userName', 'ipAddress', 'operatingSystem.platform', 'cpu.brand', 'cpu.manufacturer'],
        searchedValue,
        item,
      );

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });

    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
