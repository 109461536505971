import { useCallback, useState } from 'react';
import { MediaSchema } from 'app/types/schema';

import { UseFormFileUploaderReturnType } from '../../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import {
  filterAttachmentsById,
  filterAttachmentsByImageUrlName,
  getAttachmentUrlsFromImageUrlName,
  parseAttachmentsToIds,
  parseMediaSchemaToAttachments,
} from './utils';

const usePersistAttachmentsHelper = (
  fileUploaderProps: UseFormFileUploaderReturnType['inputProps'],
) => {
  const { attachments, getFileIdsFromUrls, removeFileById, addFileFromUrl, setAttachments } =
    fileUploaderProps;
  const [upcProductImages, setUpcProductImages] = useState<string[]>([]);
  const [assetModelAttachmentIds, setAssetModelAttachmentIds] = useState<string[]>([]);

  const removeOldUpcImages = useCallback(() => {
    const deletedUpcImageUrls: string[] = getAttachmentUrlsFromImageUrlName(
      attachments,
      upcProductImages,
    );
    const deletedUpcProductImageIds = getFileIdsFromUrls?.(deletedUpcImageUrls) || [];
    deletedUpcProductImageIds.forEach((fileId) => {
      removeFileById(fileId);
    });
  }, [attachments, getFileIdsFromUrls, upcProductImages, removeFileById]);

  const parseNoUpcAssetAttachments = useCallback(() => {
    removeOldUpcImages();
    setAttachments(filterAttachmentsByImageUrlName(attachments, upcProductImages));
    setUpcProductImages([]);
  }, [removeOldUpcImages, setAttachments, attachments, setUpcProductImages, upcProductImages]);

  const parseProduceDataImagesToAssetAttachments = useCallback(
    (images: string[]) => {
      removeOldUpcImages();
      setAttachments(filterAttachmentsByImageUrlName(attachments, upcProductImages));
      setUpcProductImages(images);
      images.forEach((image: string) => {
        addFileFromUrl(image);
      });
    },
    [
      removeOldUpcImages,
      setAttachments,
      addFileFromUrl,
      upcProductImages,
      attachments,
      setUpcProductImages,
    ],
  );

  // Asset Model to Asset Attachments
  const removeOldAssetModelAttachments = useCallback(() => {
    assetModelAttachmentIds.forEach((fileId) => {
      removeFileById(fileId);
    });
  }, [assetModelAttachmentIds, attachments]);

  const parseAssetModelAttachmentToAssetAttachments = useCallback(
    (assetModelAttachments: MediaSchema[]) => {
      removeOldAssetModelAttachments();
      const updatedAssetAttachments = filterAttachmentsById(attachments, assetModelAttachmentIds);
      const newAssetModelAttachments = parseMediaSchemaToAttachments(assetModelAttachments);
      const newAssetModelAttachmentIds: string[] = parseAttachmentsToIds(newAssetModelAttachments);
      setAssetModelAttachmentIds(newAssetModelAttachmentIds);
      setAttachments([...updatedAssetAttachments, ...newAssetModelAttachments]);
    },
    [
      setAttachments,
      removeOldAssetModelAttachments,
      attachments,
      assetModelAttachmentIds,
      setAssetModelAttachmentIds,
    ],
  );

  return {
    parseNoUpcAssetAttachments,
    parseProduceDataImagesToAssetAttachments,
    parseAssetModelAttachmentToAssetAttachments,
  };
};

export default usePersistAttachmentsHelper;
