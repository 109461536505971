import { useState } from 'react';
import Modal from 'app/components/Modal';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import DuplicateFormWrapper from '../duplicateFormWrapper';

type Props = RouteComponentProps & {
  onCancel: () => void;
  isOpen: boolean;
  contactId: string;
};
const ContactFormModal = (props: Props) => {
  const { isOpen, onCancel, contactId = '' } = props;
  const [isDuplicateContact, setIsDuplicateContact] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);

  return (
    <Modal
      id="hoover-product-detail-modal"
      open={isOpen}
      title={
        contactId
          ? Contact.UpdateContact
          : isDuplicateContact
          ? Contact.DuplicateContact
          : Contact.AddNewContact
      }
      onScroll={(event: any) => event?.currentTarget?.scrollTop > 20 && setAddressOpen(false)}
      onClose={onCancel}>
      <div className="w-[100%]">
        <DuplicateFormWrapper
          isContactModal
          form="Contact"
          hideActions={false}
          addressOpen={addressOpen}
          setAddressOpen={setAddressOpen}
          id={contactId}
          setIsDuplicateContact={setIsDuplicateContact}
          onCloseModal={onCancel}
        />
      </div>
    </Modal>
  );
};

export default withRouter(ContactFormModal);
