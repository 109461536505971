import { useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Role from 'app/i18n/Role';
import Box from 'app/ui-components';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import RolePermissionsSelector from '../RolePermissionsSelector';
import { ROLES_FORM_RULES as rules } from './utils/rules';
import { AddRoleFormProps } from './types';
import ScrollToError from 'app/utils/ScrollToError';

function RoleForm(props: AddRoleFormProps) {
  const { defaultValues, disabled, disableEdit, onCancel, onSubmit } = props;

  const { handleSubmit, control } = useForm({ mode: 'all', defaultValues });
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const updatedValues = {
          ...values,
          name: removeMultipleSpaces(values.name),
          description: values?.description || '',
        };
        onSubmit(updatedValues);
      }, (errors) => {
        ScrollToError(errors, formRef)
      }),
    [handleSubmit, onSubmit],
  );
  const fieldClassName = disableEdit ? 'pointer-events-none cursor-not-allowed' : '';
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={formRef}>
      <form onSubmit={onFormSubmit}>
        <Box className="space-y-24">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                autoFocus
                isRequired
                characterLimit={30}
                className={fieldClassName}
                error={fieldState.error}
                isDisabled={disableEdit}
                label={Role.FormLabels.Name}
              />
            )}
            rules={rules.name}
          />
          <Controller
            control={control}
            name="description"
            render={({ field, fieldState }) => (
              <FormTextArea
                {...field}
                characterLimit={500}
                className={fieldClassName}
                disabled={disableEdit}
                error={fieldState.error}
                label={Role.FormLabels.Description}
              />
            )}
          />
          <Controller
            control={control}
            name="metadata.configuredPermissions"
            render={({ field: { onChange, value = [] } }) => (
              <RolePermissionsSelector
                disabled={disableEdit}
                hideNotSelectedItems={disableEdit}
                selectedEntityPermissionKeys={value}
                onChange={onChange}
              />
            )}
          />
          <Box className="flex justify-end space-x-16">
            {disableEdit ? null : (
              <Button
                disabled={disableEdit || disabled}
                loading={disabled}
                theme="success"
                onClick={onFormSubmit}
                classes="min-w-[94px] h-[44px]">
                {Common.Actions.Save}
              </Button>
            )}
            <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Cancel}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default RoleForm;
