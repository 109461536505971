import { Divider, Typography } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { useAccessControl } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import Common from 'app/i18n/Common';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  NetworkAssetSchema,
} from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import useImportAssetData from '../../hook/useImportAssetData';

type Props = {
  networkAssetDetail: NetworkAssetSchema | undefined;
} & RouteComponentProps;

const NetworkAssetDetailHeader = (props: Props) => {
  const { networkAssetDetail, history } = props;
  const { workspacePermissions } = useCurrentUser();
  const { handleImportClick, importingAssets } = useImportAssetData(props);
  const editHandler = () => {
    history.push(routes.NetworkAssetEdit(networkAssetDetail?.id || ''));
  };

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  const canImport = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  return (
    <>
      <Stack className="mt-8" direction="col">
        <Stack alignItems="center" className="my-[24px]" spacing="gap-[16px]">
          {canEdit ? (
            <Button classes="w-[140px]" theme={'info'} onClick={editHandler}>
              {Common.Actions.Edit}
            </Button>
          ) : null}
          {canImport ? (
            <Button
              classes="w-[140px]"
              loading={importingAssets}
              theme={'success'}
              onClick={handleImportClick}>
              {Common.Actions.Import}
            </Button>
          ) : null}
        </Stack>
        <Typography color="text.primary" sx={{ fontSize: 16 }}>
          {networkAssetDetail?.type}
        </Typography>
      </Stack>
      <Stack className="mt-2" justifyContent="between">
        <Typography color="text.primary" sx={{ fontSize: 32 }}>
          {networkAssetDetail?.ipAddress}
        </Typography>
      </Stack>
      <Stack className="m-2" justifyContent="between">
        <Typography color="text.secondary" sx={{ fontSize: 12 }}>
          {networkAssetDetail?.system?.manufacturer} / {networkAssetDetail?.system?.model}
        </Typography>
      </Stack>
    </>
  );
};

export default withRouter(NetworkAssetDetailHeader);
