import { ItemLocationSchema, LocationSchema, ShippingContainerSchema } from "app/types/schema"

import { LabelTypeEnum } from "../../Labels/components/Forms/types"
import { CodeSchema } from "../components/types"

const getCodeData = (item: CodeSchema, type: LabelTypeEnum | undefined) => {
    switch (type) {
        case LabelTypeEnum.Shipping:
        case LabelTypeEnum.ShippingSetUp:
            return {id: (item as ShippingContainerSchema)?.containerId, value: `containerId:${(item as ShippingContainerSchema)?.containerId}`}
        case LabelTypeEnum.Location:     
            return {id: (item as LocationSchema)?.id, value: `location:${(item as LocationSchema)?.id}`}
        default:
            return {id: (item as ItemLocationSchema)?.id, value: `itemLocation:${(item as ItemLocationSchema)?.id}`}
    }
}

export default getCodeData