import { OrderRequestItemSchema } from '../../../../types/schema';
import TableImageView from '../../../components/TableImageView';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  onImageClick?: (imageUrl: string) => void;
}

const OrderRequestItemImageView = (props: Props) => {
  const { orderRequestItem, onImageClick } = props;
  const { imageUrl, type, item } = orderRequestItem;
  const { attachments } = item || {};

  if (attachments) {
    return <TableImageView attachments={attachments} onImageClick={onImageClick} />;
  }

  if (imageUrl) {
    return <TableImageView url={imageUrl} onImageClick={onImageClick} />;
  }

  return null;
};

export default OrderRequestItemImageView;
