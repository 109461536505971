import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flatten, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Receivables from 'app/i18n/Receivables';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useOrderHistoryTableQuery } from 'app/modules/orders/views/OrderHistory/graphql/query/generated/orderHistoryTable';
import { useCreateReceivablesMutation } from 'app/modules/receiving/graphql/mutations/generated/createReceivables';
import { useItemsReceivableInOrderQuery } from 'app/modules/receiving/graphql/queries/generated/itemsReceivableInOrder';
import { CreateReceivablesInput, OrderRequestItemStatusEnum } from 'app/types/schema';

import useItemsToBeReceivedForm from '../useItemsToBeReceivedForm';
import analytics from 'app/analytics';

function useItemsToBeReceived(props: any) {
  const { step1Data, history } = props;
  const { table } = useMultiSelectEntityManager({ selection: true });
  const previousSelectedRowIds = useRef(table.state.selectedRowIds);
  const [itemsOrdered, setItemsOrdered] = useState<any[]>([]);
  const [sortModel, setSortModel] = useState<any[]>([]);
  const [vendor, setVendor] = useState({
    companyName: '',
  });

  const { formState, control, handleSubmit, onReset, clearErrors, watch } =
    useItemsToBeReceivedForm();
  const [{ fetching: isCreating }, onCreateReceivablesMutation] = useCreateReceivablesMutation();

  const orderRequestIds = useMemo(() => {
    return step1Data.map((row: any) => row.orderRequestId);
  }, []);

  const [{ fetching: isLoadingOrderedItems, data }] = useItemsReceivableInOrderQuery({
    variables: {
      filter: {
        orderRequestIds,
      },
    },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (previousSelectedRowIds.current.length > 0 && table.state.selectedRowIds.length === 0) {
      clearErrors(['vendorId', 'dueDate']);
    }
    previousSelectedRowIds.current = table.state.selectedRowIds;
  }, [table.state.selectedRowIds]);

  const orderRequestItems = useMemo(() => {
    if (!data?.orderRequests?.edges?.[0]?.node.receivingOrderRequestItems.errorCode) {
      return flatten(
        data?.orderRequests?.edges?.map((edge: any) => {
          return edge?.node.receivingOrderRequestItems?.orderRequestItems?.map((row: any) => {
            return {
              ...row,
              orderType: row.orderRequest?.type,
              orderRequestCode: row.orderRequest?.orderRequestCode,
              orderId: row.orderRequest?.id,
              orderRequestId: row.orderRequest?.id,
              itemId: row.itemId,
            };
          });
        }),
      );
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (itemsOrdered.length === 0) {
      const modifyOrderRequestItems = orderRequestItems.map((orderItem: any) => {
        const findItem = step1Data.find(
          (findItem: any) => findItem.orderRequestId === orderItem.orderId,
        );

        if (findItem) {
          return {
            ...orderItem,
            notes: findItem.notes || '',
            formattedDescription: findItem.formattedDescription,
            itemDescription: (
              orderItem?.upcCode ||
              orderItem?.website ||
              orderItem?.description ||
              orderItem.item?.title ||
              ''
            ).trim(),
          };
        }
        return {
          ...orderItem,
          itemDescription: (
            orderItem?.upcCode ||
            orderItem?.website ||
            orderItem?.description ||
            orderItem.item?.title ||
            ''
          ).trim(),
        };
      });

      setItemsOrdered(modifyOrderRequestItems);
    }
  }, [orderRequestItems]);

  const sortedItems = useMemo(() => {
    if (!isEmpty(sortModel)) {
      const sortBy = sortModel[0];

      return orderBy(itemsOrdered, [sortBy.field], [sortBy.sort]);
    }

    return itemsOrdered;
  }, [itemsOrdered, sortModel]);

  //function is used to reset the form & table value
  const resetCreateReceivablesForm = () => {
    setVendor({ companyName: '' });
    onReset();
    table.setState({ selectedRowIds: [] });
  };

  //function used to create the payload for order Request item submission
  const createOrderRequestPayload = (rows: any) => {
    let orderRequest: any = {};

    rows.forEach((row: any) => {
      if (!orderRequest[row.orderRequest.id]) {
        orderRequest[row.orderRequest.id] = {
          orderRequestId: row.orderRequestId,
          notes: row.notes,
          formattedNotes: row.formattedDescription,
          itemConfigs: [
            {
              quantityOrdered: row.quantity,
              vendorId: row.vendorId,
              carrier: row.carrier,
              trackingNumber: row.trackingNumber,
              dueDate: moment(row?.dueDate).endOf('day').toISOString(),
              ...(row.type === 'NO_SKU' ? { orderRequestItemId: row.id } : { itemId: row.itemId }),
            },
          ],
        };
      } else {
        orderRequest[row.orderRequest.id].notes = row.notes;
        orderRequest[row.orderRequest.id].formattedNotes = row.formattedDescription;
        orderRequest[row.orderRequest.id].itemConfigs.push({
          quantityOrdered: row.quantity,
          vendorId: row.vendorId,
          carrier: row.carrier,
          trackingNumber: row.trackingNumber,
          dueDate: moment(row?.dueDate).endOf('day').toISOString(),
          ...(row.type === 'NO_SKU' ? { orderRequestItemId: row.id } : { itemId: row.itemId }),
        });
      }
    });
    return orderRequest;
  };

  // function use to full-fill payload promises
  const handlePayloadPromise = (promises: any, remainFilterItems: any) => {
    const { selectedRowIds } = table.state;
    Promise.all(promises).then((response: any) => {
      analytics?.track('Created', { name: 'Receivables' });
      const allSucceeded = response.every((res: any) => res.data?.createReceivables?.success);
      if (allSucceeded) {
        onReset();
        SnackbarService.show({
          message: Receivables.SuccessMessages.ReceivablesScheduled,
        });
        if (!remainFilterItems.length) {
          history.push(routes.ReceivingReceive());
          return;
        } else if (selectedRowIds.length > 0) {
          setItemsOrdered(remainFilterItems);
        } else {
          history.push(routes.ReceivingReceive());
        }
        resetCreateReceivablesForm();
      } else {
        console.error('[Failed to Schedule Shipment]', response);
      }
    });
  };

  //function is used to handle submit event of shipment.
  const createReceivables = useCallback(
    (values: any) => {
      const { selectedRowIds } = table.state;
      const { vendorId, carrier, trackingNumber, dueDate } = values;
      const newRows = itemsOrdered.map((row: any) => {
        return {
          ...row,
          vendorId,
          vendor,
          carrier,
          trackingNumber,
          dueDate,
        };
      });

      const filterSelected = newRows.filter((filterItem) => selectedRowIds.includes(filterItem.id));

      let payloadResult;

      payloadResult = createOrderRequestPayload(filterSelected);

      const payload: CreateReceivablesInput[] = Object.values(payloadResult);

      const remainFilterItems = newRows.filter((items) => !selectedRowIds.includes(items.id));
      const promises = payload.map((shipment) => onCreateReceivablesMutation({ input: shipment }));

      handlePayloadPromise(promises, remainFilterItems);
    },
    [formState, itemsOrdered, table, setItemsOrdered],
  );

  const vendorId = watch('vendorId');
  const dueDate = watch('dueDate');

  const requiredInfoAvailable = vendorId && dueDate;

  const resetRowSelection = useCallback(() => {
    table.setState({ selectedRowIds: [] });
  }, [table]);

  const handleSortModelChange = useCallback(
    (newSortModel: any) => {
      setSortModel(newSortModel);
    },
    [sortModel],
  );

  return {
    table,
    formState,
    control,
    sortModel,
    handleSubmit,
    onReset,
    itemsOrdered: sortedItems,
    setItemsOrdered,
    isLoadingOrderedItems,
    isCreating,
    vendor,
    setVendor,
    createReceivables,
    resetRowSelection,
    requiredInfoAvailable,
    handleSortModelChange,
  };
}

export default useItemsToBeReceived;
