import { ItemUnion, MoveTransactionEntityInput, TransactionEntityTypeEnum } from 'app/types/schema';

export const prepareCreateMoveTransactions = (
  items: ItemUnion[],
  options: {
    destinationSiteId: string;
    destinationLocationId: string;
    type: TransactionEntityTypeEnum;
  },
): MoveTransactionEntityInput[] => {
  const { destinationLocationId, destinationSiteId, type } = options;
  const createMoveTransactions: MoveTransactionEntityInput[] = [];

  items.forEach((item: ItemUnion) => {
    item.stockInformation?.forEach((stockInformationItem) => {
      const { location, totalQuantity, siteId, itemId } = stockInformationItem;

      // if (totalQuantity === null || totalQuantity === 0) {
      //   return;
      // }

      createMoveTransactions.push({
        destinationSiteId: destinationSiteId,
        destinationLocationId: destinationLocationId,
        entityType: item?.type as unknown as TransactionEntityTypeEnum,
        entityId: itemId,
        quantity: totalQuantity || 0,
        sourceSiteId: siteId,
        sourceLocationId: location?.id,
      });
    });
  });

  return createMoveTransactions;
};
