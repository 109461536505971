import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import Network from 'app/i18n/Network';

type Props = {
  control: any;
  className?:string;
};

const BiosForm = (props: Props) => {
  const { control,className="mt-[0px]" } = props;

  return (
    <>
      <Typography
        className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Bios.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="biosVendor"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Bios.Vendor}
                placeholder={Network.FormLabels.Bios.Vendor}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="biosVersion"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Bios.Version}
                placeholder={Network.FormLabels.Bios.Version}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="biosRelease"
            render={({ field, fieldState }) => (
              <FormDatePickerInput
                {...field}
                key="releaseDate"
                classes="mt-0"
                error={fieldState.error}
                label={Network.FormLabels.Bios.Release}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BiosForm;
