import Modal from '../../../components/Modal';

interface Props {
  open: boolean;
  id: string;
  imageUrl?: string;
  onClose?: () => void;
  dataTestId?: string;
}

const ImageViewDialog = (props: Props) => {
  const { imageUrl, open, id, onClose, dataTestId } = props;

  return (
    <Modal data-testid={id} id={id || 'image-preview'} open={open} onClose={onClose}>
      <img
        className="align-items-center flex h-[70vh] w-[100%] justify-center object-contain"
        data-testid={dataTestId}
        src={imageUrl}
      />
    </Modal>
  );
};

export default ImageViewDialog;
