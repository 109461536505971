import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MostRecentLocationsQueryVariables = Types.Exact<{
  filters: Types.ItemLocationFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MostRecentLocationsQuery = { __typename?: 'Query', itemlocations: { __typename?: 'ItemLocationConnection', edges: Array<{ __typename?: 'ItemLocationEdge', node: { __typename?: 'ItemLocationSchema', id: string, site: { __typename?: 'LocationSchema', id: string, name: string }, location: { __typename?: 'LocationSchema', id: string, name: string } } }> } };


export const MostRecentLocationsDocument = gql`
    query mostRecentLocations($filters: ItemLocationFilters!, $sorts: [SortProps!], $limit: Float, $after: String) {
  itemlocations(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        site {
          id
          name
        }
        location {
          id
          name
        }
      }
    }
  }
}
    `;

export function useMostRecentLocationsQuery(options: Omit<Urql.UseQueryArgs<MostRecentLocationsQueryVariables>, 'query'>) {
  return Urql.useQuery<MostRecentLocationsQuery>({ query: MostRecentLocationsDocument, ...options });
};