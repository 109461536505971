import { API_URL } from 'app/consts/config';
import currentUser from 'app/modules/auth/utils/currentUser';
import { logout, setLoginCookies } from 'app/modules/auth/views/Login/utils/authTokens';
import { request } from 'app/utils/request';
import { nanoid } from 'nanoid';
import moment from 'moment-timezone';

let refreshTokensPromise: any = null;
let previousRefreshedAt = 0;

const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`;

const executeRefreshTokens = async () => {
  try {
    let rt = currentUser.rt();

    if (rt && Date.now() - previousRefreshedAt > 1000 * 60) {
      try {
        const response = await request(REFRESH_TOKEN_URL, {
          method: 'POST',
          body: JSON.stringify({ refreshToken: rt, timezone: moment.tz.guess() }),
          headers: { 'content-type': 'application/json; charset=utf-8', 'x-request-id': nanoid() },
        });

        const tokens = await response.json();
        previousRefreshedAt = Date.now();

        if (tokens.success) {
          await setLoginCookies(tokens.loginToken);
          return tokens.loginToken;
        } else {
          await logout();
        }
      } catch (error) {
        await logout();
      }
    } else {
      await logout();
    }
  } finally {
    refreshTokensPromise = null;
  }
};

const refreshTokens = async () => {
  if (refreshTokensPromise) {
    return refreshTokensPromise;
  }

  refreshTokensPromise = executeRefreshTokens();
  return refreshTokensPromise;
};

export default refreshTokens;
