import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InventoryAgingReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemLocationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type InventoryAgingReportQuery = { __typename?: 'Query', itemlocations: { __typename?: 'ItemLocationConnection', totalCount: number, edges: Array<{ __typename?: 'ItemLocationEdge', node: { __typename?: 'ItemLocationSchema', id: string, updatedAt: string, totalQuantity?: number | null, item: { __typename?: 'AssetItemSchema' } | { __typename?: 'AssetKitItemSchema' } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, type: Types.ItemTypeEnum, sku: string, status: Types.ItemStatusEnum, title: string, cost?: number | null, unitCost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'InventoryKitItemSchema', id: string, type: Types.ItemTypeEnum, sku: string, status: Types.ItemStatusEnum, title: string, cost?: number | null, unitCost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null }, site: { __typename?: 'LocationSchema', id: string, name: string, locationCode?: string | null }, location: { __typename?: 'LocationSchema', id: string, name: string } } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const InventoryAgingReportDocument = gql`
    query inventoryAgingReport($filters: ItemLocationFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  itemlocations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        updatedAt
        totalQuantity
        item {
          ... on InventoryItemSchema {
            id
            type
            sku
            status
            title
            cost
            unitCost
            category {
              id
              name
              categoryCode
            }
          }
          ... on InventoryKitItemSchema {
            id
            type
            sku
            status
            title
            cost
            unitCost
            category {
              id
              name
              categoryCode
            }
          }
        }
        site {
          id
          name
          locationCode
        }
        location {
          id
          name
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    `;

export function useInventoryAgingReportQuery(options?: Omit<Urql.UseQueryArgs<InventoryAgingReportQueryVariables>, 'query'>) {
  return Urql.useQuery<InventoryAgingReportQuery>({ query: InventoryAgingReportDocument, ...options });
};