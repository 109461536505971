import { useCallback, useEffect, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Project from 'app/i18n/Project';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeleteProjectsMutation } from './graphql/mutations/generated/deleteProjects';
import { useProjectsTableQuery } from './graphql/queries/generated/projectsTable';
import { getProjectsTableColumns } from './utils/columns';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import analytics from 'app/analytics';

const columns = getProjectsTableColumns();

function Projects(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching: disabled }, executeDeleteProject] = useDeleteProjectsMutation();

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditProject(id));
    },
    [history],
  );

  const onDelete = useCallback(
    (ids: string[]) => {
      executeDeleteProject({ input: { projectIds: ids } }).then((response) => {
        analytics?.track('Deleted', { name: 'Project' });
        if (response.data?.deleteProjects?.success) {
          SnackbarService.show({ message: 'This project has been successfully deleted.' });
        }
      });
    },
    [executeDeleteProject],
  );

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useProjectsTableQuery,
    {
      filters: { search: search.debouncedSearchText },
      sorts: table.state.sorts,
    },
    { edgeKey: 'projects', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.projects.edges?.map(({ node }) => node) || [];
  }, [data?.projects.edges]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager
      subject={AllowedPermissionsSubjectEnum.Project}
      layoutWrapperClass
      className="pt-0">
      <EntityManager.Title documentTitle={Project.Projects} title={Project.Projects} />
      <EntityManager.Add label={Project.NewProject} to={routes.AddProject()} />

      <Box
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          loading={fetching || disabled}
          pagination={true}
          persistKey="projects_table"
          setState={table.setState}
          state={table.state}
          total={data?.projects.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Projects);
