import { useEffect, useState } from 'react';

import useGetImpactProductData from "../../hook/useGetImpactProductData";
import { VendorTypeEnum } from "../../types";

export function removeExtraWhiteSpaces(props: any) {
  for (const key in props) {
    if (typeof props[key] === 'string') {
      props[key] = props[key].replace(/\s+/g, ' ');
    }
  }
  return props;
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, 100);
};

interface GetAmazonChunksDataType {
  amazonProductIds: string[],
  totalProducts?: number,
}

export function GetAmazonChunksData(props: GetAmazonChunksDataType) {
  const { amazonProductIds, totalProducts } = props;  
  const [mergedData, setMergedData] = useState<any[]>([]);
  const [currentChunk, setCurrentChunk] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(totalProducts || 10);  
  useEffect(() => {
    const firstChunk = amazonProductIds.slice(0, pageSize);
    setCurrentChunk(firstChunk);
  }, [amazonProductIds]);  

  const { data: chunkData } = useGetImpactProductData({
    productIds: currentChunk,
    page: currentPage,
    pageSize: currentChunk.length,
    vendorType: VendorTypeEnum.AMAZON,
  });

  useEffect(() => {
    if (chunkData) {
      setMergedData((prevData) => [...prevData, ...chunkData]);
    }
  }, [chunkData]);


  const handleLoadMore = () => {
    const nextChunk = amazonProductIds.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );

    if (nextChunk.length > 0) {
      setCurrentPage((prevPage) => prevPage + 1);
      setCurrentChunk(nextChunk);
    }
  };

  useEffect(() => {
    if((mergedData.length < amazonProductIds.length&& (mergedData.length === currentPage*10) )){
      handleLoadMore()
    }
  }, [amazonProductIds.length, mergedData]);



  return mergedData
}