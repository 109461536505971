import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';

const OrderTitleField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isDisabled={disabled}
            label={Orders.FormLabels.OrderTitle}
            characterLimit={32}
            // inputProps={{
            //   maxLength: 32,
            // }}
            // formLabel={Orders.FormLabels.OrderTitle}
          />
        )}
      />
    </div>
  );
};

export default OrderTitleField;
