import {
  AssetKitItemSchema,
  InventoryKitItemSchema,
  ItemTypeEnum,
} from '../../../../../types/schema';
import { ItemInStockType } from '../../../hook/useItemInStockData';

export function isKitTypeItem(type?: ItemTypeEnum) {
  return Boolean(type && [ItemTypeEnum.AssetKit, ItemTypeEnum.InventoryKit].includes(type));
}

export function getSiteAndLocationIdsForKitItem(itemInStock: ItemInStockType) {
  if (isKitTypeItem(itemInStock?.type)) {
    const item = itemInStock as AssetKitItemSchema | InventoryKitItemSchema;
    return {
      siteIds: item.siteId ? [item.siteId] : undefined,
      locationIds: item.locationId ? [item.locationId] : undefined,
    };
  }
  return {
    siteIds: undefined,
    locationIds: undefined,
  };
}
