import { Controller } from 'react-hook-form';
import { DatePicker } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import Stack from 'app/ui-components/Stack';

const inputFormat = 'MM/dd/yyyy' as const;

interface Params {
  value: string;
  control: any;
  setValue: any;
}

export const renderOrderRequestDateInput = (params: Params) => {
  const { value, control, setValue } = params;

  switch (value) {
    case 'before': {
      return (
        <Controller
          control={control}
          name="orderRequestDateEnd"
          render={({ field }) => (
            <DatePicker
              {...field}
              inputFormat={inputFormat}
              size="small"
              onChange={(date) =>
                field.onChange(date ? moment(date).startOf('day').toISOString() : null)
              }
            />
          )}
        />
      );
    }
    case 'after': {
      return (
        <Controller
          control={control}
          name="orderRequestDateStart"
          render={({ field }) => (
            <DatePicker
              {...field}
              inputFormat={inputFormat}
              size="small"
              onChange={(date) =>
                field.onChange(date ? moment(date).endOf('day').toISOString() : null)
              }
            />
          )}
        />
      );
    }
    case 'between': {
      return (
        <Stack alignItems="center" spacing="space-x-32">
          <Controller
            control={control}
            name="orderRequestDateStart"
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat={inputFormat}
                size="small"
                onChange={(date) =>
                  field.onChange(date ? moment(date).startOf('day').toISOString() : null)
                }
              />
            )}
          />
          <Controller
            control={control}
            name="orderRequestDateEnd"
            render={({ field }) => (
              <DatePicker
                {...field}
                inputFormat={inputFormat}
                size="small"
                onChange={(date) =>
                  field.onChange(date ? moment(date).endOf('day').toISOString() : null)
                }
              />
            )}
          />
        </Stack>
      );
    }
    case 'on': {
      return (
        <Controller
          control={control}
          name="orderRequestDateStart"
          render={({ field }) => {
            const onChange = (value: Date | null) => {
              if (!value) {
                setValue('orderRequestDateStart', null);
                setValue('orderRequestDateEnd', null);
              } else {
                setValue('orderRequestDateStart', moment(value).startOf('day').toISOString());
                setValue('orderRequestDateEnd', moment(value).endOf('day').toISOString());
              }
            };

            return (
              <DatePicker {...field} inputFormat={inputFormat} size="small" onChange={onChange} />
            );
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};
