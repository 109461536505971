import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';
import { ASSET_MODEL_FORM_RULES } from '../../utils/utils';

const AssetModelFields = (props: AssetModelFieldProps) => {
  const { disabled, formState } = props;
  const { control } = formState;
  return (
    <div>
      <div className="mb-[24px]">
        <Controller
          control={control}
          name="mName"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              error={fieldState.error}
              isDisabled={disabled}
              label={AssetModel.FormLabels.Model}
            />
          )}
          rules={ASSET_MODEL_FORM_RULES.mName}
        />
      </div>
      <div className="mb-[24px]">
        <Controller
          control={control}
          name="modelNumber"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              error={fieldState.error}
              isDisabled={disabled}
              label={AssetModel.FormLabels.ModelNumber}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AssetModelFields;
