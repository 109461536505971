import { Ref, useEffect } from 'react';

interface refType {
  current: HTMLElement | undefined | null;
}

const useOutSideClick = (refs: refType[], handler?: () => void) => {
  useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      const el = event.target as HTMLElement;
      const elRef = refs?.find((ref) => ref?.current?.contains(el));

      if (!elRef) {
        handler?.();
      }
    };

    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [handler, refs]);
};

export default useOutSideClick;
