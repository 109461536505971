const Organization = {
  Organizations: 'Organizations',
  NewOrganization: 'New Organization',
  EditOrganization: 'Edit Organization',
  OrganizationSetting: 'Organization Setting',
  FormLabels: {
    OrganizationName: 'Organization Name',
    website: 'Website',
    Address: 'Address',
    Zip: 'Zip',
    City: 'City',
    State: 'State',
    Phone1: 'Phone 1',
    Phone2: 'Phone 2',
    AdminFirstName: 'Admin First Name',
    AdminLastName: 'Admin Last Name',
    AdminEmail: 'Admin Email',
    Organization: 'Organization',
  },
  FormValidations: {
    Name: 'Please enter an Organization Name between 3 and 30 characters.',
    WebsiteInvalid: 'Please enter a valid Web Address.',
    Address: 'Please enter an Address.',
    City: 'Please enter a City.',
    State: 'Please enter a State.',
    ZipCode: 'Please enter a Zip Code.',
    ZipCodeInvalid: 'Please enter a valid Zip Code.',
    Phone1: 'Please enter a Phone Number.',
    Phone1Invalid: 'Phone 1 is not valid',
    Phone2Invalid: 'Phone 2 is not valid',
    AdminFirstName: 'Please enter a First Name up to 30 characters.',
    AdminLastName: 'Please enter a Last Name up to 30 characters.',
    AdminEmail: 'Please enter an Email Address.',
    AdminEmailInvalid: 'Please enter a valid Email Address.',
  },
};

export default Organization;
