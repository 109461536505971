import { useMemo } from 'react';

import { LabelTypeEnum } from '../Labels/components/Forms/types';
import PrintAssetLabel from './views/PrintAssetLabel';
import PrintInventoryLabelStock from './views/PrintInventoryLabelStock';
import PrintLocationsLabel from './views/PrintLocationsLabel';
import PrintShippingLabel from './views/PrintShippingLabel';
import PrintShippingSetupLabel from './views/PrintShippingSetupLabel';

const PrintLabel = () => {
  const values = useMemo(() => {
    try {
      const stringifiedValues = localStorage.getItem('printLabels');
      return stringifiedValues ? JSON.parse(stringifiedValues) : {};
    } catch (error) {
      return {};
    }
  }, []);

  if (values) {
    const labelType = values.type;
    if (labelType === LabelTypeEnum.Inventory || labelType === LabelTypeEnum.InventoryKit) {
      return <PrintInventoryLabelStock values={values} />;
    }

    if (labelType === LabelTypeEnum.Asset || labelType === LabelTypeEnum.AssetKit) {
      return <PrintAssetLabel values={values} />;
    }
    if (labelType === LabelTypeEnum.Location) {
      return <PrintLocationsLabel values={values} />;
    }
    if (labelType === LabelTypeEnum.Shipping) {
      return <PrintShippingLabel values={values} />;
    }
    if (labelType === LabelTypeEnum.ShippingSetUp) {
      return <PrintShippingSetupLabel values={values} />;
    }
  }

  return null;
};

export default PrintLabel;
