import '../style.css';
import Link from 'app/components/Link';
import routes from 'app/consts/routes';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';

const LandingPage = () => {
  return (
    <>
      <Box className="space-y-30 mt-[14.1vw] mb-24 min-w-[280px] max-w-[900px] sm:mt-[14.1vh-sm] lg:mt-[14.1vh-lg]">
        <Box>
          <h1 className="heading text-[gray]">{Wishlist.LandingPage.HelpOthers}</h1>
          <h1 className="heading text-[#66CCFF]">{Wishlist.LandingPage.WithOneClick}</h1>
        </Box>
        <p className="content">{Wishlist.LandingPage.SupportParagraphs.SupportNonProfit}</p>
        <p className="content">{Wishlist.LandingPage.SupportParagraphs.ProcureDonates}</p>
        <p className="content">
          <i>{Wishlist.LandingPage.SupportParagraphs.NoExtraCost}</i>
          {Wishlist.LandingPage.SupportParagraphs.MerchantProvidesDonation}
        </p>
        <Box>
          <p className="content">
            <Link
              className=""
              href={routes.SignUp() + '?user-type=donor'}
              style={{ textDecoration: 'none', color: '#66CCFF', fontWeight: 'bold' }}>
              SignUp
            </Link>
            {Wishlist.LandingPage.SupportParagraphs.SignUp}
          </p>
        </Box>
      </Box>
    </>
  );
}

export default LandingPage;
