import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { getUnitCost } from 'app/modules/reports/utils';

export const getInventoryCostTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
  },
  {
    id: 'model',
    label: Reports.Model,
    value: 'model',
  },
  {
    id: 'category',
    label: Category.Category,
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.category?.name}>
        {row.category?.name}
      </span>
    ),
  },
  {
    id: 'categoryCode',
    label: Category.FormLabels.CategoryCode,
    value: 'categoryCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.category?.categoryCode}>
        {row.category?.categoryCode}
      </span>
    ),
  },
  {
    id: 'totalQuantity',
    label: Reports.Qty,
    value: 'totalQuantity',
    rowAlign: 'right',
  },
  {
    id: 'unitCostWithoutOverride',
    label: Reports.UnitCost,
    value: 'unitCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.unitCost)}>
        {getUnitCost(row.unitCost)}
      </span>
    ),
  },
];
