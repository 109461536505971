import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import UndeleteUsersTable from '../../components/UndeleteUsersTable';
import useDeletedUsersTable from '../../views/undeleteUser/hooks/useDeletedUsersTable';
import { useUndeleteUsersMutation } from './graphql/mutations/generated/undeleteUsers';
import { getDeletedUserTableColumns } from './utils/columns';
import Undelete from '../../../../i18n/Undelete';
import SearchBox from '../../components/SearchBox';
import useSearchSuggestion from './hooks/useSearchSuggestion';
import { Box } from '@mui/material';

function UndeletUsers(props: RouteComponentProps) {
  const [{ fetching: userLoading }, onUndeleteUsers] = useUndeleteUsersMutation();

  const { fetching, data, onNextPage, onPrevPage, onReset, table, rows, search, filters } =
    useDeletedUsersTable();

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.User} className="pt-0">
      <EntityManager.Title
        documentTitle={Undelete.UndeleteUser}
        title={Undelete.UndeleteUser}
        hasTopDivider
      />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <UndeleteUsersTable
          columns={getDeletedUserTableColumns}
          data={rows}
          fetching={fetching}
          persistKey="undelete_user_table"
          table={table}
          total={data?.users.totalCount}
          undeleting={userLoading}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          onUndeleteUsers={onUndeleteUsers}
          filterNode={
            <SearchBox
              filters={filters}
              search={search}
              searchSuggestions={searchSuggestions}
              setSearchValue={setSearchValue}
            />
          }
          paginationWrapper="p-[24px] flex-wrap md:gap-[24px]"
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(UndeletUsers);
