import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAssetItemReminderMutationVariables = Types.Exact<{
  input: Types.CreateAssetItemReminderInput;
}>;


export type CreateAssetItemReminderMutation = { __typename?: 'Mutation', createAssetItemReminder: { __typename?: 'CreateAssetItemReminderPayload', success: boolean, reason?: string | null, assetItemReminder?: { __typename?: 'AssetItemReminder', _id: string } | null } };


export const CreateAssetItemReminderDocument = gql`
    mutation createAssetItemReminder($input: CreateAssetItemReminderInput!) {
  createAssetItemReminder(input: $input) {
    success
    reason
    assetItemReminder {
      _id
    }
  }
}
    `;

export function useCreateAssetItemReminderMutation() {
  return Urql.useMutation<CreateAssetItemReminderMutation, CreateAssetItemReminderMutationVariables>(CreateAssetItemReminderDocument);
};