import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Button } from 'reactstrap';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Network from 'app/i18n/Network';
import { defaultGridColumn } from 'app/modules/network/utils';

// import { Button } from '@procurenetworks/procure-component-library';
import useChart from '../../dashboard/components/chart/useChart';

type ColumnGraphType = {
  title: string;
  subheader: string;
  chartData: Array<any>;
  onClickViewDetails?: () => void;
  detailsButton?: boolean;
  handleDataPointClick?: (datapoint: string) => void;
  changeGraphOption?: boolean;
  showLegend?: boolean;
  graphOption?: string;
  setGraphOption?: any;
};

export default function ColumnGraph({
  title,
  subheader,
  chartData,
  onClickViewDetails,
  detailsButton,
  handleDataPointClick,
  changeGraphOption,
  showLegend,
  graphOption,
  setGraphOption,
  ...other
}: ColumnGraphType) {
  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const nonZeroElement = chartSeries.find((elem) => elem !== 0);

  const chartOptions = useChart({
    colors: ['var(--red800)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: any) => seriesName,
        title: {
          formatter: () => '',
        },
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    labels: chartLabels,
    legend: {
      show: showLegend ? true : false,
    },
  });

  return (
    <>
      <Box dir="ltr">
        {!nonZeroElement ? (
          <Typography style={{ color: 'gray', fontSize: '14px', marginBottom: '10px' }}>
            {Network.NothingToShow}
          </Typography>
        ) : !detailsButton && changeGraphOption ? (
          <Grid container columns={defaultGridColumn} spacing={{ xs: 1, sm: 2, lg: 8 }}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <ReactApexChart
                height={364}
                options={chartOptions}
                series={[{ data: chartSeries }]}
                type="bar"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ display: 'flex', mb: 2, maxWidth: 358 }}>
                <FormControl sx={{ minWidth: 180 }}>
                  <InputLabel htmlFor="graph-option">Graph Option</InputLabel>
                  <Select
                    inputProps={{
                      name: 'graphOption',
                      id: 'graph-option',
                    }}
                    label="Graph Option"
                    value={graphOption}
                    onChange={(event) => {
                      setGraphOption && setGraphOption(event.target.value);
                    }}>
                    <MenuItem value="donut">Donut</MenuItem>
                    <MenuItem value="column">Column</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: '70%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  maxWidth: 358,
                }}>
                <ul style={{ margin: '0', padding: '0', textAlign: 'center' }}>
                  {chartData.map((dataPoint) =>
                    dataPoint?.label ? (
                      <li
                        key={dataPoint.label}
                        className="datapointList"
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px',
                        }}
                        onClick={() =>
                          handleDataPointClick ? handleDataPointClick(dataPoint.label || '') : null
                        }>
                        <strong style={{ textAlign: 'left' }}>
                          {dataPoint.label.charAt(0).toUpperCase() + dataPoint.label.slice(1)}
                        </strong>
                        <span style={{ textAlign: 'right' }}>{dataPoint.value}</span>
                      </li>
                    ) : null,
                  )}
                </ul>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Card
            {...other}
            style={{ height: '100%' }}
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important',
            }}
            className="border-grey300 rounded-[12px] border  p-[24px]">
            {/* <CardHeader subheader={subheader} title={title} /> */}
            <Typography className="text-[18px] font-semibold text-grey-900">{title}</Typography>
            <Divider className="mt-[16px] mb-[24px] bg-grey-300" />
            <Box dir="ltr" sx={{ mx: 3 }}>
              {!nonZeroElement ? (
                <Typography style={{ color: 'gray', fontSize: '14px', marginBottom: '10px' }}>
                  {Network.NothingToShow}
                </Typography>
              ) : (
                <ReactApexChart
                  height={364}
                  options={chartOptions}
                  series={[{ data: chartSeries }]}
                  type="bar"
                />
              )}
              {detailsButton ? (
                <Button
                  style={{ width: '100%', borderTop: '1px solid #f2f2f0' }}
                  theme="info"
                  onClick={onClickViewDetails}>
                  View Details
                </Button>
              ) : null}
            </Box>
          </Card>
        )}
      </Box>
    </>
  );
}
