import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateReserveTransactionsMutationVariables = Types.Exact<{
  input: Types.CreateReserveTransactionsInput;
}>;


export type CreateReserveTransactionsMutation = { __typename?: 'Mutation', createReserveTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', createdAt: string, updatedAt: string, id: string, entityType: Types.TransactionEntityTypeEnum, entityId: string, type: Types.TransactionTypeEnum, subType: Types.TransactionSubTypeEnum, dueDate?: string | null, endDate?: string | null, startDate?: string | null, relatedTransactionId?: string | null, status: Types.TransactionStatusEnum, previousQuantity?: number | null, departmentId?: string | null, projectId?: string | null, parentTransactionId: string, meta?: { __typename?: 'TransactionMetaSchema', note?: string | null } | null }> | null } };


export const CreateReserveTransactionsDocument = gql`
    mutation createReserveTransactions($input: CreateReserveTransactionsInput!) {
  createReserveTransactions(input: $input) {
    success
    transactions {
      createdAt
      updatedAt
      id
      entityType
      entityId
      type
      subType
      dueDate
      endDate
      startDate
      relatedTransactionId
      status
      previousQuantity
      departmentId
      projectId
      parentTransactionId
      meta {
        note
      }
    }
  }
}
    `;

export function useCreateReserveTransactionsMutation() {
  return Urql.useMutation<CreateReserveTransactionsMutation, CreateReserveTransactionsMutationVariables>(CreateReserveTransactionsDocument);
};