import { forwardRef } from 'react';
import ProjectsSelect, { ProjectsSelectProps } from 'app/modules/projects/components/ProjectsSelect';

import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & ProjectsSelectProps;

const FormProjectsSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer
      ref={ref}
      className={className}
      error={error}
      isRequired={isRequired}
      label={label}>
      <ProjectsSelect {...selectProps} />
    </FormFieldContainer>
  );
});

export default FormProjectsSelect;
