import { ORDER_ITEM_STATUS } from 'app/modules/orders/views/OrderHistory/utils/types';

import { ORDER_ITEM_VISIBLE_STATUS, VALID_ITEM_STATUS_FOR_CURRENT_STATUS } from '../../../type';

// This function is not getting used anywhere
export const getNextValidStatuses = (item: any) => {
  const currentStatus = item.status;
  let validStatusesForCurrentStatus = VALID_ITEM_STATUS_FOR_CURRENT_STATUS[currentStatus];

  if ((validStatusesForCurrentStatus || []).includes(ORDER_ITEM_STATUS.DELIVERED)) {
    if (item.parentOrderRequestItemId) {
      validStatusesForCurrentStatus = validStatusesForCurrentStatus.filter(
        (status) => ORDER_ITEM_STATUS.DELIVERED !== status,
      );
    } else {
      validStatusesForCurrentStatus = validStatusesForCurrentStatus.filter(
        (status) => ORDER_ITEM_STATUS.RETURNED !== status,
      );
    }
  }

  return Object.keys(ORDER_ITEM_VISIBLE_STATUS).map((itemStatus) => ({
    disabled: !validStatusesForCurrentStatus?.includes(itemStatus),
    id: itemStatus,
    name: ORDER_ITEM_VISIBLE_STATUS[itemStatus],
  }));
};

export const getKeysToFilter = () => [
  'quantity',
  'description',
  'website',
  'upcCode',
  'item.sku',
  'item.title',
  'cost',
  'project.name',
  'note',
];
