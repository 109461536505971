import Warehouse from 'app/i18n/Warehouse';
import { Maybe } from 'app/types/schema';

import {
  composeValidators,
  numberMinValidator,
  textValidator,
} from '../../../../../../components/Form/utils/validators';

type Value = Maybe<string | number> | undefined;

const composeMinValueWarehouseValidator = (minValidatorMessage: string) =>
  composeValidators(
    textValidator(Warehouse.Setup.Required),
    numberMinValidator(minValidatorMessage, 1),
  );

const zonesMinValidator = composeMinValueWarehouseValidator(Warehouse.Setup.ZonesMinError);
const aislesMinValidator = composeMinValueWarehouseValidator(Warehouse.Setup.AislesMinError);
const racksMinValidator = composeMinValueWarehouseValidator(Warehouse.Setup.RacksMinError);
const shelvesMinValidator = composeMinValueWarehouseValidator(Warehouse.Setup.ShelvesMinError);
const binsMinValidator = composeMinValueWarehouseValidator(Warehouse.Setup.BinsMinError);

const validateWarehouseValue = (value: Value, maxValue: number, message: string) => {
  const numberValue = Number(value);

  if (!numberValue) {
    return Warehouse.Setup.Required;
  }

  return numberValue >= 0 && numberValue <= maxValue ? true : message;
};

export const WAREHOUSE_FORM_CONFIG = {
  zones: {
    label: Warehouse.Setup.Zones,
    placeholder: Warehouse.Setup.Placeholder,
    rules: {
      validate: (value: Value) => zonesMinValidator(value?.toString()),
    },
  },
  aisles: {
    label: Warehouse.Setup.Aisles,
    placeholder: Warehouse.Setup.Placeholder,
    rules: {
      validate: (value: Value) => aislesMinValidator(value?.toString()),
    },
  },
  racks: {
    label: Warehouse.Setup.Racks,
    placeholder: Warehouse.Setup.Placeholder,
    rules: {
      validate: (value: Value) => racksMinValidator(value?.toString()),
    },
  },
  shelves: {
    label: Warehouse.Setup.Shelves,
    placeholder: Warehouse.Setup.Placeholder,
    rules: {
      validate: (value: Value) => shelvesMinValidator(value?.toString()),
    },
  },
  bins: {
    label: Warehouse.Setup.Bins,
    placeholder: Warehouse.Setup.Placeholder,
    rules: {
      validate: (value: Value) => binsMinValidator(value?.toString()),
    },
  },
};
