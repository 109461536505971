import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormError from 'app/components/Form/FormError';
import { useFormSiteSelectQuery } from 'app/modules/locations/components/FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { SiteSelectorOption } from 'app/modules/locations/components/SiteSelector/types';
import { sortBy } from 'app/utils/sort';

import { LocationTypeEnum, SortOrderEnum } from '../../../types/schema';
import { adaptNodeEdgeToNode } from '../../AsyncMultiSelect/utils';
import FormLabel from '../FormLabel';
import { Box } from '@mui/material';

export type Option = Pick<SiteSelectorOption, 'id' | 'name'>;

const getOptionLabel = (option: any) => option.name;

const AllSiteOption = {
  name: 'All Sites',
  id: 'none',
};

type Props = {
  label?: string;
  name: string;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string, option: Option | null) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  enabledAllOption?: boolean;
  isRequired?: boolean;
  formLabel?: string
};

const FormSiteSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { label, onChange, value, enabledAllOption, isRequired, error, disabled, formLabel, placeholder } = props;
  const defaultSelectedValue =
    enabledAllOption && value === AllSiteOption.id ? AllSiteOption : null;
  const [selectedValue, setSelectedValue] = useState<Option | null>(defaultSelectedValue);

  const [{ data: selectedData }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        locationIds: value ? [value] : [],
        types: [LocationTypeEnum.Site],
      },
    },
    pause:
      !value || Boolean(selectedValue && selectedValue?.id === value) || value === AllSiteOption.id,
  });

  const [{ fetching: isLoading, data: optionsData }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        search: '',
        types: [LocationTypeEnum.Site],
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const options = useMemo(() => {
    const updatedOptions =
      optionsData?.locations.edges?.map(adaptNodeEdgeToNode) || [];

    if (enabledAllOption) {
      return [AllSiteOption, ...updatedOptions];
    }
    return updatedOptions;
  }, [optionsData?.locations.edges]);

  const onValueChange = useCallback(
    (_: any, option: any | null) => {
      if (option) {
        setSelectedValue(option);
        onChange?.(option.id, option);
      } else {
        setSelectedValue(enabledAllOption ? AllSiteOption : null);
        onChange?.(enabledAllOption ? AllSiteOption.id : '', null);
      }
    },
    [onChange, setSelectedValue],
  );

  useEffect(() => {
    const selectedOption = selectedData?.locations.edges[0]?.node;
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [selectedData?.locations.edges]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  useEffect(() => {
    const selectedOption = options.find((option) => option.id === value);
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [options]);

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;

  return (
    <div ref={ref}>
      {formLabel ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
      <Box className='!mt-[6px]'>
        <Autocomplete
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          label={label || ''}
          loading={isLoading}
          options={options}
          renderInput={(params) => (
            <TextField {...params}
              InputLabelProps={{
                shrink: true,
              }}
              cleanInputIcon
              error={!!error}
              labelNode={LabelNode}
              placeholder={placeholder}
            />
          )}
          size="medium"
          value={selectedValue as any}
          onChange={onValueChange}
        />
        <FormError error={error} />
      </Box>

    </div>
  );
});

export default FormSiteSelect;
