import '../../style.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Icon } from '@iconify/react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Button, Pagination } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import CopyToClipboard from 'app/modules/wishlist/components/CopyToClipboard';
import HorizontalCard from 'app/modules/wishlist/components/horizontalCard';

import { ListViewProps } from '../../types';
import EditWishlistForm from './EditWishlistForm';

const CreateListView = (props: ListViewProps) => {
  const {
    selectedProducts,
    addViewHandler,
    cancelEdit,
    wishlistDetails,
    removeProductHandler,
    itemQuantityUpdate,
    itemPurchasedQuantityUpdate,
    editViewHandler,
    isEditing,
    saveHandler,
    wishlistId,
    loading,
    totalProducts,
    pageNumber,
    rowsPerPage,
    handlePageChange,
    refetch,
    handleRowsPerPageChange,
  } = props;

  const { control, handleSubmit, watch, reset } = useForm({ defaultValues: wishlistDetails });

  useEffect(() => {
    reset(wishlistDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistDetails]);

  const onFormSubmit = useMemo(
    () => handleSubmit(isEditing ? saveHandler : editViewHandler),
    [editViewHandler, handleSubmit, isEditing, saveHandler],
  );

  const cancelWishlistEditing = () => {
    reset(wishlistDetails);
    cancelEdit();
  };

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const wishlistDescription = useMemo(() => {
    return wishlistDetails?.formattedDescription || wishlistDetails?.description || '';
  }, [wishlistDetails]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <form data-testid="create-list-form" onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
          <Stack className="buttons-stack" direction="row" rowGap={'16px'}>
            <Button
              classes="stack-button w-[149px]"
              theme={'success'}
              onClick={() => addViewHandler(control._formValues)}>
              {Wishlist.FormActions.Add}
            </Button>
            <Stack direction="row" gap="16px">
              {/* {wishlistId && isEditing && (
                <Button
                  classes="stack-button w-[149px]"
                  theme={'info'}
                  onClick={cancelWishlistEditing}>
                  {Common.Actions.Cancel}
                </Button>
              )} */}
              <Button
                classes="stack-button w-[149px]"
                theme={'info'}
                onClick={() => onFormSubmit()}>
                {wishlistId && isEditing ? Common.Actions.Save : Common.Actions.Edit}
              </Button>
            </Stack>
          </Stack>

          {!isEditing ? (
            <Stack
              alignItems={'center'}
              className="rounded-[16px] border border-grey-300 p-[24px] lg:m-[auto] lg:max-w-[412px]"
              direction={'column'}
              justifyContent={'center'}
              margin={'10px'}>
              <Icon color="red" height="46px" icon="ph:gift-light" width="46px" />
              <Typography
                className="text-2xl mb-4 min-w-[120px] text-[32px] font-bold text-grey-900"
                component="div"
                variant="h6">
                {wishlistDetails?.name}
              </Typography>
              {wishlistDetails?.shippingAddress && (
                <Stack direction={'row'}>
                  <Typography className="text-lg mb-6 mt-[5px] text-center text-gray-600">
                    {Object.values(wishlistDetails?.shippingAddress).join(' ')}
                  </Typography>
                  <Box className="ml-[8px]">
                    <CopyToClipboard
                      label="Shipping Address"
                      text={Object.values(wishlistDetails?.shippingAddress).join(' ')}
                    />
                  </Box>
                </Stack>
              )}
              {wishlistDescription && (
                <pre
                  className="text-lg my-[20px] mx-auto select-none whitespace-pre-wrap text-left font-[Inter] text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: isExpanded
                      ? wishlistDescription
                      : wishlistDescription.slice(0, 200) + '...',
                  }}
                  style={wishlistDescription.length > 200 ? { cursor: 'pointer' } : {}}
                  onClick={toggleExpand}></pre>
              )}
            </Stack>
          ) : (
            <EditWishlistForm
              control={control}
              defaultValues={wishlistDetails || {}}
              watch={watch}
              wishlistId={wishlistId}
            />
          )}

          <Box
            className="mt-[24px] rounded-[12px] border border-grey-300 p-[24px]"
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}>
            <Grid container className="products-columns " spacing={2}>
              <Grid item md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <p className="text-[14px] font-semibold text-grey-900">Product</p>
              </Grid>
              <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }}>
                <p className="ml-[25px] text-[14px] font-semibold text-grey-900">Quantity</p>
              </Grid>
              <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }}>
                <p className="ml-[25px] text-[14px] font-semibold  text-grey-900">
                  Purchased Quantity
                </p>
              </Grid>
              <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }}>
                <p className="ml-[25px] text-[14px] font-semibold  text-grey-900">Price</p>
              </Grid>
              <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }}>
                <p className="ml-[25px] text-[14px] font-semibold text-grey-900">Action</p>
              </Grid>
            </Grid>

            {!!selectedProducts.length ? (
              <Grid container>
                {selectedProducts.map((item, index) => (
                  <HorizontalCard
                    index={index}
                    isEditing={isEditing}
                    item={item}
                    itemPurchasedQuantityUpdate={itemPurchasedQuantityUpdate}
                    itemQuantityUpdate={itemQuantityUpdate}
                    refetch={refetch}
                    removeProductHandler={removeProductHandler}
                    wishlistId={wishlistId}
                  />
                ))}

                {!!selectedProducts.length && (
                  <Box className="flot-right mt-[16px] flex w-full justify-end border-t-[1px] border-grey-300 py-[16px] pb-[0px] pt-[24px]">
                    <Pagination
                      classes="paginations"
                      count={totalProducts}
                      page={pageNumber || 0}
                      rowsPerPage={rowsPerPage || 10}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </Box>
                )}
              </Grid>
            ) : (
              <p className={'py-[30px] px-[0px] text-center'}>
                Let’s add items to your Wishlist. Please click “Add Items” to add your first item.
              </p>
            )}
          </Box>
        </form>
      )}
    </>
  );
};

export default CreateListView;
