const Undelete = {
  Undelete: 'Undelete',
  UndeleteAsset: 'Undelete Asset',
  UndeleteInventory: 'Undelete Inventory',
  UndeleteUser: 'Undelete User',
  FormLabels: {
    Site: 'Site',
    UndeleteStatus: 'Undelete Status',
  },
  SuccessMessages: {
    AssetUndeleted: 'This asset has been successfully restored.',
    AssetsUndeleted: 'These assets have been successfully restored.',
    InventoryUndeleted: 'This inventory has been successfully restored.',
    InventoriesUndeleted: 'These inventories have been successfully restored.',
    SuccessFullyDeleted: 'records were undeleted successfully.',
    NotSuccessFullyDeleted: 'records were not undeleted.',
    SuccessFullySingleRecordDeleted: 'record was undeleted successfully.',
    NotSuccessFullySingleRecordDeleted: 'record was not undeleted.',
    UserUndeleted: 'This user has been successfully restored.',
    UsersUndeleted: 'These users have been successfully restored.',
  },
  MultipleItemUndeleteMessage: (total: number) =>
    `Are you sure you want to restore ${total} record(s)?`,
};

export default Undelete;
