import React from 'react';
import { Grid } from '@mui/material';
import { CheckBox } from '@procurenetworks/procure-component-library';
import FormSelectInput from 'app/components/ProcureForm/FormSelectInput';

import Inventory from '../../../../../../i18n/Inventory';
import { EditInventoryItemsFormProps } from './types';

const EditInventoryItemsForm = (props: EditInventoryItemsFormProps) => {
  const { state, setState, siteItems, categoryItems } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <CheckBox
          label={Inventory.FormLabels.Category}
          value={state.isCategorySelected}
          onChange={(value) => {
            setState({
              isCategorySelected: value,
            });
          }}
        />

        <FormSelectInput
          className="flex min-w-[100px] pt-5"
          disabled={!state.isCategorySelected}
          items={categoryItems}
          size="small"
          value={state.category_id}
          onChange={(value) => {
            setState({
              category_id: value,
            });
          }}
        />
      </Grid>
      <Grid item xs>
        <CheckBox
          label={Inventory.FormLabels.Vendor}
          value={state.isVendorSelected}
          onChange={(value) => {
            setState({
              isVendorSelected: value,
            });
          }}
        />

        <FormSelectInput
          className="flex min-w-[100px] pt-5"
          disabled={!state.isVendorSelected}
          items={siteItems}
          size="small"
          value={state.vendor_id}
          onChange={(value) => {
            setState({
              vendor_id: value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default EditInventoryItemsForm;
