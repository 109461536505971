import { Control, Controller } from 'react-hook-form';
import { RadioGroup } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import Reports from 'app/i18n/Reports';
import { radioGroupStyleOverrides } from 'app/modules/reports/components/InventoryAssetSelectorFormField/styles';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { AccountingReportFormValues } from '../AccountingReportForm/types';

interface Props {
  control: Control<AccountingReportFormValues, any>;
  onChange: (types: TransactionEntityTypeEnum[]) => void;
}

const RADIO_ITEMS = [
  {
    id: TransactionEntityTypeEnum.Asset,
    value: TransactionEntityTypeEnum.Asset,
    label: Reports.Assets,
  },
  {
    id: TransactionEntityTypeEnum.Inventory,
    value: TransactionEntityTypeEnum.Inventory,
    label: Reports.Inventory,
  },
  {
    id: `${TransactionEntityTypeEnum.Asset}-${TransactionEntityTypeEnum.Inventory}`,
    value: `${TransactionEntityTypeEnum.Asset}-${TransactionEntityTypeEnum.Inventory}`,
    label: Reports.AssetsandInventory,
  },
];

const AccountingReportTypeSelector = (props: Props) => {
  const { control, onChange: onChangeHandler } = props;

  return (
    <Stack className={radioGroupStyleOverrides} direction="col">
      <FormLabel>Report Type</FormLabel>
      <Controller
        control={control}
        name="types"
        render={({ field }) => {
          const value = field.value.join('-');

          const onChange = (nextValue: string | number) => {
            if (typeof nextValue === 'string') {
              onChangeHandler(nextValue.split('-') as TransactionEntityTypeEnum[]);
            }
          };

          return <RadioGroup radioItems={RADIO_ITEMS} value={value} onChange={onChange} />;
        }}
      />
    </Stack>
  );
};

export default AccountingReportTypeSelector;
