import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';

import { useAffiliateMerchantsSearchSuggestionQuery } from '../../graphql/queries/generated/affiliateMerchantsSearchSuggestion';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useAffiliateMerchantsSearchSuggestionQuery({
    variables: { filters: { search: searchedValue } },
  });

  useEffect(() => {
    const result = data?.affiliateConfigurations?.edges?.map((a) => ({
      label: a.node.name,
      id: a.node.id,
    }));
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;