import { css } from '@emotion/css';

export const siteReportRadioGroupStyleOverrides = css`
  margin-top: 8px !important;
  .MuiTypography-root {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  .MuiCheckbox-root {
    padding: 4px;
    flex-shrink: 0;
  }
`;