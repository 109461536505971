import { nanoid } from 'nanoid';

import { ORDER_INSERT_TYPE, OrderRequestInput } from '../../OrderRequestForm/type';
import { BulkOrderInput } from '../types';

export function getDefaultOrderItem(): OrderRequestInput {
  return {
    itemId: '',
    projectId: '',
    projectName: '',
    quantity: '',
    cost: '0.00',
    itemType: ORDER_INSERT_TYPE.ITEM_IN_STOCK,
    id: nanoid(),
  };
}

export function getDefaultFormValues(): BulkOrderInput {
  return {
    orderItems: [getDefaultOrderItem()],
    partnerTenantId: '',
  };
}
