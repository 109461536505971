import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateImportJobMutationVariables = Types.Exact<{
  input: Types.CreateImportJobInput;
}>;


export type CreateImportJobMutation = { __typename?: 'Mutation', createImportJob: { __typename?: 'CreateJobPayload', success: boolean, reason?: string | null, job?: { __typename?: 'JobSchema', id: string } | null } };


export const CreateImportJobDocument = gql`
    mutation createImportJob($input: CreateImportJobInput!) {
  createImportJob(input: $input) {
    success
    reason
    job {
      id
    }
  }
}
    `;

export function useCreateImportJobMutation() {
  return Urql.useMutation<CreateImportJobMutation, CreateImportJobMutationVariables>(CreateImportJobDocument);
};