import { CreateJobHeaderMap, ImportEntityEnum } from '../../../types/schema';

export function createPreprocessImportJobInput(values: any) {
  return {
    url: values.attachments?.[0]?.url,
    entityType: values.entityType as ImportEntityEnum,
  };
}

export function createJobHeaderMapInput(importValues: any, values: any) {
  const createJobHeaderMap: CreateJobHeaderMap[] = [];

  for (const valueKey in values) {
    if (values[valueKey]) {
      createJobHeaderMap.push({
        columnHeader: values[valueKey],
        entityField: valueKey,
      });
    }
  }

  return {
    createJobHeaderMap,
    ...createPreprocessImportJobInput(importValues),
  };
}
