import { isAccessible } from '../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { UserProfileFragment } from '../../auth/graphql/fragments/generated/UserProfile';

export const getOrderLayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) =>
  [
    {
      label: 'Order Request',
      value: 'request',
      subject: AllowedPermissionsSubjectEnum.OrderRequest,
      action: AllowedPermissionActionsEnum.Create,
    },
    {
      label: 'Order History',
      value: 'history',
      subject: AllowedPermissionsSubjectEnum.OrderRequest,
      action: AllowedPermissionActionsEnum.Access,
    },
  ].filter((tab) => isAccessible(permissions, tab.action, tab.subject));
