import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import Inventory from '../../../../i18n/Inventory';
import { sortBy } from '../../../../utils/sort';
import { adaptLocation, isUnassignedLocation } from '../../utils/location';
import { FormSiteSelectQuery } from '../FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { LocationSelectorOption } from '../LocationSelector/types';
import { MostRecentLocationsQuery } from './graphql/generated/mostRecentLocations';
import { ToLocationSelectOption } from './type';

interface ReduceLocationParams {
  sort?: boolean;
  items: any[];
  parseToLocation?: (item: any) => any;
  siteId?: string | null;
}

const reduceLocations = (params: ReduceLocationParams) => {
  const { parseToLocation, items, siteId, sort } = params;
  const results = {
    options: [],
    unAssignedOptions: [],
  } as {
    options: ToLocationSelectOption[];
    unAssignedOptions: LocationSelectorOption[];
  };
  items.forEach((item: any) => {
    const node = adaptNodeEdgeToNode(item);
    const location = parseToLocation?.(node) || node;
    if (isUnassignedLocation(location?.id, siteId || '')) {
      results.unAssignedOptions.push(adaptLocation(siteId || '', location));
    } else {
      results.options.push(location);
    }
  });

  if (sort) {
    results.options = results.options.sort(sortBy('name'));
  }
  return [...results.unAssignedOptions, ...results.options];
};

const adaptItemLocationToMostRecentGroup = (
  itemLocations: MostRecentLocationsQuery['itemlocations']['edges'],
  siteId?: string | null | undefined,
) =>
  reduceLocations({
    items: itemLocations,
    siteId: siteId,
    parseToLocation: (item) => item.location,
  });

export const adaptLocationToOption = (
  edges: any[],
  siteId?: string | null | undefined,
): LocationSelectorOption[] =>
  reduceLocations({
    items: edges,
    siteId: siteId,
    sort: true,
  }) as LocationSelectorOption[];

const createOptionsGroup = (label: string, options: any) => {
  if (options && options.length > 0) {
    return options.map((option: any) => ({
      ...option,
      group: label,
    }));
  }

  return [];
};

export const adaptItemLocationAndLocationToOptionGroup = (
  locations: FormSiteSelectQuery['locations']['edges'],
  itemLocations: MostRecentLocationsQuery['itemlocations']['edges'],
  siteId: string | null | undefined,
) => {
  const mostRecentLocationOptions = adaptItemLocationToMostRecentGroup(itemLocations, siteId);
  const locationOptions = adaptLocationToOption(locations, siteId);

  let options: any[] = locationOptions;
  let isGroupOptions: boolean = false;

  if (mostRecentLocationOptions.length > 0) {
    isGroupOptions = true;
    options = [
      ...createOptionsGroup(Inventory.FormLabels.MostRecent, mostRecentLocationOptions),
      ...createOptionsGroup(Inventory.FormLabels.ToLocation, locationOptions),
    ];
  }
  return {
    isGroupOptions,
    options,
  };
};
