import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAffiliateMerchantMutationVariables = Types.Exact<{
  input: Types.CreateAffiliateConfigurationInput;
}>;


export type CreateAffiliateMerchantMutation = { __typename?: 'Mutation', createAffiliateConfiguration: { __typename?: 'CreateAffiliateConfigurationPayload', success: boolean, reason?: string | null, affiliateConfiguration: { __typename?: 'AffiliateConfigurationSchema', id: string, name: string } } };


export const CreateAffiliateMerchantDocument = gql`
    mutation createAffiliateMerchant($input: CreateAffiliateConfigurationInput!) {
  createAffiliateConfiguration(input: $input) {
    success
    reason
    affiliateConfiguration {
      id
      name
    }
  }
}
    `;

export function useCreateAffiliateMerchantMutation() {
  return Urql.useMutation<CreateAffiliateMerchantMutation, CreateAffiliateMerchantMutationVariables>(CreateAffiliateMerchantDocument);
};