import AdminLayout from 'app/components/AdminLayout';
import Roles from 'app/modules/roles/views/Roles';

function RolesPage() {
  return (
    <AdminLayout>
      <Roles />
    </AdminLayout>
  );
}

export default RolesPage;
