import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useUpdateReceivableMutation } from 'app/modules/receiving/graphql/mutations/generated/updateReceivable';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

import { ReceiveRowType } from '../useReceiveState';

type Props = {
  transactionData: { open: boolean } & {
    transaction: Omit<ReceiveRowType, 'editable' | 'editableFields'>;
  };
  onCancel: () => void;
};

function useEditReceiveItemState(props: Props) {
  const { transactionData, onCancel } = props;
  const recentHistoryItem =
    transactionData?.transaction.formattedNotes || transactionData?.transaction?.notes || '';

  const { formState, reset, handleSubmit, control } = useForm({
    defaultValues: {
      dueDate: transactionData?.transaction?.dueDate ? moment(transactionData?.transaction?.dueDate).format('YYYY-MM-DD') : null,
      notes: recentHistoryItem || '',
      carrier: transactionData?.transaction?.carrier || '',
      trackingNumber: transactionData?.transaction?.trackingNumber || '',
      vendorId: transactionData?.transaction?.vendorId || '',
    },
  });

  const [{ fetching: isUpdatingReceivables }, editReceivables] = useUpdateReceivableMutation();

  const onSubmit = (values: any) => {
    editReceivables({
      input: {
        receivableId: transactionData?.transaction?.id,
        dueDate: moment(values?.dueDate).endOf('day').toISOString(),
        notes: removeExtraSpacesAndNewlines(values.notes),
        formattedNotes: values.notes,
        carrier: values.carrier,
        trackingNumber: values.trackingNumber,
        vendorId: values.vendorId,
      },
    }).then(() => {
      onCancel();
    });
  };

  return {
    formState,
    control,
    reset,
    isUpdatingReceivables,
    actions: {
      handleSubmit,
      onSubmit,
    },
  };
}

export default useEditReceiveItemState;
