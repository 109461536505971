import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EditPartner from 'app/modules/partners/views/EditPartner';

function EditPartnerPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  const currentUser = useCurrentUser();
  // const isSuperAdmin = canSwitchTenants(currentUser.workspacePermissions);

  // if (!isSuperAdmin && match.params.id !== currentUser.tenantId) {
  //   return <Redirect to={routes.EditOrganization(currentUser.tenantId)} />;
  // }

  return (
    <AdminLayout>
      <EditPartner tenantId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditPartnerPage);
