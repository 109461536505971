import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Inventory from 'app/i18n/Inventory';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';
import ItemInStockSelect from 'app/modules/assetsInventory/components/FormItemInStock';
import FormNumberInput from 'app/modules/components/FormNumberInput';
import { UseInventoryFormReturnType } from 'app/modules/inventory/components/InventoryForm/hook/useInventoryForm';
import { FormLocationItemLocationSelect } from 'app/modules/locations/components/FormItemLocationSelect';
import { AssetItemSchema, InventoryItemSchema, ItemTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import { UseKitSummaryStateReturnType } from '../../hook/useKitSummaryState';
import { KIT_SUMMARY_FORM_RULES } from '../../utils';

interface Props {
  types: ItemTypeEnum[];
  formState: UseKitSummaryStateReturnType['formState'];
  disabled: boolean;
  loading?: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
  state: UseInventoryFormReturnType['state'] | UseAssetFormReturnType['formState'];
}

const KitSummaryForm = (props: Props) => {
  const { formState, disabled, loading, state, types, onSubmit, onCancel } = props;
  const { control, itemId, itemInStock, loading: formLoading, setState } = formState;

  const quantityRules = useMemo(() => {
    return !!(itemInStock as unknown as AssetItemSchema)?.serialNumber
      ? KIT_SUMMARY_FORM_RULES.quantityForSerialNumber
      : KIT_SUMMARY_FORM_RULES.quantity;
  }, [itemInStock]);

  if (state.editable && state.itemId) {
    return null;
  }

  return (
    <form className="mt-20" onSubmit={onSubmit}>
      <div className="mb-20">
        <ItemInStockSelect
          autoFocus
          control={control}
          disabled={disabled}
          filters={{ siteIds: state?.destinationSiteId ? [state?.destinationSiteId] : [] }}
          name="itemInStockId"
          types={types}
        />
      </div>
      <div className="mb-20">
        <FormLocationItemLocationSelect
          isRequired
          control={control}
          disabled={!itemId || disabled}
          itemId={itemId}
          name="sourceLocationId"
          rules={KIT_SUMMARY_FORM_RULES?.destinationLocationId}
          siteId={state?.destinationSiteId}
          onValueOptionChange={(option) => {
            setState({
              sourceLocation: option || null,
            });
          }}
        />
      </div>
      <div className="mb-20">
        <Controller
          control={control}
          name="quantity"
          render={({ field, fieldState }) => (
            <FormNumberInput
              {...field}
              isRequired
              error={fieldState.error}
              isDisabled={disabled}
              label={Inventory.FormLabels.Quantity}
              maximumLength={9}
            />
          )}
          rules={quantityRules}
        />
      </div>
      {!state.itemId && (
        <Box className="mb-20 flex justify-end space-x-8">
          <Button
            disabled={disabled || formLoading || loading || state.attachmentInputProps.isUploading}
            loading={formLoading}
            theme="success"
            onClick={onSubmit}>
            {Common.Actions.Add}
          </Button>
          <Button disabled={loading} onClick={onCancel}>{Common.Actions.Cancel}</Button>
        </Box>
      )}
    </form>
  );
};

export default KitSummaryForm;
