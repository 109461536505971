import { flatten } from 'lodash';

import { WarehouseLocationsQuery } from '../../NewWarehouseDetails/graphql/queries/generated/warehouseLocations';
import { WarehouseValueKey } from '../types';

function getZoneAsPerIterator(iterator: number): string {
  const zoneCharacter = 'A'.charCodeAt(0) + iterator;
  return String.fromCharCode(zoneCharacter);
}

function getAisleAsPerIterator(iterator: number): string {
  const aisleNumber = 100 + iterator + 1;
  return aisleNumber.toString();
}

function getRackAsPerIterator(iterator: number, rackIncrementor = 5): string {
  if ((iterator + 1) * rackIncrementor < 10) {
    if (rackIncrementor === 1) {
      return `0${(iterator + 1) * rackIncrementor}`;
    } else {
      return `00${(iterator + 1) * rackIncrementor}`;
    }
  } else if ((iterator + 1) * rackIncrementor < 100) {
    if (rackIncrementor === 1) {
      return `${(iterator + 1) * rackIncrementor}`;
    } else {
      return `0${(iterator + 1) * rackIncrementor}`;
    }
  }
  const rackNumber = (iterator + 1) * rackIncrementor;
  return rackNumber.toString();
}

function getShelfAsPerIterator(iterator: number): string {
  const shelfCharacter = 'A'.charCodeAt(0) + iterator;
  return String.fromCharCode(shelfCharacter);
}

function getBinAsPerIterator(iterator: number): string {
  const binNumber = 100 + iterator;
  return binNumber.toString();
}

// Example: A105 - 01 - B107
export const parseWarehouseLocationName = (location: string) => {
  return {
    zone: location?.substring(0, 1),
    aisle: location?.substring(1, 4),
    rack: location?.substring(7, 10),
    shelf: location?.substring(13, 14),
    bin: location?.substring(14),
  };
};

export const parseWarehouseLocationNameRackAndShelf = (location: string) => {
  return {
    zone: null,
    aisle: null,
    rack: location?.substring(0, 4),
    shelf: location?.substring(6, 7),
    bin: null,
  };
};

export const parseRackAndShelfData = (locationName: string) => {
  const [rackNumber, lastShelf] = locationName.split(' - ');
  return { rackNumber, lastShelf };
};

export const getWarehouseLocations = (data?: WarehouseLocationsQuery) =>
  flatten(data?.warehouses.edges.map(({ node }) => node.lastLocationNamesOfChild));

export const WAREHOUSE_LABEL_CONFIG = {
  zones: {
    labelPrefix: 'Zone',
    getLabel: getZoneAsPerIterator,
    separator: '',
    getLabelFromLocation: (location: string) => location?.substring(0, 1),
    getSubItemCount: (location: string) => Number(parseWarehouseLocationName(location).aisle) - 100,
  },
  aisles: {
    labelPrefix: 'Aisle',
    getLabel: getAisleAsPerIterator,
    separator: '',
    getLabelFromLocation: (location: string) => location?.substring(0, 4),
    getSubItemCount: (location: string, rackIncrementor = 5) => Number(parseWarehouseLocationName(location).rack) / rackIncrementor,
  },
  racks: {
    labelPrefix: 'Rack',
    getLabel: getRackAsPerIterator,
    separator: ' - ',
    getLabelFromLocation: (location: string) => location?.substring(0, 10),
    getSubItemCount: (location: string) =>
      parseWarehouseLocationName(location).shelf.charCodeAt(0) - 'A'.charCodeAt(0) + 1,
  },
  shelves: {
    labelPrefix: 'Shelf',
    getLabel: getShelfAsPerIterator,
    separator: ' - ',
    getLabelFromLocation: (location: string) => location?.substring(0, 14),
    getSubItemCount: (location: string) => Number(parseWarehouseLocationName(location).bin) - 100 + 1,
  },
  bins: {
    labelPrefix: 'Bin',
    getLabel: getBinAsPerIterator,
    separator: '',
    getLabelFromLocation: (location: string) => location,
    getSubItemCount: (_: string) => 0,
  },
};

export const ORDERED_WAREHOUSE_KEYS = [
  'zones',
  'aisles',
  'racks',
  'shelves',
  'bins',
] as WarehouseValueKey[];

export const RACKS_AND_SHELVES_KEYS = [
  'racks',
  'shelves',
] as WarehouseValueKey[];
