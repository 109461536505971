export const removeMultipleSpaces = (string: string) => {
  if (!string) {
    return '';
  }
  return string.replace(/\s{2,}/g, ' ').trim();
};

export const removeExtraSpacesAndNewlines = (inputString: string) => {
  let stringWithoutExtraSpaces = inputString.replace(/\s+/g, ' ');
  let stringWithoutNewlinesAndTabs = stringWithoutExtraSpaces.replace(/[\n\t]/g, '');

  return stringWithoutNewlinesAndTabs;
};
