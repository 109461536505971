import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import Link from 'app/components/Link';
import { popupHeight, popupLeft, popupTop, popupWidth } from 'app/utils/dimensions';

export const getUndeleteAssetTableColumns = (options?: {
  disabledSorting?: boolean;
  isSiteIdSelected?: boolean;
  siteId?: string;
}): DataGridHeadCell[] => {
  const { disabledSorting, isSiteIdSelected } = options || {};

  const hideColumIds = isSiteIdSelected ? [] : ['location', 'minimumQuantity', 'maximumQuantity'];

  return (
    [
      {
        id: 'attachments',
        label: 'Image',
        value: 'attachments',
        valueNode: ({ row }: any) => <img alt="" src={row?.attachments[0]?.url} width="45px" />,
        sortable: false,
      },
      {
        id: 'sku',
        label: 'SKU',
        value: 'sku',
        valueNode: ({ row }) => {
          return (
            <Link
              className="sku-link truncate"
              title={row?.sku}
              onClick={() =>
                window.open(
                  `/assets/deleted-asset/${row?.id}`,
                  '',
                  `toolbar=no,scrollbars=no,resizable=no,height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft}`,
                )
              }>
              {row?.sku}
            </Link>
          );
        },
        sortable: true,
      },
      {
        id: 'title',
        label: 'Title',
        value: 'title',
        sortable: true,
      },
      {
        id: 'Model',
        label: 'Model',
        value: 'model',
        valueNode: ({ row }) => {
          return (
            <span className="truncate" title={row?.mName}>
              {row?.mName}
            </span>
          );
        },
        sortable: true,
      },
      {
        id: 'categoryId',
        label: 'Category',
        value: 'categoryId',
        valueNode: ({ row }) => {
          return (
            <span className="truncate" title={row.category?.name || ''}>
              {row.category?.name || ''}
            </span>
          );
        },
        sortable: true,
      },
      {
        id: 'description',
        label: 'Description',
        value: 'description',
        sortable: true,
        addReadMore: true,
      },
      {
        id: 'deletedAt',
        label: 'Delete Date',
        value: 'deletedAt',
        valueNode: ({ row }) => {
          return (
            <span
              className="truncate"
              title={row.deletedAt ? moment(row.deletedAt).format('L LT') : ''}>
              {row.deletedAt ? moment(row.deletedAt).format('L LT') : ''}
            </span>
          );
        },
        sortable: true,
      },
    ] as DataGridHeadCell[]
  )
    .map((item) => {
      return {
        ...item,
        sortable: disabledSorting ? false : item.sortable,
      } as DataGridHeadCell;
    })
    .filter((item) => {
      return !hideColumIds.includes(item.id);
    });
};
