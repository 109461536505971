import { useEffect, useState } from 'react';
import { useAssetSearchSuggestionQuery } from 'app/modules/assets/graphql/queries/generated/assetSearchSuggestion';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { processSearch } from 'app/utils/processSearch';

import { AssetItemSchema, AssetKitItemSchema, ItemTypeEnum } from '../../../../../../types/schema';

const useSearchSuggestion = (options?: { siteId?: string; parentId?: string }) => {
  const { siteId, parentId } = options || {};

  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();
  const [{ data }] = useAssetSearchSuggestionQuery({
    variables: {
      filters: {
        search: searchedValue,
        siteIds: siteId ? [siteId] : [],
        parentIds: parentId ? [parentId] : [],
        includeLocationsInSearch: !!siteId,
        types: [
          ItemTypeEnum.Asset,
          ItemTypeEnum.AssetKit,
          // ItemTypeEnum.AssetModel
        ],
      },
    },
  });

  useEffect(() => {
    const result = data?.items?.edges?.map((a) => {
      const item = a.node as AssetItemSchema | AssetKitItemSchema;

      const { result, searchBy } = processSearch(
        ['title', 'sku', 'description'],
        searchedValue,
        item,
      );

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });

    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
