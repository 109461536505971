import { DataGridHeadCell, TableHeaderCell } from '@procurenetworks/procure-component-library';
import { lowerCase, snakeCase, startCase } from 'lodash';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { formatDate } from 'app/utils/date';

export const getOrderRequestReportTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'orderRequestCode',
    label: 'Order Request ID',
    value: 'orderRequestCode',
  },
  {
    id: 'userName',
    label: 'User Name',
    value: 'userName',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.createdBy?.fullName}>
        {row?.createdBy?.fullName}
      </span>
    ),
  },
  {
    id: 'destinationSiteName',
    label: 'Ship To',
    value: 'destinationSiteName',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.destinationSite?.name}>
        {row?.destinationSite?.name}
      </span>
    ),
  },
  {
    id: 'billToSiteName',
    label: 'Bill To',
    value: 'billToSiteName',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.billToSite.name}>
        {row.billToSite.name}{' '}
      </span>
    ),
  },
  {
    id: 'fulfillingSites',
    label: 'From Site',
    value: 'fulfillingSites',
    valueNode: ({ row }) => (
      <span className="truncate" title={String(row?.fulfillingSites?.map(({ name }: any) => name))}>
        {String(row?.fulfillingSites?.map(({ name }: any) => name))}{' '}
      </span>
    ),
  },
  {
    id: 'departmentName',
    label: 'Department',
    value: 'departmentName',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.department?.name}>
        {row?.department?.name}
      </span>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    value: 'date',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.createdAt)}>
        {formatDate(row.createdAt)}
      </span>
    ),
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    valueNode: ({ row }) => (
      <span className="truncate" title={startCase(lowerCase(row.status))}>
        {startCase(lowerCase(row.status))}
      </span>
    ),
  },
  {
    id: 'deliverTo',
    label: 'Deliver To',
    value: 'deliverTo',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.deliverTo?.name}>
        {row?.deliverTo?.name}
      </span>
    ),
  },
];

const HTTPS_REGEX = /^https?:\/\//i;
export const getOrderItemsTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: 'Item',
    value: 'sku',
    valueNode: ({ row }) => {
      if (row?.item?.sku) {
        return (
          <ReportSKULink
            item={{ ...row, ...row.item, type: snakeCase(row.type).toUpperCase(), id: row?.itemId }}
          />
        );
      }

      const label = row.title || row.description || row.upcCode;

      if (label) {
        return (
          <span className="truncate" title={label}>
            {label}
          </span>
        );
      }

      if (row.website) {
        let href = row.website;

        if (!HTTPS_REGEX.test(href)) {
          href = `https://${href}`;
        }

        return (
          <a
            className="sku-link truncate"
            title={row.website}
            href={href}
            rel="noreferrer"
            target="_blank">
            {row.website}
          </a>
        );
      }

      if (row.imageUrl) {
        return (
          <img alt="Item" className="max-h-[20px] max-w-[20px] object-cover" src={row?.imageUrl} />
        );
      }

      return null;
    },
  },
  {
    id: 'category',
    label: 'Category',
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={startCase(row?.item?.category?.name)}>
        {startCase(row?.item?.category?.name)}
      </span>
    ),
  },
  {
    id: 'project',
    label: 'Project',
    value: 'project',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.project?.name}>
        {row?.project?.name}
      </span>
    ),
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    labelAlign: 'left',
    rowAlign: 'right',
  },
  {
    id: 'status',
    label: 'Status',
    value: 'status',
    valueNode: ({ row }) => (
      <span className="truncate" title={startCase(lowerCase(row?.status))}>
        {startCase(lowerCase(row?.status))}
      </span>
    ),
  },
];
