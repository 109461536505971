export const generateColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const color = `hsl(${hue}, 60%, 30%)`; 
  return color;
};

export function generateWarranty() {
  const colors = ['#e14144', '#fd7e14', '#3eb45c', '#17a2b8'];
    const warranty = {
      color: colors,
      labels: [
        { value: Math.floor(Math.random() * 100), label: 'Warranty Expired' },
        { value: Math.floor(Math.random() * 100), label: 'Warranty Expiring Soon' },
        { value: Math.floor(Math.random() * 100), label: 'Under Warranty' },
        { value: Math.floor(Math.random() * 100), label: 'No Warranty' },
      ],
    };

  return warranty;
}