import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { APP_CONFIG } from 'app/consts/config';
import currentUser from '../../modules/auth/utils/currentUser';

class SentryBugReportingService {
  static init() {
    Sentry.init({
      dsn: APP_CONFIG.sentryDSN,
      integrations: [new BrowserTracing()],
      environment: APP_CONFIG.deploymentEnvironment,
      tracesSampleRate: 1,
      maxBreadcrumbs: 50,
      attachStacktrace: true,
      enabled: SentryBugReportingService.isValidEnvironment(),
      beforeBreadcrumb: (breadcrumb) => {
        if (breadcrumb.category === 'console') {
          return null;
        }
        return breadcrumb;
      },
    });

    Sentry.withScope((scope) => {
      scope.setTags({
        userId: currentUser.id(),
        tenantId: currentUser.tenantId(),
      });
    });
  }

  static setUser(user: { userId?: string; tenantId?: string } | null) {
    Sentry.setUser(user);
  }

  static isValidEnvironment() {
    return ['development', 'staging', 'production'].includes(
      APP_CONFIG.deploymentEnvironment || '',
    );
  }

  static reportError(error: unknown, options?: { tags?: any; extra?: any }) {
    if (SentryBugReportingService.isValidEnvironment()) {
      Sentry.captureException(error, options);
    } else {
      console.error('[BugReportingService: reportIssue] error', error);
    }
  }
}

export default SentryBugReportingService;
