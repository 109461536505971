import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string;
};

const StorageForm = (props: Props) => {
  const { control,className="mt-0" } = props;

  return (
    <>
      <Typography
        className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Storage.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Name}
                placeholder={Network.FormLabels.Storage.Name}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageSerial"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Serial}
                placeholder={Network.FormLabels.Storage.Serial}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageType"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Type}
                placeholder={Network.FormLabels.Storage.Type}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageInterface"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Interface}
                placeholder={Network.FormLabels.Storage.Interface}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageVendor"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Vendor}
                placeholder={Network.FormLabels.Storage.Vendor}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageSize"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Size}
                placeholder={Network.FormLabels.Storage.Size}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageFirmware"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.FirmwareRevision}
                placeholder={Network.FormLabels.Storage.FirmwareRevision}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="storageDevice"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Storage.Device}
                placeholder={Network.FormLabels.Storage.Device}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StorageForm;
