import Wishlist from 'app/i18n/Wishlist';

export const WISHLIST_FORM_STEPS = {
  WHO: 'who',
  WHY: 'why',
  WHEN: 'when',
  WHERE: 'where',
  HOW: 'how'
}

export function getWishlistFormSteps() {
  return [
    "Who",
    "Why",
    "When",
    "Where",
    "How",
  ];
}
