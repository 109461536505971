import { useCallback, useState } from 'react';

import { getPageSettingsFromStorage } from './../../../../../../app/utils/paginationSettingsUtil';
import { Product, useHooverShopProductsQuery } from './useHooverShopProducts';

export type SearchQuery = {
  brand: string;
  code: string;
  isbn: string;
  model: string;
  name: string;
  [key: string]: string;
}

export interface State {
  products: Product[];
  loading: boolean;
  totalCount: number;
  productDetailModal: {
    open: boolean;
    selectedProduct?: Product;
  };
  search: string;
  searchQuery: SearchQuery;
  numberOfRowsPerPage: number;
  activePage: number;
}

const useFindShopProductState = () => {

  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(10);

  const [state, _setState] = useState<State>({
    loading: false,
    products: [],
    totalCount: 0,
    productDetailModal: {
      open: false,
      selectedProduct: undefined,
    },
    search: '',
    searchQuery: {
      brand: '',
      code: '',
      isbn: '',
      model: '',
      name: '',
    },
    numberOfRowsPerPage: rowsPerPage,
    activePage: 0,
  });

  const { fetchHooverShopProducts } = useHooverShopProductsQuery();

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const handleProductResponse = useCallback(
    (response: any) => {
      const { products } = response?.data || {}
      const { nodes, totalCount } = products || {};
      setState({
        products: nodes || [],
        loading: false,
        totalCount,
      });
    },
    [setState],
  );

  const getHooverProducts = useCallback(
    async (variables: any) => {
      setState({
        loading: true,
      });

      const response = await fetchHooverShopProducts(variables);
      handleProductResponse(response);
    },
    [fetchHooverShopProducts, state.search, handleProductResponse],
  );

  const fetchMore = useCallback(() => {
    setState({
      activePage: state.activePage + 1,
    });
  }, [state.activePage, setState]);

  const onProductCodeClick = useCallback(
    (productData: Product) => {
      setState({
        productDetailModal: {
          open: true,
          selectedProduct: productData,
        },
      });
    },
    [setState],
  );

  return {
    state,
    setState,
    fetchMore: fetchMore,
    getProducts: getHooverProducts,
    onProductCodeClick,
  };
};

export type UseFindUpcStateReturnType = ReturnType<typeof useFindShopProductState>;
export default useFindShopProductState;
