import ReadMore from 'app/modules/components/ReadMore';
import { LimitTextLength, LinkUrl, findAndReturnURL } from '../../views/OrderDetail/utils';
import { OrderRequestItemSchema } from '../../../../types/schema';
import { Box } from '@mui/material';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  valueKey: string;
}

const OrderRequestItemNoSkuView = (props: Props) => {
  const { orderRequestItem, valueKey } = props;
  const { website, description, upcCode } = orderRequestItem;

  const text = { description, website, upcCode }[valueKey] || '';
  if (!text) {
    return null;
  }

  // Website or description (If it contains any links)
  const textDetails = findAndReturnURL(text);

  if (textDetails?.websiteUrl !== null && text) {
    const websiteOrDescription = textDetails?.content || '';
    return (
      <Box className="flex h-[80px] w-[100%] items-center text-blue-900">
        <ReadMore
          text={websiteOrDescription}
          isLinkContent
          redirectLink={LinkUrl(textDetails?.websiteUrl)}
        />
      </Box>
    );
  }
  // upcCode description or website preview
  return (
    <p className="truncate" title={text}>
      {text}
    </p>
  );
};

export default OrderRequestItemNoSkuView;
