import AdminLayout from 'app/components/AdminLayout';
import AddDepartment from 'app/modules/departments/views/AddDepartment';

function AddDepartmentPage() {
  return (
    <AdminLayout>
      <AddDepartment />
    </AdminLayout>
  );
}

export default AddDepartmentPage;
