import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import { SelectOption } from 'app/components/Select/types';
import Reports from 'app/i18n/Reports';
import { SortOrderEnum } from 'app/types/schema';

interface Props {
  control: any;
}

const getOptions = (): SelectOption[] => {
  return [
    { value: SortOrderEnum.Asc, label: Reports.Ascending },
    { value: SortOrderEnum.Desc, label: Reports.Descending },
  ];
};

const SortOrderSelectorFormField = (props: Props) => {
  const { control } = props;

  return (
    <Controller
      control={control}
      name="sortOrder"
      render={({ field, fieldState }) => (
        <FormSelect
          {...field}
          error={fieldState.error}
          label="Sort By Date"
          options={getOptions()}
        />
      )}
    />
  );
};

export default SortOrderSelectorFormField;
