import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import { upcCodeOrAsinCodeValidator } from 'app/modules/assetsInventory/utils/rules';
import { ButtonSize } from 'app/types/button';
import { Maybe } from 'app/types/schema';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import FormCheckBoxInput from '../../../../../../components/ProcureForm/FormCheckBoxInput';
import Inventory from '../../../../../../i18n/Inventory';
import { InventoryFormProps } from '../../types';

const InventoryUpcCodeField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { noUpcCode, onFindUpcClick, control, onNoUpcCodeChange, isItemTypeProduct } = formState;

  const upcCodeValidator = useCallback(
    (value: Maybe<string>) => {
      if (!noUpcCode) {
        return upcCodeOrAsinCodeValidator(value);
      }
      return undefined;
    },
    [noUpcCode],
  );

  if (!isItemTypeProduct) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name={`upcCode`}
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            endEndorsement={
              <Button
                disabled={noUpcCode || disabled}
                size={ButtonSize.medium}
                theme="success"
                onClick={onFindUpcClick}>
                {Inventory.FormLabels.FindUpc}
              </Button>
            }
            error={fieldState.error}
            isDisabled={noUpcCode || disabled}
            isRequired={!noUpcCode}
            label={Inventory.FormLabels.UpcCode}
            onChange={(value: string) => {
              const inputValue = value.toString();
              if (inputValue.length <= 14) {
                field.onChange(value);
              }
            }}
          />
        )}
        rules={{
          validate: upcCodeValidator,
        }}
      />
      <div className="mb-20 mt-[16px]">
        <Controller
          control={control}
          name="noUpcCode"
          render={({ field, fieldState }) => (
            <FormCheckBoxInput
              {...field}
              classNames="!p-0 mr-[8px]"
              disabled={disabled}
              label={Inventory.FormLabels.NoUpcCode}
              onChange={(value) => {
                field?.onChange(value);
                onNoUpcCodeChange(value);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default InventoryUpcCodeField;
