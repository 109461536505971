import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { CompanySchema } from 'app/types/schema';

import { useCompnayListQuery } from '../../components/companySearch/graphql/queries/generated/company';

const useCompanySearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useCompnayListQuery({
    variables: {
      limit: 7,
      filters: {
        search: searchedValue,
      },
    },
  });

  useEffect(() => {
    const result = data?.companies?.edges?.map((a) => {
      const item = a.node as CompanySchema
      return {
        label: item.companyName,
        id: item.id,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseCompanySearchSuggestionReturnType = ReturnType<typeof useCompanySearchSuggestion>;

export default useCompanySearchSuggestion;
