import LoginHeader from 'app/modules/auth/views/Login/components/LoginHeader';
import WishlistConfirmationViewPage from 'app/modules/wishlist/views/confirmationView';

function ConfirmationViewPage() {
  return (
    <>
      <LoginHeader />
      <WishlistConfirmationViewPage />
    </>
  );
}

export default ConfirmationViewPage;
