import ReceivingLayout from '../../modules/receiving/layout';
import Receive from '../../modules/receiving/view/Receive';

function ReceivingReceive() {
  return (
    <ReceivingLayout>
      <Receive />
    </ReceivingLayout>
  );
}

export default ReceivingReceive;
