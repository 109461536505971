import moment from 'moment';
import { SelectOption } from 'app/components/Select/types';
import { DateFilter } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

export const getAccountingDateFilterOptions = (): (SelectOption & { createdAt: DateFilter })[] => {
  const getStartTime = (days: number) => {
    return moment().subtract(days, 'day').startOf('day').toISOString();
  };

  const getEndTime = () => {
    return moment().endOf('day').toISOString();
  };

  return [
    {
      value: 'last-30',
      label: 'Last 30 days',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(30), endTime: getEndTime() },
      },
    },
    {
      value: 'last-60',
      label: 'Last 60 days',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(60), endTime: getEndTime() },
      },
    },
    {
      value: 'last-90',
      label: 'Last 90 days',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(90), endTime: getEndTime() },
      },
    },
    {
      value: 'last-120',
      label: 'Last 120 days',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(120), endTime: getEndTime() },
      },
    },
    {
      value: 'custom',
      label: 'Select Date Range',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: null, endTime: null },
      },
    },
  ];
};
