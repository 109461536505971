import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AuditsTableQueryVariables = Types.Exact<{
  filters: Types.AuditTransactionReportFilter;
}>;


export type AuditsTableQuery = { __typename?: 'Query', auditTransactionReports: Array<{ __typename?: 'AuditTransactionReportSchema', id: string, createdAt: string, site?: { __typename?: 'LocationSchema', id: string, name: string } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string } }> };


export const AuditsTableDocument = gql`
    query auditsTable($filters: AuditTransactionReportFilter!) {
  auditTransactionReports(filters: $filters) {
    id
    createdAt
    site {
      id
      name
    }
    createdBy {
      id
      name
    }
  }
}
    `;

export function useAuditsTableQuery(options: Omit<Urql.UseQueryArgs<AuditsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<AuditsTableQuery>({ query: AuditsTableDocument, ...options });
};