import React from 'react';
import { Content } from '@procurenetworks/procure-component-library';
import Network from 'app/i18n/Network';

import EntityManager from '../../../components/EntityManager';
import NetworkAssetEditForm from './components/NetworkAssetEditForm';

interface Props {
  networkAssetId: string;
}

const NetworkAssetEdit = (props: Props) => {
  const { networkAssetId } = props;

  return (
    <EntityManager>
      <EntityManager.Title
        documentTitle={Network.NetworkAssetEdit}
        title={Network.NetworkAssetEdit}
      />
      <Content>
        <NetworkAssetEditForm NetworkAssetId={networkAssetId} />
      </Content>
    </EntityManager>
  );
};

export default NetworkAssetEdit;
