import { Controller } from 'react-hook-form';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import Reports from 'app/i18n/Reports';

interface Props {
  control: any;
  name?: string;
}

const ReportUsersSelect = (props: Props) => {
  const { control, name = 'userIds' } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormUsersSelect
          {...field}
          className="flex-1"
          label={Reports.Users}
        />
      )}
    />
  );
};

export default ReportUsersSelect;
