import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ItemTypeEnum, TransactionEntityTypeEnum } from '../../../../../types/schema';
import { LocationSelectorOption } from '../../../../locations/components/LocationSelector/types';
import { SiteSelectorOption } from '../../../../locations/components/SiteSelector/types';
import useItemInStockData from '../../../hook/useItemInStockData';
import { getItemInStockIdFromParams } from '../../../utils/itemInStockParams';
import { getSiteAndLocationIdsForKitItem, isKitTypeItem } from './utils';

const DEFAULT_VALUES = {
  itemInStockId: '',
  destinationSiteId: '',
  destinationLocationId: '',
  quantity: '0',
};

const initialState = {
  destinationSite: null,
  destinationLocation: null,
};

interface State {
  destinationSite?: SiteSelectorOption | null;
  destinationLocation?: LocationSelectorOption | null;
}

const useRestockForm = (transactionType: TransactionEntityTypeEnum) => {
  const queryParamsItemInStockId = getItemInStockIdFromParams();

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const { control, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      itemInStockId: queryParamsItemInStockId || '',
    },
  });
  const itemInStockId = watch('itemInStockId');
  const destinationSiteId = watch('destinationSiteId');

  const itemInStockTypes = useMemo(() => {
    if (transactionType === TransactionEntityTypeEnum.Asset) {
      return [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit];
    } else if (transactionType === TransactionEntityTypeEnum.Inventory) {
      return [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit];
    }

    return [];
  }, [transactionType]);

  const { itemInStock } = useItemInStockData(itemInStockId || '', itemInStockTypes);

  const isKitTypeItemInStock = useMemo(() => {
    return isKitTypeItem(itemInStock?.type);
  }, [itemInStock]);
  const itemType = useMemo(() => {
    return itemInStock?.type;
  }, [itemInStock?.type]);

  const { siteIds, locationIds } = useMemo(
    () => getSiteAndLocationIdsForKitItem(itemInStock),
    [itemInStock],
  );

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  const onResetForm = useCallback(
    (initialData?: Partial<typeof DEFAULT_VALUES>) => {
      setTimeout(() => {
        reset({
          ...DEFAULT_VALUES,
          ...(initialData || {}),
        });
        setState({
          ...initialState,
        });
      }, 0);
    },
    [reset, setState],
  );

  return {
    formState: {
      control,
      destinationSiteId,
      setState,
      itemInStockId,
      siteIds,
      locationIds,
      onResetForm,
      isKitTypeItemInStock,
      itemType,
    },
    state,
    handleSubmit,
    onResetForm,
    itemInStock,
  };
};

export type UseRestockFormReturnType = ReturnType<typeof useRestockForm>;
export default useRestockForm;
