import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

import WishlistLandingPage from './landingPage';

function WishlistPage(props: RouteComponentProps) {
  const user = useCurrentUser();
  const isLoggedIn = Boolean(user.id);
  const { history } = props;
  if (isLoggedIn){
    history.push(routes.WishlistListView());
    return null;
  } else{
    return <WishlistLandingPage />
  }
}

export default withRouter(WishlistPage);
