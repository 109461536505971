import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import ReadMore from 'app/modules/components/ReadMore';

export const getContactTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'addressName',
    label: 'Address Name',
    value: 'addressName',
    sortable: true,
    width: 220,
  },
  {
    id: 'addressLineOne',
    label: 'Address',
    value: 'addressLineOne',
    sortable: true,
    valueNode: ({ row }) => {
      return <ReadMore text={row.name} />;
    },
    width: 500,
  },
];
