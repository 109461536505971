import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddShelvesInWarehouseMutationVariables = Types.Exact<{
  input: Types.AddShelvesInWarehouseInput;
  query: Types.GetLastLocationNamesOfChildInput;
}>;


export type AddShelvesInWarehouseMutation = { __typename?: 'Mutation', addShelvesInWarehouse: { __typename?: 'WarehouseMutationPayload', success: boolean, reason?: string | null, locations?: Array<{ __typename?: 'LocationSchema', id: string, name: string, site: { __typename?: 'LocationSchema', id: string, name: string } }> | null, warehouse?: { __typename?: 'WarehouseSchema', id: string, lastLocationNamesOfChild: Array<string> } | null } };


export const AddShelvesInWarehouseDocument = gql`
    mutation addShelvesInWarehouse($input: AddShelvesInWarehouseInput!, $query: GetLastLocationNamesOfChildInput!) {
  addShelvesInWarehouse(input: $input) {
    success
    reason
    locations {
      id
      name
      site {
        id
        name
      }
    }
    warehouse {
      id
      lastLocationNamesOfChild(input: $query)
    }
  }
}
    `;

export function useAddShelvesInWarehouseMutation() {
  return Urql.useMutation<AddShelvesInWarehouseMutation, AddShelvesInWarehouseMutationVariables>(AddShelvesInWarehouseDocument);
};