import React, { useEffect, useState } from 'react';
import {
  DataGridComponentState,
  DataGridHeadCell,
} from '@procurenetworks/procure-component-library';
import clsx from 'clsx';

import Inventory from '../../../../i18n/Inventory';
import FormCompanySelect from '../../../contacts/components/FormCompanySelect';
import { UseInventoryStateReturnType } from '../../views/Inventory/hook/useInventoryState';
import { useStyle } from '../useStyle';

export interface VendorFilterLabelNodeProps {
  headCell: DataGridHeadCell;
  state: DataGridComponentState;
}

const VendorFilterLabelNode = (props: VendorFilterLabelNodeProps) => {
  const { state } = props;
  const classes = useStyle();
  const { extraProps } = state;
  const { filters, setState } = extraProps as UseInventoryStateReturnType;
  const { company } = filters;

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const storedCompanyId = new URLSearchParams(window.location.search).get('company');
      if (storedCompanyId) {
        setState({
          companyIdState: storedCompanyId,
        });
      }
      setInitialized(true);
    }
  }, [initialized, setState]);

  useEffect(() => {
    if (initialized) {
      if (company.value) {
        const params = new URLSearchParams(window.location.search);
        params.set('company', company.value);
        const queryString = params.toString();
        window.history.pushState({}, '', `${window.location.pathname}?${queryString}`);
      } else {
        const params = new URLSearchParams(window.location.search);
        params.delete('company');
        const queryString = params.toString();
        const url = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
        window.history.pushState({}, '', url);
      }
    }
  }, [initialized, company.value]);

  return (
    <div className="table-filter">
      <FormCompanySelect
        className={clsx(classes.root)}
        menuPortalTarget={document.body}
        name="company-select"
        placeholder={Inventory.FormPlaceholders.Company}
        selectInputClass=""
        value={company.value}
        onChange={(value) => {
          setState({
            companyIdState: value,
          });
        }}
        size="small"
      />
    </div>
  );
};

export default VendorFilterLabelNode;
