import './form-password-input.css';
import React, { HTMLInputTypeAttribute, useCallback, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { InputBaseComponentProps } from '@mui/material';
import { Button, TextField } from '@procurenetworks/procure-component-library';
import { ButtonHeight, ButtonSize } from 'app/types/button';

import { Maybe } from '../../../types/schema';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  label?: string;
  formLabel?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string> | Maybe<number>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError | undefined;
  placeholder?: string;
  multiline?: boolean;
  rows?: string | number;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: 'small' | 'medium';
  inputProps?: InputBaseComponentProps;
}

const FormPasswordInput = (props: Props) => {
  const {
    value,
    onBlur,
    label,
    onChange,
    error,
    className = 'mt-6',
    isRequired,
    rows,
    multiline,
    disabled,
    placeholder,
    size,
    autoFocus,
    inputProps,
    formLabel,
  } = props;

  const [passwordVisible, setPasswordVisibility] = useState(false);

  const onInputChange = useCallback(
    (
      event:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      const targetElement = (event as React.ChangeEvent).target as HTMLInputElement;
      onChange?.(targetElement.value || '');
    },
    [onChange],
  );

  const onInputBlur = useCallback(
    (_: any) => {
      if (onBlur) {
        onBlur();
      }
    },
    [onBlur],
  );

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;

  return (
    <>
      {formLabel ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
      <div className="mt-[6px] flex w-full justify-center gap-x-[8px]">
        <TextField
          {...props}
          autoFocus={autoFocus}
          className={className || ''}
          disabled={!!disabled}
          error={!!error}
          errorMessage={error ? (error.message as string) : ''}
          inputProps={inputProps}
          label={label || ''}
          labelNode={LabelNode}
          multiline={!!multiline}
          placeholder={placeholder}
          rows={rows || 0}
          size={size || 'medium'}
          type={passwordVisible ? 'text' : 'password'}
          value={value || ''}
          onBlur={onInputBlur} //Note: type is not consistence from procure component lib
          onChange={onInputChange}
        />
        <div className="form-password-input ">
          <Button
            // height={ButtonHeight.fill}
            // @ts-ignore
            size={ButtonSize.medium}
            theme={passwordVisible ? 'danger' : 'success'}
            onClick={() => setPasswordVisibility(!passwordVisible)}
            classes="min-w-[98px] h-[48px]">
            {passwordVisible ? 'Hide' : 'Show'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormPasswordInput;
