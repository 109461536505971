import { Controller } from 'react-hook-form';
import FormManufactureSelect from 'app/components/Form/FormManufactureSelect';
import Inventory from 'app/i18n/Inventory';

import { InventoryFormProps } from '../../types';

const InventoryManufacturerField = (props: InventoryFormProps) => {
  let { disabled, formState } = props;
  const { control, isItemTypeProduct } = formState;

  if (!isItemTypeProduct) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="manufacturerId"
        render={({ field, fieldState }) => (
          <FormManufactureSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={Inventory.FormLabels.Manufacturer}
          />
        )}
      />
    </div>
  );
};

export default InventoryManufacturerField;
