import '../../views/createList/style.css';
import React from 'react';
import { Card, CardActions, CardContent, Stack } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@procurenetworks/procure-component-library';

import { SelectedProducts } from '../../views/createList/types';

interface Props {
  item: SelectedProducts;
  tenantId?: string;
  userId?: string;
  expired: boolean;
}

const ShareCard = (props: Props) => {
  const { item, tenantId, userId, expired } = props;

  const onClickHandler = () => {
    var url = new URL(item.affiliateLink || '');
    if (tenantId) url.searchParams.set('oid', tenantId);
    if (userId) url.searchParams.set('uid', userId);
    window.open(url, '_blank');
  };

  return (
    <>
      <Card
        className="border border-grey-300"
        sx={{
          width: { xs: '100%', sm: '46%', md: '30%' },
          display: 'flex',
          flexDirection: 'column',
          margin: '10px',
          borderRadius: '16px',
          padding: '16px',
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);',
        }}>
        <Stack direction={'row'} justifyContent={'center'}>
          <CardMedia
            alt="Product Image"
            component="img"
            image={item.imageUrl}
            style={{ height: '140', width: '50%', margin: '5px' }}
          />
          <Stack alignItems={'start'} direction={'column'} justifyContent={'center'}>
            <Typography gutterBottom className="font-bold" variant="body1">
              ${item.price}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {item.quantity} needed
            </Typography>
          </Stack>
        </Stack>
        <CardContent>
          <Typography variant="body1">
            {item.name && item.name.length > 100 ? `${item.name.slice(0, 100)}...` : item.name}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {item.vendor}
          </Typography>
        </CardContent>
        <CardActions style={{ marginTop: 'auto' }}>
          <Button
            classes="w-[100%]"
            disabled={expired}
            theme={'success'}
            onClick={onClickHandler}>
            Buy Gift
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ShareCard;
