import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { WarehouseDetailsFragmentDoc } from '../../fragments/generated/WarehouseDetails';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateWarehouseMutationVariables = Types.Exact<{
  input: Types.CreateWarehouseInput;
}>;


export type CreateWarehouseMutation = { __typename?: 'Mutation', createWarehouse: { __typename?: 'WarehouseMutationPayload', success: boolean, reason?: string | null, warehouse?: { __typename?: 'WarehouseSchema', id: string, siteId: string, zones?: number | null, aisles?: number | null, racks: number, shelves: number, bins?: number | null } | null } };


export const CreateWarehouseDocument = gql`
    mutation createWarehouse($input: CreateWarehouseInput!) {
  createWarehouse(input: $input) {
    success
    reason
    warehouse {
      ...WarehouseDetails
    }
  }
}
    ${WarehouseDetailsFragmentDoc}`;

export function useCreateWarehouseMutation() {
  return Urql.useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(CreateWarehouseDocument);
};