import { isAccessible } from '../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { UserProfileFragment } from '../../auth/graphql/fragments/generated/UserProfile';

export const getAssetLayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) =>
  [
    {
      label: 'Search',
      value: 'search',
      subject: AllowedPermissionsSubjectEnum.Asset,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'New',
      value: 'new',
      subject: AllowedPermissionsSubjectEnum.Asset,
      action: AllowedPermissionActionsEnum.Create,
    },
    {
      label: 'Move',
      value: 'move',
      subject: AllowedPermissionsSubjectEnum.AssetMove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Remove',
      value: 'remove',
      subject: AllowedPermissionsSubjectEnum.AssetRemove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Restock',
      value: 'restock',
      subject: AllowedPermissionsSubjectEnum.AssetRestock,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Reserve',
      value: 'reserve',
      subject: AllowedPermissionsSubjectEnum.AssetReserve,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Check Out',
      value: 'checkout',
      subject: AllowedPermissionsSubjectEnum.AssetCheckout,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Check In',
      value: 'checkin',
      subject: AllowedPermissionsSubjectEnum.AssetCheckout,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Audit',
      value: 'new-asset-audit',
      subject: AllowedPermissionsSubjectEnum.AssetAudit,
      action: AllowedPermissionActionsEnum.Create,
    },
  ].filter((tab) => {
    return isAccessible(permissions, tab.action, tab.subject);
  });
