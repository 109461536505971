import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';

import InventoriesLayout from '../../modules/inventory/layout';
import AddInventory from '../../modules/inventory/views/AddInventory';

function AddInventoryPage() {
  return (
    <InventoriesLayout>
      <FindUPCProvider>
        <AddInventory />
      </FindUPCProvider>
    </InventoriesLayout>
  );
}

export default AddInventoryPage;
