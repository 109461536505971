import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type ContactListFragment = { __typename?: 'ContactSchema', createdAt: string, updatedAt: string, id: string, addressId: string, companyId: string, firstName: string, lastName: string, companySqlId?: number | null, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null };

export const ContactListFragmentDoc = gql`
    fragment ContactList on ContactSchema {
  createdAt
  updatedAt
  id
  addressId
  companyId
  firstName
  lastName
  companySqlId
  email
  fax
  homeContactNumber
  mobileContactNumber
  officeContactNumber
  title
}
    `;