import { Button } from '@procurenetworks/procure-component-library';
import Orders from '../../../../i18n/Orders';
import Common from '../../../../i18n/Common';

interface Props {
  onUpdateOrder: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

const EditOrderDetailsActions = (props: Props) => {
  const { onCancel, onUpdateOrder, disabled } = props;
  return (
    <div className="flex justify-end gap-[16px] py-[16px] px-[24px]">
      <div>
        <Button
          theme="success"
          onClick={onUpdateOrder}
          loading={disabled}
          classes="min-w-[140px] h-[44px]">
          {Orders.Actions.UpdateOrder}
        </Button>
      </div>
      <div className="ml-[4px]">
        <Button
          onClick={onCancel}
          disabled={disabled}
          classes="cancelOrderBtn min-w-[94px] h-[44px]">
          {Common.Actions.Cancel}
        </Button>
      </div>
    </div>
  );
};

export default EditOrderDetailsActions;
