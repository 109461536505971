import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormImageUpload from 'app/components/Form/FormImageUpload';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Profile from 'app/i18n/Profile';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import Box from 'app/ui-components/Box';

import { ProfileUpdateFormProps, Role } from '../ProfileForm/types';
import { PROFILE_FORM_RULES as Rules } from '../ProfileForm/utils';

function ProfileUpdate(props: ProfileUpdateFormProps) {
  const { defaultValues, disabled, loading, onProfileUpdate } = props;
  const [isUploading, setIsUploading] = useState(false);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });
  const onFormSubmit = useMemo(
    () => handleSubmit(onProfileUpdate),
    [handleSubmit, onProfileUpdate],
  );

  const joinedRoles = defaultValues.userRole?.map((role: Role) => role.name).join(' | ');

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [defaultValues]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="profilePicture"
          render={({ field, fieldState }) => (
            <FormImageUpload
              {...field}
              error={fieldState.error}
              label="Profile Image"
              setIsUploading={setIsUploading}
            />
          )}
        />
        <Box className="grid grid-cols-2 space-x-[24px]">
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                characterLimit={30}
                className="flex-1"
                error={fieldState.error}
                label={Profile.FormLabels.FirstName}
              />
            )}
            rules={Rules.firstName}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                characterLimit={30}
                className="flex-1"
                error={fieldState.error}
                label={Profile.FormLabels.LastName}
              />
            )}
            rules={Rules.lastName}
          />
        </Box>

        <Controller
          control={control}
          name="userRole"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isDisabled
              label={Profile.FormLabels.UserRole}
              value={joinedRoles}
              containerClassName="mt-[6px]"
            />
          )}
        />
        <Controller
          control={control}
          name="defaultSiteId"
          render={({ field, fieldState }) => {
            return (
              <FormSiteSelect
                {...field}
                error={fieldState.error}
                label={Profile.FormLabels.DefaultSite}
                placeholder={Profile.FormPlaceholders.DefaultSite}
                selectClassName="mt-[6px]"
              />
            );
          }}
        />
        <Box className="flex justify-end">
          <Button
            classes="!px-12 w-[134px]"
            disabled={isUploading || disabled}
            loading={loading}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.UpdateProfile}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default ProfileUpdate;
