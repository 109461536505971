import { useEffect, useMemo } from 'react';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { useValuesInEntitiesShipmentSearchQuery } from 'app/modules/ship/graphql/queries/generated/shippingShipmentSearch';
import { useShippingTransactionsTableQuery } from 'app/modules/ship/graphql/queries/generated/shippingTransactionsTable';
import {
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from 'app/types/schema';
import { parseSorts } from 'app/utils/sort';

import usePagination from '../../../../../../hooks/usePagination';
import { getShipmentTableColumns } from '../../utils/columns';
import useShipmentFilters from '../useShipmentFilters';

const useShipmentTable = () => {
  const { filtersState, setFilterState } = useShipmentFilters();
  const { search, table } = useEntityManager({ selection: false });
  const columns = useMemo(() => getShipmentTableColumns(), []);
  const sorts = useMemo(() => {
    return parseSorts(table.state.sorts, columns);
  }, [columns, table.state.sorts]);

  // AssetItems Query
  const { fetching, fetchingMore, data, onNextPage, onPrevPage, onReset } = usePagination(
    useShippingTransactionsTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        destinationSiteIds: filtersState.destinationSiteIds,
        requestorIds: filtersState.requestorIds,
        deliverToIds: filtersState.deliverToIds,
        departmentIds: filtersState.departmentIds,
        statuses: filtersState.statuses,
      },
      sorts: sorts,
    },
    {
      edgeKey: 'shippingTransactions',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const shippingTransactionTableRows = useMemo(() => {
    return data?.shippingTransactions.edges?.map(({ node }) => node) || [];
  }, [data?.shippingTransactions.edges]);

  useEffect(() => {
    setFilterState({ search: search.debouncedSearchText });
  }, [search.debouncedSearchText]);

  return {
    columns,
    rows: shippingTransactionTableRows,
    fetching: fetching || fetchingMore,
    filtersState,
    setFilterState,
    search,
    table,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.shippingTransactions.totalCount || 0,
  };
};

export default useShipmentTable;
