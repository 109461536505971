import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useItemInStockData from 'app/modules/assetsInventory/hook/useItemInStockData';
import { getItemInStockIdFromParams } from 'app/modules/assetsInventory/utils/itemInStockParams';
import { FormItemLocationOption } from 'app/modules/locations/components/FormItemLocationSelect/types';
import { LocationSelectorOption } from 'app/modules/locations/components/LocationSelector/types';
import { SiteSelectorOption } from 'app/modules/locations/components/SiteSelector/types';
import { CheckOutTransactionEntityInput, ItemTypeEnum } from 'app/types/schema';

import { CHECKOUT_RESERVED_ASSET } from '../../../ReserveAssets/types';
import { getAvailableQuantity } from './utils';

const DEFAULT_VALUES = {
  itemInStockId: '',
  sourceSiteId: '',
  sourceLocationId: '',
  destinationSiteId: '',
  destinationLocationId: '',
  departmentId: '',
  actorId: '',
  isDueDateRequired: 'false',
  dueDate: new Date(),
  meta: {
    note: '',
  },
  quantity: '',
  reservedTransactionId: '',
};

const initialState = {
  sourceSite: null,
  sourceLocation: null,
  destinationSite: null,
  destinationLocation: null,
};

interface State {
  sourceSite?: FormItemLocationOption | null;
  sourceLocation?: FormItemLocationOption | null;
  destinationSite?: SiteSelectorOption | null;
  destinationLocation?: LocationSelectorOption | null;
}

const useCheckOutForm = (transactions: CheckOutTransactionEntityInput[]) => {
  const queryParamsItemInStockId = getItemInStockIdFromParams();
  const { control, watch, setValue, handleSubmit, register, resetField, reset, trigger } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      itemInStockId: queryParamsItemInStockId || '',
    },
    reValidateMode: 'onChange',
  });

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  useEffect(() => {
    let reservedAssetToCheckout: any = localStorage.getItem(CHECKOUT_RESERVED_ASSET);
    if (reservedAssetToCheckout) {
      reservedAssetToCheckout = JSON.parse(reservedAssetToCheckout);
      if (!isEmpty(reservedAssetToCheckout)) {
        reset({
          actorId: reservedAssetToCheckout?.actorId || '',
          departmentId: reservedAssetToCheckout?.departmentId || '',
          destinationSiteId: reservedAssetToCheckout?.destinationSiteId || '',
          isDueDateRequired: 'false',
          itemInStockId: reservedAssetToCheckout?.entityId || '',
          meta: reservedAssetToCheckout?.meta || { note: '' },
          quantity: reservedAssetToCheckout?.quantity || '',
          sourceLocationId: reservedAssetToCheckout?.sourceLocationId || '',
          sourceSiteId: reservedAssetToCheckout?.sourceSiteId || '',
          reservedTransactionId: reservedAssetToCheckout?.id,
        });

        localStorage.removeItem(CHECKOUT_RESERVED_ASSET);
      }
    }
  }, []);

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const itemInStockId = watch('itemInStockId');
  const sourceSiteId = watch('sourceSiteId');
  const sourceLocationId = watch('sourceLocationId');
  const destinationSiteId = watch('destinationSiteId');
  const destinationLocationId = watch('destinationLocationId');
  const isDueDateRequired = watch('isDueDateRequired');
  const quantity = watch('quantity');

  const { itemInStock } = useItemInStockData(itemInStockId || '', [
    ItemTypeEnum.Asset,
    ItemTypeEnum.AssetKit,
  ]);

  const availableQuantity = useMemo((): number | undefined => {
    return getAvailableQuantity({
      sourceLocation: state?.sourceLocation,
      sourceSite: state?.sourceSite,
      transactions,
      itemInStockId,
    });
  }, [transactions, state?.sourceLocation, state?.sourceSite, itemInStockId]);

  const totalQuantity = useMemo(() => {
    return state?.sourceLocation?.totalQuantity;
  }, [state?.sourceLocation]);

  useEffect(() => {
    setValue('sourceLocationId', '');
    setValue('sourceSiteId', '');
  }, [itemInStockId]);

  useEffect(() => {
    setValue('sourceLocationId', '');
  }, [sourceSiteId]);

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  useEffect(() => {
    trigger('dueDate');
  }, [isDueDateRequired]);

  useEffect(() => {
    if (sourceSiteId && sourceLocationId && destinationSiteId && destinationLocationId) {
      trigger('destinationLocationId');
    }
  }, [sourceSiteId, sourceLocationId, destinationSiteId, destinationLocationId]);

  useEffect(() => {
    if (availableQuantity !== undefined) {
      register('quantity');
    }
  }, [availableQuantity, quantity]);

  const onResetForm = useCallback(() => {
    setTimeout(() => {
      reset({
        ...DEFAULT_VALUES,
      });
      setState({
        ...initialState,
      });
    }, 0);
  }, [reset, setState]);

  return {
    formState: {
      control,
      itemInStockId,
      sourceSiteId,
      isDueDateRequired,
      handleSubmit,
      quantity,
      availableQuantity,
      totalQuantity,
      destinationSiteId,
      setState,
      setValue,
      resetField,
    },
    state,
    onResetForm,
    itemInStock,
  };
};

export type UseCheckOutFormReturnType = ReturnType<typeof useCheckOutForm>;

export default useCheckOutForm;
