import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { LabelItemLocationsTableRowFragmentDoc } from '../../fragments/generated/LabelItemLocationsTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LabelInventoryTableQueryVariables = Types.Exact<{
  filters: Types.ItemLocationFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LabelInventoryTableQuery = { __typename?: 'Query', itemlocations: { __typename?: 'ItemLocationConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges: Array<{ __typename?: 'ItemLocationEdge', node: { __typename?: 'ItemLocationSchema', id: string, itemId: string, site: { __typename?: 'LocationSchema', id: string, name: string }, location: { __typename?: 'LocationSchema', id: string, name: string }, item: { __typename?: 'AssetItemSchema', id: string, title: string, sku: string } | { __typename?: 'AssetKitItemSchema', id: string, title: string, sku: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, title: string, sku: string } | { __typename?: 'InventoryKitItemSchema', id: string, title: string, sku: string } } }> } };


export const LabelInventoryTableDocument = gql`
    query LabelInventoryTable($filters: ItemLocationFilters!, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  itemlocations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...LabelItemLocationsTableRow
      }
    }
  }
}
    ${LabelItemLocationsTableRowFragmentDoc}`;

export function useLabelInventoryTableQuery(options: Omit<Urql.UseQueryArgs<LabelInventoryTableQueryVariables>, 'query'>) {
  return Urql.useQuery<LabelInventoryTableQuery>({ query: LabelInventoryTableDocument, ...options });
};