import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormInputSignUp from 'app/components/Form/FormInputSignUp';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';

import { SIGNUP_FORM_RULES as rules } from '../rules';
import { SignUpStepOneProps } from '../types';

function SignUpStepOne(props: SignUpStepOneProps) {
  const { isLoading, onSubmit, defaultValues } = props;

  const { control, handleSubmit } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  return (
    <div className="">
      <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
        <Box className="mx-auto min-w-[320px] max-w-[950px] space-y-24">
          <h1 className="mt-12 text-[36px] font-semibold text-grey-900">Save More. Serve More.</h1>
          <p className="mt-12 text-start font-medium text-grey-900">
            {SignUp.FormLabels.description.first}
          </p>
          <p className="mt-12 text-start font-medium text-grey-900">
            {SignUp.FormLabels.description.second}
          </p>
          <p className="mt-12 text-start font-medium text-grey-900">
            {SignUp.FormLabels.description.third}
          </p>
        </Box>
        <Box className="space-y-24">
          <Box className="mt-12 flex space-x-32">
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormInputSignUp
                  {...field}
                  autoFocus
                  isRequired
                  className="flex-1"
                  error={fieldState.error}
                  label={SignUp.FormLabels.FirstName}
                />
              )}
              rules={rules.firstName}
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormInputSignUp
                  {...field}
                  isRequired
                  className="flex-1"
                  error={fieldState.error}
                  label={SignUp.FormLabels.LastName}
                />
              )}
              rules={rules.lastName}
            />
          </Box>

          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={SignUp.FormLabels.Email}
                response={SignUp.FormValidations.EmailExist}
              />
            )}
            rules={rules.email}
          />

          <Controller
            control={control}
            name="primaryPhoneNumber"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={SignUp.FormLabels.PrimaryPhoneNumber}
              />
            )}
            rules={rules.primaryPhoneNumber}
          />

          <Box className="flex justify-end">
            <Button
              loading={isLoading}
              theme="success"
              onClick={onFormSubmit}
              classes="min-w-[138px] h-[44px]">
              {SignUp.FormActions.letsdoThis}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  );
}

export default SignUpStepOne;
