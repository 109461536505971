import { useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Inventory from 'app/i18n/Inventory';
import {
  cleanedAttachmentItems,
  prepareBulkEditInputEntries,
} from 'app/modules/assetsInventory/utils/utils';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useUpdateQuantityConfigurationsAtItemLocationsMutation } from 'app/modules/inventory/components/QuantityBySite/graphql/mutations/generated/updateQuantityConfigurationsAtItemLocations';
import { ItemTypeEnum } from 'app/types/schema';

import { useUpdateInventoryItemsMutation } from '../../../EditInventory/graphql/mutations/generated/updateInventoryItems';
import { useUpdateInventoryKitItemsMutation } from '../../../EditInventory/graphql/mutations/generated/updateInventoryKitItems';
import { UseInventoryStateReturnType } from '../useInventoryState';
import { UseInventoryTableReturnType } from '../useInventoryTable';
import analytics from 'app/analytics';

type dataMinMaxType = {
  minimumQuantity?: string;
  maximumQuantity?: string;
};

type QuantityBySiteProps = {
  table: UseMultiSelectEntityManagerReturnType['table'];
  tableState: UseInventoryTableReturnType['tableState'];
  siteId?: string;
  setState: UseInventoryStateReturnType['setState'];
  formHasErrors: boolean;
};

const useQuantityBySiteState = (props: QuantityBySiteProps) => {
  const { table, tableState, siteId, setState } = props;

  const [tableData, setTableData] = useState<UseInventoryTableReturnType['tableState']>(tableState);

  const [{ fetching: quantityFetching }, onUpdateQuantityAtItemLocations] =
    useUpdateQuantityConfigurationsAtItemLocationsMutation();

  const [{ fetching: updateItemsFetching }, updateInventoryItems] =
    useUpdateInventoryItemsMutation();
  const [{ fetching: updateKitItemsFetching }, updateInventoryKitItems] =
    useUpdateInventoryKitItemsMutation();

  const updateEditableValues = (
    tableState: UseInventoryTableReturnType['tableState'],
    rowIds: string[],
  ) => {
    const updatedRows = tableState.rows.map((row: any) => {
      if (rowIds.includes(row.id)) {
        return {
          ...row,
          editable: true,
          editableFields: {
            minimumQuantity:
              row?.minimumQuantity?.toString() || row.stockInformation[0]?.minimumQuantity,
            maximumQuantity:
              row?.maximumQuantity?.toString() || row.stockInformation[0]?.maximumQuantity,
            pickableThroughOrderRequest: row.pickableThroughOrderRequest,
            title: row.title,
            model: row.model,
            modelNumber: row.modelNumber,
            brand: row.brand,
            description: row.description,
            type: row.type,
            categoryId: row.categoryId,
            vendorId: row.vendorId,
            manufacturerId: row.manufacturerId,
          },
        };
      }
      return row;
    });
    setTableData({ ...tableState, rows: updatedRows });
  };

  const onBulkEdit = useCallback(() => {
    // updateEditableValues(tableState, table.state.selectedRowIds)
    setState({
      bulkEditItemModal: true,
    });
  }, [setState, table.state.selectedRowIds, tableState]);

  const onCancelBulkEdit = useCallback(() => {
    updateEditableValues(tableState, table.state.selectedRowIds);
    setState({
      bulkEditItemModal: false,
    });
  }, [setState, table.state.selectedRowIds, tableState]);

  useEffect(() => {
    const updatedRows = tableState.rows.map((tableRow: any) => {
      const existingRow = tableData.rows.find((row: any) => row.id === tableRow.id);

      if (existingRow) {
        return {
          ...existingRow,
          ...tableRow,
        };
      }

      return tableRow;
    });

    setTableData({
      ...tableState,
      rows: updatedRows,
    });
  }, [tableState]);

  const debouncedUpdateTableData = (rowId: string, data: dataMinMaxType) => {
    let updatedRows = tableData.rows.map((row: any) => {
      if (row.id === rowId) {
        return {
          ...row,
          editableFields: {
            ...row.editableFields,
            ...data,
          },
        };
      }
      return row;
    });
    setTableData({ ...tableData, rows: updatedRows });
  };

  const setEditableSiteState = useCallback(
    (rowId: string, data: dataMinMaxType) => {
      debouncedUpdateTableData(rowId, data);
    },
    [debouncedUpdateTableData],
  );

  const updatedValues: any = useMemo(() => {
    const mapped = [];
    for(let row of tableData.rows){
      // @ts-ignore
      if(table.state.selectedRowIds.includes(row?.id)){
        mapped.push({
          // @ts-ignore
          id: row.id,
          // @ts-ignore
          values: { ...row, ...row.editableFields },
        })
      }
    }
    return mapped;
  }, [table.state.selectedRowIds, tableData]);

  const onSaveBulkEdit = useCallback(async () => {
    const inventories: any[] = [];
    const inventoryKits: any[] = [];

    updatedValues.forEach((item: any) => {
      const asset = {
        itemId: item.id,
        pickableThroughOrderRequest: item.values.pickableThroughOrderRequest,
        attachments: cleanedAttachmentItems(item.values?.attachments) || [],
        externalProductCodes: [],
        title: item.values.title,
        mName: item.values.model,
        brand: item.values.brand,
        description: item.values.description,
        categoryId: item.values.categoryId,
        vendorId: item.values.vendorId,
        manufacturerId: item.values.manufacturerId,
      };

      if (item.values.type === ItemTypeEnum.InventoryKit) {
        const assetKit = {
          inventoryKitItemId: asset.itemId,
          pickableThroughOrderRequest: asset.pickableThroughOrderRequest,
          title: asset.title,
          description: asset.description,
          categoryId: asset.categoryId,
        };
        inventoryKits.push(assetKit);
      } else {
        inventories.push(asset);
      }
    });
    let promises: any[] = [];

    if (inventories.length) {
      const updateAssetsPromise = updateInventoryItems({
        input: { updateInventories: inventories },
      });
      promises.push(updateAssetsPromise);
    }

    if (inventoryKits.length) {
      const updateAssetKitsPromise = updateInventoryKitItems({
        input: { updateInventoryKits: inventoryKits },
      });
      promises.push(updateAssetKitsPromise);
    }

    if (siteId) {
      const inputValues = prepareBulkEditInputEntries(updatedValues, siteId);
      if (inputValues.length) {
        const updateQuantityPromise = onUpdateQuantityAtItemLocations({
          input: inputValues,
        });
        promises.push(updateQuantityPromise);
      }
    }

    Promise.all([promises])
      .then(() => {
        analytics?.track('Edited', { name: 'Inventory Bulk Edit' });
        SnackbarService.show({
          message: Inventory.SuccessMessages.InventoryItemUpdated(updatedValues),
        });
        onCancelBulkEdit();
      })
      .catch((error) => {
        console.error('[onSaveBulkEdit] Failed!', error);
      });
  }, [updatedValues]);

  useMemo(() => {
    updateEditableValues(tableState, table.state.selectedRowIds);
    if (!table.state.selectedRowIds.length) {
      onCancelBulkEdit();
    }
  }, [tableState.rows, table.state.selectedRowIds]);

  return {
    tableData,
    setTableData,
    setEditableSiteState,
    onBulkEdit,
    onCancelBulkEdit,
    onSaveBulkEdit,
    fetchingBulkEdit: quantityFetching || updateItemsFetching || updateKitItemsFetching,
  };
};

export type UseQuantityBySiteStateReturnType = ReturnType<typeof useQuantityBySiteState>;

export default useQuantityBySiteState;
