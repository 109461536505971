import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';

import Modal from '../../../../components/Modal';
import Common from '../../../../i18n/Common';
import Import from '../../../../i18n/Import';
import { UseImportFormReturnType } from '../ImportForm/hook/useImportFormState';

interface Props {
  state: UseImportFormReturnType['state']['importStatusDialog'];
  onClose: () => void;
}
const InvalidImportInputDialog = (props: Props) => {
  const { state, onClose } = props;
  const { open, importJobMetadata } = state;
  const { importErrorMessageUrl, validDataCount, inValidDataCount } = importJobMetadata || {};

  const onDownloadFile = useCallback(() => {
    if (importErrorMessageUrl) {
      window.open(importErrorMessageUrl, '_blank');
    }
  }, [importErrorMessageUrl]);

  return (
    <Modal
      actions={
        <div className="flex gap-[16px]">
          <div className="flex">
            <Button theme="success" onClick={onDownloadFile} classes="min-w-[94px] h-[44px]">
              {Import.Actions.DownloadFile}
            </Button>
          </div>
          <div className="flex">
            <Button onClick={onClose} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Close}
            </Button>
          </div>
        </div>
      }
      id="import-validation-alert"
      open={open}
      title={Import.FormLabels.ImportStatus}
      onClose={onClose}>
      <div className="w-[350px]">
        <div>
          {validDataCount}&nbsp;{Import.FormValidationMessages.SuccessFullyImported}
        </div>
        <div>
          {inValidDataCount}&nbsp;{Import.FormValidationMessages.NotImported}
        </div>
        <div className="mt-[12px]">{Import.FormValidationMessages.DownloadFileMessage}</div>
      </div>
    </Modal>
  );
};

export default InvalidImportInputDialog;
