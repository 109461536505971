export const convertToISBN13 = (isbn = '') => {
    // eslint-disable-next-line no-param-reassign
    isbn = isbn.split(' ').join('')
    if (!isbn || isbn.length === 13) {
      return isbn
    }
    // Remove the ISBN 10 check digit from the end
    let intermediate: any = isbn.slice(0, -1)
    // Add 978 to the front
    intermediate = [`978${intermediate}`]
    // Add the ISBN-13 check digit to the end
    // Check digit calculation
    /**
     * 1 - Take each digit as an element in in an array x_ i.e. The ith digit i the ISBN is x_i e.g. X4 is the 4th digit.
     * 2 - Multiply the even digits by 3 and sum these.
     * 3 - add these to the sum of the odd digits
     * 4 - find the remainder mod 10
     * 5 - subtract from 10 (you have a number 1-10)
     * 6 - This is the result as a number between 1 and 10, unless the figure is 10 then the result is 0.
     */
    let sum = 0
    for (const [index, digit] of intermediate.entries()) {
      sum += index % 2 === 1 ? 3 * Number.parseInt(digit, 10) : Number.parseInt(digit, 10)
    }
    const checkDigit = (10 - (sum % 10)) % 10
    return intermediate.join('') + checkDigit
  }
  