import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import WishlistCreateList from 'app/modules/wishlist/views/createList';

import WishlistLayout from '../../modules/wishlist/WishlistLayout';

function WishlistEditListPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  const id = match.params.id;

  return (
    <WishlistLayout>
      <WishlistCreateList
        isCreatingProduct={match.path.includes(routes.CreateWishlistProducts(':id'))}
        listView={true}
        wishlistId={id}
      />
    </WishlistLayout>
  );
}

export default withRouter(WishlistEditListPage);
