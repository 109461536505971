import {
  composeValidators,
  emailValidator,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Manufacturer from 'app/i18n/Manufacturers';

export const MANUFACTURE_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Manufacturer.FormValidationMessages.NameRequired),
      textMinLengthValidator(Manufacturer.FormValidationMessages.NameRequired, 3),
      textMaxLengthValidator(Manufacturer.FormValidationMessages.NameRequired, 30),
    ),
  },

  email: {
    validate: emailValidator(Manufacturer.FormValidationMessages.EmailInvalid),
  },
};
