import { useEffect, useState } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { cx } from '@emotion/css';
import { Box, Grid } from '@mui/material';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { useNetworkAssetsDetailQuery } from 'app/modules/network/graphql/queries/generated/networkAssetDetail';
import { defaultGridColumn } from 'app/modules/network/utils';
import { NetworkAssetSchema, Note } from 'app/types/schema';

import Details from '../Details';
import NetworkAssetDetailHeader from '../NetworkAssetDetailHeader';
import Notes from '../Notes';
import OverviewDetail from '../Overview';
import SoftwareDetail from '../SoftwareDetail';
import SystemDetail from '../SystemDetail';
type Props = {
  NetworkAssetId: string;
} & RouteComponentProps;

const NetworkAssetDetailSidebar = (props: Props) => {
  const { NetworkAssetId, history } = props;
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const [id, setId] = useState<string>(NetworkAssetId);
  const [editable, setEditable] = useState<boolean>(false);
  const [note, setNote] = useState<Note | undefined | null>(null);
  const [networkAssetDetail, setNetworkAssetDetail] = useState<NetworkAssetSchema>();
  const [{ fetching, data }] = useNetworkAssetsDetailQuery({
    variables: {
      filters: { networkAssetIds: [id] },
    },
  });

  useEffect(() => {
    setId(NetworkAssetId);
  }, [NetworkAssetId]);

  useEffect(() => {
    if (data) {
      if (data.networkAssets.edges && data.networkAssets.edges.length) {
        const networkAsset = data.networkAssets.edges[0]?.node;
        setNote(networkAsset?.note);
        setNetworkAssetDetail(networkAsset as NetworkAssetSchema);
      } else {
        history.push(routes.Error5XX());
      }
    }
  }, [data]);
  const sideBarMenuItems = ['Overview', 'System Details', 'Software', 'Notes', 'Details'];

  if (fetching) {
    return <Loader />;
  }
  return (
    <div>
      <Box className='px-[24px]'>
        <NetworkAssetDetailHeader networkAssetDetail={networkAssetDetail} />
      </Box>
      
      <Box sx={{ flexGrow: 1 }} className='border-t-[1px] border-grey-300 mt-[24px] flex gap-[24px] flex-col lg:flex-row'>
        {/* <Grid container columns={defaultGridColumn} spacing={{ xs: 1, sm: 2, lg: 8 }}> */}
        
          <Box className='w-full md:w-full lg:w-[260px] p-[24px] border-r-[1px] border-grey-300'>
          <Nav vertical>
              {sideBarMenuItems.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <Box
                      className={cx(
                        'relative mt-8 w-[100] cursor-pointer rounded-[8px] p-10   font-semibold text-grey-700 hover:bg-orange-200 hover:text-orange-900',
                        index === activeComponentIndex && '!bg-orange-900 !text-white',
                      )}
                      onClick={() => setActiveComponentIndex(index)}>
                      {item}
                    </Box>
                  </NavItem>
                );
              })}
            </Nav>
          </Box>
         <Box className='flex-1 p-[24px] '>
         
              {
                {
                  0: <OverviewDetail networkAssetDetail={networkAssetDetail} />,
                  1: <SystemDetail networkAssetDetail={networkAssetDetail} />,
                  2: <SoftwareDetail networkAssetDetail={networkAssetDetail} />,
                  3: <Notes id={networkAssetDetail?.mac || ''} note={note} setNote={setNote} />,
                  4: <Details networkAssetDetail={networkAssetDetail} />,
                }[activeComponentIndex]
              }
         </Box>
        {/* </Grid> */}
      </Box>
    </div>
  );
};

export default withRouter(NetworkAssetDetailSidebar);
