import { useCallback, useState } from 'react';

interface ShipmentFilters {
  destinationSiteIds: string[];
  requestorIds?: string[];
  deliverToIds?: string[];
  departmentIds?: string[];
  search: string;
}

const useShipmentFilters = () => {
  const [filtersState, _setFiltersState] = useState<ShipmentFilters>({
    destinationSiteIds: [],
    requestorIds: [],
    deliverToIds: [],
    departmentIds: [],
    search: '',
  });

  const setFilterState = useCallback(
    (nextState: Partial<ShipmentFilters>) => {
      _setFiltersState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFiltersState],
  );

  return {
    filtersState,
    setFilterState,
  };
};

export type UseShipmentFiltersReturnType = ReturnType<typeof useShipmentFilters>;

export default useShipmentFilters;
