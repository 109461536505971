import { useEffect, useRef } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Common from 'app/i18n/Common';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { JobPurposeEnum, JobStatusEnum, JobSubscriptionOperationEnum } from 'app/types/schema';

import { useReportJobSubscription } from '../../graphql/subscriptions/generated/reportJob';
import downloadFile from './downloadFile';

const useReportExportSubscription = () => {
  const { id } = useCurrentUser();
  const jobIdRef = useRef<string | null>(null);

  const [{ data }] = useReportJobSubscription({
    variables: {
      data: {
        createdById: id,
        operations: [
          JobSubscriptionOperationEnum.JobCreated,
          JobSubscriptionOperationEnum.JobUpdated,
        ],
        purposes: [JobPurposeEnum.ReportExport],
      },
    },
  });

  const job = data?.jobByCreator?.job;

  useEffect(() => {
    if (!job || jobIdRef.current === job?.id) {
      return;
    }

    if (job.status === JobStatusEnum.Completed && job.exportJobMetadata?.exportReportUrl) {
      const reportName = job.exportJobMetadata.exportReportUrl.split('/').pop()?.split('.')[0];
      const fileName = `${reportName}-${job.createdAt}.csv`;
      downloadFile(job.exportJobMetadata?.exportReportUrl, fileName);
      jobIdRef.current = job.id;
    } else if (job.status === JobStatusEnum.Failed) {
      SnackbarService.showError({ message: job.error || Common.ErrorMessage });
      jobIdRef.current = job.id;
    }
  }, [job, jobIdRef.current]);
};

export default useReportExportSubscription;
