import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { DatePicker } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import { CREATELIST_FORM_RULES as rules } from '../../../rules';
import FormLabel from 'app/components/ProcureForm/FormLabel';

function StepThree(props: any) {
  const { formValues, isLoading, onNext, onPrevious } = props;
  const defaultValues = {
    eventDate: formValues.eventDate,
  };
  const { control, handleSubmit } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onNext), [handleSubmit, onNext]);

  return (
    <form data-testid="create-list-form">
      <Box className="mx-auto mt-12 min-w-[120px] max-w-[487px] space-y-24">
        <label className="font-semibold text-grey-900"></label>
        <FormLabel className="text-[14px] font-semibold text-grey-800">{`What's the due date or event date?`}</FormLabel>
        <div style={{ marginTop: '6px', marginBottom: '10px' }}>
          <Controller
            control={control}
            name="eventDate"
            render={({ field, fieldState }) => (
              <>
                <DatePicker
                  {...field}
                  error={!!fieldState.error}
                  inputFormat="MM/dd/yyyy"
                  label={Wishlist.FormLabels.Date}
                  minDate={formatDate(
                    new Date(new Date().setHours(23, 59, 59, 999)).toDateString(),
                    DateFormatEnum.YYYY_MM_DD,
                  )}
                />
                {fieldState.error && (
                  <Typography className="text-[#FF0000]" style={{ fontSize: 12 }}>
                    {fieldState.error.message}
                  </Typography>
                )}
              </>
            )}
            rules={rules.eventDate}
          />
        </div>
        <Typography className="text-[14px] text-grey-800">
          If you are undecided, enter a date and edit it later.
        </Typography>
        <Stack>
          <Box className="flex justify-end space-x-[16px]">
            <Button block={true} onClick={onPrevious}>
              {Common.Actions.Previous}
            </Button>
            <Button block={true} loading={isLoading} theme="info" onClick={onFormSubmit}>
              {Common.Actions.Next}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

export default StepThree;
