import { useCallback, useMemo, useState } from 'react';
import Modal from 'app/components/Modal';
import { SnackbarService } from 'app/components/Snackbar';
import Assets from 'app/i18n/Assets';

import { DEFAULT_VALUES, UseAssetReminderStateReturnType } from '../../hook/useAssetReminderState';
import { ViewReminderDialog } from '../../styles';
import AssetReminderList from '../AssetReminderList';

interface Props {
  state: UseAssetReminderStateReturnType['state'];
  setState: UseAssetReminderStateReturnType['setState'];
  assetItemId?: string;
  isEditMode?: boolean;
  isRemindersViewMode?: boolean;
  editable?: boolean;
}

const ViewAssetReminderDialog = (props: Props) => {
  const [itemId, setItemId] = useState('');

  const { state, assetItemId, setState, isEditMode, isRemindersViewMode, editable } = props;
  const { reminders, reminderList, openViewReminder } = state;
  const _reminders = useMemo(() => {
    if (reminderList?.find((reminder) => (reminder as any)?.mode)) {
      return [...reminderList];
    }
    if (isRemindersViewMode === true) {
      return reminderList;
    } else if (editable) {
      return [
        ...reminderList,
        {
          ...DEFAULT_VALUES,
          id: new Date().getTime(),
          mode: 'add',
          isNew: true,
          assetItemId: isEditMode ? assetItemId ?? '' : '',
        },
      ];
    } else {
      return [...reminderList];
    }
  }, [reminderList]);

  const onSubmit = useCallback(
    async (values: any, id?: any) => {
      let reminderList = [..._reminders]?.filter((reminder: any) => !reminder?.mode);
      let originalReminders = [...reminders];
      const reminderIndex = reminderList?.findIndex((reminder) => (reminder as any)?.id === id);
      delete values?.mode;

      if (!values?.id) {
        values.id = new Date().getTime();
      }

      if (reminderIndex > -1) {
        if (
          !(reminderList[reminderIndex] as any).isNew &&
          !(originalReminders[reminderIndex] as any).isNew
        ) {
          (values as any).isUpdated = true;
        }
        reminderList[reminderIndex] = values;
        originalReminders[reminderIndex] = values;
        SnackbarService.show({
          message: Assets.SuccessMessages.Reminder.AssetReminderUpdated,
        });
      } else {
        (values as any).isNew = true;
        reminderList = [...reminderList, values];
        originalReminders = [...originalReminders, values];
        SnackbarService.show({
          message: Assets.SuccessMessages.Reminder.AssetReminderCreated,
        });
      }

      setItemId(values?.id);
      setState({ reminderList: [...reminderList], reminders: [...originalReminders] });
    },
    [_reminders, reminders, setState],
  );

  const onDelete = useCallback(
    async (id: string) => {
      setItemId(id);
      let reminderList = [..._reminders]?.filter((reminder: any) => !reminder?.mode);
      let originalReminders = [...reminders];
      reminderList = reminderList?.filter((reminder) => (reminder as any)?.id !== id);
      originalReminders = originalReminders
        ?.map((reminder: any) => {
          if (reminder?.id === id) {
            if (!reminder?.isNew) {
              reminder.isDeleted = true;
            }
            if (reminder?.isNew) {
              return null;
            }
          }
          return reminder;
        })
        ?.filter((reminder) => reminder);
      setState({ reminderList: [...reminderList], reminders: [...originalReminders] });
      SnackbarService.show({
        message: Assets.SuccessMessages.Reminder.AssetReminderDeleted,
      });
    },
    [_reminders, reminders, setState],
  );

  return (
    <Modal
      className={ViewReminderDialog}
      id={'view-reminder-dialog'}
      open={openViewReminder}
      title="Reminder(s)"
      onClose={() => setState({ openViewReminder: false })}>
      <AssetReminderList
        isEditMode={isEditMode}
        isRemindersViewMode={isRemindersViewMode}
        itemId={itemId}
        reminders={_reminders}
        setState={setState}
        state={state}
        onDelete={onDelete}
        onSubmit={onSubmit}
        disabled={!editable}
      />
    </Modal>
  );
};

export default ViewAssetReminderDialog;
