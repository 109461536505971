import Undelete from 'app/i18n/Undelete';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import SearchBox from '../../components/SearchBox';
import UndeleteItemsTable from '../../components/UndeleteItemsTable';
import { useUndeleteInventoryMutation } from './graphql/mutations/generated/undeleteInventory';
import useDeletedItemsTable from './hook/useDeletedInventoriesTable';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { getUndeleteInventoryTableColumns } from './utils/columns';
import { Box } from '@mui/material';

function UndeleteItems(props: RouteComponentProps) {
  const { search, filters, table, data, rows, fetching, onNextPage, onPrevPage, onReset } =
    useDeletedItemsTable();
  const [{ fetching: inventoryLoading }, onUndeleteInventory] = useUndeleteInventoryMutation();
  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <>
      <EntityManager subject={AllowedPermissionsSubjectEnum.Undelete} className="px-[24px] pt-0">
        <EntityManager.Title
          documentTitle={Undelete.UndeleteInventory}
          title={Undelete.UndeleteInventory}
          hasTopDivider
        />

        <Box
          className="mt-[24px] rounded-[12px] border border-grey-300"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <UndeleteItemsTable
            columns={getUndeleteInventoryTableColumns}
            data={rows}
            fetching={fetching}
            persistKey="undelete_inventory_table"
            siteId={filters.site.value}
            table={table}
            total={data?.inventoryItems.totalCount}
            undeleting={inventoryLoading}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
            onUndeleteItems={onUndeleteInventory}
            filterNodes={
              <SearchBox
                filters={filters}
                search={search}
                searchSuggestions={searchSuggestions}
                setSearchValue={setSearchValue}
              />
            }
            paginationWrapper="p-[24px] flex-wrap md:gap-[24px]"
          />
        </Box>
      </EntityManager>
    </>
  );
}

export default withRouter(UndeleteItems);
