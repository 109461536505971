const Receivables = {
  Receive: 'Receive',
  Schedule: 'Schedule Receiving',
  History: 'Receive History',
  FormLabels: {
    carrier: 'Carrier',
    trackingNumber: 'Tracking Number',
    vendor: 'Vendor/Sender',
    estimateDueDate: 'Due Date/ETA',
    notes: 'Shipping Notes',
    procureOrderId: 'Order Id',
    procureOrderTitle: 'Order Title',
    scheduleReceivingStep: 'Schedule Receiving',
    selectItemStep: 'Select Items To Be Received',
    Status: 'Status',
    deliverTo: 'Deliver To',
  },
  FormValidationMessages: {
    carrier: 'Please Enter Carrier',
    trackingNumber: 'Please Enter Tracking Number',
    vendor: 'Please Select Vendor',
    estimateDueDate: 'Please Select Estimated Due Date',
    procureOrderId: 'Please Select Procure Order ID',
    procureOrderTitle: 'Please Enter Procure Order Title',
    deliverTo: 'Please Select Deliver To',
    deliverToLocation: 'Please Select Deliver To Location',
    deliverToSite: 'Please Select Deliver To Site',
    QuantityInvalid: 'Please enter a valid Quantity.',
    OrderWithOrderedStatusItemRequired: `Please select an OrderID that includes at least one item with a status of “Ordered”`,
    QuantityRequired: 'Please add quantity received.',
    SiteRequired: 'Please select a site.',
    LocationRequired: 'Please select a location.',
    ShipmentAlreadyScheduled:
      'Items in this order request have already been scheduled to be received. Please select a different order request.',
    OrderAlreadyInList: 'This order is already present in the list.',
  },
  SuccessMessages: {
    ReceivablesScheduled: 'This shipment has been scheduled successfully.',
    ShipmentMarkedReceived: 'Items in this shipment have been successfully marked as received.',
    ShipmentDeleted: 'Shipment(s) successfully deleted.',
  },
  Actions: {
    ReceiveItems: 'Receive Items',
    AddShipment: 'Add Shipment',
  },
  ConfirmationMessage: {
    MarkItemsReceived: (count: number) =>
      `Are you sure you want to mark ${count} item(s) as received.`,
    confirmDelete: `Are you sure you want to delete the selected Shipment(s)?`,
  },
};

export default Receivables;
