import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PreprocessImportJobMutationVariables = Types.Exact<{
  input: Types.PreprocessImportJobInput;
}>;


export type PreprocessImportJobMutation = { __typename?: 'Mutation', preprocessImportJob: { __typename?: 'CreateJobPreprocessorPayload', success: boolean, columnHeaders?: Array<string> | null, entityMap?: Array<{ __typename?: 'CreateJobPreprocessorEntityMap', entityField: string, entityLabel: string, isRequired: boolean }> | null } };


export const PreprocessImportJobDocument = gql`
    mutation preprocessImportJob($input: PreprocessImportJobInput!) {
  preprocessImportJob(input: $input) {
    success
    entityMap {
      entityField
      entityLabel
      isRequired
    }
    columnHeaders
  }
}
    `;

export function usePreprocessImportJobMutation() {
  return Urql.useMutation<PreprocessImportJobMutation, PreprocessImportJobMutationVariables>(PreprocessImportJobDocument);
};