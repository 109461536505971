import { useEffect, useState } from 'react';
import { processSearch } from 'app/utils/processSearch';

import useDebounce from '../../../../../hooks/useDebounce';
import {
  AssetItemSchema,
  AssetKitItemSchema,
  InputMaybe,
  InventoryItemSchema,
  InventoryKitItemSchema,
  ItemFilters,
} from '../../../../../types/schema';
import { suggestionData } from '../../../../components/EntityManager/EntityManagerSearch';
import { useItemsSearchDialogSuggestionQuery } from '../graphql/queries/generated/itemsSearchDialogSuggestion';

function parseSearchInput(search: string) {
  return (search || '').replace(/['"]+/g, '');
}

const useSearchSuggestion = (filters: InputMaybe<ItemFilters>, search: string) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  useEffect(() => {
    setSearchValue(parseSearchInput(search));
  }, [search]);

  const searchedDebounce = useDebounce(searchValue);

  const [{ data }] = useItemsSearchDialogSuggestionQuery({
    variables: {
      filters: {
        search: searchedDebounce,
        ...filters,
      },
    },
  });

  useEffect(() => {
    const result = data?.items?.edges?.map((a) => {
      const item = a.node as
        | AssetItemSchema
        | AssetKitItemSchema
        | InventoryItemSchema
        | InventoryKitItemSchema;

      const { result, searchBy } = processSearch(
        ['title', 'sku', 'description'],
        searchValue,
        item,
      );

      return {
        label: searchBy || item.title,
        id: item.id,
        value: result || item.title,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export default useSearchSuggestion;
