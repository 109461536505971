import { getLocationName } from 'app/modules/locations/utils/location';
import { ItemLocationSchema, LocationSchema, ShippingContainerSchema } from 'app/types/schema';

import {
  FormState,
  LabelCustomEnum,
  LabelStockEnum,
  LabelTypeEnum,
} from '../../Labels/components/Forms/types';
import { CodeSchema } from '../components/types';

type values = {
  site: string;
  location: string;
  sku: string;
  title: string;
  bin: string;
};

const getCustomFieldValues = (fields: Array<string | undefined>, values: Partial<values>) => {
  let labelDetail: string[] = [];
  fields.forEach((check) => {
    if (check?.includes('site')) {
      labelDetail = [...labelDetail, values?.site] as string[];
    } else if (check?.includes('location')) {
      labelDetail = [...labelDetail, values?.location] as string[];
    } else if (check?.includes('binNumber')) {
      labelDetail = [...labelDetail, values?.bin] as string[];
    } else if (check?.includes('sku')) {
      labelDetail = [...labelDetail, values?.sku] as string[];
    } else if (check?.includes('title')) {
      labelDetail = [...labelDetail, values?.title] as string[];
    } else {
      labelDetail = [...labelDetail];
    }
  });
  return labelDetail;
};

const getDetails = (
  item: CodeSchema,
  type: LabelTypeEnum | undefined,
  codes?: FormState & { data: Array<CodeSchema> },
): string[] => {
  const stock = codes && codes.labelStock;
  const format = codes && codes.labelFormat;

  let charLimit = 15;
  if ([LabelStockEnum.Avery5163, LabelStockEnum.Avery6792].includes(stock as LabelStockEnum)) {
    charLimit = 32;
  } else if ((stock as LabelStockEnum) === LabelStockEnum.ZebraPrinter) {
    charLimit = 20;
  }

  const shippingSite = (item as ShippingContainerSchema)?.destinationSite?.name?.substring(
    0,
    charLimit,
  );
  const customFieldList = [codes?.firstField, codes?.secondField, codes?.thirdField] || [];

  switch (type) {
    case LabelTypeEnum.Location:
      const siteName = (item as LocationSchema)?.site?.name?.substring(0, charLimit);
      const locationName = getLocationName((item as LocationSchema)?.site?.id, {
        id: (item as LocationSchema)?.id,
        name: (item as LocationSchema)?.name,
      })?.substring(0, charLimit);

      if (codes?.custom === LabelCustomEnum.Custom) {
        return getCustomFieldValues(customFieldList, { site: siteName, location: locationName });
      }
      return [locationName];

    case LabelTypeEnum.Shipping:
      if (codes?.custom === LabelCustomEnum.Custom) {
        return getCustomFieldValues(customFieldList, { site: shippingSite });
      }
      return [shippingSite];

    case LabelTypeEnum.ShippingSetUp:
      return [shippingSite];

    default:
      const sku = (item as ItemLocationSchema)?.item?.sku?.substring(0, 15);
      const title = (item as ItemLocationSchema)?.item?.title?.substring(0, charLimit);
      const site = (item as ItemLocationSchema)?.site?.name?.substring(0, charLimit);
      const location = (item as ItemLocationSchema)?.location?.name?.substring(0, charLimit);
      const _locationName = getLocationName((item as ItemLocationSchema)?.site?.id, {
        id: (item as ItemLocationSchema)?.location?.id,
        name: (item as ItemLocationSchema)?.location?.name,
      })?.substring(0, charLimit);

      if (codes?.custom === LabelCustomEnum.Custom) {
        return getCustomFieldValues(customFieldList, {
          site: site,
          location: location,
          title,
          sku,
        });
      } else {
        switch (format) {
          case '1':
            return [sku, site, _locationName];
          case '2':
            return [title, _locationName];
          case '3':
            return [title, sku];
          case '4':
            return [sku];
          default:
            return [title];
        }
      }
  }
};

export default getDetails;
