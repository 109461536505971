import { useCallback, useEffect, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Layout, SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import {
  getNetworkAssetLayoutRoutesConfig,
  getNetworkAssetLayoutSidebarItems,
  getNetworkAssetlayoutTabs,
} from './utils';

function NetworkAssetLayout(props: React.PropsWithChildren<RouteComponentProps>) {
  const { children, history, match } = props;
  const currentUser = useCurrentUser();

  const routesConfig = useMemo(() => getNetworkAssetLayoutRoutesConfig(), []);

  const onSidebarItemClick = useCallback(
    (item: any) => {
      const config = routesConfig.find((config) => config.id === item.id);

      if (config) {
        history.push(config.route);
      }
    },
    [history, routesConfig],
  );

  const sideBarItemGroup = useMemo(
    () => getNetworkAssetLayoutSidebarItems(currentUser.workspacePermissions),
    [currentUser.workspacePermissions],
  );

  const tabs = useMemo(
    () => getNetworkAssetlayoutTabs(currentUser.workspacePermissions),
    [currentUser.workspacePermissions],
  );

  const showTabs = useMemo(
    () => tabs.find((tab) => history.location.pathname.includes(tab.value)),
    [history.location.pathname, tabs],
  );

  const selectedItemId = useMemo(() => {
    const selectedItem = routesConfig.find(({ routes }) => routes.includes(match.path));
    return selectedItem ? selectedItem.id : '';
  }, [match.path, routesConfig]);

  let defaultTab = '';

  useEffect(() => {
    const currentTab = history.location.pathname.includes(defaultTab as string);
    if (!currentTab) {
      history.push(defaultTab as string);
    }
  }, [defaultTab, history]);

  const onTabChange = useCallback(
    (tab: string) => {
      const config = routesConfig.find((config) => config.id === tab);

      if (config) {
        history.push(config.route);
      }
    },
    [history, routesConfig],
  );

  const isTabActive = useCallback(
    (tab: string) => {
      return history.location.pathname.includes(tab);
    },
    [history.location.pathname],
  );

  const filterMenuItems: SideBarItemGroup[] = useMemo(() => {
    return sideBarItemGroup.filter((item) => item.sideBarItems.length > 0) || [];
  }, [sideBarItemGroup]);

  return (
    <Layout
      withAppBar
      withDrawer={!!filterMenuItems.length}
      components={{ AppBar: <UnifiedHeader /> }}
      sideBarProps={{
        sideBarItemGroup: filterMenuItems,
        selectedItemId,
        onClick: onSidebarItemClick,
      }}
      mainWrapperClass="mainLayoutWrapper">
      {showTabs ? (
        <Nav
          tabs
          className="no-scrollbar flex gap-[8px] overflow-x-scroll rounded-tr-[12px] bg-white py-[24px] py-[16px] pl-[16px] md:pl-[24px] lg:py-[24px] lg:pl-[24px] ">
          {tabs.map(({ label, value }) => (
            <NavItem key={value}>
              <NavLink
                className={getTabNavLinkClassName(isTabActive(value))}
                onClick={() => onTabChange(value)}>
                {label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      ) : null}
      {children}
    </Layout>
  );
}

export default withRouter(NetworkAssetLayout);
