import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateRestockTransactionsMutationVariables = Types.Exact<{
  input: Types.CreateRestockTransactionsInput;
}>;


export type CreateRestockTransactionsMutation = { __typename?: 'Mutation', createRestockTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', id: string, parentTransactionId: string }> | null } };


export const CreateRestockTransactionsDocument = gql`
    mutation createRestockTransactions($input: CreateRestockTransactionsInput!) {
  createRestockTransactions(input: $input) {
    success
    transactions {
      id
      parentTransactionId
    }
  }
}
    `;

export function useCreateRestockTransactionsMutation() {
  return Urql.useMutation<CreateRestockTransactionsMutation, CreateRestockTransactionsMutationVariables>(CreateRestockTransactionsDocument);
};