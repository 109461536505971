import React from 'react';
import Shop from 'app/i18n/Shop';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import EntityManager from '../../../components/EntityManager';
import ShopProvider from '../../context/ShopProvider';
import ShopContainer from './view/ShopContainer';

const ShopContent: React.FC = () => {
  return (
    <EntityManager
      subject={AllowedPermissionsSubjectEnum.Shop}
      className="!lg:px-[24px] !xl:px-[24px] rounded-[12px] rounded-[12px] !px-[16px] pt-0">
      <EntityManager.Title documentTitle={Shop.Shops} title={Shop.Shops} />
      <ShopProvider>
        <Box data-testid="shop-page">
          <ShopContainer />
        </Box>
      </ShopProvider>
    </EntityManager>
  );
};

export default ShopContent;
