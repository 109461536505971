import { Control, Controller } from 'react-hook-form';
import Reports from 'app/i18n/Reports';
import FormCategoriesSelect from 'app/modules/categories/components/FormCategoriesSelect';

interface Props {
  control: any;
}

const ReportCategoriesSelect = (props: Props) => {
  const { control } = props;

  return (
    <Controller
      control={control}
      name="categoryIds"
      render={({ field }) => (
        <FormCategoriesSelect
          {...field}
          className="flex-1"
          label={Reports.Category}
        />
      )}
    />
  );
};

export default ReportCategoriesSelect;
