import React from 'react';
import { CardWrapper, Item } from 'app/modules/shop/utils/styles';
import { SelectedProducts, WishListDetailType } from 'app/modules/wishlist/views/createList/types';
import Box from 'app/ui-components';

import ProductCard from './ProductCard';
import { Divider } from '@mui/material';

interface Product {
  name: string;
  vendor: string;
  price: string;
  imageUrl: string;
  hooverProductId: string;
  affiliateLink: string;
}

interface Props {
  data?: Product[];
  wishListName?: string;
  wishlistId?: string;
  selectedProducts?: SelectedProducts[];
  addProductHandler: (card: SelectedProducts) => void;
  wishlistDetails?: WishListDetailType;
}

const ProductsMerchants: React.FC<Props> = (props: Props) => {
  const { data, addProductHandler, selectedProducts, wishListName, wishlistId, wishlistDetails } =
    props;

  return (
    <>
    <Divider className='mt-[24px]'/>
      <Box className={' flex flex-wrap mt-[24px]'}>
        {!!data?.length &&
          data.map((item: any, i: number) => (
            <CardWrapper key={i}>
              <Item>
                <ProductCard
                  addProductHandler={addProductHandler}
                  item={item}
                  selectedProducts={selectedProducts}
                  wishListName={wishListName}
                  wishlistDetails={wishlistDetails}
                  wishlistId={wishlistId}
                />
              </Item>
            </CardWrapper>
          ))}
      </Box>
    </>
  );
};

export default ProductsMerchants;
