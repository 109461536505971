import routes from 'app/consts/routes';
import Reports from 'app/i18n/Reports';
import { ItemLocationItemTypeEnum } from 'app/types/schema';

import InventoriesLayout from '../../../../../inventory/layout';
import NewAuditReport from '../../components/NewAuditReport';

const NewInventoryAuditReport = () => {
  return (
    <InventoriesLayout>
      <NewAuditReport
        auditReportLink={routes.AuditReport()}
        entityType={[ItemLocationItemTypeEnum.Inventory, ItemLocationItemTypeEnum.InventoryKit]}
        title={Reports.InventoryAuditReport}
      />
    </InventoriesLayout>
  );
};

export default NewInventoryAuditReport;
