import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { LabelLocationTableRowFragmentDoc } from '../../fragments/generated/LabelLocationsTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LabelLocationTableQueryVariables = Types.Exact<{
  filters: Types.LocationFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LabelLocationTableQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string, site: { __typename?: 'LocationSchema', id: string, name: string } } }> } };


export const LabelLocationTableDocument = gql`
    query LabelLocationTable($filters: LocationFilters!, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  locations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...LabelLocationTableRow
      }
    }
  }
}
    ${LabelLocationTableRowFragmentDoc}`;

export function useLabelLocationTableQuery(options: Omit<Urql.UseQueryArgs<LabelLocationTableQueryVariables>, 'query'>) {
  return Urql.useQuery<LabelLocationTableQuery>({ query: LabelLocationTableDocument, ...options });
};