import { KeyboardEvent, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Login from 'app/i18n/Login';
import { LOGIN_FORM_RULES } from 'app/modules/auth/views/Login/components/LoginForm/rules';
import { ButtonHeight } from 'app/types/button';
import Box from 'app/ui-components/Box';

import { ForgotPasswordFormProps, ForgotPasswordInput } from '../../types';

const LOGO = `${process.env.PUBLIC_URL}/images/procure-square-logo-96px.png`;

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const { disabled, onSubmit } = props;
  const { control, handleSubmit } = useForm<ForgotPasswordInput>({});

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <img alt="Logo" className="mx-auto" src={LOGO} />
      <h2 className="mt-12 text-center text-[30px] font-semibold text-grey-900">Forgot Password</h2>
      <p className="mt-5 text-center text-grey-500">
        Enter your e-mail address below to receive a password reset link.
      </p>
      <Box className="mt-[16px] space-y-32">
        <Controller
          control={control}
          name="emailId"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              error={fieldState.error}
              formLabel={Login.FormLabels.Email}
              placeholder={Login.FormLabels.Email}
            />
          )}
          rules={LOGIN_FORM_RULES.email}
        />
        <Box className="flex justify-center">
          <Button
            classes="!min-w-[100%]"
            disabled={disabled}
            height={ButtonHeight.fill}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.Send}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default ForgotPasswordForm;
