import UserSelect, { UsersSelectProps } from '../../../modules/users/components/UserSelect';
import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & UsersSelectProps;

const FormUserSelect = (props: Props) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer className={className} error={error} isRequired={isRequired} label={label}>
      <UserSelect {...selectProps} />
    </FormFieldContainer>
  );
};

export default FormUserSelect;
