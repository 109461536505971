import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BulkImportNetworkAssetsMutationVariables = Types.Exact<{
  input: Types.BulkImportNetworkAssetInput;
}>;


export type BulkImportNetworkAssetsMutation = { __typename?: 'Mutation', bulkImportNetworkAssets: { __typename?: 'MutationResponse', success: boolean } };


export const BulkImportNetworkAssetsDocument = gql`
    mutation bulkImportNetworkAssets($input: BulkImportNetworkAssetInput!) {
  bulkImportNetworkAssets(input: $input) {
    success
  }
}
    `;

export function useBulkImportNetworkAssetsMutation() {
  return Urql.useMutation<BulkImportNetworkAssetsMutation, BulkImportNetworkAssetsMutationVariables>(BulkImportNetworkAssetsDocument);
};