import Label from 'app/i18n/Label';
import EntityManager from 'app/modules/components/EntityManager';

import FormProvider from './context/FormProvider';
import LabelsFormContainer from './views/LabelsFormContainer';

const Labels = () => {
  return (
    <EntityManager layoutWrapperClass className="pt-0">
      <EntityManager.Title documentTitle={Label.LabelsAdmin} title={Label.Labels} />
      <FormProvider>
        <LabelsFormContainer />
      </FormProvider>
    </EntityManager>
  );
};

export default Labels;
