import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditWarehouseMutationVariables = Types.Exact<{
  input: Types.EditWarehouseInput;
}>;


export type EditWarehouseMutation = { __typename?: 'Mutation', editWarehouse: { __typename: 'WarehouseMutationPayload', success: boolean, reason?: string | null, locations?: Array<{ __typename?: 'LocationSchema', id: string, name: string, site: { __typename?: 'LocationSchema', id: string, name: string } }> | null } };


export const EditWarehouseDocument = gql`
    mutation editWarehouse($input: EditWarehouseInput!) {
  editWarehouse(input: $input) {
    success
    reason
    locations {
      id
      name
      site {
        id
        name
      }
    }
    __typename
  }
}
    `;

export function useEditWarehouseMutation() {
  return Urql.useMutation<EditWarehouseMutation, EditWarehouseMutationVariables>(EditWarehouseDocument);
};