import WarningProvider from 'app/components/WarningAlert';

import ReceivingLayout from '../../modules/receiving/layout';
import History from '../../modules/receiving/view/history';

function ReceivingHistory() {
  return (
    <WarningProvider>
      <ReceivingLayout>
        <History />
      </ReceivingLayout>
    </WarningProvider>
  );
}

export default ReceivingHistory;
