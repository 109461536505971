import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddRacksMutationVariables = Types.Exact<{
  input: Types.AddRacksInWarehouseInput;
}>;


export type AddRacksMutation = { __typename?: 'Mutation', addRacksInWarehouse: { __typename?: 'WarehouseMutationPayload', warehouse?: { __typename?: 'WarehouseSchema', warehouseType: Types.WarehouseTypeEnum, racks: number, shelves: number } | null, locations?: Array<{ __typename?: 'LocationSchema', id: string, name: string, type: Types.LocationTypeEnum, site: { __typename?: 'LocationSchema', id: string, name: string } }> | null } };


export const AddRacksDocument = gql`
    mutation addRacks($input: AddRacksInWarehouseInput!) {
  addRacksInWarehouse(input: $input) {
    warehouse {
      warehouseType
      racks
      shelves
    }
    locations {
      id
      name
      type
      site {
        id
        name
      }
    }
  }
}
    `;

export function useAddRacksMutation() {
  return Urql.useMutation<AddRacksMutation, AddRacksMutationVariables>(AddRacksDocument);
};