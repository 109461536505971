import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';
import React, { memo } from 'react';

type Props = {
  value: string;
  onChange: (value: any) => void;
};

const Website = memo((props: Props) => {
  const { value, onChange } = props;
  return (
    <div className="flex-1 flex-auto">
      <FormTextInput
        value={value}
        onChange={onChange}
        label={Orders.FormLabels.Website}
        placeholder={Orders.FormLabels.Website}
        rows={1}
      />
    </div>
  );
});

const UPCOrASIN = memo((props: Props) => {
  const { value, onChange } = props;
  return (
    <div className="flex-1 flex-auto">
      <FormTextInput
        value={value}
        onChange={onChange}
        label={Orders.FormLabels.UpcCode}
        placeholder={Orders.FormLabels.UpcCode}
        rows={1}
      />
    </div>
  );
});

const Description = memo((props: Props) => {
  const { value, onChange } = props;
  return (
    <div className="flex">
      <div className="flex-1 flex-auto">
        <FormTextInput
          value={value}
          onChange={onChange}
          label={Orders.FormLabels.Description}
          rows={1}
        />
      </div>
    </div>
  );
});

const OrderRequestColumnEditField: {
  Website: React.FC<Props>;
  UPCOrASIN: React.FC<Props>;
  Description: React.FC<Props>;
} = {
  Website: Website,
  UPCOrASIN: UPCOrASIN,
  Description: Description,
};

export default OrderRequestColumnEditField;
