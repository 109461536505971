import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { AssetModelDataFragmentDoc } from '../../fragments/generated/assetModelData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditAssetModelDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AssetModelItemFilters>;
}>;


export type EditAssetModelDataQuery = { __typename?: 'Query', assetModelItems: { __typename?: 'AssetModelItemConnection', edges: Array<{ __typename?: 'AssetModelItemEdge', node: { __typename?: 'AssetModelItemSchema', id: string, sku: string, title: string, mName: string, modelNumber?: string | null, description?: string | null, formattedDescription?: string | null, brand?: string | null, manufacturerId?: string | null, unitCost?: number | null, costOverride?: number | null, pickableThroughOrderRequest?: boolean | null, externalProductCodes?: Array<{ __typename?: 'ItemExternalProductCodeSchema', type: Types.ItemExternalProductCodeTypeEnum, code: string }> | null, category?: { __typename?: 'CategorySchema', id: string, status: Types.CategoryStatusEnum } | null, vendor?: { __typename?: 'CompanySchema', id: string, status: Types.CompanyStatusEnum } | null, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null, kind: Types.MediaKindEnum, resolutions: Array<{ __typename?: 'ResolutionType', type: Types.ResolutionEnum, url: string }> }> | null } }> } };


export const EditAssetModelDataDocument = gql`
    query editAssetModelData($filters: AssetModelItemFilters) {
  assetModelItems(filters: $filters) {
    edges {
      node {
        ...AssetModelData
      }
    }
  }
}
    ${AssetModelDataFragmentDoc}`;

export function useEditAssetModelDataQuery(options?: Omit<Urql.UseQueryArgs<EditAssetModelDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditAssetModelDataQuery>({ query: EditAssetModelDataDocument, ...options });
};