import './unified-header.css';
import { useCallback, useMemo } from 'react';
import ProcureLogoBlack from 'app/assets/procureLogoBlack.png';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { ModuleVisibilityConfigType, TenantFeatureTypeEnum } from 'app/types/schema';

import NotificationsMenu from './components/BellNotificationsMenu';
import HeaderNavItem from './components/HeaderNavItem/HeaderNavItem';
import HeaderNavLink from './components/HeaderNavLink';
import HelpNavLink from './components/HelpNavLink';
import MobileMenu from './components/MobileMenu';
import ProfileMenu from './components/ProfileMenu';
import TenantSwitchNav from './components/TenantSwitchNav';
import { UnifiedHeaderContextProvider } from './context';
import { getHeaderItemsAsPerPermissions } from './utils';

interface Props {
  onLinkClick?: (next: () => void) => void;
}

const UnifiedHeader = (props: Props) => {
  const { onLinkClick } = props;
  const { workspacePermissions, tenant } = useCurrentUser();

  const headerItems = useMemo(() => {
    const header = getHeaderItemsAsPerPermissions(workspacePermissions);

    const headers = header
      .map((item) => {
        const visibility =
          tenant?.moduleVisibilityConfig?.[item.id as keyof ModuleVisibilityConfigType];
        return { ...item, visible: visibility };
      })
      .filter((item) => item.visible !== TenantFeatureTypeEnum.Hide);
    return headers;
  }, [workspacePermissions, tenant]);

  const onLinkButtonClick = useCallback(
    (next: () => void) => {
      if (onLinkClick) {
        onLinkClick(next);
      } else {
        next();
      }
    },
    [onLinkClick],
  );

  return (
    <UnifiedHeaderContextProvider
      values={{
        onLinkButtonClick,
      }}>
      <div className="app-unified-header border-b-solid relative z-[199] w-[100%] border-b-[1px] border-b-[rgba(0,0,0,0.125)] shadow-[0_1px_12px_-3px_rgba(0,0,0,0.10196)]">
        <div className="app-unified-header-nav align-center flex h-[60px] w-[100%] justify-between px-[18px]">
          <div className="flex">
            <MobileMenu headerItems={headerItems} />

            <HeaderNavLink className="align-center flex w-[150px]" to="/">
              <img
                alt="Procure Networks"
                className="h-auto w-[100%] object-contain"
                src={ProcureLogoBlack}
              />
            </HeaderNavLink>

            <div className="desktop-menu ml-[30px] mb-0 hidden w-[100%] gap-[8px] text-grey-700 lg:flex lg:items-center">
              {headerItems.map((headerItem, index) => {
                return (
                  <HeaderNavItem
                    key={'app-unified-header-nav-item-' + index}
                    headerItem={headerItem}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex">
            <HelpNavLink />
            <NotificationsMenu />
            <ProfileMenu />
          </div>
        </div>
        <TenantSwitchNav />
      </div>
    </UnifiedHeaderContextProvider>
  );
};

export default UnifiedHeader;
