const ROWS_PER_PAGE_LOCAL_STORAGE_KEY = 'rowsPerPage';

export const getPageSettingsFromStorage = (defaultPageSize: number): number => {
  let rowsPerPage: any = localStorage.getItem(ROWS_PER_PAGE_LOCAL_STORAGE_KEY);
  if (rowsPerPage) rowsPerPage = parseInt(rowsPerPage);
  else rowsPerPage = defaultPageSize;

  return rowsPerPage;
};

export const resetPaginationSettings = (): void => {
  localStorage.removeItem(ROWS_PER_PAGE_LOCAL_STORAGE_KEY);
};

export const savePageSettingsToStorage = (rowsPerPage: any): void => {
  localStorage.setItem(ROWS_PER_PAGE_LOCAL_STORAGE_KEY, rowsPerPage.toString());
};
