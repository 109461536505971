import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReceiveItemsMutationVariables = Types.Exact<{
  input: Types.ReceiveItemsInput;
}>;


export type ReceiveItemsMutation = { __typename?: 'Mutation', receiveItems: { __typename?: 'ReceivablesPayload', success: boolean, reasons?: Array<string> | null, receivables: Array<{ __typename?: 'ReceivableSchema', id: string }> } };


export const ReceiveItemsDocument = gql`
    mutation receiveItems($input: ReceiveItemsInput!) {
  receiveItems(input: $input) {
    receivables {
      id
    }
    success
    reasons
  }
}
    `;

export function useReceiveItemsMutation() {
  return Urql.useMutation<ReceiveItemsMutation, ReceiveItemsMutationVariables>(ReceiveItemsDocument);
};