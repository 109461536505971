import { useMemo } from 'react';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { JobPurposeEnum, JobSubscriptionOperationEnum } from 'app/types/schema';

import { useJobStatusSubscription } from '../../graphql/subscriptions/generated/jobStatus';

interface Props {
  pause?: boolean;
}

const useImportSubscription = (props: Props) => {
  const { pause } = props;
  const { id } = useCurrentUser();

  const [{ data }] = useJobStatusSubscription({
    variables: {
      data: {
        createdById: id,
        operations: [
          JobSubscriptionOperationEnum.JobCreated,
          JobSubscriptionOperationEnum.JobUpdated,
        ],
        purposes: [JobPurposeEnum.UserImport],
      },
    },
    pause: pause,
  });

  const job = useMemo(() => {
    return data?.jobByCreator?.job;
  }, [data]);

  return {
    job,
  };
};

export default useImportSubscription;
