import { useMemo } from 'react';

import usePagination from '../../../../hooks/usePagination';
import Inventory from '../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import { useTransactionHistoryTableQuery } from './graphql/queries/generated/transactionHistoryTable';
import useTransactionHistoryState from './hook/useTransactionHistoryState';
import { getTransactionHistoryColumns } from './utils/columns';
import { Box } from '@mui/material';

interface Props {
  itemId: string;
  itemType: ItemTypeEnum;
}

const TransactionHistory = (props: Props) => {
  const { itemId, itemType } = props;

  const {
    table,
    filterState,
    setFilterState,
    transactionTypes,
    transactionFilters,
    siteOptions,
    userOptions,
  } = useTransactionHistoryState(itemId);

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useTransactionHistoryTableQuery,
    {
      filters: transactionFilters,
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'transactions',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const rows = useMemo(() => {
    return data?.transactions.edges?.map(({ node }) => node) || [];
  }, [data?.transactions.edges]);

  const columns = useMemo(() => {
    return getTransactionHistoryColumns(itemType)
  }, []);

  return (
    <div>
      <EntityManager.Title title={Inventory.TransactionHistory} />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          ignoreRelayPagination
          pagination
          actions={table.actions}
          columns={columns}
          data={rows}
          defaultSortState={{
            id: 'createdAt',
            orderBy: 'desc',
          }}
          extraProps={{
            filterState,
            setFilterState,
            transactionTypes,
            userOptions,
            siteOptions,
          }}
          loading={fetching}
          minWidth={0}
          persistKey="transaction_history_table"
          setState={table.setState}
          state={table.state}
          total={data?.transactions.totalCount || 0}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
          paginationWrapperClass="p-[24px]"
        />
      </Box>
    </div>
  );
};

export default TransactionHistory;
