import AdminLayout from 'app/components/AdminLayout';
import AddUser from 'app/modules/users/views/AddUser';

function AddUserPage() {
  return (
    <AdminLayout>
      <AddUser />
    </AdminLayout>
  );
}

export default AddUserPage;
