import { useMemo } from 'react';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import AssetModel from 'app/i18n/AssetModel';
import { Redirect } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import FindUPCProvider from 'app/modules/components/FindUPC/context/FindUPCProvider';
import { AllowedPermissionsSubjectEnum, AssetModelItemSchema } from 'app/types/schema';

import EditAssetModelContent from './component/EditAssetModelContent';
import { useEditAssetModelDataQuery } from './graphql/query/generated/editAssetModelData';

interface Props {
  id: string;
}

const EditAssetModel = (props: Props) => {
  const { id } = props;

  const [{ fetching, data }] = useEditAssetModelDataQuery({
    variables: {
      filters: {
        assetModelIds: [id],
      },
    },
  });

  const assetModelItem = useMemo(() => {
    return (data?.assetModelItems?.edges?.[0]?.node as AssetModelItemSchema) || undefined;
  }, [data?.assetModelItems?.edges]);

  if (fetching) {
    return <Loader />;
  }

  if (!assetModelItem) {
    return <Redirect to={routes.AssetModels()} />;
  }

  return (
    <FindUPCProvider>
      <EntityManager subject={AllowedPermissionsSubjectEnum.AssetModel}>
        <EntityManager.Title
          documentTitle={AssetModel.EditAssetModel}
          title={AssetModel.EditAssetModel}
        />
        <EditAssetModelContent item={assetModelItem} />
      </EntityManager>
    </FindUPCProvider>
  );
};

export default EditAssetModel;
