import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import usePagination from 'app/hooks/usePagination';
import Partner from 'app/i18n/Partner';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { AllowedPermissionsSubjectEnum, PartnerTenantTypeEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import InvitePartnerDialog from '../../../partner/components/InvitePartnerDialog';
import useInvitePartnerDialogState from '../../../partner/components/InvitePartnerDialog/hook/useInvitePartnerDialog';
import useSearchSuggestion from '../../hook/useSearchSuggestion';
import { useDeletePartnerTenantMutation } from './graphql/mutations/generated/deletePartnerTenant';
import { usePartnerTenantsTableQuery } from './graphql/queries/generated/PartnerTenantsTable';
import { getPartnerTenantTableColumns } from './utils/columns';
import { FilterState } from './types';
import analytics from 'app/analytics';

function Partners({ history }: RouteComponentProps) {
  const [{ fetching: disabled }, deletePartnerTenant] = useDeletePartnerTenantMutation();

  const [filter, _setFilter] = useState<FilterState>({
    tenantIds: [],
    userIds: [],
  });

  const setFilter = useCallback(
    (nextFilterState: Partial<FilterState>) => {
      _setFilter((prevState) => ({ ...prevState, ...nextFilterState }));
    },
    [_setFilter],
  );

  const onEdit = useCallback(
    (id: string) => {
      history.push(routes.EditPartner(id));
    },
    [history],
  );

  const columns = useMemo(() => getPartnerTenantTableColumns(), []);

  const { state: invitePartnerDialogState, setState: setInvitePartnerDialogState } =
    useInvitePartnerDialogState();

  const onDelete = useCallback((ids: string[]) => {
    deletePartnerTenant({ input: { partnerTenantIds: ids ? ids : [] } }).then((response) => {
      analytics?.track('Deleted', { name: 'Partner' });
      if (response.data?.deletePartnerTenant?.success) {
        SnackbarService.show({ message: 'This partner has been successfully deleted.' });
      }
    });
  }, []);

  const { search, table } = useMultiSelectEntityManager({ onEdit, onDelete });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    usePartnerTenantsTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        _or: [
          {
            parentTenantIds: filter.tenantIds,
          },
          {
            childTenantIds: filter.tenantIds,
          },
        ],
        allowedUserIds: filter.userIds.length ? filter.userIds : undefined,
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'partnerTenants',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
    },
  );

  const rows = useMemo(() => {
    return data?.partnerTenants.edges.map((edge) => edge.node) || [];
  }, [data]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  const disableEdit = useMemo(() => {
    return !!table.state.selectedRowData.find(
      (row) => row?.partnerType === PartnerTenantTypeEnum.Parent,
    );
  }, [table.state.selectedRowData]);

  return (
    <EntityManager className="pt-0" subject={AllowedPermissionsSubjectEnum.PartnerTenant}>
      <EntityManager.Title documentTitle={Partner.Partners} title={Partner.Partners} />
      <div className="!mt-16">
        <Button
          classes="min-w-[204px] h-[44px]"
          theme="success"
          onClick={() => {
            setInvitePartnerDialogState({
              open: true,
            });
          }}>
          {Partner.NewPartner}
        </Button>
      </div>
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          ignoreRelayPagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={rows}
          disableEdit={disableEdit}
          extraProps={{
            filter,
            setFilter,
          }}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          loading={fetching || disabled}
          pagination={true}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          persistKey="partners_table"
          setState={table.setState}
          state={table.state}
          total={data?.partnerTenants.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />

        <InvitePartnerDialog
          setState={setInvitePartnerDialogState}
          state={invitePartnerDialogState}
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(Partners);
