import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { CategoriesTableRowFragmentDoc } from '../../../../Categories/graphql/fragments/generated/CategoriesTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory: { __typename?: 'UpdateCategoryPayload', success: boolean, reason?: string | null, category?: { __typename?: 'CategorySchema', id: string, name: string, description?: string | null, categoryCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, categoryManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }> } | null } };


export const UpdateCategoryDocument = gql`
    mutation updateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    success
    reason
    category {
      ...CategoriesTableRow
    }
  }
}
    ${CategoriesTableRowFragmentDoc}`;

export function useUpdateCategoryMutation() {
  return Urql.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument);
};