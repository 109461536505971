import { Control, Controller } from 'react-hook-form';
import Reports from 'app/i18n/Reports';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import { SiteReportFormValues } from 'app/modules/reports/views/SiteReport/types';
import FormSelect from 'app/components/Form/FormSelect';
import { useMemo } from 'react';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

interface Props {
  control: Control<SiteReportFormValues, any>;
  onChange: (types: TransactionEntityTypeEnum[]) => void;
}

const getReportTypeOptions = () => {
  return [
    { value: TransactionEntityTypeEnum.Asset, label: Reports.Asset },
    // {
    //   value: TransactionEntityTypeEnum.ItAsset, label: Reports.ItAsset,
    // },
    { value: TransactionEntityTypeEnum.Inventory, label: Reports.Inventory },

  ];
};

const ReportTypeSelector = (props: Props) => {
  const { control, onChange: onChangeHandler } = props;
  const { workspacePermissions } = useCurrentUser();

  const options = useMemo(() => getReportTypeOptions(), []);

  const getOptionDisabled = (option: any) => {
    if (option.value === TransactionEntityTypeEnum.ItAsset) {
      const hasAccess = workspacePermissions.some(
        (perm) => perm.subject === AllowedPermissionsSubjectEnum.ItAsset && perm.action === AllowedPermissionActionsEnum.Access
      );
      return !hasAccess;
    }
    return false;
  };

  const handleChange = (value: string) => {
    const types = value.split('-') as TransactionEntityTypeEnum[];
    onChangeHandler(types);
  };

  return (
    <Controller
      control={control}
      name="types"
      render={({ field, fieldState }) => (
        <FormSelect
          {...field}
          getOptionDisabled={getOptionDisabled}
          disableClearable={true}
          error={fieldState.error}
          label={'Report Type'}
          options={options}
          onChange={(event) => {
            const selectedValue = event;
            field.onChange(selectedValue);
            handleChange(selectedValue as string);
          }}
          value={field.value.join('-')}
        />
      )}
    />
  );
};

export default ReportTypeSelector;