import { ReactNode, useCallback, useMemo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { get } from 'lodash';
import RadioControl from 'app/components/Radio';
import Orders from 'app/i18n/Orders';

import { UseNormalOrderRequestReturnType } from '../../../OrderItemRequest/hook/useNormalOrderRequest';
import { ORDER_INSERT_TYPE } from '../../type';
import OrderDescriptionField from '../OrderDescriptionField';
import OrderImageField from '../OrderImageField';
import OrderUpcAsinCodeField from '../OrderUpcAsinCodeField';
import OrderWebsiteField from '../OrderWebsiteField';
import FormLabel from 'app/components/ProcureForm/FormLabel';

interface Props {
  formState: UseNormalOrderRequestReturnType['normalOrderState']['formState'];
  disabled: boolean;
  isCollapsed: boolean | null;
  formLabel?: string;
}

const OrderNonStockedFormFields = (props: Props) => {
  const { formState, disabled, isCollapsed, formLabel } = props;
  const { isExternalTenant } = formState;

  const showNonStockedFormFields = useCallback(
    () => formState.actions.onInsertItemTypeChange(ORDER_INSERT_TYPE.NON_STOCKED_ITEMS),
    [formState.actions],
  );

  const handleClick = useCallback(() => {
    showNonStockedFormFields();
  }, [showNonStockedFormFields]);

  if (isExternalTenant) return null;

  return (
    <>
      {formLabel ? (
        <FormLabel className="ml-[40px] text-[14px] font-medium text-grey-800">
          {formLabel}
        </FormLabel>
      ) : null}
      <Accordion
        className="mt-6 border-0 shadow-none before:content-none"
        expanded={!isCollapsed}
        onClick={handleClick}>
        <div className="flex items-center">
          <div className="flex w-[40px]">
            <RadioControl
              checked={
                !!formState.itemType && formState.itemType === ORDER_INSERT_TYPE.NON_STOCKED_ITEMS
              }
              onChange={handleClick}
            />
          </div>
          <AccordionSummary
            className="flex !min-h-[50px] flex-auto items-center rounded-[8px]"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              border: '1.5px solid lightgrey',
              '& .Mui-expanded': {
                margin: '0px !important',
              },
              paddingLeft: '12px !important',
            }}
            onKeyDown={handleClick}>
            <Typography>{Orders.NonStockedItems}</Typography>
          </AccordionSummary>
        </div>

        {/* TODO: When OrderRequestType is NON_STOCKED_ITEM it should throw error if none of non-stocked field is entered */}
        <AccordionDetails className="mt-[24px] p-0 pl-[40px]">
          <OrderUpcAsinCodeField
            disabled={disabled}
            formState={formState}
            isExternalTenant={isExternalTenant}
          />
          <OrderWebsiteField
            disabled={disabled}
            formState={formState}
            isExternalTenant={isExternalTenant}
          />
          <OrderImageField
            disabled={disabled}
            formState={formState}
            isExternalTenant={isExternalTenant}
          />
          <OrderDescriptionField
            disabled={disabled}
            formState={formState}
            isExternalTenant={isExternalTenant}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default OrderNonStockedFormFields;
