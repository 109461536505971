import { LocationSchema } from 'app/types/schema';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  defaultValues: any
}

const useLocationUserDataForm = (props: Props) => {
  const {
    defaultValues
  } = props;

  const [site, setSite] = useState<Partial<LocationSchema> | null>(null);

  const { control, watch, reset, getValues, handleSubmit, trigger } = useForm(
    {
      defaultValues: {
        ...defaultValues,
      },
    },
  );

  useEffect(()=>{
    reset(defaultValues)
  },[defaultValues])

  const destinationSiteId = watch('destinationSiteId');
  const destinationLocationId = watch('destinationLocationId');

  return {
    formState: {
      control,
      destinationSiteId,
      destinationLocationId,
      handleSubmit,
      getValues,
      trigger,
      site,
      setSite,
      reset, 
      watch
    }
  };
};

export type UseLocationUserDataReturnType = ReturnType<typeof useLocationUserDataForm>;

export default useLocationUserDataForm;
