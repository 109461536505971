import { useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';

import Assets from '../../../../../i18n/Assets';
import Common from '../../../../../i18n/Common';
import Inventory from '../../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../../types/schema';
import FormNumberInput from '../../../../components/FormNumberInput';
import { LabelTypeEnum } from '../../../../labels/views/Labels/components/Forms/types';
import {
  FormLocationItemLocationSelect,
  FormSiteItemLocationSelect,
} from '../../../../locations/components/FormItemLocationSelect';
import FormSiteSelect from '../../../../locations/components/FormSiteSelect';
import ItemInStockSelect from '../../../components/FormItemInStock';
import { UseMoveInventoryStateReturnType } from '../../hook/useMoveState';
import { MOVE_FORM_RULES } from './utils';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  formState: UseMoveInventoryStateReturnType['formState'];
  onFormSubmit: UseMoveInventoryStateReturnType['onFormSubmit'];
  types: ItemTypeEnum[];
  availableQuantity?: number;
}

const MoveForm = (props: Props) => {
  const { formState, onFormSubmit, types } = props;

  const {
    control,
    itemInStockId,
    sourceSiteId,
    destinationSiteId,
    isSourceAndDestinationSameLocation,
    availableQuantity,
    totalQuantity,
    setState,
    setValue,
    resetField,
  } = formState;

  const formRef = useRef<HTMLDivElement>(null);

  const onSubmit = async (data: any) => {
    await onFormSubmit(data);
    ScrollToError(formState.control._formState.errors, formRef)
  };

  const quantityRules = useMemo(() => {
    return MOVE_FORM_RULES.quantity({ availableQuantity, totalQuantity });
  }, [availableQuantity, totalQuantity]);

  const destinationLocationRules = useMemo(() => {
    return MOVE_FORM_RULES.destinationLocationId(isSourceAndDestinationSameLocation);
  }, [isSourceAndDestinationSameLocation]);

  return (
    <div ref={formRef}>
      <form className="mt-20" onSubmit={onSubmit}>
        <div className="mb-20">
          <ItemInStockSelect autoFocus control={control} name="itemInStockId" types={types} />
        </div>
        <div className="mb-20">
          <FormSiteItemLocationSelect
            includeZeroQuantity
            isRequired
            control={control}
            disabled={!itemInStockId}
            itemId={itemInStockId}
            label={Assets.FormLabels.FromSite}
            name="sourceSiteId"
            rules={MOVE_FORM_RULES.sourceId}
            onValueOptionChange={(option) => {
              setState({
                sourceSite: option || null,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <FormLocationItemLocationSelect
            includeZeroQuantity
            isRequired
            control={control}
            disabled={!sourceSiteId}
            itemId={itemInStockId}
            name="sourceLocationId"
            rules={MOVE_FORM_RULES.sourceLocationId}
            label={Assets.FormLabels.FromLocation}
            siteId={sourceSiteId}
            onValueOptionChange={(option) => {
              setState({
                sourceLocation: option || null,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="destinationSiteId"
            render={({ field, fieldState }) => (
              <FormSiteSelect
                {...field}
                isRequired
                className="flex-1"
                error={fieldState.error}
                label={Inventory.FormLabels.ToSite}
                onChange={(value, option) => {
                  field.onChange(value);
                  setState({
                    destinationSite: option,
                  });
                }}
                selectClassName="mt-[6px]"
              />
            )}
            rules={MOVE_FORM_RULES.destinationId}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="destinationLocationId"
            render={({ field, fieldState }) => (
              <FormSiteSelect
                {...field}
                isRequired
                className="flex-1"
                disabled={!destinationSiteId}
                selectClassName="mt-[6px]"
                error={fieldState.error}
                label={Inventory.FormLabels.ToLocation}
                type={LabelTypeEnum.Location}
                types={[]}
                value={destinationSiteId}
                onChange={(value, option) => {
                  if (value) setValue('destinationLocationId', value, { shouldValidate: true });
                  else resetField('destinationLocationId');
                  setState({
                    destinationLocation: option,
                  });
                }}
              />
            )}
            rules={destinationLocationRules}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                error={fieldState.error}
                label={Inventory.FormLabels.Quantity}
                maximumLength={9}
              />
            )}
            rules={quantityRules}
          />
        </div>

        <div className="flex">
          <div className="flex flex-auto justify-end">
            <Button theme="success" onClick={onSubmit} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Add}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MoveForm;
