import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';

import { Product, useHooverShopProductsQuery } from './useHooverShopProducts';

const parseProductNodes = (products: Product[]): suggestionData[] => {
  return products.map((product) => {
    const { id, name } = product;
    return {
      id,
      label: name
    };
  });
};

const useUpcSearchSuggestion = () => {

  const [suggestionData, setSuggestionData] = useState<suggestionData[]>();

  const handleProductResponse = useCallback(
    (response: any) => {
      const { products } = response?.data || {}
      const { nodes } = products || {};
      setSuggestionData(parseProductNodes(nodes || []));
    },
    [setSuggestionData],
  );

  const { fetchHooverShopProducts } = useHooverShopProductsQuery();

  const debouncedGetProductsForSuggestion = useCallback(
    debounce(async (searchQuery: any) => {
      const variables = {
        ...searchQuery,
        page: 0,
        totalPage: 50,
      };
      const response = await fetchHooverShopProducts(variables);
      handleProductResponse(response);
    }, 500),
    []
  );

  const getProductsForSuggestion = (searchQuery: any) => {
    debouncedGetProductsForSuggestion(searchQuery);
  };

  return {
    suggestionData,
    getProductsForSuggestion
  };
};

export type UseUpcSearchSuggestionReturnType = ReturnType<typeof useUpcSearchSuggestion>;
export default useUpcSearchSuggestion;
