import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { AddressesDropDownFragmentDoc } from '../../fragments/generated/address';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddressListDropDownQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AddressFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type AddressListDropDownQuery = { __typename?: 'Query', addresses: { __typename?: 'AddressConnection', edges: Array<{ __typename?: 'AddressEdge', node: { __typename?: 'AddressSchema', addressName: string, id: string } }> } };


export const AddressListDropDownDocument = gql`
    query addressListDropDown($filters: AddressFilters, $sorts: [SortProps!], $limit: Float) {
  addresses(filters: $filters, sorts: $sorts, limit: $limit) {
    edges {
      node {
        ...AddressesDropDown
      }
    }
  }
}
    ${AddressesDropDownFragmentDoc}`;

export function useAddressListDropDownQuery(options?: Omit<Urql.UseQueryArgs<AddressListDropDownQueryVariables>, 'query'>) {
  return Urql.useQuery<AddressListDropDownQuery>({ query: AddressListDropDownDocument, ...options });
};