import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { ValueComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';
import Common from 'app/i18n/Common';
import TableImageView from 'app/modules/components/TableImageView';

import TableCostView from '../../../../../../components/TableCostView';
import ProjectValueNode from '../components/ProjectValueNode';
import QuantityValueNode from '../components/QuantityValueNode';
import { getAttachment } from './utils';
import _ from 'lodash';
import React, { useMemo } from 'react';
import ReadMore from 'app/modules/components/ReadMore';

export const FOOTER_TOTAL_ROW_ID = 'FOOTER_ORDER_ROW';

const FooterValueNodeWrapper = (
  props: ValueComponentProps & {
    children: React.ReactNode;
  },
) => {
  const { children, headCell, row } = props;

  const isFooterRow = useMemo(() => row.id === FOOTER_TOTAL_ROW_ID, [row.id]);

  if (isFooterRow) {
    switch (headCell.id) {
      case 'quantity':
        return <span className="font-bold">Total:</span>;
      case 'cost':
        return <TableCostView classes="font-bold" cost={row.cost} />;
      default:
        return null;
    }
  }

  return <div key={row.id + '-' + headCell.id}>{children}</div>;
};

const ImageViewValueNode = (props: ValueComponentProps) => {
  const { row, state } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <TableImageView
        attachments={getAttachment(row)}
        onImageClick={state?.extraProps?.imagePreviewActions?.onShow}
      />
    </FooterValueNodeWrapper>
  );
};

const SKUValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <span className="truncte" title={row.itemInStock?.sku || ''}>
        {row.itemInStock?.sku || ''}
      </span>
    </FooterValueNodeWrapper>
  );
};

const TitleValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <span className="truncate" title={row.itemInStock?.title || ''}>
        {row.itemInStock?.title || ''}
      </span>
    </FooterValueNodeWrapper>
  );
};

const UpcCodeValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <span className="truncate" title={row.upcCode}>
        {row.upcCode}
      </span>
    </FooterValueNodeWrapper>
  );
};
const WebsiteValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <ReadMore text={row.website} />
    </FooterValueNodeWrapper>
  );
};
const DescriptionValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <ReadMore text={row.description} />
    </FooterValueNodeWrapper>
  );
};

const ProjectNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <ProjectValueNode {...props} />
    </FooterValueNodeWrapper>
  );
};

const QuantityNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <QuantityValueNode {...props} />
    </FooterValueNodeWrapper>
  );
};

const CostValueNode = (props: ValueComponentProps) => {
  const { row } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <TableCostView cost={row.cost} />
    </FooterValueNodeWrapper>
  );
};
const ActionValueNode = (props: ValueComponentProps) => {
  const { row, state } = props;
  return (
    <FooterValueNodeWrapper {...props}>
      <Button
        theme="danger"
        onClick={() => {
          state.extraProps?.actions.onRemoveOrderItem(row.id);
        }}
        classes="min-w-[94px] h-[44px]">
        {Common.Actions.Remove}
      </Button>
    </FooterValueNodeWrapper>
  );
};

const CART_COLUMNS: DataGridHeadCell[] = [
  {
    id: 'image',
    label: 'Image',
    value: 'imageURL',
    valueNode: ImageViewValueNode,
    width: 100,
  },
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
    valueNode: SKUValueNode,
    width: 130,
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    valueNode: TitleValueNode,
  },
  {
    id: 'upcCode',
    label: 'UPC Code',
    value: 'upcCode',
    valueNode: UpcCodeValueNode,
    width: 180,
  },
  {
    id: 'website',
    label: 'Website',
    value: 'website',
    valueNode: WebsiteValueNode,
    width: 180,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    valueNode: DescriptionValueNode,
    width: 180,
  },
  {
    id: 'projectId',
    label: 'Project',
    value: 'projectId',
    valueNode: ProjectNode,
    width: 200,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    valueNode: QuantityNode,
    width: 150,
  },
  {
    id: 'cost',
    label: 'Cost each',
    value: 'cost',
    valueNode: CostValueNode,
    width: 150,
    rowAlign: 'right',
  },
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    valueNode: ActionValueNode,
  },
];

export const notAllowedColumnForExternalTenant = ['upcCode', 'website', 'description'];

export const getOrderRequestedItemsColumns = (filters: {
  isExternalTenant: boolean;
  isProjectFieldEnabled: boolean;
}): DataGridHeadCell[] => {
  const { isExternalTenant, isProjectFieldEnabled } = filters;

  return _.filter(CART_COLUMNS, (column) => {
    if (isExternalTenant) {
      return !notAllowedColumnForExternalTenant.includes(column.id);
    }

    switch (column.id) {
      case 'projectId':
        return isProjectFieldEnabled;
      default:
        return true;
    }
  });
};
