import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Button } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components';

interface Props {
  active: number | null;
  onChange: (event: number) => void;
}

interface ButtonItemProps {
  rightColor?: string;
  leftColor?: string;
  text: string;
  active: boolean;
  showLeftArrow?: boolean;
  onClick?: () => void;
}

const ButtonItem = ({
  rightColor,
  leftColor,
  text,
  active,
  showLeftArrow,
  onClick,
}: ButtonItemProps) => {
  return (
    <Button
      classes={`!py-[10px] !h-[inherit] !w-[100%] !px-[24px] hover:!bg-[#66ccff] ${
        active ? '!bg-[#66ccff]' : '!bg-white'
      } !rounded-none`}
      onClick={onClick}>
      <Box>
        <Box className="flex justify-center">
          <DescriptionOutlinedIcon
            className={`h-[1.875rem] w-[1.875rem] ${leftColor ?? 'text-[#000]'}`}
          />
          {showLeftArrow && <ArrowBackIcon className="icon-left" />}
          <DescriptionOutlinedIcon
            className={`h-[1.875rem] w-[1.875rem] ${rightColor ?? 'text-[#000]'}`}
          />
        </Box>
        <h4 className={`mt-[8px] text-${active ? 'white' : 'black'}`}>{text}</h4>
      </Box>
    </Button>
  );
};

const ToolBarDuplicate: React.FC<Props> = ({ active, onChange }) => {
  return (
    <Box className={`border-['#ddd'] mt-[24px] mr-8 mb-[24px] flex w-[50%] min-w-fit border`}>
      <ButtonItem
        active={active === 1}
        rightColor="text-[#999]"
        text="Keep Old"
        onClick={() => onChange(1)}
      />
      <ButtonItem showLeftArrow active={active === 2} text="Update" onClick={() => onChange(2)} />
    </Box>
  );
};

export default ToolBarDuplicate;
