import AdminLayout from 'app/components/AdminLayout';
import Profile from 'app/modules/profile/views/Profile';

function ProfilePage() {
  return (
    <AdminLayout>
      <Profile />
    </AdminLayout>
  );
}

export default ProfilePage;
