import React, { useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import FormSelectInput from '../../../../components/ProcureForm/FormSelectInput';
import { SelectFilterLabelNodeProps } from './types';

type Prop = SelectFilterLabelNodeProps & { error?: Partial<FieldError> };
const SelectFilterLabelNode = (props: Prop) => {
  const {
    items,
    filterKey,
    optionValueKey,
    optionLabelKey,
    filterState,
    setFilterState,
    label,
    optionPrefix,
    wrapperClass,
    controllerHeight,
    onScroll,
    paginated,
    disableClearable,
    inputControlPadding,
    error,
  } = props;

  const final = useMemo(() => items.find((ele) => ele.id === filterState[filterKey]), [items, filterState, filterKey]);

  return (
    <div className={`table-filter ${wrapperClass}`}>
      <FormSelectInput
        className={`${wrapperClass ? '!mt-[0px]' : 'pt-16'}`}
        controllerHeight={controllerHeight}
        disableClearable={final?.id === '-1' || !final?.id ? true : disableClearable}
        inputControlPadding={inputControlPadding}
        items={items}
        label={label || ''}
        labelText={optionLabelKey}
        optionPrefix={optionPrefix}
        paginated={paginated}
        size="small"
        value={final?.id || undefined}
        valueText={optionValueKey}
        onChange={(value) => {
          setFilterState({
            [filterKey]: value || '-1',
          });
        }}
        onScroll={onScroll}
        error={error}
      />
    </div>
  );
};

export default SelectFilterLabelNode;
