import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { LocationTableRowFragmentDoc } from '../../../../Locations/graphql/fragments/generated/LocationTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateLocationMutationVariables = Types.Exact<{
  input: Types.UpdateLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'UpdateLocationPayload', success: boolean, reason?: string | null, location?: { __typename?: 'LocationSchema', id: string, name: string, updatedAt: string, description?: string | null, locationCode?: string | null, site: { __typename?: 'LocationSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } | null } };


export const UpdateLocationDocument = gql`
    mutation updateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    success
    reason
    location {
      ...LocationTableRow
    }
  }
}
    ${LocationTableRowFragmentDoc}`;

export function useUpdateLocationMutation() {
  return Urql.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument);
};