const Wishlist = {
  FormActions: {
    ReferOther: 'Refer Other',
    CreateList: 'Create List',
    SelectOrganization: 'Select A Non-Profit',
    ViewList: 'View My List',
    DeleteAccount: 'Delete My Account',
    UpdateWishList: 'Update My List',
    Add: 'Add Items',
    NewWishlist: 'New Wishlist',
  },
  SuccessMessages: {
    WishlistDeleted: 'This wishlist has been successfully deleted.',
    WishlistCreatedSuccessfully:
      'This Wishlist has been successfully created. Now you can add products to it.',
    WishlistUpdatedSuccessfully: 'This Wishlist has been successfully updated.',
  },
  ErrorMessages: {
    WishlistDeleteError: 'Something went wrong while deleting wishlists.',
    InvalidInvitationToken: 'Invalid Token',
    WishlistNotFound:
      'You have been removed from the wishlist collaboration, or the wishlist has been deleted.',
    WishlistSavingError: 'Something went wrong while saving wishlist',
  },
  FormLabels: {
    FirstName: 'First Name',
    LastName: 'Last Name',
    WishlistName: 'Wishlist Name',
    Collaborator: 'Collaborators',
    ShippingAddress: 'Shipping Address',
    City: 'City',
    State: 'State',
    ZipCode: 'ZipCode',
    Supporting: 'You are Supporting',
    CustomUrl: 'Custom URL (e.g: My-Charity-Wishlist)',
    Message: 'Add a message of support for the organization you selected.',
    MessagePlaceholder: 'It’s my message for organization',
    Date: 'Date (required)',
    CollaboratorCheckbox:
      "I'd like to invite a partner to collaborate on this registry with me. (optional)",
  },
  ConfimationView: {
    ThankYou: 'Thank you.',
    SupportMatters: 'Your support matters.',
    Paragraph: {
      Supporting: `You’re now supporting`,
      WebsiteSupoortEfforts: `When you go to a website that supports this effort,`,
      Reminder: `you’ll receive a friendly reminder in the upper right corner of your screen.`,
      WhatNext: `What would you like to do next?`,
    },
  },
  CreateList: {
    CraeteList: 'Create a Wishlist',
    ToShare: 'to share',
    CreateListMessage: 'Why would you like your donors to support this charitable cause?',
  },
  ListView: {
    WishlistSearch: 'Wishlist Search',
  },
  Settings: {
    Review: 'Review your',
    Settings: 'Settings',
    ChangeOrganization: 'Change Non-Profit',
    ViewList: 'View My Lists',
    DeleteAccount: 'Delete Account',
    DeleteDescription: 'Before you delete your account, what can we do to encourage you to stay?',
    DeleteNote: 'Please send us a note.',
  },
  CopyMessage: 'copied to clipboard.',
  LandingPage: {
    HelpOthers: 'Help others',
    WithOneClick: 'with just one click',
    SupportParagraphs: {
      SupportNonProfit: `Support a non-profit when you shop online by using the Procure Shopping Assistant.`,
      ProcureDonates: `Procure donates a percentage of the price of your eligible purchases to the non-profit of your choice.`,
      NoExtraCost: 'There is no extra cost to you',
      MerchantProvidesDonation: ` as the donation is provided by the merchant once you make a purchase.`,
      SignUp: ` and choose a non-profit organization to support.`,
    },
  },
  ReferOther: {
    InviteOthers: 'Invite others',
    SupportCharity: 'to support a charity.',
    Share: 'Share my link...',
    SupportParagraphs: {
      EncourageSupport: `Encourage your friends and family to support a charity of their choice. `,
      ProcureDonates: `Procure donates a percentage of the price of eligible purchases to the non-profit of their choice.`,
      NoExtraCost: 'There is no extra cost to you',
      MerchantProvidesDonation: ` as the donation is provided by the merchant once they make a purchase.`,
      CopyLink: `Just copy and share the link below to get them started.`,
    },
  },
  FormValidations: {
    FirstName: 'Please enter a First Name up to 30 characters.',
    LastName: 'Please enter a Last Name up to 30 characters.',
    WishlistRequired: 'Please enter a Wishlist Name',
    CustomUrlRequired: 'Please enter a custom URL',
    ShippingRequired: 'Please enter a valid shipping address',
    CityRequired: 'Please enter a valid city',
    StateRequired: 'Please enter a valid state',
    ZipCodeRequired: 'Please enter a valid zip code',
    WishlistNameInvalid: `Wishlist name can not have a single or double quotation marks(', ")`,
    CustomUrlDuplicate: 'Wishlist with this URL already exists. Please enter a different URL.',
    CustomUrlInvalid: 'Please enter a valid URL containing only letters, numbers, _ and -',
    EventDateRequired: 'Please enter a valid event date',
  },
};
export default Wishlist;
