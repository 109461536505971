import { OrderRequestItemStatusEnum } from '../../../../types/schema';
import { useMemo } from 'react';
import { getLeastItemStatusLabel } from '../../views/OrderHistory/components/OrderStatusTableFilter/utils';

interface Props {
  status?: OrderRequestItemStatusEnum;
}

const getColorByOrderStatus = (status?: OrderRequestItemStatusEnum) => {
  if (!status) {
    return '';
  }

  if (status === OrderRequestItemStatusEnum.Open) {
    return '#66cc33';
  }
  if ([OrderRequestItemStatusEnum.Closed, OrderRequestItemStatusEnum.Cancelled].includes(status)) {
    return '#ff6666';
  }
  return '';
};

const TableLeastItemStatusView = (props: Props) => {
  const { status } = props;

  const statusLabel = useMemo(() => status && getLeastItemStatusLabel(status), [status]);

  if (!statusLabel) {
    return null;
  }

  return (
    <span
      className="truncate"
      title={statusLabel}
      style={{
        color: getColorByOrderStatus(status),
        textTransform: 'capitalize',
      }}>
      {statusLabel}
    </span>
  );
};

export default TableLeastItemStatusView;
