import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { ManufacturerSchema } from 'app/types/schema';

import { useManufacturersTableQuery } from '../../views/Manufacturers/graphql/queries/generated/manufacturersTable';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useManufacturersTableQuery({
    variables: {
      filters: {
        search: searchedValue,
      },
    },
  });

  useEffect(() => {
    const result = data?.manufacturers?.edges?.map((a) => {
      const item = a.node as ManufacturerSchema
      return {
        label: item.name,
        id: item.id,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
