import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@procurenetworks/procure-component-library';
import { useAccessControl } from 'app/components/AccessControl';
import Network from 'app/i18n/Network';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { requestAgentUrl } from 'app/modules/network/utils/requestAgentUrl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

export const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

type CardAgentType = {
  color?: string;
  icon: string;
  title: string;
  subTitle: string;
  type: string;
  link: string;
  sx?: any;
};

export const AgentCard = (props: CardAgentType) => {
  const { title, type, link, subTitle, icon, sx, ...other } = props;
  const { workspacePermissions } = useCurrentUser();

  const canDownload = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Download,
    AllowedPermissionsSubjectEnum.ItAssetAgent,
  );
  return (
    <Card
      className="
      mt-[0px] 
      box-border 
      min-h-[216px]
      w-full
      rounded-[12px]
      border-[1px]
      !p-[24px]
      shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]
      "
      sx={{
        mt: 5,
        p: 5,
        textAlign: 'center',
        color: 'gray',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        ...sx,
      }}
      {...other}>
      <Typography className="text-[18px] font-semibold text-grey-900">{title}</Typography>
      <Typography className="text-grey800 mt-[4px] text-[14px]">{' (' + type + ') '}</Typography>
      <Box className="m-auto max-w-[184px]">
        <Typography className="mt-[16px] text-[14px] text-grey-800">{subTitle}</Typography>
      </Box>
      <Box className="mt-[16px]">
        {canDownload ? (
          <Button theme="info" onClick={() => requestAgentUrl(link)}>
            {Network.Action.Download}
          </Button>
        ) : (
          <Typography variant="subtitle1">{Network.Labels.DownloadAgent}</Typography>
        )}
      </Box>
    </Card>
  );
};
