import FormFieldContainer, {
  FormFieldContainerProps,
} from 'app/components/Form/FormFieldContainer';

import UserSelect, { UsersSelectProps } from '../../../modules/users/components/UserSelect';
import { forwardRef } from 'react';

type Props = Omit<FormFieldContainerProps, 'children'> & UsersSelectProps;

const FormUserSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, label, isRequired, error, size, ...selectProps } = props;

  return (
    <FormFieldContainer className={className} error={error} ref={ref}>
      <UserSelect
        {...selectProps}
        isRequired={isRequired}
        label={label}
        size={size}
        error={error}
      />
    </FormFieldContainer>
  );
});

export default FormUserSelect;
