import { cx } from '@emotion/css';
import React from 'react';

interface Props {
  isRequired?: boolean;
  children?: React.ReactChild;
  className?: string;
}

const FormLabel = (props: Props) => {
  const { children, isRequired, className } = props;
  const requiredElement = isRequired ? <span className="text-red-600">*</span> : undefined;
  return (
    <span className={cx(className, 'font-semibold')}>
      {children} {requiredElement}
    </span>
  );
};
export default FormLabel;
