import { useCallback, useState } from 'react';

interface ImageState {
  imageUrl: string;
  open: boolean;
}

const useImageViewDialog = () => {
  const [state, _setState] = useState<ImageState>({
    open: false,
    imageUrl: '',
  });

  const setState = useCallback((nextState: Partial<ImageState>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const onClose = useCallback(() => {
    setState({
      open: false,
    });
  }, [setState]);

  const onShow = useCallback(
    (imageUrl: string) => {
      setState({
        imageUrl: imageUrl,
        open: true,
      });
    },
    [setState],
  );

  return {
    state,
    actions: {
      onShow,
      onClose,
    },
  };
};

export type UseImageViewDialogReturnType = ReturnType<typeof useImageViewDialog>;

export default useImageViewDialog;
