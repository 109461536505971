import { ValueComponentProps } from '@procurenetworks/procure-component-library/build/components/ui/DataGrid/DataGrid.types';
import QuantityInput from 'app/modules/components/QuantityInput';

export const QuantityValueNode = (props: ValueComponentProps) => {
  const { state, row } = props;
  const onChange = (value: any) => {
    state.extraProps?.onUpdateOrderItem(row.id, { quantity: value });
  };
  return (
    <QuantityInput
      key={`order-quantity-${row.id}`}
      className="order-items-quantity-input max-w-[100px]"
      minimumValue={0}
      maximumLength={7}
      size="small"
      value={row.quantity}
      onChange={onChange}
    />
  );
};

export default QuantityValueNode;
