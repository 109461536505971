import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdatePartnerTenantMutationVariables = Types.Exact<{
  inputs: Array<Types.UpdatePartnerTenantInput> | Types.UpdatePartnerTenantInput;
}>;


export type UpdatePartnerTenantMutation = { __typename?: 'Mutation', updatePartnerTenants: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const UpdatePartnerTenantDocument = gql`
    mutation updatePartnerTenant($inputs: [UpdatePartnerTenantInput!]!) {
  updatePartnerTenants(inputs: $inputs) {
    success
    reason
  }
}
    `;

export function useUpdatePartnerTenantMutation() {
  return Urql.useMutation<UpdatePartnerTenantMutation, UpdatePartnerTenantMutationVariables>(UpdatePartnerTenantDocument);
};