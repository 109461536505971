import { useCallback, useState } from 'react';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';
import { SnackbarService } from 'app/components/Snackbar';
import Receivables from 'app/i18n/Receivables';

import useReceiveForm from '../useReceiveForm';

function useScheduleShipmentState(props: any) {
  const { step1Data } = props;
  const { formState, setFormState, onResetForm } = useReceiveForm();

  const { table, search } = useEntityManager({
    selection: false,
  });
  const [rows, setRows] = useState<[]>(step1Data || []);

  const onAdd = useCallback(
    (values: any) => {
      if (rows.find((row: any) => row.orderRequestId === values.orderRequestId)) {
        SnackbarService.showWarning({ message: Receivables.FormValidationMessages.OrderAlreadyInList });
        return;
      }
      const updatedValues = {
        ...values,
        notes: removeExtraSpacesAndNewlines(values.notes),
        formattedDescription: values.notes,
      };
      // @ts-ignore
      setRows([...rows, { ...updatedValues, ...formState.state, id: +new Date() }]);
      onResetForm();
    },
    [rows, setRows, formState.state],
  );

  const resetRows = useCallback(() => {
    setRows([]);
  }, []);

  const onRemoveRow = useCallback((rowId: string) => {
    const updatedRows: any = rows.filter((row: any) => row.id !== rowId);
    setRows(updatedRows);
  }, [rows, setRows]);

  return {
    formState,
    setFormState,
    onResetForm,
    onAdd,
    table,
    rows,
    resetRows,
    search,
    onRemoveRow
  };
}

export default useScheduleShipmentState;
