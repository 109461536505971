const Partner = {
  Partners: 'Partners',
  NewPartner: 'New Partner',
  EditPartner: 'Edit Partner',
  FormLabels: {
    PartnerName: 'Partner Name',
    Organization: 'Organization',
    Categories: 'Categories',
    Users: 'Users',
  },
  FormValidations: {
    Name: 'Please enter a Partner Name',
    CategoriesLength: 'Please select at least one Category.',
    UsersLength: 'Please select at least one User.',
  },
  SuccessMessages: {
    TenantInvited: 'Invitation sent successfully.',
    InvitationAccepted: 'Invitation accepted successfully.',
  },
  ErrorMessages: {
    InvalidInvitationToken:
      'This email link has expired. Please contact the organization that made the invitation and request another invitation to join Procure.',
  },
};

export default Partner;
