import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';

import Modal from '../../../../components/Modal';
import Common from '../../../../i18n/Common';
import Undelete from '../../../../i18n/Undelete';
import {
  getUnDeleteSuccessMessageByCount,
  getUnDeleteUnSuccessFullyDeletedMessageByCount,
} from '../UndeleteItemsTable/utils';
import { UseDeleteErrorDialogStateReturnType } from './hook/useUnDeleteErrorDialog';

interface Props {
  state: UseDeleteErrorDialogStateReturnType['state'];
  setState: UseDeleteErrorDialogStateReturnType['setState'];
}

const UnDeleteErrorDialog = (props: Props) => {
  const { state, setState } = props;

  const { data, open } = state;
  const { successCount, errorCount } = data || {};

  const onClose = useCallback(() => {
    setState({
      open: false,
      data: null,
    });
  }, [setState]);

  return (
    <Modal
      actions={
        <div className="flex">
          <div className="ml-[4px] flex">
            <Button onClick={onClose}>{Common.Actions.Close}</Button>
          </div>
        </div>
      }
      id="import-validation-alert"
      open={open}
      title={Undelete.FormLabels.UndeleteStatus}
      onClose={onClose}>
      <div className="w-[350px]">
        <div>{getUnDeleteSuccessMessageByCount(successCount || 0)}</div>
        <div>{getUnDeleteUnSuccessFullyDeletedMessageByCount(errorCount || 0)}</div>
      </div>
    </Modal>
  );
};

export default UnDeleteErrorDialog;
