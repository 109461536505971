import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import moment from 'moment';

import { TransactionEntityTypeEnum, TransactionTypeEnum } from '../../../../../../types/schema';
import { DateFormatEnum, formatDate } from '../../../../../../utils/date';
import { getLocationName } from '../../../../../locations/utils/location';
import ReadMore from 'app/modules/components/ReadMore';
import { Box } from '@mui/material';
import FormTextArea from 'app/components/Form/FormTextArea';

export const getRestockTransactionColumns = (
  itemType: TransactionEntityTypeEnum,
): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
  },
  {
    id: 'entity.title',
    label: 'Title',
    value: 'entity.title',
    valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
  },
  {
    id: 'sourceSite.name',
    label: 'From Site',
    value: 'sourceSite.name',
    valueNode: () => {
      const assetItemName = itemType === TransactionEntityTypeEnum.Asset ? 'Asset' : undefined;
      const inventoryItemName =
        itemType === TransactionEntityTypeEnum.Inventory ? 'Inventory' : undefined;

      return <span>New {assetItemName || inventoryItemName}</span>;
    },
  },
  {
    id: 'fromLocation',
    label: 'From Location',
    value: 'fromLocation',
    valueNode: () => {
      return <span>Unassigned</span>;
    },
  },
  {
    id: 'destinationSite.name',
    label: 'To Site',
    value: 'destinationSite.name',
    valueNode: ({ row }) => <span>{row?.destinationSite?.name || ''}</span>,
  },
  {
    id: 'destinationLocation.name',
    label: 'To Location',
    value: 'destinationLocation.name',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.destinationSite?.id, row?.destinationLocation)}</span>;
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
];

export const getRemoveTransactionColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
  },
  {
    id: 'entity.title',
    label: 'Title',
    value: 'entity.title',
    valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
  },
  {
    id: 'sourceSite.name',
    label: 'From Site',
    value: 'sourceSite.name',
    valueNode: ({ row }) => <span>{row?.sourceSite?.name || ''}</span>,
  },
  {
    id: 'fromLocation',
    label: 'From Location',
    value: 'fromLocation',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.sourceSite?.id, row?.sourceLocation)}</span>;
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'reason',
    label: 'Note',
    value: 'reason',
    valueNode: ({ row }) => {
      return <span>{row?.meta?.reason || ''}</span>;
    },
  },
];

export const getMoveTransactionColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
  },
  {
    id: 'entity.title',
    label: 'Title',
    value: 'entity.title',
    valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
  },
  {
    id: 'sourceSite.name',
    label: 'From Site',
    value: 'sourceSite.name',
    valueNode: ({ row }) => <span>{row?.sourceSite?.name || ''}</span>,
  },
  {
    id: 'fromLocation',
    label: 'From Location',
    value: 'fromLocation',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.sourceSite?.id, row?.sourceLocation)}</span>;
    },
  },
  {
    id: 'destinationSite.name',
    label: 'To Site',
    value: 'destinationSite.name',
    valueNode: ({ row }) => <span>{row?.destinationSite?.name || ''}</span>,
  },
  {
    id: 'destinationLocation.name',
    label: 'To Location',
    value: 'destinationLocation.name',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.destinationSite?.id, row?.destinationLocation)}</span>;
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
];

export const getAuditTransactionColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
  },
  {
    id: 'entity.title',
    label: 'Title',
    value: 'entity.title',
    valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
  },
  {
    id: 'sourceSite.name',
    label: 'From Site',
    value: 'sourceSite.name',
    valueNode: ({ row }) => <span>{row?.sourceSite?.name || ''}</span>,
  },
  {
    id: 'fromLocation',
    label: 'From Location',
    value: 'fromLocation',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.sourceSite?.id, row?.sourceLocation)}</span>;
    },
  },
  {
    id: 'destinationSite.name',
    label: 'To Site',
    value: 'destinationSite.name',
    valueNode: ({ row }) => <span>{row?.destinationSite?.name || ''}</span>,
  },
  {
    id: 'destinationLocation.name',
    label: 'To Location',
    value: 'destinationLocation.name',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.destinationSite?.id, row?.destinationLocation)}</span>;
    },
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'note',
    label: 'Note',
    value: 'note',
    valueNode: ({ row }) => {
      return <span>{row?.meta?.note || ''}</span>;
    },
  },
];

export const getMoveCheckOutTransactionColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
  },
  {
    id: 'entity.title',
    label: 'Title',
    value: 'entity.title',
    valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
  },
  {
    id: 'sourceSite.name',
    label: 'From Site',
    value: 'sourceSite.name',
    valueNode: ({ row }) => <span>{row?.sourceSite?.name || ''}</span>,
  },
  {
    id: 'fromLocation',
    label: 'From Location',
    value: 'fromLocation',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.sourceSite?.id, row?.sourceLocation)}</span>;
    },
  },
  {
    id: 'destinationSite.name',
    label: 'To Site',
    value: 'destinationSite.name',
    valueNode: ({ row }) => <span>{row?.destinationSite?.name || ''}</span>,
  },
  {
    id: 'destinationLocation.name',
    label: 'To Location',
    value: 'destinationLocation.name',
    valueNode: ({ row }) => {
      return <span>{getLocationName(row?.destinationSite?.id, row?.destinationLocation)}</span>;
    },
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    value: 'dueDate',
    valueNode: ({ row }) => (
      <span>{row?.dueDate ? formatDate(row.dueDate, DateFormatEnum.Date) : ''}</span>
    ),
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'note',
    label: 'Note',
    value: 'note',
    valueNode: ({ row }) => {
      return <span>{row?.meta?.note || ''}</span>;
    },
  },
];

export const getReserveTransactionColumn = (): DataGridHeadCell[] => {
  return [
    {
      id: 'entity.sku',
      label: 'SKU',
      value: 'entity.sku',
      valueNode: ({ row }) => <span>{row?.entity?.sku || ''}</span>,
    },
    {
      id: 'entity.title',
      label: 'Title',
      value: 'entity.title',
      valueNode: ({ row }) => <span>{row?.entity?.title || ''}</span>,
    },
    {
      id: 'destinationSite.name',
      label: 'To Site',
      value: 'destinationSite.name',
      valueNode: ({ row }: any) => <span>{row?.destinationSite?.name || ''}</span>,
    },
    {
      id: 'department',
      label: 'To Department',
      value: 'department.name',
      valueNode: ({ row }: any) => <span>{row?.department?.name || ''}</span>,
    },
    {
      id: 'actor.name',
      label: 'To User',
      value: 'actor.name',
      valueNode: ({ row }: any) => <span>{row?.actor?.name || ''}</span>,
    },
    {
      id: 'startDate',
      label: 'Reserve Start',
      value: 'startDate',
      valueNode: ({ row }) => {
        return <span>{row.startDate ? moment(row.startDate).format('L') : ''}</span>;
      },
    },
    {
      id: 'endDate',
      label: 'Reserve End',
      value: 'endDate',
      valueNode: ({ row }) => {
        return <span>{row.endDate ? moment(row.endDate).format('L') : ''}</span>;
      },
    },
    {
      id: 'meta.formattedNote',
      label: 'Note',
      value: 'meta.formattedNote',
      valueNode: ({ row }) => {
        return <span>{row?.meta?.note || ''}</span>;
      },
    },
    {
      id: 'quantity',
      label: 'Quantity',
      value: 'quantity',
      rowAlign: 'right',
    },
  ];
};
