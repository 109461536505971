import { APP_CONFIG } from 'app/consts/config';
import { ConfigCatProvider } from 'app/libs/featureFlag/configCat';

interface Props {
  children: React.ReactNode;
}
export const FeatureFlagProvider = (props: Props) => {
  const { children } = props;

  if (!APP_CONFIG.configCatKey) {
    return null;
  }

  return <ConfigCatProvider sdkKey={APP_CONFIG.configCatKey}>{children}</ConfigCatProvider>;
};
