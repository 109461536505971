import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import AsyncMultiTableFilter from 'app/components/AsyncMultiTableFilter';

import { ReceivingStatuses } from '../../utils/receivingStatuses';

export interface UseSelectedValueProps {
  value: string[];
  pause?: boolean;
  options?: any[];
}

export interface StatusTableFilterProps {
  name: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: undefined;
}

export interface UseSelectedValueProps {
  value: string[];
  options?: any[];
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const values = useMemo(() => ReceivingStatuses.filter((item) => value.includes(item.value)), []);

  return { isDisabled: false, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const options = useMemo(() => ReceivingStatuses, []);
  return { isLoading: false, options };
};

const OrderStatusTableFilter = (props: StatusTableFilterProps) => {
  return (
    <AsyncMultiTableFilter
      {...props}
      size="small"
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
      onBlur={(event) => event.stopPropagation()}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    />
  );
};

export default OrderStatusTableFilter;
