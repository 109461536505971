import { useCallback, useContext, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import _ from 'lodash';
import { WarningService } from 'app/components/WarningAlert';
import Orders from 'app/i18n/Orders';

import { BulkOrderInput } from '../../../BulkOrderRequest/types';
import { getDefaultFormValues, getDefaultOrderItem } from '../../../BulkOrderRequest/utils/utils';
import { OrderRequestInput } from '../../../OrderRequestForm/type';
import useCurrentUser from '../../../../../../../auth/hooks/useCurrentUser';
import { OrderRequestContext } from '../../../../../../provider/OrderRequestContextProvider/OrderRequestContextProvider';
import { ORDER_REQUEST_TYPE } from '../../type';
import { DuplicateOrderItem } from '../../../../type';
import { getDuplicateOrderItemIndexes, getQuantityTotal } from '../../../../utils/utils';
import { cloneOrderItems, findIndexesByIds, removeOrderItems, updateItem } from '../../utils/utils';

export const FIELD_ARRAY_KEY = 'orderItems';

const useBulkOrderRequest = () => {
  const { getOrderItemsFromStore, getPartnerTenantId, storeDataInStorage } =
    useContext(OrderRequestContext);
  const { tenantId } = useCurrentUser();
  const { control, handleSubmit, setValue, watch, reset, trigger, getValues } =
    useForm<BulkOrderInput>({
      defaultValues: {
        orderItems: getOrderItemsFromStore(ORDER_REQUEST_TYPE.BULK_ORDER) || [
          getDefaultOrderItem(),
        ],
        partnerTenantId: getPartnerTenantId(ORDER_REQUEST_TYPE.BULK_ORDER) || '',
      },
    });

  const { fields, append, remove } = useFieldArray({
    name: FIELD_ARRAY_KEY,
    control,
  });

  const updateStateToStorage = useCallback(() => {
    const data = getValues();
    storeDataInStorage({
      orderItems: data.orderItems,
      partnerTenantId: data.partnerTenantId,
      totalOrderItems: data.orderItems.length - 1,
      orderRequestType: ORDER_REQUEST_TYPE.BULK_ORDER,
    });
  }, [getValues, storeDataInStorage]);

  const { orderItems, partnerTenantId } = watch();

  const onAddItem = useCallback(() => {
    append(getDefaultOrderItem());
    updateStateToStorage();
  }, [append, updateStateToStorage]);

  const onRemoveOrderItems = useCallback(
    (index: number[]) => {
      remove(index);
      updateStateToStorage();
    },
    [remove, updateStateToStorage],
  );

  const onUpdateOrderItem = useCallback(
    (index: number, nextState: Partial<OrderRequestInput>) => {
      _.keys(nextState).forEach((nextStateKey) => {
        const fieldKey: any = `${FIELD_ARRAY_KEY}.${index}.${nextStateKey}`;
        setValue(fieldKey, (nextState as any)[nextStateKey]);
      });
      updateStateToStorage();
    },
    [setValue, updateStateToStorage],
  );

  const onUpdateDuplicateOrderItem = useCallback(
    (data: { update: Partial<OrderRequestInput>; delete: string[] }) => {
      let clonedOrderItems = cloneOrderItems(orderItems);

      // Update Existing Order Item
      clonedOrderItems = updateItem(clonedOrderItems, data.update.id as string, data.update);

      // set updated OrderItems and Remove Duplicate Item
      setValue('orderItems', clonedOrderItems);
      onRemoveOrderItems(findIndexesByIds(orderItems, data.delete));
      updateStateToStorage();
    },
    [orderItems, onUpdateOrderItem, onRemoveOrderItems, setValue, updateStateToStorage],
  );

  const onCancelDuplicateOrderItem = useCallback(
    (duplicateOrderItems: DuplicateOrderItem[]) => {
      const itemIndexes = getDuplicateOrderItemIndexes(duplicateOrderItems, orderItems);
      onRemoveOrderItems(itemIndexes);
    },
    [onRemoveOrderItems, orderItems],
  );

  const clearOrderItems = useCallback(() => {
    remove();
    onAddItem();
  }, [remove, onAddItem]);

  const onResetForm = useCallback(() => {
    reset(getDefaultFormValues());
  }, [reset]);

  const onPartnerTenantChange = useCallback(
    (id: string) => {
      if (orderItems.length > 1 && partnerTenantId !== id) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.switchOrganizationWarning,
          onConfirm: () => {
            setValue('partnerTenantId', id, { shouldValidate: true });
            clearOrderItems();
          },
        });
      } else {
        setValue('partnerTenantId', id, { shouldValidate: true });
      }
    },
    [clearOrderItems, orderItems.length, partnerTenantId, setValue],
  );

  const getFormValues = useCallback(() => getValues(), [getValues]);

  const isExternalTenant = useMemo(
    () => !!(partnerTenantId && tenantId !== partnerTenantId),
    [partnerTenantId, tenantId],
  );

  return {
    bulkOrderState: {
      orderItems,
      fields,
      control,
      setValue,
      trigger,
      getFormValues,
      isExternalTenant,
      partnerTenantId,
      tenantId,
    },
    bulkOrderActions: {
      handleSubmit,
      onAddItem,
      onRemoveOrderItems,
      onUpdateOrderItem,
      onUpdateDuplicateOrderItem,
      onCancelDuplicateOrderItem,
      clearOrderItems,
      onPartnerTenantChange,
      onResetForm,
    },
  };
};

export type UseBulkOrderRequestReturnType = ReturnType<typeof useBulkOrderRequest>;

export default useBulkOrderRequest;
