import CategoriesSelect, { CategoriesSelectProps } from '../../../modules/categories/components/FormCategoriesSelect'
import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & CategoriesSelectProps;

const FormCategoriesSelect = (props: Props) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer className={className} error={error} isRequired={isRequired} label={label}>
      <CategoriesSelect {...selectProps} />
    </FormFieldContainer>
  );
};

export default FormCategoriesSelect;
