import FormTenantSelect, { TenantOption } from '../../../FormTenantSelect';
import { useCallback, useContext, useMemo } from 'react';
import currentUser from '../../../../auth/utils/currentUser';
import { request } from 'app/utils/request';
import { API_URL } from '../../../../../consts/config';
import { setLoginCookies } from '../../../../auth/views/Login/utils/authTokens';
import useCurrentUser from '../../../../auth/hooks/useCurrentUser';
import { useAccessControl } from '../../../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';
import { UnifiedHeaderContext } from '../../context';

const TENANT_SWITCH_URL = `${API_URL}/tenant/switch-tenant`;

const TenantSwitchNav = () => {
  const value = useMemo(() => {
    return currentUser.tenantId() || '';
  }, [currentUser]);

  const { workspacePermissions } = useCurrentUser();

  const canAccess = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Switch,
    AllowedPermissionsSubjectEnum.Tenant,
  );

  const { onLinkButtonClick } = useContext(UnifiedHeaderContext);
  const onTenantChange = useCallback(async (tenantValue: string, option?: TenantOption) => {
    onLinkButtonClick(async () => {
      try {
        const response = await request(
          TENANT_SWITCH_URL,
          {
            method: 'post',
            body: JSON.stringify({
              tenantId: tenantValue,
            }),
            headers: { 'content-type': 'application/json; charset=utf-8' },
          },
          {
            auth: true,
          },
        );

        const data = await response.json();
        await setLoginCookies(data.loginToken);
        document.location.reload();
      } catch (error) {
        console.error('[Failed at tenant switch]', error);
      }
    });
  }, []);

  if (!canAccess) {
    return null;
  }

  return (
    <div className="border-[1px] border-l-0 border-r-0 border-grey-300  bg-white px-[15px]">
      <div className="mw-[250px]">
        <FormTenantSelect value={value} onChange={onTenantChange} />
      </div>
    </div>
  );
};

export default TenantSwitchNav;
