import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ManufacturerTableRowFragmentDoc } from '../../../../Manufacturers/graphql/fragments/generated/ManufacturerTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateManufacturerMutationVariables = Types.Exact<{
  input: Types.UpdateManufacturerInput;
}>;


export type UpdateManufacturerMutation = { __typename?: 'Mutation', updateManufacturer: { __typename?: 'UpdateManufacturerPayload', success: boolean, reason?: string | null, manufacturer?: { __typename?: 'ManufacturerSchema', id: string, name: string, emailId?: string | null, phoneNo?: string | null, url?: string | null, updatedAt: string } | null } };


export const UpdateManufacturerDocument = gql`
    mutation updateManufacturer($input: UpdateManufacturerInput!) {
  updateManufacturer(input: $input) {
    success
    reason
    manufacturer {
      ...ManufacturerTableRow
    }
  }
}
    ${ManufacturerTableRowFragmentDoc}`;

export function useUpdateManufacturerMutation() {
  return Urql.useMutation<UpdateManufacturerMutation, UpdateManufacturerMutationVariables>(UpdateManufacturerDocument);
};