import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderHistoryTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.OrderRequestFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type OrderHistoryTableQuery = {
  __typename?: 'Query';
  orderRequests: {
    __typename?: 'OrderRequestConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrderRequestEdge';
      node: {
        __typename?: 'OrderRequestSchema';
        id: string;
        orderRequestCode: string;
        title?: string | null;
      };
    }> | null;
    pageInfo?: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPrevPage: boolean;
      nextCursor?: string | null;
      prevCursor?: string | null;
    } | null;
  };
};

export const OrderHistoryScheduleReceivingDocument = gql`
  query orderHistoryTable(
    $filters: OrderRequestFilters
    $sorts: [SortProps!]
    $limit: Float
    $after: String
    $before: String
  ) {
    orderRequests(filters: $filters, sorts: $sorts, limit: $limit, after: $after, before: $before) {
      edges {
        node {
          id
          orderRequestCode
          orderRequestItems {
            status
            id
          }
          title
          deliverTo {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        nextCursor
        prevCursor
      }
      totalCount
    }
  }
`;

export function useOrderHistoryScheduleReceivingQuery(
  options?: Omit<Urql.UseQueryArgs<OrderHistoryTableQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrderHistoryTableQuery>({
    query: OrderHistoryScheduleReceivingDocument,
    ...options,
  });
}
