import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';
import { warrantyStatusFromEnum } from 'app/modules/network/utils';
import { NetworkAssetStatusEnum } from 'app/types/schema';
import { formatDate } from 'app/utils/date';

export const getITAssetReportTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'mac',
    label: 'MAC Address',
    value: 'mac',
    valueNode: ({ row }) => (
      <span className="truncate">
        {row.status === NetworkAssetStatusEnum.Active ? (
          <Link
            className={'sku-link truncate'}
            target="_blank"
            to={routes.NetworkAssetDetail(row.id)}>
            {row?.mac}
          </Link>
        ) : (
          row.mac
        )}
      </span>
    ),
    width: 200,
  },
  {
    id: 'userName',
    label: 'Username',
    value: 'userName',
    valueNode: (param) => (
      <span className="truncate" title={param.row.userName || 'Unknown'}>
        {param.row.userName || 'Unknown'}
      </span>
    ),
    width: 250,
  },
  {
    id: 'ipOctetNumeric',
    label: 'IP Address',
    value: 'ipOctetNumeric',
    valueNode: (param) => (
      <span className="truncate" title={param.row.ipAddress}>
        {param.row.ipAddress}
      </span>
    ),
    width: 150,
  },
  {
    id: 'deviceType',
    label: 'Device Type',
    value: 'deviceType',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.type}>
        {param.row?.type}
      </span>
    ),
    width: 200,
  },
  {
    id: 'system.manufacturer',
    label: 'System Manufacturer',
    value: 'system.manufacturer',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.system?.manufacturer}>
        {param.row?.system?.manufacturer}
      </span>
    ),
    width: 200,
  },
  {
    id: 'system.model',
    label: 'System Model',
    value: 'system.model',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.system?.model}>
        {param.row?.system?.model}
      </span>
    ),
    width: 150,
  },
  {
    id: 'operatingSystem.platform',
    label: 'OS Platform',
    value: 'operatingSystem.platform',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.operatingSystem?.platform}>
        {param.row?.operatingSystem?.platform}
      </span>
    ),
    width: 250,
  },
  {
    id: 'operatingSystem.distro',
    label: 'OS Distribution',
    value: 'operatingSystem.distro',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.operatingSystem?.distro}>
        {param.row?.operatingSystem?.distro}
      </span>
    ),
    width: 150,
  },
  {
    id: 'cpu.manufacturer',
    label: 'CPU Manufacturer',
    value: 'cpu.manufacturer',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.cpu?.manufacturer}>
        {param.row?.cpu?.manufacturer}
      </span>
    ),
    width: 250,
  },
  {
    id: 'cpu.brand',
    label: 'CPU Brand',
    value: 'cpu.brand',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.cpu?.brand}>
        {param.row?.cpu?.brand}
      </span>
    ),
    width: 250,
  },
  {
    id: 'battery.manufacturer',
    label: 'Battery',
    value: 'battery.manufacturer',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.battery?.manufacturer}>
        {param.row?.battery?.manufacturer}
      </span>
    ),
    width: 250,
  },
  {
    id: 'bios.version',
    label: 'BIOS Version',
    value: 'bios.version',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.bios?.version}>
        {param.row?.bios?.version}
      </span>
    ),
    width: 250,
  },
  {
    id: 'motherboard.manufacturer',
    label: 'Motherboard Manufacturer',
    value: 'motherboard.manufacturer',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.motherboard?.manufacturer}>
        {param.row?.motherboard?.manufacturer}
      </span>
    ),
    width: 250,
  },
  {
    id: 'motherboard.model',
    label: 'Motherboard Model',
    value: 'motherboard.model',
    valueNode: (param) => (
      <span className="truncate" title={param.row?.motherboard?.model}>
        {param.row?.motherboard?.model}
      </span>
    ),
    width: 250,
  },
  {
    id: 'graphics.model',
    label: 'Graphics Model',
    value: 'graphics.model',
    valueNode: (param) => {
      const graphicsModel =
        param.row?.graphics && param.row?.graphics.length ? param.row?.graphics[0]?.model : '';
      return (
        <span className="truncate" title={graphicsModel}>
          {graphicsModel}
        </span>
      );
    },
    width: 250,
  },
  {
    id: 'storage.name',
    label: 'Storage Name',
    value: 'storage.name',
    valueNode: (param) => {
      const storageName =
        param.row?.storage && param.row?.storage.length ? param.row?.storage[0]?.name : '';
      return (
        <span className="truncate" title={storageName}>
          {storageName}
        </span>
      );
    },
    width: 250,
  },
  {
    id: 'warrantyStatus',
    label: 'Warranty Status',
    value: 'warrantyStatus',
    valueNode: (param) => (
      <span className="truncate" title={warrantyStatusFromEnum(param.row?.warrantyStatus)}>
        {warrantyStatusFromEnum(param.row?.warrantyStatus)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'updatedAt',
    label: 'Last Update',
    value: 'updatedAt',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt)}>
        {formatDate(row.updatedAt)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    value: 'createdAt',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.createdAt)}>
        {formatDate(row.createdAt)}
      </span>
    ),
    width: 250,
  },
];
