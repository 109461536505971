import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { PartnerTenantsTableRowFragmentDoc } from '../../fragments/generated/PartnerTenantsTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PartnerTenantsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.PartnerTenantFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PartnerTenantsTableQuery = { __typename?: 'Query', partnerTenants: { __typename?: 'PartnerTenantConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges: Array<{ __typename?: 'PartnerTenantEdge', node: { __typename?: 'PartnerTenantSchema', id: string, partnerType: Types.PartnerTenantTypeEnum, status: Types.PartnerTenantStatusEnum, tenant: { __typename?: 'TenantSchema', id: string, name: string, logo?: string | null }, allowedUsers?: Array<{ __typename?: 'UserSchema', id: string, name: string }> | null, accessibleCategories?: Array<{ __typename?: 'CategorySchema', id: string, name: string }> | null } }> } };


export const PartnerTenantsTableDocument = gql`
    query partnerTenantsTable($filters: PartnerTenantFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  partnerTenants(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...PartnerTenantsTableRow
      }
    }
  }
}
    ${PartnerTenantsTableRowFragmentDoc}`;

export function usePartnerTenantsTableQuery(options?: Omit<Urql.UseQueryArgs<PartnerTenantsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<PartnerTenantsTableQuery>({ query: PartnerTenantsTableDocument, ...options });
};