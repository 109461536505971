import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormImageUpload from 'app/components/Form/FormImageUpload';
import FormPhoneInput from 'app/components/Form/FormPhoneInput';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Manufacturer from 'app/i18n/Manufacturers';
import { CreateManufacturerInput } from 'app/types/schema';
import Box from 'app/ui-components';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { MANUFACTURE_FORM_RULES as Rules } from './utils';

export type ManufacturerFormProps = {
  defaultValues?: CreateManufacturerInput;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: (values: CreateManufacturerInput) => void;
};

export default function ManufacturerForm(props: ManufacturerFormProps) {
  const [isUploading, setIsUploading] = useState(false);

  const { defaultValues, disabled, onCancel, onSubmit } = props;

  const { control, handleSubmit } = useForm({ defaultValues });
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const manufacturerValues = {
          ...values,
          name: removeMultipleSpaces(values.name),
          emailId: removeMultipleSpaces(values?.emailId || ''),
          url: removeMultipleSpaces(values?.url || ''),
        };
        onSubmit(manufacturerValues);
      }),
    [handleSubmit, onSubmit],
  );

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              characterLimit={30}
              error={fieldState.error}
              label={Manufacturer.FormLabels.ManufacturerName}
            />
          )}
          rules={Rules.name}
        />
        <Controller
          control={control}
          name="url"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              error={fieldState.error}
              label={Manufacturer.FormLabels.Website}
            />
          )}
        />
        <Controller
          control={control}
          name="emailId"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              className="flex-1"
              error={fieldState.error}
              label={Manufacturer.FormLabels.Email}
            />
          )}
          rules={Rules.email}
        />
        <Controller
          control={control}
          name="phoneNo"
          render={({ field, fieldState }) => (
            <FormPhoneInput
              {...field}
              error={fieldState.error}
              label={Manufacturer.FormLabels.ContactNumber}
            />
          )}
        />

        <Box className="flex justify-end space-x-16">
          <Button
            disabled={isUploading || disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
