import { Content } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import Inventory from 'app/i18n/Inventory';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';
import { ItemLocationSchema, Maybe } from 'app/types/schema';

import Loader from '../../../../components/Loader';
import EntityManager from '../../../components/EntityManager';
import EditInventoryView from '../../components/EditInventoryView';
import useEditInventoryState from './hook/useEditInventoryState';

type Props = RouteComponentProps & { inventoryId: string };

const DeletedInventory = (props: Props) => {
  const { inventoryId } = props;

  const { fetching, inventoryData, inventoryFormData, stockInformation } =
    useEditInventoryState(inventoryId);

  if (fetching) {
    return <Loader />;
  }

  if (!inventoryData) {
    return <Redirect to={routes.InventorySearch()} />;
  }

  return (
    <EntityManager>
      <EntityManager.Title
        documentTitle={Inventory.DeletedInventory}
        title={Inventory.DeletedInventory}
      />
      <Content>
        <EditInventoryView
          disabled={true}
          inventoryData={inventoryData}
          inventoryFormData={inventoryFormData}
          inventoryId={inventoryId}
          stockInformation={stockInformation as Maybe<ItemLocationSchema[]>}
        />
      </Content>
    </EntityManager>
  );
};

export default withRouter(DeletedInventory);
