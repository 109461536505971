import { useCallback, useEffect, useState } from 'react';
import usePagination from 'app/hooks/usePagination';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useReceivablesQuery } from 'app/modules/receiving/graphql/queries/generated/receivables';
import { ReceivableSchema, ReceivableStatusEnum, ReceivableTypeEnum } from 'app/types/schema';

export type ReceiveRowType = Omit<
  ReceivableSchema,
  'createdBy' | 'createdById' | 'updatedBy' | 'updatedById' | 'receivingHistory' | 'type'
>;

interface FilterState {
  statuses?: [ReceivableStatusEnum];
  orderRequestIds?: [string];
  deliverTo?: [string];
}

const useReceivingHistory = () => {
  const { table } = useMultiSelectEntityManager({
    selection: true,
  });

  const [rows, setRows] = useState<ReceiveRowType[]>([]);

  const [filterState, _setFilterState] = useState<FilterState>({});

  const setFilterState = useCallback(
    (nextState: Partial<FilterState>) => {
      _setFilterState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFilterState],
  );

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useReceivablesQuery,
    {
      filters: {
        types: [ReceivableTypeEnum.OrderRequest],
        statuses: filterState.statuses || [],
        orderRequestIds: filterState.orderRequestIds || [],
        deliverToIds: filterState.deliverTo || [],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'receivables',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  useEffect(() => {
    const rowsWithEditableFields = (data?.receivables?.edges?.map(({ node }) => node) ||
      []) as unknown as ReceiveRowType[];
    setRows(rowsWithEditableFields);
  }, [data?.receivables?.edges, setRows]);

  return {
    table,
    rows,
    rowCount: data?.receivables?.totalCount || 0,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    filterState,
    setFilterState,
  };
};

export type UseCheckInAssetsStateReturnType = ReturnType<typeof useReceivingHistory>;
export default useReceivingHistory;
