import { createRef } from 'react';
import { Controller } from 'react-hook-form';
import FormInputImageUploader from 'app/components/ProcureForm/FormInputImageUploader';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';
import { orderRequestAttachmentAccepts } from 'app/utils/attachments';

import useInsertItemChange from '../../hook/useInsertItemChange';
import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderImageField = (props: OrderFieldProps) => {
  const { formState, disabled, isExternalTenant } = props;
  const { control, orderImageFieldInputProps } = formState;

  const { value: isImageFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.ImageFieldEnabledInOrderRequestForm,
  );

  const { onInsertItemChange, setInputRef, isInsertItemTypeSame } = useInsertItemChange({
    insertItemType: ORDER_INSERT_TYPE.NON_STOCKED_ITEMS,
    formState,
  });
  const imageInputFieldRef = createRef<any>();

  if (loading || !isImageFieldEnabled || isExternalTenant) {
    return null;
  }

  return (
    <div className="mb-10 flex">
      <div className="flex-1 flex-auto">
        <Controller
          control={control}
          name="imageUrl"
          render={({ field, fieldState }) => (
            <FormInputImageUploader
              {...field}
              {...orderImageFieldInputProps}
              ref={imageInputFieldRef}
              accept={orderRequestAttachmentAccepts}
              disabled={disabled}
              error={fieldState.error}
              getInputReference={setInputRef}
              label={Orders.FormLabels.AddImage}
              maxSize={1048576}
              multiple={false}
              formLabel={Orders.FormLabels.AddImage}
              // onClick={onInsertItemChange}
            />
          )}
          rules={ORDER_REQUEST_FORM_RULES.image(formState.actions.validateAllEmpty)}
        />
      </div>
    </div>
  );
};

export default OrderImageField;
