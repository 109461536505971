import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { getTransactionTypeLabel } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import { getTotalCost, getUnitCost } from 'app/modules/reports/utils';
import { formatDate } from 'app/utils/date';

export const getInventoryTransactionTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row.entity} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.entity?.title}>
        {row.entity?.title}
      </span>
    ),
  },
  {
    id: 'model',
    label: Reports.Model,
    value: 'model',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.entity?.model}>
        {row.entity?.model}
      </span>
    ),
  },
  {
    id: 'category',
    label: Category.Category,
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.entity?.category?.name}>
        {row.entity?.category?.name}
      </span>
    ),
  },
  {
    id: 'categoryCode',
    label: 'Category Code',
    value: 'categoryCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.entity?.category?.categoryCode}>
        {row.entity?.category?.categoryCode}
      </span>
    ),
  },
  {
    id: 'project',
    label: 'Project',
    value: 'project',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.project?.name}>
        {row.project?.name}
      </span>
    ),
  },
  {
    id: 'projectCode',
    label: 'Project Code',
    value: 'projectCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.project?.projectCode}>
        {row.project?.projectCode}
      </span>
    ),
  },
  {
    id: 'quantity',
    label: Reports.Qty,
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'unitCostWithoutOverride',
    label: Reports.UnitCost,
    value: 'unitCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.entity?.unitCost)}>
        {getUnitCost(row.entity?.unitCost)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'unitCostWithOverride',
    label: Reports.UnitCostWithOverride,
    value: 'unitCostWithOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.entity?.cost)}>
        {getUnitCost(row.entity?.cost)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'totalCostWithoutOverride',
    label: Reports.TotalCostWithoutOverride,
    value: 'totalCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTotalCost(row.entity?.unitCost, row.quantity)}>
        {getTotalCost(row.entity?.unitCost, row.quantity)}
      </span>
    ),
    width: 280,
  },
  {
    id: 'totalCostWithOverride',
    label: Reports.TotalCostWithOverride,
    value: 'totalCostWithOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTotalCost(row.entity?.cost, row.quantity)}>
        {getTotalCost(row.entity?.cost, row.quantity)}
      </span>
    ),
    width: 280,
  },
  {
    id: 'createdAt',
    label: 'Transaction Date',
    value: 'createdAt',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.createdAt)}>
        {formatDate(row.createdAt)}
      </span>
    ),
  },
  {
    id: 'transactionType',
    label: 'Transaction Type',
    value: 'transactionType',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTransactionTypeLabel(row.type, row.subType)}>
        {getTransactionTypeLabel(row.type, row.subType)}
      </span>
    ),
  },
  {
    id: 'reason',
    label: Reports.Reason,
    value: 'reason',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.meta?.reason}>
        {row.meta?.reason}
      </span>
    ),
  },
  {
    id: 'sourceSite',
    label: 'From Site',
    value: 'sourceSite',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.sourceSite?.name}>
        {row.sourceSite?.name}
      </span>
    ),
  },
  {
    id: 'sourceSiteCode',
    label: 'From Site Code',
    value: 'sourceSiteCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.sourceSite?.locationCode}>
        {row.sourceSite?.locationCode}
      </span>
    ),
  },
  {
    id: 'destinationSite',
    label: 'To Site',
    value: 'destinationSite',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.destinationSite?.name}>
        {row.destinationSite?.name}
      </span>
    ),
  },
  {
    id: 'destinationSiteCode',
    label: 'To Site Code',
    value: 'destinationSiteCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.destinationSite?.locationCode}>
        {row.destinationSite?.locationCode}
      </span>
    ),
  },
  {
    id: 'billToSite',
    label: 'Bill To Site',
    value: 'billToSite',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.billToSite?.name}>
        {row.billToSite?.name}
      </span>
    ),
  },
  {
    id: 'billToSiteCode',
    label: 'Bill To Site Code',
    value: 'billToSiteCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.billToSite?.locationCode}>
        {row.billToSite?.locationCode}
      </span>
    ),
  },
  {
    id: 'department',
    label: 'Department',
    value: 'department',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.department?.name}>
        {row.department?.name}
      </span>
    ),
  },
  {
    id: 'departmentCode',
    label: 'Department Code',
    value: 'departmentCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.department?.departmentCode}>
        {row.department?.departmentCode}
      </span>
    ),
  },
  {
    id: 'createdBy',
    label: 'User',
    value: 'createdBy',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.createdBy?.name}>
        {row.createdBy?.name}
      </span>
    ),
  },
];
