import { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { FormState } from '../components/Forms/types';
import { FromContext } from './FormProvider';

const useLabelsFrom = () => {
  const context = useContext<Partial<UseFormReturn & {values: FormState, setData: Function, data: FormState, isCustomFieldValid: boolean, setIsCustomFieldValid: (isValid: boolean) => void }>>(FromContext);

  return {
    ...context,
  };
};

export default useLabelsFrom;
