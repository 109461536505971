import { Controller } from 'react-hook-form';
import FormSiteSelect from 'app/components/ProcureForm/FormSiteSelect';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';
import { ORDER_DELIVERY_DETAIL_FORM_RULES } from '../../utils/rules';

const OrderBillToField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, isRequired, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="billToSiteId"
        render={({ field, fieldState }) => (
          <FormSiteSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            isRequired={isRequired}
            label={Orders.FormLabels.BillTo}
            formLabel={Orders.FormLabels.BillTo}
          />
        )}
        rules={ORDER_DELIVERY_DETAIL_FORM_RULES.billTo}
      />
    </div>
  );
};

export default OrderBillToField;
