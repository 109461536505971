import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DistinctNetworkAssetsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.NetworkAssetsDistinctValueFilters>;
}>;


export type DistinctNetworkAssetsQuery = { __typename?: 'Query', distinctNetworkAssets: { __typename?: 'DistinctValueNetworkAssetsPayload', distinctValues: { __typename?: 'NetworkAssetsDistictValueSchema', software?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, systemManufacturer?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, systemModel?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, operatingSystem?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, operatingSystemPlatform?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, batteryManufacturer?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, biosVersion?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, biosReleaseDate?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, motherboardModel?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, cpuManufacturer?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, cpuBrand?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, graphicsModel?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null, storageName?: Array<{ __typename?: 'DistictValueSchema', value?: string | null, count?: number | null }> | null } } };


export const DistinctNetworkAssetsDocument = gql`
    query distinctNetworkAssets($filters: NetworkAssetsDistinctValueFilters) {
  distinctNetworkAssets(filters: $filters) {
    distinctValues {
      software {
        value
        count
      }
      systemManufacturer {
        value
        count
      }
      systemModel {
        value
        count
      }
      operatingSystem {
        value
        count
      }
      operatingSystemPlatform {
        value
        count
      }
      batteryManufacturer {
        value
        count
      }
      biosVersion {
        value
        count
      }
      biosReleaseDate {
        value
        count
      }
      motherboardModel {
        value
        count
      }
      cpuManufacturer {
        value
        count
      }
      cpuBrand {
        value
        count
      }
      graphicsModel {
        value
        count
      }
      storageName {
        value
        count
      }
    }
  }
}
    `;

export function useDistinctNetworkAssetsQuery(options?: Omit<Urql.UseQueryArgs<DistinctNetworkAssetsQueryVariables>, 'query'>) {
  return Urql.useQuery<DistinctNetworkAssetsQuery>({ query: DistinctNetworkAssetsDocument, ...options });
};