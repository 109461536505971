import { trim } from 'lodash';

const numberRegex = /^\d+$/;

export const getSectionedList = (array: any[] = [], key: string) => {
  if (array.length === 0) {
    return [];
  }
  const names = [...new Array(26)].map((k, i) => i + 97).map((x) => String.fromCharCode(x));

  const sortedAlphabetically: any = {};
  for (const item in array) {
    const firstLetter = `${array[item][key] ? trim(array[item][key])[0] : ''}`.toLowerCase();
    if (sortedAlphabetically[firstLetter]) {
      sortedAlphabetically[firstLetter].push(array[item]);
    } else {
      sortedAlphabetically[firstLetter] = [array[item]];
    }
  }
  const filtered = names?.filter((i) => {
    if (Object.keys(sortedAlphabetically).includes(i)) {
      return false;
    }
    return true;
  });

  filtered.forEach((i) => {
    sortedAlphabetically[i] = [];
  });

  const data: any = Object.entries(sortedAlphabetically)
    .filter((i: [string, any]) => i[1].length > 0)
    .map((i: any) => ({
      children: i[1]?.sort((a: any, b: any) => a[key]?.localeCompare(b[key])),
      group: i[0].toUpperCase(),
    }));

  let result = data?.sort((a: any, b: any) => a?.group?.localeCompare(b?.group));
  result = result.map((i: any) => {
    if (numberRegex.test(i.group[0])) {
      return {
        ...i,
        group: 'number',
      };
    }
    return i;
  });

  const hashedFilter = result.filter((i: any) => i.group === 'number');
  const hashed = [];
  if (hashedFilter?.length) {
    const child = hashedFilter?.map((i: any) => i.children.flat());
    const children = [].concat(...child);
    hashed.push({ children, group: '#' });
  }

  return result.filter((i: any) => i?.group !== 'number').concat(hashed);
};
