import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { orderBy } from 'lodash';
import {
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import AsyncMultiTableFilter from 'app/components/AsyncMultiTableFilter';
import { AsyncMultiTableFilterProps } from 'app/components/AsyncMultiTableFilter/types';
import { AsyncSingleSelectProps } from 'app/components/AsyncSingleSelect/types';
import { useReceivableSearchQuery } from 'app/modules/receiving/graphql/queries/receivableSearch';
import {
  Maybe,
  ReceivableStatusEnum,
  RoleTypeFilterEnum,
  UserStatusEnum,
  ValueInEntitiesFiltersConfig,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
  ReceivableTypeEnum
} from 'app/types/schema';

interface IStatus {
  statuses: ReceivableStatusEnum;
}

interface ITransactionFilters {
  receivableFilters: IStatus;
}

interface QueryVariables {
  roleTypes?: RoleTypeFilterEnum[];
  statuses?: UserStatusEnum[] | ReceivableStatusEnum[];
  tenantIds?: string[];
  includingPartnerTenantExternalUsers?: boolean;
  distinctTableName?: ValuesInEntitiesTableNameEnum;
  distinctKeys?: ValuesInEntitiesDistinctByKeysEnum[];
  filters?: ITransactionFilters;
  filterKey?: keyof ValueInEntitiesFiltersConfig;
  shouldSortOptions?: boolean;
}

export type TableFilterProps = {
  name: string;
  value?: Maybe<string[]> | Maybe<string>;
  onBlur?: () => void;
  onChange: AsyncMultiTableFilterProps['onChange'] | AsyncSingleSelectProps['onChange'];
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: QueryVariables;
  isMultiple?: boolean;
  isDistinct?: boolean;
};
export interface UserSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: QueryVariables;
}

const useDistinctValueSelectQuery = (props: UseSelectQueryProps & any): UseSelectQueryReturn => {
  const { distinctKeys, statuses, shouldSortOptions } = props;
  const [{ fetching, data }] = useReceivableSearchQuery({
    variables: {
      input: {
        tableName: ValuesInEntitiesTableNameEnum.Receivable,
        distinctByKeys: distinctKeys || [],
        filters: {
          receivableFilters: {
            types: [ReceivableTypeEnum.OrderRequest],
            statuses: statuses || [],
          },
        },
      },
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(() => {
    let options =
      data?.valuesInEntities.payload[0].entities.map((entity) => {
        return {
          entity,
          value: entity.id,
          label: entity.name,
        };
      }) || [];

    return options;
  }, [data?.valuesInEntities.payload]);
  return { isLoading: fetching, options };
};

const TableColumnFilter = (props: TableFilterProps) => {
  const { value, onChange, ...otherProps } = props;

  const selectedValues = () => ({ isLoading: false, options: [] });
  return (
    <AsyncMultiTableFilter
      {...otherProps}
      size="small"
      useSelectQuery={useDistinctValueSelectQuery}
      useSelectedValue={selectedValues as unknown as (props: any) => UseSelectedValuesReturn}
      value={value as Maybe<string[]>}
      onChange={onChange as AsyncMultiTableFilterProps['onChange']}
      onClick={(event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    />
  );
};

export default TableColumnFilter;
