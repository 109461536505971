import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnpackShippingTransactionsOfTrackingIdMutationVariables = Types.Exact<{
  input: Types.UnpackShippingTransactionsOfTrackingIdsInput;
}>;


export type UnpackShippingTransactionsOfTrackingIdMutation = { __typename?: 'Mutation', unpackShippingTransactionsOfTrackingIds: { __typename?: 'MutationResponse', success: boolean } };


export const UnpackShippingTransactionsOfTrackingIdDocument = gql`
    mutation unpackShippingTransactionsOfTrackingId($input: UnpackShippingTransactionsOfTrackingIdsInput!) {
  unpackShippingTransactionsOfTrackingIds(input: $input) {
    success
  }
}
    `;

export function useUnpackShippingTransactionsOfTrackingIdMutation() {
  return Urql.useMutation<UnpackShippingTransactionsOfTrackingIdMutation, UnpackShippingTransactionsOfTrackingIdMutationVariables>(UnpackShippingTransactionsOfTrackingIdDocument);
};