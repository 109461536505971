import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReturnOrderRequestItemsMutationVariables = Types.Exact<{
  input: Types.ReturnOrderRequestItemsInput;
}>;


export type ReturnOrderRequestItemsMutation = { __typename?: 'Mutation', returnOrderRequestItems: { __typename?: 'MutationResponse', success: boolean } };


export const ReturnOrderRequestItemsDocument = gql`
    mutation returnOrderRequestItems($input: ReturnOrderRequestItemsInput!) {
  returnOrderRequestItems(input: $input) {
    success
  }
}
    `;

export function useReturnOrderRequestItemsMutation() {
  return Urql.useMutation<ReturnOrderRequestItemsMutation, ReturnOrderRequestItemsMutationVariables>(ReturnOrderRequestItemsDocument);
};