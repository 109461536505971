import React from 'react';
import { Pagination } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import useShop from 'app/modules/shop/context/useShop';
import Box from 'app/ui-components';
import { Divider, Box as MuiBox } from '@mui/material';

import { Product } from '../../hook/useHooverShopProducts';
import ProductsMerchants from '../SearchBox/utils/ProductsMerchants';

const ProductsTable: React.FC = () => {
  const {
    search,
    loading,
    products,
    totalCount,
    activePage,
    numberOfRowsPerPage,
    getQuery,
    getProducts,
    setState,
  } = useShop();

  const paginateTo = (pageNumber: number) => setState({ activePage: pageNumber });

  const handlePageClick = async (event: any, page: any) => {
    if (!Number.isNaN(page)) {
      setState({ activePage: page });
      let variables = getQuery(search, page * numberOfRowsPerPage, numberOfRowsPerPage);
      await getProducts(variables);
      paginateTo(page);
      window.scrollTo({
        top: 10,
        behavior: 'smooth',
      });
    }
  };

  const handleOnChange = async (event: any) => {
    const value = event.target.value;
    setState({ numberOfRowsPerPage: Number(value) });
    let variables = getQuery(search, 0, Number(value));
    await getProducts(variables);
    setState({ activePage: 0 });
  };

  const filteredData = products.filter((item: Product) => {
    return item.offers.nodes.some((node) => {
      return node.merchant?.name;
    });
  });

  return (
    <Box data-testid="products-table">
      {!loading && filteredData?.length > 0 && (
        <>
          <Divider />

          <Box className="flex w-full justify-end py-[24px]">
            <Pagination
              classes="paginations"
              count={totalCount}
              page={activePage}
              rowsPerPage={numberOfRowsPerPage}
              onPageChange={handlePageClick}
              onRowsPerPageChange={handleOnChange}
            />
          </Box>
        </>
      )}

      {loading ? (
        <Loader />
      ) : filteredData?.length ? (
        <ProductsMerchants data={products || []} />
      ) : (
        <p className={'py-[30px] px-[0px] text-center'}>No matching records found.</p>
      )}
      {!loading && filteredData?.length > 0 && (
        <>
          <Divider className="mt-[24px]" />
          <Box className="flex w-full justify-end py-[24px]">
            <Pagination
              classes="paginations"
              count={totalCount}
              page={activePage}
              rowsPerPage={numberOfRowsPerPage}
              onPageChange={handlePageClick}
              onRowsPerPageChange={handleOnChange}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProductsTable;
