import { OrderRequestItemSchema, OrderRequestSchema } from 'app/types/schema';
import { Link } from 'app/libs/navigation';
import { useCallback, useMemo } from 'react';
import { getOrderDetailsLink } from '../../../../utils/utils';
import { isAssetInventoryTypeItem } from '../../../OrderDetail/hook/useOrderRequestItems/utils';

interface Props {
  row: OrderRequestSchema;
}

const OrderIdToolTip = (props: Props) => {
  const { row } = props;
  const { orderRequestItems } = row;

  const orderDetailLink = useMemo(() => getOrderDetailsLink(row), [row]);

  const getPreviewText = useCallback((orderRequestItem: OrderRequestItemSchema) => {
    const { type, item, upcCode, website, description } = orderRequestItem;
    if (isAssetInventoryTypeItem(type)) {
      return item?.title || '';
    } else {
      return upcCode || website || description || '';
    }
  }, []);

  return (
    <div className="order-id-tooltip">
      <div
        className={orderRequestItems.length > 6 ? 'preview-container' : 'preview-tooltip'}
        style={{ width: '210px' }}>
        {orderRequestItems?.map((orderRequestItem, index) => (
          <div key={`previewItem-${index}`}>
            <p className="order-item-title">{getPreviewText(orderRequestItem)}</p>
          </div>
        ))}

        <div className="footer-divider" />
        <Link className="linkText" to={orderDetailLink} target='_blank'>
          View this order request &gt;
        </Link>
      </div>
    </div>
  );
};

export default OrderIdToolTip;
