import { isAccessible } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import Partner from 'app/i18n/Partner';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  UserWorkspacePermission,
} from 'app/types/schema';

export const getRedirectTo = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('redirectTo');
};

const URL_DOMAIN_CHECK =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const isUrlContainsDomain = (url: string) => {
  return URL_DOMAIN_CHECK.test(url);
};

const REDIRECTION_ROUTES = {
  partner: [
    {
      route: routes.Partners(),
      subject: AllowedPermissionsSubjectEnum.PartnerTenant,
      actions: AllowedPermissionActionsEnum.Manage,
    },
  ],
  admin: [
    {
      route: routes.Users(),
      subject: AllowedPermissionsSubjectEnum.User,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Roles(),
      subject: AllowedPermissionsSubjectEnum.Role,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Organizations(),
      subject: AllowedPermissionsSubjectEnum.Tenant,
      actions: AllowedPermissionActionsEnum.Switch,
    },
    {
      route: routes.AssetModels(),
      subject: AllowedPermissionsSubjectEnum.AssetModel,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Categories(),
      subject: AllowedPermissionsSubjectEnum.Category,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Departments(),
      subject: AllowedPermissionsSubjectEnum.Department,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Locations(),
      subject: AllowedPermissionsSubjectEnum.Location,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Manufacturers(),
      subject: AllowedPermissionsSubjectEnum.Manufacturer,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Projects(),
      subject: AllowedPermissionsSubjectEnum.Project,
      actions: AllowedPermissionActionsEnum.Access,
    },
    {
      route: routes.Sites(),
      subject: AllowedPermissionsSubjectEnum.Site,
      actions: AllowedPermissionActionsEnum.Access,
    },
  ],
};

function getPartnerRoute(workspacePermissions: Array<UserWorkspacePermission>) {
  const isPartnerTab = window.location.pathname.includes(Partner.Partners.toLowerCase());

  if (!isPartnerTab) {
    return undefined;
  }

  return REDIRECTION_ROUTES.partner.find((adminRoute) => {
    const { subject, actions } = adminRoute;
    return isAccessible(workspacePermissions, actions, subject);
  })?.route;
}

function getAdminRoute(workspacePermissions: Array<UserWorkspacePermission>) {
  return REDIRECTION_ROUTES.admin.find((adminRoute) => {
    const { subject, actions } = adminRoute;
    return isAccessible(workspacePermissions, actions, subject);
  })?.route;
}

export function getRedirectToBasedOnPermission(
  workspacePermissions: Array<UserWorkspacePermission>,
) {
  const partnerRoute = getPartnerRoute(workspacePermissions);
  if (partnerRoute) {
    return partnerRoute;
  }

  const adminRoute = getAdminRoute(workspacePermissions);
  if (adminRoute) {
    return adminRoute;
  }

  return routes.Profile();
}
