import React from 'react';

import { DateFormatEnum, formatDate } from '../../../../utils/date';

interface Props {
  replacementDate?: string;
}

const ReplacementDateTableNode = (props: Props) => {
  const { replacementDate } = props;
  const dateValue = replacementDate ? formatDate(replacementDate, DateFormatEnum.Date) : '';
  return (
    <span className="truncate" title={dateValue}>
      {dateValue}
    </span>
  );
};

export default ReplacementDateTableNode;
