import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Content } from '@procurenetworks/procure-component-library';
import { APP_CONFIG } from 'app/consts/config';
import Network from 'app/i18n/Network';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import { AgentCard } from './components/AgentCard';

const NetworkDiscoveryTools = () => {
  const agents = [
    {
      name: 'Discovery Agent',
      type: 'Windows',
      subtitle: 'Automatically add your own Windows device info',
      link: APP_CONFIG.procureAgentWindows,
    },
    {
      name: 'Discovery Agent',
      type: 'Linux',
      subtitle: 'Automatically add your own Linux device info',
      link: APP_CONFIG.procureAgentLinux,
    },
    {
      name: 'Discovery Agent',
      type: 'Mac',
      subtitle: 'Automatically add your own Mac device info',
      link: APP_CONFIG.procureAgentMac,
    },
  ];
  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Asset} className="!px-[24px] pt-0">
      <EntityManager.Title
        documentTitle={Network.NetworkDiscoveryTool}
        title={Network.NetworkDiscoveryTool}
        hasTopDivider
      />
      {/* <Content > */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
        }}>
        <Typography className="m-0 text-[24px] font-bold font-semibold text-grey-600">
          {Network.DiscoverAssetHeading}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Typography className="mt-[8px] text-[14px] text-grey-600">
          {Network.DiscoverAssetParagraph}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        {/* <Grid container spacing={2}> */}
        <Box className="flex flex-wrap justify-center gap-[24px] lg:justify-start xl:justify-start">
          {agents.map((agent, key) => {
            return (
              // <Grid key={key} item lg={4} md={6} sm={6} xl={3} xs={12}>
              <Box className="min-w-[320px] max-w-[340px]">
                <AgentCard
                  icon={'ant-design:apple-filled'}
                  link={agent.link}
                  subTitle={agent.subtitle}
                  title={agent.name}
                  type={agent.type}
                />
              </Box>
              // </Grid>
            );
          })}
        </Box>
        {/* </Grid> */}
      </Box>
      {/* </Content> */}
    </EntityManager>
  );
};

export default NetworkDiscoveryTools;
