import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import usePagination from 'app/hooks/usePagination';

import { ItemStatusEnum, ItemTypeEnum } from '../../../../../../types/schema';
import useMultiSelectEntityManager from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import useDeletedItemsState from '../../../undelete/hook/useDeletedItemsState';
import { useDeletedAssetsTableQuery } from '../../graphql/queries/generated/deletedAssetsTable';

const useDeletedAssetsTable = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const { filters, siteId } = useDeletedItemsState();
  const { search, table } = useMultiSelectEntityManager({});

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useDeletedAssetsTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        statuses: [ItemStatusEnum.Deleted],
        siteIds: siteId ? [siteId] : [],
        types: [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit],
      },
    },
    { edgeKey: 'assetItems', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    const row = data?.assetItems.edges?.map(({ node }) => node) || [];
    setTableData(uniqBy([...tableData, ...row], (row) => row.id));
    return row;
  }, [data, onNextPage , onPrevPage]);

  useEffect(() => {
    const row = tableData.filter((row) => table.state.selectedRowIds.includes(row.id));
    table.setState({ selectedRowData: row });
  }, [table.state.selectedRowIds]);


  return {
    rows,
    search,
    table,
    data,
    fetching,
    onNextPage,
    onPrevPage,
    onReset,
    filters,
  };
};

export type UseDeletedAssetsTableReturnType = ReturnType<typeof useDeletedAssetsTable>;
export default useDeletedAssetsTable;
