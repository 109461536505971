import { useMemo } from 'react';
import { cx } from '@emotion/css';

interface Props {
  displayImage: string;
  height?: string;
  width?: string;
}

const getArrowImage = (displayImage: string) => {
  switch (displayImage) {
    case 'up': {
      return `${process.env.PUBLIC_URL}/images/up-arrow3.png`;
    }
    case 'down': {
      return `${process.env.PUBLIC_URL}/images/down-arrow3.png`;
    }
    default: {
      return `${process.env.PUBLIC_URL}/images/no-arrow3.png`;
    }
  }
};

const ArrowImage = (props: Props) => {
  const { displayImage, height, width } = props;

  const showImage = useMemo(() => getArrowImage(displayImage), [displayImage]);

  return (
    <img alt="arrow" className={`my-auto ${width ?? 'w-[20px]'} ${height ?? 'h-[30px]'}`} src={showImage} />
  );
};

ArrowImage.defaultProps = {
  width: 'w-[20px]',
  height: 'h-[30px]'
}

export default ArrowImage;
