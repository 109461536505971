import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateExternalOrderRequestMutationVariables = Types.Exact<{
  input: Types.CreateExternalOrderRequestInput;
}>;


export type CreateExternalOrderRequestMutation = { __typename?: 'Mutation', createExternalOrderRequest: { __typename?: 'CreateOrderRequestPayload', success: boolean, orderRequest?: { __typename?: 'OrderRequestSchema', id: string } | null } };


export const CreateExternalOrderRequestDocument = gql`
    mutation createExternalOrderRequest($input: CreateExternalOrderRequestInput!) {
  createExternalOrderRequest(input: $input) {
    success
    orderRequest {
      id
    }
  }
}
    `;

export function useCreateExternalOrderRequestMutation() {
  return Urql.useMutation<CreateExternalOrderRequestMutation, CreateExternalOrderRequestMutationVariables>(CreateExternalOrderRequestDocument);
};