import { NetworkAssetSchemaProps } from 'app/modules/network/types';
import { bytesToGB } from 'app/modules/network/utils';

const useDetailInformation = (props: NetworkAssetSchemaProps) => {
  const {networkAssetDetail} = props;

  const cpuData = [
    { label: 'Manufacturer', value: networkAssetDetail?.cpu?.manufacturer },
    { label: 'Brand', value: networkAssetDetail?.cpu?.brand },
    { label: 'Model', value: networkAssetDetail?.cpu?.model },
    { label: 'Processor', value: networkAssetDetail?.cpu?.processors?.toString() },
    { label: 'Cores', value: networkAssetDetail?.cpu?.cores?.toString() },
    { label: 'Physical Cores', value: networkAssetDetail?.cpu?.physicalCores?.toString() },
    { label: 'Performance Cores', value: networkAssetDetail?.cpu?.performanceCores?.toString() },
    { label: 'Physical Cores', value: networkAssetDetail?.cpu?.physicalCores?.toString() },
    { label: 'Stepping', value: networkAssetDetail?.cpu?.stepping },
    { label: 'Voltage', value: networkAssetDetail?.cpu?.voltage },
  ];

  const systemData = [
    { label: 'Manufacturer', value: networkAssetDetail?.system?.manufacturer },
    { label: 'Model', value: networkAssetDetail?.system?.model },
    { label: 'Serial', value: networkAssetDetail?.system?.serial },
    { label: 'Virtual', value: networkAssetDetail?.system?.virtual ? "True": "false" },
  ];

  const operatingSystemData = [
    { label: 'Platform', value: networkAssetDetail?.operatingSystem?.platform },
    { label: 'Distro', value: networkAssetDetail?.operatingSystem?.distro },
    { label: 'Codename', value: networkAssetDetail?.operatingSystem?.codename },
    { label: 'Release', value: networkAssetDetail?.operatingSystem?.release },
    { label: 'Hostname', value: networkAssetDetail?.operatingSystem?.hostname },
    { label: 'Serial', value: networkAssetDetail?.operatingSystem?.serial },
    { label: 'Architecture', value: networkAssetDetail?.operatingSystem?.arch },
    { label: 'Kernal', value: networkAssetDetail?.operatingSystem?.kernel },
  ];

  const motherboardModel = networkAssetDetail?.motherboard?.model;

  const motherboardData = [
    { label: 'Manufacturer', value: networkAssetDetail?.motherboard?.manufacturer},
    { label: 'Version', value: networkAssetDetail?.motherboard?.version },
    { label: 'Model', value:   motherboardModel
      ? motherboardModel.includes(';')
        ? motherboardModel.split(';')[0]
        : motherboardModel
      : '',
    },
    { label: 'Serial', value: networkAssetDetail?.motherboard?.serial },
  ];

  const storageData = [
    { label: 'Name', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].name },
    { label: 'Serial Number', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].serialNum },
    { label: 'Type', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].type },
    { label: 'Interface', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].interfaceType},
    { label: 'Vendor', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].vendor},
    { label: 'Size', value: bytesToGB(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].size) },
    { label: 'Firmware Revision', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].firmwareRevision},
    { label: 'Device', value: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].device},
  ];

  const memoryData = [
    { label: 'Total', value: bytesToGB(networkAssetDetail?.memory?.total) },
    { label: 'Available', value: bytesToGB(networkAssetDetail?.memory?.available) },
    { label: 'Free', value: bytesToGB(networkAssetDetail?.memory?.free)},
    { label: 'Slab', value: bytesToGB(networkAssetDetail?.memory?.slab) },
    { label: 'Buffer', value: bytesToGB(networkAssetDetail?.memory?.buffers) },
    { label: 'Swap Total', value: bytesToGB(networkAssetDetail?.memory?.swaptotal) },
    { label: 'Swap Free', value: bytesToGB(networkAssetDetail?.memory?.swapfree) },
    { label: 'Swap Used', value: bytesToGB(networkAssetDetail?.memory?.swapused) },
  ];

  const diskData = [
    { label: 'File System', value: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].fileSystem },
    { label: 'Read & Write', value: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].readWrite ? "True" : "False" },
    { label: 'Type', value: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].type },
    { label: 'Use', value: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].use?.toString() },
    { label: 'Disk Size', value:  bytesToGB(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].size) },
    { label: 'Disk Used', value:  bytesToGB(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].used) },
    { label: 'Disk Available', value:  bytesToGB(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].available) },
  ];

  const displayData = [
    { label: 'Builtin', value: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].builtin ? "True" : "False"},
    { label: 'Current Refresh Rate', value: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentRefreshRate},
    { label: 'Current Resolution X', value: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentResX},
    { label: 'Current Resolution Y', value: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentResY },
  ];

  const batteryData = [
    { label: 'Manufacturer', value: networkAssetDetail?.battery?.manufacturer },
    { label: 'Type', value: networkAssetDetail?.battery?.type },
    { label: 'Serial', value: networkAssetDetail?.battery?.serial },
    { label: 'Design Capacity', value: networkAssetDetail?.battery?.designedCapacity},
    { label: 'Max Capacity', value: networkAssetDetail?.battery?.maxCapacity },
    { label: 'Current Capacity', value: networkAssetDetail?.battery?.currentCapacity },
    { label: 'Model', value: networkAssetDetail?.battery?.model },
    { label: 'Voltage', value: networkAssetDetail?.battery?.voltage },
    { label: 'Percentage', value: networkAssetDetail?.battery?.percent },

  ];

  const biosData = [
    { label: 'Vendor', value: networkAssetDetail?.bios?.vendor },
    { label: 'Version', value: networkAssetDetail?.bios?.version },
    { label: 'Release Date', value: networkAssetDetail?.bios?.releaseDate },
  ];

  const networkDetails = networkAssetDetail?.network?.length ? networkAssetDetail.network.find(obj => obj.default) : null;

  const networkData = [
    { label: 'Iface', value: networkDetails?.iface },
    { label: 'IPv4', value: networkDetails?.ip4 },
    { label: 'IPv6', value: networkDetails?.ip6 },
    { label: 'MAC', value: networkDetails?.mac },
    { label: 'Type', value: networkDetails?.type },
    { label: 'MTU', value: networkDetails?.mtu },
  ];

  const graphicsData = [
    { label: 'Vendor', value: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].vendor },
    { label: 'Model', value: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].model },
    { label: 'Bus', value: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].bus },
    { label: 'Vram', value: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].vram },
  ];

  const portData = [
    { label: 'Manufacturer', value: networkAssetDetail?.system?.manufacturer },
    { label: 'Model', value: networkAssetDetail?.system?.model },
    { label: 'Serial', value: networkAssetDetail?.system?.serial },
    { label: 'Virtual', value: networkAssetDetail?.system?.virtual },
  ];

  const processData = [
    { label: 'All', value: networkAssetDetail?.process?.all },
    { label: 'Blocked', value: networkAssetDetail?.process?.blocked },
    { label: 'Running', value: networkAssetDetail?.process?.running},
    { label: 'Sleeping', value: networkAssetDetail?.process?.sleeping},
    { label: 'Unknown', value: networkAssetDetail?.process?.unknown},
  ];

  return {
    cpuData,
    systemData,
    operatingSystemData,
    motherboardData,
    storageData,
    memoryData, 
    diskData,
    displayData,
    batteryData,
    biosData,
    networkData,
    portData,
    processData,
    graphicsData
  };
};

export type UseUpcSearchSuggestionReturnType = ReturnType<typeof useDetailInformation>;
export default useDetailInformation;
