import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAssetKitItemsMutationVariables = Types.Exact<{
  input: Types.UpdateAssetKitItemsInput;
}>;


export type UpdateAssetKitItemsMutation = { __typename?: 'Mutation', updateAssetKitItems: { __typename?: 'MutationResponse', success: boolean } };


export const UpdateAssetKitItemsDocument = gql`
    mutation updateAssetKitItems($input: UpdateAssetKitItemsInput!) {
  updateAssetKitItems(input: $input) {
    success
  }
}
    `;

export function useUpdateAssetKitItemsMutation() {
  return Urql.useMutation<UpdateAssetKitItemsMutation, UpdateAssetKitItemsMutationVariables>(UpdateAssetKitItemsDocument);
};