import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import NetworkAssetEdit from 'app/modules/network/view/NetworkAssetEdit';

function NetworkAssetsEditPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <NetworkAssetLayout>
      <NetworkAssetEdit networkAssetId={match.params.id} />
    </NetworkAssetLayout>
  );
}

export default withRouter(NetworkAssetsEditPage);
