import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Location from 'app/i18n/Location';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateLocationInput, LocationTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import AddLocationForm from '../../components/AddLocationForm';
import { useUpdateLocationMutation } from './graphql/mutations/generated/editLocation';
import { useEditLocationDataQuery } from './graphql/queries/generated/editLocationData';
import analytics from 'app/analytics';

type Props = RouteComponentProps & { locationId: string };

function EditLocation(props: Props) {
  const { locationId, history } = props;

  const [{ fetching: disabled }, onUpdateLocation] = useUpdateLocationMutation();
  const [{ data }] = useEditLocationDataQuery({
    variables: { filters: { locationIds: [locationId] } },
    requestPolicy: 'network-only',
  });

  const location = useMemo<CreateLocationInput | null>(() => {
    const locationNode = data?.locations.edges?.[0]?.node;

    if (locationNode) {
      return {
        name: locationNode.name,
        locationCode: locationNode.locationCode != null ? locationNode.locationCode : undefined,
        description: locationNode.formattedDescription ?? '',
        type: LocationTypeEnum.Location,
        siteId: locationNode.siteId,
      };
    }

    return null;
  }, [data?.locations.edges]);

  const onSubmit = useCallback(
    ({ siteId, ...input }: CreateLocationInput) => {
      onUpdateLocation({
        input: {
          ...input,
          locationId,
          type: LocationTypeEnum.Location,
          siteStaffIds: input.siteStaffIds || [],
          siteManagerIds: input.siteManagerIds || [],
        },
      }).then((response) => {
        analytics?.track('Edited', { name: 'Location' });
        if (response.data?.updateLocation.location?.id) {
          SnackbarService.show({ message: 'This location has been successfully updated.' });
          history.replace(routes.Locations());
        }
      });
    },
    [history, onUpdateLocation, locationId],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Locations());
  }, [history]);

  if (!location) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box
        className={cx(
          'w-full space-y-40 md:max-w-[382px] lg:max-w-[382px] lg:max-w-[382px]',
          titlebarStyles,
        )}>
        <TitleBar title={Location.EditLocation} />
        <AddLocationForm
          defaultValues={location}
          disabled={disabled}
          isEditMode={true}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}

export default withRouter(EditLocation);
