import { useContext, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { NotificationContext } from 'app/context/notificationContext';
import { BellNotificationStatusEnum, SortOrderEnum } from 'app/types/schema';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { useBellNotificationsQuery } from '../../../../bellNotifications/graphql/queries/generated/bellNotificationList';

export const useNotifications = (options?: { userId: string }) => {
  const { bellNotificationUpdatedSubscription, bellNotificationCreatedSubscription } =
    useContext(NotificationContext);
  const [notificationList, setNotificationList] = useState<any[]>([]);
  const [after, setAfter] = useState<string | null>(null);
  const [onlyUnreadNotifications, setOnlyUnreadNotifications] = useState<boolean>(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);
  const currentUser = useCurrentUser();

  const [{ data, fetching }] = useBellNotificationsQuery({
    variables: {
      after: after,
      limit: 15,
      filters: {
        userIds: options?.userId ? [options.userId] : [],
        statuses: onlyUnreadNotifications ? [BellNotificationStatusEnum.Unread] : [],
      },
      sorts: [
        {
          sortField: 'createdAt',
          sortOrder: SortOrderEnum.Desc,
        },
      ],
    },
    requestPolicy: 'network-only',
  });

  const [{ data: unReadData }] = useBellNotificationsQuery({
    variables: {
      limit: 0,
      filters: {
        statuses: [BellNotificationStatusEnum.Unread],
        userIds: options?.userId ? [options.userId] : [],
      },
      sorts: [
        {
          sortField: 'createdAt',
          sortOrder: SortOrderEnum.Desc,
        },
      ],
    },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (bellNotificationCreatedSubscription?.bellNotificationByUser) {
      // console.log('Notification: Pubsub: Notification Created.', JSON.stringify(bellNotificationCreatedSubscription.bellNotificationByUser));

      if (
        currentUser?.tenantId !==
        bellNotificationCreatedSubscription.bellNotificationByUser.tenantId
      ) {
        return;
      }

      // If notification is already in list, don't add it again.
      if (
        notificationList.find(
          (item) => item.node.id === bellNotificationCreatedSubscription.bellNotificationByUser.id,
        )
      ) {
        return;
      }

      setNotificationList((prevState) => {
        return removeDuplicateNotifications([
          { node: bellNotificationCreatedSubscription?.bellNotificationByUser },
          ...prevState,
        ]);
      });

      setUnreadNotificationCount((prevState) => prevState + 1);
    }
  }, [bellNotificationCreatedSubscription]);

  useEffect(() => {
    if (bellNotificationUpdatedSubscription?.bellNotificationByUser) {
      // console.log('Notification: Pubsub: Notification UPDATED.', JSON.stringify(bellNotificationUpdatedSubscription.bellNotificationByUser));

      if (
        currentUser?.tenantId !==
        bellNotificationUpdatedSubscription.bellNotificationByUser.tenantId
      ) {
        return;
      }

      if (
        bellNotificationUpdatedSubscription?.bellNotificationByUser?.status ===
        BellNotificationStatusEnum.Read
      ) {
        let currentNotifStatus = notificationList.find(
          (item) =>
            item.node.id === bellNotificationUpdatedSubscription?.bellNotificationByUser?.id,
        )?.node?.status;

        if (currentNotifStatus === BellNotificationStatusEnum.Unread) {
          if (onlyUnreadNotifications) {
            let updated = notificationList.filter((item) => {
              return (
                item.node.id !== bellNotificationUpdatedSubscription?.bellNotificationByUser?.id
              );
            });
            setTimeout(() => {
              setNotificationList(removeDuplicateNotifications(updated));
            }, 300);
          } else {
            setNotificationList((prevState) =>
              removeDuplicateNotifications(
                prevState.map((item) => {
                  if (
                    item.node.id === bellNotificationUpdatedSubscription?.bellNotificationByUser?.id
                  ) {
                    item.node.status = BellNotificationStatusEnum.Read;
                  }
                  return item;
                }),
              ),
            );
          }

          setUnreadNotificationCount((prevState) => prevState - 1);
        }
      }
    }
  }, [bellNotificationUpdatedSubscription]);

  const hasNextPage = useMemo(() => data?.bellNotifications.pageInfo?.hasNextPage || false, [data]);
  useEffect(() => {
    // console.log('Notification: API: Notification List Received.', data?.bellNotifications?.edges?.length);
    setNotificationList((prevState) =>
      removeDuplicateNotifications(
        prevState.concat(data?.bellNotifications?.edges ? data?.bellNotifications?.edges : []),
      ),
    );
  }, [data]);

  const goToNextPage = () => {
    setAfter(data?.bellNotifications.pageInfo?.nextCursor || null);
  };

  const onToggleOnlyUnreadNotifications = () => {
    setOnlyUnreadNotifications((prevState) => !prevState);
    setNotificationList([]);
    setAfter(null);
  };

  useEffect(() => {
    // console.log('Notification: API: Notification Count Received.', JSON.stringify(unReadData?.bellNotifications?.totalCount));
    setUnreadNotificationCount(unReadData?.bellNotifications?.totalCount || 0);
  }, [unReadData]);

  const resetSettings = () => {
    setOnlyUnreadNotifications(false);
    setNotificationList([]);
    setAfter(null);
  };

  const removeDuplicateNotifications = (list: any[]) => {
    return uniqBy(list, (item) => item.node.id);
  };

  return {
    fetching,
    notificationList,
    hasNextPage,
    setAfter,
    onlyUnreadNotifications,
    resetSettings,
    onToggleOnlyUnreadNotifications,
    unreadNotificationCount,
    goToNextPage,
  };
};
