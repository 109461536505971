import './style.css';
import { memo, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import { LocationTableForAssetInventory } from 'app/modules/inventory/components/QuantityBySite';

import { useAccessControl } from '../../../../../../components/AccessControl';
import Assets from '../../../../../../i18n/Assets';
import Common from '../../../../../../i18n/Common';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  TransactionEntityTypeEnum,
} from '../../../../../../types/schema';
import Stack from '../../../../../../ui-components/Stack';
import BulkMoveModal from '../../../../../assets/views/searchAssets/components/BulkMoveModal';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import EntityManager from '../../../../../components/EntityManager';
import { UseMultiSelectEntityManagerReturnType } from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import { isAllStockInformationHaveZeroQuantity } from '../../../../components/QuantityBySite/utils/stockInormation';
import { UseInventoryStateReturnType } from '../../hook/useInventoryState';
import { UseInventoryTableReturnType } from '../../hook/useInventoryTable';
import useQuantityBySiteState from '../../hook/useQuantityBySiteState';
import { getInventoryTableColumns } from '../../utils/columns';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  tableState: UseInventoryTableReturnType['tableState'];
  filters: UseInventoryStateReturnType['filters'];
  state: UseInventoryStateReturnType['state'];
  setState: UseInventoryStateReturnType['setState'];
  showImageViewDialog: UseInventoryStateReturnType['showImageViewDialog'];
  siteId: UseInventoryStateReturnType['siteId'];
  fetching: boolean;
  filterNode?: ReactNode | ReactElement;
  paginationWrapper?: string;
}

const InventoryItemsTable = (props: Props) => {
  const {
    table,
    tableState,
    fetching,
    setState,
    showImageViewDialog,
    filters,
    siteId,
    state,
    filterNode,
    paginationWrapper,
  } = props;
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useForm({ mode: 'all' });
  const formHasErrors = !isEmpty(errors);

  const {
    tableData,
    setEditableSiteState,
    onCancelBulkEdit,
    onBulkEdit,
    onSaveBulkEdit,
    fetchingBulkEdit,
  } = useQuantityBySiteState({
    table,
    tableState,
    siteId,
    setState,
    formHasErrors,
  });

  const { workspacePermissions } = useCurrentUser();

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Inventory,
  );

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Inventory,
  );

  const canBulkMove = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.InventoryMove,
  );

  const isSiteIdSelected = !!siteId;

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    // @ts-ignore
    const rows = tableState.rows.filter((row) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...rows],
    });
  }, [table.state.selectedRowIds, tableState.rows, table.setState]);

  const renderDetailPanelContent = (data: any) => {
    return <LocationTableForAssetInventory row={data.row} siteId={siteId} />;
  };

  const columns = useMemo(() => {
    return getInventoryTableColumns({
      isSiteIdSelected,
      siteId,
      isEditable: state.bulkEditItemModal,
    });
  }, [isSiteIdSelected, siteId, state.bulkEditItemModal]);

  return (
    <div>
      <EntityManager.MultiSelectTable
        key={siteId ? 'inventory-table-with-site-filter' : 'default-inventory-table'}
        ignoreRelayPagination
        pagination
        persistSelectionData
        actions={table.actions}
        columns={columns}
        data={tableData.rows}
        defaultSortState={{
          id: undefined,
        }}
        extraProps={{
          filters,
          setState,
          showImageViewDialog,
          setEditableSiteState,
          isEditable: state.bulkEditItemModal,
          siteId,
          control,
          getFieldState,
        }}
        filterNodes={filterNode}
        getDetailPanelContent={siteId ? renderDetailPanelContent : null}
        getDetailPanelHeight={() => 'auto'}
        loading={tableState.fetching || fetching}
        minWidth={0}
        paginationWrapperClass={paginationWrapper}
        persistKey="inventory_items_table"
        setState={table.setState}
        state={table.state}
        total={tableState.totalCount || 0}
        onNextPage={tableState.onNextPage}
        onPrevPage={tableState.onPrevPage}
        onReset={tableState.onReset}
      />
      <Stack className="mt-8 p-[24px] px-16" justifyContent="end">
        {state.bulkEditItemModal ? (
          <>
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!table.state.selectedRowIds.length || fetchingBulkEdit || formHasErrors}
              loading={fetchingBulkEdit}
              theme="success"
              onClick={onSaveBulkEdit}>
              {Common.Actions.Save}
            </Button>
            <Button classes="!ml-[4px]" onClick={onCancelBulkEdit}>
              {Common.Actions.Cancel}
            </Button>
          </>
        ) : (
          <Box className="flex gap-[16px]">
            {canBulkMove && siteId ? (
              <Button
                classes="min-w-[94px] h-[44px]"
                disabled={!table.state.selectedRowIds.length}
                theme="success"
                onClick={() => {
                  setState({
                    bulkMoveItemModal: true,
                  });
                }}>
                {Assets.Actions.BulkMove}
              </Button>
            ) : null}
            {canDelete && !siteId ? (
              <Button
                classes="min-w-[94px] h-[44px]"
                disabled={!table.state.selectedRowIds.length}
                theme="danger"
                onClick={table.actions.onShowDeleteConfirmation}>
                {Common.Actions.Delete}
              </Button>
            ) : null}
            {canEdit ? (
              <Button
                classes="min-w-[94px] h-[44px]"
                disabled={!table.state.selectedRowIds.length}
                theme="info"
                onClick={onBulkEdit}>
                {Common.Actions.BulkEdit}
              </Button>
            ) : null}
          </Box>
        )}
      </Stack>

      <BulkMoveModal
        open={state.bulkMoveItemModal}
        selectedItems={table.state.selectedRowData}
        siteId={siteId}
        type={TransactionEntityTypeEnum.Inventory}
        onCancel={() => {
          setState({
            bulkMoveItemModal: false,
          });
        }}
      />
    </div>
  );
};

export default memo(InventoryItemsTable);
