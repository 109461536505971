import { useMemo } from 'react';
import { cx } from '@emotion/css';
import { DataGrid } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { getItemLocationItemTypesFromTransactionType } from 'app/modules/reports/utils/itemtypes';
import { hideColumnSelectStyles } from 'app/modules/reports/utils/styles';
import { useAuditsTableQuery } from 'app/modules/reports/views/AuditReport/graphql/queries/generated/auditsTable';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { AuditReportFormValues } from '../../types';
import { getAuditsTableColumns } from './columns';
import { Box } from '@mui/material';

interface Props {
  values: AuditReportFormValues;
  persistKey?: string;
}

const AuditReportTable = (props: Props) => {
  const { values, persistKey } = props;

  const [{ fetching, data }] = useAuditsTableQuery({
    variables: {
      filters: {
        entityTypes: getItemLocationItemTypesFromTransactionType(values.type),
        siteIds: values.siteIds,
        createdByIds: values.userIds,
        createdAt: values.createdAt,
      },
    },
  });

  const rows = useMemo(() => data?.auditTransactionReports || [], [data?.auditTransactionReports]);

  const columns = useMemo(() => {
    const auditReportDetailsRoute = (id: string) => {
      if (values.type === TransactionEntityTypeEnum.Inventory) {
        return routes.InventoryAuditReportDetails(id);
      }

      return routes.AssetAuditReportDetails(id);
    };

    return getAuditsTableColumns({ auditReportDetailsRoute });
  }, [values.type]);

  return (
    <Stack className={cx(hideColumnSelectStyles, 'mt-24')} direction="col">
      <Box className='border mx-[24px] border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
      <DataGrid columns={columns} loading={fetching} persistKey={persistKey} rows={rows} paginationWrapperClass='p-[24px]'/>
      </Box>
    </Stack>
  );
};

export default AuditReportTable;
