import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import { ItemTypeEnum } from 'app/types/schema';

import Common from '../../../../../../i18n/Common';
import Box from '../../../../../../ui-components/Box';
import { UseInventoryFormReturnType } from '../../hook/useInventoryForm';

interface Props {
  formState: UseInventoryFormReturnType['state'];
  disabled: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
}

const SaveCancelActionButtons = (props: Props) => {
  const { formState, disabled, onSubmit, onCancel } = props;
  const { attachmentInputProps, editable, itemId, isItemTypeProduct, cancelEditable } = formState;

  const handleCancel = useCallback(() => {
    if (itemId) {
      cancelEditable();
    } else if (onCancel) {
      onCancel();
    }
  }, [onCancel, itemId, cancelEditable]);

  if (!editable || !isItemTypeProduct) {
    return null;
  }

  return (
    <Box className="mb-20 flex justify-end gap-[16px]">
      <Button
        disabled={disabled || attachmentInputProps.isUploading}
        loading={disabled}
        theme="success"
        onClick={onSubmit}
        classes="min-w-[94px] h-[44px]">
        {Common.Actions.Save}
      </Button>
      <Button onClick={handleCancel} classes="min-w-[94px] h-[44px]">
        {' '}
        {Common.Actions.Cancel}
      </Button>
    </Box>
  );
};

export default SaveCancelActionButtons;
