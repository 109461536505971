import { ReactElement, ReactNode, useState } from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';
import {
  ShippingTransactionSchema,
  ShippingTransactionStatusEnum,
  ValuesInEntitiesSchema,
} from 'app/types/schema';

import EntityManager from '../../../../../components/EntityManager';
import { UseShipmentFiltersFiltersReturnType } from '../../hook/useShipmentFilters';
import DeliveryDetailsDialog from '../DeliveryDetailsDialog';
import ManuallyDeliveryDialog from '../ManuallyDeliveryDialog';

interface Props {
  table: UseEntityManagerReturnType['table'];
  setFilterState: UseShipmentFiltersFiltersReturnType['setFilterState'];
  filtersState: UseShipmentFiltersFiltersReturnType['filtersState'];
  fetching: boolean;
  columns: DataGridHeadCell[];
  rows: any;
  totalCount: number;
  onNextPage?: () => void;
  onPrevPage?: () => void;
  onReset?: () => void;
  filterNodes?: ReactNode | ReactElement;
  paginationWrapperClass?: string;
}

const ShipmentItemTable = (props: Props) => {
  const {
    table,
    rows,
    fetching,
    columns,
    totalCount,
    filtersState,
    setFilterState,
    onNextPage,
    onPrevPage,
    onReset,
    filterNodes,
    paginationWrapperClass,
  } = props;
  const [deliveryDetailsForPopup, setDeliveryDetailsInfoForPopup] = useState<
    ShippingTransactionSchema | undefined
  >();
  const [currentOrderDocumentForDelivery, setCurrentOrderDocumentForDelivery] = useState<
    ShippingTransactionSchema | undefined
  >();
  const [openDeliveryDetailsPopup, setOpenDeliveryDetailsPopup] = useState<boolean>(false);
  const [manuallyDeliveryDialog, setManuallyDeliveryDialog] = useState<boolean>(false);

  const onOpenDeliveryDetails = (deliveryData: ShippingTransactionSchema) => {
    setOpenDeliveryDetailsPopup(true);
    setDeliveryDetailsInfoForPopup(deliveryData);
  };

  const onStatusChange = (row: ShippingTransactionSchema, status: string) => {
    if (status === ShippingTransactionStatusEnum.Delivered) {
      setCurrentOrderDocumentForDelivery(row);
      setManuallyDeliveryDialog(true);
    }
  };

  const onChangeDeliveryDetailsPopup = (dialogValue: boolean) => {
    setOpenDeliveryDetailsPopup(dialogValue);
    if (!dialogValue) {
      setDeliveryDetailsInfoForPopup(undefined);
    }
  };

  const onCloseManuallyDeliveryDialog = (): void => {
    setManuallyDeliveryDialog(false);
    setCurrentOrderDocumentForDelivery(undefined);
  };

  const onContainerDeliveryComplete = async () => {
    setManuallyDeliveryDialog(false);
    setCurrentOrderDocumentForDelivery(undefined);
  };

  return (
    <div>
      <EntityManager.Table
        ignoreRelayPagination
        pagination
        actions={table.actions}
        columns={columns}
        data={rows}
        extraProps={{
          onOpenDeliveryDetails,
          onStatusChange,
          setFilterState,
          filtersState,
        }}
        filterNodes={filterNodes}
        loading={fetching}
        minWidth={0}
        paginationWrapperClass={paginationWrapperClass}
        persistKey="transaction_history_table"
        setState={table.setState}
        state={table.state}
        total={totalCount || 0}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
      />
      <DeliveryDetailsDialog
        deliveryDetails={deliveryDetailsForPopup}
        open={openDeliveryDetailsPopup}
        onChange={onChangeDeliveryDetailsPopup}
      />
      <ManuallyDeliveryDialog
        open={manuallyDeliveryDialog}
        orderDocument={currentOrderDocumentForDelivery}
        onClose={onCloseManuallyDeliveryDialog}
        onContainerDeliveryComplete={onContainerDeliveryComplete}
      />
    </div>
  );
};

export default ShipmentItemTable;
