import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorResponse, FileProgress, SuccessResponse, UppyFile } from '@uppy/core';
import find from 'lodash/find';
import omit from 'lodash/omit';

import {
  fileUrlToBlob,
  getFileNameFromFileUrl,
  getMediaKind,
} from '../components/ProcureForm/FormFileUploader/utils';
import { SnackbarService } from '../components/Snackbar/SnackbarProvider';
import { CreateMediaInput } from '../types/schema';
import getUppyInstance from './getUppyInstance';
import { UseUppyFileType } from './types';

const getPreviewUrl = (file: UppyFile) => {
  if (file.data instanceof File) {
    return URL.createObjectURL(file.data);
  }
  return file.data;
};

interface Props {
  purpose: string;
  id?: string;
  defaultAttachments?: AttachmentType[];
  uploadType?: UploadTypeEnum;
}

export type AttachmentType = CreateMediaInput & {
  id: string | number;
};
export const enum UploadTypeEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

const useUppy = ({ purpose, id, defaultAttachments, uploadType }: Props) => {
  const uppy = useMemo(() => getUppyInstance({ purpose, id: id || 'uppyFilUploader' }), [purpose]);

  const [attachments, setAttachments] = useState<AttachmentType[]>(defaultAttachments || []);
  const [files, setFiles] = useState<Record<string, UseUppyFileType>>({});
  const isUploading = useMemo(() => Boolean(find(files, (file) => !file.url)), [files]);

  const removeFileById = useCallback(
    (fileId: string) => {
      uppy.removeFile(fileId);
      setFiles((previousFiles) => omit(previousFiles, fileId));
      setAttachments((previousAttachments) => {
        return previousAttachments.filter((previousAttachment) => {
          return previousAttachment.id !== fileId;
        });
      });
    },
    [uppy],
  );

  const clearAttachments = useCallback(() => {
    attachments.forEach((attachment) => {
      uppy.removeFile(attachment.id.toString());
    });
    setFiles({});
    setAttachments([]);
  }, [uppy, attachments, setAttachments]);

  const getFileIdsFromUrls = useCallback(
    (urls: string[]) => {
      const deletedFileIds: string[] = [];
      Object.values(files).forEach((file) => {
        if (file?.url && urls.includes(file?.url)) {
          deletedFileIds.push(file.id);
        }
      });
      return deletedFileIds;
    },
    [files],
  );

  const addFileFromUrl = useCallback(
    async (fileUrl: string) => {
      const fileBlob = await fileUrlToBlob(fileUrl);

      if (fileBlob) {
        uppy.addFile({
          data: fileBlob,
          name: getFileNameFromFileUrl(fileUrl),
          type: fileBlob.type,
        });
      } else {
        console.error('[addFileFormUrl] fileBlob is null');
      }
    },
    [uppy],
  );

  const clearAll = useCallback(() => {
    uppy.reset();
    setFiles({});
    setAttachments([]);
  }, [uppy]);

  useEffect(() => {
    const onUploadProgress = (file: UppyFile, progress?: FileProgress) => {
      const { bytesUploaded = 0, bytesTotal = 1 } = progress || {};

      if (uploadType === UploadTypeEnum.SINGLE) {
        setFiles({
          [file.id]: { ...file, fileProgress: Math.round((bytesUploaded * 100) / bytesTotal) },
        });
      } else {
        setFiles((previousFiles) => ({
          ...previousFiles,
          [file.id]: { ...file, fileProgress: Math.round((bytesUploaded * 100) / bytesTotal) },
        }));
      }
    };

    const onFileAdded = (file: UppyFile) => {
      const meta = { ...file.meta, previewBlobUrl: getPreviewUrl(file) };
      uppy.setFileMeta(file.id, meta);

      const attachment = {
        id: file.id,
        kind: getMediaKind(file.type),
        mimeType: file.type,
        url: '',
        size: file.size || 0,
        resolutions: [],
      };
      if (uploadType === UploadTypeEnum.SINGLE) {
        setAttachments([attachment]);
      } else {
        setAttachments((previousAttachments) => [...previousAttachments, attachment]);
      }

      onUploadProgress({ ...file, meta }, file.progress);
    };

    const onUploadSuccess = (file: UppyFile, response: SuccessResponse) => {
      const url = response.uploadURL;
      const updatedFile = { ...file, url: url };
      if (uploadType === UploadTypeEnum.SINGLE) {
        setFiles({ [file.id]: updatedFile });
      } else {
        setFiles((previousFiles) => ({ ...previousFiles, [file.id]: updatedFile }));
      }
      setAttachments((previousAttachments) => {
        return previousAttachments.map((previousAttachment) => {
          if (previousAttachment.id === file.id) {
            return { ...previousAttachment, url: url };
          }

          return { ...previousAttachment };
        });
      });
    };

    const onUploadError = (file: UppyFile, error: Error, response?: ErrorResponse) => {
      removeFileById(file.id);
      SnackbarService.showError({
        type: 'error',
        message: error.message,
      });
      console.error('[useUppy] upload-error', { file, error, response });
    };

    const onError = (error: Error) => {
      console.error('[useUppy] error', error);
    };

    uppy.on('file-added', onFileAdded);
    uppy.on('upload-progress', onUploadProgress);
    uppy.on('upload-success', onUploadSuccess);
    uppy.on('upload-error', onUploadError);
    uppy.on('error', onError);

    return () => {
      uppy.off('file-added', onFileAdded);
      uppy.off('upload-progress', onUploadProgress);
      uppy.off('upload-success', onUploadSuccess);
      uppy.off('upload-error', onUploadError);
      uppy.off('error', onError);
      uppy.close();
    };
  }, [uppy]);

  return {
    uppy,
    isUploading,
    files,
    clearAll,
    removeFileById,
    setFiles,
    attachments,
    setAttachments,
    addFileFromUrl,
    getFileIdsFromUrls,
    clearAttachments,
  };
};

export default useUppy;
