import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormLabel from 'app/components/Form/FormLabel';
import ToggleSwitch from 'app/libs/ToggleSwitch';
import { AffiliateConfigurationStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
}

const AffiliateMerchantStatusField = (props: Props) => {
  const { formState } = props;
  const { control, getValues, setValue } = formState;

  const onToggleStatus = useCallback(() => {
    const on = getValues().status === AffiliateConfigurationStatusEnum.Active;
    setValue('status', on ? AffiliateConfigurationStatusEnum.InActive : AffiliateConfigurationStatusEnum.Active);
  }, [getValues, setValue]);

  return (
    <div className="mb-20">
      <Controller
          control={control}
          name="status"
          render={({ field }) => {
            const on = field.value === AffiliateConfigurationStatusEnum.Active;
            return (
              <Box ref={field.ref} className="flex flex-col">
                <FormLabel>Active</FormLabel>
                <ToggleSwitch className="mt-8" on={on} onClick={onToggleStatus} />
              </Box>
            );
          }}
        />
    </div>
  );
};

export default AffiliateMerchantStatusField;
