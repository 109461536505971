import { Dialog, DialogProps } from '@procurenetworks/procure-component-library';

const Modal = (props: DialogProps) => {
  const {
    id,
    title,
    children,
    actions,
    open,
    fullWidth,
    disable,
    onClose,
    hideCloseIcon,
    disableBackdropClick,
    onScroll,
    dialogType,
    className,
  } = props;

  return (
    <Dialog
      actions={actions}
      dialogType={dialogType as any}
      disable={!!disable}
      disableBackdropClick={!!disableBackdropClick}
      fullWidth={!!fullWidth}
      hideCloseIcon={!!hideCloseIcon}
      id={id}
      open={open}
      title={title || ''}
      onClose={onClose || (() => {})}
      className={className}
      onScroll={onScroll}>
      {children}
    </Dialog>
  );
};

export default Modal;
