import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type CompanyDropDownFragment = { __typename?: 'CompanySchema', companyName: string, id: string };

export const CompanyDropDownFragmentDoc = gql`
    fragment CompanyDropDown on CompanySchema {
  companyName
  id
}
    `;