import { alpha,useTheme } from "@mui/material/styles";
import merge from "lodash/merge";

export default function useChart(options: any) {
  const theme = useTheme();

  const LABEL_TOTAL = {
    show: true,
    label: "Total",
    color: theme.palette.text.secondary,
    fontSize: "14px !important",
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight
  };

  const LABEL_VALUE = {
    offsetY: 15,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight
  };

  const baseOptions = {
    // Colors
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.warning.dark,
      theme.palette.success.dark,
      theme.palette.info.dark,
      theme.palette.info.dark
    ],

    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      foreColor: theme.palette.text.disabled,
      fontFamily: theme.typography.fontFamily
    },

    states: {
      hover: {
        filter: {
          type: "lighten",
          value: 0.04
        }
      },
      active: {
        filter: {
          type: "darken",
          value: 0.88
        }
      }
    },

    fill: {
      opacity: 1,
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.4,
        opacityTo: 0,
        stops: [0, 100]
      }
    },

    dataLabels: { enabled: false },

    stroke: {
      width: 3,
      curve: "smooth",
      lineCap: "round"
    },

    grid: {
      strokeDashArray: 3,
      borderColor: theme.palette.divider
    },

    xaxis: {
      axisBorder: { show: false },
      axisTicks: { show: false }
    },

    markers: {
      size: 0,
      strokeColors: theme.palette.background.paper
    },

    tooltip: {
      x: {
        show: false
      }
    },

    legend: {
      show: true,
      fontSize: String(13),
      position: "top",
      horizontalAlign: "right",
      markers: {
        radius: 12
      },
      fontWeight: 500,
      itemMargin: { horizontal: 12 },
      labels: {
        // colors: theme.palette.text.primary
        colors: '#718096',
        
      }
    },

    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "28%"
      },

      pie: {
        donut: {
          labels: {
            show: true,
            value: LABEL_VALUE,
            total: LABEL_TOTAL
          }
        }
      },

      radialBar: {
        track: {
          strokeWidth: "100%",
          background: alpha(theme.palette.grey[500], 0.16)
        },
        dataLabels: {
          value: LABEL_VALUE,
          total: LABEL_TOTAL
        }
      },

      radar: {
        polygons: {
          fill: { colors: ["transparent"] },
          strokeColors: theme.palette.divider,
          connectorColors: theme.palette.divider
        }
      },

      polarArea: {
        rings: {
          strokeColor: theme.palette.divider
        },
        spokes: {
          connectorColors: theme.palette.divider
        }
      }
    },

    responsive: [
      {
        breakpoint: theme.breakpoints.values.sm,
        options: {
          plotOptions: { bar: { columnWidth: "40%" } }
        }
      },
      {
        breakpoint: theme.breakpoints.values.md,
        options: {
          plotOptions: { bar: { columnWidth: "32%" } }
        }
      }
    ]
  };

  return merge(baseOptions, options);
}
