import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { useNetworkAssetsDetailQuery } from 'app/modules/network/graphql/queries/generated/networkAssetDetail';
import { defaultGridColumn } from 'app/modules/network/utils';
import { NetworkAssetSchema, Note } from 'app/types/schema';

import NetworkAssetFormModal from '../NetworkAssetFormModal';

type Props = {
  NetworkAssetId: string;
} & RouteComponentProps;

const NetworkAssetEditForm = (props: Props) => {
  const { NetworkAssetId, history } = props;
  const [id, setId] = useState<string>(NetworkAssetId);
  const [networkAssetDetail, setNetworkAssetDetail] = useState<NetworkAssetSchema>();
  const [{ fetching, data }] = useNetworkAssetsDetailQuery({
    variables: {
      filters: { networkAssetIds: [id] },
    },
  });

  useEffect(() => {
    setId(NetworkAssetId);
  }, [NetworkAssetId]);

  useEffect(() => {
    if (data) {
      if (data.networkAssets.edges && data.networkAssets.edges.length) {
        const networkAsset = data.networkAssets.edges[0]?.node as NetworkAssetSchema;
        setNetworkAssetDetail(networkAsset);
      } else {
        history.push(routes.Error5XX());
      }
    }
  }, [data]);

  const onCancelHandler = () => {
    history.push(routes.NetworkAssetDetail(id));
  };
  const onSaveHandler = () => {
    history.push(routes.NetworkAssetDetail(id));
  };

  if (fetching) {
    return <Loader />;
  }
  return (
    <div>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Grid container columns={defaultGridColumn} spacing={{ xs: 1, sm: 2, lg: 8 }}>
          <Grid item lg={9} md={8} sm={12} xl={9} xs={12}>
            <Box sx={{ flexGrow: 1, height: '100%', width: '100%' }}>
              <NetworkAssetFormModal
                networkAssetDetails={networkAssetDetail}
                onCancelHandler={onCancelHandler}
                onSaveHandler={onSaveHandler}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default withRouter(NetworkAssetEditForm);
