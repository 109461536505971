import { useCallback, useState } from 'react';

import { ShippingTransactionStatusEnum } from '../../../../../../types/schema';

interface OrderHistoryFilters {
  deliverToIds: string[];
  destinationSiteIds: string[];
  requestorIds: string[];
  departmentIds: string[];
  statuses: ShippingTransactionStatusEnum[];
  search: string;
}

const useShipmentFilters = () => {
  const [filtersState, _setFiltersState] = useState<OrderHistoryFilters>({
    deliverToIds: [],
    requestorIds: [],
    destinationSiteIds: [],
    departmentIds: [],
    statuses: [],
    search: '',
  });

  const setFilterState = useCallback(
    (nextState: Partial<OrderHistoryFilters>) => {
      _setFiltersState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFiltersState],
  );

  return {
    filtersState,
    setFilterState,
  };
};

export type UseShipmentFiltersFiltersReturnType = ReturnType<typeof useShipmentFilters>;

export default useShipmentFilters;
