import { Controller } from 'react-hook-form';
import Shop from 'app/i18n/Shop';
import { imageAttachmentAccepts } from 'app/utils/attachments';

import FormFileUpload from '../../../../../../components/Form/FormFileUpload';
import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';
import { AFFILIATE_MERCHANT_FORM_RULES } from '../utils/utils';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
}

const AffiliateMerchantAttachmentField = (props: Props) => {
  const { formState, disabled } = props;
  const { control, attachmentInputProps } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="logoUrl"
        render={({ field, fieldState }) => (
          <FormFileUpload
            {...field}
            {...attachmentInputProps}
            isRequired
            accept={imageAttachmentAccepts}
            disabled={disabled}
            error={fieldState.error}
            label={Shop.Admin.FormLabel.Logo}
            multiple={false}
          />
        )}
        rules={AFFILIATE_MERCHANT_FORM_RULES.logoUrl}
      />
    </div>
  );
};

export default AffiliateMerchantAttachmentField;
