import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { CategoriesTableRowFragmentDoc } from '../../fragments/generated/CategoriesTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CategoriesTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CategoryFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CategoriesTableQuery = { __typename?: 'Query', categories: { __typename?: 'CategoryConnection', totalCount: number, edges: Array<{ __typename?: 'CategoryEdge', node: { __typename?: 'CategorySchema', id: string, name: string, description?: string | null, categoryCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, categoryManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }> } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const CategoriesTableDocument = gql`
    query categoriesTable($filters: CategoryFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  categories(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...CategoriesTableRow
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    ${CategoriesTableRowFragmentDoc}`;

export function useCategoriesTableQuery(options?: Omit<Urql.UseQueryArgs<CategoriesTableQueryVariables>, 'query'>) {
  return Urql.useQuery<CategoriesTableQuery>({ query: CategoriesTableDocument, ...options });
};