import { composeValidators, passwordValidator, textValidator } from 'app/components/Form/utils/validators';
import SetPassword from 'app/i18n/SetPassword';

export const SET_PASSWORD_RULES = {
  password: {
    validate: composeValidators(
      textValidator(SetPassword.FormValidationMessages.PasswordRequired),
      passwordValidator(SetPassword.FormValidationMessages.InvalidPassword),
    ),
  },
};
