import { useCallback, useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import { AsyncSelectOption } from 'app/components/AsyncMultiSelect/types';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import { getCurrentDateInGMTMinus7 } from 'app/modules/assets/utils/dto';
import OrderFormItemInStock from 'app/modules/assetsInventory/components/FormItemInStock/OrderFormItemInStock';
import useItemInStockWithSearchDialogState from 'app/modules/assetsInventory/components/FormItemInStockWithSearchDialog/hook/useItemInStockWithSearchDialogState';
import ItemsInStockSearchDialog from 'app/modules/assetsInventory/components/ItemInStockSearchDialog';
import FormNumberInput from 'app/modules/components/FormNumberInput';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import OrderItemInStockInput from 'app/modules/orders/views/OrderRequest/components/OrderRequestForm/components/OrderItemInStockField/OrderItemInStockInput';
import { ItemInStock } from 'app/modules/orders/views/OrderRequest/type';
import { ItemTypeEnum } from 'app/types/schema';

import FormDepartmentSelect from '../../../../../../components/Form/FormDepartmentSelect';
import FormTextArea from '../../../../../../components/Form/FormTextArea';
import FormUserSelect from '../../../../../../components/Form/FormUserSelect';
import Assets from '../../../../../../i18n/Assets';
import Common from '../../../../../../i18n/Common';
import { UseReserveStateReturnType } from '../../hook/useReserveState';
import { RESERVE_FORM_RULES } from './utils';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  formState: UseReserveStateReturnType['formState'];
  disabled?: boolean;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  itemInStock?: any;
}

const ReserveForm = (props: Props) => {
  const { formState, disabled: formDisabled, onSubmit, isEdit = false, itemInStock } = props;
  const { control, handleSubmit, setState, setValue, startDate } = formState;

  const disabled = !!formDisabled;
  const { actions: itemInStockDialogActions, state: itemInStockDialogState } =
    useItemInStockWithSearchDialogState();

  const today = useMemo(() => {
    return getCurrentDateInGMTMinus7();
  }, []);

  const minEndDate = useMemo(() => {
    const isCurrentDateGraterThanStartDate = startDate
      ? moment(startDate).isBefore(moment(today))
      : true; // If startDate is not present then current date will be consider greater

    if (isCurrentDateGraterThanStartDate) return moment(today).format('YYYY-MM-DD');
    else return moment(startDate).format('YYYY-MM-DD');
  }, [startDate, today]);

  const onSearchIconClick = useCallback(() => {
    itemInStockDialogActions.onShowItemInStockSearchDialog(itemInStock);
  }, [itemInStockDialogActions, itemInStock]);

  const onAddItemInStock = useCallback(
    (itemInStock: ItemInStock) => {
      setValue('itemInStockId', itemInStock?.id || '', {
        shouldValidate: true,
      });
      // onItemInStockValueChange(itemInStock.id);
      itemInStockDialogActions.onHideItemInStockSearchDialog();
    },
    [itemInStockDialogActions, setValue],
  );

  const formRef = useRef<HTMLDivElement>(null);
  const onError = useCallback(() => {
    ScrollToError(formState.control._formState.errors, formRef)
  }, []);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, onError), [onSubmit, handleSubmit]);

  return (
    <div ref={formRef} className="mt-20">
      <form data-testid="asset-reserve-form" onSubmit={onFormSubmit}>
        <div className="mb-20">
          <Controller
            control={control}
            name="itemInStockId"
            render={({ field, fieldState }) => (
              <>
                <OrderFormItemInStock
                  isRequired
                  disabled={disabled || isEdit}
                  {...field}
                  assetItemsOnly
                  error={fieldState.error}
                  label={Assets.FormLabels.ItemInStock}
                  placeholder="Search Item"
                  renderInput={(params) => (
                    <OrderItemInStockInput
                      name='itemInStockId'
                      autoFocus
                      // getInputReference={setInputRef}
                      inputParams={params}
                      label={Assets.FormLabels.ItemInStock}
                      onSearchIconClick={onSearchIconClick}
                    />
                  )}
                  onChange={(value, option) => {
                    field?.onChange(value);
                  }}
                  className="!mt-0"
                />
              </>
            )}
            rules={RESERVE_FORM_RULES.itemInStockSearch}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="destinationSiteId"
            render={({ field, fieldState }) => (
              <FormSiteSelect
                {...field}
                isRequired
                className="flex-1"
                selectClassName="mt-[6px]"
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.ReserveToSite}
                onChange={(value, option) => {
                  field.onChange(value);
                  setValue('destinationLocationId', value || '');
                  setState({
                    destinationSite: option,
                  });
                }}
              />
            )}
            rules={RESERVE_FORM_RULES.destinationId}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="departmentId"
            render={({ field, fieldState }) => (
              <FormDepartmentSelect
                {...field}
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.ReserveDepartment}
                onChange={(values: string, option: AsyncSelectOption): void => {
                  field.onChange(values);
                  setState({
                    department: option,
                  });
                }}
              />
            )}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="actorId"
            render={({ field, fieldState }) => (
              <FormUserSelect
                {...field}
                isRequired
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.ReserveUser}
                name="actorId"
                onChange={(valueId: string, value: any) => {
                  field.onChange(valueId);
                  setState({
                    user: value,
                  });
                }}
              />
            )}
            rules={RESERVE_FORM_RULES.actorId}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => {
              return (
                <FormDatePickerInput
                  {...field}
                  isRequired
                  disabled={disabled}
                  error={fieldState.error}
                  label={Assets.FormLabels.StartDate}
                  minDate={today}
                  size="medium"
                  classes="mt-[6px]"
                />
              );
            }}
            // @ts-ignore
            rules={RESERVE_FORM_RULES.startDate}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="endDate"
            render={({ field, fieldState }) => {
              return (
                <FormDatePickerInput
                  {...field}
                  // clearable
                  disabled={disabled}
                  error={fieldState.error}
                  label={Assets.FormLabels.EndDate}
                  minDate={minEndDate}
                  size="medium"
                  classes="mt-[6px]"
                />
              );
            }}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="meta.note"
            render={({ field, fieldState }) => (
              <FormTextArea
                {...field}
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.Note}
              />
            )}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                error={fieldState.error}
                label={Assets.FormLabels.Quantity}
                maximumLength={9}
              />
            )}
            // @ts-ignore
            rules={RESERVE_FORM_RULES.quantity}
          />
        </div>

        {!isEdit && (
          <div className="flex">
            <div className="flex flex-auto justify-end">
              <Button
                classes={'!px-[30px] min-w-[94px] h-[44px]'}
                theme="success"
                onClick={onFormSubmit}>
                {Common.Actions.Add}
              </Button>
            </div>
          </div>
        )}
      </form>
      <ItemsInStockSearchDialog
        actions={itemInStockDialogActions}
        confirmButtonText={Common.Actions.Add}
        state={itemInStockDialogState}
        types={[ItemTypeEnum.Asset, ItemTypeEnum.AssetKit]}
        onClose={itemInStockDialogActions.onHideItemInStockSearchDialog}
        onConfirm={onAddItemInStock}
      />
    </div>
  );
};

export default ReserveForm;
