import { cx } from '@emotion/css';
import { Content, TitleBar } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import Box from 'app/ui-components/Box';
import PageTitle from 'app/ui-components/Title';

import Profile from '../../../../i18n/Profile';
import ProfileForm from './components/ProfileForm';

function ProfilePage(props: RouteComponentProps) {
  return (
    // <Box className="bg-white px-48 pt-8 pb-16">
    <EntityManager>
      {/*<Box className={cx('mx-auto space-y-40', titlebarStyles)}>*/}
      <EntityManager.Title documentTitle={Profile.ProfileTitle} title={Profile.ProfileTitle} />
      <Content>
        <ProfileForm />
      </Content>
      {/*</Box>*/}
    </EntityManager>
    // {/*</Box>*/}
  );
}

export default withRouter(ProfilePage);
