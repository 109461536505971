import { FormState, LabelQrCodeOptionEnum } from '../../Labels/components/Forms/types';
import { CodeSchema } from '../components/types';

const getFieldList = (
  codes: FormState & {
    data: Array<CodeSchema>;
  },
  option: string,
) => {
  switch (option) {
    case LabelQrCodeOptionEnum.Multi:
      return [
        codes.firstField,
        codes.secondField,
        codes.thirdField,
        codes.custom,
        codes.labelFormat,
        codes.labelStock
      ];
    case LabelQrCodeOptionEnum.Single:
      return [codes.firstField, codes.custom, codes.labelFormat, codes.labelStock];
    default:
      break;
  }
};

export default getFieldList;
