import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInventoryItemMutationVariables = Types.Exact<{
  input: Types.UpdateInventoryItemInput;
}>;


export type UpdateInventoryItemMutation = { __typename?: 'Mutation', updateInventoryItem: { __typename?: 'UpdateInventoryItemPayload', success: boolean, reason?: string | null, inventoryItem?: { __typename?: 'InventoryItemSchema', id: string, brand?: string | null, categoryId: string, cost?: number | null, costOverride?: number | null, description?: string | null, title: string, type: Types.ItemTypeEnum, vendorId?: string | null, model?: string | null, sku: string, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null }> | null, externalProductCodes?: Array<{ __typename?: 'ItemExternalProductCodeSchema', type: Types.ItemExternalProductCodeTypeEnum, code: string }> | null } | null } };


export const UpdateInventoryItemDocument = gql`
    mutation updateInventoryItem($input: UpdateInventoryItemInput!) {
  updateInventoryItem(input: $input) {
    success
    reason
    inventoryItem {
      id
      attachments {
        size
        url
        mimeType
      }
      brand
      categoryId
      cost
      costOverride
      description
      externalProductCodes {
        type
        code
      }
      brand
      title
      type
      vendorId
      type
      model
      sku
    }
  }
}
    `;

export function useUpdateInventoryItemMutation() {
  return Urql.useMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>(UpdateInventoryItemDocument);
};