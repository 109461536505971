import { useState } from 'react';
import AdjustIcon from '@mui/icons-material/Adjust';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Divider, Link, Tooltip } from '@mui/material';
import moment from 'moment';
import { BellNotificationStatusEnum } from 'app/types/schema';

const NotificationItem = ({
  notification,
  onMarkNotificationRead,
}: {
  notification: any;
  onMarkNotificationRead: any;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const formatDate = (date: string) => {
    return moment(date).fromNow();
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onMarkReadButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onMarkNotificationRead(notification);
  };

  const shouldOpenInNewTab = (url: string) => {
    if (!url) return false;
    return url.includes('open_in_new_tab=1');
  }

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    onMarkNotificationRead(notification);

    if (!notification.webClickUrl) return;

    let openInNewTab = shouldOpenInNewTab(notification.webClickUrl);
    window.open(notification.webClickUrl, openInNewTab ? '_blank' : '_self');
  };

  return (
    <div
      className="mt-[12px] flex cursor-pointer flex-col"
      onClick={handleNotificationClick}
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}>
      <div className="flex  w-full items-center justify-between">
        <span>{notification.title}</span>
        {notification.status === BellNotificationStatusEnum.Unread && (
          <div className="relative h-20 w-20">
            <Tooltip className="absolute right-2 top-[-5px]" placement="left" title="Mark as read">
              <div
                className={isHovering ? 'opacity-100' : ' opacity-0'}
                onClick={onMarkReadButtonClick}>
                <AdjustIcon sx={{ fontSize: '18px', color: '#FFA602' }} />
              </div>
            </Tooltip>
            {isHovering === false && (
              <div className="absolute right-2 top-[-5px]">
                <FiberManualRecordIcon sx={{ fontSize: '12px', color: '#FFA602' }} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-[8px]">
        <p className="leading-5 m-0 text-[14px] font-medium text-grey-800">
          {notification.description}
        </p>
      </div>
      <div>
        <div className="mt-[12px] flex items-center justify-between">
          <span className="text-[14px] font-normal text-gray-700">
            {formatDate(notification.createdAt)}
          </span>
          {notification.showButton && (
            <Link
              className="text-Blue-900 text-[12px] font-normal underline "
              href={notification.buttonWebUrl}
              target={shouldOpenInNewTab(notification.buttonWebUrl) ? '_blank' : '_self'}
              underline="none">
              {notification.buttonLabel}
            </Link>
          )}
        </div>
      </div>
      <Divider className="mt-[12px] bg-grey-300" />
    </div>
  );
};

export default NotificationItem;
