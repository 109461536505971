import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportDepartmentsSelect from 'app/modules/reports/components/ReportDepartmentsSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportProjectsSelect from 'app/modules/reports/components/ReportProjectsSelect';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportUsersSelect from 'app/modules/reports/components/ReportUsersSelect';
import SortOrderSelectorFormField from 'app/modules/reports/components/SortOrderSelector';
import TransactionTypeFormField from 'app/modules/reports/components/TransactionTypeFormField';
import { LocationTypeEnum, SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import {
  getAssetTransactionEntityTypes,
  getItemLocationItemTypesFromTransactionTypes,
} from '../../../../utils/itemtypes';
import AccountingReportDateFilter from '../AccountingReportDateFilter';
import { getAccountingDateFilterOptions } from '../AccountingReportDateFilter/utils';
import AccountingReportTypeSelector from '../AccountingReportTypeSelector';
import { AccountingReportFormProps, AccountingReportFormValues } from './types';
import { REPORT_TYPES } from 'app/modules/reports/components/TransactionTypeFormField/utils';

const defaultValues: AccountingReportFormValues = {
  types: [TransactionEntityTypeEnum.Asset],
  categoryIds: [],
  departmentIds: [],
  projectIds: [],
  siteIds: [],
  transactionTypes: [],
  userIds: [],
  format: 'preview',
  createdAt: getAccountingDateFilterOptions()[0].createdAt,
  sortOrder: SortOrderEnum.Asc,
  itemTypes: getAssetTransactionEntityTypes(),
};

const TransactionReportForm = (props: AccountingReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const types = watch('types');

  useEffect(() => {
    setValue('itemTypes', getItemLocationItemTypesFromTransactionTypes(types));
  }, [types]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (types: TransactionEntityTypeEnum[]) => {
      reset({ ...defaultValues, types: types });
    },
    [reset],
  );

  return (
    <form
      className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
      onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <AccountingReportTypeSelector control={control} onChange={onTypeChange} />
        <ReportCategoriesSelect control={control} />
        <ReportSitesSelect
          control={control}
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <ReportDepartmentsSelect control={control} />
        <ReportProjectsSelect control={control} />
        <ReportUsersSelect control={control} />
        <AccountingReportDateFilter control={control} />
        <SortOrderSelectorFormField control={control} />
        <TransactionTypeFormField
          validate
          control={control}
          reportType={REPORT_TYPES.ACCOUNT}
          types={types}
        />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default TransactionReportForm;
