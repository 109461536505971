import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssetModelTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AssetModelItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AssetModelTableQuery = { __typename?: 'Query', assetModelItems: { __typename?: 'AssetModelItemConnection', totalCount: number, edges: Array<{ __typename?: 'AssetModelItemEdge', node: { __typename?: 'AssetModelItemSchema', id: string, sku: string, brand?: string | null, title: string, unitCost?: number | null, costOverride?: number | null, mName: string, modelNumber?: string | null, description?: string | null, cost?: number | null, manufacturerId?: string | null, category?: { __typename?: 'CategorySchema', name: string } | null, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null }> | null, vendor?: { __typename?: 'CompanySchema', companyName: string } | null, manufacturer?: { __typename?: 'ManufacturerSchema', name: string, status: string } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const AssetModelTableDocument = gql`
    query assetModelTable($filters: AssetModelItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  assetModelItems(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        sku
        category {
          name
        }
        brand
        title
        unitCost
        costOverride
        attachments {
          size
          url
          mimeType
        }
        vendor {
          companyName
        }
        mName
        modelNumber
        description
        cost
        manufacturerId
        manufacturer {
          name
          status
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useAssetModelTableQuery(options?: Omit<Urql.UseQueryArgs<AssetModelTableQueryVariables>, 'query'>) {
  return Urql.useQuery<AssetModelTableQuery>({ query: AssetModelTableDocument, ...options });
};