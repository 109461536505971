import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletedInventoryDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryItemFilters>;
}>;


export type DeletedInventoryDataQuery = { __typename?: 'Query', inventoryItems: { __typename?: 'InventoryItemConnection', totalCount: number, edges: Array<{ __typename?: 'InventoryItemEdge', node: { __typename?: 'InventoryItemSchema', id: string, brand?: string | null, unitCost?: number | null, costOverride?: number | null, cost?: number | null, description?: string | null, formattedDescription?: string | null, title: string, type: Types.ItemTypeEnum, model?: string | null, sku: string, totalQuantity?: number | null, pickableThroughOrderRequest?: boolean | null, manufacturerId?: string | null, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null, kind: Types.MediaKindEnum, resolutions: Array<{ __typename?: 'ResolutionType', type: Types.ResolutionEnum, url: string }> }> | null, category?: { __typename?: 'CategorySchema', id: string, status: Types.CategoryStatusEnum } | null, vendor?: { __typename?: 'CompanySchema', id: string, status: Types.CompanyStatusEnum } | null, externalProductCodes?: Array<{ __typename?: 'ItemExternalProductCodeSchema', type: Types.ItemExternalProductCodeTypeEnum, code: string }> | null, manufacturer?: { __typename?: 'ManufacturerSchema', name: string, status: string } | null, stockInformation?: Array<{ __typename?: 'ItemLocationSchema', id: string, totalQuantity?: number | null, outOfStockAt?: string | null, availableQuantity?: number | null, totalQuantityFromLocations?: number | null, minimumQuantity?: number | null, maximumQuantity?: number | null, site: { __typename?: 'LocationSchema', id: string, name: string, siteId: string }, location: { __typename?: 'LocationSchema', id: string, name: string, siteId: string, type: Types.LocationTypeEnum }, lockedQuantities: { __typename?: 'LockedQuantitiesSchema', reservedQuantity?: number | null, checkoutQuantity?: number | null, inTransitQuantity?: number | null } }> | null } }> } };


export const DeletedInventoryDataDocument = gql`
    query deletedInventoryData($filters: InventoryItemFilters) {
  inventoryItems(filters: $filters) {
    totalCount
    edges {
      node {
        id
        attachments {
          size
          url
          mimeType
          kind
          resolutions {
            type
            url
          }
        }
        brand
        category {
          id
          status
        }
        vendor {
          id
          status
        }
        unitCost
        costOverride
        cost
        description
        formattedDescription
        externalProductCodes {
          type
          code
        }
        brand
        title
        type
        type
        model
        sku
        totalQuantity
        pickableThroughOrderRequest
        manufacturerId
        manufacturer {
          name
          status
        }
        stockInformation {
          id
          totalQuantity
          outOfStockAt
          availableQuantity
          totalQuantityFromLocations
          site {
            id
            name
            siteId
          }
          location {
            id
            name
            siteId
            type
          }
          minimumQuantity
          maximumQuantity
          lockedQuantities {
            reservedQuantity
            checkoutQuantity
            inTransitQuantity
          }
        }
      }
    }
  }
}
    `;

export function useDeletedInventoryDataQuery(options?: Omit<Urql.UseQueryArgs<DeletedInventoryDataQueryVariables>, 'query'>) {
  return Urql.useQuery<DeletedInventoryDataQuery>({ query: DeletedInventoryDataDocument, ...options });
};