import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateQuantityConfigurationsAtItemLocationsMutationVariables = Types.Exact<{
  input: Array<Types.UpdateQuantityConfigurationsAtItemLocationInput> | Types.UpdateQuantityConfigurationsAtItemLocationInput;
}>;


export type UpdateQuantityConfigurationsAtItemLocationsMutation = { __typename?: 'Mutation', updateQuantityConfigurationsAtItemLocations: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const UpdateQuantityConfigurationsAtItemLocationsDocument = gql`
    mutation updateQuantityConfigurationsAtItemLocations($input: [UpdateQuantityConfigurationsAtItemLocationInput!]!) {
  updateQuantityConfigurationsAtItemLocations(input: $input) {
    success
    reason
  }
}
    `;

export function useUpdateQuantityConfigurationsAtItemLocationsMutation() {
  return Urql.useMutation<UpdateQuantityConfigurationsAtItemLocationsMutation, UpdateQuantityConfigurationsAtItemLocationsMutationVariables>(UpdateQuantityConfigurationsAtItemLocationsDocument);
};