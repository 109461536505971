import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Department from 'app/i18n/Department';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateDepartmentInput } from 'app/types/schema';
import Box from 'app/ui-components';

import DepartmentForm from '../../components/DepartmentForm';
import { useCreateDepartmentMutation } from './graphql/mutations/generated/addDepartment';
import analytics from 'app/analytics';

const DEFAULT_VALUES: CreateDepartmentInput = {
  name: '',
  departmentCode: '',
  description: '',
};

function AddDepartment({ history }: RouteComponentProps) {
  const [{ fetching }, createDepartment] = useCreateDepartmentMutation();

  const onSubmit = useCallback(
    ({ departmentCode, description, name, formattedDescription }: CreateDepartmentInput) => {
      createDepartment({ input: { departmentCode, description, name, formattedDescription } }).then(
        (response) => {
          analytics?.track('Created', { name: 'Department' });
          if (response.data?.createDepartment.department?.id) {
            SnackbarService.show({ message: 'This department has been successfully created.' });
            history.replace(routes.Departments());
          }
        },
      );
    },
    [createDepartment, history],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Departments());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Department.NewDepartment} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <DepartmentForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default withRouter(AddDepartment);
