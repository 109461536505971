import EntityManager from 'app/modules/components/EntityManager';

import OrderDetailView from './components/OrderDetailView';
import { useOrderDetailsQuery } from './graphql/query/generated/orderDetails';
import Loader from '../../../../components/Loader';
import { useMemo } from 'react';
import { Redirect } from 'app/libs/navigation';
import routes from '../../../../consts/routes';
import { OrderDetailsData } from './type';
import WarningProvider from '../../../../components/WarningAlert';
import { OrderDetailContextProvider } from '../../provider/OrderDetailsContext';
import { OrderRequestTypeEnum } from '../../../../types/schema';
import _ from 'lodash';
import { isAssetInventoryTypeItem } from './hook/useOrderRequestItems/utils';
import { useOrderItemInStockSelectQuery } from '../../../assetsInventory/graphql/query/generated/orderItemInStockSelect';
import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { ItemInStock } from '../OrderRequest/type';
import { parseOrderDetailsByOrderView } from './utils';

interface Props {
  orderId: string;
  render?: (orderDetail: any) => React.ReactNode;
}

const OrderDetail = (props: Props) => {
  const { orderId, render } = props;

  const [{ fetching, data }] = useOrderDetailsQuery({
    variables: {
      filters: {
        orderRequestIds: [orderId],
      },
    },
  });

  const orderDetails: OrderDetailsData | undefined = useMemo(() => {
    return data?.orderRequests?.edges?.[0]?.node as OrderDetailsData | undefined;
  }, [data?.orderRequests?.edges]);

  /***
   * We are fetching Items if Order is external and View from child side, because in update payload, we need to pass categoryIdOfItemInParentTenant.
   * Due to our current architect, It is not possible that's why.
   * */
  const itemIds = useMemo(() => {
    const itemIds: string[] = [];
    if (orderDetails && orderDetails.type === OrderRequestTypeEnum.External) {
      if (Boolean(orderDetails.childTenantId)) {
        return itemIds;
      }

      const orderRequestItems = orderDetails?.orderRequestItems;

      _.forEach(orderRequestItems, (orderRequestItem) => {
        const { type, item, itemIdInPartnerTenant } = orderRequestItem;
        if (isAssetInventoryTypeItem(type)) {
          itemIds.push(itemIdInPartnerTenant as string);
        }
      });
    }

    return itemIds;
  }, [orderDetails]);

  const [{ data: itemsData, fetching: fetchingItems }] = useOrderItemInStockSelectQuery({
    variables: {
      filters: {
        itemIds: itemIds,
        partnerTenantId: orderDetails?.parentTenantId,
      },
    },
    pause: itemIds.length === 0,
  });

  const itemInStockItems: ItemInStock[] = useMemo<ItemInStock[]>(() => {
    return (itemsData?.items?.edges?.map(adaptNodeEdgeToNode) as ItemInStock[]) || [];
  }, [itemsData?.items?.edges]);

  const parsedOrderDetailsByOrderView = useMemo(() => {
    if (!orderDetails || fetching) {
      return;
    }
    return parseOrderDetailsByOrderView(orderDetails, itemInStockItems);
  }, [orderDetails, itemInStockItems, fetchingItems, fetching]);

  if (fetching) {
    return <Loader />;
  }

  // Check Order Type and redirect based on order Type,
  if (!parsedOrderDetailsByOrderView) {
    return <Redirect to={routes.OrderHistory()} />;
  }

  return (
    <EntityManager>
      <OrderDetailContextProvider orderDetails={parsedOrderDetailsByOrderView}>
        <WarningProvider>
          <OrderDetailView
            orderDetail={parsedOrderDetailsByOrderView}
            itemInStockItems={itemInStockItems}
          />
        </WarningProvider>
      </OrderDetailContextProvider>
    </EntityManager>
  );
};

export default OrderDetail;
