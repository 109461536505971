import Schedule from 'app/modules/receiving/view/schedule';

import ReceivingLayout from '../../modules/receiving/layout';

function ReceivingSchedule() {
  return (
    <ReceivingLayout>
      <Schedule />
    </ReceivingLayout>
  );
}

export default ReceivingSchedule;
