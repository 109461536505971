import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type VerifyWishlistCollaborateInvitationMutationVariables = Types.Exact<{
  input: Types.VerifyWishlistCollaborationInput;
}>;


export type VerifyWishlistCollaborateInvitationMutation = { __typename?: 'Mutation', verifyWishlistCollaborateInvitation: { __typename?: 'MutationResponse', success: boolean } };


export const VerifyWishlistCollaborateInvitationDocument = gql`
    mutation verifyWishlistCollaborateInvitation($input: VerifyWishlistCollaborationInput!) {
  verifyWishlistCollaborateInvitation(input: $input) {
    success
  }
}
    `;

export function useVerifyWishlistCollaborateInvitationMutation() {
  return Urql.useMutation<VerifyWishlistCollaborateInvitationMutation, VerifyWishlistCollaborateInvitationMutationVariables>(VerifyWishlistCollaborateInvitationDocument);
};