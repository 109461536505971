import React from 'react';
import { Button, Dialog, DialogType } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components';

type UnpackConfirmationDialogProps = {
  id: string;
  open: boolean;
  onConfirmUnpack: Function;
  onCancelUnpack: Function;
};

const UnpackConfirmationDialog: React.FC<UnpackConfirmationDialogProps> = (
  props: UnpackConfirmationDialogProps,
) => {
  const { id, open, onConfirmUnpack, onCancelUnpack } = props;
  return (
    <div>
      <Dialog
        hideCloseIcon
        actions={
          <>
            <Button
              theme="success"
              onClick={() => {
                onConfirmUnpack();
              }}>
              Yes
            </Button>
            <Button
              theme="danger"
              onClick={() => {
                onCancelUnpack();
              }}>
              No
            </Button>
          </>
        }
        dialogType={DialogType.CONFIRMATION}
        id={id}
        open={open}
        title="Warning!"
        onClose={() => {
          onCancelUnpack();
        }}>
        <Box className={'mb-[20px] mt-[24px] max-w-[620px]'}>
          <p className="text-[16px] text-grey-800 ">
            By clicking “Unpack”, all containers will be removed from this container and will be
            listed separately in Shipment Search and Container Search tabs. <br />
            <br /> Any individual orders not associated with a container would be restocked to the
            source location. <br />
            <br />
            Would you like to continue with “Unpack”?
          </p>
        </Box>
      </Dialog>
    </div>
  );
};

export default UnpackConfirmationDialog;
