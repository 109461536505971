import {
  composeValidators,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Department from 'app/i18n/Department';

export const DEPARTMENT_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Department.FormValidationMessages.NameRequired),
      textMinLengthValidator(Department.FormValidationMessages.NameRequired, 3),
      textMaxLengthValidator(Department.FormValidationMessages.NameRequired, 30),
    ),
  },
  description: {
    validate: textMaxLengthValidator(Department.FormValidationMessages.DescriptionMaxLength, 500),
  },
};
