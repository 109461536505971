import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnreserveTransactionsMutationVariables = Types.Exact<{
  input: Types.UnreserveTransactionsInput;
}>;


export type UnreserveTransactionsMutation = { __typename?: 'Mutation', unreserveTransactions: { __typename?: 'MutationResponse', success: boolean, reasons?: Array<string> | null } };


export const UnreserveTransactionsDocument = gql`
    mutation unreserveTransactions($input: UnreserveTransactionsInput!) {
  unreserveTransactions(input: $input) {
    success
    reasons
  }
}
    `;

export function useUnreserveTransactionsMutation() {
  return Urql.useMutation<UnreserveTransactionsMutation, UnreserveTransactionsMutationVariables>(UnreserveTransactionsDocument);
};