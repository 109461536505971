import { useState } from 'react';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';

import AuditReportForm from './components/AuditReportForm';
import AuditReportTable from './components/AuditReportTable';
import { AuditReportFormValues } from './types';

const AuditReport = () => {
  const [values, setValues] = useState<AuditReportFormValues | null>(null);

  return (
    <ReportContainer
      title={Reports.AuditReport}
      stackWrapperClass="rounded-tr-[12px] rounded-br-[12px]">
      <AuditReportForm onSubmit={setValues} />
      {values ? <AuditReportTable persistKey="audit_report_table" values={values} /> : null}
    </ReportContainer>
  );
};

export default AuditReport;
