import React, { useState } from 'react';
import {
  Button,
  Dialog,
} from '@procurenetworks/procure-component-library';
import FormUserSelect from 'app/components/Form/FormUserSelect';
import { SnackbarService } from 'app/components/Snackbar';
import Ship from 'app/i18n/Ship';
import {
  ShippingTransactionDeliveryTypeEnum,
  ShippingTransactionSchema,
} from 'app/types/schema';
import Box from 'app/ui-components';

import { useMarkContainerAsDeliveredMutation } from '../../graphql/mutations/generated/markContainerAsDelivered';

interface ManuallyDeliveryDialogProps {
  open: boolean;
  onClose: () => void;
  orderDocument: ShippingTransactionSchema | undefined;
  onContainerDeliveryComplete: () => void;
}

const ManuallyDeliveryDialog: React.FC<ManuallyDeliveryDialogProps> = (props) => {
  const { open, onClose, orderDocument, onContainerDeliveryComplete } = props;

  const [userId, setUserId] = useState<string>('');
  const [{ fetching: markingContainer }, onMarkContainerAsDelivered] =
    useMarkContainerAsDeliveredMutation();

  const onMarkDeliver = async () => {
    let dataItem = {
      containerIds: [orderDocument?.containerId as string] || [],
      deliveryType: ShippingTransactionDeliveryTypeEnum.Delivered,
      deliveryVerificationRecord: {
        userId: userId,
      },
    };
    onMarkContainerAsDelivered({ input: dataItem }).then((res) => {
      if (res?.data?.markShippingTransactionsOfContainerIdsAsDelivered?.success) {
        SnackbarService.show({
          message: Ship.SuccessMessages.MarkDelivered,
        });
      }
      onContainerDeliveryComplete();
    });
  };

  const onUserChange = (value: any) => {
    setUserId(value);
  };

  const onCloseManuallyDelivery = () => {
    setUserId('');
    onClose();
  };

  return (
    <Dialog
      actions={
        <Box className={'flex justify-end'}>
          <div>
            <Button
              disabled={!Boolean(userId)}
              loading={markingContainer}
              theme="success"
              onClick={() => onMarkDeliver()}>
              Mark Delivered
            </Button>
          </div>
          <Box className={'ml-[4px]'}>
            <Button classes="margin-left-1" onClick={onCloseManuallyDelivery}>
              Cancel
            </Button>
          </Box>
        </Box>
      }
      id="manually-delivery"
      open={open}
      title="Deliver Order"
      onClose={onCloseManuallyDelivery}>
      <Box className={'mt-[7px] w-[400px]'}>
        <FormUserSelect name={'deliveryPerson'} placeholder='Deliver To User' onChange={onUserChange} />
      </Box>
    </Dialog>
  );
};

export default ManuallyDeliveryDialog;
