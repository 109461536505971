import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ContactsFragmentDoc } from '../../fragments/generated/contacts';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactListQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ContactFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactListQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactConnection', totalCount: number, edges: Array<{ __typename?: 'ContactEdge', node: { __typename?: 'ContactSchema', createdAt: string, updatedAt: string, addressId: string, companyId: string, firstName: string, lastName: string, companySqlId?: number | null, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null, tenantId: string, createdById: string, deletedById?: string | null, updatedById: string, deletedAt?: string | null, id: string } }> } };


export const ContactListDocument = gql`
    query contactList($filters: ContactFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  contacts(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    edges {
      node {
        ...Contacts
      }
    }
  }
}
    ${ContactsFragmentDoc}`;

export function useContactListQuery(options?: Omit<Urql.UseQueryArgs<ContactListQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactListQuery>({ query: ContactListDocument, ...options });
};