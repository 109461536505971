import { useMemo } from 'react';
import { useNetworkAssetsTableQuery } from 'app/modules/network/graphql/queries/generated/networkAssetTable';

import usePagination from '../../../../../../hooks/usePagination';
import { NetworkAssetsFilters, SortProps } from '../../../../../../types/schema';

interface Props {
  filters: NetworkAssetsFilters;
  sorts: SortProps[];
  numberOfRowsPerPage: number;
  pause: boolean;
}

const useNetworkAssetTableRows = (props: Props) => {
  const { filters, pause, numberOfRowsPerPage, sorts} = props;
  if (filters.search) {
    filters.search = `${filters.search}`; 
  }
  // NetworkAssetItems Query
  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useNetworkAssetsTableQuery,
    {
      filters,
      sorts
    },
    {
      edgeKey: 'networkAssets',
      pageSize: numberOfRowsPerPage,
      pause: pause
    },
  );

  const networkAssetTableRows = useMemo(() => {
    return data?.networkAssets.edges?.map(({ node }) => node) || [];
  }, [data?.networkAssets.edges]);

  return {
    rows: networkAssetTableRows,
    fetching,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.networkAssets.totalCount || 0,
  };
};

export default useNetworkAssetTableRows