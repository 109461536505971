import Inventories from "app/modules/inventory/views/Inventory";
import InventoriesLayout from "../../modules/inventory/layout";

function InventoryPage() {
    return (
        <InventoriesLayout>
            <Inventories/>
        </InventoriesLayout>
    )
}

export default InventoryPage;