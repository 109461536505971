import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAuditTransactionMutationVariables = Types.Exact<{
  input: Types.CreateAuditTransactionsInput;
}>;


export type CreateAuditTransactionMutation = { __typename?: 'Mutation', createAuditTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, reason?: string | null } };


export const CreateAuditTransactionDocument = gql`
    mutation createAuditTransaction($input: CreateAuditTransactionsInput!) {
  createAuditTransactions(input: $input) {
    success
    reason
  }
}
    `;

export function useCreateAuditTransactionMutation() {
  return Urql.useMutation<CreateAuditTransactionMutation, CreateAuditTransactionMutationVariables>(CreateAuditTransactionDocument);
};