import { useCallback } from 'react';
import Modal from 'app/components/Modal';
import { SnackbarService } from 'app/components/Snackbar';
import Assets from 'app/i18n/Assets';

import { DEFAULT_VALUES, UseAssetReminderStateReturnType } from '../../hook/useAssetReminderState';
import { AddReminderDialog } from '../../styles';
import AssetReminderForm from '../AssetReminderForm';
import { AssetReminderInput } from '../AssetReminderForm/types';

interface Props {
  state: UseAssetReminderStateReturnType['state'];
  setState: UseAssetReminderStateReturnType['setState'];
  assetItemId?: string;
}

const AssetReminderDialog = (props: Props) => {
  const { state, setState ,assetItemId} = props;

  const onSubmit = useCallback(
    (values: AssetReminderInput) => {
      (values as any).id = new Date().getTime();
      (values as any).isNew = true;
      const reminderList = [...state.reminderList]?.filter((reminder: any) => !reminder?.mode);
      setState({
        reminderList : [...reminderList, values],
        reminders: [...state.reminders, values],
        openAddReminder: false,
      });
      SnackbarService.show({
        message: Assets.SuccessMessages.Reminder.AssetReminderCreated
      })
    },
    [setState, state.reminderList, state.reminders],
  );

  const handleCancel = useCallback(() => {
    setState({
      openAddReminder: false,
    });
  }, [setState]);

  return (
    <Modal
      className={AddReminderDialog}
      id="add-reminder-dialog"
      open={state.openAddReminder}
      title="Add Reminder"
      onClose={handleCancel}>
        <AssetReminderForm
          defaultValues={{...DEFAULT_VALUES, assetItemId}}
          mode="add"
          setState={setState}
          state={state}
          onSubmit={onSubmit}
        />
    </Modal>
  );
};

export default AssetReminderDialog;
