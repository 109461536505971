import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';

const OrderRequestorEmailField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, isRequired } = props;
  const { control } = formState;
  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            disabled
            isRequired={isRequired}
            label={Orders.FormLabels.Email}
            formLabel={Orders.FormLabels.Email}
          />
        )}
      />
    </div>
  );
};

export default OrderRequestorEmailField;
