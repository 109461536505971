import { Control, Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import { PROFILE_FORM_RULES } from 'app/modules/profile/views/Profile/components/ProfileForm/utils';
import { InvitePartnerTenantInput } from 'app/types/schema';

interface Props {
  control: Control<InvitePartnerTenantInput, object>;
  loading?: boolean;
}

const InvitePartnerForm = (props: Props) => {
  const { control, loading } = props;

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="firstName"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              disabled={loading}
              error={fieldState.error}
              label="First Name"
              inputProps={{
                maxLength: 30,
              }}
              formLabel='First Name'
            />
          )}
          rules={PROFILE_FORM_RULES.firstName}
        />
      </div>
      <div className="mb-20">
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              disabled={loading}
              error={fieldState.error}
              label="Last Name"
              inputProps={{
                maxLength: 30,
              }}
              formLabel='Last Name'
            />
          )}
          rules={PROFILE_FORM_RULES.lastName}
        />
      </div>
      <div className="mb-20">
        <Controller
          control={control}
          name="emailId"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              disabled={loading}
              error={fieldState.error}
              label="Email"
              formLabel='Email'
            />
          )}
          rules={PROFILE_FORM_RULES.email}
        />
      </div>
    </div>
  );
};

export default InvitePartnerForm;
