import { Controller } from 'react-hook-form';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import Inventory from '../../../../../../i18n/Inventory';
import FormCostInput from '../../../../../components/FormCostInput';
import { InventoryFormProps } from '../../types';
import { INVENTORY_FORM_RULES } from '../../utils/utils';

const InventoryUnitCostOverrideField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeProduct } = formState;

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="unitCost"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={!isItemTypeProduct || disabled}
              label={Inventory.FormLabels.UnitCost}
            />
          )}
          rules={INVENTORY_FORM_RULES.unitCost}
        />
      </div>

      <div className="mb-20">
        <Controller
          control={control}
          name="costOverride"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={disabled}
              label={Inventory.FormLabels.CostOverride}
              maxValue={999.0}
            />
          )}
          rules={INVENTORY_FORM_RULES.costOverride}
        />
      </div>
    </div>
  );
};

export default InventoryUnitCostOverrideField;
