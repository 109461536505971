import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type CompanyFragment = { __typename?: 'CompanySchema', companyContactNumber?: string | null, companyEmail?: string | null, companyFax?: string | null, companyName: string, companyWebsite?: string | null, createdAt: string, createdById: string, deletedAt?: string | null, deletedById?: string | null, id: string, isVendor?: boolean | null, sqlId?: number | null, tenantId: string, updatedAt: string, updatedById: string };

export const CompanyFragmentDoc = gql`
    fragment Company on CompanySchema {
  companyContactNumber
  companyEmail
  companyFax
  companyName
  companyWebsite
  createdAt
  createdById
  deletedAt
  deletedById
  id
  isVendor
  sqlId
  tenantId
  updatedAt
  updatedById
}
    `;