import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SiteWarehouseQueryVariables = Types.Exact<{
  filters: Types.WarehouseFilters;
}>;


export type SiteWarehouseQuery = { __typename?: 'Query', warehouses: { __typename?: 'WarehouseConnection', edges: Array<{ __typename?: 'WarehouseEdge', node: { __typename?: 'WarehouseSchema', id: string, warehouseType: Types.WarehouseTypeEnum } }> } };


export const SiteWarehouseDocument = gql`
    query siteWarehouse($filters: WarehouseFilters!) {
  warehouses(filters: $filters) {
    edges {
      node {
        id
        warehouseType
      }
    }
  }
}
    `;

export function useSiteWarehouseQuery(options: Omit<Urql.UseQueryArgs<SiteWarehouseQueryVariables>, 'query'>) {
  return Urql.useQuery<SiteWarehouseQuery>({ query: SiteWarehouseDocument, ...options });
};