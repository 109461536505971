import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProfileMutationVariables = Types.Exact<{
  input: Types.UpdateUserInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UpdateUserPayload', success: boolean, reason?: string | null, user?: { __typename?: 'UserSchema', id: string } | null } };


export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateUserInput!) {
  updateUser(input: $input) {
    success
    reason
    user {
      id
    }
  }
}
    `;

export function useUpdateProfileMutation() {
  return Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument);
};