import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { RolesTableRowFragmentDoc } from '../../fragments/generated/RolesTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RolesTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.RoleFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RolesTableQuery = { __typename?: 'Query', roles: { __typename?: 'RoleConnection', totalCount: number, edges?: Array<{ __typename?: 'RoleEdge', node: { __typename?: 'RoleSchema', id: string, name: string, description?: string | null, updatedAt: string, tenant?: { __typename?: 'TenantSchema', id: string, name: string } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } }> | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const RolesTableDocument = gql`
    query rolesTable($filters: RoleFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  roles(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...RolesTableRow
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    ${RolesTableRowFragmentDoc}`;

export function useRolesTableQuery(options?: Omit<Urql.UseQueryArgs<RolesTableQueryVariables>, 'query'>) {
  return Urql.useQuery<RolesTableQuery>({ query: RolesTableDocument, ...options });
};