import { useCallback, useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import usePagination from '../../../../../../hooks/usePagination';
import {
  AssetItemSchema,
  AssetKitItemSchema,
  ReceivableStatusEnum,
  ReceivableTypeEnum,
} from '../../../../../../types/schema';
import { useReceivablesQuery } from 'app/modules/receiving/graphql/queries/receiveSearchSuggetion';
import { processSearch } from 'app/utils/processSearch';
import { Option } from 'app/modules/assetModels/components/FormAssetModelSelect';
import { adaptNodeEdgeToNode } from 'app/components/AsyncMultiSelect/utils';

//the variable use to set the fetching limit of search option
const PAGE_LIMIT = 50;

const useSearchSuggestion = () => {
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();
  const [activePage, setActivePage] = useState(0);
  const [options, setOptions] = useState<any | Option>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  //query fetch the list of receive search option using pagination
  const {
    fetching,
    data: searchData,
    onNextPage,
  } = usePagination(
    useReceivablesQuery,
    {
      filters: {
        search: searchValue,
        types: [ReceivableTypeEnum.Item],
        statuses: [ReceivableStatusEnum.InProgress],
      },
      sorts: null,
    },
    {
      edgeKey: 'receivables',
      pageSize: PAGE_LIMIT,
      pause: !searchValue,
    },
  );

  //effect use to concat the newly fetch option list
  useEffect(() => {
    if (!activePage || searchValue) {
      setOptions(searchData?.receivables?.edges?.map(adaptNodeEdgeToNode) || []);
    } else if (loadingOptions && !fetching) {
      setOptions((prevOptions: any) => {
        const newOptions = searchData?.receivables?.edges?.map(adaptNodeEdgeToNode) || [];
        const uniqueOptions = Array.from(new Set([...prevOptions, ...newOptions]));
        return uniqueOptions;
      });
      setLoadingOptions(false);
    }
  }, [activePage, searchData?.receivables?.edges, searchValue, loadingOptions, fetching]);

  //effect use to create the payload for the dropdown control
  useEffect(() => {
    if (options?.length) {
      const result = options
        ?.map((itemEdge: any) => {
          const item = itemEdge as AssetItemSchema | AssetKitItemSchema | any;
          const itemData = {
            carrier: item?.carrier || '',
            notes: item?.notes || '',
            trackingNumber: item?.trackingNumber || '',
            title:
              item?.item?.title ||
              item?.orderRequestItem?.upcCode ||
              item?.orderRequestItem?.website ||
              item?.orderRequestItem?.upcCode ||
              '',
          };

          const { result, searchBy } = processSearch(
            ['title', 'carrier', 'trackingNumber', 'notes'],
            searchedValue,
            itemData,
          );

          return {
            label: searchBy,
            id: item?.id,
            value: result,
          };
        })
        .filter(
          (filterItems: any) => filterItems.label.trim() !== '' || filterItems?.value.trim() !== '',
        );

      setSearchSuggestions(result);
    } else {
      setSearchSuggestions([]);
    }
  }, [searchData, options]);

  //effect use make search control debounce (it will pause the query mutation till 500MS when user typing)
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  //function use to dynamic scrolling (if page has more data the it will fetch next data)
  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;

      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;

      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !fetching && !loadingOptions) {
        setLoadingOptions(true);
        onNextPage();
        setActivePage(activePage + 1);
      }
    },
    [onNextPage, fetching, loadingOptions, setActivePage, activePage],
  );

  return {
    searchSuggestions,
    setSearchValue,
    onScroll,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
