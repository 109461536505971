import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';

import { SelectOption, SelectProps } from './types';

const getOptionLabel = (option: SelectOption) => option.label;
const getOptionValue = (option: SelectOption) => option.value;
const SELECT_CLASSNAME = 'rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none';

const Select = (props: SelectProps) => {
  const {
    className,
    options,
    autoFocus,
    placeholder = Common.Actions.Select,
    value: valueProp,
    onChange: onChangeProp,
    getOptionDisabled,
    disableClearable,
    isDisabled,
    name,
    ...rest
  } = props;

  const isValueString = typeof valueProp === 'string';

  const value = useMemo(() => {
    if (!valueProp) {
      return null;
    }

    if (!isValueString) {
      return valueProp;
    }

    return options.find((option) => getOptionValue(option) === valueProp);
  }, [isValueString, options, valueProp]);

  const onChange = useCallback(
    (event: React.SyntheticEvent, nextValue: SelectOption) => {
      if (!nextValue) {
        onChangeProp?.(nextValue);
      } else if (isValueString) {
        onChangeProp?.(getOptionValue(nextValue));
      } else {
        onChangeProp?.(nextValue);
      }
    },
    [isValueString, onChangeProp],
  );

  return (
    <Autocomplete
      className={cx(SELECT_CLASSNAME, className)}
      defaultValue={value}
      disableClearable={disableClearable}
      disabled={isDisabled}
      getOptionDisabled={getOptionDisabled}
      getOptionLabel={getOptionLabel}
      label={''}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus || false}
          name={name}
        />
      )}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Select;
