const Login = {
  FormActions: {
    LOGIN: 'LOGIN',
  },
  FormLabels: {
    Email: 'Email',
    Password: 'Password',
    RememberMe: 'Remember Me',
    FirstName: 'FirstName',
    LastName: 'LastName',
    Title: 'Title',
    OfficePhone: 'Office Phone',
    HomePhone: 'Home Phone',
    Mobile: 'Mobile',
    Fax: 'Fax',
    Address: 'Address',
  },

  FormValidationMessages: {
    EmailRequired: 'Please enter an Email Address.',
    EmailInvalid: 'Please enter a valid Email Address.',
    PasswordRequired: 'Please enter a valid Password.',
  },
};
export default Login;
