import { Controller } from 'react-hook-form';
import Shop from 'app/i18n/Shop';

import FormCostInput from '../../../../../components/FormCostInput';
import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';
import { AFFILIATE_MERCHANT_FORM_RULES } from '../utils/utils';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
}
const AffiliateMerchantCommissionField = (props: Props) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="commission"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              characterLimit={5}
              error={fieldState.error}
              isDisabled={disabled}
              label={Shop.Admin.FormLabel.Commission}
              maxValue={100.0}
            />
          )}
          rules={AFFILIATE_MERCHANT_FORM_RULES.commission}
        />
      </div>
    </div>
  );
};

export default AffiliateMerchantCommissionField;
