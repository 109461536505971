import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { CompanyDropDownFragmentDoc } from '../../fragments/generated/company';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompnayListDropDownQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CompanyFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CompnayListDropDownQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', edges: Array<{ __typename?: 'CompanyEdge', node: { __typename?: 'CompanySchema', companyName: string, id: string } }> } };


export const CompnayListDropDownDocument = gql`
    query compnayListDropDown($filters: CompanyFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  companies(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...CompanyDropDown
      }
    }
  }
}
    ${CompanyDropDownFragmentDoc}`;

export function useCompnayListDropDownQuery(options?: Omit<Urql.UseQueryArgs<CompnayListDropDownQueryVariables>, 'query'>) {
  return Urql.useQuery<CompnayListDropDownQuery>({ query: CompnayListDropDownDocument, ...options });
};