import './index.css';
import React, { useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box } from '@mui/material';

import useChart from '../../dashboard/components/chart/useChart';

type BarGraphType = {
  title: string;
  subheader: string;
  chartData: Array<any>;
  onClickViewDetails?: () => void;
  detailsButton?: boolean;
  highlightIndex?: boolean;
  colors?: Array<string>;
};

export default function DonutGraph({
  title,
  subheader,
  chartData,
  onClickViewDetails,
  detailsButton,
  highlightIndex,
  colors,
  ...other
}: BarGraphType) {
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);
  const chartColors = chartData.map((i) => i.color);
  const chartRef = useRef<ReactApexChart>(null);

  const chartOptions = useChart({
    colors: colors && colors.length ? colors : chartColors,
    plotOptions: {
      pie: {
        donut: {
          size: '85%',
        },
      },
    },
    labels: chartLabels,
    legend: {
      show: false,
    },
  });

  return (
    <>
      <Box dir="ltr" sx={{ mx: 3 }}>
        <ReactApexChart
          ref={chartRef}
          className={'chartClass'}
          options={chartOptions}
          series={chartSeries}
          type="donut"
          width="100%"
        />
      </Box>
    </>
  );
}
