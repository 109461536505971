import { ORDER_VIEW, OrderDetailsData } from '../../type';
import { useCallback, useEffect, useState } from 'react';
import { prepareFormData } from '../../utils';
import { useForm } from 'react-hook-form';
import { DeliveryDetailInput } from '../../../../components/OrderDeliveryDetailForm/types';

interface Props {
  orderDetail: OrderDetailsData;
  orderView: ORDER_VIEW;
}

const useOrderDetailsForm = (props: Props) => {
  const { orderDetail, orderView } = props;

  const [defaultValues, setDefaultValues] = useState<DeliveryDetailInput>(
    prepareFormData(orderDetail),
  );

  useEffect(() => {
    if (orderView === ORDER_VIEW.DEFAULT) {
      setDefaultValues(prepareFormData(orderDetail));
    }
  }, [orderDetail]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: { ...defaultValues },
  });

  const onResetForm = useCallback(() => {
    reset(prepareFormData(orderDetail), {
      keepIsSubmitted: false,
      keepDirty: false,
      keepSubmitCount: false,
    });
  }, [reset, defaultValues, orderDetail]);

  useEffect(() => {
    onResetForm();
  }, [defaultValues]);

  return {
    formState: {
      control,
      orderView,
    },
    handleSubmit,
    onResetForm,
  };
};

export default useOrderDetailsForm;
