import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';

const OrderDateField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState } = props;
  const { control } = formState;
  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="orderDate"
        render={({ field, fieldState }) => (
          <FormTextInput 
            {...field} 
            disabled 
            label={Orders.FormLabels.OrderDate} 
            formLabel={Orders.FormLabels.OrderDate}
          />
        )}
      />
    </div>
  );
};

export default OrderDateField;
