import { matchingIndex, truncateString } from "./truncate";

export const processSearch = (fields: string[], searchedValue: string, item: any) => {
  const queryWords = searchedValue ? searchedValue.trim().toLowerCase().split(" ") : [""];
  let result = null;
  let searchBy = null;
  const searchQuery = searchedValue.toLowerCase();

    for (const field of fields) {
      // Accessing 'field' value, which may be in the format 'object.value'.
      const objectField = field.split(".");
      let fieldValue = '';
      
      if (objectField.length === 2) {
        fieldValue = item[objectField[0]]?.[objectField[1]] ?? '';
      } else {
        fieldValue = item[field] ?? '';
      }
      if (fieldValue && field !== "description" && fieldValue.toLowerCase().includes(searchQuery)) {
        result = fieldValue;
        searchBy = fieldValue;
        return {result, searchBy}
      } else if(field === "description" && fieldValue.toLowerCase().includes(searchQuery)){
        const matchIndex = matchingIndex(fieldValue, [searchedValue]);
        result = truncateString(fieldValue, matchIndex);
        searchBy = fieldValue;
        return {result, searchBy}
      }
    } 

    if (queryWords.length > 0) {
      for (const word of queryWords) {
        for (const field of fields) {
          const objectField = field.split(".");
          let fieldValue = '';
          
          if (objectField.length === 2) {
            fieldValue = item[objectField[0]]?.[objectField[1]] ?? '';
          } else {
            fieldValue = item[field] ?? '';
          }
          if (fieldValue && field !== "description" && fieldValue.toLowerCase().includes(word)) {
            result = fieldValue;
            searchBy = fieldValue;
            return {result, searchBy}
          } else if(field === "description" && fieldValue.toLowerCase().includes(searchQuery)){
            const matchIndex = matchingIndex(fieldValue, [searchedValue]);
            result = truncateString(fieldValue, matchIndex);
            searchBy = fieldValue;
            return {result, searchBy}
          }
        }
      }
    }

  return { result: item[fields[0]], searchBy: item[fields[0]] };
}
