import React, { useMemo } from 'react';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import ImageViewDialog from 'app/modules/components/ImageViewDialog';
import { ItemSchema, ShippingTransactionSchema } from 'app/types/schema';

import useOrderItemsDetails from './hooks/useOrderItemsDetails';
import { getOrderItemsTableHeaders } from './columns';

interface OrderItemsProps {
  row: ShippingTransactionSchema;
}

const OrderItems: React.FC<OrderItemsProps> = (props: OrderItemsProps) => {
  const { row } = props;

  const itemIds = useMemo(
    () => row?.items?.map((item) => (item as unknown as ItemSchema)?.id),
    [row?.items],
  );
  const { state, actions } = useOrderItemsDetails({
    orderId: row?.orderRequestId || row?.id || '',
    itemIds,
    row,
  });

  const { table } = useEntityManager({
    selection: false,
    numberOfRowsPerPage: state?.rows?.length,
  });

  const columns = useMemo(() => getOrderItemsTableHeaders(), []);

  return (
    <>
      <div className="ml-[42px] mb-[20px] mt-[16px] px-[16px]">
        <EntityManager.Table
          actions={table.actions}
          columns={columns}
          data={state?.rows}
          extraProps={{
            imagePreviewActions: actions.imagePreviewActions,
          }}
          getRowId={(row: ItemSchema) => (row as any)?._id}
          loading={state?.fetching}
          minWidth={950}
          setState={table.setState}
          state={table.state}
          sx={{
            'border-left': 'none',
            'border-right': 'none',
          }}
          total={state?.rows?.length}
          tableBorder="0px"
          tableBorderRadius="12px"
        />
        <ImageViewDialog
          id="order-item-table-image-view"
          imageUrl={state.imagePreviewState.imageUrl}
          open={state.imagePreviewState.open}
          onClose={actions.imagePreviewActions.onClose}
        />
      </div>
    </>
  );
};

export default OrderItems;
