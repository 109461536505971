import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TitleBar } from '@procurenetworks/procure-component-library';
import InventoryAssetSelectorFormField from 'app/modules/reports/components/InventoryAssetSelectorFormField';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportProjectsSelect from 'app/modules/reports/components/ReportProjectsSelect';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportSKUSelect from 'app/modules/reports/components/ReportSKUSelect';
import SortOrderSelectorFormField from 'app/modules/reports/components/SortOrderSelector';
import TransactionTypeFormField from 'app/modules/reports/components/TransactionTypeFormField';
import { REPORT_TYPES } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import TransactionReportDateFilter from 'app/modules/reports/views/TransactionReport/components/TransactionReportDateFilter';
import { getTransactionDateFilterOptions } from 'app/modules/reports/views/TransactionReport/components/TransactionReportDateFilter/utils';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import {
  getAssetTransactionEntityTypes,
  getItemLocationItemTypesFromTransactionType,
} from '../../../../utils/itemtypes';
import CustomReportSelectColumns from './components/CustomReportSelectColumns';
import { CustomReportFormProps, CustomReportFormValues } from './types';

const defaultValues: CustomReportFormValues = {
  type: TransactionEntityTypeEnum.Asset,
  sortOrder: SortOrderEnum.Asc,
  transactionTypes: [],
  format: 'preview',
  columns: ['sku', 'title'],
  createdAt: getTransactionDateFilterOptions()[0].createdAt,
  skuIds: [],
  categoryIds: [],
  projectIds: [],
  siteIds: [],
  itemTypes: getAssetTransactionEntityTypes(),
};

const CustomReportForm = (props: CustomReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues });
  const type: TransactionEntityTypeEnum = watch('type');
  const transactionTypes: string[] = watch('transactionTypes');
  const itemTypes = watch('itemTypes');
  const columns = watch('columns');

  useEffect(() => {
    setValue('itemTypes', getItemLocationItemTypesFromTransactionType(type));
  }, [type]);

  useEffect(() => {
    if (!transactionTypes.includes('remove') && columns.includes('reason')) {
      const newColumns = columns.filter((col) => col !== 'reason');
      setValue('columns', newColumns);
    }
  }, [transactionTypes]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (type: TransactionEntityTypeEnum) => {
      reset({ ...defaultValues, type: type });
    },
    [reset],
  );

  return (
    <form className="max-w-[600px] py-[16px] px-[24px]" onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <InventoryAssetSelectorFormField control={control} onChange={onTypeChange} />
        <ReportCategoriesSelect control={control} />
        <ReportProjectsSelect control={control} />
        <ReportSitesSelect control={control} />
        <ReportSKUSelect control={control} types={itemTypes} />
        <TitleBar title="Transaction Date" className="!pl-0" />
        <TransactionReportDateFilter control={control} />
        <SortOrderSelectorFormField control={control} />
        <TransactionTypeFormField
          validate
          control={control}
          reportType={REPORT_TYPES.CUSTOM}
          types={[type]}
        />
        <Controller
          control={control}
          name="columns"
          render={({ field }) => (
            <CustomReportSelectColumns
              itemType={type}
              persistKey="custom_report_form_table"
              transactionTypes={transactionTypes}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default CustomReportForm;
