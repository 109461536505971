import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateAffiliateConfigurationInput, CreateMediaInput, UpdateAffiliateConfigurationInput } from 'app/types/schema';
import { AttachmentType, UploadTypeEnum } from 'app/uppy/useUppy';

import useFormFileUploader from '../../../../../../components/ProcureForm/FormFileUploader/hook/useFormFileUploader';
import { createAffiliateConfigurationInputDTO, createUpdateAffiliateConfigurationInputDTO } from '../../components/utils/dto';

interface Props {
  defaultValues: any;
  affiliateConfigurationId?: string;
  defaultEditable?: boolean;
}

const useAffiliateMerchantForm = (props: Props) => {
  const { affiliateConfigurationId, defaultEditable = true, defaultValues } = props;

  const [editable, setEditable] = useState<boolean>(defaultEditable);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const { control, handleSubmit, watch, setValue, reset, getValues } =
    useForm({
      defaultValues: {
        ...defaultValues,
      },
    });

  const logoUrl = watch('logoUrl');

  const defaultAttachments = useMemo(() => {
    if (defaultValues?.logoUrl) {
      return [{
        id: 'logo-[0]',
        kind: "IMAGE",
        url: defaultValues?.logoUrl,
        resolutions: []
      }]
    } else {
      return []
    }
  }, [defaultValues?.logoUrl]);

  const {
    inputProps: attachmentInputProps,
    setAttachments,
    addFileFromUrl: addAttachmentFromUrl,
  } = useFormFileUploader('logo', defaultAttachments as unknown as AttachmentType[], UploadTypeEnum.SINGLE);

  const resetFormData = useCallback(() => {
    reset({
      ...props.defaultValues,
    });
    setAttachments(defaultAttachments as unknown as AttachmentType[]);
  }, [reset, props.defaultValues, setAttachments, defaultAttachments]);

  useEffect(() => {
    if (!editable) {
      resetFormData();
    }
  }, [editable, setAttachments, defaultAttachments]);

  const onSaveProductData = useCallback(
    (values: any) => {
      const productImages = values?.logoUrl || [];
      setAttachments(productImages);
      // Upload Product image Url to S3
      productImages.forEach((image: string) => {
        addAttachmentFromUrl(image);
      });

      reset({
        ...getValues(),
        ...values,
      });
    },
    [
      reset,
      getValues,
      logoUrl,
      setAttachments,
      addAttachmentFromUrl
    ],
  );


  const createAffiliateMerchantInput = (values: any): CreateAffiliateConfigurationInput => {
    return createAffiliateConfigurationInputDTO(values)
  };

  const createUpdateAffiliateMerchantInput = ( affiliateConfigurationId: string, values: any): UpdateAffiliateConfigurationInput => {
    return createUpdateAffiliateConfigurationInputDTO(affiliateConfigurationId, values)
  };

  const onCancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, [setShowDeleteConfirmation]);

  const onDeleteConfirmation = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);

  const makeEditable = useCallback(() => {
    setEditable(true);
  }, [setEditable]);

  const cancelEditable = useCallback(() => {
    setEditable(false);
  }, [setEditable]);

  return {
    state: {
      getValues,
      setValue,
      control,
      defaultValues: props.defaultValues,
      onSaveProductData,
      handleSubmit,
      editable,
      makeEditable,
      cancelEditable,
      attachmentInputProps,
      showDeleteConfirmation,
      onCancelDelete,
      onDeleteConfirmation,
      affiliateConfigurationId,
    },
    createAffiliateMerchantInput,
    createUpdateAffiliateMerchantInput,
  };
};

export type UseAffiliateMerchantFormReturnType = ReturnType<typeof useAffiliateMerchantForm>;
export default useAffiliateMerchantForm;
