import AsyncMultiTableFilter from '../../../../components/AsyncMultiTableFilter';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../components/AsyncMultiSelect/types';
import { Maybe, SortOrderEnum, TenantStatusEnum } from '../../../../types/schema';
import { useMemo } from 'react';
import { adaptNodeEdgeToOption } from '../../../../components/AsyncMultiSelect/utils';
import { FieldError } from 'react-hook-form';
import { useTenantTableFilterQuery } from './graphql/query/generated/tenantTableFilter';

interface TenantQueryVariables {
  partnersOnly?: boolean;
}

export interface TenantSelectProps {
  name: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: TenantQueryVariables;
}

const useSelectedValue = (
  props: UseSelectedValueProps & TenantQueryVariables,
): UseSelectedValueReturn => {
  const { value, pause, partnersOnly } = props;

  const [{ fetching, data }] = useTenantTableFilterQuery({
    pause,
    variables: {
      filters: {
        partnersOnly: Boolean(partnersOnly),
        tenantIds: value,
      },
    },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () =>
      data?.tenants.edges
        ?.map(adaptNodeEdgeToOption)
        .filter((option) => value.includes(option.value)) || [],
    [data?.tenants.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (
  props: UseSelectQueryProps & TenantQueryVariables,
): UseSelectQueryReturn => {
  const { inputValue, partnersOnly } = props;

  const [{ fetching, data }] = useTenantTableFilterQuery({
    variables: {
      filters: {
        search: inputValue,
        partnersOnly: Boolean(partnersOnly),
        statuses: [TenantStatusEnum.Active],
      },
      limit: 10000,
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.tenants.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.tenants.edges],
  );

  return { isLoading: fetching, options };
};

const TenantsTableFilter = (props: TenantSelectProps) => {
  return (
    <AsyncMultiTableFilter
      {...props}
      size="small"
      useSelectedValue={useSelectedValue}
      useSelectQuery={useSelectQuery}
    />
  );
};

export default TenantsTableFilter;
