import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteOrderRequestsMutationVariables = Types.Exact<{
  input: Types.DeleteOrderRequestsInput;
}>;


export type DeleteOrderRequestsMutation = { __typename?: 'Mutation', deleteOrderRequests: { __typename?: 'MutationResponse', success: boolean } };


export const DeleteOrderRequestsDocument = gql`
    mutation deleteOrderRequests($input: DeleteOrderRequestsInput!) {
  deleteOrderRequests(input: $input) {
    success
  }
}
    `;

export function useDeleteOrderRequestsMutation() {
  return Urql.useMutation<DeleteOrderRequestsMutation, DeleteOrderRequestsMutationVariables>(DeleteOrderRequestsDocument);
};