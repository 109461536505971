import { useCallback, useEffect, useRef, useMemo } from 'react';
import { cx } from '@emotion/css';
import {
  DataGrid,
  DataGridComponentState,
  GridColDef,
  GridSortModel,
  useLocalStorage,
} from '@procurenetworks/procure-component-library';
import { tableLoadingStyles } from 'app/modules/components/EntityManager/EntityManagerTable/styles';
import { hideColumnSelectStyles } from 'app/modules/reports/utils/styles';
import { SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { ReportPreviewTableProps } from './types';
import { disableVirtualization } from 'app/consts/config';
import { getPageSettingsFromStorage, savePageSettingsToStorage } from 'app/utils/paginationSettingsUtil';

const ReportPreviewTable = (props: ReportPreviewTableProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    className = 'mt-24 px-16',
    columns,
    data,
    minWidth = columns.length * 200,
    total = 0,
    rowHeight,
    initialLoading,
    loading,
    pagination,
    persistKey,
    defaultSortState,
    extraProps,
    state,
    setState,
    onNextPage,
    onPrevPage,
    onReset,
    filterNode,
    paginationClass
  } = props;
  const [datagridState, setDatagridState] = useLocalStorage(persistKey);

  const onTablePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      if (page > state.activePage) {
        onNextPage?.();
      } else if (page < state.activePage) {
        onPrevPage?.();
      }

      setState({ activePage: page });
    },
    [onNextPage, onPrevPage, setState, state.activePage],
  );

  const dataGridComponentState = {
    extraProps,
    headers: columns,
    page: state.activePage,
    rows: data,
    rowsPerPage: state.numberOfRowsPerPage,
    total,
  } as DataGridComponentState;

  const columnsData: GridColDef[] = useMemo(() => {
    const originalColumns = columns.map((headCell: any, index: number) => {
      const column: any = {
        align: headCell.rowAlign,
        field: headCell.value,
        headerAlign: headCell.labelAlign,
        headerName: headCell.label,
        headerClassName: headCell.classes,
        renderCell: headCell.valueNode
          ? (parameters: any) => {
            return (
              <headCell.valueNode
                headCell={headCell}
                row={parameters.row}
                state={dataGridComponentState}
              />
            );
          }
          : undefined,
        renderHeader: headCell.labelNode
          ? () => {
            const LabelComponent = headCell.labelNode;
            return <LabelComponent headCell={headCell} state={dataGridComponentState} />;
          }
          : undefined,
        sortable: headCell.sortable,
        hideable: index === 0 ? false : true,
        width: headCell.width ? headCell.width : 200,
      };
      return column;
    });

    let savedOrder = datagridState?.columns?.all;
    if (persistKey) {
      const savedState = localStorage.getItem(persistKey);
      if (savedState && savedState !== "null") {
        const parsedState = JSON.parse(savedState);
        savedOrder = parsedState.columns.all || datagridState?.columns?.all;
      }
    }

    if (!savedOrder || savedOrder.length === 0) {
      return originalColumns;
    }

    const columnMap = Object.fromEntries(originalColumns.map(col => [col.field, col]));

    const persistedColumns = savedOrder
      .filter((field: string) => columnMap[field])
      .map((field: string, index: number) => ({
        ...columnMap[field],
        hideable: index === 0 ? false : columnMap[field].hideable,
      }));

    return persistedColumns.length === originalColumns.length ? persistedColumns : originalColumns;
  }, [columns, dataGridComponentState, datagridState, persistKey]);

  const rowsPerPage = getPageSettingsFromStorage(state.numberOfRowsPerPage);

  const onTableRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      savePageSettingsToStorage(+event.target.value);
      setState({ activePage: 0, numberOfRowsPerPage: +event.target.value });
    },
    [setState],
  );

  useEffect(() => {
    setState({ activePage: 0 });
    const shouldScroll = typeof initialLoading === 'boolean' ? !initialLoading : false;

    if (ref.current && shouldScroll) {
      const top = ref.current.offsetTop;

      setTimeout(() => {
        window.scrollTo({ top, behavior: 'smooth' });
      }, 0);
    }
  }, [initialLoading, setState, total]);

  useEffect(() => {
    const sortModel = datagridState?.sorting?.sortModel;
    setState({
      activePage: 0,
      sortState: sortModel?.length
        ? {
          id: sortModel[0].field,
          orderBy: sortModel[0].sort
            ? sortModel[0].sort
            : defaultSortState?.orderBy || ('asc' as any),
        }
        : undefined,
      sorts: sortModel?.length
        ? [
          {
            sortField: sortModel[0].field,
            sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
          },
        ]
        : [],
    });
    onReset?.();
  }, [setState, total]);

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setState({
        activePage: 0,
        sortState: sortModel.length
          ? {
            id: sortModel[0].field,
            orderBy: sortModel[0].sort
              ? sortModel[0].sort
              : defaultSortState?.orderBy || ('asc' as any),
          }
          : undefined,
        sorts: sortModel.length
          ? [
            {
              // sortField: parseSortFieldBaseOnTable(persistKey as string, sortModel)[0].field,
              sortField: sortModel[0].field,
              sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
            },
          ]
          : [],
      });
    },
    [setState],
  );

  return (
    <Box ref={ref} className={cx(className, hideColumnSelectStyles, loading && tableLoadingStyles)}>
      <DataGrid
        columns={columnsData}
        count={total}
        currentPage={state.activePage}
        loading={loading}
        pagination={pagination}
        persistKey={persistKey}
        rowHeight={rowHeight || 52}
        rows={data ?? []}
        rowsPerPage={rowsPerPage}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        onTablePageChange={onTablePageChange}
        onTableRowsPerPageChange={onTableRowsPerPageChange}
        disableVirtualization={disableVirtualization}
        tableFilters={filterNode}
        paginationWrapperClass={paginationClass}
      />
    </Box>
  );
};

export default ReportPreviewTable;
