import { composeSortFunction } from '../../../../../../../utils/sort';

export const sortBySkuLocationName = composeSortFunction((data: any) => [
  data.item.sku,
  data.location.name,
]);

export const sortBySku = composeSortFunction((data: any) => [data.item.sku]);

export const sortByItemTitleLocationName = composeSortFunction((data: any) => [
  data.item.title,
  data.location.name,
]);

export const sortByItemTitleSku = composeSortFunction((data: any) => [
  data.item.title,
  data.item.sku,
]);

export const sortByLocationName = composeSortFunction((data: any) => [data.name]);

export const sortByDestinationNameContainerId = composeSortFunction((data: any) => [
  data.destinationSite.name,
  data.containerId,
]);
