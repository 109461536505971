import { Controller } from 'react-hook-form';
import FormTextArea from 'app/components/Form/FormTextArea';
import Assets from 'app/i18n/Assets';

import { AssetFieldProps } from '../../types';

const AssetDescriptionField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <FormTextArea
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.Description}
          />
        )}
      />
    </div>
  );
};

export default AssetDescriptionField;
