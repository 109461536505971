import { Controller } from 'react-hook-form';
import FormFileUpload from 'app/components/Form/FormFileUpload';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';

const AssetModelAttachmentField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control, attachmentInputProps } = formState;

  return (
    <div className="mb-[24]">
      <Controller
        control={control}
        name="attachments"
        render={({ field, fieldState }) => (
          <FormFileUpload
            {...field}
            {...attachmentInputProps}
            key="attachmentInput"
            disabled={disabled}
            error={fieldState.error}
            id="attachmentInput"
            label={AssetModel.FormLabels.Attachments}
          />
        )}
      />
    </div>
  );
};
export default AssetModelAttachmentField;
