import { OrderRequestItemSchema } from 'app/types/schema';
import { useCallback, useMemo } from 'react';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { parseItemInStockToExistingOrderItem } from '../../views/OrderDetail/hook/useOrderDetails/utils/utils';
import { ItemInStock } from '../../views/OrderRequest/type';
import OrderRequestColumnEditField from '../../views/OrderRequest/components/OrderRequestColumnEditField';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  actions: OrderItemsExtraPropType['actions'];
  state: OrderItemsExtraPropType['state'];
  valueKey: string;
}

const OrderRequestNoSKUEditView = (props: Props) => {
  const { orderRequestItem, actions, state, valueKey } = props;
  const { onUpdateOrderItem } = actions;
  const { editableOrderRequestItems } = state;

  const value = useMemo(() => {
    const { website, upcCode, description, item, type } = orderRequestItem;

    return { website, upcCode, description, sku: item?.sku || '' }[valueKey] || '';
  }, [orderRequestItem]);

  const updateItemInStockItem = useCallback(
    (orderRequestItem: OrderRequestItemSchema, itemInStock: ItemInStock) => {
      const orderRequestItemId = orderRequestItem.id;
      const updatedOrderRequestItem = parseItemInStockToExistingOrderItem(
        orderRequestItem,
        itemInStock,
      );
      onUpdateOrderItem(orderRequestItemId, updatedOrderRequestItem);
    },
    [onUpdateOrderItem, editableOrderRequestItems],
  );

  const orderRequestItemId = orderRequestItem.id;
  const onUpdateNoSkuUpdate = useCallback(
    (value: string) => {
      const { description, website, upcCode } = orderRequestItem;

      onUpdateOrderItem(orderRequestItemId, {
        ...{ description, website, upcCode },
        [valueKey]: value,
      });
    },
    [onUpdateOrderItem, orderRequestItemId, updateItemInStockItem],
  );

  if (valueKey === 'website')
    return <OrderRequestColumnEditField.Website value={value} onChange={onUpdateNoSkuUpdate} />;
  if (valueKey === 'upcCode')
    return <OrderRequestColumnEditField.UPCOrASIN value={value} onChange={onUpdateNoSkuUpdate} />;
  if (valueKey === 'description')
    return <OrderRequestColumnEditField.Description value={value} onChange={onUpdateNoSkuUpdate} />;

  return null;
};

export default OrderRequestNoSKUEditView;
