import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletedAssetsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AssetItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeletedAssetsTableQuery = { __typename?: 'Query', assetItems: { __typename?: 'AssetItemConnection', totalCount: number, edges: Array<{ __typename?: 'AssetItemEdge', node: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string, description?: string | null, mName: string, parentId?: string | null, manufacturerId?: string | null, deletedAt?: string | null, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null, category?: { __typename?: 'CategorySchema', id: string, name: string } | null, manufacturer?: { __typename?: 'ManufacturerSchema', name: string, status: string } | null, reminders?: Array<{ __typename?: 'AssetItemReminder', _id: string, jobId: string, type?: Types.AssetItemReminderTypeEnum | null, note: string, cron?: string | null, notifyUserIds: Array<string>, status: Types.AssetItemReminderStatusEnum, startReminderAt: string, lastReminderAt?: string | null, meta?: string | null, recurring?: boolean | null, recurringInterval?: number | null }> | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const DeletedAssetsTableDocument = gql`
    query deletedAssetsTable($filters: AssetItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  assetItems(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        sku
        attachments {
          url
        }
        title
        description
        mName
        category {
          id
          name
        }
        parentId
        manufacturerId
        manufacturer {
          name
          status
        }
        reminders {
          _id
          jobId
          type
          note
          cron
          notifyUserIds
          status
          startReminderAt
          lastReminderAt
          meta
          recurring
          recurringInterval
        }
        deletedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useDeletedAssetsTableQuery(options?: Omit<Urql.UseQueryArgs<DeletedAssetsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<DeletedAssetsTableQuery>({ query: DeletedAssetsTableDocument, ...options });
};