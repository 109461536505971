import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  regexValidator,
  textMaxLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import AssetModel from 'app/i18n/AssetModel';

export const ASSET_MODEL_FORM_RULES = {
  categoryId: {
    validate: textValidator(AssetModel.FormValidationMessages.CategoryRequired),
  },
  title: {
    validate: composeValidators(
      textValidator(AssetModel.FormValidationMessages.TitleRequired),
      textMaxLengthValidator(AssetModel.FormValidationMessages.TitleRequired, 32),
    ),
  },
  mName: {
    validate: textValidator(AssetModel.FormValidationMessages.ModelRequired),
  },
  costOverride: {
    validate: composeValidators(
      numberValidator(AssetModel.FormValidationMessages.InvalidCostOverride),
      regexValidator(AssetModel.FormValidationMessages.InvalidCostOverride, /^\d{1,3}(\.\d{1,2})?$/),
      numberMinValidator(AssetModel.FormValidationMessages.InvalidCostOverride, 0),
      numberMaxValidator(AssetModel.FormValidationMessages.InvalidCostOverride, 999.99),
    ),
  },
};
