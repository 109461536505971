import { cloneDeep, sortBy as sort } from 'lodash';
import { DataGridHead } from 'app/types/dataGrid';
import { SortProps } from 'app/types/schema';

export function sortBy(key: string, caseInSensitive?: boolean) {
  return (a: any, b: any) => {
    const aValue = a[key] || '';
    const bValue = b[key] || '';

    if (caseInSensitive) {
      return aValue.localeCompare(bValue);
    }

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };
}

export function composeSortFunction(sortFn: (item: any) => string[]) {
  return (data: any[]) => sort(data, [sortFn]);
}

export const parseSorts = (
  sorts: SortProps[],
  columns: DataGridHead[],
) => {
  let _sorts = cloneDeep(sorts);
  _sorts = _sorts?.map((sortItem) => {
    const sortField = columns.find((item) => item?.id === sortItem?.sortField);
    if (sortField?.sortField) {
      sortItem.sortField = sortField?.sortField;
    }
    return sortItem;
  });
  return _sorts || [];
};
