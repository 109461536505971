import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
export const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiTabs-indicator': {
        display: 'none',
      },
      '& .MuiTab-root': {
        textTransform: 'none',
        minWidth: 0, 
        marginRight: '10px',
        borderRadius: 8,
        fontSize:'16px',
        fontWeight:'600',
        color:'#6F767E',
        minHeight:'40px',
        padding:'8px 12px !important',
        '&:hover': {
          color: '#FFA000',
          backgroundColor: '#FFE7BE',
        },
        '&.Mui-selected': {
          color: '#FFFFFF',
          backgroundColor: '#FFA000',
          textDecoration: 'none',
          fontSize:'16px',
          fontWeight:'600'
        },
      },
    },
  }));