import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditDepartmentDataFragment = { __typename?: 'DepartmentSchema', id: string, name: string, description?: string | null, departmentCode?: string | null, formattedDescription?: string | null };

export const EditDepartmentDataFragmentDoc = gql`
    fragment EditDepartmentData on DepartmentSchema {
  id
  name
  description
  departmentCode
  formattedDescription
}
    `;