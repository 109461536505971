import { useCallback, useContext, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Context } from 'app/libs/urql';
import authModel from 'app/modules/auth/model';

import { UserProfileFragment } from '../../graphql/fragments/generated/UserProfile';
import CurrentUser from '../../utils/currentUser';

const useCurrentUser = () => {
  return useObserver(
    () =>
      ({
        ...authModel.user,
        tenantId: CurrentUser.tenantId(),
      } as UserProfileFragment),
  );
};

export default useCurrentUser;

export const useCurrentUserWithHelpers = () => {
  const currentUser = useCurrentUser();
  const [user, setUser] = useState<UserProfileFragment>(currentUser);
  const client = useContext(Context);

  const fetchUser = useCallback(async () => {
    const user = await authModel.fetchUser(client);
    if (user) {
      setUser(user);
    }
  }, [setUser, client]);

  return { user, fetchUser };
};
