import {
  composeValidators,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Location from 'app/i18n/Location';

export const LOCATION_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Location.FormValidationMessages.NameRequired),
      textMinLengthValidator(Location.FormValidationMessages.NameRequired, 3),
      textMaxLengthValidator(Location.FormValidationMessages.NameRequired, 30),
    ),
  },
  siteId: {
    validate: textValidator(Location.FormValidationMessages.SiteRequired),
  },
  description: {
    validate: (value?: string | null) => {
      if (!value) {
        return true;
      }
      return textMaxLengthValidator(
        Location.FormValidationMessages.DescriptionMaxLength,
        500,
      )(value);
    },
  },
};
