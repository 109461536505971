import { useEffect, useState } from 'react';
import { APP_CONFIG } from 'app/consts/config';

import { VendorTypeEnum } from '../../types';

interface ImpactProducts {
  query?: string;
  page?: number;
  pageSize?: number;
  productIds?: string[];
  quantity?: number;
  refetch?: () => void;
  vendorType: VendorTypeEnum
}

const useGetImpactProductData = (props: ImpactProducts) => {
  const { query, page, pageSize, productIds, quantity, vendorType = VendorTypeEnum.WALMART } = props;
  const { apiUrls } = APP_CONFIG;

  const IMPACT_API_URL = `${apiUrls.api}/api/external/affiliate/vendor/vendorAffiliate`;

  const [data, setData] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null | unknown>(null);

  const isAmazon = vendorType === VendorTypeEnum.AMAZON;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, pageSize, productIds, vendorType]);


  const fetchData = async () => {
        setLoading(true);
    setError(null);

    const requestBody: Record<string, any> = {
      vendorType: vendorType
    };

    if (query) {
      requestBody.query = query;
    }

    if (page) {
      requestBody.page = page;
    }

    if (pageSize) {
      requestBody.pageSize = pageSize;
    }

    if (productIds) {
      requestBody.itemIds = productIds;
    }

    const requestOptions: {
      method: string;
      headers: { 'Content-Type': string };
      body?: string;
    } = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (Object.keys(requestBody).length > 0) {
      requestOptions.body = JSON.stringify(requestBody);
    } else {
      requestOptions.body = JSON.stringify({});
    }

    try {
      if (!productIds || (productIds && productIds.length)) {
        const response = await fetch(IMPACT_API_URL, requestOptions);

        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }

        const result = await response.json();

        const filteredData = result?.data?.items?.map((item: any) => {
          return {
            ...item,
            quantity: quantity || 1,
          };
        });
        setData(filteredData);
        const totalCount = result?.data.count - 1;
        const pageItemSize = result?.data.pageSize;
        const pageNumber = result?.data.page + 1; 
        const hasNextPage =  pageNumber <= (totalCount / pageItemSize) 
        const nextPage = isAmazon ? (page || 0) * (pageSize || 0) < (result?.data?.count || 0) : result?.data.nextPageUrl || hasNextPage;
        setNextPageUrl(nextPage);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, nextPageUrl, loading, error, refetch: fetchData };
};

export default useGetImpactProductData;
