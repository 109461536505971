import { useMemo } from 'react';
import { AsyncSelectOption } from 'app/components/AsyncMultiSelect/types';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncSingleSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncSingleSelect/utils';
import { Maybe, SortOrderEnum } from 'app/types/schema';

import AsyncSingleSelect from '../../../../components/AsyncSingleSelect';
import { useDepartmentsSelectQuery } from '../../graphql/queries/generated/departmentsSelect';

export interface DepartmentsSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string, option: AsyncSelectOption) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  isRequired?: boolean;
  size?: any;
  customOptions?: { id: string; name: string }[];
  formLabel?:string
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const [{ fetching, data }] = useDepartmentsSelectQuery({
    pause,
    variables: { filters: { departmentIds: value ? [value] : [] } },
  });
  const selectedValue = useMemo(() => {
    const options =
      data?.departments.edges
        ?.map(adaptNodeEdgeToOption)
        .filter((option) => option.value === value) || [];
    return options?.[0];
  }, [data?.departments.edges, value]);

  return { isDisabled: fetching, selectedValue };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;

  const [{ fetching, data }] = useDepartmentsSelectQuery({
    variables: {
      filters: { search: inputValue },
      limit: 10000,
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
  });
  const options = useMemo(
    () => data?.departments.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.departments.edges],
  );

  return { isLoading: fetching, options };
};

const DepartmentSelect = (props: DepartmentsSelectProps) => {
  return (
    <AsyncSingleSelect
      {...props}
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

export default DepartmentSelect;
