import { OrderRequestItemSchema, OrderRequestItemTypeEnum } from '../../../../types/schema';
import OrderRequestItemSku from '../OrderRequestItemSku';
import OrderRequestItemNoSkuView from '../OrderRequesItemNoSkuView';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  valueKey: string;
}

const OrderRequestItemDescriptionPreview = (props: Props) => {
  const { orderRequestItem, valueKey } = props;
  const { type } = orderRequestItem;
  switch (type) {
    case OrderRequestItemTypeEnum.Asset:
    case OrderRequestItemTypeEnum.AssetKit:
    case OrderRequestItemTypeEnum.Inventory:
    case OrderRequestItemTypeEnum.InventoryKit:
      if (valueKey === 'sku')
        return <OrderRequestItemSku orderRequestItem={orderRequestItem} withLink target="_blank" />;
      else return null;

    case OrderRequestItemTypeEnum.NoSku:
      return <OrderRequestItemNoSkuView orderRequestItem={orderRequestItem} valueKey={valueKey} />;

    default:
      return null;
  }
};

export default OrderRequestItemDescriptionPreview;
