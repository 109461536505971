import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { getLocationName } from 'app/modules/locations/utils/location';
import { LocationUserDataType } from 'app/modules/network/types';
import { CustomIcons } from '@procurenetworks/procure-component-library';

interface Props {
  locationUserDetail: LocationUserDataType;
  setUserModal: any;
}

const LocationUserDetail = ({ locationUserDetail, setUserModal }: Props) => {
  const {
    userName,
    email,
    destinationLocationName,
    destinationSiteName,
    destinationLocationId,
    destinationSiteId,
  } = locationUserDetail;

  const locationName = getLocationName(destinationSiteId, {
    id: destinationLocationId,
    name: destinationLocationName,
  });

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" className='mb-[16px] border-b-[1px] border-grey-300 pb-[16px]'>
        <Grid item>
          <Typography className="!text-[18px] text-grey-900 font-semibold">
            Location & User
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className='text-blue-900 text-[14px] cursor-pointer underline'
            variant="body1"
            onClick={() => setUserModal(true)}>
            Set User & Location
          </Typography>
        </Grid>
      </Grid>
      <Card sx={{boxShadow:'none'}}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {/* <Avatar src={''} sx={{ width: 45, height: 45, marginX: 'auto' }} /> */}
                  <Box className="h-[44px] w-[44px] rounded-full bg-grey-300 flex justify-center items-center">
                    <Box className='mr-[5px] mb-[5px]'>
                        <CustomIcons.AvatarDefaultIcon/>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Typography className="text-[14px] text-grey-900 font-medium">
                    {userName || 'Username not available'}
                  </Typography>
                  <Typography className="text-[12px] text-grey-800">{email || 'Email not available'}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                <Box className="h-[44px] w-[44px] rounded-full bg-grey-300 flex justify-center items-center">
                    <CustomIcons.LocationIcon/>
                </Box>
                  {/* <Avatar src={''} sx={{ width: 45, height: 45, marginX: 'auto' }}>
                    <PlaceIcon />
                  </Avatar> */}
                </Grid>
                <Grid item>
                  <Typography className="text-[14px] text-grey-900 font-medium">
                    {locationName || 'Location not available'}
                  </Typography>
                  <Typography className="text-[12px] text-grey-800">
                    {destinationSiteName || 'Site not available'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default LocationUserDetail;
