import { groupBy, orderBy } from 'lodash';
import moment from 'moment';
import { ShippingTransactionSchema } from 'app/types/schema';
import { ShippingTransactionOrderRequestItemSchema } from 'app/types/schema';

export enum EntityType {
  CONTAINER = 'CONTAINER',
  MOVE = 'MOVE',
  ORDER_REQUEST = 'ORDER_REQUEST',
  CHILD_CONTAINER = 'CHILD_CONTAINER',
}

const parseChildEntities = (transaction: ShippingTransactionSchema) => {
  return transaction?.childEntities?.map((item: any) => {
    return {
      ...item,
      trackingId: transaction?.trackingId,
      id: item?.entityId,
      rowId: transaction?.id,
      label: item?.entityId,
      createdAt: item?.createdAt ? moment(item?.createdAt).format('L LT') : '-',
      entityType: EntityType.CHILD_CONTAINER,
    };
  });
};
export const parseOrderRequest = (transaction: ShippingTransactionSchema) => {
  const { orderRequestCode } = transaction.orderRequest || {};
  return {
    ...transaction.orderRequest,
    label: orderRequestCode,
    createdAt: transaction?.createdAt ? moment(transaction?.createdAt).format('L LT') : '-',
    trackingId: transaction?.trackingId,
    id: transaction?.trackingId,
    rowId: transaction?.id,
    orderRequestId: transaction?.orderRequestId,
    entityType: EntityType.ORDER_REQUEST,
    items: [
      ...transaction?.items?.map((item) => ({
        ...(item as ShippingTransactionOrderRequestItemSchema)?.orderRequestItem,
        quantity: item?.quantity,
      })),
    ],
  };
};

export const prepareTractionTable = (data: any[]) => {
  let tableData: any = [];

  tableData = data?.map?.((transaction) => {
    let transactionData = transaction?.node || transaction;
    return {
      ...transactionData,
    };
  });

  return tableData || [];
};

export const hasMultipleValues = (data: any): boolean => {
  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 1) {
      return true;
    }
  }
  return false;
};

export enum GroupByKey {
  CHILD_CONTAINER = 'containerId',
  ORDER_REQUEST = 'orderRequestId'
}
export function distinctChildEntitiesBy(data: ShippingTransactionSchema[], distinctKey: GroupByKey = GroupByKey.CHILD_CONTAINER) {
  const groupedByOrderRequestId = groupBy(data, distinctKey);
  const distinctTransactions: any = [];

  Object.keys(groupedByOrderRequestId).map((key: string) => {
    const sortedTransactions: ShippingTransactionSchema[] = orderBy(
      groupedByOrderRequestId[key],
      'createdAt',
      'desc',
    ) as unknown as ShippingTransactionSchema[];

    const items = sortedTransactions.map((transaction: any) => transaction?.items || []).flat();
    const mainObject = sortedTransactions[0];
    distinctTransactions.push({ ...mainObject, items });
  });

  return distinctTransactions
}