import { useState } from 'react';
import {
  Autocomplete,
  Button,
  Select,
  TextField,
} from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import './styles.css';

interface AddColumnParams {
  disabled: boolean;
  onClick: () => void;
}

export const renderAddColumnAction = ({ disabled, onClick }: AddColumnParams) => (
  <Button disabled={disabled} theme="success" onClick={onClick} classes="min-w-[124px] h-[32px]">
    {Common.Actions.Add}
  </Button>
);

interface ColumnSelectorParams {
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
}

export const renderColumnSelector = ({ options, value, onChange }: ColumnSelectorParams) => {
  const actualValue = options.filter((ele) => ele.value === value)[0];

  return (
    <div className="table-filter">
      <Autocomplete
        disableClearable
        className={'table-multi-table-filter'}
        label=""
        options={options}
        renderInput={(params) => <TextField {...params} value={value} />}
        value={actualValue?.label}
        onChange={(event, option) => {
          onChange(option === null ? '' : option?.value);
        }}
        size="small"
      />
    </div>
  );
};
