import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { OrganizationTableRowFragmentDoc } from '../../fragments/generated/OrganizationTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrganizationsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TenantFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrganizationsTableQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges: Array<{ __typename?: 'TenantEdge', node: { __typename?: 'TenantSchema', id: string, logo?: string | null, name: string, tenantCode: string, primaryPhoneNumber?: string | null, status: Types.TenantStatusEnum, locationDetails: { __typename?: 'TenantLocationDetailsType', address: string, city: string, state: string, zipCode: string }, admin?: { __typename?: 'UserSchema', id: string, name: string } | null } }> } };


export const OrganizationsTableDocument = gql`
    query organizationsTable($filters: TenantFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  tenants(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...OrganizationTableRow
      }
    }
  }
}
    ${OrganizationTableRowFragmentDoc}`;

export function useOrganizationsTableQuery(options?: Omit<Urql.UseQueryArgs<OrganizationsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<OrganizationsTableQuery>({ query: OrganizationsTableDocument, ...options });
};