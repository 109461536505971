import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { DepartmentTableRowFragmentDoc } from '../../fragments/generated/DepartmentTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DepartmentsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.DepartmentFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DepartmentsTableQuery = { __typename?: 'Query', departments: { __typename?: 'DepartmentConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges?: Array<{ __typename?: 'DepartmentEdge', node: { __typename?: 'DepartmentSchema', id: string, name: string, description?: string | null, departmentCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } }> | null } };


export const DepartmentsTableDocument = gql`
    query departmentsTable($filters: DepartmentFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  departments(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...DepartmentTableRow
      }
    }
  }
}
    ${DepartmentTableRowFragmentDoc}`;

export function useDepartmentsTableQuery(options?: Omit<Urql.UseQueryArgs<DepartmentsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<DepartmentsTableQuery>({ query: DepartmentsTableDocument, ...options });
};