import Receivables from 'app/i18n/Receivables';

export enum SCHEDULE_STEP {
  SCHEDULE_RECEIVING = 'schedule-receiving',
  SELECT_ITEMS_RECEIVE = 'select-receiving',
}

export function getOrderSteps() {
  return [
    {
      id: SCHEDULE_STEP.SCHEDULE_RECEIVING,
      label: Receivables.FormLabels.scheduleReceivingStep,
    },
    {
      id: SCHEDULE_STEP.SELECT_ITEMS_RECEIVE,
      label: Receivables.FormLabels.selectItemStep,
    },
  ];
}
