import { useCallback, useContext, useEffect, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import { WarningService } from 'app/components/WarningAlert';
import Orders from 'app/i18n/Orders';

import { OrderRequestContext } from '../../../../../../provider/OrderRequestContextProvider/OrderRequestContextProvider';
import useOrderRequestForm from '../../../OrderRequestForm/hook/useOrderRequestForm';
import { ORDER_INSERT_TYPE, OrderRequestInput } from '../../../OrderRequestForm/type';
import { getDefaultFormValues } from '../../../OrderRequestForm/utils/utils';
import { ORDER_REQUEST_TYPE } from '../../type';
import {
  cloneOrderItems,
  createOrderItem,
  removeOrderItems,
  updateItem,
  updateItems,
  updateItemsById,
} from '../../utils/utils';

interface State {
  orderItems: OrderRequestInput[];
}

const useNormalOrderRequest = (orderRequestType: ORDER_REQUEST_TYPE) => {
  const { getOrderItemsFromStore, getPartnerTenantId, storeDataInStorage, clearDataFromStorage } =
    useContext(OrderRequestContext);

  const [state, _setState] = useState<State>({
    orderItems: getOrderItemsFromStore(ORDER_REQUEST_TYPE.NORMAL_ORDER) || [],
  });

  const { formState, handleSubmit, onResetForm } = useOrderRequestForm({
    defaultValues: getDefaultFormValues({
      partnerTenantId: getPartnerTenantId(ORDER_REQUEST_TYPE.NORMAL_ORDER) || '',
      itemType: ORDER_INSERT_TYPE.ITEM_IN_STOCK,
    }),
  });

  useEffect(() => {
    if (orderRequestType === ORDER_REQUEST_TYPE.NORMAL_ORDER) {
      storeDataInStorage({
        orderItems: state.orderItems,
        partnerTenantId: formState.partnerTenantId,
        totalOrderItems: state.orderItems.length,
        orderRequestType: ORDER_REQUEST_TYPE.NORMAL_ORDER,
      });
    }
  }, [formState.partnerTenantId, state.orderItems, orderRequestType]);

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({
        ...prevState,
        ...nextState,
      }));
    },
    [_setState],
  );

  const onUpdateOrderItem = useCallback(
    (id: string, nextOrderItemState: Partial<OrderRequestInput>) => {
      setState({
        orderItems: [...updateItem(state.orderItems, id, nextOrderItemState)],
      });
    },
    [setState, state.orderItems],
  );

  const onUpdateOrderItems = useCallback(
    (nextState: Partial<OrderRequestInput>) => {
      setState({
        orderItems: updateItems(state.orderItems, nextState),
      });
    },
    [setState, state.orderItems],
  );

  const onUpdateDuplicateOrderItems = useCallback(
    (data: { update: Partial<OrderRequestInput>[]; delete: string[] }) => {
      let orderItems = cloneOrderItems(state.orderItems);
      // Updated existing order Item
      orderItems = updateItemsById(orderItems, data.update);
      // Remove DuplicateOrderItem
      orderItems = removeOrderItems(orderItems, data.delete);

      setState({
        orderItems,
      });
    },
    [setState, state.orderItems],
  );

  const onRemoveOrderItem = useCallback(
    (rowId: string) => {
      setState({
        orderItems: removeOrderItems(state.orderItems, [rowId]),
      });
    },
    [setState, state.orderItems],
  );

  const clearOrderItems = useCallback(() => {
    setState({
      orderItems: [],
    });
    clearDataFromStorage();
  }, [setState, clearDataFromStorage]);

  const onAddItem = useCallback(
    (newOrderItem: OrderRequestInput) => {
      setState({
        orderItems: createOrderItem(state.orderItems, newOrderItem),
      });
      SnackbarService.show({
        message: Orders.SuccessMessages.ItemAdded,
      });
    },
    [setState, state.orderItems],
  );

  const onPartnerTenantChange = useCallback(
    (id: string) => {
      if (state.orderItems.length > 0 && formState.partnerTenantId !== id) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.switchOrganizationWarning,
          onConfirm: () => {
            clearOrderItems();
            onResetForm();
            formState.setValue('partnerTenantId', id, { shouldValidate: true });
          },
        });
      } else {
        onResetForm();
        formState.setValue('partnerTenantId', id, { shouldValidate: true });
      }
    },
    [formState, clearOrderItems, state.orderItems.length, onResetForm],
  );

  return {
    normalOrderState: {
      orderItems: state.orderItems,
      formState,
    },
    normalOrderActions: {
      onAddItem,
      clearOrderItems,
      onUpdateDuplicateOrderItems,
      onUpdateOrderItems,
      onUpdateOrderItem,
      onRemoveOrderItem,
      handleSubmit,
      onResetForm,
      onPartnerTenantChange,
    },
  };
};

export type UseNormalOrderRequestReturnType = ReturnType<typeof useNormalOrderRequest>;

export default useNormalOrderRequest;
