import { ModuleVisibilityConfigType, TenantFeatureTypeEnum } from "app/types/schema";

type InitalValues = {
  value: string, 
  id: string
}

export const initalValues: InitalValues[] = [
  {
    value: 'assets',
    id: "assets",
  },
  {
    value: 'inventory',
    id: "inventory",
  },
  {
    value: 'companies',
    id: "contacts",
  },
  {
    value: 'orders',
    id: "orderRequest",
  },
  {
    value: 'shipping',
    id: "shipping",
  },
  {
    value: 'receiving',
    id: "receiving",
  },
  {
    value: 'reports',
    id: "report",
  },
  {
    value: 'admin',
    id: "admin",
  },
  {
    value: 'shops',
    id: "shop",
  },
  {
    value: 'it',
    id: "itAsset",
  },
  {
    value: 'wishlist',
    id: "wishlist",
  },
];

export const getIsModuleVisible = (initialValue: string, moduleVisibilityConfig?: ModuleVisibilityConfigType | null) => {
  const moduleName = initalValues.find(entry => entry.value === initialValue);
  const visibility = moduleVisibilityConfig?.[moduleName?.id as keyof ModuleVisibilityConfigType];
  return visibility === TenantFeatureTypeEnum.Show
};
