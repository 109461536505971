import { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import { SnackbarService } from '../../../../components/Snackbar';
import routes from '../../../../consts/routes';
import Inventory from '../../../../i18n/Inventory';
import { ItemTypeEnum, TransactionEntityTypeEnum } from '../../../../types/schema';
import RestockManager from '../../../assetsInventory/restock';
import { useCreateRestockTransactionsMutation } from '../../../assetsInventory/restock/graphql/mutations/generated/createRestockTransactions';
import useRestockItemState from '../../../assetsInventory/restock/hook/useRestockItemState';
import { getParentTransactionIdFormTransactions } from '../../../assetsInventory/utils/utils';
import EntityManager from '../../../components/EntityManager';
import analytics from 'app/analytics';

function RestockInventory(props: RouteComponentProps) {
  const { history } = props;
  const [{ fetching }, onCreateRestockTransactions] = useCreateRestockTransactionsMutation();

  const { formState, tableState, onFormSubmit, onResetFormAndTable, transactions } =
    useRestockItemState({
      transactionType: TransactionEntityTypeEnum.Inventory,
    });

  const onSaveRestockTransactions = useCallback(() => {
    onCreateRestockTransactions({
      input: {
        entities: transactions,
      },
    }).then((response) => {
      analytics?.track('Created', { name: 'Restock Inventory' });
      const createRestockTransactionsResponse =
        response?.data?.createRestockTransactions || undefined;
      const { transactions: createdTransactions, success } =
        createRestockTransactionsResponse || {};
      if (success) {
        SnackbarService.show({
          message: Inventory.SuccessMessages.InventoryRestocked,
        });
        const parentTransactionId = getParentTransactionIdFormTransactions(createdTransactions);
        if (parentTransactionId) {
          history.push(routes.ConfirmationInventoryEditTransactions(parentTransactionId));
        }
        onResetFormAndTable();
      } else {
        console.error('[Create Restock in Inventory] Failed', response);
      }
    });
  }, [transactions, onCreateRestockTransactions, onResetFormAndTable, history]);

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] pt-0">
      <EntityManager.Title
        documentTitle={Inventory.Restock}
        title={Inventory.Restock}
        hasTopDivider
      />
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box
          className="
            w-full
            pt-6 
            md:m-[0px]
            md:min-w-[416px]
            md:max-w-[416px]
            lg:m-[0px]
            lg:min-w-[416px] 
            lg:min-w-[416px]
            xl:m-[0px]
            xl:max-w-[416px]
            xl:max-w-[416px]
        ">
          <RestockManager.Form
            formState={formState}
            types={[ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit]}
            onFormSubmit={onFormSubmit}
          />
        </Box>
        <Box className="mt-[24px] flex-1">
          <Box
            className="h-full rounded-[12px] border border-grey-300"
            sx={{
              boxShadow:
                '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
            }}>
            <RestockManager.Table
              fetching={fetching}
              tableState={tableState}
              onSaveTransactions={onSaveRestockTransactions}
            />
          </Box>
        </Box>
      </Box>
    </EntityManager>
  );
}

export default withRouter(RestockInventory);
