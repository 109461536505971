import { useCallback, useEffect, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Network from 'app/i18n/Network';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { useBulkImportNetworkAssetsMutation } from 'app/modules/network/graphql/mutations/generated/bulkImportNetworkAssets';
import { useDeleteNetworkAssetsMutation } from 'app/modules/network/graphql/mutations/generated/deleteNetworkAssets';
import { NetworkAssetsFilters } from 'app/types/schema';

import useNetworkAssetTableRows from '../useNetworkAssetTableRows';
import { getPageSettingsFromStorage } from './../../../../../../utils/paginationSettingsUtil';

export const DisplayMode = {
  GRID: 'grid',
  LIST: 'list'
};

const useNetworkAssetsState = (disableSelection?: boolean) => {

  const [filterState, _setFilterState] = useState<NetworkAssetsFilters>({
    search: ""
  });

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [display, setDisplay] = useState(DisplayMode.LIST);

  const memoizedSetDisplay = useCallback((value: string) => setDisplay(value), []);

  const setState = useCallback((nextState: Partial<NetworkAssetsFilters>) => {
    _setFilterState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const [, bulkImportNetworkAssets] = useBulkImportNetworkAssetsMutation();
  const [, deleteNetworkAssets] = useDeleteNetworkAssetsMutation();

  //import IT assets to assets.
  const onImport = useCallback(
    (ids: string[]) => {
      bulkImportNetworkAssets({ input: { networkAssetIds: ids } }).then((response) => {
        if (response.data?.bulkImportNetworkAssets.success) {
          SnackbarService.show({ message: Network.SuccessMessages.ITAssetImported });
        }
      });
    },
    [bulkImportNetworkAssets],
  );

  const onConfirmDelete = useCallback(
    () => {
      setShowDeleteConfirmation(false)
      deleteNetworkAssets({ input: { deleteAllNetworkAssets: true } }).then((response) => {
        if (response.data?.deleteNetworkAssets.success) {
          SnackbarService.show({ message: Network.SuccessMessages.ITAssetDeleted });
        }
      });
    },
    [deleteNetworkAssets],
  );

  const onCancelDelete = () => {
    setShowDeleteConfirmation(false);
  }

  const onDelete = () => {
    setShowDeleteConfirmation(true);
  }

  const { search, table } = useMultiSelectEntityManager(disableSelection ? { hideActions: true } : { onImport });

  const networkAssetTableState = useNetworkAssetTableRows({
    pause: false,
    sorts: table.state.sorts,
    numberOfRowsPerPage: table.state.numberOfRowsPerPage,
    filters: {
      search: search.debouncedSearchText || filterState.search,
      motherboardModel: filterState.motherboardModel,
      batteryManufacturer: filterState.batteryManufacturer,
      operatingSystem: filterState.operatingSystem,
      cpuManufacturer: filterState.cpuManufacturer,
      cpuBrand: filterState.cpuBrand,
      statuses: filterState.statuses
    },
  });

  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(10);

  useEffect(() => {
    setState({ operatingSystem: [], search: '' })
    table.setState({ numberOfRowsPerPage: rowsPerPage, activePage: 0 })
    networkAssetTableState.onReset()
  }, [display])

  return {
    search,
    table,
    networkAssetTableState,
    filters: {
      state: filterState,
      setState,
    },
    display,
    memoizedSetDisplay,
    onDelete,
    showDeleteConfirmation,
    onCancelDelete,
    onConfirmDelete
  };
};

export type UseNetworkAssetsStateReturnType = ReturnType<typeof useNetworkAssetsState>;

export default useNetworkAssetsState;
