import { Button } from '@procurenetworks/procure-component-library';

import Common from '../../../../../i18n/Common';
import EntityManager from '../../../../components/EntityManager';
import { UseMoveInventoryStateReturnType } from '../../hook/useMoveState';
import { getMoveInventoryTableColumns } from './columns';
import Assets from '../../../../../i18n/Assets';

interface Props {
  tableState: UseMoveInventoryStateReturnType['tableState'];
  onSaveTransactions: () => void;
  fetching: boolean;
  pagination?: boolean;
  paginationWrapper?: string;
  foooterWrapperClass?: string;
}

const MoveTable = (props: Props) => {
  const {
    tableState,
    onSaveTransactions,
    fetching,
    pagination = false,
    paginationWrapper,
    foooterWrapperClass,
  } = props;

  const { table, onResetFormAndTable, totalRows, onRemoveTransaction, rows } = tableState;

  return (
    <>
      <EntityManager.Table
        actions={table.actions}
        columns={getMoveInventoryTableColumns()}
        data={rows}
        extraProps={{
          onRemoveTransaction,
        }}
        pagination={pagination}
        persistKey="move_table"
        setState={table.setState}
        state={table.state}
        total={totalRows}
        paginationWrapperClass={paginationWrapper}
      />
      <div className={`flex ${foooterWrapperClass}`}>
        <div className="flex flex-auto justify-end gap-[16px]">
          <Button
            disabled={totalRows === 0}
            loading={fetching}
            theme="success"
            onClick={onSaveTransactions}
            classes="min-w-[94px] h-[44px]">
            {Assets.Actions.Move}
          </Button>
          <Button classes="min-w-[94px] h-[44px]" disabled={fetching} onClick={onResetFormAndTable}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MoveTable;
