import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';
import PageTitle from 'app/ui-components/Title';
import clsx from 'clsx';
interface Props {
  children: React.ReactNode;
  className?: string;
  title: string;
  stackWrapperClass?: string;
  titleBarWrapperClass?: string;
  hasTopDivider?: boolean;
}

const ReportContainer = (props: Props) => {
  const {
    children,
    className,
    title,
    stackWrapperClass,
    titleBarWrapperClass,
    hasTopDivider = false,
  } = props;

  return (
    <Stack direction="col" className={clsx('pb-16] bg-white', stackWrapperClass)}>
      <PageTitle title={title} />
      <Box className={cx(titleBarWrapperClass, 'px-[24px]')}>
        <TitleBar title={title} hasTopDivider={hasTopDivider} />
      </Box>
      <Box className={className}>{children}</Box>
    </Stack>
  );
};

export default ReportContainer;
