import refreshTokens from '../libs/urql/refreshTokens';
import currentUser from '../modules/auth/utils/currentUser';
import { httpError5XXCodes, redirectToError5xxPage } from './httpCode';
import { nanoid } from 'nanoid';
import BugReportingService from '../libs/sentry';

export async function request(
  url: string,
  fetchOptions: RequestInit = {},
  requestConfig?: { auth: boolean },
) {
  if (requestConfig?.auth) {
    return withAuthInterceptor(url, fetchOptions);
  }

  const headers = fetchOptions.headers || {};
  return withoutAuthInterceptor(url, { ...fetchOptions, headers });
}

function createAuthRequestHandler(url: string, fetchOptions: RequestInit = {}) {
  return {
    async run(accessToken?: string) {
      return request(url, {
        ...fetchOptions,
        headers: {
          'x-request-id': nanoid(),
          ...(fetchOptions.headers || {}),
          Authorization: `Bearer ${accessToken || ''}`,
        },
      });
    },
  };
}

async function withoutAuthInterceptor(url: string, fetchOptions: RequestInit = {}): Promise<any> {
  const headers = {
    'x-request-id': nanoid(),
    ...(fetchOptions.headers || {}),
  };
  const response = await fetch(url, { ...fetchOptions, headers });

  if (!response.ok && httpError5XXCodes.has(response.status)) {
    BugReportingService.reportHttpError(new Error(`[${response.status}] ${url}`), {
      extra: {
        requestBody: fetchOptions.body,
        url: url,
      },
    });
    redirectToError5xxPage();
    return null;
  }

  return response;
}

async function withAuthInterceptor(url: string, fetchOptions: RequestInit = {}): Promise<any> {
  const requestHandler = createAuthRequestHandler(url, fetchOptions);

  let accessToken = currentUser.at();
  if (!accessToken && currentUser.rt()) {
    const tokenResults = await refreshTokens();
    accessToken = tokenResults.accessToken;
  }
  const response = await requestHandler.run(accessToken);

  if (!response.ok && httpError5XXCodes.has(response.status)) {
    BugReportingService.reportHttpError(new Error(`[${response.status}] ${url}`), {
      extra: {
        requestBody: fetchOptions.body,
        url: url,
      },
    });
    redirectToError5xxPage();
    return null;
  }

  if (!response.ok && response.status === 401) {
    const tokenResults = await refreshTokens();
    return requestHandler.run(tokenResults?.accessToken);
  }

  return response;
}
