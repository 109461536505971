import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import { AffiliateConfigurationFilters, SortProps } from 'app/types/schema';

import { useAffiliateMerchantsTableQuery } from '../../graphql/queries/generated/affiliateMerchantsTable';

interface Props {
  filters: AffiliateConfigurationFilters;
  siteId?: string;
  numberOfRowsPerPage: number;
  sorts: SortProps[];
  pause?: boolean;
}

const useAffiliateMerchantRows = (props: Props) => {
  const { filters, sorts, numberOfRowsPerPage, pause } = props;

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useAffiliateMerchantsTableQuery,
    {
      filters,
      sorts,
    },
    { edgeKey: 'affiliateConfigurations', pageSize: numberOfRowsPerPage, pause },
  );

  const rows = useMemo(() => {
    return data?.affiliateConfigurations.edges?.map(({ node }) => node) || [];
  }, [data?.affiliateConfigurations.edges]);

  return {
    rows,
    fetching,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.affiliateConfigurations.totalCount || 0,
  };
};

export default useAffiliateMerchantRows;
