import { cx } from '@emotion/css';
import Box from 'app/ui-components/Box';

interface Props {
  children: React.ReactNode;
}

const PrintLabelContainer = ({ children }: Props) => {
  return (
    <Box className={cx('w-full')}>
      <Box className={cx('flex max-w-[817px] m-auto flex-wrap items-center justify-center')}>
        {children}
      </Box>
    </Box>
  );
};

export default PrintLabelContainer;
