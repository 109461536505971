import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EditCategory from 'app/modules/categories/views/EditCategory';

function EditCategoryPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  return (
    <AdminLayout>
      <EditCategory categoryId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditCategoryPage);
