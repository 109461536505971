export enum ButtonHeight {
  fill,
}

export type Size = 'small' | 'medium';

export enum ButtonSize {
  small,
  medium,
}
