import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { Size } from 'app/types/button';
import Box from 'app/ui-components/Box';

import useDebounce from '../../../hooks/useDebounce';
import usePagination from '../../../hooks/usePagination';
import { ManufacturerSchema, SortOrderEnum } from '../../../types/schema';
import { adaptNodeEdgeToNode } from '../../AsyncMultiSelect/utils';
import LoadingOption from '../../LoadingOption';
import FormError from '../FormError';
import FormLabel from '../FormLabel';
import { useFormManufactureSelectQuery } from './graphql/generated/formManufactureSelect';

// TODO: We will enable pagination after pagination fixed by backend team.
const DEFAULT_PAGE_SIZE = 10000;

interface Props {
  className?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  name?: string;
  value?: string | null;
  onBlur?: () => void;
  onChange?: (value: string | undefined, option?: any) => void;
  error?: FieldError;
  placeholder?: string;
  autoFocus?: boolean;
  size?: Size;
}

type Option = Pick<ManufacturerSchema, 'id' | 'name'>;

const getOptionLabel = (option: Option) => `${option.name}`;

const FormManufactureSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  let {
    autoFocus,
    className,
    disabled,
    error,
    isRequired,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    size,
  } = props;

  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  const searchValue = useDebounce(inputValue);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  const [{ data: selectedData }] = useFormManufactureSelectQuery({
    variables: {
      filters: {
        manufacturerIds: value ? [value] : [],
      },
    },
    pause: !value || Boolean(value && selectedValue && selectedValue?.id === value),
  });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useFormManufactureSelectQuery,
    {
      filters: {
        search: searchValue,
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
    {
      pageSize: DEFAULT_PAGE_SIZE,
      edgeKey: 'manufacturers',
    },
  );

  useEffect(() => {
    const selectedOption = selectedData?.manufacturers?.edges?.[0]?.node;
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [selectedData?.manufacturers?.edges]);

  useEffect(() => {
    const newOptions: Option[] = data?.manufacturers?.edges?.map(adaptNodeEdgeToNode) || [];
    /*
      // TODO: We will enable pagination after pagination fixed by backend team.
      if (onPrevPage) {
        setOptions([...options, ...newOptions]);
      } else {
        setOptions(newOptions);
      }*/
    setOptions(newOptions);
  }, [data?.manufacturers?.edges]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: Option) => {
      setSelectedValue(option as Option);
      onChange?.(option?.id || '', option);
    },
    [onChange],
  );

  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;
      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !fetching) {
        // TODO: We will enable pagination after pagination fixed by backend team.
        // onNextPage();
      }
    },
    [onNextPage, fetching],
  );

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        ListboxProps={{
          onScroll: onScroll,
          role: 'list-box',
        }}
        className={cx(
          'mt-[6px] rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none',
          className,
        )}
        disabled={disabled}
        filterOptions={(option) => option}
        getOptionLabel={getOptionLabel}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        label={''}
        loading={fetching}
        openOnFocus={autoFocus}
        options={options}
        renderInput={(params) => <TextField {...params} name={name} placeholder={placeholder} />}
        renderOption={(props: any, option: Option) => {
          const showLoadingOption = !!(
            options.indexOf(option) === options.length - 1 && onNextPage
          );
          return (
            <div key={option?.id} {...props}>
              <div>
                <span>{option.name}</span>
                {showLoadingOption ? (
                  <div>
                    <LoadingOption />
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
        size={size}
        value={selectedValue as any}
        onBlur={onBlur}
        onChange={onValueChange}
        onInputChange={(_: any, value: string) => {
          onReset?.();
          setInputValue(value || '');
        }}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormManufactureSelect;
