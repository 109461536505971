import { cloneDeep } from 'lodash';
import { AssetReminderInput } from 'app/modules/assets/components/AssetForm/components/AssetReminderField/components/AssetReminderForm/types';

import {
  AssetItemReminder,
  AssetItemReminderTypeEnum,
  AssetItemSchema,
  ItemExternalProductCodeTypeEnum,
} from '../../../../../../types/schema';
import { format } from '../../../../../../utils/cost';
import { isCategoryActive } from '../../../../../categories/utils/utils';
import { isCompanyActive } from '../../../../../contacts/utils/utils';

export const prepareFormData = (assetItem: AssetItemSchema) => {
  const {
    id,
    sku,
    attachments,
    unitCost,
    brand,
    category,
    vendor,
    type,
    pickableThroughOrderRequest,
    costOverride,
    title,
    description,
    formattedDescription,
    mName,
    externalProductCodes,
    protectedAttachments,
    serialNumber,
    purchaseDate,
    modelNumber,
    warrantyExpiryDate,
    replacementDate,
    manufacturerId,
    parentId,
  } = assetItem;

  const externalUpcCodeData =
    (externalProductCodes || [])?.find(
      (externalProductCode) => externalProductCode.type === ItemExternalProductCodeTypeEnum.Upc,
    ) || undefined;

  return {
    type,
    noUpcCode: externalUpcCodeData === undefined,
    upcCode: externalUpcCodeData?.code || '',
    sku,
    title,
    mName,
    modelNumber,
    brand,
    categoryId: category?.id && isCategoryActive(category?.status) ? category?.id : '',
    serialNumber,
    description: formattedDescription || description,
    vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
    unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
    costOverride: costOverride === null ? '0' : costOverride,
    reminder: [],
    purchaseDate,
    warrantyExpiryDate,
    replacementDate: replacementDate || null,
    pickableThroughOrderRequest,
    attachments: attachments || [],
    protectedAttachments,
    id,
    manufacturerId: manufacturerId || '',
    parentId: parentId || '',
  };
};

export const prepareRemindersFormData = (
  reminders: AssetItemReminder[],
  assetItemId: string,
): AssetReminderInput[] => {
  const _reminders = cloneDeep(reminders);
  const preparedReminders = _reminders?.map((reminder) => {
    const {
      cron,
      startReminderAt,
      type,
      _id,
      note,
      formattedNote,
      recurringInterval,
      notifyUserIds,
    } = reminder;
    return {
      assetItemId: assetItemId,
      isMaintenance: type === AssetItemReminderTypeEnum?.Maintenance,
      cron,
      note: formattedNote || note,
      notifyUserIds,
      id: _id,
      startReminderAt: new Date(startReminderAt) as unknown as string,
      recurringInterval,
    } as AssetReminderInput;
  });
  return preparedReminders || [];
};
