import { useCallback, useRef, useState } from 'react';
import { request } from '../../../../../utils/request';
import currentUser from '../../../../auth/utils/currentUser';
import { API_URL, APP_CONFIG } from '../../../../../consts/config';

const ZEND_DESK_LINK = `${API_URL}/auth/zendesk/sso`;

const HelpNavLink = () => {
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const openZendeskLink = useCallback(async () => {
    try {
      const response = await request(
        ZEND_DESK_LINK,
        {
          method: 'post',
          body: JSON.stringify({
            accessToken: currentUser.at(),
          }),
        },
        {
          auth: true,
        },
      );

      const data = await response.json();
      const redirectionUrl = data.url;

      if (redirectionUrl) {
        const url = new URL(redirectionUrl);
        const token = url.searchParams.get('jwt');
        const baseUrl = url.origin + url.pathname;

        setJwtToken(token);

        const form = formRef.current;
        if (form && token) {
          form.action = baseUrl
          form.submit();
        }
      }

    } catch (error) {
      console.error('Failed to redirect to Zendesk link', error);
    }
  }, []);

  return (
    <form
      className="app-unified-header-nav-menu-item flex items-center"
      ref={formRef}
      method="POST"
      target="_blank"
    >
      <input type="hidden" value={jwtToken || ""} name="jwt" />
      <input type="hidden" value={APP_CONFIG.uiUrls.zendesk} name="return_to" />

      <button
        type="button"
        className="bg-white px-[18px] font-semibold text-blue-900"
        onClick={openZendeskLink}
      >
        Help
      </button>
    </form>
  );
};

export default HelpNavLink;
