import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props {
  display: string;
  setDisplay: any;
}

const SelectDisplay = (props: Props) => {
  const { display, setDisplay } = props;
  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}
        className="w-full md:w-[374px] lg:w-[180px]">
        <FormControl className="w-full md:w-[374px] lg:w-[180px]">
          <InputLabel htmlFor="display-option">Display Option</InputLabel>
          <Select
            inputProps={{
              name: 'DisplayOption',
              id: 'Display Option',
            }}
            label="Display Option"
            value={display}
            onChange={(event) => {
              setDisplay(event.target.value);
            }}>
            <MenuItem value="grid">Grid</MenuItem>
            <MenuItem value="list">List</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default SelectDisplay;
