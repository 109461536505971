import AdminLayout from 'app/components/AdminLayout';
import AssetModels from 'app/modules/assetModels/views/AssetModels';

function AssetModelsPage() {
  return (
    <AdminLayout>
      <AssetModels />
    </AdminLayout>
  );
}

export default AssetModelsPage;
