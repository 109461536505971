import { CreateAffiliateConfigurationInput, UpdateAffiliateConfigurationInput } from "app/types/schema";

export const createAffiliateConfigurationInputDTO = (
  values: any,
): CreateAffiliateConfigurationInput => {
  const {
    name,
    logoUrl,
    domain,
    affiliateUrl,
    commission,
    status,
  } = values || {};

  let logo = '';
  if (logoUrl && logoUrl?.length > 0) {
    logo = logoUrl[0]?.url
  }

  return {
    name: name?.trim(),
    logoUrl: logo,
    domain,
    affiliateUrl,
    commission: commission ? Number(commission) : 0,
    status,
  };
};

export const createUpdateAffiliateConfigurationInputDTO = (
  affiliateConfigurationId: string,
  values: any,
): UpdateAffiliateConfigurationInput => {
  const {
    name,
    logoUrl,
    domain,
    affiliateUrl,
    commission,
    status,
  } = values;

  let logo = '';
  if (logoUrl && logoUrl?.length > 0) {
    logo = logoUrl[0]?.url
  }

  return {
    affiliateConfigurationId: affiliateConfigurationId || '',
    name: name?.trim(),
    logoUrl: logo,
    domain,
    affiliateUrl,
    commission: commission ? Number(commission) : 0,
    status,
  };
};
