import {RouteComponentProps} from "app/libs/navigation";
import InventoriesLayout from "app/modules/inventory/layout";
import EditTransactions from "app/modules/assetsInventory/Views/Confirmation/EditTransactions";
import {withRouter} from "app/libs/navigation";


const EditTransactionsPage = (props: RouteComponentProps<{ id: string }>) => {
    const { match } = props;
    return (
        <InventoriesLayout>
            <EditTransactions parentTransactionId={match.params.id} />
        </InventoriesLayout>
    )
}

export default withRouter(EditTransactionsPage)