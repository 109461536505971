import { ORDER_VIEW, OrderDetailsData } from '../../type';
import { useMemo } from 'react';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../../../types/schema';
import { groupByOrderItems } from './utils';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { useAccessControl } from '../../../../../../components/AccessControl';
import useMultiSelectEntityManager from '../../../../../components/EntityManager/useMultiSelectEntityManager';

const useOrderRequestItems = (props: {
  orderRequestItems: OrderDetailsData['orderRequestItems'];
  filtersOrderRequestItems: OrderDetailsData['orderRequestItems'];
  orderView: ORDER_VIEW;
}) => {
  const { filtersOrderRequestItems, orderRequestItems, orderView } = props;
  const { workspacePermissions } = useCurrentUser();

  const { table: assetTable } = useMultiSelectEntityManager({
    selection: orderView === ORDER_VIEW.RETURN_ORDER_VIEW,
  });

  const { table: inventoryTable } = useMultiSelectEntityManager({
    selection: orderView === ORDER_VIEW.RETURN_ORDER_VIEW,
  });

  const { table: specialTable } = useMultiSelectEntityManager({
    selection: orderView === ORDER_VIEW.RETURN_ORDER_VIEW,
  });

  const canViewAsset = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderAsset,
  );

  const canViewInventory = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderInventory,
  );

  const canViewNoSku = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderNoSku,
  );

  const { isSpecialOrderItemsFound, isInventoryItemsFound, isAssetItemFound } = useMemo(() => {
    const { specialOrderRows, assetRows, inventoryRows } = groupByOrderItems(orderRequestItems);

    return {
      isAssetItemFound: assetRows.length > 0,
      isInventoryItemsFound: inventoryRows.length > 0,
      isSpecialOrderItemsFound: specialOrderRows.length > 0,
    };
  }, [orderRequestItems]);

  const { assetRows, inventoryRows, specialOrderRows } = useMemo(() => {
    return groupByOrderItems(filtersOrderRequestItems);
  }, [filtersOrderRequestItems, assetTable.state, inventoryTable.state, specialTable.state]);

  return {
    canViewAsset: canViewAsset && isAssetItemFound,
    canViewInventory: canViewInventory && isInventoryItemsFound,
    canViewNoSku: canViewNoSku && isSpecialOrderItemsFound,
    assetRows,
    inventoryRows,
    specialOrderRows,
    assetTable,
    inventoryTable,
    specialTable,
  };
};

export default useOrderRequestItems;
