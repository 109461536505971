import { cx } from '@emotion/css';
import CloseIcon from '@mui/icons-material/Close';
import { Card, Dialog } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components';

import { Offer } from '../../hook/useHooverShopProducts';
import { CardContainer, Item, VendorsDialog } from './styles';
import { Typography } from '@mui/material';

interface Props {
  data?: Offer[];
  openSingleVendorModal: boolean;
  setSingleVendorModal: (value: boolean) => void;
  renderLogo: (merchant: any) => JSX.Element;
  renderVendorImage: (nodes: any[]) => any[] | undefined;
  _handleRedirectShop: (url: string, event: any) => void;
  handleShowDialog: (image: string) => void;
}

export default function VendorModal({
  openSingleVendorModal,
  setSingleVendorModal,
  data,
  renderLogo,
  _handleRedirectShop,
  handleShowDialog,
}: Props) {
  const handleClose = () => {
    setSingleVendorModal(false);
  };

  const filteredData = data?.filter((item) => item?.merchant);

  const body = (
    <>
      <button className={'absolute top-[10px] right-[15px] '} type="button" onClick={handleClose}>
        <CloseIcon className="text-red-700" />
      </button>

      <CardContainer items={filteredData?.length}>
        {filteredData &&
          filteredData.length > 0 &&
          filteredData.map((item, index) => (
            <Box key={index} className={cx(Item)}>
              <Card
                withImage
                cardActions={
                  <Box onClick={(event) => _handleRedirectShop(item.clickURL || '' || '', event)}>
                    <Typography className="text-[12px] text-grey-800">
                      {renderLogo(item.merchant)}
                    </Typography>
                  </Box>
                }
                data={item}
                descriptionNode={
                  <Box className="flex justify-between gap-[16px]">
                    <Box>
                      <p
                        className="text-[14px] font-medium text-grey-900"
                        onClick={(event) => _handleRedirectShop(item.clickURL || '', event)}>
                        {item.name}
                      </p>
                    </Box>
                    <Box>
                      <p className="overflow-hidden text-ellipsis text-center text-[14px] font-extrabold text-[#469ed4]">{`$${item?.currentPrice?.amount}`}</p>
                    </Box>
                  </Box>
                }
                imageHeight={290}
                imagePath={item?.images?.nodes[0]?.original}
                maxTitleLine={1}
                target="_blank"
                title=""
                onCardClick={() => handleShowDialog(item?.images?.nodes[0]?.original)}
              />
            </Box>
          ))}
      </CardContainer>
    </>
  );

  return (
    <Dialog className={cx(VendorsDialog)} id="dialogBox" open={openSingleVendorModal} title="">
      {body}
    </Dialog>
  );
}
