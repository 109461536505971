import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/ProcureForm/FormLabel';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

const INTERNAL = 'INTERNAL';
const EXTERNAL = 'EXTERNAL';

interface Option {
  id: string | undefined;
  name: string | undefined;
  type: string;
}

const getOptionLabel = (option: Option) => option.name || '';
const groupByOption = (option: Option) => option.type;

interface Props {
  value: string;
  label?: string;
  isRequired?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  formLabel?: string;
}

const FormPartnerTenantSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { value, onChange, isRequired, error, label, placeholder, formLabel } = props;
  const currentUser = useCurrentUser();
  const { tenant, parentPartnerTenants } = currentUser;
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const options = useMemo(() => {
    let optionData = [
      {
        id: tenant?.id,
        name: tenant?.name,
        type: INTERNAL,
      },
    ];

    if (parentPartnerTenants && parentPartnerTenants.length) {
      parentPartnerTenants.forEach((item) => {
        if (item.id !== tenant?.id) {
          optionData.push({
            ...item,
            type: EXTERNAL,
          });
        }
      });
    }

    return optionData;
  }, [tenant, parentPartnerTenants]);

  const onValueChange = useCallback(
    (event: any, option: any) => {
      onChange(option ? option.id : '');
    },
    [onChange],
  );

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  useEffect(() => {
    if (value && options && selectedValue && selectedValue.id !== value) {
      setSelectedValue(options.find((item) => item.id === value) || null);
    } else if (value && !selectedValue && options) {
      setSelectedValue(options.find((item) => item.id === value) || null);
    }
  }, [value, selectedValue, options]);

  const LabelNode = () => <FormLabel isRequired={isRequired}>{label}</FormLabel>;

  return (
    <div className="mt-[22px] mb-[20px]" ref={ref}>
      {formLabel ? (
        <FormLabel isRequired={isRequired} className="text-[14px] font-semibold text-grey-800">
          {formLabel}
        </FormLabel>
      ) : null}
      <Autocomplete
        disableClearable
        getOptionLabel={getOptionLabel}
        groupBy={groupByOption}
        label=""
        className="mt-6"
        options={options}
        renderInput={(params) => (
          <>
            <TextField
              autoFocus
              {...params}
              error={!!error}
              errorMessage={error ? (error.message as string) : ''}
              labelNode={LabelNode}
              placeholder={placeholder}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        )}
        size="medium"
        value={selectedValue}
        onChange={onValueChange}
      />
    </div>
  );
});

export default FormPartnerTenantSelect;
