import { Button, Dialog } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

interface Props {
  onClose: () => void;
}

const AuditCreateError = (props: Props) => {
  const { onClose } = props;

  return (
    <Dialog
      open
      actions={
        <Button classes="self-end" theme="success" onClick={onClose}>
          {Common.Actions.Ok}
        </Button>
      }
      id="audit-create-error"
      title="Error"
      onClose={onClose}>
      <Stack alignItems="start" className="w-[600px]" direction="col">
        <p className="-ml-[4px] text-13 text-grey-800">
          Please audit at least one item to save your Audit Report.
        </p>
        <Box className="-mx-24 mt-24 -mb-16 h-1 self-stretch bg-[#e5e5e5]" />
      </Stack>
    </Dialog>
  );
};

export default AuditCreateError;
