import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteCategoriesMutationVariables = Types.Exact<{
  input: Types.DeleteCategoriesInput;
}>;


export type DeleteCategoriesMutation = { __typename?: 'Mutation', deleteCategories: { __typename?: 'MutationResponse', success: boolean, reasons?: Array<string> | null } };


export const DeleteCategoriesDocument = gql`
    mutation deleteCategories($input: DeleteCategoriesInput!) {
  deleteCategories(input: $input) {
    success
    reasons
  }
}
    `;

export function useDeleteCategoriesMutation() {
  return Urql.useMutation<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>(DeleteCategoriesDocument);
};