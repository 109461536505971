import React from 'react';

type Props = {
  searchValue: string,
  data: string[],
  type: string,
  onSearchData: (values: string, index: number) => void;
};

const SearchHelper = (props: Props) => {
  const { searchValue, data, type, onSearchData } = props;

  const BoldedText = (text: string) => {
    const textArray = text.split(searchValue);
    return (
      <span>
        {textArray.map((item: string, index: number) => (
          <>
            {item}
            {index !== textArray.length - 1 && (
              <b>{searchValue}</b>
            )}
          </>
        ))}
      </span>
    );
  }

  return (
    <div>
      {
        !!data.length &&
        <ul className="block border-gray-300 z-10 box-border bg-[#f9f9f9] w-11/12 shadow-lg shadow-black-500/50 absolute">
          { 
            data.map((value, index) => (
              <li className="bg-white px-16 py-12 relative cursor-pointer hover:bg-[#66ccff] hover:text-gray-900"
                key={index} 
                onClick={() => onSearchData(value, index)}
              >
                <div>
                  {BoldedText(value)}
                </div>
              </li>
            ))
          }
        </ul>
      }
    </div>
  );
};

SearchHelper.defaultProps = {
  loading: false,
  visible: false,
  searchValue: '',
  data:[],
};

export default SearchHelper;
