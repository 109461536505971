import {
  composeValidators,
  emailValidator,
  emptyStringValidator,
  regexValidator,
} from '../../../../components/Form/utils/validators';
import Contact from '../../../../i18n/Contact';

export const CONTACT_FORM_RULES = {
  email: {
    validate: composeValidators(
      emptyStringValidator(Contact.FormValidationMessages.EmailInvalid),
      emailValidator(Contact.FormValidationMessages.EmailInvalid),
    ),
  },
  fax: {
    validate: regexValidator(Contact.FormValidationMessages.FaxInvalid, /^\+?[0-9]+$/),
  },
};
