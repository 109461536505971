import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import DiscoveredAssets from 'app/modules/network/view/discoveredAssets';

function NetworkDiscoveredAssetsPage() {
  return (
    <NetworkAssetLayout>
      <DiscoveredAssets />
    </NetworkAssetLayout>
  );
}

export default NetworkDiscoveredAssetsPage;
