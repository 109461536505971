import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Location from 'app/i18n/Location';
import Box from 'app/ui-components/Box';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import FormSiteSelect from '../FormSiteSelect';
import { LocationFormProps } from './types';
import { LOCATION_FORM_RULES as rules } from './utils';

function AddLocationForm(props: LocationFormProps) {
  const { defaultValues, disabled, onCancel, onSubmit, isEditMode } = props;

  const { control, handleSubmit } = useForm({ defaultValues });
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const locationValues = {
          ...values,
          name: removeMultipleSpaces(values.name),
          description: removeExtraSpacesAndNewlines(values?.description || ''),
          formattedDescription: values?.description,
        };
        onSubmit(locationValues);
      }),
    [handleSubmit, onSubmit],
  );

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              characterLimit={30}
              error={fieldState.error}
              label={Location.FormLabels.Name}
            />
          )}
          rules={rules.name}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FormTextArea
              {...field}
              characterLimit={500}
              error={fieldState.error}
              label={Location.FormLabels.Description}
            />
          )}
          rules={rules.description}
        />
        <Controller
          control={control}
          name="siteId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              className="flex-1"
              disabled={isEditMode}
              error={fieldState.error}
              label={Location.FormLabels.Site}
              selectClassName="mt-6"
            />
          )}
          rules={rules.siteId}
        />
        <Box className="flex justify-end space-x-16">
          <Button
            disabled={disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default AddLocationForm;
