import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import { SelectOption } from 'app/components/Select/types';
import Reports from 'app/i18n/Reports';

interface Props {
  disableExport?: boolean;
  control: any;
}

const getReportFormatOptions = (): SelectOption[] => {
  return [
    { value: 'preview', label: Reports.ReportFormat.PreviewReport },
    { value: 'csv', label: Reports.ReportFormat.CSVExport },
    // { value: 'pdf', label: Reports.ReportFormat.PDFExport },
  ];
};

const ReportFormatFormField = (props: Props) => {
  const { disableExport, control } = props;

  const options = useMemo(() => {
    const formatOptions = getReportFormatOptions();

    if (!disableExport) {
      return formatOptions;
    }

    return formatOptions.filter((option) => option.value !== 'pdf' && option.value !== 'csv');
  }, [disableExport]);

  return (
    <Controller
      control={control}
      name="format"
      render={({ field, fieldState }) => (
        <FormSelect
          {...field}
          disableClearable={true}
          error={fieldState.error}
          label={Reports.ReportFormat.ReportFormat}
          options={options}
        />
      )}
    />
  );
};

export default ReportFormatFormField;
