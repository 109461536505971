import { Link } from 'app/libs/navigation';
import { useMemo } from 'react';
import { getOrderDetailsLinkByType } from '../../../../orders/utils/utils';
import { ShippingTransactionSchema } from '../../../../../types/schema';

interface Props {
  row: ShippingTransactionSchema;
}

const ShipOrderIdToolTip = (props: Props) => {
  const { row } = props;
  const { items, orderRequest, id } = row;
  const { id: orderId, type } = orderRequest || {};

  const orderDetailLink = useMemo(
    () => getOrderDetailsLinkByType(orderId || '', type),
    [type, orderId],
  );

  return (
    <div className="order-id-tooltip">
      <div
        className={items.length > 6 ? 'preview-container' : 'preview-tooltip'}
        style={{ width: '210px' }}>
        {items?.map((item, index) => (
          <div key={`shipment-search-order-previewItem-${id}-${orderId}-${item?.item.id}`}>
            <p className="order-item-title">{item?.item?.title || ''}</p>
          </div>
        ))}

        <div className="footer-divider" />
        <Link className="linkText" to={orderDetailLink}>
          View this order request &gt;
        </Link>
      </div>
    </div>
  );
};

export default ShipOrderIdToolTip;
