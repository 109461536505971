import { cx } from '@emotion/css';
import { createContext } from 'app/context/createContext';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { AllowedPermissionsSubjectEnum, UserWorkspacePermission } from 'app/types/schema';
import Box, { BoxProps } from 'app/ui-components';

import EntityManagerAdd from './EntityManagerAdd';
import EntityManagerCollapsibleTable from './EntityManagercollapsibleTable';
import EntityManagerMultiSelectTable from './EntityManagerMultiSelectTable';
import EntityManagerSearch from './EntityManagerSearch';
import EntityManagerTable from './EntityManagerTable';
import EntityManagerTitle from './EntityManagerTitle';
import { titlebarStyles } from './styles';

interface Composition {
  Add: typeof EntityManagerAdd;
  Search: typeof EntityManagerSearch;
  Table: typeof EntityManagerTable;
  MultiSelectTable: typeof EntityManagerMultiSelectTable;
  CollapsibleTable: typeof EntityManagerCollapsibleTable;
  Title: typeof EntityManagerTitle;
}

interface ContextValueType {
  permissions: UserWorkspacePermission[];
  subject?: AllowedPermissionsSubjectEnum;
}

const [Provider, useEntityManagerContext] = createContext<ContextValueType>('EntityManager');

type Props = BoxProps & Pick<ContextValueType, 'subject'>;

const EntityManager: React.FC<Props> & Composition = (props) => {
  const { children, subject, layoutWrapperClass = false, className, ...rest } = props;
  const { workspacePermissions } = useCurrentUser();

  return (
    <Provider permissions={workspacePermissions} subject={subject}>
      <Box
        className={cx(
          `
        bg-white 
        ${layoutWrapperClass && '!rounded-tr-[12px] rounded-br-[12px]'}
        px-16 pt-6 pb-16 sm:px-16 md:px-32 lg:px-32`,
          titlebarStyles,
          className,
        )}
        {...rest}>
        {children}
      </Box>
    </Provider>
  );
};

EntityManager.Add = EntityManagerAdd;
EntityManager.Search = EntityManagerSearch;
EntityManager.Table = EntityManagerTable;
EntityManager.MultiSelectTable = EntityManagerMultiSelectTable;
EntityManager.CollapsibleTable = EntityManagerCollapsibleTable;
EntityManager.Title = EntityManagerTitle;

export { useEntityManagerContext };
export default EntityManager;
