import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddShelvesMutationVariables = Types.Exact<{
  input: Types.AddShelvesInWarehouseInput;
}>;


export type AddShelvesMutation = { __typename?: 'Mutation', addShelvesInWarehouse: { __typename?: 'WarehouseMutationPayload', warehouse?: { __typename?: 'WarehouseSchema', warehouseType: Types.WarehouseTypeEnum, racks: number, shelves: number } | null, locations?: Array<{ __typename?: 'LocationSchema', id: string, name: string, type: Types.LocationTypeEnum, site: { __typename?: 'LocationSchema', id: string, name: string } }> | null } };


export const AddShelvesDocument = gql`
    mutation addShelves($input: AddShelvesInWarehouseInput!) {
  addShelvesInWarehouse(input: $input) {
    warehouse {
      warehouseType
      racks
      shelves
    }
    locations {
      id
      name
      type
      site {
        id
        name
      }
    }
  }
}
    `;

export function useAddShelvesMutation() {
  return Urql.useMutation<AddShelvesMutation, AddShelvesMutationVariables>(AddShelvesDocument);
};