import { saveAs } from './file-saver';

const downloadFile = (file: string | Blob | undefined | null, fileName?: string) => {
  if (!file) {
    return;
  }

  saveAs(file, fileName);
};

export default downloadFile;
