import { Button } from '@procurenetworks/procure-component-library';
import Orders from '../../../../i18n/Orders';
import Common from '../../../../i18n/Common';
import { UseMultiSelectEntityManagerReturnType } from '../../../components/EntityManager/useMultiSelectEntityManager';
import { useMemo } from 'react';

interface Props {
  onCancel: () => void;
  disabled?: boolean;
  onReturnOrder: () => void;
  tableState: {
    selectedRowIds: string[];
  };
}

const ReturnOrderDetailActions = (props: Props) => {
  const { disabled, onCancel, onReturnOrder, tableState } = props;

  return (
    <div className="flex justify-end">
      <div>
        <Button
          theme="success"
          onClick={onReturnOrder}
          disabled={tableState.selectedRowIds.length === 0}
          loading={disabled}>
          {Orders.Actions.CompleteReturn}
        </Button>
      </div>
      <div className="ml-[4px]">
        <Button classes="cancelOrderBtn" onClick={onCancel} disabled={disabled}>
          {Common.Actions.Cancel}
        </Button>
      </div>
    </div>
  );
};

export default ReturnOrderDetailActions;
