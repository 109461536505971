import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { AddressSchema } from 'app/types/schema';

import { useAddressesTableQuery } from '../../components/addressList/graphql/queries/generated/addressesTable';

const useAddressSearchSuggestion = ( companyId?: string ) => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useAddressesTableQuery({
    variables: {
      limit: 7,
      filters: {
        search: searchedValue,
        companyIds: companyId ? [companyId] : [] 
      },
    },
  });

  useEffect(() => {
    const result = data?.addresses?.edges?.map((a) => {
      const item = a.node as AddressSchema
      
      const queryWords = searchedValue ? searchedValue.trim().toLowerCase().split(" ") : [""];

      let result = null;

      if (queryWords.some(word => item.addressName.toLowerCase().includes(word))) {
        result = item.addressName;
      } else if (queryWords.some(word => item.zipCode.toLowerCase().includes(word))) {
        result = item.zipCode;
      } else if (queryWords.some(word => item.state.toLowerCase().includes(word))) {
        result = item.state;
      } else if ( queryWords.some(word => item.city.toLowerCase().includes(word))) {
        result = item.city;
      } else if (queryWords.some(word => item.addressLineOne.toLowerCase().includes(word))) {
        result = item.addressLineOne;
      } else if ( item.addressLineTwo && queryWords.some(word => (item.addressLineTwo || "").toLowerCase().includes(word))) {
        result = item.addressLineOne;
      } 

      return {
        label: result || item.addressName,
        id: item.id,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseContactSearchSuggestionReturnType = ReturnType<typeof useAddressSearchSuggestion>;

export default useAddressSearchSuggestion;
