import { Controller } from 'react-hook-form';
import FormManufactureSelect from 'app/components/Form/FormManufactureSelect';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';

const AssetModelManufacturerField = (props: AssetModelFieldProps) => {
  let { disabled, formState } = props;
  const { control } = formState;
  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="manufacturerId"
        render={({ field, fieldState }) => (
          <FormManufactureSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={AssetModel.FormLabels.Manufacturer}
          />
        )}
      />
    </div>
  );
};

export default AssetModelManufacturerField;
