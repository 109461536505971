import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import { getTransactionFilterValues } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';

import { getAssetTransactionEntityTypes } from '../../../../utils/itemtypes';
import { useAssetTransactionReportQuery } from '../../graphql/generated/assetTransactionReport';
import { TransactionReportFormValues } from '../TransactionReportForm/types';
import { getAssetTransactionTableColumns } from './columns';
import { Box } from '@mui/material';

interface Props {
  values: TransactionReportFormValues;
}

const AssetTransactionTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useAssetTransactionReportQuery,
    {
      filters: {
        _and: [
          { _or: [{ destinationSiteIds: values.siteIds }, { sourceSiteIds: values.siteIds }] },
          { _or: getTransactionFilterValues(values.transactionTypes) },
        ],
        entityIds: values.skuIds,
        createdAt: values.createdAt,
        createdByIds: values.userIds,
        projectIds: values.projectIds,
        categoryIds: values.categoryIds,
        departmentIds: values.departmentIds,
        entityTypes: getAssetTransactionEntityTypes(),
      },
      sorts: [{ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc }],
    },
    { edgeKey: 'transactions', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.transactions.edges?.map(({ node }) => node) || [];
  }, [data?.transactions.edges]);

  const columns = useMemo(() => {
    let assetColumns = getAssetTransactionTableColumns();

    if(!values.transactionTypes.includes('remove')){
      assetColumns = assetColumns.filter(col => col.id !== 'reason')
    }
    return assetColumns
  }, [values.transactionTypes])

  return (
    <Box className='mx-[24px] mt-[24px] border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
    <ReportPreviewTable
      columns={columns}
      data={rows}
      initialLoading={initialFetching}
      loading={fetching}
      pagination={true}
      persistKey="asset_transaction_table"
      setState={table.setState}
      state={table.state}
      total={data?.transactions.totalCount}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationClass='p-[24px]'
      className='px-[0px]'
    />
    </Box>
  );
};

export default AssetTransactionTable;
