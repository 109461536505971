import times from 'lodash/times';
import { SelectOption } from 'app/components/Select/types';
import Label from 'app/i18n/Label';

import { LabelCustomEnum, LabelFormat, LabelStockEnum, LabelTypeEnum } from '../types';

export const getLabelTypeOptions = (): SelectOption[] => {
  return [
    { value: 'selectOne', label: Label.Form.LabelTypeOptions.Selectone },
    { value: LabelTypeEnum.Asset, label: Label.Form.LabelTypeOptions.Asset },
    // { value: LabelTypeEnum.AssetKit, label: Label.Form.LabelTypeOptions.AssetKit },
    { value: LabelTypeEnum.Inventory, label: Label.Form.LabelTypeOptions.Inventory },
    // { value: LabelTypeEnum.InventoryKit, label: Label.Form.LabelTypeOptions.InventoryKit },
    { value: LabelTypeEnum.Location, label: Label.Form.LabelTypeOptions.Location },
    { value: LabelTypeEnum.Shipping, label: Label.Form.LabelTypeOptions.Shipping },
    { value: LabelTypeEnum.ShippingSetUp, label: Label.Form.LabelTypeOptions.ShippingLabelSetup },
    // { value: 'warehouseSetup', label: Label.Form.LabelTypeOptions.WarehouseSetup },
  ];
};

export const getArrowDisplayOptions = (): SelectOption[] => {
  return [
    { value: 'no', label: Label.Form.ArrowDisplayOptions.NoArrow },
    { value: 'up', label: Label.Form.ArrowDisplayOptions.UpArrow },
    { value: 'down', label: Label.Form.ArrowDisplayOptions.DownArrow },
  ];
};

export const getInventoryLabelFormatOptions = (labelType: string): SelectOption[] => {
  if (labelType !== LabelTypeEnum.Shipping) {
    return [
      {
        value: LabelFormat.SkuSiteLocation,
        label: Label.Form.InventoryLabelFormatOptions.SKUSiteLocation,
      },
      {
        value: LabelFormat.TitleLocation,
        label: Label.Form.InventoryLabelFormatOptions.TitleLocation,
      },
      { value: LabelFormat.TextOnly, label: Label.Form.InventoryLabelFormatOptions.TextOnly },
      { value: LabelFormat.SkuOnly, label: Label.Form.InventoryLabelFormatOptions.SkuOnly },
    ];
  }
  if (labelType === LabelTypeEnum.Shipping) {
    return [{ value: '1', label: 'Bins' }];
  }
  return [];
};
export const getLabelOffsetOptions = (): SelectOption[] => {
  return [
    { value: '0', label: Label.None },
    ...times(31).map((_, index) => ({ value: `${index + 1}`, label: `${index + 1}` })),
  ];
};

export const getLabelCustomOptions = (): SelectOption[] => {
  return [
    { value: 'Default', label: Label.Form.LabelCustomOptions.Default },
    { value: 'Custom', label: Label.Form.LabelCustomOptions.Custom },
  ];
};

export const getCustomFieldOptions = (
  labelType?: string | undefined | null,
  customField?: string | undefined | null,
): SelectOption[] => {
  if (customField === LabelCustomEnum.Custom) {
    if (
      [
        LabelTypeEnum.Asset,
        LabelTypeEnum.AssetKit,
        LabelTypeEnum.InventoryKit,
        LabelTypeEnum.Inventory,
      ].includes(labelType as LabelTypeEnum)
    ) {
      return [
        { value: '', label: 'None' },
        { value: 'sku', label: 'SKU' },
        { value: 'title', label: 'Title' },
        { value: 'site', label: 'Site' },
        { value: 'location', label: 'Location' },
      ];
    }
    if (labelType === LabelTypeEnum.Location) {
      return [
        { value: '', label: 'None' },
        { value: 'site', label: 'Site' },
        { value: 'location', label: 'Location' },
      ];
    }
    if (labelType === LabelTypeEnum.Shipping) {
      return [
        { value: '', label: 'None' },
        { value: 'site', label: 'Site' },
        { value: 'binNumber', label: 'Bin Number' },
      ];
    }
  }
  return [];
};

export const getContainerTypeOptions = (): SelectOption[] => {
  return [
    { value: 'selectOne', label: Label.Form.ContainerTypeOptions.SelectOne },
    { value: 'BIN', label: Label.Form.ContainerTypeOptions.Bin },
    { value: 'PKG', label: Label.Form.ContainerTypeOptions.Package },
    { value: 'PLT', label: Label.Form.ContainerTypeOptions.Pallet },
  ];
};

const LABEL_STOCK_OPTIONS = [
  // {
  //   value: LabelStockEnum.Avery6792,
  //   label: 'Avery 6792 (5/8" x 3")',
  //   accessData: [
  //     {
  //       type: LabelTypeEnum.Asset,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation, LabelFormat.TextOnly],
  //     },
  //     {
  //       type: LabelTypeEnum.AssetKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation, LabelFormat.TextOnly],
  //     },
  //     {
  //       type: LabelTypeEnum.Inventory,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation, LabelFormat.TextOnly],
  //     },
  //     {
  //       type: LabelTypeEnum.InventoryKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation, LabelFormat.TextOnly],
  //     },
  //     {
  //       type: LabelTypeEnum.Location,
  //       labelFormats: [LabelFormat.None],
  //     },
  //   ],
  // },
  // {
  //   value: LabelStockEnum.Avery6570,
  //   label: 'Avery 6570/6576 (1-1/4" x 1-3/4")',
  //   accessData: [
  //     {
  //       type: LabelTypeEnum.Asset,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.AssetKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.Inventory,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.InventoryKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.Location,
  //       labelFormats: [LabelFormat.None],
  //     },
  //   ],
  // },
  // {
  //   value: LabelStockEnum.Avery5160,
  //   label: 'Avery 5160 (1" x 2-5/8")',
  //   accessData: [
  //     {
  //       type: LabelTypeEnum.Asset,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.AssetKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.Inventory,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.InventoryKit,
  //       labelFormats: [LabelFormat.None, LabelFormat.SkuSiteLocation],
  //     },
  //     {
  //       type: LabelTypeEnum.Location,
  //       labelFormats: [LabelFormat.None],
  //     },
  //   ],
  // },
  {
    value: LabelStockEnum.Avery5163,
    label: 'Avery 5163 (10 - 2"x4")',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
        ],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.Location,
        labelFormats: [LabelFormat.None],
      },
      {
        type: LabelTypeEnum.Shipping,
        labelFormats: [LabelFormat.None],
      },
      {
        type: LabelTypeEnum.ShippingSetUp,
        labelFormats: [LabelFormat.None],
      },
    ],
  },
  {
    value: LabelStockEnum.OneRoundLabels,
    label: '1" Round Labels',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
    ],
  },
  {
    value: LabelStockEnum.ThreeRoundLabels,
    label: '3/4" Round Labels',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
    ],
  },
  {
    value: LabelStockEnum.OneSquareLabels,
    label: '1" x 1" Square Labels',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
    ],
  },
  {
    value: LabelStockEnum.ThreeSquareLabels,
    label: '3/4" x 3/4" Square Labels',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [LabelFormat.None, LabelFormat.TextOnly, LabelFormat.SkuOnly],
      },
    ],
  },
  {
    value: LabelStockEnum.ZebraPrinter,
    label: 'Zebra Printer(LP 2824 Plus)',
    accessData: [
      {
        type: LabelTypeEnum.Asset,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.AssetKit,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.Inventory,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.InventoryKit,
        labelFormats: [
          LabelFormat.None,
          LabelFormat.SkuSiteLocation,
          LabelFormat.TitleLocation,
          LabelFormat.TextOnly,
          LabelFormat.SkuOnly,
        ],
      },
      {
        type: LabelTypeEnum.Location,
        labelFormats: [LabelFormat.None],
      },
    ],
  },
];

function filterLabelStockOption(params: { labelType: LabelTypeEnum; labelFormat: LabelFormat }) {
  const { labelFormat, labelType } = params;

  if (!labelType) {
    return [];
  }

  return LABEL_STOCK_OPTIONS.filter((item) => {
    const accessData = item.accessData.find((access) => access.type === labelType);
    return Boolean(accessData && accessData.labelFormats.includes(labelFormat));
  });
}

export const getLabelStockOptions = (
  labelType: string | undefined | null,
  labelFormat?: string,
): SelectOption[] => {
  return filterLabelStockOption({
    labelFormat: labelFormat as LabelFormat,
    labelType: labelType as LabelTypeEnum,
  });
};
