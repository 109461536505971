import React, { useCallback, useMemo } from 'react';
import { Http5XXPage } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { getRedirectToBasedOnPermission } from 'app/modules/auth/utils/redirectTo';

interface Props {
  redirectToPath: string | null;
}

const Error5XX = (props: Props & RouteComponentProps) => {
  const { redirectToPath, history } = props;
  const { workspacePermissions } = useCurrentUser();

  const redirectionPathBasedOnPermission = getRedirectToBasedOnPermission(workspacePermissions);

  const goBackRoutePath = useMemo(() => {
    if (redirectToPath === routes.Error5XX()) {
      return redirectionPathBasedOnPermission;
    }
    return redirectToPath || redirectionPathBasedOnPermission;
  }, [redirectToPath, redirectionPathBasedOnPermission]);

  const onGotHomePageClick = useCallback(() => {
    history.push(redirectionPathBasedOnPermission);
  }, [history, redirectionPathBasedOnPermission]);

  const onGoBackButtonClick = useCallback(() => {
    history.push(goBackRoutePath);
  }, [history, goBackRoutePath, redirectToPath, redirectionPathBasedOnPermission]);

  return (
    <Http5XXPage
      onPrimaryButtonClick={onGotHomePageClick}
      onSecondaryButtonClick={onGoBackButtonClick}
    />
  );
};

export default withRouter(Error5XX);
