import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

import useOrderDeliveryDetailForm from '../../../../../../components/OrderDeliveryDetailForm/hook/useOrderDeliveryDetailForm';
import { getDeliveryDetailsDefaultValue } from './utils';

const useOrderDeliveryDetail = () => {
  const currentUser = useCurrentUser();
  const { formState, handleSubmit, onResetForm } = useOrderDeliveryDetailForm({
    defaultValues: getDeliveryDetailsDefaultValue(currentUser),
  });

  return {
    deliveryDetailState: {
      formState,
    },
    deliveryDetailsAction: {
      handleSubmit,
      onResetForm,
    },
  };
};
export type UseOrderDeliveryDetailReturnType = ReturnType<typeof useOrderDeliveryDetail>;
export default useOrderDeliveryDetail;
