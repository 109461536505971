import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { SnackbarService } from 'app/components/Snackbar';
import { WarningService } from 'app/components/WarningAlert';

import Inventory from '../../../../../i18n/Inventory';
import { MoveTransactionEntityInput, TransactionEntityTypeEnum } from '../../../../../types/schema';
import useEntityManager from '../../../../components/EntityManager/useEntityManager';
import useMoveForm from '../useMoveForm';

interface Props {
  transactionType: TransactionEntityTypeEnum;
}

const isItemOnSameSite = (
  transaction: MoveTransactionEntityInput,
  transaction2: MoveTransactionEntityInput,
): boolean => {
  return (
    transaction.sourceSiteId === transaction2.sourceSiteId &&
    transaction.sourceLocationId === transaction2.sourceLocationId
  );
};

const isItemEntityIdSame = (
  transaction: MoveTransactionEntityInput,
  transaction2: MoveTransactionEntityInput,
): boolean => {
  return transaction.entityId === transaction2.entityId;
};

const isItemOnSameLocation = (
  transaction: MoveTransactionEntityInput,
  transaction2: MoveTransactionEntityInput,
): boolean => {
  return (
    transaction.destinationSiteId === transaction2.destinationSiteId &&
    transaction.destinationLocationId === transaction2.destinationLocationId
  );
};

const useMoveItemState = (props: Props) => {
  const { transactionType } = props;
  const [rows, setRows] = useState<any[]>([]);

  const { table } = useEntityManager({
    selection: false,
  });

  const { formState, handleSubmit, onResetForm, itemInStock, state } = useMoveForm({
    transactionType,
  });

  const transactions = useMemo(() => {
    return rows.map((row) => row.transaction);
  }, [rows]);

  const availableQuantity = useMemo((): number | undefined => {
    const sourceLocation = state?.sourceLocation;
    const sourceSite = state?.sourceSite;
    if (!sourceLocation) {
      return undefined;
    }

    const availableQuantityOnLocation = sourceLocation?.availableQuantity || 0;

    let totalQuantity = 0;
    transactions.forEach((transaction) => {
      if (
        transaction.entityId === formState.itemId &&
        transaction.sourceSiteId === sourceSite?.id &&
        transaction.sourceLocationId === sourceLocation?.id
      ) {
        totalQuantity += transaction.quantity;
      }
    });
    return availableQuantityOnLocation - totalQuantity;
  }, [transactions, state?.sourceLocation, state?.sourceSite, formState.itemId]);

  const isQuantityAvailable = useCallback(
    (moveTransaction: MoveTransactionEntityInput) => {
      const availableQuantityOnLocation = state?.sourceLocation?.availableQuantity || 0;

      let totalQuantity = moveTransaction.quantity;
      transactions.forEach((transaction) => {
        if (
          isItemEntityIdSame(transaction, moveTransaction) &&
          isItemOnSameSite(transaction, moveTransaction) &&
          !isItemOnSameLocation(transaction, moveTransaction)
        ) {
          totalQuantity += transaction.quantity;
        }
      });

      return totalQuantity <= availableQuantityOnLocation;
    },
    [transactions, state?.sourceLocation],
  );

  const onRemoveTransaction = useCallback(
    (rowId: number) => {
      setRows(rows.filter((row) => row.id !== rowId));
    },
    [setRows, rows],
  );

  const onResetFormAndTable = useCallback(() => {
    setRows([]);
    onResetForm();
  }, [setRows, onResetForm]);

  const createTransaction = useCallback(
    (values: any) => {
      const {
        itemInStockId,
        sourceSiteId,
        sourceLocationId,
        destinationSiteId,
        destinationLocationId,
        quantity,
      } = values;

      const { destinationSite, destinationLocation, sourceSite, sourceLocation } = state;

      const transactionQuantity = Number(quantity);

      return {
        transaction: {
          entityType: itemInStock?.type as unknown as TransactionEntityTypeEnum,
          entityId: itemInStockId,
          quantity: transactionQuantity,
          sourceSiteId,
          sourceLocationId,
          destinationSiteId,
          destinationLocationId,
        } as MoveTransactionEntityInput,
        sku: itemInStock?.sku || '',
        title: itemInStock?.title || '',
        sourceSiteName: sourceSite?.name || '',
        sourceLocationName: sourceLocation?.name || '',
        destinationSiteName: destinationSite?.name || '',
        destinationLocationName: destinationLocation?.name || '',
        quantity: transactionQuantity,
        id: `move-transaction-${nanoid()}`,
      };
    },
    [transactionType, state, itemInStock],
  );

  const getDuplicateTransaction = useCallback(
    (newTransaction: { [key: string]: any; transaction: MoveTransactionEntityInput }) => {
      const updatedRows = [...rows];
      const rowIndex = updatedRows.findIndex((row) => {
        const { transaction } = row;
        return (
          isItemEntityIdSame(transaction, newTransaction.transaction) &&
          isItemOnSameSite(transaction, newTransaction.transaction) &&
          isItemOnSameLocation(transaction, newTransaction.transaction)
        );
      });

      return { row: updatedRows[rowIndex], rowIndex };
    },
    [rows],
  );

  const getUpdatedRows = useCallback(
    (newTransaction: { [key: string]: any; transaction: MoveTransactionEntityInput }) => {
      const updatedRows = [...rows];
      const { rowIndex } = getDuplicateTransaction(newTransaction);
      if (rowIndex !== -1) {
        updatedRows[rowIndex] = newTransaction;
      } else {
        updatedRows.push(newTransaction);
      }
      return updatedRows;
    },
    [rows],
  );

  const onMoveFormSubmit = useCallback(
    (values: any) => {
      const moveTransactionData = createTransaction(values);
      const { row, rowIndex } = getDuplicateTransaction(moveTransactionData);
      const newQuantity = moveTransactionData.quantity + (row?.quantity || 0);

      if (rowIndex > -1) {
        WarningService.showWarning({
          message: Inventory.FormValidationMessages.DuplicateCartItem(newQuantity),
          onConfirm: () => {
            if (!isQuantityAvailable(moveTransactionData.transaction)) {
              SnackbarService.showError({
                message: Inventory.FormValidationMessages.QuantityIsNotAvailable,
              });
              return;
            }
            setRows(
              getUpdatedRows({
                ...moveTransactionData,
                transaction: {
                  ...moveTransactionData.transaction,
                  quantity: newQuantity,
                },
                quantity: newQuantity,
              }),
            );
            onResetForm();
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        if (!isQuantityAvailable(moveTransactionData.transaction)) {
          SnackbarService.showError({
            message: Inventory.FormValidationMessages.QuantityIsNotAvailable,
          });
          return;
        }
        const transaction = getUpdatedRows(moveTransactionData);
        setRows(transaction);
        onResetForm();
      }
    },
    [rows, setRows, transactionType, state, createTransaction, itemInStock, onResetForm],
  );

  const onFormSubmit = useMemo(
    () => handleSubmit(onMoveFormSubmit),
    [handleSubmit, onMoveFormSubmit],
  );

  return {
    formState,
    tableState: {
      table,
      rows,
      onRemoveTransaction,
      onResetFormAndTable,
      totalRows: rows.length,
    },
    availableQuantity,
    table,
    rows,
    transactions,
    onFormSubmit,
    onRemoveTransaction,
    onResetFormAndTable,
  };
};

export type UseMoveInventoryStateReturnType = ReturnType<typeof useMoveItemState>;
export default useMoveItemState;
