import React from 'react';
import { Permissions, useAccessControl } from 'app/components/AccessControl';
import { Route } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import PageNotFound from 'app/pages/PageNotFound';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';
import { getIsModuleVisible } from 'app/utils/getIsModuleVisible';

export interface Props<T, Q> {
  action: AllowedPermissionActionsEnum;
  subject: AllowedPermissionsSubjectEnum;
  permissions?: Permissions<T, Q>;
  isSuperAdminRoute?: boolean;
  children: React.ReactNode;
  path: string;
}

export const AccessControlRoute = <T, Q>(props: Props<T, Q>) => {
  const { workspacePermissions, isSuperAdmin, tenant } = useCurrentUser();
  const { action, subject, children, isSuperAdminRoute, path, ...rest } = props;
  const pathInitial = path.split('/')[1];
  const isVisible = getIsModuleVisible(pathInitial, tenant?.moduleVisibilityConfig);
  const canAccess = useAccessControl(workspacePermissions, action, subject);

  if (isSuperAdminRoute) {
    if (isSuperAdmin) {
      return <Route {...rest} exact path={path} render={() => <>{children}</>} />;
    } else {
      return <PageNotFound></PageNotFound>;
    }
  }

  return canAccess && isVisible ? (
    <Route {...rest} exact path={path} render={() => <>{children}</>} />
  ) : (
    <PageNotFound></PageNotFound>
  );
};
