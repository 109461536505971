import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { LabelShippingTableRowFragmentDoc } from '../../fragments/generated/LabelShippingTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LabelShippingTableQueryVariables = Types.Exact<{
  filters: Types.ShippingContainerFilters;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LabelShippingTableQuery = { __typename?: 'Query', shippingContainers: { __typename?: 'ShippingContainerConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges?: Array<{ __typename?: 'ShippingContainerEdge', node: { __typename?: 'ShippingContainerSchema', id: string, isReusable: boolean, containerId: string, destinationSiteId: string, tenantId: string, destinationSite: { __typename?: 'LocationSchema', id: string, name: string } } }> | null } };


export const LabelShippingTableDocument = gql`
    query LabelShippingTable($filters: ShippingContainerFilters!, $limit: Float, $after: String, $before: String) {
  shippingContainers(
    filters: $filters
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...LabelShippingTableRow
      }
    }
  }
}
    ${LabelShippingTableRowFragmentDoc}`;

export function useLabelShippingTableQuery(options: Omit<Urql.UseQueryArgs<LabelShippingTableQueryVariables>, 'query'>) {
  return Urql.useQuery<LabelShippingTableQuery>({ query: LabelShippingTableDocument, ...options });
};