import { useMemo, useRef, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import FormNumberInput from 'app/modules/components/FormNumberInput';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';

import FormDepartmentSelect from '../../../../../../components/Form/FormDepartmentSelect';
import FormTextArea from '../../../../../../components/Form/FormTextArea';
import FormUserSelect from '../../../../../../components/Form/FormUserSelect';
import FormDatePickerInput from '../../../../../../components/ProcureForm/FormDatePickerInput';
import FormRadioGroupInput from '../../../../../../components/ProcureForm/FormRadioGroupInput';
import Assets from '../../../../../../i18n/Assets';
import Common from '../../../../../../i18n/Common';
import Inventory from '../../../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../../../types/schema';
import ItemInStockSelect from '../../../../../assetsInventory/components/FormItemInStock';
import { LabelTypeEnum } from '../../../../../labels/views/Labels/components/Forms/types';
import {
  FormLocationItemLocationSelect,
  FormSiteItemLocationSelect,
} from '../../../../../locations/components/FormItemLocationSelect';
import { UseCheckOutStateReturnType } from '../../hook/useCheckOutState';
import { CHECKOUT_FORM_RULES } from './utils';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  formState: UseCheckOutStateReturnType['formState'];
  disabled?: boolean;
  onSubmit: (values: any) => void;
}

const CheckoutForm = (props: Props) => {
  const { formState, disabled: formDisabled, onSubmit } = props;
  const {
    control,
    itemInStockId,
    sourceSiteId,
    isDueDateRequired,
    handleSubmit,
    destinationSiteId,
    availableQuantity,
    setState,
    totalQuantity,
    setValue,
    resetField,
  } = formState;

  const disabled = !!formDisabled;

  const quantityRules = useMemo(() => {
    return CHECKOUT_FORM_RULES.quantity({
      availableQuantity,
      totalQuantity,
    });
  }, [availableQuantity, totalQuantity]);

  const today = useMemo(() => {
    const date = new Date();
    return moment(date).format('YYYY-MM-DD');
  }, []);

  const formRef = useRef<HTMLDivElement>(null);
  const onError = useCallback(() => {
    ScrollToError(formState.control._formState.errors, formRef)
  }, []);
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, onError), [onSubmit, handleSubmit]);
  
  return (
    <div ref={formRef} className="mt-20">
      <form onSubmit={onFormSubmit}>
        <div className="mb-20">
          <ItemInStockSelect
            autoFocus
            control={control}
            name="itemInStockId"
            types={[ItemTypeEnum.Asset, ItemTypeEnum.AssetKit]}
          />
        </div>
        <div className="mb-20">
          <FormSiteItemLocationSelect
            isRequired
            control={control}
            disabled={!itemInStockId}
            itemId={itemInStockId}
            name="sourceSiteId"
            rules={CHECKOUT_FORM_RULES.sourceId}
            onValueOptionChange={(option) => {
              setState({
                sourceSite: option || null,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <FormLocationItemLocationSelect
            isRequired
            control={control}
            disabled={!sourceSiteId}
            itemId={itemInStockId}
            name="sourceLocationId"
            rules={CHECKOUT_FORM_RULES.sourceLocationId}
            siteId={sourceSiteId}
            onValueOptionChange={(option) => {
              setState({
                sourceLocation: option || null,
              });
            }}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="destinationSiteId"
            render={({ field, fieldState }) => (
              <FormSiteSelect
                {...field}
                isRequired
                className="flex-1"
                disabled={disabled}
                error={fieldState.error}
                label={Inventory.FormLabels.ToSite}
                onChange={(value, option) => {
                  field.onChange(value);
                  setState({
                    destinationSite: option,
                  });
                }}
                selectClassName="mt-[6px]"
              />
            )}
            rules={CHECKOUT_FORM_RULES.destinationId}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="destinationLocationId"
            render={({ field, fieldState }) => (
              <FormSiteSelect
                {...field}
                isRequired
                className="flex-1"
                disabled={!destinationSiteId || disabled}
                error={fieldState.error}
                label={Inventory.FormLabels.ToLocation}
                type={LabelTypeEnum.Location}
                types={[]}
                value={destinationSiteId}
                onChange={(value, option) => {
                  field.onChange(value);
                  setState({
                    destinationLocation: option,
                  });
                }}
              />
            )}
            rules={CHECKOUT_FORM_RULES.destinationLocationId}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="departmentId"
            render={({ field, fieldState }) => (
              <FormDepartmentSelect
                {...field}
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.Department}
              />
            )}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="actorId"
            render={({ field, fieldState }) => (
              <FormUserSelect
                {...field}
                isRequired
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.User}
                name="actorId"
              />
            )}
            rules={CHECKOUT_FORM_RULES.actorId}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="isDueDateRequired"
            render={({ field }) => (
              <FormRadioGroupInput
                {...field}
                disabled={disabled}
                items={[
                  {
                    id: 'no-due-date-radio-input',
                    label: 'No Due Date',
                    value: 'false',
                  },
                  {
                    id: 'due-date-radio-input',
                    label: 'Due Date',
                    value: 'true',
                  },
                ]}
                label={Assets.FormLabels.DueDate}
              />
            )}
          />
        </div>

        {isDueDateRequired === 'true' ? (
          <div className="mb-20">
            <Controller
              control={control}
              name="dueDate"
              render={({ field, fieldState }) => (
                <FormDatePickerInput
                  {...field}
                  isRequired
                  disabled={disabled}
                  error={fieldState.error}
                  label={Assets.FormLabels.DueDate}
                  minDate={today}
                  size="medium"
                  onChange={(date) => {
                    if (date) {
                      setValue('dueDate', date, { shouldValidate: true });
                    } else {
                      resetField('dueDate');
                    }
                  }}
                />
              )}
              rules={CHECKOUT_FORM_RULES.dueDate}
            />
          </div>
        ) : undefined}

        <div className="mb-20">
          <Controller
            control={control}
            name="meta.note"
            render={({ field, fieldState }) => (
              <FormTextArea
                {...field}
                disabled={disabled}
                error={fieldState.error}
                label={Assets.FormLabels.Note}
              />
            )}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                error={fieldState.error}
                isDisabled={
                  availableQuantity === undefined || availableQuantity === null || disabled
                }
                label={Assets.FormLabels.Quantity}
                maximumLength={9}
              />
            )}
            rules={quantityRules}
          />
        </div>

        <div className="flex">
          <div className="flex flex-auto justify-end">
            <Button theme="success" onClick={onFormSubmit} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Add}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
