import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';
import routes from 'app/consts/routes';
import Network from 'app/i18n/Network';
import { Link } from 'app/libs/navigation';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';
import { useStyle } from 'app/modules/inventory/components/useStyle';
import FormOperatingSystemSelect from 'app/modules/network/components/FormOperatingSystemSelect';
import { FilterLabelNodeProps } from 'app/modules/network/types';
import { NetworkAssetStatusEnum } from 'app/types/schema';
import { formatDate } from 'app/utils/date';
import SyncStateWithUrlParam from 'app/utils/queryParams';
import { UseNetworkAssetsStateReturnType } from '../hook/useNetworkAssetsState';

const OSFilterLabelNode = (props: FilterLabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filters } = extraProps as {
    filters: UseNetworkAssetsStateReturnType['filters'];
  };

  SyncStateWithUrlParam('operatingSystem', filters.state.operatingSystem, filters.setState);

  const classes = useStyle();
  return (
    <FormOperatingSystemSelect
      className={clsx(classes.root, 'w-full')}
      placeholder={Network.FormPlaceholders.OperatingSystemSelect}
      selectInputClass="flex"
      value={filters.state.operatingSystem && filters.state.operatingSystem.length ? filters.state.operatingSystem[0] : ""}
      onChange={(value) => {
        console.log('called');
        filters.setState({
          operatingSystem: value ? [value] : [],
        });
      }}
    />
  );
};

export const getNetworkAssetsTableColumns = (
  clickable: boolean,
  discovered: boolean,
): DataGridHeadCell[] => {
  const hideColumIds = !discovered
    ? []
    : [
      'operatingSystem.distro',
      'operatingSystem.platform',
      'cpu.brand',
      'cpu.manufacturer',
      'battery.manufacturer',
    ];
  return (
    [
      {
        id: 'mac',
        label: 'MAC Address',
        value: 'mac',
        sortable: true,
        valueNode: ({ row }) => (
          <div className="truncate">
            {row.status === NetworkAssetStatusEnum.Active && clickable ? (
              <Link
                className={'sku-link truncate'}
                title={row?.mac}
                to={routes.NetworkAssetDetail(row.id)}>
                {row?.mac}
              </Link>
            ) : (
              row.mac
            )}
          </div>
        ),
        width: 200,
      },
      {
        id: 'userName',
        label: 'Username',
        value: 'userName',
        valueNode: (param) => (
          <span className="truncate" title={param.row.user ? param.row.user : 'Unknown'}>
            {param.row.user ? param.row.user : 'Unknown'}
          </span>
        ),
        width: 250,
        sortable: true,
      },
      {
        id: 'ipOctetNumeric',
        label: 'IP Address',
        value: 'ipOctetNumeric',
        valueNode: (param) => (
          <span className="truncate" title={param.row.ip}>
            {param.row.ip}
          </span>
        ),
        width: 150,
        sortable: true,
      },
      {
        id: 'operatingSystem.distro',
        label: 'OS Distribution',
        value: 'operatingSystem.distro',
        valueNode: (param) => (
          <span className="truncate" title={param.row.osDistro}>
            {param.row.osDistro}
          </span>
        ),
        width: 180,
        sortable: true,
      },
      {
        id: 'operatingSystem.platform',
        label: 'OS Platform',
        value: 'operatingSystem.platform',
        classes: dropdownHeaderStyle,
        labelNode: OSFilterLabelNode,
        valueNode: (param) => (
          <span className="truncate" title={param.row.osPlatform}>
            {param.row.osPlatform}
          </span>
        ),
        width: 200,
        sortable: false,
      },
      {
        id: 'cpu.brand',
        label: 'CPU Brand',
        value: 'cpu.brand',
        valueNode: (param) => (
          <span className="truncate" title={param.row.cpuBrand}>
            {param.row.cpuBrand}
          </span>
        ),
        width: 250,
        sortable: true,
      },
      {
        id: 'cpu.manufacturer',
        label: 'CPU Manufacturer',
        value: 'cpu.manufacturer',
        valueNode: (param) => (
          <span className="truncate" title={param.row.cpuManufacturer}>
            {param.row.cpuManufacturer}
          </span>
        ),
        width: 250,
        sortable: true,
      },
      {
        id: 'battery.manufacturer',
        label: 'Battery',
        value: 'battery.manufacturer',
        valueNode: (param) => (
          <span className="truncate" title={param.row.batteryManufacturer}>
            {param.row.batteryManufacturer}
          </span>
        ),
        sortable: true,
        width: 250,
      },
      {
        id: 'updatedAt',
        label: 'Last Update',
        value: 'updatedAt',
        valueNode: ({ row }) => (
          <span className="truncate" title={formatDate(row.updatedAt)}>
            {formatDate(row.updatedAt)}
          </span>
        ),
        sortable: true,
        width: 250,
      },
      {
        id: 'createdAt',
        label: 'Created At',
        value: 'createdAt',
        valueNode: ({ row }) => (
          <span className="truncate" title={formatDate(row.createdAt)}>
            {formatDate(row.createdAt)}
          </span>
        ),
        sortable: true,
        width: 250,
      },
    ] as DataGridHeadCell[]
  )
    .map((item) => {
      return {
        ...item,
      } as DataGridHeadCell;
    })
    .filter((item) => {
      return !hideColumIds.includes(item.id);
    });
};
