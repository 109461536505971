import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAssetItemReminderMutationVariables = Types.Exact<{
  input: Types.UpdateAssetItemReminderInput;
}>;


export type UpdateAssetItemReminderMutation = { __typename?: 'Mutation', updateAssetItemReminder: { __typename?: 'UpdateAssetItemReminderPayload', success: boolean, reason?: string | null, assetItemReminder?: { __typename?: 'AssetItemReminder', _id: string } | null } };


export const UpdateAssetItemReminderDocument = gql`
    mutation updateAssetItemReminder($input: UpdateAssetItemReminderInput!) {
  updateAssetItemReminder(input: $input) {
    success
    reason
    assetItemReminder {
      _id
    }
  }
}
    `;

export function useUpdateAssetItemReminderMutation() {
  return Urql.useMutation<UpdateAssetItemReminderMutation, UpdateAssetItemReminderMutationVariables>(UpdateAssetItemReminderDocument);
};