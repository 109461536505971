import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TenantTableFilterQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TenantFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type TenantTableFilterQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', totalCount: number, edges: Array<{ __typename?: 'TenantEdge', node: { __typename?: 'TenantSchema', id: string, name: string, status: Types.TenantStatusEnum } }> } };


export const TenantTableFilterDocument = gql`
    query tenantTableFilter($filters: TenantFilters, $sorts: [SortProps!], $limit: Float) {
  tenants(filters: $filters, sorts: $sorts, limit: $limit) {
    totalCount
    edges {
      node {
        id
        name
        status
      }
    }
  }
}
    `;

export function useTenantTableFilterQuery(options?: Omit<Urql.UseQueryArgs<TenantTableFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<TenantTableFilterQuery>({ query: TenantTableFilterDocument, ...options });
};