import { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';

import { getSoftwareDetailsColumns } from './utils/columns';

const SystemDetail = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;
  const data = useMemo(() => {
    return networkAssetDetail?.software?.length
      ? networkAssetDetail?.software?.map((value, index) => {
          return { id: index + 1, software: value };
        })
      : [];
  }, [networkAssetDetail]);

  const { table } = useEntityManager({
    selection: false,
  });

  return (
    <div>
      <Typography className="text-[18px] font-semibold text-grey-900">Software Detail</Typography>
      <Divider className="my-[16px]" />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          pagination
          actions={table.actions}
          columns={getSoftwareDetailsColumns()}
          data={data}
          paginationWrapperClass="p-[24px]"
          setState={table.setState}
          state={table.state}
          total={data.length}
        />
      </Box>
    </div>
  );
};

export default SystemDetail;
