import AdminLayout from 'app/components/AdminLayout';
import AddManufacturer from 'app/modules/manufacturers/views/AddManufacturer';

function AddManufacturerPage() {
  return (
    <AdminLayout>
      <AddManufacturer />
    </AdminLayout>
  );
}

export default AddManufacturerPage;
