import { Controller } from 'react-hook-form';
import { RadioGroup, Typography } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import { TenantFeatureTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import { radioGroupStyleOverrides } from './styles';

interface Props {
  control: any;
  isShowLabel?: boolean;
}

const RADIO_ITEMS = [
  {
    id: TenantFeatureTypeEnum.Show,
    value: TenantFeatureTypeEnum.Show,
    label: 'Show',
  },
  {
    id: TenantFeatureTypeEnum.Screen,
    value: TenantFeatureTypeEnum.Screen,
    label: 'Screen',
  },
  {
    id: TenantFeatureTypeEnum.Hide,
    value: TenantFeatureTypeEnum.Hide,
    label: 'Hide',
  },
];

const Modules = [
  { label: 'Assets', value: 'assets', disabled: false },
  { label: 'IT Asset', value: 'itAsset', disabled: false },
  { label: 'Inventory', value: 'inventory', disabled: true },
  { label: 'Contacts', value: 'contacts', disabled: true },
  { label: 'Order Request', value: 'orderRequest', disabled: true },
  { label: 'Shipping', value: 'shipping', disabled: true },
  { label: 'Receiving', value: 'receiving', disabled: true },
  { label: 'Report', value: 'report', disabled: true },
  { label: 'Admin', value: 'admin', disabled: true },
  { label: 'Shop', value: 'shop', disabled: false },
  { label: 'Wishlist', value: 'wishlist', disabled: false },
];

const ModuleConfigOptions = (props: Props) => {
  const { control, isShowLabel = false } = props;

  return (
    <Box className="flex flex-col">
      {isShowLabel ? <FormLabel>Organization Setting</FormLabel> : null}
      {Modules.map((module) => {
        return (
          <>
            <Stack
              className={radioGroupStyleOverrides}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
              }}>
              <Typography>{module.label}</Typography>
              <Controller
                control={control}
                name={`moduleVisibilityConfig.${module.value}`}
                render={({ field }) => (
                  <RadioGroup
                    disabled={module.disabled}
                    radioItems={RADIO_ITEMS}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Stack>
          </>
        );
      })}
    </Box>
  );
};

export default ModuleConfigOptions;
