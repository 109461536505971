import AssetsLayout from '../../modules/assets/layout';
import SearchAssets from '../../modules/assets/views/searchAssets';

function SearchAssetsPage() {
  return (
    <AssetsLayout>
      <SearchAssets />
    </AssetsLayout>
  );
}

export default SearchAssetsPage;
