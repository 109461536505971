import React, { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Loader from 'app/components/Loader';
import { ContactType } from 'app/types/contact';

interface SectionListProps {
  data: any[];
  loading: boolean;
  onRowClick: (
    event: React.MouseEvent<HTMLDivElement | HTMLTableRowElement | MouseEvent>,
    contact: ContactType,
  ) => void;
  keyName?: string;
  hideEmpty?: boolean;
}

const SectionList = ({ data, onRowClick, loading, keyName }: SectionListProps) => {
  const groupSectionReference = useRef<HTMLDivElement[]>([]);
  const containerReference = useRef<HTMLElement>();

  const executeScroll = (event: number) => {
    if (
      containerReference &&
      containerReference.current &&
      groupSectionReference &&
      groupSectionReference.current &&
      groupSectionReference.current[event]
    ) {
      const topPos = containerReference.current.offsetTop;
      containerReference.current.scrollTop =
        (groupSectionReference.current[event]?.offsetTop || 0) - topPos;
    }
  };

  const getSectionTitle = (section: any) => {
    return (
      keyName
        ?.split(',')
        ?.map((item) => `${section[item?.trim()]}`)
        ?.join('') || ''
    );
  };

  return (
    <>
      <div className="flex">
        <div
          ref={containerReference as React.RefObject<HTMLDivElement & number>}
          className="max-h-96"
          style={{
            width: '100%',
            scrollBehavior: 'smooth',
            maxHeight: '520px',
            overflowX: 'auto',
          }}>
          {data?.length ? (
            data?.map((cont: any, index: number) => {
              return (
                cont.children?.length > 0 && (
                  <div key={index} style={{ width: '100%' }}>
                    <div
                      ref={(el) => (groupSectionReference.current[index] = el as HTMLDivElement)}
                      className="rounded-[8px] bg-grey-300 py-8 px-[16px] font-bold text-gray-900  ">
                      {cont?.group}
                    </div>
                    {cont?.children.length ? (
                      cont?.children?.map((con: any, idx: number) => {
                        return (
                          <div
                            key={`inner_${idx}`}
                            className="cursor-pointer px-[16px] py-[6px] text-blue-900"
                            onClick={(event) => onRowClick(event, con)}>
                            <Tooltip title={getSectionTitle(con)}>
                              <span>{getSectionTitle(con)}</span>
                            </Tooltip>
                          </div>
                        );
                      })
                    ) : (
                      <div className="inner-item " />
                    )}
                  </div>
                )
              );
            })
          ) : loading ? (
            <Loader />
          ) : (
            <div className="company-data">No data available</div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'var(--blue700)',
            fontSize: 'small',
            fontWeight: 600,
          }}>
          {data?.length > 0 &&
            data?.map((alphabet, index) => (
              <div
                key={index}
                className={
                  alphabet?.children?.length
                    ? 'right-text cursor-pointer text-blue-800'
                    : 'disabled-text'
                }
                onClick={(event) => executeScroll(index)}>
                {alphabet?.group}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SectionList;
