import OrderRequest from '../OrderRequest';
import { useContext, useEffect, useMemo, useState } from 'react';
import Loader from '../../../../components/Loader';
import { useOrderRequestAgainQuery } from './graphql/query/generated/orderRequestAgain';
import {
  OrderRequestItemSchema,
  OrderRequestSchema,
  OrderRequestTypeEnum,
} from '../../../../types/schema';
import { OrderRequestContext } from '../../provider/OrderRequestContextProvider/OrderRequestContextProvider';
import {
  ORDER_REQUEST_TYPE,
  SupportedOrderItemTypes,
} from '../OrderRequest/components/OrderItemRequest/type';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { useOrderItemInStockSelectQuery } from '../../../assetsInventory/graphql/query/generated/orderItemInStockSelect';
import _ from 'lodash';
import { adaptNodeEdgeToNode } from '../../../../components/AsyncMultiSelect/utils';
import { isAssetInventoryTypeItem } from '../OrderDetail/hook/useOrderRequestItems/utils';
import {
  filterAndParseExternalOrderRequestItems,
  filterAndParseInternalOrderRequestItems,
} from './utils/utils';
import routes from '../../../../consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { isExternalUserHavePartnerTenants } from '../OrderRequest/utils/externalTenant';
import { SnackbarService } from '../../../../components/Snackbar';
import Orders from '../../../../i18n/Orders';

interface Props {
  orderId: string;
}

const OrderRequestAgain = (props: Props & RouteComponentProps) => {
  const { orderId, history } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const user = useCurrentUser();
  const isExternalUser = useMemo(() => isExternalUserHavePartnerTenants(user), [user]);
  const { tenantId } = user;

  const { storeDataInStorage, setIsRecreateOrder, clearDataFromStorage, setOrderDetails } =
    useContext(OrderRequestContext);

  const [{ data, fetching }] = useOrderRequestAgainQuery({
    variables: {
      filters: {
        orderRequestIds: [orderId],
      },
    },
  });

  const orderDetail = useMemo(
    () => data?.orderRequests?.edges?.[0]?.node as OrderRequestSchema,
    [data?.orderRequests?.edges],
  );

  useEffect(() => {
    if (orderDetail) {
      setOrderDetails(orderDetail);
    }
  }, [orderDetail]);

  const { itemIds, partnerTenantId } = useMemo(() => {
    const isExternalOrder = orderDetail?.type === OrderRequestTypeEnum.External;
    if (orderDetail) {
      const orderRequestItems = orderDetail?.orderRequestItems as OrderRequestItemSchema[];
      const itemIds: string[] = [];

      if (Boolean(orderDetail.childTenantId)) {
        return {
          partnerTenantId: orderDetail?.parentTenantId,
          itemIds: [],
        };
      }

      _.forEach(orderRequestItems, (orderRequestItem) => {
        if (isAssetInventoryTypeItem(orderRequestItem.type)) {
          if (isExternalOrder) {
            itemIds.push(orderRequestItem?.item?.entityIdInSourceTenant as string);
          } else {
            itemIds.push(orderRequestItem?.itemId as string);
          }
        }
      });

      return {
        partnerTenantId: orderDetail?.parentTenantId,
        itemIds,
      };
    }
    return {
      partnerTenantId: null,
      itemIds: [],
    };
  }, [orderDetail]);

  const [{ data: itemsData, fetching: fetchingItemIds }] = useOrderItemInStockSelectQuery({
    variables: {
      filters: {
        itemIds: itemIds,
        partnerTenantId: partnerTenantId,
        types: SupportedOrderItemTypes,
      },
      limit: itemIds?.length || 0,
    },
    pause: !Boolean(itemIds.length),
  });

  const items = useMemo(() => {
    return itemsData?.items?.edges?.map(adaptNodeEdgeToNode) || [];
  }, [itemsData?.items?.edges]);

  useEffect(() => {
    if (!fetching && !fetchingItemIds && orderDetail && isLoading) {
      const orderRequestItems = orderDetail.orderRequestItems as OrderRequestItemSchema[];
      const orderRequestType = orderDetail.type;
      const parentTenantId = orderDetail.parentTenantId;
      const isExternalOrder = orderRequestType === OrderRequestTypeEnum.External;
      const isInternalOrder = orderRequestType === OrderRequestTypeEnum.Internal;

      if (isExternalOrder && !parentTenantId) {
        clearDataFromStorage();
        history.push(routes.OrderRequest());
        return;
      }

      let notRequestableItemSkus: string[] = [];

      if (isExternalOrder) {
        const { externalOrderRequestItems, notRequestableExternalItemSkus } =
          filterAndParseExternalOrderRequestItems(orderRequestItems, items);
        notRequestableItemSkus = notRequestableExternalItemSkus;
        storeDataInStorage({
          orderRequestType: ORDER_REQUEST_TYPE.NORMAL_ORDER,
          orderItems: externalOrderRequestItems,
          totalOrderItems: externalOrderRequestItems.length,
          partnerTenantId: partnerTenantId as string,
        });
      } else if (isInternalOrder) {
        const { internalOrderRequestItems, notRequestableInternalItemSkus } =
          filterAndParseInternalOrderRequestItems(orderRequestItems, items);
        notRequestableItemSkus = notRequestableInternalItemSkus;
        storeDataInStorage({
          orderRequestType: ORDER_REQUEST_TYPE.NORMAL_ORDER,
          orderItems: internalOrderRequestItems,
          totalOrderItems: internalOrderRequestItems.length,
          partnerTenantId: tenantId as string,
        });
      }

      if (notRequestableItemSkus.length > 0) {
        SnackbarService.showError({
          message: Orders.ErrorMessages.SKUNotRequestable(notRequestableItemSkus),
        });
      }
      setIsRecreateOrder(true);
      setIsLoading(false);
    } else {
      if (!fetching && !fetchingItemIds && !orderDetail) {
        if (isExternalUser) {
          SnackbarService.showError({
            message: Orders.FormValidationMessages.ExternalOrderReOrderFailed,
          });
        }
        setIsRecreateOrder(false);
        setIsLoading(false);
        clearDataFromStorage();
        history.push(routes.OrderRequest());
      }
    }
  }, [
    items,
    orderDetail,
    fetchingItemIds,
    itemIds,
    fetching,
    partnerTenantId,
    tenantId,
    history,
    isExternalUser,
  ]);

  if (fetching || fetchingItemIds || isLoading) {
    return <Loader />;
  }

  return <OrderRequest />;
};

export default withRouter(OrderRequestAgain);
