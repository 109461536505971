import React from 'react';
import {
  Card,
  CardContent,
  LinearProgress,
  linearProgressClasses,
  Link,
  styled,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Stack from 'app/ui-components/Stack';

interface Props {
  warrantyStartDate?: string;
  warrantyEndDate?: string;
  setWarrantyModal: any;
}

const BorderLinearProgress = styled(LinearProgress)<{ daysLeft: number }>(
  ({ theme, value, daysLeft }) => ({
    marginTop: '20px',
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: daysLeft <= 0 ? 'red' : daysLeft < 60 ? 'yellow' : 'green',
    },
  }),
);

const WarrantyAcquistionDetail = (props: Props) => {
  const { warrantyEndDate, warrantyStartDate, setWarrantyModal } = props;
  const today = moment();

  const startOfDayStartDate = moment(warrantyStartDate).startOf('day');
  const endOfDayEndDate = moment(warrantyEndDate).endOf('day');

  const warrantyTotalDays = Math.max(endOfDayEndDate.diff(startOfDayStartDate, 'days'), 0);

  const warrantyDaysLeft = Math.max(endOfDayEndDate.diff(today, 'days'), 0);

  const percentOfDaysLeft =
    warrantyDaysLeft !== 0 ? Math.ceil((warrantyDaysLeft / (warrantyTotalDays + 1)) * 100) : 0;
  const percentComplete = 100 - percentOfDaysLeft;

  const isExpired = today.isAfter(endOfDayEndDate, 'day');

  return (
    <>
      <Stack alignItems="center" justifyContent="between"  className='my-[16px] border-b-[1px] border-grey-300 pb-[16px]'>
      <Typography className="text-[18px] text-grey-900 font-semibold">
          Warranty & Acquisition
        </Typography>
        {warrantyStartDate ? (
          <Typography
          className='text-blue-900 text-[14px] cursor-pointer'
            variant="body1"
            onClick={() => setWarrantyModal(true)}>
            Set Warranty
          </Typography>
        ) : null}
      </Stack>
      <Card sx={{boxShadow:'none'}}>
        <CardContent>
          {warrantyStartDate ? (
            <>
              <Stack direction="row" justifyContent="between">
                <Stack alignItems="center" direction="col">
                  <Typography  className="text-[14px] text-grey-900 font-medium">Acquisition Date</Typography>
                  <Typography className="text-[12px] text-grey-800">
                    {moment(warrantyStartDate).format('LL')}
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="col">
                  <Typography className="text-[14px] text-grey-900 font-medium">Warranty Expiration</Typography>
                  <Typography className="text-[12px] text-grey-800">
                    {' '}
                    {moment(warrantyEndDate).format('LL')}
                  </Typography>
                </Stack>
              </Stack>

              {warrantyDaysLeft >= 0 ? (
                <BorderLinearProgress
                  daysLeft={warrantyDaysLeft}
                  value={percentComplete}
                  variant="determinate"
                />
              ) : null}
              <Stack alignItems="center" direction="row" justifyContent="center">
                <Typography color="text.secondary" sx={{ marginTop: '10px' }}>
                  {!isExpired ? `${warrantyDaysLeft} Days Remaining` : 'Warranty Expired'}
                </Typography>
              </Stack>
            </>
          ) : (
            <Stack direction="row" justifyContent="center" style={{ height: '100%' }}>
              <Stack alignItems="center" direction="col" justifyContent="center">
                <Typography className="text-[18px] text-grey-900 font-semibold">Not tracking anything here, yet.</Typography>
                <Link
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                  onClick={() => {
                    setWarrantyModal(true);
                  }}>
                  <Typography className='text-blue-900 underline text-[14px] mt-[8px]'>
                    Add Warranty Details
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default WarrantyAcquistionDetail;
