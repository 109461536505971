import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { WishlistSchema } from 'app/types/schema';

import { useWishlistsQuery } from '../../../createList/graphql/queries/generated/wishlist';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useWishlistsQuery({
    variables: {
      filters: {
        search: searchedValue?.replace(/\s+/g, ' '),
      },
    },
  });

  useEffect(() => {
    const result = data?.wishlists?.edges?.map((a) => {
      const item = a.node as WishlistSchema
      return {
        label: item.name,
        id: item.id,
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
