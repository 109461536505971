import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout } from '@procurenetworks/procure-component-library';

function AdminPartnerLayout(props: { children: any }) {
  const { children } = props;

  return (
    <Layout withAppBar components={{ AppBar: <UnifiedHeader /> }}>
      {children}
    </Layout>
  );
}

export default AdminPartnerLayout;
