import {
  DataGridComponentState,
  DataGridHeadCell,
} from '@procurenetworks/procure-component-library';
import FormValuesInItemsTableFilter from '../../../../../assetsInventory/components/FormValuesInItemsTableFilter';
import { useCallback } from 'react';
import {
  ItemStatusEnum,
  ItemStringBasedColumnFilterFieldEnum,
  ItemTypeEnum,
} from '../../../../../../types/schema';
import { UseSearchAssetsStateReturnType } from '../../hook/useSearchAssetsState';
import SyncStateWithUrlParam from 'app/utils/queryParams';

export interface LabelNodeProps {
  headCell: DataGridHeadCell;
  state: DataGridComponentState;
}

const BrandLabelNode = (props: LabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filters } = extraProps as {
    filters: UseSearchAssetsStateReturnType['filters'];
  };
  const { brands } = filters.state;

  SyncStateWithUrlParam('brands', filters.state, filters.setState)

  const onChange = useCallback(
    (nextValue: string[]) => {
      filters.setState({
        brands: nextValue,
      });
    },
    [filters.setState],
  );

  return (
    <div className="table-filter">
      <FormValuesInItemsTableFilter
        value={brands}
        onChange={onChange}
        className=" "
        name="brand-label-node"
        key="brand-label-node"
        placeholder="Brand"
        queryVariables={{
          field: ItemStringBasedColumnFilterFieldEnum.Brands,
          filters: {
            types: [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit],
            statuses: [ItemStatusEnum.Active],
          },
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </div>
  );
};

export default BrandLabelNode;
