import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import usePagination from 'app/hooks/usePagination';
import Common from 'app/i18n/Common';
import Reports from 'app/i18n/Reports';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import useSearchSuggestion from 'app/modules/reports/hooks/useSearchSuggestion';
import { AuditTransactionEntityInput, ItemStatusEnum, LocationStatusEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';
import { parseSorts } from 'app/utils/sort';

import FormSiteSelect from '../../../../../locations/components/FormSiteSelect';
import { useCreateAuditTransactionMutation } from '../../graphql/mutations/generated/createAuditTransaction';
import { useNewAuditItemLocationsQuery } from '../../graphql/queries/generated/newAuditItemLocations';
import AuditCreateConfirmation from './AuditCreateConfirmation';
import AuditCreateError from './AuditCreateError';
import { getNewAuditTableColumns } from './columns';
import { FilterState, NewAuditReportProps } from './types';
import { Box, InputAdornment } from '@mui/material';
import { classNames } from 'react-select/dist/declarations/src/utils';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

const getInitialFilterState = () => {
  return {
    locationIds: [],
    categoryId: '',
  };
};

const NewAuditReport = (
  props: NewAuditReportProps & { siteId: string | undefined } & RouteComponentProps,
) => {
  const { entityType, title, siteId: initialSiteId, auditReportLink, history, className } = props;
  const { table } = useReportPreviewTable();
  const { searchSuggestions, setSearchValue, setEntityType, setSite, setInitialSite } =
    useSearchSuggestion();

  const [showError, toggleError] = useToggle(false);
  const [showConfirmation, toggleConfirmation] = useToggle(false);
  const [search, setSearch] = useState('');
  const [siteId, setSiteId] = useState(initialSiteId);
  const [filterState, _setFilterState] = useState<FilterState>(getInitialFilterState());

  const setFilterState = useCallback(
    (nextState: Partial<FilterState>) => {
      _setFilterState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFilterState],
  );

  const resetFilterState = useCallback(() => {
    setFilterState(getInitialFilterState());
  }, [setFilterState]);

  const [entities, setEntities] = useState<Record<string, AuditTransactionEntityInput>>({});
  const [{ fetching: createTransactionFetching }, execute] = useCreateAuditTransactionMutation();
  const columns = useMemo(() => getNewAuditTableColumns(), []);

  const sorts = useMemo(() => {
    return parseSorts(table.state.sorts, columns);
  }, [columns, table.state.sorts]);

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useNewAuditItemLocationsQuery,
    {
      filters: {
        itemTypes: entityType,
        siteIds: siteId ? [siteId] : [],
        _or: [{ nonZeroTotalQuantity: true }, { recentOnly: true }],
        itemStatuses: [ItemStatusEnum.Active],
        search,
        locationStatuses: [LocationStatusEnum.Active],
        locationIds: filterState.locationIds,
        categoryIds: filterState.categoryId ? [filterState.categoryId] : [],
      },
      sorts,
    },
    {
      edgeKey: 'itemlocations',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
      pause: !Boolean(siteId),
    },
  );

  useEffect(() => {
    if (initialSiteId) setInitialSite(initialSiteId);
    setEntityType(entityType);
  }, [entityType, initialSiteId, setInitialSite, setEntityType]);

  const filteredEntities = useMemo(() => {
    return Object.values(entities).filter((input) => typeof input.quantity === 'number');
  }, [entities]);

  const onCreate = useCallback(() => {
    if (filteredEntities.length) {
      toggleConfirmation(true);
    } else {
      toggleError(true);
    }
  }, [filteredEntities.length, toggleConfirmation, toggleError]);

  const onConfirmCreate = useCallback(() => {
    const updatedEntity = filteredEntities.map((item) => {
      return {
        ...item,
        meta: {
          note: removeExtraSpacesAndNewlines(item?.meta?.note || ''),
          formattedNote: item?.meta?.note || '',
        },
      };
    });

    execute({ input: { entities: updatedEntity } }).then((response) => {
      toggleConfirmation(false);
      if (response.data?.createAuditTransactions?.success) {
        history.replace(auditReportLink);
        SnackbarService.show({ message: Reports.AuditReportSuccess });
      }
    });
  }, [auditReportLink, execute, filteredEntities, history, toggleConfirmation]);

  const rows = useMemo(() => {
    return data?.itemlocations.edges?.map(({ node }) => node) || [];
  }, [data?.itemlocations.edges]);

  const isEntitiesModified = Object.values(entities).some(
    ({ quantity }) => typeof quantity === 'number',
  );

  const onCancelHandler = () => {
    setEntities({});
  };

  return (
    <ReportContainer
      hasTopDivider
      title={title}
      stackWrapperClass={className}
      titleBarWrapperClass="px-[16px] md:px-[24px] lg:px-[24px]">
      <>
        <ReportPreviewTable
          className=""
          columns={columns}
          data={rows}
          extraProps={{ entities, setEntities, siteId, filterState, setFilterState }}
          initialLoading={initialFetching}
          loading={fetching}
          pagination={true}
          persistKey="new_audit_report_table"
          rowHeight={80}
          setState={table.setState}
          state={table.state}
          total={data?.itemlocations.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          filterNode={
            <Box className="flex !w-[100%] flex-wrap gap-[16px] md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                suggestionData={searchSuggestions || []}
                value={search}
                onChange={setSearch}
                onChangeSearch={setSearchValue}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
              <FormSiteSelect
                className="w-full md:w-[374px] lg:w-[180px]"
                clearable={false}
                placeholder={Reports.SelectSite}
                value={siteId}
                onChange={(nextSiteId) => {
                  if (nextSiteId) {
                    setEntities({});
                    resetFilterState();
                    setSiteId(nextSiteId);
                    setSite(nextSiteId);
                  }
                }}
              />
            </Box>
          }
          paginationClass="p-[24px] flex-wrap md:gap-[24px]"
        />
        <Stack className="mt-24 flex gap-[16px] px-16" justifyContent="end">
          <Button
            disabled={createTransactionFetching || !isEntitiesModified}
            loading={createTransactionFetching}
            theme="success"
            onClick={onCreate}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          <Button
            disabled={!isEntitiesModified}
            onClick={onCancelHandler}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Stack>
      </>
      {showError && <AuditCreateError onClose={toggleError} />}
      {
        showConfirmation && (
          <AuditCreateConfirmation
            count={filteredEntities.length}
            onClose={toggleConfirmation}
            onConfirm={onConfirmCreate}
          />
        )
      }
    </ReportContainer >
  );
};

export default withRouter(NewAuditReport);
