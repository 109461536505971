import { textValidator } from '../../../../../../components/Form/utils/validators';
import Inventory from '../../../../../../i18n/Inventory';

export const BULK_MOVE_FORM_RULES = {
  destinationSiteId: {
    validate: textValidator(Inventory.FormValidationMessages.ToSiteRequired),
  },
  destinationLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.ToLocationRequired),
  },
};
