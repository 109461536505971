import capitalize from 'lodash/capitalize';
import { ReceivableStatusEnum } from 'app/types/schema';

export const ReceiveStatus = {
  [ReceivableStatusEnum.Completed]: 'Received',
  [ReceivableStatusEnum.Deleted]: 'Closed',
  [ReceivableStatusEnum.InProgress]: 'Pending',
};

export const getReceiveStatusLabel = (status: ReceivableStatusEnum) => {
  return ReceiveStatus[status] || capitalize(status);
};

export const ReceivingStatuses = [
  {
    label: 'Pending',
    value: ReceivableStatusEnum.InProgress,
  },
  {
    label: 'Received',
    value: ReceivableStatusEnum.Completed,
  },
  {
    label: 'Closed',
    value: ReceivableStatusEnum.Deleted,
  },
];
