import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { DateFormatEnum, formatDate } from 'app/utils/date';

export const getProjectsTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Project',
    sortable: true,
    value: 'name',
    width: 125,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
  },
  {
    id: 'projectCode',
    label: 'Project Code',
    value: 'projectCode',
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
    value: 'updatedBy',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.updatedBy?.name}>
        {row.updatedBy?.name}
      </span>
    ),
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    sortable: true,
    value: 'updatedAt',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt, DateFormatEnum.FullDate)}>
        {formatDate(row.updatedAt, DateFormatEnum.FullDate)}
      </span>
    ),
  },
];
