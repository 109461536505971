import { useMemo } from 'react';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import { Maybe, SortOrderEnum } from 'app/types/schema';

import { useProjectsSelectQuery } from '../../graphql/queries/generated/projectsSelect';

export interface ProjectsSelectProps {
  name: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const [{ fetching, data }] = useProjectsSelectQuery({
    pause,
    variables: { filters: { projectIds: value } },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.projects.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.projects.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;

  const [{ fetching, data }] = useProjectsSelectQuery({
    variables: {
      filters: { search: inputValue },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.projects.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.projects.edges],
  );

  return { isLoading: fetching, options };
};

const ProjectsSelect = (props: ProjectsSelectProps) => {
  return (
    <AsyncMultiSelect
      {...props}
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

export default ProjectsSelect;
