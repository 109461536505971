import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EditAssetModel from 'app/modules/assetModels/views/EditAssetModel';

function EditAssetModelPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  const id = match.params.id;

  return (
    <AdminLayout>
      <EditAssetModel id={id} />
    </AdminLayout>
  );
}

export default withRouter(EditAssetModelPage);
