import { useCallback, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import MaterialSnackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import routes from 'app/consts/routes';
import { TransactionSubTypeEnum } from 'app/types/schema';
import Link from '../Link';

import { SnackbarContentProps } from './types';

type KitProfile = {
  _id: string;
  status: string;
  type: string;
  sku: string;
};

type Props = { open: boolean; onClose: () => void } & SnackbarContentProps;

const classes = {
  action: 'flex-shrink-0 self-center',
  filledSuccess: 'bg-green-800',
  filledError: 'bg-red-800',
  filledWarning: 'bg-orange-800 text-white',
  filledInfo: 'bg-blue-800',
  message: 'break-words',
};

export const duration = 5000;
const position = { horizontal: 'center', vertical: 'top' } as const;
const transitionDuration = { enter: 400, exit: 10 } as const;

const Snackbar = (props: Props) => {
  const { message, children, type, open, onClose, fullError } = props;

  const onSnackbarClose = useCallback(
    (_: any, reason: SnackbarCloseReason) => {
      if (reason === 'clickaway' || reason === 'timeout') {
        return;
      }
      onClose();
    },
    [onClose],
  );

  const { formattedMessage } = useMemo(() => {
    const words = message.split(' ');
    const kitWords = words[words.length - 1].split(',')[0];

    if (!kitWords.startsWith(TransactionSubTypeEnum.Kit)) {
      return {
        formattedMessage: <span className="text-[16px] font-semibold">{message}</span>,
      };
    }

    let kits: KitProfile[] = [];
    let isAsset = false;

    const error = fullError?.graphQLErrors?.[0];
    if (error) {
      const { assetKitItemProfiles, inventoryKitItemProfiles } = error.extensions?.publicErrorParams ?? {};

      if (assetKitItemProfiles?.length) {
        kits = assetKitItemProfiles;
        isAsset = true;
      } else if (inventoryKitItemProfiles?.length) {
        kits = inventoryKitItemProfiles;
        isAsset = false;
      }
    }

    if (kits && kits.length) {
      return {
        formattedMessage: (
          <span className="text-[16px] font-semibold">
            {words.slice(0, -1).join(' ')}{' '}
            {kits.map((kit, index) => (
              <span key={kit._id}>
                <Link
                  className="text-[#0c92d5] cursor-pointer"
                  title={kit.sku}
                  onClick={() =>
                    window.open(
                      isAsset
                        ? routes.EditAssets(kit._id || '')
                        : routes.EditInventory(kit._id || '')
                    )}
                >
                  {kit.sku}
                </Link>
                {index < kits.length - 1 ? ',' : ''}{' '}
              </span>
            ))}
          </span>
        ),
      };
    }
    return {
      formattedMessage: <span className="text-[16px] font-semibold">{message}</span>,
    };
  }, [message, fullError]);

  return (
    <MaterialSnackbar
      TransitionComponent={Slide}
      anchorOrigin={position}
      autoHideDuration={duration}
      className="mt-[75px]"
      open={open}
      style={{ zIndex: 6000 }}
      transitionDuration={transitionDuration}
      onClose={onSnackbarClose}>
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        className="max-w-[500px]"
        classes={classes}
        icon={false}
        severity={type}
        variant="filled">
        {children ?? formattedMessage}
      </Alert>
    </MaterialSnackbar>
  );
};

export default Snackbar;
