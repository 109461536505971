import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type PartnerTenantsTableRowFragment = { __typename?: 'PartnerTenantSchema', id: string, partnerType: Types.PartnerTenantTypeEnum, status: Types.PartnerTenantStatusEnum, tenant: { __typename?: 'TenantSchema', id: string, name: string, logo?: string | null }, allowedUsers?: Array<{ __typename?: 'UserSchema', id: string, name: string }> | null, accessibleCategories?: Array<{ __typename?: 'CategorySchema', id: string, name: string }> | null };

export const PartnerTenantsTableRowFragmentDoc = gql`
    fragment PartnerTenantsTableRow on PartnerTenantSchema {
  id
  tenant {
    id
    name
    logo
  }
  partnerType
  status
  allowedUsers {
    id
    name
  }
  accessibleCategories {
    id
    name
  }
}
    `;