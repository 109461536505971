import { CreateAssetItemReminderInput } from 'app/types/schema';

export interface AssetReminderInput extends CreateAssetItemReminderInput {
  isMaintenance: boolean;
}

export const enum AssetItemReminderCronEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}
