import SearchContainers from 'app/modules/ship/view/searchContainers';

import ShippingLayout from '../../modules/ship/layout';

function SearchContainerPage() {
  return (
    <ShippingLayout>
      <SearchContainers />
    </ShippingLayout>
  );
}

export default SearchContainerPage;
