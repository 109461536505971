import { useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { UseNormalOrderRequestReturnType } from '../OrderItemRequest/hook/useNormalOrderRequest';
import {
  createOrderItem,
  findOrderItemById,
  updateItem,
  updateItems,
} from '../OrderItemRequest/utils/utils';
import OrderItemsTable from '../OrderItemsTable';
import OrderRequestForm from '../OrderRequestForm';
import { OrderRequestInput } from '../OrderRequestForm/type';
import { WarningService } from '../../../../../../components/WarningAlert';
import Orders from '../../../../../../i18n/Orders';
import { getDuplicateOrderItem, getDuplicateOrderItems } from '../../utils/utils';

interface Props {
  state: UseNormalOrderRequestReturnType['normalOrderState'];
  actions: UseNormalOrderRequestReturnType['normalOrderActions'];
  onNextStep: () => void;
  onBulkOrderRequest: () => void;
  disabled?: boolean;
}

const NormalOrderRequest = (props: Props) => {
  const { state, actions, onNextStep, onBulkOrderRequest, disabled } = props;

  const onAddItem = useCallback(
    (newOrderItem: OrderRequestInput) => {
      // Note: When we use createOrderItem function, It will append newly created Order Item with new nano Id.
      const orderItems = createOrderItem(state.orderItems, newOrderItem);
      const currentOrderItem = orderItems[orderItems.length - 1];

      const { duplicateOrderItem, duplicateUpdatedState } = getDuplicateOrderItem(
        orderItems,
        currentOrderItem.id as string,
      );

      if (duplicateOrderItem) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.DuplicateOrderItemInNormalOrderRequest(
            Number(duplicateUpdatedState?.quantity || 0),
          ),
          onConfirm: () => {
            actions.onUpdateOrderItem(duplicateOrderItem.id as string, duplicateUpdatedState);
          },
        });
      } else {
        actions.onAddItem(newOrderItem);
      }
    },
    [actions.onAddItem, actions.onUpdateOrderItem, state.orderItems],
  );

  const onUpdateOrderItem = useCallback(
    (id: string, nextState: Partial<OrderRequestInput>) => {
      const currentOrderItem = findOrderItemById(state.orderItems, id);
      if (!currentOrderItem) {
        return;
      }

      // 1.) We update state of order Item
      actions.onUpdateOrderItem(id, nextState);

      // 2.) We check for duplicate Order Request Items
      const { duplicateOrderItem, duplicateUpdatedState } = getDuplicateOrderItem(
        updateItem(state.orderItems, id, nextState),
        id,
      );
      // 3.) If duplicate Order Request Item found, we show alert
      if (duplicateOrderItem) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.DuplicateOrderItemInNormalOrderRequest(
            Number(duplicateUpdatedState?.quantity || 0),
          ),
          onConfirm: () => {
            // 4.) If user allow to merge duplicate order Items
            actions.onUpdateDuplicateOrderItems({
              update: [
                {
                  id: duplicateOrderItem.id,
                  ...duplicateUpdatedState,
                },
              ],
              delete: [currentOrderItem.id as string],
            });
          },
          onCancel: () => {
            // 5.) If User cancel a duplication Merge, We will reset state of order Item
            actions.onUpdateOrderItem(id, currentOrderItem);
          },
        });
      }
    },
    [state.orderItems, actions.onUpdateOrderItem, actions.onUpdateDuplicateOrderItems],
  );

  const onUpdateOrderItems = useCallback(
    (nextState: Partial<OrderRequestInput>) => {
      const currentOrderRequestItems = [...state.orderItems];

      // Updated Order Items
      actions.onUpdateOrderItems(nextState);
      const updatedOrderItems = updateItems(currentOrderRequestItems, nextState);
      const { updateDuplicateOrderItems, removeDuplicateOrderIds } =
        getDuplicateOrderItems(updatedOrderItems);

      if (removeDuplicateOrderIds.length > 0) {
        WarningService.showWarning({
          message: Orders.FormValidationMessages.DuplicateOrderItemInBulkOrderRequest,
          onConfirm: () => {
            actions.onUpdateDuplicateOrderItems({
              update: updateDuplicateOrderItems,
              delete: removeDuplicateOrderIds,
            });
          },
          onCancel: () => {
            actions.onUpdateDuplicateOrderItems({
              update: currentOrderRequestItems,
              delete: [],
            });
          },
        });
      }
    },
    [state.orderItems, actions.onUpdateOrderItems, actions.onUpdateDuplicateOrderItems],
  );

  return (
    <Box className="flex flex-wrap gap-[48px]">
      <Box className="w-[300px] pt-6 md:min-w-[416px] md:max-w-[416px]  lg:min-w-[416px] lg:min-w-[416px] xl:max-w-[416px] xl:max-w-[416px]">
        <OrderRequestForm
          actions={actions}
          disabled={disabled}
          formState={state.formState}
          onAddItem={onAddItem}
          onBulkOrderRequest={onBulkOrderRequest}
        />
      </Box>
      <Box className="flex-1">
        <OrderItemsTable
          actions={actions}
          state={state}
          onNextStep={onNextStep}
          onUpdateOrderItem={onUpdateOrderItem}
          onUpdateOrderItems={onUpdateOrderItems}
        />
      </Box>
    </Box>
  );
};

export default NormalOrderRequest;
