import Assets from 'app/i18n/Assets';
import Inventory from 'app/i18n/Inventory';
import { TRANSACTION_TYPE_CONFIG } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import { TransactionFilters, TransactionSubTypeEnum, TransactionTypeEnum } from 'app/types/schema';

const defaultTransactionFilters: TransactionFilters = {
  types: [],
  subTypes: [],
};

export const transactionTypes = [
  {
    label: Inventory.FormLabels.All,
    id: '-1',
    filters: defaultTransactionFilters,
  },
  {
    label: Inventory.FormLabels.Audit,
    id: 'audit',
    filters: TRANSACTION_TYPE_CONFIG.audit,
  },
  {
    label: Inventory.FormLabels.Checkin,
    id: 'checkin',
    filters: TRANSACTION_TYPE_CONFIG.checkin,
  },
  {
    label: Inventory.FormLabels.Checkout,
    id: 'checkout',
    filters: TRANSACTION_TYPE_CONFIG.checkout,
  },
  {
    label: Inventory.FormLabels.Delete,
    id: 'delete',
    filters: TRANSACTION_TYPE_CONFIG.delete,
  },
  {
    label: Inventory.FormLabels.Move,
    id: 'move',
    filters: TRANSACTION_TYPE_CONFIG.move,
  },
  {
    label: Inventory.FormLabels.New,
    id: 'new',
    filters: TRANSACTION_TYPE_CONFIG.new,
  },
  {
    label: Inventory.FormLabels.Restock,
    id: 'restock',
    filters: TRANSACTION_TYPE_CONFIG.restock,
  },
  {
    label: Inventory.FormLabels.Undelete,
    id: 'undelete',
    filters: TRANSACTION_TYPE_CONFIG.undelete,
  },
  {
    label: Inventory.FormLabels.Remove,
    id: 'remove',
    filters: TRANSACTION_TYPE_CONFIG.remove,
  },
  {
    label: Inventory.FormLabels.RestockKit,
    id: 'restockKit',
    filters: TRANSACTION_TYPE_CONFIG.restockKit,
  },
  {
    label: Inventory.FormLabels.RemoveKit,
    id: 'removeKit',
    filters: TRANSACTION_TYPE_CONFIG.removeKit,
  },
  {
    label: Assets.FormLabels.Reserve,
    id: 'RESERVE',
    filters: TRANSACTION_TYPE_CONFIG.reserve
  }
  // {
  //     label: Inventory.FormLabels.Kit,
  //     value: TransactionSubTypeEnum.Audit,
  //     filters: {
  //         _or: [{
  //             types: TransactionTypeEnum.Restock,
  //             subTypes: TransactionSubTypeEnum.Kit
  //         },
  //             {
  //             types: TransactionTypeEnum.Remove,
  //             subTypes: TransactionSubTypeEnum.Kit
  //         },
  //         ]
  //     }
  // },
];

export const getTransactionTypeSubTypeFilterById = (typeId: string): TransactionFilters => {
  return (
    transactionTypes.find((transactionType) => transactionType.id === typeId)?.filters ||
    defaultTransactionFilters
  );
};

export const getTransactionType = (type: TransactionTypeEnum, subType: TransactionSubTypeEnum) => {
  return transactionTypes.find((item) => {
    return item?.filters?.types?.includes(type) && item?.filters?.subTypes?.includes(subType);
  });
};

export const getTransactionTypeTitle = (
  type: TransactionTypeEnum,
  subType: TransactionSubTypeEnum,
) => {
  return getTransactionType(type, subType)?.label || '';
};
