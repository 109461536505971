import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import FormCategorySelect from 'app/components/ProcureForm/FormCategorySelect';
import { CategoryFilterLabelNodeProps } from 'app/modules/inventory/components/CategoryFilterLabelNode/types';

import { getCostWithCurrency } from '../../../../utils/cost';
import { UseEntityManagerReturnType } from '../../../components/EntityManager/useEntityManager';
import TableImageView from '../../../components/TableImageView';
import { UseItemInStockWithSearchDialogStateReturnType } from '../FormItemInStockWithSearchDialog/hook/useItemInStockWithSearchDialogState';
import ReadMore from 'app/modules/components/ReadMore';

const CategoryFilterLabelNode = (props: CategoryFilterLabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const {
    state: itemInStockDialogState,
    actions,
    partnerTenantId,
  } = extraProps as {
    state: UseItemInStockWithSearchDialogStateReturnType['state'];
    actions: UseItemInStockWithSearchDialogStateReturnType['actions'];
    partnerTenantId?: string;
  };

  return (
    <FormCategorySelect
      className="mt-[5px] w-[150px]"
      name="category-filter"
      placeholder="Category"
      value={itemInStockDialogState.categoryId}
      partnerTenantId={partnerTenantId}
      onChange={(value) => {
        actions?.onCategoryIdChange(value);
      }}
    />
  );
};

export const getItemInStockSearchTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'image',
    label: 'Image',
    value: 'attachments',
    valueNode: ({ row }) => {
      return <TableImageView attachments={row.attachments} />;
    },
    width: 100,
    sortable: false,
  },
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
    sortable: true,
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    sortable: true,
  },
  {
    id: 'model',
    label: 'Model',
    value: 'model',
    valueNode: ({ row }) => {
      return <div>{row?.mName || row?.model || ''}</div>;
    },
    sortable: false,
  },
  {
    id: 'category',
    label: 'Category',
    labelNode: CategoryFilterLabelNode,
    value: 'category',
    valueNode: ({ row }) => {
      return <div>{row?.category?.name || ''}</div>;
    },
    width: 200,
    sortable: false,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    sortable: true,
    valueNode: ({ row }) => <ReadMore text={row?.description || ''} />,
  },
  {
    id: 'brand',
    label: 'Brand',
    value: 'brand',
    sortable: true,
  },
  {
    id: 'cost',
    label: 'Cost',
    value: 'cost',
    valueNode: ({ row }) => {
      const costValue = row?.cost !== undefined ? `${getCostWithCurrency(Number(row?.cost))}` : '';
      return (
        <span className="truncate" title={costValue}>
          {costValue}
        </span>
      );
    },
    sortable: false,
    rowAlign: 'right',
    width: 150,
  },
];
