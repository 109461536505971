const Category = {
  Category: 'Category',
  Categories: 'Categories',
  NewCategory: 'New Category',
  EditCategory: 'Edit Category',
  FormLabels: {
    CategoryCode: 'Category Code',
    CategoryName: 'Category Name',
    Description: 'Description',
    CategoryManager: 'Category Manager(s)',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Category Name between 3 and 32 characters.',
    DescriptionMaxLength: 'Please enter a Description up to 500 characters.',
    CategoryManagerLength:
      'Please select at least one Category Manager. A user may be assigned a Category Manager role in the User Account tab.',
  },
};

export default Category;
