import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type AddressesDetailsFragment = { __typename?: 'AddressSchema', addressLineOne: string, addressLineTwo?: string | null, addressName: string, city: string, companyId: string, state: string, country?: string | null, zipCode: string };

export const AddressesDetailsFragmentDoc = gql`
    fragment AddressesDetails on AddressSchema {
  addressLineOne
  addressLineTwo
  addressName
  city
  companyId
  state
  country
  zipCode
}
    `;