import {
  composeValidators,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Role from 'app/i18n/Role';

export const ROLES_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Role.FormValidationMessages.NameRequired),
      textMinLengthValidator(Role.FormValidationMessages.NameRequired, 3),
    ),
  },
};
