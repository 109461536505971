import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import {
  AsyncSingleSelectProps,
  UseSelectedValueReturn,
} from 'app/components/AsyncSingleSelect/types';
import AsyncSingleTableFilter from 'app/components/AsyncSingleTableFilter';

import {
  AsyncMultiSelectProps,
  UseSelectedValueProps,
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from '../../../../components/AsyncMultiSelect/utils';
import AsyncMultiTableFilter from '../../../../components/AsyncMultiTableFilter';
import { Maybe, SortOrderEnum } from '../../../../types/schema';
import { useDepartmentsSelectQuery } from '../../graphql/queries/generated/departmentsSelect';
import { DepartmentsSelectProps } from '../DepartmentsSelect';

interface DepartmentsQueryVariables {}

export interface DepartmentSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: DepartmentsQueryVariables;
}

const useSelectedValues = (
  props: UseSelectedValueProps & { isMultiple?: boolean },
): UseSelectedValuesReturn | UseSelectedValueReturn => {
  const { value, pause, isMultiple = true } = props;

  const [{ fetching, data }] = useDepartmentsSelectQuery({
    pause,
    variables: { filters: { departmentIds: value } },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.departments.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.departments.edges],
  );

  if (isMultiple) return { isDisabled: fetching, selectedValues: values };
  else return { isDisabled: fetching, selectedValue: values[0] };
};

const useSelectQuery = (
  props: UseSelectQueryProps & DepartmentsQueryVariables,
): UseSelectQueryReturn => {
  const { inputValue } = props;

  const [{ fetching, data }] = useDepartmentsSelectQuery({
    variables: {
      filters: { search: inputValue },
      limit: 10000,
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.departments.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.departments.edges],
  );

  return { isLoading: fetching, options };
};

const DepartmentsTableFilter = (props: DepartmentsSelectProps) => {
  const { value, onChange, isMultiple = true, ...otherProps } = props;

  if (isMultiple) {
    return (
      <AsyncMultiTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValuesReturn}
        value={value as AsyncMultiSelectProps['value']}
        onChange={onChange as AsyncMultiSelectProps['onChange']}
      />
    );
  } else {
    return (
      <AsyncSingleTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValueReturn}
        value={value as AsyncSingleSelectProps['value']}
        onChange={onChange as AsyncSingleSelectProps['onChange']}
      />
    );
  }
};

export default DepartmentsTableFilter;
