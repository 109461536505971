import { getTokenData } from 'app/modules/wishlist/utils/token';
import InviteWishlist from 'app/modules/wishlist/views/InviteWishlist';
import WishlistLayout from 'app/modules/wishlist/WishlistLayout';

const InviteWishlistPage = () => {
  const tokenDataValues = getTokenData();

  return (
    <WishlistLayout>
      <InviteWishlist tokenDataValue={tokenDataValues || undefined} />
    </WishlistLayout>
  );
};

export default InviteWishlistPage;
