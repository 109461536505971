import React, { createContext, ReactElement } from 'react';
import PropTypes from 'prop-types';

interface Props {
  values: {
    onLinkButtonClick: (next: () => void) => void;
  };
  children?: ReactElement;
}

export const UnifiedHeaderContext = createContext<Props['values']>({
  onLinkButtonClick: (next: () => void) => {
    next();
  },
});

export const UnifiedHeaderContextProvider = (props: Props) => {
  const { children, values } = props;
  return <UnifiedHeaderContext.Provider value={values}>{children}</UnifiedHeaderContext.Provider>;
};

UnifiedHeaderContextProvider.propTypes = {
  onLinkClick: PropTypes.func,
};

UnifiedHeaderContextProvider.defaultProps = {
  onLinkClick: null,
};
