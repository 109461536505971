import { isAccessible } from '../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { UserProfileFragment } from '../../auth/graphql/fragments/generated/UserProfile';

export const getShippingLayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) =>
  [
    {
      label: 'Shipment Search',
      value: 'shipment-search',
      subject: AllowedPermissionsSubjectEnum.Shipment,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Container Search',
      value: 'container-search',
      subject: AllowedPermissionsSubjectEnum.Shipment,
      action: AllowedPermissionActionsEnum.Delete,
    },
  ].filter((tab) => isAccessible(permissions, tab.action, tab.subject));
