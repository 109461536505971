import { useCallback, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import MobileMenuIcon from 'app/assets/mobileMenuIcon.svg';
import { TenantFeatureTypeEnum } from 'app/types/schema';

import useOutSideClick from '../../../../../hooks/useOutSideClick/useOutSideClick';
import { HeaderItem } from '../../types';
import HeaderNavLink from '../HeaderNavLink';

interface Prop {
  headerItems: HeaderItem[];
}

const MobileMenu = (props: Prop) => {
  const { headerItems } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  useOutSideClick([ref], () => {
    if (open) {
      toggleMenu();
    }
  });

  return (
    <div
      ref={ref}
      className="mobile-menu relative mr-[5px] flex flex w-[36px] items-center justify-center lg:hidden">
      <div className="flex h-[36px] w-[36px] items-center justify-center rounded-[8px]  border border-grey-500 bg-white">
        {open ? (
          <div
            className="flex h-[20px] w-[20px] items-center justify-center"
            onClick={() => setOpen(false)}>
            <ClearIcon className="text-[20px] font-semibold text-gray-900" />
          </div>
        ) : (
          <img
            alt="Mobile menu icon"
            className="w-[24px] cursor-pointer"
            src={MobileMenuIcon}
            onClick={toggleMenu}
          />
        )}
      </div>
      <div
        className={`
            absolute 
            top-[104px] 
            left-[-18px] 
            z-[1] 
            w-[100dvh] 
            overflow-hidden 
            rounded-br-[8px] 
            rounded-bl-[8px]
            bg-grey-100
            px-[12px]
            py-[16px]
            shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]
            ease-in ${!open ? 'hidden' : ''}`}>
        {headerItems.map((headerItem) => {
          const isScreen = headerItem.visible === TenantFeatureTypeEnum.Screen;
          const visibilityStyle = isScreen ? 'text-grey-400' : 'text-grey-700 hover:text-blue-900';
          const path = isScreen ? '#' : headerItem.path;
          return (
            <HeaderNavLink
              key={'mobile-menu-item-' + headerItem.title}
              activeClassName="!text-white bg-blue-900 !font-semibold"
              className={`block flex h-[40px] w-[100%] items-center rounded-[8px] px-[12px] py-[8px] text-[16px] font-semibold font-normal ${visibilityStyle}`}
              to={path}
              onClick={toggleMenu}>
              <span>{headerItem.title}</span>
            </HeaderNavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileMenu;
