import { useEffect, useState } from 'react';
import { cx } from '@emotion/css';
import { Button } from '@procurenetworks/procure-component-library';
import { uniqBy } from 'lodash';
import noop from 'lodash/noop';
import times from 'lodash/times';
import Loader from 'app/components/Loader';
import Common from 'app/i18n/Common';
import Warehouse from 'app/i18n/Warehouse';
import {
  LabelStockEnum,
  LabelTypeEnum,
} from 'app/modules/labels/views/Labels/components/Forms/types';
import {
  parseRackAndShelfData,
  parseWarehouseLocationName,
  WAREHOUSE_LABEL_CONFIG,
} from 'app/modules/warehouse/views/WarehouseDetails/utils';
import { SortOrderEnum, WarehouseTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import WarehouseItem from '../../../WarehouseDetails/components/WarehouseItem';
import WarehouseItemsContainer from '../../../WarehouseDetails/components/WarehouseItemsContainer';
import WarehouseLabel from '../../../WarehouseDetails/components/WarehouseLabel';
import { WarehouseValueKey } from '../../../WarehouseDetails/types';
import { useLocationsListQuery } from '../../graphql/queries/generated/locations';
import { Divider } from '@mui/material';

interface Props {
  label: string;
  siteId?: string;
  rackIncrementor?: number;
  warehouseId: string;
  items: string[];
  itemKey: WarehouseValueKey;
  subItemKey: WarehouseValueKey;
  onSelectItem: (item: string) => void;
  disabled?: boolean;
  warehouseType?: string;
}
type LocationEdges = Array<{ __typename?: 'LocationSchema'; id: string; name: string }>;
type PageInfo = { __typename?: 'PageInfo'; nextCursor?: string | null; hasNextPage: boolean };

const WarehouseItems = (props: Props) => {
  const [print, setPrint] = useState(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>({ nextCursor: '', hasNextPage: true });
  const [printItems, setPrintItems] = useState<LocationEdges>([]);
  const {
    itemKey,
    siteId,
    warehouseId,
    subItemKey,
    items,
    label,
    onSelectItem,
    rackIncrementor = 5,
    disabled,
    warehouseType
  } = props;

  const [result, getLocations] = useLocationsListQuery({
    variables: {
      filters: { namePrefix: label, siteIds: siteId ? [siteId] : [], warehouseId: warehouseId },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
      after: pageInfo?.nextCursor,
    },
    pause: !print,
  });

  useEffect(() => {
    if (!result?.fetching && pageInfo?.hasNextPage) {
      setPageInfo({ ...pageInfo, ...result?.data?.locations?.pageInfo });
    }
  }, [result?.fetching, result?.data?.locations?.pageInfo]);

  useEffect(() => {
    if (result && result?.data && result?.data?.locations?.edges?.length > 0) {
      const rows = result?.data?.locations?.edges?.map((location) => location.node);
      setPrintItems(uniqBy([...printItems, ...rows], (location) => location?.id));
    }
  }, [result?.data?.locations?.edges]);

  useEffect(() => {
    if (pageInfo?.nextCursor && pageInfo?.hasNextPage) {
      getLocations();
    }
  }, [pageInfo?.nextCursor, pageInfo?.hasNextPage]);

  useEffect(() => {
    if (printItems?.length === result?.data?.locations?.totalCount) {
      printLabels();
    }
  }, [printItems?.length, result?.data?.locations?.totalCount]);

  const printLabels = () => {
    localStorage.setItem(
      'printLabels',
      JSON.stringify({
        type: LabelTypeEnum.Location,
        arrowDisplay: 'up',
        labelStock: LabelStockEnum.Avery6792,
        ids: printItems.map((items) => items?.id),
        data: printItems,
      }),
    );
    window.open('/admin/print-label', '_blank');
  };

  const onPrint = () => {
    setPrint(true);
    getLocations();
    if (printItems?.length === result?.data?.locations?.totalCount) {
      printLabels();
    }
  };

  return (
    <>
      {result?.fetching && (
        <Box className={cx('absolute h-[80vh] w-[80%]')}>
          <Loader />
        </Box>
      )}
      <Stack className="mt-32" direction="col" spacing="space-y-10">
        <Stack alignItems="center" justifyContent="between">
          <WarehouseLabel className="text-[30px]">{label}</WarehouseLabel>
        </Stack>
        <Stack direction="col">
          {items.length ? (
            items.map((item, index) => {
              let config, subItemCount, shelfItems;
              config = WAREHOUSE_LABEL_CONFIG[itemKey];
              if (warehouseType === WarehouseTypeEnum.RackAndShelf && itemKey === "racks") {
                const { rackNumber, lastShelf } = parseRackAndShelfData(item);
                shelfItems = Array.from(
                  { length: lastShelf.charCodeAt(0) - 64 },
                  (_, i) => String.fromCharCode(65 + i)
                );
                subItemCount = shelfItems.length;
              } else {
                subItemCount = config.getSubItemCount(item, rackIncrementor);
              }
              return (
                <WarehouseItemsContainer
                  key={index}
                  className={cx(
                    itemKey ? 'cursor-pointer' : ``,
                    items.length > 1 && items.length - 1 !== index && 'border-b-[0px]',
                    'border-[2px]',
                  )}
                  onClick={itemKey && !disabled ? () => onSelectItem(item) : noop}>
                  <Stack direction="col" spacing="space-y-10">
                    {config ? (
                      <p className={cx('text-[18px] font-semibold text-grey-900')}>
                        {config.labelPrefix} {(warehouseType === WarehouseTypeEnum.RackAndShelf && itemKey === "shelves") ? parseRackAndShelfData(item)?.lastShelf : config.getLabel(index)}
                      </p>
                    ) : null}
                    <Box className="flex flex-wrap gap-[16px]">
                      {warehouseType === WarehouseTypeEnum.RackAndShelf && itemKey === "racks"
                        ? shelfItems && shelfItems.map((shelf) => (
                          <WarehouseItem
                            key={shelf}
                            label={shelf}
                            className="h-[36px] w-[76px] text-[14px]"
                          />
                        ))
                        : times(subItemCount).map((__, itemIndex) => {
                          const subConfig = WAREHOUSE_LABEL_CONFIG[subItemKey];
                          return (
                            <WarehouseItem
                              key={itemIndex}
                              label={subConfig.getLabel(itemIndex, rackIncrementor)}
                              className="h-[36px] w-[76px] text-[14px]"
                            />
                          );
                        })}
                    </Box>
                  </Stack>
                </WarehouseItemsContainer>
              );
            })
          ) : (
            <Stack className="py-32" justifyContent="center">
              <WarehouseLabel>{Warehouse.NoZoneAvailable}</WarehouseLabel>
            </Stack>
          )}
        </Stack>
        <Divider className="!mt-[32px] !mb-[24px]" />
        <Box className="!m-[0px]">
          <Box className="float-right w-fit ">
            <Button theme="success" onClick={onPrint} classes="min-w-[132px] h-[44px]">
              {Common.Actions.Print}
            </Button>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default WarehouseItems;
