import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import AccessControl from 'app/components/AccessControl';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { useEntityManagerContext } from 'app/modules/components/EntityManager';
import { AllowedPermissionActionsEnum } from 'app/types/schema';

interface Props extends RouteComponentProps {
  label: string;
  to: string;
}

function EntityManagerAdd(props: Props) {
  const { history, to, label } = props;
  const { permissions, subject } = useEntityManagerContext();

  const onAddEntity = useCallback(() => {
    history.push(to);
  }, [history, to]);

  return (
    <AccessControl
      action={AllowedPermissionActionsEnum.Create}
      permissions={permissions}
      subject={subject}>
      <Button theme="success" onClick={onAddEntity} classes="!mt-16 min-w-[204px] h-[44px]">
        {label}
      </Button>
    </AccessControl>
  );
}

export default withRouter(EntityManagerAdd);
