import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteNetworkAssetsMutationVariables = Types.Exact<{
  input: Types.DeleteNetworkAssetInput;
}>;


export type DeleteNetworkAssetsMutation = { __typename?: 'Mutation', deleteNetworkAssets: { __typename?: 'MutationResponse', success: boolean } };


export const DeleteNetworkAssetsDocument = gql`
    mutation deleteNetworkAssets($input: DeleteNetworkAssetInput!) {
  deleteNetworkAssets(input: $input) {
    success
  }
}
    `;

export function useDeleteNetworkAssetsMutation() {
  return Urql.useMutation<DeleteNetworkAssetsMutation, DeleteNetworkAssetsMutationVariables>(DeleteNetworkAssetsDocument);
};