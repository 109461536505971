import { dateValidator, textValidator } from '../../../../../../components/Form/utils/validators';
import Assets from '../../../../../../i18n/Assets';
import {
  availableQuantityAndTotalQuantityValidator,
  itemInStockValidator,
} from '../../../../../assetsInventory/utils/rules';

export const CHECKOUT_FORM_RULES = {
  itemInStockSearch: {
    validate: textValidator(Assets.FormValidationMessages.ItemInStockSearchRequired),
  },
  itemInStock: itemInStockValidator,
  sourceId: {
    validate: textValidator(Assets.FormValidationMessages.SiteRequired),
  },
  sourceLocationId: {
    validate: textValidator(Assets.FormValidationMessages.SiteLocationRequired),
  },
  actorId: {
    validate: textValidator(Assets.FormValidationMessages.UserRequired),
  },
  dueDate: {
    validate: dateValidator(Assets.FormValidationMessages.DueDateRequired),
  },
  destinationId: {
    validate: textValidator(Assets.FormValidationMessages.ToSiteRequired),
  },
  destinationLocationId: {
    validate: textValidator(Assets.FormValidationMessages.ToLocationRequired),
  },
  quantity: availableQuantityAndTotalQuantityValidator,
};
