import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EditRole from 'app/modules/roles/views/EditRole';

function EditRolePage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  return (
    <AdminLayout>
      <EditRole roleId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditRolePage);
