import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';
import { ItemStatusEnum, ItemTypeEnum, ItemUnion } from 'app/types/schema';

interface Props {
  item?: Pick<ItemUnion, 'id' | 'type' | 'sku' | 'status'>;
}

const ReportSKULink = (props: Props) => {
  const { item } = props;

  if (!item) {
    return null;
  }

  if (item.status === ItemStatusEnum.Deleted) {
    return <>{item.sku}</>;
  }

  const to =
    item.type === ItemTypeEnum.Asset || item.type === ItemTypeEnum.AssetKit
      ? routes.EditAssets(item.id)
      : routes.EditInventory(item.id);

  return (
    <Link className="sku-link truncate" title={item.sku} target="_blank" to={to}>
      {item.sku}
    </Link>
  );
};

export default ReportSKULink;
