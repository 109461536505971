import { Controller } from 'react-hook-form';
import FormDepartmentSelect from 'app/components/ProcureForm/FormDepartmentSelect';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';

const OrderDepartmentField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, isRequired, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="departmentId"
        render={({ field, fieldState }) => (
          <FormDepartmentSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            isRequired={isRequired}
            label={Orders.FormLabels.Department}
            size="medium"
            formLabel={Orders.FormLabels.Department}
          />
        )}
      />
    </div>
  );
};
export default OrderDepartmentField;
