import 'react-phone-input-2/lib/material.css';
import './form-phone-input.css';
import React, { forwardRef, HTMLInputTypeAttribute, useCallback, useState } from 'react';
import { FieldError } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { Maybe } from '../../../types/schema';
import FormError from '../../Form/FormError';
import FormLabel from '../FormLabel';

export interface FormPhoneInputProps {
  className?: string;
  label?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string> | Maybe<number>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onCountryCodeChange?: (value: any) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError | undefined;
  placeholder?: string;
  multiline?: boolean;
  rows?: string | number;
  disabled?: boolean;
  disableCountryGuess?: boolean;
  size?: 'small' | 'medium';
  formLabel?: string;
}

const FormPhoneInput = forwardRef<HTMLDivElement, FormPhoneInputProps>(
  (props: FormPhoneInputProps, ref) => {
    const {
      value,
      onBlur,
      label,
      onChange,
      error,
      isRequired,
      disabled,
      placeholder,
      name,
      disableCountryGuess,
      onCountryCodeChange,
      formLabel,
    } = props;

    const onInputChange = useCallback(
      (
        values: string,
        countryObject: any,
        _event: React.ChangeEvent<HTMLInputElement>,
        _formattedValue: string,
      ) => {
        onChange?.(values);
        onCountryCodeChange?.(countryObject);
      },
      [onChange, onCountryCodeChange],
    );

    return (
      <>
        {formLabel ? (
          <FormLabel isRequired={isRequired} className="text-[14px] font-semibold text-grey-800">
            {formLabel}
          </FormLabel>
        ) : null}
        <div className="form-phone-input mt-6">
          <PhoneInput
            country={'us'}
            countryCodeEditable={false}
            disableCountryGuess={!!disableCountryGuess}
            disabled={!!disabled}
            dropdownClass="country-form-phone-dropdown"
            errorMessage={error ? (error.message as string) : ''}
            inputClass="!w-[100%] !h-[100%] !box-border"
            inputProps={{
              name,
              placeholder,
            }}
            label={label || ''}
            specialLabel={label || ''}
            // @ts-ignore
            value={value || '1'}
            onChange={onInputChange}
            inputStyle={{
              padding: '16px 43px 16px',
            }}
          />
          <FormError error={error} />
        </div>
      </>
      // <TextField
      //   className={className || ''}
      //   disabled={!!disabled}
      //   error={!!error}
      //   errorMessage={error ? (error.message as string) : ''}
      //   inputProps={{}}
      //   label={label || ''}
      //   labelNode={LabelNode}
      //   multiline={!!multiline}
      //   rows={rows || 0}
      //   size={size || 'medium'}
      //   value={value || ''}
      //   onBlur={onInputBlur}
      //   onChange={onInputChange}
      // />
    );
  },
);

export default FormPhoneInput;
