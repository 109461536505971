import AdminLayout from 'app/components/AdminLayout';
import AddLocation from 'app/modules/locations/views/AddLocation';

function AddLocationPage() {
  return (
    <AdminLayout>
      <AddLocation />
    </AdminLayout>
  );
}

export default AddLocationPage;
