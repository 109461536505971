import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditProjectDataFragmentDoc } from '../../fragments/generated/EditProjectData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditProjectDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ProjectFilters>;
}>;


export type EditProjectDataQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', totalCount: number, edges?: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'ProjectSchema', id: string, name: string, description?: string | null, projectCode?: string | null, formattedDescription?: string | null } }> | null } };


export const EditProjectDataDocument = gql`
    query editProjectData($filters: ProjectFilters) {
  projects(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditProjectData
      }
    }
  }
}
    ${EditProjectDataFragmentDoc}`;

export function useEditProjectDataQuery(options?: Omit<Urql.UseQueryArgs<EditProjectDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditProjectDataQuery>({ query: EditProjectDataDocument, ...options });
};