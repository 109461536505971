import Stack from 'app/ui-components/Stack';

interface Props {
  label: string;
  className?: string;
}

const WarehouseItem = (props: Props) => {
  const { label, className } = props;

  return (
    <Stack
      alignItems="center"
      className={`font-15 border-2 border-solid border-[#58c9f9] font-bold text-[#000] group-hover:border-[#fff] group-hover:text-[#fff] ${className}`}
      justifyContent="center">
      {label}
    </Stack>
  );
};

export default WarehouseItem;
