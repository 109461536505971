import { TenantFeatureTypeEnum } from 'app/types/schema';

import { HeaderItem } from '../../types';
import HeaderNavLink from '../HeaderNavLink';

interface Props {
  headerItem: HeaderItem;
}

const HeaderNavItem = (props: Props) => {
  const { headerItem } = props;
  const isScreen = headerItem.visible === TenantFeatureTypeEnum.Screen;
  const visibilityStyle = isScreen
    ? 'text-gray-300 cursor-default'
    : 'hover:bg-blue-300 hover:text-blue-900';
  const path = isScreen ? '#' : headerItem.path;
  return (
    <HeaderNavLink
      activeClassName="!text-white !bg-blue-900"
      className={`app-unified-header-nav-menu-item flex h-[40px] items-center rounded-lg bg-white px-[12px] py-[8px] text-[16px] font-semibold ${visibilityStyle}`}
      to={path}>
      {headerItem.title}
    </HeaderNavLink>
  );
};

export default HeaderNavItem;
