import React, { useState, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogContent } from '@mui/material';
import { Button, Dialog } from '@procurenetworks/procure-component-library';
import Warehouse from 'app/i18n/Warehouse';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import FormLabel from 'app/components/Form/FormLabel';
import { EditWarehouseInput, Maybe, WarehouseTypeEnum } from 'app/types/schema';
import FormTextInput from 'app/components/Form/FormTextInput';
import { ORDERED_WAREHOUSE_KEYS, RACKS_AND_SHELVES_KEYS, WAREHOUSE_LABEL_CONFIG } from '../../../WarehouseDetails/utils';
import { WarehouseValueKey } from '../../../WarehouseDetails/types';
import { WAREHOUSE_FORM_CONFIG } from '../../../WarehouseDetails/components/WarehouseSetupDialog/utils';
import useWarehouseSetup from '../../hooks/useWarehouseSetup';
import { SnackbarService } from 'app/components/Snackbar';
import Common from 'app/i18n/Common';

interface Props {
  warehouseId?: string;
  selectedItems: string[];
  onClose: () => void;
  warehouseType?: string;
  locationsData: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
}

type FormValues = {
  [key in WarehouseValueKey]: number;
} & {
  locationPrefixes: string[];
};

interface Option {
  value: string;
  label: string;
}

const getNumberValue = (value: string | number) => Number(value) || 0;

const WarehouseEditDialog: React.FC<Props> = ({
  onClose,
  selectedItems,
  setSelectedItems,
  warehouseType,
  locationsData,
  warehouseId
}) => {
  const { control, handleSubmit } = useForm<FormValues>({});
  const [locationPrefixes, setLocationPrefixes] = useState<Partial<FormValues>>({});
  const items = selectedItems;
  const keysToShow = ORDERED_WAREHOUSE_KEYS;
  const {
    loading,
    onEditWarehouse
  } = useWarehouseSetup();

  const visibleKeys = useMemo(() => {
    if (warehouseType === WarehouseTypeEnum.RackAndShelf) {
      return selectedItems.length === 0 ? RACKS_AND_SHELVES_KEYS : [];
    }
    return keysToShow.slice(selectedItems.length);
  }, [selectedItems, warehouseType]);


  const generateOptions = (key: string): Option[] => {
    const config = WAREHOUSE_LABEL_CONFIG[key as WarehouseValueKey];
    if (!config) return [];

    const labels = Array.from(
      new Set(locationsData.map((location) => warehouseType === WarehouseTypeEnum.RackAndShelf ? location?.substring(0, 3) : config.getLabelFromLocation(location))),
    );
    return labels.map((label) => ({
      value: label,
      label: label,
    }));
  };

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        if (!warehouseId) return;

        const submittedValues = visibleKeys.reduce((acc, key) => {
          if (key in values) {
            acc[key] = getNumberValue(values[key]);
          }
          return acc;
        }, {} as { [key in WarehouseValueKey]: number });

        const input: EditWarehouseInput = {
          warehouseId,
          locationPrefixes: values.locationPrefixes as string[],
          ...submittedValues
        }

        onEditWarehouse(input)
          .then((res) => {
            if (!res?.success) {
              return;
            }
            onClose();
            setSelectedItems([warehouseId]);
            SnackbarService.show({
              message: Warehouse.WarehouseEditSuccess,
            });
            setSelectedItems(items);
          })
          .catch((err) => {
            console.error('Error:', err);
          });
      }),
    [handleSubmit, visibleKeys, warehouseType],
  );

  return (
    <Dialog
      open
      className="!p-0"
      id="warehouse-edit"
      title={Warehouse.WarehouseEdit}
      onClose={onClose}>
      <DialogContent className="min-w-[632px] !p-0" sx={{ padding: '0px' }}>
        <form onSubmit={onFormSubmit}>
          <Box className="mt-24 space-y-24">
            {visibleKeys.map((key, index) => {
              const config = WAREHOUSE_FORM_CONFIG[key as keyof typeof WAREHOUSE_FORM_CONFIG];
              return index === 0 ? (
                <Controller
                  key={key}
                  control={control}
                  name="locationPrefixes"
                  defaultValue={[]}
                  rules={{ required: 'This field is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <Box className="flex flex-col">
                      <FormLabel>
                        {(Warehouse.Edit as any)[key.charAt(0).toUpperCase() + key.slice(1)]}
                      </FormLabel>
                      <AsyncMultiSelect
                        {...field}
                        paginated={false}
                        useSelectQuery={() => ({ isLoading: false, options: generateOptions(key), onNextPage: () => { } })}
                        useSelectedValue={({ value }) => ({
                          selectedValues: value.map(
                            (v: string) => generateOptions(key).find((opt) => opt.value === v) as Option,
                          ),
                        })}
                        value={field.value as string[]}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          setLocationPrefixes((prev) => ({ ...prev, [key]: newValue }));
                        }}
                        placeholder={Warehouse.Edit.Placeholder}
                      />
                      {error && (
                        <span className="mt-4 text-[14px] text-red-800">{error.message}</span>
                      )}
                    </Box>
                  )}
                />
              ) : (
                <Controller
                  key={key}
                  control={control}
                  name={key as WarehouseValueKey}
                  defaultValue={1}
                  render={({ field, fieldState }) => (
                    <FormTextInput
                      {...field}
                      error={fieldState.error}
                      label={config.label}
                      placeholder={config.placeholder}
                      type="number"
                      value={field.value as string | number | undefined}
                      onChange={(e) => field.onChange(e)}
                    />
                  )}
                  rules={config.rules}
                />
              );
            })}
            <Stack justifyContent="end" className="gap-[20px]">
              <Button
                onClick={onFormSubmit}
                classes="min-w-[80px] h-[44px]"
                theme="success">
                {Common.Actions.Save}
              </Button>
              <Button
                onClick={onClose}
                classes="min-w-[80px] h-[44px]">
                {Common.Actions.Cancel}
              </Button>
            </Stack>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WarehouseEditDialog;