import { useMemo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormNumberInput from 'app/modules/components/FormNumberInput';
import {
  FormLocationItemLocationSelect,
  FormSiteItemLocationSelect,
} from 'app/modules/locations/components/FormItemLocationSelect';

import FormSelect from '../../../../../components/Form/FormSelect';
import FormTextInput from '../../../../../components/Form/FormTextInput';
import Common from '../../../../../i18n/Common';
import Inventory from '../../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../../types/schema';
import ItemInStockSelect from '../../../components/FormItemInStock';
import { UseRemoveItemStateReturnType } from '../../hook/useRemoveItemState';
import { REASON_ITEMS, REMOVE_FORM_RULES } from './utils';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  formState: UseRemoveItemStateReturnType['formState'];
  onFormSubmit: UseRemoveItemStateReturnType['onFormSubmit'];
  types: ItemTypeEnum[];
}

const RemoveForm = (props: Props) => {
  const { formState, onFormSubmit, types } = props;
  const {
    control,
    itemInStockId,
    sourceSiteId,
    availableQuantity,
    totalQuantity,
    isOtherReason,
    setState,
  } = formState;

  const quantityRules = useMemo(() => {
    return REMOVE_FORM_RULES.quantity({
      availableQuantity,
      totalQuantity,
    });
  }, [availableQuantity, totalQuantity]);

  const formRef = useRef<HTMLDivElement>(null);

  const onSubmit = async (data: any) => {
    await onFormSubmit(data);
    ScrollToError(formState.control._formState.errors, formRef)
  };

  return (
    <div ref={formRef}>
      <form className="mt-20" onSubmit={onSubmit}>
        <div className="mb-20">
          <ItemInStockSelect autoFocus control={control} name="itemInStockId" types={types} />
        </div>
        <div className="mb-20">
          <FormSiteItemLocationSelect
            isRequired
            control={control}
            disabled={!itemInStockId}
            itemId={itemInStockId}
            name="sourceSiteId"
            rules={REMOVE_FORM_RULES.sourceId}
            onValueOptionChange={(option) => {
              setState({
                sourceSite: option || null,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <FormLocationItemLocationSelect
            isRequired
            control={control}
            disabled={!sourceSiteId}
            itemId={itemInStockId}
            name="sourceLocationId"
            rules={REMOVE_FORM_RULES.sourceLocationId}
            siteId={sourceSiteId}
            onValueOptionChange={(option) => {
              setState({
                sourceLocation: option || null,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="reason"
            render={({ field, fieldState }) => (
              <FormSelect
                {...field}
                isRequired
                error={fieldState.error}
                label={Inventory.FormLabels.Reason}
                options={REASON_ITEMS}
              />
            )}
            rules={REMOVE_FORM_RULES.reason}
          />
        </div>
        <div className={`mb-20 ${!isOtherReason ? 'hidden' : ''}`}>
          <Controller
            control={control}
            name="reasonMessage"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                characterLimit={32}
                error={fieldState.error}
                isDisabled={!isOtherReason}
                isRequired={isOtherReason}
                label={Inventory.FormLabels.Other}
              />
            )}
            rules={isOtherReason ? REMOVE_FORM_RULES.reasonMessage : undefined}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                error={fieldState.error}
                isDisabled={availableQuantity === undefined || availableQuantity === null}
                label={Inventory.FormLabels.Quantity}
                maximumLength={9}
              />
            )}
            rules={quantityRules}
          />
        </div>

        <div className="flex">
          <div className="flex flex-auto justify-end">
            <Button theme="success" onClick={onSubmit} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Add}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RemoveForm;
