import routes from 'app/consts/routes';
import Reports from 'app/i18n/Reports';
import { ItemLocationItemTypeEnum } from 'app/types/schema';

import AssetsLayout from '../../../../../assets/layout';
import NewAuditReport from '../../components/NewAuditReport';

const NewAssetAuditReport = () => {
  return (
    <AssetsLayout>
      <NewAuditReport
        auditReportLink={routes.AuditReport()}
        entityType={[ItemLocationItemTypeEnum.Asset, ItemLocationItemTypeEnum.AssetKit]}
        title={Reports.AssetAuditReport}
      />
    </AssetsLayout>
  );
};

export default NewAssetAuditReport;
