import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import usePagination from 'app/hooks/usePagination';
import Label from 'app/i18n/Label';
import Stack from 'app/ui-components/Stack';
import { parseSorts } from 'app/utils/sort';

import useLabelsFrom from '../../context/useLabelsFrom';
import { useLabelLocationTableQuery } from '../../graphql/queries/generated/LabelLocationsTable';
import useLabelsTable from '../../hooks/useLabelsTable';
import LabelsTable from '../LabelsTable';
import TableHeader from '../TableHeader';
import { getLabelsLocationTableColumns } from './utils/columns';

interface Props {}

type locationData = { id: string; name: string; site: { id: string; name: string } };

const LabelLocationsTable = (props: Props) => {
  const [locationData, setLocationData] = useState<locationData[]>([]);
  const { values, trigger, setIsCustomFieldValid } = useLabelsFrom();
  const { search, table, onPrintLabels } = useLabelsTable({ values, setIsCustomFieldValid });
  const columns = useMemo(() => getLabelsLocationTableColumns(), []);
  useEffect(() => {
    setLocationData([]);
    table.setState({ selectedRowIds: [] });
  }, [values?.siteId]);

  const sorts = useMemo(() => {
    return parseSorts(table.state.sorts, columns);
  }, [columns, table.state.sorts]);

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useLabelLocationTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        siteIds: values && values.siteId ? [values.siteId] : [],
      },
      sorts: sorts,
    },
    {
      edgeKey: 'locations',
      pageSize: table.state.numberOfRowsPerPage,
      pause: !Boolean(values && values.siteId),
    },
  );

  const rows = useMemo(() => {
    const row = data?.locations.edges?.map(({ node }) => node) || [];
    setLocationData(uniqBy([...locationData, ...row], (row) => row.id));
    return row;
  }, [data?.locations.edges]);

  useEffect(() => {
    const row = locationData.filter((row) => table.state.selectedRowIds.includes(row.id));
    table.setState({ selectedRowData: row });
  }, [table.state.selectedRowIds]);

  const handlePrintLabels = async () => {
    const isValid = trigger && (await trigger());
    if (isValid) {
      onPrintLabels();
    }
  };

  return (
    <Stack direction="col">
      <LabelsTable
        pagination
        actions={table.actions}
        columns={columns}
        data={rows}
        loading={fetching}
        setState={table.setState}
        state={table.state}
        total={data?.locations.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onPrintLabels={handlePrintLabels}
        onReset={onReset}
        filterNode={
          <TableHeader title={Label.Location.LocationSearch} onChange={search.onChange} />
        }
        tableType="Location"
      />
    </Stack>
  );
};

export default LabelLocationsTable;
