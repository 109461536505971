import React, { CSSProperties, forwardRef, useCallback, useEffect, useMemo } from 'react';
import { Accept, DropEvent, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Button } from '@procurenetworks/procure-component-library';
import Uppy from '@uppy/core';
import { omit } from 'lodash';
import { SnackbarService } from 'app/components/Snackbar';
import Box from 'app/ui-components/Box';

import Assets from '../../../i18n/Assets';
import { CreateMediaInput, Maybe } from '../../../types/schema';
import { UseUppyFileType } from '../../../uppy/types';
import { AttachmentType } from '../../../uppy/useUppy';
import { assetInventoryAttachmentAccepts } from '../../../utils/attachments';
import { parseFileName } from '../../ProcureForm/FormFileUploader/utils';
import FormError from '../FormError';
import FormLabel from '../FormLabel';
import UploadedFilePreview from './UploadedFilePreview';
import { isDuplicateFile, thumbsContainer } from './utils';

interface Props {
  className?: string;
  testId?: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  name?: string;
  error?: FieldError;
  value?: Maybe<CreateMediaInput[]>;
  onChange?: (value: CreateMediaInput[]) => void;
  uppy: Uppy;
  files: Record<string, UseUppyFileType>;
  isUploading: boolean;
  removeFileById: (fileId: string) => void;
  attachments: AttachmentType[];
  id?: string;
  multiple?: boolean;
  previewImages?: boolean;
  accept?: Accept;
  note?: string;
}

const FormFileUpload = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    uppy,
    attachments,
    onChange,
    isRequired,
    label,
    error,
    disabled,
    removeFileById,
    isUploading,
    files,
    multiple = true,
    accept,
    previewImages = true,
    note,
    testId,
  } = props;

  const filesAttachments = useMemo(() => {
    return attachments.map((attachment) => {
      return {
        id: attachment.id,
        preview: attachment.url,
        isLoading: attachment.url === '',
      };
    });
  }, [attachments]);

  const onAddFile = useCallback((file: any) => {
    uppy.addFile({
      data: file,
      name: parseFileName(file.name),
      type: file.type,
    });
  }, []);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    disabled: disabled || false,
    accept: accept || assetInventoryAttachmentAccepts,
    noClick: true,
    noKeyboard: true,
    multiple: multiple,
    onDrop: (acceptedFiles: any[], fileRejections: any[], event: DropEvent) => {
      try {
        acceptedFiles.forEach((file) => {
          if (isDuplicateFile(files, file)) {
            SnackbarService.showError({
              message: Assets.FormValidationMessages.AttachmentAlreadyUploaded,
            });
          } else {
            if (multiple) {
              onAddFile(file);
            } else {
              const _files = uppy.getFiles();
              removeFileById(_files?.[0]?.id);
              onAddFile(file);
            }
          }
        });

        // Clearing existing input Value, so It allow us to send same file, and we can show validation.
        const inputTarget = event?.target ? (event?.target as HTMLInputElement) : undefined;
        if (inputTarget) {
          inputTarget.value = '';
        }
      } catch (error) {
        console.error('[FormFileUpload]', error);
      }
    },
  });

  useEffect(() => {
    const updatedAttachments: CreateMediaInput[] = [];

    attachments.forEach((attachment) => {
      const copyAttachment = omit({ ...attachment }, ['id']);
      if (attachment?.url) {
        updatedAttachments.push(copyAttachment as CreateMediaInput);
      }
    });

    onChange?.(updatedAttachments);
  }, [attachments, onChange]);

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      <FormLabel isRequired={isRequired}>{label}</FormLabel>
      <div
        {...getRootProps({
          className: `dropzone relative mt-6 h-[142px] w-[100%] border-[1px] rounded-lg border-solid border-grey-500 p-10 text-center flex justify-center align-center ${
            !disabled ? 'hover:border-[#66ccff]' : ''
          }`,
        })}>
        <input {...getInputProps()} data-testId={testId} />
        <div className="absolute top-0 bottom-0 flex flex-col justify-center">
          {note ? (
            <p className="mb-8 text-[14px] font-semibold text-[#666666]">{note}</p>
          ) : undefined}
          <p className="mb-[14px] text-[14px] font-medium text-grey-600">
            Drop files here or click here to upload.
          </p>
          <div>
            <Button
              disabled={disabled}
              theme="info"
              onClick={() => {
                inputRef?.current?.click();
              }}
              classes="min-w-[104px] h-[44px]">
              Select File
            </Button>
          </div>
        </div>
      </div>
      <FormError error={!isUploading ? error : undefined} />
      {/*  // @ts-ignore */}
      {previewImages && (
        <aside style={thumbsContainer as CSSProperties}>
          <UploadedFilePreview
            disabled={disabled || false}
            files={filesAttachments}
            removeFileById={removeFileById}
          />
        </aside>
      )}
    </Box>
  );
});

export default FormFileUpload;
