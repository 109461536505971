import { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useClient } from 'urql';
import { SnackbarService } from 'app/components/Snackbar';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { ItemLocationDataDocument } from 'app/modules/inventory/graphql/queries/generated/itemLocationData';
import { FormItemLocationOption } from 'app/modules/locations/components/FormItemLocationSelect/types';
import {
  AssetItemSchema,
  AssetKitConfig,
  CreateMediaInput,
  InventoryItemSchema,
  InventoryKitConfig,
  ItemTypeEnum,
  ItemUnion,
} from 'app/types/schema';

import { prepareDefaultAssetItemAttachments } from '../../../../assets/hook/useAssetForm/utils';
import InvalidQuantity from '../../components/InvalidQuantity';
import useKitItems from '../useKitItems';
import useKitSummaryForm from '../useKitSummaryForm';

interface Props {
  types: ItemTypeEnum[];
  kitItems: (InventoryKitConfig | AssetKitConfig)[];
  updateUnitCost: (cost: number) => void;
  kitQuantity?: number;
  siteId?: string;
  itemData?: ItemUnion;
}

type Option =
  | Pick<InventoryItemSchema, 'id' | 'sku' | 'title' | 'cost' | 'attachments' | 'type'>
  | Pick<
      AssetItemSchema,
      'id' | 'sku' | 'title' | 'cost' | 'attachments' | 'type' | 'serialNumber'
    >;

const isItemEntityIdSame = (item: Option, item2: Option): boolean => {
  return item.id === item2.id;
};

const isItemOnSameLocation = (item: any, item2: any): boolean => {
  return item.locationId === item2.locationId;
};

const useKitSummaryState = (props: Props) => {
  const { types, updateUnitCost, kitItems, kitQuantity, siteId, itemData: assetItem } = props;
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const itemIds = kitItems?.length > 0 ? kitItems?.map((item) => item.itemId) : [];
  const { items } = useKitItems(kitItems, types);
  const client = useClient();

  useEffect(() => {
    if (items && items?.length > 0 && itemIds?.length > 0) {
      setRows(items);
    }
  }, [items]);

  const { table } = useEntityManager({
    selection: false,
  });

  const { formState, handleSubmit, onResetForm, itemInStock, state } = useKitSummaryForm({
    types,
  });

  const defaultAttachments = useMemo(() => {
    return prepareDefaultAssetItemAttachments(assetItem?.attachments as CreateMediaInput[]);
  }, [assetItem?.attachments, rows]);

  const availableQuantityOnLocation = useMemo(() => {
    return state?.sourceLocation?.availableQuantity || 0;
  }, [state?.sourceLocation?.availableQuantity]);

  const itemData = itemInStock as Option;

  const availableQuantity = useMemo((): number | undefined => {
    const sourceLocation = state?.sourceLocation;
    if (!sourceLocation) {
      return undefined;
    }

    let totalQuantity = 0;
    rows.forEach((item) => {
      if (item.id === formState.itemId && item.sourceLocationId === sourceLocation?.id) {
        totalQuantity += item.quantity;
      }
    });
    return availableQuantityOnLocation - totalQuantity;
  }, [rows, state?.sourceLocation, formState.itemId]);

  const isQuantityAvailable = useCallback(
    (kitData: any, stockInformation: any) => {
      const possibleKitQuantity = (kitQuantity && kitData.quantity * kitQuantity) || 0;
      const availableQuantity = stockInformation?.availableQuantity || 0;

      let totalQuantity = kitData.quantity;
      rows.forEach((item) => {
        if (isItemEntityIdSame(item, kitData) && !isItemOnSameLocation(item, kitData)) {
          totalQuantity += item.quantity;
        }
      });

      return totalQuantity <= availableQuantity && possibleKitQuantity <= availableQuantity;
    },
    [rows, state?.sourceLocation, kitQuantity, availableQuantityOnLocation],
  );

  const onRemoveTransaction = useCallback(
    (rowId: number) => {
      const _rows = rows.filter((row) => row.id !== rowId);
      setRows(_rows);
      onKitItemListChange(_rows);
    },
    [setRows, rows],
  );

  const onResetFormAndTable = useCallback(() => {
    setRows([]);
    onResetForm();
  }, [setRows, onResetForm]);

  const createKitSummary = useCallback(
    (values: any, sourceLocation: any) => {
      const { sourceLocationId, quantity } = values;

      return {
        id: itemData?.id || '',
        sku: itemData?.sku || '',
        title: itemData?.title || '',
        cost: itemData?.cost,
        quantity: Number(quantity),
        locationId: sourceLocationId,
        attachments: itemData?.attachments,
        type: itemData?.type,
        sourceLocation,
      };
    },
    [types, itemInStock],
  );

  const getUpdatedRows = useCallback(
    (item: any) => {
      const updatedRows = [...rows];
      const rowIndex = updatedRows.findIndex((row) => {
        return isItemEntityIdSame(row, item);
      });
      if (rowIndex !== -1) {
        updatedRows[rowIndex] = item;
      } else {
        updatedRows.push(item);
      }

      return updatedRows;
    },
    [rows],
  );

  const onKitItemListChange = useCallback(
    (items: any[]) => {
      let totalCost = 0;
      items.forEach((item) => {
        let itemTotalCost = 0;
        itemTotalCost = item?.cost * item.quantity;
        totalCost += itemTotalCost;
      });
      updateUnitCost(totalCost);
    },
    [updateUnitCost],
  );

  const getUpdatedStockInformation = useCallback(async () => {
    const result = await client
      .query(
        ItemLocationDataDocument,
        {
          filters: {
            search: state?.sourceLocation?.location?.name || '',
            itemIds: formState?.itemId ? [formState?.itemId] : [],
            siteIds: siteId ? [siteId] : [],
            nonZeroTotalQuantity: true,
          },
          limit: 10000,
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise();
    return result;
  }, [client, formState?.itemId, siteId, state?.sourceLocation?.location?.name]);

  const onKitFormSubmit = useCallback(
    async (values: any) => {
      setLoading(true);
      const _stockInformation = await getUpdatedStockInformation();
      setLoading(false);
      const updatedStockInformation = get(
        _stockInformation,
        'data.itemlocations.edges[0].node',
        {},
      ) as FormItemLocationOption;
      formState.setState({ sourceLocation: updatedStockInformation });

      const kitData = createKitSummary(values, state.sourceLocation);
      if (!isQuantityAvailable(kitData, updatedStockInformation)) {
        SnackbarService.showError({
          children: InvalidQuantity({
            item: {
              ...itemInStock,
              availableQuantity: updatedStockInformation?.availableQuantity || 0,
              quantity: kitData?.quantity,
            },
            kitQuantity,
          }),
          message: '',
        });
        return;
      }

      const rows = getUpdatedRows(kitData);

      setRows(rows);
      onKitItemListChange(rows);

      onResetForm();
    },
    [rows, setRows, types, state, createKitSummary, itemInStock, kitQuantity],
  );

  const onFormSubmit = useMemo(
    () => handleSubmit(onKitFormSubmit),
    [handleSubmit, onKitFormSubmit, state],
  );

  return {
    formState: {
      ...formState,
      itemInStock,
      loading,
      defaultAttachments,
      onResetFormAndTable,
    },
    onResetForm,
    tableState: {
      table,
      rows,
      onRemoveTransaction,
      onResetFormAndTable,
      totalRows: rows.length,
    },
    availableQuantity,
    onKitFormSubmit,
    table,
    rows,
    onFormSubmit,
    onRemoveTransaction,
    onResetFormAndTable,
  };
};

export type UseKitSummaryStateReturnType = ReturnType<typeof useKitSummaryState>;
export default useKitSummaryState;
