import { Context, useContext } from 'react';

import { convertToISBN13 } from '../../utils';
import { ShopContext,ShopContextType } from '../ShopProvider';

const useShop = () => {
  const context = useContext<ShopContextType>(ShopContext as unknown as Context<ShopContextType>);
  const { searchQuery, checked } = context

  const getQuery = (names: string, pages: number, totalPages: number) => {
    const queryParameters = {
      ...searchQuery,
      page: pages,
      totalPage: totalPages,
    };

    // return queryParameters
    if (!checked || checked === 'name') {
      return { ...queryParameters, name: names };
    }
    if (checked === 'code') {
      return { ...queryParameters, code: names };
    }
    if (checked === 'model') {
      return { ...queryParameters, model: names };
    }
    if (checked === 'brand') {
      return { ...queryParameters, brand: names };
    }
    if (checked === 'isbn') {
      return { ...queryParameters, code: convertToISBN13(names) };
    }
    return {};
  };
  return {
    getQuery,
    ...context,
  };
};

export default useShop;
