import { useMemo } from 'react';
import { ShippingTransactionStatusEnum } from 'app/types/schema';

import { getLeastItemStatusLabel } from '../../utils/shippmentStatus';

interface Props {
  status?: ShippingTransactionStatusEnum;
}

const ShipmentStatusView = (props: Props) => {
  const { status } = props;

  const statusLabel = useMemo(() => status && getLeastItemStatusLabel(status), [status]);

  if (!statusLabel) {
    return null;
  }

  return (
    <span
      className="truncate"
      style={{
        textTransform: 'capitalize',
      }}>
      {statusLabel}
    </span>
  );
};

export default ShipmentStatusView;
