import { useEffect, useState } from 'react';

import { useWishlistProductsQuery, WishlistSchema } from '../useWishlistProducts';

interface WishlistPublicDataType {
  shareId: string;
}

const useWishlistPublicData = (props: WishlistPublicDataType) => {
  const { shareId } = props;

  const [data, setData] = useState<WishlistSchema[] | undefined>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null | unknown>(null);
  const { fetchWishlistProducts } = useWishlistProductsQuery();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const variables = {
          shareId: shareId,
        };
        const response = await fetchWishlistProducts(variables);
        setData(response.data?.wishlistByShareId.wishlist);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareId]);

  return { data, loading, error };
};

export default useWishlistPublicData;
