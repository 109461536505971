import { useCallback, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Ship from 'app/i18n/Ship';
import {
  UnpackShippingTransactionsOfContainerIdInput,
  UnpackShippingTransactionsOfTrackingIdsInput,
} from 'app/types/schema';

import { useUnpackShippingTransactionsOfContainerMutation } from '../../../../graphql/mutations/generated/unpackShippingTransactionsOfContainer';
import { useUnpackShippingTransactionsOfTrackingIdMutation } from '../../../../graphql/mutations/generated/unpackShippingTransactionsOfTrackingId';
import { EntityType } from '../../components/TransactionsTable/utils';

export type UnPackDialogState = {
  open: boolean;
  unpackId: string | null | undefined;
};

const useUnpackContainer = () => {
  const [unpackContainerId, setUnpackContainerId] = useState<string | null>(null);
  const [unpackDialogState, setUnpackDialogState] = useState<UnPackDialogState>({
    open: false,
    unpackId: null,
  });
  const [{ fetching: unpackingContainer }, onUnpackShippingTransactionsOfContainer] =
    useUnpackShippingTransactionsOfContainerMutation();
  const [{ fetching: unpackingOrder }, onUnpackShippingTransactionsOfTrackingIds] =
    useUnpackShippingTransactionsOfTrackingIdMutation();

  const onUnpack = useCallback((unpackId: any) => {
    setUnpackDialogState({
      open: true,
      unpackId,
    });
    setUnpackContainerId(unpackId);
  }, []);

  const onCancelUnpack = useCallback(() => {
    setUnpackDialogState({
      open: false,
      unpackId: null,
    });
  }, []);

  const unpackContainer = useCallback(
    (input: UnpackShippingTransactionsOfContainerIdInput) => {
      onUnpackShippingTransactionsOfContainer({
        input,
      }).then((res) => {
        if (res?.data?.unpackShippingTransactionsOfContainerId?.success) {
          SnackbarService.show({
            message: Ship.SuccessMessages.UnPackContainerSuccessMessage,
          });
          setUnpackContainerId('');
        }
      });
    },
    [onUnpackShippingTransactionsOfContainer],
  );

  const unpackOrder = useCallback(
    (input: UnpackShippingTransactionsOfTrackingIdsInput) => {
      onUnpackShippingTransactionsOfTrackingIds({
        input,
      }).then((res) => {
        if (res?.data?.unpackShippingTransactionsOfTrackingIds?.success) {
          SnackbarService.show({
            message: Ship.SuccessMessages.UnPackOrderSuccessMessage,
          });
          setUnpackContainerId('');
        }
      });
    },
    [onUnpackShippingTransactionsOfTrackingIds],
  );

  const prepareUnpackInput = useCallback((type: EntityType) => {
    if (type === EntityType?.ORDER_REQUEST) {
      return { trackingIds: unpackDialogState?.unpackId ? [unpackDialogState?.unpackId] : [] }
    } else {
      return { containerId: unpackDialogState?.unpackId ?? '' }
    }
  }, [unpackDialogState?.unpackId])

  const onConfirmUnpack = useCallback(
    (type: EntityType) => {
      const input = prepareUnpackInput(type);

      onCancelUnpack();
      if (type === EntityType?.ORDER_REQUEST) {
        unpackOrder(input as unknown as UnpackShippingTransactionsOfTrackingIdsInput);
      } else {
        unpackContainer(input as unknown as UnpackShippingTransactionsOfContainerIdInput);
      }
    },
    [onCancelUnpack, prepareUnpackInput, unpackContainer, unpackOrder],
  );

  return {
    unpackDialogState,
    unpackContainerId,
    unpacking: unpackingContainer || unpackingOrder,
    actions: {
      onUnpack,
      onCancelUnpack,
      setUnpackContainerId,
      onConfirmUnpack,
    },
  };
};

export default useUnpackContainer;
