import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAddressMutationVariables = Types.Exact<{
  input: Types.UpdateAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'UpdateAddressPayload', success: boolean, reason?: string | null, address?: { __typename?: 'AddressSchema', id: string } | null } };


export const UpdateAddressDocument = gql`
    mutation updateAddress($input: UpdateAddressInput!) {
  updateAddress(input: $input) {
    success
    reason
    address {
      id
    }
  }
}
    `;

export function useUpdateAddressMutation() {
  return Urql.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument);
};