import AssetsLayout from '../../modules/assets/layout';
import CheckOut from '../../modules/assets/views/CheckOut';

function CheckOutAssetsPage() {
  return (
    <AssetsLayout>
      <CheckOut />
    </AssetsLayout>
  );
}

export default CheckOutAssetsPage;
