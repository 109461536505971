import { UpdateAssetItemInput } from 'app/types/schema';

const Assets = {
  Remove: 'Remove Assets',
  RemoveTitle: 'Remove Asset - Procure',
  Move: 'Move Assets',
  MoveTitle: 'Move Asset - Procure',
  TransactionTitle: (title: string) => `${title} Asset - Procure`,
  TransactionConfirmationTitle: (title: string) => `${title} Asset: Confirmation`,
  Restock: 'Restock Assets',
  RestockTitle: 'Restock Asset - Procure',
  Search: 'Asset Search',
  SearchTitle: 'Asset Search - Procure',
  AddAsset: 'New Asset',
  AddAssetTitle: 'New Asset - Procure',
  EditAsset: 'Edit Asset',
  DeletedAsset: 'Deleted Asset',
  EditAssetTitle: 'Edit Asset - Procure',
  DeletedAssetTitle: 'Deleted Asset',
  MoveAssetConfirmation: 'Move Asset: Confirmation',
  RemoveAssetConfirmation: 'Remove Asset: Confirmation',
  RestockAssetConfirmation: 'Restock Asset: Confirmation',
  AssetItems: 'Asset Items',
  ReserveAsset: 'Reserve Asset',
  AssetsReserved: 'Assets Reserved',
  EditAssetsReserve: 'Edit Reserved Asset',
  CheckinAssets: 'Check In Assets',
  CheckOutAsset: 'Check Out Assets',
  CheckInDocument: 'Check In',
  CheckOutDocument: 'Check Out',
  FormValidationMessages: {
    TypeRequired: 'Please select an Asset Type.',
    CategoryRequired: 'Please select a Category.',
    ModelRequired: 'Please enter a Model.',
    TitleRequired: 'Please enter a Title up to 32 characters.',
    SiteRequired: 'Please select a Site.',
    SiteLocationRequired: 'Please select a Location.',
    CostInvalid: 'Please enter a valid Cost (0.00)',
    CostOverrideInvalid: 'Please enter a valid Cost Override percentage (xxx.xx)',
    UpcCodeInvalid: 'Please enter a valid UPC or ASIN number between 10 and 14 digits.',
    QuantityRequired: 'Please enter a Quantity.',
    QuantityInvalid: 'Please enter a Quantity greater than 0.',
    SerialNumberQuantityInvalid: 'An asset with a Serial Number must have a quantity equal to 1.',
    QuantityMaxiMumRequired: (availableNumber: number) => {
      if (availableNumber === 0) {
        return 'Quantity is not available.';
      } else {
        return `The Quantity entered is not available. Please enter a Quantity up to ${availableNumber}.`;
      }
    },
    CheckInQuantityRequired:
      'Please enter a Quantity or click "Cancel" to cancel the Check-In process.',
    CheckInMinQuantityRequired: (quantity: number) =>
      `Actual Quantity should be less than ${quantity} or equal to ${quantity}.`,
    CheckInConfirmation: `Are you sure you want to check in this Asset?`,
    ItemInStockSearchRequired: 'Please select an item in stock.',
    ItemInStockInvalid: 'This SKU is not valid. Please select another SKU.',
    ToSiteRequired: 'Please select a Site.',
    ToLocationRequired: 'Please select a Location.',
    UserRequired: 'Please select a User.',
    DueDateRequired: 'Please select a Due Date.',
    StartDateRequired: 'Please select a Start Date.',
    EndDateRequired: 'Please select a End Date.',
    QuantityIsNotAvailable: 'Quantity is not available.',
    WarrantyRequired: 'Please select a Warranty Date.',
    AttachmentAlreadyUploaded:
      'This file has already been uploaded. Please delete the existing file or upload a different file.',
    Reminders: {
      NoteRequired: 'Please enter a Note.',
      DateRequired: 'Please select a Date.',
      CornRequired: 'Please select a Repeat Interval.',
    },
    UnreserveConfirmation: `Are you sure you want to Unreserve this Asset?`,
    CheckoutConfirmation: `Are you sure you want to Check out this Asset?`,
  },
  FormLabels: {
    All: 'All',
    Type: 'Type',
    SKU: 'SKU',
    AssetSearch: 'Asset Search',
    Category: 'Category',
    Title: 'Title',
    Description: 'Description',
    AssetModel: 'Asset Model',
    Site: 'Site',
    Location: 'Location',
    Quantity: 'Quantity',
    UnitCost: 'Cost',
    CostOverride: 'Cost Override %',
    Attachments: 'Attachments',
    protectedAttachments: 'Protected Documents',
    UpcCode: 'UPC or ASIN Code',
    NoUpcCode: 'No UPC or ASIN Code',
    FindUpc: 'Find UPC',
    Model: 'Model',
    ModelNumber: 'Model Number',
    Brand: 'Brand',
    SerialNumber: 'Serial Number',
    Vendor: 'Vendor',
    PurchaseDate: 'Purchase Date',
    ReplacementDate: 'Replacement Date',
    WarrantyExpiryDate: 'Warranty Expiration',
    DisplayInOrder: 'Display In Order Request',
    CheckInDialog: 'Check In!',
    EditAssetReserveDialog: 'Edit Asset Reserve',
    UnreserveDialog: 'Unreserve!',
    Department: 'Department',
    ReserveDepartment: 'Reserve To Department',
    ReserveUser: 'Reserve To User',
    User: 'User',
    DueDate: 'Due Date',
    ItemInStock: 'Item In Stock',
    FromSite: 'From Site',
    ToSite: 'To Site',
    ToLocation: 'To Location',
    FromLocation: 'From Location',
    Reminders: {
      Reminder: 'Reminder',
      Date: 'Start Date',
      RepeatEvery: 'Repeat Every',
      Users: 'User(s)',
      Maintenance: 'Maintenance?',
    },
    Note: 'Note',
    Move: 'Move',
    Remove: 'Remove',
    Restock: 'Restock',
    CheckOut: 'Check Out',
    CheckIn: 'Check In',
    BulkEdit: 'Bulk Edit',
    Min: 'Min',
    Max: 'Max',
    Reserve: 'Reserve',
    Manufacturer: 'Manufacturer',
    StartDate: 'Reserve Start',
    EndDate: 'Reserve End',
    ReserveToSite: 'Reserve To Site',
    MacAddress: 'MAC Address',
    IpAddress: 'IP Address',
    CpuBrand: 'CPU Brand',
    ITAssetDetails: 'IT Asset Details',
  },
  Actions: {
    AddProductDetails: 'Add Product Details',
    CheckOut: 'Check Out',
    BulkMove: 'Bulk Move',
    Move: 'Move',
    Restock: 'Restock',
    Remove: 'Remove',
    Reminder: {
      AddReminder: 'Add Reminder',
      ViewReminders: 'View Reminders',
    },
    ReserveAssets: 'Reserve Assets',
    Unreserve: 'Unreserve',
  },
  FormPlaceholders: {
    UpcCode: 'Search UPC, ASIN',
    CategorySelect: 'Category',
    VendorSelect: 'Vendor',
    ToSite: 'To Site',
    ToLocation: 'To Location',
    Site: 'Site',
    Location: 'Location',
    AssetModel: 'Asset Model',
    ManufacturerSelect: 'Manufacturer',
    ReserveToSite: 'Reserve To Site',
    ReserveToDepartments: 'Reserve To Department',
    ReserveToUser: 'Reserve To User',
    AssetDescription: 'Enter Description',
  },
  SuccessMessages: {
    AssetCreated: 'This asset has been successfully created.',
    AssetUpdated: 'This asset has been successfully updated.',
    AssetDeleted: 'This asset has been successfully deleted.',
    AssetKitCreated: 'This asset kit has been successfully created.',
    AssetKitUpdated: 'This asset kit has been successfully updated.',
    AssetKitDeleted: 'This asset kit has been successfully deleted.',
    AssetMoved: 'This asset has been successfully moved.',
    AssetRestocked: 'This asset has been successfully restocked.',
    AssetsRemoved: 'This asset has been successfully removed.',
    CheckOutTransactionCreated: 'This asset has been successfully checked out.',
    CheckInTransactionCreated: 'This asset has been successfully checked in.',
    AssetItemsDeleted: (items: string[]) =>
      items.length > 1
        ? 'These asset items have been successfully deleted.'
        : 'This asset has been successfully deleted.',
    AssetItemsUpdated: (items: UpdateAssetItemInput[]) =>
      items.length > 1
        ? 'These asset items have been successfully updated.'
        : 'This asset has been successfully updated.',
    Reminder: {
      AssetReminderCreated: 'This asset reminder has been successfully created.',
      AssetReminderUpdated: 'This asset reminder has been successfully updated.',
      AssetReminderDeleted: 'This asset reminder has been successfully deleted.',
    },
    ReserveTransactionCreated: 'This assets has been successfully reserved.',
    ReserveTransactionUpdated: 'This reservation has been successfully updated.',
    UnreserveTransactionCreated: 'This assets has been successfully unreserved.',
  },
};

export default Assets;
