import { Nav, NavItem, NavLink } from 'reactstrap';
import clsx from 'clsx';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import { OrganizationTabs } from '../utils/types';

type Props = React.PropsWithChildren<RouteComponentProps> & {
  activePage: OrganizationTabs;
  setActivePage: any;
};

function OrganizationLayout(props: Props) {
  const { activePage, setActivePage, children } = props;
  const tabs = [
    { label: 'Edit', value: OrganizationTabs.EDIT },
    { label: 'Setting', value: OrganizationTabs.SETTING },
  ];
  return (
    <>
      <Nav tabs className={clsx('flex gap-[8px] bg-white p-[24px]')}>
        {tabs.map(({ label, value }) => (
          <NavItem key={value} onClick={() => setActivePage(value)}>
            <NavLink className={getTabNavLinkClassName(activePage === value)}>{label}</NavLink>
          </NavItem>
        ))}
      </Nav>
      {children}
    </>
  );
}

export default withRouter(OrganizationLayout);
