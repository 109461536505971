import AsyncMultiTableFilter from '../../../../../../components/AsyncMultiTableFilter';
import { Maybe } from '../../../../../../types/schema';
import { FieldError } from 'react-hook-form';
import {
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../../../components/AsyncMultiSelect/types';
import { useMemo } from 'react';
import { LeastItemStatues } from './utils';

export interface UseSelectedValueProps {
  value: string[];
  pause?: boolean;
  options?: any[];
}

export interface OrderStatusTableFilterProps {
  name: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: undefined;
}

export interface UseSelectedValueProps {
  value: string[];
  options?: any[];
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const values = useMemo(() => LeastItemStatues.filter((item) => value.includes(item.value)), [value]);

  return { isDisabled: false, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;
  const options = useMemo(() => LeastItemStatues, []);
  return { isLoading: false, options };
};

const OrderStatusTableFilter = (props: OrderStatusTableFilterProps) => {
  return (
    <AsyncMultiTableFilter
      {...props}
      size="small"
      useSelectedValue={useSelectedValue}
      useSelectQuery={useSelectQuery}
      onBlur={(event) => event.stopPropagation()}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    />
  );
};

export default OrderStatusTableFilter;
