import { Grid } from '@mui/material';
import EntityManager from 'app/modules/components/EntityManager';

import OrderDeliveryDetailForm from '../../../../components/OrderDeliveryDetailForm';
import { DeliveryDetailInput } from '../../../../components/OrderDeliveryDetailForm/types';
import { UseOrderDeliveryDetailReturnType } from './hook/useOrderDeliveryDetail';

interface Props {
  onCreateOrder: (values: DeliveryDetailInput) => void;
  onPrevStep: () => void;
  state: UseOrderDeliveryDetailReturnType['deliveryDetailState'];
  actions: UseOrderDeliveryDetailReturnType['deliveryDetailsAction'];
  disabled?: boolean;
}

const OrderDeliveryDetails = (props: Props) => {
  const { onCreateOrder, onPrevStep, state, actions, disabled } = props;

  return (
    <EntityManager className='!border-[0px]'>
      <Grid container spacing={3}>
        <Grid item lg={3} md={4} sm={12} xl={3} xs={12}>
          <OrderDeliveryDetailForm
            disabled={disabled}
            formState={state.formState}
            handleSubmit={actions.handleSubmit}
            onCreateOrder={onCreateOrder}
            onPrevStep={onPrevStep}
          />
        </Grid>
      </Grid>
    </EntityManager>
  );
};
export default OrderDeliveryDetails;
