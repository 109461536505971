import { DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getContactTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'firstName',
    label: 'Name',
    value: 'firstName',
    sortable: true,
    valueNode: ({ row }) => (
      <span className="truncate" title={row.name || ''}>
        {row.name || null}
      </span>
    ),
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    value: 'email',
    sortable: true,
  },
  {
    id: 'officeContactNumber',
    label: 'Office Phone',
    value: 'officeContactNumber',
    sortable: true,
  },
  {
    id: 'mobileContactNumber',
    label: 'Mobile Phone',
    value: 'mobileContactNumber',
    sortable: true,
  },
  {
    id: 'homeContactNumber',
    label: 'Home Contact Number',
    value: 'homeContactNumber',
    sortable: true,
    width: 220,
  },
  {
    id: 'fax',
    label: 'Fax',
    value: 'fax',
    sortable: true,
  },
];
