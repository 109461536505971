import { forwardRef, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import Box from 'app/ui-components/Box';

import FormLabel from '../../../../../components/Form/FormLabel';
import FormCheckBoxInput from '../../../../../components/ProcureForm/FormCheckBoxInput';
import { Maybe } from '../../../../../types/schema';

export interface FormImageSelectProps {
  label?: string;
  isRequired?: boolean;
  images: string[];
  name?: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange?: (value: string[]) => void;
  error?: FieldError;
  isDisabled?: boolean;
}

const FormImageSelect = forwardRef<HTMLDivElement, FormImageSelectProps>((props, ref) => {
  const { isRequired, value, onChange, label, images } = props;

  const handleImageSelectionChange = useCallback(
    (checked: boolean, imageValue: string) => {
      if (checked) {
        onChange?.([...(value || []), imageValue] || []);
      } else {
        onChange?.(value?.filter((image: string) => image !== imageValue) || []);
      }
    },
    [value],
  );

  return (
    <Box ref={ref}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <div>
        <section className="overflow-hidden text-gray-700 ">
          <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-12">
            <div className="-m-1 flex flex-wrap md:-m-2">
              {images.map((image) => {
                return (
                  <div className="flex w-1/3 flex-wrap">
                    <div className="w-full p-1 md:p-2">
                      <FormCheckBoxInput
                        value={value?.includes(image)}
                        onChange={(value) => {
                          handleImageSelectionChange(value, image);
                        }}
                      />
                      <img
                        alt="gallery"
                        className="block h-full w-full rounded-lg object-cover object-center"
                        src={image}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Box>
  );
});

export default FormImageSelect;
