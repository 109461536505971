import '../../style.css';
import { useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { Alert, Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Pagination } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import ShareCard from 'app/modules/wishlist/components/ShareCard';
import { WishlistVendorEnum } from 'app/types/schema';

import CopyToClipboard from '../../../../components/CopyToClipboard';
import useGetImpactProductData from '../../hook/useGetImpactProductData';
import { WishlistSchema } from '../../hook/useWishlistProducts';
import useWishlistPublicData from '../../hook/useWishlistPublicData';
import { SelectedProducts, VendorTypeEnum } from '../../types';
import { GetAmazonChunksData, scrollToTop } from '../utils/helper';

interface Props extends RouteComponentProps {
  shareId: string;
}
const ShareListView = (props: Props) => {
  const { shareId, history } = props;
  const [expired, setExpired] = useState<boolean>(false);

  const [selectedProducts, setSelectedProducts] = useState<SelectedProducts[]>([]);
  const [productsPage, setProductsPage] = useState<number>(1);
  const [productsPageSize, setProductsPageSize] = useState<number>(12);

  const { data: wishlistData, loading: wishlistLoading } = useWishlistPublicData({ shareId });
  const productIds = useMemo(() => {
    return wishlistData && wishlistData.length
      ? wishlistData[0].products?.map((product) => {
          return product.hooverProductId;
        })
      : [];
  }, [wishlistData]);

  const amazonProductIds = useMemo(() => {
    if (!wishlistData || !wishlistData.length) {
      return [];
    }

    const productsIds = wishlistData[0].products
      ? wishlistData[0].products
          .filter((product) => product.vendor === WishlistVendorEnum.Amazon)
          .map((product) => product.hooverProductId)
      : [];

    return productsIds;
  }, [wishlistData]);

  const walmartProductIds = useMemo(() => {
    if (!wishlistData || !wishlistData.length) {
      return [];
    }

    const productsIds = wishlistData[0].products
      ? wishlistData[0].products
          .filter((product) => product.vendor === WishlistVendorEnum.Walmart)
          .map((product) => product.hooverProductId)
      : [];

    return productsIds;
  }, [wishlistData]);

  const { data: impactData } = useGetImpactProductData({
    productIds: walmartProductIds,
    page: 1,
    pageSize: walmartProductIds.length,
    vendorType: VendorTypeEnum.WALMART,
  });

  const amazonData = GetAmazonChunksData({
    amazonProductIds: amazonProductIds,
    totalProducts: amazonProductIds.length,
  });

  const wishlistDetails: WishlistSchema | undefined = wishlistData?.[0];

  useEffect(() => {
    if (!wishlistLoading && (!wishlistData || !wishlistData?.length)) {
      history.push(routes.WishlistListView());
      return;
    }
  }, [wishlistLoading, wishlistData, history]);

  useEffect(() => {
    if (shareId) {
      const products = wishlistData && wishlistData.length ? wishlistData[0].products : [];

      const updateMap = new Map(products.map((item) => [item.hooverProductId, item.quantity]));

      const allProducts = [...impactData, ...amazonData];

      const updatedData = allProducts.map((item: SelectedProducts) => ({
        ...item,
        quantity: updateMap.get(item.hooverProductId.toString()) || item.quantity,
        purchasedQuantity:
          products.find((product) => product.hooverProductId.toString() === item.hooverProductId.toString())
            ?.currentPurchasedQuantity || 0,
      }));

      const uniqueUpdatedData = updatedData.reduce((acc: any, item) => {
        const existingItemIndex = acc.findIndex(
          (x: any) => x.hooverProductId === item.hooverProductId,
        );

        if (existingItemIndex === -1) {
          acc.push(item);
        } else {
          acc[existingItemIndex] = { ...acc[existingItemIndex], ...item };
        }

        return acc;
      }, []);

      setSelectedProducts(uniqueUpdatedData);
    }
  }, [impactData, amazonData, shareId, wishlistData]);
  useEffect(() => {
    if (wishlistDetails) {
      const expiredTime = new Date(wishlistDetails.eventDate || '').getTime();
      const time = expiredTime - new Date().getTime();

      if (time <= 0) {
        setExpired(true);
      } else {
        const timeoutId = setTimeout(() => setExpired(true), time);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [wishlistDetails]);

  const handlePageChange = (event: any, page: number) => {
    setProductsPage(page);
    scrollToTop();
  };

  const handleRowsPerPageChange = (event: any) => {
    const value = event.target.value;
    setProductsPage(0);
    setProductsPageSize(value);
    scrollToTop();
  };

  const getPaginationIndexes = (page: number, pageSize: number) => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    return {
      startIndex,
      endIndex,
    };
  };

  const filteredSelectedProducts = useMemo(() => {
    const { startIndex, endIndex } = getPaginationIndexes(productsPage, productsPageSize);

    const filteredItems = selectedProducts.slice(startIndex, endIndex);

    if (!filteredItems.length) {
      if (productsPage) {
        setProductsPage(productsPage - 1);
      }
    }

    return filteredItems;
  }, [selectedProducts, productsPage, productsPageSize]);

  const wishlistDescription = useMemo(() => {
    return wishlistDetails?.formattedDescription || wishlistDetails?.description || '';
  }, [wishlistDetails]);

  return (
    <>
      <EntityManager>
        <EntityManager.Title documentTitle={'Wishlist'} title={'Wishlist Registry'} />
        {wishlistLoading ? (
          <Loader />
        ) : (
          <>
            {expired && (
              <Alert className="m-10" severity="error">
                This wishlist has expired!
              </Alert>
            )}
            <Stack
              alignItems={'center'}
              direction={'column'}
              justifyContent={'center'}
              margin={'10px'}>
              <Icon className="text-red-800" height="5%" icon="ph:gift-light" width="5%" />
              <Typography
                className="mb-[8px] text-[18px] font-semibold text-grey-900"
                component="div">
                {wishlistDetails?.name}
              </Typography>
              {wishlistDetails?.shippingAddress && (
                <>
                  <Typography
                    className="text-lg mb-6 mt-[5px] font-medium text-gray-800"
                    style={{ textDecoration: 'underline' }}>
                    Send gifts to
                  </Typography>
                  <Stack direction={'row'}>
                    <Typography className="text-lg mb-6 mt-[5px] text-gray-800">
                      {Object.values(wishlistDetails?.shippingAddress).join(' ')}
                    </Typography>
                    <CopyToClipboard
                      label="Shipping Address"
                      text={Object.values(wishlistDetails?.shippingAddress).join(' ')}
                    />
                  </Stack>
                </>
              )}
              {wishlistDescription && (
                <pre
                  dangerouslySetInnerHTML={{
                    __html: wishlistDescription,
                  }}
                  style={{ fontFamily: 'Inter', fontSize: 16, fontWeight: '400' }}></pre>
              )}
            </Stack>
            <Divider className="my-[16px]" />
            <Box
              className="rounded-[12px] border border-grey-300 p-[24px]"
              sx={{
                boxShadow:
                  '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
              }}>
              <Grid container>
                {selectedProducts.length ? (
                  <>
                    {filteredSelectedProducts.map((item, index) => (
                      <ShareCard
                        expired={expired}
                        item={item}
                        tenantId={wishlistDetails?.tenantId}
                        userId={wishlistDetails?.createdById}
                      />
                    ))}
                  </>
                ) : (
                  <p className={'py-[30px] px-[0px] text-center text-grey-900'}>
                    No products added in the wishlist yet.
                  </p>
                )}
              </Grid>
              {!!selectedProducts.length && (
                <Box className="flex w-full justify-end">
                  <Pagination
                    classes="paginations"
                    count={productIds.length}
                    page={productsPage || 0}
                    rowsPerPage={productsPageSize || 12}
                    rowsPerPageOptions={[12, 24, 48, 96]}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </EntityManager>
    </>
  );
};

export default withRouter(ShareListView);
