import { useMemo } from 'react';
import Import from 'app/i18n/Import';
import EntityManager from 'app/modules/components/EntityManager';

import { UseImportFormReturnType } from '../ImportForm/hook/useImportFormState';
import MappingForm from './components/MappingForm';

interface Props {
  state: UseImportFormReturnType['state'];
  disabled?: boolean;
  onBack: () => void;
  onSubmit: (values: any) => void;
}

const ImportMappingForm = (props: Props) => {
  const { state, onSubmit, onBack, disabled } = props;

  const defaultValues = useMemo(() => {
    const values: { [key: string]: any } = {};
    state.entityMap.forEach((entity) => {
      values[entity.entityField] = '';
    });
    return values;
  }, [state.entityMap]);

  return (
    <>
      <EntityManager.Title documentTitle={Import.Import} title={Import.ImportMapping} />
      <MappingForm
        defaultValues={defaultValues}
        disabled={disabled}
        state={state}
        onBack={onBack}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ImportMappingForm;
