import React, { Fragment, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Button, Dialog } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import DefaultImage from 'app/assets/default1.jpeg';
import { ShippingTransactionDeliveryTypeEnum, ShippingTransactionSchema } from 'app/types/schema';
import Box from 'app/ui-components';
interface DeliveryDetailsDialogProps {
  open: boolean;
  onChange: Function;
  deliveryDetails: ShippingTransactionSchema | null | undefined;
}

const DeliveryDetailsDialog: React.FC<DeliveryDetailsDialogProps> = (props) => {
  const { open, onChange, deliveryDetails } = props;
  const verificationRecord = useMemo(
    () => deliveryDetails?.deliveryVerificationRecord,
    [deliveryDetails],
  );
  return (
    <Dialog
      actions={<Button onClick={() => onChange(false)}>Cancel</Button>}
      id="delivery-details"
      open={open}
      title="Delivery Details"
      onClose={() => onChange(false)}>
      <Box className={'w-[420px]'}>
        <Grid container spacing={2} width={`calc(100% + 25px);`}>
          <Grid item xs={4}>
            Delivery Type :
          </Grid>
          <Grid item xs={8}>
            {deliveryDetails?.deliveryType === ShippingTransactionDeliveryTypeEnum.PickedUp
              ? 'Picked Up'
              : 'Delivered'}
          </Grid>
          <Grid item xs={4}>
            Delivered By :
          </Grid>
          <Grid item xs={8}>
            {deliveryDetails?.deliveryPerson?.name || '-'}
          </Grid>
          {verificationRecord?.receiverUser?.name && (
            <Fragment>
              <Grid item xs={4}>
                Received By :
              </Grid>
              <Grid item xs={8}>
                {verificationRecord?.receiverUser?.name || '-'}
              </Grid>
            </Fragment>
          )}
          {isEmpty(verificationRecord?.receiverUser) && verificationRecord?.userName && (
            <Fragment>
              <Grid item xs={4}>
                Signature Name :
              </Grid>
              <Grid item style={{ wordBreak: 'break-word' }} xs={8}>
                {verificationRecord?.userName || '-'}
              </Grid>
            </Fragment>
          )}
          {deliveryDetails?.deliveryNote && (
            <>
              <Grid item xs={4}>
                Notes :
              </Grid>
              <Grid item xs={8}>
                {deliveryDetails?.deliveryNote || '-'}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <img
              alt="verification"
              className={'h-[200px] w-[200px] object-contain'}
              src={verificationRecord?.verificationImageUrl || DefaultImage}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DeliveryDetailsDialog;
