import { useCallback, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { isValidProductCode } from "../utils/productCode"
import { Product, useHooverProductsQuery } from './useHooverProducts';

const parseProductNodes = (products: Product[]): suggestionData[] => {
  return products.map((product) => {
    const { id, name } = product;
    return {
      id,
      label: name
    };
  });
};

const useUpcSearchSuggestion = () => {

  const [suggestionData, setSuggestionData] = useState<suggestionData[]>();

  const handleProductResponse = useCallback(
    (response: any) => {
      const { products } = response?.data || {}
      const { nodes } = products || {};
      setSuggestionData(parseProductNodes(nodes || []));
    },
    [setSuggestionData],
  );

  const { fetchHooverProducts } = useHooverProductsQuery();

  const getProductsForSuggestion = async (value: string) => {
    const variables = {
      name: '',
      page: 0,
      totalPage: 50,
      model: '',
      code: '',
      brand: '',
    };
    if (isValidProductCode(value)) {
      variables.code = value || '';
    } else {
      variables.name = value || '';
    }
    const response = await fetchHooverProducts(variables);
    handleProductResponse(response)
  }

  return {
    suggestionData,
    getProductsForSuggestion
  };
};

export type UseUpcSearchSuggestionReturnType = ReturnType<typeof useUpcSearchSuggestion>;
export default useUpcSearchSuggestion;
