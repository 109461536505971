import { Maybe, Scalars } from '../types/schema';

export function format(cost: Maybe<Scalars['Float']>) {
  if (cost === undefined || cost === null) {
    return '';
  }
  const num = Number(cost);
  return (Math.round(num * 100) / 100).toFixed(2);
}

export const getCurrencySymbol = () => '$';

export function getCostWithCurrency(cost: Maybe<Scalars['Float']>) {
  return `${getCurrencySymbol()}${format(cost)}`;
}
