import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

const ScrollToTopWindow = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    const scrollToTop = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      scrollToTop();
    };
  }, []);

  return null;
};

export const ScrollToTop = withRouter(ScrollToTopWindow);
