import { useCallback, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Profile from 'app/i18n/Profile';
import { useCurrentUserWithHelpers } from 'app/modules/auth/hooks/useCurrentUser';
import ShoppingAssistantLink from 'app/modules/organizations/views/AddOrganization/components/ShoppingAssistantLink';
import Box from 'app/ui-components/Box';

import EmailUpdate from '../EmailUpdate';
import { useUpdateEmailIdMutation } from '../graphql/mutations/generated/updateEmail';
import { useUpdatePasswordMutation } from '../graphql/mutations/generated/updatePassword';
import { useUpdateProfileMutation } from '../graphql/mutations/generated/updateProfile';
import PasswordUpdate from '../PasswordUpdate';
import ProfileUpdate from '../ProfileUpdate';
import { EmailUpdateInput, PasswordUpdateInput, UpdateUserInput } from './types';
import { reloadUnifiedHeaderData } from '../../../../../../utils/unifiedHeader';
import analytics from 'app/analytics';

function ProfileForm() {
  const [{ fetching: updatingEmail }, onUpdateEmailId] = useUpdateEmailIdMutation();
  const [{ fetching: updatingPassword }, onUpdatePassword] = useUpdatePasswordMutation();
  const [{ fetching: updatingProfile }, onUpdateUser] = useUpdateProfileMutation();

  const { user, fetchUser } = useCurrentUserWithHelpers();

  const DEFAULT_VALUES_FOR_PROFILE_UPDATE: UpdateUserInput = useMemo(() => {
    return {
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName || '',
      userRole: user.roles,
      defaultSiteId: user.defaultSite?.id || '',
      profilePicture: user.profilePicture,
    };
  }, [user]);
  const DEFAULT_VALUES_FOR_PASSWORD_UPDATE: PasswordUpdateInput = useMemo(() => {
    return {
      userId: user.id,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  }, [user]);
  const DEFAULT_VALUES_FOR_EMAIL_UPDATE: EmailUpdateInput = useMemo(() => {
    return {
      userId: user.id,
      emailId: user.emailId,
    };
  }, [user]);

  const onProfileUpdate = useCallback(
    async (input: UpdateUserInput) => {
      const userUpdatePayloads: UpdateUserInput = {
        userId: user.id,
        firstName: input.firstName?.trim(),
        lastName: input.lastName?.trim(),
        profilePicture: input.profilePicture,
        defaultSiteId: input.defaultSiteId,
      };

      try {
        const response = await onUpdateUser({ input: userUpdatePayloads });
        await fetchUser();

        if (response.data?.updateUser.success) {
          analytics?.track('Edited', { name: 'Profile' });
          // Following event will be handled by Unified header, and It will reload Header
          reloadUnifiedHeaderData();
          SnackbarService.show({ message: Profile.SuccessMessages.ProfileUpdated });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [onUpdateUser, user.id, fetchUser],
  );

  const onPasswordUpdate = useCallback(
    async (input: PasswordUpdateInput) => {
      const passwordUpdatePayloads: PasswordUpdateInput = {
        userId: user.id,
        currentPassword: input.currentPassword,
        newPassword: input.newPassword,
      };

      const response = await onUpdatePassword({ input: passwordUpdatePayloads });
      await fetchUser();
      analytics?.track('Edited', { name: 'Profile' });
      if (response.data?.updatePassword.success) {
        SnackbarService.show({ message: Profile.SuccessMessages.PasswordUpdated });
      }
    },
    [onUpdatePassword, fetchUser],
  );

  const onEmailUpdate = useCallback(
    async (input: EmailUpdateInput) => {
      const emailUpdatePayloads: EmailUpdateInput = {
        userId: user.id,
        emailId: input.emailId.trim(),
      };
      const response = await onUpdateEmailId({ input: emailUpdatePayloads });
      await fetchUser();
      analytics?.track('Edited', { name: 'Profile' });
      if (response.data?.updateEmailId.success) {
        SnackbarService.show({ message: Profile.SuccessMessages.EmailUpdated });
      }
    },
    [onUpdateEmailId, fetchUser],
  );

  return (
    <form onSubmit={() => {}}>
      <Box className="max-w-[700px] space-y-24">
        <ProfileUpdate
          defaultValues={DEFAULT_VALUES_FOR_PROFILE_UPDATE}
          loading={updatingProfile}
          onProfileUpdate={onProfileUpdate}
        />
        <PasswordUpdate
          defaultValues={DEFAULT_VALUES_FOR_PASSWORD_UPDATE}
          loading={updatingPassword}
          onSubmit={onPasswordUpdate}
        />
        <EmailUpdate
          defaultValues={DEFAULT_VALUES_FOR_EMAIL_UPDATE}
          loading={updatingEmail}
          onSubmit={onEmailUpdate}
        />
        <ShoppingAssistantLink />
      </Box>
    </form>
  );
}

export default ProfileForm;
