import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { Grid,Box as MuiBox } from '@mui/material';
import { TitleBar } from '@procurenetworks/procure-component-library';
import get from 'lodash/get';
import { SnackbarService } from 'app/components/Snackbar';
import Inventory from 'app/i18n/Inventory';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';
import EntityManager from 'app/modules/components/EntityManager';
import { UseInventoryFormReturnType } from 'app/modules/inventory/components/InventoryForm/hook/useInventoryForm';
import { ItemTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components';

import KitSummaryForm from './components/KitSummaryForm';
import SaveCancelActionButtons from './components/SaveCancelActionButtons';
import useKitSummary from './context/useKitSummary';
import { UseKitSummaryStateReturnType } from './hook/useKitSummaryState';
import { getMoveInventoryTableColumns } from './utils/columns';
import { titleBarStyles } from './styles';

interface Props {
  types: ItemTypeEnum[];
  state: UseInventoryFormReturnType['state'] | UseAssetFormReturnType['formState'];
  disabled: boolean;
  loading?: boolean;
  onSubmit: (values: any, kitItems: any) => void;
}

const KitSummary = (props: Props) => {
  const { types, state, loading, disabled, onSubmit } = props;
  const { itemId, isEditMode } = state;
  const { kitState } = useKitSummary();
  const {
    formState,
    onRemoveTransaction,
    onFormSubmit,
    onResetForm,
    onResetFormAndTable,
    rows,
    tableState,
    table,
  } = kitState;

  const onKitCreate = useCallback(
    (values: any, kitItems: any) => {
      const message =
        rows?.length === 0
          ? 'To create a kit, please select at least two SKUs'
          : 'To create a kit, more than one SKU must be selected. Please select at least one more SKU.';
      if (rows?.length > 1) {
        if (onSubmit) {
          onSubmit(values, kitItems);
        }
      } else {
        SnackbarService.showError({
          message,
        });
      }
    },
    [onSubmit, rows],
  );

  const site = useMemo(() => {
    return get(state, 'itemData.site', null) || get(state, 'site');
  }, [state]);

  return (
    <>
      <Box className={cx('border p-[20px]', titleBarStyles)}>
        <TitleBar title={Inventory.KitSummary} />
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
            <KitSummaryForm
              disabled={!!itemId}
              formState={formState as UseKitSummaryStateReturnType['formState']}
              loading={loading}
              state={state}
              types={types}
              onCancel={onResetForm}
              onSubmit={onFormSubmit as UseKitSummaryStateReturnType['onFormSubmit']}
            />
          </Grid>

          <Grid item xs md={12} className='mt-[24px]'>
          <MuiBox className='border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
            <EntityManager.Table
              actions={table.actions}
              columns={getMoveInventoryTableColumns(disabled, isEditMode)}
              data={rows}
              extraProps={{
                onRemoveTransaction,
                isEditMode,
                site,
              }}
              pagination={true}
              persistKey="move_table"
              setState={table.setState}
              state={table.state}
              total={tableState.totalRows}
              paginationWrapperClass='p-[24px]'
              
            />
            </MuiBox>
          </Grid>
        </Grid>
      </Box>
      <SaveCancelActionButtons
        disabled={disabled}
        formState={{ ...state, rows, onResetFormAndTable }}
        loading={loading}
        onSubmit={() => onKitCreate(state.getValues(), rows)}
      />
    </>
  );
};

export default KitSummary;
