import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from "app/types/schema";
import routes from 'app/consts/routes';

const adminRouteHierarchy = [
  { route: routes.AssetModels(), subject: AllowedPermissionsSubjectEnum.AssetModel, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Categories(), subject: AllowedPermissionsSubjectEnum.Category, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Departments(), subject: AllowedPermissionsSubjectEnum.Department, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Locations(), subject: AllowedPermissionsSubjectEnum.Location, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Manufacturers(), subject: AllowedPermissionsSubjectEnum.Manufacturer, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Projects(), subject: AllowedPermissionsSubjectEnum.Project, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Sites(), subject: AllowedPermissionsSubjectEnum.Site, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Labels(), subject: AllowedPermissionsSubjectEnum.Label, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Import(), subject: AllowedPermissionsSubjectEnum.Import, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.UndeleteAsset(), subject: AllowedPermissionsSubjectEnum.Undelete, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Organizations(), subject: AllowedPermissionsSubjectEnum.Tenant, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Roles(), subject: AllowedPermissionsSubjectEnum.Role, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Users(), subject: AllowedPermissionsSubjectEnum.User, permission: AllowedPermissionActionsEnum.Access },
  { route: routes.Warehouse(), subject: AllowedPermissionsSubjectEnum.Warehouse, permission: AllowedPermissionActionsEnum.Access },
]

export default adminRouteHierarchy;