import _ from 'lodash';
import { nanoid } from 'nanoid';
import { ItemStatusEnum, MediaKindEnum, OrderRequestItemSchema } from 'app/types/schema';

import { isAssetInventoryTypeItem } from '../../OrderDetail/hook/useOrderRequestItems/utils';
import {
  ORDER_INSERT_TYPE,
  OrderRequestInput,
} from '../../OrderRequest/components/OrderRequestForm/type';
import { ItemInStock } from '../../OrderRequest/type';
import { isDuplicateItemInStock, isDuplicateUpcCode } from '../../OrderRequest/utils/utils';

function isValidRequestAgainOrderRequestItem(orderRequestItem: OrderRequestItemSchema) {
  const { item, type } = orderRequestItem;

  if (isAssetInventoryTypeItem(type)) {
    return Boolean(
      item &&
        item?.id &&
        item?.pickableThroughOrderRequest &&
        item.status !== ItemStatusEnum.Deleted,
    );
  }

  return true;
}

function getItemTypeFromOrderRequestItem(orderRequestItem: OrderRequestItemSchema) {
  const { type, upcCode, website, description, imageUrl } = orderRequestItem;

  if (isAssetInventoryTypeItem(type)) {
    return ORDER_INSERT_TYPE.ITEM_IN_STOCK;
  } else if (upcCode) {
    return ORDER_INSERT_TYPE.UPC_OR_ASIN_CODE;
  } else if (website) {
    return ORDER_INSERT_TYPE.WEBSITE;
  } else if (description) {
    return ORDER_INSERT_TYPE.DESCRIPTION;
  } else if (imageUrl) {
    return ORDER_INSERT_TYPE.IMAGE;
  }
}

function parseOrderRequestItemsToCartItem(orderRequestItem: OrderRequestItemSchema) {
  const { itemId, item, upcCode, website, imageUrl, description, projectId, quantity, cost } =
    orderRequestItem;

  return {
    id: nanoid(),
    lastUpdatedProject: false,
    itemId: itemId || '',
    upcCode: upcCode || '',
    website: website || '',
    imageUrl: imageUrl
      ? [
          {
            url: imageUrl,
            kind: MediaKindEnum.Image,
          },
        ]
      : [],
    description: description || '',
    projectId: projectId || '',
    quantity: (quantity || '1').toString(),
    cost: cost || '0.00',
    itemType: getItemTypeFromOrderRequestItem(orderRequestItem),
    itemInStock: item,
  } as OrderRequestInput;
}

export function filterAndParseOrderRequestItems(orderRequestItems: OrderRequestItemSchema[]) {
  const parseOrderRequestItems: OrderRequestInput[] = [];

  _.forEach(orderRequestItems, (orderRequestItem) => {
    const parsedOrderRequestItem = parseOrderRequestItemsToCartItem(orderRequestItem);
    const isValidOrderRequestItem = isValidRequestAgainOrderRequestItem(orderRequestItem);

    const isOrderRequestItemExist = _.find(parseOrderRequestItems, (orderRequestInput) => {
      return (
        (isValidOrderRequestItem &&
          isDuplicateItemInStock(orderRequestInput, parsedOrderRequestItem)) ||
        isDuplicateUpcCode(orderRequestInput, parsedOrderRequestItem)
      );
    });

    if (!isOrderRequestItemExist) {
      parseOrderRequestItems.push(parsedOrderRequestItem);
    } else {
      // If orderRequestItem is duplicating then sum up Quantity field
      const indexOfItem = parseOrderRequestItems.findIndex(
        (orderRequestItem: any) =>
          isDuplicateItemInStock(orderRequestItem, parsedOrderRequestItem) ||
          isDuplicateUpcCode(orderRequestItem, parsedOrderRequestItem),
      );

      parseOrderRequestItems[indexOfItem].quantity = (
        parseInt(parseOrderRequestItems[indexOfItem].quantity) +
        parseInt(parsedOrderRequestItem.quantity)
      ).toString();
    }
  });

  return parseOrderRequestItems;
}

function filterExternalOrderRequestItems(
  orderRequestItems: OrderRequestItemSchema[],
  items: ItemInStock[],
) {
  const filteredOrderRequestItems: OrderRequestItemSchema[] = [];
  const notRequestableExternalItemSkus: string[] = [];
  _.forEach(orderRequestItems, (orderRequestItem) => {
    const { type, item: itemInStock } = orderRequestItem;
    if (isAssetInventoryTypeItem(type)) {
      const item = items.find(
        (item) =>
          itemInStock?.entityIdInSourceTenant && item.id === itemInStock?.entityIdInSourceTenant,
      );
      if (item && item?.pickableThroughOrderRequest) {
        filteredOrderRequestItems.push({
          ...orderRequestItem,
          item,
          itemId: item.id,
        });
      } else {
        if (item?.sku && !item?.pickableThroughOrderRequest) {
          notRequestableExternalItemSkus.push(item?.sku);
        }
      }
    } else {
      filteredOrderRequestItems.push(orderRequestItem);
    }
  });

  return {
    filteredOrderRequestItems,
    notRequestableExternalItemSkus,
  };
}

export function filterAndParseExternalOrderRequestItems(
  orderRequestItems: OrderRequestItemSchema[],
  items: ItemInStock[],
) {
  const { notRequestableExternalItemSkus, filteredOrderRequestItems } =
    filterExternalOrderRequestItems(orderRequestItems, items);
  return {
    externalOrderRequestItems: filterAndParseOrderRequestItems(filteredOrderRequestItems),
    notRequestableExternalItemSkus,
  };
}

function filterInternalOrderRequestItems(
  orderRequestItems: OrderRequestItemSchema[],
  items: ItemInStock[],
) {
  const filteredOrderRequestItems: OrderRequestItemSchema[] = [];
  const notRequestableInternalItemSkus: string[] = [];
  _.forEach(orderRequestItems, (orderRequestItem) => {
    const { type, item: itemInStock } = orderRequestItem;
    if (isAssetInventoryTypeItem(type)) {
      const item = items.find((item) => itemInStock?.id && item.id === itemInStock?.id);
      if (item && item?.pickableThroughOrderRequest) {
        filteredOrderRequestItems.push(orderRequestItem);
      } else {
        if (orderRequestItem.item?.sku) {
          notRequestableInternalItemSkus.push(orderRequestItem.item?.sku);
        }
      }
    } else {
      filteredOrderRequestItems.push(orderRequestItem);
    }
  });

  return {
    filteredOrderRequestItems,
    notRequestableInternalItemSkus,
  };
}

export function filterAndParseInternalOrderRequestItems(
  orderRequestItems: OrderRequestItemSchema[],
  items: ItemInStock[],
) {
  const { notRequestableInternalItemSkus, filteredOrderRequestItems } =
    filterInternalOrderRequestItems(orderRequestItems, items);
  return {
    internalOrderRequestItems: filterAndParseOrderRequestItems(filteredOrderRequestItems),
    notRequestableInternalItemSkus,
  };
}
