import { Controller } from 'react-hook-form';
import FormLabel from 'app/components/ProcureForm/FormLabel';
import Orders from 'app/i18n/Orders';
import FormNumberInput from 'app/modules/components/Procure/FormNumberInput';

import { OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderQuantityField = (props: OrderFieldProps) => {
  const { formState, disabled, formLabel, isRequired } = props;
  const { control } = formState;

  return (
    <>
      {formLabel ? (
        <FormLabel
          className="ml-[40px] text-[14px] font-medium text-grey-800"
          isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
      <div className="mb-20 flex">
        <div className="ml-[40px] flex-auto">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                disabled={disabled}
                error={fieldState.error}
                label={Orders.FormLabels.Quantity}
                maximumLength={7}
                minimumValue={0}
              />
            )}
            rules={ORDER_REQUEST_FORM_RULES.quantity}
          />
        </div>
      </div>
    </>
  );
};

export default OrderQuantityField;
