import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Network from 'app/i18n/Network';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import { NetworkAssetsWarranty } from '../../components/NetworkAssetsWarranty';
import QuickSummary from './components/QuickSummary';
import WarrantyTypes from './components/WarrantyTypes';
import useDashboardData from './hook/useDashboardData';

const NetworkDashboard = (props: RouteComponentProps) => {
  const {
    totalUser,
    totalAsset,
    warrantyState,
    setWarrantyState,
    defaultValue,
    assetWithColor,
    totalWarrantyState,
  } = useDashboardData();

  return (
    <EntityManager className="px-[24px] pt-0" subject={AllowedPermissionsSubjectEnum.Asset}>
      <EntityManager.Title
        hasTopDivider
        documentTitle={Network.NetworkDashboard}
        title={Network.NetworkDashboard}
      />

      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Box className="flex flex-col flex-wrap gap-[24px] lg:flex-row">
          <Box className="flex-1">
            <QuickSummary asset={totalAsset} user={totalUser} />
          </Box>
          <Box className="flex-1 md:min-h-[320px] lg:min-h-[320px]">
            <Typography className="text-[18px] font-semibold text-grey-900">Warranty</Typography>
            <Divider className="bg-grey300 mt-[16px] mb-[24px]" />
            <Box sx={{ flexGrow: 1, height: '100%', borderRadius: '12px', marginTop: '5px' }}>
              <NetworkAssetsWarranty
                chartColors={totalWarrantyState.color || defaultValue.color}
                chartData={totalWarrantyState.labels || defaultValue.labels}
                subheader=""
                title=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <WarrantyTypes
        assetWithColor={assetWithColor}
        defaultValue={defaultValue}
        setWarrantyState={setWarrantyState}
        warrantyState={warrantyState}
      />
    </EntityManager>
  );
};

export default withRouter(NetworkDashboard);
