import { Controller } from 'react-hook-form';
import FormFileUpload from 'app/components/Form/FormFileUpload';
import Import from 'app/i18n/Import';

import { supportedBulkImportAttachmentFormats } from '../../../../../../utils/attachments';
import { ImportFormFieldProps } from '../../type';
import { IMPORT_FORM_RULES } from '../../utils/rules';

const ImportAttachmentField = (props: ImportFormFieldProps) => {
  const { formState, disabled } = props;
  const { control, attachmentInputProps } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="attachments"
        render={({ field, fieldState }) => (
          <FormFileUpload
            {...field}
            {...attachmentInputProps}
            key="attachments"
            isRequired
            accept={supportedBulkImportAttachmentFormats}
            disabled={disabled}
            error={fieldState.error}
            id="import-attachment"
            label={Import.FormLabels.AttachFile}
            multiple={false}
            note={Import.FormPlaceholders.AttachFile}
            testId="import-attachment"
          />
        )}
        rules={IMPORT_FORM_RULES.attachments}
      />
    </div>
  );
};

export default ImportAttachmentField;
