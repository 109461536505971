const Department = {
  Departments: 'Departments',
  NewDepartment: 'New Department',
  EditDepartment: 'Edit Department',
  FormLabels: {
    DepartmentName: 'Department',
    Description: 'Description',
    Code: 'Department Code',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Department Name between 3 and 30 characters.',
    DescriptionMaxLength: 'Please enter a Description up to 500 characters.',
  },
  FormPlaceholders: {
    Name: 'Enter department name',
    Description: 'Enter department description',
    Code: 'Enter department code',
    Search: 'Search assets for department',
  },
};

export default Department;
