import { composeValidators, emailValidator, textValidator } from 'app/components/Form/utils/validators';
import Login from 'app/i18n/Login';

export const LOGIN_FORM_RULES = {
  email: {
    validate: composeValidators(
      textValidator(Login.FormValidationMessages.EmailRequired),
      emailValidator(Login.FormValidationMessages.EmailInvalid),
    ),
  },
  password: {
    required: {
      value: true,
      message: Login.FormValidationMessages.PasswordRequired,
    },
  },
};
