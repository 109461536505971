import './style.css';
import { forwardRef, LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { cx } from '@emotion/css';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { PDFExport } from '@progress/kendo-react-pdf';
import QRCode from 'qrcode.react';
import Loader from 'app/components/Loader';
import useDimensions from 'app/hooks/useDimensions';
import Box from 'app/ui-components';
import ProcureLogoBlack from 'app/assets/procureLogoBlack.png';
import ProcureLogoSquare from 'app/assets/procureLogoSquare.png';
import AppleStoreIcon from 'app/assets/appleStoreIcon.png';
import GooglePlayStoreIcon from 'app/assets/googlePlayStoreIcon.png';
import { APP_CONFIG } from 'app/consts/config';

const PrintReturnedOrderOutput = forwardRef<HTMLDivElement, { content: any }>((props, ref) => {

    const [pdf, setPdf] = useState('');
    const [displayPdf, setDisplayPdf] = useState('block');
    const pdfViewer = useRef();
    const { width, height } = useDimensions();
    const bodyRef = useRef<any>();
    const pdfContent = props.content;

    const buildPdf = async () => {
        let gridElement = document.getElementById('divToPrint') as HTMLElement;
        const group = await drawDOM(gridElement, {
            paperSize: 'a4',
            // margin: getPageMargin,
        });
        const dataUri = await exportPDF(group, { title: `Order Return Request` });
        const data = await fetch(dataUri);
        const blob = await data.blob();
        const blobUrl = URL.createObjectURL(blob);
        setPdf(blobUrl);
    };

    useEffect(() => {
        buildPdf();
    }, [bodyRef]);

    useEffect(() => {
        if (pdf) {
            setDisplayPdf('none');
        }
    }, [pdf]);

    const keyPress = useCallback((e: KeyboardEvent) => {
        e.preventDefault();
        if (pdfViewer && pdfViewer.current) {
            (pdfViewer?.current as unknown as HTMLIFrameElement)?.contentWindow?.print();
        }
    }, [pdfViewer]);

    const wrapImageUrlWithCorsProxy = (imageUrl: string) => {
        return `${APP_CONFIG.apiUrls.corsProxy}/image?imageUrl=${encodeURIComponent(imageUrl)}`;
    }

    useHotkeys('cmd+p, ctrl+p', keyPress);

    const getNotificationContent = () => {
        return <Box className="pdfContainer">

            <div>
                <img className="max-w-[120px] max-h-[150px] mx-auto" src={ProcureLogoBlack} />
            </div>

            <div className="text-[20px] font-bold mt-[30px] mb-20">Alert: Return - Packing List</div>

            <div>
                Hi {pdfContent.first_name},
            </div>
            <div className="mt-[7px]">
                Please review, print and affix this Return Packing List securely to the item(s) you are returning.
                Place the item(s) in the area where your orders are regularly delivered.
            </div>

            <div className="my-[15px]">NOTE: This return must be completed within 30 days.</div>

            <div className="flex mt-[20px] mb-[35px]">
                <div className="flex-1">
                    <div><span className="font-bold">Date:</span>&nbsp;{pdfContent.date}</div>
                    <div><span className="font-bold">Order ID:</span>&nbsp;{pdfContent.order_id}</div>
                    <div><span className="font-bold">Requested By:</span>&nbsp;{pdfContent.created_by}</div>

                    <div className="mt-[20px]"><span className="font-bold">To Site:</span>&nbsp;{pdfContent.returnDestinationSiteName}</div>
                    <div><span className="font-bold">Delivered To:</span>&nbsp;{pdfContent.deliver_to}</div>
                    <div><span className="font-bold">Department:</span>&nbsp;{pdfContent.department_id}</div>
                </div>
                <div className="flex-1 text-center mt-[25px]">
                    <div className="mx-auto w-[70px]"><QRCode fgColor="#000" renderAs={'svg'} size={70} value={pdfContent.returnContainerId} /></div>
                    <div className="mt-[12px] mx-auto">{pdfContent.returnContainerId}</div>
                </div>
            </div>

            <table className="itemsList">
                <thead>
                    <tr>
                        <th className="imageColumn">Image</th>
                        <th className="descriptionColumn">Item Description</th>
                        <th>Order Status</th>
                        <th>Notes</th>
                        <th>Quantity</th>
                        <th className="costColumn">Cost Each</th>
                        <th className="costColumn">Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {pdfContent.items.map((item: any) => {
                        return <tr>
                            <td>
                                {item.image_url &&
                                    <img className="itemImage" src={wrapImageUrlWithCorsProxy(item.image_url)} />
                                }
                            </td>
                            <td>{item.description_overview}</td>
                            <td>{item.status}</td>
                            <td>{item.notes}</td>
                            <td>{item.quantity}</td>
                            <td className="costColumn">{item.cost_each}</td>
                            <td className="costColumn">{item.cost}</td>
                        </tr>
                    })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6} className="text-right font-bold">Total Cost:</td>
                        <td className="costColumn">{pdfContent.total_cost}</td>
                    </tr>
                </tfoot>
            </table>

            <div className="mt-[20px] mb-[15px]">
                <div>Thanks for using Procure!</div>
                <div className="mt-[7px]">Enjoy your day.</div>
            </div>

            <hr className="w-full border-t" />

            <div className="mt-[17px]">
                <img className="w-[25px] mx-auto" src={ProcureLogoSquare} />
            </div>

            <div className="mt-[20px] flex items-center items-center w-full">
                <div className="mx-auto inline flex">
                    <img className="w-[120px]" src={AppleStoreIcon} />
                    <img className="w-[120px] ml-[10px]" src={GooglePlayStoreIcon} />
                </div>
            </div>
        </Box>
    }

    return (
        <>

            <Box>
                {!pdf ? (
                    <Box className={cx('fixed top-0 left-0 h-full w-full bg-[#f1f2f7]')}>
                        <Loader />
                    </Box>
                ) : (
                    <iframe
                        ref={pdfViewer as unknown as LegacyRef<HTMLIFrameElement> | undefined}
                        height={height}
                        id="pdfViewer"
                        src={pdf}
                        title={'Procure'}
                        width={width}></iframe>
                )}
            </Box>
            <PDFExport ref={bodyRef} paperSize="auto">
                <Box className={'m-auto'} id="divToPrint" style={{ display: displayPdf }}>
                    {getNotificationContent()}
                </Box>
            </PDFExport>
        </>
    );
});
export default PrintReturnedOrderOutput;
