import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';

import FormTextInput, { FormTextInputProps } from '../../../components/Form/FormTextInput';

type Props = FormTextInputProps & { maxValue?: number };
const MAXIMUM_COST = 99999999.99;
const COST_REGEX = /^\d+$/;

const FormCostInput = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { onChange, value, maxValue = MAXIMUM_COST, ...restProps } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const selectionRef = useRef(false);

  ref = inputRef;
  useEffect(() => {
    // Prevents selection on input through mouse and keyboard
    inputRef?.current?.addEventListener(
      'select',
      function () {
        this.selectionStart = 0;
        this.selectionEnd = value?.toString()?.length || 0;
        selectionRef.current = true;
      },
      false,
    );

    return () => {
      inputRef?.current?.removeEventListener(
        'select',
        function () {
          this.selectionStart = 0;
          this.selectionEnd = value?.toString()?.length || 0;
        },
        false,
      );
    };
  }, []);

  const handleValueChange = useCallback(
    (e: any) => {
      let costValue = value?.toString() || '';
      costValue = costValue.replace(/\./g, '');

      if (COST_REGEX.test(e.key)) {
        costValue = costValue + e.key;
      } else if (e.key === 'Backspace') {
        if (selectionRef.current === true) {
          costValue = '000';
          selectionRef.current = false;
        } else {
          costValue = costValue?.slice(0, costValue.length - 1);
        }
      }

      costValue = parseFloat(costValue).toString();
      if (costValue.length > 2) {
        costValue = getFloatingValue(costValue);
      } else {
        let filledValue = new Array(3 - costValue.length).fill(0).join('') + costValue;
        costValue = getFloatingValue(filledValue);
      }

      if (COST_REGEX.test(e.key)) {
        if (parseFloat(costValue) <= maxValue) {
          onChange?.(costValue);
        }
      } else {
        onChange?.(costValue);
      }
    },
    [value],
  );

  const handleKeyDown = useCallback((e: any) => {
    // Prevents arrow navigation for cursor position changing

    if (e.keyCode >= 37 && e.keyCode <= 40) {
      e.preventDefault();
    }
  }, []);

  const getFloatingValue = useCallback((stringValue: string | number) => {
    stringValue = stringValue.toString();
    return (
      stringValue.slice(0, stringValue.length - 2) + '.' + stringValue.slice(stringValue.length - 2)
    );
  }, []);

  return (
    <FormTextInput
      ref={inputRef}
      {...restProps}
      showCharacterLimit={false}
      value={value}
      onKeyDown={handleKeyDown}
      onKeyUp={handleValueChange}
    />
  );
});

export default FormCostInput;
