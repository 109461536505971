import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAssetItemMutationVariables = Types.Exact<{
  input: Types.CreateAssetItemInput;
}>;


export type CreateAssetItemMutation = { __typename?: 'Mutation', createAssetItem: { __typename?: 'CreateAssetItemPayload', success: boolean, reason?: string | null, assetItem?: { __typename?: 'AssetItemSchema', id: string } | null } };


export const CreateAssetItemDocument = gql`
    mutation createAssetItem($input: CreateAssetItemInput!) {
  createAssetItem(input: $input) {
    success
    reason
    assetItem {
      id
    }
  }
}
    `;

export function useCreateAssetItemMutation() {
  return Urql.useMutation<CreateAssetItemMutation, CreateAssetItemMutationVariables>(CreateAssetItemDocument);
};