import AccessControl from 'app/components/AccessControl';
import FormLabel from 'app/components/Form/FormLabel';
import Warehouse from 'app/i18n/Warehouse';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import SiteSelector from 'app/modules/locations/components/SiteSelector';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

const subject = AllowedPermissionsSubjectEnum.Warehouse;

interface Props {
  siteId: string | undefined;
  onSiteChange: (siteId: string | undefined) => void;
}

const WarehouseSiteSelector = (props: Props) => {
  const { siteId, onSiteChange } = props;
  const { workspacePermissions } = useCurrentUser();

  return (
    <AccessControl
      action={AllowedPermissionActionsEnum.Access}
      permissions={workspacePermissions}
      subject={subject}>
      <Stack alignItems="start" direction="col">
        <FormLabel className="semibold text-[14px] text-grey-800">
          {' '}
          {Warehouse.SelectSite}
        </FormLabel>
        <SiteSelector
          autoFocus
          className="mt-6 lg:w-[358px]"
          placeholder="-- Select One --"
          value={siteId}
          onChange={onSiteChange}
        />
      </Stack>
    </AccessControl>
  );
};

export default WarehouseSiteSelector;
