import { ShippingTransactionStatusEnum } from 'app/types/schema';

import { ShipTransactionStatues } from '../ShipTransactionStatueFilter/utils';

export type ShippingTransactionOption = {
  value: ShippingTransactionStatusEnum;
  label: string;
  disabled?: boolean;
};

export const getShippingStatusOptions = (status: ShippingTransactionStatusEnum) => {
  const currentStatus = ShipTransactionStatues?.find(
    (transactionStatus) => transactionStatus?.value === status,
  );
  let statusDropDownOptions = [
    {
      value: ShippingTransactionStatusEnum.OutForDelivery,
      label: 'Out for Delivery',
      disabled: true,
    },
    {
      value: ShippingTransactionStatusEnum.Delivered,
      label: 'Delivered',
    },
  ];

  if (currentStatus && currentStatus?.value !== ShippingTransactionStatusEnum?.OutForDelivery) {
    statusDropDownOptions = [
      {
        value: currentStatus?.value,
        label: currentStatus?.label,
        disabled: true,
      },
      ...statusDropDownOptions,
    ];
  }

  return statusDropDownOptions;
};
