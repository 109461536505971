import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnpackShippingTransactionsOfContainerMutationVariables = Types.Exact<{
  input: Types.UnpackShippingTransactionsOfContainerIdInput;
}>;


export type UnpackShippingTransactionsOfContainerMutation = { __typename?: 'Mutation', unpackShippingTransactionsOfContainerId: { __typename?: 'MutationResponse', success: boolean } };


export const UnpackShippingTransactionsOfContainerDocument = gql`
    mutation unpackShippingTransactionsOfContainer($input: UnpackShippingTransactionsOfContainerIdInput!) {
  unpackShippingTransactionsOfContainerId(input: $input) {
    success
  }
}
    `;

export function useUnpackShippingTransactionsOfContainerMutation() {
  return Urql.useMutation<UnpackShippingTransactionsOfContainerMutation, UnpackShippingTransactionsOfContainerMutationVariables>(UnpackShippingTransactionsOfContainerDocument);
};