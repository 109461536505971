import { APP_CONFIG } from 'app/consts/config';
import Cookie from 'app/utils/cookie';

const { cookieKeys } = APP_CONFIG;

const currentUser = {
  id: () => Cookie.get(cookieKeys.id),
  at: () => Cookie.get(cookieKeys.accessToken),
  rt: () => Cookie.get(cookieKeys.refreshToken),
  tenantId: () => Cookie.get(cookieKeys.tenant),
};

export default currentUser;
