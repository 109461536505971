import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import { ItemStringBasedColumnFilterFieldEnum, LocationTypeEnum, Maybe } from 'app/types/schema';

import {
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../components/AsyncMultiSelect/types';
import AsyncMultiTableFilter from '../../../../components/AsyncMultiTableFilter';
import { AsyncMultiTableFilterProps } from '../../../../components/AsyncMultiTableFilter/types';
import {
  FormValuesInItemsSelectQueryVariables,
  useFormValuesInItemsSelectQuery,
} from './graphql/query/generated/formValuesInItemsSelect';

interface UseSelectedValueProps {
  value: string[];
  pause?: boolean;
  options?: any[];
}

interface Props {
  name: string;
  className?: string;
  testId?: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables: FormValuesInItemsSelectQueryVariables;
  onClick?: (event?: any) => void;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, options } = props;

  const values = useMemo(
    () => options?.filter((option) => value.includes(option.value)) || [],
    [value, options],
  );

  return { isDisabled: false, selectedValues: values };
};

const useSelectQuery = (
  props: UseSelectQueryProps & FormValuesInItemsSelectQueryVariables,
): UseSelectQueryReturn => {
  const { field, filters } = props;

  const [{ fetching, data }] = useFormValuesInItemsSelectQuery({
    variables: {
      field,
      filters,
    },
    requestPolicy: 'network-only',
  });

  const options = useMemo(
    () =>
      data?.valuesInItems?.map((item) => {
        return {
          label: item,
          value: item,
        };
      }) || [],
    [data?.valuesInItems],
  );

  return { isLoading: fetching, options };
};

const FormValuesInItemsTableFilter = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <AsyncMultiTableFilter
      {...rest}
      className={className || 'mt-7 pt-7'}
      size="small"
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

export default FormValuesInItemsTableFilter;
