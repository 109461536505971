import { getTransactionTypeTitle } from 'app/modules/inventory/components/TransactionHistory/utils/transactionTypeFilter';

import Assets from '../../../../../../i18n/Assets';
import Inventory from '../../../../../../i18n/Inventory';
import {
  TransactionEntityTypeEnum,
  TransactionSubTypeEnum,
  TransactionTypeEnum,
} from '../../../../../../types/schema';


export const getTitleByTransactionType = (
  type?: TransactionTypeEnum,
  subType?: TransactionSubTypeEnum,
  entityType?: TransactionEntityTypeEnum,
): {
  documentTitle: string;
  title: string;
} => {
  const defaultResponse = {
    documentTitle: '',
    title: '',
  };

  if (!type || !subType) {
    return defaultResponse;
  }

  const transactionTypeTitle = getTransactionTypeTitle(type, subType);

  const isEntityTypeAsset = entityType === TransactionEntityTypeEnum.Asset;
  const isEntityTypeInventory = entityType === TransactionEntityTypeEnum.Inventory;
  const isEntityTypeAssetKit = entityType === TransactionEntityTypeEnum.AssetKit;
  const isEntityTypeInventoryKit = entityType === TransactionEntityTypeEnum.InventoryKit;

  if (isEntityTypeAsset) {
    return {
      documentTitle: Assets.TransactionTitle(transactionTypeTitle),
      title: Assets.TransactionConfirmationTitle(transactionTypeTitle),
    };
  } else if (isEntityTypeInventory) {
    return {
      documentTitle: Inventory.TransactionTitle(transactionTypeTitle),
      title: Inventory.TransactionConfirmationTitle(transactionTypeTitle),
    };
  } else if (isEntityTypeAssetKit) {
    return {
      documentTitle: Assets.TransactionTitle(transactionTypeTitle),
      title: Assets.TransactionConfirmationTitle(transactionTypeTitle),
    };
  } else if (isEntityTypeInventoryKit) {
    return {
      documentTitle: Inventory.TransactionTitle(transactionTypeTitle),
      title: Inventory.TransactionConfirmationTitle(transactionTypeTitle),
    };
  } else {
    return defaultResponse;
  }
};
