import FileViewer from '../../../../libs/FileViewer';
import { getFilePathFromParams } from '../../utils/filePath';

const FilePreview = () => {
  const filePath = getFilePathFromParams();

  if (!filePath) {
    return null;
  }

  return <FileViewer filePath={filePath} />;
};
export default FilePreview;
