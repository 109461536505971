import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EditProject from 'app/modules/projects/views/EditProject';

function EditProjectPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  return (
    <AdminLayout>
      <EditProject projectId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditProjectPage);
