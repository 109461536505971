import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';
import FormCostInput from 'app/modules/components/FormCostInput';

import { AssetFieldProps } from '../../types';
import { ASSET_FORM_RULES } from '../../utils';

const AssetUnitCostOverrideField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeAsset } = formState;

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="unitCost"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={!isItemTypeAsset || disabled}
              label={Assets.FormLabels.UnitCost}
            />
          )}
          rules={ASSET_FORM_RULES.unitCost}
        />
      </div>

      <div className="mb-20">
        <Controller
          control={control}
          name="costOverride"
          render={({ field, fieldState }) => (
            <FormCostInput
              {...field}
              error={fieldState.error}
              isDisabled={disabled}
              label={Assets.FormLabels.CostOverride}
              maxValue={999.0}
            />
          )}
          rules={ASSET_FORM_RULES.costOverride}
        />
      </div>
    </div>
  );
};

export default AssetUnitCostOverrideField;
