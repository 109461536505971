import { useEffect } from 'react';

export const ReloadPersistedRoute = () => {
  useEffect(() => {
    const onPageShow = (event: any) => {
      if (event?.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', onPageShow);
    return () => {
      window.removeEventListener('pageshow', onPageShow);
    };
  }, []);

  return null;
};
