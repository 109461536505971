import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { SitesTableRowFragmentDoc } from '../../fragments/generated/SitesTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SitesTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SitesTableQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, updatedAt: string, locationCode?: string | null, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, siteManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }>, siteStaffs: Array<{ __typename?: 'UserSchema', id: string, name: string }> } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const SitesTableDocument = gql`
    query sitesTable($filters: LocationFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  locations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...SitesTableRow
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    ${SitesTableRowFragmentDoc}`;

export function useSitesTableQuery(options?: Omit<Urql.UseQueryArgs<SitesTableQueryVariables>, 'query'>) {
  return Urql.useQuery<SitesTableQuery>({ query: SitesTableDocument, ...options });
};