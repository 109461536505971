import React, { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';

import Modal from '../../../../../../components/Modal';
import Common from '../../../../../../i18n/Common';
import Inventory from '../../../../../../i18n/Inventory';
import EditInventoryItemsForm from '../EditInventoryItemsForm';
import useEditInventoryItemsState from './hook/useEditInventoryItemsState';
import { EditInventoryItemsModalProps } from './types';

const EditInventoryItemsModal = (props: EditInventoryItemsModalProps) => {
  const { open, tableState, filters, setState } = props;

  const { editInventoryItems } = useEditInventoryItemsState();
  const totalSelectedRecord = tableState.selectedRowIds.length;
  // const { categoryItems, siteItems } = filters;

  const onCloseEditInventoryItemsModal = useCallback(() => {
    setState({
      editInventoryItemsModal: false,
    });
  }, [setState]);

  const onSaveChanges = useCallback(() => {
    console.log(editInventoryItems.state);
  }, [editInventoryItems.state]);

  return (
    <Modal
      disableBackdropClick
      actions={
        <div>
          <Button theme="success" onClick={onSaveChanges}>
            {Common.Actions.Save}
          </Button>
          <Button classes="ml-[4px]" onClick={onCloseEditInventoryItemsModal}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="edit-inventories"
      open={open}
      title={Inventory.EditInventoryItems(totalSelectedRecord)}
      onClose={onCloseEditInventoryItemsModal}>
      <div className="min-w-[375px]">
        <EditInventoryItemsForm
          categoryItems={[]}
          setState={editInventoryItems.setState}
          siteItems={[]}
          state={editInventoryItems.state}
        />
      </div>
    </Modal>
  );
};

export default EditInventoryItemsModal;
