import { useCallback, useEffect, useMemo, useState } from 'react';
import FormTextArea from 'app/components/Form/FormTextArea';
import { OrderRequestItemSchema } from 'app/types/schema';

import { DateFormatEnum, formatDate } from '../../../../utils/date';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { Box } from '@mui/material';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
}

const OrderRequestItemNotesView = (props: Props) => {
  const { orderRequestItem, state, actions } = props;
  const { orderDetail, orderView } = state;
  const { note, nonRemovableNotes = [] } = orderRequestItem;

  const [noteValue, setNoteValue] = useState<string>(note || '');

  useEffect(() => {
    setNoteValue(note || '');
  }, [note]);

  const { isOrderRequestItemEditable } = useOrderEditPermissionCheck({
    orderDetail,
  });

  const renderEditOrderNoteField = useMemo(
    () => orderView === ORDER_VIEW.EDIT_ORDER_VIEW && isOrderRequestItemEditable(orderRequestItem),
    [orderView, orderRequestItem],
  );

  const nonRemovableNote =
    nonRemovableNotes && nonRemovableNotes.length > 0 ? nonRemovableNotes[0] : null;

  const onNoteChange = useCallback((value: string) => {
    setNoteValue(value);
  }, []);
  const onKeyDown = (e: any) => {
    if (e.code === 'Space') {
      e.preventDefault();
      e.stopPropagation();
      setNoteValue(noteValue + ' ');
    }
  };

  const onBlurNoteField = useCallback(() => {
    actions.onUpdateOrderItem(orderRequestItem.id, {
      note: noteValue,
    });
  }, [actions.onUpdateOrderItem, orderRequestItem.id, noteValue]);

  if (!renderEditOrderNoteField) {
    return (
      <>
        {nonRemovableNote ? (
          <div>
            {nonRemovableNote.vendor ? (
              <p className="truncate">Vendor: {`${nonRemovableNote.vendor}`}</p>
            ) : null}
            {nonRemovableNote.orderId ? (
              <p className="truncate">Order#: {`${nonRemovableNote.orderId}`}</p>
            ) : null}
            {nonRemovableNote.estimatedDeliveryDate ? (
              <p className="truncate">
                ETA: {`${formatDate(nonRemovableNote.estimatedDeliveryDate, DateFormatEnum.Date)}`}
              </p>
            ) : null}
          </div>
        ) : null}
        <p>{note}</p>
      </>
    );
  }

  return (
    <Box className="flex h-[80px] w-[155px] items-center justify-center pb-10 pt-5">
      <FormTextArea
        name="note"
        rows={2}
        value={noteValue ?? ''}
        onBlur={onBlurNoteField}
        onChange={onNoteChange}
        onKeyDown={onKeyDown}
        defaultTextAreaHeight="30px"
      />
    </Box>
  );
};

export default OrderRequestItemNotesView;
