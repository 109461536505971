import { useCallback, useMemo, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { CustomIcons, TitleBar } from '@procurenetworks/procure-component-library';
import FormSearchBar from 'app/components/ProcureForm/FormSearchBar';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import SectionList from 'app/modules/contacts/components/contactSectionList';
import Box from 'app/ui-components/Box';
import { getSectionedList } from 'app/utils/contactFilter';

import EntityManager from '../../../components/EntityManager';
import useContactSearchSuggestion from '../../hook/useContactSearchSuggestion';
import { useContactListQuery } from './graphql/queries/generated/contatct';

const ContactSearch = (props: RouteComponentProps<{ id: string }>) => {
  const { history, match } = props;

  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [{ fetching, data }] = useContactListQuery({
    variables: {
      limit: 10000,
      filters: {
        search: searchBoxValue,
      },
    },
  });

  const contacts = useMemo(() => {
    return (
      data?.contacts?.edges?.map((contact) => {
        return { ...contact.node, name: `${contact.node.firstName} ${contact.node.lastName}` };
      }) || []
    );
  }, [data?.contacts?.edges]);

  const _handleSearchEvent = useCallback((inputValue: any) => {
    setSearchBoxValue(inputValue);
  }, []);

  const { searchSuggestions, setSearchValue } = useContactSearchSuggestion();

  const contactData = useMemo(() => {
    return getSectionedList(contacts, 'name');
  }, [contacts]);

  const handleRowClick = (
    _event: React.MouseEvent<HTMLDivElement | HTMLTableRowElement | MouseEvent, MouseEvent>,
    row: any,
  ) => {
    history.replace(routes.Contacts(row.id));
  };
  return (
    <>
      <Grid xs={12}>
        <Box className="min-w-full divide-x-[3px]">
          <TitleBar title="Contact Search" />
        </Box>
      </Grid>

      <Grid xs={12}>
        <Box className="min-w-full pt-32 ">
          <EntityManager.Search
            autoFocus
            isDropdown
            removedSearchQoutedValue
            placeholder="Search"
            startAdornment={
              <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                <CustomIcons.SearchIcon />
              </InputAdornment>
            }
            suggestionData={searchSuggestions}
            value={searchBoxValue}
            onChange={_handleSearchEvent}
            onChangeSearch={setSearchValue}
            searchFieldWrapper="w-full md:w-[380px] lg:w-[380px]"
          />
          <Box className="mt-[24px]">
            <SectionList
              hideEmpty
              data={contactData}
              keyName="name"
              loading={fetching}
              onRowClick={handleRowClick}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default withRouter(ContactSearch);
