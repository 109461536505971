import { suggestionData } from "app/modules/components/EntityManager/EntityManagerSearch";

export const truncateString = (string: string, index: number): string => {
  const maxLength = 100;
  const start = Math.max(0, index - maxLength/2);
  const end = Math.min(string.length, index + maxLength/2);
  let truncatedStr = string.substring(start, end);
  if (string.length > maxLength) {
    const ellipsis = '...';
    const startEllipsis = (start > 0) ? ellipsis : '';
    const endEllipsis = (end < string.length) ? ellipsis : '';
    const truncatedMiddle = truncatedStr.substring(startEllipsis.length, end - endEllipsis.length - start);
    truncatedStr = startEllipsis + truncatedMiddle + endEllipsis;
  }
  return truncatedStr;
}

export const matchingIndex = (string: string, queryWords: string[]) => {
  let index = -1;
  queryWords.some(word => {
    index = string.toLowerCase().indexOf(word.toLowerCase());
    return index !== -1;
  });
  return index;
}

export const sortedData = (data: suggestionData[], searchQuery: string) => {
  return data.sort((a, b) => {
    const aTitle = a.label.toLowerCase();
    const bTitle = b.label.toLowerCase();
    const aTitleIncludesSearch = aTitle.includes(searchQuery.toLowerCase());
    const bTitleIncludesSearch = bTitle.includes(searchQuery.toLowerCase());
    
    if (aTitleIncludesSearch && !bTitleIncludesSearch) {
      return -1;
    } else if (!aTitleIncludesSearch && bTitleIncludesSearch) {
      return 1;
    } else if (aTitle.includes(searchQuery.toLowerCase()) && bTitle.includes(searchQuery.toLowerCase())) {
      return aTitle.localeCompare(bTitle);
    } else {
      return 0;
    }
  });
};
