import { ORDER_INSERT_TYPE, OrderRequestInput } from '../components/OrderRequestForm/type';
import { DuplicateOrderItem, OrderRequestItemSchema } from '../type';
import { findOrderItemById } from '../components/OrderItemRequest/utils/utils';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';

export function getQuantityTotal(orderItems: OrderRequestInput[]) {
  let quantity = 0;

  orderItems.forEach((orderItem) => {
    quantity += Number(orderItem.quantity);
  });

  return quantity;
}

export function getDuplicateOrderItemIds(duplicateOrderItems: DuplicateOrderItem[]): string[] {
  const ids: string[] = [];

  duplicateOrderItems.forEach((duplicateOrderItem) => {
    duplicateOrderItem.duplicateItems.forEach((duplicateItem) => {
      if (duplicateItem.id && !ids.includes(duplicateItem.id)) {
        ids.push(duplicateItem.id);
      }
    });
  });

  return ids;
}

export function getDuplicateOrderItemIndexes(
  duplicateOrderItems: DuplicateOrderItem[],
  orderItems: OrderRequestInput[],
): number[] {
  const indexes: number[] = [];

  duplicateOrderItems.forEach((duplicateOrderItem) => {
    duplicateOrderItem.duplicateItems.forEach((duplicateItem) => {
      const itemIndex = orderItems.findIndex((orderItem) => orderItem.id === duplicateItem.id);
      if (itemIndex !== -1) {
        indexes.push(itemIndex);
      }
    });
  });

  return indexes;
}

export const isDuplicateItemInStock = (
  existingItem: OrderRequestInput,
  newOrderItem: OrderRequestInput,
) => {
  return (
    existingItem.itemInStock &&
    newOrderItem.itemInStock &&
    existingItem.quantity &&
    newOrderItem.quantity &&
    newOrderItem?.itemType === ORDER_INSERT_TYPE.ITEM_IN_STOCK &&
    existingItem?.itemType === newOrderItem.itemType &&
    existingItem.itemInStock?.sku === newOrderItem.itemInStock?.sku &&
    existingItem.projectId === newOrderItem.projectId
  );
};

export const isDuplicateUpcCode = (
  existingItem: OrderRequestInput,
  newOrderItem: OrderRequestInput,
) =>
  newOrderItem.itemType === ORDER_INSERT_TYPE.UPC_OR_ASIN_CODE &&
  existingItem.itemType === newOrderItem.itemType &&
  existingItem.upcCode &&
  existingItem.upcCode === newOrderItem.upcCode &&
  existingItem.projectId === newOrderItem.projectId;

export function findDuplicateOrderItems(orderItems: OrderRequestInput[]): DuplicateOrderItem[] {
  const duplicateOrderItems: DuplicateOrderItem[] = [];
  orderItems.forEach((currentOrderItem) => {
    const isDuplicateItemExist = duplicateOrderItems.find(
      (duplicateOrderItem) =>
        isDuplicateItemInStock(duplicateOrderItem, currentOrderItem) ||
        isDuplicateUpcCode(duplicateOrderItem, currentOrderItem),
    );

    if (isDuplicateItemExist) {
      return;
    }

    const duplicateItems = orderItems.filter(
      (orderItem) =>
        orderItem !== currentOrderItem &&
        (isDuplicateItemInStock(orderItem, currentOrderItem) ||
          isDuplicateUpcCode(orderItem, currentOrderItem)),
    );

    if (duplicateItems.length) {
      duplicateOrderItems.push({
        ...currentOrderItem,
        duplicateItems: duplicateItems,
      });
    }
  });

  return duplicateOrderItems;
}

export function getDuplicateOrderItem(
  orderRequestItems: OrderRequestInput[],
  currentOrderItemId: string,
): {
  duplicateOrderItem: Maybe<OrderRequestInput>;
  duplicateUpdatedState: Partial<OrderRequestInput>;
} {
  const currentOrderItem = findOrderItemById(orderRequestItems, currentOrderItemId);

  let duplicateOrderItem: Maybe<OrderRequestInput> = null;

  if (!currentOrderItem) {
    return {
      duplicateOrderItem,
      duplicateUpdatedState: {},
    };
  }

  for (let i = 0; i < orderRequestItems.length; i++) {
    const orderRequestItem = orderRequestItems[i];
    const { id } = orderRequestItem;
    if (
      id != currentOrderItemId &&
      (isDuplicateItemInStock(orderRequestItem, currentOrderItem) ||
        isDuplicateUpcCode(orderRequestItem, currentOrderItem))
    ) {
      duplicateOrderItem = orderRequestItem;
      break;
    }
  }

  const totalQuantity = duplicateOrderItem
    ? Number(duplicateOrderItem.quantity || 0) + Number(currentOrderItem.quantity || 0)
    : 0;

  return {
    duplicateOrderItem,
    duplicateUpdatedState: {
      quantity: totalQuantity.toString(),
      cost: currentOrderItem.cost,
    },
  };
}

export function getDuplicateOrderItems(orderRequestItems: OrderRequestInput[]) {
  const updateDuplicateOrderItems: Partial<OrderRequestInput>[] = [];
  const removeDuplicateOrderIds: string[] = [];

  _.forEach(orderRequestItems, (orderRequestItem) => {
    const isOrderRequestItemFoundInDuplicateOrderItems = !!_.find(
      removeDuplicateOrderIds,
      (id) => id === orderRequestItem.id,
    );

    if (isOrderRequestItemFoundInDuplicateOrderItems) {
      return;
    }

    let currentOrderItem: OrderRequestInput = orderRequestItem;
    let totalQuantityTotal: number = 0;

    _.forEach(orderRequestItems, (nestedOrderRequestItem) => {
      if (
        isDuplicateItemInStock(nestedOrderRequestItem, orderRequestItem) ||
        isDuplicateUpcCode(nestedOrderRequestItem, orderRequestItem)
      ) {
        totalQuantityTotal += Number(nestedOrderRequestItem.quantity || 0);
        if (
          nestedOrderRequestItem.lastUpdatedProject ||
          nestedOrderRequestItem.id === orderRequestItem.id
        ) {
          currentOrderItem = orderRequestItem;
        } else {
          // duplicateOrderRequestItems.push(nestedOrderRequestItem);
          removeDuplicateOrderIds.push(nestedOrderRequestItem.id || '');
        }
      }
    });

    if (currentOrderItem && currentOrderItem && removeDuplicateOrderIds.length > 0) {
      updateDuplicateOrderItems.push({
        id: currentOrderItem.id,
        quantity: totalQuantityTotal.toString(),
        cost: currentOrderItem.cost,
      });
    }
  });

  return {
    updateDuplicateOrderItems,
    removeDuplicateOrderIds,
  };
}
