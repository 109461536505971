import { isAccessible } from '../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { UserProfileFragment } from '../../auth/graphql/fragments/generated/UserProfile';

export const getInventoryLayoutTabs = (permissions: UserProfileFragment['workspacePermissions']) =>
  [
    {
      label: 'Search',
      value: 'search',
      subject: AllowedPermissionsSubjectEnum.Inventory,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'New',
      value: 'new',
      subject: AllowedPermissionsSubjectEnum.Inventory,
      action: AllowedPermissionActionsEnum.Create,
    },
    {
      label: 'Move',
      value: 'move',
      subject: AllowedPermissionsSubjectEnum.InventoryMove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Remove',
      value: 'remove',
      subject: AllowedPermissionsSubjectEnum.InventoryRemove,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Restock',
      value: 'restock',
      subject: AllowedPermissionsSubjectEnum.InventoryRestock,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Audit',
      value: 'new-inventory-audit',
      subject: AllowedPermissionsSubjectEnum.InventoryAudit,
      action: AllowedPermissionActionsEnum.Create,
    },
  ].filter((tab) => isAccessible(permissions, tab.action, tab.subject));
