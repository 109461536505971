import { ItemLocationSchema, LocationSchema, ShippingContainerSchema } from "app/types/schema";

export type CodeSchema = { isBlank: boolean } & Partial<ItemLocationSchema | LocationSchema | ShippingContainerSchema>;

export const enum LabelStockTitleEnum {
    Avery6792 = 'Avery 6792 (5/8" x 3")',
    Avery6570 = 'Avery 6570/6576 (1-1/4" x 1-3/4")',
    Avery5160 = 'Avery 5160 (1" x 2-5/8")',
    Avery5163 = 'Avery 5163 (10 - 2"x4")',
    OneRoundLabels = '1" Round Labels',
    ThreeRoundLabels = '3/4" Round Labels',
    OneSquareLabels = '1" x 1" Square Labels',
    ThreeSquareLabels = '3/4" x 3/4" Square Labels',
    ZebraPrinter = 'Zebra Printer(LP 2824 Plus)',
  }