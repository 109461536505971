import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';

import { SignUpApiInput } from '../components/SignUpForm/types';

const useApiCreateJoin = () => {
  const [fetchStatus, error, data, execute] = useRest(`${API_URL}/tenant/signup`);

  const isFetching = fetchStatus === FetchingStatus.Fetching;
  const executeAPI = useCallback(
    async (values: SignUpApiInput) =>
      execute({
        body: JSON.stringify({
          name: values.organization?.trim(),
          firstName: values.firstName,
          lastName: values.lastName,
          adminEmailId: values.email,
          locationDetails: {
            address: values.address,
            city: values.city,
            state: values.state,
            zipCode: values.zip,
          },
          primaryPhoneNumber: values.primaryPhoneNumber,
          url: values.url,
          invitedByTenantId: values.invitedByTenantId,
        }),
      }),
    [execute],
  );
  return { fetchingApi: isFetching, error, data, executeApiCreateJoin: executeAPI };
};

export default useApiCreateJoin;
