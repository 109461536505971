import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';

// import { SignUpApiInput } from '../components/SignUpForm/types';

const useSignUpDonorApi = () => {
  const [fetchStatus, error, data, execute] = useRest(`${API_URL}/auth/donor/register`);

  const isFetching = fetchStatus === FetchingStatus.Fetching;
  const executeAPI = useCallback(
    async (values: any) =>
      execute({
        body: JSON.stringify({
          firstName: values.firstName,
          lastName: values.lastName,
          emailId: values.email,
          password: values.password,
          tenantId: values.tenantId,
        }),
      }),
    [execute],
  );
  return { fetchingApi: isFetching, error, data, executeApiCreateJoin: executeAPI };
};

export default useSignUpDonorApi;
