import { useCallback, useState } from 'react';
import { cx } from '@emotion/css';
import { Button, TitleBar } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import Common from 'app/i18n/Common';
import Warehouse from 'app/i18n/Warehouse';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { WarehouseTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import WarehouseLabel from '../WarehouseDetails/components/WarehouseLabel';
import WarehouseLocations from './components/WarehouseLocations';
import WarehouseSetup from './components/WarehouseSetup';
import WarehouseSetupAction from './components/WarehouseSetupAction';
import WarehouseSiteSelector from './components/WarehouseSiteSelector';
import { useSiteWarehouseQuery } from './graphql/queries/generated/siteWarehouse';
import PageTitle from 'app/ui-components/Title';
import { Divider } from '@mui/material';
import WarehouseEditDialog from './components/WarehouseEditDialog';

const WarehouseDetails = () => {
  const [showSetupDialog, setShowSetupDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [siteId, setSiteId] = useState<string | undefined>(undefined);
  const [locationsData, setLocationsData] = useState<string[]>([]);

  const [{ fetching, data }] = useSiteWarehouseQuery({
    variables: { filters: { siteId, warehouseTypes: [WarehouseTypeEnum.ZoneToBin, WarehouseTypeEnum.RackAndShelf] } },
    pause: !siteId,
  });

  const warehouseId = siteId ? data?.warehouses.edges?.[0]?.node.id : undefined;
  const warehouseType = siteId ? data?.warehouses.edges?.[0]?.node.warehouseType : undefined;

  const onSelectItem = useCallback((item: string) => {
    setSelectedItems((prev) => [...prev, item]);
  }, []);

  const handleLocations = useCallback((locations: string[]) => {
    if (locations === locationsData) return;
    setLocationsData(locations);
  }, []);

  const onBack = useCallback(() => {
    setSelectedItems((prev) => prev.slice(0, -1));
  }, []);

  const onEdit = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const onSiteChange = useCallback((nextSiteId: string | undefined) => {
    setSiteId(nextSiteId);
    setSelectedItems([]);
  }, []);

  const renderContent = () => {
    if (fetching) {
      return <Loader />;
    }
    if (warehouseId) {
      return (
        <>
          <Divider className="mt-[24px]" />
          <WarehouseLocations
            selectedItems={selectedItems}
            handleLocations={handleLocations}
            siteId={siteId}
            warehouseId={warehouseId}
            onSelectItem={onSelectItem}
            warehouseType={warehouseType}
          />
        </>
      );
    }

    if (siteId) {
      return (
        <>
          <Divider className="mt-[24px]" />
          <Stack className="py-32" justifyContent="center">
            <WarehouseLabel>{Warehouse.NoZoneAvailable}</WarehouseLabel>
          </Stack>
        </>
      );
    }

    return null;
  };

  const isZoneToBin = warehouseType === WarehouseTypeEnum.ZoneToBin && selectedItems.length < 4;
  const isRackAndShelf = warehouseType === WarehouseTypeEnum.RackAndShelf && selectedItems.length === 0;
  const canEdit = siteId && (isZoneToBin || isRackAndShelf);
  const hasSelectedItems = selectedItems.length > 0;

  return (
    <Box
      className={cx(
        'min-h-[80vh] rounded-tr-[8px] border-grey-300 bg-white px-32 pt-0 pb-16',
        titlebarStyles,
      )}>
      <TitleBar title={Warehouse.Warehouse} />
      <PageTitle title={Warehouse.Warehouse} />
      <Box className="mt-16 ml-16 flex w-[358px] flex-col  gap-[24px]">
        <WarehouseSiteSelector siteId={siteId} onSiteChange={onSiteChange} />
        <Box className="flex justify-start gap-[16px]">
          {siteId && (
            <WarehouseSetupAction onClick={() => setShowSetupDialog(true)} />
          )}

          {canEdit && (
            <Button theme="info" onClick={onEdit} classes="min-w-[90px] h-[44px]">
              {Common.Actions.Edit}
            </Button>
          )}

          {hasSelectedItems && (
            <Button onClick={onBack} classes="min-w-[90px] h-[44px]">
              {Common.Actions.Back}
            </Button>
          )}
        </Box>
      </Box>

      {renderContent()}
      {showSetupDialog && siteId ? (
        <WarehouseSetup
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          siteId={siteId}
          warehouseId={warehouseId}
          warehouseType={warehouseType}
          onClose={() => setShowSetupDialog(false)}
        />
      ) : null}
      {showEditDialog && siteId ? (
        <WarehouseEditDialog
          warehouseType={warehouseType}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          warehouseId={warehouseId}
          onClose={() => setShowEditDialog(false)}
          locationsData={locationsData}
        />
      ) : null}
    </Box>
  );
};

export default WarehouseDetails;
