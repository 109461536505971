import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditSiteDataFragment = { __typename?: 'LocationSchema', id: string, name: string, description?: string | null, locationCode?: string | null, type: Types.LocationTypeEnum, siteStaffIds?: Array<string> | null, siteManagerIds?: Array<string> | null, formattedDescription?: string | null };

export const EditSiteDataFragmentDoc = gql`
    fragment EditSiteData on LocationSchema {
  id
  name
  description
  locationCode
  type
  siteStaffIds
  siteManagerIds
  formattedDescription
}
    `;