import { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Divider, Grid, IconButton, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

interface Props {
  product: any;
  quantity: number;
  setQuantity: (quantity: number) => void;
}

const MarkAsPurchasedForm = (props: Props) => {
  const { product, quantity, setQuantity } = props;

  const allowedQuantity = useMemo(()=>{
    return product.purchasedQuantity ? product.quantity - product.purchasedQuantity : product.quantity;
  }, [product.purchasedQuantity, product.quantity])
  
  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(quantity - 1);
  };

  const handleQuantityChange = (event: any) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0 && newQuantity <= product.quantity) {
      setQuantity(newQuantity);
    }
  };

  return (
    <form className="mark-as-purchase mt-[16px]">
      <Stack direction='column' spacing={2}>
        <Grid container xs={12} lg={12}>
          <Grid item sm={12} lg={3}>
            <Typography className='text-[16px] font-semibold text-grey-900'>
            Product:
            </Typography>
          </Grid>
          <Grid item sm={12} lg={9}>
            <Typography className='text-[16px] text-grey-800'>
            {product.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} lg={12}>
        <Grid item sm={12} lg={3}>
            <Typography className='text-[16px] font-semibold text-grey-900'>
            Quantity:
            </Typography>
          </Grid>
          <Grid item sm={12} lg={9}>
          <IconButton disabled={quantity<=1} onClick={decreaseQuantity}>
              <RemoveIcon />
            </IconButton>
            <TextField
              inputProps={{ min: 1, max: product.quantity }}
              size='small'
              value={quantity}
              variant="outlined"
              onChange={handleQuantityChange}
              sx={{
                maxHeight:'44px',
                maxWidth:'44px',
                '.MuiInputBase-root': {
                  height: '44px !important',
                },
              }}
            />
            <IconButton disabled={quantity >= allowedQuantity} onClick={increaseQuantity}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Stack>
      <Divider className='mt-[16px]'/>
    </form>
  );
};

export default MarkAsPurchasedForm;
