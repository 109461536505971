import { useMemo } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import Orders from 'app/i18n/Orders';
import EntityManager from 'app/modules/components/EntityManager';

import OrderBillToField from './components/OrderBillToField';
import OrderDateField from './components/OrderDateField';
import OrderDeliverToField from './components/OrderDeliverToField';
import OrderDepartmentField from './components/OrderDepartmentField';
import OrderDueDateField from './components/OrderDueDateField';
import OrderRequestorEmailField from './components/OrderRequestorEmailField';
import OrderRequestorNameField from './components/OrderRequestorNameField';
import OrderShipToField from './components/OrderShipToField';
import OrderTitleField from './components/OrderTitleField';
import { UseOrderDeliveryDetailFormReturnType } from './hook/useOrderDeliveryDetailForm';
import { DeliveryDetailInput } from './types';

interface Props {
  onCreateOrder: (values: DeliveryDetailInput) => void;
  onPrevStep: () => void;
  formState: UseOrderDeliveryDetailFormReturnType['formState'];
  handleSubmit: UseOrderDeliveryDetailFormReturnType['handleSubmit'];
  disabled?: boolean;
}

const OrderDeliveryDetailForm = (props: Props) => {
  const { onCreateOrder, formState, handleSubmit, onPrevStep, disabled } = props;

  const onFormSubmit = useMemo(() => handleSubmit(onCreateOrder), [onCreateOrder, handleSubmit]);

  return (
    <div>
      <EntityManager.Title
        documentTitle={Orders.OrderRequestDocumentTitle}
        title={Orders.DeliveryDetails}
      />
      <form className="mt-20 w-[300px] pt-6 md:min-w-[416px] md:max-w-[416px]  lg:min-w-[416px] lg:min-w-[416px] xl:max-w-[416px] xl:max-w-[416px]">
        <OrderDateField disabled={disabled} formState={formState} />
        <OrderRequestorNameField isRequired disabled={disabled} formState={formState} />
        <OrderRequestorEmailField isRequired disabled={disabled} formState={formState} />
        <OrderTitleField disabled={disabled} formState={formState} />
        <OrderDueDateField isRequired disabled={disabled} formState={formState} />
        <OrderShipToField isRequired disabled={disabled} formState={formState} />
        <OrderDepartmentField disabled={disabled} formState={formState} />
        <OrderDeliverToField isRequired disabled={disabled} formState={formState} />
        <OrderBillToField isRequired disabled={disabled} formState={formState} />

        <div className="flex justify-between">
          <div>
            <Button disabled={disabled} onClick={onPrevStep} classes="min-w-[140px] h-[44px]">
              {Orders.Actions.Previous}
            </Button>
          </div>
          <div>
            <Button
              loading={disabled}
              theme="success"
              onClick={onFormSubmit}
              classes="min-w-[140px] h-[44px]">
              {Orders.Actions.SubmitOrder}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderDeliveryDetailForm;
