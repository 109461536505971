import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import Modal from 'app/components/Modal';
import { SnackbarService } from 'app/components/Snackbar';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import Network from 'app/i18n/Network';
import { ASSET_FORM_RULES } from 'app/modules/assets/components/AssetForm/utils';
import { LabelTypeEnum } from 'app/modules/labels/views/Labels/components/Forms/types';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import { useUpdateNetworkAssetMutation } from 'app/modules/network/graphql/mutations/generated/updateNetworkAsset';
import { LocationUserDataType } from 'app/modules/network/types';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

import { UseLocationUserDataReturnType } from '../../../../hook/useNetworkAssetData';
import analytics from 'app/analytics';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  onSavedUser: () => void;
  mac: string;
  formState: UseLocationUserDataReturnType['formState'];
};

const LocationUserFormModal = (props: Props) => {
  const { isOpen, onCancel, onSavedUser, mac, formState } = props;
  const [, onUpdateNetworkAsset] = useUpdateNetworkAssetMutation();


  const { handleSubmit, control, destinationLocationId, destinationSiteId } = formState;


  const onSubmit = useCallback(
    (input: LocationUserDataType) => {
      const updateNetworkAssetInputs = {
        locationId: input.destinationLocationId,
        siteId: input.destinationSiteId,
        email: input.email?.trim(),
        userName: input.userName?.trim(),
      };
      onUpdateNetworkAsset({ input: { ...updateNetworkAssetInputs, mac: mac } }).then(
        (response) => {
          analytics?.track('Edited', { name: 'Network Asset' });
          if (response.data?.createNetworkAsset.success) {
            SnackbarService.show({
              message: Network.SuccessMessages.NetworkAssetUpdated,
            });
            onSavedUser();
          } else {
            console.error('[Update Category] Failed', response);
          }
        },
      );
    },
    [mac, onSavedUser, onUpdateNetworkAsset],
  );

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  return (
    <Modal
      actions={
        <div>
          <Button classes="w-[90px]" theme="success" onClick={onFormSubmit}>
            {Common.Actions.Save}
          </Button>
          <Button classes="ml-[16px] w-[90px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      }
      id="location-user-modal"
      open={isOpen}
      title={Network.UpdateLocationUser}
      onClose={onCancel}>
      <div className="w-[100%] md:w-[560px]">
        <form>
          <Grid container paddingTop={1} rowSpacing={'24px'}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="userName"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    autoFocus
                    isRequired
                    {...field}
                    containerClassName="mt-0"
                    error={fieldState.error}
                    label={Network.FormLabels.Username}
                    placeholder={Network.FormLabels.Username}
                  />
                )}
                rules={NETWORK_ASSET_FORM_RULES.userName}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    containerClassName="mt-0"
                    error={fieldState.error}
                    label={Network.FormLabels.Email}
                    placeholder={Network.FormLabels.Email}
                  />
                )}
                rules={NETWORK_ASSET_FORM_RULES.email}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="destinationSiteId"
                render={({ field, fieldState }) => (
                  <FormSiteSelect
                    {...field}
                    isRequired
                    disabled={false}
                    error={fieldState.error}
                    label={Assets.FormLabels.Site}
                    placeholder={Assets.FormPlaceholders.Site}
                  />
                )}
                rules={ASSET_FORM_RULES.destinationSiteId}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="destinationLocationId"
                render={({ field, fieldState }) => (
                  <FormSiteSelect
                    {...field}
                    isRequired
                    disabled={!destinationSiteId}
                    error={destinationSiteId ? fieldState.error : undefined}
                    label={Assets.FormLabels.Location}
                    placeholder={Assets.FormPlaceholders.Location}
                    selectedLocationValue={destinationLocationId}
                    type={LabelTypeEnum.Location}
                    types={[]}
                    value={destinationSiteId}
                  />
                )}
                rules={ASSET_FORM_RULES.destinationLocationId}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default LocationUserFormModal;
