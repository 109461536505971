import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { CompanyFragmentDoc } from '../../fragments/generated/company';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompnayListQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CompanyFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CompnayListQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', totalCount: number, edges: Array<{ __typename?: 'CompanyEdge', node: { __typename?: 'CompanySchema', companyContactNumber?: string | null, companyEmail?: string | null, companyFax?: string | null, companyName: string, companyWebsite?: string | null, createdAt: string, createdById: string, deletedAt?: string | null, deletedById?: string | null, id: string, isVendor?: boolean | null, sqlId?: number | null, tenantId: string, updatedAt: string, updatedById: string } }> } };


export const CompnayListDocument = gql`
    query compnayList($filters: CompanyFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  companies(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...Company
      }
    }
    totalCount
  }
}
    ${CompanyFragmentDoc}`;

export function useCompnayListQuery(options?: Omit<Urql.UseQueryArgs<CompnayListQueryVariables>, 'query'>) {
  return Urql.useQuery<CompnayListQuery>({ query: CompnayListDocument, ...options });
};