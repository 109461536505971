import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteSitesMutationVariables = Types.Exact<{
  input: Types.DeleteLocationsInput;
}>;


export type DeleteSitesMutation = { __typename?: 'Mutation', deleteLocations: { __typename?: 'MutationResponse', success: boolean, reasons?: Array<string> | null } };


export const DeleteSitesDocument = gql`
    mutation deleteSites($input: DeleteLocationsInput!) {
  deleteLocations(input: $input) {
    success
    reasons
  }
}
    `;

export function useDeleteSitesMutation() {
  return Urql.useMutation<DeleteSitesMutation, DeleteSitesMutationVariables>(DeleteSitesDocument);
};