import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MarkContainerAsDeliveredMutationVariables = Types.Exact<{
  input: Types.MarkShippingTransactionsOfContainerIdsAsDeliveredInput;
}>;


export type MarkContainerAsDeliveredMutation = { __typename?: 'Mutation', markShippingTransactionsOfContainerIdsAsDelivered: { __typename?: 'MutationResponse', success: boolean } };


export const MarkContainerAsDeliveredDocument = gql`
    mutation markContainerAsDelivered($input: MarkShippingTransactionsOfContainerIdsAsDeliveredInput!) {
  markShippingTransactionsOfContainerIdsAsDelivered(input: $input) {
    success
  }
}
    `;

export function useMarkContainerAsDeliveredMutation() {
  return Urql.useMutation<MarkContainerAsDeliveredMutation, MarkContainerAsDeliveredMutationVariables>(MarkContainerAsDeliveredDocument);
};