import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type EditCategoryDataFragment = { __typename?: 'CategorySchema', id: string, name: string, description?: string | null, categoryCode?: string | null, categoryManagerIds: Array<string>, formattedDescription?: string | null };

export const EditCategoryDataFragmentDoc = gql`
    fragment EditCategoryData on CategorySchema {
  id
  name
  description
  categoryCode
  categoryManagerIds
  formattedDescription
}
    `;