import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { cx } from '@emotion/css';
import { uniqBy } from 'lodash';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  AsyncSelectOption,
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import FormLabel from 'app/components/Form/FormLabel';
import useDebounce from 'app/hooks/useDebounce';
import usePagination from 'app/hooks/usePagination';
import Reports from 'app/i18n/Reports';
import {
  ItemLocationItemTypeEnum,
  ItemTypeEnum,
  TransactionEntityTypeEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { useSkuSelectQuery } from '../../graphql/queries/generated/skuSelect';
import { adaptNodeEdgeToOption } from './utils';

interface SelectProps {
  className?: string;
  label?: string;

  value: string[];
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  type?: string;
  types?: TransactionEntityTypeEnum[];
}

const ITEM_PER_PAGE = 20;

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;
  const [{ fetching, data }] = useSkuSelectQuery({
    pause,
    variables: { filters: { categoryIds: value } },
    requestPolicy: 'network-only',
  });

  const values = useMemo(
    () => data?.items.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.items.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (
  props: UseSelectQueryProps & { types?: ItemTypeEnum[] },
): UseSelectQueryReturn => {
  const [options, setOptions] = useState<AsyncSelectOption[]>([]);

  const { inputValue, activePage, types } = props;
  const searchValue = useDebounce(inputValue);

  const {
    data: optionsData,
    fetching: isLoading,
    onNextPage,
  } = usePagination(
    useSkuSelectQuery,
    {
      filters: {
        search: searchValue,
        types: types || [],
      },
    },
    {
      edgeKey: 'items',
      pageSize: ITEM_PER_PAGE,
    },
  );

  useEffect(() => {
    if (activePage === 0) {
      setOptions(optionsData?.items?.edges?.map(adaptNodeEdgeToOption) || []);
    } else {
      setOptions(
        uniqBy(
          [...options, ...(optionsData?.items?.edges?.map(adaptNodeEdgeToOption) || [])],
          (item) => item?.node?.id || item?.value,
        ),
      );
    }
  }, [optionsData?.items?.edges]);

  return { isLoading: isLoading, options, onNextPage };
};

const FormSKUSelect = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const { className, label, type, value, onBlur, onChange, types, ...rest } = props;

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <AsyncMultiSelect
        {...rest}
        paginated
        limitTags={-1}
        queryVariables={{ types }}
        useSelectQuery={useSelectQuery}
        useSelectedValue={useSelectedValue}
        value={value}
        onChange={onChange}
      />
    </Box>
  );
});

interface Props {
  control: any;
  type?: string;
  types?: TransactionEntityTypeEnum[];
  name?: string;
}

const ReportSKUSelect = (props: Props) => {
  const { control, name = 'skuIds', types } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormSKUSelect
          {...field}
          className="flex-1"
          label={Reports.SKU}
          types={types}
        />
      )}
    />
  );
};

export default ReportSKUSelect;
