import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import NetworkAssets from 'app/modules/network/view/networkAssets';

function NetworkAssetsPage() {
  return (
    <NetworkAssetLayout>
      <NetworkAssets />
    </NetworkAssetLayout>
  );
}

export default NetworkAssetsPage;
