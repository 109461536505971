import _ from 'lodash';
import { nanoid } from 'nanoid';

import { OrderRequestInput } from '../../OrderRequestForm/type';

export function findOrderItemById(orderItems: OrderRequestInput[], id: string) {
  return _.find(orderItems, (item) => item.id === id);
}
export function findOrderItemIndexById(orderItems: OrderRequestInput[], id: string) {
  return _.findIndex(orderItems, (item) => item.id === id);
}

export function getUpdatedOrderItem(
  orderItem: OrderRequestInput,
  nextState: Partial<OrderRequestInput>,
) {
  return _.cloneDeep({
    ...orderItem,
    ...nextState,
  });
}

export function updateItem(
  orderItems: OrderRequestInput[],
  id: string,
  nextOrderItemState: Partial<OrderRequestInput>,
) {
  return _.map(orderItems, (orderItem) => {
    if (id && orderItem?.id === id) {
      return getUpdatedOrderItem(orderItem, {
        ...nextOrderItemState,
        lastUpdatedProject: _.keys(nextOrderItemState).includes('projectId'),
      });
    } else {
      return getUpdatedOrderItem(orderItem, {
        lastUpdatedProject: false,
      });
    }
  });
}

export function updateItems(
  orderItems: OrderRequestInput[],
  nextOrderItemState: Partial<OrderRequestInput>,
) {
  return _.map(orderItems, (orderItem) => {
    return {
      ...orderItem,
      ...nextOrderItemState,
    };
  });
}

export function updateItemsById(
  orderItems: OrderRequestInput[],
  nextUpdatedOrderItems: Partial<OrderRequestInput>[],
) {
  return _.map(orderItems, (orderItem) => {
    const nextOrderItem = _.find(
      nextUpdatedOrderItems,
      (nextUpdatedOrderItem) => nextUpdatedOrderItem.id === orderItem.id,
    );

    if (nextOrderItem) {
      return {
        ...orderItem,
        ...nextOrderItem,
      };
    }
    return orderItem;
  });
}

export function removeOrderItems(
  orderItems: OrderRequestInput[],
  ids: string[],
): OrderRequestInput[] {
  return _.filter(orderItems, (orderItem) => !!(orderItem.id && !ids.includes(orderItem.id)));
}

export function findIndexesByIds(orderItems: OrderRequestInput[], ids: string[]) {
  const indexes: number[] = [];

  _.forEach(ids, (id) => {
    const orderItemId = _.findIndex(orderItems, (orderItem) => orderItem.id === id);
    if (orderItemId !== -1) {
      indexes.push(orderItemId);
    }
  });
  return indexes;
}

export function createOrderItem(orderItems: OrderRequestInput[], orderItem: OrderRequestInput) {
  return [
    ...orderItems,
    {
      id: nanoid(),
      lastUpdatedProject: false,
      ...orderItem,
    },
  ];
}

export function cloneOrderItems(orderItems: OrderRequestInput[]) {
  return _.cloneDeep(orderItems);
}
