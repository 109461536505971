import { Grid } from '@mui/material';

import WishlistStepper from '../WishlistSepper';
import { getWishlistFormSteps } from './utils';

interface Props {
  activeStep: number;
}

const WishlistFormSteps = (props: Props) => {
  const { activeStep } = props;
  return (
    <div className="mb-[6px] bg-white p-[20px]">
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          <WishlistStepper activeStep={activeStep} steps={getWishlistFormSteps()} />
        </Grid>
      </Grid>
    </div>
  );
};

export default WishlistFormSteps;
