import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import usePagination from 'app/hooks/usePagination';
import Label from 'app/i18n/Label';
import Stack from 'app/ui-components/Stack';

import useLabelsFrom from '../../context/useLabelsFrom';
import { useLabelShippingTableQuery } from '../../graphql/queries/generated/LabelShippingTable';
import useLabelsTable from '../../hooks/useLabelsTable';
import LabelsTable from '../LabelsTable';
import TableHeader from '../TableHeader';
import { getLabelsShippingTableColumns } from './utils/columns';

interface Props {}

type containerData = {
  id: string;
  isReusable: boolean;
  containerId: string;
  destinationSiteId: string;
  tenantId: string;
  destinationSite: {
    id: string;
    name: string;
  };
};

const LabelShippingTable = (props: Props) => {
  const [containerData, setContainerData] = useState<containerData[]>([]);
  const { values, trigger, setIsCustomFieldValid } = useLabelsFrom();
  const { search, table, onPrintLabels } = useLabelsTable({ values, setIsCustomFieldValid });

  useEffect(() => {
    setContainerData([]);
    table.setState({ selectedRowIds: [] });
  }, [values?.siteId]);

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useLabelShippingTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        destinationSiteIds: values && values.siteId ? [values.siteId] : [],
      },
    },
    { edgeKey: 'shippingContainers', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    const row = data?.shippingContainers.edges?.map(({ node }) => node) || [];
    setContainerData(uniqBy([...containerData, ...row], (row) => row.id));
    return row;
  }, [data?.shippingContainers.edges]);

  useEffect(() => {
    const row = containerData.filter((row) => table.state.selectedRowIds.includes(row.id));
    table.setState({ selectedRowData: row });
  }, [table.state.selectedRowIds]);

  const handlePrintLabels = async () => {
    const isValid = trigger && (await trigger());
    if (isValid) {
      onPrintLabels();
    }
  };

  return (
    <Stack direction="col">
      <LabelsTable
        pagination
        actions={table.actions}
        columns={getLabelsShippingTableColumns()}
        data={rows}
        loading={fetching}
        setState={table.setState}
        state={table.state}
        total={data?.shippingContainers.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onPrintLabels={handlePrintLabels}
        onReset={onReset}
        filterNode={<TableHeader title={Label.Shipping.SiteSearch} onChange={search.onChange} />}
        tableType="Shipping"
      />
    </Stack>
  );
};

export default LabelShippingTable;
