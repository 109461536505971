/* eslint-disable sort-keys */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog } from '@procurenetworks/procure-component-library';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import {
  CompanySchema,
  ContactSchema,
  CreateCompanyInput,
  CreateContactInput,
} from 'app/types/schema';

import handleCheckDuplicate from '../../utils/handleCheckDuplicate';
import CompanyForm from '../companyForm';
import ContactForm from '../contactForm';
import DuplicateForm from '../duplicateForm';

type WrapperProps = RouteComponentProps<{ id: string }> & {
  id?: string;
  form: string;
  hideActions?: boolean;
  isContactModal?: boolean;
  onCloseModal?: () => void;
  setIsDuplicateContact?: any;
  addressOpen?: boolean;
  setAddressOpen?: any;
};
const DuplicateFormWrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [dataDuplicate, setDataDuplicate] = useState<
    CreateCompanyInput | CreateContactInput | null
  >(null);
  const [oldData, setOldData] = useState<CreateCompanyInput | CreateContactInput | null>(null);
  const [openPopupDuplicate, setOpenPopupDuplicate] = useState(false);
  const [isEditDuplicate, setIsEditDuplicate] = useState(false);
  const {
    form,
    hideActions,
    id,
    onCloseModal,
    setIsDuplicateContact,
    isContactModal,
    match,
    addressOpen,
    setAddressOpen,
  } = props;
  const urlId = match.params.id;

  useEffect(() => {
    if (urlId) {
      _handleCloseDuplicate();
    }
  }, [urlId]);

  const _handleCloseDuplicate = useCallback(() => {
    setIsEditDuplicate(false);
    setIsDuplicate(false);
    setOpenPopupDuplicate(false);
    if (setIsDuplicateContact) {
      setIsDuplicateContact(false);
    }
  }, [setOpenPopupDuplicate]);

  const _handleReviewDuplicate = useCallback(() => {
    setIsDuplicate(true);
    setOpenPopupDuplicate(false);
  }, [setIsDuplicate, setOpenPopupDuplicate]);

  const _handleCheckDuplicate = useCallback(
    (
      newData: CreateCompanyInput | CreateContactInput,
      checkType: string,
      dataList: Partial<CompanySchema | ContactSchema>[],
    ): boolean => {
      const keyList =
        form === 'Company' ? Contact.DuplicateCompanyKeys : Contact.DuplicateContactKeys;
      const isDuplicate = handleCheckDuplicate(dataList, newData, keyList, checkType);
      if (isDuplicate) {
        setOldData(isDuplicate as CreateCompanyInput | CreateCompanyInput);
        setDataDuplicate(newData);
        setOpenPopupDuplicate(true);
        if (setIsDuplicateContact) {
          setIsDuplicateContact(true);
        }
      }
      return isDuplicate ? true : false;
    },
    [form],
  );

  return (
    <>
      {isDuplicate && dataDuplicate && oldData ? (
        <DuplicateForm
          form={form}
          handleCancelDuplicate={_handleCloseDuplicate}
          hideActionsEdit={false}
          isContactModal={isContactModal}
          isDuplicate={isDuplicate}
          isEditDuplicate={isEditDuplicate}
          newData={dataDuplicate}
          oldData={oldData}
          onCloseModal={onCloseModal}
        />
      ) : form === 'Company' ? (
        <CompanyForm
          handleCloseDuplicate={_handleCloseDuplicate}
          hideActionsEdit={true}
          setIsEditDuplicate={setIsEditDuplicate}
          onCheckDuplicate={_handleCheckDuplicate}
        />
      ) : (
        <ContactForm
          hideActions={hideActions}
          hideActionsEdit={true}
          id={id}
          isContactModal={isContactModal}
          addressOpen={addressOpen}
          setAddressOpen={setAddressOpen}
          setIsEditDuplicate={setIsEditDuplicate}
          onCheckDuplicate={_handleCheckDuplicate}
          onCloseModal={onCloseModal}
        />
      )}
      <Dialog
        actions={
          <>
            <Button theme="success" onClick={_handleReviewDuplicate}>
              Review Duplicate
            </Button>
            <Button onClick={_handleCloseDuplicate}>Cancel</Button>
          </>
        }
        id="duplicate-company-dialog"
        open={openPopupDuplicate}
        title="Duplicate?"
        onClose={_handleCloseDuplicate}>
        <h4 className="text-14 py-[20px] font-medium text-grey-800">
          This may be a duplicate. How would you like to proceed?
        </h4>
      </Dialog>
    </>
  );
};

export default withRouter(DuplicateFormWrapper);
