import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type AssetModelDataFragment = { __typename?: 'AssetModelItemSchema', id: string, sku: string, title: string, mName: string, modelNumber?: string | null, description?: string | null, formattedDescription?: string | null, brand?: string | null, manufacturerId?: string | null, unitCost?: number | null, costOverride?: number | null, pickableThroughOrderRequest?: boolean | null, externalProductCodes?: Array<{ __typename?: 'ItemExternalProductCodeSchema', type: Types.ItemExternalProductCodeTypeEnum, code: string }> | null, category?: { __typename?: 'CategorySchema', id: string, status: Types.CategoryStatusEnum } | null, vendor?: { __typename?: 'CompanySchema', id: string, status: Types.CompanyStatusEnum } | null, attachments?: Array<{ __typename?: 'MediaSchema', size?: number | null, url?: string | null, mimeType?: string | null, kind: Types.MediaKindEnum, resolutions: Array<{ __typename?: 'ResolutionType', type: Types.ResolutionEnum, url: string }> }> | null };

export const AssetModelDataFragmentDoc = gql`
    fragment AssetModelData on AssetModelItemSchema {
  id
  sku
  externalProductCodes {
    type
    code
  }
  title
  mName
  modelNumber
  category {
    id
    status
  }
  description
  formattedDescription
  vendor {
    id
    status
  }
  brand
  manufacturerId
  unitCost
  costOverride
  attachments {
    size
    url
    mimeType
    kind
    resolutions {
      type
      url
    }
  }
  pickableThroughOrderRequest
}
    `;