import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Box } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import Receivables from 'app/i18n/Receivables';
import { getCurrentDateInGMTMinus7 } from 'app/modules/assets/utils/dto';
import FormCompanySelect from 'app/modules/contacts/components/FormCompanySelect';

import Common from '../../../../../../../i18n/Common';

const ScheduleReceiveFormStep2 = (props: any) => {
  const { control, handleSubmit, createReceivables, onPrevious, setVendor, isLoadingOrderedItems } =
    props;

  const today = useMemo(() => {
    return getCurrentDateInGMTMinus7();
  }, []);

  return (
    <div className="mt-20">
      <form onSubmit={handleSubmit(createReceivables)}>
        <Box className="flex flex-col gap-[24px]">
          <Controller
            control={control}
            name="carrier"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled={isLoadingOrderedItems}
                error={fieldState.error}
                label={Receivables.FormLabels.carrier}
              />
            )}
          />
          <Controller
            control={control}
            name="trackingNumber"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled={isLoadingOrderedItems}
                error={fieldState.error}
                label={Receivables.FormLabels.trackingNumber}
              />
            )}
          />
          <Controller
            control={control}
            name="vendorId"
            render={({ field, fieldState }) => (
              <FormCompanySelect
                {...field}
                disabled={isLoadingOrderedItems}
                error={fieldState.error}
                label={Receivables.FormLabels.vendor}
                onChange={(value: any, valueObj: any) => {
                  field.onChange(value);
                  setVendor({ companyName: valueObj?.companyName });
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="dueDate"
            render={({ field, fieldState }) => (
              <FormDatePickerInput
                {...field}
                disabled={isLoadingOrderedItems}
                key="dueDate"
                clearable
                classes="mt-6"
                error={fieldState.error}
                label={Receivables.FormLabels.estimateDueDate}
                minDate={today}
              />
            )}
          />
        </Box>
        <div className="mt-[24px] flex flex-auto justify-between gap-[16px]">
          <Button classes="min-w-[94px] h-[44px]" onClick={onPrevious}>
            {' '}
            {Common.Actions.Previous}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ScheduleReceiveFormStep2;
