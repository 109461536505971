import { useCallback, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { onOperationComplete } from 'app/modules/assetsInventory/utils/utils';
import EntityManager from 'app/modules/components/EntityManager';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';

import Inventory from '../../../../i18n/Inventory';
import { AllowedPermissionsSubjectEnum, ItemTypeEnum } from '../../../../types/schema';
import ImageViewDialog from '../../../components/ImageViewDialog';
import EditInventoryItemsModal from './components/EditInventoryItemsModal';
import InventoryItemsTable from './components/InventoryItemsTable';
import { useDeleteInventoryItemsMutation } from './graphql/mutations/generated/deleteInventoryItems';
import { useDeleteInventoryKitItemsMutation } from './graphql/mutations/generated/deleteInventoryKitItems';
import useInventoryState from './hook/useInventoryState';
import useInventoryTable from './hook/useInventoryTable';
import useSearchSuggestion from './hook/useSearchSuggestion';
import analytics from 'app/analytics';

function Inventories(props: RouteComponentProps) {
  const {
    state,
    filters,
    setState,
    onCloseImageViewDialog,
    imagePreviewState,
    showImageViewDialog,
    siteId,
  } = useInventoryState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [{ fetching: deletingInventoryItems }, executeDeleteInventoryItems] =
    useDeleteInventoryItemsMutation();
  const [, executeDeleteInventoryKitItems] = useDeleteInventoryKitItemsMutation();

  const { searchSuggestions, setSearchValue } = useSearchSuggestion({
    siteId,
  });

  const onDelete = useCallback(
    (ids: string[], itemData?: any[]) => {
      const _itemData = itemData ? itemData[0] : {};
      if (_itemData && _itemData.type === ItemTypeEnum.InventoryKit) {
        executeDeleteInventoryKitItems({
          input: {
            inventoryKitItemId: ids[0] || '',
          },
        })
          .then((response) => {
            analytics?.track('Deleted', { name: 'Inventory Kit' });
            onOperationComplete({
              response,
              message: Inventory.SuccessMessages.InventoryKitDeleted,
              error: '[Delete Inventory Kit] Failed',
              operation: 'deleteInventoryKitItem',
            });
          })
          .catch((e) => {
            console.log('[Delete Inventory Kit] Failed', e);
          });
      } else {
        executeDeleteInventoryItems({
          input: {
            itemIds: ids || [],
          },
        })
          .then((response) => {
            analytics?.track('Deleted', { name: 'Inventory' });
            onOperationComplete({
              response,
              message: Inventory.SuccessMessages.InventoryDeleted,
              error: '[Delete Inventory] Failed',
              operation: 'deleteInventoryItems',
            });
          })
          .catch((e) => {
            console.log('[Delete Inventory] Failed', e);
          });
      }
    },
    [executeDeleteInventoryKitItems, executeDeleteInventoryItems],
  );

  const { tableState, table, search } = useInventoryTable({
    siteId,
    state,
    onDelete,
  });

  return (
    <>
      <EntityManager
        className="rounded-bl-[12px] rounded-br-[12px] !p-0"
        subject={AllowedPermissionsSubjectEnum.Inventory}>
        <Box className="px-[24px]">
          <EntityManager.Title
            hasTopDivider
            documentTitle={Inventory.InventorySearch}
            title={Inventory.InventorySearch}
          />
        </Box>

        <InventoryItemsTable
          fetching={deletingInventoryItems}
          filterNode={
            <Box className="flex !w-[100%] flex-wrap gap-[16px] md:gap-[24px]">
              <EntityManager.Search
                {...search}
                isDropdown
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
              />
              <FormSiteSelect
                enabledAllOption
                className="w-[374px] lg:w-[180px]"
                currentSelectedOption={selectedOption}
                value={filters.site.value}
                onChange={(value, option) => {
                  filters.site.onChange(value);
                  setSelectedOption(option);
                }}
              />
            </Box>
          }
          filters={filters}
          paginationWrapper="p-[24px] flex-wrap md:gap-[24px]"
          setState={setState}
          showImageViewDialog={showImageViewDialog}
          siteId={siteId}
          state={state}
          table={table}
          tableState={tableState}
        />
      </EntityManager>

      <EditInventoryItemsModal
        filters={filters}
        open={state.editInventoryItemsModal}
        setState={setState}
        state={state}
        tableState={table.state}
      />

      <ImageViewDialog
        id="inventory-image-view"
        imageUrl={imagePreviewState.imageUrl}
        open={imagePreviewState.open}
        onClose={onCloseImageViewDialog}
      />
    </>
  );
}

export default withRouter(Inventories);
