import { nanoid } from 'nanoid';
import { ItemSchema, OrderRequestItemSchema, ShippingTransactionSchema } from 'app/types/schema';

import { OrderItemsQuery } from '../../graphql/queries/generated/orderItems';

export const prepareTableRows = (
  items: OrderItemsQuery,
  orderRequestItems: OrderRequestItemSchema[],
  row: ShippingTransactionSchema,
) => {
  return row?.items?.map((item: any) => {
    const _item = items?.items?.edges?.find((i) => (i?.node as ItemSchema)?.id === item?.id);
    const orderItem = orderRequestItems?.find((i) => i?.id === item?.id);

    if (orderItem) {
      return { ..._item?.node, ...orderItem, quantity: item?.quantity, _id: nanoid() };
    } else {
      return { ..._item?.node, quantity: item?.quantity, _id: nanoid() };
    }
  });
};
