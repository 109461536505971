import { css } from '@emotion/css';

const expandIconStyles = css`
  &.Mui-expanded {
    transform: rotate(90deg) !important;
  }
`;

export const accordionRootStyles = css`
  border: 1px solid #e5e7eb;
  border-radius: 0.4rem !important;

  ::before {
    background: transparent;
    content: unset !important;
  }
`;

export const accordionSummaryClasses = {
  content: '!my-12',
  expandIconWrapper: expandIconStyles,
};

export const accordionDetailsClasses = {
  root: 'flex flex-col',
};
