import WarningProvider from 'app/components/WarningAlert';

import AssetsLayout from '../../modules/assets/layout';
import RemoveAssets from '../../modules/assets/views/RemoveAssets';

function RemoveAssetsPage() {
  return (
    <AssetsLayout>
      <WarningProvider>
        <RemoveAssets />
      </WarningProvider>
    </AssetsLayout>
  );
}

export default RemoveAssetsPage;
