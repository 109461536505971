import { useMemo } from 'react';

import {
  LocationFilterQueryVariables,
  useLocationFilterQuery,
} from '../../../inventory/graphql/queries/generated/locationFilter';
import { adaptLocation, getLocationName } from '../../../locations/utils/location';

interface Props {
  variables: LocationFilterQueryVariables;
  selectedLocationId?: string;
}

const useLocations = (props?: Props) => {
  const { variables, selectedLocationId } = props || {};

  const siteId = variables?.filters?.siteId || undefined;

  const [{ data, fetching }] = useLocationFilterQuery({
    variables: variables,
  });

  const items = useMemo(() => {
    return (
      data?.locations?.edges?.map(({ node }) => {
        if (siteId) {
          return adaptLocation(siteId, node);
        }
        return node;
      }) || []
    );
  }, [data?.locations?.edges]);

  const selectedLocation = useMemo(() => {
    return items.find((location) => location.id === selectedLocationId);
  }, [selectedLocationId, items]);

  return {
    items,
    selectedLocation,
    fetching,
  };
};

export type UseLocationsReturnType = ReturnType<typeof useLocations>;
export default useLocations;
