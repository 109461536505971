import AdminLayout from 'app/components/AdminLayout';
import AddOrganization from 'app/modules/organizations/views/AddOrganization';

function AddOrganizationPage() {
  return (
    <AdminLayout>
      <AddOrganization />
    </AdminLayout>
  );
}

export default AddOrganizationPage;
