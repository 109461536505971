import React from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import FormLabel from 'app/components/Form/FormLabel';
import { Size } from 'app/types/button';
import { CategorySchema, SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import { useCategoriesSelectQuery } from '../../graphql/queries/generated/categoriesSelect';

interface Props {
  className?: string;
  selectInputClass?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  showInlineError?: boolean;
  size?: Size;
  name?: string;
}

type Option = Pick<CategorySchema, 'id' | 'name'>;

const getOptionLabel = (option: any) => option.name;

const FormCategorySelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    label,
    isRequired,
    disabled,
    value,
    onBlur,
    onChange,
    error,
    placeholder = '',
    selectInputClass = 'mt-6',
    showInlineError = false,
    size,
    name
  } = props;

  const [inputValue, setInputValue] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [{ fetching: isDisabled, data: selectedData }] = useCategoriesSelectQuery({
    variables: { filters: { categoryIds: value ? [value] : [] } },
    pause: !value || Boolean(value && selectedValue && selectedValue?.id === value),
  });

  useEffect(() => {
    const selectedOptions = selectedData?.categories.edges.map(({ node }) => node);

    if (selectedOptions) {
      setSelectedValue(selectedOptions.length ? selectedOptions[0] : null);
    }
  }, [selectedData?.categories.edges]);

  useEffect(() => {
    if (value === '') {
      setSelectedValue(null);
    }
  }, [value]);

  const [{ fetching: isLoading, data: optionsData }] = useCategoriesSelectQuery({
    variables: {
      filters: { search: inputValue },
      limit: 10000,
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
  });

  const options = useMemo<any>(
    () => optionsData?.categories.edges.map(({ node }) => node) || [],
    [optionsData?.categories.edges],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, options: any) => {
      setSelectedValue(options as Option);
      onChange?.(options?.id || '');
    },
    [onChange],
  );

  const onInputValueChange = useCallback(
    (event: React.SyntheticEvent, value: string, reason: string) => {
      if (reason !== 'reset') {
        setInputValue(value);
      }
    },
    [setInputValue],
  );

  const handleOnBlur = useCallback(
    (event: any) => {
      setInputValue('');
      // onBlur?.();
    },
    [onBlur, setInputValue],
  );

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}

      <Autocomplete
        className={`rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none ${selectInputClass}`}
        disabled={isDisabled || disabled}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id;
        }}
        label={''}
        loading={isLoading}
        options={options}
        renderInput={(params) => (
          <TextField name={name} {...params} error={!isEmpty(error)} placeholder={placeholder} />
        )}
        size={size && size}
        sx={{
          '&.MuiAutocomplete-root > div': {
            display: 'flex',
            alignItems: 'center',
          },
          '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
            position: 'absolute !important',
            marginLeft: 'auto',
          },
          '&.MuiAutocomplete-root .MuiOutlinedInput-input': {
            width: 'calc(100% - 32px) !important',
            minWidth: '20px',
          },
          '&.MuiAutocomplete-root MuiDataGrid-columnHeader': {
            minWidth: '60px !important',
          }
        }}
        value={selectedValue}
        onBlur={handleOnBlur}
        onChange={onValueChange}
        onInputChange={onInputValueChange}
      />
      {!showInlineError && !isEmpty(error) && <FormError error={error} />}
    </Box>
  );
});

export default FormCategorySelect;
