import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { useShippingTransactionsTableQuery } from 'app/modules/ship/graphql/queries/generated/shippingTransactionsTable';
import { ShippingTransactionSchema } from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

const useSearchSuggestion = (options?: { siteId?: string[] }) => {
  const { siteId } = options || {};

  const [searchedValue, setSearchedValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();
  const [{ data }] = useShippingTransactionsTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        destinationSiteIds: siteId?.length ? siteId : [],
      },
    },
  });

  useEffect(() => {
    const result = data?.shippingTransactions?.edges?.map((a) => {
      const item = a.node as ShippingTransactionSchema;

      const { result, searchBy } = processSearch(
        ['containerId', 'orderRequest.orderRequestCode'],
        searchedValue,
        item,
      );

      return {
        label: searchBy,
        id: item.id,
        value: result,
      };
    });

    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
