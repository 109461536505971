import {
  CreateExternalOrderRequestInput,
  CreateExternalOrderRequestItemInput,
  CreateOrderRequestInput,
  CreateOrderRequestItemInput,
  ItemTypeEnum,
  OrderRequestItemTypeEnum,
  OrderRequestTypeEnum,
} from '../../../../../types/schema';
import { DeliveryDetailInput } from '../../../components/OrderDeliveryDetailForm/types';
import { ORDER_INSERT_TYPE, OrderRequestInput } from '../components/OrderRequestForm/type';
import _ from 'lodash';
import { getCurrentUTCTime } from '../../../../assets/utils/dto';
import { DateFormatEnum, formatDate } from '../../../../../utils/date';
function getOrderItemTypeFromItemTypeAndItemInStock(
  itemType: ORDER_INSERT_TYPE | undefined,
  itemInStock: any,
): OrderRequestItemTypeEnum {
  if (itemType === ORDER_INSERT_TYPE.ITEM_IN_STOCK) {
    switch (itemInStock.type) {
      case ItemTypeEnum.Asset:
        return OrderRequestItemTypeEnum.Asset;
      case ItemTypeEnum.AssetKit:
        return OrderRequestItemTypeEnum.AssetKit;
      case ItemTypeEnum.Inventory:
        return OrderRequestItemTypeEnum.Inventory;
      case ItemTypeEnum.InventoryKit:
        return OrderRequestItemTypeEnum.InventoryKit;
      default:
        return OrderRequestItemTypeEnum.NoSku;
    }
  }
  return OrderRequestItemTypeEnum.NoSku;
}

function parseOrderRequestItemsForInternalOrder(
  orderItems: OrderRequestInput[],
): CreateOrderRequestItemInput[] {
  return _.map(orderItems, (orderItem) => {
    const {
      cost,
      description,
      id,
      imageUrl,
      itemId,
      itemInStock,
      itemType,
      projectId,
      quantity,
      upcCode,
      website,
    } = orderItem;

    return {
      cost: cost ? Number(cost) : 0,
      description: description || undefined,
      imageUrl: imageUrl?.[0]?.url || undefined,
      itemId: itemId || undefined,
      projectId: projectId || undefined,
      quantity: quantity ? Number(quantity) : 0,
      type: getOrderItemTypeFromItemTypeAndItemInStock(itemType, itemInStock),
      categoryId: itemInStock?.categoryId || undefined,
      upcCode: upcCode || undefined,
      website: website || undefined,
    };
  });
}

export function createInternalOrderDto(data: {
  deliveryDetails: DeliveryDetailInput;
  orderRequestItems: OrderRequestInput[];
}): CreateOrderRequestInput {
  const { deliveryDetails, orderRequestItems } = data;
  const { billToSiteId, deliverToId, departmentId, destinationSiteId, dueDate, title } =
    deliveryDetails;

  return {
    title: title || undefined,
    billToSiteId,
    deliverToId,
    departmentId: departmentId || undefined,
    destinationSiteId,
    dueDate: getCurrentUTCTime(formatDate(dueDate, DateFormatEnum.UTC)),
    type: OrderRequestTypeEnum.Internal,
    items: parseOrderRequestItemsForInternalOrder(orderRequestItems),
  };
}

function parseOrderRequestItemsForExternalOrder(
  orderItems: OrderRequestInput[],
): CreateExternalOrderRequestItemInput[] {
  return orderItems.map((orderItem) => {
    const { cost, description, itemId, itemInStock, itemType, projectId, quantity } = orderItem;

    return {
      cost: cost ? Number(cost) : 0,
      description: description || undefined,
      projectId: projectId || undefined,
      quantity: quantity ? Number(quantity) : 0,
      type: getOrderItemTypeFromItemTypeAndItemInStock(itemType, itemInStock),
      categoryIdOfItemInParentTenant: itemInStock?.categoryId || undefined,
      itemIdInParentTenant: itemId,
    };
  });
}

export function createExternalOrderDto(data: {
  deliveryDetails: DeliveryDetailInput;
  orderRequestItems: OrderRequestInput[];
  partnerTenantId: string;
}): CreateExternalOrderRequestInput {
  const { deliveryDetails, orderRequestItems, partnerTenantId } = data;
  const { billToSiteId, deliverToId, departmentId, destinationSiteId, dueDate, title } =
    deliveryDetails;

  return {
    title: title || undefined,
    billToSiteId,
    deliverToId,
    departmentId: departmentId || undefined,
    destinationSiteId,
    dueDate: getCurrentUTCTime(formatDate(dueDate, DateFormatEnum.UTC)),
    parentTenantId: partnerTenantId,
    type: OrderRequestTypeEnum.External,
    items: parseOrderRequestItemsForExternalOrder(orderRequestItems),
  };
}
