import React, { forwardRef, HTMLInputTypeAttribute, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { InputBaseComponentProps } from '@mui/material';
import { TextField } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';

import { Maybe } from '../../../types/schema';
import FormLabel from '../FormLabel';

export interface FormTextInputProps {
  className?: string;
  label?: string;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string> | Maybe<number>;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: string, ...args: any) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError | undefined;
  placeholder?: string;
  multiline?: boolean;
  rows?: string | number;
  disabled?: boolean;
  autoFocus?: boolean;
  size?: 'small' | 'medium';
  getInputReference?: (input: HTMLElement) => void;
  inputProps?: InputBaseComponentProps;
  endAdornment?: React.FC<any> | undefined;
  onSearchIconClick?: () => void;
  withSearchIcon?: boolean;
  cleanInputIcon?: boolean;
  textAlign?: 'left' | 'right';
  formLabel?: string;
}

const FormTextInput = forwardRef<HTMLDivElement, FormTextInputProps>(
  (props: FormTextInputProps, ref) => {
    const {
      value,
      onBlur,
      label,
      onChange,
      error,
      className = 'mt-6 !border-[0px]',
      isRequired,
      rows,
      multiline,
      disabled,
      placeholder,
      size,
      autoFocus,
      inputProps,
      endAdornment,
      cleanInputIcon,
      withSearchIcon,
      getInputReference,
      onSearchIconClick,
      textAlign,
      onFocus,
      formLabel,
    } = props;

    const _value = value == null || value == undefined ? "" : value;

    const [selectedValue, setSelectedValue] = useState<string | number>(_value);
    const [focused, setIsFocused] = useState<boolean>(autoFocus || false);

    useEffect(() => {
      if (value !== selectedValue) {
        setSelectedValue(_value);
      }
    }, [value]);

    const onInputChange = useCallback(
      (
        event:
          | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
          | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      ) => {
        const targetElement = (event as React.ChangeEvent).target as HTMLInputElement;
        const nextValue = targetElement?.value;
        onChange?.(nextValue, event);
      },
      [onChange, setSelectedValue],
    );

    const onInputBlur = useCallback(
      (_event: any) => {
        setIsFocused(false);
        if (onBlur) {
          onBlur();
        }
      },
      [onBlur],
    );

    const LabelNode = label
      ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
      : undefined;

    const handleFocus = () => {
      setIsFocused(true);
      onFocus?.();
    };

    return (
      <>
        {formLabel ? (
          <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
            {formLabel}
          </FormLabel>
        ) : null}
        <div
          className={
            multiline
              ? `rounded-[8px] border p-[2px] ${focused ? 'border-blue-900' : 'border-grey-500'}
               ${disabled ? 'bg-grey-200' : ''}`
              : ''
          }>
          <TextField
            ref={ref}
            InputLabelProps={{
              shrink: true,
            }}
            {...props}
            autoFocus={autoFocus}
            className={clsx(className || '', '!border-[0px] capitalize')}
            cleanInputIcon={cleanInputIcon}
            disabled={!!disabled}
            endAdornment={endAdornment}
            error={!!error}
            errorMessage={error ? (error.message as string) : ''}
            getInputReference={getInputReference}
            inputProps={{
              ...inputProps,
              style: {
                resize: `${!!multiline && !disabled ? 'block' : 'none'}`,
                overflow: multiline ? `auto` : 'hidden',
              } as React.CSSProperties,
            }}
            label={label || ''}
            labelNode={LabelNode}
            multiline={!!multiline}
            placeholder={placeholder}
            rows={rows || 0}
            size={size || 'medium'}
            sx={{
              '& .MuiOutlinedInput-root': {
                border: `${!!multiline && '0px !important'}`,
                paddingLeft: '0px',
                paddingRight: '0px',
              },
            }}
            textAlign={textAlign}
            value={_value}
            withSearchIcon={withSearchIcon}
            onBlur={onInputBlur}
            onChange={onInputChange}
            onCleanInputIconClick={() => {
              onChange?.('');
            }}
            onFocus={handleFocus}
            onKeyDown={(event: any) => {
              event.stopPropagation();
            }}
            onSearchIconClick={onSearchIconClick}
          />
        </div>
      </>
    );
  },
);

export default FormTextInput;
