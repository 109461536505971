import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NewAuditReportSiteQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type NewAuditReportSiteQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string } }> } };


export const NewAuditReportSiteDocument = gql`
    query newAuditReportSite($filters: LocationFilters, $limit: Float) {
  locations(filters: $filters, limit: $limit) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

export function useNewAuditReportSiteQuery(options?: Omit<Urql.UseQueryArgs<NewAuditReportSiteQueryVariables>, 'query'>) {
  return Urql.useQuery<NewAuditReportSiteQuery>({ query: NewAuditReportSiteDocument, ...options });
};