import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Loader from 'app/components/Loader';
import useShop from 'app/modules/shop/context/useShop';
import Stack from 'app/ui-components/Stack';

import AffiliateMerchants from '../../components/AffiliateMerchants';
import ProductsTable from '../../components/ProductsTable';
import SearchBox from '../../components/SearchBox';

const ShopContainer = () => {
  const [isShowProducts, setIsShowProducts] = useState(false);
  const { loading, search } = useShop();

  useEffect(() => {
    if (search.length === 0 || !search) {
      setIsShowProducts(false);
    }
  }, [search, setIsShowProducts]);

  return (
    <>
      <Stack className="mt-24" direction="col" justifyContent="between">
        <Typography className={'text-[14px] font-semibold text-grey-900 '}>
          Save More. Serve More.
        </Typography>
        <Typography className="mt-[8px] mb-24 text-[14px] font-medium text-grey-800">
          By using these vendors, you’re generating financial support for your organization.
        </Typography>
        <SearchBox setIsShowProducts={setIsShowProducts} />
        {loading ? <Loader /> : isShowProducts ? <ProductsTable /> : <AffiliateMerchants />}
      </Stack>
    </>
  );
};

export default ShopContainer;
