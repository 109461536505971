import AdminLayout from 'app/components/AdminLayout';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import Partners from 'app/modules/partners/views/Partners';

function PartnersPage() {

  return (
    <AdminLayout>
      <Partners />
    </AdminLayout>
  );
}

export default PartnersPage;
