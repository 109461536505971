import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

function useItemsToBeReceivedForm() {
  const { formState, reset, watch, control, handleSubmit, clearErrors } = useForm({
    defaultValues: {
      carrier: '',
      trackingNumber: '',
      vendorId: '',
      dueDate: '',
      notes: '',
    },
  });

  const onReset = useCallback(() => {
    reset({
      carrier: '',
      trackingNumber: '',
      vendorId: '',
      dueDate: '',
      notes: '',
    });
  }, []);

  return {
    formState,
    reset,
    watch,
    control,
    handleSubmit,
    onReset,
    clearErrors,
  };
}

export default useItemsToBeReceivedForm;
