import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import useDebounce from '../../../../../hooks/useDebounce';
import { ItemTypeEnum, TransactionEntityTypeEnum } from '../../../../../types/schema';
import { FormItemLocationOption } from '../../../../locations/components/FormItemLocationSelect/types';
import useItemInStockData from '../../../hook/useItemInStockData';
import { getItemInStockIdFromParams } from '../../../utils/itemInStockParams';
import { OtherReason } from '../../components/RemoveForm/utils';

const DEFAULT_VALUES = {
  itemInStockId: '',
  itemInStockSearch: '',
  itemInStock: null,
  sourceSiteId: '',
  sourceLocationId: '',
  reason: '',
  reasonMessage: '',
  quantity: '0',
};

interface State {
  sourceSite?: FormItemLocationOption | null;
  sourceLocation?: FormItemLocationOption | null;
}

const initialState = {
  sourceSite: null,
  sourceLocation: null,
};

const useRemoveForm = (transactionType: TransactionEntityTypeEnum) => {
  const queryParamsItemInStockId = getItemInStockIdFromParams();
  const { control, watch, setValue, handleSubmit, reset, register, unregister, trigger } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      itemInStockId: queryParamsItemInStockId || '',
    },
  });

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const itemInStockId = watch('itemInStockId');
  const itemInStockSearch = watch('itemInStockSearch');
  const debouncedItemInStockSearch = useDebounce(itemInStockSearch);
  const sourceSiteId = watch('sourceSiteId');
  const sourceLocationId = watch('sourceLocationId');
  const reason = watch('reason');
  const quantity = watch('quantity');

  const itemInStockTypes = useMemo(() => {
    if (transactionType === TransactionEntityTypeEnum.Asset) {
      return [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit];
    } else if (transactionType === TransactionEntityTypeEnum.Inventory) {
      return [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit];
    }

    return [];
  }, [transactionType]);

  const { itemInStock } = useItemInStockData(itemInStockId || '', itemInStockTypes);

  const availableQuantity = useMemo(() => {
    return state?.sourceLocation?.availableQuantity;
  }, [state?.sourceLocation]);

  const totalQuantity = useMemo(() => {
    return state?.sourceLocation?.totalQuantity;
  }, [state?.sourceLocation]);

  useEffect(() => {
    if (availableQuantity !== undefined) {
      register('quantity');
    }
  }, [availableQuantity]);

  useEffect(() => {
    if (itemInStock) {
      setValue('sourceLocationId', '');
      setValue('sourceSiteId', '');
    }
  }, [itemInStock]);

  useEffect(() => {
    if (itemInStock) {
      setValue('sourceLocationId', '');
    }
  }, [sourceSiteId]);

  const isOtherReason = useMemo(() => {
    return reason === OtherReason;
  }, [reason]);

  useEffect(() => {
    if (isOtherReason) {
      register('reasonMessage');
    } else {
      unregister('reasonMessage');
    }
  }, [isOtherReason, register, unregister]);

  const onResetForm = useCallback(() => {
    reset({
      ...DEFAULT_VALUES,
    });
    setState({
      ...initialState,
    });
  }, [reset, setState]);

  return {
    formState: {
      control,
      itemInStockId,
      sourceSiteId,
      itemInStock,
      availableQuantity,
      totalQuantity,
      reason,
      quantity,
      itemInStockSearch,
      debouncedItemInStockSearch,
      isOtherReason,
      setState,
    },
    state,
    itemInStock,
    handleSubmit,
    onResetForm,
  };
};

export type UseRemoveFormReturnType = ReturnType<typeof useRemoveForm>;

export default useRemoveForm;
