import Wishlist from 'app/i18n/Wishlist';
import EntityManager from 'app/modules/components/EntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';

import Settings from './components';

const WishlistSettings = () => {
  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Wishlist}>
      <EntityManager.Title
        documentTitle={Wishlist.Settings.Review}
        title={Wishlist.Settings.Settings}
      />
      <Settings />
    </EntityManager>
  );
};

export default WishlistSettings;
