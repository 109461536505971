import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import Assets from 'app/i18n/Assets';

import { AssetModelFieldProps } from '../../type';

const AssetModelSkuField = (props: AssetModelFieldProps) => {
  let { formState } = props;
  const { id, control } = formState;

  if (!id) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="sku"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isDisabled
            isRequired
            error={fieldState.error}
            label={Assets.FormLabels.SKU}
          />
        )}
      />
    </div>
  );
};

export default AssetModelSkuField;
