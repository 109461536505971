import React from 'react';
import { Box, Grid } from '@mui/material';
import { useAccessControl } from 'app/components/AccessControl';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { WidgetCard } from 'app/modules/network/components/WidgetCard';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import { UseOperatingSystemDataReturnType } from '../../hook/useOperatingSystemData';

interface Props {
  fetching: boolean;
  operatingSystems: UseOperatingSystemDataReturnType['operatingSystems'];
  history: any;
}

const OperatingSystems = (props: Props) => {
  const { fetching, operatingSystems, history } = props;
  const { workspacePermissions } = useCurrentUser();

  const canAccessDiscoveredAssets = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.ItAssetDiscoveryAsset,
  );

  return (
    <>
      {!fetching ? (
        <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
          <Grid container spacing={3}>
            {operatingSystems?.length &&
              operatingSystems.map((item, index) => {
                const isOther = operatingSystems.length - 1 === index && canAccessDiscoveredAssets;
                return (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    style={{ cursor: isOther ? 'pointer' : 'default' }}
                    xs={12}
                    onClick={() => isOther && history.push(routes.NetworkDiscoveredAssets())}>
                    <WidgetCard
                      key={index}
                      index={index}
                      title={item.value || ''}
                      total={item.count || 0}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OperatingSystems;
