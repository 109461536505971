import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import NetworkDashboard from 'app/modules/network/view/dashboard';

function NetworkDashboardPage() {
  return (
    <NetworkAssetLayout>
      <NetworkDashboard />
    </NetworkAssetLayout>
  );
}

export default NetworkDashboardPage;
