import {
  arrayValidator,
  composeValidators,
  emptyNumberValidator,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Shop from 'app/i18n/Shop';

export const AFFILIATE_MERCHANT_FORM_RULES = {
  name: {
    validate: textValidator(Shop.Admin.FormValidationMessages.NameRequired),
  },

  affiliateUrl: {
    validate: textValidator(Shop.Admin.FormValidationMessages.AffiliateUrlRequired),
  },

  domain: {
    validate: textValidator(Shop.Admin.FormValidationMessages.DomainRequired),
  },

  logoUrl: {
    validate: arrayValidator(Shop.Admin.FormValidationMessages.LogoUrlRequired),
  },

  commission: {
    validate: composeValidators(
      emptyNumberValidator(Shop.Admin.FormValidationMessages.CommissionInvalid),
      numberValidator(Shop.Admin.FormValidationMessages.CommissionInvalid),
      numberMinValidator(Shop.Admin.FormValidationMessages.CommissionInvalid, 0),
      numberMaxValidator(Shop.Admin.FormValidationMessages.CommissionInvalid, 100),
    ),
  },
};
