import { MediaKindEnum } from '../../../types/schema';
import { APP_CONFIG } from 'app/consts/config';

export const getMediaKind = (fileType?: string) => {
  if (!fileType) {
    throw new Error('File Type Required [Error in GetMediaKind) ');
  }

  if (fileType.includes('image')) {
    return MediaKindEnum.Image;
  }

  if (fileType.includes('video')) {
    return MediaKindEnum.Video;
  }

  if (fileType.includes('audio')) {
    return MediaKindEnum.Audio;
  }

  if (fileType.includes('application')) {
    return MediaKindEnum.File;
  }

  return MediaKindEnum.File;
};

export function parseFileName(fileName: string) {
  return fileName.replaceAll(/[^\\x00-\\x7F.]|[&\\/\\#,\-+()$~%'":*?<>{}=]/gi, '_');
}

export function getFileNameFromFileUrl(fileUrl: string) {
  const url = new URL(fileUrl);
  return parseFileName(url.pathname);
}

export async function fileUrlToBlob(fileUrl: string) {
  try {
    const imageFetchUrl = `${APP_CONFIG.apiUrls.corsProxy}/image?imageUrl=${fileUrl}`;
    const response = await fetch(imageFetchUrl);
    return await response.blob();
  } catch (error) {
    console.error('[fileUrlToBlob]', { error });
    return null;
  }
}
