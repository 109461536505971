import capitalize from 'lodash/capitalize';
import { TransactionStatusEnum } from 'app/types/schema';

export const TransactionStatus = {
  [TransactionStatusEnum.Completed]: 'Completed',
  [TransactionStatusEnum.Deleted]: 'Deleted',
  [TransactionStatusEnum.InTransit]: 'In Transit',
  [TransactionStatusEnum.Pending]: 'Pending',
  [TransactionStatusEnum.Expired]: 'Expired',
  [TransactionStatusEnum.PartiallyCompleted]: 'PARTIALLY_COMPLETED',
  [TransactionStatusEnum.Reserved]: 'Reserved',
  [TransactionStatusEnum.InCustody]: 'In Custody',
  [TransactionStatusEnum.CheckedOut]: 'Checked Out',
  [TransactionStatusEnum.Overdue]: 'Overdue',
};

export const getTransactionStatusLabel = (status: TransactionStatusEnum) => {
  return TransactionStatus[status] || capitalize(status);
};
