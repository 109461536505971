import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteOrganizationMutationVariables = Types.Exact<{
  input: Types.DeleteTenantInput;
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteTenant: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($input: DeleteTenantInput!) {
  deleteTenant(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteOrganizationMutation() {
  return Urql.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument);
};