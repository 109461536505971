import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import { ReserveTransactionEntityInput } from '../../../../../../types/schema';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import useReserveForm from '../useReserveForm';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

type AssetReserveRow = {
  id: string;
  sku: string;
  title: string;
  destinationName: string;
  departmentName: string;
  quantity: number;
  user: string;
  startDate: Date | string;
  endDate: Date | string;
  transaction: ReserveTransactionEntityInput;
};

const useReserveState = () => {
  const { table } = useEntityManager({
    selection: false,
  });
  const [rows, setRows] = useState<AssetReserveRow[]>([]);

  const transactions = useMemo(() => {
    return rows.map((row) => row.transaction);
  }, [rows]);

  const { formState, state, onResetForm, itemInStock } = useReserveForm({});

  const createTransaction = useCallback(
    (values: any) => {
      const {
        destinationSiteId,
        destinationLocationId,
        departmentId: departmentIdValue,
        actorId,
        meta,
        quantity: qty,
        startDate,
        endDate,
      } = values;

      const { destinationSite, department, user } = state;

      const quantity = Number(qty);
      const departmentId =
        departmentIdValue && departmentIdValue !== '-1' ? departmentIdValue : null;

      const formattedStartDate = moment(startDate).startOf('day').toISOString();
      const formattedEndDate = moment(endDate).endOf('day').toISOString();

      const updatedMetaDetails = {
        note: removeExtraSpacesAndNewlines(meta.note),
        formattedNote: meta.note,
      };

      return {
        id: `asset-reserve-row-${nanoid()}`,
        sku: itemInStock?.sku || '',
        title: itemInStock?.title || '',
        destinationName: destinationSite?.name || '',
        departmentName: department?.label || '',
        quantity,
        user: user.label || '',
        startDate: startDate ? formatDate(startDate, DateFormatEnum.Date) : '',
        endDate: endDate ? formatDate(endDate, DateFormatEnum.Date) : '',
        transaction: {
          actorId,
          departmentId,
          destinationLocationId,
          destinationSiteId,
          meta: updatedMetaDetails,
          quantity,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          entityId: itemInStock?.id || '',
        },
      } as AssetReserveRow;
    },
    [state, itemInStock],
  );

  const onAddCheckOutRow = useCallback(
    (values: any) => {
      const reserveRow = createTransaction(values);

      setRows([...rows, reserveRow]);
      onResetForm();
    },
    [rows, setRows, createTransaction, onResetForm],
  );

  const onFormSubmit = useCallback(
    (values: any) => {
      onAddCheckOutRow(values);
    },
    [onAddCheckOutRow],
  );

  const resetTableAndForm = useCallback(() => {
    setRows([]);
    onResetForm();
  }, [onResetForm, setRows]);

  const onRemoveRow = useCallback(
    (rowId: string) => {
      setRows(rows.filter((row) => row.id !== rowId));
    },
    [rows, setRows],
  );

  return {
    formState,
    table,
    rows,
    onFormSubmit,
    count: rows.length,
    resetTableAndForm,
    transactions,
    onRemoveRow,
    itemInStock,
  };
};

export type UseReserveStateReturnType = ReturnType<typeof useReserveState>;

export default useReserveState;
