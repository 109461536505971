import './order-detail-to-print.css';
import React, { forwardRef, useMemo, useContext } from 'react';
import moment from 'moment';
import Orders from 'app/i18n/Orders';
import { getCostWithCurrency } from 'app/utils/cost';

import { useAccessControl } from '../../../../components/AccessControl';
import { FeatureFlagEnum, useFeatureFlag } from '../../../../libs/featureFlag';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestItemSchema,
  OrderRequestItemTypeEnum,
  OrderRequestTypeEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import { getLeastItemStatusLabel } from '../../views/OrderHistory/components/OrderStatusTableFilter/utils';
import OrderRequestItemImageView from '../OrderRequestItemImageView';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';

interface Props {
  orderDetail: OrderDetailsData;
}

const OrderDetailToPrint = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { orderDetail } = props;
  const { isParentTenantOrderView, isChildTenantOrderView } = useContext(OrderDetailContext);

  const {
    orderRequestItems,
    createdBy,
    title,
    orderRequestCode,
    id,
    createdAt,
    dueDate,
    department,
    destinationSite,
    billToSite,
    type,
    deliverTo,
  } = orderDetail;

  const { value: isProjectFieldEnabled } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );

  const { workspacePermissions } = useCurrentUser();

  const canViewAsset = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderAsset,
  );

  const canViewInventory = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderInventory,
  );

  const canViewNoSku = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.View,
    AllowedPermissionsSubjectEnum.OrderNoSku,
  );

  const filteredOrderRequestItems = useMemo(() => {
    return orderRequestItems.filter((orderRequestItem) => {
      switch (orderRequestItem.type) {
        case OrderRequestItemTypeEnum.Asset:
          return canViewAsset;
        case OrderRequestItemTypeEnum.AssetKit:
          return canViewAsset;
        case OrderRequestItemTypeEnum.Inventory:
          return canViewInventory;
        case OrderRequestItemTypeEnum.InventoryKit:
          return canViewInventory;
        case OrderRequestItemTypeEnum.NoSku:
          return canViewNoSku;
        default:
          return false;
      }
    });
  }, [orderRequestItems, canViewAsset, canViewInventory, canViewNoSku]);

  const renderProjectField = useMemo(
    () => isProjectFieldEnabled && (type === OrderRequestTypeEnum.Internal || (type === OrderRequestTypeEnum.External && !isParentTenantOrderView)),
    [type, isProjectFieldEnabled]
  );

  const logo_url = `${process.env.PUBLIC_URL}/images/logo.png`;

  const getPageMargins = () => {
    return `@page { margin: 20px 20px 20px 20px !important; }`;
  };

  return (
    <section ref={ref} className="order-detail">
      <style>{getPageMargins()}</style>
      <div className="row pt-3 pb-2">
        <div className="col-sm-12">
          <img alt="" src={logo_url} style={{ height: '45px' }} />
        </div>
      </div>
      <div className="row pt-3 pb-2">
        <div className="col-sm-12">
          <div className="order-detail-heading">{Orders.OrderDetails}</div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.OrderId}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label> {orderRequestCode}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.OrderTitle}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label> {title || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.OrderDate}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{moment.parseZone(createdAt).format('MM/DD/Y')}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.DueDate}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{moment.parseZone(dueDate).format('MM/DD/Y')}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.Name}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{createdBy?.name || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.Email}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{createdBy?.emailId || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.Department}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{department?.name || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.ShipTo}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{destinationSite?.name || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.DeliverTo}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{deliverTo?.name || '--'}</label>
          </div>
        </div>

        <div className="col-sm-12 pt-2">
          <div className="order-detail-title">{Orders.FormLabels.BillTo}</div>
          <span className="order-detail-spacer" />
          <div className="order-detail-value pl-3">
            <label>{billToSite?.name || '--'}</label>
          </div>
        </div>
      </div>

      <div className="row pt-3 pb-2 pt-5">
        <div className="col-sm-12">
          <h4 className="order-detail-heading order-detail-heading-sub">{Orders.Items}</h4>
        </div>
        <div className="col-sm-12 order-detail-items">
          <table className="custom-table-bordered table">
            <thead>
              <tr>
                <th scope="col">{Orders.FormLabels.Image}</th>
                <th scope="col">{Orders.FormLabels.ItemDescription}</th>
                <th scope="col">{Orders.FormLabels.Title}</th>
                {renderProjectField ? <th scope="col">{Orders.FormLabels.Project}</th> : null}
                <th scope="col">{Orders.FormLabels.Quantity}</th>
                <th scope="col">{Orders.FormLabels.CostEach}</th>
                <th scope="col">{Orders.FormLabels.Status}</th>
              </tr>
            </thead>

            <tbody>
              {filteredOrderRequestItems.map(
                (orderRequestItem: OrderRequestItemSchema, index: number) => {
                  const {
                    id,
                    imageUrl,
                    item,
                    upcCode,
                    website,
                    description,
                    project,
                    quantity,
                    cost,
                    status,
                  } = orderRequestItem;

                  return (
                    <tr key={`${id}-${index}`}>
                      <td>
                        <OrderRequestItemImageView orderRequestItem={orderRequestItem} />
                      </td>
                      <td className="order-detail-description">
                        {item?.sku || upcCode || website || description}
                      </td>
                      <td>{item?.title}</td>
                      {renderProjectField ? <td>{project?.name || ''}</td> : null}
                      <td align="right">{quantity}</td>
                      <td align="right">
                        {cost === undefined || cost === null
                          ? ''
                          : `${getCostWithCurrency((cost as unknown as number) || 0)}`}
                      </td>
                      <td>{getLeastItemStatusLabel(status)}</td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
});

export default OrderDetailToPrint;
