import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UndeleteAssetMutationVariables = Types.Exact<{
  input: Types.UndeleteItemsInput;
}>;


export type UndeleteAssetMutation = { __typename?: 'Mutation', undeleteAssetItems: { __typename?: 'UndeleteItemsPayload', successCount: number, failedCount: number } };


export const UndeleteAssetDocument = gql`
    mutation undeleteAsset($input: UndeleteItemsInput!) {
  undeleteAssetItems(input: $input) {
    successCount
    failedCount
  }
}
    `;

export function useUndeleteAssetMutation() {
  return Urql.useMutation<UndeleteAssetMutation, UndeleteAssetMutationVariables>(UndeleteAssetDocument);
};