import { memo } from 'react';
import { OrderRequestItemSchema } from 'app/types/schema';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import OrderRequestItemDescriptionView from '../OrderRequestItemDescriptionPreview';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import OrderRequestItemDescriptionEditView from '../OrderRequestItemDescriptionEditView';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
  valueKey: string;
}

const OrderRequestItemDescription = (props: Props) => {
  const { orderRequestItem, state, actions, valueKey } = props;
  const { orderView, orderDetail } = state;
  const { isOrderRequestItemEditable } = useOrderEditPermissionCheck({
    orderDetail,
  });

  if (orderView === ORDER_VIEW.EDIT_ORDER_VIEW && isOrderRequestItemEditable(orderRequestItem)) {
    return (
      <OrderRequestItemDescriptionEditView
        orderRequestItem={orderRequestItem}
        state={state}
        actions={actions}
        valueKey={valueKey}
      />
    );
  }

  return (
    <OrderRequestItemDescriptionView orderRequestItem={orderRequestItem} valueKey={valueKey} />
  );
};

export default memo(OrderRequestItemDescription);
