function getPermissionDescription(permissions: string[]) {
  if (permissions.length === 1) {
    return `${permissions.length} Permission`;
  }

  if (permissions.length) {
    return `${permissions.length} Permissions`;
  }

  return 'No Permissions';
}

export default getPermissionDescription;
