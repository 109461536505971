import { UseAssetReminderStateReturnType } from '../../hook/useAssetReminderState';
import AssetReminderForm from '../AssetReminderForm';
import { AssetReminderInput } from '../AssetReminderForm/types';

interface Props {
  onSubmit: (values: any, id: string) => void;
  onDelete: (id: string) => void;
  reminders: AssetReminderInput[];
  disabled?: boolean;
  deleting?: boolean;
  isEditMode?: boolean;
  itemId?: number | string;
  loading?: boolean;
  state: UseAssetReminderStateReturnType['state'];
  setState: UseAssetReminderStateReturnType['setState'];
  isRemindersViewMode?: boolean;
}

const AssetReminderList = (props: Props) => {
  const {
    onSubmit,
    onDelete,
    state,
    setState,
    reminders,
    itemId,
    disabled,
    deleting,
    loading,
    isEditMode,
    isRemindersViewMode,
  } = props;

  return (
    <>
      {reminders?.length > 0 ? (
        reminders?.map((reminder, index) => {
          const _loading = loading && (reminder as any)?.id === itemId;
          const _deleting = deleting && (reminder as any)?.id === itemId;
          return (
            <AssetReminderForm
              key={(reminder as any)?.id}
              defaultValues={reminder}
              deleting={_deleting}
              disabled={disabled || isRemindersViewMode}
              hideClose={index !== 0}
              isEditMode={isEditMode}
              isRemindersViewMode={isRemindersViewMode}
              loading={_loading}
              mode={(reminder as any)?.mode || 'view'}
              setState={setState}
              state={state}
              onDelete={onDelete}
              onSubmit={onSubmit}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default AssetReminderList;
