import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { WarningService } from 'app/components/WarningAlert';
import Inventory from 'app/i18n/Inventory';

import {
  RestockTransactionEntityInput,
  TransactionEntityTypeEnum,
} from '../../../../../types/schema';
import useEntityManager from '../../../../components/EntityManager/useEntityManager';
import useRestockForm from '../useRestockForm';

interface RestockFormValues {
  itemInStockId: string;
  destinationSiteId: string;
  destinationLocationId: string;
  quantity: string;
}

interface Props {
  transactionType: TransactionEntityTypeEnum;
}

const useRestockItemState = (props: Props) => {
  const { transactionType } = props;

  const [rows, setRows] = useState<any[]>([]);

  const { table } = useEntityManager({
    selection: false,
  });

  const onRemoveTransaction = useCallback(
    (rowId: number) => {
      setRows(rows.filter((row) => row.id !== rowId));
    },
    [setRows, rows],
  );

  const { formState, handleSubmit, state, onResetForm, itemInStock } =
    useRestockForm(transactionType);

  const transactions = useMemo(() => {
    return rows.map((row) => row.transaction);
  }, [rows]);

  const createRestockItemRow = useCallback(
    (values: any) => {
      const { itemInStockId, destinationLocationId, quantity, destinationSiteId } =
        values as RestockFormValues;

      const qty = Number(quantity);

      return {
        transaction: {
          destinationLocationId: destinationLocationId || state?.destinationLocation?.id,
          quantity: qty,
          destinationSiteId,
          entityId: itemInStockId,
          entityType: itemInStock?.type as unknown as TransactionEntityTypeEnum,
        } as RestockTransactionEntityInput,
        sku: itemInStock?.sku || '',
        title: itemInStock?.title || '',
        source: transactionType === TransactionEntityTypeEnum.Asset ? 'New Asset' : 'New Inventory',
        destinationSiteName: state?.destinationSite?.name || '',
        destinationLocationName: state?.destinationLocation?.name || '',
        id: `restock-transaction-${nanoid()}`,
        quantity: qty,
      };
    },
    [transactionType, rows, state, itemInStock],
  );

  const onSubmit = useCallback(
    (values: any) => {
      const restockTransaction = createRestockItemRow(values);
      const updatedRows = [...rows];
      const rowIndex = updatedRows.findIndex((row) => {
        const { transaction } = row;
        return (
          transaction.entityId === restockTransaction.transaction.entityId &&
          transaction.destinationLocationId ===
            restockTransaction.transaction.destinationLocationId &&
          transaction.destinationSiteId === restockTransaction.transaction.destinationSiteId
        );
      });
      if (rowIndex !== -1) {
        const newQuantity = updatedRows[rowIndex].quantity + restockTransaction.quantity;
        WarningService.showWarning({
          message: Inventory.FormValidationMessages.DuplicateCartItem(newQuantity),
          onConfirm: () => {
            updatedRows[rowIndex] = { ...restockTransaction, quantity: newQuantity };
            setRows(updatedRows);
            onResetForm();
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        updatedRows.push(restockTransaction);
        setRows(updatedRows);
        onResetForm();
      }
    },
    [setRows, createRestockItemRow, onResetForm, transactionType, rows, state, itemInStock],
  );

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onResetFormAndTable = useCallback(() => {
    onResetForm();
    setRows([]);
  }, [onResetForm]);

  return {
    formState,
    onFormSubmit,
    table,
    rows,
    tableState: {
      table,
      rows,
      onRemoveTransaction,
      onResetFormAndTable,
      totalRows: rows.length,
    },
    onRemoveTransaction,
    totalRows: rows.length,
    onResetFormAndTable,
    transactions,
  };
};

export type UseRestockItemStateReturnType = ReturnType<typeof useRestockItemState>;
export default useRestockItemState;
