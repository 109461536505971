import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteProjectsMutationVariables = Types.Exact<{
  input: Types.DeleteProjectsInput;
}>;


export type DeleteProjectsMutation = { __typename?: 'Mutation', deleteProjects: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteProjectsDocument = gql`
    mutation deleteProjects($input: DeleteProjectsInput!) {
  deleteProjects(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteProjectsMutation() {
  return Urql.useMutation<DeleteProjectsMutation, DeleteProjectsMutationVariables>(DeleteProjectsDocument);
};