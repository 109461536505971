import { useMemo } from 'react';
import { Box } from '@mui/material';
import EntityManager from 'app/modules/components/EntityManager';

import { getReceivingHistoryItemTypeColumn } from '../../utils/columns';
import useReceivingHistoryForItems from '../hook/useReceivingHistoryForItems';

type Props = {
  receivableId: string;
};
const ReceivableItemsTable = ({ receivableId }: Props) => {
  const { table, rows, rowCount, fetching, onPrevPage, onNextPage, onReset } =
    useReceivingHistoryForItems({ receivableId });

  const columns = useMemo(() => getReceivingHistoryItemTypeColumn(), []);

  return (
    <div className="ml-[60px] mb-[20px] mt-[16px] max-w-[90%]">
      <EntityManager className="rounded-bl-[12px] rounded-br-[12px] !p-0">
        <Box className="px-[16px] md:px-[24px] lg:px-[24px]">
          <EntityManager.Table
            ignoreRelayPagination
            actions={table.actions}
            columns={columns}
            data={rows}
            loading={fetching}
            minWidth={0}
            paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
            persistKey="receive_history_table"
            setState={table.setState}
            state={table.state}
            testId="receive_history_table"
            total={rowCount}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
          />
        </Box>
      </EntityManager>
    </div>
  );
};

export default ReceivableItemsTable;
