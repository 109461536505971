import { camelCase, toInteger } from 'lodash';
import { toTitleCase } from 'app/modules/reports/utils';
import {
  DistictValueSchema,
  NetworkAssetStatusEnum,
  NetworkAssetTypeEnum,
  NetworkAssetWarrantyTypeEnum,
} from 'app/types/schema';

export const bytesToGB = (bytes: any) => {
  if (bytes && isNumber(bytes)) {
    return (bytes / 1073741824).toFixed(2) + ' GB';
  } else {
    return 'Not Available';
  }
};

export const bytesToGBNumber = (bytes: any): number => {
  if (bytes && typeof bytes === 'number') {
    return parseFloat((bytes / 1073741824).toFixed(2));
  } else {
    return 0;
  }
};

export const GBToBytes = (gb: number): number => {
  if (gb) {
    return toInteger(gb * 1073741824);
  } else {
    return 0;
  }
};

const isNumber = (value: any) => {
  return typeof value === 'number' && Number.isInteger(value) && value >= 0;
};

export const arraySort = (arr: DistictValueSchema[]) => {
  return arr.sort((a: any, b: any) => {
    if (a.value === null || a.value === '') {
      return 1;
    } else if (b.value === null || b.value === '') {
      return -1;
    } else {
      return 0;
    }
  });
};

export const defaultGridColumn = { xs: 12, sm: 12, md: 12, lg: 12 };

export const warrantyStatusFromEnum = (warrantyStatus: string) => {
  switch (warrantyStatus) {
    case 'expired':
      return 'Warranty Expired';
    case 'inWarranty':
      return 'Under Warranty';
    case 'expiringSoon':
      return 'Expiring Soon';
    case 'noWarranty':
      return 'No Warranty Added';
    default:
      return 'No Warranty Added';
  }
};

export const warrantyStatusOptions = [
  { value: NetworkAssetWarrantyTypeEnum.Expired, label: 'Warranty Expired' },
  {
    value: NetworkAssetWarrantyTypeEnum.ExpiringSoon,
    label: 'Expiring Soon',
  },
  {
    value: NetworkAssetWarrantyTypeEnum.InWarranty,
    label: 'Under Warranty',
  },
  {
    value: NetworkAssetWarrantyTypeEnum.NoWarranty,
    label: 'No Warranty Added',
  },
];

export const deviceTypeOptions = [
  { value: NetworkAssetTypeEnum.Laptop, label: 'Laptop' },
  {
    value: NetworkAssetTypeEnum.Desktop,
    label: 'Desktop',
  },
  {
    value: NetworkAssetTypeEnum.Mobile,
    label: 'Mobile',
  },
  {
    value: NetworkAssetTypeEnum.Tablet,
    label: 'Tablet',
  },
  {
    value: NetworkAssetTypeEnum.Other,
    label: 'Other',
  },
];

export const statusOptions = [
  { value: NetworkAssetStatusEnum.Active, label: 'Installed' },
  {
    value: NetworkAssetStatusEnum.Notinstalled,
    label: 'Non-Installed',
  },
];

export const getItAssetStatusType = (status?: NetworkAssetStatusEnum[]) => {
  if (!status || status.length === 0) {
    return ['notInstalled', 'active'];
  }

  const result: string[] = [];

  if (status.includes(NetworkAssetStatusEnum.Notinstalled)) {
    result.push('notInstalled');
  }

  if (status.includes(NetworkAssetStatusEnum.Active)) {
    result.push('active');
  }

  return result;
};

export const getItAssetWarrantyType = (warrantyType?: NetworkAssetWarrantyTypeEnum[]) => {
  return warrantyType?.map(string => {
    return camelCase(string)
  });
};

export const getItAssetDeviceType = (deviceType?: NetworkAssetTypeEnum[]) => {
  return deviceType?.map(string => {
    return toTitleCase(string)
  });
};
