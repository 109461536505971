import { Maybe } from 'app/types/schema';

import {
  composeValidators,
  duplicateValidator,
  emailValidator,
  emptyStringValidator,
  textMaxLengthValidator,
  textValidator,
} from '../../../../components/Form/utils/validators';
import Contact from '../../../../i18n/Contact';

export const COMPANY_FORM_RULES = {
  companyName: (isDuplicate: boolean) =>{
    return {
      validate: composeValidators(
        textValidator(Contact.FormValidationMessages.CompanyNameRequired),
        textMaxLengthValidator(Contact.FormValidationMessages.CompanyNameRequired, 30),
        duplicateValidator(Contact.FormValidationMessages.companyNameDuplicate, isDuplicate)
      ),
    }
  },
  website:(isDuplicate: boolean) => {
    return {
      validate: duplicateValidator(Contact.FormValidationMessages.companyWebsiteDuplicate, isDuplicate)
    }
  },
  email:(isDuplicate: boolean) => {
    return {
      validate: composeValidators(
        emptyStringValidator(Contact.FormValidationMessages.EmailInvalid),
        emailValidator(Contact.FormValidationMessages.EmailInvalid),
        duplicateValidator(Contact.FormValidationMessages.companyEmailDuplicate, isDuplicate)
      )
    }
  },
  companyPhoneNumber: (options?: Maybe<{ dialCode?: string; countryCode?: string }>, isDuplicate?: boolean) => {
    return {
      validate: duplicateValidator(Contact.FormValidationMessages.companyPhoneDuplicate, isDuplicate || false)
    };
  },
  companyFax: (options?: Maybe<{ dialCode?: string; countryCode?: string }>, isDuplicate?: boolean) => {
    return {
      validate: duplicateValidator(Contact.FormValidationMessages.companyFaxDuplicate, isDuplicate || false)
    };
  },
};
