import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment/moment';

import FormDatePickerInput from '../../../../../../components/ProcureForm/FormDatePickerInput';
import Assets from '../../../../../../i18n/Assets';
import { AssetFieldProps } from '../../types';

const AssetPurchaseDateField = (props: AssetFieldProps) => {
  const { disabled, formState ,size = "medium"} = props;
  const { control, isItemTypeAsset } = formState;

  const maxPurchaseDate = useMemo(() => {
    const date = new Date();
    return moment(date).format('YYYY-MM-DD');
  }, []);

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="purchaseDate"
        render={({ field, fieldState }) => (
          <FormDatePickerInput
            {...field}
            key="purchaseDate"
            clearable
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.PurchaseDate}
            maxDate={maxPurchaseDate}
            size={size}
            classes='mt-6'
          />
        )}
      />
    </div>
  );
};

export default AssetPurchaseDateField;
