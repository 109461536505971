import React from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import NotesIcon from '@mui/icons-material/Notes';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { useAccessControl } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import Network from 'app/i18n/Network';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

type QuickSummaryTypes = {
  asset: number;
  user: number;
} & RouteComponentProps;
const QuickSummary = (props: QuickSummaryTypes) => {
  const { history, asset, user } = props;
  const defaultStats = [
    { name: 'Total Assets', value: asset, navigationLink: routes.NetworkAssets() },
    { name: 'Total Users', value: user, navigationLink: routes.ITAssetUsers() },
    { name: 'Total Contracts', value: 0, navigationLink: '' },
    { name: 'Help Desk Tickets', value: 0, navigationLink: '' },
  ];

  const { workspacePermissions } = useCurrentUser();

  const canRedirect = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.ItAsset,
  );

  return (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <Typography className="text-[18px] font-semibold text-grey-900">
        {Network.QuickSummary}
      </Typography>
      <Divider className="bg-grey300 mt-[16px] mb-[24px]" />
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <Grid
          container
          // columns={{ xs: 12, md: 6, lg: 6, xl: 6 }}
          spacing={{ xs: 3 }}
          sx={{ height: '100%' }}>
          {defaultStats.map((stat, index) => {
            let icon = null;
            switch (index % 4) {
              case 0:
                icon = (
                  <DevicesIcon
                    fontSize="large"
                    style={{ width: '7rem', height: '7rem', color: '#797979' }}
                  />
                );
                break;
              case 1:
                icon = (
                  <PeopleIcon
                    fontSize="large"
                    style={{ width: '7rem', height: '7rem', color: '#797979' }}
                  />
                );
                break;
              case 2:
                icon = (
                  <NotesIcon
                    fontSize="large"
                    style={{ width: '7rem', height: '7rem', color: '#797979' }}
                  />
                );
                break;
              case 3:
                icon = (
                  <HelpCenterIcon
                    fontSize="large"
                    style={{ width: '7rem', height: '7rem', color: '#797979' }}
                  />
                );
                break;
              default:
                break;
            }
            return (
              <>
                <Grid item xs={6}>
                  <Card
                    className="
                       rounded-[12px] border-[1px] border-grey-300 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]"
                    sx={{
                      height: '152px',
                      cursor: stat.navigationLink && canRedirect ? 'pointer' : 'default',
                    }}
                    onClick={(event) =>
                      stat.navigationLink && canRedirect
                        ? history.push(stat.navigationLink)
                        : event.preventDefault()
                    }>
                    <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                        <Typography
                          gutterBottom
                          className="m-0 text-[14px] font-medium text-grey-800">
                          {stat.name}
                        </Typography>
                        <Typography className="mt-[8px] text-[36px] font-medium leading-[24px] text-grey-900">
                          {stat.value}
                        </Typography>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
        </Grid>
      </Box>
    </Grid>
  );
};

export default withRouter(QuickSummary);
