import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  Button,
  DataGridComponentState,
  DataGridHeadCell,
} from '@procurenetworks/procure-component-library';
import { isEqual } from 'lodash';
import moment from 'moment';
import FormTextArea from 'app/components/Form/FormTextArea';
import { Link } from 'app/libs/navigation';

import routes from '../../../../../consts/routes';
import {
  LocationTypeEnum,
  Maybe,
  TransactionEntityTypeEnum,
  TransactionStatusEnum,
  TransactionSubTypeEnum,
  TransactionTypeEnum,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from '../../../../../types/schema';
import DateTableFilter from '../../../../components/DateTableFilter';
import QuantityInput from '../../../../components/QuantityInput';
import { getLocationName } from '../../../../locations/utils/location';
import SitesTableFilter from '../../../../sites/components/SitesTableFilter';
import UsersTableFilter from '../../../../users/components/UsersTableFilter';
import { TransactionFilterKeyTypeEnum } from '../types';

const ActualField = (props: {
  headCell: DataGridHeadCell;
  row: any;
  state: DataGridComponentState;
}) => {
  const { row, state } = props;
  const { extraProps } = state;
  return (
    <QuantityInput
      key={`maximum-quantity-input${row.id}`}
      maximumValue={row.quantity}
      minimumValue={0}
      size="small"
      textAlign="right"
      value={row?.editableFields?.actual || ''}
      onChange={(value) => {
        extraProps?.setEditableFields(row.id, {
          actual: value,
        });
      }}
    />
  );
};

const getDistinctQuerySiteFilter = (filterState: any, key: TransactionFilterKeyTypeEnum) => {
  const transactionFilters = {
    sourceSiteIds: filterState.sourceSiteIds,
    sourceLocationIds: filterState.sourceLocationIds,
    destinationSiteIds: filterState.destinationSiteIds,
    destinationLocationIds: filterState.destinationLocationIds,
    actorIds: filterState.actorIds,
    statuses: [TransactionStatusEnum.Pending],
    types: [TransactionTypeEnum.Move],
    subTypes: [TransactionSubTypeEnum.Checkout],
    entityTypes: [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit],
  };

  delete transactionFilters[key];

  return {
    distinctTableName: ValuesInEntitiesTableNameEnum.Transactions,
    filters: transactionFilters,
  };
};

const CheckInNotesField = React.memo(
  (props: { headCell: DataGridHeadCell; row: any; state: DataGridComponentState }) => {
    const { row, state } = props;
    const { extraProps } = state;
    const [note, setNotes] = useState(row?.editableFields?.meta?.note || '');

    const handleChange = (note: string) => {
      extraProps?.setEditableFields(row.id, {
        meta: { note: note },
      });
      setNotes(note);
    };

    // NOTE: It's required to add keydown to prevent input get blurred when user hits space key
    const onKeyDown = (e: any) => {
      if (e.code === 'Space') {
        if (note.length < 30) {
          e.preventDefault();
          e.stopPropagation();
          extraProps?.setEditableFields(row.id, {
            meta: { note: note + ' ' },
          });
          setNotes(note + ' ');
        } else {
          e.preventDefault();
        }
      }
    };

    return (
      <FormTextArea
        characterLimit={30}
        defaultTextAreaHeight="30px"
        name="note"
        rows={2}
        value={note ?? ''}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    );
  },
  (pp, np) =>
    isEqual(pp?.state?.extraProps?.setEditableFields, np?.state?.extraProps?.setEditableFields),
);

export const checkInAssetsTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'id',
    label: 'SKU',
    value: 'id',
    valueNode: ({ row }) => {
      return (
        <Link
          className="sku-link truncate"
          title={row.entity?.sku}
          to={routes.EditAssets(row.entity?.id)}>
          {row.entity?.sku}
        </Link>
      );
    },
    width: 120,
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    valueNode: ({ row }) => {
      return (
        <span className="truncate" title={row.entity?.title || ''}>
          {row.entity?.title || ''}
        </span>
      );
    },
    width: 180,
  },
  {
    id: 'serialNumber',
    label: 'Serial Number',
    value: 'serialNumber',
    valueNode: ({ row }) => {
      return (
        <span className="truncate" title={row.entity?.serialNumber || ''}>
          {row.entity?.serialNumber || ''}
        </span>
      );
    },
    width: 150,
  },
  {
    id: 'sourceSite',
    label: 'From Site',
    value: 'sourceSite',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <SitesTableFilter
            key="source-site-table-filter"
            isDistinct
            name="fromSiteFilter"
            placeholder="From Site"
            queryVariables={{
              types: [LocationTypeEnum.Site],
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.SourceSiteId],
              ...getDistinctQuerySiteFilter(filterState, TransactionFilterKeyTypeEnum.SOURCE_SITE),
            }}
            value={filterState?.sourceSiteIds}
            onChange={(value: string[]) => {
              setFilterState?.({
                sourceSiteIds: value,
                sourceLocationIds: value.length === 1 ? filterState?.sourceLocationIds : [],
              });
            }}
          />
        </div>
      );
    },
    valueNode: ({ row }) => {
      return (
        <span className="truncate" title={row.sourceSite?.name || ''}>
          {row.sourceSite?.name || ''}
        </span>
      );
    },
    width: 220,
  },
  {
    id: 'sourceLocation',
    label: 'From Location',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <SitesTableFilter
            key="source-location-table-filter"
            isDistinct
            disabled={filterState?.sourceSiteIds.length !== 1}
            name="fromLocation"
            placeholder="From Location"
            queryVariables={{
              siteIds: filterState.sourceSiteIds,
              types: [LocationTypeEnum.Location],
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.SourceLocationId],
              ...getDistinctQuerySiteFilter(
                filterState,
                TransactionFilterKeyTypeEnum.SOURCE_LOCATION,
              ),
            }}
            value={filterState?.sourceLocationIds}
            onChange={(value: string[]) => {
              setFilterState?.({
                sourceLocationIds: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'sourceLocation',
    valueNode: ({ row }) => {
      const locationName = getLocationName(row?.sourceSiteId, {
        id: row?.sourceLocationId,
        name: row?.sourceLocation.name,
      } as any);
      return (
        <span className="truncate" title={locationName}>
          {locationName}
        </span>
      );
    },
    width: 220,
  },
  {
    id: 'destinationSite',
    label: 'To Site',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <SitesTableFilter
            key="destination-site-table-filter"
            isDistinct
            name="toSite"
            placeholder="To Site"
            queryVariables={{
              types: [LocationTypeEnum.Site],
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.DestinationSiteId],
              ...getDistinctQuerySiteFilter(
                filterState,
                TransactionFilterKeyTypeEnum.DESTINATION_SITE,
              ),
            }}
            value={filterState?.destinationSiteIds}
            onChange={(value: string[]) => {
              setFilterState?.({
                destinationSiteIds: value,
                destinationLocationIds:
                  value.length === 1 ? filterState?.destinationLocationIds : [],
              });
            }}
          />
        </div>
      );
    },
    value: 'destinationSite',
    valueNode: ({ row }) => {
      return (
        <span className="truncate" title={row.destinationSite?.name || ''}>
          {row.destinationSite?.name || ''}
        </span>
      );
    },
    width: 180,
  },
  {
    id: 'destinationLocation',
    label: 'To Location',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <SitesTableFilter
            key="destination-location-table-filter"
            isDistinct
            disabled={filterState?.destinationSiteIds.length !== 1}
            name="toLocation"
            placeholder="To Location"
            queryVariables={{
              siteIds: filterState.destinationSiteIds,
              types: [LocationTypeEnum.Location],
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.DestinationLocationId],
              ...getDistinctQuerySiteFilter(
                filterState,
                TransactionFilterKeyTypeEnum.DESTINATION_LOCATION,
              ),
            }}
            value={filterState?.destinationLocationIds}
            onChange={(value: string[]) => {
              setFilterState?.({
                destinationLocationIds: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'destinationLocation',
    valueNode: ({ row }) => {
      const locationName = getLocationName(row?.destinationSiteId, {
        id: row?.destinationLocationId,
        name: row?.destinationLocation.name,
      } as any);
      return (
        <span className="truncate" title={locationName}>
          {locationName}
        </span>
      );
    },
    width: 180,
  },
  {
    id: 'user',
    label: 'User',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div>
          <UsersTableFilter
            key="user-table-filter"
            isDistinct
            name="User"
            placeholder="User"
            queryVariables={{
              distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.ActorId],
              ...getDistinctQuerySiteFilter(filterState, TransactionFilterKeyTypeEnum.ACTOR),
            }}
            value={filterState?.actorIds}
            onChange={(value: Maybe<string[]>) => {
              setFilterState?.({
                actorIds: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'user',
    valueNode: ({ row }) => {
      return (
        <span className="truncate" title={row.actor?.name || ''}>
          {row.actor?.name || ''}
        </span>
      );
    },
    width: 220,
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    labelNode: (props) => {
      const { extraProps } = props.state;
      const { filterState, setFilterState } = extraProps || {};
      return (
        <div className="w-[90%]">
          <DateTableFilter
            key="due-date-filter"
            placeholder="Due Date"
            value={filterState?.dueDate}
            onChange={(value) => {
              setFilterState?.({
                dueDate: value,
              });
            }}
          />
        </div>
      );
    },
    value: 'dueDate',
    valueNode: ({ row }) => {
      const dueDate = row.dueDate ? moment(row.dueDate).format('L LT') : '';
      return (
        <span className="truncate" title={dueDate}>
          {dueDate}
        </span>
      );
    },
    width: 200,
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    valueNode: ({ row }) => {
      return (
        <span className="w-[100%] truncate text-right" title={row.quantity || ''}>
          {row.quantity || ''}
        </span>
      );
    },
    width: 120,
  },
  {
    id: 'actual',
    label: 'Actual',
    value: 'actual',
    valueNode: ActualField,
    width: 180,
  },
  {
    id: 'variance',
    label: 'Variance',
    value: 'variance',
    valueNode: ({ row }) => {
      const { quantity, editableFields } = row;
      const { actual } = editableFields;
      const isActualEmpty = actual === '';
      const results = !isActualEmpty ? Math.abs(quantity - Number(actual)) : 0;

      if (!(quantity !== undefined && actual !== '')) {
        return null;
      }
      return (
        <span
          className="w-[100%] truncate text-right"
          style={{
            color: results !== 0 ? 'red' : 'green',
          }}>
          {results}
        </span>
      );
    },
    width: 120,
  },
  {
    id: 'note',
    label: 'Notes',
    value: 'meta.note',
    valueNode: (props) => (
      <Box className="flex h-[75px] w-[150px] items-center justify-center overflow-x-hidden overflow-y-scroll py-[10px]">
        {' '}
        <CheckInNotesField {...props} />
      </Box>
    ),
    width: 250,
  },
  {
    id: 'action',
    label: 'Action',
    value: 'action',
    valueNode: ({ row, state }) => {
      return (
        <div>
          <Button
            classes="max-w-[91px] h-[44px]"
            theme="success"
            onClick={() => {
              state?.extraProps?.onConfirmCheckInTransaction(row.id);
            }}>
            Check In
          </Button>
        </div>
      );
    },
    width: 150,
  },
];
