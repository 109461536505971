const Network = {
  NetworkDashboard: 'IT Assets Dashboard',
  NetworkAssets: 'IT Assets',
  NetworkDiscoveryTool: 'IT Assets Discovery Tool',
  NetworkDiscoveredAssets: 'IT Discovered Assets',
  NetworkAssetInsights: 'IT Asset Insights',
  QuickSummary: 'Quick Summary',
  AssetSummary: 'Asset Summary',
  NetworkActivity: 'IT Assets Activity',
  AssetWarranties: 'Asset Warranties',
  Storage: 'Storage',
  NetworkAssetDetail: 'IT Asset Detail',
  NetworkAssetEdit: 'IT Asset Edit',
  DiscoverAssetHeading: 'Discover Your IT Assets',
  DiscoverAssetParagraph: 'Getting started in the Asset module is easy with one of the quick-start options below.',
  UpdateLocationUser: "Update Location And User",
  UpdateNetworkAsset: "Update IT Asset Details",
  UpdateWarranty: "Update Warranty",
  NothingToShow:"Nothing to show yet for this Insight. Please add IT assets to view.",
  Labels: {
    Manage: 'Manage IT Asset Insights Details',
    DownloadAgent: 'You may not have permission to download Procure Agent.',
  },
  Action: {
    UpdateNote: 'Update Note',
    AddNote: 'Add Note',
    DeleteAll: 'Delete Sync Data',
    Download: "Download"
  },
  FormPlaceholders:{
    OperatingSystemSelect: "Operating System"
  },
  FormValidationMessages:{
    Title: "Title is required",
    Note: "Note is required",
    UserNameRequired: 'Please enter a username.',
    UserNameMaxAllowed: 'Please enter a username up to 30 characters.',
    EmailRequired: 'Please enter an Email Address.',
    EmailInvalid: 'Please enter a valid Email Address.',
    DepartmentRequired: 'Please enter an Department.',
    LocationRequired: 'Please enter an Location.',
    startDateRequired: 'Please select a Purchase Date.',
    endDateRequired: 'Please select a Warranty Expiration Date.',
    NumberRequired: "Please enter a Number"
  },
  FormLabels: {
    Title: "Title",
    Note: "Note",
    Username: "Username",
    Email: "Email",
    Site: "Site",
    Location: "Location",
    SerialNumber: "Serial Number",
    PurchaseDate: 'Purchase Date',
    WarrantyExpiryDate: 'Warranty Expiration',
    NoResult: "No Result Found",
    Cpu: {
      Title: "CPU",
      Manufacturer: "Manufacturer",
      Brand: "Brand",
      Model: "Model",
      Processor: "Processor",
      Cores: "Cores",
      PhysicalCores: "Physical Cores",
      PerformanceCores: "Performance Cores",
      Stepping: "Stepping",
      Voltage: "Voltage",
    },
    Disk: {
      Title: "Disk",
      FileSystem: "File System",
      ReadWrite: "Read Write",
      Type: "Type", 
      Use: "Use", 
      Size: "Size",
      Used: "Used",
      Available: "Available",
    },
    System: {
      Title: "System",
      Manufacturer: "Manufacturer",
      Model: "Model",
      Serial: "Serial",
      Virtual: "Virtual",
    },
    Memory: {
      Title: "Memory",
      Available: "Available",
      Total: "Total",
      Free: "Free",
      Slab: "Slab",
      Buffers: "Buffers",
      SwapTotal: "Swap Total",
      SwapFree: "Swap Free",
      SwapUsed: "Swap Used",
    },
    Os: {
      Title: "Operating System",
      Platform: "Platform",
      Distro: "Distro",
      Codename: "Codename",
      Release: "Release",
      Hostname: "Hostname",
      Serial: "Serial",
      Architecture: "Architecture",
      Kernal: "Kernel",
    },
    Process: {
      Title: "Processes",
      All: "All",
      Blocked: "Blocked",
      Running: "Running",
      Sleeping: "Sleeping",
      Unknown: "Unknown",
    },
    Battery: {
      Title: "Battery",
      Manufacturer: "Manufacturer",
      Type: "Type",
      Serial: "Serial",
      DesignedCapacity: "Designed Capacity",
      MaxCapacity: "Max Capacity",
      CurrentCapacity: "Current Capacity",
      Model: "Model",
      Voltage: "Voltage",
      Percentage: "Percentage"
    },
    Motherboard: {
      Title: "Motherboard",
      Manufacturer: "Manufacturer",
      Version: "Version",
      Model: "Model",
      Serial: "Serial" 
    },
    Storage: {
      Title: "Storage",
      Name: "Name",
      Serial: "Serial Number",
      Type: "Type",
      Interface: "Interface",
      Vendor: "Vendor",
      Size: "Size",
      FirmwareRevision: "Firmware Revision",
      Device: "Device" 
    },
    Display: {
      Title: "Display",
      Builtin: "Built in",
      CurrentRR: "Current Refresh Rate",
      CurrentResX: "Current Resolution X",
      CurrentResY: "Current Resolution Y"
    },
    Bios: {
      Title: "BIOS",
      Vendor: "Vendor",
      Version: "Version",
      Release: "Release Date"
    },
    Network: {
      Title: "Network",
      Iface: "Iface",
      IPv4: "IPv4",
      IPv6: "IPv6",
      MAC: "MAC",
      MTU: "MTU",
      Type: "Type"
    },
    Graphics: {
      Title: 'Graphics',
      Vendor: "Vendor",
      Model: "Model",
      Bus: "Bus",
      VRAM: "VRAM"
    }
  },
  SuccessMessages: {
    NetworkAssetUpdated: 'This IT asset has been successfully updated.',
    ITAssetImported: 'This IT Asset has been successfully Imported to Assets',
    ITAssetDeleted: 'This IT Assets have been deleted successfully'
  },
};

export default Network;
