import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import FormSelect from 'app/components/Form/FormSelect';
import Reports from 'app/i18n/Reports';
import ReportDateRangeSelector from 'app/modules/reports/components/ReportDateRangeSelector';
import { Maybe } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import { getTransactionDateFilterOptions, TRANSACTION_DATE_FILTER_RULES } from './utils';

interface Props {
  control: any;
}

const TransactionReportDateFilter = (props: Props) => {
  const { control } = props;
  const options = useMemo(() => getTransactionDateFilterOptions(), []);

  return (
    <Controller
      control={control}
      name="createdAt"
      render={({ field, fieldState }) => {
        const onChange = (value?: Maybe<string>) => {
          field.onChange(
            options.find((option) => option.value === value)?.createdAt || {
              absolute: {
                endTime: null,
                startTime: null,
              },
              timezone: getCurrentTimeZoneValue(),
            },
          );
        };
        const value =
          options.find((option) => isEqual(option.createdAt, field.value))?.value || 'custom';

        return (
          <>
            <FormSelect
              {...field}
              error={fieldState.error}
              label={Reports.Date}
              options={options}
              value={value}
              onChange={onChange}
            />
            {value === 'custom' ? (
              <ReportDateRangeSelector value={field.value} onChange={field.onChange} />
            ) : null}
          </>
        );
      }}
      rules={TRANSACTION_DATE_FILTER_RULES}
    />
  );
};

export default TransactionReportDateFilter;
