import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';
import FormCategorySelect from 'app/modules/categories/components/FormCategorySelect';

import { AssetFieldProps } from '../../types';
import { ASSET_FORM_RULES } from '../../utils';

const AssetCategoryField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="categoryId"
        render={({ field, fieldState }) => (
          <FormCategorySelect
            {...field}
            isRequired
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.Category}
          />
        )}
        rules={ASSET_FORM_RULES.categoryId}
      />
    </div>
  );
};

export default AssetCategoryField;
