import { useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';

import Assets from '../../../../i18n/Assets';
import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import ImageViewDialog from '../../../components/ImageViewDialog';
import AssetItemTable from './components/AssetItemTable';
import useSearchAssetsState from './hook/useSearchAssetsState';
import { getQueryParams } from './hook/useSearchAssetsState/utils';
import useSearchSuggestion from './hook/useSearchSuggestion';

const SearchAssets = (props: RouteComponentProps) => {
  const { location } = props;

  const { parentId } = useMemo(() => {
    return getQueryParams(location.search);
  }, [location.search]);

  const {
    search,
    table,
    tableState,
    deletingAssetItems,
    filters,
    imagePreviewState,
    isSiteIdSelected,
    showImageViewDialog,
    onCloseImageViewDialog,
    onBulkMove,
    bulkMoveState,
    bulkEditState,
    siteId,
  } = useSearchAssetsState({
    parentId,
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion({
    siteId,
    parentId,
  });

  return (
    <EntityManager
      className="rounded-bl-[12px] rounded-br-[12px] !p-0"
      subject={AllowedPermissionsSubjectEnum.Asset}>
      <Box className="px-[16px] md:px-[24px] lg:px-[24px]">
        <EntityManager.Title
          hasTopDivider
          documentTitle={Assets.SearchTitle}
          title={Assets.Search}
        />
      </Box>
      <AssetItemTable
        bulkEditState={bulkEditState}
        bulkMoveState={bulkMoveState}
        fetching={deletingAssetItems}
        filterNodes={
          <Box className="flex !w-[100%] flex-wrap gap-[16px] md:!w-fit md:gap-[24px] lg:!w-fit">
            <EntityManager.Search
              {...search}
              isDropdown
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              suggestionData={searchSuggestions || []}
              onChangeSearch={setSearchValue}
            />
            <FormSiteSelect
              enabledAllOption
              className="w-full md:w-[374px] lg:w-[180px]"
              currentSelectedOption={selectedOption}
              pause={filters.state.siteId !== undefined}
              value={filters.state.siteId}
              onChange={(value, option) => {
                setSelectedOption(option);
                filters.setState({
                  siteId: value,
                });
              }}
            />
          </Box>
        }
        filters={filters}
        isSiteIdSelected={isSiteIdSelected}
        paginationWrapperClass="p-[16px] md:p-[24px] xl:p-[24px] flex-wrap md:gap-[24px]"
        showImageViewDialog={showImageViewDialog}
        siteId={siteId}
        table={table}
        tableState={tableState}
        onBulkMove={onBulkMove}
      />
      <ImageViewDialog
        id="asset-image-view"
        imageUrl={imagePreviewState.imageUrl}
        open={imagePreviewState.open}
        onClose={onCloseImageViewDialog}
      />
    </EntityManager>
  );
};

export default withRouter(SearchAssets);
