import { useCallback, useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { getTabNavLinkClassName } from 'app/utils/navLink';
import clsx from 'clsx';
import { TabProps } from './types';

function TabLayout(props: RouteComponentProps & TabProps) {
  const { history, routes, tabs, defaultTab ,className} = props;

  useEffect(() => {
    const currentTab = history.location.pathname.includes(defaultTab as string);
    if (!currentTab) {
      history.push(defaultTab as string);
    }
  }, [defaultTab, history]);

  const onTabChange = useCallback(
    (tab: string) => {
      const config = routes.find((config) => config.id === tab);

      if (config) {
        history.push(config.route);
      }
    },
    [history, routes],
  );

  const isTabActive = useCallback(
    (tab: string) => {
      return history.location.pathname.includes(tab);
    },
    [history.location.pathname],
  );

  return (
    <>
      <Nav tabs className={clsx('flex bg-white p-[24px] gap-[8px]',className)}>
        {tabs.map(({ label, value }) => (
          <NavItem key={value}>
            <NavLink
              className={getTabNavLinkClassName(isTabActive(value))}
              onClick={() => onTabChange(value)}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </>
  );
}

export default withRouter(TabLayout);
