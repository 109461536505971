import { useState } from 'react';
import { Box } from '@mui/material';
import Modal from 'app/components/Modal';
import { NetworkAssetStatusEnum } from 'app/types/schema';

import NetworkAssetTable from '../../../networkAssets/components/NetworkAssetTable';
import useNetworkAssetsState from '../../../networkAssets/hook/useNetworkAssetsState';
import { detailModelType } from '../../types';
import ColumnGraph from '../ColumnGraph';
import DonutGraph from '../DonutGraph';

type Props = {
  onCancel: () => void;
  isOpen: boolean;
  onSaveNote?: any;
  detailData: detailModelType;
};

const DetailsModal = (props: Props) => {
  const { isOpen, detailData, onCancel } = props;

  const { filters, table, networkAssetTableState } = useNetworkAssetsState(true);
  const [graphOption, setGraphOption] = useState('donut');

  const handleDataPointClick = (data: string) => {
    const searchParam = data === 'Other' || data === 'Not Available' ? '' : data;
    if (searchParam || data === 'No Location') {
      filters.setState({
        [detailData.subHeading]: searchParam,
        statuses: [NetworkAssetStatusEnum.Active],
      });
    } else {
      filters.setState({
        operatingSystem: [],
        motherboardModel: [],
        cpuBrand: [],
        cpuManufacturer: [],
        statuses: [NetworkAssetStatusEnum.Notinstalled],
      });
    }
  };

  return (
    <Modal
      fullWidth
      id="details-modal"
      open={isOpen}
      title={detailData.title}
      onClose={() => {
        onCancel();
        filters.setState({
          operatingSystem: [],
          motherboardModel: [],
          cpuBrand: [],
          cpuManufacturer: [],
        })
      }}>
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
          {graphOption === 'donut' ? (
            <DonutGraph
              changeGraphOption
              chartData={detailData?.details?.length ? detailData.details : []}
              graphOption={graphOption}
              handleDataPointClick={handleDataPointClick}
              setGraphOption={setGraphOption}
              subheader={detailData.subHeading}
              title={detailData.title}
            />
          ) : (
            <ColumnGraph
              changeGraphOption
              chartData={detailData?.details?.length ? detailData.details : []}
              graphOption={graphOption}
              handleDataPointClick={handleDataPointClick}
              setGraphOption={setGraphOption}
              subheader={detailData.subHeading}
              title={detailData.title}
            />
          )}

          <NetworkAssetTable
            persistKey={'network_insight_asset_table'}
            disableSelection
            clickable={false}
            filters={filters}
            table={table}
            tableState={networkAssetTableState}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default DetailsModal;
