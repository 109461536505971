import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';
import { LoginInput } from 'app/modules/auth/types';
import moment from 'moment-timezone';

const useEmailPasswordAPI = () => {
  const [fetchStatus, error, data, execute] = useRest(`${API_URL}/auth/login`);

  const isFetching = fetchStatus === FetchingStatus.Fetching;
  const executeAPI = useCallback(
    async (values: LoginInput) =>
      execute({
        body: JSON.stringify({
          emailId: values.email,
          password: values.password,
          timezone: moment.tz.guess(),
        }),
      }),
    [execute],
  );
  return { fetching: isFetching, error, data, execute: executeAPI };
};

export default useEmailPasswordAPI;
