import { useEffect, useMemo } from 'react';

import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { getRedirectToBasedOnPermission } from '../../utils/getRedirectTo';
import { getUndeleteTabs } from '../../utils/undeleteTabs';

const useUndeleteLayout = (history: any) => {
  const { workspacePermissions } = useCurrentUser();

  const redirectTo = useMemo(() => {
    return getRedirectToBasedOnPermission(workspacePermissions, history);
  }, [workspacePermissions]);

  useEffect(() => {
    history.push(redirectTo);
  }, [redirectTo]);

  const tabs = useMemo(() => {
    return getUndeleteTabs(workspacePermissions);
  }, [workspacePermissions]);

  return {
    tabs,
    redirectTo
  };
};

export type UseDeletedStateReturnType = ReturnType<typeof useUndeleteLayout>;
export default useUndeleteLayout;
