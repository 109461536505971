import WarningProvider from 'app/components/WarningAlert';

import InventoriesLayout from '../../modules/inventory/layout';
import RemoveInventory from '../../modules/inventory/views/RemoveInventory';

function RemoveInventoryPage() {
  return (
    <InventoriesLayout>
      <WarningProvider>
        <RemoveInventory />
      </WarningProvider>
    </InventoriesLayout>
  );
}

export default RemoveInventoryPage;
