import React, { useCallback } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import AssetModel from 'app/i18n/AssetModel';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';

import AssetModelTable from './components/AssetModelTable';
import { useDeleteAssetModelItemsMutation } from './graphql/mutations/generated/deleteAssetModelItems';
import analytics from 'app/analytics';

const AssetModels = (props: RouteComponentProps) => {
  let { history } = props;
  const [{ fetching: deletingAssetModelItems }, executeDeleteAssetModelItems] =
    useDeleteAssetModelItemsMutation();

  const onEdit = useCallback(
    (itemIds: string[]) => {
      if (itemIds.length > 0) history.push(routes.EditAssetModel(itemIds[0]));
    },
    [history],
  );

  const onDelete = useCallback(
    (itemIds: string[]) => {
      executeDeleteAssetModelItems({
        input: {
          itemIds,
        },
      }).then((response) => {
        analytics?.track('Deleted', { name: 'Asset Model' });
        if (response?.data?.deleteAssetModelItems?.success) {
          SnackbarService.show({
            message: AssetModel.SuccessMessages.AssetModelItemsDeleted(itemIds),
          });
        }
      });
    },
    [executeDeleteAssetModelItems],
  );

  return (
    <EntityManager
      subject={AllowedPermissionsSubjectEnum.AssetModel}
      layoutWrapperClass
      className="pt-0">
      <EntityManager.Title documentTitle={AssetModel.AssetModels} title={AssetModel.AssetModels} />
      <EntityManager.Add label={AssetModel.NewAssetModel} to={routes.AddAssetModel()} />
      <AssetModelTable fetching={deletingAssetModelItems} onDelete={onDelete} onEdit={onEdit} />
    </EntityManager>
  );
};

export default withRouter(AssetModels);
