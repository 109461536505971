import { css } from '@emotion/css';
import styled from '@emotion/styled';

export const CardContainer = styled.div<{ items?: number }>`
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  padding: 50px 20px 20px 20px;

  ${(props) =>
    `
    @media (min-width: 900px) {
      grid-template-columns: repeat(${props?.items && props?.items > 2 ? '3' : props?.items}, 1fr);
      width: ${props?.items && props?.items <= 2 ? `${props?.items * 480}px` : 'unset'}
    }
    @media (min-width: 711px) and (max-width: 899px) {
      grid-template-columns: repeat(${props?.items && props?.items >= 2 ? '2' : props?.items}, 1fr);
      width: ${props?.items && props?.items >= 2 ? '600px' : '300px'}
    }
    @media (min-width: 400px) and (max-width: 710px) {
      width: 300px
    }
  `}
`;

export const Item = css`
  & .MuiCardMedia-root {
    background-size: contain;
  }
`;

export const VendorsDialog = css`
  width: 950px;
  display: block;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  .MuiDialogContent-root {
    padding: unset;
  }
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    display: none !important;
  }
`;
