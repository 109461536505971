import React, { FC } from 'react';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Http4XXPage } from '@procurenetworks/procure-component-library';
import { Layout } from '@procurenetworks/procure-component-library';
import { APP_CONFIG } from 'app/consts/config';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { logout } from 'app/modules/auth/views/Login/utils/authTokens';

const Error: FC<RouteComponentProps<any, {}, unknown>> = ({ history }) => {
  const onSecondaryButtonClick = () => {
    if (history.length <= 3) {
      history.push(APP_CONFIG.uiUrls.core || '');
    } else {
      history.goBack();
    }
  };

  const onPrimaryButtonClick = () => {
    logout();
  };

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}>
      <Http4XXPage
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
      />
    </Layout>
  );
};

export default withRouter(Error);
