import { forwardRef, useMemo } from 'react';
import { cx } from '@emotion/css';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import FormLabel from 'app/components/Form/FormLabel';
import { SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { useCategoriesSelectQuery } from '../../graphql/queries/generated/categoriesSelect';

export interface CategoriesSelectProps {
  className?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: string[];
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  enabledAllOption?: boolean;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;
  const [{ fetching, data }] = useCategoriesSelectQuery({
    pause: pause || value?.includes('all'),
    variables: { filters: { categoryIds: value } },
    requestPolicy: 'network-only',
  });

  const values = useMemo(
    () => data?.categories.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.categories.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (
  props: UseSelectQueryProps & { enabledAllOption?: boolean },
): UseSelectQueryReturn => {
  const { inputValue, enabledAllOption, value } = props;

  const [{ fetching, data }] = useCategoriesSelectQuery({
    variables: {
      filters: { search: inputValue },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
      limit: 1000,
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(() => {
    const options = [];

    const parsedOptions = data?.categories.edges?.map(adaptNodeEdgeToOption) || [];

    if (enabledAllOption && parsedOptions.length > 1 && value.length !== parsedOptions.length) {
      options.push({
        label: 'All Categories',
        value: 'all',
      });
    }

    options.push(...parsedOptions);

    return options;
  }, [data?.categories.edges, enabledAllOption, value]);

  return { isLoading: fetching, options };
};

const FormCategoriesSelect = forwardRef<HTMLDivElement, CategoriesSelectProps>((props, ref) => {
  const {
    className,
    label,
    isRequired,
    value,
    onChange: onChangeProp,
    enabledAllOption,
    ...rest
  } = props;

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <AsyncMultiSelect
        {...rest}
        useSelectQuery={useSelectQuery}
        useSelectedValue={useSelectedValue}
        value={value}
        onChange={onChangeProp}
        queryVariables={{
          enabledAllOption,
        }}
        enabledAllOption
      />
    </Box>
  );
});

export default FormCategoriesSelect;
