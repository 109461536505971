import AwsS3 from '@uppy/aws-s3';
import { Uppy, UppyOptions } from '@uppy/core';
import { APP_CONFIG } from 'app/consts/config';
import { request } from 'app/utils/request';

interface Options extends UppyOptions {
  purpose: string;
}

const getUppyInstance = (opts: Options): Uppy => {
  const { purpose, ...options } = opts;

  return new Uppy({ autoProceed: true, ...options }).use(AwsS3, {
    async getUploadParameters(file) {
      const response = await request(
        APP_CONFIG.uploadSignUrl,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fileName: file.name,
            fileType: file.type,
            metadata: { name: file.name, type: file.type, purpose },
          }),
        },
        {
          auth: true,
        },
      );
      return await response.json();
    },
  });
};

export default getUppyInstance;
