import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string
};

const DisplayForm = (props: Props) => {
  const { control ,className="mt-[0px]"} = props;
  return (
    <>
      <Typography
           className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Display.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="displayBuiltin"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Display.Builtin}
                placeholder={Network.FormLabels.Display.Builtin}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="displayCRR"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Display.CurrentRR}
                placeholder={Network.FormLabels.Display.CurrentRR}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="displayCResX"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Display.CurrentResX}
                placeholder={Network.FormLabels.Display.CurrentResX}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="displayCResY"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Display.CurrentResY}
                placeholder={Network.FormLabels.Display.CurrentResY}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayForm;
