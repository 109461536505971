import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';

import FormNumberInput from '../../../../../components/FormNumberInput';
import { LabelTypeEnum } from '../../../../../labels/views/Labels/components/Forms/types';
import FormSiteSelect from '../../../../../locations/components/FormSiteSelect';
import { AssetFieldProps } from '../../types';
import { ASSET_FORM_RULES } from '../../utils';

const AssetDestinationSIteLocationQtyFields = (props: AssetFieldProps) => {
  const { formState, disabled } = props;

  const { control, destinationSiteId, id, serialNumber, destinationLocationId, setSite } =
    formState;

  const quantityRules = useMemo(() => {
    return !!serialNumber ? ASSET_FORM_RULES.quantityForSerialNumber : ASSET_FORM_RULES.quantity;
  }, [serialNumber]);

  if (id) {
    return null;
  }

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="destinationSiteId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              disabled={disabled}
              error={fieldState.error}
              label={Assets.FormLabels.Site}
              onChange={(value, valueObject) => {
                field.onChange(value);
                setSite(valueObject);
              }}
            />
          )}
          rules={ASSET_FORM_RULES.destinationSiteId}
        />
      </div>

      <div className="mb-20">
        <Controller
          control={control}
          name="destinationLocationId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              disabled={!destinationSiteId || disabled}
              error={fieldState.error}
              label={Assets.FormLabels.Location}
              selectedLocationValue={destinationLocationId}
              type={LabelTypeEnum.Location}
              types={[]}
              value={destinationSiteId}
            />
          )}
          rules={ASSET_FORM_RULES.destinationLocationId}
        />
      </div>

      <div className="mb-20">
        <Controller
          control={control}
          name="quantity"
          render={({ field, fieldState }) => (
            <FormNumberInput
              {...field}
              isRequired
              error={fieldState.error}
              isDisabled={disabled}
              label={Assets.FormLabels.Quantity}
              maximumLength={9}
            />
          )}
          rules={quantityRules}
        />
      </div>
    </div>
  );
};

export default AssetDestinationSIteLocationQtyFields;
