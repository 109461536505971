import { arrayValidator, textValidator } from 'app/components/Form/utils/validators';
import Partner from 'app/i18n/Partner';

export const PARTNER_FORM_RULES = {
  name: {
    validate: textValidator(Partner.FormValidations.Name),
  },
  categories: {
    validate: arrayValidator(Partner.FormValidations.CategoriesLength),
  },
  users: {
    validate: arrayValidator(Partner.FormValidations.UsersLength),
  },
};
