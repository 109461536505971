import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { RolesTableRowFragmentDoc } from '../../../../Roles/graphql/fragments/generated/RolesTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateRoleMutationVariables = Types.Exact<{
  input: Types.UpdateRoleInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'UpdateRolePayload', success: boolean, reason?: string | null, role?: { __typename?: 'RoleSchema', id: string, name: string, description?: string | null, updatedAt: string, tenant?: { __typename?: 'TenantSchema', id: string, name: string } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string }, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } | null } };


export const UpdateRoleDocument = gql`
    mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    success
    reason
    role {
      ...RolesTableRow
    }
  }
}
    ${RolesTableRowFragmentDoc}`;

export function useUpdateRoleMutation() {
  return Urql.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument);
};