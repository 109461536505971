import { useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { getTokenData } from '../../../../../partners/utils/token';
import useApiCreateJoin from '../../hooks/useApiCreateJoin';
import {
  stepFiveDefaultValues,
  stepFourDefaultValues,
  stepOneDefaultValues,
  stepThreeDefaultValues,
} from './defaultValues';
import SignUpStepFive from './SignUpStepFive';
import SignUpStepFour from './SignUpStepFour';
import SignUpStepOne from './SignUpStepOne';
import SignUpStepThree from './SignUpStepThree';
import SignUpStepTwo from './SignUpStepTwo';
import {
  SignUpApiInput,
  SignUpStepFiveInput,
  SignUpStepFourInput,
  SignUpStepOneInput,
  SignUpStepThreeInput,
} from './types';

function SignUpForm(props: RouteComponentProps) {
  const { history } = props;

  const [stepper, setStepper] = useState(1);
  const [firstStepData, setFirstStepData] = useState<SignUpStepOneInput>(stepOneDefaultValues);
  const [thirdStepData, setThirdStepData] = useState<SignUpStepThreeInput>(stepThreeDefaultValues);
  const [forthStepData, setForthStepData] = useState<SignUpStepFourInput>(stepFourDefaultValues);
  //Todo: for password components
  const [fifthStepData, setFifthStepData] = useState<SignUpStepFiveInput>(stepFiveDefaultValues);
  const { fetchingApi, executeApiCreateJoin } = useApiCreateJoin();

  const onConfirmStepOne = async (props: SignUpStepOneInput) => {
    setFirstStepData(props);
    setStepper(stepper + 1);
  };

  const onConfirmStepTwo = () => {
    setStepper(stepper + 1);
  };

  const onConfirmStepThree = async (props: SignUpStepThreeInput) => {
    setThirdStepData(props);
    setStepper(stepper + 1);
  };

  const onConfirmStepFour = async (props: SignUpStepFourInput) => {
    const tokenData = getTokenData();
    /** TODO: @Sanjay add a try catch logic around this function call here to show an error message mentioned by Scott. */

    const ApiPayloads: SignUpApiInput = {
      firstName: removeMultipleSpaces(firstStepData.firstName),
      lastName: removeMultipleSpaces(firstStepData.lastName.trim()),
      email: removeMultipleSpaces(firstStepData.email),
      organization: removeMultipleSpaces(thirdStepData.organization),
      address: removeMultipleSpaces(thirdStepData.address),
      zip: removeMultipleSpaces(thirdStepData.zip),
      city: removeMultipleSpaces(thirdStepData.city),
      state: removeMultipleSpaces(thirdStepData.state),
      url: removeMultipleSpaces(props.url),
      primaryPhoneNumber: removeMultipleSpaces(firstStepData.primaryPhoneNumber),
      invitedByTenantId: tokenData?.tenantId || null,
    };

    const response = await executeApiCreateJoin(ApiPayloads);
    if (response?.success) {
      SnackbarService.show({ message: 'User Created successfully' });
      history.push(routes.Login());
    } else {
      SnackbarService.showError({ message: response?.message || 'SignUp Failed' });
    }
  };

  //TODO: for password components.
  const onConfirmStepFive = async (props: SignUpStepFiveInput) => {
    setFifthStepData(fifthStepData);
  };

  const onBack = () => {
    setStepper(stepper - 1);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full max-w-[688px] rounded-[16px] border border-grey-300 p-[24px] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]">
        {
          {
            1: <SignUpStepOne defaultValues={firstStepData} onSubmit={onConfirmStepOne} />,
            2: (
              <SignUpStepTwo
                email={firstStepData?.email || ''}
                onClose={onBack}
                onConfirm={onConfirmStepTwo}
              />
            ),
            3: (
              <SignUpStepThree
                defaultValues={thirdStepData}
                firstName={firstStepData?.firstName || ''}
                onClose={onBack}
                onConfirm={onConfirmStepThree}
              />
            ),
            4: (
              <SignUpStepFour
                defaultValues={forthStepData}
                firstName={firstStepData?.firstName || ''}
                isLoading={fetchingApi}
                lastName={firstStepData?.lastName || ''}
                onClose={onBack}
                onConfirm={onConfirmStepFour}
              />
            ),
            //TODO: for Password Component
            5: (
              <SignUpStepFive
                defaultValues={fifthStepData}
                email={firstStepData?.email || ''}
                isLoading={fetchingApi}
                onClose={onBack}
                onConfirm={onConfirmStepFive}
              />
            ),
          }[stepper]
        }
      </div>
    </div>
  );
}

export default withRouter(SignUpForm);
