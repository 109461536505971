import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import { useUpdateNetworkAssetMutation } from 'app/modules/network/graphql/mutations/generated/updateNetworkAsset';
import { noteProps } from 'app/modules/network/types';
import { Note } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import NotesModal from './NotesModal';

interface NoteProps {
  id?: string;
  note?: Note | null;
  setNote: any;
}

const NotesDetail = (props: NoteProps) => {
  const { note, setNote, id } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [{ fetching }, onCreateAssetItem] = useUpdateNetworkAssetMutation();

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {note?.heading && note?.note ? (
        <Box sx={{ flexGrow: 1, marginTop: '2.25rem' }}>
          <Box className='flex justify-between items-center'>
          <Typography className='text-grey-900 font-semibold text-[18px]'>
                Notes
              </Typography>
              <Typography
                variant="body1"
                onClick={() => setIsOpen(true)}
                className='cursor-pointer text-[14px] text-blue-900 underline'
                >
                Update Notes
              </Typography>
          </Box>
          <Divider className='my-[16px]'/>
          <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
            <CardContent>
              <Stack direction="row" justifyContent="start">
                <Stack alignItems="center" direction="row" justifyContent="center">
                  <Stack className="ml-2" direction="col" justifyContent="center">
                    <Typography  className='text-[16px] text-grey-900 font-semibold'>{note?.heading}</Typography>
                    <Typography className='text-[14px] text-grey-800'>{note?.note}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Stack direction="row" justifyContent="center" style={{ height: '100%' }}>
          <Stack alignItems="center" direction="col" justifyContent="center">
            <Typography className='text-grey-900 text-[16px] font-medium'>Not tracking anything here, yet.</Typography>
            <Link
              style={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => {
                setIsOpen(true);
              }}>
              <Typography className='text-[14px] text-blue-900 mt-[8px]'>Add Some Notes</Typography>
            </Link>
          </Stack>
        </Stack>
      )}
      <NotesModal
        isOpen={isOpen}
        note={note}
        onCancel={onCancel}
        onSaveNote={(value: noteProps) => {
          setNote(value);
          setIsOpen(false);
          onCreateAssetItem({
            input: { note: value, mac: id },
          });
        }}></NotesModal>
    </>
  );
};

export default NotesDetail;
