import OrderLink from '../../../orders/components/OrderLink';
import { ToolTip } from '../../../../libs/ToolTip';
import { ShippingTransactionSchema } from '../../../../types/schema';
import ShipOrderIdToolTip from './ShipOrderIdToolTip';

interface Props {
  row: ShippingTransactionSchema;
}

const ShipOrderId = (props: Props) => {
  const { row } = props;

  const { orderRequest, id } = row;
  const { orderRequestCode, id: orderId, type } = orderRequest || {};

  if (!orderRequest || !orderId) {
    return <span>-</span>;
  }

  return (
    <ToolTip
      key={id + '-shipment-search-orderId-hover-tooltip'}
      placement="bottom"
      overlayClassName="procure-tooltip-overlay"
      overlay={() => <ShipOrderIdToolTip row={row} />}
      trigger={['hover']}>
      <div className="truncate">
        <OrderLink id={orderId} type={type}>
          {orderRequestCode}
        </OrderLink>
      </div>
    </ToolTip>
  );
};

export default ShipOrderId;
