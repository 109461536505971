import Loader from 'app/components/Loader';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { useNewAuditReportSiteQuery } from 'app/modules/reports/views/AuditReport/graphql/queries/generated/newAuditReportSite';
import { LocationStatusEnum, LocationTypeEnum } from 'app/types/schema';

import NewAuditReport from './NewAuditReport';
import { NewAuditReportProps } from './types';
import EntityManager from '../../../../../components/EntityManager';

const NewAuditReportContainer = (props: NewAuditReportProps) => {
  const { defaultSite } = useCurrentUser();

  const [{ fetching, data }] = useNewAuditReportSiteQuery({
    variables: { limit: 1, filters: { types: [LocationTypeEnum.Site] } },
    pause: Boolean(defaultSite?.id),
  });

  if (fetching) {
    return <Loader />;
  }

  const defaultSiteId = defaultSite?.status !== LocationStatusEnum.Deleted ? defaultSite?.id : '';
  const siteId = defaultSiteId;

  return (
    <EntityManager className='!rounded-bl-[12px] !rounded-br-[12px] !p-[0px]'>
      <NewAuditReport siteId={siteId} {...props} className='!rounded-bl-[12px] !rounded-br-[12px] pb-[16px]'/>
    </EntityManager>
  );
};

export default NewAuditReportContainer;
