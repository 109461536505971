import { OrderRequestSchema } from 'app/types/schema';
import { ORDER_REQUEST_TYPE } from '../../views/OrderRequest/components/OrderItemRequest/type';
import { OrderRequestInput } from '../../views/OrderRequest/components/OrderRequestForm/type';
import { Dispatch, SetStateAction } from 'react';

export type OrderRequestContentProps = { length: number };

export const ORDER_REQUEST_CONTENT_KEY = 'order_request_context_data';

export type OrderDataInStorage = {
  orderRequestType: ORDER_REQUEST_TYPE;
  orderItems: OrderRequestInput[];
  totalOrderItems?: number;
  partnerTenantId?: string;
} | null;

export interface OrderRequestContextType {
  // setOrderItemLength: (props: OrderRequestContentProps) => void;
  storeDataInStorage: (data: OrderDataInStorage) => void;
  setIsRecreateOrder: (isRecreateOrder: boolean) => void;
  isRecreateOrder: boolean;
  getDataFromStorage: () => OrderDataInStorage;
  getOrderItemsFromStore: (orderType: ORDER_REQUEST_TYPE) => OrderRequestInput[];
  getOrderRequestType: () => ORDER_REQUEST_TYPE | null;
  getPartnerTenantId: (orderType: ORDER_REQUEST_TYPE) => string | null;
  clearDataFromStorage: () => void;
  orderItemLength: number | undefined;
  orderDetails?: OrderRequestSchema | null,
  setOrderDetails: Dispatch<SetStateAction<OrderRequestSchema|null>>
}

export interface OrderRequestProviderProps {
  children: React.ReactNode;
}
