const Site = {
  Sites: 'Sites',
  NewSite: 'New Site',
  EditSite: 'Edit Site',
  FormLabels: {
    Name: 'Site Name',
    Description: 'Description',
    Code: 'Site Code',
    SiteManager: 'Site Manager(s)',
    SiteStaff: 'Site Staff(s)',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a Site Name up to 30 characters.',
    NameMinMaxLength: 'Please enter a Site Name between 3 to 30 characters.',
    DescriptionMaxLength: 'Please enter a Description up to 500 characters.',
    CodeMaxLength: 'Please enter a Site Code up to 30 characters.',
    SiteManagerLength:
      'Please select at least one Site Manager. If no Site Manager exists, please select a user to be the Site Manager for this Site. Go to Admin > Sites, select a Site to edit and add a Site Manager.',
  },
  FormPlaceholders: {
    Name: 'Enter site name',
    Description: 'Enter site description',
    Code: 'Enter site code',
    Search: 'searchAssets for site',
  },
};

export default Site;
