import React from 'react';
import { Box, Grid } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';

import DeviceAccount from './components/DeviceAccount';
import Hardware from './components/Hardware';
import NetworkDetail from './components/NetworkDetail';
import SystemInformationDetail from './components/SystemInformationDetail';
import SystemInsights from './components/SystemInsights';

const SystemDetail = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;
  return (
    <Box sx={{ flexGrow: 1, marginTop: '2.25rem' }}>
      <SystemInsights networkAssetDetail={networkAssetDetail} />
      <DeviceAccount networkAssetDetail={networkAssetDetail} />
      <Hardware networkAssetDetail={networkAssetDetail} />
      <Grid container columns={{ xs: 8, sm: 8, md: 8, lg: 12 }} spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <SystemInformationDetail networkAssetDetail={networkAssetDetail} />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <NetworkDetail networkAssetDetail={networkAssetDetail} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SystemDetail;
