import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useItemInStockData from 'app/modules/assetsInventory/hook/useItemInStockData';
import { getItemInStockIdFromParams } from 'app/modules/assetsInventory/utils/itemInStockParams';
import { FormItemLocationOption } from 'app/modules/locations/components/FormItemLocationSelect/types';
import { ItemTypeEnum } from 'app/types/schema';

const DEFAULT_VALUES = {
  itemInStockId: '',
  destinationSiteId: '',
  destinationLocationId: '',
  departmentId: '',
  actorId: '',
  startDate: '',
  endDate: '',
  meta: {
    note: '',
  },
  originalQuantity: 0,
  quantity: 0,
};

const initialState = {
  destinationSite: null,
  user: null,
  department: null,
};

interface State {
  destinationSite?: FormItemLocationOption | null;
  user: any;
  department: any;
}

export type UseReserveFormProps = {
  defaultFormValues?: {
    destinationSiteId: string;
    itemInStockId: string;
    destinationLocationId: string;
    departmentId: string | undefined;
    actorId: string;
    startDate?: string;
    endDate?: string;
    meta: {
      note: string;
      formattedNote?: string | null;
    };
    originalQuantity?: number;
    quantity: number;
    transactionId: string;
  };
};

const useReserveForm = ({ defaultFormValues }: UseReserveFormProps) => {
  const queryParamsItemInStockId = getItemInStockIdFromParams();
  const [editingId, setEditingTransactionId] = useState<string>('');
  const { control, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      itemInStockId: queryParamsItemInStockId || '',
    },
  });

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  useEffect(() => {
    if (defaultFormValues && !isEmpty(defaultFormValues)) {
      const { transactionId, quantity, originalQuantity, meta, ...rest } = defaultFormValues;
      const formValues = {
        ...rest,
        quantity: quantity > 0 ? quantity : originalQuantity,
        meta: {
          note: meta.formattedNote || meta.note,
        },
      };

      reset(formValues);
      setEditingTransactionId(transactionId);
    }
  }, [defaultFormValues]);

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const itemInStockId = watch('itemInStockId');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const destinationSiteId = watch('destinationSiteId');
  const quantity = watch('quantity');

  const { itemInStock } = useItemInStockData(itemInStockId || '', [
    ItemTypeEnum.Asset,
    ItemTypeEnum.AssetKit,
  ]);

  useEffect(() => {
    if (
      !(
        !isEmpty(defaultFormValues) &&
        defaultFormValues?.itemInStockId === itemInStockId &&
        destinationSiteId === defaultFormValues.destinationSiteId
      )
    ) {
      setValue('destinationSiteId', '');
      setValue('destinationLocationId', '');
    }
  }, [itemInStockId]);

  useEffect(() => {
    if (new Date(endDate) < new Date(startDate)) setValue('endDate', '');
  }, [startDate, endDate, setValue]);

  const onResetForm = useCallback(() => {
    setTimeout(() => {
      reset({
        ...DEFAULT_VALUES,
      });
      setState({
        ...initialState,
      });
    }, 0);
  }, [reset, setState]);

  return {
    formState: {
      control,
      itemInStockId,
      handleSubmit,
      quantity,
      destinationSiteId,
      setState,
      setValue,
      startDate,
      isEditing: !!editingId,
      editingId,
    },
    state,
    onResetForm,
    itemInStock,
  };
};

export type UseReserveFormReturnType = ReturnType<typeof useReserveForm>;

export default useReserveForm;
