import { useCallback, useMemo, useState } from 'react';
import Inventory from 'app/i18n/Inventory';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { useUserFilterQuery } from 'app/modules/inventory/graphql/queries/generated/userFilter';
import useLocations from 'app/modules/sites/hook/useLocations';
import {
  LocationTypeEnum,
  SortOrderEnum,
  TransactionFilters,
  TransactionTypeEnum,
} from 'app/types/schema';

import {
  getTransactionTypeSubTypeFilterById,
  transactionTypes,
} from '../../utils/transactionTypeFilter';

interface State {
  typeId: TransactionTypeEnum | string;
  destinationSiteId: string;
  destinationLocationId: string;
  sourceSiteId: string;
  sourceLocationId: string;
  userId: string;
  siteId?: string;
  locationId?: string;
}

const useTransactionHistoryState = (itemId: string) => {
  const [filterState, _setFilterState] = useState<State>({
    typeId: '-1',
    destinationSiteId: '-1',
    destinationLocationId: '-1',
    sourceSiteId: '-1',
    sourceLocationId: '-1',
    userId: '-1',
  });

  const setFilterState = useCallback((nextState: Partial<State>) => {
    _setFilterState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const typeSubTypeFilters = useMemo((): TransactionFilters => {
    return getTransactionTypeSubTypeFilterById(filterState.typeId);
  }, [filterState.typeId]);

  const transactionFilters = useMemo((): TransactionFilters => {
    return {
      entityIds: [itemId],
      destinationSiteIds:
        filterState.destinationSiteId !== '-1' ? [filterState.destinationSiteId] : [],
      sourceSiteIds: filterState.sourceSiteId !== '-1' ? [filterState.sourceSiteId] : [],
      createdByIds: filterState.userId !== '-1' ? [filterState.userId] : [],
      ...typeSubTypeFilters,
    };
  }, [filterState, typeSubTypeFilters]);

  const { table } = useEntityManager({
    selection: false,
    sortState: {
      id: 'createdAt',
      orderBy: 'desc',
    },
  });

  // Site Items
  const { items: siteItems } = useLocations({
    variables: {
      filters: {
        search: '',
        types: [LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant],
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const siteOptions = useMemo(() => {
    return [
      {
        id: '-1',
        name: Inventory.FormLabels.All,
      },
      ...siteItems,
    ];
  }, [siteItems]);

  // User Filter
  const [userResult] = useUserFilterQuery({
    variables: {
      sorts: [
        {
          sortField: 'firstName',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'lastName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const userOptions = useMemo(() => {
    return [
      {
        id: '-1',
        name: Inventory.FormLabels.All,
      },
      ...(userResult?.data?.users.edges || []).map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
        };
      }),
    ];
  }, [userResult?.data?.users.edges]);

  return {
    filterState,
    setFilterState,
    table,
    transactionTypes,
    transactionFilters,
    siteOptions,
    userOptions,
  };
};

export type UseTransactionHistoryStateReturnType = ReturnType<typeof useTransactionHistoryState>;
export default useTransactionHistoryState;
