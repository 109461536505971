import WarningProvider from 'app/components/WarningAlert';

import AssetsLayout from '../../modules/assets/layout';
import RestockAssets from '../../modules/assets/views/RestockAssets';

function RestockAssetsPage() {
  return (
    <AssetsLayout>
      <WarningProvider>
        <RestockAssets />
      </WarningProvider>
    </AssetsLayout>
  );
}

export default RestockAssetsPage;
