import { withRouter } from 'app/libs/navigation';
import WishlistListView from 'app/modules/wishlist/views/listView';

import WishlistLayout from '../../modules/wishlist/WishlistLayout';
function WishlistListViewPage() {
  return (
    <WishlistLayout>
      <WishlistListView />
    </WishlistLayout>
  );
}

export default withRouter(WishlistListViewPage);
