import { useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import Stack from 'app/ui-components/Stack';

import Common from '../../../../i18n/Common';
import Receivables from '../../../../i18n/Receivables';
import EntityManager from '../../../components/EntityManager';
import { getReceivedItemsInProgressColumns } from '../utils/columns';
import EditReceivableItem from './components/EditReceivableItem';
import ReceiveItemsConfirmation from './components/ReceiveItemsConfirmation';
import useReceiveState from './hook/useReceiveState';
import useSearchSuggestion from './hook/useSearchSuggestion';

const Receive = () => {
  const {
    table,
    rows,
    entities,
    setEntities,
    showConfirmModal,
    isEntitiesModified,
    rowCount,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    markingReceivedItems,
    handleMarkShipmentReceived,
    filterState,
    setFilterState,
    filteredEntities,
    onSubmit,
    closeConfirmation,
    setSearchStr,
    searchStr,
    siteOptions,
    onEdit,
    transactionData,
    resetTransactionData,
    onSiteControlScroll,
    hasErrorsInRows,
    vendorOptions,
  } = useReceiveState();

  const onCancelHandler = () => {
    setEntities({});
  };

  const hasSelectedRow = useMemo(() => !!table.state.selectedRowId, [table.state.selectedRowId]);

  const columns = useMemo(() => getReceivedItemsInProgressColumns(), []);
  const { searchSuggestions, setSearchValue, onScroll } = useSearchSuggestion();
  const onHandleSearch = (params: string) => {
    setSearchStr(params);
  };

  return (
    <EntityManager className="rounded-bl-[12px] rounded-br-[12px] !p-0 !pb-[24px]">
      <Box className="px-[16px] md:px-[24px] lg:px-[24px]">
        <EntityManager.Title
          hasTopDivider
          documentTitle={Receivables.Receive}
          title={Receivables.Receive}
        />
      </Box>
      <EntityManager.Table
        ignoreRelayPagination
        pagination
        persistSelectionData
        actions={table.actions}
        columns={columns}
        data={rows}
        extraProps={{
          entities,
          setEntities,
          filterState,
          setFilterState,
          siteOptions,
          onSiteControlScroll,
          vendorOptions,
        }}
        filterNodes={
          <Box className="flex !w-[100%] flex-wrap gap-[16px] md:!w-fit md:gap-[24px] lg:!w-fit">
            <EntityManager.Search
              isDropdown
              paginated
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              suggestionData={searchSuggestions?.length ? searchSuggestions : []}
              value={searchStr}
              onChange={(value: string) => onHandleSearch(value)}
              onChangeSearch={setSearchValue}
              onScroll={onScroll}
            />
          </Box>
        }
        loading={fetching}
        minWidth={0}
        paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
        persistKey="receive_table"
        rowHeight={85}
        setState={table.setState}
        state={table.state}
        testId="receive_table"
        total={rowCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
      />

      <Stack className="mt-24 flex gap-[16px] px-16" justifyContent="end">
        <>
          {!!hasSelectedRow ? (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!hasSelectedRow}
              loading={markingReceivedItems}
              theme="info"
              onClick={onEdit}>
              {Common.Actions.Edit}
            </Button>
          ) : (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={markingReceivedItems || !isEntitiesModified || !hasErrorsInRows}
              loading={markingReceivedItems}
              theme="success"
              onClick={onSubmit}>
              {Receivables.Actions.ReceiveItems}
            </Button>
          )}
        </>
        <Button
          classes="min-w-[94px] h-[44px]"
          disabled={!isEntitiesModified && !hasSelectedRow}
          onClick={hasSelectedRow ? resetTransactionData : onCancelHandler}>
          {Common.Actions.Cancel}
        </Button>
      </Stack>

      {showConfirmModal && (
        <ReceiveItemsConfirmation
          count={filteredEntities.length}
          onClose={closeConfirmation}
          onConfirm={handleMarkShipmentReceived}
        />
      )}

      {transactionData.open && (
        <EditReceivableItem transactionData={transactionData} onCancel={resetTransactionData} vendorOptions={vendorOptions} />
      )}
    </EntityManager>
  );
};

export default Receive;
