import Reports from 'app/i18n/Reports';
import AuditReportDetails from 'app/modules/reports/views/AuditReport/components/AuditReportDetails';

interface Props {
  id: string;
}

const InventoryAuditReportDetails = ({ id }: Props) => {
  return <AuditReportDetails id={id} title={Reports.InventoryAuditReport} />;
};

export default InventoryAuditReportDetails;
