import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { AddressesDetailsFragmentDoc } from '../../fragments/generated/addressDetails';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddressDetailsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AddressFilters>;
}>;


export type AddressDetailsQuery = { __typename?: 'Query', addresses: { __typename?: 'AddressConnection', totalCount: number, edges: Array<{ __typename?: 'AddressEdge', node: { __typename?: 'AddressSchema', addressLineOne: string, addressLineTwo?: string | null, addressName: string, city: string, companyId: string, state: string, country?: string | null, zipCode: string } }> } };


export const AddressDetailsDocument = gql`
    query addressDetails($filters: AddressFilters) {
  addresses(filters: $filters) {
    totalCount
    edges {
      node {
        ...AddressesDetails
      }
    }
  }
}
    ${AddressesDetailsFragmentDoc}`;

export function useAddressDetailsQuery(options?: Omit<Urql.UseQueryArgs<AddressDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<AddressDetailsQuery>({ query: AddressDetailsDocument, ...options });
};