import '../../style.css';
import { useEffect, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { Box, Card, CardContent, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import FormLabel from 'app/components/ProcureForm/FormLabel';
import FormSelectInput from 'app/components/ProcureForm/FormSelectInput';
import routes from 'app/consts/routes';
import Common from 'app/i18n/Common';
import { withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import CopyToClipboard from 'app/modules/wishlist/components/CopyToClipboard';
import ProductsTable from 'app/modules/wishlist/components/ProductsTable';
import WishlistFormSteps from 'app/modules/wishlist/components/WishlistFormSteps';
import { getWishlistFormSteps } from 'app/modules/wishlist/components/WishlistFormSteps/utils';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

import useGetImpactProductData from '../../hook/useGetImpactProductData';
import useSearchSuggestion from '../../hook/useSearchSuggestion';
import useWishlistActions from '../../hook/useWishlistActions';
import {
  CreateListProps,
  CreateListStepOneInput,
  VendorTypeEnum,
  WishListDetailType,
} from '../../types';
import StepFive from '../CreateListStepOne/StepFive';
import StepFour from '../CreateListStepOne/StepFour';
import StepOne from '../CreateListStepOne/StepOne';
import StepThree from '../CreateListStepOne/StepThree';
import StepTwo from '../CreateListStepOne/StepTwo';
import CreateListView from '../CreateListView';
import { removeExtraWhiteSpaces, scrollToTop } from '../utils/helper';

const CreateList = (props: CreateListProps) => {
  const { history, wishlistId, isCreatingProduct } = props;
  const [wishlistProductsPage, setWishlistProductsPage] = useState<number>(0);
  const [wishlistProductsPageSize, setWishlistProductsPageSize] = useState<number>(10);
  const [wishlistDetails, setWishlistDetails] = useState<WishListDetailType | undefined>();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [saveList, setSaveList] = useState<boolean>(false);
  const [vendorType, setVendorType] = useState<VendorTypeEnum>(VendorTypeEnum.WALMART);
  const [hasCollaborator, setHasCollaborator] = useState(false);
  const [formData, setFormData] = useState<CreateListStepOneInput>({
    firstName: '',
    lastName: '',
    wishlistName: '',
    collaborator: [],
    shippingAddress: {
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
    eventDate: null,
    shareId: '',
    description: '',
  });

  const {
    wishlistName,
    isAddingProduct,
    setIsEditing,
    isEditing,
    onAddProductHandler,
    selectedProducts,
    wishlistData,
    fetchingCreateWishlist,
    isProductSaving,
    itemQuantityUpdate,
    saveHandler,
    removeProductHandler,
    setIsAddingProduct,
    fetchingUpdateWishlist,
    firstStepData,
    setFirstStepData,
    stepper,
    setStepper,
    refetchWishList,
    paginationState,
    setPaginationState,
    wishlistDetailsFetching,
    itemPurchasedQuantityUpdate,
    setFormattedDescription,
  } = useWishlistActions({ listView: false, search: '', history, wishlistId, isCreatingProduct });

  const isAmazon = vendorType === VendorTypeEnum.AMAZON;

  useEffect(() => {
    if (firstStepData.firstName && firstStepData.lastName && firstStepData.wishlistName) {
      saveHandler();
    }
  }, [firstStepData, saveHandler]);

  const handlePageChange = (event: any, page: number) => {
    setWishlistProductsPage(page);
    scrollToTop();
  };

  const handleRowsPerPageChange = (event: any) => {
    const value = event.target.value;
    setWishlistProductsPage(0);
    setWishlistProductsPageSize(value);
    scrollToTop();
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const editViewHandler = () => {
    setIsEditing(true);
  };

  const addViewHandler = (data?: WishListDetailType) => {
    if (data) {
      setWishlistDetails(data);
    }

    setIsAddingProduct(true);
  };

  const { search } = useMultiSelectEntityManager({});

  const {
    data: affiliateData,
    loading,
    nextPageUrl,
  } = useGetImpactProductData({
    query: search.debouncedSearchText,
    pageSize: isAmazon ? 8 : paginationState.pageSize || 12,
    page: paginationState.page || 1,
    vendorType: vendorType,
  });

  const setPage = (page: number) => {
    setPaginationState((prev) => {
      return { ...prev, page: page };
    });
  };
  const setPageSize = (pageSize: number) => {
    setPaginationState((prev) => {
      return { ...prev, pageSize: pageSize };
    });
  };

  const { searchSuggestions, setSearchValue } = useSearchSuggestion(vendorType);

  const getPaginationIndexes = (page: number, pageSize: number) => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    return {
      startIndex,
      endIndex,
    };
  };

  const filteredSelectedProducts = useMemo(() => {
    const { startIndex, endIndex } = getPaginationIndexes(
      wishlistProductsPage,
      wishlistProductsPageSize,
    );

    const filteredItems = selectedProducts.slice(startIndex, endIndex);

    if (!filteredItems.length) {
      if (wishlistProductsPage) {
        setWishlistProductsPage(wishlistProductsPage - 1);
      }
    }

    return filteredItems;
  }, [selectedProducts, wishlistProductsPage, wishlistProductsPageSize]);

  useEffect(() => {
    if (wishlistData?.wishlists?.edges && !wishlistDetails) {
      const wishlistContent = wishlistData?.wishlists?.edges?.find((content) => content)?.node;
      const collaboratorsIds = wishlistContent?.collaborators
        .map((collaborator) => collaborator.userId?.toString())
        .filter(Boolean);
      const updatedWishlistContent = {
        ...wishlistContent,
        collaborators: collaboratorsIds,
      };
      const updatedList = {
        ...updatedWishlistContent,
        description: updatedWishlistContent.formattedDescription,
        showDescription: updatedWishlistContent.description,
      };

      setWishlistDetails(updatedList as WishListDetailType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistData]);

  const onNext = (stepValues: any) => {
    const objKey = Object.keys(stepValues);

    if (objKey[0] === 'description') {
      setFormattedDescription(stepValues.description);
    }

    setFormData((prevData: CreateListStepOneInput) => ({
      ...prevData,
      ...stepValues,
    }));

    if (activeStep < getWishlistFormSteps().length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setSaveList(true);
    }
  };

  const onPrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const onCancel = () => {
    history.push(routes.WishlistListView());
  };

  useEffect(() => {
    const onConfirmStepOne = async () => {
      const data = removeExtraWhiteSpaces(formData);
      setFirstStepData(data);
    };
    if (saveList) {
      onConfirmStepOne();
    }
  }, [saveList]);

  const [isExpanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  const renderWishlistFormStep = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (
          <StepOne
            formValues={formData}
            hasCollaborator={hasCollaborator}
            isLoading={isProductSaving}
            setHasCollaborator={setHasCollaborator}
            onCancel={onCancel}
            onNext={onNext}
          />
        );

      case 1:
        return (
          <StepTwo
            formValues={formData}
            isLoading={isProductSaving}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        );
      case 2:
        return (
          <StepThree
            formValues={formData}
            isLoading={isProductSaving}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        );
      case 3:
        return (
          <StepFour
            formValues={formData}
            isLoading={isProductSaving}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        );
      case 4:
        return (
          <StepFive
            formValues={formData}
            isLoading={isProductSaving}
            onPrevious={onPrevious}
            onSubmit={onNext}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      {
        {
          1: (
            <>
              <Stack alignItems="center" direction="column">
                <Box sx={{ width: { sm: '100%', md: '70%', lg: '55%' } }}>
                  <WishlistFormSteps activeStep={activeStep} />
                </Box>
                <Card
                  className="rounded-[24px] border-[1px] border-grey-300 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]"
                  sx={{ width: { sm: '100%', md: '60%', lg: '482px' }, padding: '8px' }}>
                  <CardContent>
                    <Typography
                      className="mb-[25px] text-center text-[30px] font-semibold text-grey-900"
                      component="div"
                      variant="h5">
                      Create a Wishlist
                    </Typography>
                    {renderWishlistFormStep(activeStep)}
                  </CardContent>
                </Card>
              </Stack>
            </>
          ),
          2: (
            <Stack className="mt-[16px]" direction="column" justifyContent="between">
              {isAddingProduct ? (
                <Stack className="stack-container">
                  <Box className="flex w-[300px] flex-col gap-y-[24px]">
                    <Box>
                      {/* <FormLabel className="text-[14px] text-grey-800 font-semibold">Select Vendor</FormLabel> */}
                      <FormSelectInput
                        formLabel="Select Vendor"
                        items={[
                          { label: 'Walmart', value: VendorTypeEnum.WALMART },
                          { label: 'Amazon', value: VendorTypeEnum.AMAZON },
                        ]}
                        label="Select Vendor"
                        value={vendorType}
                        onChange={(value) => {
                          setVendorType(value as VendorTypeEnum);
                        }}
                      />
                    </Box>
                    <EntityManager.Search
                      isDropdown
                      suggestionData={searchSuggestions || []}
                      onChangeSearch={(value)=>{
                        setSearchValue(value)
                        setPaginationState((prev) => {
                          return { ...prev, page: 0, pageSize: 8 };
                        });
                      }}
                      {...search}
                      autoFocus
                      removedSearchQoutedValue
                      placeholder="Search"
                      searchFieldWrapper="!md:max-w-[300px] !lg:max-w-[300px]"
                      startAdornment={
                        <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                          <CustomIcons.SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </Box>
                  <Stack
                    alignItems="center"
                    className="w-fit rounded-[16px] border border-grey-300 p-[24px] lg:h-[220px] lg:w-[460px]"
                    direction="column"
                    justifyContent="center"
                    width={{ xs: '100%', md: '60%' }}>
                    <Icon color="red" height="48px" icon="ph:gift-light" width="48px" />
                    <Typography
                      className="mb-4 mt-[16px] min-w-[120px] text-[18px] font-bold text-grey-900"
                      component="div"
                      variant="h6">
                      {wishlistDetails?.name}
                    </Typography>
                    {wishlistDetails?.shippingAddress && (
                      <Stack direction={'row'}>
                        <Typography className="mb-6 text-center text-[14px] font-medium text-grey-800">
                          {Object.values(wishlistDetails?.shippingAddress).join(' ')}
                        </Typography>
                        <Box className="ml-[8px]">
                          <CopyToClipboard
                            label="Shipping Address"
                            text={Object.values(wishlistDetails?.shippingAddress).join(' ')}
                          />
                        </Box>
                      </Stack>
                    )}
                    {wishlistDetails?.description && (
                      <p
                        className="mt-[8px] text-[14px] text-gray-600"
                        style={
                          wishlistDetails?.description.length > 200 ? { cursor: 'pointer' } : {}
                        }
                        onClick={toggleExpand}>
                        {isExpanded
                          ? wishlistDetails?.description
                          : wishlistDetails?.description.length > 200
                          ? wishlistDetails?.description.slice(0, 200) + '...'
                          : wishlistDetails?.description}
                      </p>
                    )}
                  </Stack>
                </Stack>
              ) : null}
              {isAddingProduct ? (
                false ? (
                  <Loader />
                ) : (
                  <ProductsTable
                    addProductHandler={onAddProductHandler}
                    data={affiliateData}
                    isAmazon={isAmazon}
                    loading={loading}
                    nextPageUrl={nextPageUrl}
                    paginationState={paginationState}
                    selectedProducts={selectedProducts}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    wishlistDetails={wishlistDetails}
                    wishlistId={wishlistId}
                    wishlistName={wishlistName}
                  />
                )
              ) : (
                <CreateListView
                  addViewHandler={addViewHandler}
                  cancelEdit={cancelEdit}
                  editViewHandler={editViewHandler}
                  fetchingCreateWishlist={fetchingCreateWishlist || fetchingUpdateWishlist}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  isEditing={isEditing}
                  itemPurchasedQuantityUpdate={itemPurchasedQuantityUpdate}
                  itemQuantityUpdate={itemQuantityUpdate}
                  loading={loading || wishlistDetailsFetching}
                  pageNumber={wishlistProductsPage}
                  refetch={() => {
                    refetchWishList();
                  }}
                  removeProductHandler={removeProductHandler}
                  rowsPerPage={wishlistProductsPageSize}
                  saveHandler={saveHandler}
                  selectedProducts={filteredSelectedProducts || []}
                  setIsEditing={(value) => setIsEditing(value)}
                  totalProducts={selectedProducts.length}
                  wishlistDetails={wishlistDetails}
                  wishlistId={wishlistId || ''}
                  wishlistName={wishlistName}
                />
              )}
            </Stack>
          ),
        }[stepper]
      }
    </>
  );
};

export default withRouter(CreateList);
