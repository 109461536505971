export const enum ExportReportEntityTypeEnum {
  ASSET_SITE = 'assetSite',
  INVENTORY_SITE = 'inventorySite',
  ASSET_INVENTORY_SITE = 'assetSite',
  ASSET_COST = 'assetCost',
  INVENTORY_COST = 'inventoryCost',
  ASSET_TRANSACTION = 'assetTransaction',
  INVENTORY_TRANSACTION = 'inventoryTransaction',
  ASSET_AGING = 'assetAging',
  INVENTORY_AGING = 'inventoryAging',
  ASSET_ACCOUNTING = 'assetAccounting',
  INVENTORY_ACCOUNTING = 'inventoryAccounting',
  ASSET_INVENTORY_ACCOUNTING = 'assetInventoryAccounting',
  ASSET_CUSTOM = 'assetCustom',
  INVENTORY_CUSTOM = 'inventoryCustom',
  NETWORK_ASSET = 'networkAsset'
}

export const enum ExportReportNameEnum {
  CostReport = 'CostReport',
  SiteReport = 'SiteReport',
  LowStockReport = 'LowStockReport',
  TransactionReport = 'TransactionReport',
  AgingReport = 'AgingReport',
  AccountingReport = 'AccountingReport',
  CustomReport = 'CustomReport',
  AuditReport = 'AuditReport',
  ItAssetReport = 'ITAssetReport',
  OrderRequestReport = 'OrderRequestReport',
}

export const enum ItemType {
  Asset = 'asset',
  Inventory = 'inventory'
}

export const enum EntityType {
  User = 'user'
}