import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string
};

const ProcessForm = (props: Props) => {
  const { control,className="mt-0" } = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Process.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="processAll"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Process.All}
                placeholder={Network.FormLabels.Process.All}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="processBlocked"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Process.Blocked}
                placeholder={Network.FormLabels.Process.Blocked}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="processRunning"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Process.Running}
                placeholder={Network.FormLabels.Process.Running}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="processSleeping"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Process.Sleeping}
                placeholder={Network.FormLabels.Process.Sleeping}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="processUnknown"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Process.Unknown}
                placeholder={Network.FormLabels.Process.Unknown}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProcessForm;
