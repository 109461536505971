import { Ref, useCallback, useEffect, useRef, useState } from 'react';
import { cx } from '@emotion/css';
import { uniqBy } from 'lodash';
import Link from 'app/components/Link';
import usePagination from 'app/hooks/usePagination';
import {
  AffiliateConfigurationEdge,
  AffiliateConfigurationStatusEnum,
  SortOrderEnum,
} from 'app/types/schema';
import Box from 'app/ui-components';

import { useAffiliateMerchantsQuery } from '../../graphql/queries/generated/affiliateMerchants';
import { Cards, MerchantLogo } from './styles';
import { Grid } from '@mui/material';

const AffiliateMerchants = () => {
  const [merchants, setMerchants] = useState<any[]>([]);
  const [activePage, setActivePage] = useState(0);

  const {
    data: optionsData,
    fetching: isLoading,
    onNextPage,
  } = usePagination(
    useAffiliateMerchantsQuery,
    {
      filters: { statuses: [AffiliateConfigurationStatusEnum.Active] },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    {
      edgeKey: 'affiliateConfigurations',
      pageSize: 20,
    },
  );

  useEffect(() => {
    const _merchants = optionsData?.affiliateConfigurations
      ?.edges as unknown as AffiliateConfigurationEdge[];
    if (_merchants?.length > 0) {
      if (activePage === 0) {
        setMerchants(_merchants);
      } else if (!isLoading) {
        setMerchants(uniqBy([...merchants, ..._merchants], (merchant) => merchant?.node?.id));
      }
    }
  }, [optionsData, isLoading, activePage]);

  const endOfList = useRef();

  const _handleRedirectShop = useCallback((url: string) => {
    let _url = url;
    if (!url?.startsWith('http')) {
      _url = `http://${url}`;
    }
    window?.open(_url, '_blank');
  }, []);

  const loadItems = () => {
    const element = endOfList.current;
    if (!element) return;

    const position = (element as unknown as HTMLElement)?.getBoundingClientRect();

    if (position.top >= 0 && position.bottom <= window.innerHeight && onNextPage) {
      onNextPage && onNextPage();
      setActivePage(activePage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', loadItems);

    return () => {
      window.removeEventListener('scroll', loadItems);
    };
  }, [optionsData?.affiliateConfigurations?.edges?.length]);

  return (
    <>
      <Box
        className={'flex w-full flex-wrap justify-center gap-[24px]'}
        data-testid="merchants-list">
        {merchants?.map((item: AffiliateConfigurationEdge, index: number) => {
          return (
            <Box key={index} className={cx('w-full min-w-[310px] max-w-[310px]')}>
              <Link
                className={cx(
                  'box-border flex min-h-[210px] w-[100%] cursor-pointer items-center justify-center overflow-hidden rounded-[16px] border border-grey-300 p-[24px]',
                  MerchantLogo,
                )}
                style={{
                  boxShadow:
                    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                }}
                onClick={() => _handleRedirectShop(item?.node?.affiliateUrl || '')}>
                {item?.node?.affiliateUrl ? (
                  <img alt="merchant-logo" className={'max-h-[100%]'} src={item?.node?.logoUrl} />
                ) : (
                  <Box className={'flex h-[100%] items-center justify-center text-[14px]'}>
                    <p>Image not found.</p>
                  </Box>
                )}
              </Link>
              <Link
                className={
                  'mt-[8px] mb-[4px] block cursor-pointer overflow-hidden text-ellipsis whitespace-normal text-center text-[14px] text-blue-900 hover:text-[#3366CC] '
                }
                title={item?.node?.name}
                onClick={() => _handleRedirectShop(item?.node?.affiliateUrl || '')}>
                {item?.node?.name}
              </Link>
              {item?.node?.commission ? (
                <p className={'text-center text-[12px] text-grey-800'}>
                  Gives up to {item?.node?.commission.toFixed(2)}%
                </p>
              ) : null}
            </Box>
          );
        })}
      </Box>
      <Box ref={endOfList as unknown as Ref<HTMLDivElement>} id="end-of-list" />
    </>
  );
};
export default AffiliateMerchants;
