import { omit } from 'lodash';

import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  regexValidator,
  textMaxLengthValidator,
  textValidator,
} from '../../../../../components/Form/utils/validators';
import Assets from '../../../../../i18n/Assets';
import Inventory from '../../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../../types/schema';
import { upcCodeOrAsinCodeValidator } from '../../../../assetsInventory/utils/rules';

export const INVENTORY_TYPE_ITEMS = [
  {
    label: 'New Product',
    value: ItemTypeEnum.Inventory,
  },
  {
    label: 'New Kit',
    value: ItemTypeEnum.InventoryKit,
  },
];

export const INVENTORY_FORM_RULES = {
  type: {
    validate: textValidator(Inventory.FormValidationMessages.TypeRequired),
  },

  upc: {
    validate: upcCodeOrAsinCodeValidator,
  },
  title: {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.TitleRequired),
      textMaxLengthValidator(Inventory.FormValidationMessages.TitleRequired, 32),
    ),
  },
  categoryId: {
    validate: textValidator(Inventory.FormValidationMessages.CategoryRequired),
  },
  destinationSiteId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteRequired),
  },
  destinationLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteLocationRequired),
  },
  unitCost: {
    validate: composeValidators(
      numberValidator(Inventory.FormValidationMessages.InvalidCost),
      numberMinValidator(Inventory.FormValidationMessages.CostMinRequired, 0),
    ),
  },
  costOverride: {
    validate: composeValidators(
      numberValidator(Inventory.FormValidationMessages.InvalidCostOverride),
      regexValidator(Assets.FormValidationMessages.CostOverrideInvalid, /^\d{1,3}(\.\d{1,2})?$/),
      numberMinValidator(Inventory.FormValidationMessages.InvalidCostOverride, 0),
      numberMaxValidator(Inventory.FormValidationMessages.InvalidCostOverride, 999.99),
    ),
  },
  quantity: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberValidator(Assets.FormValidationMessages.QuantityInvalid),
    ),
  },
};
