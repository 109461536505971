import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { ExportReportEntityTypeEnum, ExportReportNameEnum } from 'app/consts/enum';
import Reports from 'app/i18n/Reports';
import { getItAssetDeviceType, getItAssetStatusType, getItAssetWarrantyType } from 'app/modules/network/utils';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import { SortOrderEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

import useReportCSVExport from '../../hooks/useReportCSVExport';
import ITAssetReportForm from './components/ITAssetReportForm';
import { ITAssetReportFormValues } from './components/ITAssetReportForm/types';
import ITAssetTable from './components/ITAssetTable';

const ITAssetReport = () => {
  const [values, setValues] = useState<ITAssetReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values) {
      return <ITAssetTable values={values} />;
    }
  };

  const onSubmit = useCallback(
    (nextValues: ITAssetReportFormValues | null) => {
      if (nextValues?.format === 'csv') {
        const filters = {
          networkAssetFilters: {
            batteryManufacturer: nextValues.batteryManufacturer,
            biosReleaseDate: nextValues.biosReleaseDate,
            biosVersion: nextValues.biosVersion,
            cpuBrand: nextValues.cpuBrand,
            cpuManufacturer: nextValues.cpuManufacturer,
            graphicsModel: nextValues.graphicsModel,
            motherboardModel: nextValues.motherboardModel,
            operatingSystem: nextValues.operatingSystem,
            search: nextValues.search,
            software: nextValues.software,
            statuses: getItAssetStatusType(nextValues?.status),
            storageName: nextValues.storageName,
            systemModel: nextValues.systemModel,
            systemManufacturer: nextValues.systemManufacturer,
            warrantyTypes: getItAssetWarrantyType(nextValues.warrantyStatus),
            types: getItAssetDeviceType(nextValues?.deviceType),
          },
        };
        const sorts = [{ sortField: 'updatedAt', sortOrder: camelCase(SortOrderEnum.Desc) }];

        executeExport({
          entityType: ExportReportEntityTypeEnum.NETWORK_ASSET,
          filters,
          sorts,
          userTimezone: getCurrentTimeZoneValue(),
          reportName: ExportReportNameEnum.ItAssetReport
        });
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer
      stackWrapperClass="rounded-tr-[12px] rounded-br-[12px]"
      title={Reports.ItAssetReport}>
      <ITAssetReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null}
    </ReportContainer>
  );
};

export default ITAssetReport;
