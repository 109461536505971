import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ProjectsTableRowFragmentDoc } from '../../fragments/generated/ProjectsTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ProjectFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ProjectsTableQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges?: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'ProjectSchema', id: string, name: string, description?: string | null, projectCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } }> | null } };


export const ProjectsTableDocument = gql`
    query projectsTable($filters: ProjectFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  projects(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...ProjectsTableRow
      }
    }
  }
}
    ${ProjectsTableRowFragmentDoc}`;

export function useProjectsTableQuery(options?: Omit<Urql.UseQueryArgs<ProjectsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<ProjectsTableQuery>({ query: ProjectsTableDocument, ...options });
};