import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import InventoryAuditReportDetails from 'app/modules/reports/views/AuditReport/views/InventoryAuditReportDetails';

const InventoryAuditReportDetailsPage = (props: RouteComponentProps<{ id: string }>) => {
  const { match } = props;

  return <InventoryAuditReportDetails id={match.params.id} />;
};

export default withRouter(InventoryAuditReportDetailsPage);
