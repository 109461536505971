import {
  composeValidators,
  numberMinValidator,
  numberValidator,
  textValidator,
} from 'app/components/Form/utils/validators';

import Inventory from '../../../../../i18n/Inventory';
import { itemInStockValidator } from '../../../utils/rules';

export const RESTOCK_FORM_RULES = {
  itemInStock: itemInStockValidator,
  itemInStockSearch: {
    validate: textValidator(Inventory.FormValidationMessages.ItemInStockSearchRequired),
  },
  destinationId: {
    validate: textValidator(Inventory.FormValidationMessages.ToSiteRequired),
  },
  destinationLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.ToLocationRequired),
  },
  quantity: {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Inventory.FormValidationMessages.QuantityInvalid, 1),
    ),
  },
};
