import React, { CSSProperties, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import FormFileUpload from '../../../../../../components/Form/FormFileUpload';
import UploadedFilePreview from '../../../../../../components/Form/FormFileUpload/UploadedFilePreview';
import Inventory from '../../../../../../i18n/Inventory';
import {
  prepareItemsForFilePreview,
  prepareKitItemAttachments,
} from '../../../../../assets/hook/useAssetForm/utils';
import useKitSummary from '../../../../../components/KitSummary/context/useKitSummary';
import { InventoryFormProps } from '../../types';
import { thumbsContainer } from '../../../../../../components/Form/FormFileUpload/utils';

const InventoryAttachmentField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control, attachmentInputProps, isItemTypeProduct, isItemTypeKit, editable } = formState;

  const { kitState } = useKitSummary();

  const kitRowsAttachments = useMemo(() => {
    const attachments = kitState?.rows?.flatMap((row) => row?.attachments);
    return prepareKitItemAttachments(attachments);
  }, [kitState?.rows]);

  const kitAttachments = useMemo(() => {
    if (editable) {
      return attachmentInputProps?.attachments;
    } else {
      return kitState?.formState?.defaultAttachments;
    }
  }, [attachmentInputProps?.attachments, editable, kitState?.formState?.defaultAttachments]);

  const kitPreviewFileAttachments = useMemo(() => {
    return prepareItemsForFilePreview([
      ...attachmentInputProps?.attachments,
      ...kitRowsAttachments,
    ]);
  }, [attachmentInputProps?.attachments, kitRowsAttachments]);

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="attachments"
        render={({ field, fieldState }) => (
          <FormFileUpload
            {...field}
            {...attachmentInputProps}
            attachments={isItemTypeKit ? kitAttachments : attachmentInputProps.attachments}
            disabled={disabled}
            error={fieldState.error}
            label={Inventory.FormLabels.Attachments}
            previewImages={isItemTypeProduct}
          />
        )}
      />
      {isItemTypeKit && (
        <aside style={thumbsContainer as CSSProperties}>
          <UploadedFilePreview
            disabled={disabled || !editable}
            files={kitPreviewFileAttachments}
            removeFileById={attachmentInputProps?.removeFileById}
          />
        </aside>
      )}
    </div>
  );
};

export default InventoryAttachmentField;
