import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderItemsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderItemsQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', totalCount: number, edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', id: string, cost?: number | null, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'AssetKitItemSchema', id: string, cost?: number | null, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, cost?: number | null, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } | { __typename?: 'InventoryKitItemSchema', id: string, cost?: number | null, sku: string, title: string, type: Types.ItemTypeEnum, attachments?: Array<{ __typename?: 'MediaSchema', url?: string | null }> | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const OrderItemsDocument = gql`
    query orderItems($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  items(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ... on AssetItemSchema {
          id
          cost
          sku
          attachments {
            url
          }
          title
          type
        }
        ... on AssetKitItemSchema {
          id
          cost
          sku
          attachments {
            url
          }
          title
          type
        }
        ... on InventoryItemSchema {
          id
          cost
          sku
          attachments {
            url
          }
          title
          type
        }
        ... on InventoryKitItemSchema {
          id
          cost
          sku
          attachments {
            url
          }
          title
          type
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useOrderItemsQuery(options?: Omit<Urql.UseQueryArgs<OrderItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderItemsQuery>({ query: OrderItemsDocument, ...options });
};