import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import { SelectOption } from 'app/components/Select/types';
import { reportDateRangeSelectorStyles } from 'app/modules/reports/components/ReportDateRangeSelector/styles';
import { Maybe } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { renderOrderRequestDateInput } from './renderers';

interface Props {
  control: any;
  setValue: any;
  getValues: any;
}

const getOptions = (): SelectOption[] => {
  return [
    { value: 'none', label: 'None' },
    // { value: 'on', label: 'On' },
    // { value: 'before', label: 'Before' },
    // { value: 'after', label: 'After' },
    // { value: 'between', label: 'Between' },
    { value: 'between', label: 'Select Date Range' },
  ];
};

const OrderRequestDateField = (props: Props) => {
  const { control, setValue, getValues } = props;

  return (
    <Controller
      control={control}
      name="orderRequestDateType"
      render={({ field, fieldState }) => {
        const value = field.value || 'none';

        const onChange = (value?: Maybe<string>) => {
          setValue('orderRequestDateStart', null);
          setValue('orderRequestDateEnd', null);
          field.onChange(value === 'none' ? null : value);
        };

        return (
          <Stack className={reportDateRangeSelectorStyles} direction="col" spacing="space-y-16">
            <FormSelect
              error={fieldState.error}
              label="Order Request Date"
              options={getOptions()}
              value={value}
              onChange={onChange}
            />
            {renderOrderRequestDateInput({ value, control, setValue })}
          </Stack>
        );
      }}
      rules={{
        validate: (value) => {
          if (!value) {
            return true;
          } else if (value === 'between' || value === 'on') {
            const values = getValues();

            if (!values.orderRequestDateStart || !values.orderRequestDateEnd) {
              return value === 'on' ? 'Please enter a date.' : 'Please enter a start and end date.';
            }

            if (
              value === 'between' &&
              new Date(values.orderRequestDateStart) > new Date(values.orderRequestDateEnd)
            ) {
              return 'Start date must be before end date';
            }
          } else if (value === 'before') {
            const values = getValues();
            if (!values.orderRequestDateEnd) {
              return 'Please enter a date.';
            }
          } else if (value === 'after') {
            const values = getValues();
            if (!values.orderRequestDateStart) {
              return 'Please enter a date.';
            }
          }
        },
      }}
    />
  );
};

export default OrderRequestDateField;
