interface Props {
  user?: {
    id: string;
    name: string;
  };
}
const UserTableNode = (props: Props) => {
  const { user } = props;

  if (!user) {
    return null;
  }
  return (
    <div className="truncate" title={user.name}>
      {user.name}
    </div>
  );
};

export default UserTableNode;
