import { Maybe } from '../../../types/schema';
import { getCostWithCurrency } from '../../../utils/cost';

interface Props {
  cost?: Maybe<number> | null;
  classes?: string;
}

const TableCostView = (props: Props) => {
  const { cost, classes } = props;

  if (cost === undefined || cost === null) {
    return null;
  }

  return <div className={`text-right ${classes}`}>{getCostWithCurrency(Number(cost))}</div>;
};

export default TableCostView;
