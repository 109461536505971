import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { DateFormatEnum, formatDate } from 'app/utils/date';

export const getManufacturerTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Manufacturer',
    sortable: true,
    value: 'name',
  },
  {
    id: 'url',
    label: 'Website',
    sortable: true,
    value: 'url',
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    value: 'emailId',
  },
  {
    id: 'phone',
    label: 'Contact Number',
    sortable: true,
    value: 'phoneNo',
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    sortable: true,
    value: 'updatedAt',
    valueNode: ({ row }) => <>{formatDate(row.updatedAt, DateFormatEnum.FullDate)}</>,
  },
];
