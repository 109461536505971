import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormInputSignUp from 'app/components/Form/FormInputSignUp';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import { SIGNUP_FORM_RULES as rules } from '../rules';
import { SignUpStepFiveProps } from '../types';

function SignUpStepFive(props: SignUpStepFiveProps) {
  const { onConfirm, onClose, email, isLoading, defaultValues } = props;

  // const [passwordVisibile, setPasswordVisibility] = useState(false);
  const { control, handleSubmit, getValues } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onConfirm), [handleSubmit, onConfirm]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <h1 className="mt-12 text-center text-[50px] font-bold text-[gray]">
        {SignUp.FormLabels.stepFiveTitle}
      </h1>
      <p className="mt-12 text-center text-[28px] text-[#707070]">
        To sign into Procure, you'll use your email address &#40;
        <label className="cursor-pointer text-[#6cf] underline">{email}</label>
        &#41; and a password.
      </p>

      <p className="mt-16 text-center text-[28px] text-[#707070]">
        Please enter and confirm your password.
      </p>

      <Box className="mx-auto max-w-[450px] space-y-24 px-20 py-40">
        <Box className="flex space-x-32">
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="flex-1"
                error={fieldState.error}
                label={SignUp.FormLabels.Password}
                type="password"
              />
            )}
            rules={rules.password}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="flex-1"
                error={fieldState.error}
                label={SignUp.FormLabels.ConfirmPassword}
                type="password"
              />
            )}
            rules={{
              required: SignUp.FormValidations.ConfirmPassword,
              validate: (value) =>
                value === getValues('password') ||
                SignUp.FormValidations.ConfirmNewPasswordNotMatched,
            }}
          />
        </Box>
        <Stack className="flex justify-between" spacing="space-x-32">
          <Button classes="bg-gray !h-56 !text-[28px]" onClick={onClose}>
            {SignUp.FormActions.previous}
          </Button>
          <Button
            classes="!h-56 !text-[28px]"
            loading={isLoading}
            theme="success"
            onClick={onFormSubmit}>
            {SignUp.FormActions.next}
          </Button>
        </Stack>
      </Box>
      <Box className="mt-48 flex justify-center">
        <p className="max-w-[600px] text-center text-[20px] text-[#707070]">
          {SignUp.FormLabels.termsAndPolicy}
          <a
            className="text-[#6cf]"
            href="https://www.procure.co/index.php/terms-of-service/"
            rel="noreferrer"
            target="_blank">
            {SignUp.FormLabels.termLabel}
          </a>
          and
          <a
            className="text-[#6cf]"
            href="https://www.procure.co/index.php/privacy-policy/"
            rel="noreferrer"
            target="_blank">
            {SignUp.FormLabels.policyLabel}
          </a>
        </p>
      </Box>
    </form>
  );
}

export default SignUpStepFive;
