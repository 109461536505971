import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteRolesMutationVariables = Types.Exact<{
  input: Types.DeleteRolesInput;
}>;


export type DeleteRolesMutation = { __typename?: 'Mutation', deleteRoles: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteRolesDocument = gql`
    mutation deleteRoles($input: DeleteRolesInput!) {
  deleteRoles(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteRolesMutation() {
  return Urql.useMutation<DeleteRolesMutation, DeleteRolesMutationVariables>(DeleteRolesDocument);
};