import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import Assets from 'app/i18n/Assets';

import { AssetFieldProps } from '../../types';

const AssetBrandField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeAsset } = formState;

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="brand"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            error={fieldState.error}
            isDisabled={disabled}
            label={Assets.FormLabels.Brand}
          />
        )}
      />
    </div>
  );
};

export default AssetBrandField;
