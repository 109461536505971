import AdminLayout from 'app/components/AdminLayout';
import Sites from 'app/modules/sites/views/Sites';

function SitesPage() {
  return (
    <AdminLayout>
      <Sites />
    </AdminLayout>
  );
}

export default SitesPage;
