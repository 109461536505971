import {
  composeValidators,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Project from 'app/i18n/Project';

export const PROJECT_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Project.FormValidationMessages.NameRequired),
      textMinLengthValidator(Project.FormValidationMessages.NameRequired, 3),
      textMaxLengthValidator(Project.FormValidationMessages.NameRequired, 30),
    ),
  },
  description: {
    validate: textMaxLengthValidator(Project.FormValidationMessages.DescriptionMaxLength, 500),
  },
};
