import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Contact from 'app/i18n/Contact';
import ContactSearch from 'app/modules/contacts/components/contactSearch';
import PageTitle from 'app/ui-components/Title';

import AccessControl from '../../../../components/AccessControl';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import DuplicateFormWrapper from '../../components/duplicateFormWrapper';

const ContactView = () => {
  const { workspacePermissions } = useCurrentUser();

  return (
    <>
      <PageTitle title={Contact.Contact} />
      <Box className="w-full border-x-[1px] border-grey-300 bg-white">
        <Divider />
      </Box>
      <Box
        // container
        // columnSpacing={'48px'}
        className="mb-[24px] flex flex-wrap gap-[48px] rounded-bl-[12px] rounded-br-[12px] bg-transparent pb-[40px]">
        <Box className="m-[auto] w-[320px] md:min-w-[380px] lg:m-[0px] lg:max-w-[380px] xl:m-[0px] xl:max-w-[380px]">
          <AccessControl
            action={AllowedPermissionActionsEnum.Read}
            permissions={workspacePermissions}
            subject={AllowedPermissionsSubjectEnum.Contact}>
            <ContactSearch />
          </AccessControl>
        </Box>
        <Box className="w-[320px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]">
          <AccessControl
            action={AllowedPermissionActionsEnum.Read}
            permissions={workspacePermissions}
            subject={AllowedPermissionsSubjectEnum.Contact}>
            <DuplicateFormWrapper form={'Contact'} />
          </AccessControl>
        </Box>
      </Box>
    </>
  );
};

export default ContactView;
