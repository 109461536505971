import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';

type Props = {
  control: any;
  className?:string
};

const SystemForm = (props: Props) => {
  const { control,className="mt-0" } = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'
      >
        {Network.FormLabels.System.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="systemManufacturer"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.System.Manufacturer}
                placeholder={Network.FormLabels.System.Manufacturer}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="systemModel"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.System.Model}
                placeholder={Network.FormLabels.System.Model}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="systemSerial"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.System.Serial}
                placeholder={Network.FormLabels.System.Serial}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="systemVirtual"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.System.Virtual}
                placeholder={Network.FormLabels.System.Virtual}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SystemForm;
