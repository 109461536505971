import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateNetworkAssetMutationVariables = Types.Exact<{
  input: Types.CreateNetworkAssetInput;
}>;


export type UpdateNetworkAssetMutation = { __typename?: 'Mutation', createNetworkAsset: { __typename?: 'CreateNetworkAssetPayload', success: boolean } };


export const UpdateNetworkAssetDocument = gql`
    mutation updateNetworkAsset($input: CreateNetworkAssetInput!) {
  createNetworkAsset(input: $input) {
    success
  }
}
    `;

export function useUpdateNetworkAssetMutation() {
  return Urql.useMutation<UpdateNetworkAssetMutation, UpdateNetworkAssetMutationVariables>(UpdateNetworkAssetDocument);
};