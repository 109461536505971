import {
  labelValidator,
  numberValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Label from 'app/i18n/Label';

import { LabelTypeEnum } from '../types';

export const getValidationRules = (type: string | undefined): any => {
  const LabelType = {
    validate: textValidator(Label.FormValidationMessages.LabelTypeRequired),
  };
  const LabelFormat = {
    validate: textValidator(Label.FormValidationMessages.LabelFormatRequired),
  };
  const LabelStock = {
    validate: textValidator(Label.FormValidationMessages.LabelStockRequired),
  };
  const Site = {
    validate: textValidator(Label.FormValidationMessages.SiteRequired),
  };
  const DestinationSite = {
    validate: textValidator(Label.FormValidationMessages.DestinationSiteRequired),
  };
  const Container = {
    validate: textValidator(Label.FormValidationMessages.ContainerTypeRequired),
  };
  const LabelCount = {
    validate: labelValidator(Label.FormValidationMessages.LabelCountRequired),
  };
  const LabelSite = {
    validate: textValidator(Label.FormValidationMessages.LabelSiteRequired),
  };
  const LabelRacks = {
    validate: numberValidator(Label.FormValidationMessages.LabelRacksRequired),
  };
  const LabelShelves = {
    validate: numberValidator(Label.FormValidationMessages.LabelShelvesRequired),
  };

  const rules = {
    LabelType: { validate: true },
    LabelFormat: { validate: true },
    LabelStock: { validate: true },
    Site: { validate: true },
    DestinationSite: { validate: true },
    Container: { validate: true },
    LabelCount: { validate: true },
    LabelSite: { validate: true },
    LabelRacks: { validate: true },
    LabelShelves: { validate: true },
  };
  switch (type) {
    case LabelTypeEnum.Inventory:
    case LabelTypeEnum.InventoryKit:
    case LabelTypeEnum.AssetKit:
    case LabelTypeEnum.Asset:
      return { ...rules, LabelType, LabelFormat, LabelStock };

    case LabelTypeEnum.Location:
      return { ...rules, LabelType, Site, LabelStock };

    case LabelTypeEnum.Shipping:
      return { ...rules, LabelType, LabelStock };

    case LabelTypeEnum.ShippingSetUp:
      return { ...rules, LabelType, DestinationSite, Container, LabelCount };

    case LabelTypeEnum.WarehouseSetup:
      return { ...rules, LabelType, LabelStock, LabelSite, LabelRacks, LabelShelves };

    default:
      return { ...rules };
  }
};
