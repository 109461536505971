import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import FormLabel from 'app/components/ProcureForm/FormLabel';
import Orders from 'app/i18n/Orders';
import FormCostInput from 'app/modules/components/FormCostInput';

import { ORDER_INSERT_TYPE, OrderFieldProps } from '../../type';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

const OrderCostPerUnitField = (props: OrderFieldProps) => {
  const { formState, disabled, formLabel, isRequired } = props;
  const { control, itemType } = formState;

  const isItemInStockInsertType = useMemo(() => {
    return itemType === ORDER_INSERT_TYPE.ITEM_IN_STOCK;
  }, [itemType]);

  return (
    <>
      <div className="mb-20 flex">
        <div className="ml-[40px] flex-auto">
          <Controller
            control={control}
            name="cost"
            render={({ field, fieldState }) => (
              <FormCostInput
                {...field}
                error={fieldState.error}
                isDisabled={isItemInStockInsertType || disabled}
                label={Orders.FormLabels.CostPerUnit}
              />
            )}
            rules={ORDER_REQUEST_FORM_RULES.cost}
          />
        </div>
      </div>
    </>
  );
};

export default OrderCostPerUnitField;
