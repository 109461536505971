import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';

const OrderIdField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="orderId"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            disabled
            label={Orders.FormLabels.OrderId}
            formLabel={Orders.FormLabels.OrderId}
          />
        )}
      />
    </div>
  );
};

export default OrderIdField;
