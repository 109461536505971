import { CheckOutTransactionEntityInput } from '../../../../../../types/schema';
import { UseCheckOutFormReturnType } from './index';

export function getAvailableQuantity(options: {
  sourceLocation: UseCheckOutFormReturnType['state']['sourceLocation'];
  sourceSite: UseCheckOutFormReturnType['state']['sourceSite'];
  transactions: CheckOutTransactionEntityInput[];
  itemInStockId?: string;
}): number | undefined {
  const { sourceSite, sourceLocation, transactions, itemInStockId } = options;
  if (!sourceLocation) {
    return undefined;
  }

  const availableQuantityOnLocation = sourceLocation?.availableQuantity || 0;
  let totalQuantity = 0;
  transactions.forEach((transaction) => {
    if (
      transaction.entityId === itemInStockId &&
      transaction.sourceSiteId === sourceSite?.id &&
      transaction.sourceLocationId === sourceLocation?.id
    ) {
      totalQuantity += transaction.quantity;
    }
  });
  return availableQuantityOnLocation - totalQuantity;
}
