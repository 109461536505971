import { forwardRef, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { cx } from '@emotion/css';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import FormLabel from 'app/components/Form/FormLabel';
import useDebounce from 'app/hooks/useDebounce';
import Reports from 'app/i18n/Reports';
import { useFormSiteSelectQuery } from 'app/modules/locations/components/FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { LocationTypeEnum, SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

interface SelectProps {
  className?: string;
  label?: string;
  value: string[];
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  types?: LocationTypeEnum[];
  error?: string;
  validate?: boolean;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;
  const [{ fetching, data }] = useFormSiteSelectQuery({
    pause,
    variables: { filters: { siteIds: value ?? [] } },
    requestPolicy: 'network-only',
  });

  const values = useMemo(
    () => data?.locations.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.locations.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (
  props: UseSelectQueryProps & { types?: LocationTypeEnum[] },
): UseSelectQueryReturn => {
  const { inputValue, types } = props;
  const searchValue = useDebounce(inputValue);

  const [{ fetching, data }] = useFormSiteSelectQuery({
    variables: {
      filters: { search: searchValue, types },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
      limit: 10000,
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.locations.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.locations.edges],
  );

  return { isLoading: fetching, options };
};

const FormSitesSelect = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const { className, label, value, onChange, types = [LocationTypeEnum.Site], error, validate, ...rest } = props;

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <span className='text-[rgb(102 112 133)]'>{label}{validate && <span className="text-[14px] text-red-800"> * </span>}</span> : null}
      <AsyncMultiSelect
        {...rest}
        limitTags={-1}
        queryVariables={{ types }}
        useSelectQuery={useSelectQuery}
        useSelectedValue={useSelectedValue}
        value={value}
        onChange={onChange}
      />
      {error && <span className="text-red-500 font-[10px] m-3">{error}</span>}
    </Box>
  );
});

interface Props {
  control: any;
  name?: string;
  label?: string;
  types?: LocationTypeEnum[];
  rules?: object;
  validate?: boolean;
}

const ReportSitesSelect = (props: Props) => {
  const { control, name = 'siteIds', label = Reports.Site, types, rules, validate } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormSitesSelect
          {...field}
          className="flex-1"
          label={label}
          types={types}
          error={fieldState.error?.message}
          validate={!!validate}
        />
      )}
    />
  );
};

export default ReportSitesSelect;
