import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormImageUpload from 'app/components/Form/FormImageUpload';
import FormLabel from 'app/components/Form/FormLabel';
import FormPhoneInput from 'app/components/Form/FormPhoneInput';
import FormTextInput from 'app/components/Form/FormTextInput';
import useDebounce from 'app/hooks/useDebounce';
import Common from 'app/i18n/Common';
import Organization from 'app/i18n/Organization';
import ToggleSwitch from 'app/libs/ToggleSwitch';
import { useZipCodeLookupQuery } from 'app/modules/auth/views/SignUp/graphql/queries/generated/geoCode';
import ModuleConfigOptions from 'app/modules/organizations/components/ModuleConfigOptions';
import { OrganizationTabs } from 'app/modules/organizations/utils/types';
import { TenantStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { EditOrganizationFormProps } from './types';
import { ORGANIZATION_FORM_RULES as Rules } from './utils';

function EditOrganizationForm(props: EditOrganizationFormProps) {
  const { isSuperAdmin, defaultValues, disabled, onCancel, onSubmit, activePage } = props;
  const [isUploading, setIsUploading] = useState(false);
  const { control, handleSubmit, setValue, getValues, watch } = useForm({ defaultValues });
  const zipCode = watch('locationDetails.zipCode');

  const validZipCode = useMemo(() => (zipCode.trim().length >= 5 ? zipCode : ''), [zipCode]);
  const zipCodeDebouncedValue = useDebounce(validZipCode.trim());

  const [{ data, error }] = useZipCodeLookupQuery({
    variables: { filters: { zipcode: zipCodeDebouncedValue } },
    pause: !zipCodeDebouncedValue,
  });

  useEffect(() => {
    if (error) {
      setValue('locationDetails.city', '');
      setValue('locationDetails.state', '');
    }
  }, [error, setValue]);

  useEffect(() => {
    setValue('locationDetails.city', data?.geoCode.city || getValues('locationDetails.city'));
    setValue('locationDetails.state', data?.geoCode.state || getValues('locationDetails.state'));
  }, [data, getValues, setValue]);

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const updatedValues = {
          ...values,
          name: removeMultipleSpaces(values?.name || ''),
          primaryPhoneNumber: removeMultipleSpaces(values?.primaryPhoneNumber || ''),
          locationDetails: {
            ...values.locationDetails,
            city: removeMultipleSpaces(values.locationDetails?.city || ''),
            state: removeMultipleSpaces(values.locationDetails?.state || ''),
            address: removeMultipleSpaces(values.locationDetails?.address || ''),
            zipCode: removeMultipleSpaces(values.locationDetails?.zipCode || ''),
          },
        };
        onSubmit(updatedValues);
      }),
    [handleSubmit, onSubmit],
  );

  const onToggleStatus = useCallback(() => {
    const on = getValues().status === TenantStatusEnum.Active;
    setValue('status', on ? TenantStatusEnum.InActive : TenantStatusEnum.Active);
  }, [getValues, setValue]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        {activePage === OrganizationTabs.EDIT ? (
          <>
            <Controller
              control={control}
              name="logo"
              render={({ field, fieldState }) => (
                <FormImageUpload
                  {...field}
                  error={fieldState.error}
                  label="Profile Image"
                  setIsUploading={setIsUploading}
                />
              )}
            />
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  error={fieldState.error}
                  label={Organization.FormLabels.OrganizationName}
                />
              )}
              rules={Rules.name}
            />
            <Controller
              control={control}
              name="locationDetails.address"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  error={fieldState.error}
                  label={Organization.FormLabels.Address}
                />
              )}
              rules={Rules.address}
            />
            <Box className="flex space-x-32">
              <Controller
                control={control}
                name="locationDetails.zipCode"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.Zip}
                  />
                )}
                rules={Rules.zipCode}
              />
              <Controller
                control={control}
                name="locationDetails.city"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.City}
                  />
                )}
                rules={Rules.city}
              />
              <Controller
                control={control}
                name="locationDetails.state"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.State}
                  />
                )}
                rules={Rules.state}
              />
            </Box>
            <Box className="flex space-x-32">
              <Controller
                control={control}
                name="primaryPhoneNumber"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    isRequired
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.Phone1}
                  />
                )}
                rules={Rules.primaryPhoneNumber}
              />
              <Controller
                control={control}
                name="secondaryPhoneNumber"
                render={({ field, fieldState }) => (
                  <FormPhoneInput
                    {...field}
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.Phone2}
                    value={field.value ?? ''}
                  />
                )}
              />
            </Box>
            <Box className="flex space-x-32">
              <Controller
                control={control}
                name="url"
                render={({ field, fieldState }) => (
                  <FormTextInput
                    {...field}
                    isRequired
                    className="flex-1"
                    error={fieldState.error}
                    label={Organization.FormLabels.website}
                  />
                )}
                rules={Rules.website}
              />
            </Box>
            {/* <ShoppingAssistantLink /> */}
            {isSuperAdmin ? (
              <Controller
                control={control}
                name="status"
                render={({ field }) => {
                  const on = field.value === TenantStatusEnum.Active;
                  return (
                    <Box ref={field.ref} className="flex flex-col">
                      <FormLabel>Active</FormLabel>
                      <ToggleSwitch className="mt-8" on={on} onClick={onToggleStatus} />
                    </Box>
                  );
                }}
              />
            ) : null}
          </>
        ) : (
          <ModuleConfigOptions control={control} />
        )}

        <Box className="flex justify-end space-x-16">
          <Button
            classes="min-w-[94px] h-[44px]"
            disabled={isUploading || disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.Save}
          </Button>
          {isSuperAdmin ? (
            <Button classes="min-w-[94px] h-[44px]" onClick={onCancel}>
              {Common.Actions.Cancel}
            </Button>
          ) : null}
        </Box>
      </Box>
    </form>
  );
}

export default EditOrganizationForm;
