import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAssetModelItemMutationVariables = Types.Exact<{
  input: Types.UpdateAssetModelItemInput;
}>;


export type UpdateAssetModelItemMutation = { __typename?: 'Mutation', updateAssetModelItem: { __typename?: 'UpdateAssetModelItemPayload', success: boolean } };


export const UpdateAssetModelItemDocument = gql`
    mutation updateAssetModelItem($input: UpdateAssetModelItemInput!) {
  updateAssetModelItem(input: $input) {
    success
  }
}
    `;

export function useUpdateAssetModelItemMutation() {
  return Urql.useMutation<UpdateAssetModelItemMutation, UpdateAssetModelItemMutationVariables>(UpdateAssetModelItemDocument);
};