import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import Inventory from 'app/i18n/Inventory';
import {
  AssetItemSchema,
  InventoryItemSchema,
  ItemFilters,
  ItemTypeEnum,
  ItemUnion,
  MediaSchema,
  SortOrderEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import { textValidator } from '../../../../components/Form/utils/validators';
import useDebounce from '../../../../hooks/useDebounce';
import Assets from '../../../../i18n/Assets';
import { getSupportedImageAttachments } from '../../../../utils/attachments';
import { useItemInStockSelectQuery } from '../../graphql/query/generated/itemInStockSelect';

type Option =
  | Pick<InventoryItemSchema, 'id' | 'sku' | 'title'>
  | Pick<AssetItemSchema, 'id' | 'sku' | 'title'>;

interface SelectProps {
  className?: string;
  error?: FieldError | undefined;
  label?: string;
  isRequired?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (values: string, option?: Option) => void;
  placeholder?: string;
  types?: ItemTypeEnum[];
  filters?: ItemFilters;
  renderInput?: (params: any) => React.ReactElement;
  pickableThroughOrderRequest?: boolean;
  tenantIds?: string[];
  partnerTenantId?: string;
  name?: string;
}

const getOptionLabel = (option: Option) => `${option.title} (${option.sku})`;
const getOptionValue = (option: Option) => option.id;

export const FormItemInStock = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const {
    name,
    className,
    label,
    types,
    value,
    isRequired,
    disabled,
    onBlur,
    onChange,
    error,
    placeholder = '',
    autoFocus,
    filters,
    onFocus,
    renderInput,
    pickableThroughOrderRequest,
    tenantIds,
    partnerTenantId,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const searchValue = useDebounce(inputValue);

  const [{ fetching: isDisabled, data: selectedData }] = useItemInStockSelectQuery({
    variables: {
      filters: {
        itemIds: value ? [value] : [],
        pickableThroughOrderRequest: pickableThroughOrderRequest,
        tenantIds: tenantIds || undefined,
        partnerTenantId,
      },
    },
    pause: !value || Boolean(value && selectedValue && selectedValue.id === value),
  });

  useEffect(() => {
    const selectedOptions = selectedData?.items.edges.map(({ node }) => node);
    if (selectedOptions) {
      setSelectedValue(selectedOptions.length ? (selectedOptions[0] as Option) : null);
    }
  }, [selectedData?.items.edges]);

  useEffect(() => {
    if (value === '') {
      setSelectedValue(null);
    }
  }, [value]);

  const [{ fetching: isLoading, data: optionsData }] = useItemInStockSelectQuery({
    variables: {
      filters: {
        search: searchValue,
        types: types || [],
        ...filters,
        pickableThroughOrderRequest: pickableThroughOrderRequest,
        tenantIds: tenantIds || undefined,
        partnerTenantId,
      },
      sorts: [
        {
          sortField: 'sku',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'title',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const options = useMemo<Option[]>(
    () => optionsData?.items?.edges?.map?.(({ node }) => node as Option) || [],
    [optionsData?.items.edges],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: Option) => {
      if (option) {
        setSelectedValue(option as Option);
        setInputValue(option.sku);
        onChange?.(option.id, option);
      } else {
        setSelectedValue(option);
        onChange?.('');
      }
    },
    [onChange],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    if (selectedValue && value === getOptionLabel(selectedValue)) {
      setInputValue(selectedValue?.sku);
    } else {
      setInputValue(value);
    }
  };

  const onBlurValue = useCallback(() => {
    setInputValue(selectedValue ? selectedValue.sku : '');
    onBlur?.();
  }, [onBlur, selectedValue]);

  const inputRender = (params: any) => {
    if (renderInput) {
      return renderInput({ ...params, error: Boolean(error) });
    }

    return <TextField {...params} autoFocus={autoFocus} placeholder={placeholder} name={name} />;
  };

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        className={cx(
          'mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none',
          className,
        )}
        disabled={disabled || isDisabled}
        filterOptions={(option) => option}
        getOptionLabel={getOptionLabel}
        label={''}
        loading={isLoading}
        options={options}
        renderInput={inputRender}
        renderOption={(props: any, option: Option) => {
          const attachments = ((option as ItemUnion).attachments || []) as MediaSchema[];
          const imageAttachments = getSupportedImageAttachments(attachments);
          const imageUrl = imageAttachments.length ? imageAttachments[0].url : undefined;
          const showImageBox = selectedValue?.id !== option?.id;
          return (
            <div key={option?.id} className="flex" {...props}>
              {showImageBox ? (
                imageUrl ? (
                  <img
                    alt="country-image"
                    className="mw-[50px] mh-[50px] mr-[10px] h-[50px] w-[50px] object-contain"
                    src={imageUrl}
                  />
                ) : (
                  <div className="mw-[50px] mh-[50px] mr-[10px] h-[50px] w-[50px] text-[#e3e3e3]"></div>
                )
              ) : undefined}
              <span>
                {option.title} ({option.sku})
              </span>
            </div>
          );
        }}
        value={selectedValue}
        onBlur={onBlurValue}
        onChange={onValueChange}
        onFocus={onFocus}
        onInputChange={onInputValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

interface Props {
  control: any;
  types?: ItemTypeEnum[];
  filters?: ItemFilters;
  name?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (values: string, option?: Option) => void;
}
const ItemInStockSelect = (props: Props) => {
  const { control, name = 'skuIds', types, autoFocus, disabled, filters, onChange } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItemInStock
          {...field}
          isRequired
          autoFocus={autoFocus}
          className="flex-1"
          disabled={disabled}
          error={fieldState.error}
          filters={filters}
          label={Assets.FormLabels.ItemInStock}
          types={types}
          onChange={(value, option) => {
            field.onChange(value);
            onChange?.(value, option);
          }}
        />
      )}
      rules={{
        validate: textValidator(Inventory.FormValidationMessages.ItemInStockSearchRequired),
      }}
    />
  );
};

export default ItemInStockSelect;
