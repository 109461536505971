import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { nanoid } from 'nanoid';

import { SnackbarService } from '../../../../../../components/Snackbar';
import Assets from '../../../../../../i18n/Assets';
import { CheckOutTransactionEntityInput } from '../../../../../../types/schema';
import { DateFormatEnum, formatDate } from '../../../../../../utils/date';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import useCheckOutForm from '../useCheckOutForm';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';

const isItemOnSameSourceSiteLocation = (
  transaction: CheckOutTransactionEntityInput,
  transaction2: CheckOutTransactionEntityInput,
): boolean => {
  return (
    transaction.entityId === transaction2.entityId &&
    transaction.sourceSiteId === transaction2.sourceSiteId &&
    transaction.sourceLocationId === transaction2.sourceLocationId
  );
};

const isItemOnSameLocation = (
  transaction: CheckOutTransactionEntityInput,
  transaction2: CheckOutTransactionEntityInput,
): boolean => {
  return (
    transaction.entityId === transaction2.entityId &&
    transaction.destinationSiteId === transaction2.destinationSiteId &&
    transaction.destinationLocationId === transaction2.destinationLocationId
  );
};

const isItemForSameUser = (
  transaction: CheckOutTransactionEntityInput,
  transaction2: CheckOutTransactionEntityInput,
): boolean => {
  return transaction.actorId === transaction2.actorId;
};

type CheckOutRow = {
  id: string;
  sku: string;
  title: string;
  sourceSiteName: string;
  sourceLocationName: string;
  destinationName: string;
  destinationLocationName: string;
  dueDate: string;
  quantity: number;
  transaction: CheckOutTransactionEntityInput;
};

const useCheckOutState = () => {
  const { table } = useEntityManager({
    selection: false,
  });
  const [rows, setRows] = useState<CheckOutRow[]>([]);

  const transactions = useMemo(() => {
    return rows.map((row) => row.transaction);
  }, [rows]);

  const { formState, state, onResetForm, itemInStock } = useCheckOutForm(transactions);

  const isQuantityAvailable = useCallback(
    (moveTransaction: CheckOutTransactionEntityInput) => {
      const sourceLocation = state?.sourceLocation;
      const availableQuantityOnLocation = sourceLocation?.availableQuantity || 0;

      let totalQuantity = moveTransaction.quantity;
      transactions.forEach((transaction) => {
        if (
          isItemOnSameSourceSiteLocation(transaction, moveTransaction) &&
          !isItemOnSameLocation(transaction, moveTransaction)
        ) {
          totalQuantity += transaction.quantity;
        }
      });

      return totalQuantity <= availableQuantityOnLocation;
    },
    [transactions, state?.sourceLocation],
  );

  const getUpdatedRows = useCallback(
    (newTransaction: CheckOutRow) => {
      const updatedRows = [...rows];

      const rowIndex = updatedRows.findIndex((row) => {
        const { transaction } = row;
        return (
          isItemOnSameSourceSiteLocation(transaction, newTransaction.transaction) &&
          isItemOnSameLocation(transaction, newTransaction.transaction) &&
          isItemForSameUser(transaction, newTransaction.transaction)
        );
      });

      if (rowIndex !== -1) {
        updatedRows[rowIndex] = newTransaction;
      } else {
        updatedRows.push(newTransaction);
      }

      return updatedRows;
    },
    [rows],
  );

  const createTransaction = useCallback(
    (values: any) => {
      const {
        sourceSiteId,
        sourceLocationId,
        destinationSiteId,
        destinationLocationId,
        departmentId: departmentIdValue,
        actorId,
        isDueDateRequired,
        dueDate: dueDateValue,
        meta,
        quantity: qty,
        reservedTransactionId,
      } = values;

      const { sourceLocation, sourceSite, destinationSite, destinationLocation } = state;

      const quantity = Number(qty);
      const dueDate = isDueDateRequired === 'true' ? dueDateValue : null;
      let formattedDueDate = null;
      if (dueDate) {
        formattedDueDate = moment(dueDate).endOf('day').toISOString();
      }
      const departmentId =
        departmentIdValue && departmentIdValue !== '-1' ? departmentIdValue : null;

      const updatedMetaDetails = {
        note: removeExtraSpacesAndNewlines(meta.note),
        formattedNote: meta.note,
      };
      return {
        id: `checkout-row-${nanoid()}`,
        sku: itemInStock?.sku || '',
        title: itemInStock?.title || '',
        destinationName: destinationSite?.name || '',
        destinationLocationName: destinationLocation?.name || '',
        sourceSiteName: sourceSite?.name || '',
        sourceLocationName: sourceLocation?.name || '',
        dueDate: dueDate ? formatDate(dueDate, DateFormatEnum.Date) : '',
        quantity,
        transaction: {
          actorId,
          departmentId,
          destinationLocationId,
          destinationSiteId,
          dueDate: formattedDueDate,
          entityType: itemInStock?.type,
          meta: updatedMetaDetails,
          quantity,
          sourceSiteId,
          sourceLocationId,
          entityId: itemInStock?.id || '',
          reservedTransactionId,
        },
      } as CheckOutRow;
    },
    [state, itemInStock],
  );

  const onAddCheckOutRow = useCallback(
    (values: any) => {
      const checkOutRow = createTransaction(values);

      if (!isQuantityAvailable(checkOutRow.transaction)) {
        SnackbarService.showError({
          message: Assets.FormValidationMessages.QuantityIsNotAvailable,
        });
        return;
      }
      setRows(getUpdatedRows(checkOutRow));
      onResetForm();
    },
    [rows, setRows, createTransaction, getUpdatedRows, onResetForm, itemInStock],
  );

  const onFormSubmit = useCallback(
    (values: any) => {
      onAddCheckOutRow(values);
    },
    [onAddCheckOutRow, onResetForm],
  );

  const resetTableAndForm = useCallback(() => {
    setRows([]);
    onResetForm();
  }, [onResetForm, setRows]);

  const onRemoveRow = useCallback(
    (rowId: string) => {
      setRows(rows.filter((row) => row.id !== rowId));
    },
    [rows, setRows],
  );

  return {
    formState,
    table,
    rows,
    onFormSubmit,
    count: rows.length,
    resetTableAndForm,
    transactions,
    onRemoveRow,
  };
};

export type UseCheckOutStateReturnType = ReturnType<typeof useCheckOutState>;

export default useCheckOutState;
