import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import usePagination from 'app/hooks/usePagination';
import Label from 'app/i18n/Label';
import { ItemLocationItemTypeEnum, ItemStatusEnum, LocationStatusEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';
import { parseSorts } from 'app/utils/sort';

import useLabelsFrom from '../../context/useLabelsFrom';
import { useLabelInventoryTableQuery } from '../../graphql/queries/generated/LabelInventoryTable';
import useLabelsTable from '../../hooks/useLabelsTable';
import LabelsTable from '../LabelsTable';
import TableHeader from '../TableHeader';
import { getLabelsInventoryTableColumns } from './utils/columns';

interface Props {
  itemTypes: ItemLocationItemTypeEnum[];
}

type InventoryData = {
  id: string;
  itemId: string;
  site: { id: string; name: string };
  location: { id: string; name: string };
  item: { id: string; title: string; sku: string } | { id: string; title: string; sku: string };
};

// https://inv-01.qa.procurenetworks.com/admin/print-label?module_id=2&label_format=1&stock=1&offset=0&arrow_control=none&site_id=8069
const InventoryTable = (props: Props) => {
  const { itemTypes } = props;
  const [inventoryData, setInventoryData] = useState<InventoryData[]>([]);
  const { values, trigger, setIsCustomFieldValid } = useLabelsFrom();
  const { search, table, onPrintLabels } = useLabelsTable({ values, setIsCustomFieldValid });
  const columns = useMemo(() => getLabelsInventoryTableColumns(), []);

  const sorts = useMemo(() => {
    return parseSorts(table.state.sorts, columns);
  }, [columns, table.state.sorts]);

  useEffect(() => {
    setInventoryData([]);
    table.setState({ selectedRowIds: [] });
  }, [values?.siteId, values?.location]);

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useLabelInventoryTableQuery,
    {
      filters: {
        itemTypes: itemTypes,
        search: search.debouncedSearchText,
        locationIds: values && values.location ? [values.location] : [],
        siteIds: values && values.siteId ? [values.siteId] : [],
        itemStatuses: [ItemStatusEnum.Active],
        locationStatuses: [LocationStatusEnum.Active],
      },
      sorts,
    },
    { edgeKey: 'itemlocations', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    const row = data?.itemlocations.edges?.map(({ node }) => node) || [];
    setInventoryData(uniqBy([...inventoryData, ...row], (row) => row.id) as InventoryData[]);
    return row;
  }, [data?.itemlocations.edges]);

  useEffect(() => {
    const row = inventoryData.filter((row) => table.state.selectedRowIds.includes(row.id));
    table.setState({ selectedRowData: row });
  }, [table.state.selectedRowIds]);

  const handlePrintLabels = async () => {
    const isValid = trigger && (await trigger());
    if (isValid) {
      onPrintLabels();
    }
  };

  return (
    <Stack direction="col">
      <LabelsTable
        pagination
        actions={table.actions}
        columns={columns}
        data={rows}
        loading={fetching}
        setState={table.setState}
        state={table.state}
        total={data?.itemlocations.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onPrintLabels={handlePrintLabels}
        onReset={onReset}
        filterNode={
          <TableHeader title={Label.Inventory.InventorySearch} onChange={search.onChange} />
        }
        tableType="Inventory"
      />
    </Stack>
  );
};

export default InventoryTable;
