import {
  composeValidators,
  emailValidator,
  textMinLengthValidator,
  textValidator,
  zipCodeValidator,
} from 'app/components/Form/utils/validators';
import Organization from 'app/i18n/Organization';
import { Maybe } from 'app/types/schema';

const phoneNumberValidator = (message: string) => (value?: Maybe<string>) => {
  if (value?.charAt(0) === '1' && value.length === 11) {
    return true;
  }

  if (value?.charAt(0) !== '1' && value?.length === 10) {
    return true;
  }

  return message || false;
};

export const ORGANIZATION_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Organization.FormValidations.Name),
      textMinLengthValidator(Organization.FormValidations.Name, 3),
    ),
  },
  firstName: {
    validate: textValidator(Organization.FormValidations.AdminFirstName),
  },
  lastName: {
    validate: textValidator(Organization.FormValidations.AdminLastName),
  },
  adminEmailId: {
    validate: composeValidators(
      textValidator(Organization.FormValidations.AdminEmail),
      emailValidator(Organization.FormValidations.AdminEmailInvalid),
    ),
  },
  address: {
    validate: textValidator(Organization.FormValidations.Address),
  },
  city: {
    validate: textValidator(Organization.FormValidations.City),
  },
  state: {
    validate: textValidator(Organization.FormValidations.State),
  },
  zipCode: {
    validate: composeValidators(
      textValidator(Organization.FormValidations.ZipCode),
      zipCodeValidator(Organization.FormValidations.ZipCodeInvalid),
    ),
  },
  primaryPhoneNumber: {
    validate: textValidator(Organization.FormValidations.Phone1),
  },
  website: {
    validate: textValidator(Organization.FormValidations.WebsiteInvalid)
  }
};
