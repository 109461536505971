import {
  composeValidators,
  dateValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Receive from 'app/i18n/Receivables';
import { OrderRequestItemEdge, OrderRequestItemSchema, OrderRequestSchema } from 'app/types/schema';

export const RECEIVE_FORM_RULES = {
  dueDate: {
    validate: dateValidator(Receive.FormValidationMessages.estimateDueDate),
  },
  vendor: {
    validate: composeValidators(textValidator(Receive.FormValidationMessages.vendor)),
  },
  procureOrderId: {
    validate: composeValidators(
      textValidator(Receive.FormValidationMessages.procureOrderId),
      // orderWithOrderedItemsValidator(
      //   Receive.FormValidationMessages.OrderWithOrderedStatusItemRequired,
      //   orderRequest,
      // ),
    ),
  },
};
