import { useMemo } from 'react';
import { isManufacturerActive } from 'app/modules/manufacturers/utils/utils';

import {
  ItemExternalProductCodeTypeEnum,
  ItemStatusEnum,
  SortOrderEnum,
} from '../../../../../../types/schema';
import { InventoryItemSchema } from '../../../../../../types/schema';
import { format } from '../../../../../../utils/cost';
import { isCategoryActive } from '../../../../../categories/utils/utils';
import { isCompanyActive } from '../../../../../contacts/utils/utils';
import { useUserFilterQuery } from '../../../../graphql/queries/generated/userFilter';
import { useDeletedInventoryDataQuery } from '../../graphql/queries/generated/deletedInventoryData';

const useEditInventoryState = (inventoryId: string) => {
  const [{ data, fetching }] = useDeletedInventoryDataQuery({
    variables: {
      filters: {
        itemIds: [inventoryId],
        statuses: [ItemStatusEnum.Deleted],
      },
    },
  });

  const inventoryData = useMemo(() => {
    return data?.inventoryItems.edges?.[0]?.node;
  }, [data?.inventoryItems.edges]);

  const inventoryFormData = useMemo(() => {
    if (inventoryData) {
      const {
        sku,
        attachments,
        cost,
        costOverride,
        category,
        vendor,
        title,
        description,
        formattedDescription,
        model,
        brand,
        externalProductCodes,
        type,
        pickableThroughOrderRequest,
        unitCost,
        manufacturer,
        manufacturerId,
      } = inventoryData as InventoryItemSchema;

      const externalUpcCodeData =
        (externalProductCodes || [])?.find(
          (externalProductCode) => externalProductCode.type === ItemExternalProductCodeTypeEnum.Upc,
        ) || undefined;

      return {
        sku: sku || '',
        attachments: attachments || [],
        cost: cost || 0,
        costOverride: costOverride || 0,
        unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
        upcCode: externalUpcCodeData?.code || '',
        noUpcCode: externalUpcCodeData === undefined,
        findUpc: false,
        brand,
        categoryId: category?.id && isCategoryActive(category.status) ? category?.id : '',
        description: formattedDescription || description || '',
        mName: model || '',
        title,
        vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
        type,
        pickableThroughOrderRequest,
        manufacturerId:
          manufacturerId && isManufacturerActive(manufacturer?.status) ? manufacturerId : '',
      };
    }

    return undefined;
  }, [inventoryData]);

  // User Filter
  const [userResult] = useUserFilterQuery({
    variables: {
      sorts: [
        {
          sortField: 'firstName',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'lastName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  return {
    fetching,
    inventoryData,
    inventoryFormData,
    stockInformation: inventoryData?.stockInformation,
  };
};

export type UseEditInventoryStateReturnType = ReturnType<typeof useEditInventoryState>;
export default useEditInventoryState;
