import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateBellNotificationsStatusMutationVariables = Types.Exact<{
  input: Types.UpdateBellNotificationsStatusInput;
}>;


export type UpdateBellNotificationsStatusMutation = { __typename?: 'Mutation', updateBellNotificationsStatus: { __typename?: 'UpdateBellNotificationsStatusPayload', success: boolean, reasons?: Array<string> | null } };


export const UpdateBellNotificationsStatusDocument = gql`
    mutation updateBellNotificationsStatus($input: UpdateBellNotificationsStatusInput!) {
  updateBellNotificationsStatus(input: $input) {
    success
    reasons
  }
}
    `;

export function useUpdateBellNotificationsStatusMutation() {
  return Urql.useMutation<UpdateBellNotificationsStatusMutation, UpdateBellNotificationsStatusMutationVariables>(UpdateBellNotificationsStatusDocument);
};