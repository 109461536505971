import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetReturnedOrderRequestDetailForNotificationQueryVariables = Types.Exact<{
  input: Types.GetReturnedOrderRequestDetailForNotificationInput;
}>;


export type GetReturnedOrderRequestDetailForNotificationQuery = { __typename?: 'Query', getReturnedOrderRequestDetailForNotification: { __typename?: 'GetReturnedOrderRequestDetailForNotificationPayload', templateVariables: string } };


export const GetReturnedOrderRequestDetailForNotificationDocument = gql`
    query getReturnedOrderRequestDetailForNotification($input: GetReturnedOrderRequestDetailForNotificationInput!) {
  getReturnedOrderRequestDetailForNotification(input: $input) {
    templateVariables
  }
}
    `;

export function useGetReturnedOrderRequestDetailForNotificationQuery(options: Omit<Urql.UseQueryArgs<GetReturnedOrderRequestDetailForNotificationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReturnedOrderRequestDetailForNotificationQuery>({ query: GetReturnedOrderRequestDetailForNotificationDocument, ...options });
};