import { useState } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import { ButtonSize } from 'app/types/button';
import Box from 'app/ui-components/Box';

const procureLink = 'https://core-service-01.qa.procurenetworks.com/shop/E15-F41-C2D-A91B';

function ShoppingAssistantLink() {
  const [isCopied, setIsCopied] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(procureLink);
    setIsCopied(true);
  };
  return (
    <Box className="flex flex-col">
      <FormLabel>Link to install shopping assistant:</FormLabel>
      <Box className="flex gap-[16px]">
        <Box className="mt-8 flex flex-auto">
          <input
            disabled
            readOnly
            className="h-[50px] flex-1 rounded border py-6 px-12 text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none"
            value={procureLink}
          />
        </Box>
        <Box className="mt-8 flex gap-[8px]">
          <Button
            classes="w-[90px]"
            size={ButtonSize.medium}
            theme="info"
            onClick={() => {
              copyLink();
            }}>
            {isCopied ? 'Copied' : 'Copy'}
          </Button>

          <a href={procureLink} rel="noreferrer" target="_blank">
            <Button classes="w-[90px] h-[50px]" size={ButtonSize.medium} theme="success">
              Install
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
}

export default ShoppingAssistantLink;
