import { useEffect, useState } from 'react';
import usePagination from 'app/hooks/usePagination';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import { useReceivablesQuery } from 'app/modules/receiving/graphql/queries/generated/receivables';
import { ReceivableSchema, ReceivableTypeEnum } from 'app/types/schema';

export type ReceiveRowType = Omit<
  ReceivableSchema,
  'createdBy' | 'createdById' | 'updatedBy' | 'updatedById' | 'receivingHistory' | 'type'
>;

type Props = {
  receivableId: string;
};
const useReceivingHistoryForItems = ({ receivableId }: Props) => {
  const { table } = useEntityManager({
    selection: false,
  });

  const [rows, setRows] = useState<ReceiveRowType[]>([]);

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useReceivablesQuery,
    {
      filters: {
        parentReceivableIds: [receivableId],
        types: [ReceivableTypeEnum.Item],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'receivableItems',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  useEffect(() => {
    const rowsWithEditableFields = (data?.receivables?.edges?.map(({ node }) => node) ||
      []) as unknown as ReceiveRowType[];
    setRows(rowsWithEditableFields);
  }, [data?.receivables?.edges, setRows]);

  return {
    table,
    rows,
    rowCount: data?.receivables?.totalCount || 0,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
  };
};

export type UseCheckInAssetsStateReturnType = ReturnType<typeof useReceivingHistoryForItems>;
export default useReceivingHistoryForItems;
