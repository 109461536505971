import { useState } from 'react';

import { SCHEDULE_STEP } from '../../components/ScheduleStepper/utils';
import { CreateReceivablesInput } from 'app/types/schema';
import moment from 'moment';
import { SnackbarService } from 'app/components/Snackbar';
import Receivables from 'app/i18n/Receivables';
import { useCreateReceivablesMutation } from 'app/modules/receiving/graphql/mutations/generated/createReceivables';
import useReceiveForm from '../useReceiveForm';

function useScheduleShipment() {
  const [state, setState] = useState({
    activeStep: SCHEDULE_STEP.SCHEDULE_RECEIVING,
    step1Data: [],
    step2Data: [],
  });
  const _setState = (newState: any) => {
    setState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  return {
    state,
    _setState,
  };
}

export default useScheduleShipment;
