import { dateValidator, textValidator } from '../../../../../../components/Form/utils/validators';
import Assets from '../../../../../../i18n/Assets';
import {
  itemInStockValidator,
} from '../../../../../assetsInventory/utils/rules';

export const RESERVE_FORM_RULES = {
  itemInStockSearch: {
    validate: textValidator(Assets.FormValidationMessages.ItemInStockSearchRequired),
  },
  itemInStock: itemInStockValidator,
  actorId: {
    validate: textValidator(Assets.FormValidationMessages.UserRequired),
  },
  destinationId: {
    validate: textValidator(Assets.FormValidationMessages.ToSiteRequired),
  },
  startDate: {
    validate: dateValidator(Assets.FormValidationMessages.StartDateRequired),
  },
  endDate: {
    validate: dateValidator(Assets.FormValidationMessages.EndDateRequired),
  },
  quantity: { validate: textValidator(Assets.FormValidationMessages.QuantityRequired) },
};
