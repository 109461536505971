import { makeObservable, observable } from 'mobx';
import { Client } from 'urql';
import { getURQLClientUrl } from 'app/libs/urql/getClient';

import { UserProfileFragment } from '../graphql/fragments/generated/UserProfile';
import { UserProfileDocument, UserProfileQuery } from '../graphql/queries/generated/userProfile';
import currentUser from '../utils/currentUser';
import CurrentUser from '../utils/currentUser';
import { TenantSchema } from '../../../types/schema';
import BugReportingService from '../../../libs/sentry';

class AuthModel {
  user: UserProfileFragment = {} as UserProfileFragment;
  tenants: TenantSchema[] = [];

  constructor() {
    makeObservable(this, { user: observable });
  }

  fetchUser = async (client: Client) => {
    const userId = currentUser.id();

    if (userId) {
      try {
        const options = { requestPolicy: 'network-only' as const, url: getURQLClientUrl() };

        this.user = await client
          .query<UserProfileQuery>(UserProfileDocument, {}, options)
          .toPromise()
          .then((result) => {
            const user = result.data?.currentUser;

            if (!user || result.error) {
              throw new Error(`Error getting userId: ${userId}`);
            }

            BugReportingService.setUser({
              userId: user.id,
              tenantId: CurrentUser.tenantId(),
            });

            return {
              ...user,
              tenantId: CurrentUser.tenantId(),
            } as UserProfileFragment;
          });
        return this.user;
      } catch (error) {
        console.error('[AuthModel]', error);
      }
    }
  };

  clearUser = () => {
    this.user = {} as UserProfileFragment;
  };
}

const authModel = new AuthModel();
export default authModel;
