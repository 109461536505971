import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TransactionHistoryTableQueryVariables = Types.Exact<{
  filters: Types.TransactionFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type TransactionHistoryTableQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', totalCount: number, edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, quantity: number, entityType: Types.TransactionEntityTypeEnum, type: Types.TransactionTypeEnum, subType: Types.TransactionSubTypeEnum, entityId: string, status: Types.TransactionStatusEnum, sourceSiteId?: string | null, sourceLocationId?: string | null, destinationSiteId?: string | null, destinationLocationId?: string | null, createdAt: string, dueDate?: string | null, parentTransactionId: string, entity?: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string, serialNumber?: string | null } | { __typename?: 'AssetKitItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, sku: string, title: string, mName?: string | null } | { __typename?: 'InventoryKitItemSchema' } | null, destinationSite?: { __typename?: 'LocationSchema', id: string, name: string } | null, destinationLocation?: { __typename?: 'LocationSchema', id: string, name: string } | null, sourceSite?: { __typename?: 'LocationSchema', id: string, name: string } | null, sourceLocation?: { __typename?: 'LocationSchema', id: string, name: string } | null, createdBy: { __typename?: 'UserSchema', id: string, name: string } } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const TransactionHistoryTableDocument = gql`
    query transactionHistoryTable($filters: TransactionFilters!, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  transactions(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        quantity
        entityType
        type
        subType
        entityId
        status
        entity {
          ... on AssetItemSchema {
            id
            sku
            title
            sku
            serialNumber
          }
          ... on InventoryItemSchema {
            id
            sku
            title
            mName
          }
        }
        sourceSiteId
        sourceLocationId
        destinationSiteId
        destinationLocationId
        destinationSite {
          id
          name
        }
        destinationLocation {
          id
          name
        }
        sourceSite {
          id
          name
        }
        sourceLocation {
          id
          name
        }
        createdBy {
          id
          name
        }
        createdAt
        dueDate
        parentTransactionId
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useTransactionHistoryTableQuery(options: Omit<Urql.UseQueryArgs<TransactionHistoryTableQueryVariables>, 'query'>) {
  return Urql.useQuery<TransactionHistoryTableQuery>({ query: TransactionHistoryTableDocument, ...options });
};