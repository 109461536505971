import React from 'react';
import cn from 'classnames';

import Box from '../.';
import { StackProps } from './types';
import { ALIGN_ITEMS_CLASSNAME, DIRECTION_CLASSNAME, JUSTIFY_CONTENT_CLASSNAME } from './utils';

const Stack = React.forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  const {
    spacing,
    className,
    direction = 'row',
    alignItems = 'stretch',
    justifyContent = 'start',
    children,
    ...rest
  } = props;

  const directionClassname = DIRECTION_CLASSNAME[direction];
  const alignItemsClassname = ALIGN_ITEMS_CLASSNAME[alignItems];
  const justifyContentClassname = JUSTIFY_CONTENT_CLASSNAME[justifyContent];

  return (
    <Box
      ref={ref}
      className={cn(
        'flex',
        spacing,
        directionClassname,
        alignItemsClassname,
        justifyContentClassname,
        className,
      )}
      {...rest}>
      {children}
    </Box>
  );
});

Stack.displayName = 'Stack';

export default Stack;
