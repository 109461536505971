import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

type Props = React.PropsWithChildren<RouteComponentProps>;

function ShopLayout(props: Props) {
  const { children } = props;

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}
      mainClassName="!border-[0px]"
      mainWrapperClass="shopMainLayoutWrapper">
      {children}
    </Layout>
  );
}

export default withRouter(ShopLayout);
