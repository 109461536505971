import { Controller } from 'react-hook-form';

import FormTextInput from '../../../../../../components/Form/FormTextInput';
import Inventory from '../../../../../../i18n/Inventory';
import { InventoryFormProps } from '../../types';

const InventoryModelField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control, isItemTypeProduct } = formState;

  if (!isItemTypeProduct) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="mName"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            error={fieldState.error}
            isDisabled={disabled}
            label={Inventory.FormLabels.Model}
          />
        )}
      />
    </div>
  );
};
export default InventoryModelField;
