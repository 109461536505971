const ASSET_COLUMNS_HEADER_MAPPINGS = {
  sku: 'SKU',
  title: 'Title',
  brand: 'Brand',
  category: 'Category',
  categoryCode: 'Category Code',
  container: 'Container',
  costOverride: 'Cost Override %',
  departmentCode: 'Department Code',
  description: 'Description',
  fromLocation: 'From Location',
  fromSite: 'From Site',
  model: 'Model',
  modelNumber: 'Model Number',
  projectCode: 'Project Code',
  quantity: 'Quantity',
  serialNumber: 'Serial Number',
  toLocation: 'To Location',
  toSite: 'To Site',
  totalCostWithOverride: 'Total Cost (With Cost override)',
  totalCostWithoutOverride: 'Total Cost (Without Cost override)',
  createdAt: 'Transaction Date',
  transactionType: 'Transaction Type',
  reason: 'Reason',
  unitCostWithoutOverride: 'Unit Cost',
  unitCostWithOverride: 'Unit Cost (With Cost override)',
  vendor: 'Vendor',
  replacementDate: 'Replacement Date',
  manufacturer: 'Manufacturer',
};

const INVENTORY_COLUMNS_HEADER_MAPPINGS = {
  sku: 'SKU',
  title: 'Title',
  brand: 'Brand',
  category: 'Category',
  categoryCode: 'Category Code',
  container: 'Container',
  costOverride: 'Cost Override %',
  departmentCode: 'Department Code',
  description: 'Description',
  fromLocation: 'From Location',
  fromSite: 'From Site',
  model: 'Model',
  projectCode: 'Project Code',
  quantity: 'Quantity',
  toLocation: 'To Location',
  toSite: 'To Site',
  totalCostWithOverride: 'Total Cost (With Cost override)',
  totalCostWithoutOverride: 'Total Cost (Without Cost override)',
  createdAt: 'Transaction Date',
  transactionType: 'Transaction Type',
  reason: 'Reason',
  unitCostWithoutOverride: 'Unit Cost',
  unitCostWithOverride: 'Unit Cost (With Cost override)',
  vendor: 'Vendor',
  manufacturer: 'Manufacturer',
};

export function parseColumHeadersWithMapping(
  columns: string[],
  mapping: Record<string, string>,
): string[] {
  const parsedColumns: string[] = [];
  columns.forEach((column) => {
    const columnHeader = mapping[column];
    if (columnHeader) {
      parsedColumns.push(columnHeader);
    }
  });

  return parsedColumns;
}

export function parseAssetColumnsHeaderWithMapping(columnHeaders: string[]): string[] {
  return parseColumHeadersWithMapping(columnHeaders, ASSET_COLUMNS_HEADER_MAPPINGS);
}

export function parseInventoryColumnsHeaderWithMapping(columnHeaders: string[]): string[] {
  return parseColumHeadersWithMapping(columnHeaders, INVENTORY_COLUMNS_HEADER_MAPPINGS);
}
