import { useCallback, useMemo } from 'react';
import { isAccessible } from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestItemSchema,
  OrderRequestItemStatusEnum,
} from 'app/types/schema';

import { composeOrderItemStatusChecker } from '../../views/OrderDetail/utils';
import { OrderDetailsData } from '../../views/OrderDetail/type';

interface Props {
  orderDetail: OrderDetailsData;
}

const orderDetailsEditableOrderItemStatusCheck = composeOrderItemStatusChecker([
  OrderRequestItemStatusEnum.Open,
  OrderRequestItemStatusEnum.BackOrdered,
  OrderRequestItemStatusEnum.Ordered,
  OrderRequestItemStatusEnum.Packed,
]);

const orderDetailsEditableOrderItemWithPackStatusCheck = composeOrderItemStatusChecker([
  OrderRequestItemStatusEnum.Packed,
]);

const editPermissionOrderItemStatusCheck = composeOrderItemStatusChecker([
  OrderRequestItemStatusEnum.Open,
  OrderRequestItemStatusEnum.BackOrdered,
  OrderRequestItemStatusEnum.Ordered,
]);
const currentUserOrderItemStatusCheck = composeOrderItemStatusChecker([
  OrderRequestItemStatusEnum.Open,
]);

const orderDetailsEditableByCurrentUserOrderItemStatusCheck = composeOrderItemStatusChecker([
  OrderRequestItemStatusEnum.Open,
  OrderRequestItemStatusEnum.Packed,
]);

export const useOrderEditPermissionCheck = (props: Props) => {
  const { orderDetail } = props;
  const { id, workspacePermissions } = useCurrentUser();
  const { createdBy, orderRequestItems } = orderDetail;
  const { id: requestorId } = createdBy || {};

  const isCurrentUserIsOrderRequestor = requestorId === id;
  const isOrderEditAccessible = isAccessible(
    workspacePermissions || [],
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.OrderRequest,
  );

  const isOrderEditable = useMemo(() => {
    return (
      (isOrderEditAccessible &&
        !!(orderRequestItems || []).find(orderDetailsEditableOrderItemStatusCheck)) ||
      (isCurrentUserIsOrderRequestor &&
        !!(orderRequestItems || []).find(orderDetailsEditableByCurrentUserOrderItemStatusCheck))
    );
  }, [orderRequestItems, isOrderEditAccessible, isCurrentUserIsOrderRequestor]);

  const isEditButtonDisabled = useMemo(() => {
    if (isOrderEditAccessible) {
      return !orderRequestItems?.some(orderDetailsEditableOrderItemStatusCheck);
    }
    if (isCurrentUserIsOrderRequestor) {
      return !orderRequestItems?.some(orderDetailsEditableByCurrentUserOrderItemStatusCheck);
    }
    return true;
  }, [orderRequestItems, isOrderEditAccessible, isCurrentUserIsOrderRequestor]);

  const isOrderRequestItemEditable = useCallback(
    (orderRequestItem: OrderRequestItemSchema) => {
      return (
        (isOrderEditAccessible && editPermissionOrderItemStatusCheck(orderRequestItem)) ||
        (isCurrentUserIsOrderRequestor && currentUserOrderItemStatusCheck(orderRequestItem))
      );
    },
    [isOrderEditAccessible],
  );

  const getEditableOrderItems = useCallback(
    (orderRequestItems: OrderRequestItemSchema[]) => {
      return orderRequestItems.filter(isOrderRequestItemEditable);
    },
    [isOrderRequestItemEditable],
  );

  const isAnyOrderRequestItemHavePackStatus = useCallback(() => {
    return Boolean(
      orderRequestItems.find(
        (orderRequestItem) => orderRequestItem.status === OrderRequestItemStatusEnum.Packed,
      ),
    );
  }, [orderRequestItems]);

  const isOrderDetailsEditable = useCallback(() => {
    const orderRequestItemsAfterPackedStatus: OrderRequestItemSchema[] = [];
    const orderRequestItemsWithPackedStatus: OrderRequestItemSchema[] = [];
    orderDetail.orderRequestItems.forEach((orderRequestItem) => {
      if (!orderDetailsEditableOrderItemStatusCheck(orderRequestItem)) {
        orderRequestItemsAfterPackedStatus.push(orderRequestItem);
      }

      if (orderDetailsEditableOrderItemWithPackStatusCheck(orderRequestItem)) {
        orderRequestItemsWithPackedStatus.push(orderRequestItem);
      }
    });

    const isOrderDetailEditable = orderRequestItemsAfterPackedStatus.length === 0;

    return {
      isOrderDetailEditable,
      isOrderDetailEditableWithPackedStatus:
        isOrderDetailEditable && orderRequestItemsWithPackedStatus.length > 0,
    };
  }, [isOrderEditable, orderDetail]);

  return {
    isOrderEditable,
    isEditButtonDisabled,
    getEditableOrderItems,
    isOrderRequestItemEditable,
    isOrderDetailsEditable,
  };
};

export default useOrderEditPermissionCheck;
