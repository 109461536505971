import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AffiliateMerchantsSearchSuggestionQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AffiliateConfigurationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AffiliateMerchantsSearchSuggestionQuery = { __typename?: 'Query', affiliateConfigurations: { __typename?: 'AffiliateConfigurationConnection', edges: Array<{ __typename?: 'AffiliateConfigurationEdge', node: { __typename?: 'AffiliateConfigurationSchema', id: string, name: string } }> } };


export const AffiliateMerchantsSearchSuggestionDocument = gql`
    query affiliateMerchantsSearchSuggestion($filters: AffiliateConfigurationFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  affiliateConfigurations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useAffiliateMerchantsSearchSuggestionQuery(options?: Omit<Urql.UseQueryArgs<AffiliateMerchantsSearchSuggestionQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateMerchantsSearchSuggestionQuery>({ query: AffiliateMerchantsSearchSuggestionDocument, ...options });
};