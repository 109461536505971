import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Inventory from '../../../../i18n/Inventory';
import FormCategorySelect from '../../../categories/components/FormCategorySelect';
import { UseInventoryStateReturnType } from '../../views/Inventory/hook/useInventoryState';
import { useStyle } from '../useStyle';
import { CategoryFilterLabelNodeProps } from './types';
import SyncStateWithUrlParam from 'app/utils/queryParams';

const CategoryFilterLabelNode = (props: CategoryFilterLabelNodeProps) => {
  const { state } = props;
  const classes = useStyle();
  const { extraProps } = state;
  const { filters } = extraProps as UseInventoryStateReturnType;
  const { category } = filters;

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const storedCategory = new URLSearchParams(window.location.search).get('category');
      if (storedCategory) {
        category.onChange(storedCategory); 
      }
      setInitialized(true);
    }
  }, [initialized, category.onChange]);

  useEffect(() => {
    if (initialized) {
      const params = new URLSearchParams(window.location.search);
      if (category.value) {
        params.set('category', category.value);
      } else {
        params.delete('category');
      }
      const queryString = params.toString();
      let url = window.location.pathname;
      if (queryString) {
        url += `?${queryString}`;
      }
      window.history.pushState({}, '', url);
    }
  }, [initialized, category.value]);

  return (
    <div className="table-filter">
      <FormCategorySelect
        className={clsx(classes.root)}
        placeholder={Inventory.FormPlaceholders.Category}
        selectInputClass="flex"
        value={category.value === '-1' ? '' : category.value}
        onChange={category.onChange}
        size="small"
      />
    </div>
  );
};

export default CategoryFilterLabelNode;
