import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteWishlistMutationVariables = Types.Exact<{
  input: Types.DeleteWishlistsInput;
}>;


export type DeleteWishlistMutation = { __typename?: 'Mutation', deleteWishlists: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteWishlistDocument = gql`
    mutation deleteWishlist($input: DeleteWishlistsInput!) {
  deleteWishlists(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteWishlistMutation() {
  return Urql.useMutation<DeleteWishlistMutation, DeleteWishlistMutationVariables>(DeleteWishlistDocument);
};