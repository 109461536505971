import EntityManager from 'app/modules/components/EntityManager';

import BulkOrderRequest from '../BulkOrderRequest';
import NormalOrderRequest from '../NormalOrderRequest';
import { UseItemRequestStateReturnType } from './hook/useItemRequestState';
import { ORDER_REQUEST_TYPE } from './type';

interface Props {
  onNextStep: () => void;

  actions: UseItemRequestStateReturnType['orderItemRequest']['actions'];
  state: UseItemRequestStateReturnType['orderItemRequest']['state'];
}

const OrderItemRequest = (props: Props) => {
  const { state, actions, onNextStep } = props;

  const renderOrderForm = (orderRequestType: ORDER_REQUEST_TYPE) => {
    switch (orderRequestType) {
      case ORDER_REQUEST_TYPE.NORMAL_ORDER:
        return (
          <NormalOrderRequest
            actions={actions.normalOrderActions}
            state={state.normalOrderState}
            onBulkOrderRequest={actions.onBulkOrderRequest}
            onNextStep={onNextStep}
          />
        );
      case ORDER_REQUEST_TYPE.BULK_ORDER:
        return (
          <BulkOrderRequest
            actions={actions.bulkOrderActions}
            state={state.bulkOrderState}
            onCancelBulkOrderRequest={actions.onCancelBulkOrderRequest}
            onNextStep={onNextStep}
          />
        );
    }

    return null;
  };

  return <EntityManager className='pt-0 rounded-bl-[12px] rounded-br-[12px]'>{renderOrderForm(state.orderRequestType)}</EntityManager>;
};

export default OrderItemRequest;
