import { useMemo } from 'react';
import Common from 'app/i18n/Common';
import AlertBox from 'app/ui-components/AlertBox';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  message?: string;
  type?:
    | 'default'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'error'
    | 'input'
    | 'custom'
    | 'controlled'
    | string;
}

function ConfirmationModal(props: Props) {
  const { open, onCancel, onConfirm, message, type } = props;
  const confirmationMessage = useMemo(() => (message ? message : Common.DefaultMessage), [message]);

  if (!open) {
    return null;
  }

  return (
    <AlertBox
      open
      message={confirmationMessage}
      status="warning"
      type={type as string}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
}

export default ConfirmationModal;
