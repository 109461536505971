interface Params {
  node: { id: string; name: string };
}

export const adaptNodeEdgeToOption = ({ node }: Params) => ({
  node,
  value: node.id,
  label: node.name,
});

export const adaptNodeEdgeToNode = ({ node }: any) => node;
