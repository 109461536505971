import { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { WarningService } from 'app/components/WarningAlert';
import Inventory from 'app/i18n/Inventory';

import {
  RemoveTransactionEntityInput,
  TransactionEntityTypeEnum,
} from '../../../../../types/schema';
import useEntityManager from '../../../../components/EntityManager/useEntityManager';
import useRemoveForm from '../useRemoveForm';

interface Props {
  transactionType: TransactionEntityTypeEnum;
}

const useRemoveItemState = (props: Props) => {
  const { transactionType } = props;
  const [rows, setRows] = useState<any[]>([]);

  const { table } = useEntityManager({
    selection: false,
  });

  const { formState, handleSubmit, state, onResetForm, itemInStock } =
    useRemoveForm(transactionType);

  const createTransaction = useCallback(
    (values: any) => {
      const {
        itemInStockId,
        sourceSiteId,
        sourceLocationId,
        reason,
        reasonMessage,
        quantity: qty,
      } = values;
      const quantity = Number(qty);

      return {
        transaction: {
          entityType: itemInStock?.type as unknown as TransactionEntityTypeEnum,
          entityId: itemInStockId,
          quantity,
          meta: {
            reason: formState.isOtherReason ? reasonMessage : reason,
          },
          sourceLocationId,
          sourceSiteId,
        } as RemoveTransactionEntityInput,
        quantity,
        sku: itemInStock?.sku || '',
        title: itemInStock?.title || '',
        sourceSiteName: state?.sourceSite?.name || '',
        sourceLocationName: state?.sourceLocation?.name || '',
        reason,
        id: `remove-transaction-${nanoid()}`,
      };
    },
    [state, rows, formState, itemInStock],
  );

  const onSubmit = useCallback(
    (values: any) => {
      const removeTransaction = createTransaction(values);
      const updatedRows = [...rows];
      const rowIndex = updatedRows.findIndex((row) => {
        const { transaction } = row;

        return (
          transaction.entityId === removeTransaction.transaction.entityId &&
          transaction.sourceLocationId === removeTransaction.transaction.sourceLocationId &&
          transaction.sourceSiteId === removeTransaction.transaction.sourceSiteId
        );
      });

      if (rowIndex !== -1) {
        const newQuantity = updatedRows[rowIndex].quantity + removeTransaction.quantity;
        WarningService.showWarning({
          message: Inventory.FormValidationMessages.DuplicateCartItem(newQuantity),
          onConfirm: () => {
            updatedRows[rowIndex] = { ...removeTransaction, quantity: newQuantity };
            setRows(updatedRows);
            onResetForm();
          },
          onCancel: () => {
            return;
          },
        });
      } else {
        updatedRows.push(removeTransaction);
        setRows(updatedRows);
        onResetForm();
      }
    },
    [state, rows, formState, setRows, itemInStock, onResetForm],
  );

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onRemoveTransaction = useCallback(
    (rowId: number) => {
      setRows(rows.filter((row) => row.id !== rowId));
    },
    [setRows, rows],
  );

  const onResetFormAndTable = useCallback(() => {
    onResetForm();
    setRows([]);
  }, [onResetForm]);

  const transactions = useMemo(() => {
    return rows.map((row) => row.transaction);
  }, [rows]);

  return {
    formState,
    onFormSubmit,
    table,
    tableState: {
      table,
      rows,
      onRemoveTransaction,
      onResetFormAndTable,
      totalRows: rows.length,
    },
    rows,
    totalRows: rows.length,
    onRemoveTransaction,
    onResetFormAndTable,
    transactions,
  };
};

export type UseRemoveItemStateReturnType = ReturnType<typeof useRemoveItemState>;
export default useRemoveItemState;
