import { useCallback, useState } from 'react';

interface ImageState {
  imageUrl: string;
  open: boolean;
}

const useAffiliateMerchantState = () => {
  const [imagePreviewState, _setImagePreviewState] = useState<ImageState>({
    open: false,
    imageUrl: '',
  });

  const setImagePreviewState = useCallback((nextState: Partial<ImageState>) => {
    _setImagePreviewState((prevState) => ({ ...prevState, ...nextState }));
  }, []);


  const onCloseImageViewDialog = useCallback(() => {
    setImagePreviewState({
      open: false,
    });
  }, [setImagePreviewState]);

  const showImageViewDialog = useCallback(
    (image: string) => {
      setImagePreviewState({
        imageUrl: image,
        open: true,
      });
    },
    [setImagePreviewState],
  );

  return {
    imagePreviewState,
    onCloseImageViewDialog,
    showImageViewDialog,
  };
};

export type UseAffiliateMerchantStateReturnType = ReturnType<typeof useAffiliateMerchantState>;
export default useAffiliateMerchantState;
