import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteManufacturersMutationVariables = Types.Exact<{
  input: Types.DeleteManufacturersInput;
}>;


export type DeleteManufacturersMutation = { __typename?: 'Mutation', deleteManufacturers: { __typename?: 'MutationResponse', success: boolean, reasons?: Array<string> | null } };


export const DeleteManufacturersDocument = gql`
    mutation deleteManufacturers($input: DeleteManufacturersInput!) {
  deleteManufacturers(input: $input) {
    success
    reasons
  }
}
    `;

export function useDeleteManufacturersMutation() {
  return Urql.useMutation<DeleteManufacturersMutation, DeleteManufacturersMutationVariables>(DeleteManufacturersDocument);
};