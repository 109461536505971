import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import {
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../../../components/AsyncMultiSelect/types';
import AsyncMultiTableFilter from '../../../../../../components/AsyncMultiTableFilter';
import { Maybe, ShippingTransactionStatusEnum } from '../../../../../../types/schema';
import { ShipTransactionStatues } from './utils';

export interface UseSelectedValueProps {
  value: string[];
  pause?: boolean;
  options?: any[];
}

export interface ShipTransactionStatueFilterProps {
  name: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: undefined;
}

export interface UseSelectedValueProps {
  value: string[];
  options?: any[];
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const values = useMemo(
    () => ShipTransactionStatues.filter((item) => value.includes(item.value)),
    [value],
  );

  return { isDisabled: false, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;
  const options = useMemo(
    () =>
      ShipTransactionStatues.filter(
        (item) => item.value !== ShippingTransactionStatusEnum.Unpacked,
      ),
    [],
  );
  return { isLoading: false, options };
};

const ShipTransactionStatueFilter = (props: ShipTransactionStatueFilterProps) => {
  return (
    <AsyncMultiTableFilter
      {...props}
      size="small"
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
      onBlur={(event) => event.stopPropagation()}
    />
  );
};

export default ShipTransactionStatueFilter;
