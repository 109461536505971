import { useCallback, useEffect, useMemo, useState } from 'react';
import { ORDER_VIEW, OrderDetailsData } from '../../type';
import useOrderDetailsForm from '../useOrderDetailsForm';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import { composeFilter } from '../../../OrderRequest/components/OrderItemsTable/utils/utils';
import { getKeysToFilter } from '../../components/DefaultOrderItemsView/utils/utils';
import { FeatureFlagEnum, useFeatureFlag } from '../../../../../../libs/featureFlag';
import useImageViewDialogState from '../../../../../components/ImageViewDialog/hook/useImageViewDialogState';
import _ from 'lodash';
// import { OrderRequestItemSchema } from '../../../../../../types/schema';
import { updateOrderItem, updateOrderItems } from './utils/utils';
import useItemInStockWithSearchDialogState from '../../../../../assetsInventory/components/FormItemInStockWithSearchDialog/hook/useItemInStockWithSearchDialogState';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { OrderRequestItemSchema } from '../../../OrderRequest/type';
import { OrderRequestTypeEnum } from '../../../../../../types/schema';

interface Props {
  orderDetail: OrderDetailsData;
}

function cloneOrderDetails(orderDetails: OrderRequestItemSchema[]) {
  return _.cloneDeep(orderDetails);
}

const useOrderDetails = (props: Props) => {
  const { orderDetail } = props;
  const defaultOrderDetails = useMemo(() => {
    return orderDetail.orderRequestItems;
  }, [orderDetail.orderRequestItems]);

  const { search } = useEntityManager({});
  const [orderView, setOrderView] = useState<ORDER_VIEW>(ORDER_VIEW.DEFAULT);
  const { tenantId } = useCurrentUser();

  const [orderRequestItems, setOrderRequestItems] = useState<OrderRequestItemSchema[]>(
    cloneOrderDetails(defaultOrderDetails),
  );

  useEffect(() => {
    if (orderView === ORDER_VIEW.DEFAULT) {
      setOrderRequestItems(cloneOrderDetails(defaultOrderDetails));
    }
  }, [defaultOrderDetails]);

  const { value: isProjectFieldEnabled } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );

  const { formState, handleSubmit, onResetForm } = useOrderDetailsForm({
    orderDetail,
    orderView,
  });

  const { actions: itemInStockDialogActions, state: itemInStockDialogState } =
    useItemInStockWithSearchDialogState();

  const filteredOrderRequestItems = useMemo(() => {
    return orderRequestItems.filter(composeFilter(search.debouncedSearchText, getKeysToFilter()));
  }, [orderRequestItems, search.debouncedSearchText]);

  const { state: imagePreviewState, actions: imagePreviewActions } = useImageViewDialogState();

  const onEdit = useCallback(() => {
    setOrderView(ORDER_VIEW.EDIT_ORDER_VIEW);
  }, [setOrderView]);

  const onReturn = useCallback(() => {
    setOrderView(ORDER_VIEW.RETURN_ORDER_VIEW);
  }, [setOrderView]);

  const onCancel = useCallback(() => {
    setOrderView(ORDER_VIEW.DEFAULT);
    setOrderRequestItems(cloneOrderDetails(defaultOrderDetails));
    onResetForm();
  }, [defaultOrderDetails, onResetForm]);

  const onUpdateOrderItem = useCallback(
    (orderRequestId: string, updateOrderRequestItem: Partial<OrderRequestItemSchema>) => {
      setOrderRequestItems(
        updateOrderItem(orderRequestItems, orderRequestId, updateOrderRequestItem),
      );
    },
    [setOrderRequestItems, orderRequestItems],
  );

  const onUpdateOrderItems = useCallback(
    (updateState: Partial<OrderRequestItemSchema>[]) => {
      setOrderRequestItems(
        updateOrderItems({
          orderRequestItems,
          updateState,
        }),
      );
    },
    [setOrderRequestItems, orderRequestItems],
  );

  const onRemoveOrderItem = useCallback((id: string) => {
    setOrderRequestItems(prevItems => prevItems.filter(item => item.id !== id));
  }, []);

  const partnerTenantId = useMemo(() => {
    if (orderDetail.type === OrderRequestTypeEnum.Internal) {
      return undefined;
    }

    return orderDetail?.parentTenantId || undefined;
  }, [orderDetail.parentTenantId, orderDetail.type]);

  const onAddRow = useCallback((newItem: any) => {
    setOrderRequestItems((prevItems) => [...prevItems, newItem]);
  }, []);

  const handleRemoveRow = (idToRemove: string) => {
    const updatedOrderRequestItems = orderRequestItems.filter(item => item.id !== idToRemove);
    setOrderRequestItems(updatedOrderRequestItems);
  };

  return {
    state: {
      isProjectFieldEnabled,
      orderView,
      formState,
      handleSubmit,
      search,
      orderRequestItems,
      filteredOrderRequestItems,
      imagePreviewState,
      orderDetail,
      itemInStockDialogState,
      partnerTenantId,
    },
    actions: {
      imagePreviewActions,
      onEdit,
      onReturn,
      onCancel,
      onUpdateOrderItem,
      onUpdateOrderItems,
      onRemoveOrderItem,
      itemInStockDialogActions,
      onAddRow,
      handleRemoveRow
    },
  };
};

export type UseOrderDetailsReturnType = ReturnType<typeof useOrderDetails>;

export default useOrderDetails;
