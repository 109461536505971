import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { AccessControlLink } from 'app/libs/navigation';

import routes from '../../../../../../consts/routes';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
} from '../../../../../../types/schema';
import { getCostWithCurrency } from '../../../../../../utils/cost';
import TableImageView from '../../../../../components/TableImageView';
import ManufactureTableNode from '../../../../../manufacturers/components/ManufactureTableNode';
import ReadMore from 'app/modules/components/ReadMore';

export function getAssetModelTableColumns(): DataGridHeadCell[] {
  return [
    {
      id: 'attachments',
      label: 'Image',
      value: 'attachments',
      valueNode: ({ row, state }) => {
        return (
          <TableImageView
            attachments={row.attachments}
            onImageClick={state?.extraProps?.imageViewActions?.onShow}
          />
        );
      },
      width: 100,
      sortable: false,
    },
    {
      id: 'sku',
      label: 'SKU',
      value: 'sku',
      valueNode: ({ row }) => (
        <AccessControlLink
          action={AllowedPermissionActionsEnum.Edit}
          className="sku-link"
          subject={AllowedPermissionsSubjectEnum.AssetModel}
          to={routes.EditAssetModel(row.id)}>
          {row?.sku}
        </AccessControlLink>
      ),
      width: 130,
      sortable: true,
    },
    {
      id: 'title',
      label: 'Title',
      value: 'title',
      width: 180,
      sortable: true,
    },
    {
      id: 'mName',
      label: 'Model',
      value: 'mName',
      width: 180,
      sortable: true,
    },
    {
      id: 'modelNumber',
      label: 'Model Number',
      value: 'modelNumber',
      width: 180,
      sortable: true,
    },
    {
      id: 'categoryId',
      label: 'Category',
      value: 'categoryId',
      valueNode: ({ row }) => (
        <span className="truncate" title={row?.category?.name}>
          {row?.category?.name}
        </span>
      ),
      width: 180,
      sortable: false,
    },
    {
      id: 'vendorId',
      label: 'Vendor',
      value: 'vendorId',
      width: 180,
      sortable: false,
      valueNode: ({ row }) => (
        <span className="truncate" title={row?.vendor?.companyName || ''}>
          {row?.vendor?.companyName || ''}
        </span>
      ),
    },
    {
      id: 'brand',
      label: 'Brand',
      value: 'brand',
      width: 180,
      sortable: false,
      valueNode: ({ row }) => (
        <span className="truncate" title={row?.brand}>
          {row?.brand}
        </span>
      ),
    },
    {
      id: 'manufacturerId',
      label: 'Manufacturer',
      value: 'manufacturerId',
      valueNode: ({ row }) => <ManufactureTableNode manufacturer={row.manufacturer} />,
      sortable: false,
      width: 150,
    },
    {
      id: 'description',
      label: 'Description',
      value: 'description',
      width: 180,
      sortable: false,
      valueNode: ({ row }) => <ReadMore text={row.description} />,
    },
    {
      id: 'cost',
      label: 'Cost',
      value: 'cost',
      valueNode: ({ row }) => {
        return (
          <span>{row?.cost !== undefined ? `${getCostWithCurrency(Number(row?.cost))}` : ''}</span>
        );
      },
      width: 100,
      rowAlign: 'right',
      sortable: false,
    },
  ] as DataGridHeadCell[];
}
