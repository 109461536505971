import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';
import Stack from 'app/ui-components/Stack';

const DeviceAccount = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;
  const systemInsightDetails = [
    { label: 'Username', value: networkAssetDetail?.userName || 'Not Available' },
    { label: 'MAC Address', value: networkAssetDetail?.mac || 'Not Available' },
    { label: 'Type', value: networkAssetDetail?.type || 'Not Available' },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Typography className='text-grey-900 font-semibold text-[18px]'>
          Device Information
        </Typography>
        <Divider className='my-[16px]'/>
        <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
          <CardContent>
            <Grid container columns={{ xs: 8, sm: 8, md: 8, lg: 12 }} spacing={2}>
              {systemInsightDetails.map((item, index) => {
                return (
                  <Grid key={index} item lg={4} md={4} xs={6}>
                    <Stack
                      alignItems="center"
                      direction="col"
                      justifyContent="center"
                      className={`mt-2 ${index !== 0 && 'border-l-[1px] border-grey-300'} text-[16px] text-grey-900 font-semibold`}
                      >
                      <Typography className='text-[16px] text-grey-900 font-semibold'>{item.label}</Typography>
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: 14,
                          marginTop: '10px',
                          textAlign: 'center',
                          width: '100%',
                        }}
                        className='text-[14px] text-grey-800'
                        >
                        {item.value}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DeviceAccount;
