import { OrderRequestSchema, OrderRequestTypeEnum } from '../../../types/schema';
import routes from '../../../consts/routes';

export function getOrderDetailsLink(row: OrderRequestSchema) {
  const { type, id } = row;
  if (type === OrderRequestTypeEnum.External) {
    return routes.ExternalOrderDetail(id);
  }

  return routes.InternalOrderDetail(id);
}

export function getOrderDetailsLinkByType(id: string, type?: OrderRequestTypeEnum | null) {
  if (type === OrderRequestTypeEnum.External) {
    return routes.ExternalOrderDetail(id);
  }
  return routes.InternalOrderDetail(id);
}
