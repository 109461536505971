import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormLabel from 'app/components/Form/FormLabel';
import { Size } from 'app/types/button';
import { CompanySchema, SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import { useCompaniesFilterQuery } from '../../../inventory/graphql/queries/generated/companiesFilter';

interface Props {
  className?: string;
  selectInputClass?: string;
  label?: string;
  name: string;
  isRequired?: boolean;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (values: string, valueObj?: any) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  menuPortalTarget?: HTMLElement | null;
  size?: Size;
}

type Option = Pick<CompanySchema, 'id' | 'companyName'>;

const getOptionLabel = (option: any) => option.companyName;

const FormCompanySelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    label,
    isRequired,
    disabled,
    value,
    onBlur,
    onChange,
    error,
    placeholder = '',
    selectInputClass = 'mt-6 ',
    size,
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [{ fetching: isDisabled, data: selectedData }] = useCompaniesFilterQuery({
    variables: { filters: { companyIds: value ? [value] : [] } },
    pause: !value || Boolean(value && selectedValue && selectedValue?.id === value),
  });

  useEffect(() => {
    const selectedOptions = selectedData?.companies.edges.map(({ node }) => node);

    if (selectedOptions) {
      setSelectedValue(selectedOptions.length ? selectedOptions[0] : null);
    }
  }, [selectedData?.companies.edges]);

  useEffect(() => {
    if (value === '') {
      setSelectedValue(null);
    }
  }, [value]);

  const [{ fetching: isLoading, data: optionsData }] = useCompaniesFilterQuery({
    variables: {
      filters: { search: inputValue, isVendor: true },
      limit: 10000,
      sorts: [
        {
          sortField: 'companyName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
  });

  const options = useMemo<any>(
    () => optionsData?.companies.edges.map(({ node }) => node) || [],
    [optionsData?.companies.edges],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, options: any) => {
      setSelectedValue(options as Option);
      onChange?.(options?.id || '', options);
    },
    [onChange],
  );

  const onInputValueChange = useCallback(
    (event: React.SyntheticEvent, value: string, reason: string) => {
      if (reason !== 'reset') {
        setInputValue(value);
      }
    },
    [setInputValue],
  );

  const handleOnBlur = useCallback(
    (event: any) => {
      setInputValue('');
      onBlur?.();
    },
    [onBlur, setInputValue],
  );

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        className={`rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none ${selectInputClass} ${className}`}
        disabled={isDisabled || disabled}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => {
          return option?.id === value?.id;
        }}
        label={''}
        loading={isLoading}
        options={options}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.companyName}
            </li>
          );
        }}
        size={size}
        sx={{
          '&.MuiAutocomplete-root > div': {
            display: 'flex',
            alignItems: 'center',
          },
          '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
            position: 'absolute !important',
            marginLeft: 'auto',
            right: '1px !important'
          },
          '&.MuiAutocomplete-root .MuiOutlinedInput-input': {
            width: 'calc(100% - 32px) !important',
            minWidth: '20px',
          },
          '&.MuiAutocomplete-root MuiDataGrid-columnHeader': {
            minWidth: '60px !important',
          }
        }}
        value={selectedValue}
        onBlur={handleOnBlur}
        onChange={onValueChange}
        onInputChange={onInputValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormCompanySelect;
