import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormSiteSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FormSiteSelectQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', name: string, id: string } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const FormSiteSelectDocument = gql`
    query formSiteSelect($filters: LocationFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  locations(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        name
        id
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useFormSiteSelectQuery(options?: Omit<Urql.UseQueryArgs<FormSiteSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormSiteSelectQuery>({ query: FormSiteSelectDocument, ...options });
};