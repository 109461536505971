import { Box } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';

import DetailsComponent from './components/DetailsComponent';
import useDetailInformation from './hooks/useDetailInformation';

const Details = (props: NetworkAssetSchemaProps) => {
  const {
    cpuData,
    systemData,
    operatingSystemData,
    motherboardData,
    storageData,
    memoryData,
    diskData,
    displayData,
    batteryData,
    biosData,
    networkData,
    // portData,
    processData,
    graphicsData,
  } = useDetailInformation(props);
  return (
    <Box sx={{ flexGrow: 1, marginTop: '2.25rem' }}>
      <DetailsComponent data={cpuData} heading={'CPU Details'} />
      <DetailsComponent data={diskData} heading={'Disk Details'} />
      <DetailsComponent data={systemData} heading={'System Information'} />
      <DetailsComponent data={memoryData} heading={'Memory Details'} />
      <DetailsComponent data={operatingSystemData} heading={'Operating System Details'} />
      <DetailsComponent data={processData} heading={'Process Details'} />
      <DetailsComponent data={batteryData} heading={'Battery Details'} />
      <DetailsComponent data={motherboardData} heading={'Motherboard Details'} />
      <DetailsComponent data={storageData} heading={'Storage Details'} />
      <DetailsComponent data={displayData} heading={'Display Details'} />
      <DetailsComponent data={biosData} heading={'BIOS Details'} />
      <DetailsComponent data={networkData} heading={'Network Details'} />
      <DetailsComponent data={graphicsData} heading={'Graphics Details'} />
    </Box>
  );
};

export default Details;
