interface Props {
  user?: {
    emailId: string;
  };
}
const UserEmailTableNode = (props: Props) => {
  const { user } = props;

  if (!user?.emailId) {
    return null;
  }
  return (
    <div className="truncate" title={user?.emailId}>
      {user?.emailId}
    </div>
  );
};

export default UserEmailTableNode;
