import { useMemo } from 'react';
import Loader from 'app/components/Loader';
import WarningProvider from 'app/components/WarningAlert';
import routes from 'app/consts/routes';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';
import OrdersLayout from 'app/modules/orders/layout';
import OrderDetailProvider from 'app/modules/orders/views/OrderDetail';
import { useOrderDetailsTypeQuery } from 'app/modules/orders/views/OrderDetail/graphql/generated/orderForRoutings';
import { OrderDetailsData } from 'app/modules/orders/views/OrderDetail/type';

import { OrderRequestTypeEnum } from '../../../types/schema';

function OrderDetailsPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  const [{ fetching, data }] = useOrderDetailsTypeQuery({
    variables: {
      filters: {
        orderRequestIds: [match.params.id],
      },
    },
  });

  const orderDetails: OrderDetailsData | undefined = useMemo(() => {
    return (data?.orderRequests?.edges?.[0]?.node as OrderDetailsData | undefined) || undefined;
  }, [data?.orderRequests?.edges]);

  if (match && match.path === '/orders/history/:id') {
    if (fetching) {
      return <Loader />;
    }

    if (!fetching && data && data?.orderRequests?.edges?.length) {
      const orderType = orderDetails?.type;
      if (orderType === OrderRequestTypeEnum.Internal) {
        return <Redirect to={routes.InternalOrderDetail(match.params.id)} />;
      }
      if (orderType === OrderRequestTypeEnum.External) {
        return <Redirect to={routes.ExternalOrderDetail(match.params.id)} />;
      }
    } else {
      if (!fetching && !orderDetails) {
        return <Redirect to={routes.OrderHistory()} />;
      }
    }
  }

  return (
    <WarningProvider>
      <OrdersLayout>
        <OrderDetailProvider orderId={match.params.id} />
      </OrdersLayout>
    </WarningProvider>
  );
}

export default withRouter(OrderDetailsPage);
