import { SiteReportOption } from '../types';
import { LocationTypeEnum, SortOrderEnum } from '../../../../../types/schema';

function getLocationTypeExportValue(locationType: LocationTypeEnum): LocationTypeEnum {
  switch (locationType) {
    case LocationTypeEnum.Location: {
      return 'location' as unknown as LocationTypeEnum;
    }
    case LocationTypeEnum.PartnerTenant: {
      return 'partnerTenant' as unknown as LocationTypeEnum;
    }
    case LocationTypeEnum.Site: {
      return 'site' as unknown as LocationTypeEnum;
    }
  }
}

export function getSortsBySiteOption(siteOption: SiteReportOption) {
  const sorts = [];

  switch (siteOption) {
    case SiteReportOption.onlyMinimumQuantityThresholdBreached:
    case SiteReportOption.includeItemsWithZeroQuantity: {
      sorts.push(
        ...[
          {
            sortField: 'itemId',
            sortOrder: SortOrderEnum.Desc,
          },
          {
            sortField: 'siteId',
            sortOrder: SortOrderEnum.Desc,
          },
          {
            sortField: 'locationId',
            sortOrder: SortOrderEnum.Asc,
          },
          {
            sortField: 'updatedAt',
            sortOrder: SortOrderEnum.Desc,
          },
        ],
      );
      break;
    }
    default:
      sorts.push({ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc });
  }

  return sorts;
}
