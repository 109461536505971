import { Controller } from 'react-hook-form';

import Inventory from '../../../../../../i18n/Inventory';
import FormCategorySelect from '../../../../../categories/components/FormCategorySelect';
import { InventoryFormProps } from '../../types';
import { INVENTORY_FORM_RULES } from '../../utils/utils';

const InventoryCategoryField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="categoryId"
        render={({ field, fieldState }) => (
          <FormCategorySelect
            {...field}
            isRequired
            disabled={disabled}
            error={fieldState.error}
            label={Inventory.FormLabels.Category}
          />
        )}
        rules={INVENTORY_FORM_RULES.categoryId}
      />
    </div>
  );
};

export default InventoryCategoryField;
