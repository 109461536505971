import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { OrderRequestItemSchema } from '../../../../../../types/schema';
import { useMap } from 'react-use';

export interface OrderedNotePopoverDefaultValue {
  estimatedDeliveryDate: Date | null;
  orderId: string;
  vendor: string;
}

const DEFAULT_VALUES = {
  estimatedDeliveryDate: null,
  orderId: '',
  vendor: '',
};

interface Props {
  orderRequestItem: OrderRequestItemSchema;
}

const useOrderedNotePopoverState = (props: Props) => {
  const { orderRequestItem } = props;
  const { nonRemovableNotes } = orderRequestItem;

  const defaultValues = useMemo(() => {
    const orderNonRemovableNote = nonRemovableNotes?.[0] as
      | OrderedNotePopoverDefaultValue
      | undefined;
    const orderNonRemovableDefaultValues = orderNonRemovableNote
      ? {
          ...orderNonRemovableNote,
          estimatedDeliveryDate: orderNonRemovableNote?.estimatedDeliveryDate
            ? new Date(orderNonRemovableNote.estimatedDeliveryDate)
            : null,
        }
      : undefined;
    return orderNonRemovableDefaultValues || DEFAULT_VALUES;
  }, [nonRemovableNotes]);

  const [open, setOpen] = useState(false);
  const { control, reset, handleSubmit, setValue } = useForm<OrderedNotePopoverDefaultValue>({
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    state: {
      open,
      control,
    },
    action: {
      onClose,
      onOpen,
      handleSubmit,
      reset,
    },
  };
};

export default useOrderedNotePopoverState;
export type UseOrderedNotePopoverStateReturnType = ReturnType<typeof useOrderedNotePopoverState>;
