import { Controller } from 'react-hook-form';
import { RadioGroup } from '@procurenetworks/procure-component-library';
import Reports from 'app/i18n/Reports';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { radioGroupStyleOverrides } from './styles';

interface Props {
  control: any;
  onChange?: (type: TransactionEntityTypeEnum) => void;
}

const RADIO_ITEMS = [
  {
    id: TransactionEntityTypeEnum.Asset,
    value: TransactionEntityTypeEnum.Asset,
    label: Reports.Asset,
  },
  {
    id: TransactionEntityTypeEnum.Inventory,
    value: TransactionEntityTypeEnum.Inventory,
    label: Reports.Inventory,
  },
];

const InventoryAssetSelectorFormField = (props: Props) => {
  const { control, onChange } = props;

  return (
    <Box className={radioGroupStyleOverrides}>
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <RadioGroup
            radioItems={RADIO_ITEMS}
            value={field.value}
            onChange={(value: string | number) => {
              onChange ? onChange(value as TransactionEntityTypeEnum) : field.onChange(value);
            }}
          />
        )}
      />
    </Box>
  );
};

export default InventoryAssetSelectorFormField;
