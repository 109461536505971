import RemoveForm from "./components/RemoveForm";
import RemoveTable from "./components/RemoveTable";


interface Composition {
    Form: typeof RemoveForm;
    Table: typeof RemoveTable;
}

const RemoveManager : Composition = {
    Form: RemoveForm,
    Table: RemoveTable,
}

export default RemoveManager