import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type DepartmentTableRowFragment = { __typename?: 'DepartmentSchema', id: string, name: string, description?: string | null, departmentCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } };

export const DepartmentTableRowFragmentDoc = gql`
    fragment DepartmentTableRow on DepartmentSchema {
  id
  name
  description
  departmentCode
  updatedAt
  updatedBy {
    id
    name
  }
}
    `;