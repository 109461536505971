import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAssetModelMutationVariables = Types.Exact<{
  input: Types.CreateAssetModelItemInput;
}>;


export type CreateAssetModelMutation = { __typename?: 'Mutation', createAssetModelItem: { __typename?: 'CreateAssetModelItemPayload', success: boolean, assetModelItem?: { __typename?: 'AssetModelItemSchema', id: string } | null } };


export const CreateAssetModelDocument = gql`
    mutation createAssetModel($input: CreateAssetModelItemInput!) {
  createAssetModelItem(input: $input) {
    success
    assetModelItem {
      id
    }
  }
}
    `;

export function useCreateAssetModelMutation() {
  return Urql.useMutation<CreateAssetModelMutation, CreateAssetModelMutationVariables>(CreateAssetModelDocument);
};