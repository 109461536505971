import _ from 'lodash';
import moment from 'moment';
import { AddRowOrderDetailsSchema, OrderRequestItemSchema, OrderRequestItemStatusEnum } from '../../../../types/schema';
import { DeliveryDetailInput } from '../../components/OrderDeliveryDetailForm/types';
import { isChildTenantOrderView } from '../../provider/OrderDetailsContext/provider';
import { ItemInStock } from '../OrderRequest/type';
import { ORDER_VIEW, OrderDetailsData } from './type';
import { AddOrderRowButtonProps } from '../../components/AddOrderRowButton';

//orderDetail schema will prepare
export const prepareFormData = (orderDetail: OrderDetailsData) => {
  const {
    orderRequestCode,
    createdAt,
    dueDate,
    createdBy,
    destinationSiteId,
    departmentId,
    deliverToId,
    billToSiteId,
    title,
  } = orderDetail;

  const { name, emailId } = createdBy;

  return {
    orderId: orderRequestCode,
    orderDate: moment(createdAt).format('MM/DD/YYYY'),
    name: name,
    email: emailId,
    dueDate,
    destinationSiteId,
    departmentId,
    deliverToId,
    billToSiteId,
    title: title || '',
  } as DeliveryDetailInput;
};

export function composeOrderItemStatusChecker(status: string[]) {
  return (orderItem: any) => {
    return !!(status && status.includes(orderItem.status));
  };
}

export function LinkUrl(url: string) {
  return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}

// This function finds valid links and returns them with their related content.
export function findAndReturnURL(url: string) {
  const urlPattern = /https?:\/\/[^\s]+/g;

  const foundURLs = url.match(urlPattern);

  if (foundURLs && foundURLs.length > 0) {
    return {
      websiteUrl: foundURLs[0],
      content: url,
    };
  } else {
    return {
      websiteUrl: null,
      content: url,
    };
  }
}

export function LimitTextLength(text: string) {
  if (text.length > 14) {
    const string = text.slice(0, 14);
    return `${string.trim()}...`;
  }
  return text;
}

export function getTotalOfItems(orderItems: OrderRequestItemSchema[]) {
  let total = 0;
  _.forEach(orderItems, (row) => {
    const itemTotal = Number(row.quantity || 0) * Number(row?.cost || 0);
    total += itemTotal;
  });

  return total;
}

export function isActionFieldEnabled(
  orderRequestItems: OrderRequestItemSchema[],
  overView: ORDER_VIEW,
) {
  if (overView === ORDER_VIEW.EDIT_ORDER_VIEW) {
    return true;
  }

  if (overView !== ORDER_VIEW.DEFAULT) {
    return false;
  }

  const isParentOrderRequestItemIdFound = _.find(orderRequestItems, (orderRequestItem) => {
    return Boolean(orderRequestItem.parentOrderRequestItemId);
  });

  return Boolean(isParentOrderRequestItemIdFound);
}

export function parseOrderDetailsByOrderView(
  orderDetails: OrderDetailsData,
  itemInStockItems: ItemInStock[],
) {
  if (isChildTenantOrderView(orderDetails)) {
    orderDetails.orderRequestItems = _.map(orderDetails.orderRequestItems, (orderRequestItem) => {
      const { itemIdInPartnerTenant } = orderRequestItem;
      const itemInParentTenant = itemInStockItems.find((itemInStockItem) => {
        return itemInStockItem.id === itemIdInPartnerTenant;
      });

      if (!itemInParentTenant) {
        return orderRequestItem;
      }

      return {
        ...orderRequestItem,
        item: {
          ...orderRequestItem.item,
          categoryId: itemInParentTenant.categoryId,
        },
        itemIdInPartnerTenant: itemInParentTenant.id,
      } as OrderRequestItemSchema;
    });
  }

  return orderDetails;
}

export const handleAddRowInOrderDeails = (props: AddOrderRowButtonProps) => {
  const { actions, rows } = props;

  const AddOrderItemRow: AddRowOrderDetailsSchema = {
    id: crypto.randomUUID(),
    isNewItem: true,
    imageUrl: null,
    itemId: '',
    description: null,
    website: null,
    status: OrderRequestItemStatusEnum.Open,
    type: rows && rows[0]?.type,
    quantity: 1,
    projectId: null,
    project: null,
    cost: '0.00',
    nonRemovableNotes: [],
    note: null,
    parentOrderRequestItemId: null,
    itemIdInPartnerTenant: null,
    trackingDetails: [],
    item: {
      id: '',
      sku: '',
      title: '',
      attachments: [],
      categoryId: '',
      entityIdInSourceTenant: null,
    },
  };

  actions.onAddRow(AddOrderItemRow);
};

export const updatedOrderRequestItems = (orderRequestItems: OrderRequestItemSchema[]) => {
  const items: OrderRequestItemSchema[] = [];
  const itemsToAdd: OrderRequestItemSchema[] = [];

  orderRequestItems.forEach((order: any) => {
    if (order?.isNewItem) {
      itemsToAdd.push(order);
    } else {
      items.push(order);
    }
  });

  return { items, itemsToAdd };
};