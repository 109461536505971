import AdminLayout from 'app/components/AdminLayout';
import AddProject from 'app/modules/projects/views/AddProject';

function AddProjectPage() {
  return (
    <AdminLayout>
      <AddProject />
    </AdminLayout>
  );
}

export default AddProjectPage;
