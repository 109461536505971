import { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { get, isEqual } from 'lodash';
import FormTextArea from 'app/components/Form/FormTextArea';

const ReceiveNotesValueNode = ({ row, state }: any) => {
  const { entities, setEntities } = state.extraProps || {};
  const [note, setNotes] = useState('');

  const stopPropagation: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    if (entities[row.id]?.notes) {
      setNotes(entities[row.id]?.notes);
    } else {
      setNotes('');
    }
  }, [entities, row.id]);

  const onChange = useCallback((note: string) => {
    setNotes(note);
  }, []);

  const handleBlur = useCallback(() => {
    // Only update the state if the note has changed
    if (entities[row.id]?.notes !== note) {
      entities[row.id].notes = note;
      setEntities({
        ...entities,
      });
    }
  }, [setEntities, row.id, note]);

  // NOTE: It's required to add keydown to prevent input get blurred when user hits space key
  const onKeyDown = useCallback(
    (e: any) => {
      if (e.code === 'Space') {
        const noteValue = note;
        e.preventDefault();
        e.stopPropagation();
        setEntities({
          ...entities,
          [row.id]: {
            ...entities[row.id],
            notes: noteValue + ' ',
          },
        });
        setNotes(noteValue + ' ');
      }
    },
    [note, entities],
  );
  return (
    <Box className="flex h-[80px] w-full items-center overflow-x-hidden overflow-y-scroll py-[10px]">
      <Box onKeyDown={stopPropagation}>
        <FormTextArea
          characterLimit={30}
          className="-mt-6 w-[155px]"
          defaultTextAreaHeight="30px"
          rows={2}
          value={note || ''}
          onBlur={handleBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </Box>
    </Box>
  );
};
export default memo(ReceiveNotesValueNode, (prevProps, nextProps) => {
  // const rowId = get(nextProps, 'row.id');
  return isEqual(
    get(prevProps, `state.extraProps.entities`),
    get(nextProps, `state.extraProps.entities`),
  );
});
