import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';

import { MultiSelectProps, SelectOption } from './types';

const getOptionLabel = (option: SelectOption) => option.label;

const EMPTY_ARRAY: never[] = [];

const MultiSelect = (props: MultiSelectProps) => {
  const {
    placeholder = '',
    value: valueProp,
    onBlur,
    onChange,
    disabled,
    autoFocus,
    helperText,
    options
  } = props;

  const value = valueProp || EMPTY_ARRAY;
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (value.length === 0) {
      setSelectedValue([]);
    }
  }, [value]);

  useEffect(() => {
    if (
      !selectedValue.every(({ value: selectedOption }) => value.includes(selectedOption)) &&
      value.length &&
      selectedValue.length
    ) {
      setSelectedValue(
        selectedValue.filter(({ value: selectedOption }) => value.includes(selectedOption)),
      );
    }
  }, [value, selectedValue]);


  const onValueChange = useCallback(
    (event: React.SyntheticEvent, selectedOptions: SelectOption[]) => {

      setSelectedValue(selectedOptions as SelectOption[]);
      onChange?.(
        selectedOptions.map(({ value }) => value),
        selectedOptions,
      );
    },
    [onChange],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  return (
    <Autocomplete
      multiple
      className="mt-8 rounded"
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => {
        return option?.value === value?.value;
      }}
      label={''}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          helperText={helperText}
          placeholder={!selectedValue?.length ? placeholder : ''}
        />
      )}
      renderOption={(props: any, option) => {
        return (
          <div {...props} key={option.value}>
            <div>
              <div>{option.label}</div>
            </div>
          </div>
        );
      }}
      value={selectedValue}
      onBlur={onBlur}
      onChange={onValueChange}
      onInputChange={onInputValueChange}
    />
  );
};

export default MultiSelect;
