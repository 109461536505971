import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import NetworkAssetDetail from 'app/modules/network/view/NetworkAssetDetail';

function NetworkAssetsDetailPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <NetworkAssetLayout>
      <NetworkAssetDetail networkAssetId={match.params.id} />
    </NetworkAssetLayout>
  );
}

export default withRouter(NetworkAssetsDetailPage);
