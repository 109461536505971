import { useState } from 'react';
import EntityManager from 'app/modules/components/EntityManager';
import { UseEntityManagerReturnType } from 'app/modules/components/EntityManager/useEntityManager';

import { UseAffiliateMerchantStateReturnType } from '../../hook/useAffiliateMerchantState';
import { UseAffiliateMerchantsTableReturnType } from '../../hook/useAffiliateMerchantTable';
import { getAffiliateMerchantsTableColumns } from '../../utils/columns';

interface Props {
  table: UseEntityManagerReturnType['table'];
  tableState: UseAffiliateMerchantsTableReturnType['tableState'];
  showImageViewDialog: UseAffiliateMerchantStateReturnType['showImageViewDialog'];
  fetching?: boolean;
}

const AffiliateMerchantsTable = (props: Props) => {
  const {
    table,
    tableState,
    fetching,
    showImageViewDialog,
  } = props;

  const [focusId, setFocusId] = useState('');

  return (
    <div>
      <EntityManager.Table
        ignoreRelayPagination
        pagination
        actions={table.actions}
        columns={getAffiliateMerchantsTableColumns({
          focusId,
          setFocusId,
        })}
        data={tableState.rows}
        defaultSortState={{
          id: undefined,
        }}
        extraProps={{
          showImageViewDialog
        }}
        loading={tableState.fetching || fetching}
        minWidth={0}
        persistKey="affiliate_merchants_table"
        rowHeight={80}
        setState={table.setState}
        state={table.state}
        total={tableState.totalCount || 0}
        onNextPage={tableState.onNextPage}
        onPrevPage={tableState.onPrevPage}
        onReset={tableState.onReset}
      />
    </div>
  );
};

export default AffiliateMerchantsTable;
