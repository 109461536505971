import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RolesSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.RoleFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type RolesSelectQuery = { __typename?: 'Query', roles: { __typename?: 'RoleConnection', edges?: Array<{ __typename?: 'RoleEdge', node: { __typename?: 'RoleSchema', id: string, name: string, allowedScopes: Array<{ __typename?: 'AllowedScope', scope: Types.AllowedScopeEntityEnum }> } }> | null } };


export const RolesSelectDocument = gql`
    query rolesSelect($filters: RoleFilters, $sorts: [SortProps!], $limit: Float) {
  roles(filters: $filters, sorts: $sorts, limit: $limit) {
    edges {
      node {
        id
        name
        allowedScopes {
          scope
        }
      }
    }
  }
}
    `;

export function useRolesSelectQuery(options?: Omit<Urql.UseQueryArgs<RolesSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<RolesSelectQuery>({ query: RolesSelectDocument, ...options });
};