import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAssetItemMutationVariables = Types.Exact<{
  input: Types.UpdateAssetItemInput;
}>;


export type UpdateAssetItemMutation = { __typename?: 'Mutation', updateAssetItem: { __typename?: 'UpdateAssetItemPayload', success: boolean, reason?: string | null, assetItem?: { __typename?: 'AssetItemSchema', id: string } | null } };


export const UpdateAssetItemDocument = gql`
    mutation updateAssetItem($input: UpdateAssetItemInput!) {
  updateAssetItem(input: $input) {
    success
    reason
    assetItem {
      id
    }
  }
}
    `;

export function useUpdateAssetItemMutation() {
  return Urql.useMutation<UpdateAssetItemMutation, UpdateAssetItemMutationVariables>(UpdateAssetItemDocument);
};