import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormInputSignUp from 'app/components/Form/FormInputSignUp';
import useDebounce from 'app/hooks/useDebounce';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import { useZipCodeLookupQuery } from '../../../graphql/queries/generated/geoCode';
import { SIGNUP_FORM_RULES as rules } from '../rules';
import { SignUpStepThreeProps } from '../types';

function SignUpStepThree(props: SignUpStepThreeProps) {
  const { onConfirm, onClose, firstName, defaultValues, isLoading } = props;

  const [zipcode, setZipcode] = useState('');
  const debouoncedZipCode = useDebounce(zipcode.trim());

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: defaultValues,
  });
  const onFormSubmit = useMemo(() => handleSubmit(onConfirm), [handleSubmit, onConfirm]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  const [{ data, error }] = useZipCodeLookupQuery({
    variables: { filters: { zipcode: debouoncedZipCode } },
    pause: !debouoncedZipCode,
  });

  useEffect(() => {
    if (error) {
      setValue('city', '');
      setValue('state', '');
    }
  }, [error, setValue]);

  useEffect(() => {
    setValue('city', data?.geoCode.city || getValues('city'));
    setValue('state', data?.geoCode.state || getValues('state'));
  }, [data, getValues, setValue]);

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <h1 className="mt-12 text-center text-[36px] font-semibold text-grey-900">
        Welcome, {firstName}.
      </h1>
      <p className="mt-12 text-center text-grey-900">{SignUp.FormLabels.stepThreeDesc}</p>
      <Box className="mx-auto mt-24 min-w-[320px] max-w-[600px] space-y-24 px-20">
        <Controller
          control={control}
          name="organization"
          render={({ field, fieldState }) => (
            <FormInputSignUp
              {...field}
              autoFocus
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={SignUp.FormLabels.Organization}
              response={SignUp.FormValidations.OrganizationExist}
            />
          )}
          rules={rules.organization}
        />

        <Controller
          control={control}
          name="address"
          render={({ field, fieldState }) => (
            <FormInputSignUp
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={SignUp.FormLabels.Address}
            />
          )}
          rules={rules.address}
        />
        <Box className="flex space-x-12">
          <Controller
            control={control}
            name="zip"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="w-12 flex-auto"
                error={fieldState.error}
                label={SignUp.FormLabels.Zip}
                onChange={(value) => {
                  if (value.length >= 5) {
                    setZipcode(value);
                  }
                  field.onChange(value);
                }}
              />
            )}
            rules={rules.zipCode}
          />
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="w-24 flex-auto"
                error={fieldState.error}
                label={SignUp.FormLabels.City}
              />
            )}
            rules={rules.city}
          />

          <Controller
            control={control}
            name="state"
            render={({ field, fieldState }) => (
              <FormInputSignUp
                {...field}
                isRequired
                className="w-24 flex-auto"
                error={fieldState.error}
                label={SignUp.FormLabels.State}
              />
            )}
            rules={rules.state}
          />
        </Box>
        <Stack className="flex justify-between" spacing="space-x-48">
          <Button classes="bg-gray" onClick={onClose}>
            {SignUp.FormActions.previous}
          </Button>
          <Button classes="" loading={isLoading} theme="success" onClick={onFormSubmit}>
            {SignUp.FormActions.next}
          </Button>
        </Stack>
      </Box>
    </form>
  );
}

export default SignUpStepThree;
