import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ManufacturerTableRowFragmentDoc } from '../../fragments/generated/ManufacturerTable';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ManufacturersTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ManufacturerFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ManufacturersTableQuery = { __typename?: 'Query', manufacturers: { __typename?: 'ManufacturerConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges?: Array<{ __typename?: 'ManufacturerEdge', node: { __typename?: 'ManufacturerSchema', id: string, name: string, emailId?: string | null, phoneNo?: string | null, url?: string | null, updatedAt: string } }> | null } };


export const ManufacturersTableDocument = gql`
    query ManufacturersTable($filters: ManufacturerFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  manufacturers(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        ...ManufacturerTableRow
      }
    }
  }
}
    ${ManufacturerTableRowFragmentDoc}`;

export function useManufacturersTableQuery(options?: Omit<Urql.UseQueryArgs<ManufacturersTableQueryVariables>, 'query'>) {
  return Urql.useQuery<ManufacturersTableQuery>({ query: ManufacturersTableDocument, ...options });
};