import { ItemLocationSchema } from 'app/types/schema';

interface Props {
  row: ItemLocationSchema;
}

const SiteReportTotalQuantity = (props: Props) => {
  const { row } = props;
  if (row?.totalQuantity != null) {
    return (
      <span className="truncate" title={String(row.totalQuantity)}>
        {row.totalQuantity}
      </span>
    );
  }

  return <span></span>;
};

export default SiteReportTotalQuantity;
