const User = {
  Users: 'Users',
  NewUser: 'New User',
  EditUser: 'Edit User',
  UserAccounts: 'User Accounts',
  FormLabels: {
    Organization: 'Organization',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Email: 'Email',
    UserRole: 'User Role',
    DefaultSite: 'Default Site',
  },
  FormPlaceholders: {
    DefaultSite: 'Site',
    UserRoleHelperText:
      '“Staff” user role is a basic role that is required and cannot be removed from a user.',
  },
  FormValidationMessages: {
    OrganizationRequired: 'Please select an Organization.',
    FirstNameRequired: 'Please enter a First Name up to 30 characters.',
    LastNameRequired: 'Please enter a Last Name up to 30 characters.',
    EmailRequired: 'Please enter an Email Address.',
    EmailInvalid: 'Please enter a valid Email Address.',
    UserRoleRequired: 'Please select a User Role.',
  },
};

export default User;
