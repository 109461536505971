import {useCallback, useState} from "react";

interface State {
    isCategorySelected: boolean,
    isVendorSelected: boolean,
    category_id: string,
    vendor_id: string
}

const useEditInventoryItemsState = () => {
    const [state, _setState] = useState<State>({
        isCategorySelected: false,
        category_id: '',
        isVendorSelected: false,
        vendor_id: ''
    })

    const setState = useCallback((nextState: Partial<State>) => {
        _setState((prevState) => ({ ...prevState, ...nextState }));
    }, []);

    return {
        editInventoryItems: {
            state,
            setState
        },
    }
}

export type UseEditInventoryItemsStateReturnType = ReturnType<typeof useEditInventoryItemsState>
export default useEditInventoryItemsState