import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ItemsSearchDialogSuggestionQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ItemsSearchDialogSuggestionQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', id: string, title: string, description?: string | null, sku: string } | { __typename?: 'AssetKitItemSchema', id: string, title: string, description?: string | null, sku: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, title: string, description?: string | null, sku: string } | { __typename?: 'InventoryKitItemSchema', id: string, title: string, description?: string | null, sku: string } }> } };


export const ItemsSearchDialogSuggestionDocument = gql`
    query itemsSearchDialogSuggestion($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  items(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ... on AssetItemSchema {
          id
          title
          description
          sku
        }
        ... on AssetKitItemSchema {
          id
          title
          description
          sku
        }
        ... on InventoryItemSchema {
          id
          title
          description
          sku
        }
        ... on InventoryKitItemSchema {
          id
          title
          description
          sku
        }
      }
    }
  }
}
    `;

export function useItemsSearchDialogSuggestionQuery(options?: Omit<Urql.UseQueryArgs<ItemsSearchDialogSuggestionQueryVariables>, 'query'>) {
  return Urql.useQuery<ItemsSearchDialogSuggestionQuery>({ query: ItemsSearchDialogSuggestionDocument, ...options });
};