import { nanoid } from 'nanoid';
import { CreateMediaInput, MediaSchema } from 'app/types/schema';

import { getFileNameFromFileUrl } from '../../../../../../../components/ProcureForm/FormFileUploader/utils';

export function filterAttachmentsById(attachments: any[], attachmentIds: string[]) {
  return attachments.filter((attachment) => {
    return !attachmentIds.find((attachmentId) => attachment.id === attachmentId);
  });
}

export function parseAttachmentsToUrls(attachments: any[]) {
  const attachmentUrls: string[] = [];
  attachments.forEach((attachment) => {
    if (attachment?.url) {
      attachmentUrls.push(attachment.url);
    }
  });
  return attachmentUrls;
}

export function parseMediaSchemaToAttachment(attachment: MediaSchema) {
  const id = nanoid();
  return {
    id: id,
    ...attachment,
    resolutions: attachment?.resolutions || [],
  };
}

export function parseMediaSchemaToAttachments(attachments: MediaSchema[]) {
  return attachments?.map(parseMediaSchemaToAttachment) || [];
}

export function parseAttachmentsToIds(attachments: any[]) {
  return attachments?.map((attachment) => attachment.id) || [];
}

export function getAttachmentUrlsFromImageUrlName(attachments: any[], imageUrls: string[]) {
  const attachmentUrls: string[] = [];
  attachments?.forEach((attachment: CreateMediaInput) => {
    const upcProductImage = imageUrls.find((imageUrl) =>
      attachment.url?.includes(getFileNameFromFileUrl(imageUrl)),
    );

    if (upcProductImage && attachment?.url) {
      attachmentUrls.push(attachment.url);
    }
  });
  return attachmentUrls;
}

export function filterAttachmentsByImageUrlName(attachments: any[], attachmentUrls: string[]) {
  return attachments.filter((attachment) => {
    return !attachmentUrls.find((attachmentUrl) =>
      attachment.url?.includes(getFileNameFromFileUrl(attachmentUrl)),
    );
  });
}
