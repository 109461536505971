import { SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { isAccessible } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import Common from 'app/i18n/Common';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { canSwitchTenants } from 'app/modules/organizations/utils/canSwitchTenants';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

const action = AllowedPermissionActionsEnum.Access;

export const getAdminLayoutRoutesConfig = (currentUser: UserProfileFragment) => {

  return [
    {
      id: 'categories',
      route: routes.Categories(),
      routes: [routes.Categories(), routes.AddCategory(), routes.EditCategory(':id')],
    },
    {
      id: 'asset-models',
      route: routes.AssetModels(),
      routes: [routes.AssetModels(), routes.AddAssetModel(), routes.EditAssetModel(':id')],
    },
    {
      id: 'locations',
      route: routes.Locations(),
      routes: [routes.Locations(), routes.AddLocation(), routes.EditLocation(':id')],
    },
    {
      id: 'departments',
      route: routes.Departments(),
      routes: [routes.Departments(), routes.AddDepartment(), routes.EditDepartment(':id')],
    },
    {
      id: 'manufacturers',
      route: routes.Manufacturers(),
      routes: [routes.Manufacturers(), routes.AddManufacturer(), routes.EditManufacturer(':id')],
    },
    {
      id: 'projects',
      route: routes.Projects(),
      routes: [routes.Projects(), routes.AddProject(), routes.EditProject(':id')],
    },
    {
      id: 'sites',
      route: routes.Sites(),
      routes: [routes.Sites(), routes.AddSite(), routes.EditSite(':id')],
    },
    {
      id: 'labels',
      route: routes.Labels(),
      routes: [routes.Labels()],
    },
    {
      id: 'undelete',
      route: routes.Undelete(),
      routes: [routes.Undelete(), routes.UndeleteAsset(), routes.UndeleteInventory(), routes.UndeleteUser()],
    },
    {
      id: 'import',
      route: routes.Import(),
      routes: [routes.Import()],
    },
    {
      id: 'asset',
      route: routes.UndeleteAsset(),
      routes: [routes.UndeleteAsset()],
    },
    {
      id: 'inventory',
      route: routes.UndeleteInventory(),
      routes: [routes.UndeleteInventory()],
    },
    {
      id: 'user',
      route: routes.UndeleteUser(),
      routes: [routes.UndeleteUser()],
    },
    {
      id: 'organizations',
      route: routes.Organizations(),
      routes: [routes.Organizations(), routes.AddOrganization(), routes.EditOrganizations(':id')],
    },
    {
      id: 'organization',
      route: routes.EditOrganization(currentUser.tenantId),
      routes: [routes.EditOrganization(':id')],
    },
    {
      id: 'partners',
      route: routes.Partners(),
      routes: [routes.Partners(), routes.AddPartner(), routes.EditPartner(':id')],
    },
    {
      id: 'roles',
      route: routes.Roles(),
      routes: [routes.Roles(), routes.AddRole(), routes.EditRole(':id')],
    },
    {
      id: 'users',
      route: routes.Users(),
      routes: [routes.Users(), routes.AddUser(), routes.EditUser(':id')],
    },
    {
      id: 'warehouse',
      route: routes.Warehouse(),
      routes: [routes.Warehouse()],
    },
  ];
};

export const getAdminLayoutSidebarItems = (
  permissions: UserProfileFragment['workspacePermissions'],
) => {
  const isSuperAdmin = canSwitchTenants(permissions);

  return [
    {
      id: 'section-1',
      sideBarItems: [
        {
          id: 'categories',
          label: Common.Modules.Categories,
          subject: AllowedPermissionsSubjectEnum.Category,
        },
        {
          id: 'asset-models',
          label: Common.Modules.AssetModels,
          subject: AllowedPermissionsSubjectEnum.AssetModel,
        },
        {
          id: 'locations',
          label: Common.Modules.Locations,
          subject: AllowedPermissionsSubjectEnum.Location,
        },
        {
          id: 'departments',
          label: Common.Modules.Departments,
          subject: AllowedPermissionsSubjectEnum.Department,
        },
        {
          id: 'manufacturers',
          label: Common.Modules.Manufacturers,
          subject: AllowedPermissionsSubjectEnum.Manufacturer,
        },
        {
          id: 'projects',
          label: Common.Modules.Projects,
          subject: AllowedPermissionsSubjectEnum.Project,
        },
        {
          id: 'sites',
          label: Common.Modules.Sites,
          subject: AllowedPermissionsSubjectEnum.Site,
        },
      ].filter((item) =>
        isAccessible(permissions, action, item.subject),
      ).sort((a,b) => a.label.localeCompare(b.label)),
    },
    {
      id: 'section-2',
      sideBarItems: [
        {
          id: 'labels',
          label: Common.Modules.Labels,
          subject: AllowedPermissionsSubjectEnum.Label,
        },
        {
          id: 'import',
          label: Common.Modules.Import,
          subject: AllowedPermissionsSubjectEnum.Import,
        },
        {
          id: 'undelete',
          label: Common.Modules.Undelete.Undelete,
          subject: AllowedPermissionsSubjectEnum.Undelete,
          childActions: [AllowedPermissionActionsEnum.Undelete],
          childSubjects: [
            AllowedPermissionsSubjectEnum.Asset,
            AllowedPermissionsSubjectEnum.Inventory,
          ],
        },
      ].filter((item) =>
        isAccessible(permissions, action, item.subject, item?.childActions, item?.childSubjects),
      ),
    },
    {
      id: 'section-3',
      sideBarItems: [
        {
          id: 'organizations',
          label: isSuperAdmin ? Common.Modules.Organizations : Common.Modules.Organization,
          subject: AllowedPermissionsSubjectEnum.Tenant,
        },
        {
          id: 'partners',
          label: Common.Modules.Partners,
          subject: AllowedPermissionsSubjectEnum.PartnerTenant,
        },
        {
          id: 'roles',
          label: Common.Modules.UserRoles,
          subject: AllowedPermissionsSubjectEnum.Role,
        },
        {
          id: 'users',
          label: Common.Modules.UserAccounts,
          subject: AllowedPermissionsSubjectEnum.User,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
    {
      id: 'section-4',
      sideBarItems: [
        {
          id: 'warehouse',
          label: Common.Modules.Warehouse,
          subject: AllowedPermissionsSubjectEnum.Warehouse,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
  ] as SideBarItemGroup[];
};
