import SearchShipments from 'app/modules/ship/view/searchShipments';

import ShippingLayout from '../../modules/ship/layout';

function SearchShipmentPage() {
  return (
    <ShippingLayout>
      <SearchShipments />
    </ShippingLayout>
  );
}

export default SearchShipmentPage;
