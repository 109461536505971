import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteContactsMutationVariables = Types.Exact<{
  input: Types.DeleteContactsInput;
}>;


export type DeleteContactsMutation = { __typename?: 'Mutation', deleteContacts: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteContactsDocument = gql`
    mutation deleteContacts($input: DeleteContactsInput!) {
  deleteContacts(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteContactsMutation() {
  return Urql.useMutation<DeleteContactsMutation, DeleteContactsMutationVariables>(DeleteContactsDocument);
};