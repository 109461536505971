import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateReceivableMutationVariables = Types.Exact<{
  input: Types.UpdateReceivableInput;
}>;


export type UpdateReceivableMutation = { __typename?: 'Mutation', updateReceivable: { __typename?: 'ReceivablesPayload', success: boolean, reasons?: Array<string> | null, receivables: Array<{ __typename?: 'ReceivableSchema', id: string }> } };


export const UpdateReceivableDocument = gql`
    mutation updateReceivable($input: UpdateReceivableInput!) {
  updateReceivable(input: $input) {
    receivables {
      id
    }
    success
    reasons
  }
}
    `;

export function useUpdateReceivableMutation() {
  return Urql.useMutation<UpdateReceivableMutation, UpdateReceivableMutationVariables>(UpdateReceivableDocument);
};