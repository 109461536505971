import AdminLayout from 'app/components/AdminLayout';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EditDepartment from 'app/modules/departments/views/EditDepartment';

function EditDepartmentPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  return (
    <AdminLayout>
      <EditDepartment departmentId={match.params.id} />
    </AdminLayout>
  );
}

export default withRouter(EditDepartmentPage);
