import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { debounce } from 'lodash';
import { adaptNodeEdgeToNode } from 'app/components/AsyncMultiSelect/utils';
import usePagination from 'app/hooks/usePagination';
import { useOrderHistoryScheduleReceivingQuery } from 'app/modules/orders/views/OrderHistory/graphql/query/generated/orderHistoryScheduleReceive';

import { OrderRequestSchema, SortOrderEnum } from '../../../types/schema';
import FormLabel from '../FormLabel';
export type Option = OrderRequestSchema;

const getOptionLabel = (option: any) => {
  return option.orderRequestCode;
};
const PAGE_LIMIT = 50;
type Props = {
  className?: string;
  isRequired?: boolean;
  label?: string;
  name?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  value?: any;
  onBlur?: () => void;
  onChange: (values: string, option: Option | null) => void;
  placeholder?: string;
  error?: FieldError | undefined;
  size?: any;
  id?: string;
  formLabel?: string;
  paginated?: boolean;
};

const FormOrderSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    value,
    onBlur,
    label,
    onChange,
    error,
    className = 'mt-6',
    isRequired,
    disabled,
    placeholder,
    autoFocus,
    size,
    id,
    formLabel,
    paginated,
    name
  } = props;
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [activePage, setActivePage] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);

  const { data, fetching, onNextPage, onReset } = usePagination(
    useOrderHistoryScheduleReceivingQuery,
    {
      filters: {
        search: searchValue,
      },
      sorts: [
        {
          sortOrder: SortOrderEnum.Desc,
          sortField: 'name',
        },
      ],
    },
    {
      pageSize: PAGE_LIMIT,
      edgeKey: 'orderRequests',
    },
  );

  const [options, setOptions] = useState<any | Option>([]);

  useEffect(() => {
    if (!activePage || searchValue) {
      setOptions(data?.orderRequests?.edges?.map(adaptNodeEdgeToNode) || []);
    } else if (loadingOptions && !fetching) {
      setOptions((prevOptions: any) => {
        const newOptions = data?.orderRequests?.edges?.map(adaptNodeEdgeToNode) || [];
        const uniqueOptions = Array.from(new Set([...prevOptions, ...newOptions]));
        return uniqueOptions;
      });
      setLoadingOptions(false);
    }
  }, [activePage, data?.orderRequests?.edges, searchValue, loadingOptions, fetching]);

  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;

      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;

      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !fetching && !loadingOptions) {
        setLoadingOptions(true);
        onNextPage();
        setActivePage(activePage + 1);
      }
    },
    [onNextPage, fetching, loadingOptions, setActivePage, activePage],
  );

  const onValueChange = useCallback(
    (_: any, option: any) => {
      const updatedOption = option ? (option as Option) : null;
      const id = updatedOption?.id || '';
      onChange?.(id, updatedOption);
      setSelectedValue(updatedOption);
    },
    [onChange, setSelectedValue],
  );

  useEffect(() => {
    const selectedOption = options.find((option: any) => option?.id === value);
    if (selectedOption) {
      setSelectedValue(selectedOption);
    }
  }, [options, setSelectedValue, value]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;

  const handleReset = () => {
    onReset();
    setActivePage(0);
    setSearchValue('');
  };

  const onSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      if (!value) {
        handleReset();
      }
    }, 500),
    [],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    onSearch(value);
  };

  return (
    <div>
      {formLabel ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
      <div ref={ref}>
        <Autocomplete
          ListboxProps={
            paginated
              ? {
                  onScroll: onScroll,
                  role: 'list-box',
                }
              : {}
          }
          className={className || ''}
          disabled={!!disabled}
          getOptionLabel={getOptionLabel}
          id={id}
          inputValue={inputValue}
          isOptionEqualToValue={(option, valueForOption) => {
            return option?.id === valueForOption?.id;
          }}
          label=""
          loading={fetching}
          options={options as any}
          renderInput={(params) => (
            <TextField
              name={name}
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={!!autoFocus}
              error={!!error}
              errorMessage={error ? (error.message as string) : ''}
              label={label}
              labelNode={LabelNode}
              placeholder={placeholder}
            />
          )}
          size={size}
          value={selectedValue as any}
          onBlur={onBlur}
          onChange={onValueChange}
          onClose={() => {
            if (searchValue) {
              handleReset();
            }
          }}
          onInputChange={onInputValueChange}
          onKeyDown={(event: any) => {
            event.stopPropagation();
          }}
          sx={{
            '& .MuiInputBase-input .MuiAutocomplete-input': {
              paddingLeft: '0px !important',
            },
          }}
        />
      </div>
    </div>
  );
});

export default FormOrderSelect;
