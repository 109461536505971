import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import { useAccessControl } from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import Common from '../../../../../../i18n/Common';
import Box from '../../../../../../ui-components/Box';
import { UseAffiliateMerchantFormReturnType } from '../../hook/useAffiliateMerchantForm';

interface Props {
  formState: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
}

const SaveCancelActionButtons = (props: Props) => {
  const { formState, disabled, onSubmit, onCancel } = props;
  const { attachmentInputProps, editable, affiliateConfigurationId, cancelEditable, onDeleteConfirmation } = formState;

  const { workspacePermissions } = useCurrentUser();

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.AffiliateConfiguration,
  );

  const handleCancel = useCallback(() => {
    
      cancelEditable();
      onCancel && onCancel();
  }, [onCancel, affiliateConfigurationId, cancelEditable]);

  if (!editable) {
    return null;
  }

  return (
    <Box className="mb-20 flex justify-end space-x-8">
      <Button
        disabled={disabled || attachmentInputProps.isUploading}
        loading={disabled}
        theme="success"
        onClick={onSubmit}>
        {Common.Actions.Save}
      </Button>
      {canDelete && affiliateConfigurationId ? (
        <Button disabled={disabled} theme="danger" onClick={onDeleteConfirmation}>
          {Common.Actions.Delete}
        </Button>
      ) : null}
      <Button onClick={handleCancel}>{Common.Actions.Cancel}</Button>
    </Box>
  );
};

export default SaveCancelActionButtons;
