import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditCategoryDataFragmentDoc } from '../../fragments/generated/EditCategoryData';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditCategoryDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CategoryFilters>;
}>;


export type EditCategoryDataQuery = { __typename?: 'Query', categories: { __typename?: 'CategoryConnection', totalCount: number, edges: Array<{ __typename?: 'CategoryEdge', node: { __typename?: 'CategorySchema', id: string, name: string, description?: string | null, categoryCode?: string | null, categoryManagerIds: Array<string>, formattedDescription?: string | null } }> } };


export const EditCategoryDataDocument = gql`
    query editCategoryData($filters: CategoryFilters) {
  categories(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditCategoryData
      }
    }
  }
}
    ${EditCategoryDataFragmentDoc}`;

export function useEditCategoryDataQuery(options?: Omit<Urql.UseQueryArgs<EditCategoryDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditCategoryDataQuery>({ query: EditCategoryDataDocument, ...options });
};