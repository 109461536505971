import { ItemLocationSchema, LocationSchema, ShippingContainerSchema } from "app/types/schema"

import { FormState, LabelCustomEnum, LabelStockEnum, LabelTypeEnum } from "../../Labels/components/Forms/types"
import { CodeSchema } from "../components/types"

const getTitle = (item: CodeSchema, type?: LabelTypeEnum | undefined, field?: string[] | undefined, codes?: FormState & { data: Array<CodeSchema> }, ) => {
  const stock = codes && codes.labelStock;
  const charLimit = [LabelStockEnum.Avery6570].includes(
    stock as LabelStockEnum,
  )
    ? 21
    :  LabelStockEnum.Avery5160 === stock ? 32 : 15;
    
    switch (type) {
      case LabelTypeEnum.Location:
        const siteName= (item as LocationSchema)?.site?.name?.substring(0, charLimit);
        const locationName = (item as LocationSchema)?.name?.substring(0, charLimit);
        
        if (field?.[0] !== '' && field?.[1] === LabelCustomEnum.Custom) {
          switch (field[0]) {
            case 'site':
              return siteName;
            case 'location':
              return locationName;
          }
        }
        return locationName;

      case LabelTypeEnum.Shipping:
      case LabelTypeEnum.ShippingSetUp:
        return (item as ShippingContainerSchema)?.destinationSite?.name?.substring(0, charLimit);

      default:
        const sku = (item as ItemLocationSchema)?.item?.sku?.substring(0, charLimit);
        const title = (item as ItemLocationSchema)?.item?.title?.substring(0, charLimit);
        const site = (item as ItemLocationSchema)?.site?.name?.substring(0, charLimit);
        const location = (item as ItemLocationSchema)?.location?.name?.substring(0, charLimit);
        
        if (field?.[0] !== '' && field?.[1] === LabelCustomEnum.Custom) {
          switch (field[0]) {
            case 'title':
              return title;
            case 'sku':
              return sku;
            case 'location':
              return location;
            case 'site':
              return site;
          }
        } else {
          switch (field?.[2]) {
            case '1':
              return sku;
            case '2':
              return location;
            default:
              return title;
          }
        }
    }
}

export default getTitle