import WarningProvider from 'app/components/WarningAlert';
import OrdersLayout from 'app/modules/orders/layout';
import OrderHistory from 'app/modules/orders/views/OrderHistory';

function OrderHistoryPage() {
  return (
    <WarningProvider>
      <OrdersLayout>
        <OrderHistory />
      </OrdersLayout>
    </WarningProvider>
  );
}

export default OrderHistoryPage;
