import { TransactionEntityTypeEnum } from 'app/types/schema';

export enum SiteReportOption {
  nonZeroTotalQuantity = 'nonZeroTotalQuantity',
  includeItemsWithZeroQuantity = 'includeItemsWithZeroQuantity',
  onlyMinimumQuantityThresholdBreached = 'onlyMinimumQuantityThresholdBreached',
}

export interface SiteReportFormValues {
  types: TransactionEntityTypeEnum[];
  skuIds: string[];
  categoryIds: string[];
  siteIds: string[];
  format: string;
  siteReportOption: SiteReportOption;
  itemTypes: TransactionEntityTypeEnum[];
}