import { useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import Partner from 'app/i18n/Partner';
import { Redirect } from 'app/libs/navigation';
import { getTokenData } from 'app/modules/partners/utils/token';
import InvitePartner from 'app/modules/partners/views/InvitePartner';

import useCurrentUser from '../../../modules/auth/hooks/useCurrentUser';
import { getRedirectToBasedOnPermission } from '../../../modules/auth/utils/redirectTo';
import AdminPartnerLayout from '../../../modules/partners/layout';

const InvitePartnerPage = () => {
  const tokenData = getTokenData();

  const { workspacePermissions } = useCurrentUser();

  const redirectTo = useMemo(() => {
    return getRedirectToBasedOnPermission(workspacePermissions);
  }, [workspacePermissions]);

  if (!tokenData || !tokenData?.tenantId) {
    SnackbarService.show({
      type: 'error',
      message: Partner.ErrorMessages.InvalidInvitationToken,
    });
    return <Redirect to={redirectTo} />;
  }

  return (
    <AdminPartnerLayout>
      <InvitePartner tokenData={tokenData} />
    </AdminPartnerLayout>
  );
};

export default InvitePartnerPage;
