import AdminLayout from 'app/components/AdminLayout';
import WarehouseDetails from 'app/modules/warehouse/views/NewWarehouseDetails';

const WarehousePage = () => {
  return (
    <AdminLayout>
      <WarehouseDetails />
    </AdminLayout>
  );
};

export default WarehousePage;
