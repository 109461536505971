import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateReceivablesMutationVariables = Types.Exact<{
  input: Types.CreateReceivablesInput;
}>;


export type CreateReceivablesMutation = { __typename?: 'Mutation', createReceivables: { __typename?: 'ReceivablesPayload', success: boolean, reasons?: Array<string> | null, receivables: Array<{ __typename?: 'ReceivableSchema', id: string }> } };


export const CreateReceivablesDocument = gql`
    mutation createReceivables($input: CreateReceivablesInput!) {
  createReceivables(input: $input) {
    receivables {
      id
    }
    success
    reasons
  }
}
    `;

export function useCreateReceivablesMutation() {
  return Urql.useMutation<CreateReceivablesMutation, CreateReceivablesMutationVariables>(CreateReceivablesDocument);
};