import { useCallback, useState } from 'react';

interface State {
  open: boolean;
}

const useInvitePartnerDialogState = () => {
  const [state, _setState] = useState<State>({
    open: false,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  return {
    state,
    setState,
  };
};

export type UseInvitePartnerDialogStateReturnType = ReturnType<typeof useInvitePartnerDialogState>;

export default useInvitePartnerDialogState;
