import { Controller } from 'react-hook-form';
import FormTextInput from 'app/components/Form/FormTextInput';
import Assets from 'app/i18n/Assets';

import { AssetFieldProps } from '../../types';
import { ASSET_FORM_RULES } from '../../utils';

const AssetTitleField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="title"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            isRequired
            characterLimit={32}
            error={fieldState.error}
            isDisabled={disabled}
            label={Assets.FormLabels.Title}
          />
        )}
        rules={ASSET_FORM_RULES.title}
      />
    </div>
  );
};

export default AssetTitleField;
