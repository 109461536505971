import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type CategoriesTableRowFragment = { __typename?: 'CategorySchema', id: string, name: string, description?: string | null, categoryCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string }, categoryManagers: Array<{ __typename?: 'UserSchema', id: string, name: string }> };

export const CategoriesTableRowFragmentDoc = gql`
    fragment CategoriesTableRow on CategorySchema {
  id
  name
  description
  categoryCode
  updatedAt
  updatedBy {
    id
    name
  }
  categoryManagers {
    id
    name
  }
}
    `;