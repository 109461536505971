import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';

import Assets from '../../../../../i18n/Assets';
import Common from '../../../../../i18n/Common';
import Inventory from '../../../../../i18n/Inventory';
import { ItemTypeEnum, LocationTypeEnum } from '../../../../../types/schema';
import FormNumberInput from '../../../../components/FormNumberInput';
import FormSiteSelect from '../../../../locations/components/FormSiteSelect';
import FormToLocationSelect from '../../../../locations/components/FormToLocationSelect';
import ItemInStockSelect from '../../../components/FormItemInStock';
import { UseRestockItemStateReturnType } from '../../hook/useRestockItemState';
import { RESTOCK_FORM_RULES } from './utils';
import FormRestockLocationSelect from '../../../../locations/components/FormRestockSiteSelect';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  formState: UseRestockItemStateReturnType['formState'];
  onFormSubmit: UseRestockItemStateReturnType['onFormSubmit'];
  types: ItemTypeEnum[];
}

const RestockForm = (props: Props) => {
  const { formState, onFormSubmit, types } = props;
  const {
    control,
    destinationSiteId,
    setState,
    itemInStockId,
    siteIds,
    locationIds,
    onResetForm,
    isKitTypeItemInStock,
    itemType,
  } = formState;

  const formRef = useRef<HTMLDivElement>(null);
  const onSubmit = async (data: any) => {
    await onFormSubmit(data);
    ScrollToError(formState.control._formState.errors, formRef)
  };

  return (
    <div ref={formRef}>
      <form className="mt-20" onSubmit={onSubmit}>
        <div className="mb-20">
          <ItemInStockSelect
            autoFocus
            control={control}
            name="itemInStockId"
            types={types}
            onChange={(value, option) => {
              onResetForm({
                itemInStockId: value,
              });
            }}
          />
        </div>
        <div className="mb-20">
          <Controller
            control={control}
            name="destinationSiteId"
            render={({ field, fieldState }) => (
              <FormRestockLocationSelect
                {...field}
                isRequired
                className="flex-1"
                error={fieldState.error}
                label={Inventory.FormLabels.ToSite}
                itemId={itemInStockId}
                itemType={itemType}
                disabled={!itemInStockId}
                clearable
                locationIds={siteIds}
                onChange={(value, option) => {
                  field.onChange(value);
                  setState({
                    destinationSite: option || null,
                  });
                }}
              />
            )}
            rules={RESTOCK_FORM_RULES.destinationId}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="destinationLocationId"
            render={({ field, fieldState }) =>
              isKitTypeItemInStock ? (
                <FormRestockLocationSelect
                  {...field}
                  isRequired
                  className="flex-1"
                  error={fieldState.error}
                  label={Inventory.FormLabels.ToLocation}
                  itemId={itemInStockId}
                  siteId={destinationSiteId}
                  itemType={itemType}
                  disabled={!destinationSiteId}
                  isLocationInput
                  clearable
                  locationIds={locationIds}
                  onChange={(value, option) => {
                    field.onChange(value);
                    setState({
                      destinationLocation: option || null,
                    });
                  }}
                />
              ) : (
                <FormToLocationSelect
                  {...field}
                  isRequired
                  className="flex-1"
                  disabled={!destinationSiteId}
                  error={fieldState.error}
                  disabledMostRecent={isKitTypeItemInStock}
                  siteId={destinationSiteId}
                  locationIds={locationIds}
                  itemInStockId={itemInStockId}
                  label={Inventory.FormLabels.ToLocation}
                  onChange={(value, option) => {
                    field.onChange(value);
                    setState({
                      destinationLocation: option || null,
                    });
                  }}
                />
              )
            }
            rules={RESTOCK_FORM_RULES.destinationLocationId}
          />
        </div>

        <div className="mb-20">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState }) => (
              <FormNumberInput
                {...field}
                isRequired
                error={fieldState.error}
                label={Inventory.FormLabels.Quantity}
                maximumLength={9}
              />
            )}
            rules={RESTOCK_FORM_RULES.quantity}
          />
        </div>

        <div className="flex">
          <div className="flex flex-auto justify-end">
            <Button theme="success" onClick={onSubmit} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Add}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RestockForm;
