
import { useCallback, useMemo } from "react";
import { toInteger } from "lodash";
import { SnackbarService } from "app/components/Snackbar";
import Network from "app/i18n/Network";
import { useUpdateNetworkAssetMutation } from "app/modules/network/graphql/mutations/generated/updateNetworkAsset";
import { bytesToGBNumber,GBToBytes } from "app/modules/network/utils";
import {NetworkAssetSchema } from "app/types/schema";
import analytics from 'app/analytics';

type Props = {
  networkAssetDetail: NetworkAssetSchema | undefined;
  onSaveHandler: () => void;
}

const useNetworkAssetFormData = (props: Props) => {
  const { networkAssetDetail, onSaveHandler} = props;

  const [{fetching}, onUpdateNetworkAsset] = useUpdateNetworkAssetMutation();

  const motherboardModel = networkAssetDetail?.motherboard?.model;

  const networkDetails = networkAssetDetail?.network?.length ? networkAssetDetail.network.find(obj => obj.default) : null;

  const networkAssetFormDefaultValues = useMemo(() => {
    return {
      userName: networkAssetDetail?.userName || '',
      mac: networkDetails?.mac,
      email: networkAssetDetail?.email || '',
      cpuManufacturer: networkAssetDetail?.cpu?.manufacturer || "",
      cpuBrand: networkAssetDetail?.cpu?.brand || "",
      cpuModel: networkAssetDetail?.cpu?.model || "",
      cpuProcessors: networkAssetDetail?.cpu?.processors || 0,
      cpuCores: networkAssetDetail?.cpu?.cores || 0,
      cpuPhysicalCores: networkAssetDetail?.cpu?.physicalCores || 0,
      cpuPerformanceCores: networkAssetDetail?.cpu?.performanceCores || 0,
      cpuStepping: networkAssetDetail?.cpu?.stepping || "",
      cpuVoltage: networkAssetDetail?.cpu?.voltage || "",
      diskFileSystem: networkAssetDetail?.disk?.length ? networkAssetDetail?.disk[0].fileSystem : "",
      diskReadWrite: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].readWrite ? "True" : "False",
      diskType: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].type,
      diskUse: networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].use?.toString(),
      diskSize: bytesToGBNumber(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].size),
      diskUsed: bytesToGBNumber(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].used),
      diskAvailable: bytesToGBNumber(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].available),
      systemManufacturer: networkAssetDetail?.system?.manufacturer,
      systemModel: networkAssetDetail?.system?.model, 
      systemSerial: networkAssetDetail?.system?.serial, 
      systemVirtual: networkAssetDetail?.system?.virtual,
      memoryTotal: bytesToGBNumber(networkAssetDetail?.memory?.total),
      memoryAvailable: bytesToGBNumber(networkAssetDetail?.memory?.available),
      memoryFree: bytesToGBNumber(networkAssetDetail?.memory?.free), 
      memorySlab: bytesToGBNumber(networkAssetDetail?.memory?.slab), 
      memoryBuffer: bytesToGBNumber(networkAssetDetail?.memory?.buffers),
      memorySwapTotal: bytesToGBNumber(networkAssetDetail?.memory?.swaptotal), 
      memorySwapFree: bytesToGBNumber(networkAssetDetail?.memory?.swapfree), 
      memorySwapUsed: bytesToGBNumber(networkAssetDetail?.memory?.swapused),
      osPlatform:  networkAssetDetail?.operatingSystem?.platform,
      osDistro: networkAssetDetail?.operatingSystem?.distro,
      osCodename: networkAssetDetail?.operatingSystem?.codename,
      osRelease: networkAssetDetail?.operatingSystem?.release,
      osHostname: networkAssetDetail?.operatingSystem?.hostname,
      osSerial: networkAssetDetail?.operatingSystem?.serial,
      osArchitecture: networkAssetDetail?.operatingSystem?.arch,
      osKernal: networkAssetDetail?.operatingSystem?.kernel,
      processAll: networkAssetDetail?.process?.all,
      processBlocked:  networkAssetDetail?.process?.blocked,
      processRunning: networkAssetDetail?.process?.running,
      processSleeping: networkAssetDetail?.process?.sleeping,
      processUnknown: networkAssetDetail?.process?.unknown,
      batteryManufacturer: networkAssetDetail?.battery?.manufacturer,
      batteryType: networkAssetDetail?.battery?.type,
      batterySerial: networkAssetDetail?.battery?.serial,
      batteryDesignedCapacity: networkAssetDetail?.battery?.designedCapacity, 
      batteryMaxCapacity: networkAssetDetail?.battery?.maxCapacity,
      batteryCurrentCapacity: networkAssetDetail?.battery?.currentCapacity,
      batteryModel: networkAssetDetail?.battery?.model,
      batteryVoltage: networkAssetDetail?.battery?.voltage,
      batteryPercentage: networkAssetDetail?.battery?.percent,
      motherboardManufacturer: networkAssetDetail?.motherboard?.manufacturer,
      motherboardVersion: networkAssetDetail?.motherboard?.version,
      motherboardModel: motherboardModel && motherboardModel.includes(';') ? motherboardModel.split(';')[0] : '',
      motherboardSerial: networkAssetDetail?.motherboard?.serial,
      storageName: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].name,
      storageSerial: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].serialNum,
      storageType: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].type,
      storageInterface: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].interfaceType,
      storageVendor: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].vendor,
      storageSize: bytesToGBNumber(networkAssetDetail?.disk?.length && networkAssetDetail?.disk[0].size),
      storageFirmware: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].firmwareRevision,
      storageDevice: networkAssetDetail?.storage?.length && networkAssetDetail.storage[0].device,
      displayBuiltin: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].builtin ? "True" : "False",
      displayCRR: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentRefreshRate,
      displayCResX: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentResX,
      displayCResY: networkAssetDetail?.display?.length && networkAssetDetail?.display[0].currentResY,
      biosVendor: networkAssetDetail?.bios?.vendor,
      biosVersion: networkAssetDetail?.bios?.version,
      biosRelease: networkAssetDetail?.bios?.releaseDate,
      networkIface: networkDetails?.iface,
      networkIpv4: networkDetails?.ip4,
      networkIpv6: networkDetails?.ip6,
      networkMac: networkDetails?.mac,
      networkType: networkDetails?.type,
      graphicsVendor: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].vendor,
      graphicsModel: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].model,
      graphicsBus: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].bus,
      graphicsVram: networkAssetDetail?.graphics?.length && networkAssetDetail.graphics[0].vram,
    };
  }, [networkAssetDetail]);

  const onSubmit = useCallback(
    (input: any) => {
      const updateNetworkAssetInputs = {
        mac: input.mac,
        email: input.email?.trim(),
        userName: input.userName?.trim(),
        cpu: {
          manufacturer: input.cpuManufacturer,
          brand: input.cpuBrand,
          model: input.cpuModel, 
          processors: toInteger(input.cpuProcessors),
          cores: toInteger(input.cpuCores), 
          physicalCores: toInteger(input.cpuPhysicalCores), 
          performanceCores: toInteger(input.cpuPerformanceCores), 
          stepping: input.cpuStepping, 
          voltage: input.cpuVoltage
        },
        disk:[
          {
            fileSystem: input.diskFileSystem,
            readWrite: JSON.parse(input.diskReadWrite.toLowerCase()),
            type: input.diskType, 
            use: JSON.parse(input.diskUse), 
            size: GBToBytes(input.diskSize),
            used: GBToBytes(input.diskUsed),
            available: GBToBytes(input.diskAvailable)
          }
        ],
        system: {
          manufacturer: input.systemManufacturer, 
          model: input.systemModel, 
          serial: input.systemSerial, 
          virtual: input.systemVirtual
        },
        memory: {
          total: GBToBytes(input.memoryTotal), 
          free: GBToBytes(input.memoryFree), 
          slab: GBToBytes(input.memorySlab), 
          buffers: GBToBytes(input.memoryBuffer), 
          swaptotal: GBToBytes(input.memorySwapTotal), 
          swapfree: GBToBytes(input.memorySwapFree), 
          swapused: GBToBytes(input.memorySwapUsed)
        },
        operatingSystem: {
          platform: input.osPlatform, 
          distro: input.osDistro, 
          codename: input.osCodename, 
          release: input.osRelease, 
          hostname: input.osHostname, 
          serial: input.osSerial, 
          arch: input.osArchitecture, 
          kernel: input.osKernal
        },
        process: {
          all: toInteger(input.processAll), 
          blocked: toInteger(input.processBlocked), 
          running: toInteger(input.processRunning), 
          sleeping: toInteger(input.processSleeping), 
          unknown: toInteger(input.processUnknown)
        },
        battery: {
          manufacturer: input.batteryManufacturer, 
          type: input.batteryType,
          serial: input.batterySerial, 
          designedCapacity: toInteger(input.batteryDesignedCapacity),
          maxCapacity: toInteger(input.batteryMaxCapacity),
          currentCapacity: toInteger(input.batteryCurrentCapacity), 
          model: input.batteryModel, 
          voltage: toInteger(input.batteryVoltage), 
          percent: toInteger(input.batteryPercentage),
        },
        motherboard: {
          manufacturer: input.motherboardManufacturer,
          version: input.motherboardVersion,
          model: input.motherboardModel, 
          serial: input.motherboardSerial
        },
        storage: [{
          name: input.storageName,
          serialNum: input.storageSerial, 
          type: input.storageType, 
          interfaceType: input.storageInterface, 
          vendor: input.storageVendor,
          size: input.storageSize,
          firmwareRevision: input.storageFirmware,
          device: input.storageDevice,
        }],
        display: [{
          builtin: JSON.parse(input.displayBuiltin.toLowerCase()),
          currentRefreshRate: input.displayCRR,
          currentResX: input.displayCResX,
          currentResY: input.displayCResY,
        }],
        bios: {
          vendor: input.biosVendor, 
          version: input.biosVersion, 
          releaseDate: input.biosRelease,
        },
        graphics: [{
          vendor: input.graphicsVendor, 
          model: input.graphicsModel, 
          bus: input.graphicsBus,
          vram: input.graphicsVram,
        }]
      };
      
      onUpdateNetworkAsset({ input: { ...updateNetworkAssetInputs } }).then((response) => {
        if (response.data?.createNetworkAsset.success) {
          analytics?.track('Edited', { name: 'Network Asset' });
          SnackbarService.show({
            message: Network.SuccessMessages.NetworkAssetUpdated,
          });
          onSaveHandler()
        } else {
          console.error('[Update Category] Failed', response);
        }
      });
    },
    [onUpdateNetworkAsset],
  );

  return {
    networkAssetFormDefaultValues,
    onSubmit,
    fetching
  };
};
export type UseOperatingSystemDataReturnType = ReturnType<typeof useNetworkAssetFormData>;
export default useNetworkAssetFormData;
