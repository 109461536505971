import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import {
  AsyncSingleSelectProps,
  UseSelectedValueReturn,
} from 'app/components/AsyncSingleSelect/types';
import AsyncSingleTableFilter from 'app/components/AsyncSingleTableFilter';
import { useValuesInEntitiesSelectQuery } from 'app/modules/users/graphql/queries/generated/distinctUserSelect';

import {
  AsyncMultiSelectProps,
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from '../../../../components/AsyncMultiSelect/utils';
import AsyncMultiTableFilter from '../../../../components/AsyncMultiTableFilter';
import {
  LocationTypeEnum,
  Maybe,
  SortOrderEnum,
  ValueInEntitiesFiltersConfig,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from '../../../../types/schema';
import { useFormSiteSelectQuery } from '../../../locations/components/FormSiteSelect/graphql/queries/generated/formSiteSelect';
import { UNASSIGNED_TEXT } from '../../../locations/utils/location';

interface SiteQueryVariables {
  types?: LocationTypeEnum[];
  siteIds?: string[];
  distinctTableName?: ValuesInEntitiesTableNameEnum;
  distinctKeys?: ValuesInEntitiesDistinctByKeysEnum[];
  filters?:
  | ValueInEntitiesFiltersConfig['transactionFilters']
  | ValueInEntitiesFiltersConfig['shippingTransactionFilters'];
  filterKey?: keyof ValueInEntitiesFiltersConfig;
  filterType?: string;
}

export interface UseSelectedValueProps {
  value: string[];
  pause?: boolean;
  options?: any[];
}

export interface SiteSelectProps {
  name: string;
  testId?: string;
  value?: Maybe<string[]>;
  onBlur?: () => void;
  onChange: AsyncMultiSelectProps['onChange'] | AsyncSingleSelectProps['onChange'];
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: SiteQueryVariables;
  onClick?: (event?: any) => void;
  isMultiple?: boolean;
  isDistinct?: boolean;
}

const useSelectedValues = (
  props: UseSelectedValueProps & {
    types?: LocationTypeEnum[];
    siteIds?: string[];
    isMultiple?: boolean;
  },
): UseSelectedValuesReturn | UseSelectedValueReturn => {
  const { value, pause, types, siteIds, isMultiple = true } = props;

  const [{ fetching, data }] = useFormSiteSelectQuery({
    pause,
    variables: {
      filters: {
        locationIds: value ? value : undefined,
        types: types || [LocationTypeEnum.Site],
        siteIds,
      },
    },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.locations.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.locations.edges],
  );

  if (isMultiple) return { isDisabled: fetching, selectedValues: values };
  else return { isDisabled: fetching, selectedValue: values[0] };
};

const useSelectQuery = (
  props: UseSelectQueryProps & { types?: LocationTypeEnum[]; siteIds?: string[] },
): UseSelectQueryReturn => {
  const { types, siteIds } = props;

  const [{ fetching, data }] = useFormSiteSelectQuery({
    variables: {
      filters: {
        types: types || [LocationTypeEnum.Site],
        siteIds,
      },
      limit: 10000,
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
    },
    requestPolicy: 'network-only',
  });

  const options = useMemo(() => {
    const results = [];
    const isLocationType = types?.includes(LocationTypeEnum.Location);

    if (isLocationType && siteIds?.length) {
      results.push({
        value: siteIds?.[0],
        label: UNASSIGNED_TEXT,
      });
    }

    data?.locations.edges?.forEach((location) => {
      if (!siteIds?.includes(location.node.id)) {
        results.push(adaptNodeEdgeToOption(location));
      }
    });

    return results;
  }, [data?.locations.edges, siteIds, types]);

  return { isLoading: fetching, options };
};

const useDistinctValueSelectQuery = (
  props: UseSelectQueryProps & SiteQueryVariables,
): UseSelectQueryReturn => {
  const {
    distinctTableName,
    distinctKeys,
    filters,
    siteIds,
    types,
    filterKey = 'transactionFilters',
  } = props;
  const [{ fetching, data }] = useValuesInEntitiesSelectQuery({
    variables: {
      inputs: {
        tableName: distinctTableName || ValuesInEntitiesTableNameEnum.Transactions,
        distinctByKeys: distinctKeys || [],
        filters: {
          [filterKey]: filters,
        },
      },
    },
    requestPolicy: 'network-only',
  });

  const options = useMemo(() => {
    const isLocationType = types?.includes(LocationTypeEnum.Location);
    if (!data?.valuesInEntities?.payload?.length) {
      return [];
    }

    return (
      data.valuesInEntities.payload[0].entities.map((entity) => {
        return {
          entity,
          value: entity.id,
          label: siteIds?.includes(entity.id) && isLocationType ? UNASSIGNED_TEXT : entity.name,
        };
      }) || []
    );
  }, [data?.valuesInEntities.payload, siteIds, types]);
  return { isLoading: fetching, options };
};

const SitesTableFilter = (props: SiteSelectProps) => {
  const { value, onChange, isMultiple = true, isDistinct, ...otherProps } = props;

  if (isMultiple) {
    return (
      <AsyncMultiTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValuesReturn}
        value={value as AsyncMultiSelectProps['value']}
        onChange={onChange as AsyncMultiSelectProps['onChange']}
      />
    );
  } else {
    return (
      <AsyncSingleTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedValues as unknown as (props: any) => UseSelectedValueReturn}
        value={value as AsyncSingleSelectProps['value']}
        onChange={onChange as AsyncSingleSelectProps['onChange']}
      />
    );
  }
};

export default SitesTableFilter;