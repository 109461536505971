import { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  ORDER_REQUEST_CONTENT_KEY,
  OrderDataInStorage,
  OrderRequestContextType,
  OrderRequestProviderProps,
} from './types';
import { ORDER_REQUEST_TYPE } from '../../views/OrderRequest/components/OrderItemRequest/type';
import { OrderRequestSchema } from 'app/types/schema';

export const getDataFromStorage = () => {
  const data = localStorage.getItem(ORDER_REQUEST_CONTENT_KEY);
  return data ? JSON.parse(data) : null;
};

const getOrderRequestType = () => {
  const data = getDataFromStorage();

  if (!data) {
    return null;
  }
  return data?.orderRequestType || null;
};

const getPartnerTenantId = (orderType: ORDER_REQUEST_TYPE): string | null => {
  const data = getDataFromStorage();

  if (!data) {
    return null;
  }
  return data?.orderRequestType === orderType && data?.partnerTenantId
    ? data?.partnerTenantId
    : null;
};

const getOrderItemsFromStore = (orderType: ORDER_REQUEST_TYPE) => {
  const data = getDataFromStorage();

  if (!data) {
    return null;
  }
  return data?.orderRequestType === orderType && data?.orderItems?.length ? data?.orderItems : null;
};

const clearDataFromStorage = () => {
  localStorage.removeItem(ORDER_REQUEST_CONTENT_KEY);
};

const defaultValue: OrderRequestContextType = {
  orderItemLength: undefined,
  storeDataInStorage(data: OrderDataInStorage) {},
  clearDataFromStorage,
  getDataFromStorage,
  getPartnerTenantId,
  getOrderItemsFromStore,
  getOrderRequestType,
  setIsRecreateOrder: memo,
  isRecreateOrder: false,
  orderDetails: null,
  setOrderDetails: () => {}
};

export let OrderRequestContextService = defaultValue;

export const OrderRequestContext = createContext<OrderRequestContextType>(defaultValue);

const OrderRequestContextProvider = ({ children }: OrderRequestProviderProps) => {
  const [orderDetails, setOrderDetails] = useState<OrderRequestSchema|null>(null);
  const [orderItemLength, setOrderItemLength] = useState<number>();
  const [isRecreateOrder, setIsRecreateOrder] = useState<boolean>(false);

  const storeDataInStorage = useCallback(
    (data: OrderDataInStorage) => {
      setOrderItemLength(data?.totalOrderItems);
      localStorage.setItem(ORDER_REQUEST_CONTENT_KEY, JSON.stringify(data));
    },
    [setOrderItemLength],
  );

  const value = useMemo<OrderRequestContextType>(
    () => ({
      orderItemLength: orderItemLength || 0,
      storeDataInStorage,
      getDataFromStorage,
      getOrderRequestType,
      getOrderItemsFromStore,
      clearDataFromStorage,
      getPartnerTenantId,
      setIsRecreateOrder,
      isRecreateOrder,
      orderDetails,
      setOrderDetails
    }),
    [orderItemLength, getDataFromStorage, storeDataInStorage, orderDetails],
  );
  useEffect(() => {
    OrderRequestContextService = value;
    return () => {
      OrderRequestContextService = defaultValue;
    };
  }, [value]);

  return <OrderRequestContext.Provider value={value}>{children}</OrderRequestContext.Provider>;
};

export default OrderRequestContextProvider;
