import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateContactMutationVariables = Types.Exact<{
  input: Types.UpdateContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'UpdateContactPayload', success: boolean, reason?: string | null, contact?: { __typename?: 'ContactSchema', id: string } | null } };


export const UpdateContactDocument = gql`
    mutation updateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    success
    reason
    contact {
      id
    }
  }
}
    `;

export function useUpdateContactMutation() {
  return Urql.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument);
};