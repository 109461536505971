import { Controller } from 'react-hook-form';
import FormDepartmentsSelect from 'app/components/Form/FormDepartmentsSelect';
import Reports from 'app/i18n/Reports';

interface Props {
  control: any;
}

const ReportDepartmentsSelect = (props: Props) => {
  const { control } = props;

  return (
    <Controller
      control={control}
      name="departmentIds"
      render={({ field }) => (
        <FormDepartmentsSelect
          {...field}
          className="flex-1"
          label={Reports.Department}
        />
      )}
    />
  );
};

export default ReportDepartmentsSelect;
