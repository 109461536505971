import { OrderRequestItemSchema, OrderRequestItemTypeEnum } from '../../../../../../types/schema';
import { FOOTER_TOTAL_ROW_ID } from '../../../../components/OrderItems/columns';
import { getTotalOfItems } from '../../utils';
import { UseMultiSelectEntityManagerReturnType } from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import _ from 'lodash';

export function footerRowItem(orderRequestItems: OrderRequestItemSchema[]) {
  return {
    id: FOOTER_TOTAL_ROW_ID,
    cost: getTotalOfItems(orderRequestItems),
  } as any;
}

export function addFooterRowToItems(orderRequestItems: OrderRequestItemSchema[]) {
  const orderRequestRows = [...orderRequestItems];
  if (orderRequestRows.length) {
    orderRequestRows.push(footerRowItem(orderRequestItems));
  }
  return orderRequestRows;
}

export function getPaginatedOrderRequestItems(
  orderRequestItems: OrderRequestItemSchema[],
  tableState: UseMultiSelectEntityManagerReturnType['table']['state'],
) {
  const { numberOfRowsPerPage, activePage } = tableState;
  const page = activePage;
  return orderRequestItems.slice(page * numberOfRowsPerPage, (page + 1) * numberOfRowsPerPage);
}

export function isAssetInventoryTypeItem(type: OrderRequestItemTypeEnum) {
  return [
    OrderRequestItemTypeEnum.Asset,
    OrderRequestItemTypeEnum.AssetKit,
    OrderRequestItemTypeEnum.Inventory,
    OrderRequestItemTypeEnum.InventoryKit,
  ].includes(type);
}

export function groupByOrderItems(orderRequestItems: OrderRequestItemSchema[]) {
  const assetRows: OrderRequestItemSchema[] = [];
  const inventoryRows: OrderRequestItemSchema[] = [];
  const specialOrderRows: OrderRequestItemSchema[] = [];

  _.forEach(orderRequestItems, (orderRequestItem) => {
    switch (orderRequestItem.type) {
      case OrderRequestItemTypeEnum.Asset:
        assetRows.push(orderRequestItem);
        break;
      case OrderRequestItemTypeEnum.AssetKit:
        assetRows.push(orderRequestItem);
        break;
      case OrderRequestItemTypeEnum.Inventory:
        inventoryRows.push(orderRequestItem);
        break;
      case OrderRequestItemTypeEnum.InventoryKit:
        inventoryRows.push(orderRequestItem);
        break;
      case OrderRequestItemTypeEnum.NoSku:
        specialOrderRows.push(orderRequestItem);
        break;
    }
  });

  return {
    assetRows,
    inventoryRows,
    specialOrderRows,
  };
}
