import { Layout } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import DeletedAsset from 'app/modules/assets/views/DeletedAsset';


function DeletedAssetPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;

  return (
    <Layout>
      <DeletedAsset assetId={match.params.id} />
    </Layout>
  );
}

export default withRouter(DeletedAssetPage);
