import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletedUsersTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.UserFilters>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeletedUsersTableQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'UserSchema', id: string, firstName: string, lastName?: string | null, emailId: string, roles: Array<{ __typename?: 'RoleSchema', name: string, id: string, description?: string | null }> } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const DeletedUsersTableDocument = gql`
    query deletedUsersTable($filters: UserFilters, $limit: Float, $sorts: [SortProps!], $after: String, $before: String) {
  users(
    filters: $filters
    limit: $limit
    sorts: $sorts
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        firstName
        lastName
        emailId
        roles {
          name
          id
          description
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useDeletedUsersTableQuery(options?: Omit<Urql.UseQueryArgs<DeletedUsersTableQueryVariables>, 'query'>) {
  return Urql.useQuery<DeletedUsersTableQuery>({ query: DeletedUsersTableDocument, ...options });
};