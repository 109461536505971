import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import Inventory from 'app/i18n/Inventory';
import { INVENTORY_FORM_RULES } from 'app/modules/inventory/components/InventoryForm/utils/utils';

import { InventoryFormProps } from '../../types';

const InventoryTypeField = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { itemId, control, inventoryItemTypes } = formState;

  if (itemId) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="type"
        render={({ field, fieldState }) => (
          <FormSelect
            autoFocus
            {...field}
            isRequired
            disabled={disabled}
            error={fieldState.error}
            label={Inventory.FormLabels.Type}
            options={inventoryItemTypes}
          />
        )}
        rules={INVENTORY_FORM_RULES.type}
      />
    </div>
  );
};

export default InventoryTypeField;
