import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';

import { CREATELIST_FORM_RULES as rules } from '../../../rules';

function StepOne(props: any) {
  const { isLoading, onNext, onCancel, formValues, hasCollaborator, setHasCollaborator } = props;
  const defaultValues = {
    wishlistName: formValues.wishlistName,
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    collaborator: formValues.collaborator,
  };
  const { control, handleSubmit, setValue } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onNext), [handleSubmit, onNext]);

  return (
    <form data-testid="create-list-form">
      <Box className="mx-auto mt-12 min-w-[120px] max-w-[487px] space-y-24">
        <label className="font-semibold text-grey-900">
          Who is this Wishlist being created for?
        </label>
        <div style={{ marginTop: '8px', marginBottom: '18px' }}>
          <Controller
            control={control}
            name="wishlistName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                autoFocus
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={Wishlist.FormLabels.WishlistName}
                formLabel={Wishlist.FormLabels.WishlistName}
              />
            )}
            rules={rules.wishlistName}
          />
        </div>
        <label className="font-semibold text-grey-900">
          Who is the contact person for this Wishlist?
        </label>
        <div style={{ marginTop: '8px', marginBottom: '18px' }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="flex-1"
                error={fieldState.error}
                label={Wishlist.FormLabels.FirstName}
                formLabel={Wishlist.FormLabels.FirstName}
              />
            )}
            rules={rules.firstName}
          />
        </div>
        <Controller
          control={control}
          name="lastName"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Wishlist.FormLabels.LastName}
              formLabel={Wishlist.FormLabels.LastName}
            />
          )}
          rules={rules.lastName}
        />

        <FormCheckBoxInput
          label={Wishlist.FormLabels.CollaboratorCheckbox}
          value={hasCollaborator}
          onChange={(value) => {
            setHasCollaborator(value);
            if (!value) {
              setValue('collaborator', []);
            }
          }}
        />

        {hasCollaborator ? (
          <Controller
            control={control}
            name="collaborator"
            render={({ field, fieldState }) => (
              <FormUsersSelect
                {...field}
                className="flex-1"
                error={fieldState.error}
                label={Wishlist.FormLabels.Collaborator}
                queryVariables={{ excludeCurrentUser: true }}
              />
            )}
          />
        ) : null}

        <Stack>
          <Box className="flex justify-end space-x-[16px]">
            <Button block={true} onClick={onCancel}>
              {Common.Actions.Cancel}
            </Button>
            <Button block={true} loading={isLoading} theme="info" onClick={onFormSubmit}>
              {Common.Actions.Next}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

export default StepOne;
