import '../../views/createList/style.css';
import { useCallback, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, TextField } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Dialog } from '@procurenetworks/procure-component-library';
import { UpdateProductQuantityInWishlistInput, WishlistVendorEnum } from 'app/types/schema';

import MarkAsPurchasedForm from '../../views/createList/components/markAsPurchased';
import { SelectedProducts } from '../../views/createList/types';
import { useUpdateProductQuantityInWishlistMutation } from '../../views/listView/graphql/mutations/generated/updateWishlistProduct';

interface Props {
  item: SelectedProducts;
  index: number;
  removeProductHandler: (index: number) => void;
  itemQuantityUpdate: (id: string, quantity: number) => void;
  itemPurchasedQuantityUpdate: (id: string, quantity: number) => void;
  isEditing: boolean;
  wishlistId: string;
  refetch: () => void;
}

const HorizontalCard = (props: Props) => {
  const {
    item,
    index,
    removeProductHandler,
    itemQuantityUpdate,
    itemPurchasedQuantityUpdate,
    isEditing,
    wishlistId,
    refetch,
  } = props;
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    itemQuantityUpdate(item.hooverProductId, item.quantity + 1);
  };

  const decreaseQuantity = () => {
    if (item.quantity > 1) {
      itemQuantityUpdate(item.hooverProductId, item.quantity - 1);
    }
  };

  const increasePurchasedQuantity = () => {
    itemPurchasedQuantityUpdate(item.hooverProductId, (item.purchasedQuantity || 0) + 1);
  };

  const decreasePurchasedQuantity = () => {
    itemPurchasedQuantityUpdate(
      item.hooverProductId,
      item.purchasedQuantity ? item.purchasedQuantity - 1 : 0,
    );
  };

  const handleQuantityChange = (event: any) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= (item.purchasedQuantity || 0)) {
      itemQuantityUpdate(item.hooverProductId, newQuantity);
    }
  };

  const handlePurchasedQuantityChange = (event: any) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0 && newQuantity <= item.quantity) {
      itemPurchasedQuantityUpdate(item.hooverProductId, newQuantity);
    }
  };

  const [openPopup, setOpenPopup] = useState(false);

  const _handleClosePopup = useCallback(() => {
    setOpenPopup(false);
    setQuantity(1);
  }, [setOpenPopup]);

  const [{ fetching }, executeMarkAsPurchased] = useUpdateProductQuantityInWishlistMutation();

  const markAsPurchased = useCallback(() => {
    const productItemToUpdate: UpdateProductQuantityInWishlistInput = {
      wishlistId,
      products: [
        {
          quantity: item.quantity,
          currentPurchasedQuantity: item.purchasedQuantity || 0,
          hooverProductId: item.hooverProductId.toString(),
          updatedPurchasedQuantity: quantity,
          vendor: item.vendor?.toUpperCase() as WishlistVendorEnum,
        },
      ],
    };

    executeMarkAsPurchased({
      input: productItemToUpdate,
    }).then(() => {
      refetch();
      _handleClosePopup();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, item]);

  const isFullyPurchased = useMemo(() => {
    return Number(item.purchasedQuantity || 0) >= Number(item.quantity || 0);
  }, [item]);

  return (
    <>
      <Grid key={index} container style={{ marginTop: '10px', alignItems: 'center' }}>
        <Grid item md={2} style={{ height: '250px' }} xs={12}>
          <CardMedia
            alt="Product Image"
            component="img"
            image={item.imageUrl}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Grid>
        <Grid item className="product-desc" md={2} xs={12}>
          <Typography gutterBottom variant="body1">
            {item.name || 'Product Name not Available'}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {item.description && item.description.length > 200
              ? `${item.description.slice(0, 200)}...`
              : item.description}
          </Typography>
          <Typography color="textSecondary" sx={{ fontWeight: 'bold' }} variant="body1">
            {item.vendor}
          </Typography>
        </Grid>
        {!wishlistId || (wishlistId && isEditing) ? (
          <Grid item className="pl-[16px]" md={2} xs={12}>
            <span className="heading2 quantity-column ">Quantity: </span>
            <span>
              {!isFullyPurchased && (
                <IconButton disabled={item.quantity === 1} onClick={decreaseQuantity}>
                  <RemoveIcon />
                </IconButton>
              )}
              <TextField
                inputProps={{ min: 0 }}
                size="small"
                sx={{
                  maxHeight: '44px',
                  maxWidth: '44px',
                  '.MuiInputBase-root': {
                    height: '44px !important',
                  },
                }}
                value={item.quantity}
                variant="outlined"
                onChange={handleQuantityChange}
              />
              <IconButton onClick={increaseQuantity}>
                <AddIcon />
              </IconButton>{' '}
            </span>
          </Grid>
        ) : (
          <Grid className="pl-[16px]" md={2} xs={12}>
            <Typography className="!pl-[16px]" color="textSecondary" variant="body1">
              <span className="heading2 quantity-column">Quantity: </span>
              {item.quantity}
            </Typography>
          </Grid>
        )}
        {!wishlistId || (wishlistId && isEditing) ? (
          <Grid md={2} xs={12}>
            <span className="heading2 quantity-column">Purchased Quantity: </span>
            <span>
              <IconButton
                disabled={item.purchasedQuantity === 0}
                onClick={decreasePurchasedQuantity}>
                <RemoveIcon />
              </IconButton>
              <TextField
                size="small"
                style={{ width: '50px' }}
                sx={{
                  maxHeight: '44px',
                  maxWidth: '44px',
                  '.MuiInputBase-root': {
                    height: '44px !important',
                  },
                }}
                value={item.purchasedQuantity || 0}
                variant="outlined"
                onChange={handlePurchasedQuantityChange}
              />
              <IconButton
                disabled={item.purchasedQuantity ? item.purchasedQuantity >= item.quantity : false}
                onClick={increasePurchasedQuantity}>
                <AddIcon />
              </IconButton>
            </span>
          </Grid>
        ) : (
          <Grid md={2} xs={12}>
            <Typography className="pl-[32px]" color="textSecondary" variant="body1">
              <span className="heading2 quantity-column">Purchased Quantity: </span>
              {item.purchasedQuantity || 0}
            </Typography>
          </Grid>
        )}
        <Grid item md={2} style={{ display: 'flex', alignItems: 'center' }} xs={12}>
          <Typography className="pl-[32px] text-[16px] font-medium text-blue-900">
            ${item.price}
          </Typography>
        </Grid>

        {wishlistId && isEditing ? (
          <Grid item className="flex items-start justify-center" md={2} xs={12}>
            <Button
              classes="w-fit pl-[60px] w-[174px]"
              theme={'danger'}
              onClick={() => removeProductHandler(index)}>
              Remove
            </Button>
          </Grid>
        ) : null}

        {wishlistId && !isEditing && (
          <Grid item className='pl-[32px]' md={2} xs={12}>
            <>
              {!isFullyPurchased ? (
                <Button
                  classes="md:!h-auto lg:!h-[44px]"
                  theme="success"
                  onClick={() => {
                    setOpenPopup(true);
                  }}>
                  Mark as Purchased
                </Button>
              ) : (
                'Completed'
              )}

              <Dialog
                disableBackdropClick
                actions={
                  <>
                    <Button onClick={_handleClosePopup}>Cancel</Button>
                    <Button loading={fetching} theme="success" onClick={markAsPurchased}>
                      Mark as Purchased
                    </Button>
                  </>
                }
                id="mark-as-purchased-dialog"
                open={openPopup}
                title="Mark Product as Purchased"
                onClose={_handleClosePopup}>
                <MarkAsPurchasedForm product={item} quantity={quantity} setQuantity={setQuantity} />
              </Dialog>
            </>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HorizontalCard;
