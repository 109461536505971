import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import QRCode from 'qrcode.react';
import FormSelect from 'app/components/Form/FormSelect';
import Label from 'app/i18n/Label';
import Box from 'app/ui-components/Box';

import useLabelsFrom from '../../../context/useLabelsFrom';
import { LabelCustomEnum, LabelTypeEnum } from '../types';
import { getCustomFieldOptions } from '../utils';

interface Props {
  type?: string;
}

const LeftQrCodeLabels = (props: Props) => {
  const { type } = props;
  const { control, values, isCustomFieldValid } = useLabelsFrom();
  const isNotLocation = useMemo(() => {
    return (
      values &&
      ![LabelTypeEnum.Location, LabelTypeEnum.Shipping].includes(values.type as LabelTypeEnum)
    );
  }, [values]);
  const qrCodeSize  = isNotLocation ? 150 : 100;

  return (
    <form>
      <Box className="grid max-w-[414px] grid-flow-col content-around gap-1 rounded-md border  border-solid border-[#c4c4c4] sm:grid-cols-1 md:grid-cols-2">
        <>
          <Box className="m-auto my-10">
            <QRCode fgColor="#000" renderAs={'svg'} size={qrCodeSize} value="Procure" />
          </Box>
          <Box className="mx-10 grid grid-cols-1 gap-1 ">
            <Controller
              control={control}
              name="firstField"
              render={({ field, fieldState }) => (
                <FormSelect
                  {...field}
                  error={fieldState.error}
                  options={getCustomFieldOptions(type, LabelCustomEnum.Custom)}
                  placeholder="Select Field"
                />
              )}
            />
            <Controller
              control={control}
              name="secondField"
              render={({ field, fieldState }) => (
                <FormSelect
                  {...field}
                  error={fieldState.error}
                  options={getCustomFieldOptions(type, LabelCustomEnum.Custom)}
                  placeholder="Select Field"
                />
              )}
            />
            {isNotLocation && (
              <Controller
                control={control}
                name="thirdField"
                render={({ field, fieldState }) => (
                  <FormSelect
                    {...field}
                    error={fieldState.error}
                    options={getCustomFieldOptions(type, LabelCustomEnum.Custom)}
                    placeholder="Select Field"
                  />
                )}
              />
            )}
          </Box>
        </>
      </Box>
      {isCustomFieldValid && (
        <span className="text-[12px] text-[#ec0a0b]">
          {Label.FormValidationMessages.MultipleCustomLabelRequired}
        </span>
      )}
    </form>
  );
};

export default LeftQrCodeLabels;
