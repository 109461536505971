import { DeliveryDetailInput } from '../components/OrderDeliveryDetailForm/types';
import {
  CreateExternalOrderRequestItemInput,
  CreateOrderRequestItemInput,
  OrderRequestItemStatusEnum,
  OrderRequestItemTypeEnum,
  ReturnedOrderRequestItemDetailsInput,
  ReturnOrderRequestItemsInput,
  UpdateExternalOrderRequestFromChildTenantInput,
  UpdateExternalOrderRequestFromParentTenantInput,
  UpdateExternalOrderRequestItemFromChildTenantInput,
  UpdateExternalOrderRequestItemFromParentTenantInput,
  UpdateOrderRequestInput,
  UpdateOrderRequestItemInput,
} from '../../../types/schema';
import _ from 'lodash';
import { OrderDetailsData } from '../views/OrderDetail/type';
import { ItemInStock, OrderRequestItemSchema } from '../views/OrderRequest/type';
import { isAssetInventoryTypeItem } from '../views/OrderDetail/hook/useOrderRequestItems/utils';
import Orders from 'app/i18n/Orders';
import { updatedOrderRequestItems } from '../views/OrderDetail/utils';

function updateOrderRequestItemInput(
  orderRequestItems: OrderRequestItemSchema[],
): UpdateOrderRequestItemInput[] {
  return _.map(orderRequestItems, (orderRequestItem) => {
    const {
      website,
      upcCode,
      status,
      quantity,
      projectId,
      note,
      cost,
      nonRemovableNotes,
      description,
      imageUrl,
      itemId,
      id,
      isEdited,
      type,
      item,
    } = orderRequestItem;

    const isAssetInventoryItem = isAssetInventoryTypeItem(type);

    return {
      orderRequestItemId: id,
      cost: Number(cost),
      description: !isAssetInventoryItem ? description || '' : '',
      imageUrl: !isAssetInventoryItem ? imageUrl : '',
      itemId: isAssetInventoryItem ? itemId : '',
      nonRemovableNotes: nonRemovableNotes,
      note: note || '',
      projectId: projectId || '',
      quantity: Number(quantity),
      status: status,
      upcCode: upcCode || '',
      website: website || '',
      isEdited: Boolean(isEdited),
      categoryId: itemId && item ? item?.categoryId : '',
      type: type,
    };
  });
}

function updateOrderRequestToAddItems(items: OrderRequestItemSchema[]): CreateOrderRequestItemInput[] {
  return items.map(item => ({
    cost: Number(item.cost),
    itemId: item.itemId,
    quantity: Number(item.quantity),
    type: item.type,
    projectId: item.projectId || '',
    note: item.note || '',
  }));
}

function updateOrderRequestItemFromParentTenantInput(
  orderRequestItems: OrderRequestItemSchema[],
): UpdateExternalOrderRequestItemFromParentTenantInput[] {
  return _.map(
    orderRequestItems,
    (orderRequestItem): UpdateExternalOrderRequestItemFromParentTenantInput => {
      const {
        id,
        cost,
        nonRemovableNotes,
        note,
        itemId,
        quantity,
        status,
        projectId,
        type,
        isEdited,
        item,
      } = orderRequestItem;
      /***
       * Note: update External Tenant Order from Parent Tenant,
       * itemId and categoryIdOfItemInParentTenant must be from Parent Tenant.
       * */
      return {
        categoryIdOfItemInParentTenant: item?.categoryId || '',
        itemId: itemId || '',
        orderRequestItemId: id,
        cost: Number(cost),
        nonRemovableNotes: nonRemovableNotes,
        note: note || '',
        projectId: projectId || '',
        quantity: Number(quantity),
        status: status,
        isEdited: Boolean(isEdited),
        type: type,
      };
    },
  );
}

export function updateExternalOrderRequestFromParentTenantInput(
  deliveryDetails: DeliveryDetailInput,
  orderRequestItems: OrderRequestItemSchema[],
  orderDetails: OrderDetailsData,
): UpdateExternalOrderRequestFromParentTenantInput {
  const { billToSiteId, deliverToId, departmentId, destinationSiteId, dueDate, title } =
    deliveryDetails;

  return {
    billToSiteId: billToSiteId,
    deliverToId: deliverToId,
    departmentId: departmentId || '',
    destinationSiteId: destinationSiteId,
    dueDate: orderDetails.dueDate,
    orderRequestId: orderDetails.id,
    title: title || '',
    items: updateOrderRequestItemFromParentTenantInput(orderRequestItems),
  };
}

function updateOrderRequestItemFromChildTenantInput(
  orderRequestItems: OrderRequestItemSchema[],
  itemInStockItems: ItemInStock[],
): UpdateExternalOrderRequestItemFromChildTenantInput[] {
  return _.map(
    orderRequestItems,
    (orderRequestItem): UpdateExternalOrderRequestItemFromChildTenantInput => {
      const {
        id,
        cost,
        nonRemovableNotes,
        note,
        itemId,
        quantity,
        status,
        projectId,
        type,
        isEdited,
        item,
        itemIdInPartnerTenant,
      } = orderRequestItem;

      return {
        categoryIdOfItemInParentTenant: item?.categoryId as string,
        itemIdInPartnerTenant: itemIdInPartnerTenant as string,
        orderRequestItemId: id,
        cost: Number(cost),
        nonRemovableNotes: nonRemovableNotes,
        note: note || '',
        projectId: projectId || '',
        quantity: Number(quantity),
        status: status,
        isEdited: Boolean(isEdited),
        type: type,
      };
    },
  );
}

function updateExternalOrderRequestFromChildTenantToAddItems(items: OrderRequestItemSchema[]): CreateExternalOrderRequestItemInput[] {
  return items.map(item => ({
    cost: Number(item.cost),
    quantity: Number(item.quantity),
    type: item.type,
    itemIdInParentTenant: item.itemIdInPartnerTenant as string,
    categoryIdOfItemInParentTenant: item.item?.categoryId as string,
    note: item?.note,
    projectId: item?.projectId || '',
  }));
}

export function updateChildOrderRequestFromChildTenantInput(
  deliveryDetails: DeliveryDetailInput,
  orderRequestItems: OrderRequestItemSchema[],
  orderDetails: OrderDetailsData,
  itemInStockItems: ItemInStock[],
): UpdateExternalOrderRequestFromChildTenantInput {
  const { billToSiteId, deliverToId, departmentId, destinationSiteId, title } = deliveryDetails;
  const { items, itemsToAdd } = updatedOrderRequestItems(orderRequestItems);

  return {
    billToSiteId: billToSiteId,
    deliverToId: deliverToId,
    departmentId: departmentId || '',
    destinationSiteId: destinationSiteId,
    dueDate: orderDetails.dueDate,
    orderRequestId: orderDetails.id,
    title: title || '',
    items: updateOrderRequestItemFromChildTenantInput(items, itemInStockItems),
    itemsToAdd: updateExternalOrderRequestFromChildTenantToAddItems(itemsToAdd)
  };
}

export function updateOrderRequestInput(
  deliveryDetails: DeliveryDetailInput,
  orderRequestItems: OrderRequestItemSchema[],
  orderDetails: OrderDetailsData,
): UpdateOrderRequestInput {
  const { billToSiteId, deliverToId, departmentId, destinationSiteId, title } = deliveryDetails;

  const { items, itemsToAdd } = updatedOrderRequestItems(orderRequestItems);

  return {
    billToSiteId: billToSiteId,
    deliverToId: deliverToId,
    departmentId: departmentId || '',
    destinationSiteId: destinationSiteId,
    dueDate: orderDetails.dueDate,
    items: updateOrderRequestItemInput(items),
    itemsToAdd: updateOrderRequestToAddItems(itemsToAdd),
    orderRequestId: orderDetails.id,
    title: title || '',
  };
}

function returnOrderRequestItemsInput(
  orderRequestItems: OrderRequestItemSchema[],
  returnOrderRequestItemIds: string[],
): {
  returnedOrderRequestItems: ReturnedOrderRequestItemDetailsInput[];
  isValidReturnOrderRequestItems: boolean;
} {
  const returnedOrderRequestItems: ReturnedOrderRequestItemDetailsInput[] = [];
  let isValidReturnOrderRequestItems: boolean = true;

  for (let i = 0; i < orderRequestItems.length; i++) {
    const orderRequestItem = orderRequestItems[i];
    const { id, quantity } = orderRequestItem;

    if (quantity.toString() === '' || isNaN(quantity)) {
      isValidReturnOrderRequestItems = false;
      break;
    }

    if (returnOrderRequestItemIds.includes(orderRequestItem.id)) {
      const returnOrderRequestItem: ReturnedOrderRequestItemDetailsInput = {
        orderRequestItemId: id,
        checked: true,
        quantity: Number(quantity),
      };
      returnedOrderRequestItems.push(returnOrderRequestItem);
    }
  }

  return {
    returnedOrderRequestItems,
    isValidReturnOrderRequestItems,
  };
}

export function returnOrderRequestInput(
  orderDetails: OrderDetailsData,
  orderRequestItems: OrderRequestItemSchema[],
  returnOrderRequestItemIds: string[],
): {
  input: ReturnOrderRequestItemsInput;
  isValidReturnOrderRequestItems: boolean;
} {
  const { id } = orderDetails;
  const { isValidReturnOrderRequestItems, returnedOrderRequestItems } =
    returnOrderRequestItemsInput(orderRequestItems, returnOrderRequestItemIds);
  return {
    input: {
      orderRequestId: id,
      returnedOrderRequestItems,
    },
    isValidReturnOrderRequestItems,
  };
}

export function isValidUpdateOrderRequestItems(orderRequestItems: OrderRequestItemSchema[]) {
  let isValidReturnOrderRequestItems = true;
  const problems = new Set<string>([]);
  for (let i = 0; i < orderRequestItems.length; i++) {
    const orderRequestItem = orderRequestItems[i];
    const { id, quantity, upcCode, website, imageUrl, description } = orderRequestItem;
    if (quantity.toString() === '' || isNaN(quantity)) {
      isValidReturnOrderRequestItems = false;
      problems.add(Orders.FormValidationMessages.QuantityRequiredAndShouldBeNonZero);
    }

    if (orderRequestItem.type === OrderRequestItemTypeEnum.NoSku) {
      if (!upcCode && !website && !imageUrl && !description) {
        isValidReturnOrderRequestItems = false;
        problems.add(Orders.FormValidationMessages.MissingNonStockedFieldValues);
      }
      if (upcCode && !/^[A-Z0-9]{10,14}$/.test(upcCode)) {
        isValidReturnOrderRequestItems = false;
        problems.add(Orders.FormValidationMessages.UpcCodeInvalid);
      }
    }
  }

  return {
    isValidReturnOrderRequestItems,
    problems: Array.from(problems),
  };
}
