import { useMemo } from 'react';

import { useEditAffiliateMerchantDataQuery } from '../../graphql/queries/generated/editAffiliateMerchantData';

const useEditAffiliateMerchantState = (affiliateConfigurationId: string) => {
  const [{ data, fetching }] = useEditAffiliateMerchantDataQuery({
    variables: {
      filters: {
        affiliateConfigurationIds: [affiliateConfigurationId],
      },
    },
  });

  const affiliateMerchantData = useMemo(() => {
    return data?.affiliateConfigurations.edges?.[0]?.node;
  }, [data?.affiliateConfigurations.edges]);

  const affiliateMerchantFormData = useMemo(() => {
    if (affiliateMerchantData) {
      const { name, logoUrl, affiliateUrl, domain, status, commission } = affiliateMerchantData;

      return {
        name,
        logoUrl,
        affiliateUrl,
        domain,
        status,
        commission: commission.toFixed(2),
      };
    }

    return undefined;
  }, [affiliateMerchantData]);

  return {
    fetching,
    affiliateMerchantData,
    affiliateMerchantFormData,
  };
};

export type UseEditAffiliateMerchantStateReturnType = ReturnType<
  typeof useEditAffiliateMerchantState
>;
export default useEditAffiliateMerchantState;
