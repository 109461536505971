import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TenantSelectQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TenantFilters>;
}>;


export type TenantSelectQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', edges: Array<{ __typename?: 'TenantEdge', node: { __typename?: 'TenantSchema', id: string, name: string } }> } };


export const TenantSelectDocument = gql`
    query tenantSelect($filter: TenantFilters) {
  tenants(
    filters: $filter
    sorts: [{sortField: "name", sortOrder: ASC}]
    limit: 1000
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useTenantSelectQuery(options?: Omit<Urql.UseQueryArgs<TenantSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<TenantSelectQuery>({ query: TenantSelectDocument, ...options });
};