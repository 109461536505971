import SweetAlert from 'app/libs/SweetAlert';

import Box from '../';

export interface AlertBoxProps {
  open: boolean;
  status?: any;
  type: string;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

const AlertBox = ({ open, status, type, onConfirm, onCancel, message }: AlertBoxProps) => {
  return (
    <Box className="leading-tight mb-0">
      {open &&
        (status === 'warning' ? (
          <SweetAlert
            showCancel={type ? true : false}
            title={[
              <span key="title" className={type ? 'modal-title' : 'modal-title body-close-btn'}>
                {type}
              </span>,
              <button
                key="button"
                type="button"
                className="alertbox-close-button close"
                onClick={onCancel}
                aria-hidden="true">
                ×
              </button>,
            ]}
            allowEscape
            confirmBtnText={status === 'warning' ? (type ? 'Yes' : 'OK') : 'No'}
            cancelBtnText={'No'}
            confirmBtnBsStyle={status === false || status === 'warning' ? 'success' : 'danger'}
            onConfirm={status === 'warning' && type ? onConfirm : onCancel}
            confirmBtnCssClass="w-[94px]"
            cancelBtnCssClass="w-[94px]"
            cancelBtnBsStyle="danger"
            onCancel={onCancel}>
            <Box className={type ? 'modal-body' : 'modal-body modal-body-close'}>
              <Box className="bootbox-body">{message}</Box>
            </Box>
          </SweetAlert>
        ) : (
          <SweetAlert
            showCancel={type ? true : false}
            title={[
              <span key="title" className={type ? 'modal-title' : 'modal-title body-close-btn'}>
                {type}
              </span>,
              <button
                key="button"
                type="button"
                className="alertbox-close-button close"
                onClick={onCancel}
                aria-hidden="true">
                ×
              </button>,
            ]}
            allowEscape
            confirmBtnText={status === 'Edit' ? (type ? 'Yes' : 'OK') : 'No'}
            cancelBtnText={'No'}
            confirmBtnBsStyle={status === false || status === 'Edit' ? 'success' : 'danger'}
            onConfirm={status === 'Edit' && type ? onConfirm : onCancel}
            cancelBtnBsStyle="danger"
            onCancel={onCancel}>
            <Box className={type ? 'modal-body' : 'modal-body modal-body-close'}>
              <Box>{message}</Box>
            </Box>
          </SweetAlert>
        ))}
    </Box>
  );
};

export default AlertBox;
