import { OrderDetailsData } from '../../views/OrderDetail/type';
import { createContext, useEffect, useMemo } from 'react';
import { OrderRequestContextService } from '../OrderRequestContextProvider';

interface OrderDetailContextType {
  isChildTenantOrderView: boolean;
  isParentTenantOrderView: boolean;
}

interface Props {
  children: React.ReactNode;
  orderDetails: OrderDetailsData;
}

const defaultValue = {
  isChildTenantOrderView: false,
  isParentTenantOrderView: false,
};

export const OrderDetailContext = createContext<OrderDetailContextType>(defaultValue);

export function isChildTenantOrderView(orderDetails?: OrderDetailsData) {
  return Boolean(orderDetails?.parentTenantId);
}

export const OrderDetailContextProvider = ({ children, orderDetails }: Props) => {
  const value = useMemo((): OrderDetailContextType => {
    return {
      isChildTenantOrderView: isChildTenantOrderView(orderDetails),
      isParentTenantOrderView: Boolean(orderDetails.childTenantId),
    };
  }, [orderDetails.childTenantId, orderDetails.parentTenantId]);

  return <OrderDetailContext.Provider value={value}>{children}</OrderDetailContext.Provider>;
};
