import Import from 'app/i18n/Import';
import { ImportEntityEnum } from 'app/types/schema';

export function getEntityTypeOptions() {
  return [
    { value: ImportEntityEnum.Asset, label: Import.BulkImportOptions.Asset },
    { value: ImportEntityEnum.AssetModel, label: Import.BulkImportOptions.AssetModel },
    { value: ImportEntityEnum.Category, label: Import.BulkImportOptions.Category },
    { value: ImportEntityEnum.Company, label: Import.BulkImportOptions.Company },
    { value: ImportEntityEnum.Department, label: Import.BulkImportOptions.Department },
    { value: ImportEntityEnum.Inventory, label: Import.BulkImportOptions.Inventory },
    { value: ImportEntityEnum.Location, label: Import.BulkImportOptions.Location },
    { value: ImportEntityEnum.Manufacturers, label: Import.BulkImportOptions.Manufacturer },
    { value: ImportEntityEnum.Project, label: Import.BulkImportOptions.Project },
    { value: ImportEntityEnum.Site, label: Import.BulkImportOptions.Site },
    { value: ImportEntityEnum.User, label: Import.BulkImportOptions.User },
  ];
}
