import { useEffect, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import usePagination from 'app/hooks/usePagination';
import User from 'app/i18n/User';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import {
  AllowedPermissionsSubjectEnum, NetworkAssetSchema
} from 'app/types/schema';

import { useDistinctUsersWithNetworkAssetsQuery } from '../../graphql/queries/generated/distinctUsersWithNetworkAssets';
import useSearchSuggestion from './hooks/useSearchSuggestion';
import {
  getNetworkAssetsDetailPanelColumns,
  getNetworkAssetsUserTableColumns,
} from './utils/columns';

const columns = getNetworkAssetsUserTableColumns();

type NetworkAssetDetailPanelPropTypes = { 
  itAssets: NetworkAssetSchema[]
}

const NetworkAssetDetailPanel = (props: NetworkAssetDetailPanelPropTypes) => {
  const rows = useMemo(() => props.itAssets || [], [props.itAssets]);

  const { table } = useEntityManager({
    selection: false,
  });

  return (
    <div className="w-96 ml-[75px] mb-[20px] mt-[16px] max-w-[850px]">
      <EntityManager.Table
        actions={table.actions}
        columns={getNetworkAssetsDetailPanelColumns()}
        data={rows}
        setState={table.setState}
        state={table.state}
        tableBorder="none"
        tableBorderRadius="12px"
      />
    </div>
  );
};

function ITAssetUsers(props: RouteComponentProps) {
  const { search, table } = useMultiSelectEntityManager({ hideActions: true });

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useDistinctUsersWithNetworkAssetsQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        hasItAssets: true
      },
      sorts: table.state.sorts,
    },
    { edgeKey: 'users', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.users.edges?.map((edge) => edge.node) || [];
  }, [data]);

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    const newRows = rows.filter((row: any) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...newRows],
    });
  }, [table.state.selectedRowIds, rows, table.setState]);

  const renderDetailPanelContent = (data: any) => {
    return <NetworkAssetDetailPanel itAssets={data.row.itAssets} />
  };

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  return (
    <EntityManager className="!px-[24px] !pt-0" subject={AllowedPermissionsSubjectEnum.User}>
      <EntityManager.Title documentTitle={User.UserAccounts} title={User.Users} />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.MultiSelectTable
          disableSelection
          ignoreRelayPagination
          actions={table.actions}
          columns={columns}
          data={rows}
          filterNodes={
            <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
              <EntityManager.Search
                isDropdown
                removedSearchQoutedValue
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                {...search}
                autoFocus
                placeholder="Search"
                startAdornment={
                  <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
            </Box>
          }
          getDetailPanelContent={renderDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          getRowId={(row: any) => row.emailId}
          loading={fetching}
          pagination={true}
          paginationWrapperClass="p-[24px] flex-wrap md:gap-[24px]"
          persistKey="distinct_users_table"
          setState={table.setState}
          state={table.state}
          total={data?.users.totalCount}
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          onReset={onReset}
        />
      </Box>
    </EntityManager>
  );
}

export default withRouter(ITAssetUsers);
