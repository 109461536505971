import { useCallback, useState } from 'react';
import { ORDER_STEP } from 'app/modules/orders/components/OrderSteps/utils';

import useOrderDeliveryDetail from '../../views/OrderRequest/components/OrderDeliveryDetails/hook/useOrderDeliveryDetail';
import useItemRequestState from '../../views/OrderRequest/components/OrderItemRequest/hook/useItemRequestState';
import { ORDER_REQUEST_TYPE } from '../../views/OrderRequest/components/OrderItemRequest/type';
import { OrderRequestInput } from '../../views/OrderRequest/components/OrderRequestForm/type';

interface State {
  activeOrderStep: ORDER_STEP;
}

const useOrderRequestState = () => {
  const [state, _setState] = useState<State>({
    activeOrderStep: ORDER_STEP.ORDER_REQUEST,
  });

  const { orderItemRequest } = useItemRequestState();
  const { deliveryDetailState, deliveryDetailsAction } = useOrderDeliveryDetail();

  const setState = useCallback(
    (nextState: Partial<State>) => {
      if (nextState.activeOrderStep !== ORDER_STEP.ORDER_REQUEST) {
        orderItemRequest?.actions?.normalOrderActions?.onResetForm();
      }

      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState, orderItemRequest?.actions?.normalOrderActions?.onResetForm],
  );

  const onNextStep = useCallback(() => {
    setState({
      activeOrderStep: ORDER_STEP.DELIVERY_DETAILS,
    });
  }, [setState]);

  const onPrevStep = useCallback(() => {
    setState({
      activeOrderStep: ORDER_STEP.ORDER_REQUEST,
    });
  }, [setState]);

  const onResetOrderRequest = useCallback(() => {
    orderItemRequest.actions.bulkOrderActions.onResetForm();
    orderItemRequest.actions.bulkOrderActions.clearOrderItems();
    orderItemRequest.actions.normalOrderActions.clearOrderItems();
    orderItemRequest.actions.normalOrderActions.onResetForm();
    orderItemRequest.state.normalOrderState.formState.setValue('partnerTenantId', '');
    deliveryDetailsAction.onResetForm();
    orderItemRequest.state.setState({ orderRequestType: ORDER_REQUEST_TYPE.NORMAL_ORDER });
    onPrevStep();
  }, [
    onPrevStep,
    deliveryDetailsAction,
    orderItemRequest.actions.bulkOrderActions,
    orderItemRequest.actions.normalOrderActions,
    orderItemRequest.state,
  ]);

  const getOrderRequestDtoData = useCallback((): {
    orderRequestItems: OrderRequestInput[];
    partnerTenantId: string;
    isExternalOrder: boolean;
  } => {
    const { orderRequestType, bulkOrderState, normalOrderState } = orderItemRequest.state;
    switch (orderRequestType) {
      case ORDER_REQUEST_TYPE.BULK_ORDER:
        return {
          orderRequestItems: [...bulkOrderState.orderItems].splice(
            0,
            bulkOrderState.orderItems.length - 1,
          ),
          partnerTenantId: bulkOrderState.partnerTenantId,
          isExternalOrder: bulkOrderState.isExternalTenant,
        };
      case ORDER_REQUEST_TYPE.NORMAL_ORDER:
        return {
          orderRequestItems: normalOrderState.orderItems,
          partnerTenantId: normalOrderState.formState.partnerTenantId,
          isExternalOrder: normalOrderState.formState.isExternalTenant,
        };
      default:
        return {
          orderRequestItems: [],
          partnerTenantId: '',
          isExternalOrder: false,
        };
    }
  }, [orderItemRequest.state]);

  return {
    state,
    setState,
    orderItemRequest,
    onNextStep,
    onPrevStep,
    deliveryDetailState,
    deliveryDetailsAction,
    onResetOrderRequest,
    getOrderRequestDtoData,
  };
};

export type UseOrderRequestStateReturnType = ReturnType<typeof useOrderRequestState>;

export default useOrderRequestState;
