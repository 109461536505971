import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  textValidator
} from 'app/components/Form/utils/validators';

import Inventory from '../../../../../i18n/Inventory';
import { Maybe } from '../../../../../types/schema';
import { itemInStockValidator } from '../../../utils/rules';
import Assets from 'app/i18n/Assets';

export const availableQuantityAndTotalQuantityValidator = ({
  availableQuantity,
  totalQuantity,
}: {
  availableQuantity?: any;
  totalQuantity?: any;
}) => {

  if (availableQuantity !== undefined) {
    const qty = availableQuantity as number;
    const totalQty = totalQuantity as number;
    if (totalQty > 0 && qty === 0) {
      return {
        validate: composeValidators(
          textValidator(Assets.FormValidationMessages.QuantityRequired),
          numberMinValidator(Inventory.FormValidationMessages.MoveAssetInventoryQuantity, 0),
          numberMaxValidator(Inventory.FormValidationMessages.PendingAssetCheckOut, 0),
        ),
      };
    } else if (qty === 0) {
      return {
        validate: composeValidators(
          textValidator(Assets.FormValidationMessages.QuantityRequired),
          numberMinValidator(Inventory.FormValidationMessages.MoveAssetInventoryQuantity, 0),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable, 0),
        ),
      };
    } else {
      return {
        validate: composeValidators(
          textValidator(Assets.FormValidationMessages.QuantityRequired),
          numberMinValidator(Inventory.FormValidationMessages.MoveAssetInventoryQuantity, 0),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityMaxiMumRequired(qty), qty),
        ),
      };
    }
  }

  return {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberMinValidator(Inventory.FormValidationMessages.MoveAssetInventoryQuantity, 0)
    )
  };
};

export const MOVE_FORM_RULES = {
  itemInStockSearch: {
    validate: textValidator(Inventory.FormValidationMessages.ItemInStockSearchRequired),
  },
  itemInStock: itemInStockValidator,
  sourceId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteRequired),
  },
  destinationId: {
    validate: textValidator(Inventory.FormValidationMessages.ToSiteRequired),
  },
  sourceLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteLocationRequired),
  },
  destinationLocationId: (isSourceAndDestinationSameLocation: boolean) => {
    return {
      validate: composeValidators(
        textValidator(Inventory.FormValidationMessages.SiteLocationRequired),
        (value?: Maybe<string>) => {
          if (value && isSourceAndDestinationSameLocation) {
            return Inventory.FormValidationMessages.SameSiteLocationInvalid;
          }
          return true;
        },
      ),
    };
  },
  quantity: availableQuantityAndTotalQuantityValidator,
};
