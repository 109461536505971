import React, { forwardRef, HTMLInputTypeAttribute, useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import {
  Autocomplete,
  SelectOptionProps,
  TextField,
} from '@procurenetworks/procure-component-library';
import clsx from 'clsx';

import { Maybe } from '../../../types/schema';
import FormLabel from '../FormLabel';
export interface FormSelectInputProps {
  className?: string;
  label?: string;
  labelNode?: React.FC;
  isRequired?: boolean;
  name?: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  error?: Partial<FieldError> | undefined;
  placeholder?: string;
  labelText?: string;
  valueText?: string;
  items?: any[];
  size?: 'small' | 'medium';
  disabled?: boolean;
  disableClearable?: boolean;
  dropDownOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  formLabel?: string;
  optionPrefix?: string;
  controllerHeight?: string;
  paginated?: boolean;
  onScroll?: (event: any) => void;
  inputControlPadding?: string;
}

const FormSelectInput = forwardRef<HTMLDivElement, FormSelectInputProps>(
  (props: FormSelectInputProps, ref) => {
    const {
      value,
      onBlur,
      items,
      labelText,
      valueText,
      disableClearable,
      label,
      onChange,
      error,
      className,
      isRequired,
      dropDownOpen,
      onOpen,
      onClose,
      size,
      disabled,
      formLabel,
      placeholder,
      optionPrefix,
      controllerHeight = '48px',
      paginated = false,
      onScroll,
      inputControlPadding,
    } = props;

    const options: SelectOptionProps[] = useMemo(
      () =>
        (items || []).map((item) => {
          return {
            label: item[labelText || 'label'],
            value: item[valueText || 'value'],
          } as SelectOptionProps;
        }),
      [items, labelText, valueText],
    );

    const onInputChange = useCallback(
      (_event: React.SyntheticEvent, option: any) => {
        onChange?.(option?.value);
      },
      [onChange],
    );

    const passedValue = useMemo(
      () => options.find((option) => option.value === value) || null,
      [options, value],
    );

    const modifyPassedValue = useMemo(() => {
      if (optionPrefix) {
        return {
          ...passedValue,
          label: optionPrefix ? `${optionPrefix}: ${passedValue?.label}` : passedValue?.label,
        };
      } else {
        return passedValue;
      }
    }, [passedValue]);

    return (
      <>
        {formLabel ? (
          <FormLabel className="text-[14px] font-semibold text-grey-800" isRequired={isRequired}>
            {formLabel}
          </FormLabel>
        ) : null}
        <Autocomplete
          ref={ref}
          {...props}
          ListboxProps={
            paginated
              ? {
                  onScroll: onScroll,
                  role: 'list-box',
                }
              : {}
          }
          className={clsx(className || '', 'mt-6')}
          disableClearable={disableClearable}
          disabled={disabled}
          label=""
          open={dropDownOpen}
          options={options}
          renderInput={(parameters) => {
            return (
              <TextField
                {...parameters}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                errorMessage={error ? (error.message as string) : ''}
                labelNode={
                  label
                    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
                    : undefined
                }
                placeholder={placeholder}
                onKeyDown={(e: any) => {
                  e.stopPropagation();
                }}
              />
            );
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            );
          }}
          size={size || 'medium'}
          sx={{
            '& .MuiFormControl-root': {
              '& .MuiOutlinedInput-root': {
                height: `${controllerHeight} !important`,
                '& .MuiAutocomplete-input': {
                  padding: `${inputControlPadding && inputControlPadding} !important`,
                },
              },
            },
          }}
          value={modifyPassedValue}
          onBlur={onBlur}
          onChange={onInputChange}
          onClose={onClose}
          onOpen={onOpen}
        />
      </>
    );
  },
);

export default FormSelectInput;
