import { useSet } from 'react-use';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
} from '@mui/material';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import useEntityPermissions from '../../hooks/useEntityPermissions';
import usePermissionGroups from '../../hooks/usePermissionGroups';
import PermissionGroupHeader from '../PermissionGroupHeader';
import PermissionList from '../PermissionList';
import { accordionDetailsClasses, accordionRootStyles, accordionSummaryClasses } from './styles';

interface Props {
  disabled?: boolean;
  hideNotSelectedItems?: boolean;
  selectedEntityPermissionKeys: string[];
  onChange: (entityPermissionKeys: string[]) => void;
}

function PermissionGroups({
  disabled,
  selectedEntityPermissionKeys,
  onChange,
  hideNotSelectedItems,
}: Props) {
  const [, { has, toggle }] = useSet<string>(new Set());
  const { fetching, entityPermissions } = useEntityPermissions();
  const { groupPermissions, selectedItemsByGroup, onOptionClick, onAddMultiple, onRemoveMultiple } =
    usePermissionGroups({
      entityPermissions,
      selectedEntityPermissionKeys,
      onChange,
      hideNotSelectedItems,
    });

  if (fetching) {
    return (
      <Stack className="py-32" justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (!entityPermissions.length) {
    return null;
  }

  return (
    <Box className="space-y-12">
      {entityPermissions.map((entityPermission) => {
        const { item, filterGroup } = groupPermissions[entityPermission.key];
        const { id, label, description } = item;
        const isExpanded = has(id);

        if (hideNotSelectedItems && selectedItemsByGroup[id].length === 0) {
          return null;
        }

        return (
          <Accordion
            key={id}
            className={accordionRootStyles}
            expanded={isExpanded}
            onChange={() => toggle(id)}>
            <AccordionSummary
              classes={accordionSummaryClasses}
              expandIcon={<ArrowForwardIosIcon className="!h-16 !w-16" />}>
              <PermissionGroupHeader description={description} title={label} />
            </AccordionSummary>
            <AccordionDetails classes={accordionDetailsClasses}>
              <Box className="mb-10">
                <Divider className="w-full !border-[#e5e7eb]" />
              </Box>
              <PermissionList
                disabled={disabled}
                filterGroup={filterGroup}
                hideNotSelectedItems={hideNotSelectedItems}
                selectedItemsOfGroup={selectedItemsByGroup[id]}
                onAddMultiple={onAddMultiple}
                onOptionClick={onOptionClick}
                onRemoveMultiple={onRemoveMultiple}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default PermissionGroups;
