import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DistinctUsersWithNetworkAssetsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.UserFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DistinctUsersWithNetworkAssetsQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', totalCount: number, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null, edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'UserSchema', firstName: string, lastName?: string | null, fullName?: string | null, emailId: string, profilePicture?: string | null, roles: Array<{ __typename?: 'RoleSchema', id: string, name: string }>, itAssets?: Array<{ __typename?: 'NetworkAssetSchema', id: string, mac?: string | null, userName?: string | null, ipAddress?: string | null, type?: Types.NetworkAssetTypeEnum | null, createdById?: string | null, updatedById?: string | null, tenantId?: string | null, createdAt: string, updatedAt: string, cpu?: { __typename?: 'CpuSchema', manufacturer?: string | null, brand?: string | null, model?: string | null } | null, system?: { __typename?: 'SystemSchema', manufacturer?: string | null, model?: string | null } | null, createdBy: { __typename?: 'UserSchema', id: string, emailId: string, firstName: string, lastName?: string | null, profilePicture?: string | null } }> | null } }> } };


export const DistinctUsersWithNetworkAssetsDocument = gql`
    query distinctUsersWithNetworkAssets($filters: UserFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  users(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    edges {
      node {
        firstName
        lastName
        fullName
        emailId
        profilePicture
        roles {
          id
          name
        }
        itAssets {
          id
          mac
          userName
          ipAddress
          type
          cpu {
            manufacturer
            brand
            model
          }
          system {
            manufacturer
            model
          }
          createdBy {
            id
            emailId
            firstName
            lastName
            profilePicture
          }
          createdById
          updatedById
          tenantId
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

export function useDistinctUsersWithNetworkAssetsQuery(options?: Omit<Urql.UseQueryArgs<DistinctUsersWithNetworkAssetsQueryVariables>, 'query'>) {
  return Urql.useQuery<DistinctUsersWithNetworkAssetsQuery>({ query: DistinctUsersWithNetworkAssetsDocument, ...options });
};