import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WishlistsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WishlistFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type WishlistsQuery = { __typename?: 'Query', wishlists: { __typename?: 'WishlistConnection', totalCount: number, edges?: Array<{ __typename?: 'WishlistEdge', node: { __typename?: 'WishlistSchema', id: string, shareId: string, name: string, firstName?: string | null, lastName?: string | null, createdById: string, description?: string | null, formattedDescription?: string | null, createdAt: string, updatedAt: string, eventDate: string, products: Array<{ __typename?: 'WishlistProductSchema', hooverProductId: string, quantity: number, currentPurchasedQuantity: number, vendor: Types.WishlistVendorEnum }>, shippingAddress: { __typename?: 'WishlistShippingAddressSchema', address: string, city: string, state: string, zipCode: string }, collaborators: Array<{ __typename?: 'WishlistCollaboratorSchema', userId: string }> } }> | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const WishlistsDocument = gql`
    query wishlists($filters: WishlistFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  wishlists(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        shareId
        name
        firstName
        lastName
        products {
          hooverProductId
          quantity
          currentPurchasedQuantity
          vendor
        }
        shippingAddress {
          address
          city
          state
          zipCode
        }
        collaborators {
          userId
        }
        createdById
        description
        formattedDescription
        createdAt
        updatedAt
        eventDate
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    totalCount
  }
}
    `;

export function useWishlistsQuery(options?: Omit<Urql.UseQueryArgs<WishlistsQueryVariables>, 'query'>) {
  return Urql.useQuery<WishlistsQuery>({ query: WishlistsDocument, ...options });
};