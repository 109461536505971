import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import NetworkAssetInsights from 'app/modules/network/view/networkAssetInsights';

import NetworkLayout from '../../modules/network/layout';

function NetworkAssetsInsights() {
  return (
    <NetworkAssetLayout>
      <NetworkAssetInsights />
    </NetworkAssetLayout>
  );
}

export default NetworkAssetsInsights;
