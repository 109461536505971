const Ship = {
  ShipmentSearch: 'Shipment Search',
  ShipmentSearchTitle: 'Shipment Search - Procure',
  ContainerSearch: 'Container Search',
  ContainerTitle: 'Container Search - Procure',
  FormLabels: {
    Quantity: 'Quantity',
    ShipTo: 'Ship To',
    Department: 'Department',
    OrderId: 'Order ID',
    DeliverTo: 'Deliver To',
    BillTo: 'Bill To',
    Status: 'Status',
    Image: 'Image',
    Requester: 'Requester'
  },
  FormPlaceholders: {
    Status: 'Status',
  },
  SuccessMessages: {
    MarkDelivered: 'The selected order delivered successfully.',
    UnPackContainerSuccessMessage:
      'The selected container unpacked successfully and items returned to storage locations.',
    UnPackOrderSuccessMessage:
      'The selected order unpacked successfully and items returned to storage locations.',
  },
};

export default Ship;
