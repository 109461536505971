import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssetCostReportQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ItemFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AssetCostReportQuery = { __typename?: 'Query', items: { __typename?: 'ItemConnection', totalCount: number, edges: Array<{ __typename?: 'ItemEdge', node: { __typename?: 'AssetItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, mName: string, modelNumber?: string | null, totalQuantity?: number | null, cost?: number | null, unitCost?: number | null, replacementDate?: string | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, status: Types.ItemStatusEnum, type: Types.ItemTypeEnum, title: string, totalQuantity?: number | null, cost?: number | null, unitCost?: number | null, category?: { __typename?: 'CategorySchema', id: string, name: string, categoryCode?: string | null } | null } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema' } | { __typename?: 'InventoryKitItemSchema' } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const AssetCostReportDocument = gql`
    query assetCostReport($filters: ItemFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  items(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ... on AssetItemSchema {
          id
          sku
          status
          type
          title
          category {
            id
            name
            categoryCode
          }
          mName
          modelNumber
          totalQuantity
          cost
          unitCost
          replacementDate
        }
        ... on AssetKitItemSchema {
          id
          sku
          status
          type
          title
          category {
            id
            name
            categoryCode
          }
          totalQuantity
          cost
          unitCost
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    `;

export function useAssetCostReportQuery(options?: Omit<Urql.UseQueryArgs<AssetCostReportQueryVariables>, 'query'>) {
  return Urql.useQuery<AssetCostReportQuery>({ query: AssetCostReportDocument, ...options });
};