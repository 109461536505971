import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInventoryItemsMutationVariables = Types.Exact<{
  input: Types.UpdateInventoryItemsInput;
}>;


export type UpdateInventoryItemsMutation = { __typename?: 'Mutation', updateInventoryItems: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const UpdateInventoryItemsDocument = gql`
    mutation updateInventoryItems($input: UpdateInventoryItemsInput!) {
  updateInventoryItems(input: $input) {
    success
    reason
  }
}
    `;

export function useUpdateInventoryItemsMutation() {
  return Urql.useMutation<UpdateInventoryItemsMutation, UpdateInventoryItemsMutationVariables>(UpdateInventoryItemsDocument);
};