import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import AssetsLayout from '../../modules/assets/layout';
import EditAsset from '../../modules/assets/views/EditAsset';

function EditAssetPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <AssetsLayout>
      <EditAsset assetId={match.params.id} />
    </AssetsLayout>
  );
}

export default withRouter(EditAssetPage);
