import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { CompanyDetailsFragmentDoc } from '../../fragments/generated/company';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompanyDetailsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.CompanyFilters>;
}>;


export type CompanyDetailsQuery = { __typename?: 'Query', companies: { __typename?: 'CompanyConnection', totalCount: number, edges: Array<{ __typename?: 'CompanyEdge', node: { __typename?: 'CompanySchema', id: string, companyEmail?: string | null, companyFax?: string | null, companyName: string, companyWebsite?: string | null, isVendor?: boolean | null, companyContactNumber?: string | null } }> } };


export const CompanyDetailsDocument = gql`
    query CompanyDetails($filters: CompanyFilters) {
  companies(filters: $filters) {
    edges {
      node {
        ...CompanyDetails
        id
      }
    }
    totalCount
  }
}
    ${CompanyDetailsFragmentDoc}`;

export function useCompanyDetailsQuery(options?: Omit<Urql.UseQueryArgs<CompanyDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyDetailsQuery>({ query: CompanyDetailsDocument, ...options });
};