import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateDepartmentMutationVariables = Types.Exact<{
  input: Types.CreateDepartmentInput;
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment: { __typename?: 'CreateDepartmentPayload', success: boolean, reason?: string | null, department?: { __typename?: 'DepartmentSchema', id: string } | null } };


export const CreateDepartmentDocument = gql`
    mutation createDepartment($input: CreateDepartmentInput!) {
  createDepartment(input: $input) {
    success
    reason
    department {
      id
    }
  }
}
    `;

export function useCreateDepartmentMutation() {
  return Urql.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument);
};