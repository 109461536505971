import React, { useCallback, useState } from 'react';
import { cx } from '@emotion/css';
import { Card } from '@procurenetworks/procure-component-library';
import { CardWrapper, DescriptionStyle, Item } from 'app/modules/shop/utils/styles';
import Box from 'app/ui-components';

import { Offer, Product } from '../../../../hook/useHooverShopProducts';
import VendorModal from '../../../ItemModal/VendorModal';
import { Grid } from '@mui/material';

interface Props {
  data?: Product[];
}

const renderImages = (nodes: any[]) => {
  let imgArray: any[] = [];
  nodes.forEach(({ images }) => {
    if (images) {
      imgArray = imgArray.concat(images.nodes);
    }
  });
  if (imgArray.length > 0) {
    return imgArray[0].original;
  }
  return '';
};

const renderVendorImage = (nodes: any[]) => {
  if (nodes?.length) {
    return nodes;
  }
};

const ProductsMerchants: React.FC<Props> = ({ data }) => {
  const [openSingleVendorModal, setSingleVendorModal] = useState(false);
  const [vendorData, setVendorData] = useState<Offer[]>();
  const [, setImagePath] = useState<string | null>('');

  const openVendorModal = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    modalData: Offer[],
  ) => {
    event.stopPropagation();
    setSingleVendorModal(true);
    setVendorData(modalData);
  };

  const renderLogo = (merchant: any) => {
    // const merchantData =
    //   merchant &&
    //   affiliate.length &&
    //   affiliate.filter(
    //     (item) => item.merchant === merchant.merchantId || item.merchant === merchant.name,
    //   );

    // return merchantData[0]?.image ? (
    //   <img alt="vendorImage" className="vendorImage" src={merchantData[0]?.image} />
    // ) : (
    //   <div className="VendorName">{merchant?.name}</div>
    // );
    return <Box className={'text-[12px] text-grey-800'}>{merchant?.name}</Box>;
  };

  const handleShowDialog = (image: string) => {
    if (image?.length) {
      setImagePath(image);
    }
  };

  const _handleRedirectShop = useCallback((url: string, event: any) => {
    event.stopPropagation();
    const winObject = window?.open(url, '_blank');
    if (winObject) winObject.focus();
  }, []);

  const filteredData = data?.filter((item) => {
    item.offers.nodes = item.offers.nodes.filter((node) => {
      return node.merchant?.name;
    });
    return item.offers.nodes.length;
  });

  return (
    <>
      {/* <Box className={' flex flex-wrap'}> */}
      <Grid container spacing={3}>
        {filteredData &&
          filteredData.length > 0 &&
          filteredData.map((item: Product, i: number) => (
            // <CardWrapper key={i}>
            //
            <Grid item xs={12} xl={3} lg={3} md={4}>
              <Item>
                <Card
                  withImage
                  cardActions={
                    item.offers.nodes?.length > 1 ? (
                      <button
                        className={
                          'm-auto mb-[6px] h-[34px] rounded-[4px] bg-[#66ccff] py-[1px] px-[6px] text-[14px] font-bold text-[#fff]'
                        }
                        type="button"
                        onClick={(event) => openVendorModal(event, item.offers.nodes)}>
                        View Vendors
                      </button>
                    ) : (
                      <Box className={'w-full p-[8px]'}>
                        <span
                          onClick={(event) =>
                            _handleRedirectShop(item?.offers?.nodes[0]?.clickURL || '', event)
                          }>
                          {renderLogo(item.offers.nodes[0]?.merchant)}
                        </span>
                      </Box>
                    )
                  }
                  data={item}
                  descriptionNode={
                    <Box
                      className={cx(
                        DescriptionStyle,
                        'cursor-pointer p-[4px] text-[14px] font-medium text-grey-900',
                      )}
                      onClick={(event) => openVendorModal(event, item.offers.nodes)}>
                      {item.name}
                    </Box>
                  }
                  // imageHeight={200}
                  imagePath={renderImages(item.offers.nodes)}
                  maxTitleLine={1}
                  target="_blank"
                  title=""
                  onCardClick={() => handleShowDialog(renderImages(item.offers.nodes))}
                />
              </Item>
            </Grid>

            // </CardWrapper>
          ))}
        <VendorModal
          _handleRedirectShop={_handleRedirectShop}
          data={vendorData}
          handleShowDialog={handleShowDialog}
          openSingleVendorModal={openSingleVendorModal}
          renderLogo={renderLogo}
          renderVendorImage={renderVendorImage}
          setSingleVendorModal={(event: boolean) => setSingleVendorModal(event)}
        />
      </Grid>
      {/* </Box> */}
    </>
  );
};

export default ProductsMerchants;
