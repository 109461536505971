import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Label } from 'reactstrap';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Profile from 'app/i18n/Profile';
import Box from 'app/ui-components/Box';

import { EmailUpdateProps } from '../ProfileForm/types';
import { PROFILE_FORM_RULES as Rules } from '../ProfileForm/utils';

function EmailUpdate(props: EmailUpdateProps) {
  const { defaultValues, disabled, loading, onSubmit } = props;
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [defaultValues]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Label className="font-bold">Change Email Address</Label>
        <Controller
          control={control}
          name="emailId"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Profile.FormLabels.Email}
            />
          )}
          rules={Rules.email}
        />
        <Box className="flex justify-end">
          <Button
            classes="!px-12 w-[134px]"
            disabled={disabled}
            loading={loading}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.UpdateEmail}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default EmailUpdate;
