import React from 'react';

import { ManufacturerSchema } from '../../../../types/schema';
import { isManufacturerActive } from '../../utils/utils';

interface Props {
  manufacturer?: Pick<ManufacturerSchema, 'name' | 'status'>;
}

const ManufactureTableNode = (props: Props) => {
  const { manufacturer } = props;
  const manufactureName =
    isManufacturerActive(manufacturer?.status) && manufacturer?.name ? manufacturer?.name : '';
  return (
    <span className="truncate" title={manufactureName}>
      {manufactureName}
    </span>
  );
};

export default ManufactureTableNode;
