interface Props {
  site?: {
    id: string;
    name: string;
  };
}

const SiteTableNode = (props: Props) => {
  const { site } = props;
  if (!site) {
    return null;
  }

  return (
    <div className="truncate" title={site.name}>
      {site.name}
    </div>
  );
};

export default SiteTableNode;
