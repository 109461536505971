import routes from 'app/consts/routes';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';

function LoginRedirect(props: RouteComponentProps) {
  const { history } = props;
  const pathname = history.location.pathname;
  const search = history.location.search;
  const searchParams = new URLSearchParams(search).toString();
  const redirectTo = pathname && pathname !== '/' ? `${pathname}${searchParams ? `?${searchParams}` : ''}` : '';

  return <Redirect to={`${routes.Login()}?redirectTo=${encodeURIComponent(redirectTo)}`} />;
}

export default withRouter(LoginRedirect);
