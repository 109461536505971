import { Control, Controller } from 'react-hook-form';
import FormProjectsSelect from 'app/components/Form/FormProjectsSelect';
import Reports from 'app/i18n/Reports';

interface Props {
  control: any;
}

const ReportProjectsSelect = (props: Props) => {
  const { control } = props;

  return (
    <Controller
      control={control}
      name="projectIds"
      render={({ field }) => (
        <FormProjectsSelect
          {...field}
          className="flex-1"
          label={Reports.Project}
        />
      )}
    />
  );
};

export default ReportProjectsSelect;
