import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { useDeletedUsersTableQuery } from '../../graphql/queries/generated/deletedUsersTable';
import { UserStatusEnum } from 'app/types/schema';



const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>();
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();
  
  const [{ data }] = useDeletedUsersTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        statuses: [UserStatusEnum.Deleted],
      },
    },
  });

  useEffect(() => {
    const result = data?.users?.edges?.map((a) => {
      const item = a.node 
      const queryWords = searchedValue ? searchedValue.trim().toLowerCase().split(" ") : [""];

      let result = null;
      let searchBy = null;

      let fullName = item.firstName + ' ' + item.lastName

      if (queryWords.some(word => fullName.toLowerCase().includes(word))) {
        result = fullName;
      }

      return {
        label: searchBy || fullName,
        id: item.id,
        value: result || fullName
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
