import gql from 'graphql-tag';
import { OperationResult } from 'urql';
import { getWishlistClient } from 'app/libs/urql/getClient';
import { WishlistVendorEnum } from 'app/types/schema';

export type WishlistProductSchema = {
  hooverProductId: string;
  quantity: number;
  currentPurchasedQuantity?: number;
  vendor: WishlistVendorEnum;
};

export enum WishlistStatusEnum {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export type WishlistSchema = {
  name: string;
  description?: string;
  formattedDescription?: string;
  tenantId: string;
  shareId: string;
  products: Array<WishlistProductSchema>;
  createdById: string;
  deletedAt?: Date | string;
  deletedById?: string;
  shippingAddress: string;
  sqlId?: number;
  status: WishlistStatusEnum;
  updatedById: string;
  firstName?: string;
  lastName?: string;
  eventDate: Date;
};

export type QueryWishlistConnection = {
  wishlist: WishlistSchema[];
};

export type FetchWishlistProductResponse = {
  wishlistByShareId: QueryWishlistConnection;
};

export const Products = gql`
  query GetWishlistProducts($shareId: String!) {
    wishlistByShareId(shareId: $shareId) {
      wishlist {
        createdAt
        updatedAt
        name
        description
        formattedDescription
        tenantId
        shareId
        shippingAddress {
          address
          city
          state
          zipCode
        }
        products {
          hooverProductId
          currentPurchasedQuantity
          quantity
          vendor
        }
        createdById
        deletedAt
        deletedById
        sqlId
        status
        updatedById
        eventDate
      }
    }
  }
`;

export function useWishlistProductsQuery() {
  const hooverUrqlClient = getWishlistClient();

  const fetchWishlistProducts = (
    variables: any,
  ): Promise<OperationResult<FetchWishlistProductResponse>> => {
    return hooverUrqlClient
      .query(Products, {
        ...variables,
      })
      .toPromise();
  };

  return {
    fetchWishlistProducts,
  };
}
