import {
  DataGridComponentState,
  DataGridHeadCell,
} from '@procurenetworks/procure-component-library';
import FormValuesInItemsTableFilter from 'app/modules/assetsInventory/components/FormValuesInItemsTableFilter';
import {
  ItemStatusEnum,
  ItemStringBasedColumnFilterFieldEnum,
  ItemTypeEnum,
} from 'app/types/schema';
import { useCallback } from 'react';
import { UseInventoryStateReturnType } from '../../views/Inventory/hook/useInventoryState';
import SyncStateWithUrlParam from 'app/utils/queryParams';

export interface LabelNodeProps {
  headCell: DataGridHeadCell;
  state: DataGridComponentState;
}

const ModelLabelNode = (props: LabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filters, setState } = extraProps as UseInventoryStateReturnType;
  const { mNames } = filters;

  SyncStateWithUrlParam('mNames', mNames, setState)

  const onChange = useCallback(
    (nextValue: string[]) => {
      setState({
        mNames: nextValue,
      });
    },
    [setState],
  );

  return (
    <div className="table-filter">
      <FormValuesInItemsTableFilter
        value={mNames}
        onChange={onChange}
        key="model-label-node"
        name="model-label-node"
        className=" "
        placeholder="Model"
        queryVariables={{
          field: ItemStringBasedColumnFilterFieldEnum.Models,
          filters: {
            types: [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit],
            statuses: [ItemStatusEnum.Active],
          },
        }}
        onClick={(event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </div>
  );
};

export default ModelLabelNode;
