import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { cx } from '@emotion/css';
import FormLabel from 'app/components/Form/FormLabel';
import { SelectOption } from 'app/components/Select/types';
import Reports from 'app/i18n/Reports';
import { labelSort, toTitleCase } from 'app/modules/reports/utils';
import Box from 'app/ui-components';

import MultiSelect from '../MultiSelect';

interface Props {
  control: any;
  options: SelectOption[];
  selectName: string;
  label?: string;
}

interface SelectProps {
  name: string;
  options: SelectOption[];
  className: string;
  onChange: (values: string[], options: SelectOption[]) => void;
  placeholder: string;
}

const FormITAssetSelect = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const { className, name, options, ...rest } = props;
  const sortedOptions = labelSort(options)
  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {name ? <FormLabel>{name}</FormLabel> : null}
      <MultiSelect
        {...rest}
        className="flex-1"
        options={sortedOptions}
        placeholder={Reports.All}
      />
    </Box>
  );
});

const ITAssetReportFilter = (props: Props) => {
  const { control, options, selectName, label } = props;

  return (
    <Controller
      control={control}
      name={selectName}
      render={({ field }) => {
        return (
          <FormITAssetSelect
            {...field}
            className="flex-1"
            name={label || toTitleCase(selectName)}
            options={options}
            placeholder={Reports.All} 
          />
        );
      }}
    />
  );
};

export default ITAssetReportFilter;
