import { useEffect, useMemo, useRef } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import { useAccessControl } from 'app/components/AccessControl';
import Modal from 'app/components/Modal';
import { SnackbarService } from 'app/components/Snackbar';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import { withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  UpdateReserveTransactionEntityInput,
} from 'app/types/schema';
import Box from 'app/ui-components';
import { Divider, Box as MuiBox } from '@mui/material';
import { useUpdateReserveTransactionsMutation } from '../../graphql/mutations/generated/updateReserveTransactions';
import useAssetReservedState, { TransactionActionType } from '../../hook/useAssetsReserved';
import useReserveForm, { UseReserveFormProps } from '../../hook/useReserveForm';
import { getAssetsReservedTableColumns } from '../../utils/columns';
import ReserveForm from '../ReserveForm';
import { removeExtraSpacesAndNewlines } from 'app/utils/removeMultipleSpaces';
import analytics from 'app/analytics';

const RESERVED_ASSETS_CONTAINER = 'assets-reserved';
const AssetsReservedTable = (props: any) => {
  const { history, location } = props;
  const { workspacePermissions } = useCurrentUser();
  const divRef = useRef<HTMLDivElement | null>(null);

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.AssetReserve,
  );
  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.AssetReserve,
  );
  const canCheckOut = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.AssetCheckout,
  );

  const {
    table,
    rows,
    rowCount,
    onPrevPage,
    onNextPage,
    onReset,
    fetching,
    transactionData,
    isUnReservingTransaction,
    handleUnReserveTransaction,
    onCancelTransaction,
    onConfirmTransactionAction,
    filterState,
    setFilterState,
    userOptions,
    siteOptions,
  } = useAssetReservedState({ history });

  useEffect(() => {
    if (location.hash && divRef?.current && !fetching) {
      divRef.current.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' });
    }
  }, [location.hash, fetching]);

  const [{ fetching: isUpdating }, onUpdateReserveTransaction] =
    useUpdateReserveTransactionsMutation();

  const defaultValuesForEditForm: UseReserveFormProps = useMemo((): UseReserveFormProps => {
    const { transaction, actionType } = transactionData;

    if (actionType !== TransactionActionType.EDIT && !transaction) {
      return {};
    }

    const {
      entityId,
      destinationSiteId,
      departmentId,
      actorId,
      startDate,
      endDate,
      meta,
      originalQuantity,
      quantity,
      id,
    } = transaction || {};

    if (
      entityId &&
      destinationSiteId &&
      actorId &&
      startDate &&
      typeof quantity === 'number' &&
      id
    ) {
      return {
        defaultFormValues: {
          itemInStockId: entityId,
          destinationSiteId: destinationSiteId,
          destinationLocationId: destinationSiteId,
          departmentId: departmentId || '',
          actorId: actorId,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
          meta: {
            note: meta?.note || '',
            formattedNote: meta?.formattedNote || '',
          },
          originalQuantity,
          quantity,
          transactionId: id,
        },
      };
    }

    return {};
  }, [transactionData]);

  const { formState } = useReserveForm(defaultValuesForEditForm);
  const columns = useMemo(
    () =>
      getAssetsReservedTableColumns({
        shouldShowActionColumn: canCheckOut || canDelete || canEdit,
      }),
    [],
  );

  const onSubmit = (values: any) => {
    const payload: UpdateReserveTransactionEntityInput = {
      destinationLocationId: values?.destinationLocationId,
      destinationSiteId: values?.destinationSiteId,
      quantity: parseInt(values?.quantity || 0),
      actorId: values?.actorId,
      meta: {
        note: removeExtraSpacesAndNewlines(values?.meta?.note || ''),
        formattedNote: values?.meta?.note,
      },
      departmentId: values?.departmentId,
      startDate: moment(values?.startDate).startOf('day').toISOString(),
      endDate: values?.endDate ? moment(values?.endDate).endOf('day').toISOString() : undefined,
      transactionId: transactionData?.transaction?.id as string,
      entityId: values?.itemInStockId as string,
    };
    onUpdateReserveTransaction({
      input: {
        entities: [payload],
      },
    }).then((response) => {
      analytics?.track('Edited', { name: 'Reserve Transaction' });
      if (response?.data?.updateReserveTransactions?.success) {
        SnackbarService.show({
          message: Assets.SuccessMessages.ReserveTransactionUpdated,
        });
        onCancelTransaction();
      } else {
        console.error('[Failed to Reserve Assets]', response);
      }
    });
  };

  return (
    <Box
      ref={(ref) => (divRef.current = ref)}
      className="scroll-mt-[10px]"
      id={'#' + RESERVED_ASSETS_CONTAINER}>
      <EntityManager>
        <EntityManager.Title title={Assets.AssetsReserved} />

        <MuiBox
          className="mt-[24px] rounded-[12px]"
          sx={{
            boxShadow:
              '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}>
          <EntityManager.Table
            ignoreRelayPagination
            pagination
            actions={table.actions}
            columns={columns}
            data={rows}
            extraProps={{
              onConfirmTransactionAction,
              filterState,
              setFilterState,
              canDelete,
              canCheckOut,
              canEdit,
              userOptions,
              siteOptions,
            }}
            loading={fetching}
            minWidth={0}
            persistKey="assetsReserved_table"
            rowHeight={60}
            setState={table.setState}
            state={table.state}
            testId="assets_reserved_table"
            total={rowCount}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onReset={onReset}
            paginationWrapperClass="p-[24px]"
          />
        </MuiBox>

        <Modal
          disableBackdropClick
          actions={
            <div>
              <Button
                loading={isUnReservingTransaction}
                theme="success"
                onClick={() => {
                  handleUnReserveTransaction();
                }}>
                {transactionData?.transaction ? Common.Actions.Yes : Common.Actions.Ok}
              </Button>
              <Button
                classes="ml-[16px]"
                disabled={isUnReservingTransaction}
                onClick={onCancelTransaction}>
                {Common.Actions.Cancel}
              </Button>
            </div>
          }
          disable={isUnReservingTransaction}
          id="unreserve-transaction"
          open={
            transactionData.open && transactionData.actionType === TransactionActionType.UNRESERVE
          }
          title={Assets.FormLabels.UnreserveDialog}
          onClose={onCancelTransaction}>
          <div className="mt-[16px] min-w-[375px]">
            <span className="text-[16px] font-medium text-grey-800">{transactionData.message}</span>
          </div>
        </Modal>

        <Modal
          actions={
            <div>
              <>
                <Button
                  disabled={isUpdating}
                  loading={isUpdating}
                  theme="success"
                  onClick={formState.handleSubmit(onSubmit)}>
                  {Common.Actions.Save}
                </Button>
                <Button classes="ml-[16px]" disabled={isUpdating} onClick={onCancelTransaction}>
                  {Common.Actions.Cancel}
                </Button>
              </>
            </div>
          }
          disable={isUnReservingTransaction}
          id="reserve-edit-transaction"
          open={transactionData.open && transactionData.actionType === TransactionActionType.EDIT}
          title={Assets.FormLabels.EditAssetReserveDialog}
          onClose={onCancelTransaction}>
          <Box className="min-w-[375px]">
            <ReserveForm isEdit formState={formState} onSubmit={onSubmit} />
          </Box>
        </Modal>
      </EntityManager>
    </Box>
  );
};

export default withRouter(AssetsReservedTable);
