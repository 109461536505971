import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import routes from 'app/consts/routes';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { DeviceRowType } from 'app/modules/network/types';
import { NetworkAssetStatusEnum } from 'app/types/schema';

import { UseNetworkAssetsStateReturnType } from '../../hook/useNetworkAssetsState';
import NetworkAssetGridView from '../NetworkAssetGridView';
import NetworkAssetListView from '../NetworkAssetListView';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  filters: UseNetworkAssetsStateReturnType['filters'];
  tableState: UseNetworkAssetsStateReturnType['networkAssetTableState'];
  history?: any;
  clickable: boolean;
  discovered?: boolean;
  display?: string;
  disableSelection?: boolean;
  persistKey: string;
  filterNodes?: ReactNode | ReactElement
}

const NetworkAssetTable = (props: Props) => {
  const {
    table,
    filters,
    tableState,
    history,
    clickable,
    discovered,
    display,
    disableSelection,
    persistKey,
    filterNodes
  } = props;

  const [devicesRow, setDevicesRow] = useState<DeviceRowType[]>([]);

  useEffect(() => {
    const devices = tableState.rows.map((row) => {
      return {
        id: row.id,
        user: row.userName || '',
        osPlatform: row.operatingSystem?.platform || '',
        osDistro: row.operatingSystem?.distro || '',
        batteryManufacturer: row.battery ? row.battery?.manufacturer || 'Not Available' : '',
        cpuBrand: row.cpu?.brand || '',
        cpuManufacturer: row.cpu?.manufacturer || '',
        mac: row.mac || '',
        ip: row.ipAddress || '',
        updatedAt: row.updatedAt,
        createdAt: row.createdAt,
        type: row.type || '',
        status: row.status,
      };
    });
    setDevicesRow(devices);
  }, [tableState]);

  function handleRowClick(params: any) {
    const { row } = params;
    if (row.status === NetworkAssetStatusEnum.Active && row.mac && clickable) {
      history.push(routes.NetworkAssetDetail(row.id));
    }
  }

  return (
    <>
      {display === 'grid' && clickable ? (
        <NetworkAssetGridView
          clickable={clickable}
          devicesRow={devicesRow}
          discovered={discovered}
          display={display}
          filters={filters}
          handleItemClick={handleRowClick}
          history={history}
          table={table}
          tableState={tableState}
          filterNodes={filterNodes}
        />
      ) : (
        <NetworkAssetListView
          persistKey={persistKey}
          clickable={clickable}
          devicesRow={devicesRow}
          disableSelection={disableSelection}
          discovered={discovered}
          filters={filters}
          history={history}
          table={table}
          tableState={tableState}
          filterNodes={filterNodes}
        />
      )}
    </>
  );
};

export default NetworkAssetTable;
