import useUppy, { AttachmentType, UploadTypeEnum } from '../../../../../uppy/useUppy';

const useFormFileUploader = (
  id?: string,
  defaultAttachments?: AttachmentType[],
  uploadType?: UploadTypeEnum,
) => {
  const {
    files,
    uppy,
    clearAll,
    isUploading,
    removeFileById,
    attachments,
    setAttachments,
    addFileFromUrl,
    getFileIdsFromUrls,
    clearAttachments,
  } = useUppy({
    purpose: 'Image Upload',
    id,
    defaultAttachments: defaultAttachments,
    uploadType,
  });

  return {
    inputProps: {
      uppy,
      files,
      isUploading,
      removeFileById,
      attachments,
      getFileIdsFromUrls,
      setAttachments,
      addFileFromUrl,
      clearAttachments,
    },
    attachments,
    setAttachments,
    clearAll,
    removeFileById,
    addFileFromUrl,
    clearAttachments,
  };
};

export type UseFormFileUploaderReturnType = ReturnType<typeof useFormFileUploader>;

export default useFormFileUploader;
