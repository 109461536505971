import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditUserQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type EditUserQuery = { __typename?: 'Query', user: { __typename?: 'UserSchema', id: string, firstName: string, lastName?: string | null, status: Types.UserStatusEnum, profilePicture?: string | null, emailId: string, tenantId: string, defaultSiteId?: string | null, scopedRoles: Array<{ __typename?: 'ScopedRole', roleId: string, scopeEntity: Types.AllowedScopeEntityEnum, scopeGroupIds?: Array<string> | null }> } };


export const EditUserDocument = gql`
    query editUser($userId: String!) {
  user(id: $userId) {
    id
    firstName
    lastName
    status
    profilePicture
    emailId
    tenantId
    defaultSiteId
    scopedRoles {
      roleId
      scopeEntity
      scopeGroupIds
    }
  }
}
    `;

export function useEditUserQuery(options: Omit<Urql.UseQueryArgs<EditUserQueryVariables>, 'query'>) {
  return Urql.useQuery<EditUserQuery>({ query: EditUserDocument, ...options });
};