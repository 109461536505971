import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import AssetModel from 'app/i18n/AssetModel';
import { upcCodeOrAsinCodeValidator } from 'app/modules/assetsInventory/utils/rules';
import { ButtonSize } from 'app/types/button';
import { Maybe } from 'app/types/schema';

import { AssetModelFieldProps } from '../../type';

const AssetModelUpcCodeField = (props: AssetModelFieldProps) => {
  const { disabled, formState, className = '!mt-0' } = props;
  const { control, noUpcCode, onNoUpcCodeChange, onFindUpcClick } = formState;

  const upcCodeValidator = useCallback(
    (value: Maybe<string>) => {
      if (!noUpcCode) {
        return upcCodeOrAsinCodeValidator(value);
      }
      return undefined;
    },
    [noUpcCode],
  );

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="upcCode"
        render={({ field, fieldState }) => (
          <FormTextInput
            {...field}
            containerClassName={className}
            endEndorsement={
              <Button
                disabled={noUpcCode || disabled}
                size={ButtonSize.medium}
                theme="success"
                onClick={onFindUpcClick}
                classes="min-w-[95px] h-[48px]">
                {AssetModel.FormLabels.FindUpc}
              </Button>
            }
            error={fieldState.error}
            isDisabled={noUpcCode || disabled}
            isRequired={!noUpcCode}
            label={AssetModel.FormLabels.UpcCode}
            onChange={(value: string) => {
              const inputValue = value.toString();
              if (inputValue.length <= 14) {
                field.onChange(value);
              }
            }}
          />
        )}
        rules={{
          validate: upcCodeValidator,
        }}
      />
      <div className="mb-20 mt-[16px]">
        <Controller
          control={control}
          name="noUpcCode"
          render={({ field, fieldState }) => (
            <FormCheckBoxInput
              {...field}
              classNames="!p-0 mr-[16px]"
              disabled={disabled}
              label={AssetModel.FormLabels.NoUpcCode}
              onChange={(value) => {
                field?.onChange(value);
                onNoUpcCodeChange(value);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AssetModelUpcCodeField;
