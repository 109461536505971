import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type OrganizationTableRowFragment = { __typename?: 'TenantSchema', id: string, logo?: string | null, name: string, tenantCode: string, primaryPhoneNumber?: string | null, status: Types.TenantStatusEnum, locationDetails: { __typename?: 'TenantLocationDetailsType', address: string, city: string, state: string, zipCode: string }, admin?: { __typename?: 'UserSchema', id: string, name: string } | null };

export const OrganizationTableRowFragmentDoc = gql`
    fragment OrganizationTableRow on TenantSchema {
  id
  logo
  name
  tenantCode
  locationDetails {
    address
    city
    state
    zipCode
  }
  primaryPhoneNumber
  admin {
    id
    name
  }
  status
}
    `;