import { Controller } from 'react-hook-form';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import AssetModel from 'app/i18n/AssetModel';

import { AssetModelFieldProps } from '../../type';

const AssetModelDisplayInOrderField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-[24px]">
      <Controller
        control={control}
        name="pickableThroughOrderRequest"
        render={({ field, fieldState }) => (
          <FormCheckBoxInput
            {...field}
            disabled={disabled}
            label={AssetModel.FormLabels.DisplayInOrder}
            classNames='!p-0 mr-8'
          />
        )}
      />
    </div>
  );
};
export default AssetModelDisplayInOrderField;
