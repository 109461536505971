import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import OrdersLayout from 'app/modules/orders/layout';
import OrderDetail from 'app/modules/orders/views/OrderDetail';

function InternalOrderDetailPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <OrdersLayout>
      <OrderDetail orderId={match.params.id} />
    </OrdersLayout>
  );
}

export default withRouter(InternalOrderDetailPage);
