import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Label } from 'reactstrap';
import { Button } from '@procurenetworks/procure-component-library';
import FormInputWithButton from 'app/components/Form/FormInputWithButton';
import {
  composeValidators,
  passwordValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Common from 'app/i18n/Common';
import Profile from 'app/i18n/Profile';
import Box from 'app/ui-components/Box';

import { PasswordUpdateInput, PasswordUpdateProps } from '../ProfileForm/types';
import { PROFILE_FORM_RULES as Rules } from '../ProfileForm/utils';

function PasswordUpdate(props: PasswordUpdateProps) {
  const { defaultValues, disabled, loading, onSubmit } = props;
  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const onSubmitPassword = (values: PasswordUpdateInput) => {
    onSubmit(values);
    reset();
  };

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [defaultValues]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmitPassword), [handleSubmit, onSubmit]);

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-24">
        <Label className="font-bold">Change Password</Label>

        <Controller
          control={control}
          name="currentPassword"
          render={({ field, fieldState }) => (
            <FormInputWithButton
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Profile.FormLabels.CurrentPassword}
            />
          )}
          rules={Rules.currentPassword}
        />

        <Controller
          control={control}
          name="newPassword"
          render={({ field, fieldState }) => (
            <FormInputWithButton
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Profile.FormLabels.NewPassword}
            />
          )}
          rules={{
            validate: composeValidators(
              (value) =>
                value !== getValues('currentPassword') || Profile.FormValidations.UniquePassword,
              textValidator(Profile.FormValidations.NewPassword),
              passwordValidator(Profile.FormValidations.InvalidPassword),
            ),
          }}
        />

        <Controller
          control={control}
          name="confirmNewPassword"
          render={({ field, fieldState }) => (
            <FormInputWithButton
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Profile.FormLabels.ConfirmNewPassword}
            />
          )}
          rules={{
            required: Profile.FormValidations.ConfirmNewPassword,
            validate: (value) =>
              value === getValues('newPassword') ||
              Profile.FormValidations.ConfirmNewPasswordNotMatched,
          }}
        />
        <Box className="flex justify-end">
          <Button
            classes="!px-12 w-[160px]"
            disabled={disabled}
            loading={loading}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.UpdatePassword}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default PasswordUpdate;
