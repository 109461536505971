import { useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useClient } from 'urql';
import {
  Scalars,
  ShippingTransactionSchema,
  ShippingTransactionStatusEnum,
  ShippingContainerSchema,
  ShippingTransactionChildEntitySchema
} from 'app/types/schema';
import {
  ContainerDetailsDocument,
  useContainerDetailsQuery,
} from '../../graphql/queries/generated/containerDetails';
import { EntityType, parseOrderRequest } from '../../utils';

const useContainerDetails = (row: ShippingContainerSchema, childContainerIds: Scalars['String'][], shouldFetchDetails: Boolean) => {
  const [loading, setLoading] = useState(false);
  const client = useClient();

  const [{ fetching, data }] = useContainerDetailsQuery({
    variables: {
      filters: {
        containerIds: childContainerIds,
        statuses: [ShippingTransactionStatusEnum.Packed],
      },
    },
    pause: !shouldFetchDetails,
  });

  const containerDetails = useMemo(() => {
    let shippingTransactions: ShippingTransactionSchema[] | undefined;

    if (shouldFetchDetails) {
      shippingTransactions = cloneDeep(data?.shippingTransactions?.edges?.map(edge => edge.node)) as ShippingTransactionSchema[];
    } else {
      shippingTransactions = cloneDeep(row?.shippingTransactions) as ShippingTransactionSchema[];
    }

    const mappedData = shippingTransactions?.map((transaction) => {
      if (transaction.orderRequest) {
        return {
          ...transaction,
          ...parseOrderRequest(transaction as ShippingTransactionSchema)
        };
      } else {
        return transaction.childEntities?.map((childEntity: ShippingTransactionChildEntitySchema) => ({
          ...transaction,
          label: childEntity?.entityId || '',
          createdAt: transaction?.createdAt
            ? moment(transaction?.createdAt).format('L LT')
            : '-',
          rowId: childEntity?.entityId,
          entityType: EntityType.CHILD_CONTAINER,
          containerId: childEntity?.entityId,
          entityId: childEntity?.entityId
        }))
      }
    }).flat();

    return {
      shippingTransactions: mappedData
    }
  }, [data, row, fetching, shouldFetchDetails]);


  const getShippingContainer = useCallback(
    async (containerId: string, cb: (status: string, data?: any) => void) => {
      setLoading(true);
      const container = await client
        .query(ContainerDetailsDocument, {
          filters: { containerIds: containerId ? [containerId] : [] },
        })
        .toPromise();
      if (container?.data?.shippingTransactions?.edges?.length > 0) {
        const containerDetails =
          container?.data?.shippingTransactions?.edges?.[0].node || undefined;
        setLoading(false);
        cb?.('success', containerDetails);
      } else {
        setLoading(false);
        cb?.('fail');
      }
    },
    [],
  );

  return {
    loading: loading || fetching,
    data: containerDetails,
    getShippingContainer,
  };
};

export default useContainerDetails;
