import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import { adaptNodeEdgeToNode } from 'app/components/AsyncMultiSelect/utils';
import { ItemTypeEnum, SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../../../../components/Form/FormError';
import FormLabel from '../../../../components/Form/FormLabel';
import LoadingOption from '../../../../components/LoadingOption';
import useDebounce from '../../../../hooks/useDebounce';
import usePagination from '../../../../hooks/usePagination';
import { getSupportedImageAttachments } from '../../../../utils/attachments';
import { AssetModelDataFragment } from '../../views/EditAssetModel/graphql/fragments/generated/assetModelData';
import { useFormAssetModelSelectQuery } from './graphql/generated/formAssetModelSelect';

// TODO: We will enable pagination after pagination fixed by backend team.
const DEFAULT_PAGE_SIZE = 10000;

interface Props {
  className?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  name?: string;
  value?: string | null;
  onBlur?: () => void;
  onChange?: (value: string | undefined, option?: any) => void;
  error?: FieldError;
  placeholder?: string;
  autoFocus?: boolean;
}

export type Option = AssetModelDataFragment;

const getOptionLabel = (option: Option) => `${option.title} (${option.sku})`;

const FormAssetModelSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  let {
    autoFocus,
    className,
    disabled,
    error,
    isRequired,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
  } = props;

  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  const searchValue = useDebounce(inputValue);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  const [{ data: selectedData }] = useFormAssetModelSelectQuery({
    variables: {
      filters: {
        types: [ItemTypeEnum.AssetModel],
        assetModelIds: value ? [value] : [],
      },
    },
    pause: !value || Boolean(value && selectedValue && (setSelectedValue as any)?.id === value),
  });

  const { fetching, data, onNextPage, onPrevPage } = usePagination(
    useFormAssetModelSelectQuery,
    {
      filters: {
        search: searchValue,
        types: [ItemTypeEnum.AssetModel],
      },
      sorts: !searchValue
        ? [
            {
              sortField: 'title',
              sortOrder: SortOrderEnum.Asc,
            },
          ]
        : undefined,
    },
    {
      pageSize: DEFAULT_PAGE_SIZE,
      edgeKey: 'assetModelItems',
    },
  );

  useEffect(() => {
    const selectedOption = selectedData?.assetModelItems?.edges?.[0]?.node;
    setSelectedValue((selectedOption as Option) || null);
  }, [selectedData?.assetModelItems?.edges]);

  useEffect(() => {
    const newOptions: Option[] = data?.assetModelItems?.edges?.map(adaptNodeEdgeToNode) || [];
    /*
    // TODO: We will enable pagination after pagination fixed by backend team.
    if (onPrevPage) {
      setOptions([...options, ...newOptions]);
    } else {
      setOptions(newOptions);
    }*/
    setOptions(newOptions);
  }, [data?.assetModelItems?.edges]);

  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;
      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !fetching) {
        // TODO: We will enable pagination after pagination fixed by backend team.
        // onNextPage();
      }
    },
    [onNextPage, fetching],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: Option) => {
      if (option) {
        setSelectedValue(option as Option);
        setInputValue(option.sku);
        onChange?.(option.id, option);
      } else {
        setSelectedValue(option);
        onChange?.('', option);
      }
    },
    [onChange],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    if (selectedValue && value === getOptionLabel(selectedValue)) {
      setInputValue(selectedValue?.sku);
    } else {
      setInputValue(value);
    }
  };

  const onBlurValue = useCallback(() => {
    setInputValue(selectedValue ? selectedValue.sku : '');
    onBlur?.();
  }, [onBlur, selectedValue]);

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        ListboxProps={{
          onScroll: onScroll,
          role: 'list-box',
        }}
        className={cx(
          'mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none',
          className,
        )}
        disabled={disabled}
        filterOptions={(option) => option}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        label={''}
        loading={fetching}
        openOnFocus={autoFocus}
        options={options}
        renderInput={(params) => <TextField {...params} name={name} placeholder={placeholder} />}
        renderOption={(props: any, option: Option) => {
          const attachments = option.attachments || [];
          const imageAttachments = getSupportedImageAttachments(attachments);
          const imageUrl = imageAttachments.length ? imageAttachments[0].url : undefined;
          const showImageBox = selectedValue?.id !== option?.id;
          const showLoadingOption = !!(
            options.indexOf(option) === options.length - 1 && onNextPage
          );
          return (
            <div key={option?.id} {...props}>
              <div className="d-block w-[100%]">
                <div className="flex">
                  {showImageBox ? (
                    imageUrl ? (
                      <img
                        alt={option.title}
                        className="mw-[50px] mh-[50px] mr-[10px] h-[50px] w-[50px] object-contain"
                        src={imageUrl}
                      />
                    ) : (
                      <div className="mw-[50px] mh-[50px] mr-[10px] h-[50px] w-[50px] text-[#e3e3e3]"></div>
                    )
                  ) : undefined}
                  <span>
                    {option.title} ({option.sku})
                  </span>
                </div>
                {showLoadingOption ? (
                  <div className="d-block w-[100%]">
                    <LoadingOption />
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
        value={selectedValue as any}
        onBlur={onBlurValue}
        onChange={onValueChange}
        onInputChange={onInputValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormAssetModelSelect;
