import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProjectsSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ProjectFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type ProjectsSelectQuery = { __typename?: 'Query', projects: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node: { __typename?: 'ProjectSchema', id: string, name: string } }> | null } };


export const ProjectsSelectDocument = gql`
    query projectsSelect($filters: ProjectFilters, $sorts: [SortProps!], $limit: Float) {
  projects(filters: $filters, sorts: $sorts, limit: $limit) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useProjectsSelectQuery(options?: Omit<Urql.UseQueryArgs<ProjectsSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<ProjectsSelectQuery>({ query: ProjectsSelectDocument, ...options });
};