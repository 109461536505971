const Import = {
  Import: 'Import',
  ImportMapping: 'Import - Data Mapping',
  EntityType: 'Entity Type',
  BulkImportOptions: {
    Asset: 'Asset',
    AssetModel: 'Asset Model',
    Category: 'Category',
    Company: 'Company',
    Department: 'Department',
    Inventory: 'Inventory',
    Location: 'Location',
    Project: 'Project',
    User: 'User',
    Site: 'Site',
    Manufacturer: 'Manufacturer',
  },
  FormLabels: {
    DataType: 'Data Type',
    AttachFile: 'Attach File',
    ImportStatus: 'Import Status',
  },
  FormPlaceholders: {
    DataType: 'Select Data Type',
    AttachFile: 'NOTE: CSV file type only.',
  },
  FormValidationMessages: {
    DataTypeRequired: 'Please select a Data Type.',
    AttachFileRequired: 'Please drop or select a file.',
    MappingFieldRequired: (label: string) => `Please select ${label}`,
    SuccessFullyImported: 'records have been successfully imported.',
    NotImported: 'records were not imported.',
    DownloadFileMessage: 'Please download the file for more details.',
    DuplicateFieldFound: (valueKey: string, duplicateKeys: string) =>
      `${valueKey} value is duplicate with value of ${duplicateKeys}`,
  },
  Actions: {
    DownloadFile: 'Download File',
    Import: 'Import',
  },
};

export default Import;
