import { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Label from 'app/i18n/Label';
import EntityManager from 'app/modules/components/EntityManager';
import FormSiteSelect from 'app/modules/locations/components/FormSiteSelect';
import { ShippingContainerSchema, ShippingContainerTypePrefixEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import useLabelsFrom from '../../../context/useLabelsFrom';
import { useCreateShippingContainerMutation } from '../graphql/mutations/generated/createShippingContainer';
import { ShippingSetupFormProps } from '../types';
import { getValidationRules } from '../utils/validation';
import analytics from 'app/analytics';

const WarehouseSetupForm = (props: ShippingSetupFormProps) => {
  const { control, values, trigger, setData } = useLabelsFrom();
  const isValid = useMemo(() => getValidationRules(values?.type), [values?.type]);
  const [{ fetching }, onCreateContainer] = useCreateShippingContainerMutation();

  const onPrintLabels = useCallback(async () => {
    const isValid = trigger && (await trigger());
    if (values && isValid) {
      onCreateContainer({
        input: {
          containerType: values?.containerType as ShippingContainerTypePrefixEnum,
          count: parseInt(values?.labelsCount as unknown as string),
          destinationSiteId: values?.destinationSite,
        },
      }).then((response: any) => {
        analytics?.track('Created', { name: 'Shipping Container' });
        const shippingContainers =
          response?.data && response.data?.createShippingContainer?.shippingContainers;
        if (shippingContainers?.length > 0) {
          const ids = shippingContainers.map((container: ShippingContainerSchema) => container?.id);
          setData && setData({ ...values, ids: ids, data: shippingContainers });
          localStorage.setItem(
            'printLabels',
            JSON.stringify({ ...values, ids: ids, data: shippingContainers }),
          );
          window.open('/admin/print-label', '_blank');
        } else {
          console.error('[Create Location] Failed', response);
        }
      });
    }
  }, [values]);

  const onCancel = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <form>
      <Box className="max-w-[414px] space-y-24 py-16">
        <EntityManager.Title title={Label.Warehouse.WarehouseSetup} />
        <Controller
          control={control}
          name="labelSite"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.LabelSite}
              placeholder={Label.Form.SelectOne}
            />
          )}
          rules={isValid?.LabelSite}
        />
        <Controller
          control={control}
          name="zones"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.Zones}
              placeholder={Label.Form.EnterNumber}
              type={'number'}
            />
          )}
        />
        <Controller
          control={control}
          name="aisles"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.Aisles}
              placeholder={Label.Form.EnterNumber}
              type={'number'}
            />
          )}
        />
        <Controller
          control={control}
          name="racks"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.Racks}
              placeholder={Label.Form.EnterNumber}
              type={'number'}
            />
          )}
          rules={isValid?.LabelRacks}
        />
        <Controller
          control={control}
          name="shelves"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.Shelves}
              placeholder={Label.Form.EnterNumber}
              type={'number'}
            />
          )}
          rules={isValid?.LabelShelves}
        />
        <Controller
          control={control}
          name="bins"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              className="flex-1"
              error={fieldState.error}
              label={Label.Form.WarehouseSetup.Bins}
              placeholder={Label.Form.EnterNumber}
              type={'number'}
            />
          )}
        />
        {/* <Stack className="mt-8 px-16" justifyContent="end">
          <Button theme="success" onClick={onPrintLabels} loading={fetching}>
            {Label.PrintLabels}
          </Button>
          <Button classes="!ml-[4px]" onClick={onCancel}>
            {Common.Actions.Cancel}
          </Button>
        </Stack> */}
      </Box>
    </form>
  );
};

export default memo(WarehouseSetupForm);
