import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { useSitesTableQuery } from 'app/modules/sites/views/Sites/graphql/queries/generated/sitesTable';
import { LocationSchema, LocationTypeEnum } from 'app/types/schema';
import { matchingIndex, truncateString } from 'app/utils/truncate';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useSitesTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        types: [LocationTypeEnum.Location]
      },
    },
  });

  useEffect(() => {
    const result = data?.locations?.edges?.map((a) => {
      const item = a.node as LocationSchema
      
      const queryWords = searchedValue ? searchedValue.trim().toLowerCase().split(" ") : [""];
      let result = null;
      let searchBy = null;

      const searchQuery = searchedValue.toLowerCase();

      if (item.name.toLowerCase().includes(searchQuery)) {
        result = item.name;
        searchBy = item.name;
      } else if ((item.site?.name || "").toLowerCase().includes(searchQuery)) {
        result = item.site.name;
        searchBy = item.site.name;
      } else if (item.description && item.description.toLowerCase().includes(searchQuery)) {
        const matchIndex = matchingIndex(item.description, [searchedValue]);
        result = truncateString(item.description, matchIndex);
        searchBy = item.description;
      } else {
        if (queryWords.length > 0) {
          if (queryWords.some((word) => item.name.toLowerCase().includes(word))) {
            result = item.name;
            searchBy = item.name;
          } else if (queryWords.some((word) => (item.site?.name || "").toLowerCase().includes(word))) {
            result = item.site.name;
            searchBy = item.site.name;
          } else if (
            item.description &&
            queryWords.some((word) => item.description?.toLowerCase().includes(word))
          ) {
            const matchIndex = matchingIndex(item.description, queryWords);
            result = truncateString(item.description, matchIndex);
            searchBy = item.description;
          }
        }
      }

      return {
        label: searchBy || item.name,
        id: item.id,
        value: result || item.name
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue.trim());
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
