import { useEffect, useState } from 'react';
import { useDistinctUsersWithNetworkAssetsQuery } from 'app/modules/network/graphql/queries/generated/distinctUsersWithNetworkAssets';
import { useGetAllNetworkAssetWarrantyQuery } from 'app/modules/network/graphql/queries/generated/networkAssetWarrantyType';
import { warrantyDetails, warrantyTypes } from 'app/modules/network/types';

import { generateColor } from '../../utils';

const useDashboardData = () => {
  const [{ data: networkAssetWarrantyTypeData }] = useGetAllNetworkAssetWarrantyQuery();
  const [{data: distinctUser}] = useDistinctUsersWithNetworkAssetsQuery({variables: {filters:{hasItAssets: true}}});
  

  const defaultValue = {
    color: ['var(--red900)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
    labels: [
      { value: 0, label: 'Warranty Expired' },
      { value: 0, label: 'Warranty Expiring Soon' },
      { value: 0, label: 'Under Warranty' },
      { value: 0, label: 'No Warranty' },
    ],
  };

  const [warrantyState, setWarrantyState] = useState<warrantyDetails>(defaultValue);
  const [totalWarrantyState, setTotalWarrantyState] = useState<warrantyDetails>(defaultValue);
  const [totalAsset, settotalAsset] = useState(0);
  const [totalUser, settotalUser] = useState(0);
  const [networkAssetTypesWarranty, setNetworkAssetTypesWarranty] = useState<warrantyTypes[]>([]);

  useEffect(() => {
    const networkAssetWarranty =
      networkAssetWarrantyTypeData?.getAllNetworkAssetWarranty.totalAssetsWarranty;
    const warranties = networkAssetWarranty?.length
      ? networkAssetWarranty.map((warranty) => {
          return {
            value: warranty.totalQuantity,
            label: warranty.type,
            warranty: {
              color: ['var(--red900)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
              labels: [
                { value: warranty.expired.length || 0, label: 'Warranty Expired' },
                { value: warranty.expiringSoon.length || 0, label: 'Warranty Expiring Soon' },
                { value: warranty.inWarranty.length || 0, label: 'Under Warranty' },
                { value: warranty.noWarranty.length || 0, label: 'No Warranty' },
              ],
            },
          };
        })
      : [];

    const expiredCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[0].value || 0),
      0,
    );
    const expiringSoonCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[1].value || 0),
      0,
    );
    const underWarrantyCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[2].value || 0),
      0,
    );
    const noWarrantyCount = warranties.reduce(
      (acc, curr) => acc + (curr.warranty.labels[3].value || 0),
      0,
    );

    const totalWarranty = {
      color: ['var(--red900)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)'],
      labels: [
        { value: expiredCount, label: 'Warranty Expired' },
        { value: expiringSoonCount, label: 'Warranty Expiring Soon' },
        { value: underWarrantyCount, label: 'Under Warranty' },
        { value: noWarrantyCount, label: 'No Warranty' },
      ],
    };

    setTotalWarrantyState(totalWarranty);
    setWarrantyState(totalWarranty);

    const totalQuantity = warranties.reduce((acc, curr) => acc + curr.value, 0);
    settotalAsset(totalQuantity);
    setNetworkAssetTypesWarranty(warranties || []);
  }, [networkAssetWarrantyTypeData]);

  const assetWithColor = networkAssetTypesWarranty.length
    ? networkAssetTypesWarranty.map((value) => {
        return {
          ...value,
          color: generateColor(),
        };
      })
    : [];
  
  useEffect(()=>{
    const totalUsers = distinctUser?.users.totalCount || 0;
    settotalUser(totalUsers)
  },[distinctUser])

  return {
    totalUser,
    totalAsset,
    warrantyState,
    setWarrantyState,
    totalWarrantyState,
    defaultValue,
    assetWithColor,
  };
};

export default useDashboardData;
