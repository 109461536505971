import React, { CSSProperties, forwardRef, useCallback, useEffect, useMemo } from 'react';
import { Accept, DropEvent, useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import Uppy from '@uppy/core';
import _, { omit } from 'lodash';
import { CreateMediaInput, Maybe } from 'app/types/schema';
import Box from 'app/ui-components';
import { AttachmentType } from 'app/uppy/useUppy';

import Assets from '../../../i18n/Assets';
import Orders from '../../../i18n/Orders';
import { UseUppyFileType } from '../../../uppy/types';
import { DropZoneErrorCode } from '../../Form/FormFileUpload/type';
import UploadedFilePreview from '../../Form/FormFileUpload/UploadedFilePreview';
import {
  convertBytesToMb,
  isDuplicateFile,
  thumbsContainer,
} from '../../Form/FormFileUpload/utils';
import { SnackbarService } from '../../Snackbar';
import { parseFileName } from '../FormFileUploader/utils';
import FormTextInput from '../FormTextInput';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  label: string;
  isRequired?: boolean;
  disabled?: boolean;
  name?: string;
  error?: FieldError;
  value?: Maybe<CreateMediaInput[]>;
  onChange?: (value: CreateMediaInput[]) => void;
  uppy: Uppy;
  files: Record<string, UseUppyFileType>;
  isUploading: boolean;
  removeFileById: (fileId: string) => void;
  attachments: AttachmentType[];
  id?: string;
  getInputReference?: (input: HTMLElement) => void;
  onClick?: () => void;
  multiple?: boolean;
  maxSize?: number;
  accept?: Accept;
  formLabel?:string
}

const FormInputImageUploader = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    value,
    className,
    uppy,
    attachments,
    onChange,
    isRequired,
    label,
    error,
    disabled,
    removeFileById,
    isUploading,
    multiple,
    maxSize,
    files,
    onClick,
    accept,
    formLabel
  } = props;

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    disabled: disabled || false,
    accept: accept,
    noClick: true,
    noKeyboard: true,
    multiple: multiple,
    maxSize: maxSize,
    onDrop: (acceptedFiles: any[], fileRejections: any[], event: DropEvent) => {
      try {
        acceptedFiles.forEach((file) => {
          if (isDuplicateFile(files, file)) {
            SnackbarService.showError({
              message: Assets.FormValidationMessages.AttachmentAlreadyUploaded,
            });
          } else {
            const currentFiles = _.values(files);
            if (!multiple && currentFiles?.[0]?.id) {
              console.log('remove file by id', currentFiles[0]);
              removeFileById(currentFiles?.[0]?.id);
            }

            uppy.addFile({
              data: file,
              name: parseFileName(file.name),
              type: file.type,
            });
          }
        });

        fileRejections.forEach((file) => {
          if (file?.errors && file?.errors.length > 0) {
            const errorCode = file?.errors?.[0]?.code;

            if (errorCode === DropZoneErrorCode.FILE_TOO_LARGE && maxSize) {
              console.log('got file too large');
              SnackbarService.showError({
                message: Orders.FormValidationMessages.ImageSizeInvalid(convertBytesToMb(maxSize)),
              });
            } else if (errorCode === DropZoneErrorCode.FILE_INVALID_TYPE) {
              SnackbarService.showError({
                message: Orders.FormValidationMessages.ImageInvalid,
              });
            }
          }
        });

        // Clearing existing input Value, so It allow us to send same file, and we can show validation.
        const inputTarget = event?.target ? (event?.target as HTMLInputElement) : undefined;
        if (inputTarget) {
          inputTarget.value = '';
        }
      } catch (error) {
        console.error('[FormInputImageUploader]', error);
      }
    },
  });

  const filesAttachments = useMemo(() => {
    return attachments.map((attachment) => {
      return {
        id: attachment.id,
        preview: attachment.url,
        isLoading: attachment.url === '',
      };
    });
  }, [attachments]);

  useEffect(() => {
    const updatedAttachments: CreateMediaInput[] = [];

    attachments.forEach((attachment) => {
      const copyAttachment = omit({ ...attachment }, ['id']);
      if (attachment?.url) {
        updatedAttachments.push(copyAttachment as CreateMediaInput);
      }
    });

    onChange?.(updatedAttachments);
  }, [attachments, onChange]);

  const onImageFieldFocus = useCallback(() => {
    if (!disabled) {
      inputRef?.current?.click();
      onClick?.();
    }
  }, [inputRef, onClick, disabled]);

  return (
    <>
    {formLabel && 
      <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
            {formLabel}
          </FormLabel>}
      <Box ref={ref} onClick={onImageFieldFocus}>
        <FormTextInput
          className={className}
          disabled={disabled}
          error={error}
          isRequired={isRequired}
          label={label}
        />
      </Box>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>
      <aside style={thumbsContainer as CSSProperties}>
        <UploadedFilePreview
          disabled={disabled || false}
          files={filesAttachments}
          removeFileById={removeFileById}
        />
      </aside>
    </>
  );
});
export default FormInputImageUploader;
