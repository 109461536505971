import { useMemo } from 'react';
import useImageViewDialogState from 'app/modules/components/ImageViewDialog/hook/useImageViewDialogState';
import { OrderRequestItemSchema, ShippingTransactionSchema } from 'app/types/schema';

import { useContainerOrderDetailsQuery } from '../../../PackedShipmentItemTable/components/TransactionsTable/graphql/queries/generated/containerOrderDetails';
import { OrderItemsQuery, useOrderItemsQuery } from '../../graphql/queries/generated/orderItems';
import { prepareTableRows } from './utils';

interface Props {
  orderId: string;
  itemIds: string[];
  row: ShippingTransactionSchema;
}

const useOrderItemsDetails = ({ orderId, itemIds, row }: Props) => {
  const { state: imagePreviewState, actions: imagePreviewActions } = useImageViewDialogState();
  const [{ fetching: fetchingOrderDetails, data: orderDetails }] = useContainerOrderDetailsQuery({
    variables: {
      filters: {
        orderRequestIds: [orderId],
      },
    },
  });

  const [{ fetching, data }] = useOrderItemsQuery({
    variables: {
      filters: {
        itemIds: itemIds ?? [],
      },
    },
  });

  const rows = useMemo(() => {
    if (fetching || fetchingOrderDetails) {
      return [];
    } else {
      return prepareTableRows(
        data as OrderItemsQuery,
        orderDetails?.orderRequests?.edges?.[0]?.node
          ?.orderRequestItems as OrderRequestItemSchema[],
        row,
      );
    }
  }, [data, orderDetails?.orderRequests?.edges, row, fetching, fetchingOrderDetails]);

  return {
    state: {
      data,
      rows,
      fetching: fetching || fetchingOrderDetails,
      imagePreviewState,
    },
    actions: {
      imagePreviewActions,
    },
  };
};

export default useOrderItemsDetails;
