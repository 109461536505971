import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type WarehouseLocationsQueryVariables = Types.Exact<{
  filters: Types.WarehouseFilters;
  input: Types.GetLastLocationNamesOfChildInput;
}>;


export type WarehouseLocationsQuery = { __typename?: 'Query', warehouses: { __typename?: 'WarehouseConnection', edges: Array<{ __typename?: 'WarehouseEdge', node: { __typename?: 'WarehouseSchema', id: string, rackIncrementor?: number | null, lastLocationNamesOfChild: Array<string> } }> } };


export const WarehouseLocationsDocument = gql`
    query warehouseLocations($filters: WarehouseFilters!, $input: GetLastLocationNamesOfChildInput!) {
  warehouses(filters: $filters) {
    edges {
      node {
        id
        rackIncrementor
        lastLocationNamesOfChild(input: $input)
      }
    }
  }
}
    `;

export function useWarehouseLocationsQuery(options: Omit<Urql.UseQueryArgs<WarehouseLocationsQueryVariables>, 'query'>) {
  return Urql.useQuery<WarehouseLocationsQuery>({ query: WarehouseLocationsDocument, ...options });
};