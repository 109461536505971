import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';
import { NETWORK_ASSET_FORM_RULES } from 'app/modules/network/utils/validation';

type Props = {
  control: any;
  className?:string
};

const BatteryForm = (props: Props) => {
  const { control,className="mt-0" } = props;

  return (
    <>
      <Typography
         className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Battery.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryManufacturer"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Manufacturer}
                placeholder={Network.FormLabels.Battery.Manufacturer}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryType"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Type}
                placeholder={Network.FormLabels.Battery.Type}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batterySerial"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Serial}
                placeholder={Network.FormLabels.Battery.Serial}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryDesignedCapacity"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.DesignedCapacity}
                placeholder={Network.FormLabels.Battery.DesignedCapacity}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryMaxCapacity"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.MaxCapacity}
                placeholder={Network.FormLabels.Battery.MaxCapacity}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryCurrentCapacity"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.CurrentCapacity}
                placeholder={Network.FormLabels.Battery.CurrentCapacity}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryModel"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Model}
                placeholder={Network.FormLabels.Battery.Model}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryVoltage"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Voltage}
                placeholder={Network.FormLabels.Battery.Voltage}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="batteryPercentage"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Battery.Percentage}
                placeholder={Network.FormLabels.Battery.Percentage}
              />
            )}
            rules={NETWORK_ASSET_FORM_RULES.numberValidation}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BatteryForm;
