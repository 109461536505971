import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type NetworkAssetsTableQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.NetworkAssetsFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type NetworkAssetsTableQuery = { __typename?: 'Query', networkAssets: { __typename?: 'NetworkAssetConnection', success: boolean, errorCode?: Types.PaginationErrorCodeEnum | null, totalCount: number, edges: Array<{ __typename?: 'NetworkAssetEdge', node: { __typename?: 'NetworkAssetSchema', id: string, mac?: string | null, userName?: string | null, ipAddress?: string | null, type?: Types.NetworkAssetTypeEnum | null, status: Types.NetworkAssetStatusEnum, warrantyStatus: string, createdById?: string | null, updatedById?: string | null, tenantId?: string | null, createdAt: string, updatedAt: string, battery?: { __typename?: 'BatterySchema', manufacturer?: string | null } | null, bios?: { __typename?: 'BiosSchema', vendor?: string | null, version?: string | null } | null, cpu?: { __typename?: 'CpuSchema', manufacturer?: string | null, brand?: string | null, model?: string | null } | null, graphics?: Array<{ __typename?: 'GraphicsSchema', vendor?: string | null, model?: string | null }> | null, storage?: Array<{ __typename?: 'StorageSchema', name?: string | null }> | null, disk?: Array<{ __typename?: 'DiskSchema', size?: number | null }> | null, memory?: { __typename?: 'MemorySchema', total?: number | null } | null, motherboard?: { __typename?: 'MotherBoardSchema', manufacturer?: string | null, model?: string | null } | null, system?: { __typename?: 'SystemSchema', manufacturer?: string | null, model?: string | null } | null, operatingSystem?: { __typename?: 'OsSchema', platform?: string | null, distro?: string | null, release?: string | null, codename?: string | null } | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const NetworkAssetsTableDocument = gql`
    query networkAssetsTable($filters: NetworkAssetsFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  networkAssets(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        id
        mac
        userName
        ipAddress
        type
        status
        battery {
          manufacturer
        }
        bios {
          vendor
          version
        }
        cpu {
          manufacturer
          brand
          model
        }
        graphics {
          vendor
          model
        }
        storage {
          name
        }
        disk {
          size
        }
        memory {
          total
        }
        motherboard {
          manufacturer
          model
        }
        system {
          manufacturer
          model
        }
        operatingSystem {
          platform
          distro
          release
          codename
        }
        warrantyStatus
        createdById
        updatedById
        tenantId
        createdAt
        updatedAt
      }
    }
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
    success
    errorCode
    totalCount
  }
}
    `;

export function useNetworkAssetsTableQuery(options?: Omit<Urql.UseQueryArgs<NetworkAssetsTableQueryVariables>, 'query'>) {
  return Urql.useQuery<NetworkAssetsTableQuery>({ query: NetworkAssetsTableDocument, ...options });
};