import { useCallback, useEffect, useState } from 'react';
import { DataGridCellSortState } from '@procurenetworks/procure-component-library';
import useDebounce from 'app/hooks/useDebounce';
import { SortOrderEnum, SortProps } from 'app/types/schema';
import { getPageSettingsFromStorage } from './../../../../utils/paginationSettingsUtil';

interface State {
  activePage: number;
  search: string;
  sortState: DataGridCellSortState;
  sorts: SortProps[];
  selectedRowIds: string[];
  selectedRowData: any[];
  selection: boolean;
  multipleSelection?: boolean;
  showDeleteConfirmation: boolean;
  numberOfRowsPerPage: number;
}

interface Props {
  selection?: boolean;
  onEdit?: (id: any) => void;
  onDelete?: (id: string[], item?: object[]) => void;
  onImport?: (id: string[]) => void;
  hideActions?: boolean;
  sortState?: DataGridCellSortState;
}

const useMultiSelectEntityManager = (props: Props) => {
  const {
    onEdit: onEditProp,
    onDelete: onDeleteProp,
    selection,
    hideActions = false,
    onImport: onImportProp,
    sortState,
  } = props;

  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(10);

  const [state, _setState] = useState<State>({
    activePage: 1,
    numberOfRowsPerPage: rowsPerPage,
    search: '',
    sortState: sortState || { id: 'id', orderBy: 'asc' },
    sorts: sortState
      ? ([
          {
            sortField: sortState.id,
            sortOrder: sortState.orderBy === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
          },
        ] as SortProps[])
      : [],
    selectedRowIds: [],
    selectedRowData: [],
    showDeleteConfirmation: false,
    selection: selection !== undefined ? selection : true,
  });

  const debouncedSearchText = useDebounce(state.search);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const onSearchChange = useCallback(
    (nextSearch: string) => {
      setState({
        search: nextSearch,
        activePage: 0,
      });
    },
    [setState],
  );

  const onShowDeleteConfirmation = useCallback(() => {
    setState({ showDeleteConfirmation: true });
  }, [setState]);

  const onCancelDelete = useCallback(() => {
    setState({ showDeleteConfirmation: false });
  }, [setState]);

  const onImport = useCallback(() => {
    if (state.selectedRowIds.length > 0 && onImportProp) {
      onImportProp(state.selectedRowIds);
    }
  }, [onImportProp, state.selectedRowIds]);

  const onEdit = useCallback(() => {
    if (state.selectedRowIds.length > 0 && onEditProp) {
      onEditProp(state.selectedRowIds);
    }
  }, [onEditProp, state.selectedRowIds]);

  const onConfirmDelete = useCallback(() => {
    if (state.selectedRowIds.length > 0) {
      if (onDeleteProp) {
        onDeleteProp(state.selectedRowIds, state.selectedRowData);
      }

      setState({ selectedRowIds: [], showDeleteConfirmation: false });
    }
  }, [onDeleteProp, state.selectedRowIds, state.selectedRowData, setState]);

  const onResetSelectedRow = useCallback(() => {
    setState({
      selectedRowIds: [],
      selectedRowData: [],
    });
  }, [setState]);

  return {
    search: {
      debouncedSearchText,
      value: state.search,
      onChange: onSearchChange,
    },
    table: {
      state,
      setState,
      actions: {
        hideActions,
        onEdit: onEditProp ? onEdit : undefined,
        onCancelDelete,
        onConfirmDelete,
        onShowDeleteConfirmation,
        onResetSelectedRow,
        onImport,
      },
    },
  };
};

export type UseMultiSelectEntityManagerReturnType = ReturnType<typeof useMultiSelectEntityManager>;
export default useMultiSelectEntityManager;
