import OrderItemInStockInput from '../../views/OrderRequest/components/OrderRequestForm/components/OrderItemInStockField/OrderItemInStockInput';
import { useCallback, useEffect, useState } from 'react';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { ItemInStockDialogForEnum } from '../../../assetsInventory/components/ItemInStockSearchDialog/type';
import { OrderRequestItemSchema } from '../../views/OrderRequest/type';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  actions: OrderItemsExtraPropType['actions'];
}

const OrderRequestItemInStockEditView = (props: Props) => {
  const { orderRequestItem, actions } = props;
  const { itemInStockDialogActions } = actions;
  const [search, setSearch] = useState(orderRequestItem?.item?.sku || '');

  useEffect(() => {
    setSearch(orderRequestItem?.item?.sku || '');
  }, [orderRequestItem?.item?.sku]);

  const onSearchIconClick = useCallback(() => {
    const itemInStockSearchMeta = {
      orderRequestItem: orderRequestItem.id,
      for: ItemInStockDialogForEnum.AssetInventoryUpdateItemInStock,
    };
    if (search.trim().toLowerCase() === orderRequestItem.item?.sku.toLowerCase()) {
      const { item } = orderRequestItem;
      const { entityIdInSourceTenant, sku, id } = item;

      itemInStockDialogActions.onShowItemInStockSearchDialog(
        {
          id: entityIdInSourceTenant || id,
          sku: entityIdInSourceTenant ? '' : sku,
        },
        '',
        itemInStockSearchMeta,
      );
    } else {
      itemInStockDialogActions.onShowItemInStockSearchDialog(null, search, itemInStockSearchMeta);
    }
  }, [itemInStockDialogActions, orderRequestItem.item, search]);

  return (
    <OrderItemInStockInput
      inputParams={{
        value: search,
        id: `orderRequestItem-edit-item-in-stock-${orderRequestItem.id}`,
        InputProps: {
          onChange: (event: any) => {
            setSearch(event?.target?.value || '');
          },
        },
      }}
      onSearchIconClick={onSearchIconClick}
      size="small"
    />
  );
};

export default OrderRequestItemInStockEditView;
