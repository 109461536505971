import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useFormFileUploader from 'app/components/ProcureForm/FormFileUploader/hook/useFormFileUploader';

import { ORDER_INSERT_TYPE } from '../../type';
import { getDefaultFormValues, getUnregisterFormValues } from '../../utils/utils';
import useCurrentUser from '../../../../../../../auth/hooks/useCurrentUser';
import { isEmpty } from 'lodash';
import Orders from 'app/i18n/Orders';

interface Props {
  defaultValues: any;
}

const useOrderRequestForm = (props: Props) => {
  const { tenantId } = useCurrentUser();
  const { defaultValues } = props;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    trigger,
    unregister,
    getValues,
    formState: { errors, submitCount },
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const {
    inputProps: orderImageFieldInputProps,
    setAttachments,
    clearAll,
  } = useFormFileUploader('orderImages');

  const partnerTenantId = watch('partnerTenantId');
  const itemType = watch('itemType');
  const itemInStock = watch('itemInStock');
  const description = watch('description');
  const website = watch('website');
  const upcCode = watch('upcCode');
  const imageUrl = watch('imageUrl');
  const sku = useMemo(() => itemInStock?.sku, [itemInStock]);

  const currentUser = useCurrentUser();
  const { tenant, parentPartnerTenants } = currentUser;

  useEffect(() => {
    if (parentPartnerTenants && !parentPartnerTenants.length) {
      setValue('partnerTenantId', tenant?.id);
    }
  }, [partnerTenantId]);

  useEffect(() => {
    if (itemType === ORDER_INSERT_TYPE.NON_STOCKED_ITEMS && submitCount > 0) {
      setTimeout(() => {
        trigger(['imageUrl', 'website', 'description', 'upcCode', 'itemType']);
      }, 100);
    }
  }, [submitCount, imageUrl, website, description, upcCode, itemType]);

  const onResetForm = useCallback(
    (itemType?: ORDER_INSERT_TYPE) => {
      const currentValues = getValues();
      clearAll();
      const defaultFormValues = getDefaultFormValues({
        itemType: itemType || ORDER_INSERT_TYPE.ITEM_IN_STOCK,
        partnerTenantId: currentValues.partnerTenantId,
      });
      reset(defaultFormValues);
      unregister(getUnregisterFormValues());
    },
    [reset, defaultValues, setAttachments, unregister, getValues, clearAll],
  );

  const onInsertItemTypeChange = useCallback(
    (updatedItemType: ORDER_INSERT_TYPE) => {
      if (updatedItemType === itemType) {
        return;
      }
      onResetForm(updatedItemType);
    },
    [onResetForm, itemType],
  );

  const validateAllEmpty = useCallback(() => {
    return itemType === ORDER_INSERT_TYPE.NON_STOCKED_ITEMS &&
      !website &&
      isEmpty(imageUrl) &&
      !description &&
      !upcCode
      ? Orders.FormValidationMessages.MissingNonStockedFieldValues
      : true;
  }, [itemType, website, imageUrl, description, upcCode]);

  const isExternalTenant = useMemo(
    () => !!(partnerTenantId && tenantId !== partnerTenantId),
    [partnerTenantId, tenantId],
  );

  return {
    handleSubmit,
    onResetForm,
    formState: {
      control,
      itemType,
      itemInStock,
      sku,
      partnerTenantId,
      setValue,
      trigger,
      orderImageFieldInputProps,
      isExternalTenant,
      tenantId,
      getValues,
      errors,
      submitCount,
      actions: {
        onInsertItemTypeChange,
        validateAllEmpty,
      },
    },
  };
};

export type UseOrderRequestFormReturnType = ReturnType<typeof useOrderRequestForm>;

export default useOrderRequestForm;
