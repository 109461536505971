import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';

import useAffiliateMerchantsRows from '../useAffiliateMerchantRows';

interface Props {}

const useAffiliateMerchantsTable = (props: Props) => {

  const { search, table } = useEntityManager({ selection: false });

  const numberOfRowsPerPage = table.state.numberOfRowsPerPage;

  const filters = {
    search: search.debouncedSearchText.replace(/^"(.*)"$/, '$1'),
  };

  const affiliateMerchantsTableState = useAffiliateMerchantsRows({
    filters: {
      ...filters,
    },
    sorts: table.state.sorts,
    numberOfRowsPerPage,
  });

  return {
    tableState:  affiliateMerchantsTableState,
    search,
    table,
  };
};

export type UseAffiliateMerchantsTableReturnType = ReturnType<typeof useAffiliateMerchantsTable>;
export default useAffiliateMerchantsTable;
