import Common from 'app/i18n/Common';
import AlertBox from 'app/ui-components/AlertBox';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type DeleteManagers = {
  userName: string; 
  assignedTo: string; 
  role: string
}
interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  deletingManagersInfo: {
    categoryManagers: DeleteManagers[];
    siteManagers: DeleteManagers[];
  };
}

function DeleteManagersModal(props: Props) {
  const [errorCount, setErroCount] = useState(0);
  // NOTE: "shouldShowWarning" will be toggled to show/hide popup
  // simulatenously untill it shows all warnings
  const [shouldShowWarning, setShouldShow] = useState(false);
  const { open, onCancel, onConfirm, deletingManagersInfo } = props;

  useEffect(() => {
    // NOTE: Reopen alertbox if there is further warnings
    let timeout = setTimeout(() => {
      setShouldShow(open);
    }, 100);

    return () => clearTimeout(timeout);
  }, [open, shouldShowWarning]);

  const combinedMessages = useMemo(() => {
    return [...deletingManagersInfo.categoryManagers, ...deletingManagersInfo.siteManagers];
  }, [deletingManagersInfo]);

  const onConfirmFunc = useCallback(() => {
    if (errorCount !== combinedMessages.length - 1) {
      return () => {
        setShouldShow(false);
        setErroCount(errorCount + 1);
      };
    } else {
      return onConfirm;
    }
  }, [combinedMessages, errorCount]);

  const currentMessage = useMemo(() => {
    return combinedMessages[errorCount];
  }, [errorCount, combinedMessages]);

  if (!open || !shouldShowWarning) {
    return null;
  }

  return (
    <AlertBox
      open
      message={Common.ManagerDeleteWarningMessage(currentMessage)}
      status="warning"
      type={`${Common.Actions.Delete}!`}
      onCancel={onCancel}
      onConfirm={onConfirmFunc()}
    />
  );
}

export default DeleteManagersModal;
