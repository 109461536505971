import { DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getLabelsShippingTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'destinationSiteId',
    label: 'Site',
    value: 'destinationSiteId',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.destinationSite?.name}>
        {row.destinationSite?.name}
      </span>
    ),
  },
  {
    id: 'containerId',
    label: 'Container ID',
    value: 'containerId',
  },
];
