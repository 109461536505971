import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import Import from 'app/i18n/Import';

import { ImportFormFieldProps } from '../../type';
import { IMPORT_FORM_RULES } from '../../utils/rules';
import { getEntityTypeOptions } from './utils';

const ImportEntityTypeField = (props: ImportFormFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  const options = useMemo(() => getEntityTypeOptions(), []);

  return (
    <div className="mb-20 mt-16">
      <Controller
        control={control}
        name="entityType"
        render={({ field, fieldState }) => {
          return (
            <FormSelect
              {...field}
              disabled={disabled}
              error={fieldState.error}
              isRequired={true}
              label={Import.FormLabels.DataType}
              options={options}
            />
          );
        }}
        rules={IMPORT_FORM_RULES.entityType}
      />
    </div>
  );
};

export default ImportEntityTypeField;
