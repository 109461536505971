import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { formatDate } from 'app/utils/date';

export const getLocationsTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'name',
    label: 'Location',
    value: 'name',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    addReadMore: true,
  },
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
  },
  {
    id: 'updatedBy',
    label: 'Updated By',
    value: 'updatedBy',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.updatedBy?.name}>
        {row.updatedBy?.name}
      </span>
    ),
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    value: 'updatedAt',
    sortable: true,
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt)}>
        {formatDate(row.updatedAt)}
      </span>
    ),
  },
];
