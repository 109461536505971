import { Avatar } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { UserStatusEnum } from 'app/types/schema';
import { formatDate } from 'app/utils/date';

export const getUserTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'profilePicture',
    label: 'Image',
    labelAlign: 'center',
    rowAlign: 'center',
    sortable: false,
    value: 'profilePicture',
    valueNode: ({ row }) => (
      <Avatar sx={{ width: 45, height: 45, marginX: 'auto' }} src={row.profilePicture} />
    ),
    width: 100,
  },
  {
    id: 'firstName',
    label: 'First Name',
    value: 'firstName',
    sortable: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    value: 'lastName',
    sortable: true,
  },
  {
    id: 'emailId',
    label: 'Email',
    value: 'emailId',
    sortable: true,
  },
  {
    id: 'roles',
    label: 'User Role',
    value: 'roles',
    valueNode: ({ row }) => {
      const roles = row.roles;
      const roleNames = roles?.length ? Array.from(new Set(roles.map((role: any) => role.name))).join(', ') : '-';
      return (
        <span className="truncate" title={roleNames}>
          {roleNames}
        </span>
      );
    },
  },
  {
    id: 'status',
    label: 'Active',
    labelAlign: 'center',
    rowAlign: 'center',
    value: 'status',
    width: 100,
    valueNode: ({ row }) => {
      return [
        UserStatusEnum.Active,
        UserStatusEnum.Invited,
        UserStatusEnum.EmailNotVerified,
      ].includes(row.status) ? (
        <i aria-hidden="true" className="fa fa-check green" />
      ) : (
        <i aria-hidden="true" className="fa fa-times red" />
      );
    },
  },
  {
    id: 'lastLogin',
    label: 'Last Login',
    value: 'lastLogin',
    sortable: false,
    valueNode: ({ row }) => (
      <span className="truncate" title={row.lastLogin ? formatDate(row.lastLogin) : 'Never'}>
        {row.lastLogin ? formatDate(row.lastLogin) : 'Never'}
      </span>
    ),
  },
];
