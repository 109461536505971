import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SelectOption } from 'app/components/Select/types';
import { useDistinctNetworkAssetsQuery } from 'app/modules/network/graphql/queries/generated/networkAssetDistinctValues';
import { deviceTypeOptions, statusOptions, warrantyStatusOptions } from 'app/modules/network/utils';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import { DistictValueSchema } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import ITAssetReportFilter from '../ITAssetReportFilter';
import { ITAssetReportFormProps, ITAssetReportFormValues } from './types';

const defaultValues: ITAssetReportFormValues = {
  format: 'preview',
};

const ITAssetReportForm = (props: ITAssetReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset } = useForm({ defaultValues });

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const [{ data }] = useDistinctNetworkAssetsQuery({
    variables: {
      filters: {
        values: [
          'batteryManufacturer',
          'biosReleaseDate',
          'biosVersion',
          'graphicsModel',
          'operatingSystemPlatform',
          'storageName',
          'cpuManufacturer',
          'operatingSystem',
          'cpuBrand',
          'motherboardModel',
          'systemModel',
          'systemManufacturer',
        ],
      },
    },
  });

  const options = useMemo(() => {
    const distinctValues = data?.distinctNetworkAssets.distinctValues;

    function transformProperty(property: DistictValueSchema[]): SelectOption[] {
      return property && property.length
        ? property
            .filter((option) => option.value)
            .map((option) => ({
              value: option.value ?? '',
              label: option.value ?? '',
            }))
        : [];
    }

    const distinctOptions = {
      systemModel: transformProperty(distinctValues?.systemModel || []),
      operatingSystem: transformProperty(distinctValues?.operatingSystemPlatform || []),
      batteryManufacturer: transformProperty(distinctValues?.batteryManufacturer || []),
      biosVersion: transformProperty(distinctValues?.biosVersion || []),
      biosReleaseDate: transformProperty(distinctValues?.biosReleaseDate || []),
      motherboardModel: transformProperty(distinctValues?.motherboardModel || []),
      cpuManufacturer: transformProperty(distinctValues?.cpuManufacturer || []),
      cpuBrand: transformProperty(distinctValues?.cpuBrand || []),
      graphicsModel: transformProperty(distinctValues?.graphicsModel || []),
      storageName: transformProperty(distinctValues?.storageName || []),
      systemManufacturer: transformProperty(distinctValues?.systemManufacturer || []),
      warrantyStatus: warrantyStatusOptions,
      deviceType: deviceTypeOptions,
      status: statusOptions,
    };
    return distinctOptions;
  }, [data?.distinctNetworkAssets.distinctValues]);

  return (
    <form className="max-w-[600px] py-[16px] px-[24px]" onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <ITAssetReportFilter control={control} options={options.status} selectName="status" />
        <ITAssetReportFilter
          control={control}
          options={options.deviceType}
          selectName="deviceType"
        />
        <ITAssetReportFilter
          control={control}
          options={options.systemManufacturer}
          selectName="systemManufacturer"
        />
        <ITAssetReportFilter
          control={control}
          options={options.systemModel}
          selectName="systemModel"
        />
        <ITAssetReportFilter
          control={control}
          options={options.operatingSystem}
          selectName="operatingSystem"
        />
        <ITAssetReportFilter
          control={control}
          options={options.batteryManufacturer}
          selectName="batteryManufacturer"
        />
        <ITAssetReportFilter
          control={control}
          label="BIOS Version"
          options={options.biosVersion}
          selectName="biosVersion"
        />
        <ITAssetReportFilter
          control={control}
          options={options.motherboardModel}
          selectName="motherboardModel"
        />
        <ITAssetReportFilter
          control={control}
          label="CPU Manufacturer"
          options={options.cpuManufacturer}
          selectName="cpuManufacturer"
        />
        <ITAssetReportFilter
          control={control}
          label="CPU Brand"
          options={options.cpuBrand}
          selectName="cpuBrand"
        />
        <ITAssetReportFilter
          control={control}
          options={options.graphicsModel}
          selectName="graphicsModel"
        />
        <ITAssetReportFilter
          control={control}
          options={options.storageName}
          selectName="storageName"
        />
        <ITAssetReportFilter
          control={control}
          options={options.warrantyStatus}
          selectName="warrantyStatus"
        />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default ITAssetReportForm;
