import {withRouter} from "app/libs/navigation";
import {RouteComponentProps} from "app/libs/navigation";
import EditInventory from "app/modules/inventory/views/EditInventory";

import InventoriesLayout from "../../modules/inventory/layout";

function EditInventoryPage(props: RouteComponentProps<{ id: string }>) {
    const { match } = props;
    return (
        <InventoriesLayout>
            <EditInventory inventoryId={match.params.id} />
        </InventoriesLayout>
    )
}

export default withRouter(EditInventoryPage);