/* eslint-disable sort-keys */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { cx } from '@emotion/css';
import { Grid } from '@mui/material';
import { Button, TitleBar } from '@procurenetworks/procure-component-library';
import { omit } from 'lodash';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import {
  CompanySchema,
  ContactSchema,
  CreateCompanyInput,
  CreateContactInput,
} from 'app/types/schema';
import Box from 'app/ui-components';

import CompanyForm from '../companyForm';
import { useCreateCompanyMutation } from '../companyForm/graphql/mutations/generated/createCompany';
import { useDeleteCompanyMutation } from '../companyForm/graphql/mutations/generated/deleteCompnay';
import { useUpdateCompanyMutation } from '../companyForm/graphql/mutations/generated/updateCompany';
import ContactForm from '../contactForm';
import { useCreateContactMutation } from '../contactForm/graphql/mutations/generated/createContact';
import { useDeleteContactsMutation } from '../contactForm/graphql/mutations/generated/deleteContact';
import { useUpdateContactMutation } from '../contactForm/graphql/mutations/generated/updateConact';
import ToolBarDuplicate from '../toolbarDuplicate';
import analytics from 'app/analytics';

type FormProps = RouteComponentProps<{ id: string }> & {
  onCloseModal?: () => void;
  handleCancelDuplicate: () => void;
  oldData: Partial<CreateCompanyInput | CreateContactInput> | null;
  newData: Partial<CreateCompanyInput | CreateContactInput> | null;
  form: string;
  isContactModal?: boolean;
  isDuplicate?: boolean;
  isEditDuplicate?: boolean;
  hideActionsEdit?: boolean;
};
const DuplicateCompany: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleCancelDuplicate,
    oldData,
    newData,
    history,
    match,
    form,
    isContactModal,
    isDuplicate,
    onCloseModal: onHandleContactModal,
    isEditDuplicate,
    hideActionsEdit,
  } = props;
  const [duplicateAction, setDuplicateAction] = useState<number | null>(1);
  const urlId = match.params.id;

  useEffect(() => {
    if (!isContactModal && urlId && !isEditDuplicate) {
      handleCancelDuplicate();
    }
  }, [urlId]);

  const oldValues = useMemo(() => {
    return { ...oldData };
  }, [oldData]);
  const newValues = useMemo(() => {
    return { ...newData };
  }, [newData]);

  const {
    trigger: oldFormTrigger,
    control: oldFormControl,
    getValues: oldFormGetValues,
    setValue: oldFormSetValues,
    reset: oldFormReset,
    formState: { errors: oldFormErrors },
    setError: oldFormSetErrors,
  } = useForm({
    reValidateMode: 'onChange',
  });
  const {
    trigger: newFormTrigger,
    control: newFormControl,
    getValues: newFormGetValues,
    setValue: newFormSetValues,
    reset: newFormReset,
    formState: { errors: newFormErrors },
    setError: newFormSetErrors,
  } = useForm({
    reValidateMode: 'onChange',
  });

  const _handleChangeToolbar = useCallback(
    (index: number) => {
      setDuplicateAction(index);
    },
    [setDuplicateAction],
  );

  const _handleCancelDuplicate = useCallback(() => {
    handleCancelDuplicate();
  }, [handleCancelDuplicate]);

  const [{ fetching: onUpdateFetching }, onUpdateCompany] = useUpdateCompanyMutation();
  const [{ fetching: onCreateFetching }, onCreateCompany] = useCreateCompanyMutation();
  const [{ fetching: deleteCompanyLoading }, executeDeleteCompany] = useDeleteCompanyMutation();
  const [{ fetching: onUpdateContactFetching }, onUpdateContact] = useUpdateContactMutation();
  const [{ fetching: onCreateContactFetching }, onCreateContact] = useCreateContactMutation();
  const [{ fetching: deleteContactLoading }, executeDeleteContact] = useDeleteContactsMutation();

  const updateOldData = (oldData: CompanySchema | ContactSchema) => {
    if (form === 'Company') {
      const oldCompanyValue = oldData as CreateCompanyInput;
      const data = {
        companyContactNumber: oldCompanyValue.companyContactNumber,
        companyEmail: oldCompanyValue.companyEmail?.trim(),
        companyFax: oldCompanyValue.companyFax,
        companyName: oldCompanyValue.companyName?.trim(),
        companyWebsite: oldCompanyValue.companyWebsite?.trim(),
        isVendor: oldCompanyValue.isVendor || false,
      };
      onUpdateCompany({ input: { ...data, companyId: oldData?.id } }).then((response) => {
        analytics?.track('Edited', { name: 'Company' });
        if (response.data?.updateCompany.company?.id) {
          SnackbarService.show({
            message: Contact.SuccessMessages.CompanyUpdated,
          });
          handleCancelDuplicate();
          history.replace(routes.Companies(response.data?.updateCompany.company?.id));
        } else {
          console.error('[Create Compnay] Failed', response);
        }
      });
    } else {
      const oldContactValue = oldData as CreateContactInput;
      const data = {
        companyId: oldContactValue?.companyId,
        addressId: oldContactValue?.addressId ?? '',
        email: oldContactValue?.email,
        firstName: oldContactValue?.firstName,
        lastName: oldContactValue?.lastName,
        title: oldContactValue?.title,
        officeContactNumber: oldContactValue.officeContactNumber,
        homeContactNumber: oldContactValue.homeContactNumber,
        mobileContactNumber: oldContactValue.mobileContactNumber,
        fax: oldContactValue?.fax,
      };
      console.log('Duplicate Form updateOldData oldData?.id', oldData, data);
      onUpdateContact({ input: { ...data, contactId: oldData?.id } }).then((response) => {
        analytics?.track('Edited', { name: 'Contact' });
        if (response.data?.updateContact.contact?.id) {
          SnackbarService.show({
            message: Contact.SuccessMessages.ContactUpdated,
          });
          if (onHandleContactModal) {
            return onHandleContactModal();
          }
          handleCancelDuplicate();
          history.replace(routes.Contacts(response.data?.updateContact.contact?.id));
        } else {
          console.error('[Update Category] Failed', response);
        }
      });
    }
  };
  const createNew = (newData: CreateCompanyInput | CreateContactInput) => {
    if (form === 'Company') {
      onCreateCompany({
        input: newData as CreateCompanyInput,
      }).then((response) => {
        if (response.data?.createCompany.company?.id) {
          SnackbarService.show({
            message: Contact.SuccessMessages.CompanyCreated,
          });
          handleCancelDuplicate();
          history.replace(routes.Companies(response.data?.createCompany.company?.id));
        } else {
          console.error('[Create Company] Failed', response);
        }
      });
    } else {
      onCreateContact({
        input: newData as CreateContactInput,
      }).then((response) => {
        if (response.data?.createContact.contact?.id) {
          SnackbarService.show({
            message: Contact.SuccessMessages.ContactCreated,
          });
          if (onHandleContactModal) {
            return onHandleContactModal();
          }
          handleCancelDuplicate();
          history.replace(routes.Contacts(response.data?.createContact.contact?.id));
        } else {
          console.error('[Create Contact] Failed', response);
        }
      });
    }
  };
  const _handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const isOldFormValid = await oldFormTrigger();
      const isNewFormValid = await newFormTrigger();
      // let result;
      const oldFormData = oldFormGetValues();
      oldFormData.id = (oldData as CompanySchema | ContactSchema).id;

      const newData = omit(newFormGetValues(), 'id');

      //loader
      //setIsLoader(true)
      if (isOldFormValid && isNewFormValid) {
        try {
          switch (duplicateAction) {
            case 1: {
              // Keep Old
              // update old record
              updateOldData(oldFormData as CompanySchema | ContactSchema);
              break;
            }
            case 2: {
              // Update old record with new data
              newData.id = oldFormData?.id;
              updateOldData(newData as CompanySchema | ContactSchema);
              break;
            }
            default: {
              if (handleCancelDuplicate) {
                handleCancelDuplicate();
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    [duplicateAction, handleCancelDuplicate],
  );

  const renderDuplicateForm = (
    control: Control,
    reset: any,
    defaultValues: any,
    setValue: UseFormSetValue<CreateCompanyInput | CreateContactInput>,
    getValues: UseFormSetValue<CreateCompanyInput | CreateContactInput>,
    errors: FieldErrors,
    setError: UseFormSetError<CreateCompanyInput | CreateContactInput>,
  ) => {
    if (form === 'Contact') {
      return (
        <ContactForm
          hideActions
          control={control}
          defaultValues={defaultValues}
          errors={errors}
          formClassName="w-full ml-0 p-20"
          getValues={getValues as UseFormGetValues<CreateContactInput>}
          hideActionsEdit={hideActionsEdit}
          isContactModal={isContactModal}
          isDuplicate={isDuplicate}
          reset={reset}
          setErrors={setError as UseFormSetError<CreateContactInput>}
          setValue={setValue as UseFormSetValue<CreateContactInput>}
          onCheckDuplicate={() => {
            return false;
          }}
        />
      );
    } else {
      return (
        <CompanyForm
          hideActions
          hideTitle
          isDuplicate
          control={control}
          defaultValues={defaultValues}
          errors={errors}
          formClassName="w-full ml-0 p-20"
          getValues={getValues as UseFormGetValues<CreateCompanyInput>}
          hideActionsEdit={hideActionsEdit}
          reset={reset}
          setErrors={setError as UseFormSetError<CreateCompanyInput>}
          setValue={setValue as UseFormSetValue<CreateCompanyInput>}
          onCheckDuplicate={() => {
            return false;
          }}
        />
      );
    }
  };

  return (
    <Box>
      {!isContactModal && (
        <Box className="min-w-full">
          {form === 'Contact' ? (
            <TitleBar title={Contact.DuplicateContact} />
          ) : (
            <TitleBar title={Contact.DuplicateCompany} />
          )}
        </Box>
      )}
      <ToolBarDuplicate active={duplicateAction} onChange={_handleChangeToolbar} />
      {/* {
        isLoader&&<div className='loader_style'><Loader /></div>
      } */}

      <Box>
        <form>
          <Box className={cx(`mb-20 mr-20 border-2 border-white`)}>
            {/* <Grid container spacing={2}> */}
            <Box className="flex flex-wrap gap-[24px] md:flex-nowrap lg:flex-nowrap xl:flex-nowrap">
              {duplicateAction !== 2 && (
                // <Grid item xs={6}>
                <Box className="w-[320px] md:min-w-[350px] lg:min-w-[400px] xl:max-w-[532px]">
                  <Box
                    className={cx(
                      `border-2 ${duplicateAction === 1 ? 'border-[#66cc33]' : 'border-white'}`,
                    )}>
                    {renderDuplicateForm(
                      oldFormControl,
                      oldFormReset,
                      oldValues,
                      oldFormSetValues as unknown as UseFormSetValue<
                        CreateCompanyInput | CreateContactInput
                      >,
                      oldFormGetValues,
                      oldFormErrors,
                      oldFormSetErrors as unknown as UseFormSetError<
                        CreateCompanyInput | CreateContactInput
                      >,
                    )}
                  </Box>
                </Box>
                // </Grid>
              )}
              {/* <Grid item xs={6}> */}
              <Box className="w-[320px] md:min-w-[350px] lg:min-w-[400px] xl:max-w-[532px]">
                <Box
                  className={cx(
                    `border-2 ${duplicateAction === 2 ? 'border-[#66cc33]' : 'border-white'}`,
                  )}>
                  {renderDuplicateForm(
                    newFormControl,
                    newFormReset,
                    newValues,
                    newFormSetValues as unknown as UseFormSetValue<
                      CreateCompanyInput | CreateContactInput
                    >,
                    newFormGetValues,
                    newFormErrors,
                    newFormSetErrors as unknown as UseFormSetError<
                      CreateCompanyInput | CreateContactInput
                    >,
                  )}
                </Box>
                {/* </Grid>
            </Grid> */}
              </Box>
            </Box>
          </Box>
          <Box className={'mt-20 mb-20 flex justify-end space-x-8 pr-20'}>
            <Button
              disabled={duplicateAction === null}
              loading={
                onUpdateFetching ||
                onCreateFetching ||
                deleteCompanyLoading ||
                onUpdateContactFetching ||
                onCreateContactFetching ||
                deleteContactLoading
              }
              theme="success"
              onClick={(e) => _handleSubmit(e)}>
              Save
            </Button>
            <Button onClick={_handleCancelDuplicate}>Cancel</Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default withRouter(DuplicateCompany);
