import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';

import { DeliveryDetailInput } from '../../../../../../components/OrderDeliveryDetailForm/types';
import moment from 'moment/moment';

export function getDeliveryDetailsDefaultValue(
  currentUser: UserProfileFragment,
): DeliveryDetailInput {
  return {
    orderId: '',
    orderDate: moment(new Date().toISOString()).format('MM/DD/YYYY'),
    title: '',
    name: currentUser.firstName + ' ' + currentUser.lastName,
    email: currentUser.emailId,
    dueDate: '',
    destinationSiteId: '',
    departmentId: '',
    deliverToId: '',
    billToSiteId: '',
  };
}
