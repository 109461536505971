import '../style.css';
import { Button } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import Wishlist from 'app/i18n/Wishlist';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Box from 'app/ui-components/Box';

const ConfirmationView = (props: RouteComponentProps) => {
  const { history } = props;
  const IMAGE_URL = `${process.env.PUBLIC_URL}/images/logo.png`;
  return (
    <>
      <Box className="space-y-30 mt-[14.1vw] mb-24 min-w-[280px] max-w-[900px] p-20 sm:mt-[14.1vh-sm] lg:mt-[14.1vh-lg]">
        <Box>
          <h1 className="heading text-[gray]">{Wishlist.ConfimationView.ThankYou}</h1>
          <h1 className="heading text-[#66CCFF]">{Wishlist.ConfimationView.SupportMatters}</h1>
        </Box>
        <p className="content">
          {Wishlist.ConfimationView.Paragraph.Supporting}
          <b> Procure</b>
        </p>
        <p className="content">
          {Wishlist.ConfimationView.Paragraph.WebsiteSupoortEfforts}
          <br />
          {Wishlist.ConfimationView.Paragraph.Reminder}
        </p>

        <p className="content">{Wishlist.ConfimationView.Paragraph.WhatNext}</p>
        <Box className="mt-32 flex flex-col justify-start space-y-12 md:flex-row md:space-y-0 md:space-x-32">
          <Button
            classes="min-h-[51px] bg-gray md:w-[160px]"
            onClick={() => {
              history.push(routes.WishlistReferOther());
            }}>
            {Wishlist.FormActions.ReferOther}
          </Button>
          <Button
            classes="min-h-[51px] md:w-[160px]"
            theme="success"
            onClick={() => {
              history.push(routes.WishlistCreateList());
            }}>
            {Wishlist.FormActions.CreateList}
          </Button>
        </Box>
        <img alt="procure-logo" className="mt-[40px] h-[35px] w-[141px]" src={IMAGE_URL} />
      </Box>
    </>
  );
}

export default withRouter(ConfirmationView);
