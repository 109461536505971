import { useCallback } from 'react';
import { API_URL } from 'app/consts/config';
import useRest, { FetchingStatus } from 'app/hooks/useRest';

import { ForgotPasswordInput } from '../types';

const useForgotPassword = () => {
  const [status, error, data, onExecute] = useRest(`${API_URL}/auth/password/forgot`);

  const execute = useCallback(
    async (values: ForgotPasswordInput) => onExecute({ body: JSON.stringify(values) }),
    [onExecute],
  );

  return { fetching: status === FetchingStatus.Fetching, error, data, execute };
};

export default useForgotPassword;
