import { AlignItems, Direction, JustifyContent } from './types';

export const DIRECTION_CLASSNAME: { [key in Direction]: string } = {
  row: 'flex-row',
  col: 'flex-col',
  'row-reverse': 'flex-row-reverse',
  'col-reverse': 'flex-col-reverse',
};

export const ALIGN_ITEMS_CLASSNAME: { [key in AlignItems]: string } = {
  stretch: 'items-stretch',
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
  baseline: 'items-baseline',
};

export const JUSTIFY_CONTENT_CLASSNAME: { [key in JustifyContent]: string } = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
};
