import { useCallback, useState } from 'react';
import { request } from 'app/utils/request';

export enum FetchingStatus {
  NotStarted = 'NOT_STARTED',
  Fetching = 'FETCHING',
  Done = 'DONE',
  Error = 'ERROR',
}

const FETCH_API_OPTIONS = {
  method: 'POST',
  headers: { 'content-type': 'application/json; charset=utf-8' },
};

function useRest(
  endpoint: string,
  fetchOptions: object = FETCH_API_OPTIONS,
  requestConfig?: { auth: boolean },
): [FetchingStatus, object | null, object | null, (props: any) => Promise<any>] {
  const [status, setStatus] = useState(FetchingStatus.NotStarted);
  const [data, setData] = useState(null);
  const [error, setError] = useState<Error | null>(null);

  const execute = useCallback(
    async (requestFetchOptions?: object) => {
      setStatus(FetchingStatus.Fetching);
      let dataResponse;
      const newFetchOptions = {
        ...fetchOptions,
        ...requestFetchOptions,
      };
      try {
        const response = await request(endpoint, newFetchOptions, {
          auth: requestConfig?.auth || false,
        });

        dataResponse = await response.json();
        if (dataResponse.error) {
          setError(dataResponse.error);
        }
        setData(dataResponse);
        setStatus(FetchingStatus.Done);
        return dataResponse;
      } catch (error) {
        setError(error as Error);
        setStatus(FetchingStatus.Done);
        console.error('Error in useRest:', error);
        return null;
      }
    },
    [endpoint, fetchOptions],
  );

  return [status, error, data, execute];
}

export default useRest;
