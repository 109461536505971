import { useCallback, useState } from 'react';

interface State {
  open: boolean;
  data: {
    successCount: number;
    errorCount: number;
  } | null;
}
const useDeleteErrorDialogState = () => {
  const [state, _setState] = useState<State>({
    open: false,
    data: null,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      return _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  return {
    state,
    setState,
  };
};

export type UseDeleteErrorDialogStateReturnType = ReturnType<typeof useDeleteErrorDialogState>;

export default useDeleteErrorDialogState;
