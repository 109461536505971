import { Controller } from 'react-hook-form';
import FormUserSelect from 'app/components/ProcureForm/FormUserSelect';
import Orders from 'app/i18n/Orders';

import { OrderDeliveryDetailsFieldProps } from '../../types';
import { ORDER_DELIVERY_DETAIL_FORM_RULES } from '../../utils/rules';

const OrderDeliverToField = (props: OrderDeliveryDetailsFieldProps) => {
  const { formState, isRequired, disabled } = props;
  const { control } = formState;
  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="deliverToId"
        render={({ field, fieldState }) => (
          <FormUserSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            isRequired={isRequired}
            label={Orders.FormLabels.DeliverTo}
            name="deliverToId"
            size="medium"
            formLabel={Orders.FormLabels.DeliverTo}
          />
        )}
        rules={ORDER_DELIVERY_DETAIL_FORM_RULES.deliverToId}
      />
    </div>
  );
};

export default OrderDeliverToField;
