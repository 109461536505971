import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LocationFilterQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.LocationFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type LocationFilterQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string } }> } };


export const LocationFilterDocument = gql`
    query locationFilter($filters: LocationFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  locations(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useLocationFilterQuery(options?: Omit<Urql.UseQueryArgs<LocationFilterQueryVariables>, 'query'>) {
  return Urql.useQuery<LocationFilterQuery>({ query: LocationFilterDocument, ...options });
};