import AdminLayout from 'app/components/AdminLayout';
import Organizations from 'app/modules/organizations/views/Organizations';

function OrganizationsPage() {
  return (
    <AdminLayout>
      <Organizations />
    </AdminLayout>
  );
}

export default OrganizationsPage;
