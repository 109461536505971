import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import Assets from 'app/i18n/Assets';

import { AssetFieldProps } from '../../types';
import AssetCpuBrandField from '../AssetCpuBrandField';
import AssetIpAddressField from '../AssetIpAddressField';
import AssetMacAddressField from '../AssetMacAddressField';

const AssetITAssetAccordion = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const isITAsset = !!formState.itemData?.macAddress;

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      {isITAsset ? (
        <div className="mb-20">
          <Accordion
            className="rounded rounded-[8px] border-0 shadow-none before:content-none "
            expanded={isExpanded}>
            <div className="flex items-center">
              <AccordionSummary
                className="flex !min-h-[50px] flex-auto items-center rounded-[8px]"
                expandIcon={<GridExpandMoreIcon />}
                sx={{
                  border: '1.5px solid lightgrey',
                  '& .Mui-expanded': {
                    margin: '0px !important',
                  },
                }}
                onClick={handleClick}
                onKeyDown={handleClick}>
                <Typography>{Assets.FormLabels.ITAssetDetails}</Typography>
              </AccordionSummary>
            </div>
            <AccordionDetails>
              <AssetMacAddressField disabled={true} formState={formState} />
              <AssetIpAddressField disabled={true} formState={formState} />
              <AssetCpuBrandField disabled={true} formState={formState} />
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}
    </>
  );
};

export default AssetITAssetAccordion;
