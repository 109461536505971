import moment from 'moment';

export enum DateFormatEnum {
  FullDate = 'L LT',
  Date = 'MM/DD/YYYY',
  YYYY_MM_DD = 'YYYY-MM-DD',
  UTC = 'UTC',
}

export function formatDate(date: string, format = DateFormatEnum.FullDate): string {
  switch (format) {
    case DateFormatEnum.FullDate: {
      return moment(date).format(format);
    }
    case DateFormatEnum.Date: {
      return moment(date).format(DateFormatEnum.Date);
    }
    case DateFormatEnum.YYYY_MM_DD: {
      return moment(date).format(DateFormatEnum.YYYY_MM_DD);
    }
    case DateFormatEnum.UTC: {
      return new Date(date).toISOString();
    }
    default: {
      return date;
    }
  }
}

export function addDays(date: Date | string, days: number) {
  return moment(date).add(days, 'days');
}

export function removeDays(date: Date | string, days: number) {
  return moment(date).subtract(days, 'days');
}
