import NetworkAssetLayout from 'app/components/NetworkAssetLayout';
import NetworkDiscoveryTools from 'app/modules/network/view/networkDiscoveryTool';

function NetworkDiscoveryTool() {
  return (
    <NetworkAssetLayout>
      <NetworkDiscoveryTools />
    </NetworkAssetLayout>
  );
}

export default NetworkDiscoveryTool;
