import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ItemTypeEnum,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { useAccessControl } from '../../../../../../components/AccessControl';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import { InventoryFormProps } from '../../types';

const EditDeleteActionButtons = (props: InventoryFormProps) => {
  const { formState, disabled } = props;

  const { workspacePermissions } = useCurrentUser();

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Inventory,
  );
  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Inventory,
  );
  const canEditOrDelete = canEdit || canDelete;

  const { editable, makeEditable, onDeleteConfirmation, itemData } = formState;

  if (editable || !canEditOrDelete) {
    return null;
  }

  return (
    <Box className="mb-20 flex justify-start gap-[16px]">
      {canEdit ? (
        <Button
          disabled={
            disabled ||
            Boolean(
              itemData?.entityIdInSourceTenant && itemData?.type === ItemTypeEnum.InventoryKit,
            )
          }
          theme="info"
          onClick={() => {
            makeEditable();
          }}
          classes="min-w-[94px] h-[44px]">
          {Common.Actions.Edit}
        </Button>
      ) : undefined}
      {canDelete ? (
        <Button
          disabled={disabled}
          theme="danger"
          onClick={onDeleteConfirmation}
          classes="min-w-[94px] h-[44px]">
          {Common.Actions.Delete}
        </Button>
      ) : undefined}
    </Box>
  );
};

export default EditDeleteActionButtons;
