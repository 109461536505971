import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type AddressesTableRowFragment = { __typename?: 'AddressSchema', id: string, addressLineOne: string, addressLineTwo?: string | null, addressName: string, city: string, companyId: string, state: string, country?: string | null, zipCode: string };

export const AddressesTableRowFragmentDoc = gql`
    fragment AddressesTableRow on AddressSchema {
  id
  addressLineOne
  addressLineTwo
  addressName
  city
  companyId
  state
  country
  zipCode
}
    `;