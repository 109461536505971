import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import Import from 'app/i18n/Import';
import EntityManager from 'app/modules/components/EntityManager';

import ImportAttachmentField from './components/ImportAttachmentField';
import ImportEntityTypeField from './components/ImportEntityTypeField';
import { UseImportFormReturnType } from './hook/useImportFormState';

interface Props {
  formState: UseImportFormReturnType['formState'];
  onNext: (values: any) => void;
  disabled?: boolean;
}

const ImportForm = (props: Props) => {
  const { formState, onNext, disabled } = props;
  const { handleSubmit, resetImportForm, attachmentInputProps } = formState;

  const onFormSubmit = useMemo(() => handleSubmit(onNext), [handleSubmit, onNext]);
  return (
    <>
      <EntityManager.Title documentTitle={Import.Import} title={Import.Import} />
      <ImportEntityTypeField disabled={disabled} formState={formState} />
      <ImportAttachmentField disabled={disabled} formState={formState} />
      <div>
        <Grid container justifyContent="end" gap="16px">
          <Grid item>
            <Button
              disabled={disabled || attachmentInputProps.isUploading}
              onClick={resetImportForm}
              classes="min-w-[120px] h-[44px]">
              {Common.Actions.Cancel}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={attachmentInputProps.isUploading}
              loading={disabled}
              theme="success"
              onClick={onFormSubmit}
              classes="min-w-[120px] h-[44px]">
              {Common.Actions.Next}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ImportForm;
