import { GridColDef } from '@procurenetworks/procure-component-library';
import Reports from 'app/i18n/Reports';
import { Link } from 'app/libs/navigation';
import { formatDate } from 'app/utils/date';

interface Params {
  auditReportDetailsRoute: (id: string) => string;
}

export const getAuditsTableColumns = ({ auditReportDetailsRoute }: Params): GridColDef[] => [
  {
    headerName: 'Audit Date',
    field: 'createdAt',
    width: 500,
    sortable: false,
    renderCell: ({ row }) => (
      <Link
        className="sku-link truncate"
        title={formatDate(row.createdAt)}
        to={auditReportDetailsRoute(row.id)}>
        {formatDate(row.createdAt)}
      </Link>
    ),
  },
  {
    headerName: Reports.Site,
    field: 'site.name',
    width: 500,
    sortable: false,
    renderCell: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
  },
  {
    headerName: 'User',
    width: 500,
    sortable: false,
    field: 'createdBy.name',
    renderCell: ({ row }) => (
      <span className="truncate" title={row.createdBy?.name}>
        {row.createdBy?.name}
      </span>
    ),
  },
];
