import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import Import from 'app/i18n/Import';

import FormSelect from '../../../../../../components/Form/FormSelect';
import {
  composeValidators,
  textValidator,
} from '../../../../../../components/Form/utils/validators';
import { CreateJobPreprocessorEntityMap, Maybe } from '../../../../../../types/schema';
import Box from '../../../../../../ui-components/Box';
import { filterFormValuesWithCurrentMapField, findEntityLabelsFromFormValues } from './utils';

interface Props {
  control: any;
  entity: CreateJobPreprocessorEntityMap;
  disabled?: boolean;
  items: {
    label: string;
    value: string;
  }[];
  formValues: {
    [key: string]: string;
  };
}

const MappingField = (props: Props) => {
  const { control, entity, items, disabled, formValues } = props;
  const { entityField, entityLabel, isRequired } = entity;

  const options = useMemo(() => {
    const currentItemValue = formValues[entityField];
    const values = _.values(formValues).filter((item) => item && item !== currentItemValue);
    return _.filter(items, (item) => !values.includes(item.value));
    // return items;
  }, [items, formValues, entityField]);

  const rules = useMemo(() => {
    if (!isRequired) return undefined;

    return {
      validate: textValidator(Import.FormValidationMessages.MappingFieldRequired(entityLabel)),
    };
  }, [isRequired, entityLabel]);

  return (
    <Box className="mb-16">
      <Controller
        control={control}
        name={entityField}
        render={({ field, fieldState }) => {
          return (
            <FormSelect
              {...field}
              disabled={disabled}
              error={fieldState.error}
              isRequired={isRequired}
              label={entityLabel}
              options={options}
              placeholder={entityLabel}
            />
          );
        }}
        rules={rules}
      />
    </Box>
  );
};

export default MappingField;
