import React, { forwardRef, useCallback, useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import Box from 'app/ui-components/Box';
import { Size } from 'app/types/button';
import { OrderRequestTypeEnum } from 'app/types/schema';

interface Props {
  className?: string;
  disabled?: boolean;
  value: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  placeholder?: string;
  size?: Size;
  name?: string;
}

const orderTypes = [
  { id: OrderRequestTypeEnum.Internal, name: 'Internal' },
  { id: OrderRequestTypeEnum.External, name: 'External' },
];

const getOptionLabel = (option: any) => option.name;

const FormOrderTypeSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, disabled, value, onBlur, onChange, placeholder = '', size, name } = props;

  const [selectedValue, setSelectedValue] = useState<any>(null);

  useEffect(() => {
    const selected = orderTypes.find((type) => type.id === value);
    if (selected) {
      setSelectedValue(selected);
    }
  }, [value]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: any) => {
      setSelectedValue(option);
      if (option) {
        onChange?.(option.id);
      } else {
        onChange?.('');
      }
    },
    [onChange],
  );

  return (
    <Box ref={ref} className={`flex flex-col ${className}`}>
      <Autocomplete
        className={`mt-20 w-[120px] rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none`}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        options={orderTypes}
        label=""
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        sx={{
          '& .MuiInputBase-root': {
            fontWeight: '600 !important',
          },
          '& input::placeholder': {
            fontWeight: '600',
            opacity: 1,
          },
        }}
        size={size}
        value={selectedValue}
        onChange={onValueChange}
        onBlur={onBlur}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      />
    </Box>
  );
});

export default FormOrderTypeSelect;
