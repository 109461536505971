import { useCallback, useMemo } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Box from 'app/ui-components';
import PageTitle from 'app/ui-components/Title';

import LoginHeader from '../Login/components/LoginHeader';
import SetPasswordForm from './components/SetPasswordForm';
import useSetPassword from './hooks/useSetPassword';
import { SetPasswordInput, SetPasswordResponse } from './types';

function SetPassword(props: RouteComponentProps) {
  const { history } = props;

  const token = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token') || '';
  }, []);

  const { fetching, execute } = useSetPassword();

  const onSetPassword = useCallback(
    async (props: Pick<SetPasswordInput, 'password'>) => {
      execute({ ...props, token }).then((result: SetPasswordResponse) => {
        if (result.success) {
          SnackbarService.show({
            message: 'Your password has been changed. You can now login with your new password.',
          });
          history.push('/login');
        } else {
          SnackbarService.showError({
            message: result.errorCode || 'Some error occurred. Please try again later.',
          });
        }
      });
    },
    [execute, token, history],
  );

  return (
    <>
      <LoginHeader />
      <PageTitle title="Set Password" />
      <Box className="w-100% flex h-full items-center justify-center bg-grey-100">
        <Box className="mx-auto mt-[75px] mb-[0px] min-w-[486px] max-w-[400px] space-y-40  rounded-[16px] bg-white px-20 py-20 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),0px_1px_3px_0px_rgba(16,24,40,0.10)]">
          <SetPasswordForm disabled={fetching} onSubmit={onSetPassword} />
        </Box>
      </Box>
    </>
  );
}

export default withRouter(SetPassword);
