import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ValuesInEntitiesSelectQueryVariables = Types.Exact<{
  inputs: Types.GetValuesInEntitiesInput;
}>;


export type ValuesInEntitiesSelectQuery = { __typename?: 'Query', valuesInEntities: { __typename?: 'GetValuesInEntitiesPayload', payload: Array<{ __typename?: 'GetValuesInEntitiesConfig', key: string, entities: Array<{ __typename?: 'ValuesInEntitiesSchema', id: string, name: string, type: Types.ValuesInEntitiesSchemaTypeEnum }> }> } };


export const ValuesInEntitiesSelectDocument = gql`
    query valuesInEntitiesSelect($inputs: GetValuesInEntitiesInput!) {
  valuesInEntities(input: $inputs) {
    payload {
      key
      entities {
        id
        name
        type
      }
    }
  }
}
    `;

export function useValuesInEntitiesSelectQuery(options: Omit<Urql.UseQueryArgs<ValuesInEntitiesSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<ValuesInEntitiesSelectQuery>({ query: ValuesInEntitiesSelectDocument, ...options });
};