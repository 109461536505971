import Contact from 'app/i18n/Contact';

import {
  composeValidators,
  textMaxLengthValidator,
  textValidator,
  zipCodeValidator,
} from '../../../components/Form/utils/validators';
export const INVENTORY_FORM_RULES = {
  companyId: {
    validate: textValidator(Contact.FormValidationMessages.CompanySelectionRequired),
  },
  companyName: {
    validate: composeValidators(
      textValidator(Contact.FormValidationMessages.CompanyNameRequired),
      textMaxLengthValidator(Contact.FormValidationMessages.CompanyNameRequired, 30),
    ),
  },
  firstName: {
    validate: composeValidators(
      textValidator(Contact.FormValidationMessages.FirstNameRequired),
      textMaxLengthValidator(Contact.FormValidationMessages.FirstNameMaxAllowed, 30),
    ),
  },
  lastName: {
    validate: composeValidators(
      textValidator(Contact.FormValidationMessages.LastNameRequired),
      textMaxLengthValidator(Contact.FormValidationMessages.LastNameMaxAllowed, 30),
    ),
  },
  address: {
    validate: textValidator(Contact.FormValidationMessages.AddressRequired),
  },
  addressName: {
    validate: textValidator(Contact.FormValidationMessages.AddressNameRequired),
  },
  addressLine1: {
    validate: textValidator(Contact.FormValidationMessages.AddressLine1Required),
  },
  addressLine2: {
    validate: textValidator(Contact.FormValidationMessages.AddressLine2Required),
  },
  zipCode: {
    validate: composeValidators( 
      textValidator(Contact.FormValidationMessages.ZipCodeRequired),
      zipCodeValidator(Contact.FormValidationMessages.ZipCodeInvalid))
  },
  city: {
    validate: textValidator(Contact.FormValidationMessages.CityRequired),
  },

  state: {
    validate: textValidator(Contact.FormValidationMessages.StateRequired),
  },
};
