import { cx } from '@emotion/css';

type Props = React.ComponentProps<'label'> & { isRequired?: boolean; inline?: boolean };

function FormLabel(props: Props) {
  const { isRequired, children, inline, className, ...rest } = props;

  if (inline) {
    return (
      <span>
        {children} {isRequired && <span className="text-[14px] text-red-800">*</span>}
      </span>
    );
  }

  return (
    <label className={cx('text-[14px] font-medium text-grey-800', className)} {...rest}>
      {children} {isRequired && <span className="text-red-800">*</span>}
    </label>
  );
}

export default FormLabel;
