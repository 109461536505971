import { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

import {
  AssetItemReminderCronEnum,
  AssetReminderInput,
} from '../../components/AssetReminderForm/types';

export const DEFAULT_VALUES: AssetReminderInput = {
  assetItemId: '',
  note: '',
  startReminderAt: '',
  cron: AssetItemReminderCronEnum.Week,
  recurringInterval: 1,
  notifyUserIds: [],
  isMaintenance: false,
};

interface Props {
  reminders?: AssetReminderInput[];
  editable?: boolean;
}

interface State {
  openAddReminder: boolean;
  openViewReminder: boolean;
  reminders: AssetReminderInput[];
  reminderList: AssetReminderInput[];
}

const useAssetReminderState = (props?: Props) => {
  const { reminders, editable } = props || {};
  const [state, _setState] = useState<State>({
    openAddReminder: false,
    openViewReminder: false,
    reminders: [],
    reminderList: [],
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  useEffect(() => {
    if (reminders) {
      const _reminders = cloneDeep(reminders);
      setState({
        reminders: [...(_reminders as AssetReminderInput[])],
        reminderList: [...(_reminders as AssetReminderInput[])],
      });
    }
  }, [editable, reminders, reminders?.length, setState]);

  return {
    state,
    setState,
  };
};

export type UseAssetReminderStateReturnType = ReturnType<typeof useAssetReminderState>;

export default useAssetReminderState;
