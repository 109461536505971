import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import Network from 'app/i18n/Network';
import { AssetWithColorType, warrantyDetails } from 'app/modules/network/types';
import { defaultGridColumn } from 'app/modules/network/utils';

import DonutGraph from './DonutGraph';
type WarrantyType = {
  setWarrantyState: any;
  assetWithColor: AssetWithColorType;
  warrantyState: warrantyDetails;
  defaultValue: any;
};

export default function WarrantyTypes(props: WarrantyType) {
  const { setWarrantyState, assetWithColor, warrantyState, defaultValue } = props;
  return (
    <>
      <Card
        className="rounded-[12px] border-[1px] border-grey-300 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),_0px_1px_3px_0px_rgba(16,24,40,0.10)]"
        sx={{
          mt: 5,
          p: 5,
          textAlign: 'center',
          color: 'gray',
          boxShadow: '0 2px 2px 0',
        }}>
        <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
          <Grid container spacing={3}>
            {warrantyState?.labels &&
              warrantyState.labels.length &&
              warrantyState?.labels.map((item, index) => {
                return (
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Typography className="text-grey800 mb-[8px] text-[14px] font-medium">
                      {item.label}
                    </Typography>
                    <Typography
                      style={{
                        color: warrantyState.color?.length ? warrantyState.color[index] : '',
                        fontSize: '36px',
                        fontWeight: 500,
                      }}>
                      {item.value}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: '40px' }}>
          <Grid container>
            <Grid item lg={4} md={6} sm={12} xl={3} xs={12}>
              <DonutGraph
                chartData={assetWithColor}
                colors={['var(--red900)', 'var(--orange900)', 'var(--green900)', 'var(--blue800)']}
                subheader=""
                title={Network.AssetSummary}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={12} xl={6} xs={12}>
              <Grid container columns={defaultGridColumn}>
                {assetWithColor?.length
                  ? assetWithColor.map((asset, index) => {
                      return (
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: 'grey.300',
                              marginLeft: '30px',
                              marginRight: '30px',
                              marginBottom: '20px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setWarrantyState(asset?.warranty || defaultValue);
                            }}>
                            <Stack
                              alignItems="center"
                              direction="row"
                              justifyContent="space-between">
                              <Typography
                                alignSelf="flex-start"
                                color={asset.color}
                                variant="subtitle1">
                                {asset.label}
                              </Typography>
                              <Typography color={asset.color} ml="auto" variant="subtitle1">
                                {asset.value}
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} sm={12} xl={3} xs={12}>
              {assetWithColor?.length ? (
                <DonutGraph
                  chartData={warrantyState.labels || []}
                  colors={[
                    'var(--red900)',
                    'var(--orange900)',
                    'var(--green900)',
                    'var(--blue800)',
                  ]}
                  subheader=""
                  title={Network.AssetSummary}
                />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
