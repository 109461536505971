import { useCallback, useMemo, useState } from 'react';

import { ORDER_INSERT_TYPE } from '../../type';
import { UseOrderRequestFormReturnType } from '../useOrderRequestForm';

interface Props {
  insertItemType: ORDER_INSERT_TYPE;
  formState: UseOrderRequestFormReturnType['formState'];
}

const useInsertItemChange = (props: Props) => {
  const { insertItemType, formState } = props;
  const { actions, itemType } = formState;
  const { onInsertItemTypeChange } = actions;

  const [inputRef, setInputRef] = useState<HTMLElement | undefined>();

  const isInsertItemTypeSame = useMemo(() => {
    return itemType === insertItemType;
  }, [itemType, insertItemType]);

  const onInsertItemChange = useCallback(() => {
    onInsertItemTypeChange(insertItemType);
    inputRef?.focus();
  }, [insertItemType, onInsertItemTypeChange, inputRef]);

  return {
    inputRef,
    setInputRef,
    onInsertItemChange,
    isInsertItemTypeSame,
  };
};

export type UseInsertItemChangeReturnType = ReturnType<typeof useInsertItemChange>;

export default useInsertItemChange;
