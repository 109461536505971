import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { processSearch } from 'app/utils/processSearch';
import { matchingIndex, truncateString } from 'app/utils/truncate';

import { TransactionEntityTypeEnum, TransactionStatusEnum, TransactionSubTypeEnum, TransactionTypeEnum } from '../../../../../../types/schema';
import { useCheckInTransactionTableQuery } from '../../graphql/query/generated/checkInTransactionTable';
import { CheckInAssetRowType } from '../useCheckInAssetsState';

const useSearchSuggestion = () => {

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>([]);

  const [{ data }] = useCheckInTransactionTableQuery({
    variables: {
      filters: {
        search: searchedValue,
        types: [TransactionTypeEnum.Move],
        subTypes: [TransactionSubTypeEnum.Checkout],
        statuses: [TransactionStatusEnum.Pending],
        entityTypes: [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit],
      },
    },
  });

  useEffect(() => {
    const result = data?.transactions?.edges?.map((a) => {
      const item = a.node as CheckInAssetRowType;

      const updatedItem = {
        ...item, 
        title: item.entity?.title || "",
        sku: item.entity?.sku || "",
        description: item.entity?.description || ""
      }
      
      const {result, searchBy} = processSearch(["title","sku","description"], searchedValue, updatedItem)
      
      return {
        label: searchBy || item.entity?.title || "",
        id: item.id,
        value: result || item.entity?.title || ""
      };
    });
    setSearchSuggestions(result || []);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
