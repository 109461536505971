import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderDetailsTypeQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.OrderRequestFilters>;
}>;


export type OrderDetailsTypeQuery = { __typename?: 'Query', orderRequests: { __typename?: 'OrderRequestConnection', edges?: Array<{ __typename?: 'OrderRequestEdge', node: { __typename?: 'OrderRequestSchema', type?: Types.OrderRequestTypeEnum | null, id: string } }> | null } };


export const OrderDetailsTypeDocument = gql`
    query orderDetailsType($filters: OrderRequestFilters) {
  orderRequests(filters: $filters) {
    edges {
      node {
        type
        id
      }
    }
  }
}
    `;

export function useOrderDetailsTypeQuery(options?: Omit<Urql.UseQueryArgs<OrderDetailsTypeQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderDetailsTypeQuery>({ query: OrderDetailsTypeDocument, ...options });
};