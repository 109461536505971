import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAllNetworkAssetWarrantyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllNetworkAssetWarrantyQuery = { __typename?: 'Query', getAllNetworkAssetWarranty: { __typename?: 'GetAllNetworkAssetsWarrantyPayload', totalAssetsWarranty: Array<{ __typename?: 'TotalNetworkAssetsWarranty', totalQuantity: number, type: string, expired: Array<{ __typename?: 'NetworkAssetSchema', mac?: string | null }>, expiringSoon: Array<{ __typename?: 'NetworkAssetSchema', mac?: string | null }>, inWarranty: Array<{ __typename?: 'NetworkAssetSchema', mac?: string | null }>, noWarranty: Array<{ __typename?: 'NetworkAssetSchema', mac?: string | null }> }> } };


export const GetAllNetworkAssetWarrantyDocument = gql`
    query getAllNetworkAssetWarranty {
  getAllNetworkAssetWarranty {
    totalAssetsWarranty {
      totalQuantity
      type
      expired {
        mac
      }
      expiringSoon {
        mac
      }
      inWarranty {
        mac
      }
      noWarranty {
        mac
      }
    }
  }
}
    `;

export function useGetAllNetworkAssetWarrantyQuery(options?: Omit<Urql.UseQueryArgs<GetAllNetworkAssetWarrantyQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllNetworkAssetWarrantyQuery>({ query: GetAllNetworkAssetWarrantyDocument, ...options });
};