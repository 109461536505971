import WarningProvider from 'app/components/WarningAlert';
import OrderRequestLayout from 'app/modules/orders/layout/OrderRequestLayout';
import OrderRequestContextProvider from 'app/modules/orders/provider/OrderRequestContextProvider';
import OrderRequest from 'app/modules/orders/views/OrderRequest';

function OrderRequestPage() {
  return (
    <WarningProvider>
      <OrderRequestContextProvider>
        <OrderRequestLayout>
          <OrderRequest />
        </OrderRequestLayout>
      </OrderRequestContextProvider>
    </WarningProvider>
  );
}

export default OrderRequestPage;
