import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { NetworkAssetSchemaProps } from 'app/modules/network/types';
import Stack from 'app/ui-components/Stack';

const SystemInformationDetail = (props: NetworkAssetSchemaProps) => {
  const { networkAssetDetail } = props;
  const motherboardModel = networkAssetDetail?.motherboard?.model;
  const systemInsightDetails = [
    { label: 'Manufacturer', value: networkAssetDetail?.system?.manufacturer },
    { label: 'Model', value: networkAssetDetail?.system?.model },
    { label: 'BIOS Version', value: networkAssetDetail?.bios?.version },
    {
      label: 'MotherBoard Model',
      value: motherboardModel
        ? motherboardModel.includes(';')
          ? motherboardModel.split(';')[0]
          : motherboardModel
        : '',
    },
    { label: 'CPU Manufacturer', value: networkAssetDetail?.cpu?.manufacturer },
    { label: 'CPU Brand', value: networkAssetDetail?.cpu?.brand },
    { label: 'CPU Cores', value: networkAssetDetail?.cpu?.physicalCores },
    { label: 'Physical Cores', value: networkAssetDetail?.cpu?.physicalCores },
    {
      label: 'Graphics Controller',
      value: networkAssetDetail?.graphics?.length ? networkAssetDetail?.graphics[0].model : '',
    },
    { label: 'Battery', value: networkAssetDetail?.battery?.manufacturer },
    { label: 'Battery Type', value: networkAssetDetail?.battery?.type },
    {
      label: 'Disk Name',
      value: networkAssetDetail?.storage?.length ? networkAssetDetail.storage[0].name : '',
    },
    {
      label: 'Disk Type',
      value: networkAssetDetail?.storage?.length ? networkAssetDetail.storage[0].type : '',
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: '24px' }}>
        <Typography className='text-grey-900 font-semibold text-[18px]'>
          System Information Detail
        </Typography>
        <Divider className='my-[16px]'/>
        <Card sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);'}} className='rounded-[12px] border-[1px] border-grey-300'>
          <CardContent>
            <Grid container columns={{ xs: 8, sm: 8, md: 8, lg: 12 }} spacing={2}>
              {systemInsightDetails.map((item, index) => {
                return (
                  <Grid key={index} item lg={6} md={6} sm={12} xs={12}>
                    <Stack className="mt-2" direction="col" justifyContent="center">
                      <Typography className='text-[16px] text-grey-900 font-semibold'>{item.label}</Typography>
                      <Typography className='text-[14px] text-grey-800 mt-[8px]'>
                        {item.value || 'Not Available'}
                      </Typography>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SystemInformationDetail;
