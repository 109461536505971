import Import from 'app/i18n/Import';

import { textValidator } from '../../../../../components/Form/utils/validators';

export const IMPORT_FORM_RULES = {
  entityType: {
    validate: textValidator(Import.FormValidationMessages.DataTypeRequired),
  },
  attachments: {
    validate: (value?: any) => {
      if (!value || (value && value.length === 0)) {
        return Import.FormValidationMessages.AttachFileRequired;
      }
      return true;
    },
  },
};
