import cookie, { CookieAttributes } from 'js-cookie';

export const set = (name: string, value: string, options?: CookieAttributes) => {
  return cookie.set(name, value, options);
};

export const get = (name: string) => {
  return cookie.get(name);
};

export const remove = (name: string, options?: CookieAttributes) => {
  return cookie.remove(name, options);
};

const exports = { set, get, remove };

export default exports;
