import KitSummaryProvider from 'app/modules/components/KitSummary/context/KitSummaryProvider';
import { ItemTypeEnum } from 'app/types/schema';

import { UseAssetFormReturnType } from '../../hook/useAssetForm';
import AssetForm from '../AssetForm';
import { UseAssetReminderStateReturnType } from '../AssetForm/components/AssetReminderField/hook/useAssetReminderState';

type Props = {
  inventoryId?: string;
  disabled: boolean;
  isEditMode?: boolean;
  state: UseAssetFormReturnType['formState'] & UseAssetReminderStateReturnType;
  loading?: boolean;
  onSubmit: (values: any, kitItems: any) => void;
  onCancel: () => void;
  onDelete?: () => void;
};
const AssetFormView = (props: Props) => {
  const { disabled, isEditMode, loading, state, onDelete, onCancel, onSubmit } = props;
  return (
    <KitSummaryProvider state={state} types={[ItemTypeEnum.Asset]}>
      <AssetForm
        disabled={disabled}
        // isEditMode={isEditMode}
        formState={state}
        loading={loading}
        onCancel={onCancel}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </KitSummaryProvider>
  );
};

export default AssetFormView;
