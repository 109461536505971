import { forwardRef, useCallback } from 'react';

import FormTextInput, { FormTextInputProps } from '../../../components/Form/FormTextInput';

type Props = FormTextInputProps & {
  maximumValue?: number;
  minimumValue?: number;
  maximumLength?: number;
};

const FormNumberInput = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { onChange, maximumValue, minimumValue, maximumLength, value, ...restProps } = props;

  const onInputChange = useCallback(
    (inputValue: any) => {
      const elementValue = inputValue || '';
      let updatedValue: any = '';

      if (elementValue !== '') {
        updatedValue =
          Number(elementValue) > (minimumValue || -1)
            ? Number.parseInt(elementValue, 10)
            : value || '';
      }

      const isValidInputMaxLength =
        maximumLength !== undefined ? updatedValue.toString().length <= maximumLength : true;

      const isValidInputMaxValue = maximumValue !== undefined ? updatedValue <= maximumValue : true;

      if (onChange && isValidInputMaxValue && isValidInputMaxLength) {
        onChange(updatedValue.toString());
      }
    },
    [onChange, maximumValue, minimumValue, maximumLength, value],
  );

  return <FormTextInput ref={ref} {...restProps} value={value} onChange={onInputChange} />;
});

export default FormNumberInput;
