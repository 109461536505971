import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type WarehouseDetailsFragment = { __typename?: 'WarehouseSchema', id: string, siteId: string, zones?: number | null, aisles?: number | null, racks: number, shelves: number, bins?: number | null };

export const WarehouseDetailsFragmentDoc = gql`
    fragment WarehouseDetails on WarehouseSchema {
  id
  siteId
  zones
  aisles
  racks
  shelves
  bins
}
    `;