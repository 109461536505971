import { UpdateInventoryItemInput } from 'app/types/schema';

const Inventory = {
  InventorySearch: 'Inventory search',
  KitSummary: 'Kit Summary',
  NewInventory: 'New Inventory',
  QuickLinks: 'Quick Links',
  QuantityBySite: 'Quantity By Site',
  TransactionHistory: 'Transaction History',
  EditInventory: 'Edit Inventory',
  DeletedInventory: 'Deleted Inventory',
  EditInventoryItems: (total: number) => `Inventory Edit: ${total} record(s)`,
  EditHooverProductDetails: 'Product Detail',
  FindUpc: 'Find UPC',
  MoveInventoryConfirmation: 'Move Inventory: Confirmation',
  RemoveInventoryConfirmation: 'Remove Inventory: Confirmation',
  RestockInventoryConfirmation: 'Restock Inventory: Confirmation',
  MoveInventoryTitle: 'Move Inventory - Procure',
  RemoveInventoryTitle: 'Remove Inventory - Procure',
  RestockInventoryTitle: 'Restock Inventory - Procure',
  TransactionTitle: (title: string) => `${title} Inventory - Procure`,
  TransactionConfirmationTitle: (title: string) => `${title} Inventory: Confirmation`,
  InventoryItems: 'Inventory Items',
  Move: 'Move Inventory',
  Restock: 'Restock Inventory',
  Remove: 'Remove Inventory',
  FormLabels: {
    Site: 'Site',
    Location: 'Location',
    Quantity: 'Quantity',
    Category: 'Category',
    Vendor: 'Vendor',
    Type: 'Type',
    UpcCode: 'UPC or ASIN Code',
    NoUpcCode: 'No UPC or ASIN Code',
    FindUpc: 'Find UPC',
    Title: 'Title',
    Model: 'Model',
    Description: 'Description',
    Brand: 'Brand',
    Image: 'Image',
    UnitCost: 'Cost',
    CostOverride: 'Cost Override %',
    Attachments: 'Attachments',
    DisplayInOrder: 'Display In Order Request',
    Sku: 'SKU',
    Move: 'Move',
    Checkout: 'Check Out',
    Checkin: 'Check In',
    Restock: 'Restock',
    RestockKit: 'Restock (Kit)',
    New: 'New',
    Audit: 'Audit',
    Remove: 'Remove',
    RemoveKit: 'Remove (Kit)',
    Delete: 'Delete',
    Undelete: 'Undelete',
    Kit: 'Kit',
    DestinationSite: 'Destination Site',
    DestinationLocation: 'Destination Location',
    SourceSite: 'Source Site',
    SourceLocation: 'Source Location',
    All: 'All',
    Date: 'Date',
    User: 'User',
    ItemInStock: 'Item In Stock',
    FromSite: 'From Site',
    FromLocation: 'From Location',
    ToSite: 'To Site',
    MostRecent: 'Most Recent',
    ToLocation: 'To Location',
    Reason: 'Reason',
    Other: 'Other',
    moveShip: 'Move (Ship)',
    moveOrder: 'Move (Order)',
    Manufacturer: 'Manufacturer',
  },
  FormValidationMessages: {
    TypeRequired: 'Please select an Inventory Type.',
    TitleRequired: 'Please enter a Title up to 32 characters.',
    CategoryRequired: 'Please select a Category.',
    SiteRequired: 'Please select a Site.',
    ReasonRequired: 'Please select a Reason.',
    ReasonMessageRequired: 'Please enter a Reason.',
    ReasonMaxLength: 'Please enter a Reason up to 32 characters.',
    ToSiteRequired: 'Please select a Site.',
    ToLocationRequired: 'Please select a Location.',
    FromSiteRequired: 'Please select a Site.',
    ItemInStockInvalid: 'This SKU is not valid. Please select another SKU.',
    ItemInStockSearchRequired: 'Please select a valid SKU.',
    SiteLocationRequired: 'Please select a Location.',
    SameSiteLocationInvalid:
      'From Site and From Location may not be same as To Site and To Location. Please revise your selections.',
    QuantityInvalid: 'Please enter a Quantity greater than 0.',
    MoveAssetInventoryQuantity: 'Please enter a Quantity of 0 or greater.',
    SiteMinMaxInvalid: 'Please enter a Quantity of 0 or greater.',
    QuantityMaxiMumRequired: (availableNumber: number) =>
      `The Quantity entered is not available. Please enter a Quantity up to ${availableNumber}.`,
    InvalidCost: 'Please enter a valid Cost (xxx.xx).',
    CostMinRequired: 'Please enter a Cost greater than 0 (xxx.xx).',
    InvalidCostOverride: 'Please enter a valid Cost Override percentage (xxx.xx).',
    QuantityIsNotAvailable: 'Quantity is not available.',
    PendingAssetCheckOut:
      'At least one asset has been checked out and has a status of ‘pending’. This item must be checked in before another transaction may be created.',
    DuplicateCartItem: (quantity: string | number) =>
      `This item exists in your cart. The quantity will be updated to ${quantity}.`,
  },
  FormPlaceholders: {
    Sku: 'Auto Populate',
    Reason: 'Reason',
    UpcCode: 'Search UPC, ASIN',
    VendorSelect: 'Vendor',
    categorySelect: 'Category',
    Category: 'Category',
    Company: 'Vendor',
    ManufacturerSelect: 'Manufacturer',
    Description: 'Enter Description',
  },
  SuccessMessages: {
    QuantityMinMaxBySiteUpdated: 'The quantity has been successfully updated.',
    InventoryCreated: 'This inventory has been successfully created.',
    InventoryUpdated: 'This inventory has been successfully updated.',
    InventoryDeleted: 'This inventory has been successfully deleted.',
    InventoryKitCreated: 'This inventory kit has been successfully created.',
    InventoryKitUpdated: 'This inventory kit has been successfully updated.',
    InventoryKitDeleted: 'This inventory kit has been successfully deleted.',
    InventoryMoved: 'This item has been successfully moved.',
    InventoryRestocked: 'This item has been successfully restocked.',
    InventoryRemoved: 'This item has been successfully removed.',
    InventoryItemDeleted: (items: string[]) =>
      items.length > 1
        ? 'These items have been successfully deleted.'
        : 'This item has been successfully deleted.',
    InventoryItemUpdated: (items: UpdateInventoryItemInput[]) =>
      items.length > 1
        ? 'These items have been successfully updated.'
        : 'This item has been successfully updated.',
  },
};

export default Inventory;
