import Box from 'app/ui-components/Box';
import PageTitle from 'app/ui-components/Title';

import LoginHeader from '../Login/components/LoginHeader';
import SignUpTypeDonor from '../SignUpDonor';
import SignUpForm from './components/SignUpForm';

function SignUp() {
  const userType = new URLSearchParams(window.location.search).get('user-type');

  return (
    <Box className="h-[100dvh] bg-grey-100">
      <div className="mx-auto px-20 py-20">
        {userType === 'donor' ? (
          <Box className="pt-[103px]">
            <SignUpTypeDonor />
          </Box>
        ) : (
          <>
            <LoginHeader />
            <Box className="pt-[103px]">
              <PageTitle title="Register" /> <SignUpForm />
            </Box>
          </>
        )}
      </div>
    </Box>
  );
}

export default SignUp;
