import { useMemo } from 'react';
import { cx } from '@emotion/css';
import Box from 'app/ui-components';

import {
  FormState,
  LabelQrCodeOptionEnum,
  LabelTypeEnum,
} from '../../../Labels/components/Forms/types';
import getCodesList from '../../utils/getCodesList';
import getDetails from '../../utils/getDetails';
import getFieldList from '../../utils/getFieldList';
import CodeContainer from '../CodeContainer';
import PdfViewer from '../PdfViewer';
import { CodeSchema, LabelStockTitleEnum } from '../types';

interface Props {
  codes: FormState & { data: Array<CodeSchema> };
  type?: LabelTypeEnum;
}

const Zebra = (props: Props) => {
  const { codes, type } = props;
  const _codes = useMemo(() => getCodesList(codes, 1), [codes]);
  const field = useMemo(() => getFieldList(codes, LabelQrCodeOptionEnum.Single), [codes]);
  const labelFormate = codes.labelFormat;

  return (
    <>
      <PdfViewer print={LabelStockTitleEnum.ZebraPrinter} type={codes.type}>
        {_codes.length > 0 &&
          _codes.map((chunk) => {
            return (
              <Box id="divToPrint">
                {chunk?.length > 0 &&
                  chunk?.map((code: any, index: number) => {
                    const detail = getDetails(code, type, codes);

                    return (
                      <>
                        {!code.isBlank && (
                          <Box key={index} className={cx('h-[130px]')}>
                            <Box className={cx('flex flex-col items-center justify-center')}>
                              {labelFormate !== '3' && (
                                <CodeContainer
                                  code={code}
                                  displayImage={labelFormate !== '3' ? codes.arrowDisplay : 'no'}
                                  height={detail.length >= 2 ? 'h-[3em]' : 'h-[4em]'}
                                  size={detail.length >= 2 ? 48 : 60}
                                  type={type}
                                  width={'w-[2.7em]'}
                                />
                              )}

                              <div
                                className={
                                  'w-full overflow-hidden text-center font-[Inter] font-bold pt-5'
                                }>
                                <span className={'block font-[Inter] text-[9pt] leading-[1.3] max-w-[120px] overflow-hidden whitespace-nowrap text-ellipsis'}>
                                  {detail[0]}
                                </span>
                              </div>
                              <div
                                className={'block font-[Inter] text-[9pt] leading-[1.3] max-w-[120px] overflow-hidden whitespace-nowrap text-ellipsis'}>
                                {detail[1]}
                              </div>
                              <div
                                className={'block font-[Inter] text-[9pt] leading-[1.3] max-w-[120px] overflow-hidden whitespace-nowrap text-ellipsis'}>
                                {detail[2]}
                              </div>
                            </Box>
                          </Box>
                        )}
                        <Box className={cx('page-break hidden')}></Box>
                      </>
                    );
                  })}
              </Box>
            );
          })}
      </PdfViewer>
    </>
  );
};

export default Zebra;
