import React from 'react';
import Loader from 'app/components/Loader';
import Box from 'app/ui-components';

import {
  PaginationState,
  SelectedProducts,
  WishListDetailType,
} from '../../views/createList/types';
import Pagination from '../Pagination';
import ProductsMerchants from '../SearchBox/utils/ProductsMerchants';

interface Props {
  addProductHandler: (card: SelectedProducts) => void;
  data: any;
  loading: boolean;
  wishlistId?: string;
  selectedProducts?: SelectedProducts[];
  paginationState: PaginationState;
  nextPageUrl?: string | undefined;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  wishlistName?: string;
  isAmazon?: boolean;
  wishlistDetails?: WishListDetailType;
}

const ProductsTable = (props: Props) => {
  const {
    addProductHandler,
    data,
    selectedProducts,
    paginationState,
    setPage,
    setPageSize,
    loading,
    wishlistName,
    nextPageUrl,
    wishlistId,
    isAmazon,
    wishlistDetails,
  } = props;

  const handlePageClick = async (page: any) => {
    if (!Number.isNaN(page)) {
      setPage(page);
      window.scrollTo({
        top: 10,
        behavior: 'smooth',
      });
    }
  };

  const handlePageSizeClick = async (pageSize: number) => {
    setPage(1);
    setPageSize(pageSize);
  };

  return (
    <Box data-testid="products-table">
      {loading ? (
        <Loader />
      ) : !!data?.length ? (
        <ProductsMerchants
          addProductHandler={addProductHandler}
          data={data || []}
          selectedProducts={selectedProducts}
          wishListName={wishlistName}
          wishlistDetails={wishlistDetails}
          wishlistId={wishlistId}
        />
      ) : (
        <p className={'py-[30px] px-[0px] text-center'}>No matching records found.</p>
      )}

      {!loading && !!data.length && (
        <Pagination
          isAmazon={isAmazon}
          itemsPerPage={paginationState.pageSize || 12}
          itemsPerPageText="Products per page"
          nextPageUrl={nextPageUrl}
          page={paginationState.page || 1}
          paginationState={paginationState}
          onItemsPerPageChange={handlePageSizeClick}
          onPageChange={handlePageClick}
        />
      )}
    </Box>
  );
};

export default ProductsTable;
