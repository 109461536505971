import { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';

import { SnackbarService } from '../../../../components/Snackbar';
import DeleteConfirmationModal from '../../../components/EntityManager/DeleteConfirmationModal';
import AffiliateMerchantAffiliateUrlField from './components/AffiliateMerchantAffiliateUrlField';
import AffiliateMerchantAttachmentField from './components/AffiliateMerchantAttachmentField';
import AffiliateMerchantCommissionField from './components/AffiliateMerchantCommissionField';
import AffiliateMerchantDomainField from './components/AffiliateMerchantDomainField';
import AffiliateMerchantNameField from './components/AffiliateMerchantNameField';
import AffiliateMerchantStatusField from './components/AffiliateMerchantStatusField';
import SaveCancelActionButtons from './components/SaveDeleteCancelActionButtons';
import { UseAffiliateMerchantFormReturnType } from './hook/useAffiliateMerchantForm';

interface Props {
  state: UseAffiliateMerchantFormReturnType['state'];
  disabled: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

const AffiliateMerchantForm = (props: Props) => {
  const { state, disabled: formDisabled, onSubmit, onCancel, onDelete } = props;
  const { handleSubmit, showDeleteConfirmation, onCancelDelete, editable } =
    state;

  const disabled = useMemo(() => {
    return !editable || formDisabled;
  }, [editable, formDisabled]);

  const onError = useCallback((errors: any) => {
    const errorMessages = Object.keys(errors).map((fieldKey) => {
      return errors[fieldKey]?.message || '';
    });

    if (errorMessages.length) {
      SnackbarService.showError({
        message: errorMessages[0],
      });
    }
  }, []);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit, onError), [handleSubmit, onSubmit]);

  return (
    <div className="mt-20">
      <Grid container justifyContent={'center'} spacing={3}>
        <Grid item lg={4} md={4} sm={12} xl={3} xs={12}>
          <form onSubmit={onFormSubmit}>
            <AffiliateMerchantNameField disabled={disabled} formState={state} />
            <AffiliateMerchantAffiliateUrlField disabled={disabled} formState={state} />
            <AffiliateMerchantDomainField disabled={disabled} formState={state} />
            <AffiliateMerchantCommissionField disabled={disabled} formState={state} />
            <AffiliateMerchantAttachmentField disabled={disabled} formState={state} />
            <AffiliateMerchantStatusField disabled={disabled} formState={state} />
            <SaveCancelActionButtons
              disabled={disabled}
              formState={state}
              onCancel={onCancel}
              onSubmit={onFormSubmit}
            />
          </form>
        </Grid>
      </Grid>

      <DeleteConfirmationModal
        open={showDeleteConfirmation}
        onCancel={onCancelDelete}
        onConfirm={() => {
          if (onDelete) {
            onCancelDelete();
            onDelete();
          }
        }}
      />
    </div>
  );
};

export default AffiliateMerchantForm;
