import { DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getUPCResultTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'globalTradeItemNumber',
    label: 'Product Code',
    value: 'globalTradeItemNumber',
    valueNode: (valueComponentProps) => {
      const { row, state } = valueComponentProps;
      const { extraProps } = state;
      return (
        <span
          className="hoover-product-link truncate underline"
          onClick={() => {
            if (extraProps?.onProductCodeClick) {
              extraProps.onProductCodeClick(row);
            }
          }}
          title={row.globalTradeItemNumber}>
          {row.globalTradeItemNumber}
        </span>
      );
    },
    sortable: false,
  },
  {
    id: 'name',
    label: 'Title',
    value: 'name',
    sortable: false,
    width: 300,
  },
  {
    id: 'modelName',
    label: 'Model',
    value: 'modelName',
    sortable: false,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    sortable: false,
    width: 300,
    addReadMore: true,
  },
  {
    id: 'manufacturer',
    label: 'Brand',
    value: 'manufacturer',
    sortable: false,
  },
];
