import { useCallback, useContext, useState } from 'react';
import { WarningService } from 'app/components/WarningAlert';
import Orders from 'app/i18n/Orders';

import { ORDER_REQUEST_TYPE } from '../../type';
import useBulkOrderRequest from '../useBulkOrderRequest';
import useNormalOrderRequest from '../useNormalOrderRequest';
import { OrderRequestContext } from '../../../../../../provider/OrderRequestContextProvider/OrderRequestContextProvider';

interface State {
  orderRequestType: ORDER_REQUEST_TYPE;
}

const useItemRequestState = () => {
  const { getOrderRequestType } = useContext(OrderRequestContext);
  const [state, _setState] = useState<State>({
    orderRequestType: getOrderRequestType() || ORDER_REQUEST_TYPE.NORMAL_ORDER,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({
        ...prevState,
        ...nextState,
      }));
    },
    [_setState],
  );

  const { normalOrderState, normalOrderActions } = useNormalOrderRequest(state.orderRequestType);
  const { bulkOrderState, bulkOrderActions } = useBulkOrderRequest();

  const onBulkOrderRequest = useCallback(() => {
    if (normalOrderState.orderItems.length > 0) {
      WarningService.showWarning({
        message: Orders.FormValidationMessages.UnSavedChangesMessage,
        onConfirm: () => {
          normalOrderActions.clearOrderItems();
          normalOrderActions.onResetForm();
          normalOrderState.formState.setValue('partnerTenantId', '');
          setState({
            orderRequestType: ORDER_REQUEST_TYPE.BULK_ORDER,
          });
        },
      });
    } else {
      normalOrderState.formState.setValue('partnerTenantId', '');
      normalOrderActions.onResetForm();
      setState({
        orderRequestType: ORDER_REQUEST_TYPE.BULK_ORDER,
      });
    }
  }, [
    setState,
    normalOrderActions,
    normalOrderState.orderItems.length,
    normalOrderState.formState,
  ]);

  const onCancelBulkOrderRequest = useCallback(() => {
    bulkOrderActions.clearOrderItems();
    bulkOrderActions.onResetForm();
    bulkOrderState.setValue('partnerTenantId', '');
    setState({
      orderRequestType: ORDER_REQUEST_TYPE.NORMAL_ORDER,
    });
  }, [setState, bulkOrderActions, bulkOrderState]);

  return {
    orderItemRequest: {
      state: {
        normalOrderState,
        bulkOrderState,
        orderRequestType: state.orderRequestType,
        setState,
      },
      actions: {
        normalOrderActions,
        bulkOrderActions,
        onBulkOrderRequest,
        onCancelBulkOrderRequest,
      },
    },
  };
};

export type UseItemRequestStateReturnType = ReturnType<typeof useItemRequestState>;

export default useItemRequestState;
