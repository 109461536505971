import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrderRequestItemReturnMutationVariables = Types.Exact<{
  input: Types.UnpackShippingTransactionsOfTrackingIdsInput;
}>;


export type CancelOrderRequestItemReturnMutation = { __typename?: 'Mutation', unpackShippingTransactionsOfTrackingIds: { __typename?: 'MutationResponse', success: boolean } };


export const CancelOrderRequestItemReturnDocument = gql`
    mutation cancelOrderRequestItemReturn($input: UnpackShippingTransactionsOfTrackingIdsInput!) {
  unpackShippingTransactionsOfTrackingIds(input: $input) {
    success
  }
}
    `;

export function useCancelOrderRequestItemReturnMutation() {
  return Urql.useMutation<CancelOrderRequestItemReturnMutation, CancelOrderRequestItemReturnMutationVariables>(CancelOrderRequestItemReturnDocument);
};