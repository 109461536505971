import { Controller } from 'react-hook-form';

import Assets from '../../../../../../i18n/Assets';
import Inventory from '../../../../../../i18n/Inventory';
import FormNumberInput from '../../../../../components/FormNumberInput';
import { LabelTypeEnum } from '../../../../../labels/views/Labels/components/Forms/types';
import FormSiteSelect from '../../../../../locations/components/FormSiteSelect';
import { InventoryFormProps } from '../../types';
import { INVENTORY_FORM_RULES } from '../../utils/utils';

const InventoryDestinationSIteLocationQtyFields = (props: InventoryFormProps) => {
  const { formState, disabled } = props;
  const { control, destinationSiteId, destinationLocationId, itemId, setSite } = formState;

  if (itemId) {
    return null;
  }

  return (
    <div>
      <div className="mb-20">
        <Controller
          control={control}
          name="destinationSiteId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              disabled={disabled}
              error={fieldState.error}
              label={Inventory.FormLabels.Site}
              onChange={(value, valueObject) => {
                field.onChange(value);
                setSite(valueObject);
              }}
            />
          )}
          rules={INVENTORY_FORM_RULES.destinationSiteId}
        />
      </div>

      <div className="mb-20">
        <Controller
          control={control}
          name="destinationLocationId"
          render={({ field, fieldState }) => (
            <FormSiteSelect
              {...field}
              isRequired
              disabled={!destinationSiteId || disabled}
              error={fieldState.error}
              label={Inventory.FormLabels.Location}
              selectedLocationValue={destinationLocationId}
              type={LabelTypeEnum.Location}
              types={[]}
              value={destinationSiteId}
            />
          )}
          rules={INVENTORY_FORM_RULES.destinationLocationId}
        />
      </div>
      <div className="mb-20">
        <Controller
          control={control}
          name="quantity"
          render={({ field, fieldState }) => (
            <FormNumberInput
              {...field}
              isRequired
              error={fieldState.error}
              isDisabled={disabled}
              label={Inventory.FormLabels.Quantity}
              maximumLength={9}
            />
          )}
          rules={INVENTORY_FORM_RULES.quantity}
        />
      </div>
    </div>
  );
};

export default InventoryDestinationSIteLocationQtyFields;
