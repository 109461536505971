import { css } from '@emotion/css';

export const radioGroupStyleOverrides = css`
  .MuiFormGroup-root {
    flex-direction: row;
  }

  .MuiTypography-root {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }

  .MuiRadio-root {
    flex-shrink: 0;
  }
`;
