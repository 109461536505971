import { Controller } from 'react-hook-form';
import Assets from 'app/i18n/Assets';
import FormAssetModelSelect from 'app/modules/assetModels/components/FormAssetModelSelect';

import { AssetFieldProps } from '../../types';

const AssetAssetModelField = (props: AssetFieldProps) => {
  const { disabled, formState } = props;
  const { control, onAssetModelChange, isItemTypeAsset } = formState;

  if (!isItemTypeAsset) {
    return null;
  }

  return (
    <div className="mb-20">
      <Controller
        control={control}
        name="parentId"
        render={({ field, fieldState }) => (
          <FormAssetModelSelect
            {...field}
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.AssetModel}
            onChange={(value, assetModelData) => {
              field?.onChange(value);
              onAssetModelChange(assetModelData);
            }}
          />
        )}
      />
    </div>
  );
};

export default AssetAssetModelField;
