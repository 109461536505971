import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ContactListFragmentDoc } from '../../framgments/generated/contact';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ContactsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ContactFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsQuery = { __typename?: 'Query', contacts: { __typename?: 'ContactConnection', totalCount: number, edges: Array<{ __typename?: 'ContactEdge', node: { __typename?: 'ContactSchema', createdAt: string, updatedAt: string, id: string, addressId: string, companyId: string, firstName: string, lastName: string, companySqlId?: number | null, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPrevPage: boolean, nextCursor?: string | null, prevCursor?: string | null } | null } };


export const ContactsDocument = gql`
    query contacts($filters: ContactFilters, $sorts: [SortProps!], $limit: Float, $after: String, $before: String) {
  contacts(
    filters: $filters
    sorts: $sorts
    limit: $limit
    after: $after
    before: $before
  ) {
    edges {
      node {
        ...ContactList
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPrevPage
      nextCursor
      prevCursor
    }
  }
}
    ${ContactListFragmentDoc}`;

export function useContactsQuery(options?: Omit<Urql.UseQueryArgs<ContactsQueryVariables>, 'query'>) {
  return Urql.useQuery<ContactsQuery>({ query: ContactsDocument, ...options });
};