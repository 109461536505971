import moment from 'moment';
import { SelectOption } from 'app/components/Select/types';
import { DateFilter } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';

export const TRANSACTION_DATE_FILTER_RULES = {
  validate: (value: DateFilter) => {
    if (!value.absolute?.startTime || !value.absolute?.endTime) {
      return 'Please select a date range';
    }

    if (new Date(value.absolute?.startTime) > new Date(value.absolute?.endTime)) {
      return 'Start date must be before end date';
    }

    return true;
  },
};

export const getTransactionDateFilterOptions = (): (SelectOption & { createdAt: DateFilter })[] => {
  const getStartTime = (months: number) => {
    return moment().subtract(months, 'month').startOf('day').toISOString();
  };

  const getEndTime = (months: number) => {
    return moment().subtract(months, 'month').endOf('day').toISOString();
  };

  return [
    {
      value: '1-3',
      label: '1 to 3 months',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(3), endTime: getEndTime(0) },
      },
    },
    {
      value: '3-6',
      label: '3 to 6 months',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(6), endTime: getEndTime(3) },
      },
    },
    {
      value: '6-9',
      label: '6 to 9 months',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(9), endTime: getEndTime(6) },
      },
    },
    {
      value: '9-12',
      label: '9 to 12 months',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: getStartTime(12), endTime: getEndTime(9) },
      },
    },
    {
      value: 'custom',
      label: 'Select Date Range',
      createdAt: {
        timezone: getCurrentTimeZoneValue(),
        absolute: { startTime: null, endTime: null },
      },
    },
  ];
};
