import { css } from '@emotion/css';

export const radioGroupStyleOverrides = css`
  .MuiCheckbox-root {
    padding: 4px;
  }

  .MuiTypography-root {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
`;
