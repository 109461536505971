import './index.css';
import ReactApexChart from 'react-apexcharts';
import { Button } from 'reactstrap';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Network from 'app/i18n/Network';
import { defaultGridColumn } from 'app/modules/network/utils';

import useChart from '../../dashboard/components/chart/useChart';
import { useEffect, useState } from 'react';

type BarGraphType = {
  title: string;
  subheader: string;
  chartData: Array<any>;
  onClickViewDetails?: () => void;
  detailsButton?: boolean;
  handleDataPointClick?: (datapoint: string) => void;
  changeGraphOption?: boolean;
  showLegend?: boolean;
  graphOption?: string;
  setGraphOption?: any;
  border?: string;
  boxShadow?: string;
};

export default function DonutGraph(props: BarGraphType) {
  const {
    title,
    subheader,
    chartData,
    onClickViewDetails,
    detailsButton,
    handleDataPointClick,
    changeGraphOption,
    showLegend,
    graphOption,
    setGraphOption,
    border,
    boxShadow,
    ...other
  } = props;

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    plotOptions: {
      pie: {
        donut: {
          size: '85%',
        },
      },
    },
    labels: chartLabels,
    legend: {
      show: showLegend ? true : false,
      showForSingleSeries: true,
      formatter: (value: any, opt: any) => {
        return value + ' : ' + opt.w.config.series[opt.seriesIndex];
      },
      horizontalAlign: 'center',
      position: 'bottom',
    },
  });

  const textTruncate = () => {
    const textElements = document.getElementsByClassName('apexcharts-text');

    Array.from(textElements).forEach((element) => {
      if (element instanceof SVGTextElement) {
        const maxWidth = 20;
        const originalText = element.textContent || '';

        const tempTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        tempTextElement.setAttribute('font-size', '8px');
        tempTextElement.textContent = originalText;

        element.parentNode?.appendChild(tempTextElement);
        if (tempTextElement.getComputedTextLength() > maxWidth) {
          const truncatedText = originalText.slice(0, 10) + '...';
          element.textContent = truncatedText;
        }

        element.parentNode?.removeChild(tempTextElement);
      }
    });
  };
  const checkWindowSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768 && screenWidth <= 1440) {
      return true;
    }
    return false;
  };

  const handleChartClick = () => {
    if (checkWindowSize()) textTruncate();
  };

  const handleMouseLeave = () => {
    if (checkWindowSize()) textTruncate();
  };

  const handleMouseHover = () => {
    if (checkWindowSize()) textTruncate();
  };

  return (
    <Box
      className={`lg:!min-h-[425px] ${border}`}
      dir="ltr"
      sx={{ boxShadow: boxShadow && boxShadow }}>
      {!chartData.length ? (
        <Typography style={{ color: 'gray', fontSize: '14px', marginBottom: '10px' }}>
          {Network.NothingToShow}
        </Typography>
      ) : !detailsButton && changeGraphOption ? (
        <Grid container columns={defaultGridColumn} spacing={{ xs: 1, sm: 2, lg: 8 }}>
          <Grid item className="border" lg={6} md={6} sm={6} xs={12}>
            <ReactApexChart
              className={'chartClass '}
              height={detailsButton ? 425 : undefined}
              options={chartOptions}
              series={chartSeries}
              type="donut"
              width={'100%'}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Box sx={{ display: 'flex', mb: 2, maxWidth: 358 }}>
              <FormControl sx={{ minWidth: 180 }}>
                <InputLabel htmlFor="graph-option">Graph Option</InputLabel>
                <Select
                  inputProps={{
                    name: 'graphOption',
                    id: 'graph-option',
                  }}
                  label="Graph Option"
                  value={graphOption}
                  onChange={(event) => {
                    setGraphOption && setGraphOption(event.target.value);
                  }}>
                  <MenuItem value="donut">Donut</MenuItem>
                  <MenuItem value="column">Column</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                height: '70%',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                maxWidth: 358,
              }}>
              <ul style={{ margin: '0', padding: '0', textAlign: 'center' }}>
                {chartData.map((dataPoint) =>
                  dataPoint?.label ? (
                    <li
                      key={dataPoint.label}
                      className="datapointList"
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                      }}
                      onClick={() =>
                        handleDataPointClick ? handleDataPointClick(dataPoint.label || '') : null
                      }>
                      <strong style={{ textAlign: 'left' }}>
                        {dataPoint.label.charAt(0).toUpperCase() + dataPoint.label.slice(1)}
                      </strong>
                      <span style={{ textAlign: 'right' }}>{dataPoint.value}</span>
                    </li>
                  ) : null,
                )}
              </ul>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box className={`h-full rounded-[12px] rounded-[12px] p-[24px]`}>
          <Typography className="mb-[24px] text-[18px] font-semibold text-grey-900">
            {title}
          </Typography>
          <ReactApexChart
            className={'chartClass'}
            id="chartContainer"
            height={detailsButton ? 500 : undefined}
            options={{
              ...chartOptions,
              plotOptions: {
                ...chartOptions.plotOptions,
                pie: {
                  ...chartOptions.plotOptions.pie,
                  customScale: 0.8, // adjust as needed
                },
              },
              chart: {
                events: {
                  click: () => handleChartClick(),
                  mouseMove: () => handleMouseHover(),
                  mouseLeave: () => handleMouseLeave(),
                },
              },
            }}
            series={chartSeries}
            type="donut"
            width={'100%'}
          />
          {detailsButton ? (
            <Button
              // style={{ width: '100%', borderTop: '1px solid #f2f2f0' }}
              style={{
                width: '100%',
                borderRadius: '8px',
                border: '1px solid #9AA4B2',
                padding: '10px 12px',
                marginTop: '16px',
              }}
              theme="info"
              onClick={onClickViewDetails}>
              <Typography className="m-0 text-[16px] font-medium text-grey-700">
                View Details
              </Typography>
            </Button>
          ) : null}
        </Box>
      )}
    </Box>
  );
}
