import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreatePartnerTenantMutationVariables = Types.Exact<{
  input: Types.CreatePartnerTenantInput;
}>;


export type CreatePartnerTenantMutation = { __typename?: 'Mutation', createPartnerTenant: { __typename?: 'CreatePartnerTenantPayload', success: boolean } };


export const CreatePartnerTenantDocument = gql`
    mutation createPartnerTenant($input: CreatePartnerTenantInput!) {
  createPartnerTenant(input: $input) {
    success
  }
}
    `;

export function useCreatePartnerTenantMutation() {
  return Urql.useMutation<CreatePartnerTenantMutation, CreatePartnerTenantMutationVariables>(CreatePartnerTenantDocument);
};