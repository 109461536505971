const Shop = {
  Shops: 'Shops',
  ShopsSearch: 'Shops Search',
  Admin: {
    AffiliateConfiguration: 'Affiliate Configuration',
    NewAffiliateConfiguration: 'New Affiliate Configuration',
    EditAffiliateConfiguration: 'Edit Affiliate Configuration',
    FormLabel: {
      Name: 'Supplier Name',
      AffiliateUrl: 'Affiliate URL',
      Domain: 'Domain',
      Commission: 'Commission %',
      Logo: 'Affiliate Logo',
      Merchant: 'Merchant'
    },
    FormValidationMessages: {
      NameRequired: 'Please enter a Supplier Name up to 32 characters.',
      AffiliateUrlRequired: 'Please enter a Affiliate URL.',
      AffiliateUrlInvalid: 'Please enter a valid Affiliate URL.',
      DomainRequired: 'Please enter a Domain.',
      DomainInvalid: 'Please enter a valid Domain.',
      LogoUrlRequired: 'Please select a Logo.',
      CommissionInvalid: 'Please enter a Commission percentage between 0 and 100.',
    },
    SuccessMessages: {
      AffiliateCreated: 'This merchant has been successfully created.',
      AffiliateUpdated: 'This merchant has been successfully updated.',
      AffiliateDeleted: 'This merchant has been successfully deleted.',
    }
  }
};

export default Shop;
