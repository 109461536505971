import { forwardRef } from 'react';
import FormFieldContainer, {
  FormFieldContainerProps,
} from 'app/components/Form/FormFieldContainer';
import { DepartmentsSelectProps } from 'app/modules/departments/components/DepartmentSelect';

import DepartmentSelect from '../../../modules/departments/components/DepartmentSelect';

type Props = Omit<FormFieldContainerProps, 'children'> & DepartmentsSelectProps;

const FormDepartmentSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, label, isRequired, size, error, ...selectProps } = props;

  return (
    <FormFieldContainer ref={ref} className={className} error={error}>
      <DepartmentSelect {...selectProps} isRequired={isRequired} label={label} size={size} />
    </FormFieldContainer>
  );
});

export default FormDepartmentSelect;
