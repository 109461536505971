import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateOrderRequestItemStatusByOrderRequestIdMutationVariables = Types.Exact<{
  input: Types.UpdateOrderRequestItemStatusByOrderRequestIdInput;
}>;


export type UpdateOrderRequestItemStatusByOrderRequestIdMutation = { __typename?: 'Mutation', updateOrderRequestItemStatusByOrderRequestId: { __typename?: 'MutationResponse', success: boolean } };


export const UpdateOrderRequestItemStatusByOrderRequestIdDocument = gql`
    mutation updateOrderRequestItemStatusByOrderRequestId($input: UpdateOrderRequestItemStatusByOrderRequestIdInput!) {
  updateOrderRequestItemStatusByOrderRequestId(input: $input) {
    success
  }
}
    `;

export function useUpdateOrderRequestItemStatusByOrderRequestIdMutation() {
  return Urql.useMutation<UpdateOrderRequestItemStatusByOrderRequestIdMutation, UpdateOrderRequestItemStatusByOrderRequestIdMutationVariables>(UpdateOrderRequestItemStatusByOrderRequestIdDocument);
};