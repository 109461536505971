import { css } from '@emotion/css';

export const RadioOptionLabelStyles = css`
  // .MuiRadio-root {
  //   height: 26px;
  // }

  .MuiTypography-root {
    font-size: 13px;
    color: hsl(0, 0%, 20%);
  }
`;
