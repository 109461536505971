import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateInventoryKitItemMutationVariables = Types.Exact<{
  input: Types.CreateInventoryKitItemInput;
}>;


export type CreateInventoryKitItemMutation = { __typename?: 'Mutation', createInventoryKitItem: { __typename?: 'CreateInventoryKitItemPayload', success: boolean, reason?: string | null, inventoryKitItem?: { __typename?: 'InventoryKitItemSchema', id: string, title: string } | null } };


export const CreateInventoryKitItemDocument = gql`
    mutation createInventoryKitItem($input: CreateInventoryKitItemInput!) {
  createInventoryKitItem(input: $input) {
    success
    reason
    inventoryKitItem {
      id
      title
    }
  }
}
    `;

export function useCreateInventoryKitItemMutation() {
  return Urql.useMutation<CreateInventoryKitItemMutation, CreateInventoryKitItemMutationVariables>(CreateInventoryKitItemDocument);
};