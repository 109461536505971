import { cx } from '@emotion/css';

export const getTabNavLinkClassName = (isActive: boolean, disabled?: boolean) =>
  cx(
    'flex justify-center items-center h-[40px] w-max md:min-w-full lg:min-w-full cursor-pointer py-[8px] px-[12px] leading-normal font-semibold hover:bg-orange-200 hover:text-orange-900 rounded-[8px]',
    isActive ? '!text-white' : 'text-grey-700',
    isActive ? '!bg-orange-900' : disabled ? 'bg-transparent' : 'bg-transparent',
  );

export const getTabWishListNavLinkClassName = (isActive: boolean, disabled?: boolean) =>
  cx(
    'block cursor-pointer py-16 px-13 text-13 leading-normal',
    isActive ? 'text-white' : 'text-black',
    isActive ? 'bg-[#66CCFF]' : disabled ? 'bg-[#ffba5a]' : 'bg-[white]',
  );
