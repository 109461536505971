import { cx } from '@emotion/css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const WarehouseLabel = (props: Props) => {
  const { children, className } = props;

  return <p className={cx(className, 'text-[16px] font-semibold text-grey-900')}>{children}</p>;
};

export default WarehouseLabel;
