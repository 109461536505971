import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormOrganizationSelectQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TenantFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
}>;


export type FormOrganizationSelectQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', edges: Array<{ __typename?: 'TenantEdge', node: { __typename?: 'TenantSchema', id: string, name: string } }> } };


export const FormOrganizationSelectDocument = gql`
    query formOrganizationSelect($filters: TenantFilters, $sorts: [SortProps!]) {
  tenants(filters: $filters, sorts: $sorts) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

export function useFormOrganizationSelectQuery(options?: Omit<Urql.UseQueryArgs<FormOrganizationSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormOrganizationSelectQuery>({ query: FormOrganizationSelectDocument, ...options });
};