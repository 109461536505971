import { Controller } from 'react-hook-form';
import FormSelect from 'app/components/Form/FormSelect';
import Assets from 'app/i18n/Assets';

import { AssetFieldProps } from '../../types';
import { ASSET_FORM_RULES, ASSET_ITEM_TYPES } from '../../utils';

const AssetTypeField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;

  const { control, id } = formState;

  if (id) {
    return null;
  }

  return (
    <div className="mb-20" data-testid={'asset-type'}>
      <Controller
        control={control}
        name="type"
        render={({ field, fieldState }) => (
          <FormSelect
            {...field}
            autoFocus
            isRequired
            disabled={disabled}
            error={fieldState.error}
            label={Assets.FormLabels.Type}
            options={ASSET_ITEM_TYPES}
          />
        )}
        rules={ASSET_FORM_RULES.type}
      />
    </div>
  );
};

export default AssetTypeField;
