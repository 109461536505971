import { SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { isAccessible } from 'app/components/AccessControl';
import routes from 'app/consts/routes';
import Reports from 'app/i18n/Reports';
import { UserProfileFragment } from 'app/modules/auth/graphql/fragments/generated/UserProfile';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

const action = AllowedPermissionActionsEnum.Access;

export const REPORT_LAYOUT_ROUTES_CONFIG = [
  {
    id: 'aging-report',
    route: routes.AgingReport(),
    routes: [routes.AgingReport()],
  },
  {
    id: 'audit-report',
    route: routes.AuditReport(),
    routes: [
      routes.AuditReport(),
      routes.NewAssetAuditReport(),
      routes.NewInventoryAuditReport(),
      routes.AssetAuditReportDetails(':id'),
      routes.InventoryAuditReportDetails(':id'),
    ],
  },
  {
    id: 'cost-report',
    route: routes.CostReport(),
    routes: [routes.CostReport()],
  },
  {
    id: 'low-stock-report',
    route: routes.LowStockReport(),
    routes: [routes.LowStockReport()],
  },
  {
    id: 'site-report',
    route: routes.SiteReport(),
    routes: [routes.SiteReport()],
  },
  {
    id: 'order-request-report',
    route: routes.OrderRequestReport(),
    routes: [routes.OrderRequestReport()],
  },
  {
    id: 'it-asset-report',
    route: routes.ItAssetReport(),
    routes: [routes.ItAssetReport()],
  },
  {
    id: 'transaction-report',
    route: routes.TransactionReport(),
    routes: [routes.TransactionReport()],
  },
  {
    id: 'accounting-report',
    route: routes.AccountingReport(),
    routes: [routes.AccountingReport()],
  },
  {
    id: 'custom-report',
    route: routes.CustomReport(),
    routes: [routes.CustomReport()],
  },
];

export const getReportsLayoutSidebarItems = (
  permissions: UserProfileFragment['workspacePermissions'],
) =>
  [
    {
      id: 'reports',
      sideBarItems: [
        {
          id: 'aging-report',
          label: Reports.AgingReport,
          subject: AllowedPermissionsSubjectEnum.AgeingReport,
        },
        {
          id: 'audit-report',
          label: Reports.AuditReport,
          subject: AllowedPermissionsSubjectEnum.AssetAuditReport,
        },
        {
          id: 'cost-report',
          label: Reports.CostReport,
          subject: AllowedPermissionsSubjectEnum.CostReport,
        },
        {
          id: 'low-stock-report',
          label: Reports.LowStockReport,
          subject: AllowedPermissionsSubjectEnum.LowStockReport,
        },
        {
          id: 'site-report',
          label: Reports.SiteReport,
          subject: AllowedPermissionsSubjectEnum.SiteReport,
        },
        {
          id: 'transaction-report',
          label: Reports.TransactionReport,
          subject: AllowedPermissionsSubjectEnum.TransactionReport,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
    {
      id: 'order-request',
      sideBarItems: [
        {
          id: 'order-request-report',
          label: Reports.OrderRequestReport,
          subject: AllowedPermissionsSubjectEnum.OrderRequestReport,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
    {
      id: 'it-asset-report',
      sideBarItems: [
        {
          id: 'it-asset-report',
          label: Reports.ItAssetReport,
          subject: AllowedPermissionsSubjectEnum.ItAssetInsight,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
    {
      id: 'custom-reports',
      sideBarItems: [
        {
          id: 'accounting-report',
          label: Reports.AccountingReport,
          subject: AllowedPermissionsSubjectEnum.AccountingReport,
        },
        {
          id: 'custom-report',
          label: Reports.CustomReport,
          subject: AllowedPermissionsSubjectEnum.CustomReport,
        },
      ].filter((item) => isAccessible(permissions, action, item.subject)),
    },
  ] as SideBarItemGroup[];
