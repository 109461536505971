import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';
import { UseInventoryFormReturnType } from 'app/modules/inventory/components/InventoryForm/hook/useInventoryForm';
import Box from 'app/ui-components';

import { UseKitSummaryStateReturnType } from '../../hook/useKitSummaryState';

interface Props {
  formState: (UseInventoryFormReturnType['state'] | UseAssetFormReturnType['formState']) & {
    onResetFormAndTable: () => void;
    rows: UseKitSummaryStateReturnType['rows'];
  };
  disabled: boolean;
  loading?: boolean;
  onSubmit: (values: any) => void;
  onCancel?: () => void;
}

const SaveCancelActionButtons = (props: Props) => {
  const { formState, disabled, loading, onSubmit, onCancel } = props;
  const { attachmentInputProps, editable, itemId, cancelEditable, onCancelKitSummary, onResetFormAndTable } = formState;

  const handleCancel = useCallback(() => {
    if (itemId) {
      cancelEditable();
    } else {
      onCancelKitSummary();
      onResetFormAndTable();
    }
  }, [onCancel, itemId, cancelEditable]);

  if (!editable) {
    return null;
  }

  return (
    <Box className="mt-20 flex justify-end space-x-8">
      <Button
        disabled={disabled || attachmentInputProps.isUploading}
        loading={loading}
        theme="success"
        onClick={onSubmit}>
        {itemId ? Common.Actions.Save : 'Create Kit'}
      </Button>
      <Button onClick={handleCancel}>{Common.Actions.Cancel}</Button>
    </Box>
  );
};

export default SaveCancelActionButtons;
