import { useMemo } from 'react';
import { Maybe } from 'graphql/jsutils/Maybe';
import Common from 'app/i18n/Common';
import { AssetKitItemSchema, InventoryKitItemSchema, ItemLocationSchema } from 'app/types/schema';
import AlertBox from 'app/ui-components/AlertBox';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  itemData: AssetKitItemSchema | InventoryKitItemSchema;
}

// NOTE
// Is Kit at Source Site Only: need to consider location only
// Is Kit at Different Site only: When stockInformation also contains non source site with quantity > 0 need to consider
// Is Kit at multiple site => when stockInformation contains multiple location with quantity > 0

function DeleteKitConfirmationModal(props: Props) {
  const { open, onCancel, onConfirm, itemData } = props;

  //  filter sites having quantity
  // if stockInformation contains only source site then show message 1
  // else if stockInformation also contains non source site

  const result: number = useMemo(() => {
    const siteId = itemData.siteId;
    const onlySitesWithQty = itemData?.stockInformation?.filter?.(
      (site) => site.availableQuantity && site.availableQuantity > 0,
    );

    const includesSourceSite: boolean = !!onlySitesWithQty?.find(
      (stockItem: Maybe<ItemLocationSchema>) => stockItem?.site?.siteId === siteId,
    );

    const includesNonSourceSites = onlySitesWithQty?.some(
      (stockItem: Maybe<ItemLocationSchema>) => stockItem?.site?.siteId !== siteId,
    );

    if (includesSourceSite && includesNonSourceSites) return 2;
    else if (includesNonSourceSites) return 1;
    else return 0;
  }, [itemData]);

  const message = useMemo(() => {
    return [
      Common.KITDeleteFromSourceSiteOnly,
      Common.KITDeleteFromNonSiteOnly,
      Common.KITDeleteFromAllSite,
    ][result];
  }, [result]);

  return (
    <AlertBox
      message={message}
      open={open}
      status="warning"
      type={`${Common.Actions.Delete}!`}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
}

export default DeleteKitConfirmationModal;
