import { useCallback } from 'react';
import { Button } from '@procurenetworks/procure-component-library';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';

import Common from '../../../../../../i18n/Common';
import Box from '../../../../../../ui-components/Box';

interface Props {
  formState: UseAssetFormReturnType['formState'];
  disabled: boolean;
  loading?: boolean;
  onNext: (values: any) => void;
  onCancel?: () => void;
}

const NextCancelActionButtons = (props: Props) => {
  const { formState, disabled, loading, onNext } = props;
  const {
    attachmentInputProps,
    editable,
    itemId,
    isItemTypeAsset,
    cancelEditable,
    resetFormData,
  } = formState;

  const handleCancel = useCallback(() => {
    if (itemId) {
      cancelEditable();
    } else {
      resetFormData();
    }
  }, [itemId, cancelEditable, resetFormData]);

  if (!editable || isItemTypeAsset) {
    return null;
  }

  return (
    <Box className="mb-20 flex justify-end space-x-8">
      <Button
        disabled={disabled || attachmentInputProps.isUploading}
        loading={loading}
        theme="info"
        onClick={onNext}>
        {Common.Actions.Next}
      </Button>
      <Button disabled={disabled} onClick={handleCancel}>{Common.Actions.Cancel}</Button>
    </Box>
  );
};

export default NextCancelActionButtons;
