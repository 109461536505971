import { useCallback, useState } from 'react';
import useDebounce from 'app/hooks/useDebounce';
import { SortProps } from 'app/types/schema';
import { HeadCellSortState } from "@procurenetworks/procure-component-library";
import { getPageSettingsFromStorage } from './../../../../utils/paginationSettingsUtil';

interface State {
  activePage: number;
  search: string;
  sortState: HeadCellSortState;
  sorts: SortProps[];
  selectedRowIds: string[];
  showDeleteConfirmation: boolean;
  numberOfRowsPerPage: number;
  selection: boolean
}

interface Props {
  selection?: boolean
}

const useCollapsibleEntityManager = (props: Props) => {
  const { selection } = props;

  // Read rows per page from session storage if available.
  let rowsPerPage = getPageSettingsFromStorage(10);

  const [state, _setState] = useState<State>({
    activePage: 1,
    numberOfRowsPerPage: rowsPerPage,
    search: '',
    sortState: { id: 'id', orderBy: 'asc' },
    sorts: [],
    selectedRowIds: [],
    showDeleteConfirmation: false,
    selection: selection !== undefined ? selection : true
  });

  const debouncedSearchText = useDebounce(state.search);

  const setState = useCallback((nextState: Partial<State>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const onSearchChange = useCallback(
    (nextSearch: string) => {
      setState({ search: nextSearch, activePage: 1 });
    },
    [setState],
  );

  return {
    search: {
      debouncedSearchText,
      value: state.search,
      onChange: onSearchChange,
    },
    table: {
      state,
      setState
    },
  };
};

export type UseCollapsibleEntityManagerReturnType = ReturnType<typeof useCollapsibleEntityManager>;
export default useCollapsibleEntityManager;
