import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReceivablesQueryVariables = Types.Exact<{
  filters: Types.ReceivableFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type ReceivablesSearchSuggestionQuery = {
  __typename?: 'Query';
  receivables: {
    __typename: 'ReceivableConnection';
    totalCount: number;
    edges?: Array<{
      __typename: 'ReceivableEdge';
      node: {
        __typename?: 'ReceivableSchema';
        createdAt: string;
        updatedAt: string;
        id: string;
        carrier?: string | null;
        trackingNumber?: string | null;
        notes?: string | null;
        item?:
          | { __typename?: 'AssetItemSchema'; title: string; description?: string | null }
          | { __typename?: 'AssetKitItemSchema'; title: string; description?: string | null }
          | { __typename?: 'AssetModelItemSchema' }
          | { __typename?: 'InventoryItemSchema'; title: string; description?: string | null }
          | { __typename?: 'InventoryKitItemSchema'; title: string; description?: string | null }
          | null;
        orderRequestItem?: {
          __typename?: 'OrderRequestItemSchema';
          itemId?: string | null;
          description?: string | null;
          upcCode?: string | null;
          website?: string | null;
        } | null;
      };
    }> | null;
    pageInfo?: {
      __typename: 'PageInfo';
      hasNextPage: boolean;
      hasPrevPage: boolean;
      nextCursor?: string | null;
      prevCursor?: string | null;
    } | null;
  };
};

export const ReceivablesSearchSuggestionDocument = gql`
  query receivables(
    $filters: ReceivableFilters!
    $sorts: [SortProps!]
    $limit: Float
    $after: String
    $before: String
  ) {
    receivables(filters: $filters, sorts: $sorts, limit: $limit, after: $after, before: $before) {
      edges {
        node {
          id
          carrier
          trackingNumber
          notes
          orderRequestItem {
            itemId
            description
            upcCode
            website
          }
          item {
            ... on AssetItemSchema {
              title
              description
            }
            ... on AssetKitItemSchema {
              title
              description
            }
            ... on InventoryItemSchema {
              title
              description
            }
            ... on InventoryKitItemSchema {
              title
              description
            }
          }
        }
        __typename
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        nextCursor
        prevCursor
        __typename
      }
      totalCount
      __typename
    }
  }
`;

export function useReceivablesQuery(
  options: Omit<Urql.UseQueryArgs<ReceivablesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReceivablesSearchSuggestionQuery>({
    query: ReceivablesSearchSuggestionDocument,
    ...options,
  });
}
