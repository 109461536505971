import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import FormFieldContainer from 'app/components/Form/FormFieldContainer';
import { useOrderReportsQuery } from '../../graphql/generated/orderRequestReport';
import { SortOrderEnum } from 'app/types/schema';
import useDebounce from 'app/hooks/useDebounce';

interface SelectProps {
  value: string[];
  autoFocus?: boolean;
  onChange: (values: string[]) => void;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, options } = props;
  const selectedValues: any = useMemo(
    () => options?.filter(item => value.includes(item.value)),
    [value],
  );
  return { isDisabled: false, selectedValues };
};

const OrderRequestsSelect = (props: SelectProps) => {

  const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
    const { inputValue } = props;
    const searchValue = useDebounce(inputValue);
    const [{ fetching: isLoading, data: data }] = useOrderReportsQuery({
      variables: { filters: { search: searchValue, }, limit:1000, sorts: [{ sortField: 'createdAt', sortOrder: SortOrderEnum.Desc }], },
    });

    const rows = useMemo(() => {
      return data?.orderRequests.edges?.map(({ node }) => node) || [];
    }, [data?.orderRequests.edges]);

    const options = useMemo(
      () =>
        (rows || [])
          .filter(({orderRequestCode}: any) => orderRequestCode.toLowerCase().includes(inputValue.toLowerCase()))
          .map(({id, orderRequestCode}: any) => ({ value: id, label: orderRequestCode,})),
      [rows],
    );
    return { isLoading, options };
  };

  return (
    <AsyncMultiSelect
      {...props}
      placeholder="All"
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
    />
  );
};

interface FormFieldProps {
  control: any;
  autoFocus?: boolean;
}

const OrderRequestFormField = (props: FormFieldProps) => {
  const { control, autoFocus } = props;

  return (
    <Controller
      control={control}
      name="orderRequestIds"
      render={({ field }) => (
        <FormFieldContainer label="Order Requests">
          <OrderRequestsSelect {...field} autoFocus={autoFocus} />
        </FormFieldContainer>
      )}
    />
  );
};

export default OrderRequestFormField;
