import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';

export interface FormFieldContainerProps {
  className?: string;
  label?: string;
  isRequired?: boolean;
  children?: React.ReactNode;
  error?: FieldError;
}

const FormFieldContainer = forwardRef<HTMLDivElement, FormFieldContainerProps>((props, ref) => {
  const { className, children, label, isRequired, error } = props;

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      {children}
      <FormError error={error} />
    </Box>
  );
});

export default FormFieldContainer;
