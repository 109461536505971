import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateAffiliateMerchantMutationVariables = Types.Exact<{
  input: Types.UpdateAffiliateConfigurationInput;
}>;


export type UpdateAffiliateMerchantMutation = { __typename?: 'Mutation', updateAffiliateConfiguration: { __typename?: 'UpdateAffiliateConfigurationPayload', success: boolean, reason?: string | null, affiliateConfiguration?: { __typename?: 'AffiliateConfigurationSchema', id: string, name: string } | null } };


export const UpdateAffiliateMerchantDocument = gql`
    mutation updateAffiliateMerchant($input: UpdateAffiliateConfigurationInput!) {
  updateAffiliateConfiguration(input: $input) {
    success
    reason
    affiliateConfiguration {
      id
      name
    }
  }
}
    `;

export function useUpdateAffiliateMerchantMutation() {
  return Urql.useMutation<UpdateAffiliateMerchantMutation, UpdateAffiliateMerchantMutationVariables>(UpdateAffiliateMerchantDocument);
};