import { Box, InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';
import Ship from 'app/i18n/Ship';

import { AllowedPermissionsSubjectEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import PackedShipmentItemTable from './components/PackedShipmentItemTable';
import usePackedShippingContainersTable from './hook/usePackedShippingContainersTable';
import useSearchSuggestion from './hook/useSearchSuggestion';

const SearchContainers = () => {
  const {
    search,
    fetching,
    rows,
    totalCount,
    table,
    filtersState,
    onNextPage,
    onPrevPage,
    onReset,
    setFilterState,
  } = usePackedShippingContainersTable();

  const { searchSuggestions, setSearchValue } = useSearchSuggestion({
    siteId: filtersState.destinationSiteIds,
  });

  return (
    <EntityManager
      className="rounded-bl-[12px] rounded-br-[12px] !p-[0px]"
      subject={AllowedPermissionsSubjectEnum.ContainerLabel}>
      <Box className="p-[16px] md:px-[24px] lg:px-[24px]">
        <EntityManager.Title
          documentTitle={Ship.ContainerSearch}
          title={Ship.ContainerSearch}
          titleWrapperClass="border-t-[1px] border-grey-300"
        />
      </Box>

      <PackedShipmentItemTable
        fetching={fetching}
        filterNodes={
          <Box className="flex !w-[100%] gap-[16px] md:flex-wrap md:gap-[24px]">
            <EntityManager.Search
              {...search}
              isDropdown
              removedSearchQoutedValue
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              suggestionData={searchSuggestions || []}
              onChangeSearch={setSearchValue}
            />
          </Box>
        }
        filtersState={filtersState}
        paginationWrapperClass="p-[16px] md:px-[24px] lg:px-[24px] flex-wrap md:gap-[24px]"
        rows={rows}
        setFilterState={setFilterState}
        table={table}
        totalCount={totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        onReset={onReset}
      />
    </EntityManager>
  );
};

export default SearchContainers;
