import { useEffect, useState } from "react";
import { useDistinctNetworkAssetsQuery } from "app/modules/network/graphql/queries/generated/networkAssetDistinctValues";
import { DistictValueSchema } from "app/types/schema";

const useOperatingSystemData = () => {
  const [{ fetching: fetchingOperatingSystems, data: operatingSystemData }] =
  useDistinctNetworkAssetsQuery({
    variables: {
      filters: {
        values: ["operatingSystemPlatform"]
      }
    }
  });
  const [operatingSystems, setOperatingSystems] = useState<DistictValueSchema[]>();

  useEffect(() => {
    const operatingSystems =
      operatingSystemData?.distinctNetworkAssets.distinctValues.operatingSystemPlatform || [];
    const apple = operatingSystems.find((os) => os.value === 'darwin');
    const linux = operatingSystems.find((os) => os.value === 'linux');
    const windows = operatingSystems.find((os) => os.value === 'Windows');
    const other = operatingSystems.find((os) => os.value === null);

    const operatingSystemDetails = [
      { value: 'Apple Devices', count: apple?.count || 0 },
      { value: 'Windows Devices', count: windows?.count || 0 },
      { value: 'Linux Devices', count: linux?.count || 0 },
      { value: 'Other', count: other?.count || 0 },
    ];
    setOperatingSystems(operatingSystemDetails);
  }, [operatingSystemData, setOperatingSystems]);

  return {
    fetchingOperatingSystems,
    operatingSystems
  };
};
export type UseOperatingSystemDataReturnType = ReturnType<typeof useOperatingSystemData>;
export default useOperatingSystemData;
