import { Layout } from '@procurenetworks/procure-component-library';
import { withRouter } from 'app/libs/navigation';
import { RouteComponentProps } from 'app/libs/navigation';
import DeletedInventory from 'app/modules/inventory/views/DeletedInventory';

function DeletedInventoryPage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  return (
    <Layout>
      <DeletedInventory inventoryId={match.params.id} />
    </Layout>
  );
}

export default withRouter(DeletedInventoryPage);
