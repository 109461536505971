import { css } from '@emotion/css';

export const reportDateRangeSelectorStyles = css`
  .MuiOutlinedInput-input {
    font-size: 13px;
  }

  .MuiSvgIcon-root {
    font-size: 24px;
    margin-right: 0px;
  }
`;
