import { KeyboardEvent, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormPasswordInput from 'app/components/ProcureForm/FormPasswordInput';
import Common from 'app/i18n/Common';
import SetPassword from 'app/i18n/SetPassword';
import { ButtonHeight } from 'app/types/button';
import Box from 'app/ui-components/Box';

import { SetPasswordFormProps } from '../../types';
import { SET_PASSWORD_RULES as Rules } from './utils';

const DEFAULT_VALUES = { password: '', confirmPassword: '' };
const LOGO = `${process.env.PUBLIC_URL}/images/procure-square-logo-96px.png`;

function SetPasswordForm(props: SetPasswordFormProps) {
  const { disabled, onSubmit } = props;
  const { control, handleSubmit, getValues } = useForm({ defaultValues: DEFAULT_VALUES });

  const onFormSubmit = useMemo(
    () => handleSubmit(({ password }) => onSubmit({ password })),
    [handleSubmit, onSubmit],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <img alt="Logo" className="mx-auto" src={LOGO} />
      <h2 className="mt-12 text-center text-[36px] font-semibold text-grey-900">
        {SetPassword.CreatePassword}
      </h2>
      <Box className="mt-16">
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <FormPasswordInput
              {...field}
              isRequired
              className="mb-[24px] w-full rounded-none"
              error={fieldState.error}
              formLabel={SetPassword.FormLabels.Password}
              placeholder={SetPassword.FormLabels.Password}
            />
          )}
          rules={Rules.password}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field, fieldState }) => (
            <FormPasswordInput
              {...field}
              isRequired
              className="mb-[24px] w-full rounded-none"
              error={fieldState.error}
              formLabel={SetPassword.FormLabels.ConfirmPassword}
              placeholder={SetPassword.FormLabels.ConfirmPassword}
            />
          )}
          rules={{
            validate: (value: string) => {
              const passwordEqual = getValues().password === value;
              return passwordEqual || SetPassword.FormValidationMessages.PasswordMismatch || false;
            },
          }}
        />
        <Box className="flex justify-center">
          <Button
            classes="!min-w-[100%]"
            disabled={disabled}
            height={ButtonHeight.fill}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}>
            {Common.Actions.CreatePassword}
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default SetPasswordForm;
