import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateWishlistMutationVariables = Types.Exact<{
  input: Types.CreateWishlistInput;
}>;


export type CreateWishlistMutation = { __typename?: 'Mutation', createWishlist: { __typename?: 'CreateWishlistPayload', success: boolean, reason?: string | null, wishlist?: { __typename?: 'WishlistSchema', id: string, name: string } | null } };


export const CreateWishlistDocument = gql`
    mutation createWishlist($input: CreateWishlistInput!) {
  createWishlist(input: $input) {
    success
    reason
    wishlist {
      id
      name
    }
  }
}
    `;

export function useCreateWishlistMutation() {
  return Urql.useMutation<CreateWishlistMutation, CreateWishlistMutationVariables>(CreateWishlistDocument);
};