import { useCallback, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';

interface ImageState {
  imageUrl: string;
  open: boolean;
}

const useImageViewDialogState = () => {
  const [state, _setState] = useState<ImageState>({
    open: false,
    imageUrl: '',
  });

  const setImagePreviewState = useCallback((nextState: Partial<ImageState>) => {
    _setState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const onShow = useCallback(
    (image: string) => {
      SnackbarService.clear();
      setImagePreviewState({
        imageUrl: image,
        open: true,
      });
    },
    [setImagePreviewState],
  );

  const onClose = useCallback(() => {
    setImagePreviewState({
      open: false,
    });
  }, [setImagePreviewState]);

  return {
    actions: {
      onClose,
      onShow,
    },
    state,
  };
};

export type UseImageViewDialogStateReturnType = ReturnType<typeof useImageViewDialogState>;

export default useImageViewDialogState;
