import { cloneDeep } from 'lodash';
import { AssetReminderInput } from 'app/modules/assets/components/AssetForm/components/AssetReminderField/components/AssetReminderForm/types';

import {
  AssetItemReminder,
  AssetItemReminderTypeEnum,
  AssetItemSchema,
} from '../../../../../../types/schema';
import { format } from '../../../../../../utils/cost';
import { isCategoryActive } from '../../../../../categories/utils/utils';
import { isCompanyActive } from '../../../../../contacts/utils/utils';

export const prepareFormData = (assetItem: AssetItemSchema) => {
  const {
    id,
    sku,
    attachments,
    unitCost,
    brand,
    category,
    vendor,
    type,
    pickableThroughOrderRequest,
    costOverride,
    title,
    description,
    mName,
    externalProductCodes,
    protectedAttachments,
    serialNumber,
    purchaseDate,
    modelNumber,
    warrantyExpiryDate,
    parentId,
    manufacturerId,
    replacementDate,
    ipAddress,
    macAddress,
    cpuBrand,
    formattedDescription,
  } = assetItem;

  const externalUpcCodeData = externalProductCodes?.[0] || undefined;

  return {
    type,
    noUpcCode: Boolean(!externalUpcCodeData?.code),
    upcCode: externalUpcCodeData?.code || '',
    sku,
    title,
    mName,
    modelNumber,
    brand,
    categoryId: category?.id && isCategoryActive(category?.status) ? category?.id : '',
    serialNumber,
    description: formattedDescription || description || '',
    vendorId: vendor?.id && isCompanyActive(vendor?.status) ? vendor?.id : '',
    unitCost: unitCost === null || unitCost === undefined ? '0.00' : format(unitCost),
    costOverride: costOverride === null ? '0' : costOverride,
    reminder: [],
    purchaseDate: purchaseDate || null,
    warrantyExpiryDate: warrantyExpiryDate || null,
    pickableThroughOrderRequest,
    attachments: attachments || [],
    replacementDate: replacementDate || null,
    protectedAttachments,
    id,
    parentId: parentId || '',
    manufacturerId: manufacturerId || '',
    macAddress: macAddress || '',
    ipAddress: ipAddress || '',
    cpuBrand: cpuBrand || '',
  };
};

export const prepareRemindersFormData = (
  reminders: AssetItemReminder[],
  assetItemId: string,
): AssetReminderInput[] => {
  const _reminders = cloneDeep(reminders);
  const preparedReminders = _reminders?.map((reminder) => {
    const { cron, startReminderAt, type, _id, note, recurringInterval, notifyUserIds } = reminder;
    return {
      assetItemId: assetItemId,
      isMaintenance: type === AssetItemReminderTypeEnum?.Maintenance,
      cron,
      note: reminder?.formattedNote || reminder?.note,
      notifyUserIds,
      id: _id,
      startReminderAt: new Date(startReminderAt) as unknown as string,
      recurringInterval,
    } as AssetReminderInput;
  });
  return preparedReminders || [];
};
