const Role = {
  Roles: 'Roles',
  NewRole: 'New Role',
  UserRoles: 'User Roles',
  EditRole: 'Edit Role',
  FormLabels: {
    Name: 'Role Name',
    Description: 'Description',
    Organization: 'Organization',
  },
  FormValidationMessages: {
    NameRequired: 'Please enter a User Role name between 3 and 30 characters.',
    DescriptionMaxLength: 'Please enter a Description up to 500 characters.',
    OrganizationRequired: 'Please select an Organization.',
  },
  FormPlaceholders: {
    Organization: 'Add Organization',
    Search: 'Search Roles',
  },
};

export default Role;
