import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';

import Common from '../../../../../../i18n/Common';
import Import from '../../../../../../i18n/Import';
import { UseImportFormReturnType } from '../../../ImportForm/hook/useImportFormState';
import MappingField from '../MappingField';

interface Props {
  defaultValues: any;
  state: UseImportFormReturnType['state'];
  disabled?: boolean;
  onBack: () => void;
  onSubmit: (values: any) => void;
}

const MappingForm = (props: Props) => {
  const { defaultValues, state, onBack, disabled, onSubmit } = props;

  const { control, handleSubmit, watch } = useForm({
    defaultValues,
  });
  const formValues = watch();

  const items = useMemo(() => {
    return (state?.headers || []).map((header) => {
      return {
        label: header,
        value: header,
      };
    });
  }, [state.headers]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  return (
    <div className="mt-16">
      {state.entityMap.map((entity) => (
        <MappingField
          key={entity.entityField}
          control={control}
          disabled={disabled}
          entity={entity}
          formValues={formValues}
          items={items}
        />
      ))}
      <Grid container justifyContent="end" spacing={2}>
        <Grid item>
          <Button disabled={disabled} onClick={onBack} classes="min-w-[120px] h-[44px]">
            {Common.Actions.Back}
          </Button>
        </Grid>
        <Grid item>
          <Button
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[120px] h-[44px]">
            {Import.Actions.Import}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MappingForm;
