import { css, cx } from '@emotion/css';
import Checkbox from '@mui/material/Checkbox';
import noop from 'lodash/noop';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import { PermissionGroupType, PermissionItemType } from '../../types';

interface Props {
  disabled?: boolean;
  hideNotSelectedItems?: boolean;
  filterGroup?: PermissionGroupType;
  selectedItemsOfGroup: PermissionItemType[];
  onAddMultiple: (items: PermissionItemType[]) => void;
  onOptionClick: (item: PermissionItemType) => void;
  onRemoveMultiple: (items: PermissionItemType[]) => void;
}

const checkboxStyles = css`
  color: #797979 !important;

  &.MuiCheckbox-indeterminate,
  &.Mui-checked {
    color: #66cc33 !important;
  }
`;

function PermissionList(props: Props) {
  const {
    disabled,
    hideNotSelectedItems,
    filterGroup,
    selectedItemsOfGroup,
    onAddMultiple,
    onOptionClick,
    onRemoveMultiple,
  } = props;

  if (!filterGroup) {
    return null;
  }

  const renderLabel = () => {
    const selectedItemsLength = selectedItemsOfGroup.length;
    const isGroupSelected = filterGroup.options.length === selectedItemsLength;
    const indeterminate = !isGroupSelected && selectedItemsLength > 0;

    const onGroupClick = () => {
      if (indeterminate || isGroupSelected) {
        onRemoveMultiple(filterGroup.options);
      } else {
        onAddMultiple(filterGroup.options);
      }
    };

    return (
      <Stack
        alignItems="center"
        className="cursor-pointer"
        justifyContent="between"
        onClick={onGroupClick}>
        <p className="text-13 font-semibold text-grey-800">{filterGroup.label}</p>
        <Checkbox
          checked={isGroupSelected}
          className={checkboxStyles}
          indeterminate={indeterminate}
          size="small"
        />
      </Stack>
    );
  };

  const renderPermissions = () => {
    return (
      <Stack direction="col">
        {filterGroup.options.map((option, index) => {
          const isSelected = selectedItemsOfGroup.some((item) => item.value === option.value);
          const disabled = option.disabled;
          if (!isSelected && hideNotSelectedItems) {
            return null;
          }
          return (
            <Stack
              key={`${option.value}_${index}`}
              alignItems="center"
              className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              justifyContent="between"
              onClick={disabled ? noop : () => onOptionClick(option)}>
              <p className="text-12 text-grey-800">{option.label}</p>
              <Checkbox
                checked={isSelected}
                className={cx(checkboxStyles, { 'cursor-not-allowed opacity-50': disabled })}
                disabled={disabled}
                size="small"
              />
            </Stack>
          );
        })}
      </Stack>
    );
  };

  return (
    <Box className={cx('w-full', disabled ? 'pointer-events-none' : '')}>
      {renderLabel()}
      {renderPermissions()}
    </Box>
  );
}

export default PermissionList;
