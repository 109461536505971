import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type LabelItemLocationsTableRowFragment = { __typename?: 'ItemLocationSchema', id: string, itemId: string, site: { __typename?: 'LocationSchema', id: string, name: string }, location: { __typename?: 'LocationSchema', id: string, name: string }, item: { __typename?: 'AssetItemSchema', id: string, title: string, sku: string } | { __typename?: 'AssetKitItemSchema', id: string, title: string, sku: string } | { __typename?: 'AssetModelItemSchema' } | { __typename?: 'InventoryItemSchema', id: string, title: string, sku: string } | { __typename?: 'InventoryKitItemSchema', id: string, title: string, sku: string } };

export const LabelItemLocationsTableRowFragmentDoc = gql`
    fragment LabelItemLocationsTableRow on ItemLocationSchema {
  id
  itemId
  site {
    id
    name
  }
  location {
    id
    name
  }
  item {
    ... on InventoryItemSchema {
      id
      title
      sku
    }
    ... on InventoryKitItemSchema {
      id
      title
      sku
    }
    ... on AssetItemSchema {
      id
      title
      sku
    }
    ... on AssetKitItemSchema {
      id
      title
      sku
    }
  }
}
    `;