import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type JobStatusSubscriptionVariables = Types.Exact<{
  data: Types.JobByCreatorSubscriptionInput;
}>;


export type JobStatusSubscription = { __typename?: 'Subscription', jobByCreator: { __typename?: 'JobByCreatorSubscriptionPayload', operation: Types.JobSubscriptionOperationEnum, job: { __typename?: 'JobSchema', id: string, name: string, createdAt: string, status: Types.JobStatusEnum, error?: string | null, message?: string | null, importJobMetadata?: { __typename?: 'ImportJobMetadataType', importErrorMessageUrl?: string | null, inValidDataCount?: number | null, validDataCount?: number | null } | null } } };


export const JobStatusDocument = gql`
    subscription jobStatus($data: JobByCreatorSubscriptionInput!) {
  jobByCreator(data: $data) {
    operation
    job {
      id
      name
      createdAt
      status
      error
      message
      importJobMetadata {
        importErrorMessageUrl
        inValidDataCount
        validDataCount
      }
    }
  }
}
    `;

export function useJobStatusSubscription<TData = JobStatusSubscription>(options: Omit<Urql.UseSubscriptionArgs<JobStatusSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<JobStatusSubscription, TData>) {
  return Urql.useSubscription<JobStatusSubscription, TData, JobStatusSubscriptionVariables>({ query: JobStatusDocument, ...options }, handler);
};