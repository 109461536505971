import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type ContactsFragment = { __typename?: 'ContactSchema', createdAt: string, updatedAt: string, addressId: string, companyId: string, firstName: string, lastName: string, companySqlId?: number | null, email?: string | null, fax?: string | null, homeContactNumber?: string | null, mobileContactNumber?: string | null, officeContactNumber?: string | null, title?: string | null, tenantId: string, createdById: string, deletedById?: string | null, updatedById: string, deletedAt?: string | null, id: string };

export const ContactsFragmentDoc = gql`
    fragment Contacts on ContactSchema {
  createdAt
  updatedAt
  addressId
  companyId
  firstName
  lastName
  companySqlId
  email
  fax
  homeContactNumber
  mobileContactNumber
  officeContactNumber
  title
  tenantId
  createdById
  deletedById
  updatedById
  deletedAt
  id
}
    `;