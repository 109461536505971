import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateInventoryKitItemsMutationVariables = Types.Exact<{
  input: Types.UpdateInventoryKitItemsInput;
}>;


export type UpdateInventoryKitItemsMutation = { __typename?: 'Mutation', updateInventoryKitItems: { __typename?: 'MutationResponse', success: boolean } };


export const UpdateInventoryKitItemsDocument = gql`
    mutation updateInventoryKitItems($input: UpdateInventoryKitItemsInput!) {
  updateInventoryKitItems(input: $input) {
    success
  }
}
    `;

export function useUpdateInventoryKitItemsMutation() {
  return Urql.useMutation<UpdateInventoryKitItemsMutation, UpdateInventoryKitItemsMutationVariables>(UpdateInventoryKitItemsDocument);
};