import React, { CSSProperties, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useAccessControl } from 'app/components/AccessControl';
import FormFileUpload from 'app/components/Form/FormFileUpload';
import Assets from 'app/i18n/Assets';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import UploadedFilePreview from '../../../../../../components/Form/FormFileUpload/UploadedFilePreview';
import useKitSummary from '../../../../../components/KitSummary/context/useKitSummary';
import {
  prepareItemsForFilePreview,
  prepareKitItemAttachments,
} from '../../../../hook/useAssetForm/utils';
import { AssetFieldProps } from '../../types';
import { thumbsContainer } from '../../../../../../components/Form/FormFileUpload/utils';

const AssetAttachmentField = (props: AssetFieldProps) => {
  const { formState, disabled } = props;
  const {
    control,
    protectedAttachmentsInputProps,
    attachmentInputProps,
    isItemTypeAsset,
    isItemTypeAssetKit,
    editable,
  } = formState;

  const { workspacePermissions } = useCurrentUser();
  const canViewProtectedDocuments = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.AssetProtectedDocument,
  );

  const { kitState } = useKitSummary();

  const kitRowsAttachments = useMemo(() => {
    const attachments = kitState?.rows?.flatMap((row) => row?.attachments);
    return prepareKitItemAttachments(attachments);
  }, [kitState?.rows]);

  const kitAttachments = useMemo(() => {
    if (editable) {
      return attachmentInputProps?.attachments;
    } else {
      return kitState?.formState?.defaultAttachments;
    }
  }, [attachmentInputProps?.attachments, editable, kitState?.formState?.defaultAttachments]);

  const kitPreviewFileAttachments = useMemo(() => {
    return prepareItemsForFilePreview([
      ...attachmentInputProps?.attachments,
      ...kitRowsAttachments,
    ]);
  }, [attachmentInputProps?.attachments, kitRowsAttachments]);

  return (
    <>
      <div className="mb-20">
        <Controller
          control={control}
          name="attachments"
          render={({ field, fieldState }) => (
            <FormFileUpload
              {...field}
              {...attachmentInputProps}
              key="attachmentInput"
              attachments={isItemTypeAssetKit ? kitAttachments : attachmentInputProps.attachments}
              disabled={disabled}
              error={fieldState.error}
              id="attachmentInput"
              label={Assets.FormLabels.Attachments}
              previewImages={isItemTypeAsset}
            />
          )}
        />

        {isItemTypeAssetKit && (
          <aside style={thumbsContainer as CSSProperties}>
            <UploadedFilePreview
              disabled={disabled || !editable}
              files={kitPreviewFileAttachments}
              removeFileById={attachmentInputProps?.removeFileById}
            />
          </aside>
        )}
      </div>

      {canViewProtectedDocuments && isItemTypeAsset ? (
        <div className="mb-20">
          <Controller
            control={control}
            name="protectedAttachments"
            render={({ field, fieldState }) => (
              <FormFileUpload
                {...field}
                {...protectedAttachmentsInputProps}
                key="protectedAttachmentInput"
                disabled={disabled}
                error={fieldState.error}
                id="protectedAttachmentInput"
                label={Assets.FormLabels.protectedAttachments}
              />
            )}
          />
        </div>
      ) : undefined}
    </>
  );
};

export default AssetAttachmentField;
