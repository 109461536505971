import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormTextInput from 'app/components/Form/FormTextInput';
import Network from 'app/i18n/Network';

type Props = {
  control: any;
  className?:string
};

const NetworkForm = (props: Props) => {
  const { control,className="mt-[0px]" } = props;

  return (
    <>
      <Typography
        className='text-14px text-grey-900 mt-[24px] font-bold'>
        {Network.FormLabels.Network.Title}
      </Typography>
      <Grid container paddingTop={1} spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="networkIface"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Network.Iface}
                placeholder={Network.FormLabels.Network.Iface}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="networkIpv4"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Network.IPv4}
                placeholder={Network.FormLabels.Network.IPv4}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="networkIpv6"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Network.IPv6}
                placeholder={Network.FormLabels.Network.IPv6}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="networkMac"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Network.MAC}
                placeholder={Network.FormLabels.Network.MAC}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="networkType"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isDisabled
                containerClassName={className}
                error={fieldState.error}
                label={Network.FormLabels.Network.Type}
                placeholder={Network.FormLabels.Network.Type}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NetworkForm;
