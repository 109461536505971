import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import {
  Cost,
  QuantityInputNode,
  SKUItem,
} from 'app/modules/assetsInventory/Views/Confirmation/EditTransactions/utils/valueNodes';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';

import TableImageView from '../../../../components/TableImageView';
import BrandLabelNode from '../../../components/BrandLabelNode';
import CategoryFilterLabelNode from '../../../components/CategoryFilterLabelNode';
import ModelLabelNode from '../../../components/ModelLabelNode';
import VendorFilterLabelNode from '../../../components/VendorFilterLabelNode';
import ReadMore from 'app/modules/components/ReadMore';

export const getInventoryTableColumns = (options?: {
  isSiteIdSelected?: boolean;
  siteId?: string;
  focusId?: string;
  isEditable?: boolean;
  setFocusId?: React.Dispatch<React.SetStateAction<string>>;
  focusIndex?: number;
  setFocusIndex?: React.Dispatch<React.SetStateAction<number>>;
}): DataGridHeadCell[] => {
  const { isSiteIdSelected, setFocusId, focusId, isEditable, focusIndex, setFocusIndex } =
    options || {};
  const hideColumIds = isSiteIdSelected ? [] : ['location', 'minimumQuantity', 'maximumQuantity'];

  return (
    [
      {
        id: 'attachments',
        label: 'Image',
        value: 'attachments',
        valueNode: ({ row, state }) => {
          return (
            <TableImageView
              attachments={row.attachments}
              onImageClick={state?.extraProps?.showImageViewDialog}
            />
          );
        },
        width: 100,
        sortable: false,
      },
      {
        id: 'sku',
        label: 'SKU',
        value: 'sku',
        valueNode: SKUItem,
        sortable: true,
        width: 130,
      },
      {
        id: 'title',
        label: 'Title',
        value: 'title',
        sortable: true,
        width: 220,
      },
      {
        id: 'Model',
        label: 'Model',
        classes: dropdownHeaderStyle,
        labelNode: ModelLabelNode,
        value: 'model',
        sortable: true,
        width: 260,
      },
      {
        id: 'categoryId',
        label: 'Category',
        value: 'categoryId',
        classes: dropdownHeaderStyle,
        labelNode: CategoryFilterLabelNode,
        valueGetter: (params: any) => {
          const { category } = params?.row || {};
          return category?.name;
        },
        width: 240,
        sortable: false,
      },
      {
        id: 'description',
        label: 'Description',
        value: 'description',
        sortable: false,
        width: 220,
        valueNode: ({ row }) => <ReadMore text={row.description} />,
      },
      {
        id: 'vendorId',
        label: 'Vendor',
        value: 'vendorId',
        classes: dropdownHeaderStyle,
        labelNode: VendorFilterLabelNode,
        valueGetter: (params: any) => {
          const { vendor } = params?.row || {};
          return vendor?.companyName;
        },
        sortable: false,
        width: 230,
      },
      {
        id: 'brand',
        classes: dropdownHeaderStyle,
        label: 'Brand',
        labelNode: BrandLabelNode,
        value: 'brand',
        sortable: false,
        width: 220,
      },
      {
        id: 'manufacturerId',
        label: 'Manufacturer',
        value: 'manufacturerId',
        sortable: false,
        width: 150,
        valueGetter: (params: any) => params?.row?.manufacturer?.name,
      },
      {
        id: 'totalQuantity',
        label: 'Quantity',
        value: 'totalQuantity',
        sortable: false,
        rowAlign: 'right',
        width: 100,
      },
      {
        id: 'minimumQuantity',
        label: 'Min',
        value: 'minimumQuantity',
        sortable: false,
        rowAlign: 'right',
        valueNode: QuantityInputNode,
        width: 100,
      },
      {
        id: 'maximumQuantity',
        label: 'Max',
        value: 'maximumQuantity',
        sortable: false,
        rowAlign: 'right',
        valueNode: QuantityInputNode,
        width: 100,
      },
      {
        id: 'cost',
        label: 'Cost',
        value: 'cost',
        sortable: false,
        rowAlign: 'right',
        valueNode: Cost,
        width: 80,
      },
      {
        id: 'pickableThroughOrderRequest',
        label: 'Requestable?',
        value: 'pickableThroughOrderRequest',
        rowAlign: 'center',
        valueNode: ({ row, state }) => {
          const { extraProps } = state;
          return (
            <FormCheckBoxInput
              medium
              classNames={`${row.editable ? 'cursor-pointer' : 'cursor-default'}`}
              disabled={!row.editable || !isEditable}
              value={
                row.editable && isEditable
                  ? row.editableFields?.pickableThroughOrderRequest
                  : row.pickableThroughOrderRequest
              }
              onChange={(value) => {
                if (extraProps?.setEditableSiteState) {
                  extraProps.setEditableSiteState(row.id, {
                    pickableThroughOrderRequest: value,
                  });
                }
              }}
            />
          );
        },
        width: 150,
        type: 'boolean',
      },
    ] as DataGridHeadCell[]
  )
    .map((item) => {
      return {
        ...item,
      } as DataGridHeadCell;
    })
    .filter((item) => {
      return !hideColumIds.includes(item.id);
    });
};
