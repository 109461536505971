import React, { FC, ReactNode } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

interface CustomSvgIconProps extends SvgIconProps {
  svgContent: ReactNode;
}

const CustomSvgIcon: FC<CustomSvgIconProps> = ({ svgContent, ...iconProps }) => {
  return <SvgIcon {...iconProps}>{svgContent}</SvgIcon>;
};

export default CustomSvgIcon;
