import { dateValidator, textValidator } from 'app/components/Form/utils/validators';
import Assets from 'app/i18n/Assets';

import { AssetItemReminderCronEnum } from './types';

export const getReminderRepeatOptions = () => {
  return [
    {
      label: 'Day(s)',
      value: AssetItemReminderCronEnum.Day,
    },
    {
      label: 'Week(s)',
      value: AssetItemReminderCronEnum.Week,
    },
    {
      label: 'Month(s)',
      value: AssetItemReminderCronEnum.Month,
    },
    {
      label: 'Years(s)',
      value: AssetItemReminderCronEnum.Year,
    },
  ];
};

export const getReminderIntervalLimit = (repeatIn: AssetItemReminderCronEnum) => {
  switch (repeatIn) {
    case AssetItemReminderCronEnum.Day:
      return 31;
    case AssetItemReminderCronEnum.Week:
      return 52;
    case AssetItemReminderCronEnum.Month:
      return 12;

    default:
      return;
  }
};

export const ASSET_REMINDER_FORM_RULES = {
  note: {
    validate: textValidator(Assets.FormValidationMessages.Reminders.NoteRequired),
  },
  corn: {
    validate: textValidator(Assets.FormValidationMessages.Reminders.CornRequired),
  },
  date: {
    validate: dateValidator(Assets.FormValidationMessages.Reminders.DateRequired),
  },
};
