import { useCallback, useEffect, useMemo } from 'react';
import {
  DataGrid,
  DataGridHeadCell,
  GridColDef,
  TitleBar,
} from '@procurenetworks/procure-component-library';
import { keyBy } from 'lodash';
import Reports from 'app/i18n/Reports';
import { customDropdownHeaderStyle, dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';
import { hideColumnSelectStyles } from 'app/modules/reports/utils/styles';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import { renderAddColumnAction, renderColumnSelector } from './renderers';
import {
  CUSTOM_REPORT_ASSET_ROWS,
  CUSTOM_REPORT_INVENTORY_ROWS,
  getReportColumns,
  isColumnsContainsAssetColumns,
  removeAssetColumns,
} from './utils';
import { Box } from '@mui/material';

interface Props {
  value: string[];
  transactionTypes: string[];
  itemType: TransactionEntityTypeEnum;
  onChange: (value: string[]) => void;
  persistKey?: string;
}

const CustomReportSelectColumns = ({
  value,
  onChange,
  itemType,
  persistKey,
  transactionTypes,
}: Props) => {
  const customReportColumns = useMemo(
    () => getReportColumns(itemType, transactionTypes),
    [itemType, transactionTypes],
  );
  const columnConfig = useMemo(() => keyBy(customReportColumns, 'key'), [customReportColumns]);

  const onAddColumn = useCallback(() => {
    const columnToAdd = customReportColumns.find((column) => !value.includes(column.key));
    onChange(columnToAdd ? [...value, columnToAdd.key] : value);
  }, [onChange, value]);

  const getColumnSelectOptions = useCallback(
    (column: string) => {
      let coumns: { key: string; label: string }[] = [];
      if (columnConfig[column]) {
        coumns = [
          columnConfig[column],
          ...customReportColumns.filter((column) => !value.includes(column.key)),
        ];
      } else {
        coumns = customReportColumns;
      }
      return coumns.map((column) => ({ label: column.label, value: column.key }));
    },
    [value, columnConfig],
  );

  const onChangeColumn = useCallback(
    (column: string, index: number) => {
      onChange([...value.slice(0, index), column, ...value.slice(index + 1)]);
    },
    [onChange, value],
  );

  const headers = useMemo<DataGridHeadCell[]>(() => {
    const selectedColumnsHeaders = value.map((column, index) => {
      const { label } = columnConfig[column] || {};

      return {
        id: column,
        value: column,
        classes: customDropdownHeaderStyle,
        label,
        labelNode: () =>
          renderColumnSelector({
            value: column,
            options: getColumnSelectOptions(column),
            onChange: (value) => onChangeColumn(value, index),
          }),
        width: 200
      };
    });

    return [
      ...selectedColumnsHeaders,
      {
        id: 'add',
        value: 'add',
        labelNode: () =>
          renderAddColumnAction({
            disabled: value.length >= customReportColumns.length,
            onClick: onAddColumn,
          }),
        width: 170,
      },
    ];
  }, [getColumnSelectOptions, onAddColumn, onChangeColumn, value, columnConfig]);

  const columns: GridColDef[] = headers.map((headCell: any, index: number) => {
    const column: GridColDef = {
      align: headCell.rowAlign,
      field: headCell.value,
      headerAlign: headCell.labelAlign,
      headerName: headCell.label,
      headerClassName: headCell.classes,
      renderCell: headCell.valueNode
        ? (parameters: any) => {
            return <headCell.valueNode headCell={headCell} row={parameters.row} />;
          }
        : undefined,
      renderHeader: headCell.labelNode
        ? () => {
            const LabelComponent = headCell.labelNode;
            return <LabelComponent headCell={headCell} />;
          }
        : undefined,
      sortable: headCell.sortable,
      hideable: !index ? false : true,
      width: headCell.width ? headCell.width : 220,
    };

    return column;
  });

  const rows = useMemo(() => {
    switch (itemType) {
      case TransactionEntityTypeEnum.Asset:
        return CUSTOM_REPORT_ASSET_ROWS;
      case TransactionEntityTypeEnum.Inventory:
        return CUSTOM_REPORT_INVENTORY_ROWS;
      default:
        return [];
    }
  }, [itemType]);

  useEffect(() => {
    if (itemType === TransactionEntityTypeEnum.Inventory && isColumnsContainsAssetColumns(value)) {
      onChange?.(removeAssetColumns(value));
    }
  }, [itemType]);
  return (
    <Stack className={hideColumnSelectStyles} direction="col" spacing="space-y-24">
      <TitleBar title={Reports.SelectReportColumns} className='!pl-[0px]'/>
      <Box className='border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
        <DataGrid columns={columns} persistKey={persistKey} rows={rows} borderRadius='12px' className='custom-report-table'/>
      </Box>
    </Stack>
  );
};

export default CustomReportSelectColumns;
