const SetPassword = {
  CreatePassword: 'Create Password',
  FormLabels: {
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
  },
  FormValidationMessages: {
    PasswordRequired: 'Please enter a Password.',
    PasswordMismatch: 'Please re-enter the password, as this password must match.',
    InvalidPassword: 'Password should be more then or equal to 6 characters',
  },
};
export default SetPassword;
