import RestockForm from "./components/RestockForm";
import RestockTable from "./components/RestockTable";

interface Composition {
    Form: typeof RestockForm;
    Table: typeof RestockTable;
}

const RestockManager : Composition = {
    Form: RestockForm,
    Table: RestockTable,
}

export default RestockManager