import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Ship from 'app/i18n/Ship';
import SitesTableFilter from 'app/modules/sites/components/SitesTableFilter';
import {
  LocationTypeEnum,
  ShippingTransactionStatusEnum,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from 'app/types/schema';

import ShipmentStatusView from '../../searchShipments/components/ShipmentStatusView';

const getDistinctQuerySiteFilter = (filtersState: any) => {
  const transactionFilters = {
    destinationSiteIds: [],
    requestorIds: [],
    deliverToIds: [],
    departmentIds: [],
    statuses: [ShippingTransactionStatusEnum.Packed],
    search: filtersState?.search || '',
  };

  return {
    distinctTableName: ValuesInEntitiesTableNameEnum.ShippingTransactions,
    filters: transactionFilters,
  };
};

const getDestinationSiteHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'destinationSiteIds',
  label: 'Ship To',
  labelNode: (props) => {
    const { extraProps } = props.state;
    const { filtersState, setFilterState } = extraProps || {};
    return (
      <div>
        <SitesTableFilter
          key="destination-site-table-filter"
          isDistinct
          name="toSite"
          placeholder={Ship.FormLabels.ShipTo}
          queryVariables={{
            types: [LocationTypeEnum.Site],
            filterKey: 'shippingTransactionFilters',
            distinctKeys: [ValuesInEntitiesDistinctByKeysEnum.DestinationSiteId],
            ...getDistinctQuerySiteFilter(filtersState),
          }}
          value={filtersState?.destinationSiteIds}
          onChange={(value: string[]) => {
            setFilterState?.({
              destinationSiteIds: value,
            });
          }}
        />
      </div>
    );
  },
  value: 'destinationSiteName',
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.destinationSite?.name}>
      {row?.destinationSite?.name}
    </span>
  ),
  width: 300,
  ...(config || {}),
});

export const getShipmentTableColumns = (context: {
  isUnPackPermission: boolean;
}): DataGridHeadCell[] => {
  const { isUnPackPermission } = context;
  const headers: DataGridHeadCell[] = [
    {
      id: 'containerId',
      label: 'Container ID',
      value: 'containerId',
      sortable: true,
      width: 300,
    },
    getDestinationSiteHeadCell(),
    {
      id: 'status',
      label: 'Status',
      value: 'status',
      valueNode: ({ row }) => {
        return <ShipmentStatusView status={ShippingTransactionStatusEnum.Packed} />;
      },
      width: 300,
      sortable: false,
    },
  ];
  if (isUnPackPermission) {
    headers.push({
      id: 'action',
      label: 'Action',
      value: 'action',
      valueNode: ({ row, state }) => {
        const {
          extraProps = {
            onUnPack: null,
            unpackContainerId: null,
            unPackContainerLoading: false,
          },
        } = state;
        const { onUnPack, unpackContainerId, unPackContainerLoading } = extraProps;

        return (
          <Button
            classes="min-w-[91px] h-[44px]"
            disabled={unPackContainerLoading}
            loading={
              [row.trackingId, row.id, row.containerId].includes(unpackContainerId) &&
              unPackContainerLoading
            }
            theme="info"
            onClick={() => {
              onUnPack?.(row);
            }}>
            Unpack
          </Button>
        );
      },
      width: 110,
    });
  }
  return headers;
};
