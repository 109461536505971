import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import { processSearch } from 'app/utils/processSearch';

import { VendorTypeEnum } from '../../types';
import useGetImpactProductData from '../useGetImpactProductData';

const useSearchSuggestion = (vendorType: VendorTypeEnum) => {

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const { data } = useGetImpactProductData({query: searchedValue, vendorType: vendorType});


  useEffect(() => {
    const result = data?.map((item: any) => {
      const {result, searchBy} = processSearch(["name"], searchedValue, item)

      return {
        label: searchBy,
        id: item.id,
        value: result
      };
    });
    setSearchSuggestions(result);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue.trim());
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
