import { Button } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import Common from 'app/i18n/Common';

type Props = {
  open: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  className?: string;
};
const WarningAlert = (props: Props) => {
  const { message, onConfirm, onClose, open, className } = props;

  return (
    <Modal
      disableBackdropClick
      actions={
        <div className="flex gap-[16px]">
          <div>
            <Button theme="success" onClick={onConfirm} classes="min-w-[94px] h-[44px]">
              {Common.Actions.Yes}
            </Button>
          </div>
          <div className="ml-1">
            <Button theme="danger" onClick={onClose} classes="min-w-[94px] h-[44px]">
              {Common.Actions.No}
            </Button>
          </div>
        </div>
      }
      id="unsaved-changes-dialog"
      open={open}
      title="Warning!"
      onClose={onClose}>
      <div className={`mt-[16px] w-[345px] ${className}`}>
        <span className="text-medium text-[16px] text-grey-800">{message}</span>
      </div>
    </Modal>
  );
};

export default WarningAlert;
