const Contact = {
  FormLabels: {
    CompanyName: 'Company Name',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Title: 'Title',
    Email: 'Email',
    OfficePhone: 'Office Phone',
    HomePhone: 'Home Phone',
    MobilePhone: 'Mobile phone',
    Fax: 'Fax',
    Address: 'Address',
    Website: 'Website',
    ContactNumber: 'Contact Number',
    Vendor: 'Vendor',
    AddressName: 'Address Name',
    AddressLine1: 'Address Line 1',
    AddressLine2: 'Address Line 2',
    Zipcode: 'Zip Code',
    City: 'City',
    State: 'State',
  },
  FormValidationMessages: {
    CompanyNameRequired: 'Please enter a Company Name up to 30 characters.',
    CompanySelectionRequired: 'Please Select Company from List',
    WebsiteInvalid: 'Please enter a valid website.',
    EmailInvalid: 'Please enter a valid email address.',
    CompanyPhoneNumberInvalid: 'Please enter a valid phone number.',
    HomePhoneNumberInvalid: 'Please enter a valid phone number.',
    MobilePhoneNumberInvalid: 'Please enter a valid phone number.',
    OfficePhoneNumberInvalid: 'Please enter a valid phone number.',
    FaxInvalid: 'Please enter a valid fax number.',
    FirstNameRequired: 'Please enter a First Name.',
    LastNameRequired: 'Please enter a Last Name.',
    AddressRequired: 'Please enter an Address.',
    AddressNameRequired: 'Please enter an Address Name. (ex. home, office, warehouse)',
    AddressLine1Required: 'Please enter an Address.',
    AddressLine2Required: 'Address Line 2 is required',
    ZipInvalid: 'Zip is invalid',
    CityRequired: 'City is required',
    StateRequired: 'State is required',
    CompanyNameMaxAllowed: 'Company name should be a less then or equal to 30 characters',
    SpaceNotAllowed: 'White or empty space is not allowed',
    ZipCodeRequired: 'Please enter a Zip Code.',
    ZipCodeInvalid: 'Please enter a valid Zip Code.',
    FirstNameMaxAllowed: 'Please enter a First Name up to 30 characters.',
    LastNameMaxAllowed: 'Please enter a Last Name up to 30 characters.',
    companyNameDuplicate: "This Company Name already exists in your Contacts. Please enter a different Company Name.",
    companyEmailDuplicate: "This Company Email already exists in your Contacts. Please enter a different Company Email.",
    companyWebsiteDuplicate: "This Company Website already exists in your Contacts. Please enter a different Company Website.",
    companyPhoneDuplicate: "This Company Phone already exists in your Contacts. Please enter a different Company Phone.",
    companyFaxDuplicate: "This Company Fax already exists in your Contacts. Please enter a different Company Fax.",
  },
  New: 'New Contacts',
  NewCompany: 'New Company',
  DuplicateCompany: 'Duplicate Company',
  DuplicateCompanyKeys: [
    'companyEmail',
    'companyName',
    'companyWebsite',
    'companyContactNumber',
    'companyFax',
  ],
  Company: 'Company',
  Contact: 'Contact',
  Address: 'Address',
  NewAddress: 'New Address',
  AddNewAddress: 'Add New Address',
  UpdateAddress: 'Update Address',
  AddNewContact: 'Add New Contact',
  UpdateContact: 'Update Contact',
  DuplicateContact: 'Duplicate Contact',
  DuplicateContactKeys: [
    'email',
    'firstName',
    'lastName',
    'title',
    'officeContactNumber',
    'homeContactNumber',
    'mobileContactNumber',
    'fax',
  ],
  SuccessMessages: {
    CompanyCreated: 'This company has been successfully created.',
    CompanyUpdated: 'This company has been successfully updated.',
    CompanyDeleted: 'This company has been successfully deleted.',
    ContactCreated: 'This contact has been successfully created.',
    ContactUpdated: 'This contact has been successfully updated.',
    ContactDeleted: 'This contact has been successfully deleted.',
    AddressCreated: 'This address has been successfully created.',
    AddressUpdated: 'This address has been successfully updated.',
    AddressDeleted: 'This address has been successfully deleted.',
  },
};
export default Contact;
