import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import Modal from 'app/components/Modal';
import Common from 'app/i18n/Common';
import Network from 'app/i18n/Network';
import { NetworkAssetSchema } from 'app/types/schema';

import useNetworkAssetFormData from '../../hook/useNetworkAssetFormData';
import BatteryForm from './batteryForm';
import BiosForm from './biosForm';
import CpuForm from './cpuForm';
import DiskForm from './diskForm';
import DisplayForm from './displayForm';
import GraphicsForm from './graphicsForm';
import MemoryForm from './memoryForm';
import MotherboardForm from './motherboardForm';
import NetworkForm from './networkForm';
import OSForm from './osForm';
import ProcessForm from './processForm';
import StorageForm from './storageForm';
import SystemForm from './systemForm';

type Props = {
  networkAssetDetails?: NetworkAssetSchema;
  onCancelHandler: () => void;
  onSaveHandler: () => void;
};

const NetworkAssetForm = (props: Props) => {
  const { networkAssetDetails, onCancelHandler, onSaveHandler } = props;

  const { networkAssetFormDefaultValues, onSubmit, fetching } = useNetworkAssetFormData({
    networkAssetDetail: networkAssetDetails,
    onSaveHandler: onSaveHandler,
  });

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: networkAssetFormDefaultValues,
  });

  useEffect(() => {
    reset(networkAssetFormDefaultValues);
  }, [networkAssetFormDefaultValues, reset]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  return (
    <div className="w-[100%] overflow-x-hidden md:w-[560px]">
      <form>
        <CpuForm control={control} className='mt-[6px]' />
        <DiskForm control={control} className='mt-[6px]'/>
        <SystemForm control={control} className='mt-[6px]'/>
        <MemoryForm control={control}  className='mt-[6px]'/>
        <OSForm control={control} className="mt-6"/>
        <ProcessForm control={control}  className="mt-6"/>
        <BatteryForm control={control} className="mt-6" />
        <MotherboardForm control={control} className="mt-6"/>
        <StorageForm control={control} className="mt-6"/>
        <DisplayForm control={control} className="mt-6"/>
        <BiosForm control={control} className="mt-6"/>
        <NetworkForm control={control} className="mt-6"/>
        <GraphicsForm control={control} className="mt-6"/>
        <div className="mt-8">
          <Button disabled={fetching} loading={fetching} theme="success" onClick={onFormSubmit}>
            {Common.Actions.Save}
          </Button>
          <Button classes="ml-[16px]" onClick={onCancelHandler}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NetworkAssetForm;
