import AdminLayout from 'app/components/AdminLayout';
import Projects from 'app/modules/projects/views/Projects';

function ProjectsPage() {
  return (
    <AdminLayout>
      <Projects />
    </AdminLayout>
  );
}

export default ProjectsPage;
