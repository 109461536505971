import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';

const getOrderDateHeadCell = (config?: Partial<DataGridHeadCell>): DataGridHeadCell => ({
  id: 'createdAt',
  label: 'Packed Date',
  value: 'createdAt',
  valueNode: ({ row }) => (
    <span className="truncate" title={row?.createdAt}>
      {row?.createdAt}
    </span>
  ),
  width: 300,
  ...(config || {}),
});

export const getContainerOrderItemsTableHeaders = (context: {
  isUnPackPermission: boolean;
  hideActions?: boolean;
}): DataGridHeadCell[] => {
  const { isUnPackPermission, hideActions = false } = context;

  const headers: DataGridHeadCell[] = [
    {
      id: 'label',
      label: 'Order ID / Container ID',
      value: 'label',
      width: 300,
    },
    getOrderDateHeadCell(),
  ];

  if (!hideActions && isUnPackPermission) {
    headers.push({
      id: 'id',
      label: 'Action',
      value: 'id',
      valueNode: (props) => {
        const { state, row } = props;

        const {
          extraProps = {
            onUnPack: null,
            unPackTrackingId: null,
            unPackLoading: false,
          },
        } = state;

        const { onUnPack, unPackTransactionId, unPackLoading } = extraProps;

        return (
          <Button
            disabled={unPackLoading}
            loading={
              [row.trackingId, row.id, row.rowId, row.containerId].includes(unPackTransactionId) &&
              unPackLoading
            }
            theme="info"
            onClick={() => {
              if (onUnPack) {
                onUnPack(row);
              }
            }}
            classes="min-w-[91px] h-[44px]">
            Unpack
          </Button>
        );
      },
      width: 180,
    });
  }

  return headers;
};
