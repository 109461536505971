import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditOrganizationQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TenantFilters>;
}>;


export type EditOrganizationQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantConnection', totalCount: number, edges: Array<{ __typename?: 'TenantEdge', node: { __typename?: 'TenantSchema', id: string, name: string, logo?: string | null, status: Types.TenantStatusEnum, url?: string | null, primaryPhoneNumber?: string | null, secondaryPhoneNumber?: string | null, config?: { __typename?: 'TenantConfigType', allowSignUp: boolean } | null, locationDetails: { __typename?: 'TenantLocationDetailsType', address: string, city: string, state: string, zipCode: string }, moduleVisibilityConfig?: { __typename?: 'ModuleVisibilityConfigType', assets: Types.TenantFeatureTypeEnum, itAsset: Types.TenantFeatureTypeEnum, inventory: Types.TenantFeatureTypeEnum, contacts: Types.TenantFeatureTypeEnum, orderRequest: Types.TenantFeatureTypeEnum, shipping: Types.TenantFeatureTypeEnum, receiving: Types.TenantFeatureTypeEnum, report: Types.TenantFeatureTypeEnum, admin: Types.TenantFeatureTypeEnum, shop: Types.TenantFeatureTypeEnum, wishlist: Types.TenantFeatureTypeEnum } | null } }> } };


export const EditOrganizationDocument = gql`
    query editOrganization($filters: TenantFilters) {
  tenants(filters: $filters) {
    totalCount
    edges {
      node {
        id
        name
        logo
        status
        url
        config {
          allowSignUp
        }
        locationDetails {
          address
          city
          state
          zipCode
        }
        moduleVisibilityConfig {
          assets
          itAsset
          inventory
          contacts
          orderRequest
          shipping
          receiving
          report
          admin
          shop
          wishlist
        }
        primaryPhoneNumber
        secondaryPhoneNumber
      }
    }
  }
}
    `;

export function useEditOrganizationQuery(options?: Omit<Urql.UseQueryArgs<EditOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<EditOrganizationQuery>({ query: EditOrganizationDocument, ...options });
};