import { useCallback, useMemo } from 'react';
import { isAccessible } from 'app/components/AccessControl';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  OrderRequestSchema,
  Scalars,
  ShippingContainerSchema,
  ShippingTransactionChildEntitySchema,
  ShippingTransactionSchema,
} from 'app/types/schema';
import OrderItems from '../../../OrderItems';
import useUnpackContainer from '../../hooks/useUnpackContainer';
import UnpackConfirmationDialog from '../UnpackConfirmationDialog';
import useContainerDetails from './hooks/useShippingContainer';
import { getContainerOrderItemsTableHeaders } from './utils/columns';
import { EntityType } from './utils';

interface OrderListProps {
  row: ShippingContainerSchema
  hideActions?: boolean;
}

export type UnPackDialogState = {
  open: boolean;
  unpackId: string | number | null | undefined;
};

const TransactionsTable: React.FC<OrderListProps> = (props: OrderListProps) => {
  const { row, hideActions } = props;

  const childContainerIds = useMemo(() => {
    let childContainerId: Scalars['String'][] = [];
    if (row?.shippingTransactions) {
      row?.shippingTransactions?.forEach(({ childEntities }) => {
        childEntities?.forEach(({ entityId }) => {
          childContainerId.push(entityId);
        });
      });
    } else {
      const shippingContainer = row as ShippingContainerSchema & { childEntities: any[] };
      childContainerId.push(shippingContainer.containerId);
    }
    return childContainerId;
  }, [row]);

  const shouldFetchDetails = !row?.shippingTransactions;
  const { data, loading } = useContainerDetails(row, childContainerIds, shouldFetchDetails);

  const containerDetails = useMemo(() => {
    return data?.shippingTransactions;
  }, [data]);

  const { unpackDialogState, unpacking, unpackContainerId, actions } = useUnpackContainer();
  const currentUser = useCurrentUser();
  const { table } = useEntityManager({
    selection: false,
    numberOfRowsPerPage: data?.shippingTransactions?.length || 0,
  });

  const isUnPackPermission = isAccessible(
    (currentUser && currentUser.workspacePermissions) || [],
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Shipment,
  );

  const onConfirmUnpack = useCallback(() => {
    const unpackTrackingId = unpackDialogState.unpackId;
    const transaction: any = containerDetails?.find((transactionData: any) =>
      [transactionData.trackingId, transactionData?.entityId].includes(unpackTrackingId),
    );
    if (transaction) {
      const entityType = transaction?.entityType;
      actions?.onConfirmUnpack(entityType);
    }
  }, [actions, containerDetails, unpackDialogState.unpackId]);

  const onUnPack = useCallback(
    (item: any) => {
      if (item?.entityType === EntityType.CHILD_CONTAINER) {
        actions?.onUnpack(item?.entityId);
      } else {
        actions?.onUnpack(item?.trackingId);
      }
    },
    [actions],
  );

  const columns = useMemo(
    () =>
      getContainerOrderItemsTableHeaders({
        isUnPackPermission,
        hideActions,
      }),
    [isUnPackPermission, hideActions],
  );

  const renderDetailPanel = (data: {
    row: ShippingTransactionChildEntitySchema | OrderRequestSchema;
  }) => {
    if (
      (data?.row as ShippingTransactionChildEntitySchema)?.entityType?.toUpperCase() ===
      EntityType.CHILD_CONTAINER
    ) {
      return (
        <TransactionsTable
          key={(data?.row as unknown as ShippingTransactionSchema)?.id}
          hideActions={true}
          row={data?.row as unknown as ShippingContainerSchema}
        />
      );
    } else if (
      (data?.row as ShippingTransactionChildEntitySchema)?.entityType?.toUpperCase() ===
      EntityType.ORDER_REQUEST
    ) {
      return <OrderItems row={data?.row as unknown as ShippingTransactionSchema} />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="ml-[60px] mb-[20px] mt-[16px] max-w-[1160px]">
        <EntityManager.Table
          actions={table.actions}
          columns={columns}
          data={containerDetails}
          extraProps={{
            onUnPack,
            unPackTransactionId: unpackContainerId,
            unPackLoading: unpacking,
          }}
          getDetailPanelContent={renderDetailPanel}
          getDetailPanelHeight={() => 'auto'}
          getRowId={(row: any) => JSON.stringify(row)}
          loading={loading}
          minWidth={950}
          setState={table.setState}
          state={table.state}
          sx={{ 'border-left': 'none', 'border-right': 'none' }}
          tableBorder="none"
          tableBorderRadius="12px"
        />
        <UnpackConfirmationDialog
          id="unpack-order-dialog"
          open={unpackDialogState.open}
          onCancelUnpack={actions.onCancelUnpack}
          onConfirmUnpack={onConfirmUnpack}
        />
      </div>
    </>
  );
};

export default TransactionsTable;
