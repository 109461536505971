import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import FormError from 'app/components/Form/FormError';
import FormLabel from 'app/components/Form/FormLabel';
import { SortOrderEnum, TenantSchema } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { useFormOrganizationSelectQuery } from './graphql/queries/generated/formOrganizationSelect';

interface Props {
  className?: string;
  label?: string;
  isClearable?: boolean;
  isRequired?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  error?: FieldError;
  placeholder?: string;
}

type Option = Pick<TenantSchema, 'id' | 'name'>;

const getOptionLabel = (option: Option) => option.name;
const getOptionValue = (option: Option) => option.id;

const FormOrganizationSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    error,
    label,
    isRequired,
    disabled,
    value,
    onBlur,
    onChange,
    placeholder = '',
    autoFocus,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);

  const [{ fetching: isDisabled, data: selectedData }] = useFormOrganizationSelectQuery({
    variables: { filters: { tenantIds: value ? [value] : [] } },
    pause: !value || !!selectedValue,
  });

  useEffect(() => {
    const selectedOption = selectedData?.tenants.edges[0]?.node;
    setSelectedValue(selectedOption || null);
  }, [selectedData?.tenants.edges]);

  const [{ fetching: isLoading, data: optionsData }] = useFormOrganizationSelectQuery({
    variables: {
      filters: { search: inputValue },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
    },
  });

  const options = useMemo<Option[]>(
    () => optionsData?.tenants.edges.map(({ node }) => node) || [],
    [optionsData?.tenants.edges],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: Option) => {
      if (option) {
        setSelectedValue(option);
        onChange?.(option.id);
      } else {
        setSelectedValue(null);
        onChange?.('');
      }
    },
    [onChange],
  );
  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Autocomplete
        className="mt-6 rounded text-13 text-[#495057] focus:border-[#80bdff] focus:outline-none"
        disabled={disabled || isDisabled}
        getOptionLabel={getOptionLabel}
        label={''}
        loading={isLoading}
        options={options}
        renderInput={(params: any) => (
          <TextField {...params} autoFocus={autoFocus} placeholder={placeholder} />
        )}
        value={selectedValue}
        onBlur={onBlur}
        onChange={onValueChange}
        onInputChange={onInputValueChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormOrganizationSelect;
