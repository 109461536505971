import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useForm,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Button } from '@procurenetworks/procure-component-library';
import { omit } from 'lodash';
import FormCheckBoxInput from 'app/components/ProcureForm/FormCheckBoxInput';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import routes from 'app/consts/routes';
import Common from 'app/i18n/Common';
import Contact from 'app/i18n/Contact';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import DeleteConfirmationModal from 'app/modules/components/EntityManager/DeleteConfirmationModal';
import { CheckEditDuplicateEnum } from 'app/types/contact';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  CompanySchema,
  CreateCompanyInput,
} from 'app/types/schema';
import Box from 'app/ui-components/Box';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { useAccessControl } from '../../../../components/AccessControl';
import FormPhoneInput from '../../../../components/ProcureForm/FormPhoneInput';
import { SnackbarService } from '../../../../components/Snackbar';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import { useCompnayListQuery } from '../companySearch/graphql/queries/generated/company';
import { useCreateCompanyMutation } from './graphql/mutations/generated/createCompany';
import { useDeleteCompanyMutation } from './graphql/mutations/generated/deleteCompnay';
import { useUpdateCompanyMutation } from './graphql/mutations/generated/updateCompany';
import { useCompanyDetailsQuery } from './graphql/queries/generated/company';
import { COMPANY_FORM_RULES } from './utils';
import ScrollToError from 'app/utils/ScrollToError';
import analytics from 'app/analytics';

type FormWrapperProps = {
  formControl?: Control;
  formClassName?: string;
  children?: React.ReactNode;
  onFormSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const FormWrapper = ({ formControl, formClassName, children, onFormSubmit }: FormWrapperProps) => {
  if (formControl) {
    return <Box className={`w-full pt-12 ${formClassName}`}>{children}</Box>;
  } else {
    return (
      <form
        className={`w-[320px] pt-12 md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]`}
        onSubmit={onFormSubmit}>
        {children}
      </form>
    );
  }
};

const CompanyForm = (
  props: RouteComponentProps<{ id: string }> & {
    onCheckDuplicate: (
      input: CreateCompanyInput,
      checkType: string,
      companies: CompanySchema[],
    ) => boolean;
    handleCloseDuplicate?: () => void;
    control?: Control;
    reset?: any;
    errors?: FieldErrors;
    hideTitle?: boolean;
    hideActions?: boolean;
    formClassName?: string;
    defaultValues?: CreateCompanyInput;
    setValue?: UseFormSetValue<CreateCompanyInput>;
    getValues?: UseFormGetValues<CreateCompanyInput>;
    setIsEditDuplicate?: any;
    hideActionsEdit?: boolean;
    isDuplicate?: boolean;
    setErrors?: UseFormSetError<CreateCompanyInput>;
  },
) => {
  const {
    history,
    match,
    onCheckDuplicate,
    hideTitle,
    hideActions,
    formClassName,
    defaultValues,
    control: formControl,
    reset: formReset,
    setValue: formSetValue,
    getValues: formGetValues,
    setIsEditDuplicate,
    hideActionsEdit,
    isDuplicate,
    setErrors: formSetErrors,
  } = props;
  const companyId = match.params.id || '';

  const { workspacePermissions } = useCurrentUser();

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Company,
  );

  const canCreate = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.Company,
  );

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Company,
  );

  const canEditOrDelete = (canEdit || canDelete) && companyId;

  const canCreateOrEdit = canCreate || canEdit;

  const [isViewMode, setIsViewMode] = useState(false);

  useEffect(() => {
    setIsViewMode(companyId ? true : false);
  }, [companyId]);

  const [{ fetching: onUpdateFetching }, onUpdateCompany] = useUpdateCompanyMutation();
  const [{ fetching: onCreateFetching }, onCreateCompany] = useCreateCompanyMutation();

  const initialCountryCode = { companyContact: {} };

  const [countryCodes, setCountryCodes] = useState<{
    companyContact: any;
  }>(initialCountryCode);

  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyFax, setCompanyFax] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');

  const [{ fetching, data: dataList }] = useCompnayListQuery({
    variables: {
      limit: 10000,
    },
  });

  const companies = useMemo(() => {
    return (
      dataList?.companies?.edges?.map((company: any) => {
        return { ...company.node };
      }) || []
    );
  }, [dataList]);

  const [{ data }] = useCompanyDetailsQuery({
    variables: { filters: { companyIds: [companyId] } },
    pause: !Boolean(companyId),
  });
  const companyData = hideActionsEdit && companyId && data?.companies?.edges[0]?.node;

  const companyFormDefaultValues = useMemo(() => {
    return (
      companyData ||
      defaultValues || {
        companyEmail: '',
        companyName: '',
        companyFax: '',
        companyWebsite: '',
        companyContactNumber: '',
        isVendor: false,
      }
    );
  }, [companyData, defaultValues]);

  useEffect(() => {
    setCompanyName(companyFormDefaultValues.companyName);
    setCompanyPhone(companyFormDefaultValues.companyContactNumber || '');
    setCompanyFax(companyFormDefaultValues.companyFax || '');
    setCompanyWebsite(companyFormDefaultValues.companyWebsite || '');
    setCompanyEmail(companyFormDefaultValues.companyEmail || '');
  }, [companyFormDefaultValues]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: companyFormDefaultValues,
    reValidateMode: 'onChange',
  });

  const controller = useMemo(() => {
    return (formControl ?? control) as Control;
  }, [formControl]);
  const reseter = useMemo(() => {
    return formReset ?? reset;
  }, [formReset]);

  useEffect(() => {
    reseter(companyFormDefaultValues);
  }, [companyFormDefaultValues, companyId]);

  const onSubmit = useCallback(
    (input: CreateCompanyInput) => {
      input = {
        ...input,
        companyName: input.companyName?.trim(),
        companyEmail: input.companyEmail?.trim(),
        companyWebsite: input.companyWebsite?.trim(),
      };

      const inputValue = omit({ ...input }, 'id') as CompanySchema;
      const isDuplicate = onCheckDuplicate(
        input,
        !hideActionsEdit ? CheckEditDuplicateEnum.SUBMIT : CheckEditDuplicateEnum.EDIT,
        companies,
      );
      if (!isDuplicate) {
        if (companyId) {
          onUpdateCompany({ input: { ...inputValue, companyId } }).then((response) => {
            setIsViewMode(true);
            if (response.data?.updateCompany.company?.id) {
              analytics?.track('Edited', { name: 'Company' });
              SnackbarService.show({
                message: Contact.SuccessMessages.CompanyUpdated,
              });
              history.replace(routes.Companies(response.data?.updateCompany.company?.id));
            } else {
              console.error('[Create Company] Failed', response);
            }
          });
        } else {
          onCreateCompany({ input }).then((response) => {
            if (response.data?.createCompany.company?.id) {
              analytics?.track('Created', { name: 'Company' });
              SnackbarService.show({
                message: Contact.SuccessMessages.CompanyCreated,
              });
              history.replace(routes.Companies(response.data?.createCompany.company?.id));
            } else {
              console.error('[Create Company] Failed', response);
            }
          });
        }
      }
    },
    [history, companyId, companies, hideActionsEdit],
  );
  const formRef = useRef<HTMLDivElement>(null);
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const companyValues = {
          ...values,
          companyName: removeMultipleSpaces(values.companyName),
        };
        onSubmit(companyValues);
      }, (errors) => {
        ScrollToError(errors, formRef);
      }),
    [handleSubmit, onSubmit],
  );

  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const onCancelConfirm = () => {
    setIsOpenDeleteConfirm(false);
  };

  const onHandleCancel = (event: any) => {
    event.preventDefault();
    reseter(companyFormDefaultValues);
    if (companyId) {
      setIsViewMode(true);
      history.replace(routes.Companies(companyId));
    }
  };

  const onHandleEdit = useCallback(() => {
    setIsViewMode(false);
    if (setIsEditDuplicate) {
      setIsEditDuplicate(true);
    }
  }, [setIsViewMode, setIsEditDuplicate]);

  const [{ fetching: deleteContactLoading }, executeDeleteCompany] = useDeleteCompanyMutation();

  const onDelete = useCallback(async () => {
    const response = await executeDeleteCompany({ input: { companyIds: [companyId] } });
    analytics?.track('Deleted', { name: 'Company' });
    if (response.data?.deleteCompanies.success) {
      SnackbarService.show({
        message: Contact.SuccessMessages.CompanyDeleted,
      });
      history.replace(routes.Companies(''));
      setIsOpenDeleteConfirm(false);
    }
  }, [executeDeleteCompany, companyId]);

  const companyNameRules = useMemo(() => {
    const matchingCompany = companies.find(
      (company) =>
        company.companyName.toLowerCase() === companyName.toLowerCase() &&
        companyFormDefaultValues.companyName.toLowerCase() !== companyName.toLowerCase(),
    );
    return COMPANY_FORM_RULES.companyName(isDuplicate ? !!matchingCompany : false);
  }, [companies, companyFormDefaultValues.companyName, companyName, isDuplicate]);

  const companyWebsiteRules = useMemo(() => {
    const matchingWebsite = companies.find(
      (company) =>
        company.companyWebsite === companyWebsite &&
        companyWebsite.trim() !== '' &&
        companyFormDefaultValues.companyWebsite !== companyWebsite,
    );
    return COMPANY_FORM_RULES.website(isDuplicate ? !!matchingWebsite : false);
  }, [companies, companyFormDefaultValues.companyWebsite, companyWebsite, isDuplicate]);

  const companyEmailRules = useMemo(() => {
    const matchingEmail = companies.find(
      (company) =>
        company.companyEmail === companyEmail &&
        companyEmail.trim() !== '' &&
        companyFormDefaultValues.companyEmail !== companyEmail,
    );
    return COMPANY_FORM_RULES.email(isDuplicate ? !!matchingEmail : false);
  }, [companies, companyEmail, companyFormDefaultValues.companyEmail, isDuplicate]);

  const companyPhoneRules = useMemo(() => {
    const matchingPhone = companies.find(
      (company) =>
        company.companyContactNumber === companyPhone &&
        companyPhone.trim() !== '' &&
        companyFormDefaultValues.companyContactNumber !== companyPhone,
    );
    const isDuplicateContact = isDuplicate ? !!matchingPhone : false;
    return COMPANY_FORM_RULES.companyPhoneNumber(countryCodes.companyContact, isDuplicateContact);
  }, [
    companies,
    companyFormDefaultValues.companyContactNumber,
    companyPhone,
    countryCodes.companyContact,
    isDuplicate,
  ]);

  const companyFaxRules = useMemo(() => {
    const matchingFax = companies.find(
      (company) =>
        company.companyFax === companyFax &&
        companyFax.trim() !== '' &&
        companyFormDefaultValues.companyFax !== companyFax,
    );
    const isDuplicateFax = isDuplicate ? !!matchingFax : false;

    return COMPANY_FORM_RULES.companyFax(countryCodes.companyContact, isDuplicateFax);
  }, [
    companies,
    companyFax,
    companyFormDefaultValues.companyFax,
    countryCodes.companyContact,
    isDuplicate,
  ]);

  if (!canCreateOrEdit) {
    return null;
  }
  const isDisabledInput =
    onCreateFetching || onUpdateFetching || hideActionsEdit ? isViewMode : hideActionsEdit;
  return (
    <div ref={formRef} className="min-w-full">
      <Box
        className="box-border flex min-h-[44px]  flex-col justify-center"
        style={{ boxSizing: 'border-box' }}>
        <Box className="flex h-[56px] items-center">
          {!hideTitle && (
            <Typography className="text-[18px] font-semibold text-grey-900">
              {Contact.Company}
            </Typography>
          )}
        </Box>
        <Divider />
        {hideActionsEdit && companyId && canCreate && (
          <Button
            classes="max-w-[204px] h-[44px] my-[16px]"
            theme="success"
            onClick={() => history.replace(routes.Companies(''))}>
            {Contact.NewCompany}
          </Button>
        )}
      </Box>

      <FormWrapper
        formClassName={formClassName}
        formControl={formControl}
        onFormSubmit={onFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {hideActionsEdit && isViewMode && canEditOrDelete && (
              <Box className="mt-[] mb-20 flex justify-start gap-[16px]">
                {canEdit ? (
                  <Button classes="min-w-[94px] h-[44px]" theme="info" onClick={onHandleEdit}>
                    {Common.Actions.Edit}
                  </Button>
                ) : undefined}
                {canDelete ? (
                  <Button
                    classes="min-w-[94px] h-[44px]"
                    theme="danger"
                    onClick={() => setIsOpenDeleteConfirm(true)}>
                    {Common.Actions.Delete}
                  </Button>
                ) : undefined}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="companyName"
              render={({ field, fieldState }) => (
                <FormTextInput
                  isRequired
                  {...field}
                  disabled={isDisabledInput}
                  endAdornment={() => (
                    <h2
                      className="text-Gray-800 text-[12px] font-semibold"
                      style={{ paddingRight: '10px' }}>{`${field.value?.length || 0}/30`}</h2>
                  )}
                  error={fieldState.error}
                  formLabel={Contact.FormLabels.CompanyName}
                  inputProps={{
                    type: 'text',
                    maxLength: 30,
                  }}
                  label={Contact.FormLabels.CompanyName}
                  onChange={(value) => {
                    field?.onChange(value);
                    formSetValue && formSetValue('companyName', value);
                    setCompanyName(value);
                  }}
                />
              )}
              rules={companyNameRules}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="isVendor"
              render={({ field, fieldState }) => (
                <FormCheckBoxInput
                  {...field}
                  classNames="!p-0 mr-[8px]"
                  disabled={isDisabledInput}
                  label={Contact.FormLabels.Vendor}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="companyWebsite"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  disabled={isDisabledInput}
                  error={fieldState.error}
                  formLabel={Contact.FormLabels.Website}
                  label={Contact.FormLabels.Website}
                  onChange={(value) => {
                    field?.onChange(value);
                    formSetValue && formSetValue('companyWebsite', value);
                    setCompanyWebsite(value);
                  }}
                />
              )}
              rules={companyWebsiteRules}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="companyEmail"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  disabled={isDisabledInput}
                  error={fieldState.error}
                  formLabel={Contact.FormLabels.Email}
                  label={Contact.FormLabels.Email}
                  onChange={(value) => {
                    field?.onChange(value);
                    formSetValue && formSetValue('companyEmail', value);
                    setCompanyEmail(value);
                  }}
                />
              )}
              rules={companyEmailRules}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="companyContactNumber"
              render={({ field, fieldState }) => (
                <FormPhoneInput
                  {...field}
                  disabled={isDisabledInput}
                  error={fieldState.error}
                  // label={Contact.FormLabels.ContactNumber}
                  formLabel={Contact.FormLabels.ContactNumber}
                  placeholder={Contact.FormLabels.ContactNumber}
                  onChange={(value) => {
                    field?.onChange(value);
                    formSetValue && formSetValue('companyContactNumber', value);
                    setCompanyPhone(value);
                  }}
                  onCountryCodeChange={(updatedCountryCodes) => {
                    setCountryCodes({
                      ...countryCodes,
                      companyContact: updatedCountryCodes,
                    });
                  }}
                />
              )}
              rules={companyPhoneRules}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={controller}
              name="companyFax"
              render={({ field, fieldState }) => (
                <FormPhoneInput
                  {...field}
                  disabled={isDisabledInput}
                  error={fieldState.error}
                  // label={Contact.FormLabels.Fax}
                  formLabel={Contact.FormLabels.Fax}
                  placeholder={Contact.FormLabels.Fax}
                  onChange={(value) => {
                    field?.onChange(value);
                    formSetValue && formSetValue('companyFax', value);
                    setCompanyFax(value);
                  }}
                  onCountryCodeChange={(updatedCountryCodes) => {
                    setCountryCodes({
                      ...countryCodes,
                      companyContact: updatedCountryCodes,
                    });
                  }}
                />
              )}
              rules={companyFaxRules}
            />
          </Grid>
          <Grid item xs={12}>
            {!hideActions && !isViewMode && canCreate && (
              <Box className="mt-10 mb-20 flex justify-end gap-[18px]">
                <Button
                  classes="min-w-[94px] h-[44px]"
                  disabled={onCreateFetching || onUpdateFetching}
                  loading={onCreateFetching || onUpdateFetching}
                  theme="success"
                  onClick={onFormSubmit}>
                  {Common.Actions.Save}
                </Button>
                <Button classes="min-w-[94px] h-[44px]" onClick={onHandleCancel}>
                  {Common.Actions.Cancel}
                </Button>
              </Box>
            )}

            <DeleteConfirmationModal
              open={isOpenDeleteConfirm}
              onCancel={onCancelConfirm}
              onConfirm={onDelete}
            />
          </Grid>
        </Grid>
      </FormWrapper>
    </div>
  );
};
export default withRouter(CompanyForm);
