import { useMemo } from 'react';

import { FeatureFlagEnum, useFeatureFlag } from '../../../../../../libs/featureFlag';
import { ItemLocationItemTypeEnum } from '../../../../../../types/schema';
import AssetTable from '../../components/AssetTable';
import LeftQrCodeLabels from '../../components/Forms/CustomSetupForm/LeftQrCodeLabels';
import TopQrCodeLabels from '../../components/Forms/CustomSetupForm/TopQrCodeLabels';
import LabelsForm from '../../components/Forms/LabelsForm';
import ShippingSetupForm from '../../components/Forms/ShippingSetupForm';
import { LabelCustomEnum, LabelStockEnum, LabelTypeEnum } from '../../components/Forms/types';
import WarehouseSetupForm from '../../components/Forms/WarehouseSetupForm';
import InventoryTable from '../../components/InventoryTable';
import LabelLocationsTable from '../../components/LabelLocationsTable';
import LabelShippingTable from '../../components/LabelShippingTable';
import useLabelsFrom from '../../context/useLabelsFrom';
import { Box } from '@mui/material';

const LabelsFormContainer = () => {
  const { values } = useLabelsFrom();

  const { value: isCustomLabelFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.CustomLabelField,
  );

  const renderContent = useMemo(() => {
    if (values) {
      const type = values.type;
      if (type === LabelTypeEnum.Inventory) {
        return (
          <InventoryTable
            itemTypes={[ItemLocationItemTypeEnum.Inventory, ItemLocationItemTypeEnum.InventoryKit]}
          />
        );
      }

      if (type === LabelTypeEnum.Asset) {
        return (
          <AssetTable
            itemTypes={[ItemLocationItemTypeEnum.Asset, ItemLocationItemTypeEnum.AssetKit]}
          />
        );
      }

      if (type === LabelTypeEnum.Location) {
        return <LabelLocationsTable />;
      }

      if (type === LabelTypeEnum.Shipping) {
        return <LabelShippingTable />;
      }

      if (type === LabelTypeEnum.ShippingSetUp) {
        return <ShippingSetupForm />;
      }

      if (type === LabelTypeEnum.WarehouseSetup) {
        return <WarehouseSetupForm />;
      }
    }
    return null;
  }, [values]);

  const renderCustom = useMemo(() => {
    if (values && values.custom === LabelCustomEnum.Custom) {
      if (
        [LabelStockEnum.Avery6792, LabelStockEnum.Avery5163].includes(
          values.labelStock as LabelStockEnum,
        )
      ) {
        return <LeftQrCodeLabels type={values.type} />;
      }
      if (
        [
          LabelStockEnum.Avery6570,
          LabelStockEnum.Avery5160,
          LabelStockEnum.OneRoundLabels,
          LabelStockEnum.ThreeRoundLabels,
          LabelStockEnum.OneSquareLabels,
          LabelStockEnum.ThreeSquareLabels,
          LabelStockEnum.ZebraPrinter,
        ].includes(values.labelStock as LabelStockEnum)
      ) {
        return <TopQrCodeLabels type={values.type} />;
      }
    }
    return <></>;
  }, [values]);

  return (
    <>
      <LabelsForm formType={values?.type} isCustomLabelFieldEnable={isCustomLabelFieldEnabled} />
      {!loading && isCustomLabelFieldEnabled && renderCustom}
      {renderContent}
    </>
  );
};

export default LabelsFormContainer;
