import { useMemo } from 'react';
import { FieldError } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import { AsyncMultiTableFilterProps } from 'app/components/AsyncMultiTableFilter/types';
import {
  AsyncSingleSelectProps,
  UseSelectedValueReturn,
} from 'app/components/AsyncSingleSelect/types';
import AsyncSingleTableFilter from 'app/components/AsyncSingleTableFilter';
import { useData } from 'app/modules/ship/provider/userShippingSelectContextProvider';

import {
  UseSelectedValueProps,
  UseSelectedValueReturn as UseSelectedValuesReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from '../../../../../../components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from '../../../../../../components/AsyncMultiSelect/utils';
import AsyncMultiTableFilter from '../../../../../../components/AsyncMultiTableFilter';
import {
  LocationTypeEnum,
  Maybe,
  RoleTypeFilterEnum,
  SortOrderEnum,
  UserStatusEnum,
  ValueInEntitiesFiltersConfig,
  ValuesInEntitiesDistinctByKeysEnum,
  ValuesInEntitiesTableNameEnum,
} from '../../../../../../types/schema';
import { useValuesInEntitiesSelectQuery } from 'app/modules/users/graphql/queries/generated/distinctUserSelect';
import { useUsersSelectQuery } from 'app/modules/users/graphql/queries/generated/usersSelect';
import { UsersSelectProps } from 'app/modules/users/components/UsersSelect';
import { ShipmentColumnFilterTypeEnum } from '../../types';
import { useFormSiteSelectQuery } from 'app/modules/locations/components/FormSiteSelect/graphql/queries/generated/formSiteSelect';

interface UsersQueryVariables {
  roleTypes?: RoleTypeFilterEnum[];
  statuses?: UserStatusEnum[];
  tenantIds?: string[];
  includingPartnerTenantExternalUsers?: boolean;
  distinctTableName?: ValuesInEntitiesTableNameEnum;
  distinctKeys?: ValuesInEntitiesDistinctByKeysEnum[];
  filters?: keyof ValueInEntitiesFiltersConfig;
  filterKey?: keyof ValueInEntitiesFiltersConfig;
  filterType?: ShipmentColumnFilterTypeEnum;
  types?: string;
}

export interface UserSelectProps {
  name: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: FieldError;
  queryVariables?: UsersQueryVariables;
  filterType?: ShipmentColumnFilterTypeEnum;
  types?: string;
}

const useSelectedSiteValues = (
  props: UseSelectedValueProps & {
    types?: LocationTypeEnum[];
    siteIds?: string[];
    isMultiple?: boolean;
  },
): UseSelectedValuesReturn | UseSelectedValueReturn => {
  const { value, pause, isMultiple = true } = props;

  const [{ fetching, data }] = useFormSiteSelectQuery({
    pause,
    variables: {
      filters: {
        locationIds: value ? value : undefined,
        types: [LocationTypeEnum.Site],
      },
    },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.locations.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.locations.edges],
  );

  if (isMultiple) return { isDisabled: fetching, selectedValues: values };
  else return { isDisabled: fetching, selectedValue: values[0] };
};

const useSelectedValues = (
  props: UseSelectedValueProps & UsersQueryVariables & { isMultiple?: boolean },
): UseSelectedValuesReturn | UseSelectedValueReturn => {
  const {
    value,
    pause,
    tenantIds,
    roleTypes,
    includingPartnerTenantExternalUsers,
    statuses,
    isMultiple = true,
  } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    pause,
    variables: {
      filters: {
        userIds: value,
        tenantIds,
        roleTypes,
        includingPartnerTenantExternalUsers,
        statuses: statuses,
      },
    },
    requestPolicy: 'network-only',
  });
  const values = useMemo(() => {
    return (
      data?.users.edges
        ?.map(adaptNodeEdgeToOption)
        ?.filter((option) => value.includes(option.value)) || []
    );
  }, [data?.users.edges]);

  if (isMultiple) return { isDisabled: fetching, selectedValues: values };
  else return { isDisabled: fetching, selectedValue: values[0] };
};

const useSelectQuery = (props: UseSelectQueryProps & UsersQueryVariables): UseSelectQueryReturn => {
  const { inputValue, roleTypes, tenantIds, includingPartnerTenantExternalUsers, statuses } = props;

  const [{ fetching, data }] = useUsersSelectQuery({
    variables: {
      filters: {
        search: inputValue,
        roleTypes,
        tenantIds,
        includingPartnerTenantExternalUsers,
        statuses: statuses ?? [UserStatusEnum.Active],
      },
      limit: 10000,
      sorts: [
        { sortField: 'firstName', sortOrder: SortOrderEnum.Asc },
        { sortField: 'lastName', sortOrder: SortOrderEnum.Asc },
      ],
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.users.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.users.edges],
  );
  return { isLoading: fetching, options };
};

const useConditionalDistinctValueSelectQuery = (props: any) => {
  if (props.filterType !== ShipmentColumnFilterTypeEnum.USER) {
    return DistinctValueSelectQueryForNonUser(props);
  }
  return DistinctValueSelectQuery(props);
};

type Entity = { value: string; label: string };

const createOptions = (data: { [key: string]: Entity[] }, key: string): Entity[] => {
  return data[key]?.map((item: Entity) => ({
    value: item.value,
    label: item.label,
  })) || [];
};

const DistinctValueSelectQueryForNonUser = (
  props: UseSelectQueryProps & UsersQueryVariables & { filterType: string },
): UseSelectQueryReturn => {
  const { getData } = useData();
  const userData = getData("user");

  const filterTypeToKeyMap = {
    [ShipmentColumnFilterTypeEnum.DESTINATION]: 'deliverToId',
    [ShipmentColumnFilterTypeEnum.SITE]: 'destinationSiteId',
  };

  const key = filterTypeToKeyMap[props.filterType as keyof typeof filterTypeToKeyMap];

  if (props.filterType !== ShipmentColumnFilterTypeEnum.USER && key && userData) {
    const options = createOptions(userData, key);
    return { isLoading: false, options };
  }

  return { isLoading: false, options: [] };
};

const DistinctValueSelectQuery = (
  props: UseSelectQueryProps & UsersQueryVariables & { filterType: string },
): UseSelectQueryReturn => {
  const { distinctTableName, distinctKeys, filters, filterKey = 'transactionFilters', filterType } = props;
  const { setData, getData } = useData();

  const cachedData = getData(filterType);

  const [{ fetching, data }] = useValuesInEntitiesSelectQuery({
    variables: {
      inputs: {
        tableName: distinctTableName || ValuesInEntitiesTableNameEnum.Transactions,
        distinctByKeys: distinctKeys || [],
        filters: { [filterKey]: filters },
      },
    },
    requestPolicy: 'network-only',
  });

  const options = useMemo(() => {
    const payload = data?.valuesInEntities?.payload;

    if (!payload) {
      return [];
    }

    let distinctEntities: { [key: string]: Entity[] } = {};

    payload.forEach(distinctEntity => {
      const key = distinctEntity.key;
      const entities = distinctEntity.entities.map(entity => ({ value: entity.id, label: entity.name }));
      distinctEntities[key] = entities;
    });

    if (!cachedData && filterType === ShipmentColumnFilterTypeEnum.USER) {
      setData(filterType, distinctEntities);
    }

    const filterTypeToKeyMap: { [key: string]: string } = {
      [ShipmentColumnFilterTypeEnum.USER]: 'requestorId',
      [ShipmentColumnFilterTypeEnum.DESTINATION]: 'deliverToId',
      [ShipmentColumnFilterTypeEnum.SITE]: 'destinationSiteId',
    };

    const key = filterTypeToKeyMap[filterType];

    const filteredOptions = createOptions(distinctEntities, key);
    const sortedOptions = orderBy(filteredOptions, ['label'], ['asc']);

    return sortedOptions;
  }, [data?.valuesInEntities?.payload, filterType]);

  return { isLoading: fetching, options };
};

const ShipmentTableColumnFilter = (props: UsersSelectProps) => {
  const { isMultiple = true, value, onChange, filterType, isDistinct, ...otherProps } = props;

  const useSelectedOptionValues = filterType === ShipmentColumnFilterTypeEnum.SITE ? useSelectedSiteValues : useSelectedValues
  if (isMultiple) {
    return (
      <AsyncMultiTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useConditionalDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedOptionValues as unknown as (props: any) => UseSelectedValuesReturn}
        value={value as Maybe<string[]>}
        onChange={onChange as AsyncMultiTableFilterProps['onChange']}
      />
    );
  } else {
    return (
      <AsyncSingleTableFilter
        {...otherProps}
        size="small"
        useSelectQuery={isDistinct ? useConditionalDistinctValueSelectQuery : useSelectQuery}
        useSelectedValue={useSelectedOptionValues as unknown as (props: any) => UseSelectedValueReturn}
        value={value as Maybe<string>}
        onChange={onChange as AsyncSingleSelectProps['onChange']}
      />
    );
  }
};

export default ShipmentTableColumnFilter;