import { composeValidators, textValidator } from 'app/components/Form/utils/validators';
import Organization from 'app/i18n/Organization';
import { Maybe } from 'app/types/schema';

const zipCodeValidator = (message: string) => (value?: Maybe<string>) =>
  (value?.length === 5 && /^[0-9]+$/.test(value)) || message || false;

const phoneNumberValidator = (message: string) => (value?: Maybe<string>) => {
  if (value?.charAt(0) === '1' && value.length === 11) {
    return true;
  }

  if (value?.charAt(0) !== '1' && value?.length === 10) {
    return true;
  }

  return message || false;
};

export const ORGANIZATION_FORM_RULES = {
  name: {
    validate: textValidator(Organization.FormValidations.Name),
  },
  address: {
    validate: textValidator(Organization.FormValidations.Address),
  },
  city: {
    validate: textValidator(Organization.FormValidations.City),
  },
  state: {
    validate: textValidator(Organization.FormValidations.State),
  },
  zipCode: {
    validate: composeValidators(
      textValidator(Organization.FormValidations.ZipCode),
      zipCodeValidator(Organization.FormValidations.ZipCodeInvalid),
    ),
  },
  primaryPhoneNumber: {
    validate: textValidator(Organization.FormValidations.Phone1),
  },
  website: {
    validate: textValidator(Organization.FormValidations.WebsiteInvalid)
  }
};
