import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCheckOutTransactionsMutationVariables = Types.Exact<{
  input: Types.CreateCheckOutTransactionsInput;
}>;


export type CreateCheckOutTransactionsMutation = { __typename?: 'Mutation', createCheckOutTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', id: string, parentTransactionId: string }> | null } };


export const CreateCheckOutTransactionsDocument = gql`
    mutation createCheckOutTransactions($input: CreateCheckOutTransactionsInput!) {
  createCheckOutTransactions(input: $input) {
    success
    transactions {
      id
      parentTransactionId
    }
  }
}
    `;

export function useCreateCheckOutTransactionsMutation() {
  return Urql.useMutation<CreateCheckOutTransactionsMutation, CreateCheckOutTransactionsMutationVariables>(CreateCheckOutTransactionsDocument);
};