import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import Orders from 'app/i18n/Orders';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import EntityManagerTitle from 'app/modules/components/EntityManager/EntityManagerTitle';

import { isExternalUserHavePartnerTenants } from '../../../../utils/externalTenant';
import FormPartnerTenantSelect from '../../../FormPartnerTenantSelect';
import { UseNormalOrderRequestReturnType } from '../../../OrderItemRequest/hook/useNormalOrderRequest';
import { ORDER_REQUEST_FORM_RULES } from '../../utils/rules';

interface Props {
  control: any;
  name: string;
  onPartnerTenantChange: UseNormalOrderRequestReturnType['normalOrderActions']['onPartnerTenantChange'];
  paddedView?: boolean;
}

const OrderPartnerTenantField = (props: Props) => {
  const { control, name, paddedView, onPartnerTenantChange } = props;

  const user = useCurrentUser();

  const isExternalUser = useMemo(() => {
    return isExternalUserHavePartnerTenants(user);
  }, [user]);

  if (!isExternalUser) {
    return null;
  }

  return (
    <>
      <EntityManagerTitle title={Orders.FormLabels.SelectOrganization} />
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <div className={clsx({ 'pl-[40px]': !!paddedView })}>
            <FormPartnerTenantSelect
              {...field}
              isRequired
              error={fieldState.error}
              label={Orders.FormLabels.Organization}
              onChange={onPartnerTenantChange}
              formLabel="Organization"
            />
          </div>
        )}
        rules={ORDER_REQUEST_FORM_RULES.organization}
      />
    </>
  );
};

export default OrderPartnerTenantField;
