import { ItemTypeEnum } from '../../../../../../types/schema';

export enum ORDER_REQUEST_TYPE {
  NORMAL_ORDER = 'NORMAL_ORDER',
  BULK_ORDER = 'BULK_ORDER',
}

export const SupportedOrderItemTypes = [
  ItemTypeEnum.Asset,
  ItemTypeEnum.AssetKit,
  ItemTypeEnum.Inventory,
  ItemTypeEnum.InventoryKit,
];
