import { useMemo } from 'react';
import {
  AssetItemFilters,
  AssetItemSchema,
  AssetKitItemSchema,
  ItemLocationSchema,
  SortProps,
} from 'app/types/schema';

import usePagination from '../../../../../../hooks/usePagination';
import { parseSearchAssetInventoryStockInformationToRows } from '../../../../../inventory/components/QuantityBySite/utils/stockInormation';
import { useAssetsTableQuery } from '../../../../graphql/queries/generated/assetTable';

export interface AssetFilterState {
  siteId: string;
  categoryId: string;
  companyId: string;
  brands: string[];
  mNames: string[];
  modelNumbers: string[];
}

interface Props {
  filters: AssetItemFilters;
  siteId?: string;
  sorts: SortProps[];
  numberOfRowsPerPage: number;
}

const useAssetTableRows = (props: Props) => {
  const { filters, siteId, sorts, numberOfRowsPerPage } = props;
  // AssetItems Query
  const { fetching, stale, data, onNextPage, onPrevPage, onReset } = usePagination(
    useAssetsTableQuery,
    {
      filters: { ...filters, includeLocationsInSearch: !!siteId },
      sorts: sorts,
      siteId: siteId,
    },
    {
      edgeKey: 'items',
      pageSize: numberOfRowsPerPage,
    },
  );

  const assetTableRows = useMemo(() => {
    return (
      data?.items.edges?.map(({ node }) => {
        const item = node as AssetItemSchema | AssetKitItemSchema;
        let stockInformation: ItemLocationSchema[] = (item?.stockInformation ||
          []) as ItemLocationSchema[];
        return {
          ...node,
          parsedStockInformation: parseSearchAssetInventoryStockInformationToRows(stockInformation),
        };
      }) || []
    );
  }, [data?.items.edges]);

  return {
    rows: assetTableRows,
    fetching: fetching || stale,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.items.totalCount || 0,
  };
};

export default useAssetTableRows;
