import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { EditManufacturerDataFragmentDoc } from '../../fragments/generated/EditManufacturer';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditManufacturerQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.ManufacturerFilters>;
}>;


export type EditManufacturerQuery = { __typename?: 'Query', manufacturers: { __typename?: 'ManufacturerConnection', totalCount: number, edges?: Array<{ __typename?: 'ManufacturerEdge', node: { __typename?: 'ManufacturerSchema', id: string, name: string, emailId?: string | null, phoneNo?: string | null, url?: string | null } }> | null } };


export const EditManufacturerDocument = gql`
    query editManufacturer($filters: ManufacturerFilters) {
  manufacturers(filters: $filters) {
    totalCount
    edges {
      node {
        ...EditManufacturerData
      }
    }
  }
}
    ${EditManufacturerDataFragmentDoc}`;

export function useEditManufacturerQuery(options?: Omit<Urql.UseQueryArgs<EditManufacturerQueryVariables>, 'query'>) {
  return Urql.useQuery<EditManufacturerQuery>({ query: EditManufacturerDocument, ...options });
};