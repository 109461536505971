import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Receivables from 'app/i18n/Receivables';
import { useItemsReceivableInOrderQuery } from 'app/modules/receiving/graphql/queries/generated/itemsReceivableInOrder';
import { ReceivableOrderRequestItemErrorCodeEnum } from 'app/types/schema';

const DEFAULT_VALUES = {
  carrier: '',
  trackingNumber: '',
  vendorId: '',
  dueDate: null,
  notes: '',
  orderRequestId: '',
};

const initialState = {
  vendor: {
    companyName: '',
  },
  orderRequest: {
    title: '',
    deliverTo: {
      name: '',
    },
    orderRequestCode: '',
  },
};

interface State {
  vendor: { companyName: string };
  orderRequest: {
    title: string;
    deliverTo: { name: string };
    orderRequestCode: string;
  };
}

const useReceiveForm = () => {
  const { control, handleSubmit, setValue, reset, watch, formState, clearErrors, setError } =
    useForm({
      defaultValues: {
        ...DEFAULT_VALUES,
      },
    });
  const orderRequestId = watch('orderRequestId');

  const [{ data, fetching }] = useItemsReceivableInOrderQuery({
    variables: {
      filter: {
        orderRequestIds: [orderRequestId],
      },
    },
    requestPolicy: 'network-only',
    pause: !orderRequestId,
  });

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => {
        return { ...prevState, ...nextState };
      });
    },
    [_setState, state],
  );

  useEffect(() => {
    if (!fetching) {
      const error = data?.orderRequests?.edges?.[0]?.node?.receivingOrderRequestItems?.errorCode;

      if (orderRequestId && error === ReceivableOrderRequestItemErrorCodeEnum.ZeroOrderedItem) {
        setError('orderRequestId', {
          type: 'custom',
          message: Receivables.FormValidationMessages.OrderWithOrderedStatusItemRequired,
        });
      } else if (
        orderRequestId &&
        error === ReceivableOrderRequestItemErrorCodeEnum.AllItemsReceivedRequest
      ) {
        setError('orderRequestId', {
          type: 'manual',
          message: Receivables.FormValidationMessages.ShipmentAlreadyScheduled,
        });
      } else if (orderRequestId) {
        clearErrors('orderRequestId');
      } else if (formState.submitCount === 0 && !orderRequestId) {
        clearErrors('orderRequestId');
      }
    }
  }, [orderRequestId, formState.submitCount, data, fetching]);

  const onResetForm = useCallback(() => {
    setTimeout(() => {
      reset({
        ...DEFAULT_VALUES,
      });
      setState({
        ...initialState,
      });
    }, 0);
  }, [reset, setState]);

  return {
    formState: {
      control,
      handleSubmit,
      state,
      setValue,
      submitCount: formState.submitCount,
      clearErrors,
      orderRequestId,
      errors: formState.errors,
      isValidatingSelectedOrderId: fetching,
    },
    setFormState: setState,
    onResetForm,
  };
};

export type UseReceiveFormReturnType = ReturnType<typeof useReceiveForm>;

export default useReceiveForm;
