import React from 'react';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import moment from 'moment';
import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';
import { AffiliateConfigurationStatusEnum } from 'app/types/schema';
import Box from 'app/ui-components';
import '../style.css';

export const getAffiliateMerchantsTableColumns = (options?: {
  disabledSorting?: boolean;
  isEditable?: boolean;
  focusId?: string;
  setFocusId?: React.Dispatch<React.SetStateAction<string>>;
}): DataGridHeadCell[] => {
  const { disabledSorting } = options || {};

  return (
    [
      {
        id: 'logo',
        label: 'Logo',
        value: 'logoUrl',
        valueNode: ({ row, state }) => {
          return (
            <Box
              className={
                'flex h-[80px] w-[150px] max-w-[150px] cursor-pointer items-center justify-center overflow-hidden'
              }>
              <img
                alt=""
                className="max-h-[95%] cursor-pointer"
                src={row.logoUrl}
                onClick={() => {
                  state?.extraProps?.showImageViewDialog?.(row.logoUrl);
                }}
              />
            </Box>
          );
        },
        sortable: false,
      },
      {
        id: 'name',
        label: 'Name',
        value: 'name',
        valueNode: ({ row }) => {
          return (
            <Link className="tableRowLink" to={routes.EditAffiliateConfiguration(row?.id)}>
              {row.name}
            </Link>
          );
        },
        sortable: true,
      },
      {
        id: 'commission',
        label: 'Commission',
        value: 'commission',
        valueNode: ({ row }) => {
          return <div>{Number(row?.commission).toFixed(2)} %</div>;
        },
        sortable: true,
      },
      {
        id: 'enable',
        label: 'Enable',
        value: 'enable',
        valueNode: ({ row }) => {
          return row.status === AffiliateConfigurationStatusEnum.Active ? (
            <i aria-hidden="true" className="fa fa-check green" />
          ) : (
            <i aria-hidden="true" className="fa fa-times red" />
          );
        },
        sortable: true,
      },
      {
        id: 'createdBy',
        label: 'Created By',
        value: 'createdBy',
        valueNode: ({ row }) => {
          return <div>{row?.createdBy?.name}</div>;
        },
        sortable: true,
      },
      {
        id: 'updatedBy',
        label: 'Updated By',
        value: 'updatedBy',
        valueNode: ({ row }) => {
          return <div>{row?.createdBy?.name}</div>;
        },
        sortable: true,
      },
      {
        id: 'updatedAt',
        label: 'Last Updated',
        value: 'updatedAt',
        valueNode: ({ row }) => {
          return <div>{moment(row?.updatedAt).format(' MM/DD/YYYY hh:mm A')}</div>;
        },
        sortable: true,
      },
    ] as DataGridHeadCell[]
  ).map((item) => {
    return {
      ...item,
      sortable: disabledSorting ? false : item.sortable,
    } as DataGridHeadCell;
  });
};
