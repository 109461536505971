import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextArea from 'app/components/Form/FormTextArea';
import FormTextInput from 'app/components/Form/FormTextInput';
import Common from 'app/i18n/Common';
import Department from 'app/i18n/Department';
import { CreateDepartmentInput } from 'app/types/schema';
import Box from 'app/ui-components';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { DEPARTMENT_FORM_RULES as Rules } from './utils';

export type DepartmentFormProps = {
  defaultValues?: CreateDepartmentInput;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: (values: CreateDepartmentInput) => void;
};

export default function DepartmentForm(props: DepartmentFormProps) {
  const { defaultValues, disabled, onCancel, onSubmit } = props;

  const { control, handleSubmit } = useForm({ defaultValues });
  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const departmentValues = {
          ...values,
          name: removeMultipleSpaces(values.name),
          description: removeExtraSpacesAndNewlines(values.description),
          formattedDescription: values.description,
          departmentCode: removeMultipleSpaces(values.departmentCode),
        };
        onSubmit(departmentValues);
      }),
    [handleSubmit, onSubmit],
  );

  return (
    <form onSubmit={onFormSubmit}>
      <Box className="space-y-[24px]">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              autoFocus
              isRequired
              characterLimit={30}
              error={fieldState.error}
              label={Department.FormLabels.DepartmentName}
            />
          )}
          rules={Rules.name}
        />
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FormTextArea
              {...field}
              characterLimit={500}
              error={fieldState.error}
              label={Department.FormLabels.Description}
            />
          )}
          rules={Rules.description}
        />
        <Controller
          control={control}
          name="departmentCode"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              characterLimit={30}
              error={fieldState.error}
              label={Department.FormLabels.Code}
            />
          )}
        />
        <Box className="flex justify-end space-x-16">
          <Button
            disabled={disabled}
            loading={disabled}
            theme="success"
            onClick={onFormSubmit}
            classes="min-w-[94px] h-[44px]">
            {Common.Actions.Save}
          </Button>
          <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
            {Common.Actions.Cancel}
          </Button>
        </Box>
      </Box>
    </form>
  );
}
