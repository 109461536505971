import React from 'react';

import { useAccessControl } from '../../../components/AccessControl';
import useCurrentUser from '../../../modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { NavLink } from 'app/libs/navigation';

interface Props {
  action: AllowedPermissionActionsEnum;
  subject: AllowedPermissionsSubjectEnum;
  to: string;
  className?: string;
  activeClassName?: string;
  children?: React.ReactElement;
  target?: string;
}

export const AccessControlLink = (props: Props) => {
  const { to, subject, action, children, className, activeClassName, target } = props;
  const { workspacePermissions, isSuperAdmin } = useCurrentUser();
  const canAccess = useAccessControl(workspacePermissions, action, subject);

  return canAccess ? (
    <NavLink className={className} target={target} activeClassName={activeClassName} to={to}>
      {children}
    </NavLink>
  ) : (
    <span>{children}</span>
  );
};
