import React, { useCallback, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import useCurrentUser from '../../auth/hooks/useCurrentUser';
import { getCompanyContactTabs } from './utils';
import { Box } from '@mui/material';

type Props = React.PropsWithChildren<RouteComponentProps>;
interface IContactCompany extends Props {
  currentPage?: string;
}
const Contacts = (props: IContactCompany) => {
  const { children, history, currentPage } = props;

  const { workspacePermissions } = useCurrentUser();

  const tabs = useMemo(() => {
    return getCompanyContactTabs(workspacePermissions);
  }, [workspacePermissions]);

  const onTabChange = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  const isTabActive = useCallback(
    (tab: string) => {
      if (history.location.pathname.includes('contacts') && tab.includes('contacts')) {
        return true;
      }
      if (!history.location.pathname.includes('contacts') && !tab.includes('contacts')) {
        return true;
      }
      return false;
    },
    [history.location.pathname],
  );
  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}
      mainClassName="!border-0"
      mainWrapperClass={`${currentPage}MainLayout`}>
      <Nav
        tabs
        className="flex gap-[8px] rounded-tr-[12px] rounded-tl-[12px] bg-white p-[16px] md:p-[24px] lg:p-[24px]">
        {tabs.map(({ label, value }) => (
          <NavItem key={value}>
            <NavLink
              className={getTabNavLinkClassName(isTabActive(value))}
              onClick={() => onTabChange(value)}>
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Box className="p-[24px] pt-0">{children}</Box>
    </Layout>
  );
};

export default withRouter(Contacts);
