import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UndeleteInventoryMutationVariables = Types.Exact<{
  input: Types.UndeleteItemsInput;
}>;


export type UndeleteInventoryMutation = { __typename?: 'Mutation', undeleteInventoryItems: { __typename?: 'UndeleteItemsPayload', successCount: number, failedCount: number } };


export const UndeleteInventoryDocument = gql`
    mutation undeleteInventory($input: UndeleteItemsInput!) {
  undeleteInventoryItems(input: $input) {
    successCount
    failedCount
  }
}
    `;

export function useUndeleteInventoryMutation() {
  return Urql.useMutation<UndeleteInventoryMutation, UndeleteInventoryMutationVariables>(UndeleteInventoryDocument);
};