import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { SortOrderEnum } from 'app/types/schema';

import { getAssetItemLocationItemTypes } from '../../../../utils/itemtypes';
import { useAssetAgingReportQuery } from '../../graphql/generated/assetAgingReport';
import { AgingReportFormValues } from '../AgingReportForm/types';
import { getAssetCostTableColumns } from './columns';
import { Box } from '@mui/material';

interface Props {
  values: AgingReportFormValues;
}

const AssetCostTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useAssetAgingReportQuery,
    {
      filters: {
        itemIds: values.skuIds,
        siteIds: values.siteIds,
        categoryIds: values.categoryIds,
        itemTypes: getAssetItemLocationItemTypes(),
        nonZeroTotalQuantity: true,
        lastTransactionDate: values.updatedAt,
      },
      sorts: [{ sortField: 'lastTransactionDate', sortOrder: SortOrderEnum.Desc }],
    },
    { edgeKey: 'itemlocations', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.itemlocations.edges?.map(({ node }) => node) || [];
  }, [data?.itemlocations.edges]);

  return (
    <Box className='mt-[24px] mx-[24px] border border-grey-300 rounded-[12px]' sx={{boxShadow:'0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'}}>
    <ReportPreviewTable
      columns={getAssetCostTableColumns()}
      data={rows}
      initialLoading={initialFetching}
      loading={fetching}
      pagination={true}
      persistKey="asset_aging_table"
      setState={table.setState}
      state={table.state}
      total={data?.itemlocations.totalCount}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationClass='p-[24px]'
      className='px-[0px]'
    />
    </Box>
  );
};

export default AssetCostTable;
