import { useCallback, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Layout } from '@procurenetworks/procure-component-library';
import { matchPath } from 'app/libs/navigation';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import useCurrentUser from '../../auth/hooks/useCurrentUser';
import { getReceivingTabs } from './utils';

type Props = React.PropsWithChildren<RouteComponentProps> & { disabled?: boolean };

function ScheduleLayout(props: Props) {
  const { children, history, disabled } = props;
  const { workspacePermissions } = useCurrentUser();

  const onTabChange = useCallback(
    (tab: string) => {
      history.push(`/receiving/${tab}`);
    },
    [history],
  );

  const tabs = useMemo(() => getReceivingTabs(workspacePermissions), [workspacePermissions]);

  const isTabActive = useCallback(
    (tab: string) => {
      const result = !!matchPath(history.location.pathname, {
        path: `/receiving/${tab}`,
        exact: false,
        strict: false,
      });

      return result;
    },
    [history.location.pathname],
  );

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}
      mainClassName="!border-0">
      <div className="rounded-[12px]">
        <Nav
          tabs
          className="no-scrollbar flex gap-[8px] overflow-x-scroll rounded-tl-[12px] rounded-tr-[12px] bg-white p-[24px] p-[16px] md:p-[24px] xl:p-[24px]">
          {tabs.map(({ label, value }, index) => (
            <NavItem key={value} id={`${index}`}>
              <NavLink
                className={getTabNavLinkClassName(isTabActive(value), disabled)}
                disabled={disabled}
                onClick={() => onTabChange(value)}>
                {label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {children}
      </div>
    </Layout>
  );
}

export default withRouter(ScheduleLayout);
