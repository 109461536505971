import { OrderRequestItemSchema, OrderRequestItemStatusEnum } from '../../../../types/schema';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { ORDER_VIEW } from '../../views/OrderDetail/type';
import QuantityInput from '../../../components/QuantityInput';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useOrderEditPermissionCheck from '../../hook/useOrderEditPermissionCheck';
import { isOrderItemReturnable } from '../../hook/useOrderReturnPermissionCheck';
import { findOrderItemById } from '../../views/OrderDetail/hook/useOrderDetails/utils/utils';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
  state: OrderItemsExtraPropType['state'];
  actions: OrderItemsExtraPropType['actions'];
}

const OrderRequestItemQuantityView = (props: Props) => {
  const { orderRequestItem, state, actions } = props;
  const { orderDetail, orderView } = state;
  const { quantity } = orderRequestItem;

  const { isOrderRequestItemEditable } = useOrderEditPermissionCheck({
    orderDetail,
  });

  const [quantityValue, setQuantityValue] = useState<number>(quantity || 0);

  useEffect(() => {
    setQuantityValue(quantity || 0);
  }, [quantity]);

  const originalOrderItem = useMemo(
    () => findOrderItemById(orderDetail.orderRequestItems, orderRequestItem.id),
    [orderDetail.orderRequestItems, orderRequestItem.id],
  );

  const onQuantityChange = useCallback((value: any) => {
    setQuantityValue(value);
  }, []);

  const onBlurQuantityField = useCallback(() => {
    actions.onUpdateOrderItem(orderRequestItem.id, {
      quantity: quantityValue,
    });
  }, [actions.onUpdateOrderItem, orderRequestItem.id, quantityValue]);

  if (orderView === ORDER_VIEW.EDIT_ORDER_VIEW && isOrderRequestItemEditable(orderRequestItem)) {
    return (
      <QuantityInput
        key={`edit-order-quantity-${orderRequestItem.id}`}
        className="order-items-quantity-input max-w-[100px]"
        minimumValue={0}
        maximumLength={7}
        size="small"
        value={quantityValue}
        onChange={onQuantityChange}
        onBlur={onBlurQuantityField}
        disabled={OrderRequestItemStatusEnum.BackOrdered === orderRequestItem.status}
      />
    );
  }

  if (orderView === ORDER_VIEW.RETURN_ORDER_VIEW && isOrderItemReturnable(orderRequestItem)) {
    return (
      <QuantityInput
        key={`edit-order-quantity-${orderRequestItem.id}`}
        className="order-items-quantity-input max-w-[100px]"
        size="small"
        minimumValue={0}
        maximumValue={originalOrderItem?.quantity || 0}
        value={quantityValue}
        onChange={onQuantityChange}
        onBlur={onBlurQuantityField}
      />
    );
  }

  return <div className="w-[100%] text-right">{orderRequestItem?.quantity || ''}</div>;
};

export default OrderRequestItemQuantityView;
