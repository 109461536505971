import { useMemo } from 'react';
import { Box } from '@mui/material';
import usePagination from 'app/hooks/usePagination';
import { useNetworkAssetsTableQuery } from 'app/modules/network/graphql/queries/generated/networkAssetTable';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';
import { SortOrderEnum } from 'app/types/schema';

import { ITAssetReportFormValues } from '../ITAssetReportForm/types';
import { getITAssetReportTableColumns } from './columns';

interface Props {
  values: ITAssetReportFormValues;
}

const ITAssetTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useNetworkAssetsTableQuery,
    {
      filters: {
        batteryManufacturer: values.batteryManufacturer,
        biosReleaseDate: values.biosReleaseDate,
        biosVersion: values.biosVersion,
        cpuBrand: values.cpuBrand,
        cpuManufacturer: values.cpuManufacturer,
        graphicsModel: values.graphicsModel,
        motherboardModel: values.motherboardModel,
        operatingSystem: values.operatingSystem,
        search: values.search,
        software: values.software,
        statuses: values.status,
        storageName: values.storageName,
        systemModel: values.systemModel,
        systemManufacturer: values.systemManufacturer,
        warrantyTypes: values.warrantyStatus,
        types: values.deviceType,
      },
      sorts: [{ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc }],
    },
    { edgeKey: 'networkAssets', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.networkAssets.edges?.map(({ node }) => node) || [];
  }, [data?.networkAssets.edges]);

  return (
    <Box
      className="mx-[24px] mt-[24px] rounded-[12px] border border-grey-300"
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
      }}>
      <ReportPreviewTable
        className="px-[0px]"
        columns={getITAssetReportTableColumns()}
        data={rows}
        initialLoading={initialFetching}
        loading={fetching}
        pagination={true}
        paginationClass="p-[24px]"
        persistKey="asset_aging_table"
        setState={table.setState}
        state={table.state}
        total={data?.networkAssets.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
      />
    </Box>
  );
};

export default ITAssetTable;
