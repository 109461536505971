import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import { ProjectsTableRowFragmentDoc } from '../../../../Projects/graphql/fragments/generated/ProjectsTableRow';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateProjectMutationVariables = Types.Exact<{
  input: Types.UpdateProjectInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'UpdateProjectPayload', success: boolean, reason?: string | null, project?: { __typename?: 'ProjectSchema', id: string, name: string, description?: string | null, projectCode?: string | null, updatedAt: string, updatedBy: { __typename?: 'UserSchema', id: string, name: string } } | null } };


export const UpdateProjectDocument = gql`
    mutation updateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    success
    reason
    project {
      ...ProjectsTableRow
    }
  }
}
    ${ProjectsTableRowFragmentDoc}`;

export function useUpdateProjectMutation() {
  return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
};