import { useCallback, useMemo, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { CustomIcons, TitleBar } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import SectionList from 'app/modules/contacts/components/contactSectionList';
import Box from 'app/ui-components/Box';
import { getSectionedList } from 'app/utils/contactFilter';

import EntityManager from '../../../components/EntityManager';
import useCompanySearchSuggestion from '../../hook/useCompanySearchSuggestion';
import { useCompnayListQuery } from './graphql/queries/generated/company';

const CompanySearch = (props: RouteComponentProps<{ id: string }>) => {
  const { history } = props;
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [{ fetching, data }] = useCompnayListQuery({
    variables: {
      limit: 10000,
      filters: {
        search: searchBoxValue,
      },
    },
  });

  const companies = useMemo(() => {
    return (
      data?.companies?.edges?.map((compnay) => {
        return { ...compnay.node, companyName: `${compnay.node.companyName}` };
      }) || []
    );
  }, [data?.companies.edges]);

  const _handleSearchEvent = useCallback((inputValue: any) => {
    setSearchBoxValue(inputValue);
  }, []);

  const { searchSuggestions, setSearchValue } = useCompanySearchSuggestion();

  const companyData = useMemo(() => {
    return getSectionedList(companies, 'companyName');
  }, [companies]);

  const handleRowClick = (
    _event: React.MouseEvent<HTMLDivElement | HTMLTableRowElement | MouseEvent, MouseEvent>,
    row: any,
  ) => {
    history.replace(routes.Companies(row.id));
  };

  return (
    <>
      <Grid xs={12}>
        <Box className="min-w-full divide-x-[3px]">
          <TitleBar title="Company Search" />
        </Box>
      </Grid>
      <Grid xs={12}>
        <Box className="mt-32 min-w-full">
          <EntityManager.Search
            autoFocus
            isDropdown
            removedSearchQoutedValue
            suggestionData={searchSuggestions}
            value={searchBoxValue}
            onChange={_handleSearchEvent}
            onChangeSearch={setSearchValue}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                <CustomIcons.SearchIcon />
              </InputAdornment>
            }
            searchFieldWrapper="max-w-[400px]"
          />
          <Box className="mt-[24px]">
            <SectionList
              hideEmpty
              data={companyData}
              keyName="companyName"
              loading={fetching}
              onRowClick={handleRowClick}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};
export default withRouter(CompanySearch);
