import { OrderRequestSchema } from '../../../../../../types/schema';
import EntityManager from '../../../../../components/EntityManager';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import { getOrderItemsColumns } from './columns';

type Props = {
  row: OrderRequestSchema;
};

const OrderItemsTable = (props: Props) => {
  const { row } = props;
  const { orderRequestItems = [] } = row;
  const { table } = useEntityManager({
    selection: false,
    numberOfRowsPerPage: orderRequestItems.length,
  });

  return (
    <div className="ml-[60px] mb-[20px] max-w-[1000px] mt-[16px]">
      <EntityManager.Table
        actions={table.actions}
        columns={getOrderItemsColumns()}
        data={orderRequestItems}
        minWidth={950}
        setState={table.setState}
        state={table.state}
        total={orderRequestItems.length}
        tableBorderRadius='12px'
        tableBorder='none'
      />
    </div>
  );
};

export default OrderItemsTable;
