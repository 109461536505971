import { OrderRequestItemSchema } from '../../../../types/schema';

interface Props {
  orderRequestItem: OrderRequestItemSchema;
}

const OrderRequestItemTitleView = (props: Props) => {
  const { orderRequestItem } = props;
  return (
    <div className="truncate" title={orderRequestItem?.item?.title || ''}>
      {orderRequestItem?.item?.title || ''}
    </div>
  );
};

export default OrderRequestItemTitleView;
