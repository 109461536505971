import { LabelTypeEnum } from '../../../Labels/components/Forms/types';
import Avery5163 from '../../components/Avery/Avery5163';
import PrintLabelContainer from '../../components/PrintLabelContainer';

interface Props {
  values: any;
}

const PrintShippingSetupLabel = (props: Props) => {
  const { values } = props;

  return (
    <>
      <PrintLabelContainer>
        <Avery5163 codes={values} type={LabelTypeEnum.ShippingSetUp} />
      </PrintLabelContainer>
    </>
  );
};

export default PrintShippingSetupLabel;
