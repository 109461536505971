export enum ShipmentFilterKeyTypeEnum {
  SEARCH = 'search',
  DESTINATION_SITE = 'destinationSiteIds',
  REQUESTOR_IDS = 'requestorIds',
  DELIVER_TO_IDS = 'deliverToIds',
  DEPARTMENT_IDS = 'departmentIds',
  STATUSES = 'statuses',
}

export enum ShipmentColumnFilterTypeEnum {
  USER = 'user',
  DESTINATION = 'destination',
  SITE = 'site',
}
