import { useCallback, useMemo } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Layout } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { getTabNavLinkClassName } from 'app/utils/navLink';

import { getWishlistlayoutTabs } from './utils';

function WishlistLayout(props: React.PropsWithChildren<RouteComponentProps>) {
  const { children, history } = props;
  const { workspacePermissions } = useCurrentUser();

  const onTabChange = useCallback(
    (tab: string) => {
      history.push(`/wishlist/${tab}`);
    },
    [history],
  );

  const tabs = useMemo(() => getWishlistlayoutTabs(workspacePermissions), [workspacePermissions]);

  const isTabActive = useCallback(
    (tab: string) => {
      return history.location.pathname.includes(`/${tab}`);
    },
    [history.location.pathname],
  );

  return (
    <Layout
      withAppBar
      components={{
        AppBar: <UnifiedHeader />,
      }}
      mainClassName='!border-[0px]'
      >
      <div className="pb-[26px]">
        <Nav tabs className="flex bg-white p-[24px] gap-[8px] rounded-tr-[12px] rounded-tl-[12px]">
          {tabs.map(({ label, value }) => (
            <NavItem key={value}>
              <NavLink
                className={getTabNavLinkClassName(isTabActive(value))}
                onClick={() => onTabChange(value)}>
                {label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        {children}
      </div>
    </Layout>
  );
}

export default withRouter(WishlistLayout);
