import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { formatDate } from 'app/utils/date';

export const getRolesTableColumns = (): DataGridHeadCell[] => [
  // {
  //   id: 'tenant',
  //   label: 'Organization',
  //   value: 'tenant',
  //   valueNode: ({ row }) => <>{row.tenant?.name || '-'}</>,
  // },
  {
    id: 'role',
    label: 'Role',
    value: 'name',
    sortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    value: 'description',
    addReadMore: true,
  },
];
