import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import InventoryAssetSelectorFormField from 'app/modules/reports/components/InventoryAssetSelectorFormField';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportUsersSelect from 'app/modules/reports/components/ReportUsersSelect';
import { TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import { AuditReportFormProps, AuditReportFormValues } from '../../types';
import AuditReportCreateActions from '../AuditReportCreateActions';
import AuditReportDateFilter from '../AuditReportDateFilter';
import { getAuditDateFilterOptions } from '../AuditReportDateFilter/utils';

const defaultValues: AuditReportFormValues = {
  type: TransactionEntityTypeEnum.Asset,
  siteIds: [],
  userIds: [],
  createdAt: getAuditDateFilterOptions()[0].createdAt,
};

const AuditReportForm = (props: AuditReportFormProps) => {
  const { disabled, onSubmit } = props;

  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (type: TransactionEntityTypeEnum) => {
      reset({ ...defaultValues, type: type });
    },
    [reset],
  );

  return (
    <div>
      <form
        className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
        onSubmit={onFormSubmit}>
        <Box className="space-y-20">
          <Stack alignItems="center" justifyContent="between">
            <InventoryAssetSelectorFormField control={control} onChange={onTypeChange} />
          </Stack>
          <ReportSitesSelect control={control} />
          <ReportUsersSelect control={control} />
          <AuditReportDateFilter control={control} />
          <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
        </Box>
      </form>
    </div>
  );
};

export default AuditReportForm;
