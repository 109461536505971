import {
  arrayValidator,
  composeValidators,
  emailValidator,
  textMaxLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import User from 'app/i18n/User';

export const USER_FORM_RULES = {
  tenantId: {
    validate: textValidator(User.FormValidationMessages.OrganizationRequired),
  },
  firstName: {
    validate: composeValidators(
      textValidator(User.FormValidationMessages.FirstNameRequired),
      textMaxLengthValidator(User.FormValidationMessages.FirstNameRequired, 30),
    ),
  },
  lastName: {
    validate: composeValidators(
      textValidator(User.FormValidationMessages.LastNameRequired),
      textMaxLengthValidator(User.FormValidationMessages.LastNameRequired, 30),
    ),
  },
  emailId: {
    validate: composeValidators(
      textValidator(User.FormValidationMessages.EmailRequired),
      emailValidator(User.FormValidationMessages.EmailInvalid),
    ),
  },
  scopedRoles: {
    validate: arrayValidator(User.FormValidationMessages.UserRoleRequired),
  },
};
