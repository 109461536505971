import React, { createContext, useState } from 'react';
import { useForm } from 'react-hook-form';

export const FromContext = createContext({});

const defaultValues = {
  type: '',
  siteId: '',
  labelFormat: '',
  labelStock: '',
  arrowDisplay: 'no',
  offset: '0',
  location: '',
  custom: '',
  firstField: '',
  secondField: '',
  thirdField: '',
};

interface Props {
  children: React.ReactNode;
}

const FormProvider = ({ children }: Props) => {
  const [isCustomFieldValid, setIsCustomFieldValid] = useState<boolean>(false);
  const { control, watch, setValue, reset, getValues, trigger, unregister, clearErrors, formState: {errors}, handleSubmit } = useForm({defaultValues});

  const values = watch()

  const context = {
    values,
    control,
    watch,
    setValue,
    reset,
    getValues,
    trigger,
    clearErrors,
    unregister,
    errors,
    isCustomFieldValid,
    setIsCustomFieldValid,
    handleSubmit
  };

  return <FromContext.Provider value={context}>{children}</FromContext.Provider>;
};

export default FormProvider;
