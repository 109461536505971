import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import FormUsersSelect from 'app/components/Form/FormUsersSelect';
import FormDatePickerInput from 'app/components/ProcureForm/FormDatePickerInput';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import routes from 'app/consts/routes';
import useDebounce from 'app/hooks/useDebounce';
import Wishlist from 'app/i18n/Wishlist';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import Box from 'app/ui-components/Box';
import { Box as Muibox } from '@mui/material';
import { DateFormatEnum, formatDate } from 'app/utils/date';

import { useWishlistShareIdQuery } from '../../../graphql/queries/generated/wishlistShareId';
import { CREATELIST_FORM_RULES as rules } from '../../../rules';
import { EditWishlistDetailProps } from '../../../types';

function EditWishlistForm(props: EditWishlistDetailProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { defaultValues, watch, control, wishlistId } = props;

  const { id } = useCurrentUser();

  const [customUrl, setCustomUrl] = useState<string>(defaultValues.shareId || '');

  const shareId = watch('shareId');

  const handleCustomUrlChange = (value: string) => {
    setCustomUrl(value);
  };

  const [{ data: wishlistData }] = useWishlistShareIdQuery({
    variables: { filters: { shareId: useDebounce(shareId), wishlistId: defaultValues.id } },
  });

  const isOwner = defaultValues.createdById?.toString() === id.toString();
  const isDuplicate = !!(shareId && wishlistData?.wishlistValidateShareId.exists);

  return (
    <>
      <Muibox
        className="m-[auto] mt-[24px] rounded-[12px] border border-grey-300 p-[24px] lg:max-w-[428px] xl:max-w-[428px]"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <Box className="mx-auto min-w-[120px] max-w-[950px] space-y-24">
          <Typography className="mt-12 text-center text-[30px] font-bold font-semibold text-grey-900">
            Wishlist Registry
          </Typography>
        </Box>
        <Typography className="text-[16ox] text-center text-grey-800">
          Tell us a little about yourself.
        </Typography>
        <Box className="mx-auto mt-12 flex min-w-[120px] max-w-[487px]  flex-col gap-[16px]">
          <Box>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  autoFocus
                  isRequired
                  className="flex-1"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.FirstName}
                  formLabel={Wishlist.FormLabels.FirstName}
                />
              )}
              rules={rules.firstName}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="flex-1"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.LastName}
                  formLabel={Wishlist.FormLabels.LastName}
                />
              )}
              rules={rules.lastName}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.WishlistName}
                  formLabel={Wishlist.FormLabels.WishlistName}
                />
              )}
              rules={rules.wishlistName}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="shippingAddress.address"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.ShippingAddress}
                  formLabel={Wishlist.FormLabels.ShippingAddress}
                />
              )}
              rules={rules.shippingAddress}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="shippingAddress.city"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.City}
                  formLabel={Wishlist.FormLabels.City}
                />
              )}
              rules={rules.city}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="shippingAddress.state"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.State}
                  formLabel={Wishlist.FormLabels.State}
                />
              )}
              rules={rules.state}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="shippingAddress.zipCode"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.ZipCode}
                  formLabel={Wishlist.FormLabels.ZipCode}
                />
              )}
              rules={rules.zipCode}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="eventDate"
              render={({ field, fieldState }) => (
                <FormDatePickerInput
                  {...field}
                  isRequired={true}
                  label={Wishlist.FormLabels.Date}
                  minDate={formatDate(new Date().toString(), DateFormatEnum.YYYY_MM_DD)}
                  classes="mt-[6px]"
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="shareId"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.CustomUrl}
                  onChange={(e) => {
                    const url = e.replace(/[-\s]+/g, '-');
                    field.onChange(url);
                    handleCustomUrlChange(url);
                  }}
                  formLabel={Wishlist.FormLabels.CustomUrl}
                />
              )}
              rules={rules.customUrl(isDuplicate)}
            />
          </Box>
          <Typography className="!mt-0 break-all text-[14px] text-grey-800">
            {`URL: ${process.env.REACT_APP_CORE_UI_URL}${routes.WishlistShareWithId(customUrl)}`}
          </Typography>
          <Box>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.Message}
                  multiline={true}
                  formLabel={Wishlist.FormLabels.Message}
                />
              )}
            />
          </Box>
          <Box>
            {isOwner ? (
              <Controller
                control={control}
                name="collaborators"
                render={({ field, fieldState }) => (
                  <FormUsersSelect
                    {...field}
                    className="flex-1"
                    error={fieldState.error}
                    label={Wishlist.FormLabels.Collaborator}
                    queryVariables={{ excludeCurrentUser: true }}
                  />
                )}
              />
            ) : null}
          </Box>
        </Box>
      </Muibox>
    </>
  );
}

export default EditWishlistForm;
