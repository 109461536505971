import { ReactElement, ReactNode, memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import useQuantityBySiteState from 'app/modules/assets/hook/UseQuantityBySiteState';
import { LocationTableForAssetInventory } from 'app/modules/inventory/components/QuantityBySite';
import Stack from 'app/ui-components/Stack';

import { useAccessControl } from '../../../../../../components/AccessControl';
import Assets from '../../../../../../i18n/Assets';
import Common from '../../../../../../i18n/Common';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ItemTypeEnum,
  TransactionEntityTypeEnum,
} from '../../../../../../types/schema';
import useCurrentUser from '../../../../../auth/hooks/useCurrentUser';
import EntityManager from '../../../../../components/EntityManager';
import { UseMultiSelectEntityManagerReturnType } from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import { UseSearchAssetsStateReturnType } from '../../hook/useSearchAssetsState';
import { getAssetsTableColumns } from '../../utils/columns';
import BulkMoveModal from '../BulkMoveModal';
import { Box } from '@mui/material';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  filters: UseSearchAssetsStateReturnType['filters'];
  showImageViewDialog: UseSearchAssetsStateReturnType['showImageViewDialog'];
  onBulkMove: UseSearchAssetsStateReturnType['onBulkMove'];
  bulkMoveState: UseSearchAssetsStateReturnType['bulkMoveState'];
  bulkEditState: UseSearchAssetsStateReturnType['bulkEditState'];
  tableState: UseSearchAssetsStateReturnType['tableState'];
  isSiteIdSelected: boolean;
  fetching: boolean;
  siteId: string | undefined;
  filterNodes?: ReactNode | ReactElement;
  paginationWrapperClass?: string;
}

const AssetItemTable = (props: Props) => {
  const {
    table,
    filters,
    showImageViewDialog,
    tableState,
    isSiteIdSelected,
    fetching,
    onBulkMove,
    bulkMoveState,
    siteId,
    bulkEditState,
    filterNodes,
    paginationWrapperClass,
  } = props;
  const { workspacePermissions } = useCurrentUser();
  const {
    control,
    formState: { errors },
    getFieldState,
  } = useForm({ mode: 'all' });
  const formHasErrors = !isEmpty(errors);

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Asset,
  );

  const canEdit = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Edit,
    AllowedPermissionsSubjectEnum.Asset,
  );

  const canBulkMove = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.AssetMove,
  );

  const {
    tableData,
    setEditableSiteState,
    onSaveBulkEdit,
    onBulkEdit,
    onCancelBulkEdit,
    fetchingBulkEdit,
  } = useQuantityBySiteState({
    table,
    tableState,
    siteId,
    bulkEditState,
    formHasErrors,
  });

  useEffect(() => {
    const updatedSelectedRowsData = table.state.selectedRowData.filter((row) => {
      return table.state.selectedRowIds.includes(row.id);
    });

    const newSelectedIds = table.state.selectedRowIds.filter((id) => {
      return !table.state.selectedRowData.find((data) => data.id === id);
    });

    // @ts-ignore
    const rows = tableState.rows.filter((row) => newSelectedIds.includes(row.id));
    table.setState({
      selectedRowData: [...updatedSelectedRowsData, ...rows],
    });
  }, [table.state.selectedRowIds, tableState.rows, table.setState]);

  const renderDetailPanelContent = (data: any) => {
    return <LocationTableForAssetInventory row={data.row} siteId={siteId} />;
  };

  const isRowSelectable = useCallback((row: any) => {
    const { type } = row;

    if (type === ItemTypeEnum.AssetModel) {
      return false;
    }

    return true;
  }, []);

  const columns = useMemo(
    () =>
      getAssetsTableColumns({
        isSiteIdSelected,
        siteId,
        isEditable: bulkEditState.open,
      }),
    [isSiteIdSelected, siteId, bulkEditState.open],
  );

  return (
    <div>
      <form>
        <EntityManager.MultiSelectTable
          key={siteId ? 'asset-table-with-site-filter' : 'default-asset-table'}
          ignoreRelayPagination
          pagination
          persistSelectionData
          actions={table.actions}
          columns={columns}
          data={tableData.rows}
          defaultSortState={{
            id: undefined,
          }}
          extraProps={{
            filters,
            showImageViewDialog,
            setEditableSiteState,
            isEditable: bulkEditState.open,
            siteId,
            control,
            getFieldState,
          }}
          getDetailPanelContent={siteId ? renderDetailPanelContent : null}
          getDetailPanelHeight={() => 'auto'}
          isRowSelectable={isRowSelectable}
          loading={tableState.fetching || fetching}
          minWidth={0}
          persistKey="assets_search_table"
          setState={table.setState}
          state={table.state}
          total={tableState.totalCount || 0}
          onNextPage={tableState.onNextPage}
          onPrevPage={tableState.onPrevPage}
          onReset={tableState.onReset}
          filterNodes={filterNodes}
          paginationWrapperClass={paginationWrapperClass}
        />
        <Stack className="mt-8 p-[24px] " justifyContent="end">
          {bulkEditState.open ? (
            <Box className="flex gap-[16px]">
              <Button
                classes="min-w-[94px] !px-[10px] h-[44px]"
                disabled={!table.state.selectedRowIds.length || fetchingBulkEdit || formHasErrors}
                loading={fetchingBulkEdit}
                theme="success"
                onClick={onSaveBulkEdit}>
                {Common.Actions.Save}
              </Button>
              <Button classes="min-w-[94px] !px-[10px] h-[44px]" onClick={() => onCancelBulkEdit()}>
                {Common.Actions.Cancel}
              </Button>
            </Box>
          ) : (
            <>
              <Box className="flex gap-[16px]">
                {canDelete && !isSiteIdSelected ? (
                  <Button
                    classes=" min-w-[94px] !px-[10px] h-[44px]"
                    disabled={!table.state.selectedRowIds.length}
                    theme="danger"
                    onClick={table.actions.onShowDeleteConfirmation}>
                    {Common.Actions.Delete}
                  </Button>
                ) : null}
                {canBulkMove && isSiteIdSelected ? (
                  <Button
                    classes="min-w-[94px] !px-[10px] h-[44px]"
                    disabled={!table.state.selectedRowIds.length}
                    theme="success"
                    onClick={onBulkMove}>
                    {Assets.Actions.BulkMove}
                  </Button>
                ) : null}
                {canEdit ? (
                  <Button
                    classes="min-w-[94px] !px-[10px] h-[44px]"
                    disabled={!table.state.selectedRowIds.length}
                    theme="info"
                    onClick={onBulkEdit}>
                    {Common.Actions.BulkEdit}
                  </Button>
                ) : null}
              </Box>
            </>
          )}
        </Stack>

        <BulkMoveModal
          open={bulkMoveState.open}
          selectedItems={table.state.selectedRowData}
          siteId={siteId}
          type={TransactionEntityTypeEnum.Asset}
          onCancel={() => {
            bulkMoveState.setBulkMoveState({
              open: false,
            });
          }}
        />
      </form>
    </div>
  );
};

export default memo(AssetItemTable);
