import { useContext } from 'react';

import { FindUPCContext } from './FindUPCProvider';

const useFindUPC = () => {
  const context = useContext(FindUPCContext);

  return {
    ...context,
  };
};

export default useFindUPC;
