import { PhoneNumberUtil } from 'google-libphonenumber';
import { Maybe, OrderRequestItemStatusEnum, OrderRequestSchema } from 'app/types/schema';
const phoneUtil = PhoneNumberUtil.getInstance();

export const isEmpty = (value?: Maybe<string> | Maybe<number>): boolean => {
  return (value || '').toString().length === 0;
};

export const textValidator = (message: string) => (value?: Maybe<string>) =>
  (value?.toString() || '').trim().length > 0 || message;

export const spaceValidator = (message: string) => (value?: Maybe<string>) =>
  (value && !/\s/g.test(value)) || message;

export const passwordValidator = (message: string) => (value?: Maybe<string>) =>
  (value && /^(?=.*[a-zA-Z0-9]).{6,}$/g.test(value)) || message;

export const emptyStringValidator = (message: string) => (value?: Maybe<string>) =>
  /^(?![\s\S])|\s*?\w+/g.test(value || '') || message;

export const emptyNumberValidator = (message: string) => (value?: Maybe<string>) =>
  /^\d+/g.test(value || '') || message;

const WEBSITE_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;

export const websiteValidator = (message: string) => (value?: Maybe<string>) => {
  if (!value || (value && WEBSITE_REGEX.test(value))) {
    return true;
  }

  return message;
};

export const matchFull = (regex: RegExp, str: string) => {
  const match = str.match(regex);
  return match && str === match[0];
};

const AFFILIATE_REGEX = /((http(s?):\/\/)(www\.)?)?[a-zA-Z0-9]{2,256}\.[a-zA-Z0-9]+[^\s]{0,}/;

export const affiliateValidator = (message: string) => (value?: Maybe<string>) => {
  if (!value || (value && matchFull(AFFILIATE_REGEX, value))) {
    return true;
  }
  return message;
};

export const zipCodeValidator = (message: string) => (value?: Maybe<string>) =>
  (value?.length === 5 && /^[0-9]+$/.test(value)) || message || false;

export const dateValidator = (message: string) => (value?: Maybe<Date>) => !!value || message;

export const labelValidator = (message: string) => (value?: Maybe<Date>) => !!value || message;

export const arrayValidator = (message: string) => (value?: Maybe<any[]>) =>
  Boolean(value?.length) || message;

export const textMinLengthValidator =
  (message: string, length: number) => (value?: Maybe<string>) =>
    (value || '').trim().length >= length || message;

export const textMaxLengthValidator =
  (message: string, length: number) => (value?: Maybe<string>) =>
    (value || '').trim().length <= length || message;

export const emailValidator = (message: string) => (value?: Maybe<string>) => {
  if (
    !value ||
    /^\s*?(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*?$/.test(
      value || '',
    )
  ) {
    return true;
  }
  return message;
};

export const urlValidator = (message: string) => (value?: Maybe<string>) => {
  if (/^[A-Za-z0-9 _-]*$/.test(value || '')) {
    return true;
  }
  return message;
};

export const confirmValidator =
  (message: string, options?: Maybe<Partial<{ _value?: string; confirmValue?: string }>>) =>
  (value?: Maybe<string>) => {
    const { _value, confirmValue } = options || {};
    if (_value?.trim() === confirmValue?.trim()) {
      return true;
    }
    return message;
  };

export const phoneNumberValidator =
  (message: string, options?: Maybe<{ dialCode?: string; countryCode?: string }>) =>
  (value?: Maybe<string>) => {
    if (!value) {
      return true;
    }

    const { dialCode, countryCode } = options || {};
    try {
      const number_: string = value.slice(dialCode?.length);
      if (number_ && dialCode) {
        const _number = phoneUtil.parse(number_, countryCode);
        if (!phoneUtil.isValidNumber(_number)) {
          return message;
        }
      }
      return true;
    } catch {
      return message;
    }
  };

export const duplicateValidator =
  (message: string, isDuplicate: boolean) => (value?: Maybe<string>) => {
    if (!isDuplicate) {
      return true;
    } else {
      return message;
    }
  };

export const numberValidator = (message: string) => (value?: Maybe<string>) => {
  if (isEmpty(value)) {
    return true;
  }

  if (!(!/^\s*$/.test((value || '').toString()) && !isNaN(Number(value)))) {
    return message;
  }
  return true;
};

export const numberMinValidator = (message: string, min: number) => (value?: Maybe<string>) => {
  if (!value) {
    return true;
  }
  return min <= Number(value) || message;
};
export const numberMaxValidator = (message: string, max: number) => (value?: Maybe<string>) => {
  if (!value) {
    return true;
  }
  return max >= Number(value) || message;
};

export const regexValidator = (message: string, regex: RegExp) => (value?: Maybe<string>) => {
  if (!value) {
    return true;
  }
  return regex.test(value) || message;
};

export const composeValidators = (
  ...validators: Array<(value?: Maybe<string>) => string | boolean>
) => {
  return (value?: Maybe<string>) => {
    let composedResult: string | boolean = true;

    validators.find((validator) => {
      const result = validator(value);

      if (typeof result === 'string') {
        composedResult = result;
        return true;
      }

      return false;
    });

    return composedResult;
  };
};

export const orderWithOrderedItemsValidator =
  (message: string, orderRequest?: Partial<OrderRequestSchema>) =>
  (value?: Maybe<string> | undefined): string | boolean => {
    const orderItems = orderRequest?.orderRequestItems;
    const hasItemWithOrderStatus = orderItems?.some(
      (item) => item.status === OrderRequestItemStatusEnum.Ordered,
    );

    if (hasItemWithOrderStatus) return false;
    return message;
  };
