import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Button, CustomIcons } from '@procurenetworks/procure-component-library';
import { WarningService } from 'app/components/WarningAlert';
import Common from 'app/i18n/Common';
import Orders from 'app/i18n/Orders';
import EntityManager from 'app/modules/components/EntityManager';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';
import ImageViewDialog from 'app/modules/components/ImageViewDialog';
import useImageViewDialogState from 'app/modules/components/ImageViewDialog/hook/useImageViewDialogState';

import { SnackbarService } from '../../../../../../components/Snackbar';
import { FeatureFlagEnum, useFeatureFlag } from '../../../../../../libs/featureFlag';
import { OrderRequestContext } from '../../../../provider/OrderRequestContextProvider/OrderRequestContextProvider';
import { UseItemRequestStateReturnType } from '../OrderItemRequest/hook/useItemRequestState';
import { OrderRequestInput } from '../OrderRequestForm/type';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { FOOTER_TOTAL_ROW_ID, getOrderRequestedItemsColumns } from './utils/columns';
import { composeFilter, getTotalOfItems, validateOrderItems } from './utils/utils';

interface Props {
  state: UseItemRequestStateReturnType['orderItemRequest']['state']['normalOrderState'];
  actions: UseItemRequestStateReturnType['orderItemRequest']['actions']['normalOrderActions'];
  onNextStep: () => void;
  onUpdateOrderItem: (rowId: string, nextState: Partial<OrderRequestInput>) => void;
  onUpdateOrderItems: (nextState: Partial<OrderRequestInput>) => void;
}

const KEYS = [
  'quantity',
  'description',
  'website',
  'upcCode',
  'projectName',
  'itemInStock.sku',
  'itemInStock.title',
];

const OrderItemsTable = (props: Props) => {
  const { isRecreateOrder, getDataFromStorage } = useContext(OrderRequestContext);

  const { state, onNextStep, actions, onUpdateOrderItem, onUpdateOrderItems } = props;

  const { formState, orderItems } = state;
  const { isExternalTenant } = formState;

  const { searchSuggestions, setSearchValue } = useSearchSuggestion({
    orderItems,
  });

  const { value: isProjectFieldEnabled } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );

  const { search, table } = useEntityManager({
    selection: false,
  });

  const { state: imagePreviewState, actions: imagePreviewActions } = useImageViewDialogState();

  const onCancelClick = useCallback(() => {
    WarningService.showWarning({
      message: Orders.FormValidationMessages.UnSavedChangesMessage,
      onConfirm: () => {
        setSearchValue('');
        search.onChange('');
        actions.clearOrderItems();
        SnackbarService.show({
          message: Orders.SuccessMessages.CartCleared,
        });
      },
    });
  }, [actions.clearOrderItems]);

  const filteredOrderItems = useMemo(() => {
    return orderItems.filter(composeFilter(search.debouncedSearchText, KEYS));
  }, [orderItems, search.debouncedSearchText]);

  const filteredOrderRows = useMemo(() => {
    const page = table.state.activePage;
    const numberOfRowsPerPage = table.state.numberOfRowsPerPage;
    return filteredOrderItems.slice(page * numberOfRowsPerPage, (page + 1) * numberOfRowsPerPage);
  }, [filteredOrderItems, table.state.activePage, table.state.numberOfRowsPerPage]);

  const footerRowItems = useMemo(() => {
    if (!filteredOrderRows.length) return [];

    return [
      {
        id: FOOTER_TOTAL_ROW_ID,
        cost: getTotalOfItems(filteredOrderItems),
      },
    ];
  }, [filteredOrderItems]);

  const rows = useMemo(() => {
    return [...filteredOrderRows, ...footerRowItems];
  }, [filteredOrderRows, footerRowItems]);

  const handleOnNext = useCallback(() => {
    const errorMessage = validateOrderItems(orderItems);

    if (errorMessage) {
      SnackbarService.showError({
        message: errorMessage,
      });
      return;
    }

    onNextStep();
  }, [onNextStep, orderItems]);

  const columns = useMemo(
    () =>
      getOrderRequestedItemsColumns({
        isExternalTenant,
        isProjectFieldEnabled,
      }),
    [isExternalTenant, isProjectFieldEnabled],
  );

  if (orderItems.length === 0) {
    return null;
  }

  return (
    <EntityManager className="!px-[0px]">
      <EntityManager.Title title={Orders.ItemRequested} />
      <Box
        className="mt-[24px] rounded-[12px] border border-grey-300"
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}>
        <EntityManager.Table
          ignoreRelayPagination
          actions={table.actions}
          columns={columns}
          data={rows}
          extraProps={{
            imagePreviewActions,
            onUpdateOrderItem,
            onUpdateOrderItems,
            actions,
            showLastUpdatedProjects: isRecreateOrder,
          }}
          filterNodes={
            <EntityManager.Search
              {...search}
              autoFocus
              isDropdown
              removedSearchQoutedValue
              placeholder="Search"
              startAdornment={
                <InputAdornment className="mt-[4px] ml-[5px]" position="start">
                  <CustomIcons.SearchIcon />
                </InputAdornment>
              }
              suggestionData={searchSuggestions}
              onChangeSearch={setSearchValue}
            />
          }
          minWidth={1466}
          pagination={true}
          paginationWrapperClass="p-[24px] flex-wrap gap-[16px]"
          persistKey={
            isExternalTenant ? 'order-request_external_tenant' : 'order_request_items_table'
          }
          rowHeight={80}
          setState={table.setState}
          state={table.state}
          total={filteredOrderItems.length}
        />
        <div className="flex justify-end gap-[16px] py-[16px] px-[24px]">
          <Button classes="min-w-[94px] h-[44px]" theme="info" onClick={handleOnNext}>
            {Common.Actions.Next}
          </Button>
          <Button classes="min-w-[94px] h-[44px]" onClick={onCancelClick}>
            {Common.Actions.Cancel}
          </Button>
        </div>
      </Box>
      <ImageViewDialog
        id="order-item-table-image-view"
        imageUrl={imagePreviewState.imageUrl}
        open={imagePreviewState.open}
        onClose={imagePreviewActions.onClose}
      />
    </EntityManager>
  );
};

export default OrderItemsTable;
