export const multipleManagersDeleteMessage = ({ totalSelectedRows,
    categoryManagerNames,
    categoryNames,
    siteManagerNames,
    siteNames }: any) =>
    `${categoryManagerNames.length > 0 ? 'Category Manager' : ''} 
     ${categoryManagerNames.length > 0 && siteManagerNames.length > 0 ? '&' : ''} 
     ${siteManagerNames.length > 0 ? 'Site Manager' : ''} 
     is a required field. 
     ${String(siteManagerNames)} ${String(categoryManagerNames)}
     is a 
     ${categoryManagerNames.length > 0 ? 'Category Manager' : ''} 
     ${categoryManagerNames.length > 0 && siteManagerNames.length > 0 ? '&' : ''} 
     ${siteManagerNames.length > 0 ? 'Site Manager' : ''} for 
     ${String(categoryNames)} ${String(siteNames)}. Please add a new ${categoryManagerNames.length > 0 ? 'Category Manager' : ''} 
     ${categoryManagerNames.length > 0 && siteManagerNames.length > 0 ? '&' : ''} 
     ${siteManagerNames.length > 0 ? 'Site Manager' : ''} before deleting 
     ${String(categoryManagerNames)} 
     ${String(siteManagerNames)} 
    `