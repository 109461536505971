/* eslint-disable no-restricted-globals */
import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import { cx } from '@emotion/css';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { PaperSize } from '@progress/kendo-drawing/pdf';
import { PDFExport } from '@progress/kendo-react-pdf';
import _ from 'lodash';
import Loader from 'app/components/Loader';
import useDimensions from 'app/hooks/useDimensions';
import Box from 'app/ui-components';

import { LabelStockTitleEnum } from '../types';
interface Props {
  children: React.ReactNode;
  print?: LabelStockTitleEnum;
  type?: string;
  margin?: { top: number; left: number; bottom: number; right: number };
}

const PdfViewer = (props: Props) => {
  const [pdf, setPdf] = useState('');
  const [displayPdf, setDisplayPdf] = useState('block');
  const bodyRef = useRef<any>();
  const pdfViewer = useRef();
  const { width, height } = useDimensions();
  const { children, print, margin, type } = props;
  const paperSize = print === LabelStockTitleEnum?.ZebraPrinter ? [140,  120] : 'Letter';;

  const buildPdf = async () => {
    let gridElement = document.getElementById('divToPrint') as HTMLElement;
    const group = await drawDOM(gridElement, {
     
      paperSize: paperSize as PaperSize | undefined,
     
      margin: margin,
    });
    const dataUri = await exportPDF(group, { title: `${_.capitalize(type)} - ${print}`});
    const data = await fetch(dataUri);
    const blob = await data.blob();
    const blobUrl = URL.createObjectURL(blob);
    setPdf(blobUrl);
  };

  useEffect(() => {
    buildPdf();
  }, [bodyRef]);

  useEffect(() => {
    if (pdf) {
      setDisplayPdf('none');
    }
  }, [pdf]);

  const keyPress = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    if (pdfViewer && pdfViewer.current) {
      (pdfViewer?.current as unknown as HTMLIFrameElement)?.contentWindow?.print();
    }
  }, [pdfViewer])

  useHotkeys('cmd+p, ctrl+p', keyPress);

  return (
    <>
      <Box>
        {!pdf ? (
          <Box className={cx('fixed top-0 left-0 h-full w-full bg-[#f1f2f7]')}>
            <Loader />
          </Box>
        ) : (
          <iframe
            ref={pdfViewer as unknown as LegacyRef<HTMLIFrameElement> | undefined}
            height={height}
            id="pdfViewer"
            src={pdf}
            title={'Procure'}
            width={width}></iframe>
        )}
      </Box>
      <PDFExport ref={bodyRef} forcePageBreak=".page-break" paperSize={'a4'}>
        <Box className={'m-auto'} id="divToPrint" style={{ display: displayPdf }}>
            {children}
        </Box>
      </PDFExport>
    </>
  );
};

PdfViewer.defaultProps = {
  margin: { top: 10, left: 10, bottom: 10, right: 10 },
};

export default PdfViewer;
