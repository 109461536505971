import _ from 'lodash';

import { UseUppyFileType } from '../../../uppy/types';
import { parseFileName } from '../../ProcureForm/FormFileUploader/utils';

export function isDuplicateFile(files: Record<string, UseUppyFileType>, newFile: any) {
  const duplicateFile = _.values(files).find((existingFile) => {
    return parseFileName(newFile.name) === existingFile.name;
  });

  return Boolean(duplicateFile);
}

export const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  gap: '10px',
};

export function convertBytesToMb(bytes: number): number {
  const bytesOf1Mb = 1048576;
  return bytes / bytesOf1Mb;
}
