import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EditAffiliateMerchantDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.AffiliateConfigurationFilters>;
}>;


export type EditAffiliateMerchantDataQuery = { __typename?: 'Query', affiliateConfigurations: { __typename?: 'AffiliateConfigurationConnection', totalCount: number, edges: Array<{ __typename?: 'AffiliateConfigurationEdge', node: { __typename?: 'AffiliateConfigurationSchema', id: string, name: string, affiliateUrl: string, logoUrl: string, commission: number, domain: string, status: Types.AffiliateConfigurationStatusEnum } }> } };


export const EditAffiliateMerchantDataDocument = gql`
    query editAffiliateMerchantData($filters: AffiliateConfigurationFilters) {
  affiliateConfigurations(filters: $filters) {
    totalCount
    edges {
      node {
        id
        name
        affiliateUrl
        logoUrl
        commission
        domain
        status
      }
    }
  }
}
    `;

export function useEditAffiliateMerchantDataQuery(options?: Omit<Urql.UseQueryArgs<EditAffiliateMerchantDataQueryVariables>, 'query'>) {
  return Urql.useQuery<EditAffiliateMerchantDataQuery>({ query: EditAffiliateMerchantDataDocument, ...options });
};