import { forwardRef, HTMLInputTypeAttribute, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import { cx } from '@emotion/css';
import Select from 'app/components/Select';
import { SelectOption } from 'app/components/Select/types';
import { Maybe } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import FormError from '../FormError';
import FormLabel from '../FormLabel';

interface Props {
  className?: string;
  selectClassName?: string;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  name?: string;
  value?: Maybe<string>;
  onBlur?: () => void;
  onChange?: (value?: Maybe<string>) => void;
  type?: HTMLInputTypeAttribute;
  error?: FieldError;
  placeholder?: string;
  options: SelectOption[];
  getOptionDisabled?: any;
  disableClearable?: boolean;
}

const FormSelect = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
    selectClassName,
    error,
    label,
    options,
    name,
    isRequired,
    disabled,
    value,
    onBlur,
    onChange: onChangeProp,
    disableClearable,
    placeholder,
    autoFocus,
    getOptionDisabled,
  } = props;

  const onChange = useCallback(
    (value: SelectOption | string | null) => {
      if (!value) {
        onChangeProp?.(value);
      } else if (typeof value === 'object') {
        onChangeProp?.(value.value);
      } else {
        onChangeProp?.(value);
      }
    },
    [onChangeProp],
  );
  return (
    <Box ref={ref} className={cx('flex flex-col', className)}>
      {label ? <FormLabel isRequired={isRequired}>{label}</FormLabel> : null}
      <Select
        autoFocus={autoFocus}
        className={cx("mt-6", selectClassName)}
        disableClearable={disableClearable}
        getOptionDisabled={getOptionDisabled}
        isDisabled={disabled}
        name={name}
        options={options}
        placeholder={placeholder}
        value={Array.isArray(value) ? value?.[0] : value}
        onBlur={onBlur}
        onChange={onChange}
      />
      <FormError error={error} />
    </Box>
  );
});

export default FormSelect;
