import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAssetItemReminderMutationVariables = Types.Exact<{
  input: Types.DeleteAssetItemReminderInput;
}>;


export type DeleteAssetItemReminderMutation = { __typename?: 'Mutation', deleteAssetItemReminder: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteAssetItemReminderDocument = gql`
    mutation deleteAssetItemReminder($input: DeleteAssetItemReminderInput!) {
  deleteAssetItemReminder(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteAssetItemReminderMutation() {
  return Urql.useMutation<DeleteAssetItemReminderMutation, DeleteAssetItemReminderMutationVariables>(DeleteAssetItemReminderDocument);
};