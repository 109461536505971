import {
  composeValidators,
  emailValidator,
  textMaxLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Profile from 'app/i18n/Profile';

export const PROFILE_FORM_RULES = {
  firstName: {
    validate: composeValidators(
      textValidator(Profile.FormValidations.FirstNameInvalid),
      textMaxLengthValidator(Profile.FormValidations.FirstNameInvalid, 30),
    ),
  },
  lastName: {
    validate: composeValidators(
      textValidator(Profile.FormValidations.LastNameInvalid),
      textMaxLengthValidator(Profile.FormValidations.LastNameInvalid, 30),
    ),
  },
  currentPassword: {
    validate: textValidator(Profile.FormValidations.CurrentPassword),
  },
  email: {
    validate: composeValidators(
      textValidator(Profile.FormValidations.EmailFieldEmpty),
      emailValidator(Profile.FormValidations.EmailInvalid),
    ),
  },
};
