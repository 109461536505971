import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateInventoryItemMutationVariables = Types.Exact<{
  input: Types.CreateInventoryItemInput;
}>;


export type CreateInventoryItemMutation = { __typename?: 'Mutation', createInventoryItem: { __typename?: 'CreateInventoryItemPayload', success: boolean, reason?: string | null, inventoryItem?: { __typename?: 'InventoryItemSchema', id: string, title: string } | null } };


export const CreateInventoryItemDocument = gql`
    mutation createInventoryItem($input: CreateInventoryItemInput!) {
  createInventoryItem(input: $input) {
    success
    reason
    inventoryItem {
      id
      title
    }
  }
}
    `;

export function useCreateInventoryItemMutation() {
  return Urql.useMutation<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>(CreateInventoryItemDocument);
};