import '../style.css';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@procurenetworks/procure-component-library';
import FormSelect from 'app/components/Form/FormSelect';
import FormTextInput from 'app/components/Form/FormTextInput';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';

import { SIGNUP_FORM_RULES as rules } from '../rules';
import { SignUpDonorFormProps } from '../types';

function SignUpDonorForm(props: SignUpDonorFormProps) {
  const { isLoading, onSubmit, defaultValues, onCancel } = props;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: defaultValues,
    reValidateMode: 'onSubmit',
  });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter') {
        onFormSubmit();
        event.preventDefault();
      }
    },
    [onFormSubmit],
  );

  const email = watch('email');
  const confirmEmail = watch('confirmEmail');
  const password = watch('password');
  const confirimPassword = watch('confirmPassword');

  return (
    <form onKeyDown={onKeyDown} onSubmit={onFormSubmit}>
      <Box className="mb-24 min-w-[280px] max-w-[900px] space-y-8">
        <Box>
          <h1 className="heading text-[gray]">Save More. Serve More.</h1>
          <h1 className="heading text-[#66CCFF]">Create an Account</h1>
        </Box>
        <p className="content">{SignUp.FormLabels.donor.description.first}</p>
        <p className="content">{SignUp.FormLabels.donor.description.second}</p>
        <p className="content">{SignUp.FormLabels.donor.description.third}</p>
        <p className="content">{SignUp.FormLabels.donor.description.forth}</p>
      </Box>
      <Box className="space-y-28 mx-auto min-w-[200px] max-w-[400px]">
        <Controller
          control={control}
          name="tenantId"
          render={({ field, fieldState }) => {
            return (
              <FormSelect
                {...field}
                className="flex-1"
                options={[{ value: '1', label: 'Procure' }]}
                placeholder={SignUp.FormLabels.donor.SelectOrganization}
                value={'value'}
                // onChange={onChange}
              />
            );
          }}
        />
        <Box className="mt-12 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="w-full md:flex-1"
                error={fieldState.error}
                placeholder={SignUp.FormLabels.FirstName}
              />
            )}
            rules={rules.firstName}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="w-full md:flex-1"
                error={fieldState.error}
                placeholder={SignUp.FormLabels.LastName}
              />
            )}
            rules={rules.lastName}
          />
        </Box>

        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="rounded-none"
              error={fieldState.error}
              placeholder={SignUp.FormLabels.donor.Email}
            />
          )}
          rules={rules.email}
        />

        <Controller
          control={control}
          name="confirmEmail"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="rounded-none"
              error={fieldState.error}
              placeholder={SignUp.FormLabels.donor.confirmEmail}
            />
          )}
          rules={rules.confirmEmail(email, confirmEmail)}
        />

        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="w-full rounded-none"
              error={fieldState.error}
              placeholder={SignUp.FormLabels.donor.Password}
              type="password"
            />
          )}
          rules={rules.password}
        />

        <Controller
          control={control}
          name="confirmPassword"
          render={({ field, fieldState }) => (
            <FormTextInput
              {...field}
              isRequired
              className="rounded-none"
              error={fieldState.error}
              placeholder={SignUp.FormLabels.donor.ConfirmPassword}
              type="password"
            />
          )}
          rules={rules.confirmPassword(password, confirimPassword)}
        />

        <Box className="mt-32 flex flex-col justify-end space-y-4 md:flex-row md:space-y-0 md:space-x-4">
          <Button
            classes="min-h-[51px] bg-gray md:w-[116px]"
            disabled={isLoading}
            onClick={onCancel}>
            {SignUp.FormActions.cancel}
          </Button>
          <Button
            classes="min-h-[51px] md:w-[218px]"
            disabled={isLoading}
            loading={isLoading}
            theme="success"
            onClick={onFormSubmit}>
            {SignUp.FormActions.donorSignUp}
          </Button>
        </Box>

        <Box className="mx-auto min-w-[100px] max-w-[178px] space-y-8 text-[10px]">
          <p className="term-and-policy">{SignUp.FormLabels.donor.TermAndPolicyText}</p>
        </Box>
      </Box>
    </form>
  );
}

export default SignUpDonorForm;
