import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateOrderRequestMutationVariables = Types.Exact<{
  input: Types.CreateOrderRequestInput;
}>;


export type CreateOrderRequestMutation = { __typename?: 'Mutation', createOrderRequest: { __typename?: 'CreateOrderRequestPayload', success: boolean, orderRequest?: { __typename?: 'OrderRequestSchema', id: string } | null } };


export const CreateOrderRequestDocument = gql`
    mutation createOrderRequest($input: CreateOrderRequestInput!) {
  createOrderRequest(input: $input) {
    success
    orderRequest {
      id
    }
  }
}
    `;

export function useCreateOrderRequestMutation() {
  return Urql.useMutation<CreateOrderRequestMutation, CreateOrderRequestMutationVariables>(CreateOrderRequestDocument);
};