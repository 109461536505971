import { useEffect, useState } from 'react';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';
import {
  AssetItemSchema,
  AssetKitItemSchema,
  InventoryItemSchema,
  InventoryKitItemSchema,
  ItemStatusEnum,
} from 'app/types/schema';
import { processSearch } from 'app/utils/processSearch';

import { useNewAuditItemLocationsSearchSuggestionQuery } from '../../views/AuditReport/graphql/queries/generated/newAuditItemLocationsSearchSuggestion';

const useSearchSuggestion = () => {
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [entityType, setEntityType] = useState<any>();
  const [initialSiteId, setInitialSite] = useState('');
  const [siteId, setSite] = useState<string>(initialSiteId);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<suggestionData[]>();

  const [{ data }] = useNewAuditItemLocationsSearchSuggestionQuery({
    variables: {
      filters: {
        types: entityType,
        siteIds: [siteId || initialSiteId],
        statuses: [ItemStatusEnum.Active],
        search: searchedValue,
      },
    },
    pause: !siteId && !initialSiteId,
  });

  useEffect(() => {
    const result = data?.items?.edges?.map((a) => {
      const item = a.node as AssetItemSchema | InventoryItemSchema | AssetKitItemSchema | InventoryKitItemSchema;

      const {result, searchBy} = processSearch(["title","sku"], searchedValue, item)

      return {
        label: searchBy,
        id: item.id,
        value: result
      };
    });
    setSearchSuggestions(result);
  }, [data]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchedValue(searchValue);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  return {
    searchSuggestions,
    setSearchValue,
    setEntityType,
    setSite,
    setInitialSite,
  };
};

export type UseSearchSuggestionReturnType = ReturnType<typeof useSearchSuggestion>;

export default useSearchSuggestion;
