import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Project from 'app/i18n/Project';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import ProjectForm from 'app/modules/projects/components/ProjectForm';
import { CreateProjectInput } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import { useCreateProjectMutation } from './graphql/mutations/generated/createProject';
import analytics from 'app/analytics';

const DEFAULT_VALUES: CreateProjectInput = {
  name: '',
  description: '',
  projectCode: '',
};

const AddProject = (props: RouteComponentProps) => {
  const { history } = props;
  const [{ fetching }, onCreateProject] = useCreateProjectMutation();

  const onSubmit = useCallback(
    (input: CreateProjectInput) => {
      onCreateProject({ input }).then((response) => {
        analytics?.track('Created', { name: 'Project' });
        if (response.data?.createProject.project?.id) {
          SnackbarService.show({ message: 'This project has been successfully created.' });
          history.replace(routes.Projects());
        }
      });
    },
    [history, onCreateProject],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Projects());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Project.NewProject} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <ProjectForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AddProject);
