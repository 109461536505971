import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { CheckBox, TitleBar } from '@procurenetworks/procure-component-library';
import FormFieldContainer from 'app/components/Form/FormFieldContainer';
import { arrayValidator } from 'app/components/Form/utils/validators';
import Reports from 'app/i18n/Reports';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';
import clsx from 'clsx';
import { TransactionEntityTypeEnum } from '../../../../types/schema';
import { radioGroupStyleOverrides } from './styles';
import { getTransactionTypeItems, REPORT_TYPES } from './utils';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';

interface Props {
  control: any;
  validate?: boolean;
  types: TransactionEntityTypeEnum[];
  reportType: REPORT_TYPES;
}

const RULES = {
  validate: arrayValidator(Reports.TransactionTypes.ValidationMessage),
};

const TransactionTypeFormField = (props: Props) => {
  const { control, validate, types, reportType } = props;

  const transactionTypeItems = useMemo(() => {
    return getTransactionTypeItems(reportType, types);
  }, [types]);

  return (
    <Box className={radioGroupStyleOverrides}>
      <Title title={Reports.TransactionType} isRequired={!!validate} className='!text-grey-900'/>
      <Controller
        control={control}
        name="transactionTypes"
        render={({ field, fieldState }) => (
          <FormFieldContainer error={fieldState.error}>
            <Stack className="my-12" direction="col">
              {transactionTypeItems.map((item) => {
                const onChange = () => {
                  if (field.value.includes(item.value)) {
                    field.onChange(field.value.filter((value: string) => value !== item.value));
                  } else {
                    field.onChange([...field.value, item.value]);
                  }
                };

                return (
                  <CheckBox
                    key={item.id}
                    label={item.label}
                    value={field.value.includes(item.value)}
                    onChange={onChange}
                  />
                );
              })}
            </Stack>
          </FormFieldContainer>
        )}
        rules={validate ? RULES : undefined}
      />
    </Box>
  );
};

export default TransactionTypeFormField;

const useStyles = makeStyles({
  content: {
    padding: '10px 15px',
  },
  title: {
    color: '#58c9f9',
    fontSize: '16px',
    fontWeight: 600,
    padding: '10px 15px 10px 0px',
    textAlign: 'left',
  },
});

const Title: React.FC<{ title: string; isRequired: boolean,className?:string }> = (props) => {
  const classes = useStyles();
  const { title, isRequired ,className} = props;
  return (
    <div>
      <div className={clsx(classes.title,className)}>
        {title} {isRequired && <span className="text-[14px] text-red-800">*</span>}
      </div>
      <Divider />
    </div>
  );
};
