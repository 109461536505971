import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportDepartmentsSelect from 'app/modules/reports/components/ReportDepartmentsSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportProjectsSelect from 'app/modules/reports/components/ReportProjectsSelect';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportSKUSelect from 'app/modules/reports/components/ReportSKUSelect';
import ReportUsersSelect from 'app/modules/reports/components/ReportUsersSelect';
import Box from 'app/ui-components/Box';

import { ItemTypeEnum, LocationTypeEnum } from '../../../../../../types/schema';
import { getItemLocationItemTypesFromTransactionType } from '../../../../utils/itemtypes';
import OrderRequestDateField from '../OrderRequestDateField';
import OrderRequestDueDateField from '../OrderRequestDueDateField';
import OrderRequestFormField from '../OrderRequestFormField';
import OrderRequestStatusSelect from '../OrderRequestStatusSelect';
import { OrderRequestReportFormProps, OrderRequestReportFormValues } from './types';

const defaultValues: OrderRequestReportFormValues = {
  itemIds: [],
  categoryIds: [],
  fulfillingSiteIds: [],
  departmentIds: [],
  projectIds: [],
  createdByIds: [],
  destinationSiteIds: [],
  billToSiteIds: [],
  orderItemStatuses: [],
  orderRequestDateType: '',
  orderRequestDueDateType: '',
  format: 'preview',
};

const OrderRequestReportForm = (props: OrderRequestReportFormProps) => {
  const { disabled, onSubmit } = props;
  const { control, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues });

  const itemTypes = useMemo(() => getItemLocationItemTypesFromTransactionType(), []);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  return (
    <form
      className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
      onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <OrderRequestFormField autoFocus control={control} />
        <ReportSKUSelect control={control} name="itemIds" types={itemTypes} />
        <ReportCategoriesSelect control={control} />
        <ReportSitesSelect
          control={control}
          label="Site From"
          name="fulfillingSiteIds"
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <ReportDepartmentsSelect control={control} />
        <ReportProjectsSelect control={control} />
        <ReportUsersSelect control={control} name="createdByIds" />
        <ReportSitesSelect
          control={control}
          label="Ship To"
          name="destinationSiteIds"
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <ReportSitesSelect
          control={control}
          label="Bill To"
          name="billToSiteIds"
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <OrderRequestStatusSelect control={control} />
        <OrderRequestDateField control={control} getValues={getValues} setValue={setValue} />
        <OrderRequestDueDateField control={control} getValues={getValues} setValue={setValue} />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default OrderRequestReportForm;
