import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteInventoryKitItemsMutationVariables = Types.Exact<{
  input: Types.DeleteInventoryKitItemInput;
}>;


export type DeleteInventoryKitItemsMutation = { __typename?: 'Mutation', deleteInventoryKitItem: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteInventoryKitItemsDocument = gql`
    mutation deleteInventoryKitItems($input: DeleteInventoryKitItemInput!) {
  deleteInventoryKitItem(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteInventoryKitItemsMutation() {
  return Urql.useMutation<DeleteInventoryKitItemsMutation, DeleteInventoryKitItemsMutationVariables>(DeleteInventoryKitItemsDocument);
};