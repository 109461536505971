import { useEffect } from 'react';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { InventoryItemFilters, ItemTypeEnum } from 'app/types/schema';

import useInventoryItemRows from '../useInventoryItemRows';
import { UseInventoryStateReturnType } from '../useInventoryState';

interface Props {
  state: UseInventoryStateReturnType['state'];
  siteId: string | undefined;
  onDelete?: (ids: string[], item?: object[]) => void;
}

const useInventoryTable = (props: Props) => {
  const { state, siteId, onDelete } = props;

  const { search, table } = useMultiSelectEntityManager({ onDelete, hideActions: true });

  const numberOfRowsPerPage = table.state.numberOfRowsPerPage;

  const filters = {
    search: search.debouncedSearchText,
    categoryIds: state.categoryIds,
    siteIds: state.siteIds,
    vendorIds: state.companyIdsState,
    types: [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit],
    brands: state.brands,
    mNames: state.mNames,
    includeLocationsInSearch: !!siteId,
  } as InventoryItemFilters;

  const tableState = useInventoryItemRows({
    filters: {
      ...filters,
    },
    sorts: table.state.sorts,
    siteId,
    numberOfRowsPerPage,
  });

  useEffect(() => {
    table?.actions?.onResetSelectedRow();
  }, [siteId]);

  return {
    tableState,
    search,
    table,
  };
};

export type UseInventoryTableReturnType = ReturnType<typeof useInventoryTable>;
export default useInventoryTable;
