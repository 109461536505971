import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormValuesInItemsSelectQueryVariables = Types.Exact<{
  field: Types.ItemStringBasedColumnFilterFieldEnum;
  filters?: Types.InputMaybe<Types.ItemFilters>;
}>;


export type FormValuesInItemsSelectQuery = { __typename?: 'Query', valuesInItems: Array<string> };


export const FormValuesInItemsSelectDocument = gql`
    query formValuesInItemsSelect($field: ItemStringBasedColumnFilterFieldEnum!, $filters: ItemFilters) {
  valuesInItems(field: $field, filters: $filters)
}
    `;

export function useFormValuesInItemsSelectQuery(options: Omit<Urql.UseQueryArgs<FormValuesInItemsSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormValuesInItemsSelectQuery>({ query: FormValuesInItemsSelectDocument, ...options });
};