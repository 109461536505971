import {
  AssetItemSchema,
  AssetKitItemSchema,
  InventoryItemSchema,
  InventoryKitItemSchema,
  ItemTypeEnum,
} from '../../../types/schema';
import { useItemInStockSelectQuery } from '../graphql/query/generated/itemInStockSelect';

export type ItemInStockType =
  | AssetItemSchema
  | AssetKitItemSchema
  | InventoryItemSchema
  | InventoryKitItemSchema
  | undefined;

const useItemInStockData = (itemInStockId?: string, types?: ItemTypeEnum[]) => {
  const [{ data: itemInStock, fetching }] = useItemInStockSelectQuery({
    variables: {
      filters: {
        itemIds: itemInStockId ? [itemInStockId] : [],
        types: types || [],
      },
    },
    pause: !Boolean(itemInStockId),
  });

  return {
    fetching,
    itemInStock: (itemInStock?.items?.edges?.[0]?.node as ItemInStockType) || undefined,
  };
};

export default useItemInStockData;
