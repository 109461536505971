import { useTenantSelectQuery } from './graphql/query/generated/tenantSelect';
import { adaptNodeEdgeToNode } from '../../../components/AsyncMultiSelect/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TenantStatusEnum } from 'app/types/schema';
import Select from 'react-select';
import './style.css';

export type TenantOption = {
  label: string;
  value: string;
};

interface Props {
  value: string;
  disabled?: boolean;
  onChange?: (value: string, option?: TenantOption) => void;
  renderInput?: (params: any) => React.ReactElement;
}

export function getOptionLabel(option: any) {
  return option.name;
}

const getMaxLength = (options: TenantOption[]) => {
  return options.reduce((agg, current) => {
    return current.label.length > agg ? current.label.length : agg;
  }, 0);
};

const FormTenantSelect = (props: Props) => {
  const { disabled, value, onChange, renderInput } = props;

  const [selectedValue, setSelectedValue] = useState<TenantOption | null>(null);

  const [{ fetching: fetchingSelectedData, data: selectedData }] = useTenantSelectQuery({
    variables: {
      filter: {
        statuses: [TenantStatusEnum.Active],
        tenantIds: value ? [value] : undefined,
      },
    },
    pause: !value || Boolean(value && selectedValue && selectedValue.value === value),
  });

  useEffect(() => {
    const selectedOptions = selectedData?.tenants.edges.map(adaptNodeEdgeToNode);
    if (selectedOptions) {
      const selectedOption = selectedOptions?.[0];
      setSelectedValue(
        selectedOption
          ? ({
              label: selectedOption?.name || '',
              value: selectedOption?.id,
            } as any)
          : null,
      );
    }
  }, [selectedData?.tenants.edges]);

  const [{ fetching, data: optionsData }] = useTenantSelectQuery({
    variables: {
      filter: {
        statuses: [TenantStatusEnum.Active],
      },
    },
  });

  const options = useMemo(() => {
    return (
      optionsData?.tenants?.edges?.map(({ node }) => {
        return {
          label: node.name,
          value: node.id,
        } as any;
      }) || []
    );
  }, [optionsData?.tenants?.edges]);

  const onValueChange = useCallback(
    (option: any) => {
      if (option) {
        setSelectedValue(option as TenantOption);
        onChange?.(option.value, option);
      } else {
        setSelectedValue(option);
        onChange?.('');
      }
    },
    [onChange],
  );

  return (
    <div
      className="ml-[12px] block"
      style={{
        maxWidth: `${getMaxLength(options) + 70}px`,
      }}>
      <Select
        className="block"
        classNamePrefix="tenant-dropdown"
        isLoading={fetchingSelectedData}
        onChange={onValueChange}
        options={options}
        placeholder={''}
        isDisabled={disabled}
        value={selectedValue}
      />
    </div>
  );
};

export default FormTenantSelect;
