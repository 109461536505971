import { Stack } from "@mui/material";
import { useAccessControl } from "app/components/AccessControl";
import useCurrentUser from "app/modules/auth/hooks/useCurrentUser";
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from "app/types/schema";

import AssetsReservedTable from "./components/AssetsReserved";
import ReserveAssetFormAndTable from "./components/ReserveAssetFormAndTable";


const AssetsReservation = () => {
  const { workspacePermissions } = useCurrentUser();
  const canCreate = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Create,
    AllowedPermissionsSubjectEnum.AssetReserve,
  )
  
  return(
    <Stack gap={'20px'}>
      {
        canCreate &&
          <ReserveAssetFormAndTable />
      }
      <AssetsReservedTable />
    </Stack>
  )
}

export default AssetsReservation;