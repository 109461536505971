import MoveForm from "./components/MoveForm";
import MoveTable from "./components/MoveTable";


interface Composition {
    Form: typeof MoveForm;
    Table: typeof MoveTable;
}

const MoveManager : Composition = {
    Form: MoveForm,
    Table: MoveTable,
}

export default MoveManager