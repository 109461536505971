import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateMoveTransactionsMutationVariables = Types.Exact<{
  input: Types.CreateMoveTransactionsInput;
}>;


export type CreateMoveTransactionsMutation = { __typename?: 'Mutation', createMoveTransactions: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', id: string, tenantId: string, destinationSiteId?: string | null, destinationLocationId?: string | null, createdAt: string, updatedAt: string, quantity: number, parentTransactionId: string, status: Types.TransactionStatusEnum, type: Types.TransactionTypeEnum }> | null } };


export const CreateMoveTransactionsDocument = gql`
    mutation createMoveTransactions($input: CreateMoveTransactionsInput!) {
  createMoveTransactions(input: $input) {
    success
    transactions {
      id
      tenantId
      destinationSiteId
      destinationLocationId
      createdAt
      updatedAt
      quantity
      parentTransactionId
      status
      type
    }
  }
}
    `;

export function useCreateMoveTransactionsMutation() {
  return Urql.useMutation<CreateMoveTransactionsMutation, CreateMoveTransactionsMutationVariables>(CreateMoveTransactionsDocument);
};