import reduce from 'lodash/reduce';
import { EntityPermissionsMap } from 'app/types/schema';

type DependencyType = {
  dependentOn: string[];
  dependencyOf: string[];
};

const createDependencyObject = () => ({ dependentOn: [], dependencyOf: [] } as DependencyType);

function getPermissionDependencies(entityPermissions: EntityPermissionsMap[]) {
  return reduce(
    entityPermissions,
    (permissionDependencyMap, permissionGroup) => {
      permissionGroup.entityPermissions.forEach((permission) => {
        const permissionKey = permission.key;

        if (!permissionDependencyMap[permissionKey]) {
          permissionDependencyMap[permissionKey] = createDependencyObject();
        }

        const dependentOn = permissionDependencyMap[permissionKey].dependentOn;

        permission.dependentOn.forEach((dependency) => {
          const dependencyKey = dependency;

          if (!dependentOn.includes(dependencyKey)) {
            dependentOn.push(dependencyKey);
          }

          if (!permissionDependencyMap[dependencyKey]) {
            permissionDependencyMap[dependencyKey] = createDependencyObject();
          }

          if (!permissionDependencyMap[dependencyKey].dependencyOf.includes(permissionKey)) {
            permissionDependencyMap[dependencyKey].dependencyOf.push(permissionKey);
          }
        });
      });
      return permissionDependencyMap;
    },
    {} as Record<string, DependencyType>,
  );
}

export default getPermissionDependencies;
