import React, { ReactElement, ReactNode, forwardRef } from 'react';

export type BoxProps = React.ComponentPropsWithRef<'div'> & {
  testId?: string;
  layoutWrapperClass?: boolean;
  className?: string;
  filterNodes?: ReactNode | ReactElement;
  paginationWrapperClass?: string;
  titleWrapperClass?: string;
  hasTopDivider?: boolean;
};

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { testId, ...rest } = props;
  return <div ref={ref} data-testid={testId} {...rest} />;
});

Box.displayName = 'Box';

export default Box;
