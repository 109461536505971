import LandingPage from './components';

const LandingPageWishList = () => {
  return (
    <div
      className="ml-auto mr-auto flex items-center justify-center p-12"
      style={{ maxWidth: '576px', width: '100%' }}>
      <LandingPage />
    </div>
  );
};

export default LandingPageWishList;
