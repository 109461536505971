import { useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { Button, Content } from '@procurenetworks/procure-component-library';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';

import Inventory from '../../../../i18n/Inventory';
import { ItemTypeEnum } from '../../../../types/schema';
import useCurrentUser from '../../../auth/hooks/useCurrentUser';
import EntityManager from '../../../components/EntityManager';
import { getAssetQuickLinks, getInventoryQuickLinks } from './utils';

type Props = {
  type: ItemTypeEnum;
  itemInStockId?: string;
  disabled?: boolean;
} & RouteComponentProps;

const QuickLinks = (props: Props) => {
  const { history, type, itemInStockId, disabled } = props;

  const { workspacePermissions } = useCurrentUser();

  const onRedirect = useCallback(
    (route: string) => {
      history.push(route);
    },
    [history],
  );

  const quickLinkItems = useMemo(() => {
    if (type === ItemTypeEnum.Asset) {
      return getAssetQuickLinks(workspacePermissions, itemInStockId);
    }

    if (type === ItemTypeEnum.Inventory) {
      return getInventoryQuickLinks(workspacePermissions, itemInStockId);
    }

    return [];
  }, [workspacePermissions]);

  if (quickLinkItems.length === 0) {
    return null;
  }

  return (
    <>
      <EntityManager.Title title={Inventory.QuickLinks} />
      <Box className="mt-[24px] mb-[8px]">
        <Grid container spacing={2}>
          {quickLinkItems.map((quickLinkItem, index) => {
            return (
              <Grid key={`quickLinks-${type}-${index}`} item>
                <Button
                  disabled={disabled}
                  theme="orange"
                  onClick={() => {
                    onRedirect(quickLinkItem.url);
                  }}
                  classes="min-w-[98px] h-[44px]">
                  {quickLinkItem.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default withRouter(QuickLinks);
