import { useMemo } from 'react';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { OrderDetailsData } from '../../views/OrderDetail/type';
import {
  OrderRequestItemSchema,
  OrderRequestItemStatusEnum,
  OrderRequestTypeEnum,
} from '../../../../types/schema';

interface Props {
  orderDetail: OrderDetailsData;
}

export function isOrderItemReturnable(orderItem: OrderRequestItemSchema) {
  return orderItem.status === OrderRequestItemStatusEnum.Delivered;
}

export const useOrderReturnPermissionCheck = (props: Props) => {
  const { orderDetail } = props;
  const { orderRequestItems } = orderDetail;
  const { id, emailId } = useCurrentUser();

  const isOrderReturnable = useMemo(() => {
    if (orderDetail.type !== OrderRequestTypeEnum.Internal) {
      return false;
    }

    const returnableItems = orderRequestItems.find(isOrderItemReturnable);
    return Boolean(returnableItems);
  }, [orderRequestItems, orderDetail.type]);

  const isReturnButtonDisabled = useMemo(() => {
    return id !== orderDetail.deliverToId && id !== orderDetail.createdById;
  }, [id, emailId, orderDetail.createdBy?.emailId, orderDetail.deliverToId]);

  return { isOrderReturnable, isReturnButtonDisabled };
};

export default useOrderReturnPermissionCheck;
