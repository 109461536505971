import { useCallback, useMemo, useRef } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import { removeExtraSpacesAndNewlines, removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';

import { SnackbarService } from '../../../../components/Snackbar';
import Common from '../../../../i18n/Common';
import Box from '../../../../ui-components/Box';
import FindUPC from '../../../components/FindUPC';
import AssetModelAttachmentField from './components/AssetModelAttachmentField';
import AssetModelBrandField from './components/AssetModelBrandField';
import AssetModelCategoryField from './components/AssetModelCategoryField';
import AssetModelDescriptionField from './components/AssetModelDescriptionField';
import AssetModelDisplayInOrderField from './components/AssetModelDisplayInOrderField';
import AssetModelFields from './components/AssetModelFields';
import AssetModelManufacturerField from './components/AssetModelManufacturerField';
import AssetModelSkuField from './components/AssetModelSkuField';
import AssetModelTitleField from './components/AssetModelTitleField';
import AssetModelUnitCostOverrideField from './components/AssetModelUnitCostOverrideField';
import AssetModelUpcCodeField from './components/AssetModelUpcCodeField';
import AssetModelVendorField from './components/AssetModelVendorField';
import useAssetModelForm from './hook/useAssetModelForm';
import { AssetModelItemFormInput } from './type';
import ScrollToError from 'app/utils/ScrollToError';

interface Props {
  defaultValues: AssetModelItemFormInput;
  disabled?: boolean;
  fetching?: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => any;
}

const AssetModelForm = (props: Props) => {
  const { disabled, onCancel, defaultValues, onSubmit, fetching } = props;

  const { formState, showFindUpcSection, onSaveProductData } = useAssetModelForm({
    defaultValues,
  });
  const { attachmentInputProps, handleSubmit } = formState;
  const formRef = useRef<HTMLDivElement>(null);
  const onError = useCallback((errors: any) => { ScrollToError(formState.control._formState.errors, formRef); }, []);

  const onFormSubmit = useMemo(
    () =>
      handleSubmit((values) => {
        const updatedAssetModelValues = {
          ...values,
          brand: removeMultipleSpaces(values.brand),
          description: removeExtraSpacesAndNewlines(values.description),
          formattedDescription: values.description,
          mName: removeMultipleSpaces(values.mName),
          modelNumber: removeMultipleSpaces(values.modelNumber),
          title: removeMultipleSpaces(values.title),
        };
        onSubmit(updatedAssetModelValues);
      }, onError),
    [handleSubmit, onSubmit, onError],
  );

  return (
    <div ref={formRef} className="mt-20">
      <Box className="flex flex-wrap gap-[24px] lg:w-full xl:w-full">
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <AssetModelSkuField disabled={disabled} formState={formState} />
          <AssetModelUpcCodeField disabled={disabled} formState={formState} className="mt-[6px]" />
          <AssetModelTitleField disabled={disabled} formState={formState} />
          <AssetModelFields disabled={disabled} formState={formState} />
          <AssetModelCategoryField disabled={disabled} formState={formState} />
          <AssetModelDescriptionField disabled={disabled} formState={formState} />
          <AssetModelVendorField disabled={disabled} formState={formState} />
          <AssetModelBrandField disabled={disabled} formState={formState} />
          <AssetModelManufacturerField disabled={disabled} formState={formState} />
          <AssetModelUnitCostOverrideField disabled={disabled} formState={formState} />
          <AssetModelDisplayInOrderField disabled={disabled} formState={formState} />
          <AssetModelAttachmentField disabled={disabled} formState={formState} />
          <Box className="mb-20 flex justify-end space-x-[16px]">
            <Button
              disabled={disabled || attachmentInputProps.isUploading}
              loading={fetching}
              theme="success"
              onClick={onFormSubmit}
              classes="min-w-[94px] h-[44px]">
              {Common.Actions.Save}
            </Button>
            <Button onClick={onCancel} classes="min-w-[94px] h-[44px]">
              {' '}
              {Common.Actions.Cancel}
            </Button>
          </Box>
        </Box>
        {showFindUpcSection ? (
          <Box className="flex-1">
            <FindUPC onSaveProductData={onSaveProductData} />
          </Box>
        ) : undefined}
      </Box>
    </div>
  );
};

export default AssetModelForm;
