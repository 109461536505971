import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Grid, IconButton, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { PaginationState } from '../../views/createList/types';
import { CustomIcons } from '@procurenetworks/procure-component-library';

export interface PaginationProps {
  id?: string;
  itemsPerPage: number;
  classes?: string;
  page: number;
  itemsPerPageText?: string;
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  onPageChange?: (newPage: number) => void;
  nextPageUrl?: string;
  rowsPerPageOptions?: number[];
  paginationState: PaginationState;
  isAmazon?: boolean;
}

const Pagination = (props: PaginationProps) => {
  const {
    page,
    id,
    paginationState,
    itemsPerPageText,
    onItemsPerPageChange,
    onPageChange,
    nextPageUrl,
    rowsPerPageOptions = [8],
    isAmazon,
  } = props;

  const currentPage = !Number.isNaN(page) ? Number(page) : 1;

  const hasBack = currentPage > 1;
  const hasNext = nextPageUrl;

  const onChangePage = (pageNumber: number) => {
    if (onPageChange) {
      onPageChange(pageNumber);
    }
  };

  const onItemsPerPageChangeEvent = (event: SelectChangeEvent<number>) => {
    if (onItemsPerPageChange) {
      onItemsPerPageChange(event.target.value ? Number(event.target.value) : 12);
    }
  };

  const onNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const onBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  return (
    <div id={id}>
      <Grid container alignItems="center" justifyContent="right">
        <Grid item md="auto" sm="auto" xs={12}>
          <div style={{ fontSize: '14px' }}>
            <span className="text-[14px] font-medium text-grey-800">{itemsPerPageText}: 8</span>
            {/* <Select
              id={`pagination-${id}-select`}
              size="small"
              value={isAmazon ? 8 : paginationState.pageSize}
              onChange={onItemsPerPageChangeEvent}>
              {rowsPerPageOptions.map((itemsPerPage: any) => (
                <MenuItem key={`pagination-${id}-${itemsPerPage}`} value={itemsPerPage}>
                  {itemsPerPage}
                </MenuItem>
              ))}
            </Select> */}
          </div>
        </Grid>
        <Grid item md="auto" sm="auto" xs={12}>
          <Box className="flex items-center justify-center gap-[8px] ml-[8px]">
            <Box className="flex h-[36px] w-[36px] items-center justify-center rounded-[8px] border-[1px] border-grey-500">
              <IconButton aria-label="back" disabled={!hasBack} onClick={onBackButtonClick}>
              <CustomIcons.BackArrowIcon/>
              </IconButton>
            </Box>
            <Box className="flex h-[36px] w-[36px] items-center justify-center rounded-[8px] border-[1px] border-grey-500">
              <IconButton
                aria-label="next"
                disabled={!hasNext}
                onClick={onNextButtonClick}
                className="h-[36px] w-[36px] rounded-[8px] border-[1px] border-grey-300">
                <CustomIcons.ForwardArrowIcon/>
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Pagination;
