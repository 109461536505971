import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Assets from 'app/i18n/Assets';
import Inventory from 'app/i18n/Inventory';

export const KIT_SUMMARY_FORM_RULES = {
  destinationLocationId: {
    validate: textValidator(Inventory.FormValidationMessages.SiteLocationRequired),
  },

  quantity: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberValidator(Assets.FormValidationMessages.QuantityInvalid),
    ),
  },
  quantityForSerialNumber: {
    validate: composeValidators(
      textValidator(Assets.FormValidationMessages.QuantityRequired),
      numberValidator(Assets.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Assets.FormValidationMessages.QuantityInvalid, 1),
      numberMaxValidator(Assets.FormValidationMessages.SerialNumberQuantityInvalid, 1),
    ),
  },
};

export const getAttachmentList = (list: any[]) => {
  return (
    list?.map((attachment) => {
      return {
        id: attachment?.id || '',
        url: attachment?.url || '',
        name: attachment?.name || '',
        isLoading: attachment?.url === '',
      };
    }) || []
  );
};
