import { cx } from '@emotion/css';
import { tableLoadingStyles } from '../EntityManagerTable/styles';
import Box from 'app/ui-components';
import { EntityManagerCollapsibleTableProps, SortOrder } from '../EntityManagerTable/types';
import { useCallback } from 'react';
import { Table, TableHeaderCell } from '@procurenetworks/procure-component-library';

const rowsPerPageOptions = [10, 25, 50, 100];

function EntityManagerCollapsibleTable(props: EntityManagerCollapsibleTableProps) {
  const {
    state,
    columns,
    data,
    minWidth = 1250,
    loading,
    total,
    id,
    collapsible,
    pagination,
    fetchMore,
    setState,
    defaultSortState,
    extraProps,
    collapsibleNode,
    colSpan,
    rowHeight,
    noBorders,
  } = props;

  const onChangePage = useCallback(
    (page: number) => {
      const fetchedRows = data?.length || 0;
      const fetchedPages = Math.ceil(fetchedRows / state.numberOfRowsPerPage);

      if (page > fetchedPages) {
        fetchMore?.();
      }

      setState({ selectedRowIds: [], activePage: page });
    },
    [data?.length, fetchMore, setState, state.numberOfRowsPerPage],
  );

  const onChangeRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      setState({ selectedRowIds: [], activePage: 1, numberOfRowsPerPage: newRowsPerPage });
    },
    [setState],
  );

  const onRequestSort = useCallback(
    (headCell: TableHeaderCell | null | undefined, nextState: any) => {
      const sortStateId = headCell ? headCell.id : defaultSortState ? defaultSortState?.id : 'id';
      setState({
        sortState: {
          id: sortStateId || '',
          orderBy: nextState ? nextState : defaultSortState?.orderBy || ('asc' as any),
        },
        // sorts: sortStateId ? [
        //     {
        //         sortField: sortStateId,
        //         sortOrder: nextState === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
        //     },
        // ]: [],
      });
    },
    [setState],
  );

  const onRowSelectionChange = useCallback(
    (data: any) => {
      const { row } = data;
      const indexOfRowId = state.selectedRowIds.indexOf(row._id);

      if (indexOfRowId === -1) {
        // Note: for Multi row Selection
        // setSelectedRows([...selectedRows, row._id])

        // Note: for single Row Selection
        setState({
          selectedRowIds: [row._id as string],
        });
      } else {
        setState({
          selectedRowIds: state.selectedRowIds.filter((id) => id !== row._id),
        });
      }
    },
    [setState, state.selectedRowIds],
  );

  return (
    <Box className={cx('mt-24', loading && tableLoadingStyles)}>
      <Table
        selection={state.selection}
        headCellSortState={state.sortState}
        headers={columns}
        minWidth={minWidth || 1080}
        id={id || 'entity-table'}
        loading={!!loading}
        collapsible={!!collapsible}
        pagination={!!pagination}
        rows={data || []}
        noRowsText="No matching records found"
        page={state.activePage}
        rowsPerPage={state.numberOfRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        selectedItems={[state.selectedRowIds]}
        total={total || 0}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
        extraProps={extraProps || {}}
        onRowSelectChange={onRowSelectionChange}
        collapsibleNode={collapsibleNode}
        colSpan={colSpan}
        rowHeight={rowHeight}
        noBorders={noBorders}
        noBoxShadow
      />
    </Box>
  );
}

export default EntityManagerCollapsibleTable;
