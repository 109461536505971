import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ReportCategoriesSelect from 'app/modules/reports/components/ReportCategoriesSelect';
import ReportFormActions from 'app/modules/reports/components/ReportFormActions';
import ReportFormatFormField from 'app/modules/reports/components/ReportFormatFormField';
import ReportSitesSelect from 'app/modules/reports/components/ReportSitesSelect';
import ReportSKUSelect from 'app/modules/reports/components/ReportSKUSelect';
import { SiteReportFormValues, SiteReportOption } from 'app/modules/reports/views/SiteReport/types';
import { LocationTypeEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import {
  getAssetTransactionEntityTypes,
  getItemLocationItemTypesFromTransactionTypes,
} from '../../../../utils/itemtypes';
import { SiteReportFormProps } from './types';
import ReorderReportOptions from './../ReorderReportOptions';
import ReportTypeSelector from 'app/modules/reports/components/ReportTypeSelector';

const defaultValues: SiteReportFormValues = {
  types: [TransactionEntityTypeEnum.Asset],
  skuIds: [],
  siteIds: [],
  categoryIds: [],
  format: 'preview',
  siteReportOption: SiteReportOption.nonZeroTotalQuantity,
  itemTypes: getAssetTransactionEntityTypes(),
};

const ReorderReportForm = (props: SiteReportFormProps) => {
  const { disabled, onSubmit } = props;

  // Read query params and set form values.
  const params = new URLSearchParams(window.location.search);
  let formInput: SiteReportFormValues = JSON.parse(JSON.stringify(defaultValues));
  let autoGenerate = false;
  params.forEach((value, key) => {
    if (key === 'low_stock_report') {
      formInput.types = [value.toUpperCase()] as TransactionEntityTypeEnum[];
      formInput.itemTypes = getItemLocationItemTypesFromTransactionTypes(formInput.types);
      formInput.siteReportOption = SiteReportOption.onlyMinimumQuantityThresholdBreached;
    } else if (key === 'categories' && value.trim() !== '') {
      formInput.categoryIds = value.split(',');
    } else if (key === 'sites' && value.trim() !== '') {
      formInput.siteIds = value.split(',');
    } else if (key === 'autoGenerate') {
      if (value === 'true') autoGenerate = true;
    }
  });

  const { control, handleSubmit, reset, watch, setValue } = useForm({ defaultValues: formInput });
  const types = watch('types');
  const itemTypes = watch('itemTypes');

  useEffect(() => {
    setValue('itemTypes', getItemLocationItemTypesFromTransactionTypes(types));
  }, [types]);

  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);

  const onClear = useCallback(() => {
    reset(defaultValues);
    onSubmit(null);
  }, [onSubmit, reset]);

  const onTypeChange = useCallback(
    (types: TransactionEntityTypeEnum[]) => {
      setValue('types', types); // Update types state
      reset({ ...defaultValues, types: types });
    },
    [reset, setValue],
  );

  // Do form submit if autoGenerate is true from query parameters.
  if (autoGenerate) {
    setTimeout(() => {
      onFormSubmit();
    }, 500);
  }

  return (
    <form
      className="w-full py-[16px] px-[24px] md:min-w-[532px] lg:max-w-[532px] xl:max-w-[532px]"
      onSubmit={onFormSubmit}>
      <Box className="space-y-20">
        <ReportTypeSelector control={control} onChange={onTypeChange} />
        <ReportSKUSelect control={control} types={itemTypes} />
        <ReportCategoriesSelect control={control} />
        <ReportSitesSelect
          control={control}
          types={[LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant]}
        />
        <ReorderReportOptions control={control} />
        <ReportFormatFormField control={control} />
        <ReportFormActions disabled={disabled} onClear={onClear} onSubmit={onFormSubmit} />
      </Box>
    </form>
  );
};

export default ReorderReportForm;