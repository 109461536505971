import { useCallback } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Site from 'app/i18n/Site';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateLocationInput, LocationTypeEnum } from 'app/types/schema';
import Box from 'app/ui-components/Box';

import SiteForm from '../../components/SiteForm';
import { useCreateSiteMutation } from './graphql/mutations/generated/createSite';
import analytics from 'app/analytics';

const DEFAULT_VALUES: CreateLocationInput = {
  name: '',
  description: '',
  locationCode: '',
  type: LocationTypeEnum.Site,
};

const AddSite = (props: RouteComponentProps) => {
  const { history } = props;
  const [{ fetching }, onCreateSite] = useCreateSiteMutation();

  const onSubmit = useCallback(
    (input: CreateLocationInput) => {
      onCreateSite({ input: { ...input, type: LocationTypeEnum.Site } }).then((response) => {
        analytics?.track('Created', { name: 'Site' });
        if (response.data?.createLocation.location?.id) {
          SnackbarService.show({ message: 'This site has been successfully created.' });
          history.replace(routes.Sites());
        }
      });
    },
    [history, onCreateSite],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Sites());
  }, [history]);

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('space-y-40', titlebarStyles)}>
        <TitleBar title={Site.NewSite} />
        <Box className="!mt-[24px] w-full sm:max-w-[382px]  md:min-w-[382px] lg:max-w-[382px] xl:max-w-[382px]">
          <SiteForm
            defaultValues={DEFAULT_VALUES}
            disabled={fetching}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(AddSite);
