import jwtDecode from 'jwt-decode';
import { SnackbarService } from 'app/components/Snackbar';
import Wishlist from 'app/i18n/Wishlist';

import { TokenData, TokenDataValue } from '../type';

export const getTokenData = (): TokenDataValue | null => {
  try {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      const decodedToken = jwtDecode(token) as TokenData;
      return {tokenData: decodedToken, token};
    }
    return null;
  } catch (error) {
    SnackbarService.show({
      type: 'error',
      message: Wishlist.ErrorMessages.InvalidInvitationToken,
    });
    return null;
  }
};
