import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';

import Modal from '../../../../components/Modal';

interface Props {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  totalSelectedRows?: number;
  isLoading?: boolean;
}

function DeleteConfirmationModal(props: Props) {
  const { open, onCancel, onConfirm, totalSelectedRows, isLoading } = props;

  if (!open) {
    return null;
  }

  return (
    <Modal
      disableBackdropClick
      actions={
        <div className="flex gap-[16px]">
          <div>
            <Button theme="danger" onClick={onCancel} disabled={isLoading} classes=" min-w-[80px] !px-[10px] h-[44px]">
              {Common.Actions.No}
            </Button>
          </div>
          <div className="ml-1">
            <Button theme="success" onClick={onConfirm} disabled={isLoading} classes=" min-w-[80px] !px-[10px] h-[44px]">
              {Common.Actions.Yes}
            </Button>
          </div>
        </div>
      }
      id="delete-data-table-dialog"
      open={open}
      title="Delete!"
      onClose={onCancel}>
      <div className={`mt-[16px] w-[345px] font-medium text-grey-800`}>
        {totalSelectedRows
          ? Common.MultipleItemDeleteMessage(totalSelectedRows)
          : Common.DeleteMessage}
      </div>
    </Modal>
  );
}

export default DeleteConfirmationModal;
