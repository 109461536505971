import { useCallback, useState } from 'react';
import camelCase from 'lodash/camelCase';
import { ExportReportEntityTypeEnum, ExportReportNameEnum } from 'app/consts/enum';
import Reports from 'app/i18n/Reports';
import ReportContainer from 'app/modules/reports/components/ReportContainer';
import { SiteReportFormValues } from './types';
import { SortOrderEnum, TransactionEntityTypeEnum } from 'app/types/schema';
import { getCurrentTimeZoneValue } from 'app/utils/timezone';
import useReportCSVExport from '../../hooks/useReportCSVExport';
import { getItemLocationItemTypesFromTransactionTypes } from '../../utils/itemtypes';
import SiteReportTable from './components/ReorderReportTable';
import { getFiltersBySiteOption, getSortsBySiteOption } from './utils/filter';
import ReorderReportForm from './components/ReorderReportForm';

const ReorderReport = () => {
  const [values, setValues] = useState<SiteReportFormValues | null>(null);
  const { executeExport } = useReportCSVExport();

  const renderPreviewTable = () => {
    if (values) {
      return <SiteReportTable values={values} />;
    }

    return null;
  };

  const onSubmit = useCallback(
    (nextValues: SiteReportFormValues | null) => {
      const transactionEntityTypes = [
        TransactionEntityTypeEnum.Asset,
        TransactionEntityTypeEnum.Inventory,
      ];
      if (nextValues?.format === 'csv') {
        const filters = {
          itemLocationFilters: {
            itemIds: nextValues.skuIds,
            siteIds: nextValues.siteIds,
            categoryIds: nextValues.categoryIds,
            itemTypes: getItemLocationItemTypesFromTransactionTypes(nextValues.itemTypes).map(
              camelCase,
            ),
            _or: getFiltersBySiteOption(nextValues.siteReportOption, true),
          },
        };
        const sorts = getSortsBySiteOption(nextValues.siteReportOption);

        if (transactionEntityTypes.every((value) => nextValues.types.includes(value))) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.ASSET_SITE,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
            reportName: ExportReportNameEnum.LowStockReport
          });
        } else if (nextValues.types.some((value) => value === TransactionEntityTypeEnum.Asset)) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.ASSET_SITE,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
            reportName: ExportReportNameEnum.LowStockReport
          });
        } else if (
          nextValues.types.some((value) => value === TransactionEntityTypeEnum.Inventory)
        ) {
          executeExport({
            entityType: ExportReportEntityTypeEnum.INVENTORY_SITE,
            filters,
            sorts,
            userTimezone: getCurrentTimeZoneValue(),
            reportName: ExportReportNameEnum.LowStockReport
          });
        }
      }

      setValues(nextValues);
    },
    [executeExport],
  );

  return (
    <ReportContainer title={Reports.LowStockReport} stackWrapperClass='rounded-tr-[12px] rounded-br-[12px]'>
     <ReorderReportForm onSubmit={onSubmit} />
      {values?.format === 'preview' ? renderPreviewTable() : null} 
    </ReportContainer>
  );
};

export default ReorderReport;