import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { Maybe } from 'graphql/jsutils/Maybe';
import moment from 'moment';
import routes from 'app/consts/routes';
import Assets from 'app/i18n/Assets';
import Common from 'app/i18n/Common';
import { Link } from 'app/libs/navigation';
import ReadMore from 'app/modules/components/ReadMore';
import DepartmentsTableFilter from 'app/modules/departments/components/DepartmentsTableFilter';
import { getTransactionStatusLabel } from 'app/modules/inventory/components/TransactionHistory/utils/transactionStatus';
import SitesTableFilter from 'app/modules/sites/components/SitesTableFilter';
import UsersTableFilter from 'app/modules/users/components/UsersTableFilter';
import { TransactionStatusEnum } from 'app/types/schema';
import { dropdownHeaderStyle } from 'app/modules/components/EntityManager/EntityManagerTable/styles';

interface ExtendedDataGridHeadCell extends DataGridHeadCell {
  minWidth?: number;
}

export const getReserveAssetsTableColumn = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
  },
  {
    id: 'destinationName',
    label: 'To Site',
    value: 'destinationName',
  },
  {
    id: 'departmentId',
    label: 'To Department',
    value: 'departmentName',
  },
  {
    id: 'actorId',
    label: 'To User',
    value: 'user',
  },
  {
    id: 'startDate',
    label: 'Reserve Start',
    value: 'startDate',
  },
  {
    id: 'endDate',
    label: 'Reserve End',
    value: 'endDate',
  },

  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'id',
    label: 'Action',
    value: 'id',
    valueNode: ({ row, state }) => {
      return (
        <Button
          theme="danger"
          onClick={() => {
            state?.extraProps?.onRemoveRow(row.id);
          }}>
          Remove
        </Button>
      );
    },
  },
];

export const getAssetsReservedTableColumns = ({
  shouldShowActionColumn,
}: {
  shouldShowActionColumn: boolean;
}): ExtendedDataGridHeadCell[] => {
  const columns: ExtendedDataGridHeadCell[] = [
    {
      id: 'id',
      label: 'SKU',
      value: 'id',
      valueNode: ({ row }) => {
        return (
          <Link className="sku-link truncate" to={routes.EditAssets(row.entity?.id)}>
            {row.entity?.sku}
          </Link>
        );
      },
      width: 120,
    },
    {
      id: 'title',
      label: 'Title',
      value: 'title',
      valueNode: ({ row }) => {
        return <ReadMore hideShowMoreBtn text={row.entity?.title || ''} />;
      },
      width: 180,
    },
    {
      id: 'entity.mName',
      label: 'Model',
      value: 'entity.mName',
      valueNode: ({ row }) => (
        <span className="truncate" title={row?.entity?.mName || ''}>
          {row?.entity?.mName || ''}
        </span>
      ),
    },
    {
      id: 'entity.modelNumber',
      label: 'Model Number',
      value: 'entity.modelNumber',
      valueNode: ({ row }) => (
        <span className="truncate" title={row?.entity?.modelNumber || ''}>
          {row?.entity?.modelNumber || ''}
        </span>
      ),
    },
    {
      id: 'serialNumber',
      label: 'Serial Number',
      value: 'serialNumber',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.entity?.serialNumber || ''}>
            {row.entity?.serialNumber || ''}
          </span>
        );
      },
      width: 150,
    },
    {
      id: 'destinationSite',
      label: 'Reserve To Site',
      value: 'destinationSite',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.destinationSite?.name || ''}>
            {row.destinationSite?.name || ''}
          </span>
        );
      },
      minWidth: 220,
      classes: dropdownHeaderStyle,
      labelNode: (props) => {
        const { extraProps } = props.state;
        const { filtersState, setFilterState } = extraProps || {};
        return (
          <div className='table-filter'>
            <SitesTableFilter
              isMultiple={false}
              name="destinationSiteName"
              placeholder={Assets.FormPlaceholders.ReserveToSite}
              value={filtersState?.destinationSiteIds}
              onChange={(value: string) => {
                setFilterState?.({
                  destinationSiteId: value || '-1',
                });
              }}
            />
            </div>
        );
      },
    },
    {
      id: 'department',
      label: 'Reserve To Department',
      value: 'department',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.department?.name || ''}>
            {row.department?.name || ''}
          </span>
        );
      },
      width: 220,
      labelNode: (props) => {
        const { extraProps } = props.state;
        const { filtersState, setFilterState } = extraProps || {};
        return (
          <div>
            <DepartmentsTableFilter
              isMultiple={false}
              name="departments"
              placeholder={Assets.FormPlaceholders.ReserveToDepartments}
              value={filtersState?.departmentIds}
              onChange={(value: string) => {
                setFilterState?.({
                  departmentId: value || '-1',
                });
              }}
            />
          </div>
        );
      },
    },
    {
      id: 'user',
      label: 'Reserve To User',
      value: 'user',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.actor?.name || ''}>
            {row.actor?.name || ''}
          </span>
        );
      },
      labelNode: (props) => {
        const { extraProps } = props.state;
        const { filtersState, setFilterState } = extraProps || {};
        return (
          <div>
            <UsersTableFilter
              isMultiple={false}
              name="actorId"
              placeholder={Assets.FormPlaceholders.ReserveToUser}
              value={filtersState?.requestorIds}
              onChange={(value: Maybe<string>) => {
                setFilterState?.({
                  actorId: value || '-1',
                });
              }}
            />
          </div>
        );
      },
      width: 220,
    },
    {
      id: 'startDate',
      label: 'Reserve Start',
      value: 'startDate',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.startDate ? moment(row.startDate).format('L') : ''}>
            {row.startDate ? moment(row.startDate).format('L') : ''}
          </span>
        );
      },
      width: 200,
    },
    {
      id: 'endDate',
      label: 'Reserve End',
      value: 'endDate',
      valueNode: ({ row }) => {
        return (
          <span className="truncate" title={row.endDate ? moment(row.endDate).format('L') : ''}>
            {row.endDate ? moment(row.endDate).format('L') : ''}
          </span>
        );
      },
      width: 200,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      value: 'quantity',
      valueNode: ({ row }) => {
        const quantityToShow = parseInt(row.quantity) > 0 ? row.quantity : row.originalQuantity;
        return (
          <span className="w-[100%] truncate text-right" title={quantityToShow || ''}>
            {quantityToShow || ''}
          </span>
        );
      },
      width: 120,
    },
    {
      id: 'status',
      label: 'Status',
      value: 'status',
      valueNode: ({ row }) => {
        return (
          <span className="w-[100%] text-left" title={getTransactionStatusLabel(row?.status)}>
            {getTransactionStatusLabel(row?.status)}
          </span>
        );
      },
      width: 120,
    },
  ];

  if (shouldShowActionColumn) {
    columns.push({
      id: 'action-cell',
      label: 'Action',
      value: 'action-cell',
      minWidth: 350,
      valueNode: ({ row, state }: any) => {
        const { status } = row;
        const { canDelete, canCheckOut, canEdit } = state.extraProps;
        let disableCheckout = false;
        if (row.quantity <= 0) {
          disableCheckout = true;
        }
        return (
          <div className="flex gap-x-10">
            {canDelete && (
              <Button
                disabled={status !== TransactionStatusEnum.Reserved}
                theme="danger"
                onClick={() => {
                  state?.extraProps?.onConfirmTransactionAction(row.id, 'UNRESERVE');
                }}>
                {Assets.Actions.Unreserve}
              </Button>
            )}
            {canEdit && (
              <Button
                disabled={status !== TransactionStatusEnum.Reserved}
                theme="info"
                onClick={() => {
                  state?.extraProps?.onConfirmTransactionAction(row.id, 'EDIT');
                }}>
                {Common.Actions.Edit}
              </Button>
            )}
            {canCheckOut && (
              <Button
                disabled={disableCheckout}
                theme="success"
                onClick={() => {
                  state?.extraProps?.onConfirmTransactionAction(row.id, 'CHECKOUT');
                }}>
                {Assets.Actions.CheckOut}
              </Button>
            )}
          </div>
        );
      },
    });
  }

  return columns;
};
