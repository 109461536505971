import { Button } from '@procurenetworks/procure-component-library';
import AccessControl from 'app/components/AccessControl';
import Common from 'app/i18n/Common';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

interface Props {
  onClick: () => void;
}

const subject = AllowedPermissionsSubjectEnum.Warehouse;

const WarehouseSetupAction = ({ onClick }: Props) => {
  const { workspacePermissions } = useCurrentUser();

  return (
    <AccessControl
      action={AllowedPermissionActionsEnum.Edit}
      permissions={workspacePermissions}
      subject={subject}>
      <Button theme="success" onClick={onClick} classes="min-w-[90px] h-[44px]">
        {Common.Actions.Add}
      </Button>
    </AccessControl>
  );
};

export default WarehouseSetupAction;
