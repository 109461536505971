import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteAssetModelItemsMutationVariables = Types.Exact<{
  input: Types.DeleteItemsInput;
}>;


export type DeleteAssetModelItemsMutation = { __typename?: 'Mutation', deleteAssetModelItems: { __typename?: 'MutationResponse', success: boolean } };


export const DeleteAssetModelItemsDocument = gql`
    mutation deleteAssetModelItems($input: DeleteItemsInput!) {
  deleteAssetModelItems(input: $input) {
    success
  }
}
    `;

export function useDeleteAssetModelItemsMutation() {
  return Urql.useMutation<DeleteAssetModelItemsMutation, DeleteAssetModelItemsMutationVariables>(DeleteAssetModelItemsDocument);
};