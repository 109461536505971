import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ItemTypeEnum, TransactionEntityTypeEnum } from '../../../../../types/schema';
import { FormItemLocationOption } from '../../../../locations/components/FormItemLocationSelect/types';
import { LocationSelectorOption } from '../../../../locations/components/LocationSelector/types';
import { SiteSelectorOption } from '../../../../locations/components/SiteSelector/types';
import useItemInStockData from '../../../hook/useItemInStockData';
import { getItemInStockIdFromParams } from '../../../utils/itemInStockParams';

const DEFAULT_VALUES = {
  itemInStockId: '',
  sourceSiteId: '',
  sourceLocationId: '',
  destinationSiteId: '',
  destinationLocationId: '',
  quantity: '0',
};

interface Props {
  transactionType: TransactionEntityTypeEnum;
}

interface State {
  sourceSite?: FormItemLocationOption | null;
  sourceLocation?: FormItemLocationOption | null;
  destinationSite?: SiteSelectorOption | null;
  destinationLocation?: LocationSelectorOption | null;
}

const initialState = {
  sourceLocation: null,
  destinationSite: null,
  destinationLocation: null,
};

const useMoveForm = (props: Props) => {
  const { transactionType } = props;
  const queryParamsItemInStockId = getItemInStockIdFromParams();

  const [state, _setState] = useState<State>({
    ...initialState,
  });

  const setState = useCallback(
    (nextState: Partial<State>) => {
      _setState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setState],
  );

  const { control, watch, setValue, handleSubmit, reset, register, trigger, resetField } = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      itemInStockId: queryParamsItemInStockId || '',
    },
  });

  const itemInStockId = watch('itemInStockId');
  const sourceSiteId = watch('sourceSiteId');
  const sourceLocationId = watch('sourceLocationId');
  const destinationSiteId = watch('destinationSiteId');
  const destinationLocationId = watch('destinationLocationId');

  const itemInStockTypes = useMemo(() => {
    if (transactionType === TransactionEntityTypeEnum.Asset) {
      return [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit];
    } else if (transactionType === TransactionEntityTypeEnum.Inventory) {
      return [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit];
    }

    return [];
  }, [transactionType]);

  const { itemInStock } = useItemInStockData(itemInStockId || '', itemInStockTypes);

  useEffect(() => {
    setValue('sourceLocationId', '');
    setValue('sourceSiteId', '');
  }, [itemInStockId]);

  useEffect(() => {
    setValue('sourceLocationId', '');
  }, [sourceSiteId]);

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  const quantity = watch('quantity');

  const isSourceAndDestinationSameLocation = useMemo(() => {
    return !!(
      sourceSiteId &&
      destinationSiteId &&
      sourceLocationId &&
      destinationLocationId &&
      sourceSiteId === destinationSiteId &&
      sourceLocationId === destinationLocationId
    );
  }, [sourceSiteId, sourceLocationId, destinationSiteId, destinationLocationId]);

  const availableQuantity = useMemo(() => {
    return state?.sourceLocation?.availableQuantity;
  }, [state?.sourceLocation]);

  const totalQuantity = useMemo(() => {
    return state?.sourceLocation?.totalQuantity;
  }, [state?.sourceLocation]);

  useEffect(() => {
    if (availableQuantity !== undefined) {
      register('quantity');
    }
  }, [availableQuantity]);

  useEffect(() => {
    setValue('destinationLocationId', '');
  }, [destinationSiteId]);

  useEffect(() => {
    if (sourceSiteId && sourceLocationId && destinationSiteId && destinationLocationId) {
      trigger('destinationLocationId');
    }
  }, [
    sourceSiteId,
    sourceLocationId,
    destinationSiteId,
    destinationLocationId,
    isSourceAndDestinationSameLocation,
  ]);

  const onResetForm = useCallback(() => {
    reset({
      ...DEFAULT_VALUES,
    });
    setState({
      ...initialState,
    });
  }, [reset, setState]);

  return {
    formState: {
      control,
      itemInStockId,
      sourceSiteId,
      availableQuantity,
      totalQuantity,
      itemId: itemInStockId,
      quantity,
      destinationSiteId,
      isSourceAndDestinationSameLocation,
      setState,
      setValue,
      resetField,
    },
    itemInStock,
    state,
    handleSubmit,
    onResetForm,
  };
};

export type UseMoveFormReturnType = ReturnType<typeof useMoveForm>;
export default useMoveForm;
