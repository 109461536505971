import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type LabelLocationTableRowFragment = { __typename?: 'LocationSchema', id: string, name: string, site: { __typename?: 'LocationSchema', id: string, name: string } };

export const LabelLocationTableRowFragmentDoc = gql`
    fragment LabelLocationTableRow on LocationSchema {
  id
  name
  site {
    id
    name
  }
}
    `;