import { Content } from '@procurenetworks/procure-component-library';
import Loader from 'app/components/Loader';
import routes from 'app/consts/routes';
import Shop from 'app/i18n/Shop';
import { Redirect, RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import EditAffiliateMerchantView from 'app/modules/shop/components/EditAffiliateMerchantView';

import useEditAffiliateMerchantState from './hook/useEditAffiliateMerchantState';

type Props = RouteComponentProps & { affiliateConfigurationId: string };

const EditAffiliateMerchant = (props: Props) => {
  const { affiliateConfigurationId } = props;

  const { fetching, affiliateMerchantData, affiliateMerchantFormData } =
    useEditAffiliateMerchantState(affiliateConfigurationId);

  if (fetching) {
    return <Loader />;
  }

  if (!affiliateMerchantData) {
    return <Redirect to={routes.AffiliateConfiguration()} />;
  }

  return (
    <EntityManager>
      <EntityManager.Title
        documentTitle={Shop.Admin.EditAffiliateConfiguration}
        title={Shop.Admin.EditAffiliateConfiguration}
      />
      <Content>
        <EditAffiliateMerchantView
          affiliateConfigurationId={affiliateConfigurationId}
          affiliateMerchantFormData={affiliateMerchantFormData}
          disabled={fetching}
        />
      </Content>
    </EntityManager>
  );
};

export default withRouter(EditAffiliateMerchant);
