import { Avatar } from '@mui/material';
import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import routes from 'app/consts/routes';
import { Link } from 'app/libs/navigation';

export const getNetworkAssetsUserTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'profilePicture',
    label: 'Image',
    labelAlign: 'center',
    rowAlign: 'center',
    sortable: false,
    value: 'profilePicture',
    valueNode: ({ row }) => (
      <Avatar src={row.profilePicture} sx={{ width: 45, height: 45, marginX: 'auto' }} />
    ),
    width: 100,
  },
  {
    id: 'fullName',
    label: 'Name',
    value: 'fullName',
    sortable: true,
  },
  {
    id: 'emailId',
    label: 'Email',
    value: 'emailId',
    sortable: true,
  },
  {
    id: 'roles',
    label: 'User Role',
    value: 'roles',
    valueNode: ({ row }) => {
      const roles = row.roles;
      const roleNames = roles?.length ? roles.map((role: any) => role.name).join(', ') : '-';
      return (
        <span className="truncate" title={roleNames}>
          {roleNames}
        </span>
      );
    },
  },
];

export const getNetworkAssetsDetailPanelColumns = (): DataGridHeadCell[] => [
  {
    id: 'mac',
    label: 'MAC Address',
    value: 'mac',
    valueNode: ({ row }) => {
      return (
        <Link
          className={'sku-link truncate'}
          title={row?.mac}
          to={routes.NetworkAssetDetail(row.id)}>
          {row?.mac}
        </Link>
      );
    },
    width: 200,
  },
  {
    id: 'ipAddress',
    label: 'IP Address',
    value: 'ipAddress',
    width: 150,
  },
  {
    id: 'type',
    label: 'Type',
    value: 'type',
    width: 150,
  },
  {
    id: 'system.manufacturer',
    label: 'Manufacturer',
    value: 'system.manufacturer',
    valueNode: (param) => (
      <span className="truncate" title={param.row.system.manufacturer}>
        {param.row.system.manufacturer}
      </span>
    ),
    width: 150,
  },
  {
    id: 'system.model',
    label: 'Model',
    value: 'system.model',
    valueNode: (param) => (
      <span className="truncate" title={param.row.system.manufacturer}>
        {param.row.system.model}
      </span>
    ),
    width: 150,
  },
];
