import { useCallback, useEffect, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { cx } from '@emotion/css';
import { GridSortItem } from '@mui/x-data-grid';
import {
  Button,
  DataGrid,
  DataGridComponentState,
  GridColDef,
  GridSortModel,
  useLocalStorage,
} from '@procurenetworks/procure-component-library';
import { useAccessControl } from 'app/components/AccessControl';
import Common from 'app/i18n/Common';
import { useEntityManagerContext } from 'app/modules/components/EntityManager';
import { AllowedPermissionActionsEnum, SortOrderEnum } from 'app/types/schema';
import Box from 'app/ui-components';
import Stack from 'app/ui-components/Stack';

import { disableVirtualization } from '../../../../consts/config';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import {
  getPageSettingsFromStorage,
  savePageSettingsToStorage,
} from './../../../../utils/paginationSettingsUtil';
import { tableDropDownStyle, tableLoadingStyles } from './styles';
import { EntityManagerTableProps } from './types';
import ReadMore from '../../ReadMore';

function EntityManagerTable(props: EntityManagerTableProps) {
  const {
    actions,
    columns: headers,
    data,
    minWidth = 1250,
    state,
    rowHeight,
    setState,
    total = 0,
    loading,
    pagination,
    fetchMore,
    extraProps,
    defaultSortState,
    ignoreRelayPagination,
    onNextPage,
    onPrevPage,
    onReset,
    persistKey,
    persistSelectionData,
    getRowHeight,
    getDetailPanelContent,
    getDetailPanelHeight,
    getRowId,
    sx,
    testId,
    footerRows,
    filterNodes,
    paginationWrapperClass,
    footerWrapperClass,
    tableBorderRadius,
    tableBorder,
  } = props;

  const { permissions, subject } = useEntityManagerContext();
  const canEdit = useAccessControl(permissions, AllowedPermissionActionsEnum.Edit, subject);
  const canDelete = useAccessControl(permissions, AllowedPermissionActionsEnum.Delete, subject);
  const canEditOrDelete = canEdit || canDelete;
  const [datagridState, setDatagridState] = useLocalStorage(persistKey);

  useEffect(() => {
    const sortModel =
      datagridState?.sorting?.sortModel ||
      (state.sortState?.id
        ? ([
          {
            field: state.sortState.id,
            sort: state.sortState.orderBy,
          },
        ] as GridSortItem[])
        : undefined);

    setState({
      activePage: 0,
      sortState: sortModel?.length
        ? {
          id: sortModel[0].field,
          orderBy: sortModel[0].sort
            ? sortModel[0].sort
            : defaultSortState?.orderBy || ('asc' as any),
        }
        : undefined,
      sorts: sortModel?.length
        ? [
          {
            sortField: sortModel[0].field,
            sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
          },
        ]
        : [],
    });
    onReset?.();
  }, [setState, total]);

  const onRowSelectionChange = useCallback(
    (rowIds: any[]) => {
      setState({ selectedRowId: rowIds.length ? rowIds[0] : null });
    },
    [setState],
  );

  const onTableChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      const fetchedRows = data?.length || 0;
      const fetchedPages = Math.ceil(fetchedRows / state.numberOfRowsPerPage);

      if (page > fetchedPages) {
        fetchMore?.();
      }

      if (page > state.activePage) {
        onNextPage?.();
      } else if (page < state.activePage) {
        onPrevPage?.();
      }

      setState({ activePage: page });
    },
    [
      data?.length,
      fetchMore,
      onNextPage,
      onPrevPage,
      setState,
      state.activePage,
      state.numberOfRowsPerPage,
    ],
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setState({
        activePage: 0,
        sortState: sortModel.length
          ? {
            id: sortModel[0].field,
            orderBy: sortModel[0].sort
              ? sortModel[0].sort
              : defaultSortState?.orderBy || ('asc' as any),
          }
          : undefined,
        sorts: sortModel.length
          ? [
            {
              sortField: sortModel[0].field,
              sortOrder: sortModel[0].sort === 'asc' ? SortOrderEnum.Asc : SortOrderEnum.Desc,
            },
          ]
          : [],
      });
    },
    [setState],
  );

  const onTableChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      savePageSettingsToStorage(event.target.value);
      setState({ activePage: 0, numberOfRowsPerPage: +event.target.value });
    },
    [setState],
  );

  const rows = useMemo(() => {
    if (ignoreRelayPagination) {
      return data || [];
    }

    const initialIndex = state.activePage * state.numberOfRowsPerPage;
    return (data || []).slice(initialIndex, initialIndex + state.numberOfRowsPerPage);
  }, [data, state.activePage, state.numberOfRowsPerPage, ignoreRelayPagination]);

  useUpdateEffect(() => {
    if (!loading && !rows.length && state.activePage > 1 && ignoreRelayPagination) {
      setState({ activePage: 0 });
      onReset?.();
    }
  }, [rows.length]);

  useEffect(() => {
    if (!persistSelectionData) {
      setState({
        selectedRowId: null,
      });
    }
  }, [rows]);

  const dataGridComponentState = {
    extraProps,
    headers: headers,
    page: state.activePage,
    rows,
    rowsPerPage: state.numberOfRowsPerPage,
    total,
  } as DataGridComponentState;

  const columns: GridColDef[] = useMemo(() => {
    const originalColumns = headers.map((headCell: any, index: number) => ({
      align: headCell.rowAlign,
      field: headCell.value,
      headerAlign: headCell.labelAlign,
      headerName: headCell.label,
      headerClassName: headCell.classes,
      editable: headCell.editable,
      type: headCell.type,
      valueOptions: headCell.valueOptions,
      renderCell: headCell.valueNode
        ? (parameters: any) => (
          <headCell.valueNode
            headCell={headCell}
            row={parameters.row}
            state={dataGridComponentState}
          />
        )
        : headCell.addReadMore
          ? (parameters: any) => <ReadMore text={parameters.value} />
          : undefined,
      valueGetter: headCell.valueGetter ? headCell.valueGetter : undefined,
      renderHeader: headCell.labelNode
        ? () => {
          const LabelComponent = headCell.labelNode;
          return <LabelComponent headCell={headCell} state={dataGridComponentState} />;
        }
        : undefined,
      sortable: headCell.sortable,
      hideable: index === 0 ? false : true,
      width: headCell.width ? headCell.width : 200,
      minWidth: 80 + (headCell.sortable ? 20 : 0),
    }));
    let savedOrder = datagridState?.columns?.all;
    if (persistKey) {
      const savedState = localStorage.getItem(persistKey);
      if (savedState && savedState !== "null") {
        const parsedState = JSON.parse(savedState);
        savedOrder = parsedState.columns.all || datagridState?.columns?.all;
      }
    }

    if (!savedOrder || savedOrder.length === 0) {
      return originalColumns;
    }

    const columnMap = Object.fromEntries(originalColumns.map(col => [col.field, col]));

    const persistedColumns = savedOrder
      .filter((field: string) => columnMap[field])
      .map((field: string, index: number) => ({
        ...columnMap[field],
        hideable: index === 0 ? false : columnMap[field].hideable,
      }));

    return persistedColumns.length === originalColumns.length ? persistedColumns : originalColumns;
  }, [headers, dataGridComponentState, datagridState, persistKey]);

  const sortModel = useMemo((): GridSortItem[] => {
    return state.sortState?.id
      ? ([
        {
          field: state.sortState.id,
          sort: state.sortState.orderBy,
        },
      ] as GridSortItem[])
      : [];
  }, [state.sortState]);

  // Read Records Per Page From Session Storage.
  let rowsPerPage = getPageSettingsFromStorage(state.numberOfRowsPerPage);

  return (
    <>
      {!!actions ? (
        <DeleteConfirmationModal
          open={state.showDeleteConfirmation}
          onCancel={actions.onCancelDelete}
          onConfirm={actions.onConfirmDelete}
        />
      ) : undefined}
      <Box className={cx(tableDropDownStyle, loading && tableLoadingStyles)} testId={testId}>
        <DataGrid
          keepNonExistentRowsSelected
          border={tableBorder}
          borderRadius={tableBorderRadius}
          columns={columns}
          count={total}
          currentPage={state.activePage}
          disableVirtualization={disableVirtualization}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowHeight={getRowHeight}
          getRowId={getRowId}
          loading={!!loading}
          multipleSelection={false}
          pagination={!!pagination}
          paginationMode="server"
          paginationWrapperClass={paginationWrapperClass}
          persistKey={persistKey}
          rowHeight={rowHeight || 52}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selectedItems={state.selectedRowId ? [state.selectedRowId] : []}
          selection={subject ? state.selection && canEditOrDelete : state.selection}
          sortModel={sortModel}
          sortingMode="server"
          sx={{
            sx: sx,
            '& .MuiDataGrid-row--detailPanelExpanded.MuiDataGrid-row.MuiDataGrid-row--lastVisible':
            {
              borderBottom: '1px solid #E0E0E0FF',
            },
          }}
          tableFilters={filterNodes}
          onRowSelectionChange={onRowSelectionChange}
          onSortModelChange={onSortModelChange}
          onTablePageChange={onTableChangePage}
          onTableRowsPerPageChange={onTableChangeRowsPerPage}
        />
      </Box>
      {!!actions && canEditOrDelete ? (
        <Stack className={`gap-[16px] py-[12px] ${footerWrapperClass}`} justifyContent="end">
          {canEdit ? (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!state.selectedRowId}
              theme="info"
              onClick={actions.onEdit}>
              {Common.Actions.Edit}
            </Button>
          ) : null}
          {canDelete ? (
            <Button
              classes="min-w-[94px] h-[44px]"
              disabled={!state.selectedRowId}
              theme="danger"
              onClick={actions.onShowDeleteConfirmation}>
              {Common.Actions.Delete}
            </Button>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
}

export default EntityManagerTable;
