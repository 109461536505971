import React, { createContext, useState } from 'react';

import useFindShopProductState, {
  SearchQuery,
  State,
} from '../../view/shop/hook/useFindShopProducts';
import { Product } from '../../view/shop/hook/useHooverShopProducts';

export const ShopContext = createContext({});

interface Props {
  children: React.ReactNode;
}

export type ShopContextType = {
  search: string;
  searchQuery: SearchQuery;
  numberOfRowsPerPage: number;
  activePage: number;
  products: Product[];
  loading: boolean;
  totalCount: number;
  setState: (nextState: Partial<State>) => void;
  getProducts: (variables: any) => Promise<void>;
  checked: string;
  setChecked: React.Dispatch<React.SetStateAction<string>>;
};

const ShopProvider = ({ children }: Props) => {
  const [checked, setChecked] = useState('code');
  const { state, setState, getProducts } = useFindShopProductState();
  const { search, searchQuery, numberOfRowsPerPage, activePage, products, loading, totalCount } =
    state;

  const context = {
    search,
    searchQuery,
    numberOfRowsPerPage,
    activePage,
    products,
    loading,
    totalCount,
    setState,
    getProducts,
    checked,
    setChecked,
  };

  return <ShopContext.Provider value={context}>{children}</ShopContext.Provider>;
};

export default ShopProvider;
