import styled from '@emotion/styled';
import { suggestionData } from 'app/modules/components/EntityManager/EntityManagerSearch';

type Props = {
  loading?: Boolean;
  data: suggestionData[];
  visible: Boolean;
  searchValue: string;
  onSearchHelperItemClick: (values: any) => void;
};
const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const ListItem = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  background-color: transparent;
  &:hover,
  &:focus {
    background-color: var(--blue300);
    color: var(--blue900);
  }
`;

const SearchDropDown = (props: Props) => {
  const { data, visible, searchValue, onSearchHelperItemClick } = props;

  const BoldedText = (text: string) => {
    const textArray = text.split(searchValue);
    return (
      <span>
        {textArray.map((item: string, index: number) => (
          <>
            {item}
            {index !== textArray.length - 1 && <b>{searchValue}</b>}
          </>
        ))}
      </span>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      {visible && (
        <DropdownContent>
          {data.map((value, index) => (
            <ListItem key={index} href="#" onClick={() => onSearchHelperItemClick(value.label)}>
              <div className="text-grey-900">{BoldedText(value.label)}</div>
            </ListItem>
          ))}
        </DropdownContent>
      )}
    </div>
  );
};

SearchDropDown.defaultProps = {
  loading: false,
  visible: false,
  searchValue: '',
  data: [],
};

export default SearchDropDown;
