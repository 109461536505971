import { useMemo } from 'react';
import { cx } from '@emotion/css';
// import { QRCode } from "@progress/kendo-react-barcodes";
import QRCode from 'qrcode.react';
import Box from 'app/ui-components';

import { LabelTypeEnum } from '../../../Labels/components/Forms/types';
import getCodeData from '../../utils/getCodeData';
import ArrowImage from '../ArrowImage';
import { CodeSchema } from '../types';

interface Props {
  code: CodeSchema;
  displayImage: string;
  size?: number;
  height?: string;
  width?: string;
  type?: LabelTypeEnum | undefined;
}

const CodeContainer = (props: Props) => {
  const { code, size, type, displayImage, height, width } = props;
  const codeData = useMemo(() => getCodeData(code, type), [code, type]);

  return (
    <>
      <Box id={codeData?.id}>
        <Box className={cx('flex items-center justify-center')}>
          {displayImage !== 'no' && (
            <ArrowImage displayImage={displayImage} height={height} width={width} />
          )}
          <Box>
            {/* <LabelAssociation /> */}
            <QRCode fgColor="#000" renderAs={'svg'} size={size} value={codeData?.value} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

CodeContainer.defaultProps = {
  size: 30,
};

export default CodeContainer;
