import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';

export const getRemoveTransactionTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: 'Item In Stock',
    value: 'sku',
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
  },
  {
    id: 'sourceSiteName',
    label: 'From Site',
    value: 'sourceSiteName',
  },
  {
    id: 'sourceLocationName',
    label: 'From Location',
    value: 'sourceLocationName',
  },
  {
    id: 'reason',
    label: 'Reason',
    value: 'reason',
  },
  {
    id: 'quantity',
    label: 'Quantity',
    value: 'quantity',
    rowAlign: 'right',
  },
  {
    id: 'id',
    label: 'Action',
    value: 'id',
    valueNode: (props) => {
      const { row, state } = props;
      return (
        <Button
          theme="danger"
          onClick={() => {
            state?.extraProps?.onRemoveTransaction(row.id);
          }}>
          Remove
        </Button>
      );
    },
    width: 120,
  },
];
