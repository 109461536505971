import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LocationsListQueryVariables = Types.Exact<{
  filters: Types.LocationFilters;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type LocationsListQuery = { __typename?: 'Query', locations: { __typename?: 'LocationConnection', totalCount: number, edges: Array<{ __typename?: 'LocationEdge', node: { __typename?: 'LocationSchema', id: string, name: string } }>, pageInfo?: { __typename?: 'PageInfo', nextCursor?: string | null, hasNextPage: boolean } | null } };


export const LocationsListDocument = gql`
    query locationsList($filters: LocationFilters!, $sorts: [SortProps!], $after: String, $limit: Float) {
  locations(filters: $filters, sorts: $sorts, after: $after, limit: $limit) {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      nextCursor
      hasNextPage
    }
    totalCount
  }
}
    `;

export function useLocationsListQuery(options: Omit<Urql.UseQueryArgs<LocationsListQueryVariables>, 'query'>) {
  return Urql.useQuery<LocationsListQuery>({ query: LocationsListDocument, ...options });
};