import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateExternalOrderRequestFromParentTenantMutationVariables = Types.Exact<{
  input: Types.UpdateExternalOrderRequestFromParentTenantInput;
}>;


export type UpdateExternalOrderRequestFromParentTenantMutation = { __typename?: 'Mutation', updateExternalOrderRequestFromParentTenant: { __typename?: 'UpdateOrderRequestPayload', success: boolean } };


export const UpdateExternalOrderRequestFromParentTenantDocument = gql`
    mutation updateExternalOrderRequestFromParentTenant($input: UpdateExternalOrderRequestFromParentTenantInput!) {
  updateExternalOrderRequestFromParentTenant(input: $input) {
    success
  }
}
    `;

export function useUpdateExternalOrderRequestFromParentTenantMutation() {
  return Urql.useMutation<UpdateExternalOrderRequestFromParentTenantMutation, UpdateExternalOrderRequestFromParentTenantMutationVariables>(UpdateExternalOrderRequestFromParentTenantDocument);
};