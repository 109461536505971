import Link from 'app/components/Link';
import { APP_CONFIG } from 'app/consts/config';
import { getLocationName } from 'app/modules/locations/utils/location';
import { DataGridHead } from 'app/types/dataGrid';
import { popupHeight, popupLeft, popupTop, popupWidth } from 'app/utils/dimensions';

export const getLabelsAssetTableColumns = (): DataGridHead[] => [
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
    valueNode: ({ row }) => (
      <>
        <Link
          className="sku-link truncate"
          title={row.item?.sku}
          onClick={() =>
            window.open(
              `${APP_CONFIG.uiUrls.core}/assets/edit/${row.item?.id}`,
              '',
              `toolbar=no,scrollbars=no,resizable=no,height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft}`,
            )
          }>
          {row.item?.sku}
        </Link>
      </>
    ),
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.title}>
        {row.item?.title}
      </span>
    ),
  },
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
    sortable: true,
    sortField: 'expandedSortFields.siteName',
  },
  {
    id: 'location',
    label: 'Location',
    value: 'location',
    valueNode: ({ row }) => (
      <span className="truncate" title={getLocationName(row.site?.id, row?.location)}>
        {getLocationName(row.site?.id, row?.location)}
      </span>
    ),
    sortable: true,
    sortField: 'expandedSortFields.locationName',
  },
];
