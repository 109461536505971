import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReceivableSearchQueryVariables = Types.Exact<{
  input: Types.GetValuesInEntitiesInput;
}>;

export type ReceivableSearchQuery = {
  __typename?: 'Query';
  valuesInEntities: {
    __typename?: 'GetValuesInEntitiesPayload';
    payload: Array<{
      __typename?: 'GetValuesInEntitiesConfig';
      key: string;
      entities: Array<{
        __typename?: 'ValuesInEntitiesSchema';
        id: string;
        name: string;
        type: Types.ValuesInEntitiesSchemaTypeEnum;
      }>;
    }>;
  };
};

export const ReceivableSearchDocument = gql`
  query valuesInEntities($input: GetValuesInEntitiesInput!) {
    valuesInEntities(input: $input) {
      payload {
        key
        entities {
          id
          name
          type
        }
      }
    }
  }
`;

export function useReceivableSearchQuery(
  options: Omit<Urql.UseQueryArgs<ReceivableSearchQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReceivableSearchQuery>({
    query: ReceivableSearchDocument,
    ...options,
  });
}
