import { useEffect, useMemo } from 'react';
import useEntityManager from 'app/modules/components/EntityManager/useEntityManager';

import usePagination from '../../../../../../hooks/usePagination';
import { usePackedShippingContainersTableQuery } from '../../graphql/queries/generated/packedShippingContainersTable';
import useShipmentFilters from '../useShipmentFilters';

const usePackedShippingContainersTable = () => {
  const { search, table } = useEntityManager({ selection: false });
  const { filtersState, setFilterState } = useShipmentFilters();
  // AssetItems Query
  const { fetching, fetchingMore, data, onNextPage, onPrevPage, onReset } = usePagination(
    usePackedShippingContainersTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        destinationSiteIds: filtersState?.destinationSiteIds,
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'packedShippingContainers',
      pageSize: table.state.numberOfRowsPerPage,
    },
  );

  const shipmentTableRows = useMemo(() => {
    return data?.packedShippingContainers.edges?.map(({ node }) => node) || [];
  }, [data?.packedShippingContainers.edges]);

  useEffect(() => {
    setFilterState({ search: search.debouncedSearchText });
  }, [search.debouncedSearchText]);

  return {
    rows: shipmentTableRows,
    fetching: fetching || fetchingMore,
    search,
    table,
    filtersState,
    onNextPage,
    onPrevPage,
    onReset,
    totalCount: data?.packedShippingContainers.totalCount || 0,
    setFilterState,
  };
};
export type UsePackedShippingContainersTableReturnType = ReturnType<
  typeof usePackedShippingContainersTable
>;
export default usePackedShippingContainersTable;
