import Contacts from 'app/modules/contacts/layout/index';
import ContactView from 'app/modules/contacts/views/Contacts';

const ContactPage = () => {
  return (
    <Contacts currentPage="contact">
      <ContactView />
    </Contacts>
  );
};
export default ContactPage;
