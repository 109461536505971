import { Button, DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { SKUItem } from 'app/modules/assetsInventory/Views/Confirmation/EditTransactions/utils/valueNodes';
import { getLocationName } from 'app/modules/locations/utils/location';
import { getCostWithCurrency } from 'app/utils/cost';

export const getMoveInventoryTableColumns = (
  disabled: boolean,
  isEditMode?: boolean,
): DataGridHeadCell[] => {
  const columns = [
    {
      id: 'sku',
      label: 'SKU',
      value: 'sku',
      valueNode: (props: any) => (
        <SKUItem {...{ ...props, row: { ...props.row, target: '_blank' } }} />
      ),
    },
    {
      id: 'title',
      label: 'Title',
      value: 'title',
    },
    {
      id: 'sourceSite',
      label: 'Site',
      value: 'sourceSite',
      valueNode: (props: any) => {
        const { state } = props;

        return (
          <span className="truncate" title={state.extraProps?.site?.name}>
            {state.extraProps?.site?.name}
          </span>
        );
      },
      width: 200,
    },
    {
      id: 'sourceLocation',
      label: 'Location',
      value: 'sourceLocation',
      valueNode: (props: any) => {
        const { row, state } = props;
        const value = getLocationName(state?.extraProps?.site?.siteId, row.sourceLocation);
        return (
          <span className="truncate" title={value}>
            {value}
          </span>
        );
      },
      width: 200,
    },
    {
      id: 'quantity',
      label: 'Quantity',
      value: 'quantity',
      rowAlign: 'right',
      width: 100,
    },
    {
      id: 'cost',
      label: 'Cost',
      value: 'cost',
      valueNode: (props: any) => {
        const { row } = props;
        const value = row?.cost !== undefined ? `${getCostWithCurrency(Number(row?.cost))}` : '';
        return (
          <span className="truncate" title={value}>
            {value}
          </span>
        );
      },
      rowAlign: 'right',
      width: 100,
    },
    {
      id: 'id',
      label: 'Action',
      value: 'id',
      valueNode: (props: any) => {
        const { row, state } = props;
        return (
          <Button
            disabled={state?.extraProps?.isEditMode || disabled}
            theme="danger"
            onClick={() => {
              state?.extraProps?.onRemoveTransaction(row.id);
            }}>
            Remove
          </Button>
        );
      },
      width: 120,
    },
  ] as DataGridHeadCell[];

  if (isEditMode) {
    return columns.splice(0, 5);
  }

  return columns;
};
