export const stepOneDefaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  primaryPhoneNumber: ''
};

export const stepThreeDefaultValues = {
  organization: '',
  address: '',
  zip: '',
  city: '',
  state: '',
};

export const stepFourDefaultValues = {
  url: ''
};

export const stepFiveDefaultValues = {
  password: '',
  confirmPassword: '',
};
