import { useCallback, useEffect, useMemo, useState } from 'react';
import useCollapsibleEntityManager from 'app/modules/components/EntityManager/useCollaspibleEntityManager';
import {
  ItemLocationSchema,
  UpdateQuantityConfigurationsAtItemLocationInput,
} from 'app/types/schema';

import { parseStockInformationToRows } from '../../utils/stockInormation';

interface Props {
  stockInformation: ItemLocationSchema[];
  itemId: string;
}

const useQuantityBySiteState = (props: Props) => {
  const { stockInformation, itemId } = props;

  const [rows, setRows] = useState<any[]>([]);

  const { table } = useCollapsibleEntityManager({
    selection: false,
  });

  useEffect(() => {
    if (stockInformation) {
      setRows(parseStockInformationToRows(stockInformation));
    }
  }, [stockInformation, setRows]);

  const makeSiteRowEditable = useCallback((row: any) => {
    return {
      ...row,
      editable: true,
      editableFields: {
        minimumQuantity: row?.minimumQuantity?.toString() || '',
        maximumQuantity: row?.maximumQuantity?.toString() || '',
      },
    };
  }, []);

  const cancelSiteRowEditable = useCallback((row: any) => {
    return {
      ...row,
      editable: false,
      editableFields: null,
    };
  }, []);

  const toggleEditableSiteState = useCallback(
    (siteId: string) => {
      let updatedRows = rows.map((row) => {
        if (row.id === siteId) {
          if (row.editable) {
            return cancelSiteRowEditable(row);
          }
          return makeSiteRowEditable(row);
        }
        return row;
      });
      setRows([...updatedRows]);
    },
    [rows, setRows, makeSiteRowEditable, cancelSiteRowEditable],
  );

  const setEditableSiteState = useCallback(
    (siteId: string, data: any) => {
      let updatedRows = rows.map((row) => {
        if (row.id === siteId) {
          return {
            ...row,
            editableFields: {
              ...row.editableFields,
              ...data,
            },
          };
        }
        return row;
      });
      setRows([...updatedRows]);
    },
    [rows, setRows],
  );

  const isAllSitesEditable = useMemo(() => {
    return !rows.map((row) => row.editable).includes(false);
  }, [rows]);

  const toggleEditableSitesState = useCallback(() => {
    const updatedRows = rows.map((row) => {
      if (isAllSitesEditable) {
        return cancelSiteRowEditable(row);
      } else {
        if (!row.editable) {
          return makeSiteRowEditable(row);
        }
      }
      return row;
    });
    setRows(updatedRows);
  }, [rows, setRows, makeSiteRowEditable, cancelSiteRowEditable, isAllSitesEditable]);

  const isSiteRowInEditableMode: boolean = useMemo(() => {
    let isEditable = false;
    rows.forEach((row) => {
      if (row.editable) {
        isEditable = true;
      }
    });
    return isEditable;
  }, [rows]);

  const updateQuantityAtItemLocationInputs: UpdateQuantityConfigurationsAtItemLocationInput[] =
    useMemo(() => {
      const inputs: UpdateQuantityConfigurationsAtItemLocationInput[] = [];

      rows.forEach((row) => {
        if (row.editable) {
          const { editableFields, site } = row;
          const { id } = site;
          const { minimumQuantity, maximumQuantity } = editableFields;
          inputs.push({
            itemId: itemId,
            locationId: id,
            minimumQuantity:
              minimumQuantity !== '' && !isNaN(minimumQuantity)
                ? Number(editableFields.minimumQuantity)
                : null,
            maximumQuantity:
              maximumQuantity !== '' && !isNaN(maximumQuantity)
                ? Number(editableFields.maximumQuantity)
                : null,
          });
        }
      });

      return inputs;
    }, [rows, itemId]);

  const isValidUpdateQuantityAtItemLocation: boolean = useMemo(() => {
    let isValid = true;
    updateQuantityAtItemLocationInputs.forEach((item) => {
      const { minimumQuantity, maximumQuantity } = item;
      if (minimumQuantity === null || maximumQuantity === null) {
        isValid = false;
      }
    });

    return isValid;
  }, [updateQuantityAtItemLocationInputs]);

  const onCancelEditableSitesState = useCallback(() => {
    const updatedRows = rows.map((row) => {
      if (row.editable) {
        return cancelSiteRowEditable(row);
      }
      return row;
    });
    setRows(updatedRows);
  }, [rows, setRows, cancelSiteRowEditable]);

  return {
    rows,
    table,
    toggleEditableSiteState,
    toggleEditableSitesState,
    setEditableSiteState,
    isSiteRowInEditableMode,
    isAllSitesEditable,
    onCancelEditableSitesState,
    isValidUpdateQuantityAtItemLocation,
    updateQuantityAtItemLocationInputs,
  };
};

export type UseQuantityBySiteStateReturnType = ReturnType<typeof useQuantityBySiteState>;

export default useQuantityBySiteState;
