import './style.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import { useStyle } from '../../modules/inventory/components/useStyle';
import { AsyncSelectOption } from '../AsyncMultiSelect/types';
import { AsyncSingleSelectProps } from '../AsyncSingleSelect/types';

const getOptionLabel = (option: AsyncSelectOption) => option.label;

const EMPTY_ARRAY: never[] = [];

const AsyncSingleTableFilter = (props: AsyncSingleSelectProps) => {
  const {
    queryVariables,
    useSelectedValue,
    useSelectQuery,
    placeholder = '',
    value: valueProp,
    onBlur,
    onChange,
    disabled,
    label,
    size,
    name
  } = props;

  const classes = useStyle();
  const inputRef = useRef<any>();

  const value = valueProp || EMPTY_ARRAY;
  const [open, setOpen] = useState<boolean>(false);
  const [menuId] = useState(nanoid().toString());
  const [menuRef, setMenuRef] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<AsyncSelectOption | null>(null);
  const [activePage, setActivePage] = useState(0);
  const isQueryVariablesChange = JSON.stringify(queryVariables);
  useEffect(() => {
    setActivePage(0);
  }, [isQueryVariablesChange]);

  const { isLoading, options } = useSelectQuery({
    inputValue,
    activePage,
    ...queryVariables,
  });

  const { isDisabled, selectedValue: sv } = useSelectedValue({
    value,
    pause: !value.length || Boolean(selectedValue && selectedValue?.value === value),
    options: options,
    isMultiple: false,
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    const getMenuRef = () => ({
      current: document?.getElementById(menuId),
    });

    setTimeout(() => {
      const menuRef = getMenuRef();
      setMenuRef(menuRef);
    }, 100);
  }, [open]);

  useEffect(() => {
    if (sv) {
      setSelectedValue(sv);
    }
  }, [sv]);

  useEffect(() => {
    if (value.length === 0) {
      setSelectedValue(null);
    }
  }, [value]);

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: AsyncSelectOption) => {
      setSelectedValue(option as AsyncSelectOption);
      onChange?.(option?.value || '', option as AsyncSelectOption);
    },
    [onChange],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
  };

  const handleOnBlur = useCallback(
    (event: any) => {
      setInputValue('');
      onBlur?.();
    },
    [onBlur, setInputValue],
  );

  return (
    <div className="w-[100%]" ref={inputRef}>
      <Autocomplete
        className={clsx(classes.root, 'table-multi-table-filter')}
        disabled={isDisabled || disabled}
        getOptionLabel={getOptionLabel}
        inputValue={inputValue || ''}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value;
        }}
        label={''}
        loading={isLoading}
        open={open}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              name={name}
              {...params}
              inputProps={{
                ...params.inputProps,
                className: `${params.inputProps.className || ''} ${inputValue ? 'bg-white' : ''}`,
              }}
              label={label}
              placeholder={!selectedValue?.value ? placeholder : ''}
            />
          );
        }}
        size={size}
        value={selectedValue}
        onBlur={handleOnBlur}
        onChange={onValueChange}
        onClose={(event, reason) => {
          setInputValue('');
          setOpen(false);
        }}
        onInputChange={onInputValueChange}
        onOpen={() => {
          setOpen(true);
        }}
        sx={{
          '&.MuiAutocomplete-root > div': {
            display: 'flex',
            alignItems: 'center',
          },
          '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
            position: 'absolute !important',
            marginLeft: 'auto',
            right: '1px !important'
          },
          '&.MuiAutocomplete-root .MuiOutlinedInput-input': {
            width: 'calc(100% - 32px) !important',
            minWidth: '20px',
          },
          '&.MuiAutocomplete-root MuiDataGrid-columnHeader': {
            minWidth: '60px !important',
          }
        }}
      />
    </div>
  );
};

export default AsyncSingleTableFilter;
