import React, { ReactElement, ReactNode, useCallback } from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import DevicesOtherOutlined from '@mui/icons-material/DevicesOtherOutlined';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import { Box, Card, CardContent, Grid, Stack, TablePagination, Typography } from '@mui/material';
import { Pagination } from '@procurenetworks/procure-component-library';
import Network from 'app/i18n/Network';
import { UseMultiSelectEntityManagerReturnType } from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { DeviceRowType } from 'app/modules/network/types';

import { UseNetworkAssetsStateReturnType } from '../../hook/useNetworkAssetsState';

interface Props {
  table: UseMultiSelectEntityManagerReturnType['table'];
  filters: UseNetworkAssetsStateReturnType['filters'];
  tableState: UseNetworkAssetsStateReturnType['networkAssetTableState'];
  history?: any;
  clickable: boolean;
  discovered?: boolean;
  display?: string;
  handleItemClick?: (param: { row: DeviceRowType }) => void;
  devicesRow: DeviceRowType[];
  filterNodes?: ReactNode | ReactElement;
}

const NetworkAssetGridView = (props: Props) => {
  const { tableState, devicesRow, handleItemClick, discovered, table, filterNodes } = props;
  const { onNextPage, onPrevPage } = tableState;

  const count = Math.ceil(tableState.totalCount / table.state.numberOfRowsPerPage);

  const onTableChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      const fetchedRows = devicesRow?.length || 0;
      const fetchedPages = Math.ceil(fetchedRows / table.state.numberOfRowsPerPage);

      if (page > fetchedPages) {
        // fetchMore?.();
      }

      if (page > table.state.activePage) {
        onNextPage?.();
      } else if (page < table.state.activePage) {
        onPrevPage?.();
      }
      table.setState({
        activePage: page,
        ...{ selectedRowIds: [] },
      });
    },
    [devicesRow?.length, table, onNextPage, onPrevPage],
  );

  const onTableChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      table.setState({
        activePage: 0,
        numberOfRowsPerPage: +event.target.value,
        ...{ selectedRowIds: [] },
      });
    },
    [table],
  );

  return (
    <>
      {devicesRow.length ? (
        <>
          <Box className="flex flex-wrap items-center justify-between p-[24px]">
            <Box>{filterNodes}</Box>
            <Pagination
              aria-disabled={tableState.fetching}
              count={tableState.totalCount}
              page={table.state.activePage}
              rowsPerPage={table.state.numberOfRowsPerPage}
              onPageChange={onTableChangePage}
              onRowsPerPageChange={onTableChangeRowsPerPage}
            />
          </Box>

          <Grid container className="p-[24px]" spacing={{ sm: 1, md: 3, lg: 3 }}>
            {devicesRow.length &&
              devicesRow.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    style={{ cursor: discovered ? 'default' : 'pointer' }}
                    xl={3}
                    xs={12}>
                    <Card
                      className="rounded-[12px] border border-grey-300"
                      style={{ height: '100%' }}
                      sx={{
                        boxShadow:
                          '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                      }}
                      onClick={() => handleItemClick && handleItemClick({ row: row })}>
                      <CardContent className="p-0 !pb-0">
                        <Box className="h-[128px] p-[24px]">
                          <Typography
                            className="text-[16px] font-semibold text-grey-900"
                            variant="h6">
                            {row.type}
                          </Typography>
                          <Typography className="text-[14px] text-grey-800" variant="subtitle1">
                            {row?.mac}
                          </Typography>
                          <Typography className="text-[14px] text-grey-800" variant="subtitle1">
                            {row?.ip}
                          </Typography>
                          {!discovered ? (
                            <>
                              <Typography className="text-[14px] text-grey-800" variant="subtitle1">
                                {row?.cpuBrand}
                              </Typography>
                              <Typography className="text-[14px] text-grey-800" variant="subtitle1">
                                {row?.cpuManufacturer}
                              </Typography>
                              <Typography className="text-[14px] text-grey-800" variant="subtitle1">
                                {row?.osPlatform}
                              </Typography>
                            </>
                          ) : null}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </>
      ) : (
        <Typography color="textSecondary" variant="subtitle1">
          {Network.FormLabels.NoResult}
        </Typography>
      )}
    </>
  );
};

export default NetworkAssetGridView;
