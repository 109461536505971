import {
  arrayValidator,
  composeValidators,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from 'app/components/Form/utils/validators';
import Site from 'app/i18n/Site';

export const SITE_FORM_RULES = {
  name: {
    validate: composeValidators(
      textValidator(Site.FormValidationMessages.NameRequired),
      textMinLengthValidator(Site.FormValidationMessages.NameMinMaxLength, 3),
      textMaxLengthValidator(Site.FormValidationMessages.NameMinMaxLength, 30),
    ),
  },
  description: {
    validate: (value?: string | null) => {
      if (!value) {
        return true;
      }
      return textMaxLengthValidator(Site.FormValidationMessages.DescriptionMaxLength, 500)(value);
    },
  },
  locationCode: {
    validate: (value?: string | null) => {
      if (!value) {
        return true;
      }
      return textMaxLengthValidator(Site.FormValidationMessages.CodeMaxLength, 30)(value);
    },
  },
  siteManagerIds: {
    validate: arrayValidator(Site.FormValidationMessages.SiteManagerLength),
  },
};
