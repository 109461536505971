import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormProjectSelect, { Option } from 'app/components/ProcureForm/FormProjectSelect';
import Orders from 'app/i18n/Orders';

import {
  FIELD_ARRAY_KEY,
  UseBulkOrderRequestReturnType,
} from '../../../OrderItemRequest/hook/useBulkOrderRequest';
import { OrderRequestInput } from '../../../OrderRequestForm/type';

interface Props {
  state: UseBulkOrderRequestReturnType['bulkOrderState'];
  index: number;
  disabled?: boolean;
  onUpdateOrderItem: (index: number, nextState: Partial<OrderRequestInput>) => void;
}

const BulkOrderProjectField = (props: Props) => {
  const { state, index, onUpdateOrderItem, disabled } = props;
  const { control } = state;

  const onProjectChange = useCallback(
    (projectItem: Option | null) => {
      onUpdateOrderItem(index, {
        projectName: projectItem?.name,
      });
    },
    [onUpdateOrderItem, index],
  );

  return (
    <Controller
      control={control}
      name={`${FIELD_ARRAY_KEY}.${index}.projectId`}
      render={({ field, fieldState }) => {
        return (
          <FormProjectSelect
            label={Orders.FormLabels.Project}
            {...field}
            disabled={disabled}
            error={fieldState.error}
            onChange={(value, projectItem) => {
              field.onChange(value);
              onProjectChange(projectItem);
            }}
            formLabel='Project'
            placeholder='Project'
          />
        );
      }}
    />
  );
};
export default BulkOrderProjectField;
