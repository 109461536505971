import { useMemo } from 'react';
import usePagination from 'app/hooks/usePagination';
import ReportPreviewTable from 'app/modules/reports/components/ReportPreviewTable';
import { getTransactionFilterValues } from 'app/modules/reports/components/TransactionTypeFormField/utils';
import useReportPreviewTable from 'app/modules/reports/hooks/useReportPreviewTable';

import { useAccountingReportQuery } from '../../graphql/generated/accountingReport';
import { AccountingReportFormValues } from '../AccountingReportForm/types';
import { getAccountingReportTableColumns } from './columns';
import { Box } from '@mui/material';
import { TransactionStatusEnum } from 'app/types/schema';

interface Props {
  values: AccountingReportFormValues;
}

const AccountingReportTable = (props: Props) => {
  const { values } = props;
  const { table } = useReportPreviewTable();

  const { initialFetching, fetching, data, onNextPage, onPrevPage } = usePagination(
    useAccountingReportQuery,
    {
      filters: {
        _and: [
          { _or: [{ destinationSiteIds: values.siteIds }, { sourceSiteIds: values.siteIds }] },
          { _or: getTransactionFilterValues(values.transactionTypes) },
          {
            statuses: [
              TransactionStatusEnum.CheckedOut,
              TransactionStatusEnum.Completed,
              TransactionStatusEnum.Expired,
              TransactionStatusEnum.InCustody,
              TransactionStatusEnum.Overdue,
              TransactionStatusEnum.PartiallyCompleted,
              TransactionStatusEnum.Pending,
              TransactionStatusEnum.Reserved,
            ],
          },
        ],
        createdByIds: values.userIds,
        projectIds: values.projectIds,
        categoryIds: values.categoryIds,
        departmentIds: values.departmentIds,
        entityTypes: values.itemTypes,
        createdAt: values.createdAt,
      },
      sorts: [{ sortField: 'createdAt', sortOrder: values.sortOrder }],
    },
    { edgeKey: 'transactions', pageSize: table.state.numberOfRowsPerPage },
  );

  const rows = useMemo(() => {
    return data?.transactions.edges?.map(({ node }) => node) || [];
  }, [data?.transactions.edges]);

  const columns = useMemo(() => getAccountingReportTableColumns(values.types), [values.types]);

  return (
    <Box
      className="mx-[24px] mt-[24px] rounded-[12px] border border-grey-300"
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
      }}>
      <ReportPreviewTable
        columns={columns}
        data={rows}
        initialLoading={initialFetching}
        loading={fetching}
        pagination={true}
        persistKey="accounting_report_table"
        setState={table.setState}
        state={table.state}
        total={data?.transactions.totalCount}
        onNextPage={onNextPage}
        onPrevPage={onPrevPage}
        paginationClass="p-[24px]"
        className="px-[0px]"
      />
    </Box>
  );
};

export default AccountingReportTable;
