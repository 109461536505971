import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontWeight: '600 !important',
    },
    '& input::placeholder': {
      fontWeight: '600',
      opacity: 1,
    },
  },
}));
