import { useMemo } from 'react';
import reportRouteHierarchy from 'app/consts/reportRouteHierarchy';
import routes from 'app/consts/routes';
import { AccessControlRoute as Route, Redirect, Switch } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import useReportExportSubscription from 'app/modules/reports/hooks/useReportExportSubscription';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from 'app/types/schema';

import AssetAuditReportDetails from './asset-audit-report-details/[id]';
import ReportsLayout from './components/ReportsLayout';
import InventoryAuditReportDetails from './inventory-audit-report-details/[id]';
import AccountingReport from './accounting-report';
import AgingReport from './aging-report';
import AuditReport from './audit-report';
import CostReport from './cost-report';
import CustomReport from './custom-report';
import ITAssetReportPage from './it-asset-report';
import OrderRequestReport from './order-request-report';
import SiteReport from './site-report';
import ReorderReport from './re-order-report'
import TransactionReport from './transaction-report';

const action = AllowedPermissionActionsEnum.Access;

const ReportsPage = () => {
  useReportExportSubscription();

  const { workspacePermissions } = useCurrentUser();

  let reportRoute = useMemo(() => {
    let tempRoute = routes.Reports();
    if (workspacePermissions) {
      for (let i = 0; i < reportRouteHierarchy.length; i++) {
        let route = reportRouteHierarchy[i];
        let matched = false;

        for (let j = 0; j < workspacePermissions.length; j++) {
          if (
            workspacePermissions[j].subject === route.subject &&
            workspacePermissions[j].action === route.permission
          ) {
            matched = true;
            break;
          }
        }

        if (matched) {
          tempRoute = route.route;
          break;
        }
      }
    }

    return tempRoute;
  }, [workspacePermissions]);

  return (
    <Switch>
      <Route
        action={action}
        path={routes.CostReport()}
        subject={AllowedPermissionsSubjectEnum.CostReport}>
        <ReportsLayout>
          <CostReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.LowStockReport()}
        subject={AllowedPermissionsSubjectEnum.LowStockReport}>
        <ReportsLayout>
          <ReorderReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.SiteReport()}
        subject={AllowedPermissionsSubjectEnum.SiteReport}>
        <ReportsLayout>
          <SiteReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.TransactionReport()}
        subject={AllowedPermissionsSubjectEnum.TransactionReport}>
        <ReportsLayout>
          <TransactionReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.AgingReport()}
        subject={AllowedPermissionsSubjectEnum.AgeingReport}>
        <ReportsLayout>
          <AgingReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.AssetAuditReportDetails(':id')}
        subject={AllowedPermissionsSubjectEnum.AssetAuditReport}>
        <ReportsLayout>
          <AssetAuditReportDetails />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.InventoryAuditReportDetails(':id')}
        subject={AllowedPermissionsSubjectEnum.InventoryAuditReport}>
        <ReportsLayout>
          <InventoryAuditReportDetails />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.AuditReport()}
        subject={AllowedPermissionsSubjectEnum.AssetAuditReport}>
        <ReportsLayout>
          <AuditReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.AccountingReport()}
        subject={AllowedPermissionsSubjectEnum.AccountingReport}>
        <ReportsLayout>
          <AccountingReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.CustomReport()}
        subject={AllowedPermissionsSubjectEnum.CustomReport}>
        <ReportsLayout>
          <CustomReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.OrderRequestReport()}
        subject={AllowedPermissionsSubjectEnum.OrderRequestReport}>
        <ReportsLayout>
          <OrderRequestReport />
        </ReportsLayout>
      </Route>
      <Route
        action={action}
        path={routes.ItAssetReport()}
        subject={AllowedPermissionsSubjectEnum.ItAsset}>
        <ReportsLayout>
          <ITAssetReportPage />
        </ReportsLayout>
      </Route>

      <Redirect to={reportRoute} />
    </Switch>
  );
};

export default ReportsPage;
