import React, { createContext } from 'react';
import { UseAssetFormReturnType } from 'app/modules/assets/hook/useAssetForm';
import { UseInventoryFormReturnType } from 'app/modules/inventory/components/InventoryForm/hook/useInventoryForm';
import { AssetKitItemSchema, InventoryKitItemSchema, ItemTypeEnum } from 'app/types/schema';

import useKitSummaryState, { UseKitSummaryStateReturnType } from '../hook/useKitSummaryState';

export type KitSummaryContextType = { kitState: UseKitSummaryStateReturnType } & {
  onCancelKitSummary: () => void;
  destinationSiteId: string;
  isAttachmentUploading: boolean;
};

export const KitSummaryContext = createContext({});

interface Props {
  children: React.ReactNode;
  types: ItemTypeEnum[];
  state: UseInventoryFormReturnType['state'] | UseAssetFormReturnType['formState'];
}

const KitSummaryProvider = ({ children, types, state }: Props) => {
  const { updateUnitCost, itemData, quantity } = state;
  const {
    formState,
    rows,
    onRemoveTransaction,
    onFormSubmit,
    onResetForm,
    onResetFormAndTable,
    tableState,
    table,
  } = useKitSummaryState({
    types: types,
    updateUnitCost,
    kitItems: (itemData as InventoryKitItemSchema | AssetKitItemSchema)?.kitConfigs,
    kitQuantity: quantity,
    siteId: state?.destinationSiteId,
    itemData: itemData,
  });

  const context = {
    kitState: {
      formState,
      onRemoveTransaction,
      onFormSubmit,
      onResetForm,
      onResetFormAndTable,
      rows,
      tableState,
      table,
    },
    onCancelKitSummary: state?.onCancelKitSummary,
    destinationSiteId: state?.destinationSiteId,
    isAttachmentUploading: state?.attachmentInputProps?.isUploading,
  } as KitSummaryContextType;
  return <KitSummaryContext.Provider value={context}>{children}</KitSummaryContext.Provider>;
};

export default KitSummaryProvider;
