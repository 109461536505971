import AssetsLayout from '../../modules/assets/layout';
import ReserveAssets from '../../modules/assets/views/ReserveAssets';

function ReserveAssetsPage() {
  return (
    <AssetsLayout>
      <ReserveAssets />
    </AssetsLayout>
  );
}

export default ReserveAssetsPage;
