import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteUsersMutationVariables = Types.Exact<{
  input: Types.DeleteUsersInput;
}>;


export type DeleteUsersMutation = { __typename?: 'Mutation', deleteUsers: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteUsersDocument = gql`
    mutation deleteUsers($input: DeleteUsersInput!) {
  deleteUsers(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteUsersMutation() {
  return Urql.useMutation<DeleteUsersMutation, DeleteUsersMutationVariables>(DeleteUsersDocument);
};