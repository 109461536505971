import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import routes from 'app/consts/routes';
import useDebounce from 'app/hooks/useDebounce';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';

import { useWishlistShareIdQuery } from '../../../graphql/queries/generated/wishlistShareId';
import { CREATELIST_FORM_RULES as rules } from '../../../rules';
import FormLabel from 'app/components/ProcureForm/FormLabel';

function StepFive(props: any) {
  const { isLoading, onSubmit, onPrevious, formValues } = props;
  const defaultValues = {
    shareId: formValues.shareId,
  };
  const { control, handleSubmit, watch } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onSubmit), [handleSubmit, onSubmit]);
  const [customUrl, setCustomUrl] = useState<string>(defaultValues.shareId || '');

  const handleCustomUrlChange = (value: string) => {
    setCustomUrl(value);
  };

  const shareId = watch('shareId');

  const [{ data: wishlistData }] = useWishlistShareIdQuery({
    variables: { filters: { shareId: useDebounce(shareId) } },
  });

  const isDuplicate = !!(shareId && wishlistData?.wishlistValidateShareId.exists);

  return (
    <form data-testid="create-list-form">
      <Box className="mx-auto mt-12 min-w-[120px] max-w-[487px] space-y-24">
        <label className="font-semibold text-grey-900"></label>
        <FormLabel className="text-[14px] font-semibold text-grey-800">{`Create a custom link so that donors may easily access your Wishlist.`}</FormLabel>
        <div style={{ marginTop: '6px', marginBottom: '18px' }}>
          <Controller
            control={control}
            name="shareId"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                className="w-full rounded-none"
                error={fieldState.error}
                label={Wishlist.FormLabels.CustomUrl}
                onChange={(e) => {
                  const url = e.replace(/[-\s]+/g, '-').toLowerCase();
                  field.onChange(url);
                  handleCustomUrlChange(url);
                }}
                isRequired
                formLabel="Custom URL (e.g: My-Charity-Wishlist)"
              />
            )}
            rules={rules.customUrl(isDuplicate)}
          />
        </div>
        <Typography className="text-[14px] text-grey-800">
          This is what your custom link will look like:
        </Typography>
        <Typography className="text-[14px] text-grey-800">
          {`${process.env.REACT_APP_CORE_UI_URL}${routes.WishlistShareWithId(customUrl)}`}
        </Typography>
        <Stack>
          <Box className="flex justify-end space-x-[16px]">
            <Button block={true} onClick={onPrevious}>
              {Common.Actions.Previous}
            </Button>
            <Button block={true} loading={isLoading} theme="success" onClick={onFormSubmit}>
              {Common.Actions.Complete}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

export default StepFive;
