import { Grid } from '@mui/material';
import { Stepper } from '@procurenetworks/procure-component-library';

import { getOrderSteps, SCHEDULE_STEP } from './utils';

interface Props {
  activeStep: SCHEDULE_STEP;
}

const ScheduleStepper = (props: Props) => {
  const { activeStep } = props;
  return (
    <div className="mb-[6px] bg-white py-[20px]">
      <Grid container>
        <Grid item lg={6} md={8} xs={12}>
          <Stepper activeStep={activeStep} steps={getOrderSteps()} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ScheduleStepper;
