import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ZipCodeLookupQueryVariables = Types.Exact<{
  filters: Types.GeoCodeFilters;
}>;


export type ZipCodeLookupQuery = { __typename?: 'Query', geoCode: { __typename?: 'GeoCodeSchema', city: string, state: string } };


export const ZipCodeLookupDocument = gql`
    query zipCodeLookup($filters: GeoCodeFilters!) {
  geoCode(filters: $filters) {
    city
    state
  }
}
    `;

export function useZipCodeLookupQuery(options: Omit<Urql.UseQueryArgs<ZipCodeLookupQueryVariables>, 'query'>) {
  return Urql.useQuery<ZipCodeLookupQuery>({ query: ZipCodeLookupDocument, ...options });
};