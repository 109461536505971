import { useCallback, useMemo } from 'react';
import AsyncMultiSelect from 'app/components/AsyncMultiSelect';
import {
  AsyncSelectOption,
  UseSelectedValueProps,
  UseSelectedValueReturn,
  UseSelectQueryProps,
  UseSelectQueryReturn,
} from 'app/components/AsyncMultiSelect/types';
import { adaptNodeEdgeToOption } from 'app/components/AsyncMultiSelect/utils';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';
import { getAllowedRoleTypes } from 'app/modules/roles/utils';
import { AllowedScopeEntityEnum, SortOrderEnum, UserScopedRoleInput } from 'app/types/schema';

import { useRolesSelectQuery } from '../../graphql/queries/generated/rolesSelect';

export interface RolesSelectProps {
  name: string;
  value: UserScopedRoleInput[];
  onBlur?: () => void;
  onChange: (values: UserScopedRoleInput[]) => void;
  placeholder?: string;
  helperText?: string;
}

const useSelectedValue = (props: UseSelectedValueProps): UseSelectedValueReturn => {
  const { value, pause } = props;

  const [{ fetching, data }] = useRolesSelectQuery({
    pause,
    variables: { filters: { roleIds: value } },
    requestPolicy: 'network-only',
  });
  const values = useMemo(
    () => data?.roles.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.roles.edges],
  );

  return { isDisabled: fetching, selectedValues: values };
};

const useSelectQuery = (props: UseSelectQueryProps): UseSelectQueryReturn => {
  const { inputValue } = props;
  const currentUser = useCurrentUser();

  const [{ fetching, data }] = useRolesSelectQuery({
    variables: {
      filters: { search: inputValue, types: getAllowedRoleTypes(currentUser) },
      sorts: [{ sortField: 'name', sortOrder: SortOrderEnum.Asc }],
      limit: 10000,
    },
    requestPolicy: 'network-only',
  });
  const options = useMemo(
    () => data?.roles.edges?.map(adaptNodeEdgeToOption) || [],
    [data?.roles.edges],
  );

  return { isLoading: fetching, options };
};

const RolesSelect = (props: RolesSelectProps) => {
  const { value: valueProp, onChange: onChangeProp, ...rest } = props;

  const value = useMemo(() => valueProp.map((role) => role.roleId), [valueProp]);
  const onChange = useCallback(
    (_: string[], options: AsyncSelectOption[], allOptions?: AsyncSelectOption[]) => {
      const staff = allOptions?.filter((option) => option.label === 'Staff') || [];
      onChangeProp([
        ...staff.map((option) => ({ 
          roleId: option.value, 
          scopeEntity: 
            option.node.allowedScopes?.[0]?.scope || 
            AllowedScopeEntityEnum.Scopeless 
          }
        )),
        ...options.map((option) => {
          const scopeEntity =
            option.node.allowedScopes?.[0]?.scope || AllowedScopeEntityEnum.Scopeless;

          return { roleId: option.value, scopeEntity };
        }),
      ]);
    },
    [onChangeProp],
  );

  return (
    <AsyncMultiSelect
      {...rest}
      limitTags={-1}
      useSelectQuery={useSelectQuery}
      useSelectedValue={useSelectedValue}
      value={value}
      onChange={onChange}
      disableOptionCondition={(option) => option.label === 'Staff'}
    />
  );
};

export default RolesSelect;
