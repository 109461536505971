import Orders from 'app/i18n/Orders';

export enum ORDER_STEP {
  ORDER_REQUEST = 'order-request',
  DELIVERY_DETAILS = 'delivery-details',
}

export function getOrderSteps() {
  return [
    {
      id: ORDER_STEP.ORDER_REQUEST,
      label: Orders.FormLabels.ItemRequestStep,
    },
    {
      id: ORDER_STEP.DELIVERY_DETAILS,
      label: Orders.FormLabels.ItemDeliveryStep,
    },
  ];
}
