import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import OrderRequestItemImageView from 'app/modules/orderShipping/components/OrderRequestItemImageView';
import OrderRequestItemSku from 'app/modules/orderShipping/components/OrderRequestItemSku';

export const getOrderItemsTableHeaders = (): DataGridHeadCell[] => [
  {
    id: 'imageUrl',
    label: 'Image',
    value: 'imageUrl',
    valueNode: ({ row, state }) => (
      <OrderRequestItemImageView
        orderRequestItem={row}
        onImageClick={state?.extraProps?.imagePreviewActions?.onShow}
      />
    ),
    width: 100,
  },
  {
    id: 'sku',
    label: 'SKU',
    value: 'sku',
    valueNode: ({ row }) => {
      return <OrderRequestItemSku orderRequestItem={row} />;
    },
    flex: 1,
  },
  {
    id: 'title',
    label: 'Title',
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row?.item?.title}>
        {row?.item?.title}
      </span>
    ),
    flex: 1,
  },
  {
    id: 'quantity',
    label: 'Qty',
    value: 'quantity',
    valueNode: ({ row }) => (
      <span title={row?.quantity} className="w-[100%] truncate text-right">
        {row?.quantity}
      </span>
    ),
    flex: 1,
  },
];
