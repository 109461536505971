import React from 'react';
import AppleIcon from '@mui/icons-material/Apple';
import DevicesOtherOutlined from '@mui/icons-material/DevicesOtherOutlined';
import { Card, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { LinuxSvg, windowsSvg } from '../utils/svg';
import CustomSvgIcon from './customSVGIcon';

export const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

type CardWidgetType = {
  color?: string;
  index: number;
  title: string;
  total: number;
  sx?: any;
};

export const WidgetCard = ({
  title,
  total,
  index,
  color = 'primary',
  sx,
  ...other
}: CardWidgetType) => {
  let icon = null;
  switch (index % 4) {
    case 0:
      icon = <AppleIcon fontSize="large" style={{ color: '#704900' }} />;
      break;
    case 1:
      icon = (
        <CustomSvgIcon
          color="primary"
          fontSize="large"
          style={{
            fontSize: 25,
          }}
          svgContent={windowsSvg}
        />
      );
      break;
    case 2:
      icon = <CustomSvgIcon color="primary" fontSize="large" svgContent={LinuxSvg} />;
      break;
    case 3:
      icon = <DevicesOtherOutlined fontSize="large" style={{ color: '#704900' }} />;
      break;
    default:
      break;
  }
  return (
    <Card
      className="rounded-[12px] border-[1px] border-grey-300 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06),_0px_1px_3px_0px_rgba(16,24,40,0.10)]"
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: '#704900',
        ...sx,
      }}
      {...other}>
      <Typography className="text-grey-900 m-0 text-[36px] font-medium">
        {total}
      </Typography>

      <Typography className="text-grey-800 text-[14px] font-medium mt-[8px]" sx={{ opacity: 0.72 }} variant="subtitle2">
        {title}
      </Typography>
    </Card>
  );
};
