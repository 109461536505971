import { isAccessible } from 'app/components/AccessControl';
import { ItemType } from 'app/consts/enum';
import routes from 'app/consts/routes';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  UserWorkspacePermission,
} from 'app/types/schema';

function pathChecker(value: string) {
  var prohibited = [ItemType.Asset, ItemType.Inventory, 'user'];

  for (var i = 0; i < prohibited.length; i++) {
    if (value.includes(prohibited[i])) {
      return true;
    }
  }
  return false;
}

export function getRedirectToBasedOnPermission(
  workspacePermissions: Array<UserWorkspacePermission>,
  history: any,
) {
  const canUndeleteAsset = isAccessible(
    workspacePermissions,
    AllowedPermissionActionsEnum.Undelete,
    AllowedPermissionsSubjectEnum.Asset,
  );
  const canUndeleteInventory = isAccessible(
    workspacePermissions,
    AllowedPermissionActionsEnum.Undelete,
    AllowedPermissionsSubjectEnum.Inventory,
  );
  const canUndeleteUser = isAccessible(
    workspacePermissions,
    AllowedPermissionActionsEnum.Undelete,
    AllowedPermissionsSubjectEnum.User,
  );

  if (!pathChecker(history.location.pathname)) {

    if (canUndeleteAsset) {
      return routes.UndeleteAsset();
    }
    if (canUndeleteInventory) {
      return routes.UndeleteInventory();
    }
    if (canUndeleteUser) {
      return routes.UndeleteUser();
    }
  }
  if (canUndeleteAsset && canUndeleteInventory && canUndeleteUser) {
    return;
  }

  let path = history.location.pathname.split('/').pop();
  if (canUndeleteAsset && path === 'asset') {
    return routes.UndeleteAsset();
  }
  if (canUndeleteInventory && path === 'inventory') {
    return routes.UndeleteInventory();
  }
  if (canUndeleteUser && path === 'user') {
    return routes.UndeleteUser();
  }

  return routes.Users();
}
