import { SnackbarService } from '../components/Snackbar';

export function showReasonsError(reasons?: string[] | null) {
  if (reasons?.length) {
    const errorReasons = reasons?.slice(0, 2);
    SnackbarService.showError({
      message: errorReasons?.join(', '),
    });
  }
}
