import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import { getLocationName } from 'app/modules/locations/utils/location';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { getUnitCost } from 'app/modules/reports/utils';
import { formatDate } from 'app/utils/date';

export const getInventoryCostTableColumns = (): DataGridHeadCell[] => [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row.item} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.title}>
        {row.item?.title}
      </span>
    ),
  },
  {
    id: 'category',
    label: Category.Category,
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.category?.name}>
        {row.item?.category?.name}
      </span>
    ),
  },
  {
    id: 'categoryCode',
    label: Category.FormLabels.CategoryCode,
    value: 'categoryCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.category?.categoryCode}>
        {row.item?.category?.categoryCode}
      </span>
    ),
  },
  {
    id: 'site',
    label: 'Site',
    value: 'site',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
  },
  {
    id: 'siteCode',
    label: 'Site Code',
    value: 'siteCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.locationCode}>
        {row.site?.locationCode}
      </span>
    ),
  },
  {
    id: 'location',
    label: 'Location',
    value: 'location',
    valueNode: ({ row }) => (
      <span className="truncate" title={getLocationName(row.site?.id, row.location)}>
        {getLocationName(row.site?.id, row.location)}
      </span>
    ),
  },
  {
    id: 'totalQuantity',
    label: Reports.Quantity,
    value: 'totalQuantity',
    rowAlign: 'right',
  },
  {
    id: 'unitCostWithoutOverride',
    label: Reports.UnitCost,
    value: 'unitCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.item?.unitCost)}>
        {getUnitCost(row.item?.unitCost)}
      </span>
    ),
  },
  {
    id: 'updatedAt',
    label: 'Date',
    value: 'updatedAt',
    valueNode: ({ row }) => (
      <span className="truncate" title={formatDate(row.updatedAt)}>
        {formatDate(row.updatedAt)}
      </span>
    ),
  },
];
