import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import Category from 'app/i18n/Category';
import Reports from 'app/i18n/Reports';
import { getLocationName } from 'app/modules/locations/utils/location';
import ReportSKULink from 'app/modules/reports/components/ReportSKULink';
import { getTotalCost, getUnitCost } from 'app/modules/reports/utils';
import { TransactionEntityTypeEnum } from 'app/types/schema';

import ReplacementDateTableNode from '../../../../../assets/components/ReplacementDateTableNode';
import ReorderReportMinMaxQuantity from '../ReorderReportMinMaxQuantity';
import ReorderReportTotalQuantity from '../ReorderReportTotalQuantity';

const SITE_REPORT_TABLE_COLUMNS: DataGridHeadCell[] = [
  {
    id: 'sku',
    label: Reports.SKU,
    value: 'sku',
    valueNode: ({ row }) => <ReportSKULink item={row.item} />,
  },
  {
    id: 'title',
    label: Reports.Title,
    value: 'title',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.title}>
        {row.item?.title}
      </span>
    ),
  },
  {
    id: 'mName',
    label: Reports.Model,
    value: 'mName',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.mName || row.item?.model}>
        {row.item?.mName || row.item?.model}
      </span>
    ),
  },
  {
    id: 'modelNumber',
    label: Reports.ModelNumber,
    value: 'modelNumber',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.modelNumber}>
        {row.item?.modelNumber}
      </span>
    ),
  },
  {
    id: 'category',
    label: Category.Category,
    value: 'category',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.category?.name}>
        {row.item?.category?.name}
      </span>
    ),
  },
  {
    id: 'categoryCode',
    label: Category.FormLabels.CategoryCode,
    value: 'categoryCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.category?.categoryCode}>
        {row.item?.category?.categoryCode}
      </span>
    ),
  },
  {
    id: 'brand',
    label: Reports.Brand,
    value: 'brand',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.item?.brand}>
        {row.item?.brand}
      </span>
    ),
  },
  {
    id: 'site',
    label: Reports.Site,
    value: 'site',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.name}>
        {row.site?.name}
      </span>
    ),
  },
  {
    id: 'siteCode',
    label: Reports.SiteCode,
    value: 'siteCode',
    valueNode: ({ row }) => (
      <span className="truncate" title={row.site?.locationCode}>
        {row.site?.locationCode}
      </span>
    ),
  },
  {
    id: 'location',
    label: Reports.Location,
    value: 'location',
    valueNode: ({ row }) => (
      <span className="truncate" title={getLocationName(row.site?.id, row.location)}>
        {getLocationName(row.site?.id, row.location)}
      </span>
    ),
  },
  {
    id: 'totalQuantity',
    label: Reports.Quantity,
    value: 'totalQuantity',
    valueNode: ({ row }) => <ReorderReportTotalQuantity row={row} />,
    rowAlign: 'right',
  },
  {
    id: 'minimumQuantity',
    label: Reports.MinimumQuantity,
    value: 'minimumQuantity',
    valueNode: ({ row }) => <ReorderReportMinMaxQuantity row={row} valuePath="minimumQuantity" />,
    rowAlign: 'right',
  },
  {
    id: 'maximumQuantity',
    label: Reports.MaximumQuantity,
    value: 'maximumQuantity',
    valueNode: ({ row }) => <ReorderReportMinMaxQuantity row={row} valuePath="maximumQuantity" />,
    rowAlign: 'right',
  },
  {
    id: 'unitCostWithoutOverride',
    label: Reports.UnitCost,
    value: 'unitCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.item?.unitCost)}>
        {getUnitCost(row.item?.unitCost)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'unitCostWithOverride',
    label: Reports.UnitCostWithOverride,
    value: 'unitCostWithOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getUnitCost(row.item?.cost)}>
        {getUnitCost(row.item?.cost)}
      </span>
    ),
    width: 250,
  },
  {
    id: 'totalCostWithoutOverride',
    label: Reports.TotalCostWithoutOverride,
    value: 'totalCostWithoutOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTotalCost(row.item?.unitCost, row.totalQuantity)}>
        {getTotalCost(row.item?.unitCost, row.totalQuantity)}
      </span>
    ),
    width: 280,
  },
  {
    id: 'totalCostWithOverride',
    label: Reports.TotalCostWithOverride,
    value: 'totalCostWithOverride',
    rowAlign: 'right',
    valueNode: ({ row }) => (
      <span className="truncate" title={getTotalCost(row.item?.cost, row.totalQuantity)}>
        {getTotalCost(row.item?.cost, row.totalQuantity)}
      </span>
    ),
    width: 280,
  },
  {
    id: 'replacementDate',
    label: 'Replacement Date',
    value: 'replacementDate',
    valueNode: ({ row }) => (
      <ReplacementDateTableNode replacementDate={row?.item?.replacementDate} />
    ),
  },
];

const ASSET_SPECIFIC_COLUMNS = ['replacementDate', 'modelNumber'];

export const getReorderReportTableColumns = (
  transactionEntityTypes: TransactionEntityTypeEnum[],
): DataGridHeadCell[] => {
  if (
    transactionEntityTypes.includes(TransactionEntityTypeEnum.Asset) &&
    transactionEntityTypes.includes(TransactionEntityTypeEnum.Inventory)
  ) {
    return SITE_REPORT_TABLE_COLUMNS;
  }

  if (transactionEntityTypes.includes(TransactionEntityTypeEnum.Inventory)) {
    return SITE_REPORT_TABLE_COLUMNS.filter(
      (column) => !ASSET_SPECIFIC_COLUMNS.includes(column.id),
    );
  }
  return SITE_REPORT_TABLE_COLUMNS;
};