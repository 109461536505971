import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteInventoryItemsMutationVariables = Types.Exact<{
  input: Types.DeleteItemsInput;
}>;


export type DeleteInventoryItemsMutation = { __typename?: 'Mutation', deleteInventoryItems: { __typename?: 'MutationResponse', success: boolean, reason?: string | null } };


export const DeleteInventoryItemsDocument = gql`
    mutation deleteInventoryItems($input: DeleteItemsInput!) {
  deleteInventoryItems(input: $input) {
    success
    reason
  }
}
    `;

export function useDeleteInventoryItemsMutation() {
  return Urql.useMutation<DeleteInventoryItemsMutation, DeleteInventoryItemsMutationVariables>(DeleteInventoryItemsDocument);
};