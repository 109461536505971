import { nanoid } from 'nanoid';
import { CombinedError, ExchangeInput, getOperationName, makeOperation, Operation } from 'urql';
import {
  filter,
  fromPromise,
  fromValue,
  makeSubject,
  map,
  merge,
  mergeMap,
  pipe,
  share,
  Source,
} from 'wonka';
import currentUser from 'app/modules/auth/utils/currentUser';

import refreshTokens from './refreshTokens';

const STATIC_TOKEN= "p9eTphhFrZkemBmxv2HRkHQrYnNPMW9x";

const isAuthErrorCheck = (error?: CombinedError) => {
  return error?.response?.status === 401;
};

const addTokenToOperation = (operation: Operation) => {
  const { fetchOptions, url } = operation.context;
  const operationName = getOperationName(operation.query);
  const options = typeof fetchOptions === 'function' ? fetchOptions() : fetchOptions || {};
  // @ts-ignore
  const operationUrl = options?.headers?.['x-request-id'] ? url : url + `?op=${operationName}`;

  const noAuthOperations = ['zipCodeLookup']

  let token = `Bearer ${currentUser.at()}`

  if(operationName && noAuthOperations.includes(operationName)){
    token = STATIC_TOKEN;
  } 

  const headers: any = {
    ...options.headers,
    'x-request-id': nanoid(),
    'x-operation-name': operationName,
    Authorization: token,
  };

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    url: operationUrl,
    fetchOptions: { ...options, headers },
  });
};

const authExchange =
  () =>
  ({ forward }: ExchangeInput) => {
    return (ops$: Source<Operation>) => {
      const sharedOps$ = pipe(ops$, share);
      const { source: retry$, next: nextRetryOperation } = makeSubject<Operation>();

      const withToken$ = pipe(
        merge([sharedOps$, retry$]),
        filter((operation) => operation.kind !== 'teardown'),
        mergeMap((operation) => fromValue(addTokenToOperation(operation))),
      );

      const withoutToken$ = pipe(
        merge([sharedOps$, retry$]),
        filter((operation) => operation.kind === 'teardown'),
      );

      return pipe(
        merge([withToken$, withoutToken$]),
        forward,
        share,
        mergeMap((result) => {
          const isAuthError = result.error && isAuthErrorCheck(result.error);

          if (isAuthError) {
            return pipe(
              fromPromise(refreshTokens()),
              map((tokenResult) => {
                if (tokenResult) {
                  nextRetryOperation(result.operation);
                }
                return result;
              }),
            );
          }
          return fromValue(result);
        }),
        filter((result) => {
          if (result.error) {
            return !isAuthErrorCheck(result.error);
          }
          return true;
        }),
      );
    };
  };

export default authExchange;
