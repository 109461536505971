import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TransactionDataQueryVariables = Types.Exact<{
  filters: Types.TransactionFilters;
}>;


export type TransactionDataQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, quantity: number, type: Types.TransactionTypeEnum, dueDate?: string | null, subType: Types.TransactionSubTypeEnum, entityType: Types.TransactionEntityTypeEnum, createdAt: string, startDate?: string | null, endDate?: string | null, meta?: { __typename?: 'TransactionMetaSchema', reason?: string | null, note?: string | null, formattedNote?: string | null } | null, entity?: { __typename?: 'AssetItemSchema', id: string, sku: string, title: string } | { __typename?: 'AssetKitItemSchema', id: string, sku: string, title: string } | { __typename?: 'InventoryItemSchema', id: string, sku: string, title: string, mName?: string | null } | { __typename?: 'InventoryKitItemSchema', id: string, sku: string, title: string } | null, createdBy: { __typename?: 'UserSchema', firstName: string, lastName?: string | null }, sourceSite?: { __typename?: 'LocationSchema', id: string, name: string } | null, sourceLocation?: { __typename?: 'LocationSchema', id: string, name: string } | null, destinationSite?: { __typename?: 'LocationSchema', id: string, name: string } | null, destinationLocation?: { __typename?: 'LocationSchema', id: string, name: string } | null, department?: { __typename?: 'DepartmentSchema', id: string, name: string } | null, actor?: { __typename?: 'UserSchema', id: string, name: string } | null } }> } };


export const TransactionDataDocument = gql`
    query transactionData($filters: TransactionFilters!) {
  transactions(filters: $filters) {
    edges {
      node {
        id
        quantity
        type
        dueDate
        subType
        entityType
        meta {
          reason
          note
          formattedNote
        }
        entity {
          ... on AssetItemSchema {
            id
            sku
            title
          }
          ... on AssetKitItemSchema {
            id
            sku
            title
          }
          ... on InventoryItemSchema {
            id
            sku
            title
            mName
          }
          ... on InventoryKitItemSchema {
            id
            sku
            title
          }
        }
        createdAt
        createdBy {
          firstName
          lastName
        }
        sourceSite {
          id
          name
        }
        sourceLocation {
          id
          name
        }
        destinationSite {
          id
          name
        }
        destinationLocation {
          id
          name
        }
        startDate
        endDate
        department {
          id
          name
        }
        actor {
          id
          name
        }
      }
    }
  }
}
    `;

export function useTransactionDataQuery(options: Omit<Urql.UseQueryArgs<TransactionDataQueryVariables>, 'query'>) {
  return Urql.useQuery<TransactionDataQuery>({ query: TransactionDataDocument, ...options });
};