import {
  composeValidators,
  numberMaxValidator,
  numberMinValidator,
  numberValidator,
  regexValidator,
  textMaxLengthValidator,
  textMinLengthValidator,
  textValidator,
} from '../../../components/Form/utils/validators';
import Assets from '../../../i18n/Assets';
import Inventory from '../../../i18n/Inventory';
import { Maybe } from '../../../types/schema';

export const itemInStockValidator = (itemId?: string) => {
  return {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.ItemInStockInvalid),
      (value?: Maybe<string>) => {
        if (!value || (value && !itemId)) {
          return Inventory.FormValidationMessages.ItemInStockInvalid;
        }
        return true;
      },
    ),
  };
};

/** @deprecated We have updated our logic for pending checkout transaction, so It is deprecated. */
export const availableQuantityValidator = (availableQuantity?: any) => {
  if (availableQuantity !== undefined) {
    const qty = availableQuantity as number;
    if (qty === 0) {
      return {
        validate: composeValidators(
          textValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable),
          numberMinValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable, 1),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable, 0),
        ),
      };
    } else {
      return {
        validate: composeValidators(
          textValidator(Inventory.FormValidationMessages.QuantityInvalid),
          numberValidator(Inventory.FormValidationMessages.QuantityInvalid),
          numberMinValidator(Inventory.FormValidationMessages.QuantityInvalid, 1),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityMaxiMumRequired(qty), qty),
        ),
      };
    }
  }

  return {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Assets.FormValidationMessages.QuantityInvalid, 1),
    ),
  };
};

export const availableQuantityAndTotalQuantityValidator = ({
  availableQuantity,
  totalQuantity,
}: {
  availableQuantity?: any;
  totalQuantity?: any;
}) => {
  if (availableQuantity !== undefined) {
    const qty = availableQuantity as number;
    const totalQty = totalQuantity as number;
    if (totalQty > 0 && qty === 0) {
      return {
        validate: composeValidators(
          textValidator(Inventory.FormValidationMessages.PendingAssetCheckOut),
          numberMinValidator(Inventory.FormValidationMessages.PendingAssetCheckOut, 1),
          numberMaxValidator(Inventory.FormValidationMessages.PendingAssetCheckOut, 0),
        ),
      };
    } else if (qty === 0) {
      return {
        validate: composeValidators(
          textValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable),
          numberMinValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable, 1),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityIsNotAvailable, 0),
        ),
      };
    } else {
      return {
        validate: composeValidators(
          textValidator(Inventory.FormValidationMessages.QuantityInvalid),
          numberValidator(Inventory.FormValidationMessages.QuantityInvalid),
          numberMinValidator(Inventory.FormValidationMessages.QuantityInvalid, 1),
          numberMaxValidator(Inventory.FormValidationMessages.QuantityMaxiMumRequired(qty), qty),
        ),
      };
    }
  }

  return {
    validate: composeValidators(
      textValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberValidator(Inventory.FormValidationMessages.QuantityInvalid),
      numberMinValidator(Assets.FormValidationMessages.QuantityInvalid, 1),
    ),
  };
};

// UPC code or ASIN code validator
const upcCodeNumberValidator = numberValidator(Assets.FormValidationMessages.UpcCodeInvalid);
const asinCodeNumberValidator = regexValidator(
  Assets.FormValidationMessages.UpcCodeInvalid,
  /([A-Z0-9]{10})/,
);

const upcOrAsinCodeValidator = (value?: Maybe<string>) => {
  const upcCodeNumberResponse = upcCodeNumberValidator(value);
  const asinCodeNumberResponse = asinCodeNumberValidator(value);

  if (upcCodeNumberResponse === true || (asinCodeNumberResponse === true && value?.length === 10)) {
    return true;
  }
  return Assets.FormValidationMessages.UpcCodeInvalid;
};

export const upcCodeOrAsinCodeValidator = composeValidators(
  textValidator(Assets.FormValidationMessages.UpcCodeInvalid),
  upcOrAsinCodeValidator,
  textMinLengthValidator(Assets.FormValidationMessages.UpcCodeInvalid, 10),
  textMaxLengthValidator(Assets.FormValidationMessages.UpcCodeInvalid, 14),
);
