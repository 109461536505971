import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import Common from 'app/i18n/Common';

import { FeatureFlagEnum, useFeatureFlag } from '../../../../../../libs/featureFlag';
import { UseBulkOrderRequestReturnType } from '../OrderItemRequest/hook/useBulkOrderRequest';
import { OrderRequestInput } from '../OrderRequestForm/type';
import BulkOrderItemInStockField from './components/BulkOrderItemInStockField';
import BulkOrderProjectField from './components/BulkOrderProjectField';
import BulkOrderQuantityField from './components/BulkOrderQuantityField';
import { ButtonSize } from 'app/types/button';

interface Props {
  state: UseBulkOrderRequestReturnType['bulkOrderState'];
  actions: UseBulkOrderRequestReturnType['bulkOrderActions'];
  onUpdateOrderItem: (index: number, nextState: Partial<OrderRequestInput>) => void;
  disabled?: boolean;
}

const BulkOrderItemForm = (props: Props) => {
  const { state, actions, onUpdateOrderItem, disabled } = props;
  const { fields, isExternalTenant } = state;

  const { value: isProjectFieldEnabled, loading: fetchingProjectFieldFlag } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );

  const isLastField = useCallback(
    (index: number) => {
      return fields.length - 1 === index;
    },
    [fields.length],
  );

  return (
    <>
      {fields.map((item, index) => {
        const disabledValidation = fields.length - 1 === index;
        return (
          <div key={item.id} className="mb-[20px] lg:max-w-[890px] xl:max-w-[890px]">
            <Grid container spacing={2}>
              <Grid item md xs={12}>
                <BulkOrderItemInStockField
                  disabled={disabled}
                  disabledValidation={disabledValidation}
                  index={index}
                  state={state}
                  onUpdateOrderItem={onUpdateOrderItem}
                />
              </Grid>
              {!fetchingProjectFieldFlag && isProjectFieldEnabled ? (
                <Grid item md xs={12}>
                  <BulkOrderProjectField
                    disabled={disabled}
                    index={index}
                    state={state}
                    onUpdateOrderItem={onUpdateOrderItem}
                  />
                </Grid>
              ) : null}
              <Grid item xs="auto">
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item xs="auto">
                    <div className="w-max-[100px] w-[100px]">
                      <BulkOrderQuantityField
                        disabled={disabled}
                        disabledValidation={disabledValidation}
                        index={index}
                        state={state}
                        onUpdateOrderItem={onUpdateOrderItem}
                      />
                    </div>
                  </Grid>
                  <Grid item xs="auto">
                    <div className="pt-[30px]">
                      <Button
                        disabled={isLastField(index)}
                        theme="danger"
                        onClick={() => {
                          actions.onRemoveOrderItems([index]);
                        }}
                        size={ButtonSize.medium}
                        classes="min-w-[94px] h-[48px]">
                        {Common.Actions.Remove}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  );
};

export default BulkOrderItemForm;
