import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AuditReportDetailsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.TransactionFilters>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AuditReportDetailsQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', totalCount: number, edges: Array<{ __typename?: 'TransactionEdge', node: { __typename?: 'TransactionSchema', id: string, createdAt: string, createdBy: { __typename?: 'UserSchema', id: string, name: string } } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, nextCursor?: string | null } | null } };


export const AuditReportDetailsDocument = gql`
    query auditReportDetails($filters: TransactionFilters, $sorts: [SortProps!], $limit: Float, $after: String) {
  transactions(filters: $filters, sorts: $sorts, limit: $limit, after: $after) {
    edges {
      node {
        id
        createdBy {
          id
          name
        }
        createdAt
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      nextCursor
    }
  }
}
    `;

export function useAuditReportDetailsQuery(options?: Omit<Urql.UseQueryArgs<AuditReportDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<AuditReportDetailsQuery>({ query: AuditReportDetailsDocument, ...options });
};