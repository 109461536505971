import AdminLayout from 'app/components/AdminLayout';
import Labels from 'app/modules/labels/views/Labels';

const LabelsPage = () => {
  return (
    <AdminLayout>
      <Labels />
    </AdminLayout>
  );
};

export default LabelsPage;
