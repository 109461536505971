export const ORDER_ITEM_STATUS = {
  BACK_ORDERED: 'back-ordered',
  CANCELLED: 'cancelled',
  CLOSED: 'closed',
  DELIVERED: 'delivered',
  OPEN: 'open',
  OUT_FOR_DELIVERY: 'out-for-delivery',
  PACKED: 'packed',
  RETURNED: 'returned',
};
