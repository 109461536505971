import React, { useEffect } from 'react';
import { Content, CustomIcons } from '@procurenetworks/procure-component-library';
import Network from 'app/i18n/Network';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import Stack from 'app/ui-components/Stack';

import { AllowedPermissionsSubjectEnum, NetworkAssetStatusEnum } from '../../../../types/schema';
import EntityManager from '../../../components/EntityManager';
import NetworkAssetTable from '../networkAssets/components/NetworkAssetTable';
import SelectDisplay from '../networkAssets/components/SelectDisplay';
import useNetworkAssetsState from '../networkAssets/hook/useNetworkAssetsState';
import useSearchSuggestion from '../networkAssets/hook/useSearchSuggestion';
import { Box, InputAdornment } from '@mui/material';

const DiscoveredAssets = (props: RouteComponentProps) => {
  const { filters, table, networkAssetTableState, search, display, memoizedSetDisplay } =
    useNetworkAssetsState(true);

  useEffect(() => {
    filters.setState({
      statuses: [NetworkAssetStatusEnum.Notinstalled],
    });
  }, []);

  const { searchSuggestions, setSearchValue } = useSearchSuggestion(
    [NetworkAssetStatusEnum.Notinstalled],
    false,
  );

  return (
    <EntityManager subject={AllowedPermissionsSubjectEnum.Asset} className="pt-0">
      <EntityManager.Title
        documentTitle={Network.NetworkDiscoveredAssets}
        title={Network.NetworkDiscoveredAssets}
        hasTopDivider
      />
      <Box
        sx={{
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
        }}
        className="mt-[24px] rounded-[12px] border border-grey-300 pb-[24px]">
        <NetworkAssetTable
          persistKey={'network_discovered_asset_table'}
          disableSelection
          discovered
          clickable={true}
          display={display}
          filters={filters}
          history={props.history}
          table={table}
          tableState={networkAssetTableState}
          filterNodes={
            <Box className="flex !w-[100%] flex-wrap gap-[16px] gap-[16px] md:gap-[24px]">
              <EntityManager.Search
                {...search}
                isDropdown
                suggestionData={searchSuggestions || []}
                onChangeSearch={setSearchValue}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start" className="mt-[4px] ml-[5px]">
                    <CustomIcons.SearchIcon />
                  </InputAdornment>
                }
              />
              <SelectDisplay display={display} setDisplay={memoizedSetDisplay} />
            </Box>
          }
        />
      </Box>
    </EntityManager>
  );
};

export default withRouter(DiscoveredAssets);
