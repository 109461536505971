import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateCheckInTransactionMutationVariables = Types.Exact<{
  input: Types.CreateCheckInTransactionInput;
}>;


export type CreateCheckInTransactionMutation = { __typename?: 'Mutation', createCheckInTransaction: { __typename?: 'CreateTransactionsPayload', success: boolean, transactions?: Array<{ __typename?: 'TransactionSchema', id: string, parentTransactionId: string }> | null } };


export const CreateCheckInTransactionDocument = gql`
    mutation createCheckInTransaction($input: CreateCheckInTransactionInput!) {
  createCheckInTransaction(input: $input) {
    success
    transactions {
      id
      parentTransactionId
    }
  }
}
    `;

export function useCreateCheckInTransactionMutation() {
  return Urql.useMutation<CreateCheckInTransactionMutation, CreateCheckInTransactionMutationVariables>(CreateCheckInTransactionDocument);
};