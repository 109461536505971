import { Controller } from 'react-hook-form';
import AssetModel from 'app/i18n/AssetModel';
import FormCategorySelect from 'app/modules/categories/components/FormCategorySelect';

import { AssetModelFieldProps } from '../../type';
import { ASSET_MODEL_FORM_RULES } from '../../utils/utils';

const AssetModelCategoryField = (props: AssetModelFieldProps) => {
  const { formState, disabled } = props;
  const { control } = formState;

  return (
    <div className="mb-24">
      <Controller
        control={control}
        name="categoryId"
        render={({ field, fieldState }) => (
          <FormCategorySelect
            {...field}
            isRequired
            disabled={disabled}
            error={fieldState.error}
            label={AssetModel.FormLabels.Category}
          />
        )}
        rules={ASSET_MODEL_FORM_RULES.categoryId}
      />
    </div>
  );
};

export default AssetModelCategoryField;
