import { useCallback, useMemo } from 'react';
import { cx } from '@emotion/css';
import { TitleBar } from '@procurenetworks/procure-component-library';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import Manufacturer from 'app/i18n/Manufacturers';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import { titlebarStyles } from 'app/modules/components/EntityManager/styles';
import { CreateManufacturerInput } from 'app/types/schema';
import Box from 'app/ui-components';

import ManufacturerForm from '../../components/ManufacturerForm';
import { useUpdateManufacturerMutation } from './graphql/mutations/generated/updateManufacturer';
import { useEditManufacturerQuery } from './graphql/queries/generated/editManufacturer';
import analytics from 'app/analytics';

type Props = RouteComponentProps & { manufacturerId: string };

function EditManufacturer({ manufacturerId, history }: Props) {
  const [{ data }] = useEditManufacturerQuery({
    variables: { filters: { manufacturerIds: [manufacturerId] } },
    requestPolicy: 'network-only',
  });

  const manufacturer: CreateManufacturerInput | null = useMemo(() => {
    const manufacturerNode = data?.manufacturers.edges?.[0]?.node;

    if (manufacturerNode) {
      const { name, url, phoneNo, emailId } = manufacturerNode;
      return { name, url, phoneNo, emailId } as CreateManufacturerInput;
    }

    return null;
  }, [data]);

  const [{ fetching: disabled }, updateManufacturer] = useUpdateManufacturerMutation();

  const onSubmit = useCallback(
    ({ name, url, phoneNo, emailId }: CreateManufacturerInput) => {
      updateManufacturer({ input: { name, url, phoneNo, emailId, manufacturerId } }).then(
        (response) => {
          analytics?.track('Edited', { name: 'Manufacturer' });
          if (response.data?.updateManufacturer?.manufacturer?.id) {
            SnackbarService.show({ message: 'This manufacturer has been successfully updated.' });
            history.replace(routes.Manufacturers());
          }
        },
      );
    },
    [updateManufacturer, manufacturerId, history],
  );

  const onCancel = useCallback(() => {
    history.replace(routes.Manufacturers());
  }, [history]);

  if (!manufacturer) {
    return null;
  }

  return (
    <Box className="bg-white px-24 pt-0 pb-16">
      <Box className={cx('max-w-[382px] space-y-[24px]', titlebarStyles)}>
        <TitleBar title={Manufacturer.EditManufacturer} />
        <ManufacturerForm
          defaultValues={manufacturer}
          disabled={disabled}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}

export default withRouter(EditManufacturer);
