import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import PhoneNumberValueNode from '../../../../components/PhoneNumberValueNode/PhoneNumberValueNode';
import ReadMore from 'app/modules/components/ReadMore';

export const getOrganizationTableColumns = (): DataGridHeadCell[] => {
  return [
    {
      id: 'url',
      label: 'Image',
      labelAlign: 'center',
      rowAlign: 'center',
      value: 'url',
      valueNode: ({ row }: any) => <img alt="" src={row.logo} width="45px" />,
      width: 100,
    },
    {
      id: 'name',
      label: 'Organization',
      sortable: true,
      value: 'name',
      width: 200,
    },
    {
      id: 'tenantCode',
      label: 'Tenant Code',
      value: 'tenantCode',
      width: 150,
    },
    {
      id: 'address',
      label: 'Address',
      value: 'locationDetails.address',
      valueNode: ({ row }: any) => <ReadMore text={row.locationDetails.address} />,
      width: 150,
    },
    {
      id: 'city',
      label: 'City',
      value: 'locationDetails.city',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.locationDetails.city}>
          {row.locationDetails.city}
        </span>
      ),
    },
    {
      id: 'state',
      label: 'State',
      value: 'locationDetails.state',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.locationDetails.state}>
          {row.locationDetails.state}
        </span>
      ),
    },
    {
      id: 'zip_code',
      label: 'Zip',
      value: 'locationDetails.zipCode',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.locationDetails.zipCode}>
          {row.locationDetails.zipCode}
        </span>
      ),
    },
    {
      id: 'primaryPhoneNumber',
      label: 'Phone',
      value: 'primaryPhoneNumber',
      valueNode: ({ row }) => <PhoneNumberValueNode phoneNumber={row.primaryPhoneNumber} />,
    },
    {
      id: 'adminEmailId',
      label: 'Admin',
      value: 'adminEmailId',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.admin?.name}>
          {row.admin?.name}
        </span>
      ),
    },
    {
      id: 'status',
      label: 'Active',
      labelAlign: 'center',
      rowAlign: 'center',
      value: 'status',
      width: 100,
      valueNode: ({ row }: any) => {
        return row.status === 'ACTIVE' ? (
          <i aria-hidden="true" className="fa fa-check green" />
        ) : (
          <i aria-hidden="true" className="fa fa-times red" />
        );
      },
    },
  ];
};
