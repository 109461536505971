import SentryBugReportingService from './sentry';
import { CombinedError } from 'urql';
import { ErrorCodeEnum } from './types';

class BugReportingService {
  static init() {
    SentryBugReportingService.init();
  }

  static setUser(user: { userId?: string; tenantId?: string } | null) {
    SentryBugReportingService.setUser(user);
  }

  static reportError(error: unknown) {
    SentryBugReportingService.reportError(error);
  }

  static reportGraphqlError(error: CombinedError) {
    const graphQLError = error.graphQLErrors.find((error) => {
      const errorCode = error.extensions.errorCode;
      return (
        errorCode === ErrorCodeEnum.INTERNAL_SERVER_ERROR ||
        errorCode === ErrorCodeEnum.RESOURCE_NOT_FOUND
      );
    });
    if (graphQLError) {
      SentryBugReportingService.reportError(new Error(graphQLError.extensions.debugMessage || ''), {
        extra: graphQLError.extensions,
      });
    }
  }

  static reportHttpError(
    error: unknown,
    options?: {
      extra?: any;
    },
  ) {
    SentryBugReportingService.reportError(error, {
      extra: options?.extra,
    });
  }
}

export default BugReportingService;
