import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import LoginHeader from 'app/modules/auth/views/Login/components/LoginHeader';
import ShareListView from 'app/modules/wishlist/views/createList/components/ShareListView';

function WishlistSharePage(props: RouteComponentProps<{ id: string }>) {
  const { match } = props;
  const id = match.params.id;

  return (
    <>
      <LoginHeader />
      <div className="flex h-screen justify-center">
        <div className="w-[1200px] p-12 min-w-[120px]">
          <ShareListView shareId={id} />
        </div>
      </div>
    </>
  );
}

export default withRouter(WishlistSharePage);
