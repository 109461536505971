import AdminLayout from 'app/components/AdminLayout';
import BulkImport from 'app/modules/imports/views/BulkImport';

function ImportsPage() {
  return (
    <AdminLayout>
      <BulkImport />
    </AdminLayout>
  );
}

export default ImportsPage;
