import { isAccessible } from '../../../components/AccessControl';
import { AllowedPermissionActionsEnum, AllowedPermissionsSubjectEnum } from '../../../types/schema';
import { UserProfileFragment } from '../../auth/graphql/fragments/generated/UserProfile';

export const getReceivingTabs = (permissions: UserProfileFragment['workspacePermissions']) =>
  [
    {
      label: 'Schedule',
      value: 'schedule',
      subject: AllowedPermissionsSubjectEnum.Receivable,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Receive',
      value: 'receive',
      subject: AllowedPermissionsSubjectEnum.Receivable,
      action: AllowedPermissionActionsEnum.Access,
    },
    {
      label: 'Receive History',
      value: 'history',
      subject: AllowedPermissionsSubjectEnum.Receivable,
      action: AllowedPermissionActionsEnum.Access,
    },
  ].filter((tab) => {
    return isAccessible(permissions, tab.action, tab.subject);
  });
