import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';

import FormLabel from '../ProcureForm/FormLabel';
import { AsyncSelectOption, AsyncSingleSelectProps } from './types';

const getOptionLabel = (option: AsyncSelectOption) => option.label;
const getOptionValue = (option: AsyncSelectOption) => option.value;

const AsyncSingleSelect = (props: AsyncSingleSelectProps) => {
  const {
    queryVariables,
    useSelectedValue,
    useSelectQuery,
    placeholder = '',
    value: valueProp,
    onBlur,
    onChange,
    disabled,
    size,
    label,
    isRequired,
    error,
    formLabel,
    name
  } = props;

  const value = valueProp || '';
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<AsyncSelectOption | null>(null);

  const { isDisabled, selectedValue: updatedSelectedValue } = useSelectedValue({
    value: value || '',
    pause: !value || Boolean(selectedValue && selectedValue?.value === value),
    isMultiple: false,
  });

  useEffect(() => {
    if (updatedSelectedValue) {
      setSelectedValue(updatedSelectedValue);
    }
  }, [updatedSelectedValue]);

  useEffect(() => {
    if (!value) {
      setSelectedValue(null);
    }
  }, [value]);

  const { isLoading, options } = useSelectQuery({ inputValue, ...queryVariables });

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, option: AsyncSelectOption) => {
      setSelectedValue(option as AsyncSelectOption);
      onChange?.(option?.value || '', option as AsyncSelectOption);
    },
    [onChange],
  );
  const onInputValueChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const LabelNode = label
    ? () => <FormLabel isRequired={!!isRequired}>{label || ''}</FormLabel>
    : undefined;

  return (
    <>
      {formLabel ? (
        <FormLabel className="text-[14px] font-medium text-grey-800" isRequired={isRequired}>
          {formLabel}
        </FormLabel>
      ) : null}
    <Autocomplete
      className="mt-6 rounded"
      disabled={disabled || isDisabled}
      getOptionLabel={getOptionLabel}
      label={''}
      loading={isLoading}
      options={options}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          labelNode={LabelNode}
          placeholder={placeholder}
          error={Boolean(error)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      size={size}
      value={selectedValue}
      onBlur={onBlur}
      onChange={onValueChange}
      onInputChange={onInputValueChange}
    />
  </>);
};

export default AsyncSingleSelect;
