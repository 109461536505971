import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InvitePartnerTenantMutationVariables = Types.Exact<{
  input: Types.InvitePartnerTenantInput;
}>;


export type InvitePartnerTenantMutation = { __typename?: 'Mutation', invitePartnerTenant: { __typename?: 'InvitePartnerTenantPayload', success: boolean } };


export const InvitePartnerTenantDocument = gql`
    mutation InvitePartnerTenant($input: InvitePartnerTenantInput!) {
  invitePartnerTenant(input: $input) {
    success
  }
}
    `;

export function useInvitePartnerTenantMutation() {
  return Urql.useMutation<InvitePartnerTenantMutation, InvitePartnerTenantMutationVariables>(InvitePartnerTenantDocument);
};