import { WarehouseLocationsProps } from '../../types';
import WarehouseAisles from '../WarehouseAisles';
import WarehouseBins from '../WarehouseBins';
import WarehouseRacks from '../WarehouseRacks';
import WarehouseShelves from '../WarehouseShelves';
import WarehouseZones from '../WarehouseZones';
import { WarehouseTypeEnum } from 'app/types/schema';

const WarehouseLocations = (props: WarehouseLocationsProps) => {
  const { selectedItems, warehouseType } = props;

  if (warehouseType === WarehouseTypeEnum.RackAndShelf) {
    if (selectedItems.length === 1) {
      return <WarehouseShelves {...props} />;
    }
    return <WarehouseRacks {...props} />;
  }

  if (selectedItems.length === 4) {
    return <WarehouseBins {...props} />;
  }

  if (selectedItems.length === 3) {
    return <WarehouseShelves {...props} />;
  }

  if (selectedItems.length === 2) {
    return <WarehouseRacks {...props} />;
  }

  if (selectedItems.length === 1) {
    return <WarehouseAisles {...props} />;
  }

  return <WarehouseZones {...props} />;
};

export default WarehouseLocations;
