import React, { useCallback, useEffect, useState } from 'react';
import { cx } from '@emotion/css';
import { SearchBar } from '@procurenetworks/procure-component-library';
import { isEmpty } from 'lodash';
import SearchDropDown from 'app/modules/shop/components/searchHelper';
import useShop from 'app/modules/shop/context/useShop';
import Box from 'app/ui-components';
import {
  getPageSettingsFromStorage,
  savePageSettingsToStorage,
} from './../../../../../../../app/utils/paginationSettingsUtil';

import useUpcSearchSuggestion from '../../hook/useShopSearchSuggestion';
import ChipList from './components/ChipList';
import { Form } from './styles';

const SearchOptions = [
  {
    label: 'Product Name',
    value: 'name',
  },
  {
    label: 'ISBN',
    value: 'isbn',
  },
  {
    label: 'UPC/ Product Code',
    value: 'code',
  },
  {
    label: 'Model / Model Number',
    validationRegex: /^\d*$/,
    value: 'model',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
];

interface QueryVariables {
  brand?: string;
  code?: string;
  isbn?: string;
  model?: string;
  name?: string;
}
interface Props {
  setIsShowProducts: (value: boolean) => void;
}

const SearchBox: React.FC<Props> = ({ setIsShowProducts }) => {
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState(false);

  const [chipData, setChipData] = useState([
    { key: 0, label: 'Name', val: '' },
    { key: 1, label: 'Model', val: '' },
    { key: 2, label: 'Brand', val: '' },
    { key: 3, label: 'ISBN', val: '' },
  ]);

  const {
    search,
    searchQuery,
    checked,
    numberOfRowsPerPage,
    setState,
    setChecked,
    getProducts,
    getQuery,
  } = useShop();

  const { suggestionData, getProductsForSuggestion } = useUpcSearchSuggestion();
  const [searchBarFocused, setSearchBarFocused] = useState(false);

  const _handleChange = useCallback(
    (
      _response: any,
      event:
        | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      handleFocus();
      const inputValue = (event as any).target.value;
      if (inputValue.length < 28) {
        setValue(inputValue);
        setState({ search: inputValue, numberOfRowsPerPage: 10 });
        const searchData: any = { ...searchQuery };
        if (checked) {
          searchData[checked] = inputValue;
          setState && setState({ searchQuery: searchData });
        }
      }
    },
    [setState, searchQuery, checked],
  );

  useEffect(() => {
    getProductsForSuggestion(searchQuery);
  }, [searchQuery]);

  const searchQueries = (query: any) => {
    const _query = { ...query };
    const data: any[] = Object.entries(_query).map((item, index) => {
      const key = item[0].toLowerCase();
      return {
        key: index,
        label: key,
        val: item[1],
      };
    });

    setChipData(data);
  };

  const onSearch = useCallback(async () => {
    const variables: QueryVariables = getQuery(search, 0, numberOfRowsPerPage);
    if (isEmpty(variables)) {
      setState({
        searchQuery: {
          brand: '',
          code: '',
          isbn: '',
          model: '',
          name: '',
        },
      });
    }
    await getProducts({ ...variables, code: variables?.code?.replace(/\s/g, '') });
    setIsShowProducts(true);
    setState({ activePage: 0 });
  }, [getProducts, search, setIsShowProducts, checked, searchQuery]);

  const handleDropdownClick = useCallback(
    (selectedValue: string) => {
      // Read rows per page from session storage if available.
      let rowsPerPage = getPageSettingsFromStorage(10);

      if (selectedValue) {
        setValue(selectedValue);
        setSelectedValue(selectedValue);
        setState({ search: selectedValue, numberOfRowsPerPage: rowsPerPage });
        const searchData: any = { ...searchQuery };
        if (checked) {
          searchData[checked] = selectedValue;
          setState && setState({ searchQuery: searchData });
        }
        setSearchBarFocused(false);
        searchQueries(searchData);
        onSearch();
      }
    },
    [setState, searchQuery, checked, onSearch],
  );

  const onSearchClean = useCallback(
    async (_data: any) => {
      const query = { ..._data };

      if (Object.values(query).filter((item) => item).length > 0) {
        const variables = getQuery(query, 0, numberOfRowsPerPage);

        await getProducts(variables);

        setIsShowProducts(true);
      } else {
        setIsShowProducts(false);
      }
      setState({ activePage: 0 });
    },
    [searchQuery],
  );

  const handleOnCleanInput = () => {
    setValue('');
    setSelectedValue('');
    setError(false);
    if (checked) {
      const deleteData: any = { ...searchQuery };
      deleteData[checked] = '';
      setState && setState({ searchQuery: deleteData });
      onSearchClean(deleteData);
      searchQueries(deleteData);
    }
  };

  const onOptionChange = (option: string) => {
    if (checked && setState) {
      const searchData: any = { ...searchQuery };
      Object.keys(searchData).forEach((item) => {
        const itemValue = searchData[checked];
        if (itemValue) {
          searchData[item] = '';
          searchData[option] = itemValue;
        }
      });
      setState({ searchQuery: searchData });
    }
    setChecked(option);
  };

  const handleBlur = (event: any) => {
    const { currentTarget, relatedTarget } = event;
    if (relatedTarget && currentTarget.contains(relatedTarget)) {
      return;
    }
    setSearchBarFocused(false);
  };

  useEffect(() => {
    if (!selectedValue) {
      handleOnCleanInput();
    } else {
      onSearch();
    }
  }, [selectedValue]);

  const handleFocus = () => {
    setSearchBarFocused(true);
  };

  return (
    <>
      <Box
        className={'max-w-[652px]'}
        onBlur={handleBlur}
        onFocus={() => {
          handleFocus();
        }}>
        <SearchBar
          cleanInputIcon
          withSearchOptions
          className={cx(Form)}
          disabled={false}
          label="Search Supplier"
          placeholder="Search for brand or product using keywords or UPC code"
          searchOptions={SearchOptions}
          selectedOption={checked}
          value={value}
          onChange={_handleChange}
          onCleanInputIconClick={handleOnCleanInput}
          onSearchIconClick={() => {
            if (error) {
              return;
            }
            if (value) {
              if (checked && value) {
                const searchData: any = { ...searchQuery };
                searchQueries(searchData);
              }
              onSearch();
            }
            setError(false);
          }}
          onSearchOptionChange={onOptionChange}
        />
        <SearchDropDown
          data={suggestionData}
          loading={false}
          searchValue={value}
          visible={checked === 'name' && searchBarFocused}
          onSearchHelperItemClick={(SelectedValue) => {
            handleDropdownClick(SelectedValue);
          }}
        />
      </Box>
      <ChipList
        chipData={chipData}
        setChipData={setChipData}
        setError={setError}
        setValue={setValue}
        value={value}
        onSearchModal={onSearchClean}
      />
    </>
  );
};

export default SearchBox;
