import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

export const track = (
  event_name: string,
  properties?: Dict,
  optionsOrCallback?: RequestOptions | Callback,
  callback?: Callback,
) => {
  mixpanel?.track(event_name, properties, optionsOrCallback, callback);
};

export const identify = (id: string) => {
  mixpanel?.identify(id);
};

export const setUserProperties = (prop: string, to: any, callback?: Callback) => {
  mixpanel?.people?.set(prop, to, callback);
};

export const timeEvent = (eventName: string) => {
  mixpanel?.time_event(eventName);
};

export const optIn = () => {
  mixpanel?.opt_in_tracking();
};

export const optOut = () => {
  mixpanel?.opt_out_tracking();
};

export const startSessionRecording = () => {
  mixpanel?.start_session_recording();
};

export const stopSessionRecording = () => {
  mixpanel?.stop_session_recording();
};

export default {
  track,
  identify,
  setUserProperties,
  optIn,
  optOut,
  timeEvent,
  startSessionRecording,
  stopSessionRecording
};
