import { DataGridHeadCell } from '@procurenetworks/procure-component-library';
import { capitalize } from 'lodash';
import UsersTableFilter from 'app/modules/users/components/UsersTableFilter';

import Organization from '../../../../../i18n/Organization';
import { RoleTypeFilterEnum } from '../../../../../types/schema';
import { LabelNodeProps } from '../../../../inventory/components/BrandLabelNode';
import TenantsTableFilter from '../../../../organizations/components/TenantsTableFilter';
import { FilterState } from '../types';

const OrganizationLabelNode = (props: LabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filter, setFilter } = extraProps as {
    filter: FilterState;
    setFilter: (nextState: Partial<FilterState>) => void;
  };

  return (
    <div className="w-[90%]">
      <TenantsTableFilter
        name="partner-oragnization-filter"
        value={filter.tenantIds}
        placeholder={Organization.FormLabels.Organization}
        queryVariables={{
          partnersOnly: true,
        }}
        onChange={(values) => {
          setFilter({
            tenantIds: values,
          });
        }}
      />
    </div>
  );
};

const UserLabelNode = (props: LabelNodeProps) => {
  const { state } = props;
  const { extraProps } = state;
  const { filter, setFilter } = extraProps as {
    filter: FilterState;
    setFilter: (nextState: Partial<FilterState>) => void;
  };

  return (
    <div className="w-[90%]">
      <UsersTableFilter
        key="user-table-filter"
        name="User"
        placeholder="Users"
        queryVariables={{
          roleTypes: [RoleTypeFilterEnum.ExternalUser],
          includingPartnerTenantExternalUsers: true,
        }}
        value={filter.userIds}
        onChange={(values: string[]) => {
          setFilter({
            userIds: values,
          });
        }}
      />
    </div>
  );
};

export const getPartnerTenantTableColumns = (): DataGridHeadCell[] => {
  return [
    {
      id: 'url',
      label: 'Image',
      labelAlign: 'center',
      rowAlign: 'center',
      value: 'url',
      valueNode: ({ row }: any) => <img alt="" src={row.tenant.logo} width="45px" />,
    },
    {
      id: 'name',
      label: 'Organization',
      labelNode: OrganizationLabelNode,
      value: 'tenant',
      valueNode: ({ row }: any) => (
        <span className="truncate" title={row.tenant.name}>
          {row.tenant.name}
        </span>
      ),
    },
    {
      id: 'partnerType',
      label: 'Partner Type',
      value: 'partnerType',
      valueNode: ({ row }) => (
        <span className="truncate" title={capitalize(row.partnerType)}>
          {capitalize(row.partnerType)}
        </span>
      ),
    },
    {
      id: 'users',
      label: 'Users',
      labelNode: UserLabelNode,
      value: 'users',
      valueNode: ({ row }) => {
        const users = row.allowedUsers;
        const userNames = users?.length ? users.map((user: any) => user.name).join(', ') : '-';
        return (
          <span className="truncate" title={userNames}>
            {userNames}
          </span>
        );
      },
    },
    {
      id: 'categories',
      label: 'Categories',
      value: 'categories',
      valueNode: ({ row }) => {
        const categories = row.accessibleCategories;
        const categoriesNames = categories?.length
          ? categories.map((category: any) => category.name).join(', ')
          : '-';
        return (
          <span className="truncate" title={categoriesNames}>
            {categoriesNames}
          </span>
        );
      },
    },
  ];
};
