import _ from 'lodash';

import { OrderRequestInput } from '../../../OrderRequestForm/type';

export function updateItem(
  orderItems: OrderRequestInput[],
  orderItemIndex: number,
  nextState: Partial<OrderRequestInput>,
) {
  return _.map(orderItems, (orderItem, index) => {
    if (index === orderItemIndex) {
      return getUpdatedOrderItem(orderItem, nextState);
    }
    return orderItem;
  });
}

export function getUpdatedOrderItem(
  orderItem: OrderRequestInput,
  nextState: Partial<OrderRequestInput>,
) {
  return _.cloneDeep({
    ...orderItem,
    ...nextState,
  });
}

export function getItemByIndex(orderItems: OrderRequestInput[], index: number) {
  return orderItems[index];
}
