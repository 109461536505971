import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
import * as Urql from 'app/libs/urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FormRestockKitLocationSelectQueryVariables = Types.Exact<{
  filters: Types.ItemLocationFilters;
  limit?: Types.InputMaybe<Types.Scalars['Float']>;
  sorts?: Types.InputMaybe<Array<Types.SortProps> | Types.SortProps>;
}>;


export type FormRestockKitLocationSelectQuery = { __typename?: 'Query', itemlocations: { __typename?: 'ItemLocationConnection', edges: Array<{ __typename?: 'ItemLocationEdge', node: { __typename?: 'ItemLocationSchema', id: string, site: { __typename?: 'LocationSchema', id: string, name: string }, location: { __typename?: 'LocationSchema', id: string, name: string } } }> } };


export const FormRestockKitLocationSelectDocument = gql`
    query formRestockKitLocationSelect($filters: ItemLocationFilters!, $limit: Float, $sorts: [SortProps!]) {
  itemlocations(filters: $filters, limit: $limit, sorts: $sorts) {
    edges {
      node {
        id
        site {
          id
          name
        }
        location {
          id
          name
        }
      }
    }
  }
}
    `;

export function useFormRestockKitLocationSelectQuery(options: Omit<Urql.UseQueryArgs<FormRestockKitLocationSelectQueryVariables>, 'query'>) {
  return Urql.useQuery<FormRestockKitLocationSelectQuery>({ query: FormRestockKitLocationSelectDocument, ...options });
};