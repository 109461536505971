import { useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import usePagination from 'app/hooks/usePagination';
import useMultiSelectEntityManager from 'app/modules/components/EntityManager/useMultiSelectEntityManager';
import { SortOrderEnum, UserStatusEnum } from 'app/types/schema';

import { useDeletedUsersTableQuery } from '../../graphql/queries/generated/deletedUsersTable';
import useDeletedItemsState from '../../../undelete/hook/useDeletedItemsState';

const useDeletedUsersTable = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const { search, table } = useMultiSelectEntityManager({ hideActions: true });
  const { filters} = useDeletedItemsState();

  const { fetching, data, onNextPage, onPrevPage, onReset } = usePagination(
    useDeletedUsersTableQuery,
    {
      filters: {
        search: search.debouncedSearchText,
        statuses: [UserStatusEnum.Deleted],
      },
      sorts: table.state.sorts.length
        ? table.state.sorts
        : [
          {
            sortField: 'deletedAt',
            sortOrder: SortOrderEnum.Desc,
          },
        ],
    },
    { edgeKey: 'users', pageSize: table.state.numberOfRowsPerPage },
  );
  const rows = useMemo(() => {
    const row = data?.users.edges?.map(({ node }) => node) || [];
    setTableData(uniqBy([...tableData, ...row], (row) => row.id));
    return row;
  }, [data, onNextPage, onPrevPage]);

  useEffect(() => {
    const row = tableData.filter((row) => table.state.selectedRowIds.includes(row.id));
    table.setState({ selectedRowData: row });
  }, [table.state.selectedRowIds]);

  return {
    rows,
    search,
    table,
    data,
    fetching,
    onNextPage,
    onPrevPage,
    onReset,
    filters
  };
};
export type UseDeletedUsersTableReturnType = ReturnType<typeof useDeletedUsersTable>;
export default useDeletedUsersTable;
