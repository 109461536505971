import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDeleteAssetKitItemMutation } from 'app/modules/assets/graphql/mutations/generated/deleteAssetKitItem';
import { onOperationComplete } from 'app/modules/assetsInventory/utils/utils';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

import { useAccessControl } from '../../../../../../components/AccessControl';
import Assets from '../../../../../../i18n/Assets';
import {
  AllowedPermissionActionsEnum,
  AllowedPermissionsSubjectEnum,
  ItemTypeEnum,
  LocationStatusEnum,
} from '../../../../../../types/schema';
import useMultiSelectEntityManager from '../../../../../components/EntityManager/useMultiSelectEntityManager';
import { useDeleteAssetItemsMutation } from '../../../../graphql/mutations/generated/deleteAssetItems';
import useAssetTableRows, { AssetFilterState } from '../useAssetTableRows';
import analytics from 'app/analytics';

interface ImageState {
  imageUrl: string;
  open: boolean;
}

interface BulkMoveState {
  open: boolean;
}

export interface Props {
  parentId: string;
}

const useSearchAssetsState = (props: Props) => {
  const { parentId } = props;

  const { defaultSite, workspacePermissions } = useCurrentUser();
  const defaultSiteId =
    defaultSite?.status === LocationStatusEnum.Deleted ? 'none' : defaultSite?.id;

  const canDelete = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Delete,
    AllowedPermissionsSubjectEnum.Asset,
  );

  const canBulkMove = useAccessControl(
    workspacePermissions,
    AllowedPermissionActionsEnum.Access,
    AllowedPermissionsSubjectEnum.AssetMove,
  );

  const [filterState, _setFilterState] = useState<AssetFilterState>({
    categoryId: '',
    siteId: defaultSiteId || 'none',
    companyId: '',
    brands: [],
    mNames: [],
    modelNumbers: [],
  });

  const [imagePreviewState, _setImagePreviewState] = useState<ImageState>({
    open: false,
    imageUrl: '',
  });

  const [bulkMoveState, _setBulkMoveState] = useState<BulkMoveState>({
    open: false,
  });

  const [bulkEditState, _setBulkEditState] = useState<BulkMoveState>({
    open: false,
  });

  const setBulkMoveState = useCallback((nextState: Partial<BulkMoveState>) => {
    _setBulkMoveState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const setBulkEditState = useCallback((nextState: Partial<BulkMoveState>) => {
    _setBulkEditState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const setImagePreviewState = useCallback((nextState: Partial<ImageState>) => {
    _setImagePreviewState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const setState = useCallback((nextState: Partial<AssetFilterState>) => {
    _setFilterState((prevState) => ({ ...prevState, ...nextState }));
  }, []);

  const [{ fetching: deletingAssetItems }, executeDeleteAssetItems] = useDeleteAssetItemsMutation();
  const [{ fetching: deletingAssetKitItem }, executeDeleteAssetKitItem] =
    useDeleteAssetKitItemMutation();

  const onDelete = useCallback(
    (ids: string[], items?: any[]) => {
      const _itemData = items ? items[0] : {};
      if (_itemData && _itemData.type === ItemTypeEnum.AssetKit) {
        executeDeleteAssetKitItem({
          input: {
            assetKitItemId: ids[0],
          },
        })
          .then((response) => {
            analytics?.track('Deleted', { name: 'Asset Kit' });
            onOperationComplete({
              response,
              message: Assets.SuccessMessages.AssetKitDeleted,
              error: '[Delete Asset Kit] Failed',
              operation: 'deleteAssetKitItem',
            });
          })
          .catch((e) => {
            console.log('[Delete Asset Kit] Failed', e);
          });
      } else {
        executeDeleteAssetItems({
          input: {
            itemIds: ids || [],
          },
        })
          .then((response) => {
            onOperationComplete({
              response,
              message: Assets.SuccessMessages.AssetDeleted,
              error: '[Delete Asset] Failed',
              operation: 'deleteAssetItems',
            });
          })
          .catch((e) => {
            console.log('[Delete Asset] Failed', e);
          });
      }
    },
    [executeDeleteAssetItems],
  );

  const { search, table } = useMultiSelectEntityManager({
    onDelete,
    selection: canDelete || canBulkMove,
    hideActions: true,
  });

  const siteId =
    filterState.siteId && filterState.siteId !== 'none' ? filterState.siteId : undefined;

  const isSiteIdSelected = useMemo(() => {
    return !!siteId;
  }, [filterState.siteId]);
  const { companyId, categoryId, brands, mNames, modelNumbers } = filterState;
  const categoryIds = categoryId ? [categoryId] : [];
  const siteIds = isSiteIdSelected && siteId ? [siteId] : [];
  const vendorIds = companyId ? [companyId] : [];

  const tableState = useAssetTableRows({
    siteId: isSiteIdSelected ? siteId : undefined,
    sorts: table.state.sorts,
    numberOfRowsPerPage: table.state.numberOfRowsPerPage,
    filters: {
      search: search.debouncedSearchText,
      categoryIds,
      siteIds,
      vendorIds,
      types: [
        ItemTypeEnum.Asset,
        ItemTypeEnum.AssetKit,
        // ItemTypeEnum.AssetModel
      ],
      brands,
      mNames,
      modelNumbers,
      parentIds: parentId ? [parentId] : [],
    },
  });

  useEffect(() => {
    search.onChange('');
  }, [parentId]);

  const onCloseImageViewDialog = useCallback(() => {
    setImagePreviewState({
      open: false,
    });
  }, [setImagePreviewState]);

  const showImageViewDialog = useCallback(
    (image: string) => {
      setImagePreviewState({
        imageUrl: image,
        open: true,
      });
    },
    [setImagePreviewState],
  );

  const onBulkMove = useCallback(() => {
    setBulkMoveState({
      open: true,
    });
  }, [setBulkMoveState]);

  useEffect(() => {
    table?.actions?.onResetSelectedRow();
  }, [filterState.siteId]);

  return {
    search,
    table,
    tableState,
    // itemLocationTableState,
    deletingAssetItems,
    deletingAssetKitItem,
    onDelete,
    filters: {
      state: filterState,
      setState,
    },
    imagePreviewState,
    setImagePreviewState,
    showImageViewDialog,
    onCloseImageViewDialog,
    isSiteIdSelected,
    onBulkMove,
    bulkMoveState: {
      open: bulkMoveState.open,
      setBulkMoveState,
    },
    bulkEditState: {
      open: bulkEditState.open,
      setBulkEditState,
    },
    siteId,
  };
};

export type UseSearchAssetsStateReturnType = ReturnType<typeof useSearchAssetsState>;

export default useSearchAssetsState;
