import { useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarService } from 'app/components/Snackbar';
import routes from 'app/consts/routes';
import { useUserFilterQuery } from 'app/modules/inventory/graphql/queries/generated/userFilter';
import useLocations from 'app/modules/sites/hook/useLocations';

import usePagination from '../../../../../../hooks/usePagination';
import Assets from '../../../../../../i18n/Assets';
import {
  LocationTypeEnum,
  SortOrderEnum,
  TransactionEntityTypeEnum,
  TransactionSchema,
  TransactionStatusEnum,
  TransactionSubTypeEnum,
  TransactionTypeEnum,
} from '../../../../../../types/schema';
import useEntityManager from '../../../../../components/EntityManager/useEntityManager';
import { useUnreserveTransactionsMutation } from '../../graphql/mutations/generated/unreserveTransactions';
import { useReserveTransactionsQuery } from '../../graphql/query/generated/reservedTransaction';
import { CHECKOUT_RESERVED_ASSET } from '../../types';

export type ReservedAssetRow = TransactionSchema;

export enum TransactionActionType {
  EDIT = 'EDIT',
  CHECKOUT = 'CHECKOUT',
  UNRESERVE = 'UNRESERVE',
}

type TransactionData = {
  open: boolean;
  transaction: ReservedAssetRow | null;
  message: string | null;
  actionType: TransactionActionType | null;
};

interface FilterState {
  destinationSiteId: string;
  actorId: string;
  departmentId: string;
}

const useAssetReservedState = ({ history }: { history: any }) => {
  const { table, search } = useEntityManager({
    selection: false,
  });

  const [rows, setRows] = useState<ReservedAssetRow[]>([]);
  const [transactionData, setTransactionData] = useState<TransactionData>({
    open: false,
    transaction: null,
    message: null,
    actionType: null,
  });

  const [filterState, _setFilterState] = useState<FilterState>({
    destinationSiteId: '-1',
    actorId: '-1',
    departmentId: '-1',
  });

  const setFilterState = useCallback(
    (nextState: Partial<FilterState>) => {
      _setFilterState((prevState) => ({ ...prevState, ...nextState }));
    },
    [_setFilterState],
  );

  const { fetching, data, onPrevPage, onNextPage, onReset } = usePagination(
    useReserveTransactionsQuery,
    {
      filters: {
        types: [TransactionTypeEnum.Reserve],
        subTypes: [TransactionSubTypeEnum.Reserve],
        statuses: [
          TransactionStatusEnum.Pending,
          TransactionStatusEnum.Reserved,
          TransactionStatusEnum.InCustody,
          TransactionStatusEnum.CheckedOut,
        ],
        entityTypes: [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit],
        destinationSiteIds:
          filterState.destinationSiteId !== '-1' ? [filterState.destinationSiteId] : [],
        actorIds: filterState.actorId !== '-1' ? [filterState.actorId] : [],
        departmentIds: filterState.departmentId !== '-1' ? [filterState.departmentId] : [],
      },
      sorts: table.state.sorts,
    },
    {
      edgeKey: 'transactions',
      pageSize: table.state.numberOfRowsPerPage,
      requestPolicy: 'network-only',
    },
  );

  const [{ fetching: isUnReservingTransaction }, onUnReserveTransaction] =
    useUnreserveTransactionsMutation();

  useEffect(() => {
    const rows: any = data?.transactions?.edges?.map?.((edge) => edge.node) || [];
    setRows(rows);
  }, [data?.transactions?.edges, setRows]);

  const { items: siteItems } = useLocations({
    variables: {
      filters: {
        search: '',
        types: [LocationTypeEnum.Site, LocationTypeEnum.PartnerTenant],
      },
      sorts: [
        {
          sortField: 'name',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const siteOptions = useMemo(() => {
    return [
      {
        id: '-1',
        name: Assets.FormLabels.All,
      },
      ...siteItems,
    ];
  }, [siteItems]);

  // User Filter
  const [userResult] = useUserFilterQuery({
    variables: {
      sorts: [
        {
          sortField: 'firstName',
          sortOrder: SortOrderEnum.Asc,
        },
        {
          sortField: 'lastName',
          sortOrder: SortOrderEnum.Asc,
        },
      ],
      limit: 10000,
    },
  });

  const userOptions = useMemo(() => {
    return [
      {
        id: '-1',
        name: Assets.FormLabels.All,
      },
      ...(userResult?.data?.users.edges || []).map(({ node }) => {
        return {
          id: node.id,
          name: node.name,
        };
      }),
    ];
  }, [userResult?.data?.users.edges]);

  const onConfirmTransactionAction = useCallback(
    (transactionId: string, actionType: TransactionActionType) => {
      const transaction = rows.find((row) => row.id === transactionId);

      if (!transaction) {
        return;
      }

      if (actionType === TransactionActionType.CHECKOUT) {
        localStorage.setItem(CHECKOUT_RESERVED_ASSET, JSON.stringify(transaction));
        history.push(routes.CheckOutAssets());
      } else if (actionType === TransactionActionType.UNRESERVE) {
        setTransactionData({
          open: true,
          actionType: TransactionActionType.UNRESERVE,
          transaction: transaction,
          message: Assets.FormValidationMessages.UnreserveConfirmation,
        });
      } else {
        setTransactionData({
          open: true,
          actionType: TransactionActionType.EDIT,
          transaction: transaction,
          message: null,
        });
      }
    },
    [rows, setTransactionData],
  );

  const onCancelTransaction = useCallback(() => {
    setTransactionData({
      open: false,
      transaction: null,
      message: null,
      actionType: null,
    });
  }, [setTransactionData]);

  const handleUnReserveTransaction = useCallback(() => {
    if (transactionData?.transaction?.id) {
      onUnReserveTransaction({
        input: { transactionIds: [transactionData?.transaction?.id] },
      }).then((response) => {
        if (response?.data?.unreserveTransactions?.success) {
          SnackbarService.show({
            message: Assets.SuccessMessages.UnreserveTransactionCreated,
          });
        } else {
          console.error('[Error in handleUnReserveTransaction]', response);
        }
        onCancelTransaction();
      });
    } else {
      onCancelTransaction();
    }
  }, [onUnReserveTransaction, transactionData, onCancelTransaction]);

  return {
    table,
    rows,
    rowCount: data?.transactions?.totalCount || 0,
    search,
    fetching,
    onPrevPage,
    onNextPage,
    onReset,
    isUnReservingTransaction,
    handleUnReserveTransaction,
    transactionData,
    onConfirmTransactionAction,
    onCancelTransaction,
    filterState,
    setFilterState,
    userOptions,
    siteOptions,
  };
};

export type useAssetReservedStateReturnType = ReturnType<typeof useAssetReservedState>;
export default useAssetReservedState;
