import { Button } from '@procurenetworks/procure-component-library';
import SignUp from 'app/i18n/Signup';
import Box from 'app/ui-components/Box';
import Stack from 'app/ui-components/Stack';

import { SignUpStepTwoProps } from '../types';
import { removeMultipleSpaces } from 'app/utils/removeMultipleSpaces';
import useApiEmailAvailabilityCheck from '../../../hooks/useApiEmailAvailabilityCheck';
import { SnackbarService } from 'app/components/Snackbar';
import { useEffect } from 'react';
import Loader from 'app/components/Loader';
import { get } from 'lodash';
import { Divider } from '@mui/material';

function SignUpStepTwo(props: SignUpStepTwoProps) {
  const { email, onConfirm, onClose } = props;
  const { fetchingApi, data, executeEmailAvailabilityCheck } = useApiEmailAvailabilityCheck();
  const isEmailAvailable = get(data, 'success', false);

  useEffect(() => {
    checkEmailAvailability();
  }, []);

  const checkEmailAvailability = async () => {
    try {
      const ApiPayloads = {
        email: removeMultipleSpaces(email),
      };

      await executeEmailAvailabilityCheck(ApiPayloads);
    } catch (error: any) {
      SnackbarService.showError({ message: error?.message });
    }
  };

  return (
    <form>
      <Box className="mx-auto space-y-24 py-40 text-grey-900">
        {!!fetchingApi ? (
          <Loader />
        ) : isEmailAvailable ? (
          <>
            <h1 className="mt-12 text-center text-[36px] font-semibold">
              {SignUp.FormLabels.stepTwoTitle}
            </h1>
            <p className="mt-12 text-center ">{SignUp.FormLabels.stepTwoCorrectEmail}</p>
            <p className="mt-12 text-center text-[30px]">{email}</p>
            <Box className="flex justify-center text-center">
              <p className="mt-12 max-w-[420px] text-center">{SignUp.FormLabels.stepTwoDesc}</p>
            </Box>
            <div className="relative h-[20px] w-full">
              <Divider
                className="absolute"
                sx={{ color: 'var(--red900)', width: 'calc(100% + 40px)' }}
              />
            </div>
            <Stack justifyContent="center" spacing="space-x-12">
              <Button theme="success" onClick={onConfirm}>
                {SignUp.FormActions.yes}
              </Button>
              <Button theme="danger" onClick={onClose}>
                {SignUp.FormActions.no}
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box className="flex justify-center">
              <p className="mt-12 max-w-[420px] text-center">{SignUp.FormLabels.unAvailableMsg}</p>
            </Box>
            <Stack justifyContent="center" spacing="space-x-12">
              <Button classes="" theme="danger" onClick={onClose}>
                {SignUp.FormActions.useDifferentEmail}
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </form>
  );
}

export default SignUpStepTwo;
