import RolesSelect, { RolesSelectProps } from 'app/modules/roles/components/RolesSelect';

import FormFieldContainer, { FormFieldContainerProps } from '../FormFieldContainer';

type Props = Omit<FormFieldContainerProps, 'children'> & RolesSelectProps;

const FormRolesSelect = (props: Props) => {
  const { className, label, isRequired, error, ...selectProps } = props;

  return (
    <FormFieldContainer className={className} error={error} isRequired={isRequired} label={label}>
      <RolesSelect {...selectProps} />
    </FormFieldContainer>
  );
};

export default FormRolesSelect;
