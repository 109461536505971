import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { Button } from '@procurenetworks/procure-component-library';
import FormTextInput from 'app/components/ProcureForm/FormTextInput';
import Common from 'app/i18n/Common';
import Wishlist from 'app/i18n/Wishlist';
import Box from 'app/ui-components/Box';

import { CREATELIST_FORM_RULES as rules } from '../../../rules';

function StepFour(props: any) {
  const { isLoading, onNext, onPrevious, formValues } = props;

  const defaultValues = {
    shippingAddress: formValues.shippingAddress,
  };

  const { control, handleSubmit } = useForm({ defaultValues: defaultValues });
  const onFormSubmit = useMemo(() => handleSubmit(onNext), [handleSubmit, onNext]);

  return (
    <form data-testid="create-list-form">
      <Box className="mx-auto mt-12 min-w-[120px] max-w-[487px] space-y-24">
        <label className="font-semibold text-grey-900">
          Where do you want these items shipped?
        </label>
        <div className="space-y-10" style={{ marginTop: '32px', marginBottom: '18px' }}>
          <Stack gap="16px" direction="column">
            <Box>
            <Controller
              control={control}
              name="shippingAddress.address"
              render={({ field, fieldState }) => (
                <FormTextInput
                  {...field}
                  isRequired
                  className="w-full rounded-none"
                  error={fieldState.error}
                  label={Wishlist.FormLabels.ShippingAddress}
                  formLabel={Wishlist.FormLabels.ShippingAddress}
                />
              )}
              rules={rules.shippingAddress}
            />
          </Box>
          <Box>
          <Controller
            control={control}
            name="shippingAddress.city"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={Wishlist.FormLabels.City}
                formLabel={Wishlist.FormLabels.City}
              />
            )}
            rules={rules.city}
          />
          </Box>
          <Box>
          <Controller
            control={control}
            name="shippingAddress.state"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={Wishlist.FormLabels.State}
                formLabel={Wishlist.FormLabels.State}
              />
            )}
            rules={rules.state}
          />
          </Box>
          <Box>
          <Controller
            control={control}
            name="shippingAddress.zipCode"
            render={({ field, fieldState }) => (
              <FormTextInput
                {...field}
                isRequired
                className="w-full rounded-none"
                error={fieldState.error}
                label={Wishlist.FormLabels.ZipCode}
                formLabel={Wishlist.FormLabels.ZipCode}
              />
            )}
            rules={rules.zipCode}
          />
          </Box>
          </Stack>
        </div>
        <Stack className='w-full'>
          <Box className='flex gap-[16px]'>
            <Button block={true} onClick={onPrevious} classes='flex-1'>
              {Common.Actions.Previous}
            </Button>
            <Button block={true} loading={isLoading} theme="info" onClick={onFormSubmit} classes='flex-1'>
              {Common.Actions.Next}
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
  );
}

export default StepFour;
