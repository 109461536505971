import { ItemLocationItemTypeEnum, ItemTypeEnum, TransactionEntityTypeEnum } from 'app/types/schema';

export function getItemLocationItemTypesFromTransactionType(
  type?: TransactionEntityTypeEnum,
): TransactionEntityTypeEnum[] {
  if (type === TransactionEntityTypeEnum.Asset) {
    return [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit];
  } else if (type === TransactionEntityTypeEnum.Inventory) {
    return [TransactionEntityTypeEnum.Inventory, TransactionEntityTypeEnum.InventoryKit];
  }
  return [
    TransactionEntityTypeEnum.Asset,
    TransactionEntityTypeEnum.AssetKit,
    TransactionEntityTypeEnum.Inventory,
    TransactionEntityTypeEnum.InventoryKit,
  ];
}
export function getItemLocationItemTypesFromTransactionTypes(
  type: TransactionEntityTypeEnum[],
): TransactionEntityTypeEnum[] {
  const results: TransactionEntityTypeEnum[] = [];

  if (type.includes(TransactionEntityTypeEnum.Asset)) {
    results.push(TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit);
  }
  if (type.includes(TransactionEntityTypeEnum.Inventory)) {
    results.push(TransactionEntityTypeEnum.Inventory, TransactionEntityTypeEnum.InventoryKit);
  }
  if (type.includes(TransactionEntityTypeEnum.ItAsset)) {
    results.push(TransactionEntityTypeEnum.ItAsset);
  }

  return results;
}

export function getItemLocationItemsTypesFromTransactionTypes(
  type: TransactionEntityTypeEnum[],
): ItemLocationItemTypeEnum[] {
  const typeMapping: Record<TransactionEntityTypeEnum, ItemLocationItemTypeEnum[]> = {
    [TransactionEntityTypeEnum.Asset]: [ItemLocationItemTypeEnum.Asset, ItemLocationItemTypeEnum.AssetKit],
    [TransactionEntityTypeEnum.AssetKit]: [ItemLocationItemTypeEnum.AssetKit],
    [TransactionEntityTypeEnum.AssetModel]: [],
    [TransactionEntityTypeEnum.Inventory]: [ItemLocationItemTypeEnum.Inventory, ItemLocationItemTypeEnum.InventoryKit],
    [TransactionEntityTypeEnum.InventoryKit]: [ItemLocationItemTypeEnum.InventoryKit],
    [TransactionEntityTypeEnum.ItAsset]: [ItemLocationItemTypeEnum.ItAsset]
  };

  const results: ItemLocationItemTypeEnum[] = [];

  type.forEach((entityType) => {
    if (typeMapping[entityType]) {
      results.push(...typeMapping[entityType]);
    }
  });

  return results;
}

export function getAssetTransactionEntityTypes() {
  return [TransactionEntityTypeEnum.Asset, TransactionEntityTypeEnum.AssetKit];
}

export function getInventoryTransactionEntityTypes() {
  return [TransactionEntityTypeEnum.Inventory, TransactionEntityTypeEnum.InventoryKit];
}

export function getAssetItemTypes() {
  return [ItemTypeEnum.Asset, ItemTypeEnum.AssetKit];
}

export function getInventoryItemTypes() {
  return [ItemTypeEnum.Inventory, ItemTypeEnum.InventoryKit];
}

export function getInventoryItemLocationItemTypes() {
  return [ItemLocationItemTypeEnum.Inventory, ItemLocationItemTypeEnum.InventoryKit];
}

export function getAssetItemLocationItemTypes() {
  return [ItemLocationItemTypeEnum.Asset, ItemLocationItemTypeEnum.AssetKit];
}
