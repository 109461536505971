import { useCallback, useMemo } from 'react';
import UnifiedHeader from 'app/modules/components/UnifiedHeader';
import { Layout, SideBarItemGroup } from '@procurenetworks/procure-component-library';
import { RoutePropsWithChildren, withRouter } from 'app/libs/navigation';
import useCurrentUser from 'app/modules/auth/hooks/useCurrentUser';

import { getReportsLayoutSidebarItems, REPORT_LAYOUT_ROUTES_CONFIG } from './utils';

const ReportsLayout = (props: RoutePropsWithChildren) => {
  const { children, history, match } = props;
  const currentUser = useCurrentUser();

  const onClick = useCallback(
    (item: any) => {
      const config = REPORT_LAYOUT_ROUTES_CONFIG.find((config) => config.id === item.id);

      if (config) {
        history.push(config.route);
      }
    },
    [history],
  );

  const sideBarItemGroup = useMemo(
    () => getReportsLayoutSidebarItems(currentUser.workspacePermissions),
    [currentUser.workspacePermissions],
  );

  const selectedItemId = useMemo(() => {
    const selectedItem = REPORT_LAYOUT_ROUTES_CONFIG.find(({ routes }) =>
      routes.includes(match.path),
    );
    return selectedItem ? selectedItem.id : '';
  }, [match.path]);

  const filterMenuItems: SideBarItemGroup[] = useMemo(() => {
    return sideBarItemGroup.filter((item) => item.sideBarItems.length > 0) || [];
  }, [sideBarItemGroup]);

  return (
    <Layout
      withAppBar
      withDrawer={!!filterMenuItems.length}
      components={{ AppBar: <UnifiedHeader /> }}
      sideBarProps={{
        selectedItemId,
        sideBarItemGroup: filterMenuItems,
        onClick,
      }}>
      {children}
    </Layout>
  );
};

export default withRouter(ReportsLayout);
