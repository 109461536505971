import { Button } from '@procurenetworks/procure-component-library';
import { AddRowOrderDetailsSchema, OrderRequestItemTypeEnum, OrderRequestTypeEnum } from 'app/types/schema';
import { OrderItemsExtraPropType } from '../OrderItems/type';
import { handleAddRowInOrderDeails } from '../../views/OrderDetail/utils';
import { OrderDetailContext } from '../../provider/OrderDetailsContext';
import { useContext } from 'react';
import Orders from 'app/i18n/Orders';

export type AddOrderRowButtonProps = {
  actions: OrderItemsExtraPropType['actions'];
  rows: Array<AddRowOrderDetailsSchema>;
  type: OrderRequestTypeEnum;
  isDisabled: boolean;
}

const AddOrderRowButton = (props: AddOrderRowButtonProps) => {
  const { rows, isDisabled } = props;
  const { isParentTenantOrderView } = useContext(OrderDetailContext);
  return (
    <div>
      <Button
        theme="success"
        disabled={rows[0].type === OrderRequestItemTypeEnum.NoSku || isParentTenantOrderView || isDisabled}
        onClick={() => handleAddRowInOrderDeails(props)}
        classes="min-w-[80px] h-[44px]">
        {Orders.Actions.AddItemRow}
      </Button>
    </div>
  );
};

export default AddOrderRowButton;