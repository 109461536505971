import * as Types from 'app/types/schema';

import gql from 'graphql-tag';
export type LabelShippingTableRowFragment = { __typename?: 'ShippingContainerSchema', id: string, isReusable: boolean, containerId: string, destinationSiteId: string, tenantId: string, destinationSite: { __typename?: 'LocationSchema', id: string, name: string } };

export const LabelShippingTableRowFragmentDoc = gql`
    fragment LabelShippingTableRow on ShippingContainerSchema {
  id
  isReusable
  containerId
  destinationSiteId
  tenantId
  destinationSite {
    id
    name
  }
}
    `;