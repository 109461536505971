import routes from 'app/consts/routes';
import Shop from 'app/i18n/Shop';
import { RouteComponentProps, withRouter } from 'app/libs/navigation';
import EntityManager from 'app/modules/components/EntityManager';
import ImageViewDialog from 'app/modules/components/ImageViewDialog';
import { AllowedPermissionsSubjectEnum } from 'app/types/schema';
import Stack from 'app/ui-components/Stack';

import AffiliateMerchantsTable from './components/AffiliateMerchantsTable';
import useAffiliateState from './hook/useAffiliateMerchantState';
import useAffiliateTable from './hook/useAffiliateMerchantTable';
import useSearchSuggestion from './hook/useSearchSuggestion';
import { InputAdornment } from '@mui/material';
import { CustomIcons } from '@procurenetworks/procure-component-library';

function AffiliateMerchants(props: RouteComponentProps) {
  const { onCloseImageViewDialog, imagePreviewState, showImageViewDialog } = useAffiliateState();

  const { searchSuggestions, setSearchValue } = useSearchSuggestion();

  const { tableState, table, search } = useAffiliateTable({});

  return (
    <>
      <EntityManager subject={AllowedPermissionsSubjectEnum.AffiliateConfiguration}>
        <EntityManager.Title
          documentTitle={Shop.Admin.AffiliateConfiguration}
          title={Shop.Admin.AffiliateConfiguration}
        />
        <EntityManager.Add
          label={Shop.Admin.NewAffiliateConfiguration}
          to={routes.AddAffiliateConfiguration()}
        />
        <Stack className="mt-32" justifyContent="between">
          <EntityManager.Search
            {...search}
            isDropdown
            suggestionData={searchSuggestions || []}
            onChangeSearch={setSearchValue}
            placeholder='Search'
            startAdornment={
              <InputAdornment position="start" className='mt-[4px] ml-[5px]'>
                 <CustomIcons.SearchIcon/>
                </InputAdornment>
            }
          />
        </Stack>

        <AffiliateMerchantsTable
          showImageViewDialog={showImageViewDialog}
          table={table}
          tableState={tableState}
        />
      </EntityManager>

      <ImageViewDialog
        id="logo-image-view"
        imageUrl={imagePreviewState.imageUrl}
        open={imagePreviewState.open}
        onClose={onCloseImageViewDialog}
      />
    </>
  );
}

export default withRouter(AffiliateMerchants);
