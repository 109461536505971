import { SiteReportOption } from '../types';
import { LocationTypeEnum, SortOrderEnum } from '../../../../../types/schema';

function getLocationTypeExportValue(locationType: LocationTypeEnum): LocationTypeEnum {
  switch (locationType) {
    case LocationTypeEnum.Location: {
      return 'location' as unknown as LocationTypeEnum;
    }
    case LocationTypeEnum.PartnerTenant: {
      return 'partnerTenant' as unknown as LocationTypeEnum;
    }
    case LocationTypeEnum.Site: {
      return 'site' as unknown as LocationTypeEnum;
    }
  }
}

export function getFiltersBySiteOption(siteOption: SiteReportOption, isActionExport?: boolean) {
  const filters = [];
  switch (siteOption) {
    case SiteReportOption.nonZeroTotalQuantity:
      filters.push({ nonZeroTotalQuantity: true });
      break;
    case SiteReportOption.includeItemsWithZeroQuantity: {
      let locationTypeValue: LocationTypeEnum = LocationTypeEnum.Site;
      if (isActionExport) {
        locationTypeValue = getLocationTypeExportValue(LocationTypeEnum.Site);
      }
      filters.push({ nonZeroTotalQuantity: true });
      filters.push({ recentOnly: true });
      filters.push({
        locationTypes: [locationTypeValue],
        _or: [
          {
            nonZeroMinimumQuantity: true,
          },
          {
            nonZeroTotalQuantityFromLocations: true,
          },
        ],
      });
      break;
    }
    case SiteReportOption.onlyMinimumQuantityThresholdBreached:
      filters.push({
        _or: [
          {
            onlyMinimumQuantityThresholdOfSiteBreached: true,
          },
          {
            onlyMinimumQuantityThresholdBreached: true,
          },
        ],
      });
      break;
  }

  return filters;
}

export function getSortsBySiteOption(siteOption: SiteReportOption) {
  const sorts = [];

  switch (siteOption) {
    case SiteReportOption.onlyMinimumQuantityThresholdBreached:
    case SiteReportOption.includeItemsWithZeroQuantity: {
      sorts.push(
        ...[
          {
            sortField: 'itemId',
            sortOrder: SortOrderEnum.Desc,
          },
          {
            sortField: 'siteId',
            sortOrder: SortOrderEnum.Desc,
          },
          {
            sortField: 'locationId',
            sortOrder: SortOrderEnum.Asc,
          },
          {
            sortField: 'updatedAt',
            sortOrder: SortOrderEnum.Desc,
          },
        ],
      );
      break;
    }
    default:
      sorts.push({ sortField: 'updatedAt', sortOrder: SortOrderEnum.Desc });
  }

  return sorts;
}