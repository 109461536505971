import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormProjectSelect, { Option } from 'app/components/ProcureForm/FormProjectSelect';
import Orders from 'app/i18n/Orders';
import { FeatureFlagEnum, useFeatureFlag } from 'app/libs/featureFlag';

import { OrderFieldProps } from '../../type';
import FormLabel from 'app/components/ProcureForm/FormLabel';

const OrderProjectField = (props: OrderFieldProps) => {
  const { formState, disabled, isExternalTenant, formLabel, isRequired } = props;
  const { control, setValue } = formState;

  const { value: isProjectFieldEnabled, loading } = useFeatureFlag(
    FeatureFlagEnum.ProjectFieldEnabledInOrderRequestForm,
  );

  const onChange = useCallback(
    (projectItem: Option | null) => {
      setValue('projectName', projectItem?.name || '');
    },
    [setValue],
  );

  if (loading || !isProjectFieldEnabled) {
    return null;
  }

  return (
    <div className="mb-20">
      {formLabel ? (
        <FormLabel
          isRequired={isRequired}
          className="ml-[40px] text-[14px] font-medium text-grey-800">
          {formLabel}
        </FormLabel>
      ) : null}
      <div className="order-project-input ml-[40px] mt-6 flex-1 flex-auto">
        <Controller
          control={control}
          name="projectId"
          render={({ field, fieldState }) => (
            <FormProjectSelect
              label={Orders.FormLabels.Project}
              {...field}
              disabled={disabled}
              error={fieldState.error}
              onChange={(value, option) => {
                field.onChange(value);
                onChange(option);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default OrderProjectField;
